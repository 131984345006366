import React from 'react';
import styled from 'styled-components';

interface NormalButtonProps {
	text: string;
	onSubmit: any;
	className?: string;
}

const NormalButton = ({ text, onSubmit, className }: NormalButtonProps) =>
	<Button onClick={() => onSubmit()} className={className}>
		{text}
	</Button>;

export default NormalButton;

const Button = styled.button`
  padding: 12px 46px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.mainWhite};
  background-color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;

  //비활성버튼
  &.unActiveBtn {
    background-color: ${({ theme }) => theme.color.subUnactiveGray};
    cursor: not-allowed;
  }

  //취소버튼
  &.cancelBtn {
    padding: 12px 32px;
    color: ${({ theme }) => theme.color.subDarkGray};
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subGray};
  }

  // 막는 버튼
  &.blockBtn {
    padding: 12px 46px;
    color: ${({ theme }) => theme.color.subDarkGray};
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subGray};
  }

  &.refreshBtn {
    padding: 12px 46px;
    color: ${({ theme }) => theme.color.subDarkGray};
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subGray};
  }

  &.modalBtn {
    min-width: 140px;
    padding: 13px 25px;
    margin: 25px 0 0 30px;
  }

  &.unActiveModalBtn {
    min-width: 140px;
    padding: 13px 25px;
    margin: 25px 0 0 30px;
    background-color: ${({ theme }) => theme.color.subUnactiveGray};
    cursor: default;
  }

  //비활성버튼
  &.deleteBtn {
    background-color: ${({ theme }) => theme.color.mainRed};
    color: ${({ theme }) => theme.color.mainWhite};
  }
  
  // 파일 첨부 버튼 대체
  &.fileInputModule {
    background-color: ${({ theme }) => theme.color.subDarkGray};
    color: ${({ theme }) => theme.color.mainWhite};
    height: 50px;
  }
  
  &.reportBtn{
  	padding: 12px 23px;
  	width: 100%;
  }

	&.subDarkGray {
    background-color: ${({theme}) => theme.color.subDarkGray};
    color: ${({theme}) => theme.color.mainWhite};
  }

`;
