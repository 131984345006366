import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { OFFICIAL_ACCOUNTS } from '../../constant/apiUrl';

const OfficialCollectionList = () => {
  const navigate = useNavigate();
  const [accountList, setAccountList] = useState<any>([]);

  // 상세로 이동
  const goToDetail = (state: any) => {
    sessionStorage.setItem('explore_collection_id', state.id);
    navigate('/explore/collection/list');
  };

  // 장르탭 리스트 불러오기
  const getOfficialAccount = useCallback(async () => {
    const params = {
      limit: 100,
      offset: 0,
    };
    try {
      const resp = await axios.get(OFFICIAL_ACCOUNTS, { params });
      setAccountList(resp.data.officialAccountList);
      console.log(resp.data.officialAccountList);
    } catch (err) {
      // console.log(err);
    }
  }, []);

  useEffect(() => {
    getOfficialAccount();
  }, [getOfficialAccount]);

  return (
    <MainContainer>
      <Ul>
        {accountList?.map((account: any) => {
          return (
            <Li
              key={account.id}
              onClick={() => {
                goToDetail(account);
              }}
            >
              {account.pid} - {account.name}
            </Li>
          );
        })}
      </Ul>
    </MainContainer>
  );
};

export default OfficialCollectionList;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.font.titleFont};
`;

const Ul = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 59px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;
