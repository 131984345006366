import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './Nav/Navbar';

const MainLayout = () => {
	return <MainLayoutRoot>
		<Container>
			<Navbar />
			<Outlet />
		</Container>
	</MainLayoutRoot>;
};

export default MainLayout;

const MainLayoutRoot = styled.div`
  width: 100%;
`;

const Container = styled.div`
  margin: 0 auto;
`;
