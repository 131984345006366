export const upcomingBeatSearchTypeList = [
  { text: 'Title', value: 'T' },
  { text: 'pid', value: 'BMP' },
];

export const allTabMenuList = [
  { id: 1, title: '추천 해시태그', link: 'hashTag' },
  { id: 2, title: '추천 장르(스튜디오 노출)', link: 'recommendGenre' },
  { id: 3, title: '추천 비트메이커', link: 'recommendBeatMaker' },
  { id: 4, title: '최신 비트', link: 'recentlyBeat' },
  { id: 5, title: '추천 장르(둘러보기 노출)', link: 'genre' },
  { id: 6, title: '추천 컬렉션', link: 'recommendCollection' },
  { id: 7, title: '발매 예정 비트', link: 'upcomingBeat' },
  { id: 8, title: 'Baund Music(Complit, Original Play, Powered)', link: 'baundMusic',},
  { id: 9, title: '인기 비트', link: 'hotBeat' },
  { id:10, title: '추천 무드(스튜디오 비트 추천)', link: 'mood'}
];

type collectionTypeOption = {
  [key: string]: string;
};

export const collectionType: collectionTypeOption = {
  BT: '비트',
  AR: '영상',
};

export const reportType: collectionTypeOption = {
  NC: '차단',
  CB: '차단',
  CU: '정상',
};

export const baundMusicSearchTypeList = [
  { text: 'Index', value: 'I' },
  { text: 'Title', value: 'T' },
];

export const recommendHotBeatSearchTypeList = [
  { text: 'Title', value: 'T' },
  { text: 'pid', value: 'BMP' },
];

export const bannerTypeValues = {
  STICK: '스틱형',
}

export const bannerImgTypeValues = {
  ADDRESS: '주소',
  FILE: '파일',
}


//----------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------
//----------------------------------------------------------------------------------------------------------------------


export const upComingColList = [52, 142, 282, 220, 220];
export const recommendBeatMakerColList = [66, 163, 100, 164, 122, 98, 112, 170];
export const recommendHashTagList = [800, 60];

export const beatCollectionList = [70, 60, 140, 120, 100, 90, 90, 80, 150];
export const videoCollectionList = [70, 70, 100, 120, 105, 120, 90, 50, 80];
export const officialCollectionList = [84, 123, 111, 98, 232, 165, 99, 83];
export const beatCollectionColList = [66, 160, 100, 160, 120, 100, 112, 170];

export const addCollectionList = [70, 100, 100, 100, 260, 180, 100, 70];
export const addBeatCollectionColList = [66, 160, 100, 150, 90, 90, 110, 150];
export const addUserCollectionColList = [70, 140, 170, 100, 140, 80, 100, 60];
export const addArticleCollectionColList = [61, 73, 142, 120, 183, 168, 107, 61, 70,];




