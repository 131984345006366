import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import axios from "axios";

import NormalButton from "../../../components/common/Button/NormalButton";
import blankImg from '../../../assets/images/page/blankImg.png';
import {resetLoading, setLoading} from "../../../redux/slices/user";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import {
  ADVERTISEMENT,
  ADVERTISEMENT_LANDING, ADVERTISER
} from "../../../constant/apiUrl";
import FileInput from "../../../components/common/Input/FileInput";
import RadioButton from "../../../components/common/Button/RadioButton";
import CheckBoxInput from "../../../components/common/Input/CheckBoxInput";
import {
  isValidURL
} from "../../../utils/commonFn";
import AdvertisementSelector from "../component/AdvertisementSelector";
import {GetCountryOption} from "../../../constant/common";
import LabelPlusButtonInput
  from "../../../components/common/Input/LabelPlusButtonInput";
import NormalButtonWithLabel from "../component/NormalButtonWiteLabel";
import handleDate from "../../../hooks/handleDate";

// 광고 상세 화면(추가, 수정)
const AdvertisementLandingDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const _thumbnailFileInputRef = useRef();
  const _detailImg01FileInputRef = useRef();
  const _detailImg01EnFileInputRef = useRef();
  const _detailImg02FileInputRef = useRef();
  const _detailImg02EnFileInputRef = useRef();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const advertisementLandingId = detailData !== null ? detailData.advertisementLandingId : null;
  // console.log('AdvertisementLandingDetail > advertisementLandingId:', advertisementLandingId);

  // 광고 속성 //////////////////////////////////////////////////////////////////
  const [advertisement, setAdvertisement] = useState(null);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [advertisementTitle, setAdvertisementTitle] = useState('');
  const [advertisementStrId, setAdvertisementStrId] = useState('');
  const [advertisementStartDt, setAdvertisementStartDt] = useState('');
  const [advertisementEndDt, setAdvertisementEndDt] = useState('');
  const [advertisementCountries, setAdvertisementCountries] = useState('');

  // 광고주 속성 ////////////////////////////////////////////////////////////////
  const [advertiserId, setAdvertiserId] = useState();
  const [advertiserName, setAdvertiserName] = useState('');

  // 광고 랜딩 속성 /////////////////////////////////////////////////////////////
  // 광고 Id
  const [advertisementId, setAdvertisementId] = useState();
  // 공유 썸네일 URL
  const [thumbnailImgUrl, setThumbnailImgUrl] = useState('');
  const [thumbnailImgFile, setThumbnailImgFile] = useState('');
  // 국문 내용 유무
  const [koreanYn, setKoreanYn] = useState('Y');
  // 영문 내용 유무
  const [englishYn, setEnglishYn] = useState('N');
  // 제목
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  // 상세 이미지1 URL
  const [detailImgUrl01, setDetailImgUrl01] = useState('');
  const [detailImgUrl01En, setDetailImgUrl01En] = useState('');
  const [detailImgFile01, setDetailImgFile01] = useState();
  const [detailImgFile01En, setDetailImgFile01En] = useState();
  // 상세 이미지2 URL
  const [detailImgUrl02, setDetailImgUrl02] = useState('');
  const [detailImgUrl02En, setDetailImgUrl02En] = useState('');
  const [detailImgFile02, setDetailImgFile02] = useState();
  const [detailImgFile02En, setDetailImgFile02En] = useState();
  // 상세 영상 URL
  const [detailMovieUrl, setDetailMovieUrl] = useState('');
  const [detailMovieUrlEn, setDetailMovieUrlEn] = useState('');
  // const [detailMovieFile, setDetailMovieFile] = useState();
  // const [detailMovieFileEn, setDetailMovieFileEn] = useState();
  // 외부 연동 버튼 노출 여부
  const [externalLinkBtnYn, setExternalLinkBtnYn] = useState('Y');
  // 외부 연동 버튼 명
  const [externalLinkBtnName, setExternalLinkBtnName] = useState('');
  const [externalLinkBtnNameEn, setExternalLinkBtnNameEn] = useState('');
  // 외부 연동 URL
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  // 상단 공유 버튼 노출 여부
  const [shareBtnYn, setShareBtnYn] = useState('Y');
  // 유의 사항
  const [notice, setNotice] = useState([]);
  const [noticeEn, setNoticeEn] = useState([]);

  const [checkResultDetailMovieUrl, setCheckResultDetailMovieUrl] = useState('');
  const [checkResultDetailMovieUrlEn, setCheckResultDetailMovieUrlEn] = useState('');
  const [checkResultExternalLinkUrl, setCheckResultExternalLinkUrl] = useState('');

  //광고 데이터 조회 ////////////////////////////////////////////////////////////
  const getAdvertisements = async() => {
    await axios.get(`${ADVERTISEMENT}-active`, {params:{offset: 0, limit: 100,}})
    .then(res => {
      setAdvertisementList(res.data.adminAdvertisementList);
      // console.log('AdvertisementLanding > getAdvertisements > adminAdvertisementList:',res.data.adminAdvertisementList);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  useEffect(() => {
    getAdvertisements().then(() => {})
  }, []);

  // 광고 정보 변경시 설정
  const handleAdvertisement = (e) => {
    // console.log('AdvertisementLandingDetail > handleAdvertisement > e:', e);

    if (!advertisementId || confirm(`아래 광고캠페인 데이터도 함께 변경됩니다. 광고id를 변경하시겠습니까?`)) {
      const countrySelected = setAdvertisementInfo(e);
      setAdvertisementCountries(countrySelected);

      setAdvertiserId(e.advertiserId);
    }
  };

  const setAdvertisementInfo = (advertisementDetail) => {
    setAdvertisement(advertisementDetail);
    setAdvertisementId(advertisementDetail.advertisementId);
    setAdvertisementStrId(advertisementDetail.advertisementStrId);
    setAdvertisementTitle(advertisementDetail.title);
    setAdvertisementStartDt(advertisementDetail.startDt);
    setAdvertisementEndDt(advertisementDetail.endDt);
    let countrySelected = advertisementDetail.countries.map(country => {
          return GetCountryOption[country];
        }
    );
    setAdvertisementCountries(countrySelected.join(","));
    // setAdvertisementMemo(adInfo.memo);
    return countrySelected;
  }

  //광고주 데이터 조회 //////////////////////////////////////////////////////////
  const getAdvertiser = async() => {
    // console.log('AdvertisementLandingDetail > getAdvertiser > advertiserId:', advertiserId);
    await axios.get(`${ADVERTISER}/${advertiserId}`,{})
    .then(res =>{
      setAdvertiserName(res.data.adminAdvertiser.name);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  };
  useEffect(() => {
    if (advertiserId) getAdvertiser().then();
  }, [advertiserId]);

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    let retVal = null;
    await axios.get(
        `${ADVERTISEMENT_LANDING}/${advertisementLandingId}`,
    )
    .then(res => {
      // console.log('AdvertisementLanding > getDetail > res:',res);
      const detail = res.data.adminAdvertisementLanding;

      setAdvertiserId(detail.advertisement.advertiser.advertiserId);
      setAdvertiserName(detail.advertisement.advertiser.name);

      setThumbnailImgUrl(detail.thumbnailImgUrl);
      // setKoreanYn(detail.koreanYn);
      setEnglishYn(detail.englishYn);
      setTitle(detail.title);
      setTitleEn(detail.titleEn);
      setDetailImgUrl01(detail.detailImgUrl01);
      setDetailImgUrl01En(detail.detailImgUrl01En);

      setDetailImgUrl02(detail.detailImgUrl02);
      setDetailImgUrl02En(detail.detailImgUrl02En);
      setDetailMovieUrl(detail.detailMovieUrl);
      setDetailMovieUrlEn(detail.detailMovieUrlEn);
      setExternalLinkBtnYn(detail.externalLinkBtnYn);
      setExternalLinkBtnName(detail.externalLinkBtnName);
      setExternalLinkBtnNameEn(detail.externalLinkBtnNameEn);
      setExternalLinkUrl(detail.externalLinkUrl);
      setShareBtnYn(detail.shareBtnYn);

      if (detail.notice) {
        setNotice(detail.notice.map((el, idx) => ({id: idx, text: el})));
        noticeNextId.current = detail.notice.length;
      }

      if (detail.noticeEn) {
        setNoticeEn(detail.noticeEn.map((el, idx) => ({id: idx, text: el})));
        noticeNextIdEn.current = detail.noticeEn.length;
      }

      // setAdvertisementInfo(advertisementList.filter(ad=>ad.advertisementId===detail.advertisementId)[0]);
      retVal = detail.advertisementId;
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
    return retVal;
  };
  // (호출 순서 보장을 위해) 활성화된 광고 목록에 변화가 있으면 캠페인 상세 조회
  useEffect(() => {
    // 수정일 때만
    if (advertisementLandingId) {
      // 랜댕 상세 조회
      getDetail().then((_advertisementId) => {
        // 랜딩의 광고 상세를 조회하고,
        getAdvertisementDetail(_advertisementId).then();
      });
    }
  }, [advertisementList]);

  // 광고 상세 조회
  const getAdvertisementDetail = async (_advertisementId) => {
    await axios.get(
        `${ADVERTISEMENT}/${_advertisementId}`,
    )
    .then(res => {
      // 광고 정보 설정
      setAdvertisementInfo(res.data.adminAdvertisement);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };

  // 활성화된 광고 목록이나 랜딩의 광고에 변화가 있을때
  useEffect(()=>{
    // 활성화된 광고 목록이 없고, 사용된 광고가 있을때
    if ((!advertisementList || advertisementList.length === 0) && advertisement) {
      // 이 랜딩의 광고를 활성화 된 광고 목록으로 설정
      setAdvertisementList([advertisement]);
    }
  }, [advertisementList, advertisement]);

  // 정보 저장 //////////////////////////////////////////////////////////////////
  // 저장
  const saveDetail = async () => {
    // 파라미터 체크
    if(!parameterCheck()) return;

    const request = {};
    const formData = new FormData();

    // 광고 Id
    request['advertisementId'] = advertisementId;
    // 광고주 Id
    request['advertiserId'] = advertiserId;
    // 공유 썸네일 URL
    (thumbnailImgFile != null) && formData.append('thumbnailImgFile', thumbnailImgFile);
    // 국문 내용 유무
    request['koreanYn'] = koreanYn;
    // 영문 내용 유무
    request['englishYn'] = englishYn;
    // 제목
    request['title'] = title;
    request['titleEn'] = titleEn;
    // 상세 이미지1 URL
    if (detailImgFile01 != null) {
      formData.append('detailImgFile01', detailImgFile01);
    }
    if (detailImgFile01En != null) {
      formData.append('detailImgFile01En', detailImgFile01En);
    }
    // 상세 이미지2 URL
    if (detailImgFile02 != null) {
      formData.append('detailImgFile02', detailImgFile02);
    }
    if (detailImgFile02En != null) {
      formData.append('detailImgFile02En', detailImgFile02En);
    }
    // 상세 영상 URL
    detailMovieUrl && (request['detailMovieUrl'] = detailMovieUrl);
    detailMovieUrlEn && (request['detailMovieUrlEn'] = detailMovieUrlEn);
    // if (detailMovieFile != null) {
    //   formData.append('detailMovieFile', detailMovieFile);
    // }
    // if (detailMovieFileEn != null) {
    //   formData.append('detailMovieFileEn', detailMovieFileEn);
    // }

    // 외부 연동 버튼 노출 여부
    request['externalLinkBtnYn'] = externalLinkBtnYn;
    // 외부 연동 버튼 명
    request['externalLinkBtnName'] = externalLinkBtnName;
    request['externalLinkBtnNameEn'] = externalLinkBtnNameEn;
    // 외부 연동 URL
    request['externalLinkUrl'] = externalLinkUrl;
    // 상단 공유 버튼 노출 여부
    request['shareBtnYn'] = shareBtnYn;
    // 유의 사항
    if (notice.length > 0) {
      request['notice'] = [];
      notice.forEach(el => request['notice'].push(el.text))
    }
    if (noticeEn.length > 0) {
      request['noticeEn'] = [];
      noticeEn.forEach(el => request['noticeEn'].push(el.text))
    }
    // console.log('AdvertisementLanding > saveDetail > request:', request);

    const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
    formData.append((advertisementLandingId) ? 'updateRequest' : 'saveRequest', blob);

    dispatch(setLoading());
    try {
      (advertisementLandingId)
        ? await axios.put(`${ADVERTISEMENT_LANDING}/${advertisementLandingId}`, formData)
        : await axios.post(`${ADVERTISEMENT_LANDING}`, formData, {});
      alert('저장이 완료 되었습니다.');
      navigate('/advertisement/advertisementLanding');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 저장 처리 호출 전 필수 입력값 체크
  const parameterCheck = () => {
    let notEnteredItems = [];
    if (!advertisementId ) notEnteredItems.push('- 광고 id');
    if ((koreanYn ==='Y' && !title) || (englishYn ==='Y' && !titleEn)
    ) notEnteredItems.push('- 랜딩 페이지 제목');
    if ((koreanYn ==='Y' && !detailImgUrl01 && detailImgFile01 == null)
      || (englishYn ==='Y' && !detailImgUrl01En && detailImgFile01En == null)
    ) notEnteredItems.push('- 첫번째 이미지');
    // if((koreanYn==='Y' && externalLinkBtnYn==='Y' && !externalLinkBtnName)
    //   || (englishYn==='Y'&& externalLinkBtnYn==='Y' && !externalLinkBtnNameEn)
    // ) notEnteredItems.push('- 버튼명');
    if(externalLinkBtnYn==='Y' && !externalLinkUrl)
      notEnteredItems.push('- 외부 연동 URL');
    if ((koreanYn ==='Y' && (!notice || notice.length === 0))
        || (englishYn ==='Y' && (!noticeEn || noticeEn.length === 0))
    ) notEnteredItems.push('- 유의사항');

    if (notEnteredItems.length !== 0) {
      notEnteredItems = ['아래 입력값을 확인해주세요.', ...notEnteredItems];
      alert(notEnteredItems.join('\n'));
      return false;
    }
    return true;
  }

  // const
  const noticeNextId = useRef(0);
  const noticeNextIdEn = useRef(0);

  // 유의사항 set 함수
  const handleNoticeSetData = (text) => {
    const noticeText = {id: noticeNextId.current, text};
    setNotice([...notice, noticeText]);
    noticeNextId.current += 1;
  };
  const handleNoticeSetDataEn = (text) => {
    const noticeText = {id: noticeNextIdEn.current, text};
    setNoticeEn([...noticeEn, noticeText]);
    noticeNextIdEn.current += 1;
  };

  // 유의사항 수정 함수
  const onEditNotice = useCallback((noticeId, text) => {
    setNotice(notice.map((el) => el.id === noticeId ? {...el, text} : el));
  }, [notice]);
  const onEditNoticeEn = useCallback((noticeId, text) => {
    setNoticeEn(noticeEn.map((el) => el.id === noticeId ? {...el, text} : el));
  }, [noticeEn]);

  // 유의사항 삭제 함수
  const onRemoveNotice = (noticeId) => {
    setNotice(notice.filter((noticeText) => noticeText.id !== noticeId));
  };
  const onRemoveNoticeEn = (noticeId) => {
    setNoticeEn(noticeEn.filter((noticeText) => noticeText.id !== noticeId));
  };

  const handleResultDetailMovieUrl = (_url) => {
    setCheckResultDetailMovieUrl(_url && !isValidURL(_url) ? 'URL 형식이 아닙니다.':'');
    setDetailMovieUrl(_url);
  };
  const handleResultDetailMovieUrlEn = (_url) => {
    setCheckResultDetailMovieUrlEn(_url && !isValidURL(_url) ? 'URL 형식이 아닙니다.':'');
    setDetailMovieUrlEn(_url);
  };
  const handleResultExternalLinkUrl = (_url) => {
    setCheckResultExternalLinkUrl(_url && !isValidURL(_url) ? 'URL 형식이 아닙니다.':'');
    setExternalLinkUrl(_url);
  };

  const FILE_CHANGE_MESSAGE = '첨부된 소재가 있습니다. 소재를 교체하시겠습니까?';
  // 공유 썸네일 파일 첨부 버튼 클릭시
  const handleThumbnail = () => {
    // 파일이 선택되어 있지 않거나 확인을 받은 경우에는
    if((!thumbnailImgUrl && !thumbnailImgFile) || confirm(FILE_CHANGE_MESSAGE)) {
      _thumbnailFileInputRef.current.click();
    }
  }
  // 공유 썸네일 파일 첨부 버튼 클릭시
  const handleDetailImg01 = () => {
    // 파일이 선택되어 있지 않거나 확인을 받은 경우에는
    if((!detailImgUrl01 && !detailImgFile01) || confirm(FILE_CHANGE_MESSAGE)) {
      _detailImg01FileInputRef.current.click();
    }
  }
  // 공유 썸네일 파일 첨부 버튼 클릭시
  const handleDetailImg01En = () => {
    // 파일이 선택되어 있지 않거나 확인을 받은 경우에는
    if((!detailImgUrl01En && !detailImgFile01En) || confirm(FILE_CHANGE_MESSAGE)) {
      _detailImg01EnFileInputRef.current.click();
    }
  }
  // 공유 썸네일 파일 첨부 버튼 클릭시
  const handleDetailImg02 = () => {
    // 파일이 선택되어 있지 않거나 확인을 받은 경우에는
    if((!detailImgUrl02 && !detailImgFile02) || confirm(FILE_CHANGE_MESSAGE)) {
      _detailImg02FileInputRef.current.click();
    }
  }
  // 공유 썸네일 파일 첨부 버튼 클릭시
  const handleDetailImg02En = () => {
    // 파일이 선택되어 있지 않거나 확인을 받은 경우에는
    if((!detailImgUrl02En && !detailImgFile02En) || confirm(FILE_CHANGE_MESSAGE)) {
      _detailImg02EnFileInputRef.current.click();
    }
  }

  return <>

    <TitleText>광고 랜딩페이지 {advertisementLandingId ? '수정':'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper>
        {/*<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>*/}
        <NormalButton text={'저장하기'} onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}/>
      </ButtonWrapper>
    </TopContainer>

    <SubTitle>광고 랜딩 페이지 기본 정보</SubTitle>
    <MainContainer>
      {/* 광고 설정 */}
      <InputWrapper colSpan={2}>
        {advertisementList.length === 0
            ?(<LabelTextInput
                name={'warn'} label={'광고ID 선택'} readOnly
                value={'광고ID가 없습니다.'}
            />)
            :(<AdvertisementSelector
                placeholder="연결 할 광고를 선택해주세요." label="광고 설정(공개 설정 되어 있는 광고 식별 제목만 노출)" Required
                type={'advertisement'}
                options={advertisementList}
                value={
                  (advertisementTitle && advertisementStrId) ? `${advertisementTitle}(${advertisementStrId})`:''
                }
                onChange={handleAdvertisement}
            />)
        }
      </InputWrapper>

      {/*광고 식별 제목*/}
      <InputWrapper>
        <LabelTextInput
          name={'advertisementTitle'} label={'광고 식별 제목'} readOnly
          placeholder={'광고 식별 제목과 같은 제목이 노출돼요'} value={advertisementTitle}
        />
      </InputWrapper>

      {/* 노출 기간 */}
      <InputWrapper>
        <LabelTextInput
            name={'advertisementDt'} label={'노출 기간'} readOnly
            placeholder={'광고에 입력한 노출 기간이 노출돼요'}
            value={ (advertisementStartDt && advertisementEndDt)
              ? `${handleDate(advertisementStartDt)}~${handleDate(advertisementEndDt)}`
              : ''}
        />
      </InputWrapper>

      {/* 광고주 명 */}
      <InputWrapper>
        <LabelTextInput
            name={'advertiserName'} label={'광고주 명'} readOnly
            placeholder={'광고와 연결된 광고주 명이 노출돼요.'} value={advertiserName}
        />
      </InputWrapper>

      {/* 국가 */}
      <InputWrapper>
        <LabelTextInput
            name={'advertisementCountries'} label={'국가'} readOnly
            placeholder={'광고와 같은 국가가 노출돼요.'} value={advertisementCountries}
        />
      </InputWrapper>
    </MainContainer>
    <LineDiv/>

    <SubTitle>상세 내용 첨부</SubTitle>
    <MainContainer>
      <InputWrapper>
        <NormalButtonWithLabel label={"공유 썸네일(국가 공통)"} buttonText={"파일 첨부"} Required buttonOnClick={handleThumbnail}/>
        랜딩페이지를 공유 했을 때 노출되는 이미지에요.
      </InputWrapper>
      <InputWrapper className={'hide'}>
        <FileInput
            type="eventImg" name="thumbnailImgUrl" label="공유 썸네일"
            placeholder={thumbnailImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={thumbnailImgFile} setFile={setThumbnailImgFile}
            fileUrl={thumbnailImgUrl} setImgUrl={setThumbnailImgUrl}
            fileInputRef={_thumbnailFileInputRef}
        />
      </InputWrapper>
      <InputWrapper mt={29}>
        {thumbnailImgUrl ?(<EventImg src={thumbnailImgUrl}/>) :(<EventImg src={blankImg}/>)}
      </InputWrapper>

      <InputWrapper mt={-15}>
        <CheckBoxInput name="koreanYn" text="한국어" type="yn" checked={koreanYn} onChange={()=>setKoreanYn('Y')}/>
      </InputWrapper>
      <InputWrapper mt={-15}>
        <CheckBoxInput name="englishYn" text="영어" type="yn" checked={englishYn} onChange={setEnglishYn}/>
      </InputWrapper>

      <InputWrapper colSpan={2} mt={-10}>
        영어 버전을 만들지 않으면 영어로 설정한 디바이스에서도 한국어로 노출돼요.
      </InputWrapper>

      {/*소제목*/}
      <InputWrapper>
        <InputWrapper><SubTitle>한국어 버전</SubTitle></InputWrapper>
      </InputWrapper>
      <InputWrapper>
        {englishYn==='Y' && (
          <InputWrapper><SubTitle>영어 버전</SubTitle></InputWrapper>
        )}
      </InputWrapper>

      {/*랜딩 페이지 제목*/}
      <InputWrapper>
        <LabelTextInput
            name="title" label="랜딩 페이지 제목" placeholder="회원에게 노출되는 화면 이름이에요" Required
            value={title} maxLen={20}
            onChange={(e) => setTitle(e.target.value)}
            onReset={() => setTitle('')}
        />
      </InputWrapper>
      <InputWrapper>
        {englishYn==='Y' && (
            <LabelTextInput
                name="titleEn" label="랜딩 페이지 제목" placeholder="회원에게 노출되는 화면 이름이에요" Required
                value={titleEn} maxLen={20}
                onChange={(e) => setTitleEn(e.target.value)}
                onReset={() => setTitleEn('')}
            />
        )}
      </InputWrapper>

      {/*첫번째 이미지*/}
      <InputWrapper>
        <NormalButtonWithLabel label={"첫번째 이미지(필수)"} buttonText={"파일 첨부"} Required buttonOnClick={handleDetailImg01}/>
      </InputWrapper>
      <InputWrapper>
        {(englishYn==='Y') &&<NormalButtonWithLabel label={"첫번째 이미지(필수)"} buttonText={"파일 첨부"} Required buttonOnClick={handleDetailImg01En}/>}
      </InputWrapper>

      <InputWrapper className={'hide'}>
        <FileInput
            type="advertisementOther" name="detailImgUrl01" label="첫번째 이미지(국문)"
            placeholder={detailImgUrl01 ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={detailImgFile01} setFile={setDetailImgFile01}
            fileUrl={detailImgUrl01} setImgUrl={setDetailImgUrl01}
            fileInputRef={_detailImg01FileInputRef}
        />
      </InputWrapper>
      <InputWrapper className={'hide'}>
        <FileInput
            type="advertisementOther" name="detailImgUrl01En" label="첫번째 이미지(영문)"
            placeholder={detailImgUrl01En ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={detailImgFile01En} setFile={setDetailImgFile01En}
            fileUrl={detailImgUrl01En} setImgUrl={setDetailImgUrl01En}
            fileInputRef={_detailImg01EnFileInputRef}
        />
      </InputWrapper>
      <InputWrapper>
        {detailImgUrl01 ?(<EventImg src={detailImgUrl01}/>) :(<EventImg src={blankImg}/>)}
      </InputWrapper>
      <InputWrapper>
        {(englishYn==='Y') &&(detailImgUrl01En ?(<EventImg src={detailImgUrl01En}/>) :(<EventImg src={blankImg}/>))}
      </InputWrapper>

      {/*영상 URL*/}
      <InputWrapper>
        <LabelTextInput
            name="detailMovieUrl" label="영상 URL" placeholder="첨부할 영상 URL을 입력해 주세요"
            value={detailMovieUrl}
            onChange={(e) => handleResultDetailMovieUrl(e.target.value)}
            onReset={() => handleResultDetailMovieUrl('')}
        />
        {checkResultDetailMovieUrl && <ResultText>{checkResultDetailMovieUrl}</ResultText>}
        {/*<FileWrapper>*/}
        {/*  <FileInput*/}
        {/*      type="article" label="동영상"*/}
        {/*      placeholder={(detailMovieUrl || detailMovieFile) ? '파일이 등록되었습니다.' : '파일을 선택해주세요. (mp4 파일만 가능)'}*/}
        {/*      value={detailMovieFile} setFile={setDetailMovieFile}*/}
        {/*  />*/}
        {/*  {(detailMovieUrl || detailMovieFile) && (*/}
        {/*      <Video key={detailMovieFile ? detailMovieFile.name: 1} ref={_videoRef} controls>*/}
        {/*        <track default kind="captions" />*/}
        {/*        <source type="video/mp4"*/}
        {/*                src={detailMovieFile ? URL.createObjectURL(detailMovieFile):detailMovieUrl}/>*/}
        {/*        Your browser does not support the video tag. I suggest you upgrade your browser.*/}
        {/*      </Video>*/}
        {/*  )}*/}
        {/*</FileWrapper>*/}
      </InputWrapper>
      <InputWrapper>
        {englishYn==='Y' && (
          <>
            <LabelTextInput
                name="detailMovieUrlEn" label="영상 URL" placeholder="첨부할 영상 URL을 입력해 주세요"
                value={detailMovieUrlEn}
                onChange={(e) => handleResultDetailMovieUrlEn(e.target.value)}
                onReset={() => handleResultDetailMovieUrlEn('')}
            />
            {checkResultDetailMovieUrlEn && <ResultText>{checkResultDetailMovieUrlEn}</ResultText>}
          </>
        )}
        {/*<FileWrapper>*/}
        {/*  <FileInput*/}
        {/*      type="article" label="동영상"*/}
        {/*      placeholder={(detailMovieUrlEn || detailMovieFileEn) ? '파일이 등록되었습니다.' : '파일을 선택해주세요. (mp4 파일만 가능)'}*/}
        {/*      value={detailMovieFileEn} setFile={setDetailMovieFileEn}*/}
        {/*  />*/}
        {/*  {(detailMovieUrlEn || detailMovieFileEn) && (*/}
        {/*      <Video key={detailMovieFileEn ? detailMovieFileEn.name: 1} ref={_videoRefEn} controls>*/}
        {/*        <track default kind="captions" />*/}
        {/*        <source type="video/mp4"*/}
        {/*                src={detailMovieFileEn ? URL.createObjectURL(detailMovieFileEn):detailMovieUrlEn}/>*/}
        {/*        Your browser does not support the video tag. I suggest you upgrade your browser.*/}
        {/*      </Video>*/}
        {/*  )}*/}
        {/*</FileWrapper>*/}
      </InputWrapper>

      {/*두번째 이미지*/}
      <InputWrapper>
        <NormalButtonWithLabel label={"두번째 이미지(선택)"} buttonText={"파일 첨부"} buttonOnClick={handleDetailImg02}/>
      </InputWrapper>
      <InputWrapper>
        {(englishYn==='Y') &&<NormalButtonWithLabel label={"두번째 이미지(선택)"} buttonText={"파일 첨부"} buttonOnClick={handleDetailImg02En}/>}
      </InputWrapper>
      <InputWrapper className={'hide'}>
        <FileInput
            type="advertisementOther" name="detailImgUrl02" label="두번째 이미지(국문)"
            placeholder={detailImgUrl02 ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={detailImgFile02} setFile={setDetailImgFile02}
            fileUrl={detailImgUrl02} setImgUrl={setDetailImgUrl02}
            fileInputRef={_detailImg02FileInputRef}
        />
      </InputWrapper>
      <InputWrapper className={'hide'}>
        <FileInput
            type="advertisementOther" name="detailImgUrl021En" label="두번째 이미지(영문)"
            placeholder={detailImgUrl02En ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={detailImgFile02En} setFile={setDetailImgFile02En}
            fileUrl={detailImgUrl02En} setImgUrl={setDetailImgUrl02En}
            fileInputRef={_detailImg02EnFileInputRef}
        />
      </InputWrapper>
      <InputWrapper>
        {detailImgUrl02 ?(<EventImg src={detailImgUrl02}/>) :(<EventImg src={blankImg}/>)}
      </InputWrapper>
      <InputWrapper>
        {(englishYn==='Y') &&(detailImgUrl02En ?(<EventImg src={detailImgUrl02En}/>) :(<EventImg src={blankImg}/>))}
      </InputWrapper>

      <SubTitle>외부 연동 버튼 설정</SubTitle>
      <InputWrapper colSpan={2} mt={-25}>
        <RadioButton
            name="externalLinkBtnYn" label=""
            text={['노출', '미노출']} type="activeYn"
            value={externalLinkBtnYn} onChange={(e) => setExternalLinkBtnYn(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        {koreanYn==='Y' && (
          <LabelTextInput
              name="externalLinkBtnName" label="한글 버튼 명" placeholder="랜딩 페이지에 노출되는 버튼명을 입력해주세요."
              value={externalLinkBtnName} maxLen={15}
              onChange={(e) => setExternalLinkBtnName(e.target.value)}
              onReset={() => setExternalLinkBtnName('')}
          />
        )}
      </InputWrapper>
      <InputWrapper>
        {englishYn==='Y' && (
          <LabelTextInput
              name="externalLinkBtnNameEn" label="영문 버튼 명" placeholder="랜딩 페이지에 노출되는 버튼명을 입력해주세요."
              value={externalLinkBtnNameEn} maxLen={15}
              onChange={(e) => setExternalLinkBtnNameEn(e.target.value)}
              onReset={() => setExternalLinkBtnNameEn('')}
          />
        )}
      </InputWrapper>

      <InputWrapper colSpan={2}>
        <LabelTextInput
            name="externalLinkUrl" label="URL 입력 창" placeholder="버튼을 통해 이동시킬 URL을 입력해주세요."
            value={externalLinkUrl}
            onChange={(e) => handleResultExternalLinkUrl(e.target.value)}
            onReset={() => handleResultExternalLinkUrl('')}
        />
        {checkResultExternalLinkUrl && <ResultText>{checkResultExternalLinkUrl}</ResultText>}
      </InputWrapper>

      <InputWrapper colSpan={2}>
        <RadioButton
            name="shareBtnYn" label="상단 공유 버튼 노출 여부 설정" Required
            text={['노출', '미노출']} type="activeYn"
            value={shareBtnYn} onChange={(e) => setShareBtnYn(e.target.value)}
        />
      </InputWrapper>

      {/* 유의사항 */}
      <InputWrapper colSpan={2} className='area1'>
        <LabelPlusButtonInput
            name="notice" label="유의사항 한국어(필수)" value={notice}
            onClick={handleNoticeSetData} onEdit={onEditNotice} onRemove={onRemoveNotice}
        />
      </InputWrapper>
      {englishYn==='Y' &&
        <InputWrapper colSpan={2} className='area1'>
          <LabelPlusButtonInput
              name="noticeEn" label="유의사항 영어(필수)" value={noticeEn}
              onClick={handleNoticeSetDataEn} onEdit={onEditNoticeEn} onRemove={onRemoveNoticeEn}
          />
        </InputWrapper>
      }
    </MainContainer>

  </>;
};
export default AdvertisementLandingDetail;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '20px'};
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({row}) => row && `span ${row}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

  &.area1 {
    background-color: #ccc;
    padding: 15px;
    border-radius: 6px;
  }
  &.hide{display:none;}
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 0px;
  border-radius: 10px;
`;

const ResultText = styled.span`
  position: absolute;
  // margin-top: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;