import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

// 컴포넌트
import NormalButton from '../../components/common/Button/NormalButton';
import ProfileImg from '../../components/common/image/ProfileImg';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TagTextInput from '../../components/common/Input/TagTextInput';
import DateInput from '../../components/common/Input/DateInput';
import SelectModule from '../../components/common/Select/SelectModule';
import RadioButton from '../../components/common/Button/RadioButton';
import TagMenuSelect from '../../components/common/Select/TagMenuSelect';
import FileInput from '../../components/common/Input/FileInput';
import AudioPlayer from '../../components/common/audio/AudioPlayer';

// hooks
import { useAppSelector } from '../../redux/store/hooks';

// 액션
import { getBeatMakerList } from '../../redux/actions/beat/beat';
import { getGenreList } from '../../redux/actions/genre/genre';
import { getMoodList } from '../../redux/actions/mood/mood';
import { setLoading, resetLoading } from '../../redux/slices/user';

// config
import { BEAT_SAVE } from '../../constant/apiUrl';

// constant
import { beatKeyRegExp } from '../../constant/common';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialBeatData: data = {
  bmName: '',
  bmPid: '',
  bpm: '',
  startDt: '',
  genreNames: [],
  moodNames: [],
  id: '',
  key: '',
  length: '',
  price: '',
  privateYn: 'Y',
  title: '',
  titleEn: '',
  titleEs: '',
  bmId: '',
  genreIds: [],
  moodIds: [],
  beatFile: null,
  beatCoverImgFile: null,
  searchKeywords: null,
};

const AddBeat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const detailBeat = useAppSelector((state) => state.beat.detailBeat);
  const genreList = useAppSelector((state) => state.genre.genreList);
  const moodList = useAppSelector((state) => state.mood.masterMoodList);
  const beatMakerList = useAppSelector((state) => state.beat.beatMakerList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [beatData, setBeatData] = useState(initialBeatData);
  const [keyWord, setKeyWord] = useState<any>(null);
  const [addWord, setAddWord] = useState('');
  const [coverImg, setCoverImg] = useState(
    detailBeat ? detailBeat.coverImgUrl : '',
  );
  const [audioUrl, setAudioUrl] = useState(
    detailBeat ? detailBeat.contentsUrl : '',
  );
  const {
    bmName,
    bpm,
    startDt,
    genreNames,
    moodNames,
    id,
    key,
    length,
    price,
    privateYn,
    title,
    titleEn,
    titleEs,
    bmId,
    genreIds,
    moodIds,
    beatFile,
    beatCoverImgFile,
    searchKeywords,
    recordableYn,
  } = beatData;

  const initialStartDate = startDt?.replace(' ', 'T').slice(0, 16);

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setBeatData({ ...beatData, [name]: value });
    },
    [beatData],
  );

  const handleBeatSetData = useCallback(
    (e: string) => {
      setBeatData({ ...beatData, beatFile: e });
    },
    [beatData],
  );

  const handleCoverSetData = useCallback(
    (e: string) => {
      setBeatData({ ...beatData, beatCoverImgFile: e });
    },
    [beatData],
  );
  const handleSelectSetData = useCallback(
    (e) => {
      setBeatData({ ...beatData, bmName: e.name, bmId: e.id });
    },
    [beatData],
  );

  // 무드나 장르 태그 추가하기
  const handleTagSetData = useCallback(
    (e: any, name) => {
      // 장르 추가일 때
      if (name === 'genre') {
        // 장르가 겹칠 때
        const duplicateCheck: number = genreNames?.indexOf(e.genre);
        if (duplicateCheck !== -1) {
          const removeOption = genreNames?.filter(
            (el: string) => el !== e.genre,
          );
          const removeIdOption = genreIds?.filter((el: number) => el !== e.id);
          setBeatData({
            ...beatData,
            genreNames: removeOption,
            genreIds: removeIdOption,
          });
          return;
        }

        setBeatData({
          ...beatData,
          genreNames: genreNames.concat(e.genre),
          genreIds: genreIds.concat(e.id),
        });
      }

      // 무드 추가일때
      if (name === 'mood') {
        // 초기값이 null일 때
        if (moodNames === null) {
          setBeatData({
            ...beatData,
            moodNames: [e.moodName],
            moodIds: [e.id],
          });
          return;
        }

        // 무드가 겹칠 때
        const duplicateCheck = moodNames?.indexOf(e.moodName);
        if (duplicateCheck !== -1) {
          const removeOption = moodNames?.filter(
            (el: string) => el !== e.moodName,
          );
          const removeIdOption = moodIds?.filter((el: number) => el !== e.id);

          setBeatData({
            ...beatData,
            moodNames: removeOption,
            moodIds: removeIdOption,
          });
          return;
        }

        setBeatData({
          ...beatData,
          moodNames: moodNames.concat(e.moodName),
          moodIds: moodIds.concat(e.id),
        });
      }
    },
    [genreNames, beatData, genreIds, moodNames, moodIds],
  );

  const handleLengthData = useCallback(
    (e: any) => {
      setBeatData({ ...beatData, length: e });
    },
    [beatData],
  );

  const handleResetData = useCallback(
    (e) => {
      setBeatData({ ...beatData, [e]: initialBeatData[e] });
    },
    [beatData],
  );

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/beat/list');
  };

  // 검색 키워드 변경 확인
  const checkKeyWordChange = () => {
    return (
      JSON.stringify(
        searchKeywords !== null ? searchKeywords?.split(',') : searchKeywords,
      ) !== JSON.stringify(keyWord)
    );
  };

  // 비트 변경 확인
  const checkGenreChange = () => {
    return JSON.stringify(detailBeat) !== JSON.stringify(beatData);
  };

  // 비트 key 확인
  const checkBeatKey = () => {
    return beatKeyRegExp.test(key);
  };
  // 비트 등록하기
  const HandleAddBeat = async () => {
    const formData = new FormData();

    formData.append('adminName', adminName);
    formData.append('beatCoverImgFile', beatCoverImgFile);
    formData.append('beatFile', beatFile);
    formData.append('bmId', bmId);
    formData.append('bpm', bpm);
    formData.append('genreIds', genreIds);
    formData.append('moodIds', moodIds);
    formData.append('key', key);
    formData.append('searchKeywords', keyWord || []);
    formData.append('length', length);
    formData.append('price', price);
    formData.append('privateYn', privateYn);
    formData.append(
      'startDate',
      `${startDt.replace('T', ' ').slice(0, 16)}:00`,
    );
    if (title.length > 0) {
      formData.append('title', title);
    } else {
      formData.append('title', titleEn);
    }
    formData.append('titleEn', titleEn);
    formData.append('titleEs', titleEs);
    formData.append('recordableYn', recordableYn);

    if (
      !titleEn ||
      !startDt ||
      !adminName ||
      !beatFile ||
      !genreIds ||
      !key ||
      !bpm
    ) {
      alert('필요한 양식을 전부 채워주세요.');
      return;
    }

    // beat key 유효성 검사
    if (!checkBeatKey()) {
      alert('Beat key 양식에 맞춰 작성해주세요.');
      return;
    }

    try {
      dispatch(setLoading());
      await axios.post(BEAT_SAVE, formData, {});
      await alert('비트가 등록되었습니다.');
      await navigate('/beat/list');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 비트 상세 수정하기
  const ChangeDetail = async () => {
    const formData = new FormData();
    formData.append('adminName', adminName);
    formData.append('bmId', bmId);
    formData.append('beatId', id);
    formData.append('bpm', bpm);
    formData.append('key', key);
    formData.append('length', length);
    formData.append('price', price);
    formData.append('privateYn', privateYn);
    formData.append(
      'startDate',
      `${startDt.replace('T', ' ').slice(0, 16)}:00`,
    );
    formData.append('title', title);
    formData.append('titleEn', titleEn);
    formData.append('titleEs', titleEs);
    formData.append('coverImg', beatCoverImgFile);
    formData.append('beatFile', beatFile);
    formData.append('masterGenreIds', genreIds);
    formData.append('masterMoodIds', moodIds || []);
    // 검색 키워드 변경 확인
    if (checkKeyWordChange()) {
      formData.append('searchKeywords', keyWord);
    } else {
      formData.delete('searchKeywords');
    }
    formData.append('recordableYn', recordableYn);
    try {
      dispatch(setLoading());
      await axios.post(`/beat/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await alert('비트가 수정되었습니다.');
      await navigate(-1);
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    const params = { limit: 1000, offset: 0 };
    dispatch(getGenreList(params));
    dispatch(getMoodList(params));
    dispatch(getBeatMakerList(params));
  }, [dispatch]);

  useEffect(() => {
    setBeatData(detailBeat);
    if (detailBeat.searchKeywords) {
      const keywords = detailBeat.searchKeywords.split(',');
      setKeyWord(keywords);
    }
  }, [detailBeat]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            {coverImg ? <ProfileImg url={coverImg} /> : <ProfileImg />}
            <ProfileTitleWrapper>
              <BeatTitle>{titleEn}</BeatTitle>
              <BeatMaker>{bmName}</BeatMaker>
              {audioUrl && (
                <AudioPlayer
                  src={audioUrl}
                  handleLengthData={handleLengthData}
                />
              )}
            </ProfileTitleWrapper>
          </ProfileWrapper>
          <LineDiv />
          <ButtonWrapper>
            {detailBeat.contentsUrl ? (
              <>
                {(checkGenreChange() || checkKeyWordChange()) && (
                  <NormalButton text="수정하기" onSubmit={ChangeDetail} />
                )}
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
              </>
            ) : (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton text="저장하기" onSubmit={HandleAddBeat} />
              </>
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <FileInput
            type="coverImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버"
            value={beatCoverImgFile}
            setFile={handleCoverSetData}
            setImgUrl={setCoverImg}
          />
        </InputWrapper>
        <InputWrapper>
          <FileInput
            type="audioFile"
            placeholder={
              audioUrl ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="비트파일"
            value={beatFile}
            setFile={handleBeatSetData}
            setAudioUrl={setAudioUrl}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEn"
            label="영어"
            value={titleEn}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="title"
            label="한국어"
            value={title}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEs"
            label="스페인어"
            value={titleEs}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <TagTextInput
            placeholder="키워드를 입력해주세요."
            label="검색 키워드"
            keyWord={keyWord || []}
            setKeyWord={setKeyWord}
            addWord={addWord}
            setAddWord={setAddWord}
          />
        </InputWrapper>
        <InputWrapper>
          <SelectModule
            name="bmName"
            placeholder="비트메이커를 선택해주세요."
            value={bmName}
            label="비트메이커"
            onChange={handleSelectSetData}
            options={beatMakerList}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TagMenuSelect
            name="genre"
            value={genreNames}
            placeholder="장르를 선택해주세요."
            tagIds={genreIds}
            label="장르"
            onClick={handleTagSetData}
            options={genreList}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TagMenuSelect
            name="mood"
            value={moodNames}
            placeholder="무드를 선택해주세요."
            tagIds={moodIds}
            label="무드"
            onClick={handleTagSetData}
            options={moodList}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="bpm"
            label="BPM"
            placeholder="BPM을 입력해주세요."
            value={bpm}
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="key"
            label="Beat Key"
            placeholder="Beat Key을 입력해주세요."
            value={key}
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            type="priceInput"
            name="price"
            label="Beat 가격"
            placeholder="가격을 입력해주세요."
            value={price}
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={privateYn}
            name="privateYn"
            label="공개 여부"
            onChange={handleInputSetData}
            type="privateYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="공개 시간"
            name="startDt"
            initialStartDate={initialStartDate}
            onChange={handleInputSetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
              name="recordableYn" type="recordableYn" label="이 비트 사용하기 버튼 표시 여부"
              value={recordableYn} onChange={handleInputSetData} text={['표시', '비표시']}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AddBeat;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const BeatTitle = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  line-height: 35px;
`;

const BeatMaker = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 30px;
  letter-spacing: -0.3px;
`;

const LineDiv = styled.div`
  position: absolute;
  width: 100%;
  top: 205px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 18px 0;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
    margin-bottom: 60px;
  }

  &:nth-child(3) {
    grid-row: 2;
  }
  &:nth-child(n + 4):nth-child(-n + 5) {
    grid-row: 3;
  }

  &:nth-child(6) {
    grid-row: 4;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(7) {
    grid-row: 5;
  }
  &:nth-child(n + 8):nth-child(-n + 9) {
    grid-row: 6;
    margin-bottom: 40px;
  }
  &:nth-child(n + 10):nth-child(-n + 11) {
    grid-row: 7;
  }
  &:nth-child(12) {
    grid-row: 8;
  }
  &:nth-child(13) {
    grid-row: 9;
    grid-column: span 2;
  }
  &:nth-child(14) {
    grid-row: 10;
  }
  &:nth-child(15) {
    grid-row: 11;
    grid-column: span 2;
  }
`;
