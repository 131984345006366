import React from 'react';
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';
import handleDate from '../../../hooks/handleDate';
import refineContentsType from '../../../hooks/changeReportType';

interface props {
  data: dataTypes;
  onClick: (data: dataTypes) => void;
  saveStorage: () => void;
}

interface dataTypes {
  id: number;
  contentsId: number;
  contentsType: string;
  description: string;
  masterReportContentsType: string;
  masterReportDescription: string;
  originMemberPid: string;
  tarMemberPid: string;
  createdDt: string;
  adminName: string;
}

const ExNeedConfirmListRowComponent = ({
  data,
  onClick,
  saveStorage,
}: props) => {
  const {
    id,
    contentsType,
    masterReportDescription,
    tarMemberPid,
    originMemberPid,
    createdDt,
  } = data;

  return (
    <tr key={id}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={refineContentsType(contentsType)} />
      </td>
      <td>
        <ListItem text={masterReportDescription} />
      </td>
      <td>
        <ListItem text={tarMemberPid} />
      </td>
      <td>
        <ListItem text={originMemberPid} />
      </td>
      <td>
        <ListItem text={`${handleDate(createdDt)}`} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            onClick(data);
            saveStorage();
          }}
          text="내용"
        />
      </td>
    </tr>
  );
};

export default ExNeedConfirmListRowComponent;
