import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/store/hooks';
import { MenuList } from '../../constant/menuList';
import Routes from '../../App/Routes';
import { setMenu, setSideToggle, setSubMenu } from '../../redux/slices/main';

interface SubMenuType {
	id: number;
	name: string;
	address: string;
}

const SideBar = () => {
	const dispatch = useDispatch();

	// 로그인 여부 및 사용자 권한 확인
	const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
	const storeMenu = useAppSelector((state) => state.main.menu);
	const storeSubMenu = useAppSelector((state) => state.main.subMenu);
	const sideToggle = useAppSelector((state) => state.main.sideToggle);

	// Routes List
	const routesList = Routes()[0]?.children;

	// Routes List 중에서 subMenu에 해당하는 부분만 필터
	const pathList = Array.from(new Set(
		routesList
			.filter(url => url.path !== '/' && url.path !== '*')
			.map(r => r.path.split('/').slice(0, 3).join('/'))
	));

	// addressList => main메뉴 필터
	const routeList = (() => {
		const addressList = Array.from(new Set(pathList.map(p => p.split('/').slice(0, 2).join('/'))));
		return addressList.map((address) => MenuList.find(menu => menu.address === address));
	})();

	// main메뉴 안에 subMenu filter한 pathList 넣기
	const setAuthRoutes = (routes: any, paths: any) => {
		return routes.map((route: any) => {
			return {
				...route,
				subMenu: route.subMenu?.filter((sub: any) => paths.includes(sub.address) === true),
			};
		});
	};

	const handleToggle = (name: string | number) => {
		const result = setAuthRoutes(routeList, pathList)?.filter((el: any) => el.name === name)[0];

		if (storeMenu?.name === name) {
			dispatch(setSideToggle(false));
			dispatch(setMenu({}));
			return;
		}

		dispatch(setSideToggle(true));
		dispatch(setMenu(result));
	};

	const handleSubMenu = (subMenu: any, menu: any) => {
		dispatch(setSubMenu(subMenu));
		dispatch(setMenu(menu));
		localStorage.clear();
	};

	return (
		<Container>
			<SideBarWrapper>
				{isLoggedIn && <ListContainer>
					{setAuthRoutes(routeList, pathList)?.map((item: any) =>
						<Fragment key={item.id}>
							<Li key={item.address} onClick={() => handleToggle(item.name)}>
								<button type="button">{item.name}</button>
							</Li>

							{(sideToggle && storeMenu?.name === item.name) &&
								<SubListContainer>
									{storeMenu?.subMenu?.map((subItem: SubMenuType) =>
										<Fragment key={subItem.id}>
											<Link to={subItem.address}>
												<SubLi
													onClick={() => handleSubMenu(subItem, item)}
													className={storeSubMenu?.name === subItem.name ? 'selected' : ''}
												>
													{subItem.name}
												</SubLi>
											</Link>
										</Fragment>
									)}
								</SubListContainer>
							}
						</Fragment>
					)}
				</ListContainer>}
			</SideBarWrapper>
		</Container>
	);
};

export default SideBar;

const Container = styled.div`
  position: fixed;
  overflow-y: auto;
  margin-top: 30px;
	max-height: calc(100vh - 100px);
`;

const SideBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const ListContainer = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  width: 100%;
  height: 47px;
  padding: 12px 100px 12px 0;

  button {
    height: 100%;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const SubListContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-left: 22px;

  a {
    text-decoration: none;
  }
`;

const SubLi = styled.li`
  width: 100%;
  height: 39px;
  padding: 10px 20px 10px 0;
  flex-grow: 0;
  color: ${({ theme }) => theme.color.mainBlack};
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;

  &.selected {
    font-weight: 600;
  }
`;
