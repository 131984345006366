import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

// hooks
import { useAppSelector } from '../../../../redux/store/hooks';
import { getBeatMakerList } from '../../../../redux/actions/beat/beat';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import RadioButton from '../../../../components/common/Button/RadioButton';
import SelectModule from '../../../../components/common/Select/SelectModule';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import DateInput from '../../../../components/common/Input/DateInput';
import ProfileImg from '../../../../components/common/image/ProfileImg';
import RecommendBeatMakerRowComponents from './RecommendBeatMakerRowComponents';
import Pagination from '../../../../components/common/Pagination';

// config
import { EXPLORE, RECOMMEND_BEATMAKER } from '../../../../constant/apiUrl';

// constant
import { recommendBeatMakerColList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';

// 타입
import { RowComponentDataProps } from '../../../../definitions/beat';

// redux
import { resetLoading, setLoading } from '../../../../redux/slices/user';

const RecommendBeatMakerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storageNumber = localStorage.getItem('pageNumber');
  const detailData: any = location.state;
  const beatMakerListData = useAppSelector((state) => state.beat.beatMakerList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [data, setData] = useState<any>(detailData);
  const [editList, setEditList] = useState<any>([]);
  const [activeBmList, setActiveBmList] = useState<Array<any>>([]);
  const {
    beatMakerId,
    beatMakerPid,
    id,
    recommendStartDt,
    recommendEndDt,
    beatMakerCoverImgUrl,
    beatMakerProfileImgUrl,
    activeYn,
  } = data;
  const initialStartDate = recommendStartDt?.replace(' ', 'T').slice(0, 16);
  const initialEndDate = recommendEndDt?.replace(' ', 'T').slice(0, 16);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storageNumber ? JSON.parse(storageNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const goBack = () => {
    navigate('/explore/allTab/recommendBeatMaker');
  };
  const handleSetData = useCallback(
    (e: any) => {
      setData({ ...data, beatMakerPid: e.pid, beatMakerId: e.id });
    },
    [data],
  );

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  // 추천비트메이커 생성 & 수정
  const requestRecommendBeatMaker = async (type: string) => {
    const recommendStartDate = `${recommendStartDt
      .replace('T', ' ')
      .slice(0, 16)}:00`;
    const recommendEndDate = `${recommendEndDt
      .replace('T', ' ')
      .slice(0, 16)}:59`;
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(recommendEndDate);

    const params: any = {
      adminName,
      beatMakerId,
      recommendStartDate,
      recommendEndDate,
      activeYn,
    };

    // 공개된 비트메이커가 중복인지 체크
    const isActiveBm = activeBmList?.some(function (e) {
      return e.beatMakerId === beatMakerId;
    });

    if (!beatMakerId || !recommendStartDt || !recommendEndDt) {
      alert('양식을 모두 채워주세요.');
      return;
    }

    if (recommendStartDt > recommendEndDt) {
      alert('시작종료일시가 종료일시보다 느릴 수 없습니다.');
      return;
    }

    if (compareEndDate) {
      alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
      return;
    }

    if (
      type === 'add' &&
      detailData?.masterGenreId === undefined &&
      isActiveBm
    ) {
      alert('이미 공개된 추천 비트메이커 입니다.');
      return;
    }

    try {
      dispatch(setLoading());
      // 장르탭 생성
      if (type === 'add' && detailData?.masterGenreId) {
        await axios.post(
          `${EXPLORE}/recommend/${detailData?.masterGenreId}/beat-maker`,
          {},
          { params },
        );
        navigate('/explore/genreTab/recommendBeatMaker', {
          state: { detailData: data, tabType: 'GENRE' },
        });
        alert('저장 되었습니다.');
      }
      // 전체탭 생성
      if (type === 'add' && detailData?.masterGenreId === undefined) {
        await axios.post(RECOMMEND_BEATMAKER, {}, { params });
        navigate('/explore/allTab/recommendBeatMaker');
        alert('저장 되었습니다.');
      }
      // 장르탭 수정
      if (type === 'change' && detailData?.masterGenreId !== 0) {
        await axios.post(`${RECOMMEND_BEATMAKER}/${id}/update`, {}, { params });
        alert('수정되었습니다.');
        navigate('/explore/genreTab/recommendBeatMaker', {
          state: { detailData: data, tabType: 'GENRE' },
        });
      }
      // 전체탭 수정
      if (type === 'change' && detailData?.masterGenreId === 0) {
        await axios.post(`${RECOMMEND_BEATMAKER}/${id}/update`, {}, { params });
        alert('수정되었습니다.');
        navigate('/explore/allTab/recommendBeatMaker');
      }
    } catch (err: any) {
      console.log('err', err.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 추천 비트메이커의 비트리스트 불러오기
  const getEditBeatList = async (masterGenreId: number | undefined) => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
      type: 'EXP_BM',
      word: beatMakerPid,
      masterGenreId,
    };
    if (!masterGenreId || masterGenreId === 0) {
      params.masterGenreId = undefined;
      const resp = await axios.get(`/beat/all/list`, { params });
      setEditList(resp.data.beatList);
      setTotalCount(resp.data.count || 0);
    } else {
      const resp = await axios.get(`/beat/${masterGenreId}/list`, { params });
      setEditList(resp.data.beatList);
      setTotalCount(resp.data.count || 0);
    }
  };

  // 공개된 추천 비트메이커 불러오기
  const getRecommendBeatMakerList = useCallback(async () => {
    const params = { limit: 100, offset: 0, type: '' };
    try {
      const resp = await axios.get(RECOMMEND_BEATMAKER, { params });
      setActiveBmList(resp.data.masterRecommendBeatMakerList);
    } catch (err) {
      setActiveBmList([]);
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (beatMakerPid) {
      getEditBeatList(detailData.masterGenreId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beatMakerPid, detailData.masterGenreId, pageNumber]);

  useEffect(() => {
    const params = {
      limit: 1000,
      offset: 0,
    };
    dispatch(getBeatMakerList(params));
  }, [dispatch]);

  useEffect(() => {
    getRecommendBeatMakerList();
  }, [getRecommendBeatMakerList]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            <ProfileTitleWrapper>
              <Title>{id}</Title>
            </ProfileTitleWrapper>
          </ProfileWrapper>
          <ButtonWrapper>
            {detailData?.id ? (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="수정하기"
                  onSubmit={() => {
                    requestRecommendBeatMaker('change');
                  }}
                />
              </>
            ) : (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="저장하기"
                  onSubmit={() => {
                    requestRecommendBeatMaker('add');
                  }}
                />
              </>
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      {(!detailData?.masterGenreId || id) && (
        <ImageWrapper>
          <ProfileImg
            url={beatMakerProfileImgUrl}
            label="프로필(둘러보기 노출)"
          />
          <ProfileImg
            url={beatMakerCoverImgUrl}
            label="커버(상세 페이지 노출)"
          />
        </ImageWrapper>
      )}

      <MainContainer>
        <InputWrapper>
          <SelectModule
            name="bmName"
            placeholder="비트메이커를 선택해주세요."
            value={beatMakerPid}
            label="비트메이커"
            onChange={handleSetData}
            options={beatMakerListData}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          {/*<DateRangeInput*/}
          {/*  title="노출 기간"*/}
          {/*  placeHolder={*/}
          {/*    data.recommendStartDt*/}
          {/*      ? `${data.recommendStartDt} ~ ${data.recommendEndDt}`*/}
          {/*      : '날짜를 선택해주세요. YYYY-MM-DD ~ YYYY-MM-DD'*/}
          {/*  }*/}
          {/*  setMinStartDate={setMinStartDate}*/}
          {/*  setMaxStartDate={setMaxStartDate}*/}
          {/*/>*/}
          <DateWrapper>
            <DateInput
              initialStartDate={initialStartDate}
              onChange={handleInputSetData}
              name="recommendStartDt"
              label="노출 시작일"
              Required
            />
            <DateInput
              initialStartDate={initialEndDate}
              onChange={handleInputSetData}
              name="recommendEndDt"
              label="노출 종료일"
              Required
            />
          </DateWrapper>
        </InputWrapper>
      </MainContainer>
      {detailData.type !== 'add' && editList.length !== 0 && (
        <>
          <LineDiv />
          <PaginationWrapper>
            <PaginationTitle>비트</PaginationTitle>
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          </PaginationWrapper>
          <Table
            colList={recommendBeatMakerColList}
            thead={
              <>
                <ListHead title="Cover" />
                <ListHead title="Title" />
                <ListHead title="Pid" />
                <ListHead title="장르" />
                <ListHead title="BPM" />
                <ListHead title="BeatKey" />
                <ListHead title="공개 여부" />
                <ListHead title="공개 날짜" />
              </>
            }
          >
            {editList.map((el: RowComponentDataProps) => (
              <RecommendBeatMakerRowComponents key={el.id} data={el} />
            ))}
          </Table>
        </>
      )}
    </>
  );
};

export default RecommendBeatMakerDetail;

const TopContainer = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 60px;
`;

const ImageWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  div {
    margin-right: 30px;
  }
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  height: 45px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  //margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 46px 0;
`;

const PaginationTitle = styled.p`
  width: 26px;
  height: 18px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.subDarkGray};
`;
