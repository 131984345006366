import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {BAUND_PROJECT_QNA} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextAreaInput
  from "../../components/common/Input/LabelTextAreaInput";
import styled from "styled-components";
import {isBlank} from "../../utils/commonFn";
import LabelText from "../../components/common/LabelText";

// 바운드 프로젝트 QnA 상세 화면(추가, 수정)
const BaundProjectQnaDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectQnAId = detailData?.baundProjectQnAId || null;
  const baundProjectIdForSearch = detailData?.baundProjectIdForSearch || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;
  const answerYnForSearch = detailData?.answerYnForSearch || null;

  const [question, setQuestion] = useState('');
  const [memberPid, setMemberPid] = useState('');
  const [questionDt, setQuestionDt] = useState('');
  const [baundProjectTitle, setBaundProjectTitle] = useState('');
  const [answer, setAnswer] = useState('');
  const [answerYn, setAnswerYn] = useState('N');
  const [answerCreateDt, setAnswerCreateDt] = useState('');
  const [createAdminAccountName, setCreateAdminAccountName] = useState('');
  const [answerUpdateDt, setAnswerUpdateDt] = useState('');
  const [updateAdminAccountName, setUpdateAdminAccountName] = useState('');

  // 상세 조회 /////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${BAUND_PROJECT_QNA}/${baundProjectQnAId}`,
    )
    .then(res => {
      const detail = res.data.baundProjectQna;
      setQuestion(detail.question);
      setMemberPid(detail.memberPid);
      setQuestionDt(detail.questionDt);
      setBaundProjectTitle(detail.baundProjectTitle + ((detail.baundProjectDeleteYn === "Y") ? '(삭제된 프로젝트 입니다.)':''));
      setAnswer(detail.answer ?detail.answer:'');
      setAnswerYn(detail.answerYn);
      setAnswerCreateDt(detail.answerCreateDt);
      setCreateAdminAccountName(detail.answerCreateAdminAccountName);
      setAnswerUpdateDt(detail.answerUpdateDt);
      setUpdateAdminAccountName(detail.answerUpdateAdminAccountName);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  useEffect(() => {
    if (baundProjectQnAId) {
      getDetail().then();
    }

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ baundProjectIdForSearch, pageNumberForSearch, answerYnForSearch })
    );
  }, [baundProjectQnAId]);

  // 저장 //////////////////////////////////////////////////////////////////////
  const saveDetail = async () => {

    if (!confirm(
        (answerYn === "N")
          ? `답변을 저장하시겠습니까? 답변 저장 시, 질문 작성자에게 자동으로 push가 전송됩니다. `
          : `답변 내용을 수정하시겠습니까? 수정 시, 변경된 내용으로 답변이 노출되고, 사용자에게는 알림이 가지 않습니다. `
    )) {
      return;
    }
    if (isBlank(answer)) {
      alert('답변은 최소 1자 이상 작성해야합니다.');
      return;
    }

    const formData = new FormData();
    formData.append('answer', answer);
    formData.append('answerYn', answerYn);

    dispatch(setLoading());
    try {
      await axios.put(`${BAUND_PROJECT_QNA}/${baundProjectQnAId}`, formData);
      alert('저장 되었습니다.');
      navigate('/baundProject/baundProjectQna');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  return <>
    <TitleText>바운드 프로젝트 QnA 답변</TitleText>
    <TopContainer>
      <ButtonWrapper jc={'flex-end'}>
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>

    {/*질문 정보*/}
    <MainContainer>
      <InputWrapper colSpan={2} className={'veryLightGreyBackgroundColor'}>
        <LabelTextAreaInput name="question" label="질문" value={question} readOnly/>
      </InputWrapper>
      <InputWrapper><LabelText label="질문 작성자" value={memberPid}/></InputWrapper>
      <InputWrapper><LabelText label="작성 일시" value={questionDt}/></InputWrapper>
      <InputWrapper colSpan={2}><LabelText label="프로젝트 이름" value={baundProjectTitle}/></InputWrapper>
    </MainContainer>

    {/*답변 정보*/}
    <MainContainer>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="answer" label="답변" placeholder="답변을 입력해주세요."
            value={answer}
            setValue={(e)=> setAnswer(e.target.value)}
            onReset={()=> setAnswer('')}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}><LabelText label="답변 상태" value={(answerYn === 'Y') ? '답변 완료' : '미답변'}/></InputWrapper>
      <InputWrapper><LabelText label="최초 답변자" value={createAdminAccountName}/></InputWrapper>
      <InputWrapper><LabelText label="최종 답변 수정자" value={updateAdminAccountName}/></InputWrapper>
      <InputWrapper><LabelText label="생성 일자" value={answerCreateDt}/></InputWrapper>
      <InputWrapper><LabelText label="수정 일자" value={answerUpdateDt}/></InputWrapper>
    </MainContainer>

    </>;

};
export default BaundProjectQnaDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc && `${jc}`};;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({row}) => row && `span ${row}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  
  &.veryLightGreyBackgroundColor textarea {
    background-color: #cccccc;
  }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;
