import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getMoodList } from '../actions/mood/mood';

interface initialType {
  count: string;
  masterMoodList: Array<any>;
  detailMood: any;
  checkAddMood: boolean;
  getMoodListError: string;
}

const initialState: initialType = {
  count: '',
  masterMoodList: [],
  detailMood: {
    id: '',
    moodName: '',
    searchKeywords: '',
  },
  checkAddMood: false,
  getMoodListError: '',
};

const moodSlice = createSlice({
  name: 'mood',
  initialState,
  reducers: {
    getDetailMood: (state, action) => {
      state.detailMood = action.payload;
    },
    resetDetailMood: (state) => {
      state.detailMood = initialState.detailMood;
    },
  },
  extraReducers: (builder) => {
    builder
      // 무드 리스트가져오기
      .addCase(getMoodList.pending, (state, action) => {})
      .addCase(getMoodList.fulfilled, (state, action) => {
        state.checkAddMood = false;
        state.masterMoodList = action.payload.masterMoodList;
        state.count = action.payload.count;
      })
      .addCase(getMoodList.rejected, (state, action: PayloadAction<any>) => {
        state.getMoodListError = action.payload;
      });
  },
});

export default moodSlice;
export const { getDetailMood, resetDetailMood } = moodSlice.actions;
