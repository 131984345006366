import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import useOutSideClick from '../../../hooks/useOutSideClick';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';

interface optionProps {
  text: string;
  value: string;
}

/**
 * SearchMenuSelect 프로퍼티
 * @property {Array<optionProps>} options 옵션 리스트
 * @property {optionProps} state 검색 옵션 지정
 * @property {React.Dispatch<React.SetStateAction<string>>} setState setter (검색 옵션이 수정됐을시 사용)
 */
type MenuButtonProps = {
  options: Array<optionProps>;
  onClick: (e: React.MouseEvent<HTMLLIElement>, setState: React.Dispatch<React.SetStateAction<string>>,) => void;
  setState: React.Dispatch<React.SetStateAction<string>>;
  state?: optionProps;
};

const SearchMenuSelect = (props: MenuButtonProps) => {
  const { options, onClick, setState, state } = props;
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option: optionProps) => {
    setSelectedOption(option.text);
    setIsOpen(false);
  };

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    // 기본 선택 항목 세팅
    if (state) {
      onOptionClicked(state);
      setState(state.value);
    }
    // 선택된 항목이 없을 때 전체로 바꿔주기
    else {
      setSelectedOption('');
    }
  }, [state]);

  return (
    <Container>
      {options.length !== 0 ? (
        <MenuList
          className="menuList"
          onClick={() => {
            toggling();
          }}
          ref={ref}
        >
          <OptionMenu className="option-menu">
            {selectedOption.length === 0 ? (
              <>{options[0].text}</>
            ) : (
              <TitleOption>{selectedOption}</TitleOption>
            )}
          </OptionMenu>
          {isOpen && (
            <DropDownList>
              {options.map((option) => (
                <ListItem
                  title={option.value}
                  onClick={(e) => {
                    onOptionClicked(option);
                    onClick(e, setState);
                  }}
                  key={Math.random()}
                >
                  {selectedOption === option.text ? (
                    <TitleOption>{option.text}</TitleOption>
                  ) : (
                    <>{option.text}</>
                  )}
                </ListItem>
              ))}
            </DropDownList>
          )}
        </MenuList>
      ) : (
        <OptionMenu>{options[0].text}</OptionMenu>
      )}
    </Container>
  );
};

export default SearchMenuSelect;

const Container = styled.div`
  margin-right: 10px;
`;

const MenuList = styled.span`
  position: relative;
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 14px 19px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ theme }) => theme.color.subBrightGray};
  z-index: 10;

  &.option-menu {
    padding-right: 32px;
    background: url(${bottomArrow}) calc(100% - 6px) center no-repeat
      ${({ theme }) => theme.color.subBrightGray};
    background-size: 22px;
  }
`;

const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 100%;
  left: 0;
  top: 12px;
  padding: 26px 0 4px;
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  z-index: 5;
`;

const ListItem = styled.li`
  margin: 12px auto;
  white-space: nowrap;
  font-size: 14px;
  cursor: pointer;
`;

const TitleOption = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;
