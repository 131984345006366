import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import useOutSideClick from '../../../hooks/useOutSideClick';
import toggleButton from '../../../assets/images/page/toggleButton.png';

const TitleSelect = ({ options, setState }: any) => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const toggling = () => setIsOpen(!isOpen);

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  const onOptionClicked = (option: string) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    setSelectedOption(options[0]?.masterGenre);
  }, [options]);

  return (
    <Container
      onClick={() => {
        toggling();
      }}
      ref={ref}
    >
      <SelectedMenu className="option-menu">
        <TitleOption>{selectedOption}</TitleOption>
        <Img alt="toggleButton" src={toggleButton} />
      </SelectedMenu>
      {isOpen && (
        <DropDownList>
          {options.map((option: any) => (
            <ListItem
              key={Math.random()}
              title={option.masterGenre}
              onClick={() => {
                onOptionClicked(option.masterGenre);
                setState(option);
              }}
            >
              {option.masterGenre}
            </ListItem>
          ))}
        </DropDownList>
      )}
    </Container>
  );
};

export default TitleSelect;

const Container = styled.div`
  position: relative;
  width: fit-content;
  margin-bottom: 32px;
`;

const SelectedMenu = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  height: 45px;
`;

const TitleOption = styled.p`
  margin-right: 16px;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;
const Img = styled.img`
  width: 30px;
  height: 30px;
  margin: auto 0;
  object-fit: contain;
`;

const DropDownList = styled.ul`
  width: 100%;
  min-width: fit-content;
  position: absolute;
  display: flex;
  flex-direction: column;
  left: 0;
  top: 52px;
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: 6px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  z-index: 5;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 56px;
  margin: auto 30px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  color: ${({ theme }) => theme.color.mainBlack};
  font-size: 16px;
  white-space: nowrap;
  cursor: pointer;
  &:last-child {
    border-bottom: 0px;
  }
`;
