import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// component
import LabelTextAreaInput from '../../components/common/Input/LabelTextAreaInput';
import NormalButton from '../../components/common/Button/NormalButton';
import FileInput from '../../components/common/Input/FileInput';
import ProfileImg from '../../components/common/image/ProfileImg';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import { SelectedEventRowComponent } from './components/EventModalRowComponent';

// config
import {INDIVIDUAL_PUSH} from '../../constant/apiUrl';

// hook
import { useAppSelector } from '../../redux/store/hooks';
import SelectModule from '../../components/common/Select/SelectModule';
import VideoInput from '../../components/common/Input/VideoInput';
import PushForArticle from './components/PushForArticle';
import PushForBeat from './components/PushForBeat';
import PushForEvent from './components/PushForEvent';
import PushForMember from './components/PushForMember';
import DetailAddBeatRowComponents from '../explore/components/category/DetailAddBeatRowComponents';
import SearchMemberRowComponent from '../member/components/SearchMemberRowComponent';
import PushForEventRowComponent from './components/PushForEventRowComponent';
import Table from '../../components/common/List/Table';
import {resetLoading, setLoading} from "../../redux/slices/user";
import RadioButton from "../../components/common/Button/RadioButton";
import EditButton from "../../components/common/Button/EditButton";
import PushForMagazine, {PushForMagazineRowComponent} from "./components/PushForMagazine";

const IndividualPush = () => {
  const dispatch = useDispatch();
  const adminAccount = useAppSelector((state) => state.user.user);
  const [pushImg, setPushImg] = useState('');
  const [imgFile, setImgFile] = useState(null);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [memberObjects, setMemberObjects] = useState([]);
  const [navigationType, setNavigationType] = useState({});
  const [navigationTypeObject, setNavigationTypeObject] = useState({});

  const [memberModal, setMemberModal] = useState(false);

  const [navigationModal, setNavigationModal] = useState(false);
  const [navigationModalType, setNavigationModalType] = useState('선택안함');

  const [directAssignYn, setDirectAssignYn] = useState('Y');
  const [directMemberIds, setDirectMemberIds] = useState('');

  // 개별 푸시 송신
  const sendIndividualPush = async () => {
    const imgMaxSize = 3 * 1024 * 100;

    if (!message) {
      alert('푸시 내용을 입력해 주세요.');
      return;
    }
    if (memberObjects.length < 1) {
      alert('푸시 송신 대상을 선택해 주세요.');
      return;
    }

    const formData = new FormData();
    // 고정 값
    formData.append('pushType', 'AD');
    formData.append('os', 'ALL');

    if (imgFile) {
      if (imgFile?.size > imgMaxSize) {
        alert('이미지는 300KB를 넘을 수 없습니다.');
        return;
      }
      formData.append('imageFile', imgFile);
    }
    else {
      formData.append('imageFile', '');
    }
    formData.append('title', title);
    formData.append('message', message);
    formData.append('senderName', adminAccount.name);
    formData.append('memberIds', memberObjects?.map((el) => el.id));

    if (navigationTypeObject && navigationType) {
      formData.append('type', navigationType);
      formData.append('typeId', navigationTypeObject?.id);
    }

    dispatch(setLoading());
    await axios.post(INDIVIDUAL_PUSH, formData)
    .then(() => alert('등록되었습니다.'))
    .catch (err => console.log(err))
    dispatch(resetLoading());
  };

  // 네비게이션 타입 변경시 타입값과 선택 컨텐츠 클리어
  useEffect(() => {
    setNavigationType(null);
    setNavigationTypeObject(null);
  }, [navigationModalType]);

  // 수신자 전체 삭제
  const clearMemberObject = () => {
    setMemberObjects([]);
  }

  // 선택 수신자 삭제
  const clearOneMemberObject = (data) => {
    setMemberObjects(memberObjects.filter((item) => item !== data));
  }

  const checkAndSetDirectMemberIds = (event) => {
    setDirectMemberIds(event.target.value.replace(/[^0-9,]/g, ""))
  }

  // 지정된 아이디의 사용자 검색
  const getMemberInfosToSend = useCallback(async () => {
    let memberIds = directMemberIds === ''
        ? []
        : directMemberIds?.split(',').filter((el) => el !== '');
    if (memberIds.length > 0) {
      const params = {
        ids: memberIds
      };
      await axios.get('/member-by-ids', {params})
      .then( res => {
        const result = res.data.memberList;
        setMemberObjects(result);
      })
      .catch(reason => {
        console.error(reason);
        alert('잠시후 다시 이용해주세요.');
      });
    }
  }, [directMemberIds]);

  return (
    <>
      {/* 버튼 */}
      <TopContainer>
        <TopWrapper>
          <ButtonWrapper>
            <NormalButton text="개별 푸시 송신" onSubmit={sendIndividualPush} className=""/>
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <TitleText>송신 내용</TitleText><LineDiv/>
      <MainContainer>

        {/* 푸시 이미지 */}
        <InputWrapper span={5} >
          <FileWrapper>
            <FileInput
              name="imgFile" label="푸시 이미지" type="push"
              placeholder={pushImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요. (용량 300KB 미만)'}
              value={imgFile}
              setFile={e=>setImgFile(e)}
              setImgUrl={setPushImg}
            />
          </FileWrapper>
        </InputWrapper>
        <InputWrapper span={5} >
          <PushImgWrapper>
            {pushImg ? <ProfileImg url={pushImg} className={'block'}/> : <ProfileImg />}
          </PushImgWrapper>
        </InputWrapper>

        {/* 푸시 제목 */}
        <InputWrapper span={10}>
          <LabelTextInput
            name="title" label="타이틀" placeholder="타이틀을 입력해주세요."
            value={title} onChange={(e)=>setTitle(e.target.value)}
          />
        </InputWrapper>

        {/* 푸시 내용  */}
        <InputWrapper span={10} >
          <LabelTextAreaInput
            name="message" label="푸시 내용 입력" placeholder="내용을 입력해주세요."
            value={message} setValue={(e)=>setMessage(e.target.value)}
            Required
          />
        </InputWrapper>

        {/* 네비게이션 선택 */}
        <InputWrapper span={10}>
          <SelectModule
            id="push" name="navigateType" label="네비게이션 타입" placeholder="네비게이션 타입을 정해주세요."
            value={navigationModalType}
            onChange={e => setNavigationModalType(e)}
            options={['선택안함', '영상', '비트', '멤버', '이벤트', '매거진']}
          />
          {navigationModalType !== '선택안함' && (
            <>
              <LineDiv />
              <VideoInput
                  text={`${navigationModalType} 추가하기`}
                  onClick={()=>setNavigationModal(true)}/>
            </>
          )}
        </InputWrapper>

        {/* 선택된 네비게이션 컨텐츠 */}
        {navigationTypeObject && (
          <InputWrapper span={10}>
            <TableContainer>
              <Table className="nonListTable">
                {navigationType === 'AR' && (
                  <SelectedEventRowComponent data={navigationTypeObject} type="preview" />
                )}
                {navigationType === 'BT' && (
                  <DetailAddBeatRowComponents data={navigationTypeObject} type="preview"/>
                )}
                {navigationType === 'MB' && (
                  <SearchMemberRowComponent data={navigationTypeObject} listType="preview"/>
                )}
                {navigationType === 'EV' && (
                  <PushForEventRowComponent data={navigationTypeObject} type="preview" />
                )}
                {navigationType === 'MAGAZINE' && (
                  <PushForMagazineRowComponent data={navigationTypeObject} type="preview" />
                )}
              </Table>
            </TableContainer>
          </InputWrapper>
        )}
        <SpaceDiv value="10px" />
      </MainContainer>

      <TitleText>수신자 지정</TitleText><LineDiv/>
      <MainContainer>
        <InputWrapper span={10}>
          <RadioButton
              type=''
              name="directAssignYn"
              label="수신자 지정 방식"
              text={['지정된 아이디로 검색', '직접 검색']}
              value={directAssignYn}
              onChange={(e)=>setDirectAssignYn(e.target.value)}
          />
        </InputWrapper>
        {/*아이디 검색 */}
        {
            directAssignYn === 'Y'
            && memberObjects.length === 0
            && (
                <>
                <InputWrapper span={9}>
                  <LabelTextInput
                      name={'memberIds'}
                      label={'검색 대상 회원 아이디'}
                      value={directMemberIds}
                      placeholder={`검색 대상 회원 아이디를 입력해 주세요.`}
                      onChange={checkAndSetDirectMemberIds}
                  />
                </InputWrapper>
                <InputWrapper span={1}>
                    <EditButton text="검색" className='performanceAddBtn' onClick={getMemberInfosToSend} />
                </InputWrapper>
                </>
            )
        }
        {/*검색 지정 버튼*/}
        {
          directAssignYn === 'N'
            && memberObjects.length === 0
            && (
                <InputWrapper span={10}>
                  <VideoInput onClick={() => setMemberModal(true)} text="수신자 검색" />
                </InputWrapper>
            )
        }
        {/* 선택된 수신자 */}
        <InputWrapper span={10}>
          {
            memberObjects.length > 0
              && (
                  <>
                    <SubTitle>{memberObjects.length}명의 회원</SubTitle>
                    <VideoInput onClick={clearMemberObject} text="선택 취소" />
                  </>
              )
          }
          <Table className="nonListTable">
            {memberObjects?.map((data) =>  (
                <SearchMemberRowComponent
                    data={data} type={'modal'} selected={true}
                    modalOnClick={clearOneMemberObject}
                />
            ))}
          </Table>
        </InputWrapper>
      </MainContainer>


      {/* 모달창 */}
      {navigationModal && navigationModalType === '영상' && (
          <PushForArticle closeModal={()=>setNavigationModal(false)}
                          setSelectedModalObj={setNavigationTypeObject}
                          setSelectedModalType={setNavigationType}/>
      )}
      {navigationModal && navigationModalType === '비트' && (
          <PushForBeat closeModal={()=>setNavigationModal(false)}
                       setSelectedModalObj={setNavigationTypeObject}
                       setSelectedModalType={setNavigationType}/>
      )}
      {navigationModal && navigationModalType === '멤버' && (
          <PushForMember closeModal={()=>setNavigationModal(false)}
                         setSelectedModalObj={setNavigationTypeObject}
                         setSelectedModalType={setNavigationType}/>
      )}
      {navigationModal && navigationModalType === '이벤트' && (
          <PushForEvent closeModal={()=>setNavigationModal(false)}
                        setSelectedModalObj={setNavigationTypeObject}
                        setSelectedModalType={setNavigationType}/>
      )}
      {navigationModal && navigationModalType === '매거진' && (
          <PushForMagazine closeModal={()=>setNavigationModal(false)}
                        setSelectedModalObj={setNavigationTypeObject}
                        setSelectedModalType={setNavigationType}/>
      )}
      {memberModal && (
          <PushForMember closeModal={()=>setMemberModal(false)}
                         setSelectedModalObj={setMemberObjects}
                         setSelectedModalType={()=>{}}
                         allowMultiSelect={true}/>
      )}
    </>
  );
};

export default IndividualPush;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const FileWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const PushImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TableContainer = styled.div`
  z-index: 1;
`;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const SubTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-right: 25px;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SpaceDiv = styled.div`
  height: ${({ value }) => value};
`;
