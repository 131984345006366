import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import RadioButton from '../../../../components/common/Button/RadioButton';
import DateInput from '../../../../components/common/Input/DateInput';

// asset
import blankImg from '../../../../assets/images/page/blankImg.png';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// config
import { EXPLORE_CONTENTS } from '../../../../constant/apiUrl';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import LabelTextAreaInput from '../../../../components/common/Input/LabelTextAreaInput';
import FileInput from '../../../../components/common/Input/FileInput';

type data = {
  [key: string]: any;
};

//초기 데이터
const initialData: data = {
  exploreContentsId: null,
  type: 'BM',
  title: '',
  titleEn: '',
  description: '',
  coverImgUrl: '',
  activeYn: 'Y',
  startDate: '',
  endDate: '',
};

const ExploreContentsDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const locationData: any = location.state;
  const prevId = locationData !== null ? locationData.exploreContentsId : null;
  const pageType = locationData !== null ? locationData.pageType : 'add';
  const [exploreContentsData, setExploreContentsData] = useState(initialData);
  const [coverImg, setCoverImg] = useState<string>('');
  const {
    exploreContentsId,
    type,
    title,
    titleEn,
    description,
    coverImgUrl,
    activeYn,
    startDate,
    endDate,
    coverImgFile,
  } = exploreContentsData;
  const initialStartDate = startDate?.replace(' ', 'T').slice(0, 16) || '';
  const initialEndDate = endDate?.replace(' ', 'T').slice(0, 16) || '';

  // 뒤로가기
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // input Date 컨트롤
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setExploreContentsData({ ...exploreContentsData, [name]: value });
    },
    [exploreContentsData],
  );

  // 이미지 파일 세팅을 위한 메소드
  const handleCoverSetData = useCallback(
    (e: string) => {
      setExploreContentsData({ ...exploreContentsData, coverImgFile: e });
    },
    [exploreContentsData],
  );

  // 상세 데이터 가져오기
  const getExploreContents = useCallback(async () => {
    try {
      const resp = await axios.get(`${EXPLORE_CONTENTS}/${prevId}`);
      const result = resp.data.adminExploreContents;
      setExploreContentsData({
        ...result,
      });

      // 이미지 파일 세팅
      setCoverImg(result.coverImgUrl ? result.coverImgUrl : '');
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      // eslint-disable-next-line no-alert
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  // Explore Contents Insert, Update 하기
  const saveExploreContents = async () => {
    const formData = new FormData();
    if (!type || !title || !activeYn || !startDate) {
      alert('필수 양식을 전부 채워주세요.');
      return;
    }

    // Data 추가
    if (exploreContentsId != null) {
      formData.append('exploreContentsId', exploreContentsId);
    }

    formData.append('type', type);
    formData.append('title', title);
    formData.append('titleEn', titleEn);
    formData.append('description', description);
    formData.append('coverImgUrl', coverImgUrl);
    formData.append('activeYn', activeYn);
    formData.append('activeYn', activeYn);
    formData.append(
      'startDate',
      `${startDate.replace('T', ' ').slice(0, 16)}:00`,
    );

    if (endDate != null && endDate !== '') {
      formData.append(
        'endDate',
        `${endDate.replace('T', ' ').slice(0, 16)}:00`,
      );
    }

    // File 추가
    if (coverImgFile != null) {
      formData.append('coverImgFile', coverImgFile);
    }

    console.log(`${startDate.replace('T', ' ').slice(0, 16)}:00`);

    dispatch(setLoading());
    try {
      await axios.post(EXPLORE_CONTENTS, formData, {});
      alert('저장 완료됐습니다.');
      goBack();
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (prevId) {
      getExploreContents().then((r) => r);
    }
  }, [getExploreContents, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  return (
    <>
      <TopContainer>
        <ProfileWrapper>
          {coverImg ? (
            <Img src={coverImg} alt="coverImg" />
          ) : (
            <Img src={blankImg} alt="defaultImg" />
          )}
          <ProfileTitleWrapper>
            <Title>{exploreContentsId}</Title>
          </ProfileTitleWrapper>
        </ProfileWrapper>
        <ButtonWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
          {pageType === 'edit' ? (
            <NormalButton text="수정하기" onSubmit={saveExploreContents} />
          ) : (
            <NormalButton text="추가하기" onSubmit={saveExploreContents} />
          )}
        </ButtonWrapper>
      </TopContainer>
      <LineDiv />
      <MainContainer>
        <InputWrapper>
          <FileInput
            type="coverImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버"
            value={coverImgFile}
            setFile={handleCoverSetData}
            setImgUrl={setCoverImg}
          />
        </InputWrapper>
        {/*<InputWrapper>*/}
        {/*  <SelectModule*/}
        {/*    name="type"*/}
        {/*    placeholder="Baund Music 타입을 선택해주세요."*/}
        {/*    value={baundMusicType[type]}*/}
        {/*    label="Baund Music 타입"*/}
        {/*    onChange={handleSelectSetData}*/}
        {/*    options={baundMusicTypeOption}*/}
        {/*    Required*/}
        {/*  />*/}
        {/*</InputWrapper>*/}
        <InputWrapper>
          <LabelTextInput
            name="title"
            label="타이틀"
            value={title}
            placeholder="제목을 입력해주세요."
            onChange={handleInputSetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEn"
            label="영문 타이틀"
            value={titleEn}
            placeholder="영문 타이틀을 입력해주세요."
            onChange={handleInputSetData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextAreaInput
            name="description"
            label="설명"
            value={description}
            placeholder="설명을 입력해주세요."
            setValue={handleInputSetData}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateWrapper>
            <DateInput
              label="노출기간(시작일)"
              name="startDate"
              onChange={handleInputSetData}
              initialStartDate={initialStartDate}
              Required
            />
            <DateInput
              label="노출기간(종료일)"
              name="endDate"
              onChange={handleInputSetData}
              initialStartDate={initialEndDate}
            />
          </DateWrapper>
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default ExploreContentsDetail;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  object-fit: contain;
  margin-right: 30px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.h1`
  height: 45px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 40px 0;
`;

const MainContainer = styled.div`
  width: 100%;
  //margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
  }
  &:nth-child(3):nth-child(4) {
    grid-row: 3;
  }
  &:nth-child(5) {
    grid-row: 4;
    grid-column: span 2;
  }
  &:nth-child(6) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(7) {
    grid-row: 6;
    grid-column: span 2;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;
