import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import axios from 'axios';

import { fabric } from "fabric"
import { COUPONS } from "../../constant/apiUrl";
import TextField from "../../components/common/TextField";
import NormalButton from "../../components/common/Button/NormalButton";
import {IssuanceTypeObjects} from "./CouponParams";


interface GenerateCodeCouponDetail {
  code: string;
  compensateType: string;
  createDate: string;
  endDateTime: string;
  startDateTime: string;
  issuanceCount: number;
  maxIssuanceCount: number;
  name: string;
  privateYn: string;
  updateDate: string;
  value: number;
  issuanceType: string;
  usedYn: string;
  usingDateTime: string;
  maxRedeemCount: number;
}

// 골드 캠페인 목록 화면(추가, 수정)
const CouponDetail = () => {
  const location = useLocation();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  // 쿠폰 id
  const masterCouponId = detailData !== null ? detailData.masterCouponId : null;
  // 쿠폰 코드 생성 프로젝트 id
  const couponProjectId = detailData.couponProjectId !== null ? detailData.couponProjectId : null;

  // 상위 노출

  // 페이지 이동 ////////////////////////////////////////////////////////////////
  // 뒤로가기
  const [code, setCode] = useState('');
  const [compensateType, setCompensateType] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [value, setValue] = useState(0);
  const [issuanceType, setIssuanceType] = useState('');
  const [issuanceCount, setIssuanceCount] = useState(0);
  const [maxIssuanceCount, setMaxIssuanceCount] = useState(0);
  const [name, setName] = useState('');
  const [privateYn, setPrivateYn] = useState('');
  const [updateDate, setUpdateDate] = useState('');
  const [canvas, setCanvas] = useState('');
  const [couponHeight, setCouponHeight] = useState(812);
  const [couponWidth, setCouponWidth] = useState(375);
  const [usedYn, setUsedYn] = useState('');
  const [usingDateTime, setUsingDateTime] = useState('');
  const [maxRedeemCount, setMaxRedeemCount] = useState(0);
  const convertUtcToLocalTimeZone = (targetDateTime: string) => {
    if(!targetDateTime) return '';
    const utcDateTime = new Date(`${targetDateTime}Z`);
    return utcDateTime.toLocaleString().substring(0, 11);
  };
  const convertCompensateType = (compensateType: string) => {
    if (!compensateType) return '';
    switch (compensateType) {
      case 'BAUND_GOLD':
        return '바운드 골드';
      default:
        return '';
    }
  };
  const convertIssuanceType = (originIssuanceType: string) => {
    // 상품권 발급 형식 변환
    const convertedIssuanceType = IssuanceTypeObjects.map((issuanceTypeObj) => {
      return Object.entries(issuanceTypeObj)
        .filter((typeObj) => typeObj[0] === originIssuanceType)
        .map((typeObj) => typeObj[1]);
    });
    // 상품권 발급 형식 객체 목록 상수에 없는 발급 형식의 경우 원본 값 그대로 리턴
    if (!convertedIssuanceType) return originIssuanceType;
    // 있는 경우 변환 값 리턴
    return convertedIssuanceType;
  };
  const getTextObject = (
    text: string,
    left: number,
    top: number,
    fontSize: number,
  ) => {
    return new fabric.Text(text, {
      fontSize,
      fill: 'white',
      left,
      top,
      textAlign: 'right',
      originX: 'right',
      originY: 'top',
      height: 24,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      lockSkewingX: true,
      lockSkewingY: true,
      selectable: false,
    });
  };
  const moveHandler = (evt) => {
    const movingObject = evt.target;
    console.log(movingObject.get('left'), movingObject.get('top'));
  };
  const getUnitConvertedCompensateType = (couponCompensateType: string) => {
    switch (couponCompensateType) {
      case 'BAUND_GOLD':
        return 'G';
      default:
        return '';
    }
  };
  const parseCouponImageDate = (originUtcDateTime: string) => {
    if(!originUtcDateTime) return '';
    const utcDateTime = new Date(`${originUtcDateTime}Z`);
    const dateElements = utcDateTime
      .toLocaleDateString()
      .replaceAll(' ', '')
      .split('.');
    return `${dateElements[0]}년 ${dateElements[1]}월 ${dateElements[2]}일`;
  };
  const initCanvas = (masterCoupon: GenerateCodeCouponDetail) => {
    const canvasWindow = new fabric.Canvas('canvas', {
      height: couponHeight,
      width: couponWidth,
    });
    fabric.Image.fromURL(
      'https://cdn.baund.com/admin/coupon/baund_coupon.png',
      (img) => {
        const scaleToWidth = img.scaleToWidth(canvasWindow.getWidth(), false);
        canvasWindow.add(scaleToWidth);
        canvasWindow.sendToBack(scaleToWidth);
      },
      {
        crossOrigin: 'anonymous',
        selectable: false,
      },
    );
    const valueText = getTextObject(
      `${masterCoupon.value} ${getUnitConvertedCompensateType(masterCoupon.compensateType)}`,
      330,
      451,
      13,
    );
    const codeText = getTextObject(masterCoupon.code, 330, 489, 13);
    const validDateTime = `${parseCouponImageDate(masterCoupon.startDateTime)} ~ ${parseCouponImageDate(masterCoupon.endDateTime)}`;
    const validDateTimeText = getTextObject(validDateTime, 330, 527, 13);
    canvasWindow.add(valueText);
    canvasWindow.add(codeText);
    canvasWindow.add(validDateTimeText);
    canvasWindow.renderAll();
    return canvasWindow;
  };
  // 쿠폰 상세 데이터
  const setInitData = (couponDetail: GenerateCodeCouponDetail) => {
    setCode(couponDetail.code);
    setCompensateType(couponDetail.compensateType);
    setValue(couponDetail.value);
    setIssuanceType(couponDetail.issuanceType);
    setIssuanceCount(couponDetail.issuanceCount);
    setMaxIssuanceCount(couponDetail.maxIssuanceCount);
    setName(couponDetail.name);
    setPrivateYn(couponDetail.privateYn);
    setCreateDate(convertUtcToLocalTimeZone(couponDetail.createDate));
    setEndDateTime(convertUtcToLocalTimeZone(couponDetail.endDateTime));
    setStartDateTime(convertUtcToLocalTimeZone(couponDetail.startDateTime));
    setUpdateDate(
      couponDetail.issuanceCount > 0
        ? convertUtcToLocalTimeZone(couponDetail.updateDate)
        : '미등록',
    );
    setUsedYn(couponDetail.usedYn);
    setUsingDateTime(convertUtcToLocalTimeZone(couponDetail.usingDateTime));
    setMaxRedeemCount(couponDetail.maxRedeemCount);
  };
  const convertCanvasToImage = (targetAnchor, targetCanvas) => {
    targetAnchor.href = targetCanvas.toDataURL({
      format: 'png',
      quality: 1.0,
      multiplier: 3,
    });
    targetAnchor.download = 'coupon.png';
  };
  // 캠페인 상세 조회
  const getDetail = async () => {
    await axios
      .get(`${COUPONS}/${masterCouponId}`)
      .then((res) => {
        const { coupon } = res.data;
        setInitData(coupon);
        setCanvas(initCanvas(coupon));
      })
      .catch((reason) => {
        console.error(reason);
        alert('잠시후 다시 이용해주세요.');
      });
  };
  useEffect(() => {
    if (masterCouponId) {
      getDetail().then((r) => r);
    }
  }, [masterCouponId]);
  return (
    <>
      <TitleText>쿠폰 상세 보기</TitleText>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="뒤로 가기"
            onSubmit={() => window.history.back()}
          />
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <TextField label="쿠폰 번호" text={masterCouponId} />
        <TextField label="쿠폰 명" text={name} />
        <TextField label="쿠폰 가격" text={value} />
        <TextField label="쿠폰 코드" text={code} />
        <TextField label="쿠폰 재화 지급 유형" text={convertCompensateType(compensateType)} />
        <TextField label="쿠폰 형식" text={convertIssuanceType(issuanceType)} />
        <TextField label="쿠폰 비공개 여부" text={privateYn === 'Y' ? '비공개' : '공개'} />
        <TextField label="가장 최근 등록 일자" text={updateDate} />
        <TextField label="현재 쿠폰을 등록한 멤버 수" text={issuanceCount} />
        <TextField label="같은 종류 쿠폰의 다른 시리얼 중복 입력 가능 수" text={maxRedeemCount} />
        <TextField label="등록/사용 시작 기간(해당 날 0시0분0초 부터 사용 가능합니다.)" text={startDateTime} />
        <TextField label="등록/사용 만료 기간(표시 날짜의 23시59분59초 이전 까지 사용 가능합니다.)" text={endDateTime} />
        <TextField label="발급 일자" text={createDate} />
        {issuanceType === 'GENERATE' && (
          <>
            <TextField label="사용 여부" text={usedYn === 'Y' ? '사용' : '미사용'} />
            <TextField label="사용 시간" text={usingDateTime && usingDateTime !== '' ? usingDateTime : ''} />
          </>
        )}
      </MainContainer>
      <hr />
      <CouponContainer>
        <TitleText>쿠폰 이미지</TitleText>
        <canvas id="canvas" />
        <a id="imageDownloadAnchor">
          <NormalButton text="이미지 저장" onSubmit={() => {
              convertCanvasToImage(
                document.getElementById('imageDownloadAnchor'),
                canvas,
              );
            }}/>
        </a>
      </CouponContainer>
    </>
  );
};
export default CouponDetail;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CouponContainer = styled.div`
  width: 375px;
  margin-top: 30px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
  a {
    width: 375px;
  }
  button {
    width: 375px;
  }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.mainBlack};
`;