import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';


import NormalButton from "../../../components/common/Button/NormalButton";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import ListItem from "../../../components/common/List/ListItem";
import {ADVERTISER} from "../../../constant/apiUrl";
import axios from "axios";
import {postsPerPage} from "../../../constant/common";
import SearchInput from "../../../components/common/Input/SearchInput";
import RoundButton from "../../../components/common/Button/RoundButton";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";
import SelectModule from "../../../components/common/Select/SelectModule";
import * as commonFn from "../../../utils/commonFn";

// 광고주 목록 화면
const Advertisers = () => {
  const navigate = useNavigate();

  // 페이징
  const storagePageNumber = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
      storagePageNumber ? JSON.parse(storagePageNumber) : 0
  );
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
    window.localStorage.setItem('pageNumber', String(e - 1));
  }, []);

  //표시 데이터
  const [advertiserList, setAdvertiserList] = useState([]);

  // 검색 조건
  const storageWord = localStorage.getItem('storageWord');
  const [word, setWord] = useState(storageWord || '');
  const storageExecuteType = localStorage.getItem('executeType');
  const [executeType, setExecuteType] = useState(storageExecuteType || 'ALL');

  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params = {
    word,
    executeType,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };

  //광고주 데이터 조회 //////////////////////////////////////////////////////////
  const getAdvertisers = async() => {
    await axios.get(ADVERTISER, {params})
    .then(res => {
      setAdvertiserList(
          res.data.adminAdvertiserList.map((adv)=>{
            return {
              advertiserId: adv.advertiserId,
              advertiserStrId: adv.advertiserStrId,
              name: adv.name,
              underExecuteYn: adv.underExecuteYn,
              selected: false
            }
          })
      );
      setTotalCount(res.data.count);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => getAdvertisers(), [pageNumber, executeType]);

  const resetPageAndGetAdvertisers = () => {
    if(word) {
      setPageNumber(0);
      params.offset = 0;
      getAdvertisers().then();
    }
  }

  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      resetPageAndGetAdvertisers();
    }
  };

  const handleWord = (_word) => {
    setWord(_word);
    window.localStorage.setItem('storageWord', _word);
  }

  const handleExecuteType = (_executeType) => {
    setExecuteType(_executeType);
    window.localStorage.setItem('executeType', _executeType);
  }

  // 상세 페이지로 이동
  const moveToDetail = (_advertiserId) => {
    navigate(
        '/advertisement/advertiser/edit',
        {state:{advertiserId: _advertiserId}}
    );
  }

  //데이터 삭제 /////////////////////////////////////////////////////////////////
  // 전체 삭제 선택 여부
  const [checkAll, setCheckAll] = useState(false);
  // 삭제할 아이디 목록
  const [idsToDelete, setIdsToDelete] = useState([]);

  // 삭제
  const deleteSelected = async () => {
    if(idsToDelete.length === 0) {return;}
    if (!confirm('선택한 광고주를 삭제하시겠습니까?.\n삭제 시, 모든 데이터는 삭제 처리됩니다.')) {
      return;
    }

    // console.log(idsToDelete);

    await axios.delete(`${ADVERTISER}`, {params:{ids: idsToDelete}})
    .then(() => {
      getAdvertisers();
      setCheckAll(false);
      alert('삭제 완료');
    })
    .catch (err => {
      const errorMessage = (err.response.status === 418)
          ? '연동된 광고가 있어 삭제할 수 없습니다.'
          : '문제가 발생하였습니다. 담당자에게 문의해주세요.';
      // console.log('Advertisers > deleteSelected > err.response:', err.response);
      alert(errorMessage);
    });
  };

  // 전체 선택
  const setSelectedAll = (checked) => {
    advertiserList.map((item) => {
      item.selected = checked;
    });

    const arr = [];
    //선택한 것이면
    if (checked) {
      // 전체 추가
      advertiserList.forEach((el) => arr.push(el.advertiserId));
    }
    setIdsToDelete(arr);
    setCheckAll(checked);
  }

  // 개별 선택
  const setSelected = (_id, checked) => {
    // console.log('setSelected > _id:',_id, '/checked:', checked);
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    advertiserList.map((item) => {
      if (item.advertiserId === _id) {
        item.selected = checked;
      }
    });

    setIdsToDelete(
        //선택한 것이면
        checked
            // 아이디 목록에 추가
            ? [...idsToDelete, _id]
            // 아이디 목록에서 제외
            : idsToDelete.filter((item)=> item !== _id)
    );
    setCheckAll(false);
  }

  return (
      <>
        <TitleText>광고주 리스트</TitleText>
        <TopContainer>
          <TopWrapper jc={'right'}>
            <ButtonWrapper>
              <NormalButton
                  text="선택 삭제"
                  className={(idsToDelete.length === 0) ? "unActiveBtn": "blockBtn"}
                  onSubmit={deleteSelected}
              />
              <NormalButton
                  text="새로 만들기"
                  onSubmit={() => navigate('/advertisement/advertiser/add')}
              />
            </ButtonWrapper>
          </TopWrapper>
          <TopWrapper jc={'left'}>
            <RoundButton
                title="전체" selected={executeType === 'ALL'}
                onClick={() => handleExecuteType('ALL')}/>
            <RoundButton
                title="집행중" selected={executeType === 'UNDER_EXECUTE'}
                onClick={() => handleExecuteType('UNDER_EXECUTE')}/>
            <RoundButton
                title="미집행중" selected={executeType === 'OUT_EXECUTE'}
                onClick={() => handleExecuteType('OUT_EXECUTE')}/>
          </TopWrapper>
          <TopWrapper>
            <SearchWrapper>
              <SelectModule
                  name="type" label="" placeholder="" className='commonSearchType200'
                  value={'광고주 명'}
                  onChange={() => {}}
                  options={commonFn.transObjectToOptions({ADVERTISER_NAME: '광고주 명'})}
              />
              <SearchInputWrapper>
                <SearchInput value={word} onChange={e => handleWord(e.target.value)}
                             onKeyPress={enterKeyPress}/>
              </SearchInputWrapper>
              <NormalButton text="검색" className='cancelBtn' onSubmit={resetPageAndGetAdvertisers} />
            </SearchWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
              <PaginationWrapper>
                <PaginationWithTotalCount
                    postsPerPage={postsPerPage}
                    totalCount={totalCount}
                    totalCountPostfix={'명 광고주'}
                    currentPage={pageNumber}
                    pageDispatch={pageDispatch}
                />
              </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {advertiserList.length !== 0 ? (
            <Table
                colList={[10, 50, 100, 100]}
                thead={<>
                  <TableHead>
                    <input id={`checkbox_0`} type="checkbox"
                           checked={checkAll}
                           onChange={(e) => setSelectedAll(e.currentTarget.checked)}/>
                  </TableHead>
                  <ListHead title="Id" />
                  <ListHead title="광고주 명"/>
                  <ListHead title="광고 집행 유무"/>
                </>}
            >
              {
                advertiserList?.map(el =>
                  <tr key={el.advertiserId}>
                    <td>
                      <input id={`checkbox_${el.advertiserId}`} type="checkbox"
                             checked={ el.selected}
                             onChange={(ev) => setSelected(el.advertiserId, ev.currentTarget.checked)}/>
                    </td>
                    <td onClick={() => moveToDetail(el.advertiserId)}>
                      <ListItem text={el.advertiserId} />
                    </td>
                    <td onClick={() => moveToDetail(el.advertiserId)}>
                      <ListItem text={`${el.name}(${el.advertiserStrId})`} />
                    </td>
                    <td onClick={() => moveToDetail(el.advertiserId)}>
                      <ListItem text={(el.underExecuteYn === 'Y')?'집행중':'미집행중'} />
                    </td>
                  </tr>
                )
              }
            </Table>
          ) : (
              <SpanNoResult>검색 결과가 없습니다.</SpanNoResult>
          )}
        </MainContainer>
      </>
  );
};
export default Advertisers;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 430px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;
