import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

// common
import RoundButton from '../../components/common/Button/RoundButton';
import SearchInput from '../../components/common/Input/SearchInput';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import Pagination from '../../components/common/Pagination';
import ListSelect from '../../components/common/Select/ListSelect';

// config
import {
  CONTENTS_ARTICLE,
  CONTENTS_ARTICLE_COMMENT,
  CONTENTS_ARTICLE_SUB_COMMENT,
  CONTENTS_BEAT_COMMENT,
  CONTENTS_BEAT_SUB_COMMENT,
  CONTENTS_MEMBER,
  CONTENTS_MEMBER_COLLECTION,
  EXCLUDE_ARTICLE_BLOCK_LIST,
  EXCLUDE_ARTICLE_LIST,
  EXCLUDE_ARTICLE_REJECT_LIST,
  REPORT_BLOCK_LIST,
  REPORT_CONFIRM_LIST,
  REPORT_REJECT_LIST,
  REPORT_DETAIL,
  CONTENTS_COMMUNITY_BOARD,
  CONTENTS_COMMUNITY_BOARD_COMMENT,
  CONTENTS_COMMUNITY_BOARD_SUB_COMMENT,
} from '../../constant/apiUrl';

// hooks
import useInput from '../../hooks/useInput';

//constant
import {
  csExDeleteColList,
  csSearchTypeList,
  blockTypeOptions,
  csExConfirmColList,
  csExBlockColList,
} from '../../constant/cs';
import { postsPerPage } from '../../constant/common';

// 타입
import { blockRowComponentDataProps } from '../../definitions/customService';

// component
import ExDeleteListRowComponent from './components/ExDeleteListRowComponent';
import ExBlockListRowComponent from './components/ExBlockListRowComponent';
import ExNeedConfirmListRowComponent from './components/ExNeedConfirmRowComponent';

const BlockElse = () => {
  const navigate = useNavigate();
  const storageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('blockVideoType');
  // const storageContentsType = localStorage.getItem('contentsType');

  const [needConfirmList, setNeedConfirmList] = useState([]);
  const [blockList, setBlockList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [contentsType, setContentsType] = useState('');
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, onReset] = useInput('');
  const [type, setType] = useState(storageType || '미차단 내역');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storageNumber ? JSON.parse(storageNumber) : 0,
  );
  const params = {
    searchType,
    contentsType,
    keyword,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const saveStorage = () => {
    window.localStorage.setItem('pageNumber', String(pageNumber));
    window.localStorage.setItem('blockVideoType', type);
    // window.localStorage.setItem('contentsType', contentsType);
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      if (!searchType) {
        setSearchType('TM');
        params.searchType = 'TM';
      }
      setPageNumber(0);
      params.offset = 0;
      getAllReportList();
    }
  };

  // 리스트별 디테일 페이지 나타내기
  const goToVideoDetail = async (data: any) => {
    const { contentsId } = data;
    let resp;
    try {
      switch (data.contentsType) {
        // 영상
        case 'AR':
          resp = await axios.get(`${CONTENTS_ARTICLE}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { contentsUrl: resp.data.article.contentsUrl, data, type },
          });
          break;
        // 멤버부분 상의후 결정
        case 'MB':
          resp = await axios.get(`${CONTENTS_MEMBER}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { member: resp.data.member, data, type },
          });
          break;
        // 영상 댓글
        case 'ACM':
          resp = await axios.get(`${CONTENTS_ARTICLE_COMMENT}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.articleComment, data, type },
          });
          break;
        // 영상 대댓글
        case 'ASCM':
          resp = await axios.get(
            `${CONTENTS_ARTICLE_SUB_COMMENT}/${contentsId}`,
          );
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.articleSubComment, data, type },
          });
          break;
        // 비트 댓글
        case 'BCM':
          resp = await axios.get(`${CONTENTS_BEAT_COMMENT}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.beatComment, data, type },
          });
          break;
        // 비트 대댓글
        case 'BSCM':
          resp = await axios.get(`${CONTENTS_BEAT_SUB_COMMENT}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.beatSubComment, data, type },
          });
          break;
        // 컬렉션
        case 'CL':
          resp = await axios.get(`${CONTENTS_MEMBER_COLLECTION}/${contentsId}`);
          navigate(REPORT_DETAIL, {
            state: { memberCollection: resp.data.memberCollection, data, type },
          });
          break;
        // 채팅방
        case 'DM':
          navigate(REPORT_DETAIL, { state: { data, type } });
          break;
        // 커뮤니티 보드
        case 'CB':
          console.log('contentsId', contentsId);
          resp = await axios.get(`${CONTENTS_COMMUNITY_BOARD}/${contentsId}`);
          console.log(resp.data);
          navigate(REPORT_DETAIL, {
            state: { board: resp.data.communityBoardArticle, data, type },
          });
          break;
        // 커뮤니티 보드 댓글
        case 'CBCM':
          resp = await axios.get(
            `${CONTENTS_COMMUNITY_BOARD_COMMENT}/${contentsId}`,
          );
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.comment, data, type },
          });
          break;
        // 커뮤니티 보드 대댓글
        case 'CBSCM':
          resp = await axios.get(
            `${CONTENTS_COMMUNITY_BOARD_SUB_COMMENT}/${contentsId}`,
          );
          navigate(REPORT_DETAIL, {
            state: { comment: resp.data.subComment, data, type },
          });
          break;
        default:
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  const getAllReportList = async () => {
    switch (type) {
      case '미차단 내역':
        try {
          const resp = await axios.get(EXCLUDE_ARTICLE_LIST, {
            params,
          });
          await setNeedConfirmList(resp.data.adminArticleReportList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      case '차단 내역':
        try {
          const resp = await axios.get(EXCLUDE_ARTICLE_BLOCK_LIST, {
            params,
          });
          await setBlockList(resp.data.adminLogList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      case '삭제 내역':
        try {
          const resp = await axios.get(EXCLUDE_ARTICLE_REJECT_LIST, {
            params,
          });
          await setDeleteList(resp.data.adminLogList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      default:
    }
  };

  // 신고 미처리, 차단, 삭제 내역 조회
  const getReportList = async () => {
    // 분류 진행했을때
    // if (contentsType) {
    switch (type) {
      case '미차단 내역':
        try {
          const resp = await axios.get(REPORT_CONFIRM_LIST, {
            params,
          });
          await setNeedConfirmList(resp.data.adminArticleReportList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      case '차단 내역':
        try {
          const resp = await axios.get(REPORT_BLOCK_LIST, {
            params,
          });
          await setBlockList(resp.data.adminLogList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      case '삭제 내역':
        try {
          const resp = await axios.get(REPORT_REJECT_LIST, {
            params,
          });
          await setDeleteList(resp.data.adminLogList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      default:
    }
    // } else {
    // switch (type) {
    //   case '미차단 내역':
    //     try {
    //       const resp = await axios.get(EXCLUDE_ARTICLE_LIST, {
    //         params,
    //       });
    //       await setNeedConfirmList(resp.data.adminArticleReportList);
    //       await setTotalCount(resp.data.count);
    //     } catch (err) {
    //       alert('잠시후 다시 시도 해주세요.');
    //     }
    //     break;
    //   case '차단 내역':
    //     try {
    //       const resp = await axios.get(EXCLUDE_ARTICLE_BLOCK_LIST, {
    //         params,
    //       });
    //       await setBlockList(resp.data.adminLogList);
    //       await setTotalCount(resp.data.count);
    //     } catch (err) {
    //       alert('잠시후 다시 시도 해주세요.');
    //     }
    //     break;
    //
    //   case '삭제 내역':
    //     try {
    //       const resp = await axios.get(EXCLUDE_ARTICLE_REJECT_LIST, {
    //         params,
    //       });
    //       await setDeleteList(resp.data.adminLogList);
    //       await setTotalCount(resp.data.count);
    //     } catch (err) {
    //       alert('잠시후 다시 시도 해주세요.');
    //     }
    //     break;
    //
    //   default:
    // }
    // }
  };

  const handleSelect = (e: React.MouseEvent<HTMLLIElement>, setState: any) => {
    setState(e.currentTarget.title);
  };

  // 상단 버튼 클릭 이벤트
  const handleButton = (e: any) => {
    const { title } = e.target;
    setType(title);
    onReset();
  };

  useEffect(() => {
    if (storageNumber || storageType) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('blockVideoType', '');
    }
  }, [storageNumber, storageType]);

  // 타입이 바뀔때마다 페이지네이션 0으로 바꿔주기
  useEffect(() => {
    if (storageType !== type) {
      params.offset = 0;
      setPageNumber(0);
    }
    setSearchType('');
    setContentsType('');
    getAllReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (contentsType) {
      params.offset = 0;
      setPageNumber(0);
      getReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentsType]);

  useEffect(() => {
    if (contentsType) {
      getReportList();
    } else {
      getAllReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    const urlQuery = new URLSearchParams(window.location.search);
    if (urlQuery.has('contents-type') && urlQuery.get('contents-type') !== '') {
      setContentsType(String(urlQuery.get('contents-type')));
    } else {
      getAllReportList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ButtonContainer>
        <RoundButton
          title="미차단 내역"
          onClick={handleButton}
          selected={type === '미차단 내역' && true}
        />
        <RoundButton
          title="차단 내역"
          selected={type === '차단 내역' && true}
          onClick={handleButton}
        />
        <RoundButton
          title="삭제 내역"
          selected={type === '삭제 내역' && true}
          onClick={handleButton}
        />
      </ButtonContainer>
      <Line />
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={csSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
              state={csSearchTypeList[0]}
            />
            <SearchInputWrapper>
              <SearchInput
                value={keyword}
                onChange={setKeyword}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        {type === '미차단 내역' && (
          <Table
            colList={csExConfirmColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListSelect
                  title="분류"
                  list={blockTypeOptions}
                  onClick={handleSelect}
                  setState={setContentsType}
                />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="" />
              </>
            }
          >
            {needConfirmList?.map((el: blockRowComponentDataProps) => (
              <ExNeedConfirmListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '차단 내역' && (
          <Table
            colList={csExBlockColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListSelect
                  title="분류"
                  list={blockTypeOptions}
                  onClick={handleSelect}
                  setState={setContentsType}
                />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="처리자" />
                <ListHead title="처리 날짜" />
                <ListHead title="신고 상태" />
                <ListHead title="" />
              </>
            }
          >
            {blockList?.map((el: blockRowComponentDataProps) => (
              <ExBlockListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '삭제 내역' && (
          <Table
            colList={csExDeleteColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListSelect
                  title="분류"
                  list={blockTypeOptions}
                  onClick={handleSelect}
                  setState={setContentsType}
                />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="처리자" />
                <ListHead title="처리 날짜" />
                <ListHead title="신고 상태" />
                <ListHead title="" />
              </>
            }
          >
            {deleteList?.map((el: blockRowComponentDataProps) => (
              <ExDeleteListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
      </MainContainer>
    </>
  );
};

export default BlockElse;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Line = styled.div`
  display: block;
  border-bottom: 1px solid #e7e7e7;
  margin: 30px 0 50px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 50px;
`;
