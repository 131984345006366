// 시간 날라왔을때 오전 오후 표현해서 리턴하는거
const handleDate = (date: any) => {
  const result = new Date(date);
  const resultDate = `${date?.slice(0, 10)} ${result.toLocaleTimeString(
    'ko-KR',
  )}`;
  if (resultDate?.length === 22) {
    return resultDate?.slice(0, 19);
  }
  return resultDate?.slice(0, 18);
};

export default handleDate;
