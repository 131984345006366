import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import SelectModule from "../../../components/common/Select/SelectModule";
import CheckboxButton from "../../../components/common/Button/CheckboxButton";

// config
import { resetLoading, setLoading } from '../../../redux/slices/user';
import MultiSelect from "../../../components/common/Select/MultiSelect";

const SuggestWordRandomDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;


  const [name, setName] = useState('');
  const [activeYn, setActiveYn] = useState('Y');
  const [count, setCount] = useState(1);
  const [suggestWordMasters, setSuggestWordMasters] = useState([
    {id:0, name:''},{id:0, name:''},{id:0, name:''},{id:0, name:''},{id:0, name:''},
    {id:0, name:''},{id:0, name:''},{id:0, name:''},{id:0, name:''},{id:0, name:''}
  ]);

  // 카테고리 마스터
  const [categoryAll, setCategoryAll] = useState([]);
  // 선택된 카테고리 명
  const [categoryNameSelected, setCategoryNameSelected] = useState([]);
  // 카테고리 전체 선택 여부
  const [checkedSelectAll, setCheckedSelectAll] = useState(false);

  // 페이지 초기 표시 ///////////////////////////////////////////////////////////
  // 페이지 진입시 실행
  useEffect(async () => {
    // 카테고리 조회
    await axios.get(`/setting/suggest/word_category`, {
      params: {
        sortType: 'NAME_ASC',
        limit: 100,
        offset: 0,
      },
    })
    .then(res => {
      setCategoryAll(res.data.suggestWordCategories);
      // 수정인 경우
      if(prevId) {
        // 상세 조회
        getDetail(res.data.suggestWordCategories);
      }
    })
    .catch (err => {
      console.log(err);
      alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  }, []);

  // 조회 //////////////////////////////////////////////////////////////////////
  const getDetail = async (categoryAll) => {
    // console.log('SuggestWordRandomDetail > getDetail > prevId:', prevId);
    // console.log('SuggestWordRandomDetail > getDetail > categoryAll:', categoryAll);
    axios.get(`/setting/suggest/word_random/${prevId}`)
    .then((resp) => {
      let data = resp.data.suggestWordRandom;
      setName(data.name);
      setActiveYn(data.activeYn);
      setCount(data.count);
      let wordMasterTemp = [
        {id: 0, name: ''}, {id: 0, name: ''}, {id: 0, name: ''},
        {id: 0, name: ''}, {id: 0, name: ''},
        {id: 0, name: ''}, {id: 0, name: ''}, {id: 0, name: ''},
        {id: 0, name: ''}, {id: 0, name: ''}
      ];
      if (data.suggestWordMasters !== null && data.suggestWordMasters.length !== 0) {
        data.suggestWordMasters.forEach((el, index) => {
          wordMasterTemp[index] = el;
        });
      }
      setSuggestWordMasters(wordMasterTemp);

      // 카테고리 선택이 있으면
      if (data?.suggestWordCategories) {
        let tmpCategoryNameSelected = [];
        // 마스터에서 찾아서 설정
        data.suggestWordCategories.forEach((itemSelected) => {
          categoryAll.forEach((itemAll) => {
            if (itemAll.id === itemSelected.id) {
              tmpCategoryNameSelected = [...tmpCategoryNameSelected, itemAll.name];
            }
          });
        });
        setCategoryNameSelected(tmpCategoryNameSelected);
      }

    })
    .catch((err) => {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    });
  }

  // 추가/수정 //////////////////////////////////////////////////////////////////
  const addContents = async (type) => {

    // console.log('SuggestWordRandomDetail > addContents');
    // console.log('name:',name);
    // console.log('activeYn:',activeYn);
    // console.log('count:',count);
    // console.log('suggestWordMasters:',[...suggestWordMasters].filter(b => b.name !== '').map(b=>b.name));
    // console.log('suggestWordCategoryIds:',[...categoryAll].filter(c => c.selected === true).map(b=>b.id));

    // let tmpWordCount = [...suggestWordMasters].filter(b => b.name !== '').length;
    // let tmpCategoryWordCount = 0;
    // [...categoryAll].filter(c => c.selected === true).forEach(cat=>{
    //   // console.log('cat.count:',cat);
    //   tmpCategoryWordCount += cat.wordCount;
    // });
    // console.log('tmpWordCount:',tmpWordCount);
    // console.log('tmpCategoryWordCount:',tmpCategoryWordCount);

    // 특정 제시어
    let tmpWords = [...suggestWordMasters].filter(b => b.name !== '').map(b=>b.name);
    // 제시어 카테고리
    // let tmpCategoryIds = [...categoryAll].filter(c => c.selected === true).map(b=>b.id);
    let tmpCategoryIds = [];
    let tmpCategoryWordCount = 0;
    categoryAll.forEach((category) => {
      if (categoryNameSelected.includes(category.name)) {
        tmpCategoryIds.push(category.id)
        tmpCategoryWordCount += category.wordCount;
      }
    });

    // console.log('tmpWords:',tmpWords);
    // console.log('tmpCategoryWordCount:',tmpCategoryWordCount);

    if (count > (tmpWords.length + tmpCategoryWordCount)) {
      alert('최대 제시어 개수가 사용 가능한 제시어 수 보다 많습니다.');
      return;
    }

    // 특정 제시어 중복 체크
    if (tmpWords.length !== new Set(tmpWords).size) {
      alert('중복된 특정 제시어가 있습니다.');
      return;
    }

    if (tmpWords.length === 0 && tmpCategoryIds.length === 0) {
      alert('특정 제시어 또는 제시어 카테고리를 선택해 주세요.');
      return;
    }

    const request = {};
    if (name.length === 0) {
      alert('이벤트 랜덤 제시어 제목을 입력해 주세요.');
      return;
    }
    request['name'] = name;
    request['activeYn'] = activeYn;
    request['count'] = count;
    request['predefinedSuggestWords'] = tmpWords;
    request['suggestWordCategoryIds'] = tmpCategoryIds;

    const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
    // console.log('SuggestWordRandomDetail > addContents> request', request);

    const formData = new FormData();
    formData.append('request', blob);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(`/setting/suggest/word_random/${prevId}`, formData, {});
      } else {
        await axios.post(`/setting/suggest/word_random`, formData, {});
      }
      await navigate('/operation/suggestWordRandom');
      alert('저장되었습니다.');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 특정 제시어 값 설정
  const setWordNameByIndex = (index, value) => {
    // 삭제인 경우 확인
    if ((value === '') && !confirm('삭제하시겠습니까?\n삭제 시, 해당 제시어는 랜덤 제시어로 노출되지 않습니다.')) {
      return;
    }

    const _list = [...suggestWordMasters];
    _list[index].name = value;
    setSuggestWordMasters(_list);
  }

  // 카테고리 전체 선택
  const setSelectedAll = (event) => {
    // 전체 선택이면
    if(event.target.checked) {
      // 이름 모두 추가
      setCategoryNameSelected(
        categoryAll.map((item) => {
          return item.name;
        })
      );
    }
    // 전체 취소면
    else{
      // 모두 삭제
      setCategoryNameSelected([]);
    }
    setCheckedSelectAll(event.target.checked);
  }

  // 카테고리 선택시
  const processSelectedCategory = (categoryName) => {

    // 전체 선택 풀기
    setCheckedSelectAll(false);

    // 선택된 카테고리명에 존재하면
    if (categoryNameSelected?.indexOf(categoryName) !== -1) {
      // 제거
      setCategoryNameSelected(categoryNameSelected?.filter(
          (el) => el !== categoryName,
      ));
      return;
    }

    // 존재하지 않으면 추가
    setCategoryNameSelected((prev) => [...prev, categoryName]);
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          <NormalButton text="저장" onSubmit={() => addContents(prevId ?'edit':'add')}/>
        </ButtonWrapper>
      </TopContainer>

      <TitleText>이벤트 랜덤 제시어{prevId ? (' : ' + prevId) : ''}</TitleText>
      <LineDiv/>

      <MainContainer>
        <InputWrapper span={10}>
          <LabelTextInput
            name="name"
            label="이벤트 랜덤 제시어 제목"
            placeholder="이벤트 랜덤 제시어 제목을 입력해 주세요."
            Required
            value={name}
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
          />
        </InputWrapper>

        <InputWrapper span={10}>
          <RadioButton
              name="activeYn"
              label="사용 여부"
              Required
              type="activeYn"
              value={activeYn}
              onChange={(e) => setActiveYn(e.target.value)}
              text={['사용', '미사용']}
          />
        </InputWrapper>

        <InputWrapper span={10}>
          <SelectModule
              name="count"
              placeholder=""
              value={count}
              label="최대 제시어 개수 선택"
              options={[1,2,3,4]}
              onChange={(e)=>setCount(e)}
              Required
          />
        </InputWrapper>
      </MainContainer>

      <TitleText>{'특정 제시어 입력'}</TitleText>
      <InputText>
        <span>*</span>{' 최대 10개까지 입력 가능하며 최대 제시어 개수와 제시어 개수가 동일하거나, 많으면 특정 제시어 안에서 랜덤으로 제시어가 노출됩니다.'}
      </InputText>
      <LineDiv/>

      <MainContainer mt={20}>
        {suggestWordMasters?.map((data, index) =>
          <InputWrapper span={5}>
            <LabelTextInput
                key={index}
                name={`suggestWordMaster_${index}`}
                label={`특정 제시어${index+1}`}
                value={data.name}
                placeholder={`특정 제시어를 입력해주세요.`}
                onChange={e => setWordNameByIndex(index, e.target.value)}
                maxLen={5}
                onReset={() => setWordNameByIndex(index, '')}
            />
          </InputWrapper>
        )}
      </MainContainer>

      <TitleText>{'특정 제시어 외 불러올 제시어 카테고리 선택'}</TitleText>
      <LineDiv/>

      <MainContainer mt={20}>

        <InputWrapper span={2} mt={-20}>
          <CheckboxButton
              value={0}
              name={'전체'}
              checkedValue={checkedSelectAll}
              onChange={setSelectedAll}
          />
        </InputWrapper>

        <InputWrapper span={8} mt={-10}>
          <MultiSelect label=""
              name="category"
              placeholder="카테고리를 선택해주세요."
              value={categoryNameSelected}
              onChange={processSelectedCategory}
              options={categoryAll?.map((el) => el.name)}
          />
        </InputWrapper>

      </MainContainer>
    </>
  );
};
export default SuggestWordRandomDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '50px'};
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;