const getUtcTime = (time: any, type: string) => {
  const date = new Date();
  const Utctime = new Date(time.getTime() - date.getTimezoneOffset() * 60000);
  if (type === 'start') {
    return Utctime.toISOString().slice(0, 19);
  }
  return `${Utctime.toISOString().slice(0, 11)}23:59:59`;
};

export default getUtcTime;
