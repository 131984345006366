import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import TagMenuSelect from '../../../components/common/Select/TagMenuSelect';

// config
import {COMMUNITY_CATEGORY, SEARCH_MEMBER} from '../../../constant/apiUrl';
import useInput from "../../../hooks/useInput";
import {postsPerPage} from "../../../constant/common";
import VideoInput from "../../../components/common/Input/VideoInput";
import Table from "../../../components/common/List/Table";
import {addUserCollectionColList} from "../../../constant/explore";
import UserRowComponent from "./components/UserRowComponent";
import VideoModal from "../../../components/common/Modal/VideoModal";
import SearchMenuSelect from "../../../components/common/Select/SearchMenuSelect";
import {
  memberRestrictOptions,
  searchMemberSearchTypeList,
  userTypeOptions
} from "../../../constant/member";
import SearchInput from "../../../components/common/Input/SearchInput";
import Pagination from "../../../components/common/Pagination";
import ListHead from "../../../components/UI/ListHead";
import ListSelect from "../../../components/common/Select/ListSelect";
import {RowComponentDataProps} from "../../../definitions/beat";
import {CustomProps} from "../../../definitions/common";

const FilterCategoryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const detailData: any = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;
  const prevTotalCount = detailData !== null ? detailData.prevTotalCount : 0;
  const communityCategoryList =
    detailData !== null ? detailData.communityCategoryList : [];

  const [sequence, setSequence] = useState<any>(0);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [nameEs, setNameEs] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionEs, setDescriptionEs] = useState('');
  const [privateYn, setPrivateYn] = useState('N');
  const [connectedCategoryIds, setConnectedCategoryIds] = useState<any>([]);
  const [connectedCategoryNames, setConnectedCategoryNames] = useState<any>([]);
  const [designatedCommentMemberIds, setDesignatedCommentMemberIds] = useState<any>([]);
  const [designatedCommentMembersCurated, setDesignatedCommentMembersCurated] = useState<any>([]);

  // modal  state
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [designatedCommentMembersMaster, setDesignatedCommentMembersMaster] = useState<any>([]);
  const [designatedCommentMembersWork, setDesignatedCommentMembersWork] = useState<any>([]);
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, onReset] = useInput('');
  const [isRestricted, setIsRestricted] = useState('');
  const [loginType, setLoginType] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // 카테고리 상세 표시
  const getCommunityCategoryDetail = async () => {
    try {
      const resp = await axios.get(`${COMMUNITY_CATEGORY}/${prevId}`);
      const result = resp.data.category;
      setSequence(result?.sequence);
      setName(result?.name);
      setNameEn(result?.nameEn);
      setNameEs(result?.nameEs);
      setDescription(result?.description);
      setDescriptionEn(result?.descriptionEn);
      setDescriptionEs(result?.descriptionEs);
      setPrivateYn(result?.privateYn);
      setConnectedCategoryIds(
        result?.connectedCategoryIds ? result?.connectedCategoryIds : [],
      );
      if (result?.connectedCategoryIds) {
        const arr: string[] = [];
        communityCategoryList.forEach((el: any) => {
          result?.connectedCategoryIds.forEach((el2: any) => {
            if (el.id === el2) arr.push(el.name);
          });
        });
        setConnectedCategoryNames(arr);
      }
      setDesignatedCommentMemberIds(
          result?.designatedCommentMemberIds ? result?.designatedCommentMemberIds : [],
      );
      setDesignatedCommentMembersCurated(
          resp.data?.designatedCommentMembers ? resp.data?.designatedCommentMembers : [],
      );
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  useEffect(() => {
    if (prevId) getCommunityCategoryDetail()
  }, []);

  // 카테고리 저장
  const addFilterCategory = async (type: string) => {
    if (!name || !nameEn || !nameEs || !privateYn) {
      alert('필요한 양식을 전부 채워주세요.');
      return;
    }

    const body = {
      sequence,
      name,
      nameEn,
      nameEs,
      description,
      descriptionEn,
      descriptionEs,
      privateYn,
      connectedCategoryIds: connectedCategoryIds?.map((el: any) => el),
      designatedCommentMemberIds: designatedCommentMemberIds?.map((el: any) => el),
    };
    try {
      if (type === 'add') {
        body.sequence = prevTotalCount + 1;
        await axios.post(COMMUNITY_CATEGORY, body);
        alert('생성 되었습니다.');
      }
      if (type === 'edit') {
        await axios.put(`${COMMUNITY_CATEGORY}/${prevId}`, body, {});
        alert('수정 되었습니다.');
      }
      navigate('/explore/communityCategory');
    } catch (err: any) {
      if (err.response.data.message) {
        alert(err.response.data.message);
        return;
      }
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 관련 카테고리 추가
  const handleTagSetData = useCallback(
    (e: any) => {
      if (e.id === prevId) {
        alert('동일 카테고리는 선택할 수 없습니다.');
        return;
      }
      // 장르가 겹칠 때
      const duplicateCheck: number = connectedCategoryNames?.indexOf(e.name);
      if (duplicateCheck !== -1) {
        const filteredNames = connectedCategoryNames?.filter(
          (el: string) => el !== e.name,
        );
        const filteredIds = connectedCategoryIds?.filter(
          (el: number) => el !== e.id,
        );
        setConnectedCategoryNames(filteredNames);
        setConnectedCategoryIds(filteredIds);
      } else {
        setConnectedCategoryNames(connectedCategoryNames.concat(e.name));
        setConnectedCategoryIds(connectedCategoryIds.concat(e.id));
      }
    },
    [connectedCategoryNames, connectedCategoryIds],
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 검색어 인코딩
  const encodeStr = encodeURI(keyword);

  // 데이터 초기화
  const resetData = () => {
    setModalOpen(false);
    onReset();
    setPageNumber(0);
    setSearchType('');
  };

  // 영상이나 비트 추가하기
  const setModal = () => {
    setModalOpen(true);
    setDesignatedCommentMembersWork(designatedCommentMembersCurated ? designatedCommentMembersCurated : []);
    searchMember();
  };

  // modal 닫기
  const closeModal = () => {
    resetData();
  };

  const userParams = {
    isRestricted,
    loginType,
    type: searchType,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    verifiedYn: '',
  };

  // user 조회
  const searchMember = async () => {
    try {
      const resp = await axios.get(SEARCH_MEMBER, {
        params: userParams,
      });
      setDesignatedCommentMembersMaster(resp.data.memberList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      if (!searchType) {
        setSearchType('I');
        userParams.type = 'I';
        setPageNumber(0);
      }
      searchMember();
    }
  };

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    setDesignatedCommentMembersCurated(designatedCommentMembersWork);
    setDesignatedCommentMembersWork([]);
    closeModal();
  };

  useEffect(() => {
    setDesignatedCommentMemberIds(designatedCommentMembersCurated.map((el: any) => el.id));
  }, [designatedCommentMembersCurated]);

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
      overflow-y: hidden;
      width: 100%;`;
    }
    return () => {
      document.body.style.cssText = '';
    };
  }, [modalOpen]);

  useEffect(() => {
    searchMember();
  }, [pageNumber, searchType]);

  useEffect(() => {
    userParams.offset = 0;
    setPageNumber(0);
    searchMember();
  }, [loginType, isRestricted,]);

  // 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (!designatedCommentMembersWork?.find((e: any) => e.id === data.id)) {
      setDesignatedCommentMembersWork([...designatedCommentMembersWork, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (el: any) => {
    const result = designatedCommentMembersWork.filter((e: any) => e.id !== el.id);
    setDesignatedCommentMembersWork(result);
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
      e: React.MouseEvent<HTMLLIElement>,
      setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addFilterCategory('add')}/>
          ) : (
            <NormalButton text="수정하기" onSubmit={() => addFilterCategory('edit')}/>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="카테고리 명"
            value={name}
            placeholder="카테고리 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="카테고리 명 EN"
            value={nameEn}
            placeholder="카테고리 EN 명을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEs"
            label="카테고리 명 ES"
            value={nameEs}
            placeholder="카테고리 ES 명을 입력해 주세요."
            onChange={(e) => setNameEs(e.target.value)}
            onReset={() => setNameEs('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="설명"
            value={description}
            placeholder="설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="설명 EN"
            value={descriptionEn}
            placeholder="설명 EN 을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEs"
            label="설명 ES"
            value={descriptionEs}
            placeholder="설명 ES 을 입력해 주세요."
            onChange={(e) => setDescriptionEs(e.target.value)}
            onReset={() => setDescriptionEs('')}
          />
        </InputWrapper>
        <InputWrapper>
          <TagMenuSelect
            name="connectedCategoryId"
            value={connectedCategoryNames}
            placeholder="동시 선택 카테고리를 선택해주세요."
            tagIds={connectedCategoryIds}
            label="동시 선택 카테고리"
            onClick={handleTagSetData}
            options={communityCategoryList}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={privateYn}
            name="privateYn"
            label="숨김 여부"
            onChange={(e: any) => setPrivateYn(e.target.value)}
            text={['공개', '비공개']}
            type="privateYn"
          />
        </InputWrapper>
        <InputWrapper>
          <InputLabel>유저 선택</InputLabel>
          <LineDiv />
          <VideoInput onClick={() => setModal()} text="댓글 표시 여부를 표시하기 위한 특정 사용자 선택" />
          <Table colList={addUserCollectionColList} className="nonListTable">
            {designatedCommentMembersCurated?.map((data: any) =>  (
                <UserRowComponent data={data}/>
            ))}
          </Table>
        </InputWrapper>
      </MainContainer>
      {/*유저 추가 모달*/}
      {modalOpen && (
          <VideoModal closeModal={closeModal} blockCloseBtn>
            <VideoModalWrapper>
              <ModalButtonWrapper>
                <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
                <NormalButton text="저장하기" onSubmit={() => saveContentList()}/>
              </ModalButtonWrapper>
              <VideoModalTitle>선택 유저</VideoModalTitle>
              {designatedCommentMembersWork?.length !== 0 && designatedCommentMembersWork ? (
                  <>
                    <TableTitle>{designatedCommentMembersWork?.length}명 유저</TableTitle>
                    <LineDiv />
                    <Table colList={addUserCollectionColList} className="nonListTable">
                      {designatedCommentMembersWork?.map((el: any) => (
                          <UserRowComponent
                              key={el.id}
                              data={el}
                              onClick={filterSelectedContent}
                              buttonType={'delete'}
                          />
                      ))}
                    </Table>
                  </>
              ) : (
                  <>
                    <VideoModalSubText>
                      선택된 유저가 없습니다. 아래 검색 후 유저를 선택해주세요.
                    </VideoModalSubText>
                    <LineDiv />
                  </>
              )}
              <SpaceDiv value="90px" />
              <VideoModalTitle>유저 검색</VideoModalTitle>
              <TitleWrapper>
                <SearchWrapper>
                  <SearchMenuSelect
                      options={searchMemberSearchTypeList}
                      onClick={handleSelect}
                      setState={setSearchType}
                  />
                  <SearchInputWrapper>
                    <SearchInput
                        value={keyword}
                        onChange={setKeyword}
                        onKeyPress={enterKeyPress}
                    />
                  </SearchInputWrapper>
                </SearchWrapper>
              </TitleWrapper>
              <PaginationWrapper>
                <TableTitle>{totalCount}명 유저</TableTitle>
                {totalCount !== 0 && (
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalCount={totalCount}
                        currentPage={pageNumber}
                        pageDispatch={pageDispatch}
                    />
                )}
              </PaginationWrapper>
              <LineDiv className="30" />
              <Table
                  colList={addUserCollectionColList}
                  thead={
                    <>
                      <ListHead title="인덱스" />
                      <ListHead title="Pid" />
                      <ListHead title="이메일" />
                      <ListSelect
                          title="가입 유형"
                          list={userTypeOptions}
                          onClick={handleSelect}
                          setState={setLoginType}
                      />
                      <ListHead title="계정 생성일" />
                      <ListHead title="벌점" />
                      <ListSelect
                          title="계정 상태"
                          list={memberRestrictOptions}
                          onClick={handleSelect}
                          setState={setIsRestricted}
                      />
                      <ListHead title="" />
                    </>
                  }
              >
                {designatedCommentMembersMaster?.map((el: RowComponentDataProps) => (
                    <UserRowComponent key={el.id} data={el} onClick={addSelectedContent} buttonType={'add'}/>
                ))}
              </Table>
            </VideoModalWrapper>
          </VideoModal>
      )}
    </>
  );
};

export default FilterCategoryDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
    grid-column: span 2;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
  }
  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(4) {
    grid-row: 4;
    grid-column: span 2;
  }
  &:nth-child(5) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(6) {
    grid-row: 6;
    grid-column: span 2;
  }
  &:nth-child(7) {
    grid-row: 7;
    grid-column: span 2;
  }
  &:nth-child(8) {
    grid-row: 8;
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-row: 9;
    grid-column: span 2;
  }
`;

const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div<CustomProps>`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;
