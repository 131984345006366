import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import SearchInput from '../../../components/common/Input/SearchInput';
import ListHead from '../../../components/UI/ListHead';
import {ADVERTISER} from '../../../constant/apiUrl';
import useInput from '../../../hooks/useInput';
import {postsPerPage} from '../../../constant/common';
import ListItem from "../../../components/common/List/ListItem";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";
import SelectModule from "../../../components/common/Select/SelectModule";
import * as commonFn from "../../../utils/commonFn";

const ModalSingleAdvertiserSelector = ({
  closeModal,
  selectedAdvertiserSetter,
}) => {
  const [masterAdvertisers, setMasterAdvertisers] = useState([]);
  const [word, setWord] = useInput('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => setPageNumber(e - 1), []);

  // 검색 파라미터
  const params = {
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  // 광고주 검색
  const getMasterAdvertisers = async () => {
    await axios.get(ADVERTISER, {params,})
    .then((resp)=>{
      setMasterAdvertisers(resp.data.adminAdvertiserList);
      setTotalCount(resp.data.count || 0);
    })
    .catch((err)=>{
      console.log(err);
    });
  };
  // 페이지 번호 바뀌면 광고주 검색
  useEffect(() => {
    getMasterAdvertisers().then();
  }, [pageNumber]);
  // 첫번째 페이지부터 검색
  const resetPageAndGetMasterAdvertisers = () => {
    if(word) {
      setPageNumber(0);
      params.offset = 0;
      getMasterAdvertisers().then();
    }
  }
  // 검색 창에서 엔터키로 광고주 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      resetPageAndGetMasterAdvertisers();
    }
  };

  // 선택된 광고주를 caller에 설정
  const saveSelectedAdvertisers = (data) => {
    selectedAdvertiserSetter(data);
    closeModal();
  };

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
      <ModalWrapper>
        <ModalButtonWrapper>
          <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
        </ModalButtonWrapper>
        <VideoModalTitle>광고주 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SelectModule
                name="type" label="" placeholder="" className='battleSearchType'
                value={'광고주 명'}
                onChange={() => {}}
                options={commonFn.transObjectToOptions({ADVERTISER_NAME: '광고주 명'})}
            />
            <SearchInputWrapper>
              <SearchInput value={word} onChange={setWord} onKeyPress={enterKeyPress}/>
            </SearchInputWrapper>
            <NormalButton text="검색" className='cancelBtn' onSubmit={resetPageAndGetMasterAdvertisers} />
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <PaginationWithTotalCount
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  totalCountPostfix={'개 광고주'}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
        {/*<LineDiv />*/}
        {masterAdvertisers.length !== 0 ? (
          <Table
            colList={[50, 90, 90]}
            thead={
              <>
                <ListHead title="번호" />
                <ListHead title="광고주 이름"/>
                <ListHead title="광고주 ID"/>
              </>
            }
          >
            {masterAdvertisers?.map((el) => (
              <tr key={el.advertiserId} onClick={() => saveSelectedAdvertisers(el)}>
                <td><ListItem text={el.advertiserId} /></td>
                <td><ListItem text={el.name} /></td>
                <td><ListItem text={el.advertiserStrId || '광고주 ID가 없습니다.'} /></td>
              </tr>

            ))}
          </Table>
        ) : (
            <SpanNoResult>일치하는 값이 없습니다.</SpanNoResult>
        )}
      </ModalWrapper>
    </VideoModal>
  );
};
export default ModalSingleAdvertiserSelector;


const ModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;
