import React, {useCallback, useState, useEffect} from "react";
import styled from "styled-components";
import axios from "axios";

//
import {useDispatch} from "react-redux";
import {resetLoading, setLoading} from "../../redux/slices/user";
import {useAppSelector} from "../../redux/store/hooks";
import {getBeatList} from "../../redux/actions/beat/beat";

//
import VideoInput from "../../components/common/Input/VideoInput";
import Table from "../../components/common/List/Table";
import VideoModal from "../../components/common/Modal/VideoModal";
import NormalButton from "../../components/common/Button/NormalButton";
import SearchMenuSelect from "../../components/common/Select/SearchMenuSelect";
import SearchInput from "../../components/common/Input/SearchInput";
import Pagination from "../../components/common/Pagination";
import ListHead from "../../components/UI/ListHead";

//
import DetailAddBeatRowComponents from "../explore/components/category/DetailAddBeatRowComponents";
import {BattleArticleModalRowComponent, SelectedVideoComponent} from "../operation/components/EventModalRowComponent";

//
import {addBeatCollectionColList} from "../../constant/explore";
import {beatSearchTypeList} from "../../constant/beat";
import {postsPerPage} from "../../constant/common";
import {battleArticleModalColList} from "../../constant/event";
import {articleSearchTypeList} from "../../constant/video";

//
import {ARTICLE} from "../../constant/apiUrl";

const beatSearchInit = {
	bpm: 0,
	minBpm: 0,
	maxBpm: 250,
	minStartDate: '',
	maxStartDate: ''
};


const ChangeBeatOfVideo = () => {
	const dispatch = useDispatch();
	
	const [article, setArticle] = useState(null);
	const [currentBeat, setCurrentBeat] = useState(null);
	const [afterBeat, setAfterBeat] = useState(null);
	
	// 모달 관련
	const [modal, setModal] = useState(0);
	const [modalKeyword, setModalKeyword] = useState('');
	const [modalSearchType, setModalSearchType] = useState('');
	const [modalPageNumber, setModalPageNumber] = useState(0);
	const [searchedArticleList, setSearchedArticleList] = useState([]);
	const [searchedArticleCount, setSearchedArticleCount] = useState(0);
	const searchedBeatList = useAppSelector(state => state.beat.beatList);
	const searchedBeatCount = useAppSelector(state => state.beat.beatTotalCount);
	const [beatSearch, setBeatSearch] = useState(beatSearchInit);
	
	const pageDispatch = useCallback((e) => setModalPageNumber(e - 1), []);
	
	const getSearchBeats = () => {
		dispatch(getBeatList({
			offset: modalPageNumber * postsPerPage,
			limit: postsPerPage,
			word: modalKeyword,
			type: modalSearchType || 'T',
			minBpm: beatSearch.minBpm,
			maxBpm: beatSearch.maxBpm,
			minStartDate: beatSearch.minStartDate,
			maxStartDate: beatSearch.maxStartDate,
			privateYn: 'N',
		}));
	}
	
	const getSearchArticles = (id) => {
		axios
			.get(ARTICLE, {params: {
					searchType: id ? 'ID' : modalSearchType,
					keyword: id ? id : modalKeyword,
					articleType: 'NR',
					privateYn: 'N',
					offset: modalPageNumber * postsPerPage,
					limit: postsPerPage,
			}}).then(res => {
				if (id && res.data.articleList.length > 0) {
					setArticle(res.data.articleList[0]);
				}
				
				setSearchedArticleList(res.data.articleList);
				setSearchedArticleCount(res.data.count);
			}).finally(() => dispatch(resetLoading()));
	}
	
	// 영상이나 비트 추가하기
	const openModal = (i) => {
		setModal(i);
		if (i === 1) getSearchArticles();
		if (i === 2) getSearchBeats();
	};
	
	const enterKeyPress = e => {
		if (e.key === 'Enter') {
			setModalPageNumber(0);
			if (modal === 1) getSearchArticles();
			if (modal === 2) getSearchBeats();
		}
	};
	
	// modal 닫기
	const closeModal = () => {
		setModalPageNumber(0);
		setModalKeyword('');
		setModalSearchType('');
		
		if (modal === 2) setBeatSearch(beatSearchInit);
		
		setModal(0);
	};
	
	const modifyArticle = () => {
		dispatch(setLoading());
		axios
			.put(`/article/${article.id}/${afterBeat.id}`)
			.then(res => {
				if (res.data) {
					alert("수정이 완료되었습니다.");
					const url = new URL(location.href);
					url.searchParams.set('id', article.id);
					location.search = url.search;
				}
				else alert("문제가 발생하였습니다. 다시 시도해주세요.");
			})
			.finally(() => dispatch(resetLoading()));
	}
	
	useEffect(() => {
		if (modal === 1) getSearchArticles();
		if (modal === 2) getSearchBeats();
	}, [modalPageNumber])
	
	useEffect(() => {
		if (article) {
			axios
			.get(`/beat/${article.beatId}/detail`)
			.then(res => {
				console.log(res.data.beat)
				setCurrentBeat(res.data.beat)
			})
			.finally(() => dispatch(resetLoading()));
		} else {
			setCurrentBeat(null);
		}
	}, [article]);
	
	useEffect(() => {
		const articleId = new URL(location.href).searchParams.get('id');
		if (articleId) getSearchArticles(articleId);
	}, []);

	const useUserBeat = () => {
		if (!article) {
			alert('먼저 영상을 선택해주세요.');
			return;
		}
		setAfterBeat({
				id: -1,
				coverImgUrl: "https://cdn.baund.com/user/3263/profile/profileImg/user_3263_profile_profileImg_20220113104736.jpg",
				title: "userBeat",
				bmPid: "baundAdmin"
			}
		);
	}

	return <>
		<MainContainer>
			<ButtonWrapper>
				{
					article && afterBeat
						? <NormalButton text='수정' onSubmit={modifyArticle} />
						: <NormalButton text='수정' className='unActiveBtn' onSubmit={() => {}} />
				}
			</ButtonWrapper>
			
			<SpaceDiv value={80} />
			<TitleWrapper>
				<TitleText>플레이 선택</TitleText>
				<VideoInput text="영상 선택" onClick={() => openModal(1)}/>
			</TitleWrapper>
			<LineDiv/>
			<SelectedVideoContainer>{
				article && <SelectedVideoComponent
					key={article.id}
					data={article}
					editable
					deleteVideo={() => setArticle(null)}
				/>
			}</SelectedVideoContainer>
			
			<SpaceDiv value={20} />
			
			<TitleWrapper>
				<TitleText>현재 비트</TitleText>
			</TitleWrapper>
			<LineDiv/>
			<Table colList={addBeatCollectionColList} className="nonListTable">{
				currentBeat && <DetailAddBeatRowComponents
					data={currentBeat}
					name="beat"
				/>
			}</Table>
			
			<SpaceDiv value={100} />
			
			<TitleWrapper>
				<TitleText>수정할 비트 선택</TitleText>
				{(article) && (<BeatSelectButtonWrapper>
					<VideoInput onClick={useUserBeat} text="사용자 비트 사용"/>
					<VideoInput onClick={() => {
						if (!article) {
							alert('먼저 영상을 선택해주세요.');
							return;
						}
						openModal(2);
					}} text="비트 선택"/>
				</BeatSelectButtonWrapper>)}

			</TitleWrapper>
			<LineDiv/>
			<Table colList={addBeatCollectionColList} className="nonListTable">{
				afterBeat && <DetailAddBeatRowComponents
					data={afterBeat}
					name="beat"
					selected
					editable
					onClick={() => setAfterBeat(null)}
				/>
			}</Table>
			
			<SpaceDiv value={80} />
		</MainContainer>
		
		
		{/* 영상 추가 모달 */}
		{modal === 1 &&
			<VideoModal closeModal={closeModal} blockCloseBtn>
				<ModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="확인" onSubmit={closeModal} className="cancelBtn"/>
					</ModalButtonWrapper>

					<ModalTitle>영상 검색</ModalTitle>
					<ModalTitleWrapper>
						<ModalSearchWrapper>
							<SearchMenuSelect
								options={articleSearchTypeList}
								onClick={(e, setState) => setState(e.currentTarget.title)}
								state={articleSearchTypeList[0]}
								setState={setModalSearchType}
							/>
							<ModalSearchInputWrapper>
								<SearchInput value={modalKeyword} onChange={e => setModalKeyword(e.target.value)} onKeyPress={enterKeyPress}/>
							</ModalSearchInputWrapper>
						</ModalSearchWrapper>
					</ModalTitleWrapper>
					<ModalPaginationWrapper>
						<TableTitle>{searchedArticleCount}개 영상</TableTitle>
						{searchedArticleCount !== 0 &&
							<Pagination
								totalCount={searchedArticleCount}
								postsPerPage={postsPerPage}
								currentPage={modalPageNumber}
								pageDispatch={pageDispatch}
							/>}
					</ModalPaginationWrapper>
					<LineDiv/>
					<Table
						colList={battleArticleModalColList}
						thead={
							<>
								<ListHead title="id"/>
								<ListHead title="썸네일"/>
								<ListHead title="memberId"/>
								<ListHead title="Pid"/>
								<ListHead title="이름"/>
								<ListHead title="비트명"/>
								<ListHead title="업로드 날짜"/>
								<ListHead title="배틀 참여 여부"/>
								<ListHead title="공개 여부"/>
								<ListHead title="상태"/>
								<ListHead title=""/>
							</>
						}
					>
						{searchedArticleList?.map((el) => (
							<BattleArticleModalRowComponent
								key={el.id}
								data={el}
								onClick={data => {
									if (data.articleType === 'BT') {
										alert('배틀에 참여했던 이력이 있는 영상은 선택할 수 없습니다.')
										return;
									}
									
									setArticle(data);
									closeModal();
								}}
							/>
						))}
					</Table>
				</ModalWrapper>
			</VideoModal>
		}
		
		{/* 비트 추가 모달 */}
		{modal === 2 &&
			<VideoModal closeModal={closeModal} blockCloseBtn>
				<ModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="확인" onSubmit={closeModal} className="cancelBtn"/>
					</ModalButtonWrapper>
					<ModalTitle>비트 검색</ModalTitle>
					<ModalTitleWrapper>
						<ModalSearchWrapper>
							<SearchMenuSelect
								options={beatSearchTypeList}
								onClick={(e, setState) => setState(e.currentTarget.title)}
								state={beatSearchTypeList[0]}
								setState={setModalSearchType}
							/>
							<ModalSearchInputWrapper>
								<SearchInput value={modalKeyword} onChange={e => setModalKeyword(e.target.value)} onKeyPress={enterKeyPress}/>
							</ModalSearchInputWrapper>
						</ModalSearchWrapper>
					</ModalTitleWrapper>
					<ModalPaginationWrapper>
						<TableTitle>{searchedBeatCount}개 비트</TableTitle>
						{searchedBeatCount !== 0 &&
							<Pagination
								totalCount={searchedBeatCount}
								postsPerPage={postsPerPage}
								currentPage={modalPageNumber}
								pageDispatch={pageDispatch}
							/>
						}
					</ModalPaginationWrapper>
					<LineDiv/>
					<Table
						colList={addBeatCollectionColList}
						thead={<>
							<ListHead title="Cover"/>
							<ListHead title="Title"/>
							<ListHead title="Pid"/>
							<ListHead title="장르"/>
							<ListHead title="BPM"/>
							<ListHead title="BeatKey"/>
							<ListHead title="공개여부"/>
							<ListHead title="공개 날짜"/>
							<ListHead title=""/>
						</>}
					>
						{searchedBeatList?.map(el =>
							<DetailAddBeatRowComponents
								key={el.id}
								data={el}
								editable
								onClick={data => {
									if (currentBeat.id === data.id) {
										alert('현재와 같은 비트를 선택하였습니다.');
										return;
									}
									
									setAfterBeat(data);
									closeModal();
								}}
							/>
						)}
					</Table>
				</ModalWrapper>
			</VideoModal>
		}
	</>;
};

export default ChangeBeatOfVideo;


const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const ButtonWrapper = styled.section`
	transition: 1s;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 10px;
	margin-bottom: 35px;
`;

const TitleWrapper = styled.section`
	transition: 1s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-bottom: ${({value}) => `${value}px`};
`;

// 모달
const ModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 60px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({theme}) => theme.boxShadow};
  background-color: ${({theme}) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme}) => theme.color.subDarkGray};
`;

const SelectedVideoContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const ModalSearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ModalSearchInputWrapper = styled.div`
  min-width: 483px;
`;

const ModalPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const BeatSelectButtonWrapper = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;
