import React from 'react';
import { useNavigate } from 'react-router-dom';

// component
import EditButton from '../../../../components/common/Button/EditButton';
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hook
import handleDate from '../../../../hooks/handleDate';

const RowComponent = ({ data, genreTabData }: any) => {
  const { beatId, beatTitle, beatCoverImgUrl, exposeEndDt, exposeStartDt } =
    data;
  const navigate = useNavigate();

  const editBeatMaker = (beat: any) => {
    navigate('/explore/allTab/upcomingBeat/edit', {
      state: { beat, genreTabData },
    });
  };

  return (
    <tr>
      <td>
        <ListItem text={beatId} />
      </td>
      <td>
        <ListCoverImg url={beatCoverImgUrl} />
      </td>
      <td>
        <ListItem text={beatTitle} />
      </td>
      <td>
        <ListItem text={handleDate(exposeStartDt)} />
      </td>
      <td>
        <ListItem text={handleDate(exposeEndDt)} />
      </td>
      <td>
        <EditButton onClick={() => editBeatMaker(data)} text="수정" />
      </td>
    </tr>
  );
};

export default RowComponent;
