import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import axios from "axios";

// constants
import {ARTICLE, BATTLE} from "../../constant/apiUrl";
import {battleTypeValues, battleStatusValues} from "../../constant/battle";
import * as commonFn from "../../utils/commonFn";

import {battleArticleModalColList, eventModalColList} from "../../constant/event";
import {mediaTypeValues, postsPerPage} from "../../constant/common";
import {beatSearchTypeList} from "../../constant/beat";
import {addBeatCollectionColList} from "../../constant/explore";
import {articleSearchTypeList} from "../../constant/video";

// redux
import {setLoading, resetLoading} from "../../redux/slices/user";
import {getBeatList} from "../../redux/actions/beat/beat";
import {useAppSelector} from "../../redux/store/hooks";

// components
import styled from "styled-components";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import RadioButton from "../../components/common/Button/RadioButton";
import DateInput from "../../components/common/Input/DateInput";
import SelectModule from "../../components/common/Select/SelectModule";
import VideoModal from "../../components/common/Modal/VideoModal";
import NormalButton from "../../components/common/Button/NormalButton";
import Table from "../../components/common/List/Table";
import SearchMenuSelect from "../../components/common/Select/SearchMenuSelect";
import SearchInput from "../../components/common/Input/SearchInput";
import Pagination from "../../components/common/Pagination";
import VideoInput from "../../components/common/Input/VideoInput";
import FileInput from "../../components/common/Input/FileInput";
import ListItem from "../../components/common/List/ListItem";
import LabelTextAreaInput from "../../components/common/Input/LabelTextAreaInput";
import ListHead from "../../components/UI/ListHead";
import DetailAddBeatRowComponents from "../explore/components/category/DetailAddBeatRowComponents";
import {
	BattleArticleModalRowComponent,
	SelectedEventRowComponent,
	SelectedVideoComponent
} from "../operation/components/EventModalRowComponent";

const battleInitValue = {
	type: 'SPECIAL',
	title: '',
	status: 'BEGIN',
	nextStatusDate: '',
	maxParticipation: 4,
	extraVotingPoint: 0,
	extraWinnerPoint: 0,
	activeYn: 'Y',
	results: [{
		memberId: 0,
		pid: '',
		name: '',
		gotVote: 0,
		gotPoint: 0,
		winnerYn: '',
	}],

	participants: [],
	beatId: 0,
	shareThumbnailImgUrl: null,
	
	pickTopVisuals: [{type: 'IMAGE', url: null, file: null}, {type: 'IMAGE', url: null, file: null}],
	showPickResultYn: 'N',
	pickStartDate: '',
	pickEndDate: '',
	
	pickDescS: '',
	pickDescSEn: '',
	pickDescL: '',
	pickDescLEn: '',
	
	pickTitle: '',
	pickTitleEn: '',
	pickSubTitle: '',
	pickSubTitleEn: '',
	pickResultText: '',
	pickResultTextEn: '',
	pickResults: [{
		memberId: 0,
		pid: '',
		name: '',
		rank: 0,
		pickCount: 0,
		rankMeta: 0
	}],

	specialBattleStartDate: '',
};

const beatSearchInit = {
	bpm: 0,
	minBpm: 0,
	maxBpm: 250,
	minStartDate: '',
	maxStartDate: ''
};

const BattleDetail = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const battleId = location?.state || 0;

	const [battle, setBattle] = useState(battleInitValue);
	const [beat, setBeat] = useState(null);
	const [articles, setArticles] = useState([]);
	const [readOnly, setReadOnly] = useState(false);

	const [coverImgUrl, setCoverImgUrl] = useState(null);
	const [coverImgFile, setCoverImgFile] = useState(null);

	// 모달 관련
	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState('');
	const [modalKeyword, setModalKeyword] = useState('');
	const [modalSearchType, setModalSearchType] = useState('');
	const [modalPageNumber, setModalPageNumber] = useState(0);

	// 모달 관련 : 비트
	const [playBeat, setPlayBeat] = useState('');
	const [beatSearch, setBeatSearch] = useState(beatSearchInit);
	const searchedBeatList = useAppSelector(state => state.beat.beatList);
	const searchedBeatCount = useAppSelector(state => state.beat.beatTotalCount);

	// 모달 관련 : 플레이
	const [searchedArticleList, setSearchedArticleList] = useState([]);
	const [searchedArticleCount, setSearchedArticleCount] = useState(0);
	
	// 모달 관련 : 픽커스
	const [pickerList, setPickerList] = useState([]);
	const [pickerCount, setPickerCount] = useState(0);
	const [selectedPickPlayer, setSelectedPickPlayer] = useState(null);
	const [isGetPicker, setIsGetPicker] = useState(true);

	const getBattleDetail = () => {
		dispatch(setLoading());
		
		axios.get(`${BATTLE}/${battleId}`, {
		}).then(res => {
			if (res?.data) {
				if (res.data.battle.type === 'SPECIAL_PICK' && (!res.data.battle?.pickTopVisuals || res.data.battle.pickTopVisuals.length === 0)) {
					res.data.battle.pickTopVisuals = [{type: 'IMAGE', url: null, file: null}, {type: 'IMAGE', url: null, file: null}];
				} else if (res.data.battle.type === 'SPECIAL_PICK' && (!res.data.battle?.pickTopVisuals || res.data.battle.pickTopVisuals.length === 1)) {
					res.data.battle.pickTopVisuals.push({type: 'IMAGE', url: null, file: null});
				}
				
				setBattle(res.data.battle);
				setBeat(res.data.beat);
				setArticles(res.data.articles);
				setCoverImgUrl(res.data.battle.coverImgUrl);
				
				// 일반배틀은 수정 못함.
				setReadOnly(res.data.battle.type === 'NORMAL');
			}
		}).catch(error => {
			console.log(error);
			error?.response?.data
				? alert(error.response.data.message)
				: alert(`${battleId ? '수정' : '저장'} 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
		}).finally(() => dispatch(resetLoading()));
	}

	const getSearchBeats = () => {
		dispatch(getBeatList({
			offset: modalPageNumber * postsPerPage,
			limit: postsPerPage,
			word: modalKeyword,
			type: modalSearchType || 'T',
			minBpm: beatSearch.minBpm,
			maxBpm: beatSearch.maxBpm,
			minStartDate: beatSearch.minStartDate,
			maxStartDate: beatSearch.maxStartDate,
			privateYn: 'N',
		}));
	}

	const getSearchArticles = () => {
		dispatch(setLoading());
		
		axios.get(ARTICLE, {params: {
			offset: modalPageNumber * postsPerPage,
			limit: postsPerPage,
			keyword: modalKeyword,
			searchType: modalSearchType || 'PID',
			articleType: 'NR',
			privateYn: 'N',
			beatId: beat.id,
		}}).then(res => {
			setSearchedArticleList(res.data.articleList);
			setSearchedArticleCount(res.data.count);
		}).catch(error => {
			console.log(error);
			error?.response?.data
				? alert(error.response.data.message)
				: alert(`${battleId ? '수정' : '저장'} 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
		}).finally(() => dispatch(resetLoading()));
	}
	
	const getPickers = (memberId) => {
		dispatch(setLoading());
		setIsGetPicker(false);
		
		axios.get(`${BATTLE}/pickers`, {params: {
			battleId: battleId,
			memberId: memberId,
			offset: modalPageNumber,
			limit: postsPerPage
		}}).then(res => {
			setPickerList(res?.data?.pickers || []);
			setPickerCount(res?.data?.count || 0);
			setModalOpen(true);
		}).catch(error => {
			console.log(error);
			error?.response?.data
				? alert(error.response.data.message)
				: alert(`${battleId ? '수정' : '저장'} 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
		}).finally(() => dispatch(resetLoading()));
	}
	
	const saveBattle = () => {
		if (readOnly) {
			alert('수정이 불가능한 상태입니다.');
			return;
		}
		
		const participantSize = articles.length;
		let valid = true;
		let message = '';
		let focus  = '';
		
		//-------------------- 일반 저장 조건 -------------------- //
		if (battle.title.length === 0) {
			valid = false;
			message = '제목을 확인해주세요.';
			focus = 'title';
		} else if (!beat?.id) {
			valid = false;
			message = '비트를 선택해주세요.';
			focus = 'beatSelect';
		} else if (articles.length === 0) {
			valid = false;
			message = '플레이는 적어도 하나 이상 선택해주세요.';
			focus = 'playSelect';
		}
		
		//-------------------- 배틀 타입에 따른 저장 조건 -------------------- //
		// 스페셜 배틀
		if (battle.type === 'SPECIAL') {
			// 픽 관련 필드들 null 처리
			Object.keys(battle).forEach(key => {
				if (key.indexOf('pick') > -1) battle[key] = null;
			});
			
			if (battle.nextStatusDate.length === 0) {
				valid = false;
				message = '다음 상태 변환 일시를 확인해주세요.';
				focus = 'nextStatusDate';
			} else if (battle.status !== 'BEGIN' && articles.length < 2) {
				valid = false;
				focus = 'status';
				message = 'SPARK나 VOTING 상태로 저장 시 영상은 두 개 이상 선택해야 합니다.';
			} else if (battle.status === 'BEGIN' && articles.length > 1) {
				valid = false;
				focus = 'status';
				message = '영상을 두 개 이상 선택했을 시 SPARK나 VOTING 상태여야 합니다.';
			} else if (participantSize === battle.maxParticipation && battle.status !== 'VOTING') {
				valid = false;
				focus = 'status';
				message = '참가자 수와 최대 참가자 수가 같을 때는 VOTING으로 설정해야합니다.';
			} else if (!battleId && !coverImgFile) {
				valid = false;
				message = '커버이미지를 선택해주세요.';
				focus = 'coverImgUrl';
			}
			else if (battle.specialBattleStartDate.length === 0) {
				valid = false;
				message = '스페셜 배틀 노출 일시를 확인해주세요.';
				focus = 'specialBattleStartDate';
			}
		}
		// 스페셜 픽
		else if (battle.type === 'SPECIAL_PICK') {
			if (battle.pickTopVisuals.length > 0) {
				// 첫번째것만 필수
				const topVisual = battle.pickTopVisuals[0];
				if ((topVisual.type === 'VIDEO' && topVisual.url.length === 0) || (topVisual.type === 'IMAGE' && topVisual.file === null)) {
					message = '스페셜 픽 생성에 필요한 필수값이 누락되었습니다.';
					focus = 'pickTopVisuals';
					valid = false;
				}
			}
			
			if (!battle.pickStartDate) {
				message = '픽 시작 일시값이 누락되었습니다.';
				focus = 'pickStartDate';
				valid = false;
			}
			
			if (!battle.pickEndDate) {
				message = '픽 종료 일시값이 누락되었습니다.';
				focus = 'pickEndDate';
				valid = false;
			}
		}
		// 일반 배틀 단독 조건
		else if (!battleId && battle.type === 'NORMAL') {
			// 픽 관련 필드들 null 처리
			Object.keys(battle).forEach(key => {
				if (key.toLowerCase().indexOf('pick') > -1) battle[key] = null;
			});
			
			if (!confirm('일반 방의 경우, 최초 생성 후에는 수정할 수 없습니다.\n계속 진행하시겠습니까?')) {
				return;
			}
		}

		if (!valid) {
			alert(message);
			const focusElement = document.getElementById(focus);                                                // 엘리먼트 지정
			window.scrollBy({top: focusElement.getBoundingClientRect().top - 100, behavior: 'smooth'}); // 이동
			focusElement.style.boxShadow = '0 0 75px #8100fa4e';                                               // 그림자 나타냄
			setTimeout(() => focusElement.style.boxShadow = 'none', 2000);                      // 그림자 끄기
			return;
		} else {
			if (!confirm(`${battleId ? '수정' : '저장'}하시겠습니까?`)) {
				return;
			}
		}

		dispatch(setLoading());
		const request = {};
		const formData = new FormData();

		// 기본 값들 세팅
		Object.keys(battle).forEach(key => {
			if (typeof battle[key] === 'string') {
				request[key] = battle[key].trim();
			} else {
				request[key] = battle[key];
			}
		});
		// 비트 세팅
		request.beatId = beat.id;
		// 플레이 세팅 (플레이가 수정된게 없어도 새로 세팅됨.)
		if (articles.length > 0) {
			const nowDate = commonFn.nowDateToSting(new Date(), true);
			request.participants = [];
			articles.forEach(article => {
				request.participants.push({
					articleId: article.id,
					memberId: article.memberId,
					participationDate: nowDate
				})
			})
		}
		
		// 일반방일때 상태값 세팅
		if (request.type === 'NORMAL') {
			request.nextStatusDate = null;
			if (participantSize === 1) {
				request.status = 'BEGIN';
			} else if (participantSize > 1 && participantSize < request.maxParticipation) {
				request.status = 'SPARK';
			} else if (participantSize === request.maxParticipation) {
				request.status = 'VOTING';
			} else {
				alert('문제가 발생하였습니다. 담당자에게 문의해주세요.');
				return;
			}
			request.specialBattleStartDate = null;
		}
		// 스페셜 배틀일때 상태값 세팅
		else if (battle.type === 'SPECIAL') {
			request.nextStatusDate = commonFn.nowDateToSting(new Date(request.nextStatusDate), true);
			request.specialBattleStartDate = commonFn.nowDateToSting(new Date(request.specialBattleStartDate), true);
			formData.append('coverImgFile', coverImgFile);
		}
		// 스페셜 픽일때 상태값 세팅
		else if (request.type === 'SPECIAL_PICK') {
			battle.pickTopVisuals.forEach((topVisual, i) => {
				request[`pickTopVisualType${i+1}`] = topVisual.type;
				if (topVisual.type === 'VIDEO' && topVisual.url) {
					request[`pickTopVisualVideoUrl${i+1}`] = topVisual.url;
				} else if (topVisual.type === 'IMAGE' && topVisual.file) {
					formData.append(`visualImgFile${i+1}`, topVisual.file);
				} else if (topVisual.type === 'IMAGE' && topVisual.url && battleId) {
					request[`pickTopVisualImageUrl${i+1}`] = topVisual.url;
				}
			});
			
			request.nextStatusDate = null;
			request.pickStartDate = commonFn.nowDateToSting(new Date(request.pickStartDate), true);
			request.pickEndDate = commonFn.nowDateToSting(new Date(request.pickEndDate), true);
			request.specialBattleStartDate = null;
		}

		// 배틀 객체 세팅
		const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
		formData.append('adminBattleRequest', blob);

		// 저장 실행
		let res;
		if (battleId) res = axios.put(`${BATTLE}/${battleId}`, formData, {});   // 수정
		else res = axios.post(`${BATTLE}`, formData, {});                       // 저장

		res.then(() => {
			alert(`${battleId ? '수정' : '저장'}이 완료되었습니다.`);
			navigate('/battle');
		}).catch(error => {
			console.log(error);
			error?.response?.data
				? alert(error.response.data.message)
				: alert(`${battleId ? '수정' : '저장'} 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
		}).finally(() => dispatch(resetLoading()));
	}
	
	// 페이지 넘김 정책
	const pageDispatch = useCallback(e => {
		if (modalType === 'pickResult' && !isGetPicker) {
			alert('picker 조회는 5초에 한번 가능합니다.');
			return;
		}
		
		setModalPageNumber(e - 1);
	}, []);
	
	// input set 함수
	const handleInputSetData = useCallback(e => {
		const {name, value} = e.target;
		setBattle({...battle, [name]: value});
	}, [battle]);
	
	// 글자 입력 제한 input set 함수
	const handleInputSetStringLimit = (e, limit) => {
		const {name, value} = e.target;
		if (String(value).length > limit) return;
		setBattle({...battle, [name]: value});
	};

	// input reset 함수
	const handleResetData = useCallback(name => {
		setBattle({...battle, [name]: battleInitValue[name]});
	}, [battle]);

	// 영상이나 비트 추가하기
	const openModal = (name, metaData) => {
		if (name === 'pickResult' && !isGetPicker) {
			alert('picker 조회는 5초에 한번 가능합니다.');
			return;
		}
		
		setModalType(name);
		setModalOpen(true);

		if (name === 'beat') getSearchBeats();
		else if (name === 'article') getSearchArticles();
		else if (name === 'pickResult' && metaData) {
			setSelectedPickPlayer(metaData);
			getPickers(metaData);
		}
	};

	// modal 닫기
	const closeModal = () => {
		setModalPageNumber(0);
		handleModalKeyword('');
		handleModalSearchType('');

		if (modalType === 'beat') setBeatSearch(beatSearchInit);
		
		setModalOpen(false);
	};

	// 엔터키로 검색
	const enterKeyPress = e => {
		if (e.key === 'Enter') {
			setModalPageNumber(0);

			if (modalType === 'beat') getSearchBeats();
			else if (modalType === 'article') getSearchArticles();
		}
	};
	
	const ImgWarning = () => (battleId > 0 && battle.status !== 'FINISH') && <ValueDesc>
		<span className='red'>이미지 수정 시 바로 반영되지않으며 각 엔드포인트마다 변경 시점이 다를 수 있습니다.</span>
		<span className='red'>(* 긴급 시 시점을 어느 정도 앞당길 수 있는 작업이 있으니 서버팀에 요청해주세요.)</span>
	</ValueDesc>

	// 초기화
	useEffect(() => {
		if (battleId) getBattleDetail();
	}, [])

	useEffect(() => {
		if (modalType === 'beat') getSearchBeats();
		else if (modalType === 'article') getSearchArticles();
		else if (modalType === 'pickResult') getPickers(selectedPickPlayer);
	}, [modalPageNumber]);

	useEffect(() => {
		if (!battleId) {
			setCoverImgFile(null);
			setCoverImgUrl(null);
			if (battle.type === 'NORMAL') {
				setBattle({
					...battle,
					nextStatusDate: null,
					pickStartDate: null,
					pickEndDate: null
				});
			} else if (battle.type === 'SPECIAL') {
				setBattle({
					...battle,
					nextStatusDate: commonFn.nowDateToSting(new Date(Date.now() + (3600 * 1000 * 48)), false),
					pickStartDate: null,
					pickEndDate: null,
					specialBattleStartDate: commonFn.nowDateToSting(new Date(Date.now() + (3600 * 1000 * 48)), false),
					status: 'VOTING'
				});
			} else if (battle.type === 'SPECIAL_PICK') {
				setBattle({
					...battle,
					status: 'SPARK',
					pickStartDate: commonFn.nowDateToSting(new Date(Date.now() + (3600 * 1000 * 48)), false),
					pickEndDate: commonFn.nowDateToSting(new Date(Date.now() + (3600 * 1000 * 96)), false)
				});
			}
		}
	}, [battle.type]);

	useEffect(() => {
		if (!isGetPicker) {
			setTimeout(() => setIsGetPicker(true), 5000);
		}
	}, [isGetPicker])
	
	// useEffect(() => console.log(battle), [battle])

	const useUserBeat = () => {
		setBeat({
			id: -1,
			coverImgUrl: "https://cdn.baund.com/user/3263/profile/profileImg/user_3263_profile_profileImg_20220113104736.jpg",
			title: "userBeat",
			bmPid: "baundAdmin"
		});
	}

	const handleModalKeyword = (keyword) => {
		if (['ID', 'MEMBER_ID', 'BPM'].includes(modalSearchType) && keyword.replace(/[0-9]/g, '')) {
			alert('숫자만 가능합니다.');
			setModalKeyword('');
			return;
		}
		setModalKeyword(keyword);
	}

	const handleModalSearchType = (modalSearchType) => {
		setModalKeyword('');
		setModalSearchType(modalSearchType);
	}

	return <>
		<MainContainer>
			<ButtonWrapper>
				<NormalButton className='cancelBtn' text="목록으로" onSubmit={() => navigate('/battle')} />
				{
					battleId === 0
						? <NormalButton text='저장' onSubmit={saveBattle} />
						: readOnly
							? <NormalButton text='수정' onSubmit={() => {}} className='unActiveBtn' />
							: <NormalButton text='수정' onSubmit={saveBattle} />
				}
			</ButtonWrapper>

			<TitleWrapper>
				<SectionTitle>배틀 기본 정보 {battleId > 0 && `[${battleId}]`}</SectionTitle>
			</TitleWrapper>
			<LineDiv mb={30}/>

			<InputWrapper id='type'>
				<SelectModule
					Required={!battleId}
					readonly={battleId}
					name="type"
					label="배틀 타입"
					placeholder="타입을 선택해주세요."
					value={battleTypeValues[battle.type]}
					onChange={e => {
						if (e.value === 'NORMAL') setBattle({...battle, type: e.value, maxParticipation: 4});
						else setBattle({...battle, type: e.value});
					}}
					options={commonFn.transObjectToOptions(battleTypeValues)}
				/>
			</InputWrapper>

			<InputWrapper id='title'>
				<LabelTextInput
					Required={!battleId}
					name="title"
					label="배틀 제목"
					placeholder="제목을 입력해주세요."
					value={battle.title}
					onChange={handleInputSetData}
					onReset={handleResetData}
				/>
			</InputWrapper>

			<InputWrapper id='maxParticipation'>
				<LabelTextInput
					Required={!battleId}
					readOnly={battle.type === 'NORMAL' || battle.type === 'NORMAL_PICK'}
					type='number'
					name="maxParticipation"
					label="최대 참가 인원"
					value={battle.maxParticipation}
					onChange={e => setBattle({...battle, maxParticipation: Number(e.target.value)})}
					min={0}
					max={20}
				/>
			</InputWrapper>
			
			{/* 배틀 타입에 따른 상태 설정 */}
			{
				// 스페셜 배틀
				battle.type === 'SPECIAL' && <InputWrapper id='status'>
					<SelectModule
						Required={!battleId}
						name="status"
						label="배틀 상태"
						placeholder="오픈 시 시작 상태를 선택해주세요."
						value={battleStatusValues[battle.status]}
						onChange={e => setBattle({...battle, status: e.value})}
						options={commonFn.transObjectToOptions({VOTING: 'VOTING',})}
						readonly={(battle.status === 'MISFIRE' || battle.status === 'FINISH')}
					/>
					<ValueDesc>
						{/*<span>BEGIN: 생성자의 영상만 있는 방 생성</span>*/}
						{/*<span>SPARK: 플레이 지정하여 생성 후 다른 회원들이 추가 지원 가능한 방 생성</span>*/}
						{/*<span>VOTING: 오픈 후 바로 투표하는 방 생성</span>*/}
						<span>VOTING: 오픈 후 바로 투표하는 방 생성. 스페셜 배틀은 이 상태만 지원 합니다.</span>
					</ValueDesc>
				</InputWrapper>
			}
			
			{/* 상태변환일시 설정 */}
			{
				battle.type === 'SPECIAL' && <InputWrapper id='nextStatusDate'>
					<DateInput
						Required={!battleId}
						name="nextStatusDate"
						label="다음 상태 변환 일시"
						initialStartDate={battle.nextStatusDate || new Date()}
						onChange={handleInputSetData}
					/>
					<ValueDesc>
						<span>VOTING: 투표 '종료' 일시 설정</span>
						<span>BEGIN, SPARK: 투표 '시작' 일시 설정</span>
					</ValueDesc>
				</InputWrapper>
			}
			{
				battle.type === 'SPECIAL_PICK' && <>
					<InputWrapper id='pickStartDate'>
						<DateInput
							Required={!battleId}
							name="pickStartDate"
							label="투표 '시작' 일시 설정"
							initialStartDate={battle.pickStartDate || new Date()}
							onChange={handleInputSetData}
						/>
					</InputWrapper>
					<InputWrapper id='pickEndDate'>
						<DateInput
							Required={!battleId}
							name="pickEndDate"
							label="투표 '종료' 일시 설정"
							initialStartDate={battle.pickEndDate || new Date()}
							onChange={handleInputSetData}
						/>
					</InputWrapper>
				</>
			}
			
			{/* 공개 여부 설정 */}
			{
				(battle.type === 'SPECIAL' || battle.type === 'SPECIAL_PICK') &&
					<InputWrapper id='activeYn'>
						<RadioButton
							Required={!battleId}
							name="activeYn"
							type="activeYn"
							label="공개 여부"
							text={['공개', '비공개']}
							value={battle.activeYn}
							onChange={e => setBattle({...battle, activeYn: e.target.value})}
						/>
					</InputWrapper>
			}
			
			{
				battle.type === 'SPECIAL' && <>

						<InputWrapper id='specialBattleStartDate'>
							<DateInput
									Required={!battleId}
									name="specialBattleStartDate"
									label="스페셜 배틀 '노출' 일시 설정"
									initialStartDate={battle.specialBattleStartDate || new Date()}
									onChange={handleInputSetData}
							/>
						</InputWrapper>

						<InputWrapper id='extraWinnerPoint'>
						<LabelTextInput
							Required={!battleId}
							type='number'
							name="extraWinnerPoint"
							label="승리자 추가 포인트"
							value={battle.extraWinnerPoint}
							onChange={handleInputSetData}
							onReset={handleResetData}
							min={0}
						/>
					</InputWrapper>

					<InputWrapper id='extraVotingPoint'>
						<LabelTextInput
							Required={!battleId}
							type='number'
							name="extraVotingPoint"
							label="투표자 추가 포인트"
							value={battle.extraVotingPoint}
							onChange={handleInputSetData}
							onReset={handleResetData}
							min={0}
						/>
					</InputWrapper>

					<InputWrapper id='coverImgUrl'>
						<FileWrapper>
							<FileInput
								type="battleCoverImg"
								placeholder={coverImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
								label="특별 배틀방 커버 이미지 (이미지 파일 및 gif 파일)"
								value={coverImgFile}
								name="coverImgUrl"
								setFile={file => setCoverImgFile(file)}
								setImgUrl={setCoverImgUrl}
								fileUrl={coverImgUrl}
								Required={!battleId}
							/>
							<div>
								<Img src={coverImgUrl ? coverImgUrl : ''}/>
								<ImgWarning/>
							</div>
						</FileWrapper>
					</InputWrapper>
				</>
			}
			
			{
				battle.type === 'SPECIAL_PICK' && <>
					<InputWrapper id='pickTopVisuals'>
						<FileWrapper2>{
							battle.pickTopVisuals.map((topVisual, i) => <div key={i}>
								<SelectModule
									name="type"
									label={`${i + 1}번째 상단 비주얼 타입 선택`}
									Required={i === 0}
									placeholder="타입을 선택해주세요."
									value={mediaTypeValues[topVisual.type]}
									onChange={e => {
										const topVisuals = [...battle.pickTopVisuals];
										topVisuals[i].type = e.value;
										topVisuals[i].url = null;
										topVisuals[i].file = null;
										setBattle({...battle, topVisuals: topVisuals})
									}}
									options={commonFn.transObjectToOptions(mediaTypeValues)}
								/>
								
								{
									topVisual.type === 'IMAGE' && <>
										<FileInput
											type="battleCoverImg"
											name={`topVisualImage${i+1}`}
											placeholder={topVisual.url ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
											value={topVisual.file}
											setFile={file => {
												const topVisuals = [...battle.pickTopVisuals];
												topVisuals[i].file = file;
												setBattle({...battle, topVisuals: topVisuals})
											}}
											setImgUrl={data => {
												const topVisuals = [...battle.pickTopVisuals];
												topVisuals[i].url = data;
												setBattle({...battle, topVisuals: topVisuals})
											}}
											fileUrl={topVisual.url}
										/>
										<Img src={topVisual.url ? topVisual.url : ''}/>
										<ImgWarning/>
									</>
								}
								
								{
									topVisual.type === 'VIDEO' && <div style={{marginTop: '9px'}}>
										<LabelTextInput
											name=''
											label=''
											placeholder='영상 url을 입력해주세요.'
											value={topVisual.url}
											onChange={e => {
												const topVisuals = [...battle.pickTopVisuals];
												topVisuals[i].url = e.target.value;
												setBattle({...battle, topVisuals: topVisuals})
											}}
										/>
									</div>
								}
							</div>)
						}</FileWrapper2>
					</InputWrapper>
				
					<InputWrapper id='pickDescS'>
						<LabelTextAreaInput
							name="pickDescS"
							label="설명 (좁은 영역)"
							value={battle.pickDescS}
							setValue={handleInputSetData}
						/>
						<LabelTextAreaInput
							name="pickDescSEn"
							label="영어 설명 (좁은 영역)"
							value={battle.pickDescSEn}
							setValue={handleInputSetData}
						/>
					</InputWrapper>
				
					<InputWrapper id='pickDescL'>
						<LabelTextAreaInput
							name='pickDescL'
							label='설명 (넓은 영역)'
							value={battle.pickDescL}
							setValue={handleInputSetData}
						/>
						<LabelTextAreaInput
							name='pickDescLEn'
							label='영어 설명 (넓은 영역)'
							value={battle.pickDescLEn}
							setValue={handleInputSetData}
						/>
					</InputWrapper>
				
					<InputHalfWrapper id='pickTitle'>
						<LabelTextInput
							name="pickTitle"
							label="아티클 타이틀 (미입력 시 기본값 : '플레이')"
							placeholder="참가자 플레이를 모아보는 타이틀입니다. (최대 20자)"
							value={battle.pickTitle}
							onChange={e => handleInputSetStringLimit(e, 20)}
						/>
						<LabelTextInput
							name="pickTitleEn"
							label="아티클 타이틀 (영어)"
							placeholder=''
							value={battle.pickTitleEn}
							onChange={e => handleInputSetStringLimit(e, 20)}
						/>
					</InputHalfWrapper>
					
					<InputHalfWrapper id='pickSubTitle'>
						<LabelTextInput
							name="pickSubTitle"
							label="아티클 서브 타이틀"
							placeholder="메인 타이틀에 설명을 적는 란입니다. (최대 40자)"
							value={battle.pickSubTitle}
							onChange={e => handleInputSetStringLimit(e, 40)}
						/>
						<LabelTextInput
							name="pickSubTitleEn"
							label="아티클 서브 타이틀 (영어)"
							placeholder=''
							value={battle.pickSubTitleEn}
							onChange={e => handleInputSetStringLimit(e, 40)}
						/>
					</InputHalfWrapper>
					
					<InputHalfWrapper id='pickResultText'>
						<LabelTextInput
							name="pickResultText"
							label="투표 결과 타이틀 (미입력 시 기본값 : '투표 결과')"
							placeholder="투표 결과 타이틀입니다. (최대 20자)"
							value={battle.pickResultText}
							onChange={e => handleInputSetStringLimit(e, 20)}
						/>
						<LabelTextInput
							name="pickResultTextEn"
							label="투표 결과 타이틀 (영어)"
							placeholder=''
							value={battle.pickResultTextEn}
							onChange={e => handleInputSetStringLimit(e, 20)}
						/>
					</InputHalfWrapper>
					
					<InputWrapper id='showPickResultYn'>
						<RadioButton
							Required={!battleId}
							name="showPickResultYn"
							type="privateYn"
							label="스페셜 픽 결과 노출 여부"
							text={['비노출', '노출']}
							value={battle.showPickResultYn}
							onChange={e => {
								setBattle({...battle, showPickResultYn: e.target.value});
								alert('투표 종료 전후 30분 동안 사용하지 마세요.');
							}}
						/>
						<ValueDesc>
							<span>*** 투표 종료 전후 30분 동안 사용하지 마세요.</span>
							<span>‘비노출’ 설정</span>
								<li>- 픽 상세페이지에서 투표 결과가 노출되지 않습니다.</li>
								<li>- 픽 결과가 자동으로 푸쉬되지 않습니다. (정산 시점 기준)</li>
							<span>‘노출’ 설정</span>
								<li>- 픽 상세페이지에서 투표 결과가 노출 됩니다.</li>
								<li>- 픽 결과 또는 종료 안내가 참가자 및 투표자들에게 자동으로 푸쉬되어 날아갑니다. (정산 시점 기준)</li>
						</ValueDesc>
					</InputWrapper>
				</>
			}
			
			<SpaceDiv value={80} />
			
			<TitleWrapper id='beatSelect'>
				<SectionTitle>비트 선택 {!battleId && <span>*</span>}</SectionTitle>
				<BeatSelectButtonWrapper>
					{battle.type === 'SPECIAL' && (<VideoInput onClick={useUserBeat} text="사용자 비트 사용"/>)}
					<VideoInput onClick={() => openModal('beat')} text="비트 선택하기"/>
				</BeatSelectButtonWrapper>
			</TitleWrapper>
			<LineDiv/>
			<Table colList={addBeatCollectionColList} className="nonListTable">
				{
					beat && <DetailAddBeatRowComponents
						data={beat}
						name="beat"
						selected
						editable
						onClick={() => {
							setBeat(null);
							setArticles([]);
						}}
					/>
				}
			</Table>

			<SpaceDiv value={80} />
			
			<TitleWrapper id='playSelect'>
				<SectionTitle>참가 플레이 선택 {!battleId && <span>*</span>}</SectionTitle>
				<VideoInput text="영상 추가하기" onClick={() => {
					if (!beat) {
						alert('먼저 비트를 선택해주세요.');
						return;
					}
					openModal('article');
				}}/>
			</TitleWrapper>
			<LineDiv/>
			<SelectedVideoContainer>
				{
					articles?.map((el) =>
						<SelectedVideoComponent
							key={el.id}
							data={el}
							editable
							deleteVideo={data => setArticles(articles.filter(e => e.id !== data.id))}
						/>
					)
				}
			</SelectedVideoContainer>

			<SpaceDiv value={80} />
			
			{
				(battle.status === 'FINISH' && battle.results?.length > 0) &&
					<ResultBox>
						<SectionTitle>결과</SectionTitle>
						<LineDiv/>
						<Table
							colList={[50, 120, 120, 50, 50, 50]}
							className='nonListTable'
							thead={<>
								<ListHead title="memberId"/>
								<ListHead title="name"/>
								<ListHead title="pid"/>
								<ListHead title="획득표수"/>
								<ListHead title="획득골드"/>
								<ListHead title="승리여부"/>
							</>}
						>
							{
								battle.results.map(result => <tr key={result.memberId} className={result.winnerYn === 'Y' ? 'winner' : ''}>
									<td><ListItem text={result.memberId} /></td>
									<td><ListItem text={commonFn.dotdotdot(result.name, 50)} /></td>
									<td><ListItem text={commonFn.dotdotdot(result.pid, 50)} /></td>
									<td><ListItem text={result.gotVote} /></td>
									<td><ListItem text={result.gotPoint} /></td>
									<td><ListItem text={result.winnerYn} /></td>
								</tr>)
							}
						</Table>
					</ResultBox>
			}
			
			{
				(battle.status === 'FINISH' && battle.pickResults?.length > 0) &&
					<ResultBox>
						<SectionTitle>결과</SectionTitle>
						<LineDiv/>
						<Table
							colList={[50, 120, 120, 50, 50, 60]}
							className='nonListTable'
							thead={<>
								<ListHead title="memberId"/>
								<ListHead title="name"/>
								<ListHead title="pid"/>
								<ListHead title="랭크"/>
								<ListHead title="획득픽수"/>
								<ListHead title="랭크메타데이터"/>
								{/*<ListHead title="픽커 확인"/>*/}
							</>}
						>
							{
								battle.pickResults.map(pickResult => <tr key={pickResult.memberId} className={pickResult.rank === 1 ? 'winner' : ''}>
									<td><ListItem text={pickResult.memberId} /></td>
									<td><ListItem text={commonFn.dotdotdot(pickResult.name, 50)} /></td>
									<td><ListItem text={commonFn.dotdotdot(pickResult.pid, 50)} /></td>
									<td><ListItem text={pickResult.rank} /></td>
									<td><ListItem text={pickResult.pickCount} /></td>
									<td><ListItem text={pickResult.rankMeta} /></td>
									{/*{pickResult.pickCount > 0 && <td>*/}
									{/*	<EditButton text="확인" className="selectBtn" onClick={() => openModal('pickResult', pickResult.memberId)}/>*/}
									{/*</td>}*/}
								</tr>)
							}
						</Table>
					</ResultBox>
			}
			
			<SpaceDiv value={80} />
		</MainContainer>

		{/* 비트 추가 모달 */}
		{(modalOpen && modalType === 'beat') &&
			<VideoModal closeModal={closeModal} className="beat" blockCloseBtn>
				<ModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="확인" onSubmit={closeModal} className="cancelBtn"/>
					</ModalButtonWrapper>
					<ModalTitle>비트 검색</ModalTitle>
					<ModalTitleWrapper>
						<ModalSearchWrapper>
							<SearchMenuSelect
								options={beatSearchTypeList}
								onClick={(e, setState) => setState(e.currentTarget.title)}
								setState={handleModalSearchType}
								state={beatSearchTypeList[0]}
							/>
							<ModalSearchInputWrapper>
								<SearchInput value={modalKeyword} onChange={e => handleModalKeyword(e.target.value)} onKeyPress={enterKeyPress}/>
							</ModalSearchInputWrapper>
						</ModalSearchWrapper>
					</ModalTitleWrapper>
					<ModalPaginationWrapper>
						<TableTitle>{searchedBeatCount}개 비트</TableTitle>
						{searchedBeatCount !== 0 &&
							<Pagination
								totalCount={searchedBeatCount}
								postsPerPage={postsPerPage}
								currentPage={modalPageNumber}
								pageDispatch={pageDispatch}
							/>
						}
					</ModalPaginationWrapper>
					<LineDiv/>
					<Table
						colList={addBeatCollectionColList}
						thead={<>
							<ListHead title="Cover"/>
							<ListHead title="Title"/>
							<ListHead title="Pid"/>
							<ListHead title="장르"/>
							<ListHead title="BPM"/>
							<ListHead title="BeatKey"/>
							<ListHead title="공개여부"/>
							<ListHead title="공개 날짜"/>
							<ListHead title=""/>
						</>}
					>
						{searchedBeatList?.map(el =>
							<DetailAddBeatRowComponents
								key={el.id}
								data={el}
								onClick={data => {
									setBeat(data);
									closeModal();
								}}
								playBeat={playBeat} setPlayBeat={setPlayBeat}
								editable
							/>
						)}
					</Table>
				</ModalWrapper>
			</VideoModal>
		}

		{/* 영상 추가 모달 */}
		{(modalOpen && modalType === 'article') &&
			<VideoModal closeModal={closeModal} blockCloseBtn>
				<ModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="확인" onSubmit={closeModal} className="cancelBtn"/>
					</ModalButtonWrapper>
					<ModalTitle>선택 영상</ModalTitle>
						{
							articles.length > 0 ? <>
								<TableTitle>{articles.length}개 영상</TableTitle>
								<LineDiv/>
								<Table colList={eventModalColList} className="nonListTable">
									{articles?.map((el) =>
										<SelectedEventRowComponent
											key={el.id}
											data={el}
											onClick={data => setArticles(articles.filter(e => e.id !== data.id))}
										/>)
									}
								</Table>
							</> : <>
								<ModalSubText>선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.</ModalSubText>
								<LineDiv/>
							</>
						}
					<SpaceDiv value={70}/>
					<ModalTitle>영상 검색</ModalTitle>
					<ModalTitleWrapper>
						<ModalSearchWrapper>
							<SearchMenuSelect
								options={articleSearchTypeList}
								onClick={(e, setState) => setState(e.currentTarget.title)}
								state={articleSearchTypeList[0]}
								setState={handleModalSearchType}
							/>
							<ModalSearchInputWrapper>
								<SearchInput value={modalKeyword} onChange={e => handleModalKeyword(e.target.value)} onKeyPress={enterKeyPress}/>
							</ModalSearchInputWrapper>
						</ModalSearchWrapper>
					</ModalTitleWrapper>
					<ModalPaginationWrapper>
						<TableTitle>{searchedArticleCount}개 영상</TableTitle>
						{searchedArticleCount !== 0 &&
							<Pagination
								totalCount={searchedArticleCount}
								postsPerPage={postsPerPage}
								currentPage={modalPageNumber}
								pageDispatch={pageDispatch}
							/>}
					</ModalPaginationWrapper>
					<LineDiv/>
					<Table
						colList={battleArticleModalColList}
						thead={
							<>
								<ListHead title="id"/>
								<ListHead title="썸네일"/>
								<ListHead title="memberId"/>
								<ListHead title="Pid"/>
								<ListHead title="이름"/>
								<ListHead title="비트명"/>
								<ListHead title="업로드 날짜"/>
								<ListHead title="배틀 참여 여부"/>
								<ListHead title="공개 여부"/>
								<ListHead title="상태"/>
								<ListHead title=""/>
							</>
						}
					>
						{searchedArticleList?.map((el) => (
							<BattleArticleModalRowComponent
								key={el.id}
								data={el}
								onClick={data => {
									if (data.privateYn === 'Y') {
										alert('비공개 영상은 추가할 수 없습니다.');
										return;
									}
									if (data.articleStatus === 'CB') {
										alert('신고되어 관리자가 공개 제한한 영상입니다.');
										return;
									}
									if (data.articleType === 'BT') {
										alert('배틀에 참여했던 이력이 있는 영상은 추가할 수 없습니다.')
										return;
									}
									if (articles?.find(a => a.memberId === data.memberId)) {
										alert('이미 동일한 회원의 영상이 있습니다.');
										return;
									}
									if (articles.length === battle.maxParticipation) {
										alert('이미 최대 참가 인원에 도달하였습니다.');
										return;
									}
									if (!articles?.find(a => a.id === data.id)) {
										if (articles.length+1 === battle.maxParticipation) {
											closeModal();
										}
										setArticles([...articles, data]);
									}
								}}
							/>
						))}
					</Table>
				</ModalWrapper>
			</VideoModal>
		}
		
		{/* 픽 결과 모달 */}
		{(modalOpen && modalType === 'pickResult' && pickerList.length > 0) &&
			<VideoModal closeModal={closeModal}>
				<ModalWrapper className="pickers">
					<ModalPaginationWrapper>
						<TableTitle>picker 총 {pickerCount}명</TableTitle>
						{pickerCount !== 0 &&
							<Pagination
								totalCount={pickerCount}
								postsPerPage={postsPerPage}
								currentPage={modalPageNumber}
								pageDispatch={pageDispatch}
							/>
						}
					</ModalPaginationWrapper>
					<Table
						colList={[50, 120, 120, 150, 50]}
						className='nonListTable'
						thead={<>
							<ListHead title="memberId"/>
							<ListHead title="name"/>
							<ListHead title="pid"/>
							<ListHead title="email"/>
							<ListHead title="pick수"/>
						</>}
					>
						{
							pickerList.map(picker =>
								<tr key={picker.memberId}>
									<td><ListItem text={picker.memberId} /></td>
									<td><ListItem text={commonFn.dotdotdot(picker.name, 50)} /></td>
									<td><ListItem text={commonFn.dotdotdot(picker.pid, 50)} /></td>
									<td><ListItem text={picker.email} /></td>
									<td><ListItem text={picker.pickCount} className='battleStatus_VOTING' /></td>
								</tr>)
						}
					</Table>
				</ModalWrapper>
			</VideoModal>
		}
	</>;
};

export default BattleDetail;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const ButtonWrapper = styled.section`
	transition: 1s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
	margin-bottom: 35px;
`;

const FileWrapper = styled.section`
  transition: 1s;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  margin-bottom: 20px;
  gap: 30px;
  grid-gap: 30px;
`;

const FileWrapper2 = styled.section`
  transition: 1s;
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-bottom: 20px;
  
  img {
    margin-top: 0;
  }
`;

const Img = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const TitleWrapper = styled.section`
	transition: 1s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`;

const InputWrapper = styled.section`
	transition: 2s;
	margin-bottom: 35px;
`;

const ValueDesc = styled.div`
  padding: 10px;
  line-height: 1.5;

  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
    
    &.red {
      color: ${({ theme }) => theme.color.mainRed};
    }
  }
  li {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
    margin-left: 20px;
  }
`;

const InputHalfWrapper = styled.section`
	transition: 2s;
	margin-bottom: 35px;

	position: relative;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 30px;
`;

const SectionTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-bottom: ${({value}) => `${value}px`};
`;

// 모달
const ModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 60px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({theme}) => theme.boxShadow};
  background-color: ${({theme}) => theme.color.mainWhite};
  
  &.pickers {
    width: 800px;
    margin-bottom: 0;
  }
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme}) => theme.color.subDarkGray};
`;

const ModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme}) => theme.color.subGray};
`;

const SelectedVideoContainer = styled.div`
  display: flex;
  overflow-x: auto;
  padding-bottom: 15px;
`;

const ModalTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const ModalSearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ModalSearchInputWrapper = styled.div`
  min-width: 483px;
`;

const ModalPaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const ResultBox = styled.div`
  h1 {
    padding: 10px;
  }

  table {
    margin-top: 20px;
  }
  
  tr.winner {
    background-color: #fdeca94a;
  }
`;

const BeatSelectButtonWrapper = styled.section`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;