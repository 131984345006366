import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import FileInput from '../../../../components/common/Input/FileInput';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import RadioButton from '../../../../components/common/Button/RadioButton';

// asset
import blankImg from '../../../../assets/images/page/blankImg.png';

// hook
import { useAppSelector } from '../../../../redux/store/hooks';

// config
import { RECENTLY_BEAT } from '../../../../constant/apiUrl';

// constant
import { recommendBeatMakerColList } from '../../../../constant/explore';

// 타입
import { RowComponentDataProps } from '../../../../definitions/beat';
import RecentlyBeatRowComponents from './RecentlyBeatRowComponents';
import Pagination from '../../../../components/common/Pagination';
import { postsPerPage } from '../../../../constant/common';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

const RecentlyBeatDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const storageNumber = localStorage.getItem('pageNumber');
  const navigate = useNavigate();
  const detailData: any = location.state;
  const adminName = useAppSelector((state) => state.user.user.name);
  const [data, setData] = useState(detailData || {});
  const [coverImgFile, setCoverImgFile] = useState<string | Blob>();
  const [editList, setEditList] = useState<any>([]);
  const {
    id,
    coverImgUrl,
    titleKo,
    titleEn,
    titleEs,
    descriptionKo,
    descriptionEn,
    descriptionEs,
    activeYn,
  } = data;

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storageNumber ? JSON.parse(storageNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const goBack = () => {
    if (detailData?.masterGenreId) {
      navigate('/explore/genreTab/recentlyBeat', {
        state: { tabType: 'GENRE', detailData },
      });
    } else {
      navigate('/explore/allTab/recentlyBeat', {
        state: { tabType: 'ALL', detailData },
      });
    }
  };

  const handleCoverSetData = useCallback(
    (e: string) => {
      setData({ ...data, coverImgUrl: e });
    },
    [data],
  );

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const handleResetData = useCallback(
    (e) => {
      setData({ ...data, [e]: '' });
    },
    [data],
  );

  // 최신비트 생성 & 수정
  const requestRecentlyBeat = async (type: string) => {
    const formData = new FormData();

    formData.append('titleKo', titleKo);
    formData.append('titleEn', titleEn);
    formData.append('titleEs', titleEs);
    formData.append('activeYn', activeYn);
    if (coverImgFile) {
      formData.append('coverImg', coverImgFile);
    } else formData.append('coverImg', '');
    if (descriptionKo && descriptionEn && descriptionEs) {
      formData.append('descriptionKo', descriptionKo);
      formData.append('descriptionEn', descriptionEn);
      formData.append('descriptionEs', descriptionEs);
    }
    // if (type === 'change') {
    //   formData.append('activeYn', activeYn);
    // }

    try {
      dispatch(setLoading());
      // 장르탭 생성
      if (type === 'add' && detailData?.masterGenreId) {
        await axios.post(
          `${RECENTLY_BEAT}/${detailData?.masterGenreId}`,
          formData,
          {
            params: { adminName, limit: 20, offset: 0 },
          },
        );
        navigate('/explore/genreTab/recentlyBeat', {
          state: { tabType: 'GENRE', detailData },
        });
        alert('저장 되었습니다.');
      }
      // 전체탭 생성
      if (type === 'add' && Object.keys(detailData).length === 0) {
        await axios.post(RECENTLY_BEAT, formData, {
          params: { adminName, limit: 20, offset: 0 },
        });
        navigate('/explore/allTab/recentlyBeat');
        alert('저장 되었습니다.');
      }
      // 장르탭 수정
      if (type === 'change' && detailData?.masterGenreId !== 0) {
        await axios.post(`${RECENTLY_BEAT}/${id}/update`, formData, {
          params: { adminName },
        });
        navigate('/explore/genreTab/recentlyBeat', {
          state: { detailData, tabType: 'GENRE' },
        });
        alert('수정 되었습니다.');
      }
      // 전체탭 수정
      if (type === 'change' && detailData?.masterGenreId === 0) {
        await axios.post(`${RECENTLY_BEAT}/${id}/update`, formData, {
          params: { adminName },
        });
        navigate('/explore/allTab/recentlyBeat');
        alert('수정 되었습니다.');
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  };

  // 최신 비트 수정에서 비트리스트 불러오기
  const getEditBeatList = async (masterGenreId: number | undefined) => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
      type: 'EXP_RT',
      masterGenreId,
    };
    if (masterGenreId === 0) {
      params.masterGenreId = undefined;
      const resp = await axios.get(`/beat/all/list`, { params });
      setEditList(resp.data.beatList);
      setTotalCount(resp.data.count || 0);
    } else {
      const resp = await axios.get(`/beat/${masterGenreId}/list`, { params });
      setEditList(resp.data.beatList);
      setTotalCount(resp.data.count || 0);
    }
  };

  useEffect(() => {
    if (detailData?.id) {
      getEditBeatList(detailData?.masterGenreId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData?.id, detailData?.masterGenreId, pageNumber]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            {coverImgUrl ? (
              <Img src={coverImgUrl} alt="coverImg" />
            ) : (
              <Img src={blankImg} alt="defaultImg" />
            )}
            <ProfileTitleWrapper>
              <Title>{titleKo}</Title>
            </ProfileTitleWrapper>
          </ProfileWrapper>

          <ButtonWrapper>
            {data.updateDt ? (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="수정하기"
                  onSubmit={() => {
                    requestRecentlyBeat('change');
                  }}
                />
              </>
            ) : (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="저장하기"
                  onSubmit={() => {
                    requestRecentlyBeat('add');
                  }}
                />
              </>
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <FileInput
            type="coverImg"
            placeholder={
              coverImgUrl ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버 이미지"
            value={coverImgFile}
            setFile={setCoverImgFile}
            setImgUrl={handleCoverSetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleKo"
            label="타이틀"
            value={titleKo}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEn"
            label="영어 타이틀"
            value={titleEn}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEs"
            label="스페인어 타이틀"
            value={titleEs}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
      </MainContainer>
      {editList.length !== 0 && (
        <>
          <LineDiv />
          <PaginationWrapper>
            <PaginationTitle>비트</PaginationTitle>
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          </PaginationWrapper>
          <Table
            colList={recommendBeatMakerColList}
            thead={
              <>
                <ListHead title="Cover" />
                <ListHead title="Title" />
                <ListHead title="Pid" />
                <ListHead title="장르" />
                <ListHead title="BPM" />
                <ListHead title="BeatKey" />
                <ListHead title="공개 여부" />
                <ListHead title="공개 날짜" />
              </>
            }
          >
            {editList.map((el: RowComponentDataProps) => (
              <RecentlyBeatRowComponents key={el.id} data={el} />
            ))}
          </Table>
        </>
      )}
    </>
  );
};

export default RecentlyBeatDetail;

const TopContainer = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  object-fit: contain;
  margin-right: 30px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  height: 45px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  //margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 60px;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
    //margin-bottom: 40px;
  }
  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
    //margin-bottom: 40px;
  }
  &:nth-child(4) {
    grid-row: 4;
    grid-column: span 2;
    margin-bottom: 50px;
  }
  &:nth-child(5) {
    grid-row: 5;
    grid-column: span 2;
  }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 46px 0;
`;

const PaginationTitle = styled.p`
  width: 26px;
  height: 18px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.subDarkGray};
`;
