import React from "react";

const ConfirmPopup = (
    message = "진행 하시겠습니까?",
    onConfirm
) => {
  if (!onConfirm || typeof onConfirm !== "function") {
    return;
  }

  return () => {
    if (window.confirm(message)) {
      onConfirm();
    }
  };
};

export default ConfirmPopup;
