//LabelTextInput의 readOnly에 해당
import React from "react";
import styled from "styled-components";

const LabelText = ({label, value}) => {

  return (
      <InputContainer>
        {label && (<InputText>{label}</InputText>)}
        <InputBox>{value}</InputBox>
      </InputContainer>
  );
};
export default LabelText;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const InputText = styled.div`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const InputBox = styled.div`
  position: relative;
  width: 100%;
  height: 55px;
  padding: 0 40px 0 20px;
  border-radius: 6px;
  background-color: #cccccc;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  line-height: 55px;

`;
