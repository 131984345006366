import React, { useState, useEffect, useCallback, useRef } from 'react';
import styled from 'styled-components';

// common
import { useDrag, useDrop } from 'react-dnd';

// constant
import dragAndDrop from '../../../assets/images/page/dragAndDrop.png';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import ListItem from '../../../components/common/List/ListItem';

const FilterList = ({ data, index, moveListItem, filterTypeList }: any) => {
  const componentRef = useRef<any>(null);

  const handleFilterName = () => {
    const arr: string[] = [];
    filterTypeList.forEach((filter: any) => {
      if (data?.studioCameraFilterTypeIds.includes(filter.id))
        arr.push(filter.name);
    });
    return arr;
  };

  const handleFileTitle = (url: string) => {
    const arr = url.split('/');
    return arr[arr.length - 1];
  };

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'item',
    hover: (item: any, monitor: any) => {
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = item.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      item.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
    (node: any) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );
  return (
    <TagRow ref={applyRef}>
      <img src={dragAndDrop} alt="dnd icon" />
      <div>
        <ListCoverImg url={data?.iconUrl} />
      </div>
      <ListItem text={data?.shaderName} />
      <ListItem text={handleFilterName().join(', ')} />
      <ListItem text={handleFileTitle(data?.shaderUrl)} />
    </TagRow>
  );
};

export default FilterList;

const TagRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 15px 0.5fr 0.7fr 1fr 0.7fr;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};

    img {
      width: 15px;
    }
  }
`;

const TagName = styled.span`
  text-align: left;
`;
const TagStartDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
const TagEndDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
