import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import dragAndDrop from '../../../../assets/images/page/dragAndDrop.png';
import ListItem from '../../../../components/common/List/ListItem';

const BaundApiEditSeqRowComponent = ({ data, moveListItem, index }: any) => {
  const componentRef = useRef<any>(null);
  const { baundApiId, name, apiUrl, description, categoryType } = data;
  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element: any, monitor: any) => {
      // const dragIndex = item.index;
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
    (node: any) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );
  return (
      <Row ref={applyRef} key={baundApiId}>
        <img src={dragAndDrop} alt="dnd icon"/>
        <ListItem text={baundApiId}/>
        <ListItem text={name}/>
        <ListItem text={apiUrl}/>
        <ListItem text={description}/>
        <ListItem text={categoryType}/>
      </Row>
  );
};

export default BaundApiEditSeqRowComponent;

const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 1fr 2fr 2fr 2fr;
  padding: 27px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};
  }
`;
