import React, {useEffect, useRef, useState} from 'react';
import styled, { css } from 'styled-components';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';
import useOutSideClick from '../../../hooks/useOutSideClick';

const SelectWithSearch = ({
  id,
  placeholder,
  value,
  label,
  onChange,
  Required,
  options,
  className
}) => {
  const [searchWord, setSearchWord] = useState('');
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const ref = useRef(null);
  // console.log('SelectWithSearch > options:', options);

  useEffect(() => {
    // console.log('SelectWithSearch > useEffect > options:', options);
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    let workSearchWord = searchWord.replace(/\s+/g, '').toLowerCase();
    searchWord
      ? setFilteredOptions(options?.filter((el) => el.title.replace(/\s+/g, '').toLowerCase().indexOf(workSearchWord) !== -1))
      : setFilteredOptions(options);
  }, [searchWord]);

  useEffect(() => {
    isDropDownOpen &&  document.getElementById("searchWord").focus();
  }, [isDropDownOpen]);

  const showOptions = () => {
    setSearchWord('');
    setIsDropDownOpen(true);
  };

  const hideOptions = () => {
    setSearchWord('');
    setIsDropDownOpen(false);
  };

  const onOptionSelected = (option) => {
    onChange(option);
    hideOptions();
  };

  // 외부 클릭시 닫기
  useOutSideClick(ref, () => {
    hideOptions();
  });

  return (
    <SelectBox id={id} className={className}>
      {label &&(
        <InputText>{label} {Required && <span>*</span>}</InputText>
      )}
      <DropDownContainer ref={ref}>
        {isDropDownOpen
          ? (
            <DropDownHeaderContainer>
              <InputBox>
                <input name="searchWord" value={searchWord} id="searchWord"
                  onChange={(e) => setSearchWord(e.target.value)}
                />
              </InputBox>
              <DropDownHeaderArrow onClick={hideOptions}/>
            </DropDownHeaderContainer>
          )
          : (
            <DropDownHeaderContainer onClick={showOptions}>
              <DropDownHeader className={className}>
                {value || <span>{placeholder}</span>}
              </DropDownHeader>
              <DropDownHeaderArrow className={"flipped"}/>
            </DropDownHeaderContainer>
          )
        }
        {isDropDownOpen && (
          <DropDownListContainer>
            <DropDownList className={className}>
              {filteredOptions && filteredOptions.map((option) => (
                <ListItem onClick={() => onOptionSelected(option)} key={Math.random()}>
                  <>{option.title}{(option.deleteYn === "Y") && ('(삭제)')}</>
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </SelectBox>
  );
};
export default SelectWithSearch;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 3;
  margin-right: 20px;
  &.widthThin {
    min-width: 300px;
    max-width: 650px;
  }

  ${(props) =>
    props.id === 'push' &&
    css`
      width: 100%;
    `}
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const DropDownContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 40px;
  border-radius: 6px;
  background: ${({ theme }) => theme.color.subBrightGray};
`;

const DropDownHeader = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 20px;
  background-size: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 52px;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: text;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  span {
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
  }
  
  &.widthThin {
    height: 45px;
    font-size: 16px;
    line-height: 48px;
  }

`;

const InputBox = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 45px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-weight: bold;
    font-size: 16px;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const DropDownHeaderArrow = styled.div`
  width: 100%;
  height: 45px;
  padding: 0 20px;
  background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
  ${({ theme }) => theme.color.subBrightGray};
  border-radius: 6px;
  cursor: pointer;
  
  &.flipped {
    transform: scaleY(-1);
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
`;

const DropDownList = styled.ul`
  margin: 0;
  max-height: 200px;
  padding: 18px 30px;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;

  :not(:last-child)&:after {
    content: '';
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin: 18px 0;
  }
`;
