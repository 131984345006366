import styled from "styled-components";
import bottomArrow from "../../../assets/images/page/bottomArrow.png";
import React, {useRef, useState} from "react";
import useOutSideClick from "../../../hooks/useOutSideClick";

const BaundProjectMenuSelect = ({
    id,
    value,
    onChange,
    options,
    placeholder,
    label,
    Required,
    className
}) => {
  // console.log("BaundProjectMenuSelect >> init >> options:" + options);

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => {
    //console.log("BaundProjectMenuSelect >> onOptionClicked >> option:" + option);
    onChange(option);
    setIsOpen(false);
  };

  // 외부 클릭시 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  return (
      <SelectBox id={id} className={className}>
        {label && (
            <InputText>{label} {Required && <span>*</span>}</InputText>
        )}
        <DropDownContainer ref={ref}>
          <DropDownHeader onClick={toggling} className={className}>
            {value || <span>{placeholder}</span>}
          </DropDownHeader>
          {isOpen && (
              <DropDownListContainer>
              <DropDownList>
                {options.map((option) => (
                    <ListItem onClick={() => onOptionClicked(option)} key={Math.random()}>
                      <>{option.title}{(option.deleteYn === "Y") && ('(삭제)')}</>
                    </ListItem>
                ))}
              </DropDownList>
              </DropDownListContainer>
          )}
        </DropDownContainer>
      </SelectBox>
  );

};

export default BaundProjectMenuSelect;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 3;
  min-width: 200px;
  margin-right: 40px;
  &.widthThin {
    min-width: 300px;
  }
  &.noMarginRight {
    margin-right: 0px;
  }
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const DropDownContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled.div`
  width: 100%;
  height: 55px;
  padding: 0 20px;
  border-radius: 6px;
  background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
    ${({ theme }) => theme.color.subBrightGray};
  background-size: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 52px;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.borderType {
    background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
      ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subGray};
  }

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
  }

  &.widthThin {
    height: 45px;
    font-size: 16px;
    line-height: 48px;
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
`;

const DropDownList = styled.ul`
  margin: 0;
  max-height: 200px;
  padding: 18px 30px;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;

  :not(:last-child)&:after {
    content: '';
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin: 18px 0;
  }
`;
