import React, {useCallback} from "react";
import styled from "styled-components";

import NormalButton from "../../../components/common/Button/NormalButton";
import {ADVERTISEMENT_CAMPAIGN,} from "../../../constant/apiUrl";
import axios from "axios";
import AdvertisementCampaignEditSeqRow from "./AdvertisementCampaignEditSeqRow";
import ListHeadDiv from "../../../components/UI/ListHeadDiv";

// 광고 목록 화면
const AdvertisementCampaignEditSeq = ({
  title,
  contentsList,
  setContentsList,
  callBack
}) => {
  const changeSequence = async () => {
    const ids = contentsList.map((el) => el.advertisementCampaignId);
    await axios.put(`${ADVERTISEMENT_CAMPAIGN}-sequence`, {ids})
    .then(()=>{
      alert('저장되었습니다.');
      callBack()
    })
    .catch (()=>{
      alert('잠시후 다시 이용해주세요.');
    });
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = contentsList[dragIndex];
      const hoverItem = contentsList[hoverIndex];

      setContentsList((tagList) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [contentsList],
  );

  return (<>
    <TitleText>{title}</TitleText>
    <TopContainer>
      <ButtonWrapper>
        <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
      </ButtonWrapper>
    </TopContainer>

    <PaginationWrapper><SpaceDiv /></PaginationWrapper>

    <MainContainer>
      <TagHead>
        <ListHeadDiv title="" />
        <ListHeadDiv title="노출 순서"/>
        <ListHeadDiv title="광고 식별 제목"/>
        <ListHeadDiv title="배너 속성" />
      </TagHead>
      {contentsList?.map((item, index) =>
        <AdvertisementCampaignEditSeqRow
            key={item.advertisementCampaignId}
            data={item}
            moveListItem={moveItem}
            index={index}
        />
      )}
    </MainContainer>
  </>);
};
export default AdvertisementCampaignEditSeq;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
  `;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 2fr 10fr 10fr;
`;