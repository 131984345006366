import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import { eventModalColList } from '../../../constant/event';
import {
  EventModalRowComponent,
  SelectedEventRowComponent,
} from './EventModalRowComponent';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import { articleSearchTypeList } from '../../../constant/video';
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import { addArticleCollectionColList } from '../../../constant/explore';
import ListHead from '../../../components/UI/ListHead';
import { ARTICLE } from '../../../constant/apiUrl';
import useInput from '../../../hooks/useInput';
import { CustomProps } from '../../../definitions/common';

const PushForArticle = ({
  closeModal,
  setSelectedModalObj,
  setSelectedModalType,
}: any) => {
  const [seletedArticleList, setSeletedArticleList] = useState<any>([]);
  const [articleList, setArticleList] = useState<any>([]);
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, keywordReset] = useInput('');

  // pagination
  const postsPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getArticleList();
    }
  };

  // 영상 리스트 불러오기
  const getArticleList = useCallback(async () => {

    if (searchType === 'ID' && keyword.replace(/[0-9]/g, '')) {
      alert('ID는 숫자만 가능합니다.');
      keywordReset();
      return;
    }

    const videoParams = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
      keyword,
      searchType,
      articleType: 'NR',
    };
    try {
      const resp = await axios.get(ARTICLE, { params: videoParams });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, pageNumber]);

  const deleteVideo = (data: any) => {
    setSeletedArticleList(
      seletedArticleList.filter((e: any) => e.id !== data.id),
    );
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const addSelectedContent = (item: any) => {
    if (seletedArticleList.length !== 0) {
      alert('영상은 하나만 선택할 수 있습니다.');
    } else setSeletedArticleList([...seletedArticleList, item]);
  };

  const saveArticle = () => {
    if (seletedArticleList.length === 0) {
      alert('최소 하나의 비트를 선택해야합니다.');
      return;
    }
    alert('저장되었습니다.');
    setSelectedModalObj(seletedArticleList[0]);
    setSelectedModalType('AR');
    closeModal();
  };

  useEffect(() => {
    getArticleList();
  }, [getArticleList]);

  useEffect(() => {
    keywordReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchType]);

  return (
    <VideoModal
      closeModal={() => {
        closeModal();
      }}
      blockCloseBtn
      blockOutSideClick
    >
      <VideoModalWrapper>
        <ModalButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={() => {
              closeModal();
            }}
            className="cancelBtn"
          />
          <NormalButton
            text="저장하기"
            onSubmit={() => {
              saveArticle();
            }}
          />
        </ModalButtonWrapper>
        <VideoModalTitle>선택 영상</VideoModalTitle>
        {seletedArticleList.length !== 0 ? (
          <>
            <TableTitle>{seletedArticleList.length}개 영상</TableTitle>
            <LineDiv />
            <Table colList={eventModalColList}>
              {seletedArticleList?.map((el: any) => (
                <SelectedEventRowComponent
                  key={el.id}
                  data={el}
                  onClick={() => deleteVideo(el)}
                />
              ))}
            </Table>
          </>
        ) : (
          <VideoModalSubText>
            선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.
            <LineDiv />
          </VideoModalSubText>
        )}
        {seletedArticleList.length === 0 && <EmptyDiv> </EmptyDiv>}
        <SpaceDiv value="90px" />
        <VideoModalTitle>영상 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={articleSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={keyword}
                onChange={setKeyword}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          <TableTitle>{totalCount}개 영상</TableTitle>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv />
        <Table
          colList={addArticleCollectionColList}
          thead={
            <>
              <ListHead title="번호" />
              <ListHead title="썸네일" />
              <ListHead title="Pid" />
              <ListHead title="이름" />
              <ListHead title="비트명" />
              <ListHead title="업로드 날짜" />
              <ListHead title="공개 여부" />
              <ListHead title="상태" />
              <ListHead title="" />
            </>
          }
        >
          {articleList?.map((el: any) => (
            <EventModalRowComponent
              key={el.id}
              data={el}
              onClick={() => addSelectedContent(el)}
            />
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

export default PushForArticle;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpaceDiv = styled.div<CustomProps>`
  height: ${({ value }) => value};
`;

const EmptyDiv = styled.div`
  margin-top: 120px;
  height: 90px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
