import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useAppSelector } from '../../redux/store/hooks';

// common
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TextButton from '../../components/common/Button/TextButton';

// hooks
import useInput from '../../hooks/useInput';

// config
import { BANNED_WORD } from '../../constant/apiUrl';

interface wordProps {
  id: number;
  word: string;
}

const BannedWordManagement = () => {
  const managerName = useAppSelector((state) => state.user.user.name);
  const [searchWord, setSearchWord] = useInput('');
  const [addWord, setAddWord] = useState('');
  const [bannedWord, setBannedWord] = useState<Array<wordProps>>([]);
  const [error, setError] = useState('');

  // pagination state
  const postsPerPage = 200;
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );

  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    word: searchWord,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const onChangeWord = useCallback((e: any) => {
    const { value } = e.target;
    setAddWord(value);
  }, []);

  // 엔터키로 금칙어 검색
  const searchEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getBannedWordData();
    }
  };

  // 금칙어 불러오기
  const getBannedWordData = async () => {
    try {
      const res = await axios.get(BANNED_WORD, {
        params,
      });
      setBannedWord(res.data.bannedWordList);
      setTotalCount(res.data.count || 0);
    } catch (err) {
      console.error(err);
    }
  };

  // 금칙어 지우기
  const deleteWord = async (el: any): Promise<void> => {
    const { id } = el;

    try {
      await axios.delete(`${BANNED_WORD}/${id}`, {
        params: { adminName: managerName },
      });
      await getBannedWordData();
    } catch (err) {
      // console.log(err);
    }
  };

  // 금칙어 추가 api호출 함수
  const addBannedWord = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const params = {
      word: addWord,
      adminName: managerName,
    };
    try {
      await axios.post(
        `${BANNED_WORD}`,
        {},
        {
          params,
        },
      );
      await getBannedWordData();
    } catch (err) {
      console.log(err);
    }
    setAddWord('');
  };

  // 금칙어 추가
  const addSelectedWord = () => {
    // 중복 체크
    const duplicateCheck: boolean = bannedWord
      ?.map((el: wordProps) => el.word)
      .includes(addWord);
    // 공백 체크
    const blankPattern = /^\s+|\s+$/g;
    const isBlank = addWord.replace(blankPattern, '') === '';
    if (duplicateCheck) {
      return setError('이미 등록된 금칙어입니다.');
    }
    if (addWord === '' || isBlank) {
      // alert('금칙어를 입력해주세요.')
      return setError('금칙어를 입력해주세요.');
    }
    setError('');
    return addBannedWord();
  };

  // 엔터키로 금칙어 저장
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addSelectedWord();
    }
  };

  useEffect(() => {
    getBannedWordData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsPerPage, pageNumber]);

  useEffect(() => {
    if (addWord === '') {
      setError('');
    }
  }, [addWord]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchInput
              value={searchWord}
              onChange={setSearchWord}
              onKeyPress={searchEnterKeyPress}
            />
          </SearchWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <AddWordWrapper>
          <LabelTextInput
            name="bannedWord"
            label="금칙어 입력"
            placeholder="추가할 금칙어를 입력하세요."
            value={addWord}
            onChange={onChangeWord}
            onKeyPress={enterKeyPress}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </AddWordWrapper>
        <BannedWordWrapper>
          {bannedWord?.map((el: wordProps) => (
            <TextButton key={el.id} data={el} onClick={deleteWord} />
          ))}
        </BannedWordWrapper>
      </MainContainer>
    </>
  );
};

export default BannedWordManagement;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 483px;
`;

const PaginationWrapper = styled.div``;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
`;

const BannedWordWrapper = styled.div`
  display: grid;
  margin-top: 75px;
  grid-template-columns: repeat(3, 1fr);
  gap: 29px 24px;
  grid-gap: 29px 24px;
`;

const AddWordWrapper = styled.div`
  position: relative;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -30px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;
