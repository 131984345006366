import React from 'react';

//컴포넌트
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hooks
import EditButton from '../../../../components/common/Button/EditButton';
import {
  GetCountryOption,
  GetLanguageOption,
  IsActive
} from "../../../../constant/common";
import handleDate from "../../../../hooks/handleDate";

const WorkMagazineRow = ({
  data,
  onClick,
  selected,
  editable,
}) => {
  const {
    id,
    bannerImgUrl,
    title,
    activeYn,
    startDt,
    endDt,
    countryCode,
    language,
  } = data;

  return (
    <tr>
      <td><ListItem text={id} /></td>
      <td><ListCoverImg url={bannerImgUrl} className="event" /></td>
      <td><ListItem type="title" text={title} /></td>
      <td><ListItem text={IsActive[activeYn]} /></td>
      <td><ListItem text={handleDate(startDt)} /></td>
      <td><ListItem text={handleDate(endDt)} /></td>
      <td><ListItem text={GetCountryOption[countryCode]} /></td>
      <td><ListItem text={GetLanguageOption[language]} /></td>
      {editable && (
        <td>
          {selected ? (
            <EditButton onClick={() => onClick(data)} text="삭제" className="deleteBtn"/>
          ) : (
             <EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>
          )}
        </td>
      )}
    </tr>
  );
};
export default WorkMagazineRow;
