import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

// common
import ListHead from '../../../../components/UI/ListHead';
import NormalButton from '../../../../components/common/Button/NormalButton';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// constant
import { EXPLORE, HOT_BEAT } from '../../../../constant/apiUrl';
import HotBeatEditSeqRowComponents from './HotBeatEditSeqRowComponents';

const HotBeatEditSeq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { detailData = {}, tabType = 'ALL' }: any = location.state || {};
  const [hotBeatList, setHotBeatList] = useState<any>([]);

  // 인기 비 리스트 받아오기
  const getHotBeatList = async () => {
    const params = {
      limit: 100,
      offset: 0,
    };
    console.log(detailData);
    try {
      if (tabType === 'GENRE') {
        const resp = await axios.get(
          `${EXPLORE}/${detailData?.masterGenreId}/hot-beat`,
          { params },
        );
        setHotBeatList(resp.data.masterRecommendHotBeatList);
      }
      if (tabType === 'ALL') {
        const resp = await axios.get(HOT_BEAT, { params });
        setHotBeatList(resp.data.masterRecommendHotBeatList);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // 순서 바꾸기
  const editHotBeatList = async () => {
    const idList = hotBeatList.map((hotBeat: any) => hotBeat.id);
    try {
      dispatch(setLoading());
      await axios.post(`${HOT_BEAT}/update/sequence`, {
        ids: idList,
      });
      alert('순서가 변경되었습니다.');

      // 전체탭 수정
      if (Object.keys(detailData).length === 0) {
        navigate('/explore/allTab/hotBeat');
        return;
      }

      // 장르탭 수정
      if (detailData?.masterGenreId !== 0) {
        navigate('/explore/genreTab/hotBeat', {
          state: { detailData, tabType },
        });
        return;
      }
    } catch (err) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    getHotBeatList();
  }, []);

  // 드래그 앤 드롭
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = hotBeatList[dragIndex];
      const hoverItem = hotBeatList[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setHotBeatList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [hotBeatList],
  );

  const setIndex = () => {
    // 드래그 드롭이 끝났을 때 이뤄지는 함수
  };

  return (
    <Container>
      <TopContainer>
        <TitleText>Admin 등록 인기 비트</TitleText>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => {
              navigate(-1);
            }}
          />
          <NormalButton text="저장하기" onSubmit={editHotBeatList} />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        <SpaceDiv />
      </PaginationWrapper>
      <Ul>
        <TagHead>
          <ListHead title="비트 아이디" />
          <ListHead title="cover" />
          <ListHead title="Title" />
          <ListHead title="시작일" />
          <ListHead title="종료일" />
        </TagHead>
        {hotBeatList?.map((item: any, index: number) => (
          <HotBeatEditSeqRowComponents
            key={item.id}
            data={item}
            moveListItem={moveItem}
            setIndex={setIndex}
            index={index}
          />
        ))}
      </Ul>
    </Container>
  );
};

export default HotBeatEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const Ul = styled.ul`
  width: 100%;
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 0.8fr 1.2fr 2fr 1.8fr 1.8fr;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 55px;
`;
