import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import SearchInput from '../../../../components/common/Input/SearchInput';
import NormalButton from '../../../../components/common/Button/NormalButton';
import checkedButton from '../../../../assets/images/page/checkedButton.png';
import SearchMenuSelect from '../../../../components/common/Select/SearchMenuSelect';
import ListDate from '../../../../components/common/List/ListDate';
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';
import RadioButton from '../../../../components/common/Button/RadioButton';
import DateInput from '../../../../components/common/Input/DateInput';

// hook
import { useAppSelector } from '../../../../redux/store/hooks';
import useInput from '../../../../hooks/useInput';

// constant
import { upcomingBeatSearchTypeList } from '../../../../constant/explore';

// 액션
import { getBeatList } from '../../../../redux/actions/beat/beat';

//config
import { EXPLORE, UPCOMING_BEAT } from '../../../../constant/apiUrl';

const UpcomingBeatAdd = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const detailData: any = location.state;
  const beatList = useAppSelector((state) => state.beat.beatList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [selectedBeat, setSelectedBeat] = useState<any>({});
  const [type, setType] = useState('');
  const [word, setWord] = useInput('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [exposeStartDt, setexposeStartDt] = useState('');
  const [exposeEndDt, setexposeEndDt] = useState('');
  const [activeYn, setActiveYn] = useState('Y');

  const params = {
    type,
    word,
    offset: 0,
    limit: 1000,
  };
  const enterKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!type) {
        setType('T');
        params.type = 'T';
      }
      dispatch(getBeatList(params));
    }
  };

  // type, bpm, privateUse, openDate
  const handleSelect = useCallback(
    (
      e: React.MouseEvent<HTMLLIElement>,
      setState: React.Dispatch<React.SetStateAction<string>>,
    ) => {
      setState(e.currentTarget.title);
    },
    [],
  );

  // activeYn 컨트롤
  const handlePrivate = useCallback((e: any) => {
    const { value } = e.target;
    setActiveYn(value);
  }, []);

  // input Date 컨트롤
  const handleInputSetData = useCallback((e: any) => {
    const { name, value } = e.target;
    if (name === 'exposeStartDt') {
      setexposeStartDt(value);
    } else {
      setexposeEndDt(value);
    }
  }, []);

  // 노출 시간 설정하기 켜지게하기
  const editSelectedList = useCallback(
    (item: any) => {
      setSelectedBeat(item);
      if (
        Object.keys(selectedBeat).length !== 0 &&
        selectedBeat?.id === item.id
      ) {
        setSelectedBeat({});
      }
    },
    [selectedBeat],
  );

  // 선택한 비트 check 표시
  const checkSelect = useCallback(
    (item: any) => {
      if (
        Object.keys(selectedBeat).length !== 0 &&
        selectedBeat?.id === item.id
      ) {
        return 'checked';
      }
      return '';
    },
    [selectedBeat],
  );

  // 전체탭 - 발매 예정 비트 저장하기
  const requestUpcomingBeat = useCallback(async () => {
    const exposeStartDate = `${exposeStartDt.replace('T', ' ')}:00`;
    const exposeEndDate = `${exposeEndDt.replace('T', ' ')}:59`;
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(exposeEndDt);

    const masterRecommendUpcomingBeatRequest = {
      activeYn,
      beatId: selectedBeat.id,
      exposeStartDate,
      exposeEndDate,
    };

    try {
      if (exposeStartDt > exposeEndDt) {
        alert('시작일시가 종료일시보다 느릴 수 없습니다.');
        return;
      }

      if (compareEndDate) {
        alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
        return;
      }

      // 장르탭 생성
      if (detailData?.masterGenreId) {
        await axios.post(
          `${EXPLORE}/${detailData?.masterGenreId}/upcoming-beat`,
          masterRecommendUpcomingBeatRequest,
          { params: { adminName } },
        );
        alert('추가되었습니다.');
        navigate('/explore/genreTab/upcomingBeat', {
          state: { detailData, tabType: 'GENRE' },
        });
      }
      // 전체탭 생성
      if (Object.keys(detailData).length === 0) {
        await axios.post(
          UPCOMING_BEAT,
          masterRecommendUpcomingBeatRequest,
          { params: { adminName } },
        );
        alert('추가되었습니다.');
        navigate('/explore/allTab/upcomingBeat');
      }
    } catch (err: any) {
      console.log(err);
    }
  }, [
    activeYn,
    adminName,
    detailData,
    exposeEndDt,
    exposeStartDt,
    navigate,
    selectedBeat.id,
  ]);

  // filter 기능
  // useEffect(() => {
  //   if (type === '') {
  //     setBeatList(
  //       beatData.filter(
  //         (item) =>
  //           item.bmPid.toLowerCase().indexOf(word) !== -1 ||
  //           item.title.toLowerCase().indexOf(word) !== -1,
  //       ),
  //     );
  //   }
  //   if (type === 'T') {
  //     setBeatList(
  //       beatData.filter(
  //         (item) => item.title.toLowerCase().indexOf(word) !== -1,
  //       ),
  //     );
  //   }
  //   if (type === 'BMP') {
  //     setBeatList(
  //       beatData.filter(
  //         (item) => item.genre.toLowerCase().indexOf(word) !== -1,
  //       ),
  //     );
  //   }
  // }, [beatData, word, type, startDate, endDate]);

  // masterBeatList 불러오기
  useEffect(() => {
    // const params = { limit: 1000, offset: 0 };
    dispatch(getBeatList(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Container>
      <SearchContainer>
        <H1>비트 검색</H1>
        <FilterContainer>
          <SearchMenuSelect
            options={upcomingBeatSearchTypeList}
            onClick={handleSelect}
            setState={setType}
          />
          <SearchInputWrapper>
            <SearchInput
              value={word}
              onChange={setWord}
              onKeyPress={enterKeyPress}
            />
          </SearchInputWrapper>
          <ListDate
            setMinStartDate={setStartDate}
            setMaxStartDate={setEndDate}
            active={!!(startDate && endDate)}
          />
        </FilterContainer>
        <Ul>
          {beatList?.map((item: any) => (
            <ListContainer onClick={() => editSelectedList(item)} key={item.id}>
              <CheckBox className={checkSelect(item)} />
              <ListCoverImgContainer>
                <ListCoverImg url={item.coverImgUrl} />
              </ListCoverImgContainer>
              <ListTitleContainer>
                <ListItem type="title" text={item.title} />
              </ListTitleContainer>
              <ListPidContainer>
                <ListItem text={item.bmPid} />
              </ListPidContainer>
            </ListContainer>
          ))}
        </Ul>
        {/*<DisplayRight>*/}
        {/*  <NormalButton*/}
        {/*    className="cancelBtn"*/}
        {/*    text="추가하기"*/}
        {/*    onSubmit={() => {*/}
        {/*      console.log('추가하기');*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</DisplayRight>*/}
      </SearchContainer>
      {Object.keys(selectedBeat).length !== 0 && (
        <SelectedContainer>
          <TitleContainer>
            <H1>노출 시간 설정</H1>
          </TitleContainer>
          <InputWrapper>
            <RadioButton
              value={activeYn}
              name="activeYn"
              label="공개 여부"
              onChange={handlePrivate}
              text={['공개', '비공개']}
              type="activeYn"
              Required
            />
          </InputWrapper>
          <InputWrapper>
            <DateInput
              label="노출기간(시작일)"
              name="exposeStartDt"
              onChange={handleInputSetData}
              initialStartDate={exposeStartDt}
            />
          </InputWrapper>
          <InputWrapper>
            <DateInput
              label="노출기간(종료일)"
              name="exposeEndDt"
              onChange={handleInputSetData}
              initialStartDate={exposeEndDt}
            />
          </InputWrapper>
          <DisplayRight>
            <NormalButton text="저장하기" onSubmit={requestUpcomingBeat} />
          </DisplayRight>
        </SelectedContainer>
      )}
    </Container>
  );
};

export default UpcomingBeatAdd;

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 461px;
  padding-right: 51px;
  border-right: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const H1 = styled.h1`
  width: 100%;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  width: calc(100% - 146px);
`;

const Ul = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 22px 0 40px 0;
  width: 100%;
  max-height: 640px;
  overflow: scroll;
`;

const ListContainer = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 69px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const CheckBox = styled.div`
  content: '';
  width: 30px;
  height: 30px;
  margin-right: 14px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 100%;

  &.checked {
    background: url(${checkedButton}) center no-repeat;
    border: 1px solid ${({ theme }) => theme.color.mainWhite};
    background-size: 28px;
  }
`;

const ListCoverImgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 72px;
  height: 69px;
`;

const ListTitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 179px;
  padding: 0 15px 0 14px;
`;

const ListPidContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 115px;
  padding: 0 15px 0 14px;
`;

const DisplayRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 60px;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-left: 51px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const InputWrapper = styled.div`
  width: 100%;

  &:nth-child(2) {
    margin-top: 50px;
  }

  &:nth-child(3) {
    margin-top: 40px;
  }

  &:nth-child(4) {
    margin-top: 40px;
  }
`;
