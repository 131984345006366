//계정 권한 설정
export const accountRoles = [
    'Super',
    'Marketing',
    'Client',
    'CS-OP',
    'Contents-Management',
    'Operation',
    'Design',
    'Front-Server',
];

export const accountSearchTypeList = [
    // { text: '전체', value: '' },
    {text: '이메일', value: 'EMAIL'},
    {text: '이름', value: 'NAME'},
];

export const accountRoleList = [
    {text: 'Super', value: 'SUPER'},
    {text: 'Marketing', value: 'MARKETING'},
    {text: 'Client', value: 'CLIENT'},
    {text: 'Design', value: 'DESIGN'},
    {text: 'Front-Server', value: 'FRONT_SERVER'},
    {text: 'Plan-Management', value: 'PLAN_MANAGEMENT'},
    {text: 'CS-OP', value: 'CS_OP'},
    {text: 'Contents-Management', value: 'CONTENTS_MANAGEMENT'},
    {text: 'Operation', value: 'OPERATION'},
];

type accountOptions = {
    [key: string]: string;
};

export const GetAccountRole: accountOptions = {
    SUPER: 'Super',
    MARKETING: 'Marketing',
    CLIENT: 'Client',
    DESIGN: 'Design',
    FRONT_SERVER: 'Front-Server',
    PLAN_MANAGEMENT: 'Plan-Management',
    CS_OP: 'CS-OP',
    CONTENTS_MANAGEMENT: 'Contents-Management',
    OPERATION: 'Operation',
};
