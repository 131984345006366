import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

//컴포넌트
import styled from 'styled-components';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import { IsPrivate } from '../../../constant/common';
import { getDetailBeat } from '../../../redux/slices/beat';
import smallPlayIcon from '../../../assets/images/page/smallPlayIcon.png';
import smallPauseIcon from '../../../assets/images/page/smallPauseIcon.png';

// 타입
import { RowComponentDataProps } from '../../../definitions/beat';

// hooks
import handleDate from '../../../hooks/handleDate';

// 인터페이스
interface dataProps {
  data: RowComponentDataProps;
  saveStorage: any;
  playBeat: any;
  setPlayBeat: any;
}

interface PlayIconProps {
  url?: string;
}

const RowComponent = ({
  data,
  saveStorage,
  playBeat,
  setPlayBeat,
}: dataProps) => {
  const {
    id,
    coverImgUrl,
    contentsUrl,
    title,
    bmPid,
    genreNames,
    bpm,
    key,
    privateYn,
    startDt,
  } = data;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const audioPlayer = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const goToDetail = (e: { data: RowComponentDataProps }) => {
    dispatch(getDetailBeat(e.data));
    saveStorage();
    navigate('/beat/list/detail');
  };

  const handleAudio = (e: any) => {
    e.stopPropagation();
  };

  const togglePlayPause = (e: any) => {
    e.stopPropagation();
    const prevValue = isPlaying;
    setPlayBeat(id);
    setIsPlaying(!prevValue);
    if (prevValue) {
      audioPlayer.current?.play();
    } else {
      audioPlayer.current?.pause();
    }
  };

  useEffect(() => {
    if (playBeat !== id) {
      setIsPlaying(true);
      audioPlayer.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playBeat]);

  return (
    <tr onClick={() => goToDetail({ data })}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg url={coverImgUrl} onClick={(e) => handleAudio(e)} />
        <AudioPlay>
          <Audio controls src={contentsUrl} ref={audioPlayer} />
          {/*<track default kind="captions" />*/}
          <PlayButton onClick={(e) => togglePlayPause(e)}>
            {isPlaying ? (
              <PlayIcon url={smallPlayIcon} className="play" />
            ) : (
              <PlayIcon url={smallPauseIcon} className="pause" />
            )}
          </PlayButton>
        </AudioPlay>
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={bmPid} />
      </td>
      <td>
        <ListItem text={genreNames.join(', ')} />
      </td>
      <td>
        <ListItem text={bpm} />
      </td>
      <td>
        <ListItem text={key} />
      </td>
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
    </tr>
  );
};

export default RowComponent;

const AudioPlay = styled.div`
  display: block;
  margin: -45px 0 0 0;
`;

const Audio = styled.audio`
  width: 0;
  height: 0;
  overflow: hidden;

  audio::-webkit-media-controls-play-button {
    -webkit-appearance: none;
  }
  audio::-internal-media-controls-download-button {
    display: none;
  }
  audio::-webkit-media-controls-enclosure {
    display: none;
  }
  audio::-webkit-media-controls-mute-button {
    -webkit-appearance: none;
  }
  audio::-webkit-media-controls-panel {
    background-color: transparent;
  }
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    color: #1e140d;
  }
`;

const PlayButton = styled.button``;

const PlayIcon = styled.div<PlayIconProps>`
  width: 44px;
  height: 44px;
  &.play {
    &:hover {
      background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
      background-size: 12px;
      border-radius: 3px;
    }
  }

  &.pause {
    background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
    background-size: 12px;
    border-radius: 3px;
  }
`;
