import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { postsPerPage } from '../../constant/common';

import VideoModal from '../../components/common/Modal/VideoModal';
import NormalButton from '../../components/common/Button/NormalButton';
import Table from '../../components/common/List/Table';
import Pagination from '../../components/common/Pagination';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import { COUPONS } from "../../constant/apiUrl";

interface CouponPopupParams {
  issuanceTypeValue: string;
  couponProjectId: number;
  onClose: any;
  titleName: string;
  code?: string;
}

interface SearchParams {
  issuanceType: string;
  parentId: number;
  usedYn: string;
  offset: number;
  limit: number;
}

interface IssuanceCoupon {
  masterCouponId: number;
  memberCouponId: number;
  couponName: string;
  pid: string;
  memberCreateDateTime: string;
  memberCouponCreateDateTime: string;
  masterCouponCreateDateTime: string;
  couponStartDateTime: string;
  couponEndDateTime: string;
  value: string;
  code: string;
  usedYn: string;
}

const issuanceTypeObjList = [
  { key: '갯수 제한', value: 'GENERATE' },
  { key: '시리얼 설정', value: 'DOWNLOAD' },
];

const convertIssuanceTypeValueToKey = (issuanceTypeValue: string) => {
  const issuanceTypeObj = issuanceTypeObjList.find((typeObj) =>
      typeObj.value === issuanceTypeValue);
  if (!issuanceTypeObj) {
    alert('데이터를 조회하던 중 에러가 발생하였습니다.');
    return null;
  }
  return issuanceTypeObj.value;
};

// 쿠폰 프로젝트에서 사용한 쿠폰에 대한 데이터 목록 조회 팝업
export const CouponProjectModal = ({
  couponProjectId,
  issuanceTypeValue,
  titleName,
  code,
  onClose,
}: CouponPopupParams) => {
  const dispatch = useDispatch();
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [projectCouponList, setProjectCouponList] = useState([]);
  const [issuanceCouponList, setIssuanceCouponList] = useState([]);
  const [issuanceType, setIssuanceType] = useState(
    convertIssuanceTypeValueToKey(issuanceTypeValue),
  );
  const [usedYn, setUsedYn] = useState('N');
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);
  // params
  const searchParams = {
    issuanceType,
    couponProjectId,
    usedYn,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  // 쿠폰 프로젝트에 생성된 쿠폰 데이터 조회 하기
  const getProjectCoupons = async (params: SearchParams) => {
    return axios
      .get(`${COUPONS}/projects/${couponProjectId}/issuance/coupons`, {
        params,
      })
      .then((response) => {
        return response.data;
      })
      .catch((reason) => console.error(`catch: ${reason}`));
  };

  // 쿠폰 프로젝트에서 생성된 쿠폰 데이터 세팅하기
  const setCouponDataList = async (params: SearchParams) => {
    await getProjectCoupons(params)
      .then((data) => {
        setIssuanceCouponList(data.issuanceCouponList);
        setTotalCount(data.issuanceCount || 0);
        if (data.issuanceCouponList.length > 0) {
          const convertedProjectCouponList: IssuanceCoupon[] =
            data.issuanceCouponList.map((issuanceCoupon: IssuanceCoupon) => {
              return {
                masterCouponId: issuanceCoupon.masterCouponId,
                name: issuanceCoupon.couponName,
                pid: issuanceCoupon.pid,
                memberCreateDateTime: issuanceCoupon.memberCreateDateTime,
                memberCouponCreateDateTime:
                  issuanceCoupon.memberCouponCreateDateTime,
                masterCouponCreateDateTime:
                  issuanceCoupon.masterCouponCreateDateTime,
                couponStartDateTime: issuanceCoupon.couponStartDateTime,
                couponEndDateTime: issuanceCoupon.couponEndDateTime,
                value: issuanceCoupon.value,
                code: issuanceCoupon.code,
                usedYn: issuanceCoupon.usedYn,
                memberCouponId: issuanceCoupon.memberCouponId,
              };
            });
          setProjectCouponList(convertedProjectCouponList);
        }
      })
      .catch((e) => {
        alert('쿠폰 데이터를 조회하던 중 문제가 발생 하였습니다.');
      })
      .catch((reason) => console.error(reason));
  };
  // 페이지 변경시 실행. pageDispatch(?)로 화면 진입시 1회 실행됨
  useEffect(() => {
    setCouponDataList(searchParams).then(() => {});
  }, [pageNumber]);

  // modal 닫기
  const closeModal = () => {
    setPageNumber(0);
    setProjectCouponList([]);
    setIssuanceCouponList([]);
    onClose(false);
  };
  const convertUtcToLocalTimeZone = (targetDateTime: string) => {
    if (!targetDateTime) return '';
    // yyyy-MM-ddTHH:mm:ss 패턴으로 입력되는 타입이므로
    // yyyy-MM-ddTHH:mm:ssZ 로 utc 기준 시간이란 것을 표현
    const utcTargetDateTime = new Date(`${targetDateTime}Z`);
    return utcTargetDateTime.toLocaleString().substring(0, 11);
  };

  const convertCouponPeriod = (startDateTime: string, endDateTime: string) => {
    return `${
      startDateTime ? convertUtcToLocalTimeZone(startDateTime) : ''
    } ~ ${endDateTime ? convertUtcToLocalTimeZone(endDateTime) : ''}`;
  };
  const copyCode = (targetCode: string) => {
    try {
      // navigator.clipboard.writeText(createdCode);
      const textArea = document.createElement("textarea");
      textArea.id = String(couponProjectId);
      textArea.value = targetCode;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (e) {
      alert(`코드 복사 중 에러가 발생했습니다.${e}`);
    }
    alert('코드 복사 완료');
  };

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn>
      <VideoModalWrapper>
        <TopContainer>
          <VideoModalTitle>
            {titleName}
          </VideoModalTitle>
          <ModalButtonWrapper>
            {issuanceType === 'DOWNLOAD' && code && (
              <NormalButton text={code} onSubmit={() => copyCode(code)} />
            )}
            <NormalButton
              text="취소"
              className="cancelBtn"
              onSubmit={closeModal}
            />
          </ModalButtonWrapper>
        </TopContainer>

        {/*페이징 표시 영역*/}
        <PaginationWrapper>
          <TableTitle>{totalCount}개</TableTitle>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv className="30" />

        {/*검색 결과 표시 영역*/}
        <Table
          colList={[50, 80, 50, 80, 80, 80, 80, 80]}
          thead={
            <>
              <ListHead title="NO" />
              <ListHead title="등록 계정" />
              <ListHead title="사용 여부" />
              <ListHead title="가입 일자" />
              <ListHead title="등록 일자" />
              <ListHead title="발급 일자" />
              <ListHead title="사용 유효기간" />
              <ListHead title="쿠폰 가격" />
            </>
          }
        >
          {projectCouponList?.map((projectCoupon: IssuanceCoupon) => (
            <tr
              style={{ height: `${40}px` }}
              key={projectCoupon.memberCouponId}
            >
              <td>
                <ListItem text={projectCoupon.memberCouponId} />
              </td>
              <td>
                <ListItem text={projectCoupon.pid} />
              </td>
              <td>
                <ListItem text={projectCoupon.usedYn} />
              </td>
              <td>
                <ListItem
                  text={convertUtcToLocalTimeZone(
                    projectCoupon.memberCreateDateTime,
                  )}
                />
              </td>
              <td>
                <ListItem
                  text={convertUtcToLocalTimeZone(
                    projectCoupon.memberCouponCreateDateTime,
                  )}
                />
              </td>
              <td>
                <ListItem
                  text={convertUtcToLocalTimeZone(
                    projectCoupon.masterCouponCreateDateTime,
                  )}
                />
              </td>
              <td>
                <ListItem
                  text={convertCouponPeriod(
                    projectCoupon.couponStartDateTime,
                    projectCoupon.couponEndDateTime,
                  )}
                />
              </td>
              <td>
                <ListItem text={projectCoupon.value} />
              </td>
            </tr>
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    & {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
