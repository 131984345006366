import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import styled from "styled-components";
import axios from "axios";

import {languageOptions, postsPerPage as limit} from "../../../constant/common";
import {DATAS_MASTER_MESSAGE} from "../../../constant/apiUrl";

import SearchInput from "../../../components/common/Input/SearchInput";
import RoundButton from "../../../components/common/Button/RoundButton";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import ListItem from "../../../components/common/List/ListItem";
import EditButton from "../../../components/common/Button/EditButton";
import NormalButton from "../../../components/common/Button/NormalButton";
import Modal from "../../../components/common/Modal/Modal";
import Pagination from "../../../components/common/Pagination";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";

import handleDate from "../../../hooks/handleDate";
import {dotdotdot} from "../../../utils/commonFn";
import {setLoading, resetLoading} from "../../../redux/slices/user";

const MasterMessage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const [masterMessageList, setMasterMessageList] = useState([]);
	const [locale, setLocale] = useState('all');
	const [search, setSearch] = useState('');
	const [offset, setOffset] = useState(0);
	const [detail, setDetail] = useState(null);
	const [totalCount, setTotalCount] = useState(0);
	
	const pageDispatch = useCallback((e) => setOffset(e - 1), []);
	
	const getMasterMessage = (locale, offset, search) => {
		dispatch(setLoading());
		axios
			.get(`${DATAS_MASTER_MESSAGE}`, {params: {locale, offset, search}})
			.then(res => {
				setMasterMessageList(res.data.masterMessages || []);
				setTotalCount(res.data.totalCount);
			})
			.finally(() => dispatch(resetLoading()))
	}
	
	const modifyMM = () => {
		dispatch(setLoading());
		axios
			.put(`${DATAS_MASTER_MESSAGE}`, {masterMessage: detail})
			.then(() => {
				alert('수정되었습니다.');
				setDetail(null);
				getMasterMessage();
			})
			.finally(() => dispatch(resetLoading()))
	}
	
	const deleteMM = (id) => {
		dispatch(setLoading());
		if (!confirm('삭제하시겠습니까?')) {
			dispatch(resetLoading());
			return;
		}
		
		axios
			.delete(`${DATAS_MASTER_MESSAGE}/${id}`)
			.then(() => {
				alert('삭제되었습니다.');
				setDetail(null);
				getMasterMessage();
			})
			.finally(() => dispatch(resetLoading()))
	}
	
	// 엔터키로 검색
	const enterKeyPress = e => {
		if (e.key === 'Enter') {
			setOffset(0);
			getMasterMessage(locale, offset, search);
		}
	};
	
	useEffect(() => getMasterMessage(locale, offset, search), [locale, offset]);
	
	return <>
		<TopContainer>
			<SearchWrapper>
				{languageOptions.map(obj =>
					<RoundButton
						key={obj.name}
						title={obj.name}
						selected={locale === obj.value}
						onClick={() => setLocale(obj.value)}
					/>
				)}
				<ButtonWrapper>
					<SearchInput value={search} onChange={e => setSearch(e.target.value)} onKeyPress={enterKeyPress}/>
				</ButtonWrapper>
				<NormalButton text="마스터 메시지 추가" onSubmit={() => navigate('/datas/masterMessage/add')}/>
			</SearchWrapper>
		</TopContainer>
		
		<LineDiv mb={40}/>
		
		<MainContainer>
			<PaginationWrapper>
				{totalCount !== 0 &&
					<Pagination
						totalCount={totalCount}
						postsPerPage={limit}
						currentPage={offset}
						pageDispatch={pageDispatch}
					/>
				}
			</PaginationWrapper>
			
			<Table thead={<>
				<ListHead title="id" rowWidth={40} />
				<ListHead title="언어" rowWidth={50} />
				<ListHead title="키" rowWidth={200} />
				<ListHead title="제목" rowWidth={100} />
				<ListHead title="내용" rowWidth={300} />
				<ListHead title="생성일시" rowWidth={120} />
				<ListHead title="수정" rowWidth={50} />
			</>}>
				{masterMessageList?.map(mm => <tr key={mm.masterMessageId}>
					<td><ListItem text={mm.masterMessageId} /></td>
					<td><ListItem text={mm.locale} /></td>
					<td><ListItem text={mm.messageKey} /></td>
					<td><ListItem text={dotdotdot(mm.messageTitle, 20)} /></td>
					<td><ListItem text={dotdotdot(mm.messageContents, 40)} /></td>
					<td><ListItem text={handleDate(mm.createDate)} /></td>
					<td><EditButton text="수정" onClick={() => setDetail(mm)}/></td>
				</tr>)}
			</Table>
		</MainContainer>
		
		<LineDiv mb={70}/>
		
		{
			detail && <Modal closeModal={() => setDetail(null)} option={{class: 'compact'}}>
				<ModalWrapper>
					<LabelTextInput
						label='언어'
						value={detail.locale}
						name='masterMessage'
						onChange={e => setDetail({...detail, locale: e.target.value})}
						Required
					/>
					<LabelTextInput
						label='키'
						value={detail.messageKey}
						name='masterMessage'
						onChange={e => setDetail({...detail, messageKey: e.target.value})}
						Required
					/>
					<LabelTextInput
						label='제목'
						value={detail.messageTitle}
						name='masterMessage'
						onChange={e => setDetail({...detail, messageTitle: e.target.value})}
					/>
					<LabelTextInput
						label='내용'
						value={detail.messageContents}
						name='masterMessage'
						onChange={e => setDetail({...detail, messageContents: e.target.value})}
						Required
					/>
					<DetailButtonWrapper>
						<NormalButton text="수정" onSubmit={() => modifyMM()}/>
						<NormalButton text="삭제" onSubmit={() => deleteMM(detail.masterMessageId)} className='deleteBtn' />
					</DetailButtonWrapper>
				</ModalWrapper>
			</Modal>
		}
	</>;
};

export default MasterMessage;

const TopContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  flex: 1;
  gap: 10px;
  margin-right: 20px;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
`;

const MainContainer = styled.div`
  width: 100%;
`;

const PaginationWrapper = styled.div`
  margin-bottom: 30px;
`;

const DetailButtonWrapper = styled.div`
	display: flex;
	gap: 15px;
	justify-content: center;
`;

const ModalWrapper = styled.div`
	width: 600px;
`;