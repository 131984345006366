import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import {rewardCurrencyTypeList, missionRewardTypeList,} from '../../constant/mission';

// 컴포넌트
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import RadioButton from '../../components/common/Button/RadioButton';

// config
import { MISSIONS } from '../../constant/apiUrl';
import { resetLoading, setLoading } from '../../redux/slices/user';
import TextField from '../../components/common/TextField';
import SelectModule from '../../components/common/Select/SelectModule';
import {missionConditionList} from "../../constant/mission";
import CommonCondition from "./CommonCondition";
import MultiLabelTextInput from "../../components/common/Input/MultiLabelTextInput";
import DateInput from "../../components/common/Input/DateInput";
import FileInput from "../../components/common/Input/FileInput";

const MissionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [missionName, setMissionName] = useState('');
  const [missionDescription, setMissionDescription] = useState('');
  const [missionNameEn, setMissionNameEn] = useState('');
  const [missionDescriptionEn, setMissionDescriptionEn] = useState('');
  const [privateYn, setPrivateYn] = useState('');
  const [conditionCount, setConditionCount] = useState(0);
  const [conditionList, setConditionList] = useState([]);
  const [wordCount, setWordCount] = useState(0);
  const [wordCountActiveYn, setWordCountActiveYn] = useState('');
  const [excludeSameContentsPeriod, setExcludeSameContentsPeriod] = useState(0);
  const [excludeSameContentsPeriodActiveYn, setExcludeSameContentsPeriodActiveYn] = useState('');
  const [excludeOwnerContentsActiveYn, setExcludeOwnerContentsActiveYn] = useState('');
  const [onlyOwnerContentsActiveYn, setOnlyOwnerContentsActiveYn] = useState('');
  const [secondsCount, setSecondsCount] = useState(0);
  const [secondsCountActiveYn, setSecondsCountActiveYn] = useState('');
  const [rewardType, setRewardType] = useState('');
  const [rewardTypeKey, setRewardTypeKey] = useState('');
  const [rewardCurrencyType, setRewardCurrencyType] = useState('');
  const [rewardCurrencyTypeKey, setRewardCurrencyTypeKey] = useState('');
  const [rewardValue, setRewardValue] = useState('');
  const [createDate, setCreateDate] = useState('');
  const [maxCompensateCount, setMaxCompensateCount] = useState(0);
  const [exposeLimitDays, setExposeLimitDays] = useState(0);
  const [iconImgUrl, setIconImgUrl] = useState('');
  const [iconImgFile, setIconImgFile] = useState(null);
  const [exposeLimitDate, setExposeLimitDate] = useState(new Date());
  const getDetail = useCallback(async () => {
    const resp = await axios.get(`${MISSIONS}/${prevId}`);
    const data = resp.data.mission;
    setMissionName(data.missionName);
    setMissionNameEn(data.missionNameEn);
    setPrivateYn(data.privateYn);
    setConditionCount(
        data.conditionCount === 0
        ? 0
        : data.conditionCount,
    );
    setRewardType(data.rewardType);
    missionRewardTypeList.forEach((typeObj) => {
      if (typeObj.value === data.rewardType) {
        setRewardTypeKey(typeObj.text);
      }
    });
    setRewardCurrencyType(data.rewardCurrencyType);
    rewardCurrencyTypeList.forEach((typeObj) => {
      if (typeObj.value === data.rewardCurrencyType) {
        setRewardCurrencyTypeKey(typeObj.text);
      }
    });
    setRewardValue(data.rewardValue === 0 ? '0' : data.rewardValue);
    setCreateDate(data.createDate);
    setMaxCompensateCount(
      data.maxCompensateCount === 0 ? '0' : data.maxCompensateCount,
    );
    setMissionDescription(data.missionDescription);
    setMissionDescriptionEn(data.missionDescriptionEn);
    setConditionList(data.conditionList);
    data.conditionList.forEach((conditionData) => {
      const { count } = conditionData;
      const { activeYn } = conditionData;
      switch (conditionData.conditionType) {
        case 'WORD_COUNT':
          setWordCount(count);
          setWordCountActiveYn(activeYn);
          break;
        case 'SECONDS_COUNT':
          setSecondsCount(count);
          setSecondsCountActiveYn(activeYn);
          break;
        case 'EXCLUDE_OWNER_CONTENTS':
          setExcludeOwnerContentsActiveYn(activeYn);
          break;
        case 'EXCLUDE_SAME_CONTENTS_PERIOD':
          setExcludeSameContentsPeriod(count);
          setExcludeSameContentsPeriodActiveYn(activeYn);
          break;
        case 'ONLY_OWNER_CONTENTS':
          setOnlyOwnerContentsActiveYn(activeYn);
          break;
        default:
          break;
      }
    });
      setExposeLimitDays(data.exposeLimitDays || exposeLimitDays);
      const initDate = new Date(data.exposeLimitDate ? data.exposeLimitDate : exposeLimitDate).toISOString();
      setExposeLimitDate(initDate.split('T')[0]);
      setIconImgUrl(data.iconImgUrl);
  }, [prevId]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

    // 필수 파라미터 체크
    const paramsCheck = () => {
        if (!missionName || missionName === '') {
            alert('미션 명을 작성 해 주세요!');
            return false;
        }
        if (!missionNameEn || missionNameEn === '') {
            alert('미션 영문 명을 작성 해 주세요!');
            return false;
        }
        if (!missionDescription || missionDescription === '') {
            alert('미션 설명을 작성 해 주세요!');
            return false;
        }
        if (!missionDescriptionEn || missionDescriptionEn === '') {
            alert('미션 영문 설명을 작성 해 주세요!');
            return false;
        }
        if (!privateYn) {
            alert('비공개 여부를 선택 해 주세요!');
            return false;
        }
        if (conditionCount <= 0) {
            alert('보상을 받는데 필요한 미션 달성 횟수는 1 이상의 값을 입력 해 주세요!');
            return false;
        }
        if (!rewardType || rewardType === '') {
            alert('보상 유형을 선택 해 주세요!');
            return false;
        }
        if (!rewardCurrencyType || rewardCurrencyType === '') {
            alert('보상 재화 유형을 선택 해 주세요!');
            return false;
        }
        if (rewardValue < 0) {
            alert('보상 재화 지급 량은 최소 0 이상의 값을 입력 해 주세요!');
            return false;
        }
        if (maxCompensateCount <= 0) {
            alert('보상 재화 지급 량은 최소 1 이상의 값을 입력 해 주세요!');
            return false;
        }
        // // 최대 보상 지급 횟수 증가시, 미션 수행 횟수는 그대로인 경우 예외
        // if (maxCompensateCount !== originMaxCompensateCount && conditionCount === originConditionCount) {
        //     alert(`최대 보상 지급 횟수를 변경 한 경우 보상을 받기 위한 미션 달성 횟수도 같이 변경 해 주세요.`);
        //     return false;
        // }
        if (!conditionList || conditionList.length < 0) {
            alert('미션 조건 목록을 저장하는 도중 문제가 발생 했습니다. 문제가 지속되면 새로고침 혹은 담당자에게 문의 해 주세요');
            return false;
        }
        if (conditionList) {
            conditionList.forEach((condition) => {
                if(!condition.activeYn) {
                    alert(`${missionConditionList.find((el) => el.text === condition.conditionType).value} 조건의 활성화 여부 값을 선택 해 주세요!`);
                    return false;
                }
                if(condition.count && condition.count < 0) {
                    alert(`${missionConditionList.find((el) => el.text === condition.conditionType).value} 조건의 숫자 값은 0 이상의 값을 입력 해 주세요!`);
                    return false;
                }
            });
        }
        return true;
    }

  const addContents = async (type) => {
      if (!paramsCheck()) return;
      const request = {};
      request.missionName = missionName;
      request.missionNameEn = missionNameEn;
      request.privateYn = privateYn;
      request.conditionCount = conditionCount;
      request.rewardType = rewardType;
      request.rewardCurrencyType = rewardCurrencyType;
      request.rewardValue = rewardValue;
      request.maxCompensateCount = maxCompensateCount;
      if (rewardType === 'ONLY_ONCE') {
          request.exposeLimitDays = exposeLimitDays;
          const dateString = `${exposeLimitDate}T23:59:59`;
          const originDate = new Date(dateString);
          const convertedDate = originDate.toISOString();
          request.exposeLimitDate = convertedDate.substring(0, 19);
      }
      request.missionDescription = missionDescription;
      request.missionDescriptionEn = missionDescriptionEn;
      const formData = new FormData();
      if (iconImgFile) {
          formData.append('iconImgFile', iconImgFile);
      }
      if (conditionList && conditionList.length > 0) {
          conditionList.forEach((condition, idx) => {
              Object.entries(condition)
                  .filter(el => el[1] !== null && el[1] !== 'null' && el[1] !== '' && el[1] !== undefined)
                  .forEach(el => formData.append(`conditionList[${idx}].${el[0]}`, el[1]))
          });
      }
      Object.entries(request).forEach((el) => {
          formData.append(el[0], el[1]);
      });
    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(`${MISSIONS}/${prevId}`, formData, {});
      } else {
        await axios.post(`${MISSIONS}`, formData, {});
      }
      await getDetail();
      alert('저장되었습니다.');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  const handleConditionList = conditionList.map((condition) => {
        if (condition.conditionType === 'NO_OBJECT') {
          return <TextField
              key={condition.conditionType}
              text='현재 이 미션에는 미션을 수행하기 위한 조건이 없어요!'
              label=''
          ></TextField>
        }
        if (condition.conditionType === 'WORD_COUNT') {
          return (
              <CommonCondition
                  key={condition.conditionType}
                  title='글자 수 제한'
                  countExposure
                  countValue={wordCount}
                  countLabel='글자 수'
                  countInputName='wordCount'
                  activeYn={wordCountActiveYn}
                  activeYnInputName='wordCountActiveYn'
                  countOnChange={(e) => {
                      let value = Number(e.target.value);
                      if (!value || value < 0) {
                          alert('글자 수 제한 값은 1 이상의 값을 입력 해 주세요');
                          value=1;
                      }
                        const nowCondition = conditionList.find((el) => el.conditionType === 'WORD_COUNT');
                        nowCondition.count = value;
                        setConditionList(conditionList);
                        setWordCount(value);
                      }}
                  activeYnOnChange={(e) => {
                        const nowCondition = conditionList.find((el) => el.conditionType === 'WORD_COUNT');
                        nowCondition.activeYn = e.target.value;
                        setConditionList(conditionList);
                        setWordCountActiveYn(e.target.value);
                      }}
                  onCountReset={() => setWordCount(0)}
              />
          );
        }
        if (condition.conditionType === 'SECONDS_COUNT') {
            return (
                <CommonCondition
                    key={condition.conditionType}
                    title='초 수 제한'
                    countExposure
                    countValue={secondsCount}
                    countLabel='업로드한 컨텐츠 시간(초) 수'
                    countInputName='secondsCount'
                    activeYn={secondsCountActiveYn}
                    activeYnInputName='secondsActiveYn'
                    countOnChange={(e) => {
                        let value = Number(e.target.value);
                        if (!value || value < 0) {
                            alert('초수 제한 값은 1 이상의 값을 입력 해 주세요');
                            value=1;
                        }
                        const nowCondition = conditionList.find((el) => el.conditionType === 'SECONDS_COUNT');
                        nowCondition.count = value;
                        setConditionList(conditionList);
                        setSecondsCount(value);
                    }}
                    activeYnOnChange={(e) => {
                        const nowCondition = conditionList.find((el) => el.conditionType === 'SECONDS_COUNT');
                        nowCondition.activeYn = e.target.value;
                        setConditionList(conditionList);
                        setSecondsCountActiveYn(e.target.value)
                    }}
                    onCountReset={() => setSecondsCount(0)}
                />
            )
        }
        if (condition.conditionType === 'ONLY_OWNER_CONTENTS') {
          return (
              <CommonCondition
                  key={condition.conditionType}
                  title='자기 자신의 컨텐츠만 카운트'
                  countExposure={false}
                  activeYn={onlyOwnerContentsActiveYn}
                  activeYnInputName='onlyOwnerContentsActiveYn'
                  activeYnOnChange={(e) => {
                    const nowCondition = conditionList.find((el) => el.conditionType === 'ONLY_OWNER_CONTENTS');
                    nowCondition.activeYn = e.target.value;
                    setConditionList(conditionList);
                    setOnlyOwnerContentsActiveYn(e.target.value)
                  }}
              />
          );
        }
        if (condition.conditionType === 'EXCLUDE_SAME_CONTENTS_PERIOD') {
          return (
              <CommonCondition
                  key={condition.conditionType}
                  title='동일 컨텐츠 주기 제한'
                  countExposure
                  countValue={excludeSameContentsPeriod}
                  countLabel='제한 주기'
                  countInputName='excludeSameContentsPeriod'
                  activeYn={excludeSameContentsPeriodActiveYn}
                  activeYnInputName='excludeSameContentsPeriodActiveYn'
                  countOnChange={(e) => {
                      let value = Number(e.target.value);
                      if (!value || value < 0) {
                          alert('주기 제한 값은 1 이상의 값을 입력 해 주세요');
                          value=1;
                      }
                    const nowCondition = conditionList.find((el) => el.conditionType === 'EXCLUDE_SAME_CONTENTS_PERIOD');
                    nowCondition.count = value;
                    setConditionList(conditionList);
                    setExcludeSameContentsPeriod(value);
                  }}
                  activeYnOnChange={(e) => {
                    const nowCondition = conditionList.find((el) => el.conditionType === 'EXCLUDE_SAME_CONTENTS_PERIOD');
                    nowCondition.activeYn = e.target.value;
                    setConditionList(conditionList);
                    setExcludeSameContentsPeriodActiveYn(e.target.value)
                  }}
                  onCountReset={() => setExcludeSameContentsPeriod(0)}
              />
          );
        }
      if (condition.conditionType === 'EXCLUDE_OWNER_CONTENTS') {
          return (
              <CommonCondition
                  key={condition.conditionType}
                  title='본인 컨텐츠 제외 하기'
                  activeYn={excludeOwnerContentsActiveYn}
                  activeYnInputName='excludeOwnerContentsActiveYn'
                  activeYnOnChange={(e) => {
                      const nowCondition = conditionList.find((el) => el.conditionType === 'EXCLUDE_OWNER_CONTENTS');
                      nowCondition.activeYn = e.target.value;
                      setConditionList(conditionList);
                      setExcludeOwnerContentsActiveYn(e.target.value)
                  }}
              />
          );
      }
      }
  );

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
          <IconImgWrapper>
              {iconImgUrl && <IconImg src={iconImgUrl}/>}
          </IconImgWrapper>
          <InputWrapper>
              <FileInput
                  key='iconImgFile'
                  name="iconImgFile"
                  type='profileImg'
                  labels='아이콘 이미지'
                  value={iconImgFile}
                  setFile={(e) => setIconImgFile(e)}
                  setImgUrl={setIconImgUrl}
                  placeholder="아이콘 이미지를 등록 해 주세요"
                  label='아이콘 이미지'
              />
          </InputWrapper>
        <InputWrapper>
          <MultiLabelTextInput
              key='missionName'
            name="missionName"
            labels={[
                '미션 이름',
                '(미션 이름에 횟수가 들어가는 경우, 아래 미션 달성 횟수 값과 동일하게 횟수를 맞춰주세요)'
            ]}
            value={missionName}
            placeholder="미션 이름을 입력 해 주세요(미션 이름에 횟수가 들어가는 경우, 아래 미션 달성 횟수 값과 동일하게 횟수를 맞춰주세요)"
            onChange={(e) => setMissionName(e.target.value)}
            onReset={() => setMissionName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <MultiLabelTextInput
              key='missionNameEn'
            name="missionNameEn"
            labels={[
                '미션 영문 이름',
                '(미션 이름에 횟수가 들어가는 경우, 아래 미션 달성 횟수 값과 동일하게 횟수를 맞춰주세요)'
            ]}
            value={missionNameEn}
            placeholder="영문 미션 명을 입력해 주세요."
            onChange={(e) => setMissionNameEn(e.target.value)}
            onReset={() => setMissionNameEn('')}
            Required
          />
        </InputWrapper>
          <InputWrapper>
              <MultiLabelTextInput
                  key='missionDescription'
                  name="missionDescription"
                  labels={[
                      '미션 설명',
                      '(미션 설명에 횟수가 들어가는 경우, 아래 미션 달성 횟수 값과 동일하게 횟수를 맞춰주세요)'
                  ]}
                  value={missionDescription}
                  placeholder="미션 설명을 입력해 주세요."
                  onChange={(e) => setMissionDescription(e.target.value)}
                  onReset={() => setMissionDescription('')}
                  Required
              />
          </InputWrapper>
          <InputWrapper>
              <MultiLabelTextInput
                  key='missionDescriptionEn'
                  name="missionDescriptionEn"
                  labels={[
                      '미션 영문 설명',
                      '(미션 설명에 횟수가 들어가는 경우, 아래 미션 달성 횟수 값과 동일하게 횟수를 맞춰주세요)'
                  ]}
                  value={missionDescriptionEn}
                  placeholder="미션 영문 설명을 입력해 주세요."
                  onChange={(e) => setMissionDescriptionEn(e.target.value)}
                  onReset={() => setMissionDescriptionEn('')}
                  Required
              />
          </InputWrapper>
      <InputWrapper>
          <LabelTextInput
              name="conditionCount"
              label="보상을 받는데 필요한 미션 달성 횟수"
              value={conditionCount}
              placeholder="미션 달성에 필요한 횟수를 입력 해 주세요(0 이상)"
              onChange={(e) => {
                  if (Number(e.target.value) >= 0) {
                      setConditionCount(e.target.value);
                  } else {
                      alert('미션 달성에 필요한 횟수는 0 이상의 값을 입력 해 주세요');
                  }
              }}
              onReset={() => setConditionCount('')}
          />
      </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={privateYn}
            name="privateYn"
            label="비공개 여부"
            onChange={e => setPrivateYn(e.target.value)}
            text={['공개', '비공개']}
            type="privateYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="보상 유형"
            text={rewardTypeKey}
          />
        </InputWrapper>
        <InputWrapper>
          <SelectModule
            label="보상 재화 유형"
            name="rewardCurrencyType"
            placeholder="보상 재화 유형을 선택 해 주세요"
            options={rewardCurrencyTypeList}
            value={rewardCurrencyTypeKey}
            onChange={e => {
              setRewardCurrencyType(e.value);
              setRewardCurrencyTypeKey(e.text);
            }}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            label="보상 재화 지급 량"
            name="rewardValue"
            value={rewardValue}
            placeholder="보상 재화 지급 량을 선택 해 주세요(0 이상)"
            onChange={(e) => {
              if (Number(e.target.value) >= 0) {
                setRewardValue(e.target.value);
              } else {
                alert('보상 재화 지급 양은 0 이상의 값을 입력 해 주세요');
              }
            }}
            onReset={() => setRewardValue('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            label="최대 보상 지급 횟수 (횟수 증가시 보상을 받는데 필요한 미션 수행 횟수도 같이 증가 시켜 주세요)"
            name="maxCompensateCount"
            value={maxCompensateCount}
            placeholder="최대 보상 지급 횟수를 입력 해 주세요(0 이상)"
            onChange={(e) => {
              if (Number(e.target.value) >= 0) {
                setMaxCompensateCount(e.target.value);
              } else {
                alert('최대 보상 지급 횟수는 1 이상의 값을 입력 해 주세요');
              }
            }}
            onReset={() => setMaxCompensateCount('')}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="미션 생성 일자" text={createDate} />
        </InputWrapper>
          {
              rewardType === 'ONLY_ONCE' && (
                  <InputWrapper >
                      <LabelTextInput
                          key='exposeLimitDays'
                          name='exposeLimitDays'
                          label='완료 이력 노출 기간'
                          type='number'
                          value={exposeLimitDays}
                          onChange={(e) => {setExposeLimitDays(e.target.value)}}
                          onReset={() => {setExposeLimitDays(0)}}
                          min={1}
                          Required>
                      </LabelTextInput>
                      <DateInput
                          key='exposeLimitDate'
                          label='미션 노출 기간(설정한 날 23:59:59까지 노출됩니다)'
                          name='exposeLimitDate'
                          onChange={(e) => {setExposeLimitDate(e.target.value)}}
                          initialStartDate={exposeLimitDate}
                          type='date'
                          Required
                      ></DateInput>
                  </InputWrapper>
              )
          }
        <InputWrapper>
          <>
            <InputText>미션 조건 목록</InputText>
            <hr />
          {handleConditionList}
          </>
        </InputWrapper>
      </MainContainer>
    </>
  );
};
export default MissionDetail;
const InputText = styled.p`
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
    grid-column: span 2;
  }

  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }

  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }

  &:nth-child(n + 7):nth-child(-n + 8) {
    grid-row: 4;
  }

  &:nth-child(n + 9):nth-child(-n + 10) {
    grid-row: 5;
  }

  &:nth-child(n + 11):nth-child(-n + 12) {
    grid-row: 6;
  }
  &:nth-child(n + 13) {
    grid-row: 7;
    grid-column: span 2;
  }
  &:nth-child(n + 14) {
    grid-row: 8;
    grid-column: span 2;
  }
  &:nth-child(n + 15) {
    grid-row: 9;
    grid-column: span 2;
  }
  &:nth-child(n + 16) {
    grid-row: 10;
    grid-column: span 2;
  }
`;

const IconImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  grid-row: 1;
`;

const IconImg = styled.img`
  width: 50%;
  border-radius: 10px;
`;
