import React, { useState, useCallback, useEffect, ChangeEvent } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// common
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import EditButton from '../../components/common/Button/EditButton';

// config
import { APP_VERSION } from '../../constant/apiUrl';

// hooks
import { useAppSelector } from '../../redux/store/hooks';

//constant
import { appVersionManagementColList } from '../../constant/operation';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';

// constant
import { appVersionRegExp } from '../../constant/common';

// 타입
interface versionType {
  id: number;
  type: string;
  aosMinVersion: string;
  aosNewVersion: string;
  iosMinVersion: string;
  iosNewVersion: string;
}

const AppVersionManagement = () => {
  const dispatch = useDispatch();

  const adminName = useAppSelector((state) => state.user.user.name);
  const [versionList, setVersionList] = useState([]);
  const [editData, setEditData] = useState<any>({});
  const [isUpdate, setIsUpdate] = useState(false);

  // 버전 환경 변수
  const serverType = process.env.REACT_APP_SERVER_APP_VERSION;

  // App 버전 조회
  const getAppVersionList = useCallback(async () => {
    const params = { limit: 20, offset: 0, serverType };

    try {
      const resp = await axios.get(APP_VERSION, { params });
      setVersionList(resp?.data.masterAppVersionList);
    } catch (err) {
      console.log(err);
    }
  }, [serverType]);

  // 항목 선택
  const handleSelectData = (data: versionType) => {
    setEditData(data);
  };

  // 항목 수정
  const editAppVersion = async () => {
    const { aosMinVersion, aosNewVersion, iosMinVersion, iosNewVersion } =
      editData;
    const params = {
      adminName,
      iosMinVersion,
      iosNewVersion,
      aosMinVersion,
      aosNewVersion,
    };

    if (
      appVersionRegExp.test(aosMinVersion) &&
      appVersionRegExp.test(aosNewVersion) &&
      appVersionRegExp.test(iosMinVersion) &&
      appVersionRegExp.test(iosNewVersion)
    ) {
      try {
        dispatch(setLoading());
        await axios.post(`${APP_VERSION}/${editData.id}`, {}, { params });
        alert('수정되었습니다');
        setEditData({});
        setIsUpdate(true);
      } catch (err) {
        console.log(err);
      }
      dispatch(resetLoading());
    } else alert('버전 양식에 맞지않습니다. 다시 시도해 주세요.');
  };

  // 항목 input
  const handleEditData = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  useEffect(() => {
    getAppVersionList().then((r) => r);
    setIsUpdate(false);
  }, [getAppVersionList, isUpdate]);

  return (
    <MainContainer>
      <Table
        colList={appVersionManagementColList}
        thead={
          <>
            <ListHead title="분류" />
            <ListHead title="최소 버전(iOS)" />
            <ListHead title="최신 버전(iOS)" />
            <ListHead title="최소 버전(AOS)" />
            <ListHead title="최신 버전(AOS)" />
            <ListHead title="" />
          </>
        }
      >
        {versionList?.map((el: versionType) => {
          const {
            id,
            type,
            aosMinVersion,
            aosNewVersion,
            iosMinVersion,
            iosNewVersion,
          } = el;

          return (
            <tr key={id}>
              <td>
                <ListItem text={type.toUpperCase()} type="title" />
              </td>
              {editData?.id === id ? (
                <>
                  <td>
                    <InputVersionData
                      name="iosMinVersion"
                      value={editData.iosMinVersion}
                      onChange={(e) => handleEditData(e)}
                    />
                  </td>
                  <td>
                    <InputVersionData
                      name="iosNewVersion"
                      value={editData.iosNewVersion}
                      onChange={(e) => handleEditData(e)}
                    />
                  </td>
                  <td>
                    <InputVersionData
                      name="aosMinVersion"
                      value={editData.aosMinVersion}
                      onChange={(e) => handleEditData(e)}
                    />
                  </td>
                  <td>
                    <InputVersionData
                      name="aosNewVersion"
                      value={editData.aosNewVersion}
                      onChange={(e) => handleEditData(e)}
                    />
                  </td>
                  <td>
                    <EditButton
                      onClick={editAppVersion}
                      text="저장"
                      className="saveBtn"
                    />
                  </td>
                </>
              ) : (
                <>
                  <td>
                    <ListItem text={iosMinVersion} />
                  </td>
                  <td>
                    <ListItem text={iosNewVersion} />
                  </td>
                  <td>
                    <ListItem text={aosMinVersion} />
                  </td>
                  <td>
                    <ListItem text={aosNewVersion} />
                  </td>
                  <td>
                    <EditButton
                      onClick={() => handleSelectData(el)}
                      text="수정"
                    />
                  </td>
                </>
              )}
            </tr>
          );
        })}
      </Table>
    </MainContainer>
  );
};

export default AppVersionManagement;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const InputVersionData = styled.input`
  width: 116px;
  padding: 12px 30px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  background-color: ${({ theme }) => theme.color.subBrightGray};
  border-radius: 6px;
  text-align: center;
`;
