////////////////////////////////////////////////////////////////////////////////
// 광고주 리포트
////////////////////////////////////////////////////////////////////////////////
// 광고주 통합 리포트 : 광고주 리포트
import React from "react";
import TypicalTable from "../../../components/common/List/TypicalTable";
import ListItem from "../../../components/common/List/ListItem";
import {Tooltip} from "./Tooltip";
import {BRIEF_HEADERS} from "./ReportFunction";

const AdvertisementBriefReport = ({parsedReports, parsedReportTotal}) => {

  return (
    <TypicalTable
      thead={
        <tr>
          {BRIEF_HEADERS.map(header =>(
            <th key={header.value}>
              <Tooltip message={header.tooltip}>
                {header.name}
              </Tooltip>
            </th>
          ))}
        </tr>
      }
    >
      <AdvertisementBriefReportRow row={parsedReportTotal}/>
      {parsedReports?.map((el) => (
        <AdvertisementBriefReportRow row={el} key={el.dateUnit}/>
      ))}
    </TypicalTable>
  );
};
export default AdvertisementBriefReport;

// 광고주 리포트 ROW
const AdvertisementBriefReportRow = ({row}) => {
  return (
    <tr key={row.dateUnit}>
      <td><ListItem text={row.dateUnit || '-'} /></td>
      <td><ListItem text={row.imprCnt || '-'} /></td>
      <td><ListItem text={row.playCnt || '-'} /></td>
      <td><ListItem text={row.clckCnt || '-'} /></td>
      <td><ListItem text={row.ldvwCnt || '-'} /></td>
      <td><ListItem text={row.engaCnt || '-'} /></td>
      <td><ListItem text={`${row.clckRat} %` || '-'} /></td>
      <td><ListItem text={`${row.engaRat} %` || '-'} /></td>
    </tr>
  );
};
