import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import styled from "styled-components";

import {BATTLE} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import blankImg from '../../assets/images/page/blankImg.png';
import {battleAnyIsValues} from "../../constant/battle";
import * as commonFn from "../../utils/commonFn";

import FileInput from "../../components/common/Input/FileInput";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextAreaInput from "../../components/common/Input/LabelTextAreaInput";
import SelectModule from "../../components/common/Select/SelectModule";


const initPage = {imgUrl: '', title: '', description: '', titleEn: '', descriptionEn: ''}

export default function BattleAnyIsConfig () {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const [type, setType] = useState(null);
	const [anyIs, setAnyIs] = useState([]);
	const [images, setImages] = useState({
		imageFile0: null,
		imageFile1: null,
		imageFile2: null,
		imageFile3: null,
		imageFile4: null
	});
	
	const getAnyIs = () => {
		axios
		.get(`${BATTLE}/any-is?type=${type}`)
		.then(res => setAnyIs(res.data.anyIs || [{...initPage}]))
	}
	
	const saveAnyIs = () => {
		dispatch(setLoading());
		if (confirm('저장하시겠습니까?')) {
			let valid = true;
			let page;
			anyIs.forEach((el, i) => {
				if (!el.imgUrl) {
					valid = false;
					page = i+1;
				}
			})
			if (!valid) {
				alert(`필수값이 누락되었습니다.\n${page}페이지를 확인해주세요.`);
				dispatch(resetLoading());
				return;
			}
			
			const formData = new FormData();
			// 이미지 삽입
			Object.keys(images).forEach(key => {
				if (images[key]) formData.append(key, images[key]);
			})
			// 데이터 가공
			const _anyIs = [...anyIs]
			_anyIs.forEach(el => {
				if (!el.imgUrl.includes('http')) el.imgUrl = '';
			})
			const blob = new Blob([JSON.stringify({type: type, anyIs: _anyIs})], {type: 'application/json'});
			formData.append('request', blob);
			
			axios.post(`${BATTLE}/any-is`, formData, {}).then(() => {
				alert(`저장이 완료되었습니다.`);
				navigate(0);
			}).catch((err) => {
				console.log(err);
				alert(`저장 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
			}).finally(() => {
				dispatch(resetLoading());
			});
		}
	}
	
	const addPage = () => {
		if (anyIs.length > 4) {
			alert('페이지는 최대 5개까지 입니다.');
			return;
		}
		
		setAnyIs([...anyIs, {...initPage}]);
	}
	
	const removePage = () => {
		if (anyIs.length === 1) {
			alert('페이지는 최소 1개가 필요합니다.')
			return;
		}
		
		setAnyIs([...anyIs].slice(0, anyIs.length-1));
	}
	
	const change = (i, key, value) => {
		const _anyIs = [...anyIs];
		_anyIs[i][key] = value;
		setAnyIs(_anyIs);
	}
	
	useEffect(getAnyIs, [type]);
	
	// useEffect(() => {console.log(images)}, [images])
	// useEffect(() => {console.log(anyIs)}, [anyIs])
	
	return <>
		<TopSection>
			<NormalButton text="저장" onSubmit={saveAnyIs} />
		</TopSection>
		
		<LineDiv />
		
		<MiddleSection>
			<SelectModule
				name="type"
				label={`타입 선택`}
				placeholder="타입을 선택해주세요."
				value={battleAnyIsValues[type]}
				onChange={e => setType(e.value)}
				options={commonFn.transObjectToOptions(battleAnyIsValues)}
			/>
		</MiddleSection>
		
		<MiddleSection>
			{anyIs.map((el, i) => <PageBox key={i}>
				<FileWrapper>
					<FileInput
						Required
						type="coverImg"
						label={`${i+1}페이지 이미지`}
						name={`imageFile${i}`}
						value={images[`imageFile${i}`]}
						setFile={e => {
							const _images = {...images};
							_images[`imageFile${i}`] = e;
							setImages(_images);
						}}
						setImgUrl={img => change(i, 'imgUrl', img)}
					/>
					<div id='thumb'><img src={el.imgUrl || blankImg} alt='' /></div>
				</FileWrapper>
				
				<InputWrapper>
					<LabelTextInput
						Required
						name="title"
						label={`${i+1}페이지 제목`}
						placeholder="제목을 입력해주세요."
						value={el.title}
						// onChange={e => change(i, 'title', e.target.value)}
						readOnly
					/>
					<LabelTextInput
						name="titleEn"
						label="제목 (영어)"
						placeholder="제목(영어)을 입력해주세요."
						value={el.titleEn}
						// onChange={e => change(i, 'titleEn', e.target.value)}
						readOnly
					/>
					<LabelTextAreaInput
						Required
						name="battle_rankingis_description"
						label={`${i+1}페이지 설명`}
						placeholder="설명을 입력해주세요."
						value={el.description}
						// setValue={e => change(i, 'description', e.target.value)}
						readOnly
					/>
					<LabelTextAreaInput
						name="battle_rankingis_descriptionEn"
						label="설명 (영어)"
						placeholder="설명(영어)을 입력해주세요."
						value={el.descriptionEn}
						// setValue={e => change(i, 'descriptionEn', e.target.value)}
						readOnly
					/>
				</InputWrapper>
			</PageBox>)}
		</MiddleSection>
		
		<LineDiv />
		
		<BottomSection>
			<NormalButton text="삭제" className='cancelBtn' onSubmit={removePage} />
			<NormalButton text="추가" className='cancelBtn' onSubmit={addPage} />
		</BottomSection>
	</>;
};

const TopSection = styled.section`
  width: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: flex-end;
`;

const MiddleSection = styled.section`
  width: 100%;
  padding: 20px 0;
`;

const BottomSection = styled.section`
  width: 100%;
  margin: 15px 0;
  display: flex;
  justify-content: space-between;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const PageBox = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  padding: 12px 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 2px solid #00000017
`;

const FileWrapper = styled.div`
  grid-column: 1 / span 2;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(5, 1fr);

  #thumb {
    display: grid;
    grid-row: 2 / span 4;
    background-color: #f1f3f5;
    height: 398px;
	border-radius: 10px;
	padding: 10px;
    align-items: center;
    justify-content: center;
	
    img {
	  width: 100%;
      height: 100%;
	}
  }
  
`;

const InputWrapper = styled.div`
  grid-column: 3 / span 3;
  display: grid;
  grid-template-rows: repeat(10, 1fr);
  align-content: space-between;
  gap: 10px;

  .title,
  .titleEn {
    grid-row: span 2;
  }
  .battle_rankingis_description,
  .battle_rankingis_descriptionEn {
    grid-row: span 3;
  }
`;
