import React from 'react';
import styled from 'styled-components';
import NormalButton from "../../../components/common/Button/NormalButton";

const NormalButtonWithLabel = ({
  label,
  Required,
  buttonText,
  buttonOnClick,
}) => {

  return (
    <InputContainer>
      <InputText>
        {label} {Required && <span>*</span>}
      </InputText>
      <NormalButton
          text={buttonText}
          onSubmit={buttonOnClick}
          className={'fileInputModule'}
      />
    </InputContainer>
  );
};
export default NormalButtonWithLabel;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;
