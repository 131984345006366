import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// common
import axios from 'axios';
import Pagination from '../../../components/common/Pagination';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import RoundButton from '../../../components/common/Button/RoundButton';
import { postsPerPage } from '../../../constant/common';

// config
import { PERFORMANCE_ONGOING, PERFORMANCE_BYGONE, PERFORMANCE_CATEGORY_ACTIVE } from '../../../constant/apiUrl';

// component
import NormalButton from '../../../components/common/Button/NormalButton';
import ListItem from "../../../components/common/List/ListItem";
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import EditButton from "../../../components/common/Button/EditButton";
import handleDate from '../../../hooks/handleDate';

const Row = ({data}) => {
	const navigate = useNavigate();
	return (
		<tr>
			<td><ListCoverImg url={data.posterImgUrl} /></td>
			<td><ListItem text={data.title} /></td>
			<td><ListItem text={data.strDate} /></td>
			<td><ListItem text={data.placeCity} /></td>
			<td><ListItem text={data.placeHall} /></td>
			<td><ListItem text={handleDate(data.publicStartDate)} /></td>
			<td><ListItem text={handleDate(data.publicEndDate)} /></td>
			<td><EditButton text="수정" onClick={() => navigate('/culture/performance/detail', {state: {id: data.performanceId}})}/></td>
		</tr>
	);
};

const Performance = () => {
	const navigate = useNavigate();

	// pagination state
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);

	// 0: 공개, 1: 비공개
	const [privateYn, setPrivateYn] = useState(0);

	// 카테고리 관련
	const [categoryId, setCategoryId] = useState(0);
	const [categoryList, setCategoryList] = useState([]);

	// 공연 리스트 데이터
	const [performanceList, setPerformanceList] = useState([]);

	// 카테고리 조회
	const getCategoryList = async () => {
		await axios.get(`${PERFORMANCE_CATEGORY_ACTIVE}?offset=0&limit=100`)
			.then(res => {
				const list = res.data.performanceCategorys;
				list.unshift({performanceCategoryId:0, name:'전체'});	// 카테고리 전체 카테고리 만들기
				setCategoryList(list);
			})
			.catch(reason => console.error(reason));
	}

	// 조건에 맞는 공연 조회
	const getPerformanceList = async (privateYn, categoryId, pageNumber) => {
		let api;
		if (privateYn === 0) api = PERFORMANCE_ONGOING;
		else api = PERFORMANCE_BYGONE;

		await axios.get(`${api}/${categoryId}?offset=${pageNumber * postsPerPage}&limit=${postsPerPage}`)
			.then(res => {
				setPerformanceList(res.data.performanceList);
				setTotalCount(res.data.totalCount); // 토탈 페이지 추가
			})
			.catch(reason => console.error(reason));
	};

	// 페이지 입장시 카테고리 먼저 그리기
	useEffect(() => getCategoryList(), []);
	// 카테고리 리스트의 변화가 있으면 테이블 그리기
	useEffect(() => getPerformanceList(privateYn, categoryId, pageNumber), [categoryList]);
	// 검색 조건에 따른 테이블 그리기
	useEffect(() => {
		// 카테고리가 그려지고 작동하기
		if (categoryList.length > 0) {
			getPerformanceList(privateYn, categoryId, pageNumber);
		}
	}, [privateYn, categoryId, pageNumber]);

	return (
		<>
			<TopContainer>
				<TopWrapper>
					<NormalButton text="공연 추가" onSubmit={() => navigate('/culture/performance/detail')}/>
				</TopWrapper>

				<LineDiv />

				<ButtonWrapper>
					<RoundButton title="공개" selected={privateYn === 0} onClick={() => setPrivateYn(0)}/>
					<RoundButton title="비공개" selected={privateYn === 1} onClick={() => setPrivateYn(1)}/>
				</ButtonWrapper>

				<LineDiv />

				<ButtonWrapper>
					{categoryList.map(c =>
						<RoundButton
							key={c.performanceCategoryId}
							title={c.name}
							selected={categoryId === c.performanceCategoryId}
							onClick={() => setCategoryId(c.performanceCategoryId)}
						/>)}
				</ButtonWrapper>

				<PaginationWrapper>
					{totalCount !== 0 && (
						<PaginationWrapper>
							<Pagination
								postsPerPage={postsPerPage}
								totalCount={totalCount}
								currentPage={pageNumber}
								pageDispatch={e => setPageNumber(e - 1)}
							/>
						</PaginationWrapper>
					)}
				</PaginationWrapper>
			</TopContainer>

			{/*<LineDiv />*/}

			<MainContainer>
				<Table thead={<>
					  <ListHead title="포스터" rowWidth={80} />
					  <ListHead title="타이틀" rowWidth={70} />
					  <ListHead title="공연 기간" rowWidth={120} />
					  <ListHead title="도시" rowWidth={50} />
					  <ListHead title="공연장" rowWidth={70} />
					  <ListHead title="게시 시작" rowWidth={120} />
					  <ListHead title="게시 종료" rowWidth={120} />
					  <ListHead title="수정" rowWidth={50} />
				</>}>
					{performanceList?.map(p => <Row key={p.performanceId} data={p} />)}
				</Table>
		  </MainContainer>
		</>
	);
};

export default Performance;


const TopContainer = 		styled.div`width: 100%; 		z-index: 1;`;
const PaginationWrapper = 	styled.div`margin-top: 50px;`;
const MainContainer = 		styled.div`width: 100%; 		margin-top: 60px;`;
const ButtonWrapper = 		styled.div`display: flex; justify-content: flex-start; align-items: center;`;

const LineDiv = styled.div`
  	width: 100%;
  	margin-bottom: 10px;
	margin-top: 10px;
  	border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
const TopWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;