import React from 'react';
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';

//constant
import { IsActive, GetCountryCodeOption } from '../../../constant/common';

// hooks
import getMobileNum from '../../../hooks/getMobileNum';

interface props {
  data: dataTypes;
  onClick: (data: dataTypes) => void;
}

interface dataTypes {
  id: number;
  email: string;
  name: string;
  activeYn: string;
  mobile: string;
  countryCode: string;
  roles: Array<string>;
}

const AccountRowComponent = ({ data, onClick }: props) => {
  const { id, name, email, activeYn, mobile, countryCode, roles } = data;

  return (
    <tr key={id}>
      <td>
        <ListItem text={name} />
      </td>
      <td>
        <ListItem text={email} type="title" />
      </td>
      <td>
        <ListItem text={getMobileNum(`0${mobile}`)} />
      </td>
      <td>
        <ListItem text={GetCountryCodeOption[countryCode]} />
      </td>
      <td>
        <ListItem text={roles?.join(', ')} />
      </td>
      <td>
        <ListItem text={IsActive[activeYn]} />
      </td>
      <td>
        <EditButton onClick={() => onClick(data)} text="수정" />
      </td>
    </tr>
  );
};

export default AccountRowComponent;
