import React from 'react';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const CopyButton = ({ text, copyText, onCopyText }) =>
		<CopyToClipboard
				text={copyText}
				onCopy={()=> onCopyText && alert(onCopyText)}
		>
			<Button>{text}</Button>
		</CopyToClipboard>
;
export default CopyButton;

const Button = styled.button`
  background-color: ${({ theme }) => theme.color.mainBlack};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.mainWhite};
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
	padding: 12px 23px;
	width: 100%;
`;
