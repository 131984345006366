import React, { useRef, } from 'react';
import styled from 'styled-components';
import closeBtn from '../../../assets/images/modal/closeBtn.png';
import useOutSideClick from '../../../hooks/useOutSideClick';
import useKeyEscClose from '../../../hooks/useKeyEscClose';

interface Props {
	closeModal: () => void;
	blockOutSideClick?: boolean;
	option?: {
		class: string,
	};
}

const Modal: React.FC<Props> = ({
	children,
    closeModal,
    blockOutSideClick,
    option
}) => {
	const ref = useRef(null);

	if (!blockOutSideClick) useOutSideClick(ref, closeModal);

	// esc 누르면 모달 닫힘
	useKeyEscClose(closeModal);

	return (
		<Container>
			<ModalWrapper ref={ref} className={option?.class}>
				{!blockOutSideClick &&
					<ButtonWrapper>
						<button onClick={closeModal} type="button">
							<img src={closeBtn} alt="closeBtn" />
						</button>
					</ButtonWrapper>
				}
				<ContentWrapper className={option?.class}>{children}</ContentWrapper>
			</ModalWrapper>
		</Container>
	);
};

export default Modal;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: calC(100% - 60px);
  background-color: rgba(0, 0, 0, 0.5);
  overflow: hidden;
  z-index: 40;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};

  &.event-promotion {
    height: 85vh;
    max-height: 1000px;
    overflow: auto;
  }

  &.event-promotion-add {
    height: 80vh;
    max-height: 760px;
    overflow: auto;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    height: 30px;
    padding: 0;
    cursor: pointer;
  }

  img {
    width: 30px;
  }
`;

const ContentWrapper = styled.div`
  margin: 0 auto;
  padding: 70px 0 60px 0;

  &.compact {
    padding: 10px 0;
  }
  
  &.suggestCategoryInput {
    padding: 0 0 0 0;
  }
`;
