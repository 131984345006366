import axios from 'axios';
import qs from 'qs';

export const SERVER = `${process.env.REACT_APP_SERVER}`;

axios.defaults.baseURL = SERVER;
axios.defaults.withCredentials = true; // front, back 간 쿠키 공유
axios.defaults.headers.common.UserAgent = 'admin 14.3 99.99.99 1 ko-KR Asia/Seoul';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['Cache-Control'] = 'no-store';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS';
axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.headers.common.Expires = 'Mon, 06 Jan 1990 00:00:01 GMT';
axios.defaults.paramsSerializer = params => {return qs.stringify(params, { arrayFormat: 'repeat' });};