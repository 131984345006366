import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';
import VideoModal from '../../../components/common/Modal/VideoModal';
import VideoInput from '../../../components/common/Input/VideoInput';
import Pagination from '../../../components/common/Pagination';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';

import ListSelect from '../../../components/common/Select/ListSelect';
import ListDateSelect from '../../../components/common/Select/ListDateSelect';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../components/common/Input/SearchInput';
import LabelTextAreaInput from '../../../components/common/Input/LabelTextAreaInput';
import DetailAddBeatRowComponents from '../../explore/components/category/DetailAddBeatRowComponents';
import UserRowComponent from './UserRowComponent';

// constants
import { postsPerPage } from '../../../constant/common';
import {
  memberRestrictOptions,
  searchMemberSearchTypeList,
  userTypeOptions,
} from '../../../constant/member';

// config
import { ARTICLE_REGISTER, SEARCH_MEMBER } from '../../../constant/apiUrl';
import { beatBpmOptions, beatSearchTypeList } from '../../../constant/beat';
import {
  addBeatCollectionColList,
  addUserCollectionColList,
} from '../../../constant/explore';

// redux
import { getBeatList } from '../../../redux/actions/beat/beat';
import { setLoading, resetLoading } from '../../../redux/slices/user';

//hook
import useInput from '../../../hooks/useInput';
import { useAppSelector } from '../../../redux/store/hooks';

//타입
import { RowComponentDataProps } from '../../../definitions/beat';
import { CustomProps } from '../../../definitions/common';
import {
  commentAllowOptions,
  fileFormatOptions,
} from '../../../constant/article';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialData: data = {
  isPrivate: 'Y',
  isCrewShow: 'N',
  allowComment: '',
  description: '',
  articleFile: '',
  lyrics: '',
  title: '',
  articleThumbnailFile: '',
  userData: '',
  beatData: '',
  extension: '',
};

const AddVideo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [videoData, setVideoData] = useState(initialData);
  const [thumbnailImgUrl, setThumbnailImgUrl] = useState('');

  const {
    isPrivate,
    isCrewShow,
    description,
    allowComment,
    extension,
    title,
    articleFile,
    articleThumbnailFile,
    beatData,
    userData,
    lyrics,
  } = videoData;

  // modal  state
  const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);
  const [contentList, setContentList] = useState<any>([]);
  const [selectedContentList, setSelectedContentList] = useState<any>([]);
  const [modalType, setModalType] = useState<any>('');
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, onReset] = useInput('');
  const beatList = useAppSelector((state) => state.beat.beatList);
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const [playBeat, setPlayBeat] = useState('');
  const [beatType, setBeatType] = useState<string>('');
  const [bpm, setBpm] = useState<string>('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);

  // user state
  const [isRestricted, setIsRestricted] = useState('');
  const [loginType, setLoginType] = useState('');

  // const [privateYn, setPrivateYn] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 검색어 인코딩
  const encodeStr = encodeURI(keyword);

  const beatParams = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    word: keyword,
    type: beatType,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn: 'N',
  };

  // memberId추가해야함
  const userParams = {
    isRestricted,
    loginType,
    type: searchType,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    verifiedYn: '',
  };

  // input set 함수
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setVideoData({ ...videoData, [name]: value });
    },
    [videoData],
  );

  // input reset 함수
  const handleResetData = useCallback(
    (e) => {
      setVideoData({ ...videoData, [e]: initialData[e] });
    },
    [videoData],
  );

  // 파일 등록하기
  const handleFileSetData = useCallback(
    (file: any, name: string) => {
      setVideoData({ ...videoData, [name]: file });
    },
    [videoData],
  );

  // 데이터 초기화
  const resetData = () => {
    setVideoModalOpen(false);
    onReset();
    setPageNumber(0);
    setSearchType('');

    // beat state 초기화
    setBeatType('');
    setBpm('');
    setMinBpm(0);
    setMaxBpm(250);
    setMinStartDate('');
    setMaxStartDate('');
  };

  // modal 닫기
  const closeModal = () => {
    resetData();
  };

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/video/searchVideo');
  };

  // user 조회
  const searchMember = async () => {
    try {
      const resp = await axios.get(SEARCH_MEMBER, {
        params: userParams,
      });
      setContentList(resp.data.memberList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      beatParams.offset = 0;
      setPageNumber(0);
      if (!searchType) {
        setSearchType('I');
        userParams.type = 'I';
        setBeatType('T');
        beatParams.type = 'T';
        setPageNumber(0);
      }
      if (modalType === 'user') {
        searchMember().then();
      } else {
        dispatch(getBeatList(beatParams));
      }
    }
  };

  // 영상이나 비트 추가하기
  const setModal = (name: string) => {
    setModalType(name);
    setVideoModalOpen(true);
    if (name === 'beat') {
      setSelectedContentList(beatData ? [beatData] : []);
      dispatch(getBeatList(beatParams));
    }
    if (name === 'user') {
      setSelectedContentList(userData ? [userData] : []);
      searchMember().then();
    }
  };

  // 파일 형식 저장
  const handleSelectSetData = useCallback(
    (e) => {
      if (e.name) {
        setVideoData({ ...videoData, allowComment: e });
      } else {
        setVideoData({ ...videoData, extension: e });
      }
    },
    [videoData],
  );

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    if (modalType === 'user') {
      const nowDate = `${new Date().toISOString().slice(0, 16)}:00`;
      const isBlockedContent = selectedContentList.some(
        (content: any) =>
          content.reportScoreExpireDt === null ||
          Date.parse(nowDate) > Date.parse(content.reportScoreExpireDt),
      );
      if (selectedContentList.length > 1) {
        alert('유저는 한 명의 유저만 선택이 가능합니다.');
        return;
      }
      if (!isBlockedContent) {
        alert('제재 처리된 유저는 등록이 불가합니다. 다시 선택해 주세요.');
        return;
      }
      setVideoData({ ...videoData, userData: selectedContentList[0] });
    }
    if (modalType === 'beat') {
      if (selectedContentList.length > 1) {
        alert('비트는 한 개의 비트만 선택이 가능합니다.');
        return;
      }
      setVideoData({ ...videoData, beatData: selectedContentList[0] });
    }

    closeModal();
  };

  // 영상 지우기
  const deleteVideo = (data: any, name: string) => {
    if (name === 'user') {
      setVideoData({
        ...videoData,
        userData: '',
      });
    } else if (name === 'beat') {
      setVideoData({
        ...videoData,
        beatData: '',
      });
    } else {
      setSelectedContentList(
        selectedContentList.filter((e: any) => e.id !== data.id),
      );
    }
  };

  // 비트 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (!selectedContentList?.find((e: any) => e.id === data.id)) {
      setSelectedContentList([...selectedContentList, data]);
    }
  };
  // 비트 클릭시 삭제
  const filterSelectedContent = (el: any) => {
    const result = selectedContentList.filter((item: any) => item !== el);
    setSelectedContentList(result);
  };

  // 영상 업로드
  const registerVideo = async () => {
    const formData = new FormData();
    if (!title || !extension || !articleFile || !userData || !beatData) {
      alert('필수 양식을 전부 채워주세요.');
      return;
    }
    // 파일 형식 확인
    const getType = (file: any) => {
      return file?.type.slice(file.type.lastIndexOf('/') + 1);
    };
    if (getType(articleFile) === 'mp4') {
      if (extension !== 'mp4') {
        alert('파일 형식과 파일을 맞춰주세요.');
        return;
      }
    } else if (getType(articleFile) === 'mpeg') {
      if (extension !== 'mp3') {
        alert('파일 형식과 파일을 맞춰주세요.');
        return;
      }
    }

    formData.append('isPrivate', String(isPrivate === 'Y'));
    formData.append('description', description === null ? '' : description);
    formData.append('title', title);
    formData.append('allowComment', allowComment.value);
    formData.append('extension', extension);
    formData.append('memberId', userData.id);
    formData.append('beatId', beatData.id);
    formData.append('articleThumbnailFile', articleThumbnailFile);
    formData.append('articleFile', articleFile);
    formData.append('isCrewShow', 'false');
    formData.append('lyrics', lyrics);
    formData.append('device', 'admin');

    dispatch(setLoading());
    try {
      await axios.post(ARTICLE_REGISTER, formData, {});
      alert('영상이 등록되었습니다.');
      goBack();
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (videoModalOpen) {
      document.body.style.cssText = `
      overflow-y: hidden;
      width: 100%;`;
    }
    return () => {
      document.body.style.cssText = '';
    };
  }, [videoModalOpen]);

  useEffect(() => {
    if (modalType === 'beat') {
      dispatch(getBeatList(beatParams));
    } else {
      searchMember().then();
    }
  }, [pageNumber, searchType, beatType]);

  useEffect(() => {
    if (modalType === 'beat') {
      beatParams.offset = 0;
      setPageNumber(0);
      switch (bpm) {
        case '':
          setMinBpm(0);
          setMaxBpm(250);
          break;
        case '49':
          setMinBpm(0);
          setMaxBpm(49);
          break;
        case '50':
          setMinBpm(50);
          setMaxBpm(80);
          break;
        case '80':
          setMinBpm(80);
          setMaxBpm(100);
          break;
        case '100':
          setMinBpm(100);
          setMaxBpm(140);
          break;
        case '140':
          setMinBpm(140);
          setMaxBpm(180);
          break;
        case '180':
          setMinBpm(180);
          setMaxBpm(230);
          break;
        default:
          setMinBpm(0);
          setMaxBpm(250);
          break;
      }

      dispatch(getBeatList(beatParams));
    } else {
      userParams.offset = 0;
      setPageNumber(0);
      searchMember().then();
    }
  }, [
    dispatch,
    bpm,
    maxBpm,
    minBpm,
    minStartDate,
    maxStartDate,
    loginType,
    isRestricted,
  ]);

  const useUserBeat = () => {
    setVideoData({ ...videoData, beatData: {
        id: -1,
        coverImgUrl: "https://cdn.baund.com/user/3263/profile/profileImg/user_3263_profile_profileImg_20220113104736.jpg",
        title: "userBeat",
        bmPid: "baundAdmin"
      } }
    );
  }

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
          <NormalButton text="저장하기" onSubmit={registerVideo} />
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <InputLabel>
            유저 선택<span>*</span>
          </InputLabel>
          <LineDiv />
          <VideoInput onClick={() => setModal('user')} text="유저 선택하기" />
          <Table colList={addUserCollectionColList} className="nonListTable">
            {userData && (
              <UserRowComponent
                data={userData}
                name="user"
                onClick={deleteVideo}
                selected
              />
            )}
          </Table>
        </InputWrapper>
        <InputWrapper>
          <InputLabel>
            비트<span>*</span>
          </InputLabel>
          <LineDiv />
          <VideoInput onClick={useUserBeat} text="사용자 비트 사용"/>
          <VideoInput onClick={() => setModal('beat')} text="비트 선택하기" />
          <SpaceDiv value="20px" />
          <Table colList={addBeatCollectionColList} className="nonListTable">
            {beatData && (
              <DetailAddBeatRowComponents
                data={beatData}
                name="beat"
                onClick={deleteVideo}
                selected
                editable
              />
            )}
          </Table>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="title"
            label="제목"
            value={title}
            placeholder="제목을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="설명"
            value={description}
            placeholder="설명을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        {/*<InputWrapper>*/}
        {/*  <LabelTextInput*/}
        {/*    name="extension"*/}
        {/*    label="파일 형식"*/}
        {/*    value={extension}*/}
        {/*    placeholder="업로드할 영상의 파일 형식을 입력해 주세요.(ex: mp4)"*/}
        {/*    onChange={handleInputSetData}*/}
        {/*    onReset={handleResetData}*/}
        {/*  />*/}
        {/*</InputWrapper>*/}
        <InputWrapper>
          <SelectModule
            name="extension"
            placeholder="업로드할 영상의 파일 형식을 선택해주세요."
            value={extension}
            label="파일 형식"
            onChange={handleSelectSetData}
            options={fileFormatOptions}
            Required
          />
        </InputWrapper>
        <FileWrapper>
          <FileInput
            type="file"
            placeholder="파일을 등록해 주세요."
            label="파일"
            name="articleFile"
            value={articleFile}
            setFile={handleFileSetData}
            Required
          />
        </FileWrapper>
        <FileWrapper>
          <FileInput
            type="coverImg"
            placeholder="파일을 등록해 주세요."
            label="썸네일 파일"
            name="articleThumbnailFile"
            value={articleThumbnailFile}
            setFile={handleFileSetData}
            setImgUrl={setThumbnailImgUrl}
            fileUrl={thumbnailImgUrl}
          />
          <EventImg src={thumbnailImgUrl || ''} />
        </FileWrapper>
        <InputWrapper>
          <RadioButton
            value={isPrivate}
            name="isPrivate"
            label="공개 설정"
            text={['공개', '비공개']}
            onChange={handleInputSetData}
            // subText="영상 업로드는 비공개만 가능합니다. 등록 후에 공개 여부를 수정해 주세요."
            type="privateYn"
            // disabled
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={isCrewShow}
            name="isCrewShow"
            label="크루 공개 설정"
            text={['공개', '비공개']}
            subText="크루 공개 여부는 현재 비공개만 가능합니다."
            type="uneditable"
            disabled
          />
        </InputWrapper>
        <InputWrapper>
          <SelectModule
            name="allowComment"
            placeholder="댓글 허용 범위를 선택해 주세요."
            value={allowComment.name}
            label="댓글 허용"
            onChange={handleSelectSetData}
            options={commentAllowOptions}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextAreaInput
            name="lyrics"
            label="가사"
            value={lyrics}
            placeholder="가사를 작성해주세요."
            setValue={handleInputSetData}
          />
        </InputWrapper>
      </MainContainer>
      {/*유저 추가 모달*/}
      {videoModalOpen && modalType === 'user' && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={closeModal}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => saveContentList()}
              />
            </ModalButtonWrapper>
            <VideoModalTitle>선택 유저</VideoModalTitle>
            {selectedContentList?.length !== 0 && selectedContentList ? (
              <>
                <TableTitle>{selectedContentList?.length}명 유저</TableTitle>
                <LineDiv />
                <Table
                  colList={addUserCollectionColList}
                  className="nonListTable"
                >
                  {selectedContentList?.map((data: any) => (
                    <UserRowComponent
                      key={data.id}
                      data={data}
                      onClick={() => {
                        filterSelectedContent(data);
                      }}
                      selected
                      editable
                    />
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>
                  선택된 유저가 없습니다. 아래 검색 후 유저를 선택해주세요.
                </VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>유저 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchMenuSelect
                  options={searchMemberSearchTypeList}
                  onClick={handleSelect}
                  setState={setSearchType}
                />
                <SearchInputWrapper>
                  <SearchInput
                    value={keyword}
                    onChange={setKeyword}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{totalCount}명 유저</TableTitle>
              {totalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={addUserCollectionColList}
              thead={
                <>
                  <ListHead title="인덱스" />
                  <ListHead title="Pid" />
                  <ListHead title="이메일" />
                  <ListSelect
                    title="가입 유형"
                    list={userTypeOptions}
                    onClick={handleSelect}
                    setState={setLoginType}
                  />
                  <ListHead title="계정 생성일" />
                  <ListHead title="벌점" />
                  <ListSelect
                    title="계정 상태"
                    list={memberRestrictOptions}
                    onClick={handleSelect}
                    setState={setIsRestricted}
                  />
                  <ListHead title="" />
                </>
              }
            >
              {contentList?.map((el: RowComponentDataProps) => (
                <UserRowComponent
                  key={el.id}
                  data={el}
                  onClick={addSelectedContent}
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
      {/*비트 추가 모달*/}
      {videoModalOpen && modalType === 'beat' && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={closeModal}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => saveContentList()}
              />
            </ModalButtonWrapper>
            <VideoModalTitle>선택 비트</VideoModalTitle>
            {selectedContentList?.length !== 0 && selectedContentList ? (
              <>
                <TableTitle>{selectedContentList?.length}개 비트</TableTitle>
                <LineDiv />
                <Table
                  colList={addBeatCollectionColList}
                  className="nonListTable"
                >
                  {selectedContentList?.map((data: any) => (
                    <DetailAddBeatRowComponents
                      key={data.id}
                      data={data}
                      playBeat={playBeat}
                      setPlayBeat={setPlayBeat}
                      onClick={() => {
                        filterSelectedContent(data);
                      }}
                      selected
                      editable
                    />
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>
                  선택된 비트가 없습니다. 아래 검색 후 비트를 선택해주세요.
                </VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>비트 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchMenuSelect
                  options={beatSearchTypeList}
                  onClick={handleSelect}
                  setState={setBeatType}
                />
                <SearchInputWrapper>
                  <SearchInput
                    value={keyword}
                    onChange={setKeyword}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{beatTotalCount}개 비트</TableTitle>
              {beatTotalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={beatTotalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={addBeatCollectionColList}
              thead={
                <>
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListSelect
                    title="BPM"
                    list={beatBpmOptions}
                    onClick={handleSelect}
                    setState={setBpm}
                  />
                  <ListHead title="BeatKey" />
                  <ListHead title="공개여부" />
                  <ListDateSelect
                    title="공개 날짜"
                    setMinStartDate={setMinStartDate}
                    setMaxStartDate={setMaxStartDate}
                  />
                  <ListHead title="" />
                </>
              }
            >
              {beatList?.map((el: RowComponentDataProps) => (
                <DetailAddBeatRowComponents
                  key={el.id}
                  data={el}
                  onClick={addSelectedContent}
                  playBeat={playBeat}
                  setPlayBeat={setPlayBeat}
                  editable
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default AddVideo;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px 40px;
  grid-gap: 30px 40px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    margin-bottom: 30px;
  }
  &:nth-child(2) {
    margin-bottom: 20px;
  }
  &:nth-child(4) {
    margin-bottom: 40px;
  }
  &:nth-child(8) {
    margin-top: 40px;
  }
  &:nth-child(9) {
    margin-top: 20px;
  }
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  margin-bottom: 20px;
  gap: 30px 40px;
  grid-gap: 30px 40px;
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div<CustomProps>`
  width: 100%;
  height: ${({ value }) => value};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;