import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import Table from '../../../../components/common/List/Table';
import RoundButton from '../../../../components/common/Button/RoundButton';
import ListHead from '../../../../components/UI/ListHead';
import Pagination from '../../../../components/common/Pagination';

// constant
import { addCollectionList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';

// config
import { MEMBER_COLLECTION_LIST } from '../../../../constant/apiUrl';
import { CollectionRowComponent } from './RecommendCollectionRowComponent';

const RecommendCollectionAddList = () => {
  const navigate = useNavigate();
  const [listType, setListType] = useState(true);
  const [collectionList, setCollectionList] = useState([]);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );

  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getCollectionList = async () => {
    if (listType) {
      try {
        const resp = await axios.get(`${MEMBER_COLLECTION_LIST}/official`, {
          params,
        });
        setCollectionList(resp.data.memberCollectionList);
        setTotalCount(resp.data.count || 0);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const resp = await axios.get(MEMBER_COLLECTION_LIST, { params });
        setCollectionList(resp.data.memberCollectionList);
        setTotalCount(resp.data.count || 0);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const addCollection = (data: any) => {
    navigate('/explore/allTab/recommendCollection/add', {
      state: { addData: data },
    });
  };

  useEffect(() => {
    getCollectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(0);
    params.offset = 0;
    getCollectionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listType]);

  return (
    <Container>
      <h1>컬렉션 리스트</h1>
      <ButtonContainer>
        <RoundButton
          title="Baund"
          selected={listType}
          onClick={() => {
            setListType(true);
          }}
        />
        <RoundButton
          title="유저"
          selected={!listType}
          onClick={() => {
            setListType(false);
          }}
        />
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </ButtonContainer>
      <Table
        colList={addCollectionList}
        thead={
          <>
            <ListHead title="번호" />
            <ListHead title="컬렉션 종류" />
            <ListHead title="컨텐츠 수" />
            <ListHead title="Cover" />
            <ListHead title="컬렉션 제목" />
            <ListHead title="작성 Pid" />
            <ListHead title="보관 수" />
            <ListHead title="" />
          </>
        }
      >
        {collectionList?.map((el: any) => (
          <CollectionRowComponent
            key={el.id}
            data={el}
            onClick={addCollection}
          />
        ))}
      </Table>
    </Container>
  );
};

export default RecommendCollectionAddList;

const Container = styled.div`
  width: 100%;
  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 50px;
`;
