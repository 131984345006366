import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NormalButton from '../../components/common/Button/NormalButton';
import RoundButton from '../../components/common/Button/RoundButton';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import { postsPerPage } from '../../constant/common';
import useInput from '../../hooks/useInput';
import { FILTER, FILTER_TYPE } from '../../constant/apiUrl';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import { filterColList } from '../../constant/studio';
import FilterRowComponent from './components/FilterRowComponent';
import FilterList from './components/FilterList';

const Filter = () => {
  const navigate = useNavigate();
  const [word, setWord, resetWord] = useInput('');
  const [activeYn, setActiveYn] = useState('Y');
  const [filterList, setFilterList] = useState<any>([]);
  const [filterTypeList, setFilterTypeList] = useState([]);
  const [selectedFilterTypeId, setSelectedFilterTypeId] = useState('');
  const [viewType, setViewType] = useState('normal');

  console.log(filterTypeList);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = {
    activeYn,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    searchType: 'NAME',
    word,
    studioCameraFilterTypeId: selectedFilterTypeId,
  };

  console.log(word);

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log('함수시작');
    if (e.key === 'Enter' && word) {
      setPageNumber(0);
      getFilterList();
    }
    if (e.key === 'Enter' && !word) {
      getFilterList();
    }
  };

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate('/filter/edit', {
      state: { prevId: state?.id, prevTotalCount: totalCount },
    });
  };

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = filterList[dragIndex];
      const hoverItem = filterList[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setFilterList((filterList: any) => {
        const updatedPets = [...filterList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [filterList],
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getFilterList = useCallback(
    async (type = '') => {
      if (type === 'sequence') {
        try {
          const resp = await axios.get(FILTER, {
            params: { ...params, limit: 10000, offset: 0 },
          });
          setTotalCount(resp.data.count);
          setFilterList(resp.data.studioCameraFilterList);
        } catch (err) {
          console.log(err);
        }
      } else {
        try {
          const resp = await axios.get(FILTER, { params });
          setTotalCount(resp.data.count);
          setFilterList(resp.data.studioCameraFilterList);
        } catch (err) {
          console.log(err);
        }
      }
    },
    [params],
  );

  const getFilterType = async () => {
    const typeParams = { limit: 100, offset: 0 };
    try {
      const resp = await axios.get(FILTER_TYPE, { params: typeParams });
      setFilterTypeList(resp.data.adminStudioCameraFilterTypeList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelect = (e: React.MouseEvent<HTMLLIElement>, setState: any) => {
    setState(e.currentTarget.title);
    setPageNumber(0);
  };

  const handleFilterTypeOption = (list: any) => {
    const initial = { text: '전체', value: '' };
    const option = list?.map((el: any) => ({
      text: el?.name,
      value: el?.id,
    }));
    return [initial, ...option];
  };

  // const changeSequence = async () => {
  //   const ids = filterCategoryList.map((el: any) => el.id);
  //   try {
  //     const resp = await axios.post(`${FILTER_TYPE}/sequence`, { ids });
  //     console.log(resp);
  //     alert('저장되었습니다.');
  //     setViewType('normal');
  //     getFilterCategoryList();
  //   } catch (err) {
  //     console.log(err);
  //     alert('잠시후 다시 이용해주세요.');
  //   }
  // };

  const changeSequence = async () => {
    const ids = filterList.map((el: any) => el.id);
    try {
      await axios.post(`${FILTER}/sequence`, { ids });
      alert('저장되었습니다.');
      setViewType('normal');
      getFilterList('normal');
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getFilterList();
    getFilterType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getFilterList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, selectedFilterTypeId, activeYn]);

  useEffect(() => {
    if (viewType === 'sequence') {
      getFilterList('sequence');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewType]);

  return (
    <>
      <TopContainer>
        {viewType === 'normal' ? (
          <>
            <NormalButton
              text="노출 순서 변경"
              className="cancelBtn"
              onSubmit={() => {
                setViewType('sequence');
              }}
            />
            <NormalButton
              text="필터 등록하기"
              onSubmit={() => {
                navigate('/filter/add', {
                  state: { prevTotalCount: totalCount },
                });
              }}
            />
          </>
        ) : (
          <>
            <NormalButton
              text="취소"
              className="cancelBtn"
              onSubmit={() => {
                setViewType('normal');
              }}
            />
            <NormalButton
              text="저장하기"
              onSubmit={() => {
                changeSequence();
              }}
            />
          </>
        )}
      </TopContainer>
      <MainContainer>
        {viewType === 'normal' && (
          <>
            <LineDiv />
            <ButtonWrapper>
              <RoundButton
                title="공개"
                selected={activeYn === 'Y'}
                onClick={() => {
                  setActiveYn('Y');
                }}
              />
              <RoundButton
                title="비공개"
                selected={activeYn === 'N'}
                onClick={() => {
                  resetWord();
                  setActiveYn('N');
                }}
              />
            </ButtonWrapper>
            <NavigateContainer>
              <SearchWrapper>
                <SearchInputWrapper>
                  <SearchInput
                    placeholder="필터 명을 입력해 주세요."
                    value={word}
                    onChange={setWord}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
              {totalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </NavigateContainer>
            <Table
              colList={filterColList}
              thead={
                <>
                  <ListHead title="아이콘" />
                  <ListHead title="필터 명" />
                  <ListSelect
                    title="카테고리"
                    list={handleFilterTypeOption(filterTypeList)}
                    onClick={handleSelect}
                    setState={setSelectedFilterTypeId}
                  />
                  <ListHead title="파일명" />
                </>
              }
            >
              {filterList?.map((el: any) => (
                <FilterRowComponent
                  key={el.id}
                  data={el}
                  onClick={goToDetail}
                  filterTypeList={filterTypeList}
                />
              ))}
            </Table>
          </>
        )}
        {viewType === 'sequence' && (
          // <SequenceContainer>
          <>
            <TagHead>
              <ListHead title="" />
              <ListHead title="아이콘" />
              <ListHead title="필터명" />
              <ListHead title="카테고리" />
              <ListHead title="파일명" />
            </TagHead>
            <Ul>
              {filterList?.map((item: any, index: number) => (
                <FilterList
                  key={item.id}
                  data={item}
                  moveListItem={movePetListItem}
                  index={index}
                  filterTypeList={filterTypeList}
                />
              ))}
            </Ul>
          </>
          // </SequenceContainer>
        )}
      </MainContainer>
    </>
  );
};

export default Filter;

const TopContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  margin-bottom: 29px;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 50px;
`;

const LineDiv = styled.div`
  width: 100%;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const NavigateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const Ul = styled.ul`
  width: 100%;
`;

// const SequenceContainer = styled.div`
//   height: 760px;
//   overflow: scroll;
// `;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 15px 0.5fr 0.7fr 1fr 0.7fr;
`;
