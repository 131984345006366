import React, {useEffect, useState, useCallback} from 'react';
import styled from 'styled-components';
import axios from "axios";

// component
import Table from '../../../components/common/List/Table';
import Modal from '../../../components/common/Modal/Modal';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import NormalButton from '../../../components/common/Button/NormalButton';
import Pagination from "../../../components/common/Pagination";

import ListHead from '../../../components/UI/ListHead';
import {EventPromotionModalRowComponent} from '../components/EventModalRowComponent';

// constant
import {postsPerPage} from "../../../constant/common";

// config
import {EVENT_PROMOTION} from '../../../constant/apiUrl';
import LabelTextAreaInput from "../../../components/common/Input/LabelTextAreaInput";
import RadioButton from "../../../components/common/Button/RadioButton";

const EventPromotion = ({ onSelect }) => {
    // 페이징
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [searchActiveYn, setSearchActiveYn] = useState('Y');

    // 데이터 세팅
    const [promotionList, setPromotionList] = useState([]);
    const [promotion, setPromotion] = useState({
        eventPromotionId: 0, name: '', nameEn: '',
        description: '', descriptionEn: '', activeYn: ''
    });

    // 서버에서 데이터 가져오기
    const eventPromotionApi = async (method, paramData) => {
        let config;
        if (method === 'get') {
            config = {
                method: method,
                url: `${EVENT_PROMOTION}?offset=${pageNumber * postsPerPage}&limit=10&activeYn=${paramData}`
            }
        } else if (method === 'detail') {
            config = {
                method: 'get',
                url: `${EVENT_PROMOTION}/${paramData}`
            }
        } else {
            config = {
                method: method,
                url: EVENT_PROMOTION,
                data: promotion
            }
        }

        await axios(config).then((res) => {
            if (method === 'get') {
                setPromotionList(res.data.eventPromotionList);
                setTotalCount(res.data.totalCount);
            } else if (method === 'detail') {
                setPromotion(res.data);
            } else {
                eventPromotionApi('get', 'Y');
                closeModal();
            }
        }).catch((err) => console.log(err));
    };

    const promotionSetData = useCallback(e => setPromotion({...promotion, [e.target.name]: e.target.value}), [promotion]);
    const resetInput = useCallback(e => setPromotion({...promotion, [e]: ''}), [promotion]);

    // modal 닫기
    const closeModal = () => {
        setPromotion({
            eventPromotionId: 0, name: '', nameEn: '',
            description: '', descriptionEn: '', activeYn: ''
        });
        setModalOpen(false);
    };


    useEffect(() => eventPromotionApi('get', searchActiveYn), [searchActiveYn]);

    return (
        <>
            {modalOpen && <Modal closeModal={closeModal} option={{class: 'compact'}}>
                <InputContainer>
                    <InputWrapper>
                        <LabelTextInput
                            name="name"
                            label="프로모션 이름"
                            value={promotion.name}
                            onChange={promotionSetData}
                            placeholder="프로모션 이름을 입력해주세요."
                            onReset={resetInput}
                            Required
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <LabelTextInput
                            name="nameEn"
                            label="프로모션 영어 이름"
                            value={promotion.nameEn}
                            onChange={promotionSetData}
                            placeholder="프로모션 영어 이름을 입력해주세요."
                            onReset={resetInput}
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <LabelTextAreaInput
                            name="description"
                            label="프로모션 설명"
                            value={promotion.description}
                            setValue={promotionSetData}
                            placeholder="프로모션 설명을 입력해주세요."
                            Required
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <LabelTextAreaInput
                            name="descriptionEn"
                            label="프로모션 영어 설명"
                            value={promotion.descriptionEn}
                            setValue={promotionSetData}
                            placeholder="프로모션 영어 설명을 입력해주세요."
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <SelectModule
                            name="activeYn"
                            placeholder="활성화 여부를 선택해주세요."
                            value={promotion.activeYn}
                            label="활성화 여부"
                            onChange={v => setPromotion({...promotion, activeYn: v})}
                            options={['Y', 'N']}
                        />
                    </InputWrapper>

                    {
                        modalMode === 'edit'
                            ? <NormalButton text="수정하기" onSubmit={() => eventPromotionApi('put')}/>
                            : <NormalButton text="저장하기" onSubmit={() => eventPromotionApi('post')}/>
                    }
                </InputContainer>
            </Modal>}

            <TopContainer>
                <NormalButton
                    text="추가하기"
                    onSubmit={() => {
                        setModalMode('add');
                        setModalOpen(true);
                    }}
                />
                <SpaceDiv value="30px"/>
                <RadioButton
                    value={searchActiveYn}
                    name="searchActiveYn"
                    label="활성여부"
                    text={['활성', '비활성']}
                    onChange={e => {setSearchActiveYn(e.target.value)}}
                    type="activeYn"
                />
            </TopContainer>
            <MainContainer>
                <PaginationWrapper>
                    {totalCount !== 0 && (
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalCount={totalCount}
                            currentPage={pageNumber}
                            pageDispatch={(e) => setPageNumber(e - 1)}
                        />
                    )}
                </PaginationWrapper>
                <Table
                    thead={<>
                        <ListHead title="id"/>
                        <ListHead title="이름"/>
                        <ListHead title="설명"/>
                        <ListHead title="사용된 이벤트 아아디"/>
                        <ListHead title=""/>
                    </>}
                    colList={searchActiveYn === 'Y' ? [50, 150, 300, 100, 50, 50] : [50, 150, 300, 100, 50]}
                >
                    {promotionList?.map(el => (
                        <EventPromotionModalRowComponent
                            key={el.eventPromotionId}
                            data={el}
                            active={searchActiveYn}
                            onEdit={async data => {
                                setModalMode('edit');
                                await eventPromotionApi('detail', data.eventPromotionId);
                                setModalOpen(true);
                            }}
                            onSelect={onSelect}
                        />
                    ))}
                </Table>
            </MainContainer>
        </>
    );
};

export default EventPromotion;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 20px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({value}) => value};
`;