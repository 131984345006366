import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination';

// config
import { BAUND_MUSIC_PROJECT } from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';

const BaundMusicProject = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );
  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate('/baundMusic/project/edit', {
      state: {
        prevId: state?.id,
        nextSequence: totalCount + 1,
      },
    });
  };

  const editSeq = () => {
    navigate('editSeq', {});
  };

  const getContents = useCallback(async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(`${BAUND_MUSIC_PROJECT}/${activationType}`, {
        params,
      });
      setTotalCount(resp.data.count);
      setContentsList(resp.data.adminBaundMusicProject);
    } catch (err) {
      console.log(err);
    }
  }, [activationType, pageNumber]);

  useEffect(() => {
    getContents();
  }, [activationType, pageNumber]);

  return (
    <Container>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <RoundButton
              title="활성"
              selected={activationType === 'active'}
              onClick={() => setActivationType('active')}
            />
            <RoundButton
              title="비활성"
              selected={activationType === 'inactive'}
              onClick={() => setActivationType('inactive')}
            />
          </SearchWrapper>
        </TopWrapper>
        <ButtonWrapper>
          {contentsList && (
            <>
              {activationType === 'active' && (
                <NormalButton
                  text="노출 순서 변경"
                  className="cancelBtn"
                  onSubmit={() => editSeq()}
                />
              )}
            </>
          )}
          <NormalButton
            text="프로젝트 등록"
            onSubmit={() => {
              navigate('/baundMusic/project/add', {
                state: {
                  nextSequence: totalCount + 1,
                  prevId: null,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[50, 50, 100, 100]}
          thead={
            <>
              <ListHead title="프로젝트 아이디" />
              <ListHead title="제목" />
              <ListHead title="목록용 설명" />
              <ListHead title="상세 화면용 설명" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td><ListItem text={el.id} /></td>
              <td><ListItem text={el.name} /></td>
              <td><ListItem text={el.description} /></td>
              <td><ListItem text={el.descriptionDetail} /></td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default BaundMusicProject;

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
