// 기간 데이터 유효성 확인
export const validateStrDate = (startDateStr, endDateStr) => {
	const startDate = new Date(startDateStr);
	const endDate = new Date(endDateStr);

	if (startDate > endDate) {
		alert('종료일시가 시작일시보다 작을 수 없습니다.');
		return false;
	}
	return true;
}

export const nowDateToSting = (date, utc) => {
	if (utc) return date.toISOString();
	
	let retStr = date.getFullYear() + '-';

	// 달 값 보정
	if ((date.getMonth()+1) < 10) retStr += '0';
	retStr += (date.getMonth()+1) + '-';

	// 일 값 보정
	if (date.getDate() < 10) retStr += '0';
	retStr += date.getDate() + ' ';

	// 시간 값 보정
	if (date.getHours() < 10) retStr += '0';
	retStr += date.getHours() + ':';

	// 분 값 보정
	if (date.getMinutes() < 10) retStr += '0';
	return retStr + date.getMinutes();
};

export const transObjectToOptions = (object) => {
	return Object.keys(object).map(key => {return {name:object[key], value: key}});
};

export const dotdotdot = (str, limit) => {
	if (str) {
		if (str.length > limit) return `${str.substring(0, limit - 1)}...`;
		else return str;
	} else {
		return '-';
	}
}

// 현재 시각을 다음 포맷의 문자열로 구하기 : yyyy.mm.dd_hh.MM.ss
export const getNowDateStringForFileName = () => {
	let today = new Date();
	return today.getFullYear()
			+ '.' + ((today.getMonth() + 1) > 9 ? (today.getMonth() + 1).toString() : "0" + (today.getMonth() + 1))
			+ '.' + (today.getDate() > 9 ? today.getDate().toString() : "0" + today.getDate().toString())
			+ '_' + (today.getHours() > 9 ? today.getHours().toString() : "0" + today.getHours().toString())
			+ '.' + (today.getMinutes() > 9 ? today.getMinutes().toString() : "0" + today.getMinutes().toString())
			+ '.' + (today.getSeconds() > 9 ? today.getSeconds().toString() : "0" + today.getSeconds().toString());
}

// 현재 시각을 다음 포맷의 문자열로 구하기(시작일 초기 값에 사용) : yyyy-mm-dd hh:MM
export const getNowDateStringForStartDate = () => {
	const today = new Date();
	return today.getFullYear()
			+ '-' + ('0' + (today.getMonth() + 1)).slice(-2)
			+ '-' + ('0' + today.getDate()).slice(-2)
			+ 'T' + ('0' + today.getHours()).slice(-2)
			+ ':' + ('0' + today.getMinutes()).slice(-2);
}

//URL validation check
export const isValidURL = (url) =>{
	// const RegExp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
	// const RegExp = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
	const RegExp = /^(http|https)(:\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/;
	if(RegExp.test(url)){
		return true;
	}else{
		return false;
	}
}

export const isNotInOrderStrDateWithAlert = (
		alertPrefix,
		strDateBeforeDescription,
		strDateBefore,
		strDateAfterDescription,
		strDateAfter
) => {
	if (strDateBefore && strDateAfter && !isInOrderStrDate(strDateBefore, strDateAfter)) {
		alert(
				alertPrefix + '\n'
				+ strDateBeforeDescription + ':' + strDateBefore + '\n'
				+ strDateAfterDescription + ':' + strDateAfter);
		return true;
	}
	return false;
}

export const isInOrderStrDate = (strDateBefore, strDateAfter) => {
	const dateBefore = new Date(strDateBefore);
	const dateAfter = new Date(strDateAfter);
	return dateBefore <= dateAfter;
}

export const hasSpecialSymbol = (str) => {
	const specialPattern = /[`~!@#$%^&*|\\'";:/?]/gi;
	return specialPattern.test(str);
}

export const isPast = (strDate) => {
	if (isInOrderStrDate(strDate, nowDateToSting(new Date(), false))) {
		return true;
	}
	return false;
}

export const isBlank = (inputText) => {
	const blankPattern = /^\s+|\s+$|^\n+|\n+$/g;
	return inputText.replace(blankPattern, '') === '';
}