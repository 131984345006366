import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';
import useOutSideClick from '../../../hooks/useOutSideClick';

interface optionsType {
  moodName?: string;
  id: number;
  genre?: string;
}

interface TagMenuSelectProps {
  name: string;
  placeholder: string;
  value: string[];
  label: string;
  onClick?: any;
  tagIds?: number[];
  Required?: boolean;
  options?: Array<optionsType>;
}

const TagMenuSelect = ({
  placeholder,
  value,
  label,
  onClick,
  Required,
  options,
  tagIds,
  name,
}: TagMenuSelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  // 장르 갯수 제한

  // 장르 3개 선택 시 자동으로 모달 닫기
  useEffect(() => {
    if (value?.length === 5) {
      setIsOpen(false);
    }
    //
    // if (value?.length > 5) {
    //   alert('장르 선택은 5개까지 가능합니다.');
    //   setIsOpen(false);
    // }
  }, [value]);

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  // const onOptionClicked = (option: optionsType) => () => {
  //   // 특정 단어 삭제
  //   const duplicateCheck = value?.includes(option.genre);
  //   if (duplicateCheck) {
  //     const removeOption = value?.filter((el) => el !== option.genre);
  //     onChange(removeOption);
  //     return;
  //   }
  //
  //   // 장르 갯수 제한
  //   if (value?.length > 10) {
  //     alert('장르 선택은 10개까지 가능합니다.');
  //     setIsOpen(false);
  //     return;
  //   }
  //
  //   // 장르 3개 선택 시 자동으로 모달 닫기
  //   if (value?.length === 11) {
  //     setIsOpen(false);
  //   }
  //
  //   onChange([...value, option.genre]);
  // };

  const changeHandlerBgColor = (option: optionsType) => {
    if (tagIds?.includes(option?.id)) {
      if (name === 'mood') {
        return 'otherSelectOption';
      }
      if (tagIds[0] === option.id) {
        return 'firstSelectOption';
      }
      return 'otherSelectOption';
    }
    return '';
  };

  const changeOptionColor = (option: string[]) => {
    const colorOptions = option?.join(', ');
    const commaIndex = colorOptions?.indexOf(',');
    const otherOption = colorOptions?.substring(
      commaIndex,
      colorOptions.length,
    );
    if (name === 'mood') {
      return <>{colorOptions}</>;
    }
    return option?.length > 1 ? (
      <>
        <TitleOption>{option[0]}</TitleOption>
        {otherOption}
      </>
    ) : (
      <TitleOption>{option}</TitleOption>
    );
  };

  return (
    <SelectBox>
      <InputText>
        {label}
        {Required && <span>*</span>}
      </InputText>
      <DropDownContainer ref={ref}>
        <DropDownHeader onClick={toggling}>
          {!value || value?.length === 0 ? (
            <PlaceHolder>{placeholder}</PlaceHolder>
          ) : (
            changeOptionColor(value)
          )}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {options.map((option) => {
                return (
                  <ListItem
                    className={changeHandlerBgColor(option)}
                    onClick={() => {
                      onClick(option, name);
                    }}
                    key={Math.random()}
                  >
                    {name === 'genre' && <>{option.genre}</>}
                    {name === 'mood' && <>{option.moodName}</>}
                    {name === 'connectedCategoryId' && <>{option.name}</>}
                  </ListItem>
                );
              })}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </SelectBox>
  );
};

export default TagMenuSelect;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const DropDownContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  min-height: 52px;
  padding: 10px 20px;
  border-radius: 6px;
  background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
    ${({ theme }) => theme.color.subBrightGray};
  background-size: 32px;
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
`;

const PlaceHolder = styled.span`
  font-weight: 500;
  color: ${({ theme }) => theme.color.subGray};
`;

const TitleOption = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 1;
`;

const DropDownList = styled.ul`
  display: flex;
  max-height: 200px;
  overflow: scroll;
  flex-wrap: wrap;
  margin: 0;
  padding: 13px 14px;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.li`
  list-style: none;
  padding: 8px 12px;
  margin: 7px 6px;
  border: 0.5px solid ${({ theme }) => theme.color.subGray};
  border-radius: 30px;
  font-size: 13px;
  color: ${({ theme }) => theme.color.subGray};

  &.firstSelectOption {
    border: 0.5px solid ${({ theme }) => theme.color.mainPurple};
    background-color: ${({ theme }) => theme.color.mainPurple};
    color: #ffff;
  }
  &.otherSelectOption {
    border: 0.5px solid ${({ theme }) => theme.color.subDarkGray};
    background-color: ${({ theme }) => theme.color.subDarkGray};
    color: #ffff;
  }
`;
