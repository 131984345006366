import React, { useState } from 'react';
import styled from 'styled-components';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

interface TagTextInputProps {
  placeholder: string;
  keyWord: string[];
  label: string;
  setKeyWord: (strings: string[]) => void;
  addWord: string;
  setAddWord: (value: string) => void;
  Required?: boolean;
  className?: string;
}

const TagTextInput = ({
  placeholder,
  keyWord,
  label,
  setKeyWord,
  addWord,
  setAddWord,
  Required,
  className,
}: TagTextInputProps) => {
  const clearInput = () => {
    setAddWord('');
  };

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addSelectedTag();
    }
  };

  const inputCheck = () => {
    // 중복 체크
    const duplicateCheck = keyWord.includes(addWord);
    if (duplicateCheck) {
      alert('중복된 태그입니다.');
      return false;
    }
    // 공백 체크
    const blankPattern = /\s/g;
    if (!addWord.replace(blankPattern, '').length) {
      alert('값을 입력해주세요.');
      return false;
    }

    // 특수문자 체크
    const specialPattern = /[`~!@#$%^&*|\\'";:/?]/gi;
    if (specialPattern.test(addWord)) {
      alert('특수문자는 사용할 수 없습니다.');
      return false;
    }

    // 태그 갯수 제한
    if (keyWord.length > 9) {
      alert('태그는 10개까지 등록 가능합니다.');
      return false;
    }

    return true;
  };

  const addSelectedTag = () => {
    if (inputCheck()) {
      setKeyWord([...keyWord, addWord]);
    }
    clearInput();
  };

  const deleteSelectedTag = (el: string) => {
    const arr = keyWord.filter((tag) => tag !== el);
    setKeyWord(arr);
  };

  const handleInputWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAddWord(value);
  };

  return (
    <>
      <InputBox id="pid" className={className}>
        <InputText>
          {label}
          {Required && <span>*</span>}
        </InputText>
        <input
          id="tagWord"
          value={addWord}
          placeholder={placeholder}
          onChange={handleInputWord}
          onKeyPress={enterKeyPress}
        />
        {addWord && (
          <InputDeleteButton
            onClick={() => {
              clearInput();
            }}
          />
        )}
      </InputBox>
      {keyWord.length !== 0 &&
        keyWord.map((el) => (
          <SelectTag key={el}>
            <SelectTagText>{el}</SelectTagText>
            <TagDeleteButton
              onClick={() => deleteSelectedTag(el)}
              className={className}
            />
          </SelectTag>
        ))}
    </>
  );
};

export default TagTextInput;

const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-content: center;

  input {
    width: 100%;
    height: 52px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  input::placeholder {
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
  }

  &.unEditable {
    input {
      display: none;
    }
  }
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputDeleteButton = styled.button`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 38px;
  right: 10px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;

const SelectTag = styled.div`
  display: inline-flex;
  align-items: center; //세로가운데
  margin: 12px 10px 0 0;
  padding: 8px 10px 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  border-radius: 30px;
`;

const SelectTagText = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagDeleteButton = styled.button`
  width: 18px;
  height: 18px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
  &.unEditable {
    display: none;
  }
`;
