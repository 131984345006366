import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// common
import axios from 'axios';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';

// constant
import {
  celebTypeOptions,
  memberRestrictOptions,
  searchMemberColList,
  searchMemberSearchTypeList,
  userTypeOptions,
} from '../../constant/member';
import { postsPerPage } from '../../constant/common';

// hooks
import useInput from '../../hooks/useInput';
import saveStorage from '../../hooks/saveStorage';

// config
import { SEARCH_MEMBER } from '../../constant/apiUrl';

// component
import SearchMemberRowComponent from './components/SearchMemberRowComponent';
import NormalButton from '../../components/common/Button/NormalButton';

const SearchMember = () => {
  const navigate = useNavigate();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [memberList, setMemberList] = useState([]);
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState<string>(storageType || '');
  const [isRestricted, setIsRestricted] = useState('');
  const [filterIsRestricted, setFilterIsRestricted] = useState('');
  const [loginType, setLoginType] = useState('');
  const [filterLoginType, setFilterLoginType] = useState('');
  const [celebType, setCelebType] = useState('');
  const [filterCelebType, setFilterCelebType] = useState('');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );
  // 검색어 인코딩
  const encodeStr = encodeURI(word);

  const params = {
    isRestricted,
    loginType,
    type,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    verifiedYn: celebType,
  };

  useEffect(() => {
    if (storagePageNumber) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('searchType', '');
      window.localStorage.setItem('searchWord', '');
    }
  }, [storagePageNumber]);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      // 인덱스 string 검색 차단
      if (type === 'I' && !Number(word)) {
        alert('인덱스는 숫자만 검색 가능합니다.');
        return;
      }
      if (!type) {
        setType('I');
        params.type = 'I';
      }
      setPageNumber(0);
      params.offset = 0;
      searchMember();
    }
  };

  // member 조회
  const searchMember = async () => {
    try {
      const resp = await axios.get(SEARCH_MEMBER, {
        params,
      });
      setMemberList(resp.data.memberList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  useEffect(() => {
    if (storagePageNumber === '0') {
      params.offset = 0;
      setPageNumber(0);
    }
    searchMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, isRestricted, loginType, celebType]);

  useEffect(() => {
    setPageNumber(0);
    setIsRestricted(filterIsRestricted);
  }, [filterIsRestricted])

  useEffect(() => {
    setPageNumber(0);
    setLoginType(filterLoginType);
  }, [filterLoginType])

  useEffect(() => {
    setPageNumber(0);
    setCelebType(filterCelebType);
  }, [filterCelebType])

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={searchMemberSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="회원 등록하기"
            onSubmit={() => {
              navigate('/member/searchMember/add');
            }}
            className=""
          />
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={searchMemberColList}
          thead={
            <>
              <ListHead title="인덱스" />
              <ListHead title="Pid" />
              <ListHead title="이메일" />
              <ListSelect
                title="가입 유형"
                list={userTypeOptions}
                onClick={handleSelect}
                setState={setFilterLoginType}
              />
              <ListSelect
                title="셀럽 인증"
                list={celebTypeOptions}
                onClick={handleSelect}
                setState={setFilterCelebType}
              />
              <ListHead title="계정 생성일" />
              <ListHead title="벌점" />
              <ListSelect
                title="계정 상태"
                list={memberRestrictOptions}
                onClick={handleSelect}
                setState={setFilterIsRestricted}
              />
              <ListHead title="" />
            </>
          }
        >
          {memberList?.map((el: any) => (
            <SearchMemberRowComponent
              key={el.id}
              data={el}
              saveStorage={() => {
                saveStorage(pageNumber, type, word);
              }}
            />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default SearchMember;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;
