// 메뉴
export const MenuList = [
	{
		id: 1,
		name: `회원 관리`,
		address: '/member',
		subMenu: [
			{id: 1, name: '계정 조회', address: '/member/searchMember'},
			{id: 2, name: '접속 이력', address: '/member/history'},
			{id: 3, name: '탈퇴 이력', address: '/member/resign'},
		],
	}, {
		id: 2,
		name: '비트 관리',
		address: '/beat',
		subMenu: [
			{id: 1, name: '장르', address: '/beat/genre'},
			{id: 2, name: '무드', address: '/beat/mood'},
			{id: 3, name: '비트', address: '/beat/list'},
			{id: 4, name: '비트메이커', address: '/beat/beatmaker'},
			{id: 5, name: '추천 비트', address: '/beat/curatedBeat'},
		],
	}, {
		id: 3,
		name: '영상 관리',
		address: '/video',
		subMenu: [
			{id: 1, name: '영상 조회', address: '/video/searchVideo'},
			{id: 2, name: '비트 수정', address: '/video/changeBeat'},
		],
	}, {
		id: 4,
		name: '둘러보기',
		address: '/explore',
		subMenu: [
			{id: 1, name: '전체 탭 설정', address: '/explore/allTab',},
			{id: 2, name: '추천 장르 탭 설정', address: '/explore/genreTab',},
			{id: 3, name: '공식 계정 컬렉션', address: '/explore/collection',},
			{id: 4, name: '커뮤니티 카테고리', address: '/explore/communityCategory',},
			{id: 5, name: '추천 영상', address: '/explore/curatedArticle',},
			{id: 6, name: '추천 태그의 영상', address: '/explore/curatedTagsArticle',},
			{id: 7, name: '추천 제시어의 영상', address: '/explore/curatedSuggestWordArticle',},
			{id: 8, name: '둘러보기 배너 관리', address: '/explore/banner',},
		],
	}, {
		id: 5,
		name: '운영 관리',
		address: '/operation',
		subMenu: [
			{id: 1, name: '신고 유형', address: '/operation/management',},
			{id: 2, name: 'rekognition 기준', address: '/operation/rekognition',},
			{id: 3, name: '이벤트', address: '/operation/event'},
			{id: 4, name: '메인 공지', address: '/operation/notice',},
			{id: 5, name: '일반 공지', address: '/operation/noticeNormal',},
			{id: 6, name: '금칙어', address: '/operation/word',},
			{id: 7, name: 'reserve id', address: '/operation/reservation',},
			{id: 8, name: '앱 버전', address: '/operation/version',},
			{id: 9, name: 'Baund 공식 계정 관리', address: '/operation/officialAccount',},
			{id: 10, name: '광고 푸시', address: '/operation/push',},
			{id: 11, name: '커뮤니티 게시글 고정 배너 설정', address: '/operation/communityFixArticle',},
			{id: 12, name: '커뮤니티 게시글 고정 설정', address: '/operation/communityCuratedArticle',},
			{id: 13, name: '개별 푸시', address: '/operation/individualPush',},
			{id: 14, name: '커뮤니티 게시판 배너 관리', address: '/operation/communityBoardBanner',},
			{id: 15, name: '제시어 카테고리 관리', address: '/operation/suggestWordCategory'},
			{id: 16, name: '제시어 관리', address: '/operation/suggestWord'},
			{id: 17, name: '이벤트 랜덤 제시어 관리', address: '/operation/suggestWordRandom'},
			{id: 18, name: '커뮤니티 게시글 검색', address: '/operation/communityBoardArticleSearch'},
		],
	}, {
		id: 6,
		name: 'CS 관리',
		address: '/cs',
		subMenu: [
			{id: 1, name: '신고 내역 - 영상', address: '/cs/blockVideo',},
			{id: 2, name: '신고 내역 - 영상 외', address: '/cs/blockElse',},
			{id: 3, name: 'Rekognition 영상', address: '/cs/rekognitionVideo',},
			{id: 4, name: '신고 제재 관리', address: '/cs/restrictMember',},
		],
	}, {
		id: 7,
		name: '내 정보 관리',
		address: '/account',
		subMenu: [
			{id: 1, name: '내 계정 관리', address: '/account',},
		],
	}, {
		id: 8,
		name: '권한 관리',
		address: '/admin',
		subMenu: [
			{id: 1, name: '관리자 계정 조회', address: '/admin/adminAccount',},
			{id: 2, name: '권한 설정', address: '/admin/adminRole',},
			{id: 3, name: '메뉴 설정', address: '/admin/adminCategory',},
		],
	}, {
		id: 9,
		name: `필터 관리`,
		address: '/filter',
		subMenu: [
			{id: 1, name: '카테고리', address: '/filter/category',},
			{id: 2, name: '필터', address: '/filter'},
		],
	}, {
		id: 10,
		name: `시스템 관리`,
		address: '/system',
		subMenu: [
			{id: 1, name: '서버 데이터 갱신', address: '/system/updateData',},
			{id: 2, name: '캐시 초기화', address: '/system/refreshCache'},
			{id: 3, name: '메인터넌스 관리', address: '/system/maintenance'},
			{id: 4, name: '바운드 API 카테고리 관리', address: '/system/baundApiCategory',},
			{id: 5, name: '바운드 API 관리', address: '/system/baundApi'},
		],
	}, {
		id: 11,
		name: `Baund Music`,
		address: '/baundMusic',
		subMenu: [
			{id: 1, name: '발매 음원', address: '/baundMusic/release'},
			{id: 2, name: '프로젝트 관리', address: '/baundMusic/project'},
		],
	}, {
		id: 12,
		name: `컬처`,
		address: '/culture',
		subMenu: [
			{id: 1, name: '채널 관리', address: '/culture/channel'},
			{id: 2, name: '프로그램 관리', address: '/culture/program'},
			{id: 3, name: '에피소드 관리', address: '/culture/episode'},
			{id: 4, name: '상단 고정 공연 관리', address: '/culture/curatedPerformance'},
			{id: 5, name: '공연 카테고리 관리', address: '/culture/performanceCategory'},
			{id: 6, name: '공연 관리', address: '/culture/performance'},
			{id: 7, name: '매거진 카테고리 관리', address: '/culture/magazine-category'},
			{id: 8, name: '매거진 관리', address: '/culture/magazine'},
			{id: 9, name: '추천 매거진', address: '/culture/curatedMagazine'},
		],
	}, {
		id: 13,
		name: `리워드 관리`,
		address: '/rewards',
		subMenu: [
			{id: 1, name: '미션 관리', address: '/rewards/mission-list'},
			{id: 2, name: '포인트 안내 영역 관리', address: '/rewards/point-guide-area-list'},
			{id: 3, name: '마스터 데이터 관리', address: '/rewards/master'},
			{id: 4, name: '재화 지급', address: '/rewards/give-currency'},
		],
	}, {
		id: 14,
		name: `캠페인 관리`,
		address: '/campaign',
		subMenu: [
			{id: 1, name: '골드 캠페인', address: '/campaign/goldCampaign'},
			// 임시 운영 (prod 배포 전 삭제 필요)
			{id: 2, name: '당첨 이력 삭제', address: '/campaign/history-delete'},
		],
	}, {
		id: 15,
		name: `배틀`,
		address: '/battle',
		subMenu: [
			{id: 1, name: '기본 설정', address: '/battle/config'},
			{id: 2, name: '안내 설정', address: '/battle/config-any-is'},
			{id: 3, name: '관리', address: '/battle'},
		],
	}, {
		id: 16,
		name: `쿠폰 관리`,
		address: '/coupons',
		subMenu: [
			{id: 1, name: '바운드 골드 쿠폰', address: '/coupons/coupon-projects'},
		]
	}, {
		id: 17,
		name: `데이터 관리`,
		address: '/datas',
		subMenu: [
			{id: 1, name: '마스터 메시지', address: '/datas/masterMessage'},
		],
	}, {
		id: 18,
		name: `광고`,
		address: '/advertisement',
		subMenu: [
			{id: 1, name: '광고주 관리', address: '/advertisement/advertiser'},
			{id: 2, name: '광고 관리', address: '/advertisement/advertisementSummary'},
			{id: 3, name: '광고 랜딩 페이지 관리', address: '/advertisement/advertisementLanding'},
			{id: 4, name: '광고 캠페인 관리', address: '/advertisement/advertisementCampaign'},
			{id: 5, name: '메인 배너 순서', address: '/advertisement/advertisementCampaignEditSeq_EL'},
			{id: 6, name: '메인 팝업 순서', address: '/advertisement/advertisementCampaignEditSeq_NM'},
		],
	}, {
		id: 19,
		name: `바운드 프로젝트`,
		address: '/baundProject',
		subMenu: [
			{id: 1, name: '프로젝트 관리', address: '/baundProject/baundProject'},
			{id: 2, name: 'QnA 관리', address: '/baundProject/baundProjectQna'},
			{id: 3, name: '유의사항 관리', address: '/baundProject/baundProjectCaution'},
			{id: 4, name: 'FAQ 관리', address: '/baundProject/baundProjectFaq'},
		],
	},
];