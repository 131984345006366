import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';

import styled from 'styled-components';

// common
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TextField from '../../components/common/TextField';

// hooks
import { useAppSelector } from '../../redux/store/hooks';

// configs
import { ADD_ACCOUNTS, SIGNIN } from '../../constant/apiUrl';
import EditButton from '../../components/common/Button/EditButton';

type data = {
  [key: string]: any;
};

//초기 계정 데이터
const initialAccountData: data = {
  id: '',
  name: '',
  password: '',
  email: '',
  mobile: '',
  countryCode: '82',
  roles: [],
};

const MyAccount = () => {
  const userData = useAppSelector((state) => state.user.user);
  const [accountData, setAccountData] = useState(initialAccountData);
  const [newPasswordData, setNewPasswordData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  });
  const [checkPassword, setCheckPassword] = useState<any>('');
  const [checkNewPassword, setCheckNewPassword] = useState<any>('');
  const { id, name, password, email, mobile, roles, countryCode } = accountData;

  // 정보 바뀌었는지 체크
  const isChanged = userData.name !== name || `0${userData.mobile}` !== mobile;

  // 초기 유저 데이터 값 적용
  const handleSetInitialData = useCallback(() => {
    setAccountData({ ...userData, mobile: `0${userData.mobile}` });
  }, [userData]);

  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      if (name === 'newPassword' || name === 'confirmNewPassword') {
        if (checkNewPassword) {
          setCheckNewPassword('');
        }
        setNewPasswordData({ ...newPasswordData, [name]: value });
        return;
      }
      setAccountData({ ...accountData, [name]: value });
    },
    [accountData, checkNewPassword, newPasswordData],
  );

  const handleResetData = useCallback(
    (e) => {
      if (e === 'newPassword' || e === 'confirmNewPassword') {
        setNewPasswordData({ ...newPasswordData, [e]: '' });
      }
      setAccountData({ ...accountData, [e]: initialAccountData[e] });
    },
    [accountData, newPasswordData],
  );

  // 로그인 로직으로 현재 비밀번호 확인
  const checkCurPassword = async () => {
    const data = { email, password };
    try {
      await axios.post(SIGNIN, data, {});
      setCheckPassword('curCorrect');
    } catch (error: any) {
      setCheckPassword('curError');
    }
  };

  // 현재 비밀번호 엔터키 눌렀을 때
  // const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   if (e.key === 'Enter') {
  //     checkCurPassword().then((r) => r);
  //   }
  // };

  // 개인 정보 수정
  const changeAccountDetail = async () => {
    const changeAccountData = {
      name,
      mobile: mobile.slice(1, 11),
      countryCode,
    };
    try {
      await axios.post(`${ADD_ACCOUNTS}/${id}`, changeAccountData, {});
      alert('정보가 변경되었습니다.');
      window.location.replace('/account');
    } catch (err) {
      // console.log(err);
    }
  };

  // 개인 비밀번호 수정
  const changePassword = () => {
    const { newPassword, confirmNewPassword } = newPasswordData;
    const adminAccountPasswordUpdateRequest = {
      currentPassword: password,
      password: newPassword,
    };
    if (password === newPassword) {
      return setCheckNewPassword('현재 비밀번호와 새 비밀번호가 같습니다.');
    }
    if (newPassword !== confirmNewPassword) {
      return setCheckNewPassword('입력하신 비밀번호와 일치하지 않습니다.');
    }

    return axios
      .post(
        `${ADD_ACCOUNTS}/${id}/modify`,
        adminAccountPasswordUpdateRequest,
        {},
      )
      .then(() => {
        if (isChanged) {
          changeAccountDetail();
        }
        alert('비밀번호가 변경되었습니다.');
        window.location.replace('/account');
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    handleSetInitialData();
  }, [handleSetInitialData]);

  useEffect(() => {
    if (!password) {
      setCheckPassword('');
    }
  }, [password]);
  return (
    <>
      <TopContainer>
        <TopWrapper>
          <div />
          <ButtonWrapper>
            {(isChanged || newPasswordData.newPassword) && (
              <NormalButton
                text="저장하기"
                onSubmit={changePassword}
                className=""
              />
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="이름"
            value={name}
            placeholder="이름을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="이메일" text={email} type="" />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="mobile"
            label="휴대폰 번호"
            value={mobile}
            placeholder="휴대폰 번호를 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            type="mobile"
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="권한" text={roles?.join(', ')} type="" />
        </InputWrapper>
        <SpaceDiv />
        <InputWrapper>
          <PasswordContainer>
            <LabelTextInput
              name="password"
              label="비밀번호 수정"
              value={password || ''}
              placeholder="현재 비밀번호를 입력해주세요."
              onChange={handleInputSetData}
              onReset={handleResetData}
              type="passwordInput"
            />
            {checkPassword === 'curError' && (
              <ErrorText>비밀번호가 일치하지 않습니다.</ErrorText>
            )}
            {checkPassword === 'curCorrect' && (
              <CorrectText>비밀번호가 일치합니다.</CorrectText>
            )}
            <EditButton
              text="확인"
              className="labelInputBtn"
              onClick={checkCurPassword}
            />
          </PasswordContainer>
        </InputWrapper>
        <SpaceDiv />
        {checkPassword === 'curCorrect' ? (
          <PasswordContainer>
            <PasswordWrapper>
              <LabelTextInput
                name="newPassword"
                label="새 비밀번호"
                value={newPasswordData?.newPassword}
                placeholder="새 비밀번호를 입력해주세요."
                onChange={handleInputSetData}
                onReset={handleResetData}
                type="passwordInput"
              />
            </PasswordWrapper>
            <Space />
            <PasswordWrapper>
              <LabelTextInput
                name="confirmNewPassword"
                label="새 비밀번호 확인"
                value={newPasswordData?.confirmNewPassword}
                placeholder="새 비밀번호를 입력해주세요."
                onChange={handleInputSetData}
                onReset={handleResetData}
                type="passwordInput"
              />
              <ErrorText>{checkNewPassword || ''}</ErrorText>
            </PasswordWrapper>
          </PasswordContainer>
        ) : (
          <PasswordContainer>
            <PasswordWrapper>
              <TextField
                label="새 비밀번호"
                type="unActive"
                text="새 비밀번호를 입력해주세요."
              />
            </PasswordWrapper>
            <Space />
            <PasswordWrapper>
              <TextField
                label="새 비밀번호 확인"
                type="unActive"
                text="새 비밀번호를 입력해주세요."
              />
              <ErrorText>{checkNewPassword || ''}</ErrorText>
            </PasswordWrapper>
          </PasswordContainer>
        )}
      </MainContainer>
    </>
  );
};

export default MyAccount;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 100px;
`;

const InputWrapper = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -30px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;

const CorrectText = styled.span`
  position: absolute;
  bottom: -30px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainPurple};
`;

const PasswordContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PasswordWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Space = styled.div`
  margin: 0 15px;
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-top: 40px;
`;
