import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

// common
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';

// definition
import { officialAccountRowComponentDataProps } from '../../definitions/operation';

// config
import { OFFICIAL_ACCOUNTS } from '../../constant/apiUrl';

// constant
import { officialAccountColList } from '../../constant/operation';

// component
import OfficialAccountRowComponent from './components/OfficialAccountRowComponent';

const OfficialAccount = () => {
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState([]);

  // 배너 리스트 불러오기
  const getAccountList = useCallback(async () => {
    try {
      const resp = await axios.get(OFFICIAL_ACCOUNTS, {
        params: { limit: 1000, offset: 0 },
      });
      setAccountData(resp.data.officialAccountList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // rekognition 수정 버튼 클릭
  const editRekognition = (data: officialAccountRowComponentDataProps) => {
    navigate('/operation/officialAccount/edit', { state: data });
  };

  useEffect(() => {
    getAccountList();
  }, [getAccountList]);

  return (
    <MainContainer>
      <Table
        colList={officialAccountColList}
        thead={
          <>
            <ListHead title="Profile Image" />
            <ListHead title="Pid" />
            <ListHead title="이름" />
            <ListHead title="" />
          </>
        }
      >
        {accountData?.map((el: officialAccountRowComponentDataProps) => (
          <OfficialAccountRowComponent
            key={el.id}
            data={el}
            onClick={editRekognition}
          />
        ))}
      </Table>
    </MainContainer>
  );
};

export default OfficialAccount;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;
