import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { TAB_GENRE } from '../../constant/apiUrl';
import TitleSelect from '../../components/common/Select/TitleSelect';

const GenreTabCategory = () => {
  const [tabGenreList, setTabGenreList] = useState([]);
  const [selectedGenre, setSelectedGenre] = useState<any>();
  const genreList = [
    { id: 1, title: '최신 비트', link: 'recentlyBeat' },
    { id: 2, title: '추천 비트메이커', link: 'recommendBeatMaker' },
    { id: 3, title: '발매 예정 비트', link: 'upcomingBeat' },
    // { id: 4, title: '인기 비트', link: 'hotBeat' },
  ];

  // 장르탭 리스트 불러오기
  const getTabGenreList = useCallback(async () => {
    const params = {
      limit: 100,
      offset: 0,
    };
    try {
      const resp = await axios.get(TAB_GENRE, { params });
      setTabGenreList(resp.data.masterRecommendTabGenreList);
      setSelectedGenre(resp.data.masterRecommendTabGenreList[0]);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getTabGenreList();
  }, [getTabGenreList]);

  return (
    <MainContainer>
      <TitleSelect setState={setSelectedGenre} options={tabGenreList} />

      {genreList.map((item) => {
        const { id, title, link } = item;
        return (
          <Link
            key={id}
            id={String(id)}
            to={link}
            state={{ detailData: selectedGenre, tabType: 'GENRE' }}
          >
            <ListItem>
              <ListText>{title}</ListText>
            </ListItem>
          </Link>
        );
      })}
    </MainContainer>
  );
};

export default GenreTabCategory;

const MainContainer = styled.div`
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-start;
  //align-items: flex-start;
  width: 100%;
  margin-top: 32px;
`;

const ListItem = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const ListText = styled.span`
  ${({ theme }) => theme.font.listFont};
`;
