import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import EditButton from '../../components/common/Button/EditButton';
import Table from '../../components/common/List/Table';

// config
import { MASTER_ROLE_LIST } from '../../constant/apiUrl';
import { postsPerPage } from '../../constant/common';
import Pagination from '../../components/common/Pagination';
import NormalButton from '../../components/common/Button/NormalButton';

interface RoleList {
  id: number;
  userRole: string;
}

const AdminRoleManagement = () => {
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const [adminRoleList, setAdminRoleList] = useState<any>([]);

  const editCategory = (role: any) => {
    navigate('/admin/adminRole/editCategory', { state: role });
  };

  const editRole = (state: any) => {
    navigate('/admin/adminRole/edit', {
      state: {
        roleId: state?.id,
        userRole: state?.userRole,
      },
    });
  };

  const addRole = () => {
    navigate('/admin/adminRole/add', {
      state: {
        roleId: null,
        userRole: null,
      },
    });
  };

  const viewMember = (state: any) => {
    navigate('/admin/adminRole/member', {
      state: {
        userRole: state?.userRole,
      },
    });
  };

  const getAdminRoleList = useCallback(async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(MASTER_ROLE_LIST, { params });
      setAdminRoleList(resp.data.adminRoleList);
      setTotalCount(resp.data.count);
    } catch (err) {
      // console.log(err);
    }
  }, [pageNumber]);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  useEffect(() => {
    getAdminRoleList();
  }, [pageNumber]);

  return (
    <MainContainer>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton onSubmit={() => addRole()} text="롤 등록" />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <Table colList={[100, 50, 50, 50]}>
        {adminRoleList?.map((role: RoleList) => {
          return (
            <tr key={role.id}>
              <td>{role.userRole}</td>
              <td>
                <EditButton
                  onClick={() => editCategory(role)}
                  text="카테고리 수정"
                />
              </td>
              <td>
                <EditButton onClick={() => editRole(role)} text="롤 명 수정" />
              </td>
              <td>
                <EditButton onClick={() => viewMember(role)} text="사용자 조회" />
              </td>
            </tr>
          );
        })}
      </Table>
    </MainContainer>
  );
};

export default AdminRoleManagement;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.font.titleFont};
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;
