import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { CULTURE_CHANNEL } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import FileInput from '../../../components/common/Input/FileInput';

const CultureChannelDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const today = new Date();
  const todayWIthFormat =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2) +
    'T' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2);

  const detailData: any = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [profileImg, setProfileImg] = useState<string>('');
  const [profileImgFile, setProfileImgFile] = useState<any>(null);
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState(todayWIthFormat);
  const [endDt, setEndDt] = useState('2222-12-31 23:59');

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(`${CULTURE_CHANNEL}/${prevId}/detail`);
      if (resp.data.adminCultureChannel) {
        setName(resp.data.adminCultureChannel.name);
        setNameEn(resp.data.adminCultureChannel.nameEn);
        setDescription(resp.data.adminCultureChannel.description);
        setDescriptionEn(resp.data.adminCultureChannel.descriptionEn);
        setProfileImg(
          resp.data.adminCultureChannel.profileImgUrl
            ? resp.data.adminCultureChannel.profileImgUrl
            : '',
        );
        setExternalLinkUrl(resp.data.adminCultureChannel.externalLinkUrl);
        setActiveYn(resp.data.adminCultureChannel.activeYn);
        setStartDt(
          resp.data.adminCultureChannel.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCultureChannel.endDt?.replace(' ', 'T').slice(0, 16),
        );
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  const addContents = async (type: string) => {
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }
    // if (!profileImg) {
    //   alert('채널 프로필 썸네일 이미지를 입력해주세요.');
    //   return;
    // }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('nameEn', nameEn);
    if (description != null) {
      formData.append('description', description);
    }
    if (descriptionEn != null) {
      formData.append('descriptionEn', descriptionEn);
    }
    if (profileImgFile != null) {
      formData.append('profileImg', profileImgFile);
    }
    if (externalLinkUrl != null) {
      formData.append('externalLinkUrl', externalLinkUrl);
    }
    formData.append('activeYn', activeYn);
    formData.append('startDate', `${startDt.replace('T', ' ')}:59`);
    formData.append('endDate', `${endDt.replace('T', ' ')}:59`);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${CULTURE_CHANNEL}/${prevId}/edit`, formData, {});
      } else {
        await axios.post(`${CULTURE_CHANNEL}`, formData, {});
      }
      await navigate('/culture/channel');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <CoverImgWrapper>
          {profileImg ? <EventImg src={profileImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              profileImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="배너 이미지(둘러보기 노출)"
            value={profileImgFile}
            setFile={(e: string) => setProfileImgFile(e)}
            setImgUrl={setProfileImg}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="채널 명"
            value={name}
            placeholder="채널 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="채널 명 EN"
            value={nameEn}
            placeholder="채널 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="채널 설명"
            value={description}
            placeholder="채널 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="채널 설명 EN"
            value={descriptionEn}
            placeholder="채널 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="externalLink"
            label="채널 링크"
            value={externalLinkUrl}
            placeholder="채널 링크 입력해 주세요."
            onChange={(e) => setExternalLinkUrl(e.target.value)}
            onReset={() => setExternalLinkUrl('')}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default CultureChannelDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
  }

  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }

  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }

  &:nth-child(7) {
    grid-row: 4;
    grid-column: span 2;
  }

  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }

  &:nth-child(n + 9):nth-child(-n + 10) {
    grid-row: 6;
  }
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;
