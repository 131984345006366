import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

// common
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import NormalButton from '../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import BeatMakerRowComponents from './components/BeatMakerRowComponents';

// 액션
import { getBeatMakerList } from '../../redux/actions/beat/beat';

// hooks
import { useAppSelector } from '../../redux/store/hooks';
import useInput from '../../hooks/useInput';
import saveStorage from '../../hooks/saveStorage';

// 타입
import { BeatMakerRowComponentDataProps } from '../../definitions/beat';

// constant
import { beatMakerColList, beatMakerSearchTypeList } from '../../constant/beat';
import { postsPerPage } from '../../constant/common';

const BeatMaker = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const beatMakerList = useAppSelector((state) => state.beat.beatMakerList);
  const totalCount = useAppSelector((state) => state.beat.beatMakerTotalCount);
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState<string>(storageType || '');

  // pagination state
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  const params = {
    type,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };

  useEffect(() => {
    if (storagePageNumber) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('searchType', '');
      window.localStorage.setItem('searchWord', '');
    }
  }, [storagePageNumber]);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      if (!type) {
        setType('P');
        params.type = 'P';
      }
      setPageNumber(0);
      params.offset = 0;
      dispatch(getBeatMakerList(params));
    }
  };

  useEffect(() => {
    dispatch(getBeatMakerList(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber, postsPerPage]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={beatMakerSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="비트메이커 등록하기"
            onSubmit={() => {
              navigate('/beat/beatmaker/add');
            }}
            className=""
          />
        </TopWrapper>
        {totalCount !== 0 && (
          <PaginationWrapper>
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          </PaginationWrapper>
        )}
      </TopContainer>
      <MainContainer>
        <Table
          colList={beatMakerColList}
          thead={
            <>
              <ListHead title="Id" />
              <ListHead title="Profile" />
              <ListHead title="Pid" />
              <ListHead title="아티스트명" />
              <ListHead title="휴대폰 번호" />
              <ListHead title="이메일" />
              <ListHead title="" />
            </>
          }
        >
          {beatMakerList?.map((el: BeatMakerRowComponentDataProps) => (
            <BeatMakerRowComponents
              key={el.id}
              data={el}
              saveStorage={() => {
                saveStorage(pageNumber, type, word);
              }}
            />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default BeatMaker;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-bottom: 50px;
`;
