import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import axios from "axios";

import {postsPerPage as LIMIT} from "../../constant/common";
import {battleSearchTypeValues, battleTypeValues} from "../../constant/battle";
import {BATTLE} from "../../constant/apiUrl";
import * as commonFn from "../../utils/commonFn";

import styled from "styled-components";
import SearchInput from "../../components/common/Input/SearchInput";
import NormalButton from "../../components/common/Button/NormalButton";
import Pagination from "../../components/common/Pagination";
import BattleTable from "./BattleTable";
import SelectModule from "../../components/common/Select/SelectModule";
import RadioButton from "../../components/common/Button/RadioButton";

const Battle = () => {
	const navigate = useNavigate();

	const [type, setType] = useState('0');
	const [activeYn, setActiveYn] = useState('Y');
	const [searchType, setSearchType] = useState('title');
	const [keyword, setKeyword] = useState('');
	const [offset, setOffset] = useState(0);

	const [battleList, setBattleList] = useState([]);
	const [totalCount, setTotalCount] = useState(0);
	const [ableCallPage, setAbleCallPage] = useState(false); // 페이지 데이터 조회 가능 여부

	const pageDispatch = useCallback((e) => setOffset(e - 1), []);

	const getBattleList = ({type, activeYn, searchType, keyword, offset}) => {
		const searchInfo = {
			type: Object.keys(battleTypeValues)[Number(type)],
			offset: offset * LIMIT,
			limit: LIMIT,
			activeYn, searchType, keyword,
		};

		const searchUrl = new URLSearchParams(searchInfo);
		axios
		.get(`${BATTLE}?${searchUrl.toString()}`)
		.then(res => {
			if (res?.data) {
				setBattleList(res.data.battles);
				setTotalCount(res.data.totalCount);
				sessionStorage.setItem('battleSearchInfo', JSON.stringify(searchInfo));
			}
		}).catch(error => {
			console.log(error.response);
			error?.response?.data
				? alert(error.response.data.message)
				: alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
		}).finally(() => {
			setAbleCallPage(true);
		});
	}

	// 타입 트리거
	useEffect(() => {
		if (ableCallPage) {
			setAbleCallPage(false);
			setActiveYn('Y');
			setKeyword('');
			setOffset(0);
			getBattleList({type, activeYn:'Y', searchType, keyword:'', offset:0});
		}
	}, [type]);
	
	// 활성여부 트리거
	useEffect(() => {
		if (ableCallPage) {
			setAbleCallPage(false);
			setKeyword('');
			getBattleList({type, activeYn, searchType, keyword: '', offset});
		}
	}, [activeYn]);
	
	// 페이지 트리거
	useEffect(() => {
		if (ableCallPage) {
			setAbleCallPage(false);
			getBattleList({type, activeYn, searchType, keyword, offset});
		}
	}, [offset]);

	// 페이지 첫 랜더 트리거
	useEffect(() => {
		const sessionSearchInfo = JSON.parse(sessionStorage.getItem('battleSearchInfo') || '{}');
		if (sessionSearchInfo?.type) {
			sessionSearchInfo.type = sessionSearchInfo.type === 'SPECIAL' ? '0' : sessionSearchInfo.type === 'SPECIAL_PICK' ? '1' : '2';
			setType(sessionSearchInfo.type);
			setActiveYn(sessionSearchInfo.activeYn);
			setSearchType(sessionSearchInfo.searchType);
			setKeyword(sessionSearchInfo.keyword);
			setOffset(sessionSearchInfo.offset);
		} else {
			sessionSearchInfo.type = type;
			sessionSearchInfo.activeYn = activeYn;
			sessionSearchInfo.searchType = searchType;
			sessionSearchInfo.keyword = keyword;
			sessionSearchInfo.offset = offset;
		}

		getBattleList(sessionSearchInfo);
	}, []);
	
	// useEffect(() => console.log(type), [type])

	return <>
		<TopContainer>
			<TopWrapper>
				<SearchWrapper>
					<InputWrapper>
						<RadioButton
							type="list"
							value={type}
							name="type"
							onChange={e => setType(e.target.value)}
							text={Object.values(battleTypeValues)}
						/>
					</InputWrapper>

					{
						type === '2' ||
							<InputWrapper>
								<RadioButton
									value={activeYn}
									name="activeYn"
									label=""
									onChange={e => setActiveYn(e.target.value)}
									text={['공개', '비공개']}
									type="activeYn"
								/>
							</InputWrapper>
					}

					<InputWrapper2>
						<SelectModule
							name="type"
							label=""
							placeholder=""
							className='battleSearchType'
							value={battleSearchTypeValues[searchType]}
							onChange={e => setSearchType(e.value)}
							options={commonFn.transObjectToOptions(battleSearchTypeValues)}
						/>
						<SearchInput value={keyword} onChange={e => setKeyword(e.target.value)} />
						<NormalButton text="검색" className='cancelBtn' onSubmit={() => getBattleList({type, activeYn, searchType, keyword, offset})} />
					</InputWrapper2>
				</SearchWrapper>
			</TopWrapper>

			<CreationWrapper>
				<NormalButton text="배틀 생성" onSubmit={() => navigate('/battle/detail')} />
			</CreationWrapper>

			<PaginationWrapper>
				{totalCount !== 0 && (
					<Pagination
						totalCount={totalCount}
						postsPerPage={LIMIT}
						currentPage={offset}
						pageDispatch={pageDispatch}
					/>
				)}
			</PaginationWrapper>
		</TopContainer>


		<MainContainer>
			<BattleTable battleList={battleList} />
		</MainContainer>
	</>;
};

export default Battle;

const TopContainer = styled.div``;
const TopWrapper = styled.div`
	padding: 10px 20px;
	background-color: #ccc;
	border-radius: 10px;
`;

const SearchWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const CreationWrapper = styled.div`
  margin-top: 30px;
  text-align: right;
`;

const PaginationWrapper = styled.div`
  margin-top: 30px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const InputWrapper2 = styled.div`
	margin: 20px 0;
	display: grid;
	gap: 10px;
	grid-template-columns: 1fr 8fr 1fr;
	align-items: center;
`;
