export const DepthUnits = {
	member: '회원 관리',
	searchMember: '계정 조회',
	history: '접속 이력',
	resign: '탈퇴 이력',
	beat: '비트 관리',
	beatmaker: '비트 메이커',
	add: '등록',
	list: '목록',
	genre: '장르',
	edit: '수정',
	mood: '무드',
	detail: '상세',
	explore: '둘러보기',
	operation: '운영 관리',
	allTab: '전체 탭 설정',
	recentlyBeat: '최신 비트',
	recommendGenre: '추천 장르',
	recommendBeatMaker: '추천 비트메이커',
	upcomingBeat: '발매 예정 비트',
	recommendCollection: '추천 컬렉션',
	genreTab: '추천 장르 탭 설정',
	collection: '컬렉션',
	word: '금칙어',
	reservation: 'reserve id',
	event: '이벤트',
	rekognition: 'rekognition 기준',
	admin: '권한 관리',
	account: '내 계정 관리',
	adminRole: '권한 설정',
	adminAccount: '관리자 계정 조회',
	cs: 'CS 관리',
	blockVideo: '신고 내역 - 영상',
	blockElse: '신고 내역 - 영상 외',
	rekognitionVideo: 'Rekognition 영상',
	restrictMember: '신고 제재 관리',
	management: '신고 유형 관리',
	notice: '메인 공지',
	version: '앱 버전 관리',
	video: '영상 관리',
	searchVideo: '영상 조회',
	officialAccount: 'Baund 공식 계정 관리',
	hashTag: '추천 해시태그',
	colList: '리스트',
	editSeq: '순서 변경',
	system: '시스템 관리',
	updateData: '서버 데이터 갱신',
	refreshCache: '캐시 초기화',
	push: '광고 푸시',
	maintenance: '메인터넌스 관리',
	category: '카테고리',
	filter: '필터',
	baundMusic: 'Baund Music',
	type: '음원 타입',
	release: '발매 음원',
	communityCategory: '커뮤니티 카테고리',
	community: '커뮤니티',
	communityFixArticle: '커뮤니티 게시글 고정 배너 설정',
	communityCuratedArticle: '커뮤니티 게시글 고정 설정',
	communityBoardBanner: '커뮤니티 배너 설정',
	articleList: '게시글 리스트',
	culture: '컬쳐',
	channel: '채널 관리',
	program: '프로그램 관리',
	episode: '에피소드 관리',
	fixArticle: '게시글 고정',
	hotBeat: '인기 비트',
	curatedBeat: '추천 비트',
	curatedArticle: '추천 영상',
	adminCategory: '메뉴',
	baundApiCategory: '바운드 API 카테고리',
	baundApi: '바운드 API',
	project: '프로젝트 관리',
	curatedTagsArticle: '추천 태그의 영상',
	performance: '공연',
	performanceCategory: '공연 카테고리',
	curatedPerformance: '상단 고정 공연',
	individualPush: '개별 푸시',
	noticeNormal: '일반 공지',
	rewards: '리워드 관리',
	'mission-list': '미션 관리',
	'point-guide-area-list': '포인트 안내 영역 목록',
	'point-guide-area-detail': '포인트 안내 영역 상세',
	campaign: '캠페인',
	goldCampaign: '골드 캠페인',
	master: '마스터 데이터 관리',
	'give-currency': '재화 지급',
	battle: '배틀',
	config: '기본 설정',
	'config-any-is': '안내 설정',
	changeBeat: '비트 수정',
	datas: '데이터 관리',
	masterMessage: '마스터 메시지',
	coupons: '쿠폰 관리',
	'coupon-projects': '바운드 골드 쿠폰',
	'coupon-list': '쿠폰 목록',
	'coupon-detail': '쿠폰 상세',
    magazine: '매거진',
    'magazine-category': '매거진 카테고리',
	curatedMagazine: '추천 매거진',
	suggestWordCategory: '제시어 카테고리 관리',
	suggestWord: '제시어 관리',
	suggestWordRandom: '이벤트 랜덤 제시어',
	curatedSuggestWordArticle: '추천 제시어의 영상',
	banner: '배너',
	advertiser: '광고주 관리',
	advertisement: '광고',
	advertisementSummary: '광고 관리',
	advertisementLanding: '광고 랜딩 관리',
	advertisementCampaign: '광고 캠페인 관리',
	advertisementCampaignEditSeq_EL: '메인 배너 순서',
	advertisementCampaignEditSeq_NM: '메인 팝업 순서',
	communityBoardArticleSearch: '커뮤니티 게시글 검색',
	baundProject: '프로젝트',
	baundProjectFaq: 'FAQ',
	baundProjectCaution: '유의사항',
	baundProjectQna: 'QnA',
	baundProjectParticipant: '지원자 확인'
};
