import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

// common
import axios from 'axios';
import NormalButton from '../../components/common/Button/NormalButton';
import ProfileImg from '../../components/common/image/ProfileImg';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TagTextInput from '../../components/common/Input/TagTextInput';
import FileInput from '../../components/common/Input/FileInput';
import RadioButton from '../../components/common/Button/RadioButton';
import SelectModule from '../../components/common/Select/SelectModule';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import ListDateSelect from '../../components/common/Select/ListDateSelect';

//hooks
import { useAppSelector } from '../../redux/store/hooks';

//액션
import { getParentGenreList } from '../../redux/actions/genre/genre';
import { setLoading, resetLoading } from '../../redux/slices/user';

// constant
import { genreBeatColList, beatPrivateYnOptions } from '../../constant/beat';

// 타입
import { RowComponentDataProps } from '../../definitions/beat';

// config
import { GENRE_LIST } from '../../constant/apiUrl';

import GenreRowComponent from './components/GenreRowComponent';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialGenreData: data = {
  id: '',
  code: '',
  genre: '',
  createdDt: '',
  updateDt: '',
  genreImgUrl: '',
  genreImgFile: '',
  searchKeywords: null,
};

const initialParentGenre: data = {
  id: '',
  code: '',
  genre: '',
};

const AddGenre = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const genreList = useAppSelector((state) => state.genre.genreList);
  const detailGenre = useAppSelector((state) => state.genre.detailGenre);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [genreData, setGenreData] = useState(initialGenreData);
  const [genreBeatList, setGenreBeatList] = useState<any>([]);
  const [keyWord, setKeyWord] = useState<any>(null);
  const [addWord, setAddWord] = useState('');
  const [coverImg, setCoverImg] = useState(
    detailGenre ? detailGenre.genreImgUrl : '',
  );
  const [genreType, setGenreType] = useState('Y');
  const [parentGenre, setParentGenre] = useState(initialParentGenre);
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');
  const [privateYn, setPrivateYn] = useState<string>('');

  const { id, genre, searchKeywords, genreImgFile } = genreData;

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setGenreData({ ...genreData, [name]: value });
    },
    [genreData],
  );

  const handleCoverSetData = useCallback(
    (e: string) => {
      setGenreData({ ...genreData, genreImgFile: e });
    },
    [genreData],
  );

  const handleGenreType = useCallback((e: any) => {
    const { value } = e.target;
    setGenreType(value);
  }, []);

  const handleSelectSetData = useCallback((e: any) => {
    setParentGenre(e);
  }, []);

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 장르 추가하기
  const onAddGenre = async () => {
    const formData = new FormData();

    formData.append('adminName', adminName);
    formData.append('genre', genre);
    formData.append('genreImgFile', genreImgFile);
    formData.append('searchKeywords', keyWord || []);
    if (parentGenre?.id) {
      formData.append('parentGenreId', parentGenre.id);
    }

    if (!genre) {
      alert('양식에 맞춰 작성해주세요.');
      return;
    }

    if (genreList.some((object: any) => object.genre === genre)) {
      alert('해당 장르가 이미 등록되어 있습니다.');
    } else {
      try {
        await axios.post(GENRE_LIST, formData, {});
        await alert('장르가 등록되었습니다.');
        await goBack();
      } catch (error: any) {
        console.log(error.response);
      }
    }
  };

  // 장르명 변경 확인
  const checkGenreChange = () => {
    return JSON.stringify(detailGenre) !== JSON.stringify(genreData);
  };

  // 검색 키워드 변경 확인
  const checkKeyWordChange = () => {
    return (
      JSON.stringify(
        searchKeywords !== null ? searchKeywords.split(',') : searchKeywords,
      ) !== JSON.stringify(keyWord)
    );
  };

  // 장르 수정하기
  const onChangeGenre = async () => {
    const formData = new FormData();

    formData.append('adminName', adminName);

    // 장르명 변경 확인
    if (checkGenreChange()) {
      formData.append('genre', genre);
    } else {
      formData.delete('genre');
    }
    formData.append('genreImgFile', genreImgFile);

    // 검색 키워드 변경 확인
    if (checkKeyWordChange()) {
      formData.append('searchKeywords', keyWord);
    } else {
      formData.delete('searchKeywords');
    }

    // 장르 수정 api
    try {
      dispatch(setLoading());
      await axios.post(`${GENRE_LIST}/${id}/update`, formData, {});
      await alert('장르가 수정되었습니다.');
      await goBack();
    } catch (error: any) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  // 장르 삭제하기
  const onDeleteGenre = async () => {
    try {
      dispatch(setLoading());
      await axios.delete(`${GENRE_LIST}/${id}`, {
        params: { adminName },
      });
      await alert('장르가 삭제되었습니다.');
      await goBack();
    } catch (error: any) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  const handleResetData = useCallback(
    (e) => {
      setGenreData({ ...genreData, [e]: initialGenreData[e] });
    },
    [genreData],
  );

  // 장르에 따른 비트리스트 불러오기
  const getGenreBeatList = useCallback(async () => {
    const params = {
      type: 'GENRE',
      word: detailGenre?.genre,
      offset: 0,
      limit: 1000,
      maxStartDate,
      minStartDate,
      privateYn,
    };
    try {
      const response = await axios.get('/beat/list', { params });
      setGenreBeatList(response.data.beatList);
    } catch (error: any) {
      console.log(error);
    }
  }, [detailGenre?.genre, maxStartDate, minStartDate, privateYn]);

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/beat/genre');
  };

  useEffect(() => {
    setGenreData(detailGenre);
    if (detailGenre.searchKeywords) {
      const keywords = detailGenre.searchKeywords.split(',');
      setKeyWord(keywords);
    }
    // 하위 장르일 경우
    if (detailGenre.parentGenreId) {
      const parentGenreName = genreList.find(
        (el: any) => el.id === detailGenre.parentGenreId,
      );
      setGenreType('N');
      setParentGenre(parentGenreName);
    }
  }, [setKeyWord, detailGenre, genreList]);

  useEffect(() => {
    dispatch(getParentGenreList({ limit: 1000, offset: 0 }));
    getGenreBeatList();
  }, [dispatch, getGenreBeatList]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            {coverImg ? <ProfileImg url={coverImg} /> : <ProfileImg />}
            <ProfileTitleWrapper>
              <BeatTitle>{genre}</BeatTitle>
            </ProfileTitleWrapper>
          </ProfileWrapper>
          <LineDiv />
          <ButtonWrapper>
            {detailGenre.genre ? (
              <>
                {checkGenreChange() || checkKeyWordChange() ? (
                  <NormalButton text="수정하기" onSubmit={onChangeGenre} />
                ) : (
                  <NormalButton
                    text="장르 삭제하기"
                    onSubmit={onDeleteGenre}
                    className="cancelBtn"
                  />
                )}
              </>
            ) : (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton text="저장하기" onSubmit={onAddGenre} />
              </>
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <FileInput
            type="coverImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버"
            value={genreImgFile}
            setFile={handleCoverSetData}
            setImgUrl={setCoverImg}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="genre"
            label="장르명"
            value={genre}
            placeholder="장르명을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={genreType}
            name="genreType"
            label="장르 종류"
            onChange={handleGenreType}
            disabled={detailGenre?.id && true}
            text={['대표 장르', '일반 장르']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        {genreType === 'N' && (
          <InputWrapper>
            <SelectModule
              label="대표 장르"
              name="parentGenre"
              onChange={handleSelectSetData}
              options={genreList}
              placeholder="대표 장르를 선택해 주세요."
              value={parentGenre?.genre}
            />
          </InputWrapper>
        )}

        <InputWrapper>
          <TagTextInput
            placeholder="키워드를 입력해주세요."
            label="검색 키워드"
            keyWord={keyWord || []}
            setKeyWord={setKeyWord}
            addWord={addWord}
            setAddWord={setAddWord}
          />
        </InputWrapper>
        {detailGenre?.id && (
          <InputWrapper>
            <Table
              colList={genreBeatColList}
              thead={
                <>
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListSelect
                    title="공개 여부"
                    list={beatPrivateYnOptions}
                    onClick={handleSelect}
                    setState={setPrivateYn}
                  />
                  <ListDateSelect
                    title="공개 날짜"
                    setMinStartDate={setMinStartDate}
                    setMaxStartDate={setMaxStartDate}
                  />
                </>
              }
            >
              {genreBeatList?.map((el: RowComponentDataProps) => (
                <GenreRowComponent key={el.id} data={el} />
              ))}
            </Table>
          </InputWrapper>
        )}
      </MainContainer>
    </>
  );
};

export default AddGenre;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const BeatTitle = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  letter-spacing: -0.3px;
  line-height: 35px;
`;

const LineDiv = styled.div`
  position: absolute;
  width: 100%;
  top: 205px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 18px 0;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;
  &:nth-child(1) {
    margin-bottom: 60px;
  }
  //
  //&:nth-child(2) {
  //  grid-row: 2;
  //  margin-bottom: 40px;
  //}
  //
  //&:nth-child(2) {
  //  grid-row: 2;
  //  margin-bottom: 40px;
  //}
  //&:nth-child(n + 4):nth-child(-n + 5) {
  //  grid-row: 3;
  //}
  //
  //&:nth-child(6) {
  //  grid-row: 4;
  //  grid-column: span 2;
  //  margin-bottom: 40px;
  //}
  //&:nth-child(7) {
  //  grid-row: 5;
  //}
  //&:nth-child(n + 8):nth-child(-n + 9) {
  //  grid-row: 6;
  //  margin-bottom: 40px;
  //}
  //&:nth-child(n + 10):nth-child(-n + 11) {
  //  grid-row: 7;
  //}
  //&:nth-child(12) {
  //  grid-row: 8;
  //}
  //&:nth-child(13) {
  //  grid-row: 9;
  //  grid-column: span 2;
  //}
  //&:nth-child(14) {
  //  grid-row: 10;
  //  margin-bottom: 100px;
  //}
`;
