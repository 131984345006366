import React from 'react';
import styled from 'styled-components';
import searchIcon from '../../../assets/images/page/searchIcon.png';

interface SearchInputProps {
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
}

const SearchInput = ({
  value,
  onChange,
  onKeyPress,
  placeholder,
}: SearchInputProps) => {
  return (
    <SearchInputWrapper>
      <InputWrapper>
        <Img alt="searchIcon" src={searchIcon} />
        <Input
          placeholder={placeholder || '검색어를 입력해주세요.'}
          type="text"
          value={value}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </InputWrapper>
    </SearchInputWrapper>
  );
};

export default SearchInput;

const SearchInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 44px;
  padding: 7px 12px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
`;

const Img = styled.img`
  width: 30px;
  margin-right: 6px;
`;

const Input = styled.input`
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;

  &::placeholder {
    color: ${({ theme }) => theme.color.subGray};
  }
`;
