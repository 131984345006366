// Event Type
export const eventTypeKeyAndValue = {
    MC: '마케팅',
    CP: 'COMPLIT',
    OP: 'Baund Original Plays',
    PW: 'POWERED',
    CAR: '커뮤니티이동형',
    EXL: '외부링크형',
    ICD: '초대코드형',
    ENT: '단순응모형',
    ATC: '출석체크형',
    PTP: '참여형',
    MISSION: '미션 목록 이동형',
    CAMPAIGN: '캠페인 목록 이동형',
    ABOUT_BAUND_GOLD: 'BaundGold란? 이동형',
    ABOUT_GOLD_CAMPAIGN: '골드 캠페인이란? 이동형',
    BATTLE: '배틀',
    BATTLE_ROOM: '배틀 룸 이동',
    BATTLE_SPECIAL_LIST: '스페셜 배틀 리스트 이동',
};

export const eventActiveYnOptions = [
    {text: '전체', value: ''},
    {text: '공개', value: 'Y'},
    {text: '비공개', value: 'N'},
];

export const eventCountryTypeList = [
    {text: '전체', value: ''},
    {text: '한국', value: 'KR'},
    {text: '미국', value: 'US'},
    {text: '스페인', value: 'ES'},
];

export const eventLanguageTypeList = [
    { text: '전체', value: '' },
    { text: '한국어', value: 'ko' },
    { text: '영어', value: 'en' },
    { text: '스페인어', value: 'es' },
];

export const eventSearchTypeList = [
    // { text: '전체', value: '' },
    {text: '제목', value: 'T'},
    {text: '프로젝트 명', value: 'P'},
];

export const pupRuleKeyAndValue = {
    TG_PRS: '태그에 좋아요',
    BT_CM: '비트에 댓글'
}

export const ptpRuleTypeList = [
    {name: '태그에 좋아요', value: 'TG_PRS'},
    // {name: '비트에 댓글', value: 'BT_CM'},
];

export const noticeTypeList = [
    {name: '이벤트 연결 없이 등록', value: ''},
    {name: '이벤트', value: 'EV'},
    {name: '외부 링크', value: 'EL'},
    {name: '미션 목록 이동', value: 'MISSION'},
    {name: '캠페인 목록 이동', value: 'CAMPAIGN'},
    {name: '골드 캠페인 이란? 이동', value: 'MOVE_WHAT_IS_GOLD_CAMPAIGN'},
    {name: '바운드 골드란? 이동', value: 'MOVE_WHAT_IS_BAUND_GOLD'},
    {name: '배틀 룸 이동', value: 'BATTLE_ROOM' },
    {name: '스페셜 배틀 리스트 이동', value: 'BATTLE_SPECIAL_LIST' },
];

// 이벤트 테이블 컴포넌트
export const eventColList = [60, 160, 110, 110, 100, 140, 140, 100, 70];
export const noticeModalColList = [60, 160, 130, 120, 100, 100, 100, 80, 70];
export const eventModalColList = [60, 120, 150, 110, 150, 170, 80, 90];
export const eventBeatModalColList = [60, 120, 150, 110, 150, 170, 80, 90];
export const pushEventModalColList = [160, 110, 110, 100, 140, 140, 100, 70, 60,];
export const battleArticleModalColList = [60, 100, 60, 100, 100, 140, 130, 105, 80, 70];
