import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import { FILTER_TYPE } from '../../../constant/apiUrl';

const FilterCategoryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const detailData: any = location.state;
  const prevPageProps = detailData !== null ? detailData.prevPageProps : null;
  const prevTotalCount = detailData && detailData.prevTotalCount;

  const [activeYn, setActiveYn] = useState(prevPageProps?.activeYn || 'Y');
  const [description, setDescription] = useState(prevPageProps?.description);
  const [name, setName] = useState(prevPageProps?.name);
  const [sequence, setSequence] = useState(prevTotalCount);

  const addFilterCategory = async (type: string) => {
    const params = {
      activeYn,
      description,
      name,
      sequence: sequence + 1,
    };
    if (type === 'add') {
      try {
        await axios.post(FILTER_TYPE, {}, { params });
        alert('생성되었습니다.');
        navigate('/filter/category');
      } catch (err) {
        console.log(err);
        alert('잠시후 다시 이용해주세요.');
      }
    }
    if (type === 'edit') {
      try {
        await axios.post(
          `${FILTER_TYPE}/${prevPageProps.id}`,
          {},
          { params: { ...params, sequence: prevPageProps.sequence } },
        );
        alert('수정되었습니다.');
        navigate('/filter/category');
      } catch (err) {
        console.log(err);
        alert('잠시후 다시 이용해주세요.');
      }
    }
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={() => {
              navigate('/filter/category');
            }}
            className="cancelBtn"
          />
          {!prevPageProps ? (
            <NormalButton
              text="저장하기"
              onSubmit={() => {
                addFilterCategory('add');
              }}
            />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => {
                addFilterCategory('edit');
              }}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="카테고리 명"
            value={name}
            placeholder="프로젝트 명을 입력해 주세요."
            onChange={(e) => {
              setName(e.target.value);
            }}
            onReset={() => {
              setName('');
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="설명"
            value={description}
            placeholder="프로젝트 명을 입력해 주세요."
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            onReset={() => {
              setDescription('');
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default FilterCategoryDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
    grid-column: span 2;
  }

  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
  }

  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }
`;
