import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import {
  eventActiveYnOptions,
  eventCountryTypeList,
  eventSearchTypeList,
  pushEventModalColList,
} from '../../../constant/event';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import { EVENT_LIST } from '../../../constant/apiUrl';
import { CustomProps } from '../../../definitions/common';
import useInput from '../../../hooks/useInput';
import { postsPerPage } from '../../../constant/common';
import PushForEventRowComponent from './PushForEventRowComponent';

const PushForEvent = ({
  closeModal,
  setSelectedModalObj,
  setSelectedModalType,
  selectedContentList,
}: any) => {
  const [selectedEventList, setSelectedEventList] = useState<any>(
      selectedContentList ? selectedContentList : []
  );
  const [eventList, setEventList] = useState<any>([]);
  const [word, setWord] = useInput('');
  const [type, setType] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [activeYn, setActiveYn] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxEndDate, setMaxEndDate] = useState<string>('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  console.log(eventList);

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      if (!type) {
        setType('T');
        params.searchType = 'T';
      }
      setPageNumber(0);
      params.offset = 0;
      getEventList();
    } else {
      getEventList();
    }
  };

  const params = {
    searchType: type,
    countryCode: country,
    activeYn,
    word,
    limit: postsPerPage,
    searchStartDate: minStartDate,
    searchEndDate: maxEndDate,
    offset: pageNumber * postsPerPage,
  };

  const getEventList = async () => {
    try {
      const resp = await axios.get(EVENT_LIST, {
        params,
      });
      setEventList(resp.data.eventList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
    }
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const saveEvent = () => {
    if (selectedEventList.length === 0) {
      alert('최소 한개의 이벤트를 선택해야합니다.');
      return;
    }
    //저장 진행 여부
    let isProceed = true
    //비활성화 되어 있거나, 공개일이 지난 이벤트는 저장 전 확인을 받는다
    if (selectedEventList[0].activeYn !== 'Y'
        || new Date() > new Date(selectedEventList[0].endDt)) {
      isProceed = confirm("비공개 상태이거나 노출 기간이 경과된 이벤트가 선택 되었습니다. 진행하시겠습니까?");
    }
    // 저장 진행하는 경우에는
    if (isProceed) {
      alert('저장되었습니다.');
      setSelectedModalObj(selectedEventList[0]);
      setSelectedModalType('EV');
      closeModal();
    }
  };

  const filterSelectedContent = (el: any) => {
    const result = selectedEventList.filter((item: any) => item !== el);
    setSelectedEventList(result);
  };

  // 비트 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (selectedEventList.length !== 0) {
      alert('이벤트는 하나만 선택할 수 있습니다.');
    } else setSelectedEventList([...selectedEventList, data]);
  };

  useEffect(() => {
    getEventList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, activeYn, country]);

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
      <VideoModalWrapper>
        <ModalButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={closeModal}
            className="cancelBtn"
          />
          <NormalButton text="저장하기" onSubmit={saveEvent} />
        </ModalButtonWrapper>
        <VideoModalTitle>선택한 이벤트</VideoModalTitle>
        {selectedEventList.length !== 0 ? (
          <>
            <TableTitle>{selectedEventList.length}개 이벤트</TableTitle>
            <LineDiv />
            <Table colList={pushEventModalColList} className="nonListTable">
              {selectedEventList?.map((data: any) => (
                <PushForEventRowComponent
                  key={data.id}
                  data={data}
                  onClick={filterSelectedContent}
                  selected
                />
              ))}
            </Table>
          </>
        ) : (
          <>
            <VideoModalSubText>
              선택된 이벤트가 없습니다. 아래 검색 후 이벤트를 선택해주세요.
            </VideoModalSubText>
            <LineDiv />
          </>
        )}
        <SpaceDiv value="90px" />
        {selectedEventList.length === 0 && <EmptyDiv />}
        <VideoModalTitle>이벤트 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={eventSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          <TableTitle>{totalCount}개 이벤트</TableTitle>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv />
        <Table
          colList={pushEventModalColList}
          thead={
            <>
              <ListHead title="이미지" />
              <ListHead title="프로젝트 명" />
              <ListHead title="제목" />
              <ListSelect
                title="공개여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setActiveYn}
              />
              <ListHead title="시작일" />
              <ListHead title="종료일" />
              <ListSelect
                title="국가"
                list={eventCountryTypeList}
                onClick={handleSelect}
                setState={setCountry}
              />
              <ListHead title="언어" />
            </>
          }
        >
          {eventList?.map((el: any) => (
            <PushForEventRowComponent
              key={el.id}
              data={el}
              onClick={addSelectedContent}
            />
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

export default PushForEvent;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpaceDiv = styled.div<CustomProps>`
  height: ${({ value }) => value};
`;

const EmptyDiv = styled.div`
  margin-top: 120px;
  height: 90px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
