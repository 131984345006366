import {useLocation, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import React, {useCallback, useEffect, useRef, useState} from "react";
import axios from "axios";
import {BAUND_PROJECT, BAUND_PROJECT_CAUTION} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import styled from "styled-components";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import NormalButtonWithLabel
  from "../advertisement/component/NormalButtonWiteLabel";
import FileInput from "../../components/common/Input/FileInput";
import blankImg from "../../assets/images/page/blankImg.png";
import DateInput from "../../components/common/Input/DateInput";
import LabelPlusButtonInput
  from "../../components/common/Input/LabelPlusButtonInput";
import VideoInput from "../../components/common/Input/VideoInput";
import CuratedBeatRow from "../beat/curatedBeat/CuratedBeatRow";
import RadioButton from "../../components/common/Button/RadioButton";
import {
  isInOrderStrDate,
  isBlank,
  nowDateToSting,
  isNotInOrderStrDateWithAlert, isPast
} from "../../utils/commonFn";
import {
  baundProjectStatusTypeOptions, baundProjectUseVoteYnOptions,
  baundProjectVoteTypeOptions
} from "../../constant/baundProject";
import SelectModule from "../../components/common/Select/SelectModule";
import LabelText from "../../components/common/LabelText";
import BilingualPropertyTable from "../../components/common/BilingualPropertyTable"
import CheckBoxInput from "../../components/common/Input/CheckBoxInput";
import BeatSelectModal from "../../components/common/Modal/BeatSelectModal";
import ListHeadDiv from "../../components/UI/ListHeadDiv";
import LabelTextAreaInput
  from "../../components/common/Input/LabelTextAreaInput";

// 바운드 프로젝트 상세
const BaundProjectDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const DATETIME_WARN_MESSAGE = `프로젝트 기간 설정에서 이전 상태가 이후 상태보다 시간이 앞서 있습니다.\n 상태에 따라 순차적으로 시간이 기입되어야 합니다. `;

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectId = detailData !== null ? detailData.baundProjectId : null;
  const propertyForSort = detailData !== null ? detailData.propertyForSort : null;
  const directionForSort = detailData !== null ? detailData.directionForSort : null;
  const privateYnForSearch = detailData !== null ? detailData.privateYnForSearch : null;
  const privateYnTextForSearch = detailData !== null ? detailData.privateYnTextForSearch : null;
  const statusForSearch = detailData !== null ? detailData.statusForSearch : null;
  const statusTextForSearch = detailData !== null ? detailData.statusTextForSearch : null;
  const pageNumberForSearch = detailData !== null ? detailData.pageNumberForSearch : null;
  const keywordForSearch = detailData !== null ? detailData.keywordForSearch : null;
  const searchTypeForSearch = detailData !== null ? detailData.searchTypeForSearch : null;

  // 내용 입력의 편의를 위해 true 인 경우에 한글 입력 값을 영문 값으로 복사. 신규 일때는 기본 값 true
  const [copyKR2EN, setCopyKR2EN] = useState(!baundProjectId);

  // 바운드 프로젝트 속성 //////////////////////////////////////////////////////
  const [title, setTitle] = useState('');                               //프로젝트 이름
  const [titleEn, setTitleEn] = useState('');                           //프로젝트 이름(EN)
  const [carouselProjectTitle, setCarouselProjectTitle] = useState('');                               //캐러셀 프로젝트 이름
  const [carouselProjectTitleEn, setCarouselProjectTitleEn] = useState('');                           //캐러셀 프로젝트 이름(EN)
  //프로젝트 상태
  const [status, setStatus] = useState('');
  const [statusText, setStatusText] = useState();
  // 프로젝트 진행 방식
  const [useVoteYn, setUseVoteYn] = useState('N');                      // 투표 사용 여부
  const [voteType, setVoteType] = useState();                                   //투표 방식
  const [voteTypeText, setVoteTypeText] = useState();
  const [description, setDescription] = useState('');                   //설명
  const [descriptionEn, setDescriptionEn] = useState('');               //설명(EN)
  const [preReleaseStartDt, setPreReleaseStartDt] = useState('');       //사전 공개 노출 시작 일시
  const [applyStartDt, setApplyStartDt] = useState('');                 //모집 시작 일시
  const [applyEndDt, setApplyEndDt] = useState('');                     //모집 종료 일시
  const [finalAnnounceDt, setFinalAnnounceDt] = useState('') ;          //본선 진출자 발표 일시
  const [voteStartDt, setVoteStartDt] = useState('');                   //투표 시작 일시
  const [voteEndDt, setVoteEndDt] = useState('');                       //투표 종료 일시
  const [winnerAnnounceDt, setWinnerAnnounceDt] = useState('');         //합격자 발표 일자
  const [detailImgUrl, setDetailImgUrl] = useState('');                 //상세 페이지 이미지
  const [detailImgFile, setDetailImgFile] = useState();
  const [externalMovieLinkUrl, setExternalMovieLinkUrl] = useState([]);  //모집 홍보 관련 영상 링크
  const [judgeCriteriaLike, setJudgeCriteriaLike] = useState(0);       //선정 기준: 좋아요 점수
  const [judgeCriteriaView, setJudgeCriteriaView] = useState(0);       //선정 기준: 조회수 점수
  const [judgeCriteriaHost, setJudgeCriteriaHost] = useState(0);       //선정 기준: 호스트 점수
  const [judgeCriteriaVote, setJudgeCriteriaVote] = useState(0);       //선정 기준: 투표 개수
  const [judgeCriteriaPanel, setJudgeCriteriaPanel] = useState(0);     //선정 기준: baund 선정 위원단 점수
  const [beatSelected, setBeatSelected] = useState([]);                   // 선택된 비트
  const [beatIds, setBeatIds] = useState([]);                            //비트 아이디 목록
  const [winnerBenefit, setWinnerBenefit] = useState([]);                //우승자 혜택
  const [howToApply, setHowToApply] = useState([]);                      //지원 방법
  const [commonCautions, setCommonCautions] = useState([]);               // 공통 유의사항
  const [projectCautions, setProjectCautions] = useState([]);             //프로젝트 별 유의사항
  //프로젝트, Q&A, FAQ 노출 여부 설정
  const [useQnaYn, setUseQnaYn] = useState('Y');                         //프로젝트 Q&A on/off
  const [useFaqYn, setUseFaqYn] = useState('Y');                         //FAQ 노출/미노출
  const [privateYn, setPrivateYn] = useState('N');                       //프로젝트 노출/미노출 설정
  // 생성 정보
  const [createDt, setCreateDt] = useState('');
  const [updateDt, setUpdateDt] = useState('');
  const [createAdminAccountName, setCreateAdminAccountName] = useState('');
  const [updateAdminAccountName, setUpdateAdminAccountName] = useState('');

  // 비트 미리 듣기 기능에서 2개 이상 동시 플레이 방지
  const [playBeat, setPlayBeat] = useState('');

  // 비트 선택 모달 창 표시. 이 페이지에서는 'beat'인지 아닌지만사용됨
  const [modalType, setModalType] = useState('');

  // 수정 활성화 기준
  const [isFinished, setIsFinished] = useState(false);

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${BAUND_PROJECT}/${baundProjectId}`,
    )
    .then(res => {
      // console.log('AdvertisementLanding > getDetail > res:',res);
      const detail = res.data.baundProject;

      setTitle(detail.title);
      setTitleEn(detail.titleEn);
      setCarouselProjectTitle(detail.carouselProjectTitle || detail.title);
      setCarouselProjectTitleEn(detail.carouselProjectTitleEn || detail.titleEn);
      setDescription(detail.description);
      setDescriptionEn(detail.descriptionEn);
      setPreReleaseStartDt(detail.preReleaseStartDt);
      setApplyStartDt(detail.applyStartDt);
      setApplyEndDt(detail.applyEndDt);
      setFinalAnnounceDt(detail.finalAnnounceDt);
      setVoteStartDt(detail.voteStartDt);
      setVoteEndDt(detail.voteEndDt);
      setWinnerAnnounceDt(detail.winnerAnnounceDt);
      setDetailImgUrl(detail.detailImgUrl);
      setJudgeCriteriaLike(detail.judgeCriteriaLike);
      setJudgeCriteriaView(detail.judgeCriteriaView);
      setJudgeCriteriaHost(detail.judgeCriteriaHost);
      setJudgeCriteriaVote(detail.judgeCriteriaVote);
      setJudgeCriteriaPanel(detail.judgeCriteriaPanel);
      setBeatIds(detail.beatIds);
      setCreateDt(detail.createDt);
      setUpdateDt(detail.updateDt);
      setCreateAdminAccountName(detail.createAdminAccountName);
      setUpdateAdminAccountName(detail.updateAdminAccountName);
      setStatus(detail.status);

      // 상태 값에 따라 수정 가능 범위 설정. status는 도중에 변경되지 않으므로 직접 설정
      setIsFinished(status === "FINISH");

      setPrivateYn(detail.privateYn);
      setUseQnaYn(detail.useQnaYn);
      setUseFaqYn(detail.useFaqYn);
      if(detail.voteType) {
        setUseVoteYn('Y');
        setVoteType(detail.voteType);
        setVoteTypeText(baundProjectVoteTypeOptions.filter(e=>e.value===detail.voteType)[0].text);
      }

      setBeatSelected(res.data.beats ?res.data.beats:[]);
      setProjectCautions(
          res.data.projectCautions?.length > 0 ? res.data.projectCautions : []
      );

      if (detail.externalMovieLinkUrl) {
        setExternalMovieLinkUrl(detail.externalMovieLinkUrl.map((el, idx) => ({id: idx, text: el})));
        externalMovieLinkUrlNextId.current = detail.externalMovieLinkUrl.length;
      }

      if (detail.winnerBenefit) {
        setWinnerBenefit(detail.winnerBenefit?.length > 0 ? detail.winnerBenefit : []);
      }
      if (detail.howToApply) {
        setHowToApply(detail.howToApply?.length > 0 ? detail.howToApply : []);
      }

      let work = baundProjectStatusTypeOptions.filter(e=>e.value === detail.status)[0]
      // console.log(work);
      setStatusText(work.text);

    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  useEffect(() => {
    // 수정일 때는
    if (baundProjectId) {
      // 상세 가져오기
      getDetail().then();
    }
    // 공통 유의 사항 조회
    axios.get(`${BAUND_PROJECT_CAUTION}/all`)
    .then(res => {
      setCommonCautions(res.data.baundProjectCautions);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({
          propertyForSort,
          directionForSort,
          privateYnForSearch,
          privateYnTextForSearch,
          statusForSearch,
          statusTextForSearch,
          pageNumberForSearch,
          keywordForSearch,
          searchTypeForSearch
        })
    );
  }, []);

  // 정보 저장 //////////////////////////////////////////////////////////////////
  const saveDetail = async () => {

    if (baundProjectId && !confirm(`프로젝트 내용을 수정하시겠습니까? 프로젝트가 오픈중이라면 수정된 정보가 노출됩니다.`)) {
      return;
    }

    // 파라미터 체크
    if(!isParameterValid()) return;

    const request = {};
    const formData = new FormData();

    request['title'] = title;
    request['titleEn'] = titleEn;
    request['carouselProjectTitle'] = carouselProjectTitle || title;
    request['carouselProjectTitleEn'] = carouselProjectTitleEn || titleEn;
    request['description'] = description;
    request['descriptionEn'] = descriptionEn;

    request['preReleaseStartDateTime'] = `${preReleaseStartDt.replace(' ', 'T')}`;
    request['applyStartDateTime'] = `${applyStartDt.replace(' ', 'T')}`;
    request['applyEndDateTime'] = `${applyEndDt.replace(' ', 'T')}`;

    if(useVoteYn === "Y") {
      request['finalAnnounceDateTime'] = `${finalAnnounceDt.replace(' ', 'T')}`;
      request['voteStartDateTime'] = `${voteStartDt.replace(' ', 'T')}`;
      request['voteEndDateTime'] = `${voteEndDt.replace(' ', 'T')}`;
    }

    request['winnerAnnounceDateTime'] = `${winnerAnnounceDt.replace(' ', 'T')}`;

    (detailImgFile != null) && formData.append('detailImgFile', detailImgFile);

    if (externalMovieLinkUrl.length > 0) {
      request['externalMovieLinkUrl'] = [];
      externalMovieLinkUrl.forEach(el => request['externalMovieLinkUrl'].push(el.text))
    }

    request['judgeCriteriaLike'] = judgeCriteriaLike;
    request['judgeCriteriaView'] = judgeCriteriaView;
    request['judgeCriteriaHost'] = judgeCriteriaHost;
    request['judgeCriteriaVote'] = judgeCriteriaVote;
    request['judgeCriteriaPanel'] = judgeCriteriaPanel;

    request['beatIds'] = beatIds;

    if (winnerBenefit.length > 0) {
      request['winnerBenefit'] = [...winnerBenefit].filter(b => b.contentsKr !== ''); // 빈값 제외 삽입
    }
    if (howToApply.length > 0) {
      request['howToApply'] = [...howToApply].filter(b => b.contentsKr !== ''); // 빈값 제외 삽입
    }

    request['privateYn'] = privateYn;
    request['useQnaYn'] = useQnaYn;
    request['useFaqYn'] = useFaqYn;
    request['voteType'] = voteType;

    if (projectCautions.length > 0) {
      request['projectCautions'] = [...projectCautions].filter(
          c => c.caution !== ''); // 빈값 제외 삽입
    }

    const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
    formData.append('request', blob);

    dispatch(setLoading());
    try {
      (baundProjectId)
          ? await axios.put(`${BAUND_PROJECT}/${baundProjectId}`, formData)
          : await axios.post(`${BAUND_PROJECT}`, formData, {});
      alert('저장이 완료 되었습니다.');
      navigate('/baundProject/baundProject');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 저장 처리 호출 전 필수 입력값 체크
  const isParameterValid = () => {
    let alertItems = [];

    // 필수값 체크
    (isBlank(title) || isBlank(titleEn)) && alertItems.push('- 프로젝트 이름');
    if(useVoteYn === "Y") {
      (!voteType) && alertItems.push('- 프로젝트 투표 방식');
      (!finalAnnounceDt) && alertItems.push('- 투표 진출자 발표');
      (!voteStartDt || !voteEndDt) && alertItems.push('- 투표 기간');
    }
    (!applyStartDt || !applyEndDt) && alertItems.push('- 모집 기간');
    (!winnerAnnounceDt) && alertItems.push('- 최종 우승자 발표');
    (!detailImgUrl && detailImgFile == null) && alertItems.push('- 프로젝트 상세 상단 이미지');
    ((!commonCautions || commonCautions.length===0)&&(!projectCautions || projectCautions.length===0)) && alertItems.push('- 유의사항');
    commonCautions.some(hasBlankCaution) && alertItems.push('- 공통 유의사항');
    projectCautions.some(hasBlankCaution) && alertItems.push('- 프로젝트별 개별 유의사항');
    // (!judgeCriteriaLike || !judgeCriteriaView || !judgeCriteriaVote || !judgeCriteriaHost || !judgeCriteriaPanel)
    //   && alertItems.push('- 프로젝트 선정 기준');
    if (alertItems.length !== 0) {
      alertItems = [`필수 값을 확인해주세요.`, ...alertItems];
      alert(alertItems.join('\n'));
      return false;
    }

    // 날짜간의 선후 관계 확인
    if (!isDatetimeValid()) {
      return false;
    }

    return true;
  }

  // 날짜간의 선후 관계 확인
  const isDatetimeValid = (
    _preReleaseStartDt,       //사전 공개 노출 시작 일시
    _applyStartDt,                 //모집 시작 일시
    _applyEndDt,                     //모집 종료 일시
    _finalAnnounceDt,          //본선 진출자 발표 일시
    _voteStartDt,                   //투표 시작 일시
    _voteEndDt,                       //투표 종료 일시
    _winnerAnnounceDt,         //합격자 발표 일자
  ) => {

    // 과거 날짜 입력 확인
    if(
        (_preReleaseStartDt && isPreReleaseStartDtEditable(_preReleaseStartDt) && isPast(_preReleaseStartDt))
        || (_applyStartDt && isApplyStartDtEditable() && isPast(_applyStartDt))
        || (_applyEndDt && isApplyEndDtEditable() && isPast(_applyEndDt))
        || (_finalAnnounceDt && isFinalAnnounceDtEditable() && isPast(_finalAnnounceDt))
        || (_voteStartDt && isVoteStartDtEditable() && isPast(_voteStartDt))
        || (_voteEndDt && isVoteEndDtEditable() && isPast(_voteEndDt))
        || (_winnerAnnounceDt && isWinnerAnnounceDtEditable() && isPast(_winnerAnnounceDt))
    ) {
      alert(`현재보다 이전 날짜를 지정할 수 없습니다.`);
      return false;
    }

    // 날짜 선후 관계 확인
    if (isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '사전 공개', _preReleaseStartDt, '모집 시작 기간', _applyStartDt)) {
      return false;
    }
    if (isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '모집 시작 기간', _applyStartDt, '모집 마감 기간', _applyEndDt)) {
      return false;
    }

    // 투표 사용시
    if(useVoteYn === "Y") {
      if(isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '모집 마감 기간', _applyEndDt, '투표 진출자 발표', _finalAnnounceDt)) {
        return false;
      }
      if(isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '투표 진출자 발표', _finalAnnounceDt, '투표 시작 기간', _voteStartDt)) {
        return false;
      }
      if(isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '투표 시작 기간', _voteStartDt, '투표 마감 기간', _voteEndDt)) {
        return false;
      }
      if(isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE, '투표 마감 기간', _voteEndDt, '최종 우승자 발표', _winnerAnnounceDt)) {
        return false;
      }
    }
    // 투표 미 사용시
    else {
      if(isNotInOrderStrDateWithAlert(DATETIME_WARN_MESSAGE , '모집 마감 기간', _applyEndDt, '최종 우승자 발표', _winnerAnnounceDt)) {
        return false;
      }
    }
    return true;
  }

  // 유의사항 공백 체크
  const hasBlankCaution = (el) => {
    return isBlank(el.caution) || isBlank(el.cautionEn)
  }

  //삭제 ///////////////////////////////////////////////////////////////////////
  const deleteSelected = async() => {
    if(!baundProjectId || baundProjectId === 0 ||  ['FINAL', 'VOTE', 'JUDGE', 'FINISH'].includes(status)) {return;}
    if (!confirm('프로젝트를 삭제하시겠습니까? 삭제 후 복구가 어렵습니다.')) {
      return;
    }

    await axios.delete(`${BAUND_PROJECT}/${baundProjectId}`, {})
    .then(() => {
      alert('삭제 완료');
      navigate('/baundProject/baundProject');
    })
    .catch (err => {
      const errorMessage = (err.response.status === 418)
          ? '이 프로젝트는 현재 삭제가 불가능합니다.'
          : '문제가 발생하였습니다. 담당자에게 문의해주세요.';
      console.log('BaundProjectDetail > deleteSelected > err.response:', err.response);
      alert(errorMessage);
    });
  };

  //모집 홍보 관련 영상 링크 ///////////////////////////////////////////////////
  const externalMovieLinkUrlNextId = useRef(0);
  const onClickExternalMovieLinkUrl = (text) => {
    const el = {id: externalMovieLinkUrlNextId.current, text};
    //console.log(externalMovieLinkUrl);
    setExternalMovieLinkUrl([...externalMovieLinkUrl, el]);
    externalMovieLinkUrlNextId.current += 1;
  };
  const onEditExternalMovieLinkUrl = useCallback((externalMovieLinkUrlId, text) => {
    setExternalMovieLinkUrl(externalMovieLinkUrl.map((el) => el.id === externalMovieLinkUrlId ? {...el, text} : el));
  }, [externalMovieLinkUrl]);
  const onRemoveExternalMovieLinkUrl = (externalMovieLinkUrlId) => {
    setExternalMovieLinkUrl(externalMovieLinkUrl.filter((el) => el.id !== externalMovieLinkUrlId));
  };

  // 파일 첨부 /////////////////////////////////////////////////////////////////
  const FILE_CHANGE_MESSAGE = '첨부된 소재가 있습니다. 소재를 교체하시겠습니까?';
  const _detailImgFileInputRef = useRef();
  const handleDetail = () => {
    if((!detailImgUrl && !detailImgFile) || confirm(FILE_CHANGE_MESSAGE)) {
      _detailImgFileInputRef.current.click();
    }
  }

  // 모달 관련 /////////////////////////////////////////////////////////////////
  // 모달 열기
  const openModal = (selectedModalType) => {
    setModalType(selectedModalType);
  };

  // modal 닫기
  const closeModal = () => {
    setModalType('');
  };

  // 공통 함수 /////////////////////////////////////////////////////////////////
  // 드래그 앤 드롭
  const moveBeatListItem = useCallback(
      (dragIndex, hoverIndex) => {
        const dragItem = beatSelected[dragIndex];
        const hoverItem = beatSelected[hoverIndex];

        // eslint-disable-next-line @typescript-eslint/no-shadow
        setBeatSelected((temp) => {
          const workList = [...temp];
          workList[dragIndex] = hoverItem;
          workList[hoverIndex] = dragItem;
          return workList;
        });
      },
      [beatSelected],
  );

  const handleUseVoteYn = (val) => {
    setUseVoteYn(val);
    if(val === 'N') {
      setVoteType(null);
      setVoteTypeText(null);
      setFinalAnnounceDt(null);
      setVoteStartDt(null);
      setVoteEndDt(null);
    }
  }

  const handleVoteType = (voteTypeOption) => {
    setVoteType(voteTypeOption.value);
    setVoteTypeText(voteTypeOption.text);
  }

  const setNumberWithDefaultZero = (value) => {
    let work = (String(value) == 'undefined' || String(value) == 'null' || String(value) == '' || String(value) == 'NaN')
        ? 0 : value;
    work = parseInt(work);
    work = (work < 0) ? 0: work;
    work = (100 < work) ? 100: work;
    return work;
  }

  // useEffect /////////////////////////////////////////////////////////////////
  // 선택된 비트 정보 변경 시 아이디 추출
  useEffect(() => {
    setBeatIds(beatSelected?.map((el) => el.id));
  }, [beatSelected]);

  useEffect(() => {
    if (copyKR2EN) setTitleEn(title);
  }, [title]);

  useEffect(() => {
    if (copyKR2EN) setCarouselProjectTitleEn(carouselProjectTitle);
  }, [carouselProjectTitle]);

  useEffect(() => {
    if (copyKR2EN) {
      setTitleEn(title);
      setCarouselProjectTitleEn(carouselProjectTitle);
    }
  }, [copyKR2EN]);

  // 화면 이동 /////////////////////////////////////////////////////////////////
  // Qna 목록으로 이동
  const moveToQnas = () => {
    // 검색 초기 값을 localStorage에 저장
    // state에 설정하니, 페이지 리로딩 되면서 값이 덮어씌워짐
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({
          baundProjectIdForSearch: baundProjectId,
          pageNumberForSearch: 0,
          answerYnForSearch: 'N'
        })
    );

    navigate(`/baundProject/baundProjectQna/`)
  }

  // 지원자 확인 화면으로 이동
  const moveToParticipants = () => {
    navigate(`/baundProject/baundProject/baundProjectParticipant`, {state:{baundProjectId: baundProjectId}})
  }

  // 날짜 관련 함수 ////////////////////////////////////////////////////////////
  //사전 공개 노출 시작 일시
  const isPreReleaseStartDtEditable = (_preReleaseStartDt) => {
    return !((status === "PRE_RELEASE" && isInOrderStrDate(_preReleaseStartDt, nowDateToSting(new Date(), false)))
        || status === "APPLY" || status === "FINAL" || status === "VOTE" || status === "JUDGE" || status === "FINISH");
  }
  const handlePreReleaseStartDt = (event) => {
    let _preReleaseStartDt = event.target.value;
    if(!isDatetimeValid(_preReleaseStartDt, applyStartDt, applyEndDt, finalAnnounceDt, voteStartDt, voteEndDt, winnerAnnounceDt)) {
      setPreReleaseStartDt('');
      return;
    }
    setPreReleaseStartDt(_preReleaseStartDt);
  }
  //모집 시작 일시
  const isApplyStartDtEditable = () => {
    return !(status === "APPLY" || status === "FINAL" || status === "VOTE" || status === "JUDGE" || status === "FINISH");
  }
  const handleApplyStartDt = (event) => {
    let _applyStartDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, _applyStartDt, applyEndDt, finalAnnounceDt, voteStartDt, voteEndDt, winnerAnnounceDt)) {
      setApplyStartDt('');
      return;
    }
    setApplyStartDt(_applyStartDt);
  }
  //모집 종료 일시
  const isApplyEndDtEditable = () => {
    return !(status === "FINAL" || status === "VOTE" || status === "JUDGE" || status === "FINISH");
  }
  const handleApplyEndDt = (event) => {
    let _applyEndDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, applyStartDt, _applyEndDt, finalAnnounceDt, voteStartDt, voteEndDt, winnerAnnounceDt)) {
      setApplyEndDt('');
      return;
    }
    setApplyEndDt(_applyEndDt);
  }
  //우승 후보 공개 일시
  const isFinalAnnounceDtEditable = () => {
    return !((useVoteYn === 'N')
        || (status === "FINAL" || status === "VOTE" || status === "JUDGE" || status === "FINISH"));
  }
  const handleFinalAnnounceDt = (event) => {
    let _finalAnnounceDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, applyStartDt, applyEndDt, _finalAnnounceDt, voteStartDt, voteEndDt, winnerAnnounceDt)) {
      setFinalAnnounceDt('');
      return;
    }
    setFinalAnnounceDt(_finalAnnounceDt);
  }
  //투표 시작 일시
  const isVoteStartDtEditable = () => {
    return !((useVoteYn === 'N')
        || (status === "VOTE" || status === "JUDGE" || status === "FINISH"));
  }
  const handleVoteStartDt = (event) => {
    let _voteStartDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, applyStartDt, applyEndDt, finalAnnounceDt, _voteStartDt, voteEndDt, winnerAnnounceDt)) {
      setVoteStartDt('');
      return;
    }
    setVoteStartDt(_voteStartDt);
  }
  //투표 종료 일시
  const isVoteEndDtEditable = () => {
    return !((useVoteYn === 'N')
        || (status === "JUDGE" || status === "FINISH"));
  }
  const handleVoteEndDt = (event) => {
    let _voteEndDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, applyStartDt, applyEndDt, finalAnnounceDt, voteStartDt, _voteEndDt, winnerAnnounceDt)) {
      setVoteEndDt('');
      return;
    }
    setVoteEndDt(_voteEndDt);
  }
  //합격자 발표 일자
  const isWinnerAnnounceDtEditable = () => {
    return !((status === "JUDGE" || status === "FINISH"));
  }
  const handleWinnerAnnounceDt = (event) => {
    let _winnerAnnounceDt = event.target.value;
    if(!isDatetimeValid(preReleaseStartDt, applyStartDt, applyEndDt, finalAnnounceDt, voteStartDt, voteEndDt, _winnerAnnounceDt)) {
      setWinnerAnnounceDt('');
      return;
    }
    setWinnerAnnounceDt(_winnerAnnounceDt);
  }

  return <>
    <TitleText>바운드 프로젝트 {baundProjectId ? '수정':'생성'}</TitleText>

    {/* 버튼 영역 */}
    <TopContainer>
      <InputWrapper className={'spaceBetween'}>
        <ButtonWrapper>
          {/*수정 시에만 버튼 표시*/}
          {baundProjectId && (<>
            <NormalButton className={'subDarkGray'} text={"프로젝트 질문 확인하기Q&A"}
                          onSubmit={moveToQnas}/>
            <NormalButton className={'subDarkGray'} text={"지원자 확인"}
                          onSubmit={moveToParticipants}/>
          </>)}
        </ButtonWrapper>
        <ButtonWrapper>
          {baundProjectId && (
              <NormalButton text={"삭제"} onSubmit={deleteSelected}
                className={
                  (!baundProjectId || baundProjectId === 0 || ['FINAL', 'VOTE', 'JUDGE', 'FINISH'].includes(status))
                    ? "unActiveBtn" : "blockBtn"
              }
              />
          )}
          <NormalButton text={'저장하기'} onSubmit={() => saveDetail().then(
              () => dispatch(resetLoading()))}/>
        </ButtonWrapper>
      </InputWrapper>
    </TopContainer>

    {/* 프로젝트 명*/}
    <MainContainer mt={20}>
      <InputWrapper colSpan={2} mt={-20}>
        <CheckBoxInput
            name="copyKR2EN" text="한글 / 영문 동일값 사용" checked={copyKR2EN} onChange={setCopyKR2EN}
        />
      </InputWrapper>

      <InputWrapper>
        <LabelTextInput
          name="title" label="프로젝트 이름" placeholder="프로젝트 이름" Required
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          onReset={() => setTitle('')}
        />
      </InputWrapper>
      <InputWrapper>
        <LabelTextInput
          name="titleEn" label="프로젝트 이름(EN)" placeholder="프로젝트 이름" Required
          value={titleEn} readOnly={copyKR2EN}
          onChange={(e) => setTitleEn(e.target.value)}
          onReset={() => setTitleEn('')}
        />
      </InputWrapper>

      <InputWrapper>
        <LabelTextAreaInput
            name="carouselProjectTitle" label="캐러셀 프로젝트 이름" placeholder="캐러셀 프로젝트 이름" Required
            value={carouselProjectTitle}
            setValue={(e) => setCarouselProjectTitle(e.target.value)}
            onReset={() => setCarouselProjectTitle('')}
        />
      </InputWrapper>
      <InputWrapper>
        <LabelTextAreaInput
            name="carouselProjectTitleEn" label="캐러셀 프로젝트 이름(EN)" placeholder="캐러셀 프로젝트 이름" Required
            value={carouselProjectTitleEn} readOnly={copyKR2EN}
            setValue={(e) => setCarouselProjectTitleEn(e.target.value)}
            onReset={() => setCarouselProjectTitleEn('')}
        />
      </InputWrapper>

      {((preReleaseStartDt && isInOrderStrDate(preReleaseStartDt, nowDateToSting(new Date(), false)))
        || (applyStartDt && isInOrderStrDate(applyStartDt, nowDateToSting(new Date(), false))))
        && (<InputWrapper colSpan={2}><LabelText label={"프로젝트 상태"} value={statusText}/></InputWrapper>)
      }
    </MainContainer>

    <SubTitle>프로젝트 진행 방식 선택</SubTitle>
    <LineDiv />
    <MainContainer>
      {/*프로젝트 진행 방식 선택*/}
      <InputWrapper colSpan={2}>
        <NoticeBox mt={0} className={"lightSteelBlue"}>
          <NoticeSpan>
            <span>프로젝트 2가지 진행 방식</span><br/>
            1번 : 모집 후, 바로 최종 우승자를 발표하는 프로젝트 : 지원자 모집 → 최종 발표<br/>
            2번 : 모집 후, 투표 기간을 거쳐 최종 우승자를 발표하는 프로젝트 : 지원자 모집 → 결선 진출자 공개 → 투표 → 최종 발표
          </NoticeSpan>
        </NoticeBox>
      </InputWrapper>
      <InputWrapper colSpan={2}>
        {((preReleaseStartDt && isInOrderStrDate(preReleaseStartDt, nowDateToSting(new Date(), false)))
                || (applyStartDt && isInOrderStrDate(applyStartDt, nowDateToSting(new Date(), false))))
            ? (<LabelText value={baundProjectUseVoteYnOptions.filter(
                e => e.value === useVoteYn)[0].text}/>)
            : (<RadioButton name="useVoteYn" label=""
                            text={['1번(투표 미사용)', '2번(투표 사용)']} type="privateYn"
                            value={useVoteYn} onChange={(e) => handleUseVoteYn(e.target.value)}
            />)
        }
      </InputWrapper>
      {(useVoteYn === "Y")
        && (<InputWrapper colSpan={2}>
          {((preReleaseStartDt && isInOrderStrDate(preReleaseStartDt, nowDateToSting(new Date(), false)))
          || (applyStartDt && isInOrderStrDate(applyStartDt, nowDateToSting(new Date(), false))))
          ? (<LabelText value={voteTypeText}/>)
          : (<SelectModule name="baundMusicType" label="프로젝트 투표 방식"
                           placeholder={"(투표 방식을 선택해 주세요)"}
                            Required={(useVoteYn==='Y')}
                            value={voteTypeText}
                            onChange={e => handleVoteType(e)}
                            options={baundProjectVoteTypeOptions}
          />)
          }
      </InputWrapper>)}
    </MainContainer>

    <SubTitle>프로젝트 기간 설정</SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper colSpan={2}>
        <NoticeBox mt={0} className={"lightSteelBlue"}>
          <NoticeSpan>
            프로젝트 설정한 기간에 따라 프로젝트 상태가 자동으로 변경됩니다.<br/>
            프로젝트 기간 설정 시, 이전 상태보다 이후 상태의 시간이 더 이후여야 설정 가능합니다.
          </NoticeSpan>
        </NoticeBox>
      </InputWrapper>
      {/*사전 공개 일시*/}
      <InputWrapper >
        {!isPreReleaseStartDtEditable(preReleaseStartDt)
          ? (<LabelText label="사전 공개(선택)" value={preReleaseStartDt}/>)
          : (<DateInput
              name="preReleaseStartDt" label="사전 공개(선택)"
              initialStartDate={preReleaseStartDt}
              onChange={handlePreReleaseStartDt}
          />)
        }
      </InputWrapper>
      <InputWrapper >
        <NoticeBox mt={32}>
          <NoticeSpan>모집 전에 프로젝트를 사전 공개 할 경우 사용하는 레이블</NoticeSpan>
        </NoticeBox>
      </InputWrapper>

      {/* 모집 기간 */}
      <InputWrapper>
        {!isApplyStartDtEditable()
            ? (<LabelText label="모집 시작 기간(필수)" value={applyStartDt}/>)
            : (<DateInput
              name="applyStartDt" label="모집 시작 기간(필수)" Required initialStartDate={applyStartDt}
              onChange={handleApplyStartDt}
            />)
        }
      </InputWrapper>
      <InputWrapper >
        {!isApplyEndDtEditable()
          ? (<LabelText label="모집 마간 기간(필수)" value={applyEndDt}/>)
          : (<DateInput
                name="applyEndDt" label="모집 마감 기간(필수)" Required initialStartDate={applyEndDt}
                onChange={handleApplyEndDt}
            />)
        }
      </InputWrapper>

      {/* 결선 진출자 공개 일시 */}
      <InputWrapper >
        {!isFinalAnnounceDtEditable()
            ? (<LabelText label="결선 진출자 공개" value={finalAnnounceDt}/>)
            : (<DateInput
                  name="finalAnnounceDt" label="결선 진출자 공개" initialStartDate={finalAnnounceDt} Required
                  onChange={handleFinalAnnounceDt}
              />)
        }
      </InputWrapper>
      <InputWrapper >&nbsp;</InputWrapper>

      {/* 투표 기간 */}
      <InputWrapper >
        {!isVoteStartDtEditable()
            ? (<LabelText label="투표 시작 기간" value={voteStartDt}/>)
            : ( <DateInput
                    name="voteStartDt" label="투표 시작 기간" initialStartDate={voteStartDt} Required
                    onChange={handleVoteStartDt}
            />)
        }
      </InputWrapper>
      <InputWrapper >
        {!isVoteEndDtEditable()
            ? (<LabelText label="투표 마감 기간" value={voteEndDt}/>)
            : ( <DateInput
                name="voteEndDt" label="투표 마감 기간" initialStartDate={voteEndDt} Required
                onChange={handleVoteEndDt}
            />)
        }
      </InputWrapper>

      {/* 최종 우승자 공개 */}
      <InputWrapper >
        {!isWinnerAnnounceDtEditable()
          ? (<LabelText label="최종 우승자 공개(필수)" value={winnerAnnounceDt}/>)
          : (<DateInput
                name="winnerAnnounceDt" label="최종 우승자 공개(필수)" Required initialStartDate={winnerAnnounceDt}
                onChange={handleWinnerAnnounceDt}
            />)
        }
      </InputWrapper>
      <InputWrapper >&nbsp;</InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트 선정 기준 설정(필수)</SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper colSpan={2}>
        <NoticeBox mt={0} className={"lightSteelBlue"}>
          <NoticeSpan>
            - 각 항목별 비율에 따라 지원자들의 등수를 정렬로 확인 할 수 있습니다.<br/>
            - 해당 비율은 지원자에게 노출됩니다.<br/>
            &nbsp;&nbsp;&nbsp;- 단, 항목에 0% 입력 시, 해당 항목은 사용하지 않는 기준으로 인지해 해당 항목을 제외하고 노출됩니다.<br/>
            - 우승자 혹은 지출자 선정은 등수에 따라 자동 선택이 아닌 등수를 참고하여 운영자가 직접 선택하여 지정합니다.
          </NoticeSpan>
        </NoticeBox>
      </InputWrapper>
      <InputWrapper ><NoticeBox mt={10}>좋아요 개수</NoticeBox></InputWrapper>
      <InputWrapper >
        {/* 최종 발표 이후 비활성화 */}
        {isFinished
            ? (<LabelText value={judgeCriteriaLike}/>)
            : (<LabelTextInput
                label={""} name="judgeCriteriaLike" type="number" value={judgeCriteriaLike}
                onChange={(e) => setJudgeCriteriaLike(setNumberWithDefaultZero(e.target.value))}
            />)
        }
      </InputWrapper>
      <InputWrapper ><NoticeBox mt={10}>조회수</NoticeBox></InputWrapper>
      <InputWrapper >
        {/* 최종 발표 이후 비활성화 */}
        {isFinished
            ? (<LabelText value={judgeCriteriaView}/>)
            : (<LabelTextInput
                label={""} name="judgeCriteriaView" type="number" value={judgeCriteriaView}
                onChange={(e) => setJudgeCriteriaView(setNumberWithDefaultZero(e.target.value))}
            />)
        }
      </InputWrapper>
      <InputWrapper ><NoticeBox mt={10}>투표수</NoticeBox></InputWrapper>
      <InputWrapper >
        {/* 최종 발표 이후 비활성화 */}
        {isFinished
            ? (<LabelText value={judgeCriteriaVote}/>)
            : (<LabelTextInput
                label={""} name="judgeCriteriaVote" type="number" value={judgeCriteriaVote}
                onChange={(e) => setJudgeCriteriaVote(setNumberWithDefaultZero(e.target.value))}
            />)
        }
      </InputWrapper>
      <InputWrapper ><NoticeBox mt={10}>호스트 점수</NoticeBox></InputWrapper>
      <InputWrapper >
        {/* 최종 발표 이후 비활성화 */}
        {isFinished
            ? (<LabelText value={judgeCriteriaHost}/>)
            : ( <LabelTextInput
                label={""} name="judgeCriteriaHost" type="number" value={judgeCriteriaHost}
                onChange={(e) => setJudgeCriteriaHost(setNumberWithDefaultZero(e.target.value))}
            />)
        }
      </InputWrapper>
      <InputWrapper ><NoticeBox mt={10}>Baund 선정 위원단 점수</NoticeBox></InputWrapper>
      <InputWrapper >
        {/* 최종 발표 이후 비활성화 */}
        {isFinished
            ? (<LabelText value={judgeCriteriaPanel}/>)
            : (<LabelTextInput
                label={""} name="judgeCriteriaPanel" type="number" value={judgeCriteriaPanel}
                onChange={(e) => setJudgeCriteriaPanel(setNumberWithDefaultZero(e.target.value))}
            />)
        }
      </InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트 이미지 추가(필수)</SubTitle>
    <LineDiv />
    <MainContainer>
      {/*상세 페이지 상단 이미지*/}
      <InputWrapper >
        {(!isFinished)
            && (<NormalButtonWithLabel label={"프로젝트 대표 이미지"} buttonText={"파일 첨부"} Required buttonOnClick={handleDetail}/>)
        }
        <NoticeBox mt={10}>프로젝트 목록 및 상세 상단에 노출되는 이미지를 의미합니다.</NoticeBox>
      </InputWrapper>
      <InputWrapper>
        {detailImgUrl ?(<EventImg src={detailImgUrl}/>) :(<EventImg src={blankImg}/>)}
      </InputWrapper>
      <InputWrapper className={'hide'}>
        <FileInput
            type="advertisementMain" name="detailImgUrl" label="프로젝트 대표 이미지"
            placeholder={detailImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
            value={detailImgFile} setFile={setDetailImgFile}
            fileUrl={detailImgUrl} setImgUrl={setDetailImgUrl}
            fileInputRef={_detailImgFileInputRef}
        />
      </InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트 영상 추가(선택) </SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper colSpan={2} className='area1'>
        {/* 최종 발표 이후 읽기 전용 */}
        <LabelPlusButtonInput readOnly={isFinished}
            name="externalMovieLinkUrl" label="Youtube URL" value={externalMovieLinkUrl}
            placeholder={"유튜브 링크를 입력해 주세요."} maxCount={10}
            onClick={onClickExternalMovieLinkUrl} onEdit={onEditExternalMovieLinkUrl} onRemove={onRemoveExternalMovieLinkUrl}
        />
      </InputWrapper>
    </MainContainer>

    <SubTitle>지정 비트(선택) </SubTitle>
    <LineDiv />
    <MainContainer >
      <InputWrapper colSpan={2} className={'spaceBetween'}>
        <NoticeBox mt={0}>최대 10개까지 선택 가능합니다.</NoticeBox>
        {(!isFinished)
            && (<VideoInput onClick={() => openModal('beat')} text="비트 선택하기" />)
        }
      </InputWrapper>
      { (beatSelected && beatSelected.length > 0) && (
          <InputWrapper colSpan={2} mb={15}>
            <TagHeadBeat>
              <ListHeadDiv title="" />
              <ListHeadDiv title="비트아이디" />
              <ListHeadDiv title="Cover" />
              <ListHeadDiv title="Title" />
              <ListHeadDiv title="Pid" />
              <ListHeadDiv title="장르" />
              <ListHeadDiv title="BPM" />
              <ListHeadDiv title="BeatKey" />
              <ListHeadDiv title="공개여부" />
              <ListHeadDiv title="공개 날짜" />
            </TagHeadBeat>
            <>
              <LineDiv mb={0}/>
              {beatSelected?.map((item, index) => (
                  <CuratedBeatRow
                      key={item.id}
                      rowKey={item.id}
                      data={item}
                      moveListItem={moveBeatListItem}
                      index={index}
                      playBeat={playBeat}
                      setPlayBeat={setPlayBeat}
                  />
              ))}
            </>
          </InputWrapper>
      )}
    </MainContainer>

    <SubTitle>프로젝트 혜택(선택) </SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper colSpan={2}>
        <NoticeBox mt={0} className={"lightSteelBlue"}>
          <NoticeSpan>
            - 혜택을 입력하지 않으면, 프로젝트 혜택 목록이 사라집니다.<br/>
            - 혜택이 복수개인 경우, [+] 버튼을 통해 혜택 입력을 추가할 수 있습니다.<br/>
            - 한개의 입력창은 하나의 구분점을 가집니다.
          </NoticeSpan>
        </NoticeBox>
      </InputWrapper>
      <InputWrapper colSpan={2} className='area1'>
        {/* 최종 발표 이후 읽기 전용 */}
        <BilingualPropertyTable
            name='프로젝트 혜택' readOnly={isFinished} keyPrefix={"winnerBenefit"}
            list={winnerBenefit} listSetter={setWinnerBenefit}
            propertyNameKr={'contentsKr'} propertyNameEn={'contentsEn'} propertyNameCommon={'프로젝트 혜택'}
            copyKR2EN={copyKR2EN}
        />
      </InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트 지원 방법(선택) </SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper colSpan={2}>
        <NoticeBox mt={0} className={"lightSteelBlue"}>
          <NoticeSpan>
            - 지원 방법을 입력하지 않으면, 프로젝트 혜택 목록이 사라집니다.<br/>
            - 지원 방법이 복수개인 경우, [+] 버튼을 통해 지원 방법을 추가할 수 있습니다.<br/>
            - 한개의 입력창은 하나의 구분점을 가집니다.
          </NoticeSpan>
        </NoticeBox>
      </InputWrapper>
      <InputWrapper colSpan={2} className='area1'>
        {/* 최종 발표 이후 읽기 전용 */}
        <BilingualPropertyTable
            name='프로젝트 지원 방법' readOnly={isFinished} keyPrefix={"howToApply"}
            list={howToApply} listSetter={setHowToApply}
            propertyNameKr={'contentsKr'} propertyNameEn={'contentsEn'} propertyNameCommon={'프로젝트 지원 방법'}
            copyKR2EN={copyKR2EN}
        />
      </InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트, Q&A, FAQ 노출 여부 설정</SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper>
        <RadioButton
            name="privateYn" label="프로젝트 노출 여부" Required
            text={['노출', '미노출']} type="privateYn"
            value={privateYn} onChange={(e) => setPrivateYn(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <NoticeBox mt={14}>
          1. 사전 공개, 모집 기간, 투표 진출자 발표일, 투표 기간, 최종 우승자 발표일 설정 시간 이후에 ‘미노출' 설정 시,
          프로젝트 탭 화면에서 해당 프로젝트가 숨김처리 됩니다.<br/>
          2. 프로젝트 상태에서 지정한 설정 시간 보다 현 시간이 이전일 경우 ‘노출' 로 설정하더라도 프로젝트 탭에 해당 프로젝트는
          노출되지 않습니다.
        </NoticeBox>
      </InputWrapper>

      <InputWrapper >
        <RadioButton
            name="useQnaYn" label="Q&A 노출 여부" Required
            text={['노출', '미노출']} type="activeYn"
            value={useQnaYn} onChange={(e) => setUseQnaYn(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <NoticeBox mt={14}>
          - Q&A는 프로젝트 별로 사용자들에게 질문을 받고 답변을 할 수 있는 서비스이며, 해당 서비스의 사용 노출 여부를 설정하는 영역입니다.
        </NoticeBox>
      </InputWrapper>

      <InputWrapper >
        <RadioButton
            name="useFaqYn" label="FAQ 노출 여부" Required
            text={['노출', '미노출']} type="activeYn"
            value={useFaqYn} onChange={(e) => setUseFaqYn(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper>
        <NoticeBox mt={14}>
          - 프로젝트 공통 FAQ 노출 여부를 설정하는 영역입니다.
        </NoticeBox>
      </InputWrapper>
    </MainContainer>

    <SubTitle>유의사항 설정</SubTitle>
    <LineDiv />
    <MainContainer>
      {/* 공통 유의사항 */}
      <InputWrapper colSpan={2} mb={10} mt={0} className='area1'>
        <BilingualPropertyTable
            name='공통 유의사항' readOnly={true} keyPrefix={"commonCaution"}
            list={commonCautions} propertyNameKr={'caution'} propertyNameEn={'cautionEn'}
            actionButton={(
                <VideoInput onClick={() => {
                  if (confirm(`공통 유의사항 관리 화면으로 이동하시겠습니까? 생성 또는 수정된 내용은 저장되지 않습니다.`)) {
                    navigate(`/baundProject/baundProjectCaution`);
                  }
                }} text="공통 유의사항 관리 화면으로 이동" />
            )}
        />
      </InputWrapper>

      {/* 프로젝트 유의사항 */}
      <InputWrapper colSpan={2} mb={0} mt={0} className='area1'>
        {/* 최종 발표 이후 읽기 전용 */}
        <BilingualPropertyTable
            name='프로젝트 개별 유의사항' readOnly={isFinished} keyPrefix={"projectCaution"}
            list={projectCautions} listSetter={setProjectCautions}
            propertyNameKr={'caution'} propertyNameEn={'cautionEn'} propertyNameCommon={'유의사항'}
            copyKR2EN={copyKR2EN}
        />
      </InputWrapper>
    </MainContainer>

    <SubTitle>생성 정보</SubTitle>
    <LineDiv />
    <MainContainer>
      <InputWrapper ><LabelText label="생성 일자" value={createDt}/></InputWrapper>
      <InputWrapper ><LabelText label="수정 일자" value={updateDt}/></InputWrapper>
      <InputWrapper ><LabelText label="최초 생성자" value={createAdminAccountName}/></InputWrapper>
      <InputWrapper ><LabelText label="최종 수정자"  value={updateAdminAccountName}/></InputWrapper>
    </MainContainer>

    {/* 비트 추가 모달*/}
    {(modalType === 'beat') && (
      <BeatSelectModal
          closeModal={closeModal}
          playBeat={playBeat}
          setPlayBeat={setPlayBeat}
          beatSelected={beatSelected}
          beatSetter={setBeatSelected}
          maxBeatCount={10}
      />
    )}
  </>;

}
export default BaundProjectDetail;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:not(:first-child) {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '0px'};
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 15px;
  grid-gap: 10px 15px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({rowSpan}) => rowSpan && `span ${rowSpan}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

  &.area1 {
    background-color: #e9e7e7;
    padding: 15px;
    border-radius: 6px;
  }
  
  &.hide{display:none;}
  
  &.spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  input:read-only {
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 0px;
  border-radius: 10px;
`;

const TagHeadBeat = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.8fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 1fr;
`;

const NoticeBox = styled.div`
	padding: 15px;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '30px'};
	border-radius: 5px;
  
  &.lightSteelBlue {
    background-color: #B0C4DE;
  }
`;

const NoticeSpan = styled.div`
	font-size: 15px;
	display: block;
  
  span {
    font-weight: bold;
	}
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '10px'};
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;