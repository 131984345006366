import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// common
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import ListHead from '../../components/UI/ListHead';
import Table from '../../components/common/List/Table';
import VideoModal from '../../components/common/Modal/VideoModal';

// hook
import useInput from '../../hooks/useInput';
import changeUrl from '../../hooks/changeUrl';

// constant
import {
  articleSearchTypeList,
  articleColList,
  articlePrivateYnOptions,
} from '../../constant/video';
import { postsPerPage } from '../../constant/common';

// config
import { ARTICLE } from '../../constant/apiUrl';

import ArticleRowComponent from './components/ArticleRowComponent';
import FileInput from '../../components/common/Input/FileInput';
import NormalButton from '../../components/common/Button/NormalButton';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';
import ListSelect from '../../components/common/Select/ListSelect';

const SearchVideo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword] = useInput('');
  const [privateYn, setPrivateYn] = useState('');
  const [articleList, setArticleList] = useState([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [contentsUrl, setContentsUrl] = useState('');
  const [coverImgUrl, setCoverImgUrl] = useState('');
  const [selectedArticle, setSelectedArticle] = useState<any>({});

  // modal state
  const [articleFile, setArticleFile] = useState<any>();
  const [articleUrl, setArticleUrl] = useState('');
  const [articleCoverFile, setArticleCoverFile] = useState('');
  const [articleCoverUrl, setArticleCoverUrl] = useState('');
  const videoRef = useRef<any>();

  // const [tags, setTags] = useState<string[]>([]);

  useEffect(() => {
    if (articleFile) {
      videoRef.current.src = URL?.createObjectURL(articleFile);
    }
  }, [articleFile]);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const params = {
    keyword,
    searchType,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    privateYn,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // modal 닫기
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setArticleFile('');
    setArticleCoverFile('');
  }, []);

  const handleArticleFile = (e: any) => {
    setArticleFile(e);
  };

  const handleArticleCoverFile = (e: any) => {
    setArticleCoverFile(e);
  };

  // 수정 버튼 클릭
  const viewArticle = (contentUrl: any, imgUrl: any, data?: any) => {
    setContentsUrl(contentUrl);
    setCoverImgUrl(imgUrl);
    setSelectedArticle(data);
    setModalOpen(true);
    // setTags(data.tags);
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (searchType === 'ID' && !Number(keyword)) {
        alert('영상 id는 숫자만 검색 가능합니다.');
        return;
      }
      if (!searchType) {
        setSearchType('ID');
        params.searchType = 'ID';
      }
      params.offset = 0;
      setPageNumber(0);
      getArticleList();
    }
  };

  // url 컨트롤
  const changedUrl = useMemo(() => changeUrl(contentsUrl), [contentsUrl]);

  // 영상 리스트 불러오기
  const getArticleList = useCallback(async () => {
      const resp = await axios.get(ARTICLE, { params });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
  }, [searchType, pageNumber, keyword, privateYn]);

  // 영상, 영상 썸네일 수동 교체
  const handleChangeContents = useCallback(async () => {
    const formData = new FormData();

    if (articleFile) {
      formData.append('articleFile', articleFile);
      formData.append('extension', 'mp4');
      try {
        dispatch(setLoading());
        await axios.post(`${ARTICLE}/${selectedArticle?.id}/update`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setArticleUrl('');
        alert('수정되었습니다.');
        closeModal();
        getArticleList();
      } catch (err) {
        console.log(err);
      }
    }
    if (articleCoverFile) {
      formData.append('articleCoverImgFile', articleCoverFile);
      try {
        dispatch(setLoading());
        await axios.post(
          `${ARTICLE}/${selectedArticle?.id}/update/thumbnail`,
          formData,
          {headers: {'Content-Type': 'multipart/form-data',},},
        );
        setArticleCoverUrl('');
        alert('수정되었습니다.');
        closeModal();
        getArticleList();
      } catch (err) {
        console.log(err);
      }
    }
    if (!articleFile && !articleCoverFile) {
      alert('업로드할 파일을 선택해 주세요.');
      return;
    }
    dispatch(resetLoading());
  }, [
    articleCoverFile,
    articleFile,
    closeModal,
    dispatch,
    getArticleList,
    selectedArticle?.id,
  ]);

  useEffect(() => {
    getArticleList();
  }, [pageNumber]);

  useEffect(() => {
    params.offset = 0;
    setPageNumber(0);
    getArticleList();
  }, [privateYn]);

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
      position: fixed;
      overflow-y: scroll;
      width: 100%;`;
    }
    return () => {
      document.body.style.cssText = '';
    };
  }, [modalOpen]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={articleSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={keyword}
                onChange={setKeyword}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="영상 업로드하기"
            onSubmit={() => navigate('/video/searchVideo/add')}
          />
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={articleColList}
          thead={
            <>
              <ListHead title="id" />
              <ListHead title="썸네일" />
              <ListHead title="타입" />
              <ListHead title="영상길이" />
              <ListHead title="Pid" />
              <ListHead title="이름" />
              <ListHead title="비트명" />
              <ListHead title="업로드 날짜" />
              <ListSelect title="공개 여부" list={articlePrivateYnOptions} onClick={handleSelect} setState={setPrivateYn} />
              <ListHead title="상태" />
              <ListHead title="" />
            </>
          }
        >
          {articleList.length !== 0 &&
            articleList?.map((item: any) => (
              <ArticleRowComponent
                key={item.id}
                data={item}
                onClick={viewArticle}
              />
            ))}
        </Table>
      </MainContainer>

      {modalOpen &&
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <ModalContainer>
            <ButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={() => closeModal()}
                className="cancelBtn"
              />
              {(articleFile || articleCoverFile) && <NormalButton text="저장하기" onSubmit={handleChangeContents} />}
            </ButtonWrapper>
            <MainWrapper>
              <VideoContainer>
                <ContentType>영상</ContentType>
                <Video ref={videoRef} controls autoPlay>
                  <track default kind="captions" />
                  <source
                    src={articleUrl ? URL.createObjectURL(articleFile) : changedUrl}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                  I suggest you upgrade your browser.
                </Video>
              </VideoContainer>
              <ImgContainer>
                <ContentType>영상 썸네일</ContentType>
                <img alt="thumbnail Img" src={articleCoverUrl || coverImgUrl} />
              </ImgContainer>
              <UploadContainer>
                <InputContainer>
                  <FileInput
                    type="article"
                    placeholder={
                      articleFile
                        ? '파일이 등록되었습니다.'
                        : '파일을 선택해주세요. (mp4 파일만 가능)'
                    }
                    label="영상 재업로드"
                    value={articleFile}
                    setFile={handleArticleFile}
                  />
                </InputContainer>
                <InputContainer>
                  <FileInput
                    type="eventImg"
                    placeholder={
                      articleCoverFile
                        ? '파일이 등록되었습니다.'
                        : '파일을 선택해주세요.'
                    }
                    label="영상 썸네일 재업로드"
                    value={articleCoverFile}
                    setFile={handleArticleCoverFile}
                    setImgUrl={setArticleCoverUrl}
                  />
                </InputContainer>

                {/*<InputContainer>*/}
                {/*  <TextField label="해시 태그" text={tags && tags.join(', ')} />*/}
                {/*</InputContainer>*/}

              </UploadContainer>
            </MainWrapper>
          </ModalContainer>
        </VideoModal>
      }
    </>
  );
};

export default SearchVideo;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const Video = styled.video`
  max-width: 300px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 1356px;
  padding: 70px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const VideoContainer = styled.div`
  margin-right: 50px;
`;

const ImgContainer = styled.div`
  img {
    width: 300px;
  }
`;

const ContentType = styled.p`
  margin-bottom: 10px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 70px 0 0 30px;
  width: calc(100% - 600px);
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;

  button {
    &:nth-child(2) {
      margin-left: 20px;
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
  padding-left: 50px;
  margin-bottom: 30px;
  &:nth-child(2) {
    margin-top: 70px;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainWrapper = styled.div`
  display: flex;
`;
