import {useLocation, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import React, {useEffect, useMemo, useRef, useState} from "react";
import axios from "axios";
import {BAUND_PROJECT_PARTICIPANT} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import styled from "styled-components";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import {
  baundProjectParticipantResultTypeOption, baundProjectVoteTypeOptions
} from "../../constant/baundProject";
import SelectModule from "../../components/common/Select/SelectModule";
import VideoModal from "../../components/common/Modal/VideoModal";
import changeUrl from "../../hooks/changeUrl";
import LabelText from "../../components/common/LabelText";
import blankImg from "../../assets/images/page/blankImg.png";

// 바운드 프로젝트 지원자 상세
const BaundProjectParticipantDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectParticipantId = detailData !== null ? detailData.baundProjectParticipantId : null;
  const baundProjectId = detailData !== null ? detailData.baundProjectId : null;
  // 재검색 조건
  const filterForSearch = detailData?.filterForSearch || null;
  const wordForSearch = detailData?.wordForSearch || null;
  const typeForSearch = detailData?.typeForSearch || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;

  // 영상 정보
  const [contentsUrl, setContentsUrl] = useState('');
  const [coverImgUrl, setCoverImgUrl] = useState('');
  // 지원자 정보
  const [pid, setPid] = useState('');
  const [email, setEmail] = useState('');
  const [profileImgUrl, setProfileImgUrl] = useState('');
  // 지원한 프로젝트 정보
  const [title, setTitle] = useState('');
  const [voteType, setVoteType] = useState('');
  const [voteTypeText, setVoteTypeText] = useState();
  // 프로젝트 지원 상태
  const [allRank, setAllRank] = useState('-');
  const [applyRank, setApplyRank] = useState('-');
  const [judgeRank, setJudgeRank] = useState('-');
  const [applyLikeCount, setApplyLikeCount] = useState('-');
  const [applyViewCount, setApplyViewCount] = useState('-');
  const [applyHostPoint, setApplyHostPoint] = useState('-');
  const [applyPanelPoint, setApplyPanelPoint] = useState('-');
  const [judgeLikeCount, setJudgeLikeCount] = useState('-');
  const [judgeViewCount, setJudgeViewCount] = useState('-');
  const [judgeVoteCount, setJudgeVoteCount] = useState('-');
  const [judgeHostPoint, setJudgeHostPoint] = useState('-');
  const [judgePanelPoint, setJudgePanelPoint] = useState('-');
  // 지원자 결과 설정
  const [applyResult, setApplyResult] = useState('');
  const [applyResultText, setApplyResultText] = useState('');
  const [judgeResult, setJudgeResult] = useState('');
  const [judgeResultText, setJudgeResultText] = useState('');
  // 선정 기준
  const [judgeCriteriaLike, setJudgeCriteriaLike] = useState(0);
  const [judgeCriteriaView, setJudgeCriteriaView] = useState(0);
  const [judgeCriteriaHost, setJudgeCriteriaHost] = useState(0);
  const [judgeCriteriaVote, setJudgeCriteriaVote] = useState(0);
  const [judgeCriteriaPanel, setJudgeCriteriaPanel] = useState(0);
  const [status, setStatus] = useState();

  // 영상 팝업 /////////////////////////////////////////////////////////////////
  const [modalOpen, setModalOpen] = useState(false);
  const videoRef = useRef();
  const changedUrl = useMemo(() => changeUrl(contentsUrl), [contentsUrl]);

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${BAUND_PROJECT_PARTICIPANT}/${baundProjectParticipantId}`,
    )
    .then(res => {
      const detail = res.data.baundProjectParticipant;
      setContentsUrl(detail.contentsUrl);
      setCoverImgUrl(detail.coverImgUrl);
      setPid(detail.pid);
      setEmail(detail.email);
      setProfileImgUrl(detail.profileImgUrl)
      setTitle(detail.title);
      setVoteType(detail.voteType);
      setAllRank(detail.allRank ? detail.allRank : "-");
      setApplyRank(detail.applyRank ? detail.applyRank : "-");
      setJudgeRank(detail.judgeRank ? detail.judgeRank : '-');
      setApplyLikeCount(detail.applyLikeCount);
      setApplyViewCount(detail.applyViewCount);
      setApplyHostPoint(detail.applyHostPoint);
      setApplyPanelPoint(detail.applyPanelPoint);
      setJudgeLikeCount(detail.judgeLikeCount);
      setJudgeViewCount(detail.judgeViewCount);
      setJudgeVoteCount(detail.judgeVoteCount);
      setJudgeHostPoint(detail.judgeHostPoint);
      setJudgePanelPoint(detail.judgePanelPoint);
      setApplyResult(detail.applyResult);
      setJudgeResult(detail.judgeResult);

      setApplyResultText(baundProjectParticipantResultTypeOption.filter(
          e => e.value === detail.applyResult)[0].text);
      setJudgeResultText(baundProjectParticipantResultTypeOption.filter(
          e => e.value === detail.judgeResult)[0].text);

      let work = baundProjectVoteTypeOptions.filter(
          e => e.value === detail.voteType)[0];
      (work) && setVoteTypeText(work.text);

      const projectDetail = res.data.baundProject;
      setJudgeCriteriaLike(projectDetail.judgeCriteriaLike);
      setJudgeCriteriaView(projectDetail.judgeCriteriaView);
      setJudgeCriteriaVote(projectDetail.judgeCriteriaVote);
      setJudgeCriteriaHost(projectDetail.judgeCriteriaHost);
      setJudgeCriteriaPanel(projectDetail.judgeCriteriaPanel);
      setStatus(projectDetail.status);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  useEffect(() => {
    getDetail().then();

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition2nd',
        JSON.stringify({ filterForSearch, wordForSearch, typeForSearch, pageNumberForSearch })
    );
  }, [baundProjectParticipantId]);

  // 점수 수정 /////////////////////////////////////////////////////////////////
  const handleApplyHostPoint = (value) => {
    let senitizedValue = setNumberWithDefaultZero(value);
    setApplyHostPoint(senitizedValue);
  }
  const handleApplyPanelPoint = (value) => {
    let senitizedValue = setNumberWithDefaultZero(value);
    setApplyPanelPoint(senitizedValue);
  }
  const handleJudgeHostPoint = (value) => {
    let senitizedValue = setNumberWithDefaultZero(value);
    setJudgeHostPoint(senitizedValue);
  }
  const handleJudgePanelPoint = (value) => {
    let senitizedValue = setNumberWithDefaultZero(value);
    setJudgePanelPoint(senitizedValue);
  }
  // 점수 값 제한 처리
  const setNumberWithDefaultZero = (value) => {
    let work = (String(value) === 'undefined' || String(value) === 'null' || String(value) === '' || String(value) === 'NaN')
        ? 0 : value;
    work = parseInt(work);
    work = (work < 0) ? 0: work;
    work = (100 < work) ? 100: work;
    return work;
  }

  // 지원자 결과 설정 //////////////////////////////////////////////////////////
  // 우승 후보 결과 변경
  const handleApplyResult = (e) => {
    // 모든 상태에서 수정 가능하나, 우승 후보 공개 일자가 지난 경우
    // ‘우승 후보 진출자’에 대한 값을 시도 할 경우 컨펌 팝업 확인 후, 변경 가능
    if (
        ["FINAL", "VOTE", "JUDGE", "FINISH"].includes(status)
        && !confirm("우승 후보 결과 일자가 지났습니다. 지원자 결과 값을 변경 하시겠습니까?\n"
          + "변경 시, 앱에 바로 적용됩니다.")
    ) {
      return false;
    }
    setApplyResult(e.value);
    setApplyResultText(e.text);
  }
  // 최종 우승자 결과 변경
  const handleJudgeResult = (e) => {

    // 결과 발표 단계가 존재하는 프로젝트의 경우, 결과 발표 진출자를 선택하지 않으면 최종 우승자를 선택 할 수 없음
    // 시도 시, 변경 불가 팝업 노출
    if (voteType && ["PRE_RELEASE", "APPLY"].includes(status)) {
      alert("우승 후보 공개 일자가 지난 이후, 최종 우승자 선택이 가능합니다.\n"
        + "해당 프로젝트는 우승 후보 대상으로 최종 우승자 선정이 되는 프로젝트입니다.");
      return false;
    }

    // 모든 상태에서 수정 가능하나
    if (
        // 프로젝트 종료 일자가 지난 경우 ‘최종 진출자’에 대한 값을 시도 할 경우
        (status === "FINISH")
        // 컨펌 팝업 확인 후, 변경 가능
        && !confirm("프로젝트 종료 일자가 지났습니다. 지원자 결과 값을 변경 하시겠습니까?\n"
        + "변경 시, 앱에 바로 적용됩니다.")
    ) {
      return false;
    }

    if (
        // 투표를 사용하는 경우
        voteType
        //해당 팝업은 프로젝트 상태가 [투표중], [최종 우승자 선정중], [종료] 의 경우에만 확인 할 수 있는 팝업이다.
        &&["VOTE", "JUDGE", "FINISH"].includes(status)
        //우승 후보가 ‘진출’이 아닌데 최종 우승자로 시도할 경우,
        && (e.value === "PASS") && (applyResult !== "PASS")
    ) {
      alert("우승 후보 진출자만 해당 프로젝트의 최종 우승자가 될 수 있습니다. ");
      return false;
    }

    setJudgeResult(e.value);
    setJudgeResultText(e.text);
  }
  
  // 지원자 상세 정보 저장 /////////////////////////////////////////////////////
  const saveDetail = async () => {
    const formData = new FormData();
    formData.append('applyHostPoint', applyHostPoint);
    formData.append('judgeHostPoint', judgeHostPoint);
    formData.append('applyPanelPoint', applyPanelPoint);
    formData.append('judgePanelPoint', judgePanelPoint);
    formData.append('applyResult', applyResult);
    formData.append('judgeResult', judgeResult);

    dispatch(setLoading());
    try {
      await axios.put(`${BAUND_PROJECT_PARTICIPANT}/${baundProjectParticipantId}`, formData)
      alert('저장이 완료 되었습니다.');
      navigate(`/baundProject/baundProject/baundProjectParticipant`, {state:{baundProjectId: baundProjectId}})
    }
    catch(reason){
      console.error(
          '문제가 발생하였습니다. 담당자에게 문의해주세요.:\n'
          + JSON.stringify(reason.response.data.message)
      )
      alert(errorMessage);
    }
    finally{
      dispatch(resetLoading());
    }
  }

  // modal /////////////////////////////////////////////////////////////////////
  const closeModal = () => {
    setModalOpen(false);
  };

  return (<>
    <TitleText>지원자 상세 정보</TitleText>

    {/* 버튼 영역 */}
    <TopContainer>
      <TopWrapper jc={'right'}>
      <InputWrapper className={'spaceBetween'}>
        <ButtonWrapper>
          <NormalButton text={'저장'} onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}/>
        </ButtonWrapper>
      </InputWrapper>
      </TopWrapper>
    </TopContainer>

    <SubTitle>지원자 지원 정보</SubTitle>
    <LineDiv />
    <MainContainer colCount={7}>
      <InputWrapper rowSpan={2}>
          <EventImg src={profileImgUrl || blankImg}/>
      </InputWrapper>
      <InputWrapper colSpan={6}>
        <NormalButton className={'subDarkGray'} text={"지원 영상 보러가기"}
                      onSubmit={()=>setModalOpen(true)}/>
      </InputWrapper>
      <InputWrapper colSpan={3}><LabelText label="지원자 pid" value={pid}/></InputWrapper>
      <InputWrapper colSpan={3}><LabelText label="이메일 주소" value={email}/></InputWrapper>
    </MainContainer>

    <SubTitle>지원한 프로젝트 정보</SubTitle>
    <LineDiv />
    <MainContainer colCount={3}>
      <InputWrapper><LabelText label="프로젝트 ID" value={baundProjectId}/></InputWrapper>
      <InputWrapper><LabelText label="프로젝트 이름" value={title}/></InputWrapper>
      <InputWrapper><LabelText label="프로젝트 진행 방식" value={voteTypeText}/></InputWrapper>
    </MainContainer>

    <SubTitle>프로젝트 지원 상태</SubTitle>
    <LineDiv />
    <MainContainer colCount={6}>
      <InputWrapper colSpan={2}><LabelText label="전체 지원자 중 등수" value={allRank}/></InputWrapper>
      <InputWrapper colSpan={2}><LabelText label="결선 진출자 중 등수" value={applyRank}/></InputWrapper>
      <InputWrapper colSpan={2}><LabelText label="최종 우승자 중 등수" value={judgeRank}/></InputWrapper>

      <InputWrapper colSpan={3} mt={10}>
        <SmallTitle mt={0}>결선 진출자 선정 기준 점수</SmallTitle>
        <InnerContainer>
          <InputWrapper>&nbsp;</InputWrapper>
          <InputWrapper><InputText>비율</InputText></InputWrapper>
          <InputWrapper><InputText>개수 / 점수</InputText></InputWrapper>

          <InputWrapper mt={19}>좋아요 개수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaLike}/></InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaLike?applyLikeCount:'-'}/></InputWrapper>
          <InputWrapper mt={19}>조회 수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaView}/></InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaView?applyViewCount:'-'}/></InputWrapper>
          <InputWrapper mt={19}>투표 수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaVote}/></InputWrapper>
          <InputWrapper><LabelText value={'-'}/></InputWrapper>
          <InputWrapper mt={19}>호스트 점수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaHost}/></InputWrapper>
          {/* 투표 사용하고, 호스트 점수 선정 기준이 설정되어 있고, 결승 진출자 선정 이전 */}
          {(voteType && judgeCriteriaHost && !["FINAL", "VOTE", "JUDGE", "FINISH"].includes(status))
              ?(<InputWrapper mt={-10}>
                  <LabelTextInput
                    name="applyHostPoint" type="number" value={applyHostPoint} label={''}
                    onChange={(e) => handleApplyHostPoint(e.target.value)}/>
              </InputWrapper>)
              :(<InputWrapper>
                  <LabelText value={judgeCriteriaHost?applyHostPoint:'-'}/>
              </InputWrapper>)
          }
          <InputWrapper mt={19}>Baund 선정 위원단 점수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaPanel}/></InputWrapper>
          {/* 투표 사용하고, 위원단 점수 선정 기준이 설정되어 있고, 결승 진출자 선정 이전 */}
          {(voteType && judgeCriteriaPanel && !["FINAL", "VOTE", "JUDGE", "FINISH"].includes(status))
              ?(<InputWrapper mt={-10}>
                  <LabelTextInput
                    name="applyPanelPoint" type="number" value={applyPanelPoint} label={''}
                    onChange={(e) => handleApplyPanelPoint(e.target.value)}/>
              </InputWrapper>)
              :(<InputWrapper>
                  <LabelText value={judgeCriteriaPanel?applyPanelPoint:'-'}/>
              </InputWrapper>)
          }
        </InnerContainer>
      </InputWrapper>
      <InputWrapper colSpan={3} mt={10}>
        <SmallTitle mt={0}>최종 우승자 선정 기준 점수</SmallTitle>
        <InnerContainer>
          <InputWrapper>&nbsp;</InputWrapper>
          <InputWrapper><InputText>비율</InputText></InputWrapper>
          <InputWrapper><InputText>개수 / 점수</InputText></InputWrapper>

          <InputWrapper mt={19}>좋아요 개수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaLike}/></InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaLike?judgeLikeCount:'-'}/></InputWrapper>
          <InputWrapper mt={19}>조회수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaView}/></InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaView?judgeViewCount:'-'}/></InputWrapper>
          <InputWrapper mt={19}>투표수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaVote}/></InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaVote?judgeVoteCount:'-'}/></InputWrapper>
          <InputWrapper mt={19}>호스트 점수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaHost}/></InputWrapper>
          {/* 호스트 점수 선정 기준 입력 되어 있고, 최종 발표 이전 단계인 경우 활성화 */}
          {(judgeCriteriaHost && (status !== "FINISH"))
              ?(<InputWrapper mt={-10}>
                  <LabelTextInput
                    name="judgeHostPoint" type="number" value={judgeHostPoint} label={''}
                    onChange={(e) => handleJudgeHostPoint(e.target.value)}/>
              </InputWrapper>)
              :(<InputWrapper>
                  <LabelText value={judgeCriteriaPanel?judgeHostPoint:'-'}/>
              </InputWrapper>)
          }
          <InputWrapper mt={19}>Baund 선정 위원단 점수</InputWrapper>
          <InputWrapper><LabelText value={judgeCriteriaPanel}/></InputWrapper>
          {/* 위원단 점수 선정 기준 입력 되어 있고, 최종 발표 이전 단계인 경우 활성화 */}
          {(judgeCriteriaPanel && (status !== "FINISH"))
              ?(<InputWrapper mt={-10}>
                  <LabelTextInput
                    name="judgePanelPoint" type="number" value={judgePanelPoint} label={''}
                    onChange={(e) => handleJudgePanelPoint(e.target.value)}/>
              </InputWrapper>)
              :(<InputWrapper>
                  <LabelText value={judgeCriteriaPanel?judgePanelPoint:'-'}/>
              </InputWrapper>)
          }
        </InnerContainer>
      </InputWrapper>
    </MainContainer>

    <SubTitle>지원자 결과 설정</SubTitle>
    <LineDiv />
    <MainContainer colCount={2}>
      <InputWrapper>
        {/* 투표 사용시 활성화 */}
        {voteType
            ?(<SelectModule
                name="applyResult" label="결선 진출자 결과" placeholder={"결선 진출자 결과"}
                options={baundProjectParticipantResultTypeOption}
                value={applyResultText}
                onChange={handleApplyResult}
            />)
            :(<LabelText label="결선 진출자 상태" value={applyResultText}/>)
        }
      </InputWrapper>
      <InputWrapper>
        <SelectModule
            name="judgeResult" label="최종 우승자 결과" placeholder={"최종 우승자 결과"}
            options={baundProjectParticipantResultTypeOption}
            value={judgeResultText}
            onChange={handleJudgeResult}
        />
      </InputWrapper>
    </MainContainer>

    {modalOpen &&
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <ModalContainer>
            <ButtonWrapper>
              <NormalButton text="취소" onSubmit={() =>closeModal()} className="cancelBtn"/>
            </ButtonWrapper>
            <MainWrapper>
              <VideoContainer>
                <ContentType>영상</ContentType>
                <Video ref={videoRef} controls autoPlay>
                  <track default kind="captions" />
                  <source src={changedUrl} type="video/mp4"/>
                  Your browser does not support the video tag.
                  I suggest you upgrade your browser.
                </Video>
              </VideoContainer>
              <ImgContainer>
                <ContentType>영상 썸네일</ContentType>
                <img alt="thumbnail Img" src={coverImgUrl} />
              </ImgContainer>
            </MainWrapper>
          </ModalContainer>
        </VideoModal>
    }
  </>);
}
export default BaundProjectParticipantDetail;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '0px'};
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: ${({colCount}) => colCount ? `repeat(${colCount}, 1fr)`: `repeat(10, 1fr)`};;
  gap: 10px 15px;
  grid-gap: 10px 15px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({rowSpan}) => rowSpan && `span ${rowSpan}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  &.area1 {
    background-color: #ccc;
    padding: 15px;
    border-radius: 6px;
  }
  
  &.hide{display:none;}
  
  &.spaceBetween{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const SmallTitle = styled.h3`
  font-size: 17px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 0;
  border-radius: 10px;
`;

const InnerContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '0px'};
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px 15px;
  grid-gap: 10px 15px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 830px;
  padding: 70px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const VideoContainer = styled.div`
  margin-right: 50px;
`;

const ImgContainer = styled.div`
  img {
    width: 300px;
  }
`;

const ContentType = styled.p`
  margin-bottom: 10px;
  flex-grow: 0;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const MainWrapper = styled.div`
  display: flex;
`;

const Video = styled.video`
  max-width: 300px;
`;

const InputText = styled.span`
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '10px'};
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;