// Baund Music Option
export const baundMusicType = {
  OP: 'Baund Original Plays',
  PW: 'POWERED',
  CP: 'COMPLIT',
  TR: 'THE:RISE',
};

// Baund Music StreamingService 타입
export const baundMusicStreamingServiceType = {
  APM: 'Apple Music',
  SPT: 'Spotify',
  UTM: 'Youtube Music',
  MEL: 'Melon',
};

export const releaseMusicSearchTypeList = [
  { text: 'Index', value: 'I' },
  { text: 'Title', value: 'T' },
  { text: '아티스트', value: 'A' },
];

export const baundMusicTypeOption = [
  { text: 'Baund Original Plays', value: 'OP' },
  { text: 'POWERED', value: 'PW' },
  { text: 'COMPLIT', value: 'CP' },
  { text: 'THE:RISE', value: 'TR' },
];

export const commentAllowOptions = [
  { name: '모두에게 허용', value: 'A' },
  { name: '팔로우한 사용자에게만 허용', value: 'F' },
  { name: '허용 안 함', value: 'N' },
];

export const musicPrivateYnOptions = [
  { text: '전체', value: '' },
  { text: '공개', value: 'N' },
  { text: '비공개', value: 'Y' },
];

export const releaseMusicColList = [70, 100, 105, 160, 110, 120, 150, 100];

export const modalExternalLinkColList = [75, 100, 110, 110, 110, 40];

export const modalStreamingServicesColList = [150, 520];
