import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import { BAUND_PROJECT_FAQ} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextAreaInput
  from "../../components/common/Input/LabelTextAreaInput";
import styled from "styled-components";
import RadioButton from "../../components/common/Button/RadioButton";
import LabelText from "../../components/common/LabelText";
import {isBlank} from "../../utils/commonFn";
import CheckBoxInput from "../../components/common/Input/CheckBoxInput";

// 바운드 프로젝트 FAQ 상세 화면(추가, 수정)
const BaundProjectFaqDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectFaqId = detailData?.baundProjectFaqId || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;
  const privateYnForSearch = detailData?.privateYnForSearch || null;

  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [questionEn, setQuestionEn] = useState('');
  const [answerEn, setAnswerEn] = useState('');
  const [privateYn, setPrivateYn] = useState('N');
  const [createDt, setCreateDt] = useState('');
  const [updateDt, setUpdateDt] = useState('');
  const [createAdminAccountName, setCreateAdminAccountName] = useState('');
  const [updateAdminAccountName, setUpdateAdminAccountName] = useState('');

  // 내용 입력의 편의를 위해 true 인 경우에 한글 입력 값을 영문 값으로 복사. 신규 일때는 기본 값 true
  const [copyKR2EN, setCopyKR2EN] = useState(!baundProjectFaqId);

  // 상세 조회 /////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${BAUND_PROJECT_FAQ}/${baundProjectFaqId}`,
    )
    .then(res => {
      const detail = res.data.baundProjectFaq;
      setQuestion(detail.question);
      setAnswer(detail.answer);
      setQuestionEn(detail.questionEn);
      setAnswerEn(detail.answerEn);
      setPrivateYn(detail.privateYn);
      setCreateDt(detail.createDt);
      setUpdateDt(detail.updateDt);
      setCreateAdminAccountName(detail.createAdminAccountName);
      setUpdateAdminAccountName(detail.updateAdminAccountName);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  useEffect(() => {
    if (baundProjectFaqId) {
      getDetail().then();
    }

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ pageNumberForSearch, privateYnForSearch })
    );

  }, [baundProjectFaqId]);

  // 저장 //////////////////////////////////////////////////////////////////////
  const saveDetail = async () => {

    if (!confirm((baundProjectFaqId)
        ? `FAQ 내용을 수정하시겠습니까? 수정시, 변경한 내용으로 앱에 노출됩니다. `
        : `FAQ 내용을 저장하시겠습니까? 저장한 내용으로 앱에 노출됩니다. `
    )) {
      return;
    }
    if (isBlank(question)) {
      alert('질문을 입력해 주세요.');
      return;
    }
    if (isBlank(answer)) {
      alert('답변을 입력해 주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('question', question);
    formData.append('answer', answer);
    formData.append('questionEn', questionEn);
    formData.append('answerEn', answerEn);
    formData.append('privateYn', privateYn);

    dispatch(setLoading());
    try {
      (baundProjectFaqId)
          ? await axios.put(`${BAUND_PROJECT_FAQ}/${baundProjectFaqId}`, formData)
          : await axios.post(`${BAUND_PROJECT_FAQ}`, formData, {});
      alert('저장 되었습니다.');
      navigate('/baundProject/baundProjectFaq');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 논리 삭제 //////////////////////////////////////////////////////////////////////
  const deleteSelected = async() => {

    if (!confirm(`FAQ를 삭제하시겠습니까? 삭제 후 복구가 어렵습니다. `)) {
      return;
    }

    dispatch(setLoading());
    try {
      await axios.delete(`${BAUND_PROJECT_FAQ}/${baundProjectFaqId}`);
      alert('삭제 되었습니다.');
      navigate('/baundProject/baundProjectFaq');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  useEffect(() => {
    if (copyKR2EN) setQuestionEn(question);
  }, [question]);

  useEffect(() => {
    if (copyKR2EN) setAnswerEn(answer);
  }, [answer]);

  useEffect(() => {
    if (copyKR2EN) {
      setAnswerEn(answer);
      setQuestionEn(question);
    }
  }, [copyKR2EN]);

  return <>
    <TitleText>바운드 프로젝트 FAQ {baundProjectFaqId ? '상세':'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper jc={'flex-end'}>
        {baundProjectFaqId && (
            <NormalButton
                text={"삭제"}
                className={(!baundProjectFaqId || baundProjectFaqId === 0) ? "unActiveBtn": "blockBtn"}
                onSubmit={deleteSelected}
            />
        )}
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>

    <MainContainer>
      <InputWrapper span={2} mt={-20}>
        <CheckBoxInput
            name="copyKR2EN" text="한글 / 영문 동일값 사용" checked={copyKR2EN} onChange={setCopyKR2EN}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="question" label="질문" placeholder="질문을 입력해주세요."
            value={question}
            setValue={(e)=> setQuestion(e.target.value)}
            onReset={()=> setQuestion('')}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
                name="questionEn" label="질문(EN)" placeholder="질문(EN)을 입력해주세요."
                value={questionEn} readOnly={copyKR2EN}
                setValue={(e)=> setQuestionEn(e.target.value)}
                onReset={()=> setQuestionEn('')}
            />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="answer" label="답변" placeholder="답변을 입력해주세요."
            value={answer}
            setValue={(e)=> setAnswer(e.target.value)}
            onReset={()=> setAnswer('')}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="answerEn" label="답변(EN)" placeholder="답변(EN)을 입력해주세요."
            value={answerEn} readOnly={copyKR2EN}
            setValue={(e)=> setAnswerEn(e.target.value)}
            onReset={()=> setAnswerEn('')}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <RadioButton
            name="privateYn" label="비공개 여부" Required
            text={['공개', '비공개']} type="privateYn"
            value={privateYn} onChange={(e) => setPrivateYn(e.target.value)}
        />
      </InputWrapper>
    </MainContainer>

    {/*생성 정보*/}
    <MainContainer>
      <InputWrapper><LabelText label="생성 일자" value={createDt}/></InputWrapper>
      <InputWrapper><LabelText label="수정 일자" value={updateDt}/></InputWrapper>
      <InputWrapper><LabelText label="최초 생성자" value={createAdminAccountName}/></InputWrapper>
      <InputWrapper><LabelText label="최종 수정자" value={updateAdminAccountName}/></InputWrapper>
    </MainContainer>

  </>;

};
export default BaundProjectFaqDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc && `${jc}`};;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({row}) => row && `span ${row}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

  &.area1 {
    background-color: #ccc;
    padding: 15px;
    border-radius: 6px;
  }

  textarea:read-only {
    color: ${({ theme }) => theme.color.subGray};
  }

`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;
