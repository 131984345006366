import React, { useState, useCallback, ChangeEvent } from 'react';

type onChangeType = (e: ChangeEvent<HTMLInputElement>) => void;

const useInput = (initialState = '') => {
  const [state, setState] = useState(initialState);

  const onChange = useCallback((e) => {
    setState(e.target.value);
  }, []);

  const onReset = (): void => {
    setState('');
  };

  return [state, onChange, onReset] as [string, onChangeType, () => void];
};

export default useInput;
