import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import axios from "axios";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import {postsPerPage} from "../../../constant/common";

import VideoModal from "../../../components/common/Modal/VideoModal";
import NormalButton from "../../../components/common/Button/NormalButton";
import Table from "../../../components/common/List/Table";
import Pagination from "../../../components/common/Pagination";
import ListHead from "../../../components/UI/ListHead";
import ListItem from "../../../components/common/List/ListItem";
import handleDate from "../../../hooks/handleDate";
import useInput from "../../../hooks/useInput";
import {CAMPAIGNS} from "../../../constant/apiUrl";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import {useDispatch} from "react-redux";
import ConfirmPopup from "../../../components/common/ConfirmPopup";

//골드 캠페인 응모,교환자 확인 팝업
export const GoldCampaignApplicantPopup = ({
  campaignId,
  campaignName,
  modalType,
  onClose
}) => {
  const dispatch = useDispatch();

  // 응모자/교환자 정보 검색 조건
  const [keyword, setKeyword, onReset] = useInput('');
  const [searchType, setSearchType] = useState('');

  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  // 응모자 또는 교환자 정보
  const [applicants, setApplicants] = useState([]);

  // 당첨자 아이디
  const [winnerIds, setWinnerIds] = useState([]);

  // 진행 중인 타입이 응모 인지 여부(false는 교환)
  const isRaffle = (modalType === 'RAFFLE');

  //응모자/교환자 정보 조회 공통
  const getApplicantsCommon = async(params) => {
    return await axios.get(`${CAMPAIGNS}/${campaignId}/participants`, { params: params })
    .then(response => {return response.data;})
    .catch(reason => console.error("catch:" + reason));
  }

  //응모자/교환자 정보 조회(페이징 된 화면 표시용)
  const getApplicantsForPage = async () => {
    // params
    const params = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
      searchType: modalType,
      // keyword,
      // searchType,
    };
    await getApplicantsCommon(params)
    .then(response => {
      setApplicants(response.participantList.map((item) => {
        //체크박스 체크시 undefined<->명시적 값 토글시 발생하는 에러를 막기 위해 기본 값 설정
        item.selected = item.status === 'WIN';
        return item;
      }));
      setTotalCount(response.historyCount || 0);
    })
    .catch(reason => console.error(reason));
  };
  // 페이지 변경시 실행. pageDispatch(?)로 화면 진입시 1회 실행됨
  useEffect(() =>{
      getApplicantsForPage().then(()=> {});
  }, [pageNumber]);

  // 선택 상태 변경
  const setSelected = (selectedMemberId, checked) => {
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    applicants.map((item, index) => {
      if (item.memberId === selectedMemberId) {
        item.selected = checked;
      }
    });

    setWinnerIds(
        //선택한 것이면
        checked
            // 당첨자 아이디 목록에 추가
            ? [...winnerIds, selectedMemberId]
            // 당첨자 아이디 목록에서 제외
            : winnerIds.filter((item)=> item !== selectedMemberId)
    );
  }

  // 당첨자 정보 저장
  const saveWinner = ConfirmPopup(
    winnerIds.length + "명이 선택 되었습니다. 저장 하시겠습니까?",
    async () => {
      if(winnerIds.length === 0) {
        return;
      }
      await axios.put(`${CAMPAIGNS}/${campaignId}/announce/winner`,
          {memberIdList:winnerIds}
          )
        .then(() => {
          alert('저장되었습니다.');
        })
        .catch(reason => {
          alert(reason.response.data.message);
          console.error(reason);
        })
      .finally(() =>{
        setApplicants([]);
        setWinnerIds([]);
        getApplicantsForPage();
      });
    }
  );

  //응모자/교환자 정보 조회(엑셀 출력용)
  const getApplicantsForExcel = async () => {
    dispatch(setLoading());

    // 처음 1회 호출 (데이터도 가져오지만 갯수만 사용)
    const workForCount = await getApplicantsCommon(
        {offset: 0, limit: 1, searchType: modalType}
    );

    // 모두 받을 때 까지 반복 호출
    const PAGE_FOR_EXCEL = 500;
    let applicantsAll = [];
    for (let initPageNumber=0; workForCount.historyCount >= initPageNumber * PAGE_FOR_EXCEL; initPageNumber++){
      const workForData = await getApplicantsCommon({
        offset: initPageNumber * PAGE_FOR_EXCEL, limit: PAGE_FOR_EXCEL, searchType: modalType
      }).then(response => {
        return response;
      });
      applicantsAll.push(...workForData.participantList);
    }
    dispatch(resetLoading());

    return applicantsAll;
  };

  // 엑셀 다운로드
  const excelDownload = async () => {
    // 엑셀 출력용 데이터 조회
    const rawDatas = await getApplicantsForExcel()
    .then(response => {return response;});

    // 필요 항목
    const excelDatas = rawDatas.map(row => (
        {
          status: (row.status==='WIN')
                    ?"당첨"
                    :((row.status === "RAFFLE") ? "응모":"교환"),
          memberId: row.memberId,
          pid: row.memberPid,
          participationDate: row.participationDate,
          campaignSavedName: row.campaignSavedName,
          campaignSavedMobile: row.campaignSavedMobile,
          campaignSavedAddress: row.campaignSavedAddress + ((row.campaignSavedAddressDetail) ? (' ' + row.campaignSavedAddressDetail) : ''),
          campaignSavedEmail: row.campaignSavedEmail,
          agreementPersonalInfoTerms: row.agreementPersonalInfoTerms,
          agreementIdentifyInfoTerms: row.agreementIdentifyInfoTerms,
          agreementMmsAdvertisementInfoTerms: row.agreementMmsAdvertisementInfoTerms,
        }
    ));

    // console.log(excelDatas);
    //워크 시트 작성
    const worksheet = XLSX.utils.json_to_sheet(excelDatas);

    // 컬럼명 변경
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [[
        '상태',
        'memberId',
        'pid',
        (isRaffle ?'응모 일시':'교환 신청 일시'),
        '개인정보-이름',
        '개인정보-휴대폰 번호',
        '개인정보-주소',
        '개인정보-이메일',
        '개인 정보 처리 동의 여부',
        '고유 정보 처리 동의 여부',
        'mms 수신 정보 동의 여부',
      ]],
      { origin: "A1" }
    );

    // 열 폭 지정
    worksheet['!cols'] = [
       {wpx:50},
       {wpx:70},
       {wpx:100},
       {wpx:120},
       {wpx:100},
       {wpx:120},
       {wpx:200},
       {wpx:150},
       {wpx:180},
       {wpx:180},
       {wpx:180},
     ];

    //캠페인명으로 워크시트명 지정
    // let replacedCampaignName = campaignName.replace(/\/|\\|\?|\*|\[|]/g, "_");
    // if (replacedCampaignName.length > 30) {
    //   replacedCampaignName = replacedCampaignName.substring(0,30);
    // }
    let replacedCampaignName = campaignId.toString();
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, replacedCampaignName);

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array'});

    const excelFile = new Blob(
        [excelBuffer],
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        }
    );
    // 다운로드 파일 명
    let today = new Date();
    let excelFileName = (isRaffle ? "응모":"교환 신청")
        + " 회원 목록"
        + "_" +today.getFullYear()
        + '.' + ((today.getMonth() + 1) > 9 ? (today.getMonth() + 1).toString() : "0" + (today.getMonth() + 1))
        + '.' + (today.getDate() > 9 ? today.getDate().toString() : "0" + today.getDate().toString())
        + '_' + (today.getHours() > 9 ? today.getHours().toString() : "0" + today.getHours().toString())
        + '.' + (today.getMinutes() > 9 ? today.getMinutes().toString() : "0" + today.getMinutes().toString())
        + '.' + (today.getSeconds() > 9 ? today.getSeconds().toString() : "0" + today.getSeconds().toString())
        + ".xlsx";
    FileSaver.saveAs(excelFile, excelFileName);
  }

  // modal 닫기
  const closeModal = () => {
    onClose(null);
    onReset();
    setPageNumber(0);
    setSearchType('');
    setWinnerIds([]);
  };

  return (
      <VideoModal closeModal={closeModal} blockCloseBtn>
        <VideoModalWrapper>
          <TopContainer>
            <VideoModalTitle>
              {isRaffle ? ("응모 회원 목록") : ("교환 신청 회원 목록")}
            </VideoModalTitle>
            <ModalButtonWrapper>
              <NormalButton text="취소" className="cancelBtn" onSubmit={closeModal}/>
              {/*응모일 경우에만 저장하기 버튼 표시*/}
              {isRaffle && (
                  <NormalButton text="당첨자 저장" onSubmit={saveWinner}/>
              )}
              <NormalButton text="엑셀 다운로드" onSubmit={() => excelDownload()}/>
            </ModalButtonWrapper>
          </TopContainer>

          {/*검색 영역*/}
          {/*<TitleWrapper>*/}
          {/*  <SearchWrapper>*/}
          {/*    <SearchMenuSelect*/}
          {/*        options={campaignApplicantSearchTypeList}*/}
          {/*        onClick={handleSelect}*/}
          {/*        setState={setSearchType}*/}
          {/*    />*/}
          {/*    <SearchInputWrapper>*/}
          {/*      <SearchInput*/}
          {/*          value={keyword}*/}
          {/*          onChange={setKeyword}*/}
          {/*          onKeyPress={enterKeyPress}*/}
          {/*      />*/}
          {/*    </SearchInputWrapper>*/}
          {/*  </SearchWrapper>*/}
          {/*</TitleWrapper>*/}

          {/*페이징 표시 영역*/}
          <PaginationWrapper>
            <TableTitle>{totalCount}명</TableTitle>
            {totalCount !== 0 && (
                <Pagination
                    postsPerPage={postsPerPage}
                    totalCount={totalCount}
                    currentPage={pageNumber}
                    pageDispatch={pageDispatch}
                />
            )}
          </PaginationWrapper>
          <LineDiv className="30" />

          {/*검색 결과 표시 영역*/}
          <Table
              colList={[20, 80, 140, 140, 140, 100]}
              thead={
                <>
                  <ListHead title="" />
                  <ListHead title="회원Id" />
                  <ListHead title="Pid" />
                  <ListHead title="이메일" />
                  <ListHead title="전화번호" />
                  <ListHead title="응모 날짜" />
                </>
              }
          >
            {applicants?.map((applicant) => (
              <tr style={{height: 40 + 'px'}} key={applicant.memberId}>
                <td>
                  {/*응모일 경우에만 체크 박스 표시*/}
                  {isRaffle &&(
                      <input
                          id={`checkbox_${applicant.memberId}`} type="checkbox"
                          disabled={applicant.status === "WIN"}
                          checked={applicant.status === "WIN" || applicant.selected}
                          // checked={applicant.status === "WIN"}
                          onChange={(e) => setSelected(applicant.memberId, e.currentTarget.checked)}
                      />
                  )}
                </td>
                <td><ListItem text={applicant.memberId} /></td>
                <td><ListItem text={applicant.memberPid} /></td>
                <td><ListItem text={applicant.memberEmail} /></td>
                <td><ListItem text={applicant.memberMobile} /></td>
                <td><ListItem text={handleDate(applicant.participationDate)} /></td>
              </tr>
            ))}
          </Table>
        </VideoModalWrapper>
      </VideoModal>
  );
};

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  button {
    & {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
