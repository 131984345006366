import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getGenreList, getParentGenreList } from '../actions/genre/genre';

interface initialType {
  genreCount: number;
  genreList: Array<any>;
  getGenreListError: string;
  detailGenre: any;
}

const initialState: initialType = {
  genreCount: 0,
  genreList: [],
  getGenreListError: '',
  detailGenre: {
    id: '',
    code: '',
    genre: '',
    createdDt: '',
    updateDt: '',
    genreImgUrl: '',
    genreImgFile: '',
    searchKeywords: null,
  },
};

const genreSlice = createSlice({
  name: 'genre',
  initialState,
  reducers: {
    getDetailGenre: (state, action) => {
      state.detailGenre = action.payload;
    },
    resetDetailGenre: (state) => {
      state.detailGenre = initialState.detailGenre;
    },
  },
  extraReducers: (builder) => {
    builder
      // 장르 리스트가져오기
      .addCase(getGenreList.pending, (state, action) => {})
      .addCase(getGenreList.fulfilled, (state, action) => {
        state.genreList = action.payload.masterGenreList;
        state.genreCount = action.payload.count;
      })
      .addCase(getGenreList.rejected, (state, action: PayloadAction<any>) => {
        state.getGenreListError = action.payload;
      })

      // 부모 장르 리스트 가져오기
      .addCase(getParentGenreList.pending, (state, action) => {})
      .addCase(getParentGenreList.fulfilled, (state, action) => {
        state.genreList = action.payload.masterParentGenreList;
        state.genreCount = action.payload.count;
      })
      .addCase(
        getParentGenreList.rejected,
        (state, action: PayloadAction<any>) => {
          console.log('error');
        },
      );
  },
});

export default genreSlice;
export const { getDetailGenre, resetDetailGenre } = genreSlice.actions;
