import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import { ColorPicker, useColor } from 'react-color-palette';
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import 'react-color-palette/lib/css/styles.css';
import LeftArrow from '../../assets/images/page/leftArrow.png';
import RightArrow from '../../assets/images/page/rightArrow.png';

// config
import { MISSIONS_POINT_GUIDE_AREA } from '../../constant/apiUrl';
import { resetLoading, setLoading } from '../../redux/slices/user';
import {useLocation} from "react-router";

function fontSizeCheck(e: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) {
  if (Number(e.currentTarget.value) < 1) {
    alert('폰트 크기는 숫자만, 1 이상 입력 해 주세요');
    e.currentTarget.value = '13';
  }
}

const initRgbColor = {
  r: 255,
  g: 255,
  b: 255,
};

const PointGuideAreaDetail = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const languageType = location.state.languageType;
  // 프론트 에서만 사용하는 필드
  const [lessPointBgColor, setLessPointBgColor] = useColor('rgb', initRgbColor);
  const [lessMessageColor, setLessMessageColor] = useColor('rgb', initRgbColor);
  const [overPointBgColor, setOverPointBgColor] = useColor('rgb', initRgbColor);
  const [overMessageColor, setOverMessageColor] = useColor('rgb', initRgbColor);
  const [focusedOverPointColorBoxId, setFocusedOverPointColorBoxId] =
      useState('overPointColorBox0');
  // 서버와 동기화 되어야 하는 필드
  const [lessPointBg, setLessPointBg] = useState('rgb(230,230,230)');
  const [lessPointMessage, setLessPointMessage] = useState('');
  const [lessPointMessageColor, setLessPointMessageColor] = useState('black');
  const [lessPointMessageSize, setLessPointMessageSize] = useState('38');
  const [overPointBgColors, setOverPointBgColors] = useState<string[]>([
    `rgb(0, 0, 0)`,
    `rgb(230,230,230)`,
  ]);
  const [overPointMessage, setOverPointMessage] = useState('');
  const [overPointMessageColor, setOverPointMessageColor] = useState('black');
  const [overPointMessageSize, setOverPointMessageSize] = useState('38');
  const [standardPoint, setStandardPoint] = useState('');

  const getDetail = useCallback(async () => {
    const resp = await axios.get(`${MISSIONS_POINT_GUIDE_AREA}/${languageType}`);
    // 기준 포인트 세팅
    setStandardPoint(resp.data.adminPointGuideArea.standardPoint);
    // lessPoint 영역 세팅
    setLessPointBg(resp.data.adminPointGuideArea.lessPointBg);
    setLessPointMessage(resp.data.adminPointGuideArea.lessPointMessage);
    setLessPointMessageColor(resp.data.adminPointGuideArea.lessPointMessageColor);
    setLessPointMessageSize(resp.data.adminPointGuideArea.lessPointMessageSize);
    // overPoint 영역 세팅
    setOverPointBgColors(
        resp.data.adminPointGuideArea.overPointBgColors === undefined ||
        resp.data.adminPointGuideArea.overPointBgColors.length === 0
            ? [
              `rgb(${lessPointBgColor.rgb.r}, ${lessPointBgColor.rgb.g}, ${lessPointBgColor.rgb.b})`,
              `rgb(230,230,230)`,
            ]
            : resp.data.adminPointGuideArea.overPointBgColors,
    );
    setOverPointMessage(resp.data.adminPointGuideArea.overPointMessage);
    setOverPointMessageColor(resp.data.adminPointGuideArea.overPointMessageColor);
    setOverPointMessageSize(resp.data.adminPointGuideArea.overPointMessageSize);
  }, []);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    getDetail().then((r) => r);
  }, []); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함
  const addContents = async () => {
    const data = {
      lessPointBg,
      lessPointMessage,
      lessPointMessageColor,
      lessPointMessageSize,
      overPointBgColors,
      overPointMessageColor,
      overPointMessageSize,
      overPointMessage,
      standardPoint,
    };
    const jsonData = JSON.stringify(data);
    try {
      dispatch(setLoading());
      await axios.put(`${MISSIONS_POINT_GUIDE_AREA}/${languageType}`, jsonData, {
          headers: {
              'Content-Type': 'application/json'
          },
        },
      )
      await getDetail();
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };
  const setOverPointColor = (changeColor: any, id: string) => {
    if (id === undefined) return;
    const replacedId = id.replace('overPointColorBox', '');
    const convertedId = Number(replacedId);
    overPointBgColors[convertedId] =
        `rgb(${changeColor.rgb.r}, ${changeColor.rgb.g}, ${changeColor.rgb.b})`;
    if (convertedId > 1) {
      document.getElementById(id)
      .style.setProperty('background-color', overPointBgColors[convertedId]);
    }
  };
  const addGradientColor = () => {
    const parentBoxDiv = document.getElementById('parentOverPointColorBox');
    if (parentBoxDiv == null) return;
    const elementCount = parentBoxDiv.childElementCount;
    if (parentBoxDiv.childElementCount > 10) {
      alert('너무 많은 색 조합은 비효율적일 수 있습니다.');
      return;
    }
    overPointBgColors.push('rgb(230,230,230)');
    setOverPointBgColors(overPointBgColors);
    const id = `overPointColorBox${elementCount}`;
    const width = '30px';
    const height = '30px';
    const display = 'inline-flex';
    const newDiv = document.createElement('div');
    newDiv.setAttribute('id', id);
    newDiv.style.setProperty('width', width);
    newDiv.style.setProperty('height', height);
    newDiv.style.setProperty('display', display);
    newDiv.style.setProperty('margin-left', '10px');
    newDiv.style.setProperty('background-color', `${overPointBgColors[elementCount]}`);
    newDiv.addEventListener('mouseover', () => mouseOverEvent(newDiv));
    newDiv.addEventListener('mouseleave', () => mouseLeaveEvent(newDiv));
    newDiv.addEventListener('click', () => {
        setFocusedOverPointColorBoxId(id);
    });
    parentBoxDiv.appendChild(newDiv);
    setFocusedOverPointColorBoxId(`overPointColorBox${parentBoxDiv.childElementCount-1}`);
  };
  const removeGradientColor = () => {
    const parentBoxDiv = document.getElementById('parentOverPointColorBox');
    if (parentBoxDiv == null) return;
    const elemCount = parentBoxDiv.childElementCount;
    if (elemCount <= 2) {
      alert('기준 포인트 이상 노출 문구의 최소 색 개수는 2개 이상 이어야 합니다.');
      return;
    }
    parentBoxDiv.removeChild(parentBoxDiv.lastElementChild);
    overPointBgColors.pop();
    const focusedId = Number(focusedOverPointColorBoxId.replace('overPointColorBox', ''));
    if (focusedId >= elemCount - 1) {
      setFocusedOverPointColorBoxId(`overPointColorBox${parentBoxDiv.childElementCount - 1}`);
    }
  };

  return (
      <>
        <TopContainer>
          <ButtonWrapper>
            <NormalButton
                text="새로고침"
                className="refreshBtn"
                onSubmit={() => window.location.reload()}
            />
            <NormalButton
                text="수정하기"
                onSubmit={() => addContents('edit')}
            />

          </ButtonWrapper>
        </TopContainer>
        <MainContainer>
          <InputWrapper>
            <LabelTextInput
                name="standardPoint"
                label="기준 포인트"
                value={standardPoint}
                placeholder="문구 노출의 기준이 될 포인트 값을 입력 해 주세요"
                onChange={(e) => setStandardPoint(e.target.value)}
                onReset={() => setStandardPoint('')}
                Required
            />
          </InputWrapper>
        </MainContainer>
        <hr />
        <h2>기준 포인트 미만 노출 문구</h2>
        <LabelWrapper>기본 메시지 폰트는 38이며, 각 기기별로 글자의 배치가 서로 다를 수 있으므로 확인이 필요 합니다.</LabelWrapper>
        <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
            }}
        >
          <div
              style={{
                width: '1020px',
                height: '150px',
                marginTop: '50px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
                backgroundColor: `${lessPointBg}`,
                borderRadius: '10px',
              }}
          >
            <p
                style={{
                  color: `${lessPointMessageColor}`,
                  fontSize: Number(`${lessPointMessageSize}`),
                }}
            >
              {lessPointMessage}
          </p>
        </div>
    </div>
      <MainContainer>
        <InputWrapper>
          <LabelWrapper>배경 색</LabelWrapper>
          <ColorPicker
            width={500}
            height={100}
            color={lessPointBgColor}
            onChange={(color) => {
              setLessPointBgColor(color);
              setLessPointBg(
                `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
              );
            }}
            hideHSV
            hideHEX
            dark
          />
        </InputWrapper>
        <InputWrapper>
          <LabelWrapper>메시지 색</LabelWrapper>
          <ColorPicker
            width={500}
            height={100}
            color={lessMessageColor}
            onChange={(color) => {
              setLessMessageColor(color);
              setLessPointMessageColor(
                `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`,
              );
            }}
            hideHSV
            hideHEX
            dark
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="lessPointMessageSize"
            label="메시지 폰트 크기"
            type="number"
            value={lessPointMessageSize}
            placeholder="기준 포인트를 입력 해 주세요"
            onChange={(e) => {
              fontSizeCheck(e);
              setLessPointMessageSize(e.currentTarget.value);
            }}
            onReset={() => setLessPointMessageSize('')}
            onKeyPress={(e) => {
              fontSizeCheck(e);
            }}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="lessPointMessage"
            label="기준 포인트 미만 보유시 노출 메시지"
            value={lessPointMessage}
            placeholder="메시지를 입력 해 주세요"
            onChange={(e) => setLessPointMessage(e.target.value)}
            onReset={() => setLessPointMessage('')}
            Required
          />
        </InputWrapper>
      </MainContainer>
      <hr />
      <h2>기준 포인트 이상 노출 문구</h2>
        <LabelWrapper>기본 메시지 폰트는 38이며, 각 기기별로 글자의 배치가 서로 다를 수 있으므로 확인이 필요 합니다.</LabelWrapper>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            width: '1020px',
            height: '150px',
            marginTop: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
            backgroundImage: `${getLinearGradientProps(overPointBgColors)}`,
            borderRadius: '10px',
          }}
        >
          <p
            style={{
              color: `${overPointMessageColor}`,
              fontSize: Number(`${overPointMessageSize}`),
            }}
          >
            {overPointMessage}
          </p>
        </div>
      </div>
      <MainContainer>
        <OverPointInputWrapper>
          <div style={{ marginBottom: '10px' }}>
            <LabelWrapper>배경 색</LabelWrapper>
            <ColorPicker
              width={500}
              height={100}
              color={overPointBgColor}
              onChange={(color) => {
                setOverPointBgColor(color);
                setOverPointColor(color, focusedOverPointColorBoxId);
              }}
              hideHSV
              hideHEX
              dark
            />
          </div>
          <div
            style={{
              width: '30px',
              height: '30px',
              display: 'inline-flex',
              marginRight: '10px',
            }}
          >
            <img
                src={LeftArrow}
                onMouseOver={(e) => mouseOverEvent(e.currentTarget)}
                onMouseLeave={(e) => mouseLeaveEvent(e.currentTarget)}
                onClick={removeGradientColor}
                alt=''
            />
          </div>
          <div id="parentOverPointColorBox" style={{ display: 'inline-flex' }}>
            <div
              id="overPointColorBox0"
              style={{
                width: '30px',
                height: '30px',
                display: 'inline-flex',
                marginRight: '10px',
                backgroundColor:
                  overPointBgColors === undefined
                    ? 'rgb(0, 0, 0)'
                    : overPointBgColors[0],
              }}
              onMouseOver={(e) => mouseOverEvent(e.currentTarget)}
              onMouseLeave={(e) => mouseLeaveEvent(e.currentTarget)}
              onClick={(e) => {
                setFocusedOverPointColorBoxId(e.currentTarget.id);
              }}
            />
            <div
              id="overPointColorBox1"
              style={{
                width: '30px',
                height: '30px',
                display: 'inline-flex',
                backgroundColor:
                  overPointBgColors === undefined ||
                  overPointBgColors.length <= 1
                    ? 'rgb(230, 230, 230)'
                    : overPointBgColors[1],
              }}
              onMouseOver={(e) => mouseOverEvent(e.currentTarget)}
              onMouseLeave={(e) => mouseLeaveEvent(e.currentTarget)}
              onClick={(e) => {
                setFocusedOverPointColorBoxId(e.currentTarget.id);
              }}
            />
          </div>
          <div
            style={{
              width: '30px',
              height: '30px',
              display: 'inline-flex',
              marginLeft: '10px',
            }}
          >
            <img
                src={RightArrow}
                onMouseOver={(e) => mouseOverEvent(e.currentTarget)}
                onMouseLeave={(e) => mouseLeaveEvent(e.currentTarget)}
                onClick={addGradientColor}
                alt=''
            />
          </div>
        </OverPointInputWrapper>
        <OverPointInputWrapper>
          <LabelWrapper>메시지 색</LabelWrapper>
          <ColorPicker
            width={500}
            height={100}
            color={overMessageColor}
            onChange={(color) => {
              setOverMessageColor(color);
              setOverPointMessageColor(
                `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
              );
            }}
            hideHSV
            hideHEX
            dark
          />
        </OverPointInputWrapper>
        <OverPointInputWrapper>
          <LabelTextInput
            name="overPointMessageSize"
            label="메시지 폰트 크기"
            type="number"
            value={overPointMessageSize}
            placeholder="폰트 크기를 입력 해 주세요 (숫자만)"
            onChange={(e) => {
              fontSizeCheck(e);
              setOverPointMessageSize(e.currentTarget.value);
            }}
            onReset={() => setOverPointMessageSize('')}
            Required
            onKeyPress={(e) => fontSizeCheck(e)}
          />
        </OverPointInputWrapper>
        <OverPointInputWrapper>
          <LabelTextInput
            name="overPointMessage"
            label="기준 포인트 이상 보유시 노출 메시지"
            value={overPointMessage}
            placeholder="메시지를 입력 해 주세요"
            onChange={(e) => setOverPointMessage(e.target.value)}
            onReset={() => setOverPointMessage('')}
            Required
          />
        </OverPointInputWrapper>
      </MainContainer>
    </>
  );
};

export default PointGuideAreaDetail;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const mouseOverEvent = (target: EventTarget & HTMLDivElement) => {
  if (target == null) return;
  target.style.borderStyle = 'solid';
  target.style.borderWidth = '3px';
  target.style.cursor = 'pointer';
};

const mouseLeaveEvent = (target: EventTarget & HTMLDivElement) => {
  if (target == null) return;
  target.style.borderStyle = 'none';
};

const getLinearGradientProps = (colorArray: string[]) => {
  let linearGradientProps = `linear-gradient(to right,`;
  if (colorArray === undefined) {
    return `${linearGradientProps} rgb(0,0,0), rgb(255,255,255))`;
  }
  colorArray.forEach((color) => (linearGradientProps += `${color},`));
  linearGradientProps = linearGradientProps.slice(0, -1);
  linearGradientProps += `)`;
  return linearGradientProps;
};

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
  }

  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }

  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }

  &:nth-child(n + 7):nth-child(-n + 8) {
    grid-row: 4;
  }
`;

const OverPointInputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
  }

  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }

  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }

  &:nth-child(n + 7):nth-child(-n + 8) {
    grid-row: 4;
  }
`;
