import React from 'react';
import styled, { css } from 'styled-components';

type CustomProps = {
  selected: boolean;
};

interface Props {
  title: string;
  selected: boolean;
  onClick: any;
  value?: number;
}

const LargeRoundButton: React.FC<Props> = ({
  value,
  title,
  selected,
  onClick,
}) => {
  return (
    <Container
      value={value}
      selected={selected}
      onClick={onClick}
      title={title}
    >
      {title}
    </Container>
  );
};

export default LargeRoundButton;

const Container = styled.button<CustomProps>`
  width: 100%;
  padding: 13px 0;
  border-radius: 40px;
  border: solid 1px ${({ theme }) => theme.color.subGray};
  color: ${({ theme }) => theme.color.subDarkGray};
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  ${(props) =>
    props.selected === true &&
    css`
      border: none;
      background-color: ${({ theme }) => theme.color.subDarkGray};
      color: ${({ theme }) => theme.color.mainWhite};
    `}
`;
