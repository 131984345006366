import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { COMMUNITY_CURATED_ARTICLE } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import ListHead from '../../../components/UI/ListHead';
import CuratedBeatRow from './CuratedBeatRow';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Table from '../../../components/common/List/Table';
import Pagination from '../../../components/common/Pagination';
import { postsPerPage } from '../../../constant/common';
import { RowComponentDataProps } from '../../../definitions/beat';
import { CustomProps } from '../../../definitions/common';
import DetailAddBeatRowComponents from '../../explore/components/category/DetailAddBeatRowComponents';
import { addBeatCollectionColList } from '../../../constant/explore';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import { beatBpmOptions, beatSearchTypeList } from '../../../constant/beat';
import SearchInput from '../../../components/common/Input/SearchInput';
import useInput from '../../../hooks/useInput';
import { getBeatList } from '../../../redux/actions/beat/beat';
import ListSelect from '../../../components/common/Select/ListSelect';
import ListDateSelect from '../../../components/common/Select/ListDateSelect';
import { useAppSelector } from '../../../redux/store/hooks';

const CuratedBeatDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const [contentsIds, setContentsIds] = useState<any>([]);
  const [playBeat, setPlayBeat] = useState('');
  const [beatType, setBeatType] = useState<string>('');
  const [keyword, setKeyword, onReset] = useInput('');
  const [searchType, setSearchType] = useState('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');
  const [bpm, setBpm] = useState<string>('');
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const beatList = useAppSelector((state) => state.beat.beatList);

  const [listType, setListType] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);

  const [beatListWork, setBeatListWork] = useState<any>([]);
  const [beatListCurated, setBeatListCurated] = useState<any>([]);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${COMMUNITY_CURATED_ARTICLE}/${prevId}/detail`,
      );
      if (resp.data.adminCuratedBeat) {
        setName(resp.data.adminCuratedBeat.name);
        setNameEn(resp.data.adminCuratedBeat.nameEn);
        setDescription(resp.data.adminCuratedBeat.description);
        setDescriptionEn(resp.data.adminCuratedBeat.descriptionEn);
        setSequence(resp.data.adminCuratedBeat.sequence);
        setActiveYn(resp.data.adminCuratedBeat.activeYn);
        setStartDt(
          resp.data.adminCuratedBeat.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCuratedBeat.endDt?.replace(' ', 'T').slice(0, 16),
        );
        setBeatListCurated(resp.data.adminCuratedBeat.adminBeat);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]);

  useEffect(() => {
    setContentsIds(beatListCurated.map((el: any) => el.id));
  }, [beatListCurated]);

  useEffect(() => {
    dispatch(getBeatList(beatParams));
  }, [pageNumber, searchType, beatType]);

  useEffect(() => {
    beatParams.offset = 0;
    setPageNumber(0);
    switch (bpm) {
      case '':
        setMinBpm(0);
        setMaxBpm(250);
        break;
      case '49':
        setMinBpm(0);
        setMaxBpm(49);
        break;
      case '50':
        setMinBpm(50);
        setMaxBpm(80);
        break;
      case '80':
        setMinBpm(80);
        setMaxBpm(100);
        break;
      case '100':
        setMinBpm(100);
        setMaxBpm(140);
        break;
      case '140':
        setMinBpm(140);
        setMaxBpm(180);
        break;
      case '180':
        setMinBpm(180);
        setMaxBpm(230);
        break;
      default:
        setMinBpm(0);
        setMaxBpm(250);
        break;
    }

    dispatch(getBeatList(beatParams));
  }, [listType]);

  const addContents = async (type: string) => {
    if (contentsIds?.length === 0) {
      alert('게시글을 선택해주세요.');
      return;
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }
    if (!description) {
      alert('설명을 입력해주세요.');
      return;
    }
    if (!descriptionEn) {
      alert('설명(영문)을 입력해주세요.');
      return;
    }
    if (!startDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const body = {
      name,
      nameEn,
      description,
      descriptionEn,
      contentsType: 'BT',
      contentsIds,
      sequence,
      activeYn,
      startDate: startDt,
      endDate: endDt,
    };

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(
          `${COMMUNITY_CURATED_ARTICLE}/${prevId}/edit`,
          body,
          {},
        );
      } else {
        await axios.post(`${COMMUNITY_CURATED_ARTICLE}`, body, {});
      }
      await navigate('/beat/curatedBeat');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 모달 열기
  const setModal = () => {
    setModalOpen(true);
    setBeatListWork(beatListCurated || []);
    dispatch(getBeatList(beatParams));
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    onReset();
    setPageNumber(0);
    setSearchType('');

    // beat state 초기화
    setBeatType('');
    setBpm('');
    setMinBpm(0);
    setMaxBpm(250);
    setMinStartDate('');
    setMaxStartDate('');

    setBeatListWork([]);
  };

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    setBeatListCurated(beatListWork);
    closeModal();
  };

  // 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (!beatListWork?.find((e: any) => e.id === data.id)) {
      setBeatListWork([...beatListWork, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (el: any) => {
    const result = beatListWork.filter((item: any) => item !== el);
    setBeatListWork(result);
  };

  const beatParams = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    word: keyword,
    type: beatType,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn: 'N',
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      beatParams.offset = 0;
      setPageNumber(0);
      if (!searchType) {
        setSearchType('I');
        // userParams.type = 'I';
        setBeatType('T');
        beatParams.type = 'T';
        setPageNumber(0);
      }
      dispatch(getBeatList(beatParams));
    }
  };

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = beatListCurated[dragIndex];
      const hoverItem = beatListCurated[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setBeatListCurated((temp: any) => {
        const workList = [...temp];
        workList[dragIndex] = hoverItem;
        workList[hoverIndex] = dragItem;
        return workList;
      });
    },
    [beatListCurated],
  );

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="컨텐츠 명"
            value={name}
            placeholder="컨텐츠 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="컨텐츠 명 EN"
            value={nameEn}
            placeholder="컨텐츠 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="컨텐츠 설명"
            value={description}
            placeholder="컨텐츠 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="컨텐츠 설명 EN"
            value={descriptionEn}
            placeholder="컨텐츠 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <VideoInput onClick={() => setModal()} text="비트 선택하기" />
        </InputWrapper>
        <InputWrapper>
          <TagHead>
            <ListHead title="" />
            <ListHead title="비트아이디" />
            <ListHead title="Cover" />
            <ListHead title="Title" />
            <ListHead title="Pid" />
            <ListHead title="장르" />
            <ListHead title="BPM" />
            <ListHead title="BeatKey" />
            <ListHead title="공개여부" />
            <ListHead title="공개 날짜" />
          </TagHead>
          <>
            {beatListCurated?.map((item: any, index: number) => (
              <CuratedBeatRow
                rowKey={item.id}
                data={item}
                moveListItem={movePetListItem}
                index={index}
              />
            ))}
          </>
        </InputWrapper>
      </MainContainer>
      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={closeModal}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => saveContentList()}
              />
            </ModalButtonWrapper>
            <VideoModalTitle>선택 비트</VideoModalTitle>
            {beatListWork?.length !== 0 && beatListWork ? (
              <>
                <TableTitle>{beatListWork?.length}개의 비트</TableTitle>
                <LineDiv />
                <Table
                  colList={addBeatCollectionColList}
                  className="nonListTable"
                >
                  {beatListWork?.map((data: any) => (
                    <DetailAddBeatRowComponents
                      key={data.id}
                      data={data}
                      playBeat={playBeat}
                      setPlayBeat={setPlayBeat}
                      onClick={() => filterSelectedContent(data)}
                      selected
                      editable
                    />
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>
                  선택된 비트가 없습니다. 아래 검색 후 비트를 선택해주세요.
                </VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>비트 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchMenuSelect
                  options={beatSearchTypeList}
                  onClick={handleSelect}
                  setState={setBeatType}
                />
                <SearchInputWrapper>
                  <SearchInput
                    value={keyword}
                    onChange={setKeyword}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{beatTotalCount}개 비트</TableTitle>
              {beatTotalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={beatTotalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={addBeatCollectionColList}
              thead={
                <>
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListSelect
                    title="BPM"
                    list={beatBpmOptions}
                    onClick={handleSelect}
                    setState={setBpm}
                  />
                  <ListHead title="BeatKey" />
                  <ListHead title="공개여부" />
                  <ListDateSelect
                    title="공개 날짜"
                    setMinStartDate={setMinStartDate}
                    setMaxStartDate={setMaxStartDate}
                  />
                  <ListHead title="" />
                </>
              }
            >
              {beatList?.map((el: RowComponentDataProps) => (
                <DetailAddBeatRowComponents
                  key={el.id}
                  data={el}
                  onClick={addSelectedContent}
                  playBeat={playBeat}
                  setPlayBeat={setPlayBeat}
                  editable
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default CuratedBeatDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }
  &:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(n + 6):nth-child(-n + 7) {
    grid-row: 4;
  }
  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-row: 6;
    grid-column: span 2;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.8fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 1fr;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div<CustomProps>`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;
