import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// image
import toggleButton from '../../../assets/images/page/toggleButton.png';

// config
import { CHILD_GENRE_LIST } from '../../../constant/apiUrl';

interface ColumnListModuleProps {
  menu?: any;
  type: string;
  goToDetail: (menu: MenuList) => void;
}

interface MenuList {
  name: string;
  options?: Array<string>;
  id: number;
  moodName?: string;
  searchKeywords?: string;
  genre?: string;
  genreImgUrl?: string;
  code?: string;
  childCount?: number;
}

const ColumnList = ({ menu, type, goToDetail }: ColumnListModuleProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Array<any>>([]);

  const toggling = async (id: number) => {
    if (selectedOption[0]?.parentGenreId === id) {
      setSelectedOption([]);
      return;
    }
    await getChildGenre(id);
    setIsOpen(true);
  };

  // 하위장르 불러오기
  const getChildGenre = useCallback(async (id: number) => {
    try {
      const res = await axios.get(`${CHILD_GENRE_LIST}/${id}`, {
        params: { limit: 1000, offset: 0 },
      });
      const childGenre = res.data.masterGenreList;
      setSelectedOption(childGenre);
    } catch (err) {
      console.error(err);
    }
  }, []);

  return (
    <Container>
      {type === 'mood' ? (
        <>
          {menu?.map((option: any) => {
            const keyWords = option.searchKeywords;
            const keyWordsCount = keyWords?.split(',').length;

            return (
              <ListItem onClick={() => goToDetail(option)}>
                <ListText>
                  {option.moodName}
                  {keyWordsCount ? `(${keyWordsCount})` : ''}
                </ListText>
              </ListItem>
            );
          })}
        </>
      ) : (
        <>
          {menu.childCount ? (
            <>
              <ListItem onClick={() => goToDetail(menu)}>
                <ListText>
                  {menu.genre}({menu.childCount})
                </ListText>
                <ToggleButton
                  onClick={(e) => {
                    e.stopPropagation();
                    toggling(menu.id);
                  }}
                  className={
                    selectedOption[0]?.parentGenreId === menu.id ? 'active' : ''
                  }
                >
                  <img alt="toggleButton" src={toggleButton} />
                </ToggleButton>
              </ListItem>
              {isOpen && selectedOption[0]?.parentGenreId === menu.id && (
                <DropDownList>
                  {selectedOption?.map((option: any) => (
                    <ListItems
                      onClick={() => goToDetail(option)}
                      key={Math.random()}
                    >
                      {option.genre}
                    </ListItems>
                  ))}
                </DropDownList>
              )}
            </>
          ) : (
            <ListItem onClick={() => goToDetail(menu)}>
              <ListText>{menu?.genre}</ListText>
            </ListItem>
          )}
        </>
      )}
    </Container>
  );
};

export default ColumnList;

const Container = styled.div`
  // overflow-y: scroll;
`;

const ListItem = styled.div`
  display: block;
  position: relative;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const ListText = styled.span`
  ${({ theme }) => theme.font.listFont};
`;

const ToggleButton = styled.div`
  position: absolute;
  top: 13px;
  right: 10px;
  width: 30px;
  height: 30px;
  padding: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  &.active {
    transform: rotate(180deg);
  }
`;

const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 8px 0 18px;
`;

const ListItems = styled.li`
  margin: 12px 0;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
`;
