import React, { useCallback, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import TextField from '../../../components/common/TextField';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { ADD_MEMBER } from '../../../constant/apiUrl';

// constant
import { passwordRegExp } from '../../../constant/common';

// 타입
type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialMemberData: data = {
  email: '',
  pid: '',
  name: '',
  password: '',
  receiveMarketingInfoYn: 'Y',
  receiveNightTimeInfoYn: 'Y',
};

const AddMember = () => {
  const navigate = useNavigate();
  const [memberData, setMemberData] = useState(initialMemberData);

  const {
    email,
    pid,
    name,
    password,
    receiveMarketingInfoYn,
    receiveNightTimeInfoYn,
  } = memberData;

  // 회원 등록
  const addMember = useCallback(async () => {
    if (!email || !password || !name || !pid) {
      alert('회원 등록 양식을 모두 채워주세요.');
      return;
    }

    if (!passwordRegExp.test(password)) {
      alert(
        '비밀번호 양식에 영문 대소문자, 특수문자, 숫자가 포함되어 있는지 확인해 주세요.',
      );
      return;
    }
    try {
      await axios.post(ADD_MEMBER, {}, { params: memberData });
      alert('회원이 등록되었습니다.');
      navigate('/member/searchMember');
    } catch (err: any) {
      if (err.response.data.message) {
        alert(err.response.data.message);
        return;
      }
      alert('잠시후 다시 시도 해주세요.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberData]);

  // input값 저장
  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      setMemberData({ ...memberData, [name]: value });
    },
    [memberData],
  );

  // input값 리셋하기
  const handleResetData = useCallback(
    (e) => {
      setMemberData({ ...memberData, [e]: initialMemberData[e] });
    },
    [memberData],
  );

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/member/searchMember');
  };

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
            <NormalButton text="저장하기" onSubmit={addMember} />
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="pid"
            label="Pid"
            value={pid}
            onChange={handleInputSetData}
            placeholder="Pid를 입력해 주세요. Pid는 4~20자로 영문 소문자, 숫자, 밑줄 및 마침표 포함이 가능합니다."
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="email"
            label="이메일"
            value={email}
            onChange={handleInputSetData}
            placeholder="이메일을 입력해 주세요."
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        {/*<InputWrapper>*/}
        {/*  <CheckboxButton*/}
        {/*    label="셀럽 인증 유무"*/}
        {/*    checkedValue={BooleanChecker(verifiedYn)}*/}
        {/*    onChange={handleVerifyYn}*/}
        {/*    eventButton={changeVerifyYn}*/}
        {/*  />*/}
        {/*</InputWrapper>*/}
        <InputWrapper>
          <TextField label="가입 유형" text="이메일" />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="닉네임"
            value={name}
            onChange={handleInputSetData}
            placeholder="닉네임을 입력해 주세요."
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="password"
            label="비밀번호"
            value={password}
            onChange={handleInputSetData}
            placeholder="영문, 특수문자, 숫자가 포함된 비밀번호를 입력해 주세요."
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <SpaceDiv />
        <InputWrapper>
          <RadioButton
            name="receiveMarketingInfoYn"
            value={receiveMarketingInfoYn}
            label="마케팅 정보 수신 동의"
            onChange={handleInputSetData}
            text={['Y', 'N']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            name="receiveNightTimeInfoYn"
            value={receiveNightTimeInfoYn}
            label="야간 푸시 수신 동의"
            onChange={handleInputSetData}
            text={['Y', 'N']}
            type="activeYn"
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AddMember;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonWrapper = styled.div`
  display: flex;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  position: relative;

  &:nth-child(1) {
    grid-column: span 2;
  }
  &:nth-child(n + 4):nth-child(-n + 9) {
    grid-column: span 2;
  }
`;

const SpaceDiv = styled.div`
  height: 30px;
`;
