import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import ListHead from '../../../../components/UI/ListHead';
import NormalButton from '../../../../components/common/Button/NormalButton';
import RoundButton from '../../../../components/common/Button/RoundButton';
import EditButton from '../../../../components/common/Button/EditButton';
import { CustomProps } from '../../../../definitions/common';

// hook
import handleDate from '../../../../hooks/handleDate';

// constant
import { RECOMMEND_HASHTAG } from '../../../../constant/apiUrl';

const RecommendHashTag = () => {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState(true);
  const [tagList, setTagList] = useState<any>([]);

  const getTagList = async (activeYn: string) => {
    const params = { limit: 20, offset: 0, activeYn };
    try {
      const resp = await axios.get(RECOMMEND_HASHTAG, { params });
      setTagList(resp.data.recommendTagsList);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTagList(viewType ? 'Y' : 'N');
  }, [viewType]);

  return (
    <Container>
      <TopWrapper>
        <ButtonContainer>
          <ActiveButtonWrapper>
            <RoundButton
              title="공개"
              selected={viewType}
              onClick={() => {
                setTagList([]);
                setViewType(true);
              }}
            />
            <RoundButton
              title="비공개"
              selected={!viewType}
              onClick={() => {
                setTagList([]);
                setViewType(false);
              }}
            />
          </ActiveButtonWrapper>
          {viewType && (
            <ButtonWrapper>
              <NormalButton
                text="해시태그 순서 변경"
                className="cancelBtn"
                onSubmit={() => {
                  navigate('editSeq');
                }}
              />
              <NormalButton
                text="해시태그 추가하기"
                onSubmit={() => {
                  navigate('colList');
                }}
              />
            </ButtonWrapper>
          )}
        </ButtonContainer>
      </TopWrapper>
      <SpaceDiv />
      <TagHead type={viewType}>
        {viewType && <ListHead title="번호" className="marginRight" />}
        <ListHead title="해시태그 명" className="alignLeft" />
        <ListHead title="시작일" />
        <ListHead title="종료일" />
      </TagHead>
      {tagList?.map((item: any, index: number) => (
        <Li key={item.id} type={viewType}>
          {viewType && <TagIndex>{index + 1}</TagIndex>}
          <TagRow>
            <TagName>{item.tag}</TagName>
            <TagStartDt>{handleDate(item.startDt)}</TagStartDt>
            <TagEndDt>{handleDate(item.endDt)}</TagEndDt>
            <EditButton
              text="수정"
              onClick={() => {
                navigate('edit', {
                  state: { editTagId: item.id, tagId: item.tagsId },
                });
              }}
            />
          </TagRow>
        </Li>
      ))}
    </Container>
  );
};

export default RecommendHashTag;

const Container = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ActiveButtonWrapper = styled.div``;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const Li = styled.li<CustomProps>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ type }) => (type ? '85px 1fr' : '1fr')};
`;

const TagHead = styled.div<CustomProps>`
  display: grid;
  align-items: center;
  grid-template-columns: ${({ type }) =>
    type ? '85px 2fr 0.7fr 0.7fr 60px' : '2fr 0.7fr 0.7fr 60px'};
`;

const TagIndex = styled.div`
  margin-right: 24px;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.mainPurple};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 0.7fr 0.7fr 60px;
  padding: 10px 0 10px 5px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagName = styled.span`
  text-align: left;
`;
const TagStartDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
const TagEndDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 55px;
`;
