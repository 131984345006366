import React from 'react';
import styled from 'styled-components';

// common
import EditButton from '../../../../components/common/Button/EditButton';
import ListItem from '../../../../components/common/List/ListItem';
import closeRoundButton from '../../../../assets/images/page/closeRoundButton.png';

export const CuratedTagsRowComponent = ({ data, onClick, btnType }: any) => {
  const { id, tag } = data;
  return (
    <tr key={id}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={tag} />
      </td>
      {onClick && btnType === 'add' && (
        <td>
          <EditButton
            onClick={() => onClick(data)}
            text="선택"
            className="selectBtn"
          />
        </td>
      )}
      {onClick && btnType === 'delete' && (
        <td>
          <DeleteBtn onClick={() => onClick(data)} />
        </td>
      )}
    </tr>
  );
};

const DeleteBtn = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 35px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 30px;
`;
