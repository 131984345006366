import React from "react";
import RadioButton from "../../components/common/Button/RadioButton";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import styled from "styled-components";

interface ConditionDataProps {
    title : string;
    activeYn : string;
    countExposure? : boolean;
    countValue? : number;
    countLabel? : string;
    countOnChange?: React.ChangeEventHandler<HTMLInputElement>;
    countKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
    countInputName? : string;
    activeYnOnChange : React.ChangeEventHandler<HTMLInputElement>;
    activeYnInputName : string;
    onCountReset?: (name: string) => void | undefined;
}

const CommonCondition = ({
    title,
    countValue,
    countLabel,
    activeYn,
    countOnChange,
    countKeyPress,
    activeYnOnChange,
    countExposure,
    countInputName,
    activeYnInputName,
    onCountReset
}: ConditionDataProps) => {
    return <>
        <InputText>
            {title}
        </InputText>
        <RadioButton
            label='활성화 여부'
            type='activeYn'
            value={activeYn}
            name={activeYnInputName}
            onChange={activeYnOnChange}
            Required
            text={['활성화','비활성화']}
        />
        {
            countExposure &&
            countValue &&
            countLabel &&
            countOnChange &&
            countInputName &&
            onCountReset &&
            <LabelTextInput
                name={countInputName}
                type='number'
                label={countLabel}
                value={countValue}
                onChange={countOnChange}
                onKeyPress={countKeyPress}
                onReset={onCountReset}
                Required/>
        }
        <br/>
    </>
}

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

export default CommonCondition;