import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';

// config
import { MASTER_REWARD_API } from '../../constant/apiUrl';
import { resetLoading, setLoading } from '../../redux/slices/user';
import TextField from '../../components/common/TextField';
import MultiLabelTextInput from '../../components/common/Input/MultiLabelTextInput';
import {convertLanguageType} from "../../constant/reward";
import DateInput from "../../components/common/Input/DateInput";
import LabelPlusButtonInput from "../../components/common/Input/LabelPlusButtonInput";

interface RequestParams {
  onlyOnceMissionTitle: string;
  onlyOnceMissionDescription: string;
  dailyMissionTitle: string;
  dailyMissionDescription: string;
  completedMissionTitle: string;
  completedMissionDescription: string;
  missionCautions: string[];
  dailyInitializeUtcTime: string;
}

const MissionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const languageKey = detailData !== null ? detailData.languageKey : null;
  
  const getInitDailyInitializeUtcTimeString = (
    localDailyInitializeHour: number,
  ) => {
    return `${
      localDailyInitializeHour < 10 ?
        '0'.concat(localDailyInitializeHour.toString()) : localDailyInitializeHour
    }:00:00`;
  };
  const [languageType, setLanguageType] = useState('');
  const [languageName, setLanguageName] = useState('');
  const [onlyOnceMissionTitle, setOnlyOnceMissionTitle] = useState('');
  const [onlyOnceMissionDescription, setOnlyOnceMissionDescription] = useState('');
  const [dailyMissionTitle, setDailyMissionTitle] = useState('');
  const [dailyMissionDescription, setDailyMissionDescription] = useState('');
  const [modifyDailyMissionDescription, setModifyDailyMissionDescription] = useState('');
  const [completedMissionTitle, setCompletedMissionTitle] = useState('');
  const [completedMissionDescription, setCompletedMissionDescription] = useState('');
  const [missionCautions, setMissionCautions] = useState([]);
  const missionCautionNextId = useRef(0);
  const [dailyInitializeUtcTime, setDailyInitializeUtcTime] = useState(
    getInitDailyInitializeUtcTimeString(new Date().getHours())
  );
  const getDetail = useCallback(async () => {
    const resp = await axios.get(`${MASTER_REWARD_API}/${languageKey}`);
    const data = resp.data.rewardMaster;
    setLanguageType(data.languageType);
    setLanguageName(convertLanguageType(data.languageType));
    setOnlyOnceMissionTitle(data.onlyOnceMissionTitle);
    setOnlyOnceMissionDescription(data.onlyOnceMissionDescription);
    setDailyMissionTitle(data.dailyMissionTitle);
    setDailyMissionDescription(data.dailyMissionDescription);
    setCompletedMissionTitle(data.completedMissionTitle);
    setCompletedMissionDescription(data.completedMissionDescription);
    setMissionCautions(
      data.missionCautions.map((el: string, idx: number) => ({
        id: idx,
        text: el,
      })),
    );
    missionCautionNextId.current = data.missionCautions.length;
    const utcTimes = data.dailyInitializeUtcTime.split(':');
    const now = new Date();
    const utcTimestamp = Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      utcTimes[0],
      utcTimes[1],
      utcTimes[2],
    );
    const localDailyInitializeHour = new Date(utcTimestamp).getHours();
    const initDailyInitializeUtcTime = getInitDailyInitializeUtcTimeString(localDailyInitializeHour);
    setDailyInitializeUtcTime(initDailyInitializeUtcTime);
  }, [languageKey]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (languageKey) {
      getDetail().then((r) => r);
    }
  }, [getDetail, languageKey]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  // 필수 파라미터 체크
  const paramsCheck = () => {
    if (!languageType || languageType === '') {
      alert('언어 타입이 등록 되어 있지 않습니다');
      return false;
    }
    if (!onlyOnceMissionTitle || onlyOnceMissionTitle === '') {
      alert('일회성 미션 제목을 입력 해 주세요.');
      return false;
    }
    if (!onlyOnceMissionDescription || onlyOnceMissionDescription === '') {
      alert('일회성 미션 설명을 입력 해 주세요.');
      return false;
    }
    if (!dailyMissionTitle || dailyMissionTitle === '') {
      alert('일일 초기화 미션 제목을 입력 해 주세요.');
      return false;
    }
    if (!dailyMissionDescription || dailyMissionDescription === '') {
      alert('일일 초기화 미션 설명을 입력 해 주세요.');
      return false;
    }
    if (!completedMissionTitle || completedMissionTitle === '') {
      alert('완료 미션 제목을 입력 해 주세요.');
      return false;
    }
    // eslint-disable-next-line consistent-return
    missionCautions.forEach((caution) => {
      if (!caution || caution === '') {
        alert('미션 유의사항 중 값이 없거나 빈 칸인 행이 존재 합니다.');
        return false;
      }
    });
    if (!dailyInitializeUtcTime) {
      alert('일일 초기화 기준 시간을 입력 해 주세요.');
      return false;
    }
    return true;
  };
  // 일일 초기화 미션 설명 변환
  const convertDailyMissionDescription = (changeString: string) => {
    if (
      changeString === undefined ||
      changeString === null ||
      changeString === '' ||
      dailyInitializeUtcTime === undefined ||
      dailyInitializeUtcTime === null
    )
      return '미션 설명 혹은 초기화 시간 값이 정의 되지 않았습니다.';
    let result = changeString;
    const reg = /({\d})/gims;
    if (reg.test(changeString)) {
      const hour = dailyInitializeUtcTime.toString().substring(0, 2);
      result = changeString.replaceAll(reg, hour);
    }
    return result;
  };

  useEffect(() => {
    setModifyDailyMissionDescription(
      convertDailyMissionDescription(dailyMissionDescription),
    );
  }, [dailyMissionDescription, dailyInitializeUtcTime]);
  
  const onAddText = (nextId, text, data, setter) => {
    setter([...data, { id: nextId.current, text }]);
    nextId.current += 1;
  };
  const onEditText = (id, text, data, setter) => {
    setter(data.map((el) => (el.id === id ? { ...el, text } : el)));
  };
  const onRemoveText = (id, data, setter) => {
    setter(data.filter((el) => el.id !== id));
  };
  const onAddMissionCautionText = (text: string) => {
    onAddText(missionCautionNextId, text, missionCautions, setMissionCautions);
  };
  const onEditMissionCautionText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, missionCautions, setMissionCautions);
    },
    [missionCautions],
  );
  const onRemoveMissionCautionText = (cautionId: number) => {
    onRemoveText(cautionId, missionCautions, setMissionCautions);
  };

  const addContents = async () => {
    if (!paramsCheck()) return;
    const body: RequestParams = {
      onlyOnceMissionTitle,
      onlyOnceMissionDescription,
      dailyMissionTitle,
      dailyMissionDescription,
      completedMissionTitle,
      completedMissionDescription,
      missionCautions: [...missionCautions]
        .filter((el: any) => el.text !== '')
        .map((el: any) => el.text),
      dailyInitializeUtcTime: dailyInitializeUtcTime.toString(),
    };
    try {
      dispatch(setLoading());
      await axios.patch(`${MASTER_REWARD_API}/${languageKey}`, body, {});
      await getDetail();
      alert('수정되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          <NormalButton
            text="수정하기"
            onSubmit={() => addContents('edit')}
          />
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <TextField
            label="언어 타입"
            text={languageName}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="onlyOnceMissionTitle"
            value={onlyOnceMissionTitle}
            label="일회성 미션 목록 제목"
            onChange={(e) => setOnlyOnceMissionTitle(e.target.value)}
            onReset={() => setOnlyOnceMissionTitle('')}
            placeholder="일회성 미션 목록의 제목을 입력 해 주세요."
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="onlyOnceMissionDescription"
            value={onlyOnceMissionDescription}
            label="일회성 미션 목록 설명"
            onChange={(e) => setOnlyOnceMissionDescription(e.target.value)}
            onReset={() => setOnlyOnceMissionDescription('')}
            placeholder="일회성 미션 목록의 설명을 입력 해 주세요."
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="dailyMissionTitle"
            value={dailyMissionTitle}
            label="일일 초기화 미션 목록 제목"
            onChange={(e) => setDailyMissionTitle(e.target.value)}
            onReset={() => setDailyMissionTitle('')}
            placeholder="일일 초기화 미션 목록의 제목을 입력 해 주세요."
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="일일 미션 초기화 시간 (시간만 변경이 가능하며, 기준 시간대는 한국입니다)"
            initialStartDate={dailyInitializeUtcTime}
            type="time"
            onChange={(e) => {
              const hour = e.target.value.substring(0, 2);
              setDailyInitializeUtcTime(`${hour}:00:00`);
            }}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <MultiLabelTextInput
            key="dailyMissionDescription"
            name="dailyMissionDescription"
            labels={[
              '일일 초기화 미션 목록 설명',
              '설명에 시간이 들어가는 경우 "{숫자}" 로 시간이 들어갈 위치를 순서대로 정해 주세요.',
              'ex1: 미션은 매일 {0}시에 초기화 됩니다.',
              'ex2: 미션은 매일 {0}시에 초기화 되며, {1}시 이후 모든 수행 이력이 초기화 됩니다.',
            ]}
            value={dailyMissionDescription}
            placeholder="일일 초기화 미션 목록의 설명을 입력 해 주세요."
            onChange={(e) => {
              setDailyMissionDescription(e.target.value);
              setModifyDailyMissionDescription(
                convertDailyMissionDescription(e.target.value),
              );
            }}
            onReset={() => {
              setDailyMissionDescription('');
              setModifyDailyMissionDescription('');
            }}
            Required
          />
          <TextField
            label="앱에 노출 되는 설명"
            text={modifyDailyMissionDescription}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="completedMissionTitle"
            value={completedMissionTitle}
            label="완료 미션 목록 제목"
            onChange={(e) => setCompletedMissionTitle(e.target.value)}
            onReset={() => setCompletedMissionTitle('')}
            placeholder="완료 미션 목록의 제목을 입력 해 주세요."
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="completedMissionDescription"
            value={completedMissionDescription}
            label="완료 미션 목록 설명"
            onChange={(e) => setCompletedMissionDescription(e.target.value)}
            onReset={() => setCompletedMissionDescription('')}
            placeholder="완료 미션 목록의 설명을 입력 해 주세요."
          />
        </InputWrapper>
        <InputWrapper>
          <LabelPlusButtonInput
            name="missionCautions"
            label="미션 목록 하단 리워드 유의사항"
            value={missionCautions}
            onClick={onAddMissionCautionText}
            onEdit={onEditMissionCautionText}
            onRemove={onRemoveMissionCautionText}
            placeholder="교환하기 유의사항을 입력해 주세요."
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};
export default MissionDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1) {
    grid-row: 1;
    grid-column: span 2;
  }
  &:nth-child(n + 2) {
    grid-row: 2;
  }
  &:nth-child(n + 3) {
    grid-row: 3;
  }
  &:nth-child(n + 4) {
    grid-row: 4;
  }
  &:nth-child(n + 5) {
    grid-row: 5;
  }
  &:nth-child(n + 6) {
    grid-row: 6;
  }
  &:nth-child(n + 7) {
    grid-row: 7;
  }
  &:nth-child(n + 8) {
    grid-row: 8;
  }
  &:nth-child(n + 9) {
    grid-row: 9;
  }
  &:nth-child(n + 10) {
    grid-row: 10;
  }
  &:nth-child(n + 11) {
    grid-row: 11;
  }
`;
