import styled from "styled-components";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import React, {useCallback, useEffect, useRef, useState} from "react";

import {
  CAMPAIGNS, CAMPAIGNS_EXPOSE_UPPER,
} from "../../../constant/apiUrl";
import {campaignOptions, IsPrivate} from "../../../constant/common";

import NormalButton from "../../../components/common/Button/NormalButton";
import ListHead from "../../../components/UI/ListHead";
import {useDrag, useDrop} from "react-dnd";
import dragAndDrop from "../../../assets/images/page/dragAndDrop.png";
import ListItem from "../../../components/common/List/ListItem";
import ListCoverImg from "../../../components/common/List/ListCoverImg";

// 골드 캠페인 상단 노출 순서 변경
const GoldCampaignEditSeq = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [campaignList, setCampaignList] = useState([]);

  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getCampaigns = async () => {
    await axios.get(CAMPAIGNS, {
      params:{
        limit: 100,
        offset: 0,
        upperExposureYn: 'Y'
      }
    })
    .then(res => setCampaignList(res.data.campaignList))
    .catch(reason => console.error(reason));
  };
  //페이지 진입시 최초 1회 조회
  useEffect(() => {
    getCampaigns().then(() => {});
  }, []);

  // 시퀀스 변경
  const changeSequence = async () => {
    const body = campaignList.map((campaign, index) => ({
      campaignId: campaign.campaignId,
      sequence: index + 1,
    }));
    await axios.put(CAMPAIGNS_EXPOSE_UPPER, body)
    .then( () =>{
      alert('저장 되었습니다.');
      navigate('/campaign/goldCampaign');
    })
    .catch(reason => {
      console.error(reason);
      alert('시스템 관리자에게 문의해 주세요.');
    });
  };

  // 드래그 앤 드롭
  const moveItem = useCallback((dragIndex, hoverIndex) => {
    const dragItem = campaignList[dragIndex];
    const hoverItem = campaignList[hoverIndex];

    setCampaignList((campaign) => {
      const updatedCampaign = [...campaign];
      updatedCampaign[dragIndex] = hoverItem;
      updatedCampaign[hoverIndex] = dragItem;
      return updatedCampaign;
    });
  }, [campaignList],);

  return (
      <Container>
        <TopContainer>
          <TitleText>상단 노출 순서 변경</TitleText>
          <ButtonWrapper>
            <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
            <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
          </ButtonWrapper>
        </TopContainer>
        <PaginationWrapper>
          <SpaceDiv />
        </PaginationWrapper>
        <MainContainer>
          <TagHead>
            <ListHead title="" />
            <ListHead title="Id" />
            <ListHead title="썸네일"/>
            <ListHead title="상위 노출"/>
            <ListHead title="캠페인 타입"/>
            <ListHead title="캠페인 명"/>
            <ListHead title="경품명"/>
            <ListHead title="브랜드명"/>
            <ListHead title="공개 여부"/>
            <ListHead title="캠페인 기간"/>
          </TagHead>
          {campaignList?.map((item, index) => (
              <GoldCampaignEditSeqRowComponent
                  key={item.id}
                  data={item}
                  moveListItem={moveItem}
                  index={index}
              />
          ))}
        </MainContainer>
      </Container>
  );

}
export default GoldCampaignEditSeq;

// 골드 캠페인 상단 노출 순서 변경 row
const GoldCampaignEditSeqRowComponent = ({
  data,
  moveListItem,
  index
}) => {

  const componentRef = useRef(null);

  const [, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element, monitor) => {
      const hoverIndex = index;

      if (!componentRef.current) {return null;}

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {return null;}

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {return null;}

      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if ((dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
          || (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback((node) => {
    componentRef.current = node;
    dragRef(dropRef(node));
  }, [dragRef, dropRef],);

  let campaignPeriod = [
    data.startDate ? data.startDate.slice(0,10):"",
    data.endDate ? data.endDate.slice(0,10):""
  ].join(' ~ ');

  return (
      <Row ref={applyRef} key={data.campaignId}>
        <img src={dragAndDrop} alt="dnd icon" />
        <ListItem text={data.campaignId} />
        <td><ListCoverImg url={data.thumbnailImgUrl} /></td>
        <td><ListItem text={data.upperExposureYn === 'Y' ? "노출":""} /></td>
        <td><ListItem text={campaignOptions.filter(
            option => option.value === data.goodsType
        )[0].text} /></td>
        <td><ListItem text={data.name} /></td>
        <td><ListItem text={data.goodsName} /></td>
        <td><ListItem text={data.goodsBrand} /></td>
        <td><ListItem text={IsPrivate[data.privateYn]} /></td>
        <td><ListItem text={campaignPeriod} /></td>
      </Row>
  );
}

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 50fr 60fr 60fr 80fr 100fr 100fr 100fr 60fr 110fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;

const Row = styled.tr`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 50fr 60fr 60fr 80fr 100fr 100fr 100fr 60fr 110fr;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};
  }
`;