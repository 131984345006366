import React from 'react';
import styled from 'styled-components';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

interface TextInputProps {
	label: string;
	value: string | number;
	name: string;

	type?: string;
	placeholder?: string;
	onChange?: React.ChangeEventHandler<HTMLInputElement>;
	onReset?: (name: string) => void | undefined;
	onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
	Required?: boolean;
	readOnly?: boolean;
	min?: number | undefined;
	max?: number | undefined;
	style?: object | undefined;
    maxLen?: number;
}

const LabelTextInput = ({
    name,
    type,
    value,
    label,
    onChange,
    onReset,
    placeholder,
    onKeyPress,
    Required,
    readOnly,
    min,
	max,
    style,
    maxLen
}: TextInputProps) => {
	return (
		<InputContainer className={name} style={style}>
			<InputText>
				{label} {Required && <span>*</span>}
			</InputText>
			<InputBox className={name}>
				{(type === 'priceInput' ||
					type === 'reportPoint' ||
					type === 'mobile' ||
					type === 'number' ||
					type === 'id') && (
					<input
						name={name}
						value={value}
						type="number"
						placeholder={placeholder}
						onChange={onChange}
						onKeyPress={onKeyPress}
						readOnly={readOnly}
						min={min}
						max={max}
					/>
				)}

				{type === 'passwordInput' && <input
					name={name}
					value={value}
					type="password"
					placeholder={placeholder}
					onChange={onChange}
					onKeyPress={onKeyPress}
				/>}

				{type === 'textarea' && <textarea cols={50} rows={10}/>}

				{!type && (maxLen
					? <input
						name={name}
						value={value || ''}
						placeholder={placeholder}
						onChange={onChange}
						onKeyPress={onKeyPress}
						readOnly={readOnly}
						maxLength={maxLen}
					/>
					: <input
						name={name}
						value={value || ''}
						placeholder={placeholder}
						onChange={onChange}
						onKeyPress={onKeyPress}
						readOnly={readOnly}
					/>
		        )}

				{(value !== 0 && value && onReset && readOnly !== true) &&
					<InputDeleteButton onClick={() => onReset(name)} tabIndex={-1} />
				}
			</InputBox>
		</InputContainer>
	);
};

export default LabelTextInput;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 55px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.subGray};
    font-weight: 500;
  }

  &.eventBattle input {
    height: 52px !important;
  }

	&.masterMessage {
		margin-bottom: 35px;
	}
	
	&.warn > input {
			color: ${({ theme }) => theme.color.mainRed};
	}
`;

const InputDeleteButton = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;
