import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import RoundButton from '../../components/common/Button/RoundButton';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import { beatSearchTypeList } from '../../constant/beat';
import SearchInput from '../../components/common/Input/SearchInput';
import NormalButton from '../../components/common/Button/NormalButton';
import useInput from '../../hooks/useInput';
import Pagination from '../../components/common/Pagination';
import { postsPerPage } from '../../constant/common';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import { officialCollectionList } from '../../constant/explore';
import { OFFICIAL_COLLECTION } from '../../constant/apiUrl';
import saveStorage from '../../hooks/saveStorage';
import { OfficialCollectionType } from '../../definitions/explore';
import OfficialCollectionRowComponent from './components/category/OfficialCollectionRowComponent';

const RegisterCollection = () => {
  const navigate = useNavigate();
  const collectionId: number = Number(sessionStorage.getItem('explore_collection_id'));
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [list, setList] = useState<Array<OfficialCollectionType>>();
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState<string>(storageType || '');
  const [totalCount, setTotalCount] = useState(0);
  const [collectionType, setCollectionType] = useState('BEAT');

  // pagination
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // listType;

  const getOfficialCollectionList = useCallback(async () => {
    const params = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
      collectionType,
    };
    try {
      const resp = await axios.get(`${OFFICIAL_COLLECTION}/${collectionId}`, {
        params,
      });
      setList(resp.data.memberCollectionList);
      setTotalCount(resp.data.count);
    } catch (err) {
      console.log(err);
    }
  }, [collectionType, pageNumber, collectionId]);

  useEffect(() => {
    getOfficialCollectionList();
  }, [getOfficialCollectionList]);

  return (
    <Container>
      <RoundButton
        title="비트"
        selected={collectionType === 'BEAT'}
        onClick={() => {
          setCollectionType('BEAT');
        }}
      />
      <RoundButton
        title="플레이"
        selected={collectionType === 'ARTICLE'}
        onClick={() => {
          setCollectionType('ARTICLE');
        }}
      />
      <LineDiv />
      <TopWrapper>
        <SearchWrapper>
          <SearchMenuSelect
            options={beatSearchTypeList}
            onClick={handleSelect}
            setState={setType}
          />
          <SearchInputWrapper>
            <SearchInput
              value={word}
              onChange={setWord}
            />
          </SearchInputWrapper>
        </SearchWrapper>
        <NormalButton
          text="컬렉션 등록하기"
          onSubmit={() => {
            navigate('/explore/collection/add');
          }}
          className=""
        />
      </TopWrapper>
      <PaginationWrapper>
        <Pagination
          postsPerPage={postsPerPage}
          totalCount={totalCount}
          currentPage={pageNumber}
          pageDispatch={pageDispatch}
        />
      </PaginationWrapper>
      <Table
        colList={officialCollectionList}
        thead={
          <>
            <ListHead title="번호" />
            <ListHead title="컬렉션 종류" />
            <ListHead title="컨텐츠 수" />
            <ListHead title="Cover" />
            <ListHead title="컬렉션 제목" />
            <ListHead title="작성자 Pid" />
            <ListHead title="공개 여부" />
            <ListHead title="" />
          </>
        }
      >
        {list?.map((el: OfficialCollectionType) => (
          <OfficialCollectionRowComponent
            key={el.id}
            data={el}
            officialId={collectionId}
            saveStorage={() => {
              saveStorage(pageNumber, type, word);
            }}
          />
        ))}
      </Table>
    </Container>
  );
};

export default RegisterCollection;

const Container = styled.div`
  width: 100%;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 30px 0 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
