import React from 'react';

// common
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import EditButton from '../../../components/common/Button/EditButton';

// constant
import { IsPrivate, IsStatus } from '../../../constant/common';

// 타입
import { RowComponentDataProps } from '../../../definitions/beat';
import handleDate from '../../../hooks/handleDate';
import styled from "styled-components";

// 인터페이스
interface dataProps {
  data: RowComponentDataProps;
  onClick: any;
}

const ArticleRowComponent = ({ data, onClick }: dataProps) => {
  const {
    id,
    memberPid,
    memberName,
    coverImgUrl,
    privateYn,
    beatTitle,
    uploadDt,
    articleStatus,
    contentsUrl,
    type,
    length,
    tags,
    suggestWords,
  } = data;

  const typeName = (type: string) => {
      return type === 'NR' ? '일반'
                : type === 'BT' ? '배틀'
                : '광고';
  }

  return (<>
    <TrRow className={'upper'}>
      <td rowSpan={2}><ListItem text={id} /></td>
      <td rowSpan={2}><ListCoverImg className="article" url={coverImgUrl} /></td>
      <td><ListItem text={typeName(type)} className={`articleType_${type}`} /></td>
      <td><ListItem text={`${length}초`} /></td>
      <td><ListItem text={memberPid} /></td>
      <td><ListItem text={memberName} /></td>
      <td><ListItem text={beatTitle} /></td>
      <td><ListItem text={handleDate(uploadDt)} /></td>
      <td><ListItem text={IsPrivate[privateYn]} /></td>
      <td><ListItem text={IsStatus[articleStatus]} /></td>
      <td><EditButton text="영상" onClick={() => onClick(contentsUrl, coverImgUrl, data)}/></td>
    </TrRow>
    <TrRow className={'lower'}>
      <TdLowerRow colSpan={4} className={'hashTag'}>
        <TextSpan>해시 태그</TextSpan>&nbsp;&nbsp;
        <ListItem text={(tags ? tags.join(', '): '')} />
      </TdLowerRow>
      <TdLowerRow colSpan={4} className={'suggestWord'}>
        <TextSpan>제시어</TextSpan>&nbsp;&nbsp;
        <ListItem text={(suggestWords ? suggestWords.join(', '): '')} />
      </TdLowerRow>
    </TrRow>
  </>);
};

export default ArticleRowComponent;

const TextSpan = styled.span`
  display: inline-block;
  padding: 5px 7px;
  font-size: 12px;
  color: ${({ theme }) => theme.color.mainWhite};
  background-color: ${({ theme }) => theme.color.subGray};
`;

const TrRow = styled.tr`
  &.upper {
    height:40px; 
    border-bottom: 1px solid #f5f5f5;
  }
  &.lower {
    height:30px;
    border-bottom: 1px solid #e7e7e7;
  }
`;

const TdLowerRow = styled.td`
  &.hashTag, &.suggestWord {
    text-align:left;
    padding-left:20px;
  }
`;