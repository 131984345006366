import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import BaundApiCategorySeqRowComponent from './BaundApiCategoryEditSeqRowComponent';
import ListHead from '../../../../components/UI/ListHead';

// config
import { ADMIN_BAUND_API_CATEGORY } from '../../../../constant/apiUrl';
import {useLocation} from "react-router";

const BaundApiCategoryEditSeq = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const detailData: any = location.state;
  const activationTypeForSearch = detailData?.activationTypeForSearch || 0;
  const baundApiCategoryTypeForSearch = detailData?.baundApiCategoryTypeForSearch || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;

  const [contentsList, setContentsList] = useState<any>([]);

  const getContents = async () => {
    const params = {
      limit: 100,
      offset: 0,
      categoryType: baundApiCategoryTypeForSearch.value,
    };
    try {
      const resp = await axios.get(`${ADMIN_BAUND_API_CATEGORY}/active`, {
        params,
      });
      setContentsList(resp.data.adminBaundApiCategoryList);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };
  useEffect(() => {
    getContents().then();

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ activationTypeForSearch, baundApiCategoryTypeForSearch, pageNumberForSearch })
    );
  }, []);

  const changeSequence = async () => {
    const ids = contentsList.map((el: any) => el.baundApiCategoryId);
    try {
      await axios.put(`${ADMIN_BAUND_API_CATEGORY}/sequence`, { ids });
      alert('저장되었습니다.');
      navigate('/system/baundApiCategory');
    } catch (error: any) {
      console.log(error.response.data);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = contentsList[dragIndex];
      const hoverItem = contentsList[hoverIndex];

      setContentsList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [contentsList],
  );

  return (
    <Container>
      <TitleText>노출 순서 변경</TitleText>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        <SpaceDiv />
      </PaginationWrapper>
      <MainContainer>
        <TagHead>
          <ListHead title="" />
          <ListHead title="아이디" />
          <ListHead title="제목" />
          <ListHead title="설명" />
          <ListHead title="타입" />
        </TagHead>
        {contentsList?.map((item: any, index: number) => (
          <BaundApiCategorySeqRowComponent
            data={item}
            moveListItem={moveItem}
            index={index}
          />
        ))}
      </MainContainer>
    </Container>
  );
};

export default BaundApiCategoryEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  padding-top: 20px;
  height: 75px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 1fr 2fr 2fr 2fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
