import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import {useNavigate} from 'react-router-dom';

import NormalButton from "../../../components/common/Button/NormalButton";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import {ADVERTISEMENT_LANDING,} from "../../../constant/apiUrl";
import axios from "axios";
import ListItem from "../../../components/common/List/ListItem";
import {postsPerPage} from "../../../constant/common";
import SelectModule from "../../../components/common/Select/SelectModule";
import * as commonFn from "../../../utils/commonFn";
import SearchInput from "../../../components/common/Input/SearchInput";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";

// 광고 목록 화면
const AdvertisementLandings = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [advertisementLandingList, setAdvertisementLandingList] = useState([]);

  // 검색 조건
  const storageType = localStorage.getItem('storageType');
  const [type, setType] = useState(storageType || 'LANDING_TITLE');
  const storageWord = localStorage.getItem('storageWord');
  const [word, setWord] = useState(storageWord || '');

  // 페이징
  const storagePageNumber = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
      storagePageNumber ? JSON.parse(storagePageNumber) : 0
  );
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
    window.localStorage.setItem('pageNumber', String(e - 1));
  }, []);

  //데이터 목록 조회 ////////////////////////////////////////////////////////////
  const params = {
    type,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  const getAdvertisementLandings = async() => {
    await axios.get(`${ADVERTISEMENT_LANDING}`, {params})
    .then(res => {
      setAdvertisementLandingList(
        res.data.adminAdvertisementLandingList.map((landing)=>{
          return {
            advertisementLandingId: landing.advertisementLandingId,
            title: landing.title,
            advertisement: landing.advertisement,
            advertisementCampaigns: landing.advertisementCampaigns,
            koreanYn: landing.koreanYn,
            englishYn: landing.englishYn,
            selected: false
          };
        })
      );
      setTotalCount(res.data.count);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => getAdvertisementLandings(), [pageNumber]);

  const handleType = (_type) => {
    setType(_type);
    window.localStorage.setItem('storageType', _type);
  }
  const handleWord = (_word) => {
    setWord(_word);
    window.localStorage.setItem('storageWord', _word);
  }

  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getAdvertisementLandings();
    }
  };

  // 상세 페이지로 이동
  const moveToDetail = (advertisementLandingId) => {
    navigate(`/advertisement/advertisementLanding/edit`, {state:{advertisementLandingId}});
  }

  const advertisementLandingSearchTypes = {
    LANDING_TITLE: '랜딩 페이지 명',
    ADVERTISEMENT_TITLE: '광고 식별 제목',
    ADVERTISER_NAME: '광고주 명',
  };

  const handleCampaigns = (campaigns) => {
    let campaignCounts = [
      {campaignType:"NM", campaignTypeStr:"메인 팝업", campaignCount:0},
      {campaignType:"EL", campaignTypeStr:"메인 배너", campaignCount:0},
      {campaignType:"CBL", campaignTypeStr:"커뮤니티 인피드 배너", campaignCount:0},
      {campaignType:"CL", campaignTypeStr:"골드 캠페인 배너", campaignCount:0},
      {campaignType:"ML", campaignTypeStr:"바운드 골드 배너", campaignCount:0},
    ];
    campaigns.forEach(campaign =>{
      campaignCounts.forEach(count =>{
        if (campaign.campaignType === count.campaignType) {
          count.campaignCount += 1;
        }
      })
    });
    // console.log(retVal);
    return campaignCounts
    .filter(count => count.campaignCount !== 0)
    .map(count => {
      return <div key={count.campaignType}>
          <ListItem text={`${count.campaignTypeStr}(${count.campaignCount})`}/>
          <br/>
        </div>;
    });
  }

  const handleLanguage = (adl) => {
    let tmpLanguage = [];
    if(adl.koreanYn==='Y') {
      tmpLanguage.push(<ListItem key={1} text={"한국어"} />);
    }
    if(adl.englishYn==='Y') {
      tmpLanguage && tmpLanguage.push(<br key={2} />);
      tmpLanguage.push(<ListItem key={3} text={"영어"}/>);
    }
    return tmpLanguage;
  }

  //데이터 삭제 /////////////////////////////////////////////////////////////////
  // 전체 삭제 선택 여부
  const [checkAll, setCheckAll] = useState(false);
  // 삭제할 아이디 목록
  const [idsToDelete, setIdsToDelete] = useState([]);

  // 삭제
  const deleteSelected = async () => {
    if(idsToDelete.length === 0) {return;}
    if (!confirm('선택한 광고 랜딩 페이지를 삭제하시겠습니까?.\n삭제 시, 모든 데이터는 삭제 처리됩니다.')) {
      return;
    }

    await axios.delete(`${ADVERTISEMENT_LANDING}`, {params:{ids: idsToDelete}})
    .then((res) => {
      // 캠페인에 참조되고 있는 랜딩 아이디 목록이 있다면 삭제가 진행되지 않은 것이므로
      if (res.data.ids && res.data.ids.length > 0) {
        // 보낼 때도 화면에 표시된 내용중에서 보냈으니,
        let work = advertisementLandingList
          // 참조되고 있는 랜딩만 필터링 해서
          .filter(landing => res.data.ids.includes(landing.advertisementLandingId))
          // 제목만 뽑는다.
          .map(landing=> {
            return "- " + landing.title;
          });
        // 해당 랜딩 제목을 표시
        const messages = ['연동된 광고캠페인이 있을 경우, 삭제할 수 없습니다.', ...work];
        alert(messages.join('\n'));
        console.log(res.data.ids);
      }
      // 빈 목록이 왔다면 정상 삭제 된 것이므로
      else {
        alert('삭제가 완료되었습니다.');
        getAdvertisementLandings();
        setCheckAll(false);
      }
    })
    .catch (err => {
      console.log('AdvertisementLandings > deleteSelected > err.response:', err.response);
      alert('문제가 발생하였습니다. 담당자에게 문의해주세요.');
    });
  };

  // 전체 선택
  const setSelectedAll = (checked) => {
    advertisementLandingList.map((item) => {
      item.selected = checked;
    });

    const arr = [];
    //선택한 것이면
    if (checked) {
      // 전체 추가
      advertisementLandingList.forEach((el) => arr.push(el.advertisementLandingId));
    }
    setIdsToDelete(arr);
    setCheckAll(checked);
  }

  // 개별 선택
  const setSelected = (landingId, checked) => {
    // console.log('setSelected > landingId:',landingId, '/checked:', checked);
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    advertisementLandingList.map((item) => {
      if (item.advertisementLandingId === landingId) {
        item.selected = checked;
      }
    });

    setIdsToDelete(
        //선택한 것이면
        checked
            // 아이디 목록에 추가
            ? [...idsToDelete, landingId]
            // 아이디 목록에서 제외
            : idsToDelete.filter((item)=> item !== landingId)
    );
    setCheckAll(false);
  }

  return (
      <>
        <TitleText>광고 랜딩페이지 리스트</TitleText>
        <TopContainer>
          <TopWrapper jc={'right'}>
            <ButtonWrapper>
              <NormalButton
                  text="선택 삭제"
                  className={(idsToDelete.length === 0) ? "unActiveBtn": "blockBtn"}
                  onSubmit={deleteSelected}
              />
              {/*<NormalButton text="새로 만들기" onSubmit={() => moveToDetail('add')}/>*/}
              <NormalButton text="새로 만들기" onSubmit={() => navigate(`/advertisement/advertisementLanding/add`)}/>
            </ButtonWrapper>
          </TopWrapper>
          <TopWrapper jc={'left'}>
            <SearchWrapper>
              <SelectModule
                  name="type" label="" placeholder="" className='commonSearchType200'
                  value={advertisementLandingSearchTypes[type]}
                  onChange={e => handleType(e.value)}
                  options={commonFn.transObjectToOptions(advertisementLandingSearchTypes)}
              />
              <SearchInputWrapper>
                <SearchInput value={word} onChange={e => handleWord(e.target.value)}
                             onKeyPress={enterKeyPress}/>
              </SearchInputWrapper>
              <NormalButton text="검색" className='cancelBtn' onSubmit={getAdvertisementLandings} />
            </SearchWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 랜딩페이지'}
                      currentPage={pageNumber}
                      pageDispatch={pageDispatch}/>
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {advertisementLandingList.length !== 0 ? (
            <Table
                colList={[10, 50, 50, 100, 30, 50, 30]}
                thead={<>
                  <TableHead>
                    <input id={`checkbox_0`} type="checkbox"
                           checked={checkAll}
                           onChange={(e) => setSelectedAll(e.currentTarget.checked)}/>
                  </TableHead>
                  <ListHead title="번호" />
                  <ListHead title="랜딩 페이지 명"/>
                  <ListHead title="광고 식별 제목"/>
                  <ListHead title="광고주 명"/>
                  <ListHead title="연동 캠페인" />
                  <ListHead title="언어 명" />
                </>}
            >
            {
              advertisementLandingList?.map(adl =>
                <tr key={adl.advertisementLandingId}>
                  <td>
                    <input id={`checkbox_${adl.advertisementLandingId}`} type="checkbox"
                           checked={ adl.selected}
                           onChange={(e) => setSelected(adl.advertisementLandingId, e.currentTarget.checked)}/>
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    <ListItem text={adl.advertisementLandingId} />
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    <ListItem text={adl.title} />
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    <ListItem text={adl.advertisement.title} />
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    <ListItem text={adl.advertisement.advertiser.name} />
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    {handleCampaigns(adl.advertisementCampaigns)}
                  </td>
                  <td onClick={() => moveToDetail(adl.advertisementLandingId)}>
                    {handleLanguage(adl)}
                  </td>
                </tr>
              )
            }
          </Table>
          ) : (
              <SpanNoResult>검색 결과가 없습니다.</SpanNoResult>
          )}
        </MainContainer>
      </>
  );
};
export default AdvertisementLandings;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
  `;

const TopWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  // justify-content: right;
  // align-items: center;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 430px;
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;
