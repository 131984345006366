import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import {COMMUNITY_CURATED_ARTICLE, MAGAZINE} from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import ListHead from '../../../components/UI/ListHead';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Table from '../../../components/common/List/Table';
import Pagination from '../../../components/common/Pagination';
import { postsPerPage } from '../../../constant/common';
import SearchInput from '../../../components/common/Input/SearchInput';
import useInput from '../../../hooks/useInput';
import CuratedMagazineRow from "./component/CuratedMagazineRow";
import WorkMagazineRow from "./component/WorkMagazineRow";

const CuratedMagazineDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const today = new Date();
  const todayWithFormat =
      today.getFullYear() +
      '-' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + today.getDate()).slice(-2) +
      'T' +
      ('0' + today.getHours()).slice(-2) +
      ':' +
      ('0' + today.getMinutes()).slice(-2);

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  // 시작 시간
  const [startDt, setStartDt] = useState(todayWithFormat);
  // 종료 시간
  const [endDt, setEndDt] = useState('2222-12-31 23:59');

  const [contentsIds, setContentsIds] = useState([]);
  const [word, setWord, onReset] = useInput('');

  const [magazineCurated, setMagazineCurated] = useState([]);
  const [magazineWork, setMagazineWork] = useState([]);
  const [magazineMaster, setMagazineMaster] = useState([]);

  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  const getDetail = async () => {
    await axios.get(
      `${COMMUNITY_CURATED_ARTICLE}/${prevId}/detail`,
    )
    .then(resp=>{
    console.log('CuratedMagazineDetail >> getDetail >> resp.data.adminCuratedMagazine:', resp.data);
      if (resp.data.adminCuratedMagazines) {
        setName(resp.data.adminCuratedMagazines.name);
        setNameEn(resp.data.adminCuratedMagazines.nameEn);
        setDescription(resp.data.adminCuratedMagazines.description);
        setDescriptionEn(resp.data.adminCuratedMagazines.descriptionEn);
        setSequence(resp.data.adminCuratedMagazines.sequence);
        setActiveYn(resp.data.adminCuratedMagazines.activeYn);
        setStartDt(
            resp.data.adminCuratedMagazines.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
            resp.data.adminCuratedMagazines.endDt?.replace(' ', 'T').slice(0, 16),
        );
        const workList = resp.data.adminCuratedMagazines.adminMagazines;
        for (let i = 0; i < workList.length; i += 1) {
          workList[i].articleStatus = workList[i].reportType;
        }
        setMagazineCurated(workList);
      }
    })
    .catch (err => {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    });
  };
  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [prevId]);

  useEffect(() => {
    setContentsIds(magazineCurated.map((el) => el.id));
  }, [magazineCurated]);

  useEffect(() => {
    getMagazines().then();
  }, [pageNumber]);

  // 날짜 저장시 사용
  const handleDate = (date, tRemove, start) => {
    let _date = date;

    if (tRemove && date.indexOf('T') < 0) {
      _date = date.split(' ');
      _date = _date[0] + 'T' + _date[1];
    }
    if (!tRemove && date.indexOf('T') > 0) {
      _date = date.split('T');
      _date = _date[0] + ' ' + _date[1];
    }
    return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
  }

  const addContents = async (type) => {
    if (contentsIds?.length === 0) {
      alert('매거진을 선택해주세요.');
      return;
    }
    if (!startDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const body = {
      name,
      nameEn,
      description,
      descriptionEn,
      contentsType: 'MAGAZINE',
      contentsIds,
      sequence,
      activeYn,
      startDate: handleDate(startDt, true, true),
      endDate: handleDate(endDt, true, false),
    };

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(
          `${COMMUNITY_CURATED_ARTICLE}/${prevId}/edit`,
          body,
          {},
        );
      } else {
        await axios.post(`${COMMUNITY_CURATED_ARTICLE}`, body, {});
      }
      await navigate('/culture/curatedMagazine');
      alert('저장되었습니다.');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 목록 조회
  const getMagazines = async () => {
    // params
    const params = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
      word,
    };
    await axios.get(MAGAZINE, { params: params })
    .then(resp =>{
      setMagazineMaster(resp.data.magazineList);
      setTotalCount(resp.data.count || 0);
    })
    .catch(err => {
      console.log(err);
    });
  };

  // 모달 열기
  const setModal = () => {
    setModalOpen(true);
    setMagazineWork(magazineCurated || []);
    getMagazines().then();
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    onReset();
    setPageNumber(0);
    setMagazineWork([]);
  };

  // 선택된 컨텐츠 저장
  const saveContentList = () => {
    setMagazineCurated(magazineWork);
    closeModal();
  };

  // 클릭시 추가
  const addSelectedContent = (data) => {
    if (magazineWork.length >= 1 ) {
      alert('한개만 선택 가능합니다.');
      return;
    }
    if (!magazineWork?.find((e) => e.id === data.id)) {
      setMagazineWork([...magazineWork, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (el) => {
    const result = magazineWork.filter((item) => item !== el);
    setMagazineWork(result);
  };

  // 엔터키로 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      getMagazines().then();
    }
  };

  // 드래그 앤 드롭
  const moveListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = magazineCurated[dragIndex];
      const hoverItem = magazineCurated[hoverIndex];

      setMagazineCurated((temp) => {
        const workList = [...temp];
        workList[dragIndex] = hoverItem;
        workList[hoverIndex] = dragItem;
        return workList;
      });
    },
    [magazineCurated],
  );

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton text="수정하기" onSubmit={() => addContents('edit')}/>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name" label="컨텐츠 명" placeholder="컨텐츠 명을 입력해 주세요."
            value={name}
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn" label="컨텐츠 명 EN" placeholder="컨텐츠 명 EN을 입력해 주세요."
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description" label="컨텐츠 설명" placeholder="컨텐츠 설명을 입력해 주세요."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn" label="컨텐츠 설명 EN" placeholder="컨텐츠 설명 EN을 입력해 주세요."
            value={descriptionEn}
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            name="activeYn" label="공개 여부"
            value={activeYn}
            onChange={(e) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            name="startDt" label="노출 개시 일시"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            name="endDt" label="노출 종료 일시"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <VideoInput onClick={() => setModal()} text="매거진 선택" />
        </InputWrapper>
        <InputWrapper>
          <TagHead>
            <ListHead title="" />
            <ListHead title="번호" />
            <ListHead title="이미지" />
            <ListHead title="제목" />
            <ListHead title="공개여부"/>
            <ListHead title="노출 시작일" />
            <ListHead title="노출 종료일" />
            <ListHead title="국가"/>
            <ListHead title="언어" />
          </TagHead>
          <>
            {magazineCurated?.map((item, index) => (
              <CuratedMagazineRow
                data={item}
                moveListItem={moveListItem}
                index={index}
              />
            ))}
          </>
        </InputWrapper>
      </MainContainer>
      
      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
              <NormalButton text="저장" onSubmit={() => saveContentList()}/>
            </ModalButtonWrapper>
            <VideoModalTitle>선택 매거진</VideoModalTitle>
            {magazineWork?.length !== 0 && magazineWork ? (
              <>
                <TableTitle>{magazineWork?.length}개의 매거진</TableTitle>
                <LineDiv />
                <Table
                    colList={[50, 100, 160, 70, 110, 110, 60, 60, 50]}
                    className="nonListTable"
                >
                  {magazineWork?.map((data) => (
                    <WorkMagazineRow
                      key={data.id}
                      data={data}
                      onClick={() => filterSelectedContent(data)}
                      selected
                      editable
                    />
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>선택된 매거진이 없습니다. 아래 검색 후 매거진을 선택해주세요.</VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>매거진 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchInputWrapper>
                  <SearchInput
                    value={word}
                    onChange={setWord}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{totalCount}개 매거진</TableTitle>
              {totalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={[50, 100, 160, 70, 110, 110, 60, 60, 50]}
              thead={
                <>
                  <ListHead title="번호" />
                  <ListHead title="이미지" />
                  <ListHead title="제목" />
                  <ListHead title="공개여부"/>
                  <ListHead title="노출 시작일" />
                  <ListHead title="노출 종료일" />
                  <ListHead title="국가"/>
                  <ListHead title="언어" />
                </>
              }
            >
              {magazineMaster?.map((el) => (
                <WorkMagazineRow
                  key={el.id}
                  data={el}
                  onClick={addSelectedContent}
                  editable
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};
export default CuratedMagazineDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }
  &:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(n + 6):nth-child(-n + 7) {
    grid-row: 4;
  }
  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-row: 6;
    grid-column: span 2;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.6fr 1.2fr 1.5fr 1.1fr 1.5fr 1.7fr 0.8fr 0.9fr;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;
