import {
  configureStore,
  ThunkAction,
  Action,
  combineReducers,
} from '@reduxjs/toolkit';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import logger from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import { setupListeners } from '@reduxjs/toolkit/query';
import userSlice from '../slices/user';
import beatSlice from '../slices/beat';
import genreSlice from '../slices/genre';
import operationSlice from '../slices/operation';
import MoodSlice from '../slices/mood';
import exploreSlice from '../slices/explore';
import csSlice from '../slices/customService';
import mainSlice from '../slices/main';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  beat: beatSlice.reducer,
  genre: genreSlice.reducer,
  explore: exploreSlice.reducer,
  mood: MoodSlice.reducer,
  operation: operationSlice.reducer,
  cs: csSlice.reducer,
  main: mainSlice.reducer,
});

// persist를 위한 기본 세팅
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

// 두번째 인자에 combine된 리듀서 넣기
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  // 바로 하단 middleware처럼 array 형태로 저장 가능하지만 추가적으로 middleware 적용시 custom해야함
  // middleware: [thunk, logger],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // toolkit에서 제공하는 thunk
      thunk: true,
      // redux-persist 설정
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // redux-logger 설정
    }),
  // .concat(logger),
  //devTools prod에서만 사용안하고 나머지 사용
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

// 새로고침해도 스토어상의 값들 유지하는거 내보내기
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
