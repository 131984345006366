import React from 'react';
import styled from 'styled-components';

interface CustomProps {
  trHeight?: string;
}

type Props = {
  thead?: React.ReactNode;
  colList?: number[];
  trHeight?: string;
  className?: string;
};

const Table: React.FC<Props> = ({
  className,
  thead,
  children,
  colList,
  trHeight,
}) => {
  return (
    <TableContainer className={className}>
      <TableWrapper trHeight={trHeight}>
        <colgroup>
          {colList?.map((el) => (
            <col style={{ width: el }} key={Math.random()} />
          ))}
        </colgroup>
        <thead>
          <tr>{thead}</tr>
        </thead>
        <tbody>{children}</tbody>
      </TableWrapper>
    </TableContainer>
  );
};

export default Table;

const TableContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  min-height: 400px;
  -ms-overflow-style: none;
  z-index: 0;
  &::-webkit-scrollbar {
    display: none;
  }

  &.nonListTable {
    min-height: 0;
  }
`;

const TableWrapper = styled.table<CustomProps>`
  width: 100%;
  text-align: center;
  table-layout: fixed;

  thead {
    position: sticky;
    top: -1px;
  }

  tbody {
    overflow-y: scroll;
    tr {
      border-bottom: 1px solid #e7e7e7;
      height: ${({ trHeight }) => trHeight || '70px'};
    }
    td {
      padding: 0 5px;
      text-align: center;
    }
  }
`;
