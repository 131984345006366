import React, {useEffect, useState} from "react";
import {ADVERTISEMENT_CAMPAIGN,} from "../../../constant/apiUrl";
import axios from "axios";
import AdvertisementCampaignEditSeq from "./AdvertisementCampaignEditSeq";

// 광고 목록 화면
const AdvertisementCampaignEditSeqNM = () => {

  //표시 데이터
  const [contentsList, setContentsList] = useState([]);

  //데이터 조회
  const getCommunityCategoryList = async () => {
    await axios.get(`${ADVERTISEMENT_CAMPAIGN}-sequence`, {
      params : {
        offset: 0,
        limit: 100,
        campaignType:'NM'
      },
    })
    .then(resp=>{
      setContentsList(resp.data.adminAdvertisementCampaignList);
    })
    .catch(err=>{
      console.log(err);
      alert('문제가 발생하였습니다. 담당자에게 문의해주세요.'+ err.response.data.message);
    });
  };
  useEffect(() => {
    getCommunityCategoryList().then();
  }, []);

  return <AdvertisementCampaignEditSeq
      title={'메인 팝업 순서 변경'}
      contentsList={contentsList}
      setContentsList={setContentsList}
      callBack={getCommunityCategoryList}
  />;
};
export default AdvertisementCampaignEditSeqNM;
