////////////////////////////////////////////////////////////////////////////////
// 광고주 통합 리포트 : 광고 상세
////////////////////////////////////////////////////////////////////////////////
import React from "react";
import TypicalTable from "../../../components/common/List/TypicalTable";
import ListItem from "../../../components/common/List/ListItem";
import {Tooltip} from "./Tooltip";

////////////////////////////////////////////////////////////////////////////////
// 전체 리포트
////////////////////////////////////////////////////////////////////////////////
// 광고주 통합 리포트 : 전체 리포트
const AdvertisementFullReport = ({parsedReports, parsedReportTotal}) => {

  return (
      <TypicalTable
          thead={
            <>
              <tr>
                <th rowSpan={2} width='7%'>기간</th>
                <th colSpan={2}>노출수</th>
                <th colSpan={2}>재생수</th>
                <th colSpan={2}>클릭수</th>
                <th colSpan={2}>조회수</th>
                <th colSpan={2}>참여수</th>
                <th><Tooltip message={'노출수\n대비\n클릭률'}>클릭률</Tooltip></th>
                <th><Tooltip message={'클릭수\n대비\n참여율'}>참여율</Tooltip></th>
              </tr>
              <tr>
                <th>전체</th><th>유니크</th>
                <th>전체</th><th>유니크</th>
                <th>전체</th><th>유니크</th>
                <th>전체</th><th>유니크</th>
                <th>전체</th><th>유니크</th>
                <th>전체</th>
                <th>전체</th>
              </tr>
            </>
          }
      >
        {parsedReportTotal && (
            <AdvertisementFullReportRow row={parsedReportTotal}/>
        )}
        {parsedReports?.map((el) => (
            <AdvertisementFullReportRow row={el} key={el.dateUnit} />
        ))}
      </TypicalTable>
  );
};
export default AdvertisementFullReport;

// 전체 리포트 ROW
const AdvertisementFullReportRow = ({row}) => {
  // console.log('AdvertisementFullReportRow > row:', row);
  return (
      <tr>
        <td><ListItem text={row.dateUnit || '-'} /></td>
        <td><ListItem text={row.imprCnt || '-'} /></td>
        <td><ListItem text={row.imprUnq || '-'} /></td>
        <td><ListItem text={row.playCnt || '-'} /></td>
        <td><ListItem text={row.playUnq || '-'} /></td>
        <td><ListItem text={row.clckCnt || '-'} /></td>
        <td><ListItem text={row.clckUnq || '-'} /></td>
        <td><ListItem text={row.ldvwCnt || '-'} /></td>
        <td><ListItem text={row.ldvwUnq || '-'} /></td>
        <td><ListItem text={row.engaCnt || '-'} /></td>
        <td><ListItem text={row.engaUnq || '-'} /></td>
        <td><ListItem text={`${row.clckRat} %` || '-'} /></td>
        <td><ListItem text={`${row.engaRat} %` || '-'} /></td>
      </tr>
  );
};
