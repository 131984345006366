import React from 'react';
import styled from 'styled-components';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

interface TextInputProps {
  name: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  id?: string;
  onReset?: () => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  className?: string;
}

interface ButtonProps {
  tabIndex?: React.HTMLAttributes<number>;
}

const TextInput = ({
  name,
  id,
  type,
  value,
  onChange,
  onReset,
  placeholder,
  onKeyPress,
  className
}: TextInputProps) => {
  return (
    <InputBox className={className}>
      <input
        name={name}
        id={id}
        value={value}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {(value && onReset) && (
        <InputDeleteButton
          tabIndex={-1}
          onClick={() => {
            onReset();
          }}
        />
      )}
    </InputBox>
  );
};

export default TextInput;

const InputBox = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 55px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.subGray};
  }
  
  &.masterMessage input {
    border: solid 3px #1d5d90;
  }
`;

const InputDeleteButton = styled.button<ButtonProps>`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;
