import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';

// config
import { SERVER } from '../../../../utils/config'
import { ADMIN_BAUND_API_API } from '../../../../constant/apiUrl';
import { resetLoading, setLoading } from '../../../../redux/slices/user';
import {sortTypeValues} from "../../../../constant/common";
import * as commonFn from "../../../../utils/commonFn";

import BaundApiCategoryMenuSelect from '../component/BaundApiCategoryMenuSelect';
import VideoModal from '../../../../components/common/Modal/VideoModal';
import SelectModule from "../../../../components/common/Select/SelectModule";

const BaundApiDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const baundApiId = detailData?.baundApiId || null;
  const nextSequence = detailData?.nextSequence || 1;
  const categoryIdForSearch = detailData?.categoryIdForSearch || 0;
  const categoryNameForSearch = detailData?.categoryNameForSearch || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;
  const activationTypeForSearch = detailData?.activationTypeForSearch || null;

  const [categoryId, setCategoryId] = useState(categoryIdForSearch);
  const [categoryName, setCategoryName] = useState(categoryNameForSearch);
  const categories = detailData?.categories || [];

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [responseType, setResponseType] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [needSignin, setNeedSignin] = useState('Y');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [activeYn, setActiveYn] = useState('Y');
  const [sortType, setSortType] = useState(null);

  const [modalOpen, setModalOpen] = useState(false);
  const [apiUrlResult, setApiUrlResult] = useState('');

  useEffect(() => {
    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ categoryIdForSearch, categoryNameForSearch, pageNumberForSearch, activationTypeForSearch })
    );
  }, []);

  const handleSelectSetData = (e) => {
    setCategoryId(e.baundApiCategoryId);
    setCategoryName(e.name);
  };

  useEffect(() => {
    const result = categories.filter(
      (item) => item.baundApiCategoryId === categoryId,
    )[0];
    if (result) {
      setCategoryName(result.name);
    }
  }, [categoryId, categories]);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(`${ADMIN_BAUND_API_API}/${baundApiId}/detail`);
      if (resp.data.adminBaundApi) {
        setCategoryId(resp.data.adminBaundApi.baundApiCategoryId);
        setName(resp.data.adminBaundApi.name);
        setNameEn(resp.data.adminBaundApi.nameEn);
        setResponseType(resp.data.adminBaundApi.responseType);
        setApiUrl(resp.data.adminBaundApi.apiUrl);
        setSequence(resp.data.adminBaundApi.sequence);
        setNeedSignin(resp.data.adminBaundApi.needSignin);
        setDescription(resp.data.adminBaundApi.description);
        setDescriptionEn(resp.data.adminBaundApi.descriptionEn);
        setActiveYn(resp.data.adminBaundApi.activeYn);

        // 배틀에서 필요
        setSortType(resp.data.adminBaundApi.sortType || null);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [baundApiId]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (baundApiId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, baundApiId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  const addContents = async (type) => {
    if (!categoryId) {
      alert('카테고리를 선택해주세요.');
      return;
    }
    if (!responseType) {
      alert('조회 결과 타입을 입력해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('baundApiCategoryId', categoryId);
    if (name != null) {
      formData.append('name', name);
    }
    if (nameEn != null) {
      formData.append('nameEn', nameEn);
    }
    formData.append('responseType', responseType);
    formData.append('apiUrl', apiUrl);
    formData.append('sequence', sequence);
    formData.append('needSignin', needSignin);
    if (description != null) {
      formData.append('description', description);
    }
    if (descriptionEn != null) {
      formData.append('descriptionEn', descriptionEn);
    }
    formData.append('activeYn', activeYn);

    if ((categoryName === '배틀' || categoryName === 'battle' || responseType.startsWith('BATTLE')) && sortType) {
      formData.append('sortType', sortType);
    }

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${ADMIN_BAUND_API_API}/${baundApiId}/edit`, formData, {});
      } else {
        await axios.post(`${ADMIN_BAUND_API_API}`, formData, {});
      }
      await navigate('/system/baundApi', {
        state: {
          categoryId,
          categoryName,
        },
      });
      alert('저장되었습니다.');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  const openModal = async () => {
    try {
      dispatch(setLoading());
      const apiUrlResultResp = await axios.get(
        SERVER.replace('/admin', '') + apiUrl,
        {
          params: {
            limit: 10,
            offset: 0,
          },
        },
      );
      if (apiUrlResultResp.data) {
        setApiUrlResult(JSON.stringify(apiUrlResultResp.data, null, 2));
      }
      setModalOpen(true);
    } catch (error) {
      setApiUrlResult(error.response.data.message);
      setModalOpen(true);
    } finally {
      dispatch(resetLoading());
    }
  };
  const closeModal = () => {
    setApiUrlResult('');
    setModalOpen(false);
  };

  return (
    <>
      <TitleText>바운드 API 상세</TitleText>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!baundApiId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper span={6}>
          <BaundApiCategoryMenuSelect
            placeholder="카테고리를 선택해주세요."
            value={categoryName}
            onChange={handleSelectSetData}
            options={categories}
            label="채널"
            Required
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="name"
            label="API 명"
            value={name}
            placeholder="API 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="nameEn"
            label="API 명 EN"
            value={nameEn}
            placeholder="API 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <LabelTextInput
            name="responseType"
            label="조회 결과 타입"
            value={responseType}
            placeholder="조회 결과 타입을 입력해 주세요."
            onChange={(e) => setResponseType(e.target.value)}
            onReset={() => setResponseType('')}
            Required
          />
        </InputWrapper>
        <InputWrapper span={5}>
          <LabelTextInput
            name="apiUrl"
            label="호출용 URL"
            value={apiUrl}
            placeholder="호출용 URL을 입력해 주세요."
            onChange={(e) => setApiUrl(e.target.value)}
            onReset={() => setApiUrl('')}
          />
        </InputWrapper>
        <InputWrapper mt={35}>
          <NormalButton
            text="api 결과 확인"
            className="cancelBtn"
            onSubmit={() => openModal()}
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <RadioButton
            value={needSignin}
            name="needSignin"
            label="로그인 필요 여부"
            onChange={(e) => setNeedSignin(e.target.value)}
            text={['필요', '불필요']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <LabelTextInput
            name="description"
            label="설명"
            value={description}
            placeholder="설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <LabelTextInput
              name="descriptionEn"
              label="설명 EN"
              value={descriptionEn}
              placeholder="설명 EN을 입력해 주세요."
              onChange={(e) => setDescriptionEn(e.target.value)}
              onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>

        {
          (categoryName === '배틀' || categoryName === 'battle' || responseType.startsWith('BATTLE')) &&
            <InputWrapper span={6}>
              <SelectModule
                  name='type'
                  label="정렬 타입"
                  placeholder="정렬 타입을 선택해주세요."
                  value={sortTypeValues[sortType]}
                  onChange={e => setSortType(e.value)}
                  options={commonFn.transObjectToOptions(sortTypeValues)}
              />
            </InputWrapper>
        }
      </MainContainer>

      {/*Api 조회 결과 표시 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <Textarea
              name="apiUrlResult"
              value={apiUrlResult}
              placeholder="api 조회 결과"
              onChange={(e) => e}
            />
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default BaundApiDetail;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const Textarea = styled.textarea`
  width: 100%;
  height: auto;
  padding: 15px 40px 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  min-height: 500px;

  ::placeholder {
    color: ${({ theme }) => theme.color.subGray};
    font-weight: 500;
  }
`;
