import React from 'react';
import { useNavigate } from 'react-router-dom';

// common
import EditButton from '../../../components/common/Button/EditButton';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

// 타입
import { BeatMakerRowComponentDataProps } from '../../../definitions/beat';

// hooks
import getMobileNum from '../../../hooks/getMobileNum';

// 인터페이스
interface dataProps {
  data: BeatMakerRowComponentDataProps;
  saveStorage: () => void;
}

const RowComponent = ({ data, saveStorage }: dataProps) => {
  const { id, name, profileImgUrl, pid, mobile, email } = data;
  const navigate = useNavigate();

  const editBeatMaker = (beatMakerId: any) => {
    navigate('/beat/beatmaker/edit', { state: beatMakerId });
  };

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg url={profileImgUrl} className="beatMaker" />
      </td>
      <td>
        <ListItem text={pid} />
      </td>
      <td>
        <ListItem text={name} />
      </td>
      <td>
        <ListItem text={mobile ? getMobileNum(`0${mobile}`) : '미작성'} />
      </td>
      <td>
        <ListItem text={email} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            editBeatMaker(id);
            saveStorage();
          }}
          text="수정"
        />
      </td>
    </tr>
  );
};

export default RowComponent;
