import { useRef } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// component
import Logo from './Logo';
import MenuIcon from './MenuIcon';

// hook
import { useAppSelector } from '../../redux/store/hooks';

const Navbar = () => {
	const ref = useRef(null);

	// 로그인 여부 및 사용자 권한 확인
	const isLoggedIn = useAppSelector(state => state.user.isLoggedIn);

	return <NavWrapper>
		<Container>
			<LogoWrapper to="/">
				<Logo />
			</LogoWrapper>

			{isLoggedIn && <>
				<MenuWrapper ref={ref} />
				<MenuIcon />
			</>}
		</Container>
	</NavWrapper>;
};

export default Navbar;

const NavWrapper = styled.header`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 60px;
  top: 0;
  background-color: ${({ theme }) => theme.color.mainBlack};
  z-index: 50;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 1300px;
  margin: auto;

  @media only screen and (max-width: 768px) {
    width: calc(100%- 40px);
    margin: auto 20px;
  }
`;

const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  margin-right: 98px;
`;

const MenuWrapper = styled.ul`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 295px);
`;
