import React, {useCallback, useEffect, useState} from "react";
import useInput from "../../../../hooks/useInput";
import {postsPerPage} from "../../../../constant/common";
import {COMMUNITY_BOARD_ARTICLE} from "../../../../constant/apiUrl";
import axios from "axios";
import Pagination from "../../../../components/common/Pagination";
import Table from "../../../../components/common/List/Table";
import ListHead from "../../../../components/UI/ListHead";
import ListItem from "../../../../components/common/List/ListItem";
import styled from "styled-components";
import SearchMenuSelect
  from "../../../../components/common/Select/SearchMenuSelect";
import SearchInput from "../../../../components/common/Input/SearchInput";
import handleDate from "../../../../hooks/handleDate";

const CommunityBoardArticleSearch = () => {

  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [articleList, setArticleList] = useState([]);

  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState(storageType || 'MEMBER_ID');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
      storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  const params = {
    type,
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  useEffect(() => {
    if (storagePageNumber) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('searchType', '');
      window.localStorage.setItem('searchWord', '');
    }
  }, [storagePageNumber]);


  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
    setPageNumber(0);
  }

  const searchCommunityBoardArticle = async () => {
    try {
      const resp = await axios.get(`${COMMUNITY_BOARD_ARTICLE}/search`, { params });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
    }
  };

  // 엔터키로 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter' && word) {
      if (!type) {
        setType('MEMBER_ID');
        params.type = 'MEMBER_ID';
      }
      setPageNumber(0);
      params.offset = 0;
      searchCommunityBoardArticle();
    }
  };

  useEffect(() => {
    searchCommunityBoardArticle();
  }, [pageNumber]);

  return (<>
    <TopContainer>
      <TopWrapper>
        <SearchWrapper>
          <SearchMenuSelect
              options={[
                {text: '회원 아이디', value: 'MEMBER_ID'}
              ]}
              onClick={handleSelect}
              setState={setType}
          />
          <SearchInputWrapper>
            <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
            />
          </SearchInputWrapper>
        </SearchWrapper>
      </TopWrapper>

      <PaginationWrapper>
        {totalCount !== 0 && (
        <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
        />
      )}
      </PaginationWrapper>
    </TopContainer>
    <MainContainer>
      <Table
        colList={[50, 70, 70, 100, 300, 85]}
        thead={
          <>
            <ListHead title="게시글 아이디" />
            <ListHead title="작성자 Pid" />
            <ListHead title="작성자 명" />
            <ListHead title="제목" />
            <ListHead title="내용" />
            <ListHead title="작성일" />
          </>
        }
      >
        {articleList?.map((el) => (
          <tr key={el.id} >
            <td><ListItem text={el.id} /></td>
            <td><ListItem text={el.memberPid} /></td>
            <td><ListItem text={el.memberName} /></td>
            <td><ListItem text={el.title} /></td>
            <td><ListItem text={el.content} /></td>
            <td><ListItem text={handleDate(el.createdDt)} /></td>
          </tr>
        ))}
      </Table>
    </MainContainer>
  </>);
};
export default CommunityBoardArticleSearch;


const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;
