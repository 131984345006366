import React, {useMemo, useRef} from 'react';
import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import styled from "styled-components";
import './CssQuill.css';

Quill.register(Quill.import('attributors/style/direction'     ), true);
Quill.register(Quill.import('attributors/style/align'         ), true);
Quill.register(Quill.import('attributors/style/background'    ), true);
Quill.register(Quill.import('attributors/style/color'         ), true);
Quill.register(Quill.import('attributors/style/font'          ), true);

// Don't forget corresponding css
const Size = Quill.import("attributors/style/size");
// Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Size.whitelist = ['6px','8px', '10px', '12px','14px','16px', '18px', '20px', '22px'];
Quill.register(Size, true);

//Text indent
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    // console.log('EditorQuill > IndentAttributor > add > node:',node, ' / value:', value);
    if (value <= 0) {
      this.remove(node);
      return true;
    }
    else {
      return super.add(node, `${value}em`);
    }
  }

  value(node) {
    let indentTemp = super.value(node).replace('em','');
    return parseInt(indentTemp) || undefined;  // Don't return NaN
  }
}
Quill.register(new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
}), true);

//line-height
class LineHeightAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === '+10' || value === '-10') {
      let indent = this.value(node) || 100;
      value = (value === '+10' ? (indent + 10) : (indent - 10));
    }
    // console.log('EditorQuill > LineHeightAttributor > add > node:',node, ' / value:', value);
    if (value === 100) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}%`);
    }
  }

  canAdd(node, value) {
    return super.canAdd(node, value) || super.canAdd(node, parseInt(value));
  }

  value(node) {
      let indentTemp = super.value(node).replace('%','');
      console.log('EditorQuill > LineHeightAttributor > value > indentTemp:', indentTemp);
      return parseInt(indentTemp) || undefined;  // Don't return NaN
  }
}
Quill.register(new LineHeightAttributor('lineHeightDecrease', 'line-height', {
  scope: Parchment.Scope.BLOCK,
  whitelist: [ '100%', '110%', '120%', '130%', `140%`, `150%`, '160%', '170%', '180%', `190%`, `200%`]
}), true);

Quill.register(new LineHeightAttributor('lineHeightIncrease', 'line-height', {
  scope: Parchment.Scope.BLOCK,
  whitelist: [ '100%', '110%', '120%', '130%', `140%`, `150%`, '160%', '170%', '180%', `190%`, `200%`]
}), true);

let icons = Quill.import('ui/icons');
icons['lineHeightIncrease'] = `<svg viewBox="0 0 18 18">
  <line class="ql-stroke" x1="8" x2="15" y1="4" y2="4"></line>
  <line class="ql-stroke" x1="8" x2="15" y1="9" y2="9"></line>
  <line class="ql-stroke" x1="8" x2="15" y1="14" y2="14"></line>
  <line class="ql-stroke" x1="3" x2="3" y1="2" y2="6"></line>
  <line class="ql-stroke" x1="3" x2="3" y1="12" y2="16"></line>
  <line class="ql-stroke" x1="1" x2="5" y1="4" y2="4"></line>
  <line class="ql-stroke" x1="1" x2="5" y1="14" y2="14"></line>
  <line class="ql-stroke" x1="2" x2="4" y1="3" y2="3"></line>
  <line class="ql-stroke" x1="2" x2="4" y1="15" y2="15"></line>
</svg>`;
icons['lineHeightDecrease'] = `<svg viewBox="0 0 18 18">
  <line class="ql-stroke" x1="8" x2="15" y1="4" y2="4"></line>
  <line class="ql-stroke" x1="8" x2="15" y1="9" y2="9"></line>
  <line class="ql-stroke" x1="8" x2="15" y1="14" y2="14"></line>
  <line class="ql-stroke" x1="3" x2="3" y1="2" y2="6"></line>
  <line class="ql-stroke" x1="3" x2="3" y1="12" y2="16"></line>
  <line class="ql-stroke" x1="1" x2="5" y1="4" y2="4"></line>
  <line class="ql-stroke" x1="1" x2="5" y1="14" y2="14"></line>
  <line class="ql-stroke" x1="2" x2="4" y1="5" y2="5"></line>
  <line class="ql-stroke" x1="2" x2="4" y1="13" y2="13"></line>
</svg>`;

const ImageBlot = Quill.import('formats/image');
export class CustomImageBlot extends ImageBlot {

  static blotName = 'customImage';
  static tagName = 'img';

  /**
   * Converts the HTML tag to image blot
   * @param value
   */
  static create(value) {
    let node = super.create();
    node.setAttribute('src', value.url);
    node.setAttribute('width', '100%');
    return node;
  }

  /**
   * Converts the image blot to HTML tag
   * @param node
   */
  static value(node) {
    let blot = {};
    blot.url = node.getAttribute('src');
    blot.width = node.getAttribute('width');
    return blot;
  }
}
Quill.register(CustomImageBlot);

const VideoBlot = Quill.import('formats/video');
export class CustomVideoBlot extends VideoBlot {

  static create(value) {

    // CustomImageBlot는 imagehandler를 커스터마이즈 했기에 입력 값의 형식을 지정할 수 있었지만
    // 비디오의 경우 기존 것을 사용하기에 입력시에는 문자열, 수정시에는 객체로 넘어와 다음과 같이 처리
    let src = (typeof value === 'string') ?value:value.src;

    let node = super.create(src);
    node.setAttribute('src', this.sanitize(src));
    node.setAttribute('width', '100%');
    // node.setAttribute('height', '64%');
    node.setAttribute('style', 'aspect-ratio:100/56');
    return node;
  }

  static value(node) {
    // console.log('CustomImageBlot > value : ', node);
    let blot = {};
    blot.src = node.getAttribute('src');
    blot.width = '100%';
    // blot.height = '64%';
    blot.style = 'aspect-ratio:100/56';
    return blot;
  }
}
Quill.register(CustomVideoBlot);

const EditorQuill = ({
  value,
  onValueChange,
  fileSaveUrl,
  label,
  allowFileUpload,
  Required,
}) => {
  // console.log('EditorQuill > value', value);
  // console.log('EditorQuill > onValueChange', onValueChange);
  const quillRef = useRef(); // 에디터 접근을 위한 ref return (

  // 이미지 처리를 하는 핸들러
  const imageHandler = () => {
    // console.log('에디터에서 이미지 버튼을 클릭하면 이 핸들러가 시작됩니다!');

    // 1. 이미지를 저장할 input type=file DOM을 만든다.
    const input = document.createElement('input');
    // 속성 써주기
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    // 에디터 이미지버튼을 클릭하면 이 input이 클릭된다.
    // input이 클릭되면 파일 선택창이 나타난다.
    input.click();

    // input에 변화가 생긴다면 = 이미지를 선택
    input.addEventListener('change', async () => {
      // console.log('온체인지');
      const file = input.files[0];
      // formData는 키-밸류 구조
      const formData = new FormData();
      formData.append('imgFile', file);
      try {
        const result = await axios.post(`${fileSaveUrl}`, formData);
        // console.log('성공 시, 백엔드가 보내주는 데이터', result.data.imgUrl);
        const IMG_URL = result.data.imageUrl;
        // 이 URL을 img 태그의 src에 넣은 요소를 현재 에디터의 커서에 넣어주면 에디터 내에서 이미지가 나타난다
        // src가 base64가 아닌 짧은 URL이기 때문에 데이터베이스에 에디터의 전체 글 내용을 저장할 수있게된다
        // 이미지는 꼭 로컬 백엔드 uploads 폴더가 아닌 다른 곳에 저장해 URL로 사용하면된다.

        // 이미지 태그를 에디터에 써주기 - 여러 방법이 있다.
        const editor = quillRef.current.getEditor(); // 에디터 객체 가져오기
        // 1. 에디터 root의 innerHTML을 수정해주기
        // editor의 root는 에디터 컨텐츠들이 담겨있다. 거기에 img태그를 추가해준다.
        // 이미지를 업로드하면 -> 멀터에서 이미지 경로 URL을 받아와 -> 이미지 요소로 만들어 에디터 안에 넣어준다.
        // editor.root.innerHTML =
        //   editor.root.innerHTML + `<img src=${IMG_URL} /><br/>`; // 현재 있는 내용들 뒤에 써줘야한다.

        // 2. 현재 에디터 커서 위치값을 가져온다
        const range = editor.getSelection();
        // 가져온 위치에 이미지를 삽입한다
        // editor.insertEmbed(range.index, 'image', IMG_URL);
        editor.insertEmbed(range.index, 'customImage', {url:IMG_URL});

      } catch (reason) {
        console.error(reason);
        alert('시스템 관리자에게 문의해 주세요');
      }
    });
  };

  // Quill 에디터에서 사용하고싶은 모듈들을 설정한다.
  // useMemo를 사용해 modules를 만들지 않는다면 매 렌더링 마다 modules가 다시 생성된다.
  // 그렇게 되면 addrange() the given range isn't in document 에러가 발생한다.
  // -> 에디터 내에 글이 쓰여지는 위치를 찾지 못하는듯
  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          // [{ font: Font.whitelist }],
          // 폰트 크기
          // [{ 'header': [1, 2, 3, false] }],
          // [{ size: ["0.75em", "1em", "1.5em", "2.5em"] }],
          [{size:['6px','8px', '10px', '12px','14px','16px', '18px', '20px', '22px']}],

        // 폰트 효과
          ['bold', 'italic', 'underline','strike', 'blockquote'],
          // 문단 형식
          [
            {'list': 'ordered'},
            {'list': 'bullet'},
            {'indent': '-1'},
            {'indent': '+1'},
            // {'lineHeightDecrease': '-10'},
            // {'lineHeightIncrease': '+10'},
          ],
          // 컨텐츠 연결
          allowFileUpload ? ['link', 'image', 'video']:['link', 'video'],
          // 효과
          [
            { 'align': [] },
            { 'color': [] },
            { 'background': [] },
            'code-block'
          ],
          ['clean'],
        ],
        handlers: {
          // 이미지 처리 함수 연결
          image: imageHandler,
          'code-block': htmlHandler
        },
      },

    };
  }, []);


  // html_textArea
  const htmlTextArea = document.createElement('textarea');
  const attrHtmlTextArea = document.createAttribute('quill__html');
  htmlTextArea.setAttributeNode(attrHtmlTextArea);

  /**
   * html 제어
   */
  function htmlHandler() {

    const activeTextArea = (htmlTextArea.getAttribute('quill__html').indexOf('-active-') > -1);

    if (activeTextArea) {
      //html editor to quill
      this.quill.pasteHTML(htmlTextArea.value);
    }
    else {
      if (!this.quill.container.querySelector('.ql-custom')) {
        // textArea 삽입
        const quillCustomDiv = this.quill.addContainer('ql-custom');
        quillCustomDiv.appendChild(htmlTextArea);
      }
      //quill to html editor
      htmlTextArea.value = this.quill.container.querySelector('.ql-editor').innerHTML;
    }

    htmlTextArea.setAttribute('quill__html', activeTextArea ? '' : '-active-');
  }

  // let formats = [
  //   //'font',
  //   'header',
  //   'bold', 'italic', 'underline', 'strike', 'blockquote',
  //   'list', 'bullet', 'indent',
  //   'link',
  //   // 'image',
  //   'video',
  //     'image',
  //   'align', 'color', 'background',
  //     'clean',
  // ]

  return(
      <InputContainer>
        <InputText>
          {label} {Required && <span>*</span>}
        </InputText>
        <div style={{height: "950px"}}>
          <ReactQuill
              style={{height: "900px"}}
              ref={quillRef}
              theme="snow"
              modules={modules}
              // formats={formats}
              value={value || ''}
              onChange={onValueChange}
              bounds={`[data-text-editor="form-editor"]`} //for link editor to be not cut-off
          />
        </div>
      </InputContainer>
  )
}
export default EditorQuill;


const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;