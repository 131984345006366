import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import {
  campaignOptions,
  campaignParticipateOptions,
  GetNotContainsAllCountryOption,
  notContainsAllCountryOptions,
} from '../../../constant/common';

import NormalButton from '../../../components/common/Button/NormalButton';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import FileInput from '../../../components/common/Input/FileInput';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import DateInput from '../../../components/common/Input/DateInput';
import VideoInput from '../../../components/common/Input/VideoInput';
import CheckBoxInput from '../../../components/common/Input/CheckBoxInput';
import LabelTextAreaInput from '../../../components/common/Input/LabelTextAreaInput';
import MultipleRadioButton from '../../../components/common/Button/MultipleRadioButton';
import { GoldCampaignApplicantPopup } from './GoldCampaignApplicantPopup';
import { CAMPAIGNS } from '../../../constant/apiUrl';
import LabelPlusButtonInput from '../../../components/common/Input/LabelPlusButtonInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import TextField from '../../../components/common/TextField';
import SelectModule from '../../../components/common/Select/SelectModule';

// 골드 캠페인 목록 화면(추가, 수정)
const GoldCampaignDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  // 여기서는 캠페인 아이디
  const campaignId = detailData !== null ? detailData.campaignId : null;

  // 내용 입력의 편의를 위해 true 인 경우에 한글 입력 값을 영문 값으로 복사. 신규 일때는 기본 값 true
  const [copyKR2EN, setCopyKR2EN] = useState(!campaignId);

  // 상위 노출
  const [upperExposureYn, setUpperExposureYn] = useState('N');
  const [upperExposureSequence, setUpperExposureSequence] = useState(99);
  // 썸네일 이미지
  const [thumbnailImgFile, setThumbnailImgFile] = useState(null);
  const [thumbnailImgUrl, setThumbnailImgUrl] = useState('');
  const [thumbnailEnImgFile, setThumbnailEnImgFile] = useState(null);
  const [thumbnailEnImgUrl, setThumbnailEnImgUrl] = useState('');
  // 상품 유형
  const [goodsType, setGoodsType] = useState('');
  // 상세보기 상단 메인 이미지
  const [detailUpperImgFile, setDetailUpperImgFile] = useState(null);
  const [detailUpperImgUrl, setDetailUpperImgUrl] = useState('');
  const [detailUpperEnImgFile, setDetailUpperEnImgFile] = useState(null);
  const [detailUpperEnImgUrl, setDetailUpperEnImgUrl] = useState('');
  // 캠페인 명
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  // 상품명
  const [goodsName, setGoodsName] = useState('');
  const [goodsNameEn, setGoodsNameEn] = useState('');
  // 상품 브랜드명
  const [goodsBrand, setGoodsBrand] = useState('');
  const [goodsBrandEn, setGoodsBrandEn] = useState('');
  // 상품 가격
  const [goodsPrice, setGoodsPrice] = useState('');
  // 제공 내역
  const [provideInfo, setProvideInfo] = useState('');
  const [provideInfoEn, setProvideInfoEn] = useState('');
  // 골드 캠페인 기간
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  // 국가 코드
  const [countryType, setCountryType] = useState('');
  // 적용 국가 언어
  // const [languageType, setLanguageType] = useState('');
  // 비공개 여부
  const [privateYn, setPrivateYn] = useState('Y');
  // 경품 유의사항
  const [goodsCautions, setGoodsCautions] = useState([]);
  const goodsCautionNextId = useRef(0);
  const [goodsEnCautions, setGoodsEnCautions] = useState([]);
  const goodsEnCautionNextId = useRef(0);
  // 상세페이지 이미지
  const [detailInfoImgFile, setDetailInfoImgFile] = useState(null);
  const [detailInfoImgUrl, setDetailInfoImgUrl] = useState('');
  const [detailInfoEnImgFile, setDetailInfoEnImgFile] = useState(null);
  const [detailInfoEnImgUrl, setDetailInfoEnImgUrl] = useState('');
  // 상세페이지 유튜브 제목, 설명, 주소
  const [youtubeTitle, setYoutubeTitle] = useState('');
  const [youtubeTitleEn, setYoutubeTitleEn] = useState('');
  const [youtubeDescription, setYoutubeDescription] = useState('');
  const [youtubeDescriptionEn, setYoutubeDescriptionEn] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  // 상세페이지 텍스트
  const [text, setText] = useState('');
  const [textEn, setTextEn] = useState('');
  // 약관 동의 관련 선택 파라미터
  // 개인정보 처리 동의 약관의 경우 상시 노출 필수 이므로 기본값 Y로 세팅합니다
  const [
    needAgreementPersonalInfoTermsYn,
    setNeedAgreementPersonalInfoTermsYn,
  ] = useState('Y');
  const [
    needAgreementIdentifyInfoTermsYn,
    setNeedAgreementIdentifyInfoTermsYn,
  ] = useState('N');
  const [
    needAgreementMmsAdvertisementTermsYn,
    setNeedAgreementMmsAdvertisementTermsYn,
  ] = useState('N');
  const [termsUrlList, setTermsUrlList] = useState([]);
  //참가 타입
  const [participateType, setParticipateType] = useState('');

  // 응모하기 정보 입력 탭 //////////////////////////////////////////////////////
  // 캠페인 응모 가격
  const [rafflePrice, setRafflePrice] = useState(0);
  // 최대 응모 인원
  const [maxRaffleCount, setMaxRaffleCount] = useState(0);
  // 현재 응모 인원
  // let raffleCount = 0;
  const [raffleCount, setRaffleCount] = useState(0);
  // 응모하기 유의사항
  const [raffleCautions, setRaffleCautions] = useState([]);
  const raffleCautionNextId = useRef(0);
  const [raffleEnCautions, setRaffleEnCautions] = useState([]);
  const raffleEnCautionNextId = useRef(0);
  // 응모하기 개인정보 입력 유의사항 설명
  const [rafflePersonalInfoFormCautions, setRafflePersonalInfoFormCautions] =
    useState([]);
  const rafflePersonalInfoFormCautionNextId = useRef(0);
  const [
    rafflePersonalInfoFormEnCautions,
    setRafflePersonalInfoFormEnCautions,
  ] = useState([]);
  const rafflePersonalInfoFormEnCautionNextId = useRef(0);
  // 당첨자 발표 기간
  const [announceWinnerStartDate, setAnnounceWinnerStartDate] = useState('');
  const [announceWinnerEndDate, setAnnounceWinnerEndDate] = useState('');
  // 응모하기 개인정보 작성 일자
  const [
    rafflePersonalInfoWriteStartDate,
    setRafflePersonalInfoWriteStartDate,
  ] = useState('');
  const [rafflePersonalInfoWriteEndDate, setRafflePersonalInfoWriteEndDate] =
    useState('');
  // 경품 발송 시작 일자
  const [raffleGoodsDeliveryStartDate, setRaffleGoodsDeliveryStartDate] =
    useState('');
  // 응모시 개인 정보 이름 입력 필요 여부
  const [needRaffleMemberNameYn, setNeedRaffleMemberNameYn] = useState('N');
  // 응모하기 개인정보 입력부분 이름 설명
  const [raffleInfoNameDescription, setRaffleInfoNameDescription] =
    useState('');
  const [raffleInfoNameEnDescription, setRaffleInfoNameEnDescription] =
    useState('');
  // 응모시 개인 정보 휴대폰 번호 입력 필요 여부
  const [needRafflePhoneNumberYn, setNeedRafflePhoneNumberYn] = useState('N');
  //응모하기 개인정보 입력부분 휴대폰 번호 설명
  const [
    raffleInfoPhoneNumberDescription,
    setRaffleInfoPhoneNumberDescription,
  ] = useState('');
  const [
    raffleInfoPhoneNumberEnDescription,
    setRaffleInfoPhoneNumberEnDescription,
  ] = useState('');
  // 응모시 개인 정보 주소 입력 필요 여부
  const [needRaffleAddressYn, setNeedRaffleAddressYn] = useState('N');
  //응모하기 개인정보 입력부분 주소 설명
  const [raffleInfoAddressDescription, setRaffleInfoAddressDescription] =
    useState('');
  const [raffleInfoAddressEnDescription, setRaffleInfoAddressEnDescription] =
    useState('');
  // 응모하기 이메일 주소 필요 여부
  const [needRaffleEmailYn, setNeedRaffleEmailYn] = useState('N');
  //응모하기 개인정보 입력부분 이메일 설명
  const [raffleInfoEmailDescription, setRaffleInfoEmailDescription] =
    useState('');
  const [raffleInfoEmailEnDescription, setRaffleInfoEmailEnDescription] =
    useState('');
  // 제세공과금안내 > 상세 텍스트
  const [raffleTaxInformation, setRaffleTaxInformation] = useState([]);
  const raffleTaxInformationNextId = useRef(0);
  const [raffleTaxEnInformation, setRaffleTaxEnInformation] = useState([]);
  const raffleTaxEnInformationNextId = useRef(0);

  // 교환하기 정보 입력 탭 //////////////////////////////////////////////////////
  // 교환 가격
  const [exchangePrice, setExchangePrice] = useState(0);
  // 최대 교환 인원
  const [maxExchangeCount, setMaxExchangeCount] = useState(0);
  // 현재 교환 인원
  // let exchangeCount = 0;
  const [exchangeCount, setExchangeCount] = useState(0);
  // 교환하기 유의사항
  const [exchangeCautions, setExchangeCautions] = useState([]);
  const exchangeCautionNextId = useRef(0);
  const [exchangeEnCautions, setExchangeEnCautions] = useState([]);
  const exchangeEnCautionNextId = useRef(0);
  // 정보 입력 유의사항 설명
  const [
    exchangePersonalInfoFormCautions,
    setExchangePersonalInfoFormCautions,
  ] = useState([]);
  const exchangePersonalInfoFormCautionNextId = useRef(0);
  const [
    exchangePersonalInfoFormEnCautions,
    setExchangePersonalInfoFormEnCautions,
  ] = useState([]);
  const exchangePersonalInfoFormEnCautionNextId = useRef(0);
  // 교환하기 개인정보 작성 일자
  const [
    exchangePersonalInfoWriteStartDate,
    setExchangePersonalInfoWriteStartDate,
  ] = useState('');
  const [
    exchangePersonalInfoWriteEndDate,
    setExchangePersonalInfoWriteEndDate,
  ] = useState('');
  // 교환하기 경품 발송 시작 일자
  const [exchangeGoodsDeliveryStartDate, setExchangeGoodsDeliveryStartDate] =
    useState('');
  // 교환시 개인 정보 이름 입력 필요 여부
  const [needExchangeMemberNameYn, setNeedExchangeMemberNameYn] = useState('N');
  // 교환하기 개인정보 입력부분 이름 설명
  const [exchangeInfoNameDescription, setExchangeInfoNameDescription] =
    useState('');
  const [exchangeInfoNameEnDescription, setExchangeInfoNameEnDescription] =
    useState('');
  // 교환시 개인 정보 휴대폰 번호 입력 필요 여부
  const [needExchangePhoneNumberYn, setNeedExchangePhoneNumberYn] =
    useState('N');
  //교환하기 개인정보 입력부분 휴대폰 번호 설명
  const [
    exchangeInfoPhoneNumberDescription,
    setExchangeInfoPhoneNumberDescription,
  ] = useState('');
  const [
    exchangeInfoPhoneNumberEnDescription,
    setExchangeInfoPhoneNumberEnDescription,
  ] = useState('');
  // 교환시 개인 정보 주소 입력 필요 여부
  const [needExchangeAddressYn, setNeedExchangeAddressYn] = useState('N');
  //교환하기 개인정보 입력부분 주소 설명
  const [exchangeInfoAddressDescription, setExchangeInfoAddressDescription] =
    useState('');
  const [
    exchangeInfoAddressEnDescription,
    setExchangeInfoAddressEnDescription,
  ] = useState('');
  // 교환시 개인 정보 이메일 주소 입력 필요 여부
  const [needExchangeEmailYn, setNeedExchangeEmailYn] = useState('N');
  //교환하기 개인정보 입력부분 이메일 설명
  const [exchangeInfoEmailDescription, setExchangeInfoEmailDescription] =
    useState('');
  const [exchangeInfoEmailEnDescription, setExchangeInfoEmailEnDescription] =
    useState('');
  // 제세공과금안내 > 상세 텍스트
  const [exchangeTaxInformation, setExchangeTaxInformation] = useState([]);
  const exchangeTaxInformationNextId = useRef(0);
  const [exchangeTaxEnInformation, setExchangeTaxEnInformation] = useState([]);
  const exchangeTaxEnInformationNextId = useRef(0);

  //응모자,교환자 확인 팝업 타입
  const [applicantModalType, setApplicantModalType] = useState(null);

  // 페이지 이동 ////////////////////////////////////////////////////////////////
  // 뒤로가기
  const goBack = useCallback(() => navigate(-1), [navigate]);

  // 캠페인 정보 ////////////////////////////////////////////////////////////////
  // 캠페인 상세 조회
  const getDetail = async () => {
    await axios
      .get(`${CAMPAIGNS}/${campaignId}`)
      .then((res) => {
        // console.log(res);
        // 상위 노출
        setUpperExposureYn(res.data.campaign.upperExposureYn);
        setUpperExposureSequence(res.data.campaign.upperExposureSequence);
        // 썸네일 이미지
        setThumbnailImgUrl(res.data.campaign.thumbnailImgUrl);
        setThumbnailEnImgUrl(res.data.campaign.thumbnailEnImgUrl);
        // 상품 유형
        setGoodsType(res.data.campaign.goodsType);
        // 상세보기 상단 메인 이미지
        setDetailUpperImgUrl(res.data.campaign.detailUpperImgUrl);
        setDetailUpperEnImgUrl(res.data.campaign.detailUpperEnImgUrl);
        // 캠페인 명
        setName(res.data.campaign.name);
        setNameEn(res.data.campaign.nameEn);
        // 상품명
        setGoodsName(res.data.campaign.goodsName);
        setGoodsNameEn(res.data.campaign.goodsNameEn);
        // 상품 브랜드명
        setGoodsBrand(res.data.campaign.goodsBrand);
        setGoodsBrandEn(res.data.campaign.goodsBrandEn);
        // 상품 가격
        setGoodsPrice(res.data.campaign.goodsPrice);
        // 제공 내역
        setProvideInfo(res.data.campaign.provideInfo);
        setProvideInfoEn(res.data.campaign.provideInfoEn);
        // 캠페인 기간
        res.data.campaign?.startDate &&
          setStartDate(res.data.campaign.startDate.slice(0, 10));
        res.data.campaign?.endDate &&
          setEndDate(res.data.campaign.endDate.slice(0, 10));
        //캠페인 공개 국가
        setCountryType(res.data.campaign.countryType);
        //공개 언어
        // setLanguageType(res.data.campaign.languageType);
        // 비공개 여부
        setPrivateYn(res.data.campaign.privateYn);
        // 경품 유의사항
        if (res.data.campaign.goodsCautions) {
          setGoodsCautions(
            res.data.campaign.goodsCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          goodsCautionNextId.current = res.data.campaign.goodsCautions.length;
        }
        if (res.data.campaign.goodsEnCautions) {
          setGoodsEnCautions(
            res.data.campaign.goodsEnCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          goodsEnCautionNextId.current =
            res.data.campaign.goodsEnCautions.length;
        }
        // 상세보기 이미지
        setDetailInfoImgUrl(res.data.campaign.detailInfoImgUrl);
        setDetailInfoEnImgUrl(res.data.campaign.detailInfoEnImgUrl);

        // 상세보기 유튜브
        setYoutubeTitle(res.data.campaign.youtubeTitle);
        setYoutubeTitleEn(res.data.campaign.youtubeTitleEn);
        setYoutubeDescription(
          res.data.campaign.youtubeDescription
            ? res.data.campaign.youtubeDescription
            : '',
        );
        setYoutubeDescriptionEn(
          res.data.campaign.youtubeDescriptionEn
            ? res.data.campaign.youtubeDescriptionEn
            : '',
        );
        setYoutubeUrl(res.data.campaign.youtubeUrl);

        // 상세보기 텍스트
        setText(res.data.campaign.text ? res.data.campaign.text : '');
        setTextEn(res.data.campaign.textEn ? res.data.campaign.textEn : '');
        // 정보 동의 약관 관련 데이터
        setNeedAgreementPersonalInfoTermsYn(
          res.data.campaign.needAgreementPersonalInfoTermsYn
            ? res.data.campaign.needAgreementPersonalInfoTermsYn
            : 'Y',
        );
        setNeedAgreementIdentifyInfoTermsYn(
          res.data.campaign.needAgreementIdentifyInfoTermsYn
            ? res.data.campaign.needAgreementIdentifyInfoTermsYn
            : 'N',
        );
        setNeedAgreementMmsAdvertisementTermsYn(
          res.data.campaign.needAgreementMmsAdvertisementTermsYn
            ? res.data.campaign.needAgreementMmsAdvertisementTermsYn
            : 'N',
        );
        setTermsUrlList(
          res.data.campaign.termsUrlList ? res.data.campaign.termsUrlList : [],
        );
        //참가 타입
        setParticipateType(res.data.campaign.participateType);

        // 응모하기 정보 입력 탭 //////////////////////////////////////////////////////
        // 캠페인 응모 가격
        setRafflePrice(res.data.campaign.rafflePrice);
        // 최대 응모 인원
        setMaxRaffleCount(res.data.campaign.maxRaffleCount);
        // 현재 응모 인원
        setRaffleCount(res.data.campaign.raffleCount);
        // 응모하기 유의사항
        if (res.data.campaign.raffleCautions) {
          setRaffleCautions(
            res.data.campaign.raffleCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          raffleCautionNextId.current = res.data.campaign.raffleCautions.length;
        }
        if (res.data.campaign.raffleEnCautions) {
          setRaffleEnCautions(
            res.data.campaign.raffleEnCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          raffleEnCautionNextId.current =
            res.data.campaign.raffleEnCautions.length;
        }
        // 정보 입력 유의사항 설명
        if (res.data.campaign.rafflePersonalInfoFormCautions) {
          setRafflePersonalInfoFormCautions(
            res.data.campaign.rafflePersonalInfoFormCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          rafflePersonalInfoFormCautionNextId.current =
            res.data.campaign.rafflePersonalInfoFormCautions.length;
        }
        if (res.data.campaign.rafflePersonalInfoFormEnCautions) {
          setRafflePersonalInfoFormEnCautions(
            res.data.campaign.rafflePersonalInfoFormEnCautions.map(
              (el, idx) => ({ id: idx, text: el }),
            ),
          );
          rafflePersonalInfoFormEnCautionNextId.current =
            res.data.campaign.rafflePersonalInfoFormEnCautions.length;
        }
        // 당첨자 발표 기간
        res.data.campaign?.announceWinnerStartDate &&
          setAnnounceWinnerStartDate(
            res.data.campaign.announceWinnerStartDate.slice(0, 10),
          );
        res.data.campaign?.announceWinnerEndDate &&
          setAnnounceWinnerEndDate(
            res.data.campaign.announceWinnerEndDate.slice(0, 10),
          );
        // 응모하기 개인정보 작성 일자
        res.data.campaign?.rafflePersonalInfoWriteStartDate &&
          setRafflePersonalInfoWriteStartDate(
            res.data.campaign.rafflePersonalInfoWriteStartDate.slice(0, 10),
          );
        res.data.campaign?.rafflePersonalInfoWriteEndDate &&
          setRafflePersonalInfoWriteEndDate(
            res.data.campaign.rafflePersonalInfoWriteEndDate.slice(0, 10),
          );
        // 경품 발송 시작 일자
        res.data.campaign?.raffleGoodsDeliveryStartDate &&
          setRaffleGoodsDeliveryStartDate(
            res.data.campaign.raffleGoodsDeliveryStartDate.slice(0, 10),
          );
        // 응모시 개인 정보 이름 입력 필요 여부
        setNeedRaffleMemberNameYn(
          res.data.campaign.needRaffleMemberNameYn
            ? res.data.campaign.needRaffleMemberNameYn
            : 'N',
        );
        //응모하기 개인정보 입력부분 이름 설명
        setRaffleInfoNameDescription(
          res.data.campaign.raffleInfoNameDescription,
        );
        setRaffleInfoNameEnDescription(
          res.data.campaign.raffleInfoNameEnDescription,
        );
        // 응모시 개인 정보 휴대폰 번호 입력 필요 여부
        setNeedRafflePhoneNumberYn(
          res.data.campaign.needRafflePhoneNumberYn
            ? res.data.campaign.needRafflePhoneNumberYn
            : 'N',
        );
        //응모하기 개인정보 입력부분 휴대폰 번호 설명
        setRaffleInfoPhoneNumberDescription(
          res.data.campaign.raffleInfoPhoneNumberDescription,
        );
        setRaffleInfoPhoneNumberEnDescription(
          res.data.campaign.raffleInfoPhoneNumberEnDescription,
        );
        // 응모시 개인 정보 주소 입력 필요 여부
        setNeedRaffleAddressYn(
          res.data.campaign.needRaffleAddressYn
            ? res.data.campaign.needRaffleAddressYn
            : 'N',
        );
        // 응모하기 개인정보 입력부분 주소 설명
        setRaffleInfoAddressDescription(
          res.data.campaign.raffleInfoAddressDescription,
        );
        setRaffleInfoAddressEnDescription(
          res.data.campaign.raffleInfoAddressEnDescription,
        );
        // 응모하기 이메일 주소 필요 여부
        setNeedRaffleEmailYn(
          res.data.campaign.needRaffleEmailYn
            ? res.data.campaign.needRaffleEmailYn
            : 'N',
        );
        // 응모하기 개인정보 입력부분 이메일 설명
        setRaffleInfoEmailDescription(
          res.data.campaign.raffleInfoEmailDescription,
        );
        setRaffleInfoEmailEnDescription(
          res.data.campaign.raffleInfoEmailEnDescription,
        );
        // 제세공과금안내 > 상세 텍스트
        if (res.data.campaign.raffleTaxInformation) {
          setRaffleTaxInformation(
            res.data.campaign.raffleTaxInformation.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          raffleTaxInformationNextId.current =
            res.data.campaign.raffleTaxInformation.length;
        }
        if (res.data.campaign.raffleTaxEnInformation) {
          setRaffleTaxEnInformation(
            res.data.campaign.raffleTaxEnInformation.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          raffleTaxEnInformationNextId.current =
            res.data.campaign.raffleTaxEnInformation.length;
        }

        // 교환하기 정보 입력 탭 //////////////////////////////////////////////////////
        // 교환 가격
        setExchangePrice(res.data.campaign.exchangePrice);
        // 최대 교환 인원
        setMaxExchangeCount(res.data.campaign.maxExchangeCount);
        // 현재 교환 인원
        setExchangeCount(res.data.campaign.exchangeCount);
        // 교환하기 유의사항
        if (res.data.campaign.exchangeCautions) {
          setExchangeCautions(
            res.data.campaign.exchangeCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          exchangeCautionNextId.current =
            res.data.campaign.exchangeCautions.length;
        }
        if (res.data.campaign.exchangeEnCautions) {
          setExchangeEnCautions(
            res.data.campaign.exchangeEnCautions.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          exchangeEnCautionNextId.current =
            res.data.campaign.exchangeEnCautions.length;
        }
        // 정보 입력 유의사항 설명
        if (res.data.campaign.exchangePersonalInfoFormCautions) {
          setExchangePersonalInfoFormCautions(
            res.data.campaign.exchangePersonalInfoFormCautions.map(
              (el, idx) => ({ id: idx, text: el }),
            ),
          );
          exchangePersonalInfoFormCautionNextId.current =
            res.data.campaign.exchangePersonalInfoFormCautions.length;
        }
        if (res.data.campaign.exchangePersonalInfoFormEnCautions) {
          setExchangePersonalInfoFormEnCautions(
            res.data.campaign.exchangePersonalInfoFormEnCautions.map(
              (el, idx) => ({ id: idx, text: el }),
            ),
          );
          exchangePersonalInfoFormEnCautionNextId.current =
            res.data.campaign.exchangePersonalInfoFormEnCautions.length;
        }
        // 교환하기 개인정보 작성 일자
        res.data.campaign?.exchangePersonalInfoWriteStartDate &&
          setExchangePersonalInfoWriteStartDate(
            res.data.campaign.exchangePersonalInfoWriteStartDate.slice(0, 10),
          );
        res.data.campaign?.exchangePersonalInfoWriteEndDate &&
          setExchangePersonalInfoWriteEndDate(
            res.data.campaign.exchangePersonalInfoWriteEndDate.slice(0, 10),
          );
        // 교환하기 경품 발송 시작 일자
        res.data.campaign?.exchangeGoodsDeliveryStartDate &&
          setExchangeGoodsDeliveryStartDate(
            res.data.campaign.exchangeGoodsDeliveryStartDate.slice(0, 10),
          );
        // 교환시 개인 정보 이름 입력 필요 여부
        setNeedExchangeMemberNameYn(
          res.data.campaign.needExchangeMemberNameYn
            ? res.data.campaign.needExchangeMemberNameYn
            : 'N',
        );
        // 교환하기 개인정보 입력부분 이름 설명
        setExchangeInfoNameDescription(
          res.data.campaign.exchangeInfoNameDescription,
        );
        setExchangeInfoNameEnDescription(
          res.data.campaign.exchangeInfoNameEnDescription,
        );
        // 교환시 개인 정보 휴대폰 번호 입력 필요 여부
        setNeedExchangePhoneNumberYn(
          res.data.campaign.needExchangePhoneNumberYn
            ? res.data.campaign.needExchangePhoneNumberYn
            : 'N',
        );
        //교환하기 개인정보 입력부분 휴대폰 번호 설명
        setExchangeInfoPhoneNumberDescription(
          res.data.campaign.exchangeInfoPhoneNumberDescription,
        );
        setExchangeInfoPhoneNumberEnDescription(
          res.data.campaign.exchangeInfoPhoneNumberEnDescription,
        );
        // 교환시 개인 정보 주소 입력 필요 여부
        setNeedExchangeAddressYn(
          res.data.campaign.needExchangeAddressYn
            ? res.data.campaign.needExchangeAddressYn
            : 'N',
        );
        //교환하기 개인정보 입력부분 주소 설명
        setExchangeInfoAddressDescription(
          res.data.campaign.exchangeInfoAddressDescription,
        );
        setExchangeInfoAddressEnDescription(
          res.data.campaign.exchangeInfoAddressEnDescription,
        );
        // 교환시 개인 정보 이메일 주소 입력 필요 여부
        setNeedExchangeEmailYn(
          res.data.campaign.needExchangeEmailYn
            ? res.data.campaign.needExchangeEmailYn
            : 'N',
        );
        //교환하기 개인정보 입력부분 이메일 설명
        setExchangeInfoEmailDescription(
          res.data.campaign.exchangeInfoEmailDescription,
        );
        setExchangeInfoEmailEnDescription(
          res.data.campaign.exchangeInfoEmailEnDescription,
        );
        // 제세공과금안내 > 상세 텍스트
        if (res.data.campaign.exchangeTaxInformation) {
          setExchangeTaxInformation(
            res.data.campaign.exchangeTaxInformation.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          exchangeTaxInformationNextId.current =
            res.data.campaign.exchangeTaxInformation.length;
        }
        if (res.data.campaign.exchangeTaxEnInformation) {
          setExchangeTaxEnInformation(
            res.data.campaign.exchangeTaxEnInformation.map((el, idx) => ({
              id: idx,
              text: el,
            })),
          );
          exchangeTaxEnInformationNextId.current =
            res.data.campaign.exchangeTaxEnInformation.length;
        }
      })
      .catch((reason) => {
        console.error(reason);
        alert('잠시후 다시 이용해주세요.');
      });
  };
  useEffect(() => {
      axios.get(`${CAMPAIGNS}/multilingual`).then(res => {
          setTermsUrlList(res.data.termsUrlList);
      })
    if (campaignId) {
      getDetail().then((r) => r);
    }
  }, [campaignId]);

  // 캠페인 정보 저장
  const saveCampaign = async (type) => {
    const formData = new FormData();
    const request = {};
    request.type = 'BAUND_GOLD';

    // 상위 노출
    if (upperExposureYn === 'Y' && !upperExposureSequence) {
      alert('상위 노출시 시퀀스를 입력해주세요.');
      return;
    }
    request.upperExposureYn = upperExposureYn;
    request.upperExposureSequence = upperExposureSequence;

    // 썸네일 이미지
    if (!thumbnailImgUrl && !thumbnailImgFile) {
      alert('썸네일 이미지를 입력해주세요.');
      return;
    }
    // request['thumbnailImgUrl'] = thumbnailImgUrl;
    if (thumbnailImgFile) {
      formData.append('thumbnailImg', thumbnailImgFile);
    }
    if (!thumbnailEnImgUrl && !thumbnailEnImgFile) {
      alert('썸네일 이미지(EN)를 입력해주세요.');
      return;
    }
    // request['thumbnailImgUrl'] = thumbnailImgUrl;
    if (thumbnailEnImgFile) {
      formData.append('thumbnailEnImg', thumbnailEnImgFile);
    }

    // 상세보기 상단 메인 이미지
    if (!detailUpperImgUrl && !detailUpperImgFile) {
      alert('상단 이미지를 입력해주세요.');
      return;
    }
    // request['detailUpperImgUrl'] = detailUpperImgUrl;
    if (detailUpperImgFile) {
      formData.append('detailUpperImg', detailUpperImgFile);
    }
    if (!detailUpperEnImgUrl && !detailUpperEnImgFile) {
      alert('상단 이미지(EN)를 입력해주세요.');
      return;
    }
    // request['detailUpperImgUrl'] = detailUpperImgUrl;
    if (detailUpperEnImgFile) {
      formData.append('detailUpperEnImg', detailUpperEnImgFile);
    }

    // 상품 유형
    if (!goodsType) {
      alert('상품 유형을 선택해주세요.');
      return;
    }
    request.goodsType = goodsType;

    // 캠페인 명
    if (!name) {
      alert('캠페인명을 입력해주세요.');
      return;
    }
    request.name = name;
    if (!nameEn) {
      alert('캠페인명(EN)을 입력해주세요.');
      return;
    }
    request.nameEn = nameEn;

    // 상품명
    if (!goodsName) {
      alert('상품명을 입력해주세요.');
      return;
    }
    request.goodsName = goodsName;
    if (!goodsNameEn) {
      alert('상품명(EN)을 입력해주세요.');
      return;
    }
    request.goodsNameEn = goodsNameEn;

    // 상품 브랜드명
    if (!goodsBrand) {
      alert('브랜드명을 입력해주세요.');
      return;
    }
    request.goodsBrand = goodsBrand;
    if (!goodsBrandEn) {
      alert('브랜드명(EN)을 입력해주세요.');
      return;
    }
    request.goodsBrandEn = goodsBrandEn;

    // 상품 가격
    if (!goodsPrice) {
      alert('상품 가격을 입력해주세요.');
      return;
    }
    request.goodsPrice = goodsPrice;

    // 제공 내역
    request.provideInfo = provideInfo;
    request.provideInfoEn = provideInfoEn;

    // 골드 캠페인 기간
    if (!startDate || !endDate) {
      alert('캠페인 기간를 입력해주세요.');
      return;
    }
    if (startDate > endDate) {
      alert('시작종료일시가 종료일시보다 느릴 수 없습니다.');
      return;
    }
    request.startDate = `${startDate}T00:00:00`;
    request.endDate = `${endDate}T23:59:59`;

    if (!countryType) {
      alert('국가를 선택해주세요.');
      return;
    }
    request.countryType = countryType;
    // if (!languageType) { alert('언어를 선택해주세요.'); return; }
    // request['languageType'] = languageType;

    // 비공개 여부
    request.privateYn = privateYn;

    // 경품 유의사항
    request.goodsCautions = [...goodsCautions]
      .filter((b) => b.text !== '')
      .map((b) => b.text);
    request.goodsEnCautions = [...goodsEnCautions]
      .filter((b) => b.text !== '')
      .map((b) => b.text);

    // 상세보기 이미지
    if (detailInfoImgFile != null) {
      formData.append('detailInfoImg', detailInfoImgFile);
    }
    if (detailInfoEnImgFile != null) {
      formData.append('detailInfoEnImg', detailInfoEnImgFile);
    }
    // 상세보기 유튜브
    request.youtubeTitle = youtubeTitle;
    request.youtubeTitleEn = youtubeTitleEn;
    request.youtubeDescription = youtubeDescription;
    request.youtubeDescriptionEn = youtubeDescriptionEn;
    request.youtubeUrl = youtubeUrl;
    // 상세보기 텍스트
    request.text = text;
    request.textEn = textEn;
    // 약관 동의 관련
    request.needAgreementPersonalInfoTermsYn = needAgreementPersonalInfoTermsYn;
    request.needAgreementIdentifyInfoTermsYn = needAgreementIdentifyInfoTermsYn;
    request.needAgreementMmsAdvertisementTermsYn =
      needAgreementMmsAdvertisementTermsYn;
    //참가 타입
    if (!participateType) {
      alert('참가 타입을 선택해주세요.');
      return;
    }
    request.participateType = participateType;

    // 응모하기 정보 입력 탭 //////////////////////////////////////////////////////
    if (participateType === 'ALL' || participateType === 'RAFFLE') {
      // 캠페인 응모 가격
      if (!rafflePrice) {
        alert('캠페인 응모 가격을 입력해주세요.');
        return;
      }
      request.rafflePrice = rafflePrice;
      // 최대 응모 인원
      if (!maxRaffleCount) {
        alert('최대 응모 인원을 입력해주세요.');
        return;
      }
      request.maxRaffleCount = maxRaffleCount;
      // 응모하기 유의사항
      request.raffleCautions = [...raffleCautions]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.raffleEnCautions = [...raffleEnCautions]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      // 정보 입력 유의사항 설명
      request.rafflePersonalInfoFormCautions = [
        ...rafflePersonalInfoFormCautions,
      ]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.rafflePersonalInfoFormEnCautions = [
        ...rafflePersonalInfoFormEnCautions,
      ]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      // 당첨자 발표 기간
      if (!announceWinnerStartDate || !announceWinnerEndDate) {
        alert('당첨자 발표 기간을 입력해주세요.');
        return;
      }
      if (announceWinnerStartDate > announceWinnerEndDate) {
        alert(
          '당첨자 발표 기간의 시작종료일시가 종료일시보다 느릴 수 없습니다.',
        );
        return;
      }
      request.announceWinnerStartDate = `${announceWinnerStartDate}T00:00:00`;
      request.announceWinnerEndDate = `${announceWinnerEndDate}T23:59:59`;
      // 응모하기 개인정보 작성 일자
      if (
        !rafflePersonalInfoWriteStartDate ||
        !rafflePersonalInfoWriteEndDate
      ) {
        alert('응모하기 개인정보 작성 일자을 입력해주세요.');
        return;
      }
      if (rafflePersonalInfoWriteStartDate > rafflePersonalInfoWriteEndDate) {
        alert(
          '응모하기 개인정보 작성 일자의 시작종료일시가 종료일시보다 느릴 수 없습니다.',
        );
        return;
      }
      request.rafflePersonalInfoWriteStartDate = `${rafflePersonalInfoWriteStartDate}T00:00:00`;
      request.rafflePersonalInfoWriteEndDate = `${rafflePersonalInfoWriteEndDate}T23:59:59`;
      // 경품 발송 시작 일자
      if (!raffleGoodsDeliveryStartDate) {
        alert('응모하기 경품 발송 시작 일자을 입력해주세요.');
        return;
      }
      request.raffleGoodsDeliveryStartDate = `${raffleGoodsDeliveryStartDate}T00:00:00`;
      // 응모시 개인 정보 이름 입력 필요 여부
      request.needRaffleMemberNameYn = needRaffleMemberNameYn;
      // 응모하기 개인정보 입력부분 이름 설명
      request.raffleInfoNameDescription = raffleInfoNameDescription;
      request.raffleInfoNameEnDescription = raffleInfoNameEnDescription;
      // 응모시 개인 정보 휴대폰 번호 입력 필요 여부
      request.needRafflePhoneNumberYn = needRafflePhoneNumberYn;
      //응모하기 개인정보 입력부분 휴대폰 번호 설명
      request.raffleInfoPhoneNumberDescription =
        raffleInfoPhoneNumberDescription;
      request.raffleInfoPhoneNumberEnDescription =
        raffleInfoPhoneNumberEnDescription;
      // 응모시 개인 정보 주소 입력 필요 여부
      request.needRaffleAddressYn = needRaffleAddressYn;
      //응모하기 개인정보 입력부분 주소 설명
      request.raffleInfoAddressDescription = raffleInfoAddressDescription;
      request.raffleInfoAddressEnDescription = raffleInfoAddressEnDescription;
      // 응모하기 이메일 주소 필요 여부
      request.needRaffleEmailYn = needRaffleEmailYn;
      //응모하기 개인정보 입력부분 이메일 설명
      request.raffleInfoEmailDescription = raffleInfoEmailDescription;
      request.raffleInfoEmailEnDescription = raffleInfoEmailEnDescription;
      // 제세공과금안내 > 상세 텍스트
      request.raffleTaxInformation = [...raffleTaxInformation]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.raffleTaxEnInformation = [...raffleTaxEnInformation]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
    }

    // 교환하기 정보 입력 탭 //////////////////////////////////////////////////////
    if (participateType === 'ALL' || participateType === 'EXCHANGE') {
      // 교환 가격
      if (!exchangePrice) {
        alert('교환 가격을 입력해주세요.');
        return;
      }
      request.exchangePrice = exchangePrice;
      // 최대 교환 인원
      if (!maxExchangeCount) {
        alert('최대 교환 인원을 입력해주세요.');
        return;
      }
      request.maxExchangeCount = maxExchangeCount;
      // 교환하기 유의사항
      request.exchangeCautions = [...exchangeCautions]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.exchangeEnCautions = [...exchangeEnCautions]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      // 정보 입력 유의사항 설명
      request.exchangePersonalInfoFormCautions = [
        ...exchangePersonalInfoFormCautions,
      ]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.exchangePersonalInfoFormEnCautions = [
        ...exchangePersonalInfoFormEnCautions,
      ]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      // 교환하기 개인정보 작성 일자
      if (
        !exchangePersonalInfoWriteStartDate ||
        !exchangePersonalInfoWriteEndDate
      ) {
        alert('교환하기 개인정보 작성 일자을 입력해주세요.');
        return;
      }
      if (
        exchangePersonalInfoWriteStartDate > exchangePersonalInfoWriteEndDate
      ) {
        alert(
          '교환하기 개인정보 작성 일자의 시작종료일시가 종료일시보다 느릴 수 없습니다.',
        );
        return;
      }
      request.exchangePersonalInfoWriteStartDate = `${exchangePersonalInfoWriteStartDate}T00:00:00`;
      request.exchangePersonalInfoWriteEndDate = `${exchangePersonalInfoWriteEndDate}T23:59:59`;
      // 경품 발송 시작 일자
      if (!exchangeGoodsDeliveryStartDate) {
        alert('교환하기 경품 발송 시작 일자을 입력해주세요.');
        return;
      }
      request.exchangeGoodsDeliveryStartDate = `${exchangeGoodsDeliveryStartDate}T00:00:00`;
      // 교환시 개인 정보 이름 입력 필요 여부
      request.needExchangeMemberNameYn = needExchangeMemberNameYn;
      // 교환하기 개인정보 입력부분 이름 설명
      request.exchangeInfoNameDescription = exchangeInfoNameDescription;
      request.exchangeInfoNameEnDescription = exchangeInfoNameEnDescription;
      // 교환시 개인 정보 휴대폰 번호 입력 필요 여부
      request.needExchangePhoneNumberYn = needExchangePhoneNumberYn;
      //교환하기 개인정보 입력부분 휴대폰 번호 설명
      request.exchangeInfoPhoneNumberDescription =
        exchangeInfoPhoneNumberDescription;
      request.exchangeInfoPhoneNumberEnDescription =
        exchangeInfoPhoneNumberEnDescription;
      // 교환시 개인 정보 주소 입력 필요 여부
      request.needExchangeAddressYn = needExchangeAddressYn;
      //교환하기 개인정보 입력부분 주소 설명
      request.exchangeInfoAddressDescription = exchangeInfoAddressDescription;
      request.exchangeInfoAddressEnDescription =
        exchangeInfoAddressEnDescription;
      // 교환시 개인 정보 이메일 주소 입력 필요 여부
      request.needExchangeEmailYn = needExchangeEmailYn;
      //교환하기 개인정보 입력부분 이메일 설명
      request.exchangeInfoEmailDescription = exchangeInfoEmailDescription;
      request.exchangeInfoEmailEnDescription = exchangeInfoEmailEnDescription;
      // 제세공과금안내 > 상세 텍스트
      request.exchangeTaxInformation = [...exchangeTaxInformation]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
      request.exchangeTaxEnInformation = [...exchangeTaxEnInformation]
        .filter((b) => b.text !== '')
        .map((b) => b.text);
    }
    // const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
    // formData.append('request', blob);
    Object.entries(request).forEach((el) => {
      formData.append(el[0], el[1]);
    });

    dispatch(setLoading());
    // await axios({
    //     method:(type === 'edit')? 'put':'post',
    //     url:(type === 'edit')?`${CAMPAIGNS}/${campaignId}`:`${CAMPAIGNS}`,
    //     params: request,
    //     data: formData
    // })
    // .then(() => {
    //   alert('저장 되었습니다.');
    //   navigate('/campaign/goldCampaign');
    // })
    // .catch(reason => {
    //   console.error(reason)
    //   alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    // })
    // .finally(() => {
    //   dispatch(resetLoading());
    // });
    if (type === 'edit') {
      await axios
        .put(`${CAMPAIGNS}/${campaignId}`, formData, {})
        .then(() => {
          alert('저장 되었습니다.');
          navigate('/campaign/goldCampaign');
        })
        .catch((reason) => {
          alert(
            `시스템 관리자에게 문의해 주세요.:\n${reason.response?.data?.message}`
          );
        })
        .finally(() => {
          dispatch(resetLoading());
        });
    } else {
      await axios
        .post(`${CAMPAIGNS}`, formData, {})
        .then(() => {
          alert('저장 되었습니다.');
          navigate('/campaign/goldCampaign');
        })
        .catch((reason) => {
          alert(
            `시스템 관리자에게 문의해 주세요.:${reason}`
          );
        })
        .finally(() => {
          dispatch(resetLoading());
        });
    }
  };

  // 유의사항 공통 //////////////////////////////////////////////////////////////
  const onAddText = (nextId, text, data, setter) => {
    setter([...data, { id: nextId.current, text }]);
    nextId.current += 1;
  };
  const onEditText = (id, text, data, setter) => {
    setter(data.map((el) => (el.id === id ? { ...el, text } : el)));
  };
  const onRemoveText = (id, data, setter) => {
    setter(data.filter((el) => el.id !== id));
  };

  // 경품 유의사항 //////////////////////////////////////////////////////////////
  const onAddGoodsCautionText = (text) => {
    onAddText(goodsCautionNextId, text, goodsCautions, setGoodsCautions);
  };
  const onEditGoodsCautionText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, goodsCautions, setGoodsCautions);
    },
    [goodsCautions],
  );
  const onRemoveGoodsCautionText = (cautionId) => {
    onRemoveText(cautionId, goodsCautions, setGoodsCautions);
  };
  const onAddGoodsEnCautionText = (text) => {
    onAddText(goodsEnCautionNextId, text, goodsEnCautions, setGoodsEnCautions);
  };
  const onEditGoodsEnCautionText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, goodsEnCautions, setGoodsEnCautions);
    },
    [goodsEnCautions],
  );
  const onRemoveGoodsEnCautionText = (cautionId) => {
    onRemoveText(cautionId, goodsEnCautions, setGoodsEnCautions);
  };

  // 응모하기 유의사항 //////////////////////////////////////////////////////////
  const onAddRaffleCautionText = (text) => {
    onAddText(raffleCautionNextId, text, raffleCautions, setRaffleCautions);
  };
  const onEditRaffleCationText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, raffleCautions, setRaffleCautions);
    },
    [raffleCautions],
  );
  const onRemoveRaffleCautionText = (cautionId) => {
    onRemoveText(cautionId, raffleCautions, setRaffleCautions);
  };
  const onAddRaffleEnCautionText = (text) => {
    onAddText(
      raffleEnCautionNextId,
      text,
      raffleEnCautions,
      setRaffleEnCautions,
    );
  };
  const onEditRaffleEnCationText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, raffleEnCautions, setRaffleEnCautions);
    },
    [raffleEnCautions],
  );
  const onRemoveRaffleEnCautionText = (cautionId) => {
    onRemoveText(cautionId, raffleEnCautions, setRaffleEnCautions);
  };

  // 응모하기 정보 입력 유의사항 ////////////////////////////////////////////////
  const onAddRafflePersonalInfoFormCautionText = (text) => {
    onAddText(
      rafflePersonalInfoFormCautionNextId,
      text,
      rafflePersonalInfoFormCautions,
      setRafflePersonalInfoFormCautions,
    );
  };
  const onEditRafflePersonalInfoFormCautionText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        rafflePersonalInfoFormCautions,
        setRafflePersonalInfoFormCautions,
      );
    },
    [rafflePersonalInfoFormCautions],
  );
  const onRemoveRafflePersonalInfoFormCautionText = (cautionId) => {
    onRemoveText(
      cautionId,
      rafflePersonalInfoFormCautions,
      setRafflePersonalInfoFormCautions,
    );
  };
  const onAddRafflePersonalInfoFormEnCautionText = (text) => {
    onAddText(
      rafflePersonalInfoFormEnCautionNextId,
      text,
      rafflePersonalInfoFormEnCautions,
      setRafflePersonalInfoFormEnCautions,
    );
  };
  const onEditRafflePersonalInfoFormEnCautionText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        rafflePersonalInfoFormEnCautions,
        setRafflePersonalInfoFormEnCautions,
      );
    },
    [rafflePersonalInfoFormEnCautions],
  );
  const onRemoveRafflePersonalInfoFormEnCautionText = (cautionId) => {
    onRemoveText(
      cautionId,
      rafflePersonalInfoFormEnCautions,
      setRafflePersonalInfoFormEnCautions,
    );
  };

  // 응모하기 제세 공과금 안내 //////////////////////////////////////////////////
  const onAddRaffleTaxInformationText = (text) => {
    onAddText(
      raffleTaxInformationNextId,
      text,
      raffleTaxInformation,
      setRaffleTaxInformation,
    );
  };
  const onEditRaffleTaxInformationText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        raffleTaxInformation,
        setRaffleTaxInformation,
      );
    },
    [raffleTaxInformation],
  );
  const onRemoveRaffleTaxInformationText = (cautionId) => {
    onRemoveText(cautionId, raffleTaxInformation, setRaffleTaxInformation);
  };
  const onAddRaffleTaxEnInformationText = (text) => {
    onAddText(
      raffleTaxEnInformationNextId,
      text,
      raffleTaxEnInformation,
      setRaffleTaxEnInformation,
    );
  };
  const onEditRaffleTaxEnInformationText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        raffleTaxEnInformation,
        setRaffleTaxEnInformation,
      );
    },
    [raffleTaxEnInformation],
  );
  const onRemoveRaffleTaxEnInformationText = (cautionId) => {
    onRemoveText(cautionId, raffleTaxEnInformation, setRaffleTaxEnInformation);
  };

  // 교환하기 유의사항 //////////////////////////////////////////////////////////
  const onAddExchangeCautionText = (text) => {
    onAddText(
      exchangeCautionNextId,
      text,
      exchangeCautions,
      setExchangeCautions,
    );
  };
  const onEditExchangeCationText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, exchangeCautions, setExchangeCautions);
    },
    [exchangeCautions],
  );
  const onRemoveExchangeCautionText = (cautionId) => {
    onRemoveText(cautionId, exchangeCautions, setExchangeCautions);
  };
  const onAddExchangeEnCautionText = (text) => {
    onAddText(
      exchangeEnCautionNextId,
      text,
      exchangeEnCautions,
      setExchangeEnCautions,
    );
  };
  const onEditExchangeEnCationText = useCallback(
    (cautionId, text) => {
      onEditText(cautionId, text, exchangeEnCautions, setExchangeEnCautions);
    },
    [exchangeEnCautions],
  );
  const onRemoveExchangeEnCautionText = (cautionId) => {
    onRemoveText(cautionId, exchangeEnCautions, setExchangeEnCautions);
  };

  // 교환하기 정보 입력 유의사항 ////////////////////////////////////////////////
  const onAddExchangePersonalInfoFormCautionText = (text) => {
    onAddText(
      exchangePersonalInfoFormCautionNextId,
      text,
      exchangePersonalInfoFormCautions,
      setExchangePersonalInfoFormCautions,
    );
  };
  const onEditExchangePersonalInfoFormCautionText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        exchangePersonalInfoFormCautions,
        setExchangePersonalInfoFormCautions,
      );
    },
    [exchangePersonalInfoFormCautions],
  );
  const onRemoveExchangePersonalInfoFormCautionText = (cautionId) => {
    onRemoveText(
      cautionId,
      exchangePersonalInfoFormCautions,
      setExchangePersonalInfoFormCautions,
    );
  };
  const onAddExchangePersonalInfoFormEnCautionText = (text) => {
    onAddText(
      exchangePersonalInfoFormEnCautionNextId,
      text,
      exchangePersonalInfoFormEnCautions,
      setExchangePersonalInfoFormEnCautions,
    );
  };
  const onEditExchangePersonalInfoFormEnCautionText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        exchangePersonalInfoFormEnCautions,
        setExchangePersonalInfoFormEnCautions,
      );
    },
    [exchangePersonalInfoFormEnCautions],
  );
  const onRemoveExchangePersonalInfoFormEnCautionText = (cautionId) => {
    onRemoveText(
      cautionId,
      exchangePersonalInfoFormEnCautions,
      setExchangePersonalInfoFormEnCautions,
    );
  };

  // 교환하기 제세 공과금 안내 //////////////////////////////////////////////////
  const onAddExchangeTaxInformationText = (text) => {
    onAddText(
      exchangeTaxInformationNextId,
      text,
      exchangeTaxInformation,
      setExchangeTaxInformation,
    );
  };
  const onEditExchangeTaxInformationText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        exchangeTaxInformation,
        setExchangeTaxInformation,
      );
    },
    [exchangeTaxInformation],
  );
  const onRemoveExchangeTaxInformationText = (cautionId) => {
    onRemoveText(cautionId, exchangeTaxInformation, setExchangeTaxInformation);
  };
  const onAddExchangeTaxEnInformationText = (text) => {
    onAddText(
      exchangeTaxEnInformationNextId,
      text,
      exchangeTaxEnInformation,
      setExchangeTaxEnInformation,
    );
  };
  const onEditExchangeTaxEnInformationText = useCallback(
    (cautionId, text) => {
      onEditText(
        cautionId,
        text,
        exchangeTaxEnInformation,
        setExchangeTaxEnInformation,
      );
    },
    [exchangeTaxEnInformation],
  );
  const onRemoveExchangeTaxEnInformationText = (cautionId) => {
    onRemoveText(
      cautionId,
      exchangeTaxEnInformation,
      setExchangeTaxEnInformation,
    );
  };

  const personalInfoTermsUrls = termsUrlList.map((termsUrl) => {
    if (!termsUrl.personalInfoTermsUrl) return;
    const { languageType } = termsUrl;
    const result = [];
    result.push(
      <TextField
        key={`personalInfoTermsUrl${languageType}`}
        label={`개인 정보 처리 동의 ${
          languageType === 'ko' ? '한글' : '영문'
        } 약관 주소`}
        type="clickButton"
        text="클릭하면 조회 페이지가 생성됩니다"
        onClick={() => {
          window.open(termsUrl.personalInfoTermsUrl);
        }}
      />,
      <br />,
    );
    return result;
  });

  const identifyInfoTermsUrls = termsUrlList.map((termsUrl) => {
    if (!termsUrl.identifyInfoTermsUrl) return;
    const { languageType } = termsUrl;
    const result = [];
    result.push(
      <TextField
        key={`identifyInfoTermsUrl${languageType}`}
        label={`고유 식별 정보 처리 동의 ${
          languageType === 'ko' ? '한글' : '영문'
        } 약관 주소`}
        type="clickButton"
        text="클릭하면 조회 페이지가 생성됩니다"
        onClick={() => {
          window.open(termsUrl.identifyInfoTermsUrl);
        }}
      />,
      <br />,
    );
    return result;
  });

  const mmsAdvertisementTermsUrls = termsUrlList.map((termsUrl) => {
    if (!termsUrl.mmsAdvertisementTermsUrl) return;
    const { languageType } = termsUrl;
    const result = [];
    result.push(
      <TextField
        key={`mmsAdvertisementTermsUrl${languageType}`}
        label={`MMS 광고성 정보 수신 동의 ${
          languageType === 'ko' ? '한글' : '영문'
        } 약관 주소`}
        type="clickButton"
        text="클릭하면 조회 페이지가 생성됩니다"
        onClick={() => {
          window.open(termsUrl.mmsAdvertisementTermsUrl);
        }}
      />,
      <br />,
    );
    return result;
  });

  //한글 입력시 영문 동시 입력
  useEffect(() => {
    if (copyKR2EN) setNameEn(name);
  }, [name]);
  useEffect(() => {
    if (copyKR2EN) setGoodsNameEn(goodsName);
  }, [goodsName]);
  useEffect(() => {
    if (copyKR2EN) setGoodsBrandEn(goodsBrand);
  }, [goodsBrand]);
  useEffect(() => {
    if (copyKR2EN) setProvideInfoEn(provideInfo);
  }, [provideInfo]);
  useEffect(() => {
    if (copyKR2EN) setGoodsEnCautions(goodsCautions);
  }, [goodsCautions]);
  useEffect(() => {
    if (copyKR2EN) setYoutubeTitleEn(youtubeTitle);
  }, [youtubeTitle]);
  useEffect(() => {
    if (copyKR2EN) setYoutubeDescriptionEn(youtubeDescription);
  }, [youtubeDescription]);
  useEffect(() => {
    if (copyKR2EN) setTextEn(text);
  }, [text]);
  useEffect(() => {
    if (copyKR2EN) setRaffleEnCautions(raffleCautions);
  }, [raffleCautions]);
  useEffect(() => {
    if (copyKR2EN)
      setRafflePersonalInfoFormEnCautions(rafflePersonalInfoFormCautions);
  }, [rafflePersonalInfoFormCautions]);
  useEffect(() => {
    if (copyKR2EN) setRaffleInfoNameEnDescription(raffleInfoNameDescription);
  }, [raffleInfoNameDescription]);
  useEffect(() => {
    if (copyKR2EN)
      setRaffleInfoPhoneNumberEnDescription(raffleInfoPhoneNumberDescription);
  }, [raffleInfoPhoneNumberDescription]);
  useEffect(() => {
    if (copyKR2EN)
      setRaffleInfoAddressEnDescription(raffleInfoAddressDescription);
  }, [raffleInfoAddressDescription]);
  useEffect(() => {
    if (copyKR2EN) setRaffleInfoEmailEnDescription(raffleInfoEmailDescription);
  }, [raffleInfoEmailDescription]);
  useEffect(() => {
    if (copyKR2EN) setRaffleTaxEnInformation(raffleTaxInformation);
  }, [raffleTaxInformation]);
  useEffect(() => {
    if (copyKR2EN) setExchangeEnCautions(exchangeCautions);
  }, [exchangeCautions]);
  useEffect(() => {
    if (copyKR2EN)
      setExchangePersonalInfoFormEnCautions(exchangePersonalInfoFormCautions);
  }, [exchangePersonalInfoFormCautions]);
  useEffect(() => {
    if (copyKR2EN)
      setExchangeInfoNameEnDescription(exchangeInfoNameDescription);
  }, [exchangeInfoNameDescription]);
  useEffect(() => {
    if (copyKR2EN)
      setExchangeInfoPhoneNumberEnDescription(
        exchangeInfoPhoneNumberDescription,
      );
  }, [exchangeInfoPhoneNumberDescription]);
  useEffect(() => {
    if (copyKR2EN)
      setExchangeInfoAddressEnDescription(exchangeInfoAddressDescription);
  }, [exchangeInfoAddressDescription]);
  useEffect(() => {
    if (copyKR2EN)
      setExchangeInfoEmailEnDescription(exchangeInfoEmailDescription);
  }, [exchangeInfoEmailDescription]);
  useEffect(() => {
    if (copyKR2EN) setExchangeTaxEnInformation(exchangeTaxInformation);
  }, [exchangeTaxInformation]);

  // useEffect(()=> { if(copyKR2EN) setThumbnailEnImgFile(thumbnailImgFile); }, [thumbnailImgFile]);
  // useEffect(()=> { if(copyKR2EN) setThumbnailEnImgUrl(thumbnailImgUrl); }, [thumbnailImgUrl]);
  // useEffect(()=> { if(copyKR2EN) setDetailUpperEnImgFile(detailUpperImgFile); }, [detailUpperImgFile]);
  // useEffect(()=> { if(copyKR2EN) setDetailUpperEnImgUrl(detailUpperImgUrl); }, [detailUpperImgUrl]);
  // useEffect(()=> { if(copyKR2EN) setDetailInfoEnImgFile(detailInfoImgFile); }, [detailInfoImgFile]);
  // useEffect(()=> { if(copyKR2EN) setDetailInfoEnImgUrl(detailInfoImgUrl); }, [detailInfoImgUrl]);

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
          <NormalButton
            text={campaignId ? '수정하기' : '저장하기'}
            onSubmit={() =>
              saveCampaign(campaignId ? 'edit' : 'add').then(() =>
                dispatch(resetLoading()),
              )
            }
          />
        </ButtonWrapper>
      </TopContainer>
      <TitleText>골드 캠페인 정보</TitleText>
      <TextField
        label='주의사항'
        text='캠페인 수정은 교환/응모 한 멤버가 없는 경우에만 가능합니다. 만약 수정이 필요하다면 담당자에게 문의 해 주세요.'
      ></TextField>
      <MainContainer>
        <InputWrapper span={3}>
          <CheckBoxInput
            name="copyKR2EN"
            text="한글 / 영문 동일값 사용"
            checked={copyKR2EN}
            onChange={setCopyKR2EN}
          />
        </InputWrapper>

        {/*상위 노출 여부*/}
        <InputWrapperContainer span={6} className="area1">
          <InputWrapper span={upperExposureYn ? 2 : 4} mt={-15}>
            <CheckBoxInput
              name="upperExposureYn"
              text="상위 노출"
              type="yn"
              checked={upperExposureYn}
              onChange={setUpperExposureYn}
            />
          </InputWrapper>
          {upperExposureYn === 'Y' && (
            <>
              {/* 상위 노출시 시퀀스 */}
              <InputWrapper span={2}>
                <LabelTextInput
                  name="sequence"
                  label="상위 노출시 시퀀스"
                  type="priceInput"
                  Required
                  value={upperExposureSequence}
                  onChange={(e) => setUpperExposureSequence(e.target.value)}
                />
              </InputWrapper>
            </>
          )}
        </InputWrapperContainer>

        {/*썸네일 이미지 */}
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="썸네일 이미지"
              name="thumbnailImgUrl"
              placeholder={
                thumbnailImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={thumbnailImgFile}
              setFile={(e) => setThumbnailImgFile(e)}
              setImgUrl={setThumbnailImgUrl}
              Required
            />
            <ImgWrapper>
              <ImageTag src={thumbnailImgUrl} />
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="썸네일 이미지(EN)"
              name="thumbnailEnImgUrl"
              placeholder={
                thumbnailEnImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={thumbnailEnImgFile}
              setFile={(e) => setThumbnailEnImgFile(e)}
              setImgUrl={setThumbnailEnImgUrl}
              Required
            />
            <ImgWrapper>
              <ImageTag src={thumbnailEnImgUrl} />
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>

        {/*상세보기 상단 메인 이미지 */}
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="상세 상단 이미지"
              name="detailUpperImgUrl"
              placeholder={
                detailUpperImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={detailUpperImgFile}
              setFile={(e) => setDetailUpperImgFile(e)}
              setImgUrl={setDetailUpperImgUrl}
              Required
            />
            <ImgWrapper>
              <ImageTag src={detailUpperImgUrl} />
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="상세 상단 이미지(EN)"
              name="detailUpperEnImgUrl"
              placeholder={
                detailUpperEnImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={detailUpperEnImgFile}
              setFile={(e) => setDetailUpperEnImgFile(e)}
              setImgUrl={setDetailUpperEnImgUrl}
              Required
            />
            <ImgWrapper>
              <ImageTag src={detailUpperEnImgUrl} />
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>

        {/*상품 유형:기프티콘/티켓/배송 선택*/}
        <InputWrapper span={6}>
          <MultipleRadioButton
            label="상품 유형"
            name="goodsType"
            Required
            value={goodsType}
            options={campaignOptions}
            onChange={(e) => setGoodsType(e.target.value)}
          />
        </InputWrapper>

        {/*캠페인 명*/}
        <InputWrapper span={3}>
          <LabelTextInput
            name="name"
            label="캠페인 명"
            placeholder="캠페인 명을 입력해주세요."
            Required
            value={name}
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="nameEn"
            label="캠페인 명(EN)"
            placeholder="캠페인 명(EN)을 입력해주세요."
            Required
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>

        {/*상품명*/}
        <InputWrapper span={3}>
          <LabelTextInput
            name="goodsName"
            label="상품명"
            placeholder="상품명을 입력해주세요."
            Required
            value={goodsName}
            onChange={(e) => setGoodsName(e.target.value)}
            onReset={() => setGoodsName('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="goodsNameEn"
            label="상품명(EN)"
            placeholder="상품명(EN)을 입력해주세요."
            Required
            value={goodsNameEn}
            onChange={(e) => setGoodsNameEn(e.target.value)}
            onReset={() => setGoodsNameEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>

        {/*상품 브랜드명*/}
        <InputWrapper span={3}>
          <LabelTextInput
            name="goodsBrand"
            label="캠페인 경품 브랜드명"
            placeholder="캠페인 경품 브랜드명을 입력해주세요."
            Required
            value={goodsBrand}
            onChange={(e) => setGoodsBrand(e.target.value)}
            onReset={() => setGoodsBrand('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="goodsBrandEn"
            label="캠페인 경품 브랜드명(EN)"
            placeholder="캠페인 경품 브랜드명(EN)을 입력해주세요."
            Required
            value={goodsBrandEn}
            onChange={(e) => setGoodsBrandEn(e.target.value)}
            onReset={() => setGoodsBrandEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>

        {/*상품 가격*/}
        <InputWrapper span={6} mb={10}>
          <LabelTextInput
            name="goodsPrice"
            label="상품 가격"
            placeholder="상품 가격을 입력해주세요."
            Required
            value={goodsPrice}
            onChange={(e) => setGoodsPrice(e.target.value)}
          />
        </InputWrapper>

        {/*제공 내역*/}
        <InputWrapper span={3}>
          <LabelTextInput
            name="provideInfo"
            label="제공 내역"
            placeholder="제공 내역을 입력해주세요."
            value={provideInfo}
            onChange={(e) => setProvideInfo(e.target.value)}
            onReset={() => setProvideInfo('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="provideInfoEn"
            label="제공 내역(EN)"
            placeholder="제공 내역(EN)을 입력해주세요."
            value={provideInfoEn}
            onChange={(e) => setProvideInfoEn(e.target.value)}
            onReset={() => setProvideInfoEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>

        {/*골드 캠페인 기간*/}
        <InputWrapper span={6}>
          <DateWrapper>
            <DateInput
              name="startDate"
              label="캠페인 시작일(입력한 날짜의 00:00:00부터 시작됩니다.)"
              type="date"
              Required
              initialStartDate={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <DateInput
              name="endDate"
              label="캠페인 종료일(입력한 날짜의 23:59:59에 종료됩니다.)"
              type="date"
              Required
              initialStartDate={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </DateWrapper>
        </InputWrapper>

        {/*국가*/}
        <InputWrapper span={3}>
          <SelectModule
            name="countryCode"
            placeholder="국가를 선택해주세요."
            label="국가"
            value={GetNotContainsAllCountryOption[countryType]}
            onChange={(e) => setCountryType(e.value)}
            options={notContainsAllCountryOptions}
            Required
          />
        </InputWrapper>

        {/*언어*/}
        {/*<InputWrapper span={3}>*/}
        {/*  <SelectModule*/}
        {/*      name="language" placeholder="언어 코드를 선택해주세요." label="언어 코드"*/}
        {/*      value={GetLanguageOption[languageType]} onChange={e => setLanguageType(e.value)} options={languageOptions}*/}
        {/*      Required*/}
        {/*  />*/}
        {/*</InputWrapper>*/}

        {/*공개 여부*/}
        <InputWrapper span={6}>
          <RadioButton
            name="privateYn"
            label="공개 여부"
            type="privateYn"
            value={privateYn}
            onChange={(e) => setPrivateYn(e.target.value)}
            Required
          />
        </InputWrapper>

        {/*경품 유의사항*/}
        <InputWrapper span={6}>
          <LabelPlusButtonInput
            name="goodsCautions"
            label="경품 유의사항"
            value={goodsCautions}
            onClick={onAddGoodsCautionText}
            onEdit={onEditGoodsCautionText}
            onRemove={onRemoveGoodsCautionText}
            placeholder="경품 유의사항을 입력해 주세요."
          />
        </InputWrapper>
        <InputWrapper span={6}>
          <LabelPlusButtonInput
            name="goodsEnCautions"
            label="경품 유의사항(EN)"
            value={goodsEnCautions}
            onClick={onAddGoodsEnCautionText}
            onEdit={onEditGoodsEnCautionText}
            onRemove={onRemoveGoodsEnCautionText}
            readOnly={copyKR2EN}
            placeholder="경품 유의사항(EN)을 입력해 주세요."
          />
        </InputWrapper>

        {/*상세보기 이미지 */}
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="상세 이미지"
              name="detailInfoImgFile"
              placeholder={
                detailInfoImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={detailInfoImgFile}
              setFile={(e) => setDetailInfoImgFile(e)}
              setImgUrl={setDetailInfoImgUrl}
            />
            <ImgWrapper>
              {detailInfoImgUrl ? (
                <ImageTag mt={30} src={detailInfoImgUrl} />
              ) : (
                <ImageTag />
              )}
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>
        <InputWrapper span={3}>
          <VerticalFileWrapper>
            <FileInput
              type="coverImg"
              label="상세 이미지(EN)"
              name="detailInfoEnImgFile"
              placeholder={
                detailInfoEnImgUrl
                  ? '이미지가 등록되어 있습니다.'
                  : '이미지를 등록해 주세요.'
              }
              value={detailInfoEnImgFile}
              setFile={(e) => setDetailInfoEnImgFile(e)}
              setImgUrl={setDetailInfoEnImgUrl}
            />
            <ImgWrapper>
              {detailInfoEnImgUrl ? (
                <ImageTag mt={30} src={detailInfoEnImgUrl} />
              ) : (
                <ImageTag />
              )}
            </ImgWrapper>
          </VerticalFileWrapper>
        </InputWrapper>

        {/*상세보기 텍스트 */}
        <InputWrapper span={3}>
          <LabelTextAreaInput
            name="text"
            label="상세보기 텍스트"
            placeholder="상세보기 텍스트를 입력해 주세요."
            value={text}
            setValue={(e) => setText(e.target.value)}
            onReset={() => setText('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextAreaInput
            name="textEn"
            label="상세보기 텍스트(EN)"
            placeholder="상세보기 텍스트(EN)를 입력해 주세요."
            value={textEn}
            setValue={(e) => setTextEn(e.target.value)}
            onReset={() => setTextEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>

        {/*상세보기 유튜브*/}
        <InputWrapper span={6}>
          <LabelTextInput
            name="youtubeUrl"
            label="상세보기 유튜브 주소"
            placeholder="상세보기 유튜브 주소를 입력해 주세요."
            value={youtubeUrl}
            onChange={(e) => setYoutubeUrl(e.target.value)}
            onReset={() => setYoutubeUrl('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="youtubeTitle"
            label="상세보기 유튜브 제목"
            placeholder="상세보기 유튜브 제목을 입력해 주세요."
            value={youtubeTitle}
            onChange={(e) => setYoutubeTitle(e.target.value)}
            onReset={() => setYoutubeTitle('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextInput
            name="youtubeTitleEn"
            label="상세보기 유튜브 제목(EN)"
            placeholder="상세보기 유튜브 제목(EN)을 입력해 주세요."
            value={youtubeTitleEn}
            onChange={(e) => setYoutubeTitleEn(e.target.value)}
            onReset={() => setYoutubeTitleEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextAreaInput
            name="youtubeDescription"
            label="상세보기 유튜브 설명"
            placeholder="상세보기 유튜브 설명을 입력해 주세요."
            value={youtubeDescription}
            setValue={(e) => setYoutubeDescription(e.target.value)}
            onReset={() => setYoutubeDescription('')}
          />
        </InputWrapper>
        <InputWrapper span={3}>
          <LabelTextAreaInput
            name="youtubeDescriptionEn"
            label="상세보기 유튜브 설명(EN)"
            placeholder="상세보기 유튜브 설명(EN)을 입력해 주세요."
            value={youtubeDescriptionEn}
            setValue={(e) => setYoutubeDescriptionEn(e.target.value)}
            onReset={() => setYoutubeDescriptionEn('')}
            readOnly={copyKR2EN}
          />
        </InputWrapper>
        {/* 약관 동의 여부 */}
        <InputWrapper span={2}>
          <>
            <CheckBoxInput
              name="needAgreementPersonalInfoTermsYn"
              checked={needAgreementPersonalInfoTermsYn}
              text="개인 정보 처리 동의 약관 노출 여부(항시 필수 노출 값 입니다)"
              Required
              disabled
            />
            <br />
            {/* 개인 정보 처리 동의 관련 언어별 양식 주소 */}
            {personalInfoTermsUrls}
          </>
        </InputWrapper>
        <InputWrapper span={2}>
          <>
            <CheckBoxInput
              name="needAgreementIdentifyInfoTermsYn"
              text="고유 식별 정보 동의 약관 노출 여부 (노출시 당첨/교환 정보 입력시 필수로 동의 해야하는 사항으로 간주됩니다)"
              type="yn"
              checked={needAgreementIdentifyInfoTermsYn}
              onChange={setNeedAgreementIdentifyInfoTermsYn}
            />
            <br />
            {/* 고유 식별 정보 처리 관련 언어별 양식 주소 */}
            {identifyInfoTermsUrls}
          </>
        </InputWrapper>
        <InputWrapper span={2}>
          <>
            <CheckBoxInput
              name="needAgreementMmsAdvertisementTermsYn"
              text="MMS 광고 동의 약관 노출 여부"
              type="yn"
              checked={needAgreementMmsAdvertisementTermsYn}
              onChange={setNeedAgreementMmsAdvertisementTermsYn}
            />
            <br />
            {/* MMS 광고성 정보 수신 동의 관련 언어별 양식 주소 */}
            {mmsAdvertisementTermsUrls}
          </>
        </InputWrapper>
        {/*참가 타입:전체/응모/교환*/}
        <InputWrapper span={6}>
          <MultipleRadioButton
            label="참가 타입"
            name="participateType"
            Required
            value={participateType}
            options={campaignParticipateOptions}
            onChange={(e) => setParticipateType(e.target.value)}
          />
        </InputWrapper>

        {/* 응모 하기 정보 입력 영역 START -------------------------------------*/}
        {(participateType === 'ALL' || participateType === 'RAFFLE') && (
          <InputWrapperContainer span={6} className="area1">
            <InputWrapper span={3} mt={10}>
              <TitleText>응모 관련 정보</TitleText>
            </InputWrapper>

            {/* 응모 희망자 확인 모달 열기 */}
            <InputWrapper span={1}>
              <VideoInput
                onClick={() => setApplicantModalType('RAFFLE')}
                text="응모 희망자 확인"
              />
            </InputWrapper>

            {/*캠페인 응모 가격 */}
            <InputWrapper span={2} mt={10}>
              <LabelTextInput
                name="rafflePrice"
                label="캠페인 응모 가격"
                type="priceInput"
                Required
                value={rafflePrice}
                onChange={(e) => setRafflePrice(e.target.value)}
              />
            </InputWrapper>

            {/*현재 응모 인원 */}
            <InputWrapper span={1} mt={10}>
              <TextField label="현재 응모 인원" text={raffleCount} />
            </InputWrapper>
            {/*최대 응모 인원 */}
            <InputWrapper span={1} mt={10}>
              <LabelTextInput
                name="maxRaffleCount"
                label="당첨 인원"
                type="priceInput"
                Required
                value={maxRaffleCount}
                onChange={(e) => setMaxRaffleCount(e.target.value)}
              />
            </InputWrapper>

            {/*응모하기 유의사항 */}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="raffleCautions"
                label="응모하기 유의사항"
                value={raffleCautions}
                onClick={onAddRaffleCautionText}
                onEdit={onEditRaffleCationText}
                onRemove={onRemoveRaffleCautionText}
                placeholder="응모하기 유의사항을 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="raffleEnCautions"
                label="응모하기 유의사항(EN)"
                value={raffleEnCautions}
                onClick={onAddRaffleEnCautionText}
                onEdit={onEditRaffleEnCationText}
                onRemove={onRemoveRaffleEnCautionText}
                readOnly={copyKR2EN}
                placeholder="응모하기 유의사항(EN)을 입력해 주세요."
              />
            </InputWrapper>

            {/*응모하기 개인정보 입력 유의사항 설명 */}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="rafflePersonalInfoFormCautions"
                label="개인정보 입력 유의사항"
                value={rafflePersonalInfoFormCautions}
                onClick={onAddRafflePersonalInfoFormCautionText}
                onEdit={onEditRafflePersonalInfoFormCautionText}
                onRemove={onRemoveRafflePersonalInfoFormCautionText}
                placeholder="개인정보 입력 유의사항을 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="rafflePersonalInfoFormEnCautions"
                label="개인정보 입력 유의사항(EN)"
                value={rafflePersonalInfoFormEnCautions}
                onClick={onAddRafflePersonalInfoFormEnCautionText}
                onEdit={onEditRafflePersonalInfoFormEnCautionText}
                onRemove={onRemoveRafflePersonalInfoFormEnCautionText}
                readOnly={copyKR2EN}
                placeholder="개인정보 입력 유의사항(EN)을 입력해 주세요."
              />
            </InputWrapper>

            {/*당첨자 발표 기간*/}
            <InputWrapper span={4} mt={10}>
              <DateWrapper>
                <DateInput
                  name="announceWinnerStartDate"
                  label="당첨자 발표 시작일"
                  type="date"
                  Required
                  initialStartDate={announceWinnerStartDate}
                  onChange={(e) => setAnnounceWinnerStartDate(e.target.value)}
                />
                <DateInput
                  name="announceWinnerEndDate"
                  label="당첨자 발표 종료일"
                  type="date"
                  Required
                  initialStartDate={announceWinnerEndDate}
                  onChange={(e) => setAnnounceWinnerEndDate(e.target.value)}
                />
              </DateWrapper>
            </InputWrapper>

            {/*응모하기 개인정보 작성 일자*/}
            <InputWrapper span={4} mt={10}>
              <DateWrapper>
                <DateInput
                  name="rafflePersonalInfoWriteStartDate"
                  label="개인정보 작성 시작일"
                  type="date"
                  Required
                  initialStartDate={rafflePersonalInfoWriteStartDate}
                  onChange={(e) =>
                    setRafflePersonalInfoWriteStartDate(e.target.value)
                  }
                />
                <DateInput
                  name="rafflePersonalInfoWriteEndDate"
                  label="개인정보 작성 종료일"
                  type="date"
                  Required
                  initialStartDate={rafflePersonalInfoWriteEndDate}
                  onChange={(e) =>
                    setRafflePersonalInfoWriteEndDate(e.target.value)
                  }
                />
              </DateWrapper>
            </InputWrapper>

            {/*경품 발송 시작 일자*/}
            <InputWrapper span={4} mt={10}>
              <DateInput
                name="raffleGoodsDeliveryStartDate"
                label="경품 발송 시작일"
                type="date"
                Required
                initialStartDate={raffleGoodsDeliveryStartDate}
                onChange={(e) =>
                  setRaffleGoodsDeliveryStartDate(e.target.value)
                }
              />
            </InputWrapper>

            {/*응모시 개인 정보 이름 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needRaffleMemberNameYn"
                text="이름 입력 필요 여부"
                type="yn"
                checked={needRaffleMemberNameYn}
                onChange={setNeedRaffleMemberNameYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="raffleInfoNameDescription"
                placeholder="개인정보 입력부분 이름 설명을 입력해 주세요."
                value={raffleInfoNameDescription}
                onChange={(e) => setRaffleInfoNameDescription(e.target.value)}
                onReset={() => setRaffleInfoNameDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="raffleInfoNameEnDescription"
                placeholder="개인정보 입력부분 이름 설명(EN)을 입력해 주세요."
                value={raffleInfoNameEnDescription}
                onChange={(e) => setRaffleInfoNameEnDescription(e.target.value)}
                onReset={() => setRaffleInfoNameEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*응모시 개인 정보 휴대폰 번호 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needRafflePhoneNumberYn"
                text="휴대폰 번호 입력 필요 여부"
                type="yn"
                checked={needRafflePhoneNumberYn}
                onChange={setNeedRafflePhoneNumberYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="raffleInfoPhoneNumberDescription"
                placeholder="개인정보 입력부분 휴대폰 번호 설명을 입력해 주세요."
                value={raffleInfoPhoneNumberDescription}
                onChange={(e) =>
                  setRaffleInfoPhoneNumberDescription(e.target.value)
                }
                onReset={() => setRaffleInfoPhoneNumberDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="raffleInfoPhoneNumberEnDescription"
                placeholder="개인정보 입력부분 휴대폰 번호 설명(EN)을 입력해 주세요."
                value={raffleInfoPhoneNumberEnDescription}
                onChange={(e) =>
                  setRaffleInfoPhoneNumberEnDescription(e.target.value)
                }
                onReset={() => setRaffleInfoPhoneNumberEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*응모시 개인 정보 주소 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needRaffleAddressYn"
                text="주소 입력 필요 여부"
                type="yn"
                checked={needRaffleAddressYn}
                onChange={setNeedRaffleAddressYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="raffleInfoAddressDescription"
                placeholder="개인정보 입력부분 주소 설명을 입력해 주세요."
                value={raffleInfoAddressDescription}
                onChange={(e) =>
                  setRaffleInfoAddressDescription(e.target.value)
                }
                onReset={() => setRaffleInfoAddressDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="raffleInfoAddressEnDescription"
                placeholder="개인정보 입력부분 주소 설명(EN)을 입력해 주세요."
                value={raffleInfoAddressEnDescription}
                onChange={(e) =>
                  setRaffleInfoAddressEnDescription(e.target.value)
                }
                onReset={() => setRaffleInfoAddressEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*응모하기 이메일 주소 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needRaffleEmailYn"
                text="이메일 입력 필요 여부"
                type="yn"
                value={needRaffleEmailYn}
                checked={needRaffleEmailYn}
                onChange={setNeedRaffleEmailYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="raffleInfoEmailDescription"
                placeholder="개인정보 입력부분 이메일 주소 설명을 입력해 주세요."
                value={raffleInfoEmailDescription}
                onChange={(e) => setRaffleInfoEmailDescription(e.target.value)}
                onReset={() => setRaffleInfoEmailDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="raffleInfoEmailEnDescription"
                placeholder="개인정보 입력부분 이메일 주소 설명(EN)을 입력해 주세요."
                value={raffleInfoEmailEnDescription}
                onChange={(e) =>
                  setRaffleInfoEmailEnDescription(e.target.value)
                }
                onReset={() => setRaffleInfoEmailEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*제세공과금안내 상세 텍스트*/}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="raffleTaxInformation"
                label="제세 공과금 안내 상세"
                value={raffleTaxInformation}
                onClick={onAddRaffleTaxInformationText}
                onEdit={onEditRaffleTaxInformationText}
                onRemove={onRemoveRaffleTaxInformationText}
                placeholder="제세 공과금 안내 상세를 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="raffleTaxEnInformation"
                label="제세 공과금 안내 상세(EN)"
                value={raffleTaxEnInformation}
                onClick={onAddRaffleTaxEnInformationText}
                onEdit={onEditRaffleTaxEnInformationText}
                onRemove={onRemoveRaffleTaxEnInformationText}
                readOnly={copyKR2EN}
                placeholder="제세 공과금 안내 상세(EN)를 입력해 주세요."
              />
            </InputWrapper>
          </InputWrapperContainer>
        )}
        {/* 응모 하기 정보 입력 영역 END ---------------------------------------*/}

        {/* 교환 하기 정보 입력 영역 START -------------------------------------*/}
        {(participateType === 'ALL' || participateType === 'EXCHANGE') && (
          <InputWrapperContainer span={6} className="area1" grg={5}>
            <InputWrapper span={3} mt={10}>
              <TitleText>교환 관련 정보</TitleText>
            </InputWrapper>
            {/* 교환 희망자 확인 모달 열기 */}
            <InputWrapper span={1}>
              <VideoInput
                onClick={() => setApplicantModalType('EXCHANGE')}
                text="교환 희망자 확인"
              />
            </InputWrapper>

            {/*교환 가격 */}
            <InputWrapper span={2} mt={10}>
              <LabelTextInput
                name="exchangePrice"
                label="교환 가격"
                Required
                value={exchangePrice}
                onChange={(e) => setExchangePrice(e.target.value)}
              />
            </InputWrapper>

            {/*현재 교환 인원 */}
            <InputWrapper span={1} mt={10}>
              <TextField label="현재 교환 인원" text={exchangeCount} />
            </InputWrapper>
            {/*최대 교환 인원 */}
            <InputWrapper span={1} mt={10}>
              <LabelTextInput
                name="maxExchangeCount"
                label="교환 인원"
                Required
                value={maxExchangeCount}
                onChange={(e) => setMaxExchangeCount(e.target.value)}
              />
            </InputWrapper>

            {/*교환하기 유의사항 */}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="exchangeCautions"
                label="교환하기 유의사항"
                value={exchangeCautions}
                onClick={onAddExchangeCautionText}
                onEdit={onEditExchangeCationText}
                onRemove={onRemoveExchangeCautionText}
                placeholder="교환하기 유의사항을 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="exchangeEnCautions"
                label="교환하기 유의사항(EN)"
                value={exchangeEnCautions}
                onClick={onAddExchangeEnCautionText}
                onEdit={onEditExchangeEnCationText}
                onRemove={onRemoveExchangeEnCautionText}
                readOnly={copyKR2EN}
                placeholder="교환하기 유의사항(EN)을 입력해 주세요."
              />
            </InputWrapper>

            {/*정보 입력 유의사항 설명 */}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="exchangePersonalInfoFormCautions"
                label="정보 입력 유의사항"
                value={exchangePersonalInfoFormCautions}
                onClick={onAddExchangePersonalInfoFormCautionText}
                onEdit={onEditExchangePersonalInfoFormCautionText}
                onRemove={onRemoveExchangePersonalInfoFormCautionText}
                placeholder="정보 입력 유의사항을 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="exchangePersonalInfoFormEnCautions"
                label="정보 입력 유의사항(EN)"
                value={exchangePersonalInfoFormEnCautions}
                onClick={onAddExchangePersonalInfoFormEnCautionText}
                onEdit={onEditExchangePersonalInfoFormEnCautionText}
                onRemove={onRemoveExchangePersonalInfoFormEnCautionText}
                readOnly={copyKR2EN}
                placeholder="정보 입력 유의사항(EN)을 입력해 주세요."
              />
            </InputWrapper>

            {/*교환하기 개인정보 작성 일자 */}
            <InputWrapper span={4} mt={10}>
              <DateWrapper>
                <DateInput
                  name="exchangePersonalInfoWriteStartDate"
                  label="개인정보 작성 시작일"
                  type="date"
                  Required
                  initialStartDate={exchangePersonalInfoWriteStartDate}
                  onChange={(e) =>
                    setExchangePersonalInfoWriteStartDate(e.target.value)
                  }
                />
                <DateInput
                  name="exchangePersonalInfoWriteEndDate"
                  label="개인정보 작성 종료일"
                  type="date"
                  Required
                  initialStartDate={exchangePersonalInfoWriteEndDate}
                  onChange={(e) =>
                    setExchangePersonalInfoWriteEndDate(e.target.value)
                  }
                />
              </DateWrapper>
            </InputWrapper>

            {/*경품 발송 시작 일자 */}
            <InputWrapper span={4} mt={10}>
              <DateInput
                name="exchangeGoodsDeliveryStartDate"
                label="경품 발송 시작일"
                type="date"
                Required
                initialStartDate={exchangeGoodsDeliveryStartDate}
                onChange={(e) =>
                  setExchangeGoodsDeliveryStartDate(e.target.value)
                }
              />
            </InputWrapper>

            {/*교환시 개인 정보 이름 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needExchangeMemberNameYn"
                text="이름 입력 필요 여부"
                type="yn"
                checked={needExchangeMemberNameYn}
                onChange={setNeedExchangeMemberNameYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="exchangeInfoNameDescription"
                placeholder="개인정보 입력부분 이름 설명을 입력해 주세요."
                value={exchangeInfoNameDescription}
                onChange={(e) => setExchangeInfoNameDescription(e.target.value)}
                onReset={() => setExchangeInfoNameDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="exchangeInfoNameEnDescription"
                placeholder="개인정보 입력부분 이름 설명(EN)을 입력해 주세요."
                value={exchangeInfoNameEnDescription}
                onChange={(e) =>
                  setExchangeInfoNameEnDescription(e.target.value)
                }
                onReset={() => setExchangeInfoNameEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*교환시 개인 정보 휴대폰 번호 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needExchangePhoneNumberYn"
                text="휴대폰 번호 입력 필요 여부"
                type="yn"
                checked={needExchangePhoneNumberYn}
                onChange={setNeedExchangePhoneNumberYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="exchangeInfoPhoneNumberDescription"
                placeholder="개인정보 입력부분 휴대폰 번호 설명을 입력해 주세요."
                value={exchangeInfoPhoneNumberDescription}
                onChange={(e) =>
                  setExchangeInfoPhoneNumberDescription(e.target.value)
                }
                onReset={() => setExchangeInfoPhoneNumberDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="exchangeInfoPhoneNumberEnDescription"
                placeholder="개인정보 입력부분 휴대폰 번호 설명(EN)을 입력해 주세요."
                value={exchangeInfoPhoneNumberEnDescription}
                onChange={(e) =>
                  setExchangeInfoPhoneNumberEnDescription(e.target.value)
                }
                onReset={() => setExchangeInfoPhoneNumberEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*교환시 개인 정보 주소 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needExchangeAddressYn"
                text="주소 입력 필요 여부"
                type="yn"
                checked={needExchangeAddressYn}
                onChange={setNeedExchangeAddressYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="exchangeInfoAddressDescription"
                placeholder="개인정보 입력부분 주소 설명을 입력해 주세요."
                value={exchangeInfoAddressDescription}
                onChange={(e) =>
                  setExchangeInfoAddressDescription(e.target.value)
                }
                onReset={() => setExchangeInfoAddressDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="exchangeInfoAddressEnDescription"
                placeholder="개인정보 입력부분 주소 설명(EN)을 입력해 주세요."
                value={exchangeInfoAddressEnDescription}
                onChange={(e) =>
                  setExchangeInfoAddressEnDescription(e.target.value)
                }
                onReset={() => setExchangeInfoAddressEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*교환시 개인 정보 이메일 주소 입력 필요 여부*/}
            <InputWrapper span={1} mt={10}>
              <CheckBoxInput
                name="needExchangeEmailYn"
                text="이메일 주소 입력 필요 여부"
                type="yn"
                checked={needExchangeEmailYn}
                onChange={setNeedExchangeEmailYn}
              />
            </InputWrapper>
            <InputWrapper span={3} mt={10}>
              <LabelTextInput
                label={null}
                name="exchangeInfoEmailDescription"
                placeholder="개인정보 입력부분 이메일 주소 설명을 입력해 주세요."
                value={exchangeInfoEmailDescription}
                onChange={(e) =>
                  setExchangeInfoEmailDescription(e.target.value)
                }
                onReset={() => setExchangeInfoEmailDescription('')}
              />
            </InputWrapper>
            <InputWrapper span={1} />
            <InputWrapper span={3}>
              <LabelTextInput
                label={null}
                name="exchangeInfoEmailEnDescription"
                placeholder="개인정보 입력부분 이메일 주소 설명(EN)을 입력해 주세요."
                value={exchangeInfoEmailEnDescription}
                onChange={(e) =>
                  setExchangeInfoEmailEnDescription(e.target.value)
                }
                onReset={() => setExchangeInfoEmailEnDescription('')}
                readOnly={copyKR2EN}
              />
            </InputWrapper>

            {/*제세공과금안내 상세 텍스트*/}
            <InputWrapper span={4} mt={10}>
              <LabelPlusButtonInput
                name="exchangeTaxInformation"
                label="제세 공과금 안내 상세"
                value={exchangeTaxInformation}
                onClick={onAddExchangeTaxInformationText}
                onEdit={onEditExchangeTaxInformationText}
                onRemove={onRemoveExchangeTaxInformationText}
                placeholder="제세 공과금 안내 상세를 입력해 주세요."
              />
            </InputWrapper>
            <InputWrapper span={4}>
              <LabelPlusButtonInput
                name="exchangeTaxEnInformation"
                label="제세 공과금 안내 상세(EN)"
                value={exchangeTaxEnInformation}
                onClick={onAddExchangeTaxEnInformationText}
                onEdit={onEditExchangeTaxEnInformationText}
                onRemove={onRemoveExchangeTaxEnInformationText}
                readOnly={copyKR2EN}
                placeholder="제세 공과금 안내 상세(EN)을 입력해 주세요."
              />
            </InputWrapper>
          </InputWrapperContainer>
        )}
        {/* 교환 하기 정보 입력 영역 END ---------------------------------------*/}
      </MainContainer>

      {/*응모자, 교환자 확인 모달*/}
      {applicantModalType && (
        <GoldCampaignApplicantPopup
          campaignId={campaignId}
          campaignName={name}
          modalType={applicantModalType}
          onClose={() => setApplicantModalType(null)}
        />
      )}
    </>
  );
};
export default GoldCampaignDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapperContainer = styled.div`
  width: 100%;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 30px;
  grid-gap: ${({ rgr }) => (typeof rgr === 'number' ? `${rgr}px` : '5px')} 15px;
  grid-column: ${({ span }) => span && `span ${span}`};
  margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : '5px')};
  margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : '')};

  &.area1 {
    background-color: #ccc;
    padding: 15px;
    border-radius: 6px;
  }
`;

const InputWrapper = styled.div`
  grid-row: ${({ row }) => row && `${row}`};
  grid-column: ${({ span }) => span && `span ${span}`};
  margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : '5px')};
  margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : '')};

  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const VerticalFileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px 40px;
  grid-gap: 5px 40px;
`;

const ImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ImageTag = styled.img`
  width: 100%;
  margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : '')};
  border-radius: 10px;
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;
