import React from 'react';
import styled from 'styled-components';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

interface TextButtonProps {
  text?: string;
  data: any;
  onClick: (data: any) => void;
}
const TextButton = ({ text, data, onClick }: TextButtonProps) => {
  const { word } = data;
  return (
    <SelectTag>
      <SelectTagText>{text ? text:word}</SelectTagText>
      {onClick && (
        <TagDeleteButton onClick={() => onClick(data)} />
      )}
    </SelectTag>
  );
};

export default TextButton;

const SelectTag = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 20px;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  border-radius: 10px;
`;

const SelectTagText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagDeleteButton = styled.button`
  width: 20px;
  height: 20px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 38px;
`;
