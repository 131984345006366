import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// component
import TextInput from '../components/common/Input/TextInput';
import FullButton from '../components/common/Button/FullButton';

// hook
import useInput from '../hooks/useInput';
import { useAppSelector } from '../redux/store/hooks';

// redux
import { logIn, loadUser } from '../redux/actions/user/user';

// constant
import { emailRegExp } from '../constant/common';

const LogIn = () => {
  const [email, setEmail, onResetEmail] = useInput('');
  const [password, setPassword, onResetPassword] = useInput('');
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const logInError = useAppSelector((state) => state.user.logInError);
  const dispatch = useDispatch();

  // 이메일 비밀번호 유효성 검사
  const disabled = useCallback(() => {
    return emailRegExp.test(email) && password.length !== 0;
  }, [email, password]);

  // 로그인 데이터 감싸고 dispatch
  const onSubmitLogIn = useCallback(async () => {
    const req = {
      email,
      password,
    };
    if (disabled()) {
      await dispatch(logIn(req));
      await dispatch(loadUser());
    }
  }, [email, password, dispatch, disabled]);

  // 엔터키로 로그인
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSubmitLogIn();
    }
  };

  return (
    <Container>
      {isLoggedIn ? (
        <p>Dash Board는 준비중 입니다.</p>
      ) : (
        <LoginForm>
          <h1>Log In to Baund Admin</h1>
          <h2>관리자 페이지를 사용하시려면 로그인이 필요합니다</h2>
          <TextForm>
            <TextInput
              name="email"
              id="email"
              value={email}
              type="email"
              placeholder="이메일을 입력해주세요"
              onChange={setEmail}
              onReset={onResetEmail}
              onKeyPress={enterKeyPress}
            />
            <TextInput
              name="password"
              id="password"
              value={password}
              type="password"
              placeholder="비밀번호를 입력해주세요"
              onChange={setPassword}
              onReset={onResetPassword}
              onKeyPress={enterKeyPress}
            />
          </TextForm>
          <LoginButton>
            <FullButton
              text="로그인"
              onSubmit={onSubmitLogIn}
              className={disabled() ? '' : 'unActiveBtn'}
            />
          </LoginButton>
          {logInError && (
            <LoginError>
              이메일 주소 혹은 비밀번호를 다시 확인해주세요
            </LoginError>
          )}
        </LoginForm>
      )}
    </Container>
  );
};

export default LogIn;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 60px;
`;

const LoginForm = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 287px auto 0 auto;

  h1 {
    margin-bottom: 28px;
    font-size: 48px;
    font-weight: 700;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  h2 {
    margin-bottom: 70px;
    font-size: 16px;
    color: ${({ theme }) => theme.color.subDarkGray};
  }

  // 반응형
  ${({ theme }) => theme.media.mobile`
    h1 {
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 28px;
    }
   h2 {
    font-size: 6px;
    margin-bottom: 70px;
  }
  `};
`;

const TextForm = styled.div`
  width: 380px;
  margin-bottom: 40px;
  div {
    &:nth-child(1) {
      margin-bottom: 20px;
    }
  }
`;

const LoginButton = styled.div`
  width: 380px;
`;

const LoginError = styled.span`
  position: absolute;
  bottom: -45px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;
