import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// common
import Pagination from '../../../components/common/Pagination';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';

// hooks
import RoundButton from "../../../components/common/Button/RoundButton";
import NormalButton from "../../../components/common/Button/NormalButton";
import ListItem from "../../../components/common/List/ListItem";
import ListHead from "../../../components/UI/ListHead";
import Table from "../../../components/common/List/Table";
import Modal from "../../../components/common/Modal/Modal";
import ListHeadWithSort from "../../../components/UI/ListHeadWithSort";

const SuggestWordCategoryRow = ({ data, setSelected }) => {
  // console.log('SuggestWordCategoryRow > data : ', data);
  return (<>
    <tr key={data.id}>
      <td>
        <input id={`checkbox_${data.id}`} type="checkbox"
           checked={ data.selected}
           onChange={(e) => setSelected(data.id, e.currentTarget.checked)}/>
      </td>
      <td><ListItem text={data.id} /></td>
      <td><ListItem text={data.name} /></td>
      <td><ListItem text={data.wordCount} /></td>
    </tr>
  </>);
};

const SuggestWordCategory = () => {
  const [categoryName, setCategoryName] = useState('');
  const [suggestWordCategories, setSuggestWordCategories] = useState([]);
  const [categoryIdsToDelete, setCategoryIdsToDelete] = useState([]);
  const [error, setError] = useState('');
  const [checkAll, setCheckAll] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSortKey, setSelectedSortKey] = useState('');
  const [selectedSortDirection, setSelectedSortDirection] = useState('');
  const [sortParam, setSortParam] = useState('');


  // pagination state
  const postsPerPage = 21;
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  // 카테고리 조회
  const getSuggestWordCategory = async () => {
    await axios.get(`/setting/suggest/word_category`, {
      params: {
        sortType: sortParam,
        limit: postsPerPage,
        offset: pageNumber * postsPerPage,
      },
    })
    .then(res => {
      // console.log('SuggestWordCategory > getSuggestWordCategory > data', res.data);
      setSuggestWordCategories(
          res.data.suggestWordCategories.map((cat) => {
            return {
              id: cat.id, name: cat.name, wordCount: cat.wordCount, selected: false
            }
          })
      );
      setTotalCount(res.data.count || 0);
    })
    .catch (err => {
      console.log(err);
      alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  };
  // 페이지 변경시
  useEffect(() => {
    getSuggestWordCategory().then();
  }, [postsPerPage, pageNumber, sortParam]);

  // 제시어 추가 api호출 함수
  const addSuggestWordCategory = async () => {
      // 공백 체크
    const blankPattern = /^\s+|\s+$/g;
    const isBlank = categoryName.replace(blankPattern, '') === '';
    if (categoryName === '' || isBlank) {
      setError('카테고리 명을 입력해 주세요.');
    }
    await axios.post(
        `/setting/suggest/word_category`,
        {},
        {params : {name: categoryName}},
    )
    .then(() => {
      getSuggestWordCategory();
      closeModal();
    })
    .catch (err => {
      console.log(err.response.data.message);
      setError(err.response.data.message);
      // alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  };

  // 전체 카테고리 선택
  const setSelectedAll = (checked) => {
    suggestWordCategories.map((item) => {
      item.selected = checked;
    });

    const arr = [];
    //선택한 것이면
    if (checked) {
      // 전체 추가
      suggestWordCategories.forEach((el) => {
        arr.push(el.id);
      });
    }
    setCategoryIdsToDelete(arr);
    setCheckAll(checked);
  }

  // 카테고리 선택
  const setSelected = (categoryId, checked) => {
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    suggestWordCategories.map((item) => {
      if (item.id === categoryId) {
        item.selected = checked;
      }
    });

    setCategoryIdsToDelete(
        //선택한 것이면
        checked
            // 아이디 목록에 추가
            ? [...categoryIdsToDelete, categoryId]
            // 아이디 목록에서 제외
            : categoryIdsToDelete.filter((item)=> item !== categoryId)
    );
    setCheckAll(false);
  }

  // 제시어 지우기
  const deleteCategory = async () => {
    // console.log('SuggestWordCategory > deleteCategory > categoryIdsToDelete:', categoryIdsToDelete);
    if (categoryIdsToDelete.length === 0) {
      return;
    }
    if (!confirm('선택한 카테고리를 삭제하시겠습니까?.\n삭제 시, 카테고리에 있는 제시어도 모두 삭제됩니다.')) {
      return;
    }
    await axios.delete(`/setting/suggest/word_category/`, {
      params:{
        suggestWordCategoryIds: categoryIdsToDelete
      }
    })
    .then(() => {
      getSuggestWordCategory();
      setCheckAll(false);
    })
    .catch (err => {
      console.log(err.response.data);
      alert(err.response.data.message);
    });
  };

  useEffect(() => {
    if (categoryName === '') {
      setError('');
    }
  }, [categoryName]);

  const closeModal = () => {
    setError('');
    setCategoryName('');
    setModalOpen(false);
  }

  const setSort = (sortKey, sortDirection) => {
    // console.log('SuggestWordCategory > setSort > sortKey:', sortKey, ' / sortDirection:',sortDirection);
    setSelectedSortKey(sortKey);
    setSelectedSortDirection(sortDirection);

    if(!sortKey || !sortDirection) {
      setSortParam(null );
    }
    else {
      setSortParam(sortKey.concat('_', sortDirection) );
    }

  }

  return (
      <>
        <ButtonWrapper>
          <NormalButton text="선택 삭제" className={(categoryIdsToDelete.length === 0) ? "unActiveBtn": "blockBtn"} onSubmit={deleteCategory}/>
          <NormalButton text="카테고리 추가" className={"blockBtn"} onSubmit={() => setModalOpen(true)}/>
        </ButtonWrapper>

        <MainContainer>
          <InputWrapper span={6}>
            <TopContainer>
              {totalCount !== 0 && (
                  <PaginationWrapper>
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalCount={totalCount}
                        currentPage={pageNumber}
                        pageDispatch={pageDispatch}
                    />
                  </PaginationWrapper>
              )}
            </TopContainer>
          </InputWrapper>

          <InputWrapper span={6}>
            <Table
                colList={[10, 10, 100, 20]}
                thead={
                  <>
                    <TableHead>
                      <input id={`checkbox_0`} type="checkbox"
                             checked={checkAll}
                             onChange={(e) => setSelectedAll(e.currentTarget.checked)}/>
                    </TableHead>
                    <ListHeadWithSort
                        title="No"
                        sortKey={"CREATE_DATE"}
                        selectedSortKey={selectedSortKey}
                        selectedSortDirection={selectedSortDirection}
                        onClick={setSort}
                    />
                    <ListHeadWithSort
                        title="카테고리 이름"
                        sortKey={"NAME"}
                        selectedSortKey={selectedSortKey}
                        selectedSortDirection={selectedSortDirection}
                        onClick={setSort}
                    />
                    <ListHead title="제시어 총 개수" />
                  </>
                }
            >
              {suggestWordCategories?.map((el) => (
                <SuggestWordCategoryRow
                    key={el.id}
                    data={el}
                    setSelected={setSelected}
                />
              ))}
            </Table>
          </InputWrapper>

        </MainContainer>
        {modalOpen && (
            <Modal option={{class: 'suggestCategoryInput'}} closeModal={closeModal}>
              <ModalContainer>
                <InputWrapper width={390}>
                  <LabelTextInput
                      name="category"
                      label="카테고리 추가"
                      placeholder="카테고리 이름을 입력하세요."
                      value={categoryName}
                      onChange={(e)=> {
                        setCategoryName(e.target.value);
                        setError('');
                      }}
                      maxLen={20}
                  />
                  {error && <ErrorText>{error}</ErrorText>}
                </InputWrapper>
                <InputWrapper mt={32}>
                  <RoundButton title="추가" selected={true} onClick={addSuggestWordCategory}/>
                </InputWrapper>
              </ModalContainer>
            </Modal>
        )}
      </>
  );
};
export default SuggestWordCategory;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
  &.simpleGray {
    padding: 0px 0px;
    background-color: #ccc;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div``;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  width: ${({width}) => typeof(width) === "number" ? `${width}px` : ''};
`;

const ErrorText = styled.span`
  position: absolute;
  // bottom: -30px;
  margin-top: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;

const ModalContainer = styled.div`
  width: 505px;
  padding-bottom: 55px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;
