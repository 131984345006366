// react
import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";

// module
import styled from 'styled-components';
import axios from 'axios';

// components
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';

// constants
import { PERFORMANCE_CATEGORY_ACTIVE, PERFORMANCE_CATEGORY_INACTIVE, PERFORMANCE_CATEGORY_SEQUENCE} from '../../../constant/apiUrl';
import DragList from './DragList';
import {resetLoading, setLoading} from "../../../redux/slices/user";

const PerformanceCategory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [activeYn, setActiveYn] = useState(1);            // 0: 비활성, 1: 활성
    const [mode, setMode] = useState(0);                    // 0: 일반 모드, 1: 수정 모드
    const [categoryList, setCategoryList] = useState([]);   // 카테고리 정보
    const [totalCount, setTotalCount] = useState(0);        // 페이징 전체 크기

    const getCategoryList = async () => {
        let api = PERFORMANCE_CATEGORY_ACTIVE;
        if (activeYn === 0) api = PERFORMANCE_CATEGORY_INACTIVE;

        await axios.get(`${api}?offset=0&limit=100`)
            .then(res => {
                setTotalCount(res.data.count);
                setCategoryList(res.data.performanceCategorys);
            })
            .catch(reason => console.error(reason));
    };

    const changeSequence = async () => {
        dispatch(setLoading());
        const ids = categoryList.map(c => c.performanceCategoryId);
        await axios.put(PERFORMANCE_CATEGORY_SEQUENCE, { ids })
            .then(() => {
                alert('저장되었습니다.');
                setMode(0);
                getCategoryList();
            })
            .catch(reason => console.error(reason))
            .finally(() => dispatch(resetLoading()))
    };

    const moveDetail = (id, count) => {
        navigate('/culture/performanceCategory/detail', {state: {id:id, count:count}});
    }

    // 드래그 앤 드롭
    const movePetListItem = useCallback(
        (dragIndex, hoverIndex) => {
            const dragItem = categoryList[dragIndex];
            const hoverItem = categoryList[hoverIndex];

            setCategoryList((tagList) => {
                const updatedPets = [...tagList];
                updatedPets[dragIndex] = hoverItem;
                updatedPets[hoverIndex] = dragItem;
                return updatedPets;
            });
      }, [categoryList]);

    useEffect(() => {
        getCategoryList();
    }, [activeYn]);

    return (
        <MainContainer>
            <NavigateBtnContainer>
                {mode === 1 ? (
                      <>
                            <NormalButton
                                text="취소"
                                className="cancelBtn"
                                onSubmit={() => {
                                    setMode(0);
                                    getCategoryList();
                                }}
                            />
                            <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
                      </>
                ) : (
                      <>
                            <NormalButton
                                text="노출 순서 변경"
                                className="cancelBtn"
                                onSubmit={() => setMode(1)}
                            />
                            <NormalButton
                                text="카테고리 등록하기"
                                onSubmit={() => moveDetail(null, totalCount)}
                            />
                      </>
                )}
            </NavigateBtnContainer>

            {mode === 0 && (
                <ButtonWrapper>
                    <RoundButton title="공개" selected={activeYn === 1} onClick={() => setActiveYn(1)}/>
                    <RoundButton title="비공개" selected={activeYn === 0} onClick={() => setActiveYn(0)}/>
                </ButtonWrapper>
            )}

            <Ul>
                {
                    mode === 1
                        ? categoryList?.map((c, index) =>
                            <DragList
                                key={c.performanceCategoryId}
                                data={c} moveListItem={movePetListItem}
                                index={index}/>)
                        : categoryList?.map(c =>
                            <NormalList
                                key={c.performanceCategoryId}
                                onClick={() => moveDetail(c.performanceCategoryId, totalCount)}
                            >{c.name}</NormalList>)
                }
            </Ul>
        </MainContainer>
    );
};

export default PerformanceCategory;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavigateBtnContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 30px;
    button {
        &:last-child {
            margin-left: 20px;
        }
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 30px;
`;

const Ul = styled.ul`width: 100%;`;

const NormalList = styled.li`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 59px;
    border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
`;
