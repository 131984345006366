import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

import styled from 'styled-components';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import TextField from '../../../components/common/TextField';
import Modal from '../../../components/common/Modal/Modal';

// hooks
import handleDate from '../../../hooks/handleDate';

// config
import { SEARCH_MEMBER } from '../../../constant/apiUrl';

// constant
import {
  IsAgree,
  GetCountryCodeOption,
  loginTypeOptions,
  emailRegExp,
} from '../../../constant/common';
import RadioButton from '../../../components/common/Button/RadioButton';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialMemberData: data = {
  articleCount: '',
  countryCode: '',
  createdDt: '',
  email: '',
  id: '',
  lastSigninDate: '',
  loginType: '',
  name: '',
  pid: '',
  receiveMarketingInfoDt: '',
  receiveMarketingInfoYn: '',
  receiveNightTimeInfoDt: '',
  receiveNightTimeInfoYn: '',
  reportScore: '',
  reportScoreExpireDt: '',
  termsOfServiceDt: '',
  termsOfServiceYn: '',
  usePersonalInfoDt: '',
  usePersonalInfoYn: '',
  verifiedYn: 'N',
};

const DetailMember = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [memberData, setMemberData] = useState(initialMemberData);
  const [newEmail, setNewEmail] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [checkEmail, setCheckEmail] = useState('');
  const [checkAuthCodeText, setCheckAuthCodeText] = useState('');
  const [checkAuthCode, setCheckAuthCode] = useState<boolean>(false);
  const [isSendNum, setIsSendNum] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const memberId: any = location.state;
  const nowDate = `${new Date().toISOString().slice(0, 16)}:00`;

  const {
    id,
    email,
    articleCount,
    countryCode,
    lastSigninDate,
    loginType,
    name,
    pid,
    receiveMarketingInfoDt,
    receiveMarketingInfoYn,
    receiveNightTimeInfoDt,
    receiveNightTimeInfoYn,
    reportScore,
    reportScoreExpireDt,
    termsOfServiceDt,
    termsOfServiceYn,
    usePersonalInfoDt,
    usePersonalInfoYn,
    createdDt,
    verifiedYn,
  } = memberData;

  // 이메일 비밀번호 유효성 검사
  const disabled = () => {
    if (!newEmail) {
      return true;
    }
    return emailRegExp.test(newEmail);
  };

  // modal 닫기
  const closeModal = () => {
    setAuthCode('');
    setNewEmail('');
    setCheckEmail('');
    setCheckAuthCodeText('');
    setCheckAuthCode(false);
    setIsSendNum(false);
    setModalOpen(false);
  };

  // 셀럽 인증 바꾸기
  const handleVerifyYn = (e: any) => {
    setMemberData({
      ...memberData,
      verifiedYn: e.target.value,
    });
  };

  // 셀럽 인증 post 함수
  const changeVerifyYn = async () => {
    try {
      await axios.post(`${SEARCH_MEMBER}/${memberId}/verify`, {
        verifiedYn,
      });
      alert('적용 되었습니다.');
      navigate('/member/searchMember');
    } catch (err) {
      console.log(err);
    }
  };

  // member 상세 조회
  const searchMember = useCallback(async () => {
    try {
      const resp = await axios.get(`${SEARCH_MEMBER}/${memberId}/detail`, {});
      setMemberData(resp.data.member);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  }, [memberId]);

  // member 이메일 변경
  const editMemberEmail = async () => {
    if (checkEmail || !newEmail) {
      return;
    }
    try {
      const data = { email: newEmail };
      await axios.post(
        `${SEARCH_MEMBER}/${memberId}/modify/email/auth`,
        data,
        {},
      );
      alert('인증번호를 전송하였습니다.');
      setCheckEmail('');
      setIsSendNum(true);
    } catch (err: any) {
      // alert(err.response.data.message);
      setCheckEmail(err.response.data.message);
    }
  };

  // member 인증번호 확인
  const checkNumber = async () => {
    if (!isSendNum) {
      return;
    }
    try {
      const data = { authCode, email: newEmail };
      await axios.post(
        `${SEARCH_MEMBER}/${memberId}/modify/email/change`,
        data,
        {},
      );
      await setCheckAuthCode(true);
      await setCheckAuthCodeText('인증이 완료되어 메일이 변경되었습니다.');
      await searchMember();
    } catch (err: any) {
      setCheckAuthCodeText(err.response.data.message);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const handleResetData = (name: any) => {
    if (name === 'newEmail') {
      setNewEmail('');
    }
    if (name === 'authCode') {
      setCheckAuthCodeText('');
    }
  };

  const handleInputSetData = useCallback((e: any) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { name, value } = e.target;
    if (name === 'newEmail') {
      setNewEmail(value);
    }
    if (name === 'authCode') {
      setAuthCode(value);
    }
  }, []);

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    searchMember();
  }, [searchMember]);

  useEffect(() => {
    if (!disabled()) {
      setCheckEmail('이메일 형식이 맞지 않습니다.');
    } else {
      setCheckEmail('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEmail]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            <ProfileTitleWrapper>
              <BeatTitle>{id}</BeatTitle>
            </ProfileTitleWrapper>
          </ProfileWrapper>
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
            <NormalButton text="저장하기" onSubmit={changeVerifyYn} />
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <InputContainer>
            <InputWrapper>
              <LabelTextWrapper>
                <LabelTextInput
                  name="newEmail"
                  label="이메일"
                  value={newEmail}
                  placeholder="변경 할 이메일을 입력해 주세요."
                  onChange={handleInputSetData}
                  onReset={handleResetData}
                />
                <ErrorText>{checkEmail || ''}</ErrorText>
                <NormalButton
                  text="인증 번호 발송"
                  onSubmit={editMemberEmail}
                  className={
                    checkEmail || !newEmail ? 'unActiveModalBtn' : 'modalBtn'
                  }
                />
              </LabelTextWrapper>
            </InputWrapper>
            <SpaceDiv />
            <InputWrapper>
              <LabelTextWrapper>
                {isSendNum ? (
                  <>
                    <LabelTextInput
                      name="authCode"
                      label="인증번호"
                      value={authCode}
                      placeholder="인증 번호를 입력해 주세요."
                      onChange={handleInputSetData}
                      onReset={handleResetData}
                    />
                    <ErrorText className={!checkAuthCode ? '' : 'correctText'}>
                      {checkAuthCodeText || ''}
                    </ErrorText>
                  </>
                ) : (
                  <TextField
                    label="인증번호"
                    type="unActive"
                    text="인증 번호를 입력해 주세요."
                  />
                )}
                <NormalButton
                  text="확인"
                  onSubmit={checkNumber}
                  className={isSendNum ? 'modalBtn' : 'unActiveModalBtn'}
                />
              </LabelTextWrapper>
            </InputWrapper>
          </InputContainer>
        </Modal>
      )}
      <MainContainer>
        <InputWrapper>
          <TextField label="Pid" text={pid} />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="이메일"
            text={email}
            onClick={() => {
              setModalOpen(true);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="닉네임" text={name} />
        </InputWrapper>
        <InputWrapper>
          {/*<CheckboxButton*/}
          {/*  label="셀럽 인증 유무"*/}
          {/*  checkedValue={BooleanChecker(verifiedYn)}*/}
          {/*  onChange={handleVerifyYn}*/}
          {/*  eventButton={changeVerifyYn}*/}
          {/*/>*/}
          <RadioButton
            value={verifiedYn}
            name="privateYn"
            label="셀럽 인증 유무"
            onChange={handleVerifyYn}
            type="privateYn"
            text={['비인증', '인증']}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="가입 유형" text={loginTypeOptions[loginType]} />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="계정 생성일"
            text={createdDt ? handleDate(createdDt) : ''}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="국가 코드"
            text={GetCountryCodeOption[countryCode]}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="개인정보 처리방침 동의"
            text={IsAgree[usePersonalInfoYn]}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="개인정보 처리방침 동의일"
            text={usePersonalInfoDt ? handleDate(usePersonalInfoDt) : ''}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="이용 약관 동의" text={IsAgree[termsOfServiceYn]} />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="이용 약관 동의일"
            text={termsOfServiceDt ? handleDate(termsOfServiceDt) : ''}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="마케팅 정보 수신 동의"
            text={IsAgree[receiveMarketingInfoYn]}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="마케팅 정보 수신 동의일"
            text={
              receiveMarketingInfoDt ? handleDate(receiveMarketingInfoDt) : ''
            }
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="야간 푸시 수신 동의"
            text={IsAgree[receiveNightTimeInfoYn]}
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="야간 푸시 수신 동의일"
            text={
              receiveNightTimeInfoDt ? handleDate(receiveNightTimeInfoDt) : ''
            }
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="영상 개수" text={articleCount || 0} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="벌점" text={reportScore || 0} />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="계정 상태"
            text={
              reportScoreExpireDt === null ||
              Date.parse(nowDate) > Date.parse(reportScoreExpireDt)
                ? '정상'
                : '제재'
            }
          />
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="최근 접속 일시"
            text={lastSigninDate ? handleDate(lastSigninDate) : '-'}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default DetailMember;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BeatTitle = styled.h1`
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const LabelTextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputWrapper = styled.div`
  position: relative;

  &:nth-child(3) {
    grid-column: span 2;
  }
  &:nth-child(4) {
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(7) {
    grid-column: span 2;
    margin-bottom: 40px;
  }

  //&:nth-child(9) {
  //  grid-column: span 2;
  //}
  //&:nth-child(10) {
  //  grid-column: span 2;
  //  margin-bottom: 40px;
  //}
  &:nth-child(15) {
    margin-bottom: 40px;
  }
  &:nth-child(16) {
    grid-column: span 2;
  }
  &:nth-child(19) {
    grid-column: span 2;
  }
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -25px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};

  &.correctText {
    color: ${({ theme }) => theme.color.mainPurple};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 483px;
  margin: 0 138px;
`;

const SpaceDiv = styled.div`
  height: 60px;
`;
