import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';

const Loader = ({ message }: any) => (
  <LoaderContainer>
    <LoaderWrapper>
      <h1>{message}</h1>
      <ReactLoading type="spin" color="white" height="100px" width="100px" />
    </LoaderWrapper>
  </LoaderContainer>
);

export default Loader;

const LoaderContainer = styled.div`
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.08);
  overflow: scroll;
  z-index: 50;
  margin-bottom: 500px;
`;

const LoaderWrapper = styled.div`
  display: block;
  position: absolute;
  top: calc(50% - 60px);
  left: 50%;
  transform: translate(-50%, -50%);
`;
