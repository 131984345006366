import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useInput from "../../hooks/useInput";
import {postsPerPage} from "../../constant/common";
import axios from "axios";
import {BAUND_PROJECT_PARTICIPANT} from "../../constant/apiUrl";
import styled from "styled-components";
import SearchMenuSelect from "../../components/common/Select/SearchMenuSelect";
import {
  baundProjectParticipantResultTypeOption,
  baundProjectParticipantSearchTypeOptions
} from "../../constant/baundProject";
import SearchInput from "../../components/common/Input/SearchInput";
import NormalButton from "../../components/common/Button/NormalButton";
import PaginationWithTotalCount
  from "../../components/common/Pagination/PaginationWithTotalCount";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import ListCoverImg from "../../components/common/List/ListCoverImg";
import {resetLoading, setLoading} from "../../redux/slices/user";
import {useLocation} from "react-router";
import {Tooltip} from "../advertisement/component/Tooltip";
import {useDispatch} from "react-redux";

// 바운드 프로젝트 지원자 목록
const BaundProjectParticipants = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 이전 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectId = detailData !== null ? detailData?.baundProjectId : 0;

  // 저장된 검색 조건
  const storageSearchCondition2nd = JSON.parse(localStorage.getItem('storageSearchCondition2nd'));

  // 검색 조건
  const [filter, setFilter] = useState(storageSearchCondition2nd?.filterForSearch || "ALL");
  const [word, setWord] = useInput(storageSearchCondition2nd?.wordForSearch || '');
  const [type, setType] = useState(storageSearchCondition2nd?.typeForSearch || '');
  const [pageNumber, setPageNumber] = useState(storageSearchCondition2nd?.pageNumberForSearch || 0);

  //표시 데이터
  const [participantList, setParticipantList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  //프로젝트 정보
  const [judgeCriteriaLike, setJudgeCriteriaLike] = useState(0);
  const [judgeCriteriaView, setJudgeCriteriaView] = useState(0);
  const [judgeCriteriaVote, setJudgeCriteriaVote] = useState(0);
  const [judgeCriteriaHost, setJudgeCriteriaHost] = useState(0);
  const [judgeCriteriaPanel, setJudgeCriteriaPanel] = useState(0);
  const [voteType, setVoteType] = useState();
  const [searchTrigger, setSearchTrigger] = useState(false);

  // 페이지 초기화 처리 ////////////////////////////////////////////////////////
  useEffect(()=> {
    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition2nd');
  }, []);

  //목록 조회 //////////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    baundProjectId,
    filter,
    searchType: type,
    keyword: word
  };
  const getParticipants = async() => {
    await axios.get(`${BAUND_PROJECT_PARTICIPANT}`, {params})
    .then(res => {
      setParticipantList(res.data.baundProjectParticipants);
      setTotalCount(res.data.totalCount);
      const projectDetail = res.data.baundProject;
      setJudgeCriteriaLike(projectDetail.judgeCriteriaLike);
      setJudgeCriteriaView(projectDetail.judgeCriteriaView);
      setJudgeCriteriaVote(projectDetail.judgeCriteriaVote);
      setJudgeCriteriaHost(projectDetail.judgeCriteriaHost);
      setJudgeCriteriaPanel(projectDetail.judgeCriteriaPanel);
      setVoteType(projectDetail.voteType);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => {
    getParticipants().then();
  }, [searchTrigger]);

  // 상세 페이지로 이동
  const moveToDetail = (baundProjectParticipantId) => {
    navigate(
        `/baundProject/baundProject/baundProjectParticipant/detail`,
        {
          state:{
            baundProjectParticipantId: baundProjectParticipantId,
            baundProjectId: baundProjectId,
            filterForSearch: filter,
            wordForSearch: word,
            typeForSearch: type,
            pageNumberForSearch: pageNumber
          }
        }
    );
  }

  // 검색 //////////////////////////////////////////////////////////////////////////////////////////
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
  };
  const enterKeyPress = async (e) => {
    if (e.key === 'Enter') {
      if (!type) {
        setType('pid');
        params.searchType = 'pid';
      }
      executeSearch(true);
    }
  };
  // 페이지 변경시
  const handlePageNumber = (e) => {
    setPageNumber(e - 1);
    executeSearch(false);
  }
  // 필터 변경시
  const handleFilter = (_filter) => {
    setFilter(_filter);
    executeSearch(true);
  }
  const executeSearch = (resetPageNumber) => {
    if(resetPageNumber) {
      params.offset = 0;
      setPageNumber(0);
    }
    // 트리거 값 변경으로 검색 수행 유도
    setSearchTrigger(!searchTrigger);
  }

  // 재정렬 ////////////////////////////////////////////////////////////////////////////////////////
  const reOrder = async() => {
    dispatch(setLoading());
    try {
      await axios.put(`${BAUND_PROJECT_PARTICIPANT}/reorder/${baundProjectId}`);
      alert('저장이 완료 되었습니다.');
      getParticipants();
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  }

  return (<>
    <TitleText>지원자 현황</TitleText>
    <TopContainer>

      <TopWrapper>
        <SearchWrapper>
          <SearchMenuSelect
              options={baundProjectParticipantSearchTypeOptions}
              onClick={handleSelect}
              setState={setType}
          />
          <SearchInputWrapper>
            <SearchInput value={word} onChange={setWord} onKeyPress={enterKeyPress}/>
          </SearchInputWrapper>
        </SearchWrapper>
      </TopWrapper>

      <ButtonWrapper jc={'space-between'}>
        <ButtonWrapper>
          <NormalButton onSubmit={() =>handleFilter("ALL")} text={"전체"}
            className={(filter === "ALL") ? 'subDarkGray': 'refreshBtn'}/>
          <NormalButton onSubmit={() =>handleFilter("FINAL")} text={"결선 진출자(final)"}
            className={(filter === "FINAL") ? 'subDarkGray': 'refreshBtn'}/>
          <NormalButton onSubmit={() =>handleFilter("WINNER")} text={"최종 우승자(finish)"}
            className={(filter === "WINNER") ? 'subDarkGray': 'refreshBtn'}/>
        </ButtonWrapper>
        <NormalButton text={'재정렬'}
            onSubmit={() => reOrder().then(() => dispatch(resetLoading()))}/>
      </ButtonWrapper>

      <PaginationWrapper>
        {totalCount !== 0 && (
            <PaginationWrapper>
              <PaginationWithTotalCount
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  totalCountPostfix={'명 지원자'}
                  currentPage={pageNumber}
                  pageDispatch={handlePageNumber}
              />
            </PaginationWrapper>
        )}
      </PaginationWrapper>
    </TopContainer>

    <MainContainer>
      <Table
          colList={[25, 35, 60, 60, 25, 25, 25, 25, 25, 25, 25]}
          thead={<>
            <ListHead title="등수" />
            <ListHead title="지원 영상"/>
            <ListHead title="사용자 pid"/>
            <ListHead title="업로드 일시"/>
            <TableHead><OptionMenu>좋아요<br/>개수</OptionMenu></TableHead>
            <ListHead title="조회수"/>
            <ListHead title="투표수"/>
            <TableHead className={'withComment'}>
              <Tooltip message={`호스트 총점 : 
              결선 진출자 호스트 점수 + 최종 우승자 호스트 점수 
              결선 진출자 점수가 없을 경우,
              최종 우승자 호스트 점수만을 의미합니다. `}>
                <OptionMenu>호스트<br/>총점�</OptionMenu>
              </Tooltip>
            </TableHead>
            <TableHead className={'withComment'}>
              <Tooltip message={`baund 총점 : 
              결선 진출자 baund 점수 + 최종 우승자 baund 점수 
              결선 진출자 점수가 없을 경우, 
              최종 우승자 호스트 점수만을 의미합니다`}>
                <OptionMenu>Baund<br/>총점�</OptionMenu>
              </Tooltip>
            </TableHead>
            <TableHead><OptionMenu>결선 진출자<br/>결과</OptionMenu></TableHead>
            <TableHead><OptionMenu>최종<br/>우승자</OptionMenu></TableHead>
          </>}
      >
        {participantList?.map(el =>
            <tr onClick={() => moveToDetail(el.baundProjectParticipantId)}
                key={el.baundProjectParticipantId}>
              <td><ListItem text={
                (filter === "FINAL")
                    ? el.applyRank
                    : (filter === "WINNER")
                        ? el.judgeRank
                        : el.allRank
              }/></td>
              <td><ListCoverImg url={el.coverImgUrl}/></td>
              <td><ListItem text={el.pid}/></td>
              <td><ListItem text={el.createDt}/></td>
              <td><ListItem text={judgeCriteriaLike ?el.likeCount :'-'}/></td>
              <td><ListItem text={judgeCriteriaView ?el.viewCount :'-'}/></td>
              <td><ListItem text={(judgeCriteriaVote && voteType) ?el.voteCount :'-'}/></td>
              <td><ListItem text={judgeCriteriaHost ?el.hostPoint :'-'}/></td>
              <td><ListItem text={judgeCriteriaPanel ?el.panelPoint :'-'}/></td>
              <td>
                <ListItem text={
                  baundProjectParticipantResultTypeOption.filter(
                      e => e.value === el.applyResult)[0].text
                }/>
              </td>
              <td>
                <ListItem text={
                  baundProjectParticipantResultTypeOption.filter(
                      e => e.value === el.judgeResult)[0].text
                }/>
              </td>
            </tr>
        )}
        {participantList.length === 0 && (
            <tr>
              <td colSpan={8}>
                <SpanNoResult>지원자가 없습니다.</SpanNoResult>
              </td>
            </tr>
        )}
      </Table>
    </MainContainer>
  </>);
}
export default BaundProjectParticipants;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 430px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 20px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'flex-start'};
  align-items: center;
  button:not(:first-child) {
    margin-left: 20px;
  }
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
  &.withComment {
    cursor: help;
  }
`;

const OptionMenu = styled.div`
display: inline-block;
position: relative;
padding: 10px 14px;
border-radius: 40px;
font-size: 13px;
font-weight: 600;
color: ${({ theme }) => theme.color.subDarkGray};
background-color: ${({ title, theme }) =>
  title === '' ? theme.color.mainWhite : theme.color.subBrightGray};
z-index: 1;
`;