import React from 'react';
import styled from 'styled-components';

interface RadioButtonProps {
  value: string | number;
  name: string;
  type: string;
  label?: string;
  onChange?: any;
  Required?: boolean;
  disabled?: boolean;
  text?: string[] | undefined;
  subText?: string;
  values?: string[] | number[] | undefined;
}

const RadioButton = ({
  value,
  name,
  label,
  onChange,
  Required,
  disabled,
  text,
  subText,
  type,
  values,
}: RadioButtonProps) => {
  const osType = ['ALL', 'I', 'A'];
  return (
    <InputContainer className={name}>
      <InputText>
        {label} {Required && <span>*</span>}
      </InputText>
      <RadioBox className={name}>
        {/*라디오 버튼이 반대인 경우*/}
        {
          type === 'privateYn' ? <>
            <RadioSection>
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio1"
                    name={name}
                    value="N"
                    onChange={onChange}
                    checked={value !== '' && value === 'N'}
                />
                <p>{text ? text[0] : '공개'}</p>
              </RadioLabel>
            </RadioSection>
            <RadioSection>
                  <RadioLabel>
                    <InputField
                        type="radio"
                        id="radio2"
                        name={name}
                        value="Y"
                        onChange={onChange}
                        checked={value !== '' && value === 'Y'}
                    />
                    <p>{text ? text[1] : '비공개'}</p>
                  </RadioLabel>
                </RadioSection>
          </> :
          type === 'activeYn' ? <>
            <RadioSection>
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio1"
                    name={name}
                    value="Y"
                    onChange={onChange}
                    checked={value !== '' && value === 'Y'}
                    disabled={disabled}
                />
                <p>{text ? text[0] : ''}</p>
              </RadioLabel>
            </RadioSection>
            <RadioSection>
                  <RadioLabel>
                    <InputField
                        type="radio"
                        id="radio2"
                        name={name}
                        value="N"
                        onChange={onChange}
                        checked={value !== '' && value === 'N'}
                        disabled={disabled}
                    />
                    <p>{text ? text[1] : ''}</p>
                  </RadioLabel>
                </RadioSection>
          </> :
          type === 'uneditable' ? <>
            <RadioSection className="uneditable">
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio1"
                    name={name}
                    value="Y"
                    onChange={onChange}
                    checked={value !== '' && value === 'Y'}
                    disabled={disabled}
                />
                <p>{text ? text[0] : ''}</p>
              </RadioLabel>
            </RadioSection>
            <RadioSection>
                  <RadioLabel>
                    <InputField
                        type="radio"
                        id="radio2"
                        name={name}
                        value="N"
                        onChange={onChange}
                        checked={value !== '' && value === 'N'}
                        disabled={disabled}
                    />
                    <p>{text ? text[1] : ''}</p>
                  </RadioLabel>
                  <SubText>{subText}</SubText>
                </RadioSection>
          </> :
          type === 'uneditablePrivate' ? <>
            <RadioSection className="uneditable">
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio1"
                    name={name}
                    value="N"
                    onChange={onChange}
                    checked={value !== '' && value === 'N'}
                    disabled={disabled}
                />
                <p>{text ? text[0] : ''}</p>
              </RadioLabel>
            </RadioSection>
            <RadioSection>
                  <RadioLabel>
                    <InputField
                        type="radio"
                        id="radio2"
                        name={name}
                        value="Y"
                        onChange={onChange}
                        checked={value !== '' && value === 'Y'}
                        disabled={disabled}
                    />
                    <p>{text ? text[1] : ''}</p>
                  </RadioLabel>
                  <SubText>{subText}</SubText>
                </RadioSection>
          </> :
          type === 'os' ? <>
            {text &&
                text.map((item, i) => (
                    <RadioSection key={i}>
                      <RadioLabel>
                        <InputField
                            type="radio"
                            id={`os_radio${i + 1}`}
                            name={name}
                            value={osType[i]}
                            onChange={onChange}
                            checked={value !== '' && value === osType[i]}
                        />
                        <p>{item}</p>
                      </RadioLabel>
                    </RadioSection>
                ))
            }
          </> :
          type === 'list' ? <>
            { text && text.map((item, i) => (
              <RadioSection key={i}>
                <RadioLabel>
                  <InputField
                    type="radio"
                    id={`list_radio${i + 1}`}
                    name={name}
                    value={values ? values[i] : i}
                    onChange={onChange}
                    checked={value === (values ? String(values[i]) : String(i))}
                  />
                  <p>{item}</p>
                </RadioLabel>
              </RadioSection>
            ))}
          </> :
          // else
          <>
            <RadioSection className={type}>
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio1"
                    name={name}
                    value="Y"
                    onChange={onChange}
                    checked={value !== '' && value === 'Y'}
                />
                <p>{text ? text[0] : ''}</p>
              </RadioLabel>
            </RadioSection>
            <RadioSection className={type}>
              <RadioLabel>
                <InputField
                    type="radio"
                    id="radio2"
                    name={name}
                    value="N"
                    onChange={onChange}
                    checked={value !== '' && value === 'N'}
                />
                <p>{text ? text[1] : ''}</p>
              </RadioLabel>
            </RadioSection>
          </>
        }
      </RadioBox>
    </InputContainer>
  );
};

export default RadioButton;

const InputContainer = styled.div`
  display: block;

  &.receiveNightTimeInfoYn {
    width: 100%;
  }
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const RadioBox = styled.div`
  display: flex;
  align-content: center;

  &.eventBattle {
    flex-direction: column;
    gap: 10px;
  }
`;

const RadioSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  line-height: 52px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &.uneditable {
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  }
  
  &.custom {
    margin-right: 5px;
  }
`;

const SubText = styled.span`
  position: absolute;
  top: 48px;
  left: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const RadioLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.color.subGray};
`;

const InputField = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.mainWhite};

  &:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 15% auto;
    border-radius: 50%;
  }

  &:checked:before {
    background-color: ${({ theme }) => theme.color.mainPurple};
  }

  &:checked + p {
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }
}

p {
  margin-left: 10px;
}

`;
