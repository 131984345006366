const getMobileNum = (e: string) => {
  if (e.length === 10) {
    return e.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  if (e.length === 11) {
    return e.replace(/-/g, '').replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  }
  return e;
};

export default getMobileNum;
