import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';

// common components
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import DateInput from '../../../components/common/Input/DateInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';

// constants
import {
	GetLanguageOption,
	GetCountryOption,
	countryOptions,
	languageOptions,
} from '../../../constant/common';

// config
import {NOTICE} from '../../../constant/apiUrl';

// redux
import {setLoading, resetLoading} from '../../../redux/slices/user';

//타입
import {limitCall} from '../../../utils/utils';

const NoticeNormalDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const today = new Date();
	const todayWIthFormat =
			today.getFullYear() +
			'-' +
			('0' + (today.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + today.getDate()).slice(-2) +
			'T' +
			('0' + today.getHours()).slice(-2) +
			':' +
			('0' + today.getMinutes()).slice(-2);

	const detailData = location.state;
	const prevId = detailData !== null ? detailData.prevId : null;

	// 배너 이미지 주소
	const [bannerImgUrl, setBannerImgUrl] = useState('');
	const [bannerImgFile, setBannerImgFile] = useState(null);
	// 프로젝트 명
	const [projectName, setProjectName] = useState('');
	// 공지 제목
	const [title, setTitle] = useState('');
	// 프로젝트 명
	const [description, setDescription] = useState('');
	// 공지 활성화 여부
	const [activeYn, setActiveYn] = useState('Y');
	// 국가 코드
	const [countryCode, setCountryCode] = useState('');
	// 공지사항 적용 국가 언어
	const [language, setLanguage] = useState('');
	// 공지사항 유형 : 일반 공지 이므로 N 고정
	// const [noticeType, setNoticeType] = useState('');
	// 공지 시작 시간
	const [startDt, setStartDt] = useState(todayWIthFormat);
	// 공지 종료 시간
	const [endDt, setEndDt] = useState('2222-12-31 23:59');

	// 이미지 파일 확장자 체크 결과
	const [isFileError, setIsFileError] = useState(false);

	//공지 상세 조회
	const getDetail = useCallback(async () => {
			await axios.get(`${NOTICE}/${prevId}`)
			.then(res => {
				const detail = res.data.notice;
				setBannerImgUrl(detail.bannerImgUrl);
				setProjectName(detail.projectName)
				setTitle(detail.title);
				setDescription(detail.description);
				setActiveYn(detail.activeYn);
				setCountryCode(detail.countryCode);
				setLanguage(detail.language);
				setStartDt(detail.startDt?.replace(' ', 'T').slice(0, 16));
				setEndDt(detail.endDt?.replace(' ', 'T').slice(0, 16));
			})
			.catch(reason => {
				console.error(reason);
				alert('잠시후 다시 이용해주세요.');
			});
	}, [prevId]);

	// 검색 키워드 변경 확인
	const checkProjectNameChange = () =>
			JSON.stringify(
				projectName !== null ? projectName?.split(',') : projectName
			) !== JSON.stringify(projectName);

	// 데이트 저장시 사용
	const handleDate = (date, tRemove, start) => {
		let _date = date;

		if (tRemove && date.indexOf('T') < 0) {
			_date = date.split(' ');
			_date = _date[0] + 'T' + _date[1];
		}
		if (!tRemove && date.indexOf('T') > 0) {
			_date = date.split('T');
			_date = _date[0] + ' ' + _date[1];
		}

		return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
	}

	// 취소하면 뒤로가기
	const goBack = () => navigate('/operation/noticeNormal');

	/* 저장 및 수정 */
	const handleEventBanner = limitCall(async (handleType) => {

		if(isFileError) {
			alert('파일 확장자를 확인해주세요.');
			return;
		}
		// 데이터 유효성 체크
		if (!activeYn || !startDt || !endDt || !title || !countryCode || !language) {
			alert('양식을 모두 채워주세요.');
			return;
		}
		if (startDt > endDt) {
			alert('시작일시가 종료일시보다 느릴 수 없습니다.');
			return;
		}
		if (new Date() > new Date(endDt)) {
			alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
			return;
		}

		const formData = new FormData();
		formData.append('noticeType', 'N');
		formData.append('noticeBannerImgFile', bannerImgFile);

		const request = {};
		if (checkProjectNameChange()) {
			request['projectName'] = projectName;
		}
		request['title'] = title;
		request['description'] = description === null ? '' : description;
		request['activeYn'] = activeYn;
		request['startDate'] = handleDate(startDt, false, true);
		request['endDate'] = handleDate(endDt, false, false);
		request['countryCode'] = countryCode;
		request['language'] = language;

		Object.keys(request).forEach(key => formData.append(key, request[key]));

		dispatch(setLoading());
		await axios.post(
				handleType === 'add' ? NOTICE : `${NOTICE}/${prevId}`,
				formData,
				{}
		)
		.then( () => {
			alert('공지가 저장되었습니다.');
			navigate('/operation/noticeNormal');
		})
		.catch(reason => {
			alert('잠시후 다시 이용해주세요');
			console.error(reason);
		})
		.finally(() => dispatch(resetLoading()))

	}, 'event-detail');

	// 수정으로 페이지 진입시, 공지 상세 조회
	useEffect(() => {
		if (prevId) {
			getDetail().then((r) => r);
		}
	}, [getDetail, prevId]);

	// 이미지 파일 변경시 확장자 검사
	useEffect(() => {
		// const eventFileNm = bannerImgFile?.name;
		const getImgType = (file) => file.slice(file.lastIndexOf('.') + 1).toLowerCase();

		if (bannerImgFile && getImgType(bannerImgFile.name) !== 'jpg') {
			setIsFileError(true);
		} else {
			setIsFileError(false);
		}

	}, [bannerImgFile]);

	return <>
		<TopContainer>
			<ButtonWrapper>
				<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>
				<NormalButton
					text={prevId ? '수정하기' : '저장하기'}
					onSubmit={() => handleEventBanner(prevId ? 'edit' : 'add').then(() => dispatch(resetLoading()))}
				/>
			</ButtonWrapper>
		</TopContainer>
		<TitleText>공지 기본 정보</TitleText>
		<LineDiv/>
		<MainContainer>
			{/*공지 이미지*/}
			<InputWrapper span={2}>
				<FileWrapper>
					<FileInput
						type="eventImg" label="공지 이미지 (jpg 파일만 등록 가능)" name="eventBannerFile"
						placeholder={bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
						value={bannerImgFile}
						setFile={(e) => setBannerImgFile(e)}
						setImgUrl={setBannerImgUrl}
						// fileUrl={eventBannerImgUrl}
						Required
					/>
					{isFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
					<CoverImgWrapper>
						{bannerImgUrl ? <EventImg src={bannerImgUrl } /> : <EventImg />}
					</CoverImgWrapper>
				</FileWrapper>
			</InputWrapper>

			{/*프로젝트 명*/}
			<InputWrapper span={2}>
				<LabelTextInput
					name="projectName" label="프로젝트 명" placeholder="프로젝트 명을 입력해주세요."
					value={projectName} onChange={(e) => setProjectName(e.target.value)} onReset={() => setProjectName('')}
					Required
				/>
			</InputWrapper>

			{/*제목*/}
			<InputWrapper span={2}>
				<LabelTextInput
					name="title" label="제목" placeholder="제목을 입력해주세요."
					value={title} onChange={(e) => setTitle(e.target.value)} onReset={() => setTitle('')}
					Required
				/>
			</InputWrapper>

			{/*설명*/}
			<InputWrapper span={2} mb={15}>
				<LabelTextInput
					name="description" label="설명" placeholder="설명을 입력해주세요."
					value={description} onChange={(e) => setDescription(e.target.value)} 	onReset={() => setDescription('')}
				/>
			</InputWrapper>

			{/*공개 여부*/}
			<InputWrapper span={2}>
				<RadioButton
					name="activeYn" label="공개 여부" type="activeYn"
					value={activeYn} onChange={(e) => setActiveYn(e.target.value)} text={['공개', '비공개']}
					Required
				/>
			</InputWrapper>

			{/*게시 시작,종료일*/}
			<InputWrapper span={2} mb={15}>
				<DateWrapper>
					<DateInput initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)} name="startDt" label="게시 시작일" Required/>
					<DateInput initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)} name="endDt" label="게시 종료일" Required/>
				</DateWrapper>
			</InputWrapper>

			{/*국가*/}
			<InputWrapper>
				<SelectModule
					name="countryCode" placeholder="국가를 선택해주세요." label="국가"
					value={GetCountryOption[countryCode]} onChange={e => setCountryCode(e.value)} options={countryOptions}
					Required
				/>
			</InputWrapper>

			{/*언어*/}
			<InputWrapper>
				<SelectModule
					name="language" placeholder="언어 코드를 선택해주세요." label="언어 코드"
					value={GetLanguageOption[language]} onChange={e => setLanguage(e.value)} options={languageOptions}
					Required
				/>
			</InputWrapper>
		</MainContainer>
	</>;
};

export default NoticeNormalDetail;

const TopContainer = styled.div`
  // position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 40px;
  grid-gap: 15px 40px;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const ErrorText = styled.span`
  position: absolute;
  top: 60px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({theme}) => theme.color.mainRed};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  
  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }

  &.external-add {
    text-align: right;
    display: block !important;
  }

  &.area2 {
    background-color: #f1f3f5;
    padding: 25px;
    border-radius: 6px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  gap: 30px;
  grid-gap: 15px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;
