import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';

// config
import { TAB_MOOD } from '../../../../constant/apiUrl';

const ExposureMood = () => {
  const navigate = useNavigate();

  const [moodList, setMoodList] = useState(null);
  const getMoodTabList = async () => {
    try {
      const resp = await axios.get(TAB_MOOD, {
        params: { limit: 20, offset: 0 },
      });
      setMoodList(resp.data.masterRecommendTabMoodList);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => getMoodTabList(), []);

  return (
    <>
      <TopContainer>
        <NormalButton text="수정하기" onSubmit={() => navigate('edit')}/>
      </TopContainer>
      <MainContainer>
        <Ul>
          {moodList?.map((item) => (
            <Li key={item.sequence}>
              <GenreIndex>{item.sequence}</GenreIndex>
              <GenreName>{item.masterMood}</GenreName>
            </Li>
          ))}
        </Ul>
      </MainContainer>
    </>
  );
};
export default ExposureMood;

const TopContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  margin-top: 40px;
`;

const Ul = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const GenreIndex = styled.div`
  width: 73px;
  margin-right: 24px;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.mainPurple};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const GenreName = styled.div`
  width: calc(100% - 98px);
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;
