import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// common
import NormalButton from '../../components/common/Button/NormalButton';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import ColumnList from '../../components/common/List/ColumnList';

// 액션
import { getMoodList } from '../../redux/actions/mood/mood';
import { getDetailMood, resetDetailMood } from '../../redux/slices/mood';

// constant
import { postsPerPage } from '../../constant/common';

// hooks
import { useAppSelector } from '../../redux/store/hooks';
import useInput from '../../hooks/useInput';
import saveStorage from '../../hooks/saveStorage';

const Mood = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const moodData = useAppSelector((state) => state.mood);
  const getNumber = localStorage.getItem('pageNumber');

  const { masterMoodList, count } = moodData;
  const [word, setWord] = useInput('');

  // pagination state
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );
  const params = {
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 검색
  const enterKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      dispatch(getMoodList(params));
    }
  };

  // 무드 추가하기 이동
  const goToAddMood = () => {
    dispatch(resetDetailMood());
    navigate('/beat/mood/add');
  };

  // 무드 상세로 이동
  const goToDetail = (e: any) => {
    dispatch(getDetailMood(e));
    // 페이지 저장
    saveStorage(pageNumber, '', '');
    navigate('/beat/mood/detail');
  };

  useEffect(() => {
    dispatch(getMoodList(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);

  useEffect(() => {
    if (getNumber) {
      window.localStorage.setItem('pageNumber', '0');
    }
  }, [getNumber]);

  return (
    <>
      <TopContainer>
        <SearchWrapper>
          <SearchInputWrapper>
            <SearchInput
              value={word}
              onChange={setWord}
              onKeyPress={enterKeyPress}
            />
          </SearchInputWrapper>
          <NormalButton text="무드 추가하기" onSubmit={goToAddMood} />
        </SearchWrapper>
        <PaginationWrapper>
          <Title>{count}개의 무드</Title>
          {Number(count) !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={Number(count)}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        {masterMoodList && (
          <ColumnList
            menu={masterMoodList}
            type="mood"
            goToDetail={goToDetail}
          />
        )}
      </MainContainer>
    </>
  );
};

export default Mood;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 25px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.font.titleFont};
`;
