import React, { useRef, useState, useEffect } from 'react';

//컴포넌트
import styled from 'styled-components';
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';
import { IsPrivate } from '../../../../constant/common';
import smallPlayIcon from '../../../../assets/images/page/smallPlayIcon.png';
import smallPauseIcon from '../../../../assets/images/page/smallPauseIcon.png';

// 타입
import { RowComponentDataProps } from '../../../../definitions/beat';

// hooks
import handleDate from '../../../../hooks/handleDate';
import EditButton from '../../../../components/common/Button/EditButton';

// 인터페이스
interface dataProps {
  data: RowComponentDataProps;
  playBeat?: any;
  setPlayBeat?: any;
  onClick?: any;
  selected?: boolean;
  name?: string;
  editable?: boolean;
  type?: string;
}

interface PlayIconProps {
  url?: string;
}

const DetailAddBeatRowComponents = ({
  data,
  playBeat,
  setPlayBeat,
  onClick,
  selected,
  name,
  editable,
  type,
}: dataProps) => {
  const {
    id,
    coverImgUrl,
    contentsUrl,
    title,
    bmPid,
    genreNames,
    bpm,
    key,
    privateYn,
    startDt,
    beatMakerPid,
  } = data;

  const audioPlayer = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleAudio = (e: any) => {
    e.stopPropagation();
  };

  const togglePlayPause = (e: any) => {
    e.stopPropagation();
    const prevValue = isPlaying;
    setPlayBeat(id);
    setIsPlaying(!prevValue);
    if (prevValue) {
      audioPlayer.current?.play();
    } else {
      audioPlayer.current?.pause();
    }
  };

  useEffect(() => {
    if (playBeat !== id) {
      setIsPlaying(true);
      audioPlayer.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playBeat]);

  return (
    <tr>
      <td>
        <ListCoverImg url={coverImgUrl} onClick={(e) => handleAudio(e)} />
        <AudioPlay>
          <Audio controls src={contentsUrl} ref={audioPlayer} />
          {/*<track default kind="captions" />*/}
          <PlayButton onClick={(e) => togglePlayPause(e)}>
            {isPlaying ? (
              <PlayIcon url={smallPlayIcon} className="play" />
            ) : (
              <PlayIcon url={smallPauseIcon} className="pause" />
            )}
          </PlayButton>
        </AudioPlay>
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={bmPid || beatMakerPid} />
      </td>
      <td>
        <ListItem text={genreNames?.join(', ')} />
      </td>
      <td>
        <ListItem text={bpm} />
      </td>
      <td>
        <ListItem text={key} />
      </td>
      <td>
        <ListItem className={privateYn === 'Y' ? 'private' : ''} text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={startDt?handleDate(startDt):''} />
      </td>
      {!type && editable && (
        <td>
          {selected ? (
            <EditButton
              onClick={() => onClick(data, name)}
              text="삭제"
              className="deleteBtn"
            />
          ) : (
            privateYn !== 'Y' && <EditButton
              onClick={() => onClick(data)}
              text="선택"
              className="selectBtn"
            />
          )}
        </td>
      )}
    </tr>
  );
};

export default DetailAddBeatRowComponents;

const AudioPlay = styled.div`
  display: block;
  margin: -45px 0 0 0;
`;

const Audio = styled.audio`
  width: 0;
  height: 0;
  overflow: hidden;

  audio::-webkit-media-controls-play-button {
    -webkit-appearance: none;
  }
  audio::-internal-media-controls-download-button {
    display: none;
  }
  audio::-webkit-media-controls-enclosure {
    display: none;
  }
  audio::-webkit-media-controls-mute-button {
    -webkit-appearance: none;
  }
  audio::-webkit-media-controls-panel {
    background-color: transparent;
  }
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    color: #1e140d;
  }
`;

const PlayButton = styled.button``;

const PlayIcon = styled.div<PlayIconProps>`
  width: 44px;
  height: 44px;
  &.play {
    &:hover {
      background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
      background-size: 12px;
      border-radius: 3px;
    }
  }

  &.pause {
    background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
    background-size: 12px;
    border-radius: 3px;
  }
`;
