import React from 'react';
import styled from 'styled-components';

interface VideoInputProps {
  text: string;
  subText?: string;
  Required?: boolean;
  onClick?: any;
  isBaundMusic?: boolean;
  className?: string | undefined;
}

const VideoInput = ({
    onClick,
    text,
    subText,
    className
}: VideoInputProps) => {

  return (
    <InputContainer className={className}>
      <InputBox className={className} onClick={onClick}>{text}</InputBox>
      <InputText>{subText}</InputText>
    </InputContainer>
  );
};

export default VideoInput;

const InputContainer = styled.div`
  display: inline-block;

  &.eventBattle {
    position: absolute;
    top: 161px;
    left: 400px;
    width: 100%;
  }
`;

const InputText = styled.span`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const InputBox = styled.span`
  display: inline-block;
  padding: 10px 14px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: ${({ theme }) => theme.color.mainWhite};
  background-color: ${({ theme }) => theme.color.subDarkGray};
  cursor: pointer;
  
  &.square {
    border-radius: 0px;
  }
`;
