import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { CULTURE_PROGRAM } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';

import FileInput from '../../../components/common/Input/FileInput';
import CultureMenuSelect from '../component/CultureMenuSelect';

const CultureProgramDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const today = new Date();
  const todayWIthFormat =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2) +
    'T' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2);

  const detailData: any = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [channelId, setChannelId] = useState(
    detailData !== null ? detailData.channelId : 0,
  );
  const [channelName, setChannelName] = useState(
    detailData !== null ? detailData.channelName : '채널 선택',
  );
  const [cultureChannels, setCultureChannels] = useState<any>(
    detailData !== null ? detailData.cultureChannels : [],
  );

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [descriptionDetailEn, setDescriptionDetailEn] = useState('');
  const [coverImg, setCoverImg] = useState<string>('');
  const [coverImgFile, setCoverImgFile] = useState<any>(null);
  const [headerImg, setHeaderImg] = useState<string>('');
  const [headerImgFile, setHeaderImgFile] = useState<any>(null);
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState(todayWIthFormat);
  const [endDt, setEndDt] = useState('2222-12-31 23:59');

  const handleSelectSetData = (e: any) => {
    setChannelId(e.id);
    setChannelName(e.name);
  };

  useEffect(() => {
    const result = cultureChannels.filter(
      (item: any) => item.id === channelId,
    )[0];
    if (result) {
      setChannelName(result.name);
    }
  }, [channelId, cultureChannels]);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(`${CULTURE_PROGRAM}/${prevId}/detail`);
      if (resp.data.adminCultureProgram) {
        setChannelId(resp.data.adminCultureProgram.cultureChannelId);
        setName(resp.data.adminCultureProgram.name);
        setNameEn(resp.data.adminCultureProgram.nameEn);
        setDescription(resp.data.adminCultureProgram.description);
        setDescriptionEn(resp.data.adminCultureProgram.descriptionEn);
        setDescriptionDetail(resp.data.adminCultureProgram.descriptionDetail);
        setDescriptionDetailEn(
          resp.data.adminCultureProgram.descriptionDetailEn,
        );
        setCoverImg(
          resp.data.adminCultureProgram.coverImgUrl
            ? resp.data.adminCultureProgram.coverImgUrl
            : '',
        );
        setHeaderImg(
          resp.data.adminCultureProgram.headerImgUrl
            ? resp.data.adminCultureProgram.headerImgUrl
            : '',
        );
        setSequence(resp.data.adminCultureProgram.sequence);
        setActiveYn(resp.data.adminCultureProgram.activeYn);
        setStartDt(
          resp.data.adminCultureProgram.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCultureProgram.endDt?.replace(' ', 'T').slice(0, 16),
        );
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  const addContents = async (type: string) => {
    if (!channelId) {
      alert('채널을 선택해주세요.');
      return;
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }
    // if (!coverImg) {
    //   alert('프로그램 커버 이미지를 입력해주세요.');
    //   return;
    // }
    // if (!headerImg) {
    //   alert('프로그램 상세 이미지를 입력해주세요.');
    //   return;
    // }

    const formData = new FormData();
    formData.append('cultureChannelId', channelId);
    formData.append('name', name);
    formData.append('nameEn', nameEn);
    if (description != null) {
      formData.append('description', description);
    }
    if (descriptionEn != null) {
      formData.append('descriptionEn', descriptionEn);
    }
    if (descriptionDetail != null) {
      formData.append('descriptionDetail', descriptionDetail);
    }
    if (descriptionDetailEn != null) {
      formData.append('descriptionDetailEn', descriptionDetailEn);
    }
    if (coverImgFile != null) {
      formData.append('coverImg', coverImgFile);
    }
    if (headerImgFile != null) {
      formData.append('headerImg', headerImgFile);
    }
    formData.append('sequence', sequence);
    formData.append('activeYn', activeYn);
    formData.append('startDate', `${startDt.replace('T', ' ')}:59`);
    formData.append('endDate', `${endDt.replace('T', ' ')}:59`);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${CULTURE_PROGRAM}/${prevId}/edit`, formData, {});
      } else {
        await axios.post(`${CULTURE_PROGRAM}`, formData, {});
      }
      await navigate('/culture/program');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <CoverImgWrapper>
          {coverImg ? <EventImg src={coverImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버 이미지 : 목록 표시용"
            value={coverImgFile}
            setFile={(e: string) => setCoverImgFile(e)}
            setImgUrl={setCoverImg}
            Required
          />
        </InputWrapper>
        <CoverImgWrapper>
          {headerImg ? <EventImg src={headerImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              headerImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="헤더 이미지 : 상세 상단 표시용"
            value={headerImgFile}
            setFile={(e: string) => setHeaderImgFile(e)}
            setImgUrl={setHeaderImg}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <CultureMenuSelect
            placeholder="채널을 선택해주세요."
            value={channelName}
            onChange={handleSelectSetData}
            options={cultureChannels}
            label="채널"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="프로그램 명"
            value={name}
            placeholder="프로그램 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="프로그램 명 EN"
            value={nameEn}
            placeholder="프로그램 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="프로그램 설명"
            value={description}
            placeholder="프로그램 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="프로그램 설명 EN"
            value={descriptionEn}
            placeholder="컨텐츠 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionDetail"
            label="프로그램 상세 설명"
            value={descriptionDetail}
            placeholder="프로그램 상세 설명을 입력해 주세요."
            onChange={(e) => setDescriptionDetail(e.target.value)}
            onReset={() => setDescriptionDetail('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionDetailEn"
            label="프로그램 상세 설명 EN"
            value={descriptionDetailEn}
            placeholder="컨텐츠 상세 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionDetailEn(e.target.value)}
            onReset={() => setDescriptionDetailEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default CultureProgramDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    grid-row: 1;
  }

  &:nth-child(3) {
    grid-row: 2;
  }
`;

const InputWrapper = styled.div`
  &:nth-child(2) {
    grid-row: 1;
  }

  &:nth-child(4) {
    grid-row: 2;
  }

  &:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
  }

  &:nth-child(n + 6):nth-child(-n + 7) {
    grid-row: 4;
  }

  &:nth-child(n + 8):nth-child(-n + 9) {
    grid-row: 5;
  }

  &:nth-child(n + 10):nth-child(-n + 11) {
    grid-row: 6;
  }

  &:nth-child(12) {
    grid-row: 7;
    grid-column: span 2;
  }

  &:nth-child(n + 13):nth-child(-n + 14) {
    grid-row: 8;
  }
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;
