import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import Pagination from '../../components/common/Pagination';

// config
import { MISSIONS_POINT_GUIDE_AREA } from '../../constant/apiUrl';

// constant
import { postsPerPage } from '../../constant/common';

const PointGuideAreaList = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);
  
  const getContents = useCallback(async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber,
    };
    await axios
      .get(`${MISSIONS_POINT_GUIDE_AREA}`, { params })
      .then((resp) => {
        setTotalCount(resp.data.totalCount);
        setContentsList(resp.data.adminPointGuideAreaList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contentsList, pageNumber]);

  useEffect(() => {
    getContents();
  }, [pageNumber]);

  const goToDetail = (state: any) => {
    navigate(`/rewards/point-guide-area-detail`, {
      state: {
        languageType: state.languageType,
      },
    });
  };

  return (
    <Container>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="언어 유형" />
              <ListHead title="기준 미만 포인트 메시지" />
              <ListHead title="기준 미만 포인트 폰트 크기" />
              <ListHead title="기준 초과 포인트 메시지" />
              <ListHead title="기준 초과 포인트 폰트 크기" />
              <ListHead title="기준 포인트" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.missionId} onClick={() => goToDetail(el)}>
              <td>
                <ListItem text={el.languageType} />
              </td>
              <td>
                <ListItem text={el.lessPointMessage} />
              </td>
              <td>
                <ListItem text={el.lessPointMessageSize} />
              </td>
              <td>
                <ListItem text={el.overPointMessage} />
              </td>
              <td>
                <ListItem text={el.overPointMessageSize} />
              </td>
              <td>
                <ListItem text={el.standardPoint} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default PointGuideAreaList;

export const colList = [80, 150, 150, 100, 100, 80];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
