import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NotExposure from '../../../../components/UI/NotExposure';
import NormalButton from '../../../../components/common/Button/NormalButton';

// hook
import handleDate from '../../../../hooks/handleDate';

// 타입
import { RecommendBeatMakerType } from '../../../../definitions/explore';

// config
import {EXPLORE, RECOMMEND_BEATMAKER,} from '../../../../constant/apiUrl';
import blankImg from '../../../../assets/images/page/blankImg.png';

const RecommendBeatMaker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { detailData = {}, tabType = 'ALL' }: any = location.state || {};
  const [listType, setListType] = useState(true);
  const [list, setList] = useState<Array<RecommendBeatMakerType> | null>(null);
  const getRecommendBeatMakerList = useCallback(
    async (type: boolean) => {
      const params = { limit: 100, offset: 0, type: '' };
      try {
        if (!type) {
          params.type = 'disable';
        }
        if (tabType === 'GENRE') {
          const resp = await axios.get(
            `${EXPLORE}/recommend/${detailData?.masterGenreId}/beat-maker`,
            { params },
          );
          setList(resp.data.masterRecommendBeatMakerList);
        }
        if (tabType === 'ALL') {
          const resp = await axios.get(RECOMMEND_BEATMAKER, { params });
          setList(resp.data.masterRecommendBeatMakerList);
        }
      } catch (err) {
        setList([]);
        console.log(err);
      }
    },
    [detailData?.masterGenreId, tabType],
  );

  useEffect(() => {
    getRecommendBeatMakerList(listType);
  }, [listType, getRecommendBeatMakerList]);

  return (
    <Container>
      <RegisterButtonWrapper>
        {detailData.masterGenre && tabType === 'GENRE' ? (
          <MasterGenre>{detailData?.masterGenre}</MasterGenre>
        ) : (
          <div>
            <RoundButton
              title="공개"
              selected={listType}
              onClick={() => {
                setListType(true);
              }}
            />
            <RoundButton
              title="비공개"
              selected={!listType}
              onClick={() => {
                setListType(false);
              }}
            />
          </div>
        )}
        <NormalButton
          className="cancelBtn"
          text="등록하기"
          onSubmit={() => {
            navigate('add', {
              state: {
                masterGenreId: detailData.masterGenreId,
                masterGenre: detailData?.masterGenre,
                type: 'add',
              },
            });
          }}
        />
      </RegisterButtonWrapper>
      {detailData.masterGenre && tabType === 'GENRE' && (
        <>
          <RoundButton
            title="공개"
            selected={listType}
            onClick={() => {
              setListType(true);
            }}
          />
          <RoundButton
            title="비공개"
            selected={!listType}
            onClick={() => {
              setListType(false);
            }}
          />
        </>
      )}
      {list?.length !== 0 ? (
        <MainContainer>
          {list?.map((item) => (
            <Li key={item.id}>
              {item.beatMakerProfileImgUrl ? (
                <Img src={item.beatMakerProfileImgUrl} alt="coverImg" />
              ) : (
                <Img src={blankImg} alt="defaultImg" />
              )}
              <TextWrapper>
                <TitleContainer>
                  <Title>
                    <span>{item.id}.</span> {item.beatMakerPid}
                  </Title>
                  <GenreName>{item.beatMakerPid}</GenreName>
                </TitleContainer>
                <ButtonWrapper>
                  <DateTitle>
                    {`${handleDate(item.recommendStartDt)} ~ ${handleDate(
                      item.recommendEndDt,
                    )}`}
                  </DateTitle>
                  <NormalButton
                    text="수정하기"
                    onSubmit={() => {
                      navigate('edit', { state: item });
                    }}
                  />
                </ButtonWrapper>
              </TextWrapper>
            </Li>
          ))}
        </MainContainer>
      ) : (
        <NotExposure
          title="추천 비트메이커"
          link="add"
          onSubmit={() => {
            if (Object.keys(detailData).length !== 0) {
              navigate('add', {
                state: {
                  masterGenreId: detailData.masterGenreId,
                  masterGenre: detailData.masterGenre,
                  type: 'add',
                },
              });
            } else {
              navigate('add', { state: {} });
            }
          }}
        />
      )}
    </Container>
  );
};

export default RecommendBeatMaker;

const Container = styled.div`
  width: 100%;
`;

const RegisterButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 36px;
`;

const MasterGenre = styled.div`
  height: 30px;
  font-size: 24px;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
`;

const Li = styled.li`
  display: flex;
  width: 100%;
  padding: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 205px);
  margin: 20px 0;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.div`
  height: 45px;
  margin-bottom: 16px;
  flex-grow: 0;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};

  span {
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const GenreName = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  margin-right: 30px;
  border-radius: 3px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DateTitle = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.color.subGray};
`;
