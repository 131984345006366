import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

// asset
import styled from 'styled-components';
import dragAndDrop from '../../../../assets/images/page/dragAndDrop.png';

// component
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hook
import handleDate from '../../../../hooks/handleDate';

const RowComponent = ({ data, index, moveListItem, setIndex }: any) => {
  const { beatId, beatTitle, beatCoverImgUrl, exposeEndDt, exposeStartDt } =
    data;

  const componentRef = useRef<any>(null);

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'item',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),

    end: (item) => {
      setIndex();
    },
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'item',
    hover: (item: any, monitor: any) => {
      // const dragIndex = item.index;
      const hoverIndex = index;
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
      //
      // // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = item.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      item.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
    (node: any) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );

  return (
    <BeatRow ref={applyRef}>
      <img src={dragAndDrop} alt="dnd icon" />
      <ListItem text={beatId} />
      <ListCoverImg url={beatCoverImgUrl} />
      <ListItem text={beatTitle} />
      <TagStartDt>{handleDate(exposeStartDt)}</TagStartDt>
      <TagEndDt>{handleDate(exposeEndDt)}</TagEndDt>
    </BeatRow>
  );
};

export default RowComponent;

const BeatRow = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 5px 0.8fr 1.2fr 2fr 1.8fr 1.8fr;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};

    img {
      width: 15px;
    }
  }
`;

const TagStartDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
const TagEndDt = styled.span`
  font-size: 12px;
  font-weight: 400;
`;
