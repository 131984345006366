import styled from "styled-components";
import { useNavigate} from 'react-router';
import React, {useEffect, useState} from "react";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";

import NormalButton from "../../../components/common/Button/NormalButton";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import LabelTextAreaInput
  from "../../../components/common/Input/LabelTextAreaInput";
import axios from "axios";
import {ADVERTISER} from "../../../constant/apiUrl";

// 광고주 상세 화면(추가, 수정)
const AdvertiserDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  // 여기서는 광고주 아이디
  const advertiserId = detailData !== null ? detailData.advertiserId : null;

  // 광고주 문자열 아이디
  const [advertiserStrId, setAdvertiserStrId] = useState('');
  // 광고주 명
  const [name, setName] = useState('')
  // 메모
  const [memo, setMemo] = useState('');

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
      await axios.get(`${ADVERTISER}/${advertiserId}`,)
      .then(res => {
        const detail = res.data.adminAdvertiser;
        setAdvertiserStrId(detail.advertiserStrId);
        // 광고주 명
        setName(detail.name);
        // 메모
        setMemo(detail.memo);
      })
      .catch(reason => {
        console.error(reason)
        alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
      });
  };
  // 초기 진입시 아이디 받았으면 데이터 가져오기
  useEffect(() => {
    if (advertiserId) {
      getDetail().then((r) => r);
    }
  }, [advertiserId]);

  // 정보 저장
  const saveDetail = async () => {

    const request = {};

    // 광고주 명
    if (!name) { alert('아래 값을 확인해주세요.\n- 광고주 이름'); return; }
    request['name'] = name;
    // 메모
    request['memo'] = memo;

    dispatch(setLoading());
    await axios({
        method:(advertiserId)? 'put':'post',
        url:(advertiserId)?`${ADVERTISER}/${advertiserId}`:`${ADVERTISER}`,
        params: request,
    })
    .then(() => {
      alert('저장이 완료 되었습니다.');
      // navigate('/advertisement/advertiser');
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    })
    .finally(() => {
      dispatch(resetLoading());
    });
  };

  return <>
    <TitleText>광고주 {(advertiserId) ?'수정' :'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper>
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>

    <MainContainer>
      {/* 광고주 ID*/}
      <InputWrapper>
        <LabelTextInput
            name="advertiserStrId" label="광고주 ID" placeholder="광고주 생성하면 ID가 생성돼요." readOnly
            value={advertiserStrId}
        />
      </InputWrapper>

      {/* 광고주 명*/}
      <InputWrapper>
        <LabelTextInput
            name="name" label="광고주 이름" placeholder="광고주 이름을 입력해주세요." Required
            value={name} maxLen={30}
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
        />
      </InputWrapper>

      {/* 메모*/}
      <InputWrapper>
        <LabelTextAreaInput
            name="memo" label="메모" placeholder={`광고주 담당자(연락처, 메일), 내부 담당자 등 해당 광고와 관련된 추가 정보를 입력해 보아요.
- 담당자: 김샤넬(010-0000-0000 / abcd@chanel.com)
- 내부 담당자: 이바운드(바운드팀)`}
            value={memo}
            setValue={(e) => setMemo(e.target.value)}
            onReset={() => setMemo('')}
        />
      </InputWrapper>
    </MainContainer>
  </>;
};
export default AdvertiserDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
    grid-row: ${({row}) => row && `${row}`};
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '5px'};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

    &.area1 {
      background-color: #ccc;
      padding: 25px;
      border-radius: 6px;
    }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;