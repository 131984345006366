import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import styled from 'styled-components';
import {useNavigate} from 'react-router-dom';

import axios from 'axios';
import {createAsyncThunk} from '@reduxjs/toolkit';
import Pagination from '../../components/common/Pagination';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import {COUPONS} from '../../constant/apiUrl';
import { postsPerPage } from '../../constant/common';
import {useLocation} from 'react-router';
import TextField from '../../components/common/TextField';
import NormalButton from "../../components/common/Button/NormalButton";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";

interface Coupon {
  masterCouponId: number;
  name: string;
  privateYn: string;
  value: number;
  issuanceType: string;
  issuanceCount: number;
  maxIssuanceCount: number;
  usedYn: string;
  startDateTime: string;
  endDateTime: string;
  createDate: string;
  updateDate: string;
  code: string;
  deliveredYn: string;
}

interface CouponDetailParam {
  couponProjectId: number;
  masterCouponId: number;
  name: string;
  privateYn: string;
  value: number;
  issuanceType: string;
  issuanceCount: number;
  maxIssuanceCount: number;
  usedYn: string;
  startDateTime: string;
  endDateTime: string;
  createDate: string;
  updateDate: string;
  code: string;
  deliveredYn: string;
  checkIdList: number[];
}

interface SearchParam {
  issuanceType: string;
  parentId: number;
  offset: number;
  limit: number;
}

// 골드 캠페인 목록 화면
const GenerateCodeCouponDetailList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { state } = location;
  const { couponProjectId } = state;
  // 페이징
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // 표시 데이터
  const [masterCouponList, setMasterCouponList] = useState([]);
  const [couponDetailParams, setCouponDetailParams] = useState([]);

  const [checkIdList, setCheckIdList] = useState([]);

  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params: SearchParam = {
    issuanceType: 'GENERATE',
    parentId: couponProjectId,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  const initCouponDetailParam = (
    targetList: CouponDetailParam[],
  ): CouponDetailParam[] => {
    return targetList.map((target) => {
      const targetObj = JSON.parse(JSON.stringify(target));
      targetObj.couponProjectId = couponProjectId;
      targetObj.checkIdList = checkIdList;
      return targetObj;
    });
  };
  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getCoupons = createAsyncThunk(
    'coupon/Coupons',
    async (parameters: SearchParam) => {
      await axios
        .get(COUPONS, { params: parameters })
        .then((res) => {
          const { data } = res;
          setTotalCount(data.count);
          setMasterCouponList(data.masterCouponList);
          setCouponDetailParams(initCouponDetailParam(data.masterCouponList));
        })
        .catch((error) => alert(error.response));
    },
  );
  //검색 조건 변경시 dispatch
  useEffect(() => {
    dispatch(getCoupons(params));
  }, [dispatch, pageNumber]);

  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getExcelDownloadCouponData = async (parameters: SearchParam) => {
    return axios
      .get(COUPONS, { params: parameters })
      .then((res) => {
        return res.data.masterCouponList;
      })
      .catch((error) => alert(error.response));
  };
  const getDataArray: () => Promise<Coupon[][]> = async () => {
    const limit = 100;
    let maxLoopCount;
    const remain = totalCount % limit;
    if (remain > 0) {
      maxLoopCount = totalCount / limit + 1;
    } else {
      maxLoopCount = totalCount / limit;
    }
    const excelSearchParams: SearchParam = {
      issuanceType: 'GENERATE',
      parentId: couponProjectId,
      offset: 0,
      limit,
    };
    // 엑셀 출력용 데이터 조회
    const rawDataArr: Coupon[][] = [];
    for (let idx = 0; idx < maxLoopCount; idx += 1) {
      excelSearchParams.offset = idx * limit;
      // eslint-disable-next-line no-await-in-loop
      const dataList = await getExcelDownloadCouponData(excelSearchParams);
      rawDataArr.push(dataList);
    }
    return rawDataArr;
  };
  // 엑셀 다운로드
  const excelDownload = async () => {
    if (totalCount <= 0) return;

    const rawDatas = await getDataArray();
    // 필요 항목
    const excelDatas = rawDatas
        // 2중배열 -> 1중배열 변환
      .flatMap((el) => el)
        // Coupon Object -> Excel Data Object
      .map((row) => ({
        masterCouponId: row.masterCouponId,
        name: row.name,
        value: row.value,
        couponPeriod: convertCouponPeriod(row.startDateTime, row.endDateTime),
        code: row.code,
        issuanceCount: convertIssuanceCount(row.issuanceCount),
        deliveredYn: convertDeliveredYn(row.deliveredYn),
      }));
    // 워크 시트 작성
    const worksheet = XLSX.utils.json_to_sheet(excelDatas);
    // 컬럼명 변경
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [
        [
          '쿠폰Id',
          '쿠폰명',
          '가격',
          '유효기간',
          '코드',
          '등록 멤버 수',
          '전달 여부',
        ],
      ],
      { origin: 'A1' },
    );
    // 열 폭 지정
    worksheet['!cols'] = [
      { wpx: 50 },
      { wpx: 120 },
      { wpx: 50 },
      { wpx: 180 },
      { wpx: 180 },
      { wpx: 70 },
      { wpx: 70 },
    ];
    // 쿠폰 이름으로 워크시트명 지정
    const workSheetName = '쿠폰_목록';
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, workSheetName);
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    const excelFile = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    // 다운로드 파일 명
    const today = new Date();
    const fileName = `쿠폰_목록_${today.getFullYear()}.${(today.getMonth() + 1) > 9 ? (today.getMonth() + 1).toString() : 0 + (today.getMonth() + 1)}.${today.getDate() > 9 ? today.getDate().toString() : 0 + today.getDate().toString()}_${today.getHours() > 9 ? today.getHours().toString() : "0" + today.getHours().toString()}.${today.getMinutes() > 9 ? today.getMinutes().toString() : "0" + today.getMinutes().toString()}.${today.getSeconds() > 9 ? today.getSeconds().toString() : "0" + today.getSeconds().toString()}.xlsx`;
    FileSaver.saveAs(excelFile, fileName);
  };
  const createCopyCodes = (
    idList: number[],
    couponList: Coupon[],
  ): string | null => {
    if (!idList || idList.length <= 0) {
      alert('복사할 쿠폰을 선택 해 주세요.');
    }
    try {
      const couponCodeList = idList.map((id) => {
        return couponList
          .filter((coupon) => coupon.masterCouponId === id)
          .map((coupon) => coupon.code);
      });
      return couponCodeList.join(',\n');
    } catch (e) {
      alert(`코드 생성에 실패 하였습니다.`);
    }
    return null;
  };
  const copyCode = (code: string) => {
    try {
      // navigator.clipboard.writeText(createdCode);
      const textArea = document.createElement("textarea");
      textArea.id = couponProjectId;
      textArea.value = code;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
    } catch (e) {
      alert(`코드 복사 중 에러가 발생했습니다.${e}`);
    }
    alert('코드 복사 완료');
  };
  const deliveryCheck = async (masterCouponIds: number[]) => {
    await axios.patch(`${COUPONS}/check/delivered-yn`, { masterCouponIds })
      .then(res => {
        alert('체크 완료');
        window.location.reload();
      })
      .catch(err => alert('체크 실패'));
  };
  return (
    <>
      <TopContainer>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={(e) => setPageNumber(e - 1)}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>
      <TotalCountWrapper>
        <TextField label="발행한 쿠폰 총 갯수" text={`${totalCount} 개`} />
      </TotalCountWrapper>
      <ButtonWrapper>
        <NormalButton
          text="선택 쿠폰 코드 복사"
          onSubmit={() => {
            const createdCode = createCopyCodes(
              checkIdList,
              couponDetailParams,
            );
            if (createdCode) {
              copyCode(createdCode);
            }
          }}
        />
        <NormalButton
          text="전체 쿠폰 엑셀 다운로드"
          onSubmit={() => excelDownload()}
        />
        <NormalButton
          text="전달 완료 체크"
          onSubmit={() => deliveryCheck(checkIdList)}
        />
      </ButtonWrapper>
      <MainContainer>
        <Table
          colList={[50, 50, 60, 60, 80, 80, 100, 100]}
          thead={
            <>
              <ListHead title="선택" />
              <ListHead title="Id" />
              <ListHead title="쿠폰 명" />
              <ListHead title="쿠폰 가격" />
              <ListHead title="사용 유효기간" />
              <ListHead title="시리얼 번호" />
              <ListHead title="등록 여부" />
              <ListHead title="전달 여부" />
            </>
          }
        >
          {couponDetailParams?.map((deepCopyCouponParam: CouponDetailParam) => (
            <CouponRow key={deepCopyCouponParam.masterCouponId} { ...deepCopyCouponParam } />))}
        </Table>
      </MainContainer>
    </>
  );
};
export default GenerateCodeCouponDetailList;

const convertUtcToLocalTimeZone = (targetDateTime: string) => {
  if (!targetDateTime) return '';
  // yyyy-MM-ddTHH:mm:ss 패턴으로 입력되는 타입이므로
  // yyyy-MM-ddTHH:mm:ssZ 로 utc 기준 시간이란 것을 표현
  const utcTargetDateTime = new Date(`${targetDateTime}Z`);
  return utcTargetDateTime.toLocaleString().substring(0, 11);
};
const convertIssuanceCount = (issuanceCount: number) => {
  return issuanceCount > 0 ? '등록' : '미등록';
};
const convertDeliveredYn = (deliveredYn: string) => {
  return deliveredYn === 'Y' ? '전달' : '-';
};

function convertCouponPeriod(startDateTime: string, endDateTime: string) {
  return `${startDateTime ? convertUtcToLocalTimeZone(startDateTime) : ''} ~ ${endDateTime ? convertUtcToLocalTimeZone(endDateTime) : ''}`;
}

const CouponRow = (params: CouponDetailParam) => {
  const navigate = useNavigate();
  const couponPeriod = convertCouponPeriod(
    params.startDateTime,
    params.endDateTime,
  );
  const [checked, setChecked] = useState(false);

  const modifyCouponIdList = (eventObj: any) => {
    // 체크를 한 경우
    if (eventObj.target.checked) {
      setChecked(true);
      params.checkIdList.push(params.masterCouponId);
    }
    // 체크를 해제 한 경우
    else {
      const index = params.checkIdList.indexOf(
          params.masterCouponId,
      );
      if (index >= 0) {
        setChecked(false);
        params.checkIdList.splice(index, 1);
      }
    }
  };
  return (
    <tr
      key={params.masterCouponId}
      onClick={() =>
        navigate('/coupons/coupon-projects/coupon-list/detail', {
          state: {
            masterCouponId: params.masterCouponId,
            couponProjectId: params.couponProjectId,
          },
        })
      }
    >
      <td onClick={(e) => e.stopPropagation()}>
        <input
          id={`${params.masterCouponId}`}
          type="checkbox"
          disabled={false}
          checked={checked}
          onChange={(e) => {
            modifyCouponIdList(e);
          }}
        />
      </td>
      <td>
        <ListItem text={params.masterCouponId} />
      </td>
      <td>
        <ListItem text={params.name} />
      </td>
      <td>
        <ListItem text={params.value} />
      </td>
      <td>
        <ListItem text={couponPeriod} />
      </td>
      <td>
        <ListItem text={params.code} />
      </td>
      <td>
        <ListItem text={convertIssuanceCount(params.issuanceCount)} />
      </td>
      <td>
        <ListItem text={convertDeliveredYn(params.deliveredYn)} />
      </td>
    </tr>
  );
};

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const TotalCountWrapper = styled.div`
  width: 20%;
`;

const TopWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: right;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;
