import styled from 'styled-components';
import { Link, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import axios from 'axios';

import { COUPONS } from "../../constant/apiUrl";
import TextField from "../../components/common/TextField";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import SelectModule from "../../components/common/Select/SelectModule";
import DateInput from "../../components/common/Input/DateInput";
import RadioButton from "../../components/common/Button/RadioButton";

interface CreateCoupon {
  compensateType: string;
  endDateTime: string;
  startDateTime: string;
  maxIssuanceCount: number;
  name: string;
  privateYn: string;
  value: number;
  issuanceType: string;
  issuanceReason: string;
  issuanceCode: string;
  maxRedeemCount: number;
}

// 골드 캠페인 목록 화면(추가, 수정)
const CreateCoupon = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  // 쿠폰 id
  const masterCouponId = detailData !== null ? detailData.masterCouponId : null;

  // 상위 노출

  // 페이지 이동 ////////////////////////////////////////////////////////////////
  // 뒤로가기
  const [compensateType, setCompensateType] = useState('');
  const [compensateTypeKey, setCompensateTypeKey] = useState('');
  const [endDateTime, setEndDateTime] = useState(new Date().toISOString().substring(0, 10));
  const [startDateTime, setStartDateTime] = useState(new Date().toISOString().substring(0, 10));
  const [value, setValue] = useState(0);
  const [issuanceType, setIssuanceType] = useState('');
  const [issuanceTypeKey, setIssuanceTypeKey] = useState('');
  const [maxIssuanceCount, setMaxIssuanceCount] = useState(0);
  const [name, setName] = useState('');
  const [privateYn, setPrivateYn] = useState('N');
  const [issuanceReason, setIssuanceReason] = useState('');
  const [defaultCompensateType, setDefaultCompensateType] = useState({
    '바운드 골드': 'BAUND_GOLD',
  });
  const [defaultIssuanceType, setDefaultIssuanceType] = useState({ '갯수 제한': 'GENERATE', '시리얼 설정': 'DOWNLOAD' });
  const [issuanceCode, setIssuanceCode] = useState('');
  const [maxRedeemCount, setMaxRedeemCount] = useState(1);
  const create = async () => {
    const alertMessages: string[] = [];
    if (!compensateType || compensateType === '') {
      alertMessages.push('보상 지급 유형을 선택 해 주세요');
    }
    if (!value || Number.isNaN(value) || value <= 0) {
      alertMessages.push('올바른 쿠폰 가격을 입력 해 주세요.');
    } else if (value > 1000000) {
      alertMessages.push('쿠폰 가격은 최대 1,000,000 을 넘을 수 없습니다.');
    }
    if (!issuanceType || issuanceType === '') {
      alertMessages.push('발급 형식을 선택 해 주세요.');
    }
    if (!maxIssuanceCount || Number.isNaN(maxIssuanceCount) || maxIssuanceCount <= 0) {
      alertMessages.push('올바른 최대 쿠폰 등록 가능 수를 입력 해 주세요.');
    } else if (issuanceType === 'GENERATE' && maxIssuanceCount > 1000) {
      alertMessages.push(
        '갯수 제한 최대 쿠폰 등록 가능 수는 1000 이하로 입력 해 주세요.',
      );
    } else if (issuanceType === 'DOWNLOAD' && maxIssuanceCount > 500000) {
      alertMessages.push(
        '시리얼 설정 최대 쿠폰 등록 가능 수는 500000 이하로 입력 해 주세요.',
      );
    }
    if (!name || name === '' || name.replaceAll(' ', '') === '') {
      alertMessages.push('쿠폰 명을 입력 해 주세요.');
    } else if (name.length > 20) {
      alertMessages.push('쿠폰 명은 최대 20자까지 입력 가능합니다.');
    }
    if (!privateYn || privateYn === '') {
      alertMessages.push('쿠폰 비공개 여부를 입력 해 주세요.');
    }
    if (!issuanceReason || issuanceReason === '') {
      alertMessages.push('쿠폰 발행 사유를 입력 해 주세요.');
    }
    let newStartDateTime = '';
    if (!startDateTime || startDateTime === '') {
      alertMessages.push('쿠폰 사용 시작 일자를 선택 해 주세요');
    } else {
      newStartDateTime = new Date(startDateTime+'T00:00:00').toISOString().substring(0, 19);
    }
    let newEndDateTime = '';
    if (!endDateTime || endDateTime === '') {
      alertMessages.push('쿠폰 사용 만료 일자를 선택 해 주세요');
    } else {
      const newEndDate = new Date(endDateTime+'T23:59:59');
      newEndDateTime = newEndDate.toISOString().substring(0, 19);
    }
    if (
      issuanceType === 'DOWNLOAD' &&
      (!issuanceCode || issuanceCode.replaceAll(' ', '') === '')
    ) {
      alertMessages.push(
        '시리얼 설정 쿠폰 발행의 경우 쿠폰 시리얼 번호는 필수 값 입니다.',
      );
    }
    if (!maxRedeemCount) {
      alertMessages.push('중복 등록 가능 수를 입력 해 주세요');
    } else if (maxRedeemCount < 1 || maxRedeemCount > 1000) {
      alertMessages.push(
        '중복 등록 가능 수는 1~1000 까지의 숫자를 입력 해 주세요',
      );
    }
    if (alertMessages.length > 0) {
      alert(alertMessages.join('\n'));
      return;
    }
    const createRequest: CreateCoupon = {
      compensateType,
      endDateTime: newEndDateTime,
      startDateTime: newStartDateTime,
      maxIssuanceCount,
      name,
      privateYn,
      value,
      issuanceType,
      issuanceReason,
      issuanceCode,
      maxRedeemCount,
    };
    await axios.post(`${COUPONS}`, JSON.stringify(createRequest), {headers:{'Content-Type': 'application/json'}})
      .then((res) => {
        alert('쿠폰 등록이 완료 되었습니다.');
        window.history.back();
      })
      .catch((error) => {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  return (
    <>
      <TitleText>쿠폰 상세 보기</TitleText>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="뒤로 가기"
            onSubmit={() => window.history.back()}
          />
          <NormalButton text="등록 하기" onSubmit={create} />
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <LabelTextInput
          label="쿠폰 명"
          name="name"
          value={name}
          placeholder="쿠폰 이름을 입력 해 주세요."
          onChange={(e) => setName(e.target.value)}
          onReset={(e) => setName('')}
          Required
        />
        <LabelTextInput
          label="쿠폰 가격"
          name="value"
          value={value}
          type="number"
          onChange={(e) => setValue(Number(e.target.value))}
          onReset={(e) => setValue(0)}
          Required
        />
        <SelectModule
          label="쿠폰 재화 지급 유형"
          name={compensateTypeKey}
          value={compensateTypeKey}
          options={Object.keys(defaultCompensateType)}
          Required
          onChange={(e) => {
            const object = Object.entries(defaultCompensateType).find((entry) => entry[0] === e);
            if (object) {
              setCompensateTypeKey(object[0]);
              setCompensateType(object[1]);
            } else {
              alert(
                '쿠폰 재화 지급 유형을 선택하던 중 문제가 발생했습니다. 담당자에게 문의 해 주세요.',
              );
            }
          }}
          placeholder="재화 지급 유형을 선택 해 주세요."
        />
        <SelectModule
          label="쿠폰 형식"
          placeholder="쿠폰 발행 방식을 선택 해 주세요."
          name={issuanceTypeKey}
          value={issuanceTypeKey}
          options={Object.keys(defaultIssuanceType)}
          Required
          onChange={(e) => {
            const object = Object.entries(defaultIssuanceType).find((entry) => entry[0] === e);
            if (object) {
              setIssuanceTypeKey(object[0]);
              setIssuanceType(object[1]);
              setMaxRedeemCount(object[1] === 'DOWNLOAD' ? 1 : maxRedeemCount);
            } else {
              alert(
                '쿠폰 형식 유형을 선택하던 중 문제가 발생했습니다. 담당자에게 문의 해 주세요.',
              );
            }
          }}
        />
        <LabelTextInput
          label="쿠폰 발급 수"
          name="maxIssuanceCount"
          value={maxIssuanceCount}
          type="number"
          onChange={(e) => setMaxIssuanceCount(Number(e.target.value))}
          onReset={(e) => setMaxIssuanceCount(0)}
          Required
        />
        {issuanceType === 'GENERATE' && (
          <LabelTextInput
            label="멤버 한명이 중복 등록 가능한 쿠폰 수(1~1000)"
            name="maxRedeemCount"
            value={maxRedeemCount}
            type="number"
            onChange={(e) => {
              const inputVal = Number(e.target.value);
              if (inputVal < 1 || inputVal > 1000) {
                alert(
                  '중복 등록 가능한 쿠폰 수는 1~1000 사이 값을 입력 해 주세요',
                );
                setMaxRedeemCount(1);
              } else {
                setMaxRedeemCount(inputVal);
              }
            }}
            onReset={(e) => setMaxRedeemCount(0)}
            Required
          />
        )}
        <DateInput
          label="등록/사용 시작 기간(해당 날 0시0분0초 부터 사용 가능합니다)"
          name="startDateTime"
          type="date"
          initialStartDate={startDateTime}
          onChange={(e) => setStartDateTime(e.target.value)}
          Required
        />
        <DateInput
          label="등록/사용 만료 기간(선택한 날 23시59분59초 까지 사용가능합니다)"
          name="endDateTime"
          type="date"
          initialStartDate={endDateTime}
          onChange={(e) => setEndDateTime(e.target.value)}
          Required
        />
        <LabelTextInput
          label="발행 사유"
          name="issuanceReason"
          placeholder="발행 사유를 입력 해 주세요."
          value={issuanceReason}
          onChange={(e) => setIssuanceReason(e.target.value)}
          onReset={(e) => setIssuanceReason('')}
          Required
        />
        <RadioButton
          label="비공개 여부"
          name="privateYn"
          type="privateYn"
          value={privateYn}
          onChange={(e) => setPrivateYn(e.target.value)}
          Required
        />
        {issuanceType === 'DOWNLOAD' && (
          <LabelTextInput
            label="쿠폰 시리얼(코드)"
            name="issuanceCode"
            placeholder="쿠폰의 시리얼(코드)을 입력 해 주세요."
            value={issuanceCode}
            onChange={(e) => setIssuanceCode(e.target.value)}
            onReset={(e) => setIssuanceCode('')}
            Required
          />
        )}
      </MainContainer>
    </>
  );
};
export default CreateCoupon;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({ theme }) => theme.color.mainBlack};
`;