import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { COMMUNITY_CURATED_ARTICLE } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import ListHead from '../../../components/UI/ListHead';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Table from '../../../components/common/List/Table';
import SearchInput from '../../../components/common/Input/SearchInput';
import useInput from '../../../hooks/useInput';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import handleDate from '../../../hooks/handleDate';
import { IsPrivate, IsStatus } from '../../../constant/common';
import { eventModalColList } from '../../../constant/event';
import EditButton from "../../../components/common/Button/EditButton";

// 제시어 선택 모달창의 row
const CuratedSuggestWordRowComponent = ({ data, onClick, btnType }) => {
  const { id, name } = data;
  return (
    <tr key={id}>
      <td><ListItem text={id} /></td>
      <td><ListItem text={name} /></td>
      <td>
        {onClick && btnType === 'add' && (
            <EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>
        )}
        {onClick && btnType === 'delete' && (
            <DeleteBtn onClick={() => onClick(data)} />
        )}
      </td>
    </tr>
  );
};

const CuratedSuggestWordArticleDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // 이전 화면에서 받아온 정보
  const detailData = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [modalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const [contentsIds, setContentsIds] = useState([]);
  const [keyword, setKeyword, onReset] = useInput('');
  //선택된 제시어
  const [wordsCurated, setWordsCurated] = useState([]);
  //선택된 제시어로 옮기기 전 임시 저장
  const [wordsWork, setWordsWork] = useState([]);
  //선택을 위한 검색 결과
  const [wordsMaster, setWordsMaster] = useState([]);
  //제시어를 사용한 영상 확인 용
  const [targetArticles, setTargetArticles] = useState([]);

  // 상세 조회
  // const getDetail =
  useEffect(async () => {
    // console.log('CuratedSuggestWordArticleDetail > useCallback > prevId:', prevId);
    if (!prevId) {
      return;
    }
    await axios.get(
      `${COMMUNITY_CURATED_ARTICLE}/${prevId}/detail`,
    )
    .then((resp)=>{
      if (resp.data.adminCuratedSuggestWords) {
        setName(resp.data.adminCuratedSuggestWords.name);
        setNameEn(resp.data.adminCuratedSuggestWords.nameEn);
        setDescription(resp.data.adminCuratedSuggestWords.description);
        setDescriptionEn(resp.data.adminCuratedSuggestWords.descriptionEn);
        setSequence(resp.data.adminCuratedSuggestWords.sequence);
        setActiveYn(resp.data.adminCuratedSuggestWords.activeYn);
        setStartDt(
          resp.data.adminCuratedSuggestWords.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCuratedSuggestWords.endDt?.replace(' ', 'T').slice(0, 16),
        );
        setWordsCurated(resp.data.adminCuratedSuggestWords.adminSuggestWords);
      }
    })
    .catch((err)=>{
      console.log(err);
      alert(`시스템 관리자에게 문의해 주세요.:\n${err.response?.data?.message}`);
    });
  }, [prevId]);

  // 선택된 제시어가 변경되면
  useEffect(() => {
    // 저장을 위해 아이디 추리고
    setContentsIds(wordsCurated.map((el) => el.id));
    // 확인을 위해 보여줬던 영상 초기화하고
    setTargetArticles([]);
    // 임시 저장 제시어 초기화
    setWordsWork([]);
  }, [wordsCurated]);

  // 모달 열기
  const openModal = () => {
    setModalOpen(true);
    setWordsWork([...wordsCurated]);
    setWordsMaster([]);
  };

  // 제시어 검색
  const searchSuggestWords = async () => {
    await axios.get(
        '/setting/suggest/word_master',
        { params: {offset: 0, limit: 100, search: keyword,} }
    )
    .then((resp)=> {
      setWordsMaster(resp.data.items);
    })
    .catch ((err) => {
      console.log(err);
      alert(`시스템 관리자에게 문의해 주세요.:\n${err.response?.data?.message}`);
    });
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    setWordsWork([]);
    onReset();
  };

  // 모달안에서 제시어 저장 버튼 클릭
  const saveContentList = () => {
    setWordsCurated([...wordsWork]);
    closeModal();
  };

  // 클릭시 row 추가
  const addSelectedContent = (data) => {
    // console.log(data);
    if (!wordsWork?.find((e) => e.id === data.id)) {
      setWordsWork([...wordsWork, data]);
    }
  };

  // 클릭시 row 삭제
  const filterSelectedContent = (id) => {
    setWordsWork(wordsWork.filter((item) => item.id !== id));
  };

  // 엔터키로 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      searchSuggestWords().then();
    }
  };

  // 대상이 되는 영상 검색
  const getTargetArticles = async () => {
    await axios.get(
        '/setting/suggest/word_master/articles',
        {params: {ids: contentsIds, offset: 0, limit: 100}}
    )
    .then((resp)=>{
      if (resp.data.articles) {
        setTargetArticles(resp.data.articles || []);
      }
    })
    .catch((err) =>{
      console.log(err);
      alert(`시스템 관리자에게 문의해 주세요.:\n${err.response?.data?.message}`);
    });
  };

  // 저장
  const addContents = async (type) => {
    if (contentsIds?.length === 0) {
      alert('제시어를 선택해주세요.');
      return;
    }
    if (!startDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const body = {
      name,
      nameEn,
      description,
      descriptionEn,
      contentsType: 'SUGGEST_WORD',
      contentsIds,
      sequence,
      activeYn,
      startDate: startDt,
      endDate: endDt,
    };

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(`${COMMUNITY_CURATED_ARTICLE}/${prevId}/edit`, body, {},
        );
      } else {
        await axios.post(`${COMMUNITY_CURATED_ARTICLE}`, body, {});
      }
      await navigate('/explore/curatedSuggestWordArticle');
      alert('저장되었습니다.');
    } catch (err) {
      console.log(err);
      alert(`시스템 관리자에게 문의해 주세요.:\n${err.response?.data?.message}`);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton text="수정하기" onSubmit={() => addContents('edit')}/>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput label="컨텐츠 명" Required
            name="name" value={name}
            placeholder="컨텐츠 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput label="컨텐츠 명 EN" Required
            name="nameEn" value={nameEn}
            placeholder="컨텐츠 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput label="컨텐츠 설명" Required
            name="description" value={description}
            placeholder="컨텐츠 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput label="컨텐츠 설명 EN" Required
            name="descriptionEn" value={descriptionEn}
            placeholder="컨텐츠 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper span={2}>
          <RadioButton label="공개 여부" Required
            name="activeYn" value={activeYn}
            onChange={(e) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput label="노출 개시 일시" Required
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput label="노출 종료 일시" Required
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper span={2}>
          <VideoInput onClick={() => openModal()} text="제시어 선택" />
        </InputWrapper>
        <InputWrapper span={2}>
          <LineDiv value="0" />
        </InputWrapper>
        <InputWrapper>
          {wordsCurated?.map((data) => (
            <SelectWord key={data.id}>
              <SelectWordText>{data.name}</SelectWordText>
            </SelectWord>
          ))}
        </InputWrapper>
        {wordsCurated?.length !== 0 && (<>
            <InputWrapper span={2}>
              <SpaceDiv />
              <VideoInput onClick={() => getTargetArticles()} text="제시어를 사용한 영상 확인"/>
            </InputWrapper>
            <InputWrapper span={2}>
              <Table colList={eventModalColList}
                thead={<>
                  <ListHead title="id" />
                  <ListHead title="썸네일" />
                  <ListHead title="Pid" />
                  <ListHead title="이름" />
                  <ListHead title="비트명" />
                  <ListHead title="업로드 날짜" />
                  <ListHead title="공개 여부" />
                  <ListHead title="상태" />
                  <ListHead title="" />
                </>}
              >
                {targetArticles?.map((data) => (
                  <tr key={data.id}>
                    <td><ListItem text={data.id} /></td>
                    <td><ListCoverImg className="article" url={data.coverImgUrl}/></td>
                    <td><ListItem text={data.pid} /></td>
                    <td><ListItem text={data.name} /></td>
                    <td><ListItem text={data.beatTitle} /></td>
                    <td><ListItem text={handleDate(data.updateDt)} /></td>
                    <td><ListItem text={IsPrivate[data.privateYn]} /></td>
                    <td><ListItem text={IsStatus[data.articleStatus]} /></td>
                  </tr>
                ))}
              </Table>
            </InputWrapper>
          </>
        )}
      </MainContainer>

      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
              <NormalButton text="저장" onSubmit={() => saveContentList()}/>
            </ModalButtonWrapper>
            <VideoModalTitle>선택된 제시어</VideoModalTitle>
            {wordsWork?.length !== 0 && wordsWork ? (<>
              <LineDiv />
              {/*<Table colList={[20, 100, 20]}*/}
              {/*       thead={<>*/}
              {/*         <ListHead title="id" />*/}
              {/*         <ListHead title="제시어 명" />*/}
              {/*         <ListHead title="" />*/}
              {/*       </>}*/}
              {/*>*/}
              {wordsWork?.map((datum) => (
                <SelectWord key={datum.id}>
                  <SelectWordText>{datum.name}</SelectWordText>
                  <TagDeleteButton onClick={() => filterSelectedContent(datum.id)}/>
                </SelectWord>
                //   <CuratedSuggestWordRowComponent data={datum} onClick={() => filterSelectedContent(datum.id)} btnType="delete"/>
              ))}
            {/*</Table>*/}
            </>) : (<>
              <VideoModalSubText>선택된 제시어가 없습니다. 아래 검색 후 제시어를 선택해주세요.</VideoModalSubText>
              <LineDiv />
            </>)}
            <SpaceDiv value="90px" />
            <VideoModalTitle>제시어 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchInputWrapper>
                  <SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <LineDiv className="30" />
            <Table colList={[20, 100, 20]}
              thead={<>
                <ListHead title="id" />
                <ListHead title="제시어 명" />
                <ListHead title="" />
              </>}
            >
              {wordsMaster?.map((datum) => (
                <CuratedSuggestWordRowComponent data={datum} onClick={addSelectedContent} btnType="add"/>
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};
export default CuratedSuggestWordArticleDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: ${({ value }) => value || '10'}px 0 ${({ value }) => value || '10'}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SelectWord = styled.div`
  display: inline-flex;
  align-items: center; //세로가운데
  margin: 0 10px 0 0;
  padding: 8px 10px 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  border-radius: 30px;
`;

const SelectWordText = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagDeleteButton = styled.button`
  width: 18px;
  height: 18px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
  &.unEditable {
    display: none;
  }
`;

const DeleteBtn = styled.button`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 20px;
  right: 35px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 30px;
`;