import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios'; // 장르 받아오기

// rekognition 받아오기
export const getRekognitionList = createAsyncThunk(
    'operation/getRekognitionList',
    async (params: object, {rejectWithValue}) => {
        try {
            const response = await axios.get('/master/rekognition', {params});
            return response.data;
        } catch (error: any) {
            console.log(rejectWithValue(error.response.data));
            return rejectWithValue(error.response.data);
        }
    },
);

// rekognition 상세 수정하기
export const changeRekognitionDetail = createAsyncThunk(
    'operation/changeRekognitionDetail',
    async (data: { id: number; params: object }) => {
        const {params, id} = data;
        try {
            return await axios.post(
                `/master/rekognition/${id}/update`,
                {},
                {
                    params,
                },
            );
        } catch (error: any) {
            console.log(error.response.data);
            return error.response.data;
        }
    },
);
