import DashBoard from '../pages/DashBoard';
import SearchMember from '../pages/member/SearchMember';
import DetailMember from '../pages/member/components/DetailMember';
import MemberHistory from '../pages/member/MemberHistory';
import ResignMember from '../pages/member/ResignMember';
import AddMember from '../pages/member/components/AddMember';
import Genre from '../pages/beat/Genre';
import AddGenre from '../pages/beat/AddGenre';
import Mood from '../pages/beat/Mood';
import AddMood from '../pages/beat/AddMood';
import Beat from '../pages/beat/Beat';
import AddBeat from '../pages/beat/AddBeat';
import BeatMaker from '../pages/beat/BeatMaker';
import AddBeatMaker from '../pages/beat/AddBeatMaker';
import SearchVideo from '../pages/article/SearchVideo';
import AddVideo from '../pages/article/components/AddVideo';
import RekognitionVideo from '../pages/customerService/RekognitionVideo';
import DetailBlock from '../pages/customerService/components/DetailBlock';
import BlockVideo from '../pages/customerService/BlockVideo';
import BlockElse from '../pages/customerService/BlockElse';
import RestrictMember from '../pages/customerService/RestrictMember';
import Notice from '../pages/operation/notice/Notice';
import NoticeEditSeq from '../pages/operation/notice/NoticeEditSeq';
import Management from '../pages/operation/Management';
import AddReport from '../pages/operation/components/AddReport';
import EditReport from '../pages/operation/components/EditReport';
import RekognitionManagement from '../pages/operation/RekognitionManagement';
import Event from '../pages/operation/event/Event';
import EventDetail from '../pages/operation/event/EventDetail';
import EventEditSeq from '../pages/operation/event/component/EventEditSeq';
import BannedWordManagement from '../pages/operation/BannedWordManagement';
import ReserveIdManagement from '../pages/operation/ReserveIdManagement';
import AppVersionManagement from '../pages/operation/AppVersionManagement';
import OfficialAccount from '../pages/operation/OfficialAccount';
import OfficialAccountDetail from '../pages/operation/components/OfficialAccountDetail';
import Category from '../pages/explore/Category';
import RecentlyBeat from '../pages/explore/components/category/RecentlyBeat';
import RecentlyBeatDetail from '../pages/explore/components/category/RecentlyBeatDetail';
import ExposureGenre from '../pages/explore/components/category/ExposureGenre';
import ExposureGenreDetail from '../pages/explore/components/category/ExposureGenreDetail';
import RecommendGenre from '../pages/explore/components/category/RecommendGenre';
import RecommendGenreDetail from '../pages/explore/components/category/RecommendGenreDetail';
import RecommendBeatMaker from '../pages/explore/components/category/RecommendBeatMaker';
import RecommendBeatMakerDetail from '../pages/explore/components/category/RecommendBeatMakerDetail';
import RecommendHashTagEditSeq from '../pages/explore/components/category/RecommendHashTagEditSEq';
import UpcomingBeat from '../pages/explore/components/category/UpcomingBeat';
import UpcomingBeatAdd from '../pages/explore/components/category/UpcommingBeatAdd';
import UpcomingBeatEdit from '../pages/explore/components/category/UpcomingBeatEdit';
import RecommendCollection from '../pages/explore/components/category/RecommendCollection';
import RecommendCollectionAddList from '../pages/explore/components/category/RecommendCollectionAddList';
import RecommendCollectionDetail from '../pages/explore/components/category/RecommendCollectionDetail';
import GenreTabCategory from '../pages/explore/GenreTabCategory';
import RegisterCollection from '../pages/explore/RegisterCollection';
import RecommendHashTag from '../pages/explore/components/category/RecommendHashTag';
import RecommendHashTagList from '../pages/explore/components/category/RecommendHashTagList';
import RecommendHashTagDetail from '../pages/explore/components/category/RecommendHashTagDetail';
import OfficialCollectionDetail from '../pages/explore/components/category/OfficialCollectionDetail';
import MyAccount from '../pages/adminAccount/MyAccount';
import AdminRoleManagement from '../pages/adminAccount/AdminRoleManagement';
import EditCategoryOfAdminRole from '../pages/adminAccount/components/EditCategoryOfAdminRole';
import AdminRoleManagementDetail from '../pages/adminAccount/components/AdminRoleManagementDetail';
import AdminAccountManagement from '../pages/adminAccount/AdminAccountManagement';
import AddAccount from '../pages/adminAccount/components/AddAccount';
import OfficialCollectionList from '../pages/explore/OfficialCollectionList';
import Push from '../pages/operation/Push';
import Maintenance from '../pages/system/Maintenance';
import Filter from '../pages/filter/Filter';
import FilterCategory from '../pages/filter/FilterCategory';
import UpdateData from '../pages/system/UpdateData';
import RefreshCache from '../pages/system/RefreshCache';
import FilterDetail from '../pages/filter/components/FilterDetail';
import FilterCategoryDetail from '../pages/filter/components/FilterCategoryDetail';
import SoundType from '../pages/baundMusic/SoundType';
import ReleaseSound from '../pages/baundMusic/ReleaseSound';
import ReleaseSoundDetail from '../pages/baundMusic/components/ReleaseSoundDetail';
import ExploreContentsBaundMusic from '../pages/explore/components/category/ExploreContentsBaundMusic';
import ExploreContentsBaundMusicDetail from '../pages/explore/components/category/ExploreContentsBaundMusicDetail';
import CommunityCategory from '../pages/explore/communityCategory/CommunityCategory';
import CommunityCategoryEditSeq from '../pages/explore/communityCategory/CommunityCategoryEditSeq';
import CommunityCategoryDetail from '../pages/explore/communityCategory/CommunityCategoryDetail';
import CommunityFixArticle from '../pages/operation/community/FixArticle';
import CommunityFixArticleDetail from '../pages/operation/community/FixArticleDetail';
import CommunityFixArticleAddList from '../pages/operation/community/FixArticleAddList';
import CultureChannel from '../pages/culture/channel/CultureChannel';
import CultureChannelDetail from '../pages/culture/channel/CultureChannelDetail';
import CultureProgram from '../pages/culture/program/CultureProgram';
import CultureProgramDetail from '../pages/culture/program/CultureProgramDetail';
import CultureProgramEditSeq from '../pages/culture/program/CultureProgramEditSeq';
import CultureEpisode from '../pages/culture/episode/CultureEpisode';
import CultureEpisodeDetail from '../pages/culture/episode/CultureEpisodeDetail';
import CommunityCuratedArticle from '../pages/operation/community/CuratedArticle';
import CommunityCuratedArticleDetail from '../pages/operation/community/CuratedArticleDetail';
import CommunityCuratedArticleEditSeq from '../pages/operation/community/CuratedArticleEditSeq';
import CuratedBeat from '../pages/beat/curatedBeat/CuratedBeat';
import CuratedBeatDetail from '../pages/beat/curatedBeat/CuratedBeatDetail';
import CuratedBeatEditSeq from '../pages/beat/curatedBeat/CuratedBeatEditSeq';
import CuratedArticle from '../pages/explore/curatedArticle/CuratedArticle';
import CuratedArticleDetail from '../pages/explore/curatedArticle/CuratedArticleDetail';
import CuratedArticleEditSeq from '../pages/explore/curatedArticle/CuratedArticleEditSeq';
import HotBeat from '../pages/explore/components/category/HotBeat';
import HotBeatAdd from '../pages/explore/components/category/HotBeatAdd';
import HotBeatEdit from '../pages/explore/components/category/HotBeatEdit';
import HotBeatEditSeq from '../pages/explore/components/category/HotBeatEditSeq';
import AdminCategory from '../pages/adminAccount/category/AdminCategory';
import AdminCategoryDetail from '../pages/adminAccount/category/AdminCategoryDetail';
import BaundApiCategory from '../pages/system/baundApi/category/BaundApiCategory';
import BaundApiCategoryDetail from '../pages/system/baundApi/category/BaundApiCategoryDetail';
import BaundApiCategoryEditSeq from '../pages/system/baundApi/category/BaundApiCategoryEditSeq';
import BaundApi from '../pages/system/baundApi/api/BaundApi';
import BaundApiDetail from '../pages/system/baundApi/api/BaundApiDetail';
import BaundApiEditSeq from '../pages/system/baundApi/api/BaundApiEditSeq';
import BaundMusicProject from '../pages/baundMusic/project/BaundMusicProject';
import BaundMusicProjectDetail from '../pages/baundMusic/project/BaundMusicProjectDetail';
import BaundMusicProjectEditSeq from '../pages/baundMusic/project/BaundMusicProjectEditSeq';
import CuratedTagsArticle from '../pages/explore/curatedTagsArticle/CuratedTagsArticle';
import CuratedTagsArticleDetail from '../pages/explore/curatedTagsArticle/CuratedTagsArticleDetail';
import IndividualPush from '../pages/operation/IndividualPush';
import MissionList from '../pages/rewards/MissionList';
import MissionDetail from '../pages/rewards/MissionDetail';
import Performance from '../pages/culture/performance/Performance';
import PerformanceDetail from '../pages/culture/performance/performanceDetail';
import PerformanceCategory from '../pages/culture/performance/PerformanceCategory';
import PerformanceCategoryDetail from '../pages/culture/performance/PerformanceCategoryDetail';
import CuratedPerformance from '../pages/culture/performance/curatedPerformance/CuratedPerformance';
import CuratedPerformanceDetail from '../pages/culture/performance/curatedPerformance/CuratedPerformanceDetail';
import CuratedPerformanceEditSeq from '../pages/culture/performance/curatedPerformance/CuratedPerformanceEditSeq';
import NoticeDetail from '../pages/operation/notice/NoticeDetail';
import CommunityBoardBannerList from '../pages/operation/community/banner/CommunityBoardBannerList';
import CommunityBoardBannerDetail from '../pages/operation/community/banner/CommunityBoardBannerDetail';
import CommunityBoardBannerEditSeq from '../pages/operation/community/banner/CommunityBoardBannerEditSeq';
import PointGuideAreaDetail from '../pages/rewards/PointGuideAreaDetail';
import MemberOfRole from "../pages/adminAccount/role/MemberOfRole";
import NoticeNormal from '../pages/operation/noticeNormal/NoticeNormal';
import NoticeNormalDetail from '../pages/operation/noticeNormal/NoticeNormalDetail';
import NoticeNormalEditSeq from '../pages/operation/noticeNormal/NoticeNormalEditSeq';
import GoldCampaignList from "../pages/campaign/goldCampaign/GoldCampaigns";
import GoldCampaignDetail from "../pages/campaign/goldCampaign/GoldCampaignDetail";
import GoldCampaignEditSeq from "../pages/campaign/goldCampaign/GoldCampaignEditSeq";
import DeleteParticipationHistory from "../pages/campaign/delete_before_deploy_prod/DeleteParticipationHistory";
import RewardMasterList from "../pages/rewards/RewardMasterList";
import RewardMasterDetail from "../pages/rewards/RewardMasterDetail";
import GiveCurrency from "../pages/rewards/GiveCurrency";
import PointGuideAreaList from "../pages/rewards/PointGuideAreaList";
import Battle from "../pages/battle/Battle";
import BattleDetail from "../pages/battle/BattleDetail";
import BattleBasicConfig from "../pages/battle/BattleBasicConfig";
import ChangeBeatOfVideo from "../pages/article/ChangeBeatOfVideo";
import MasterMessage from "../pages/datas/masterMessage/MasterMessage";
import MasterMessageAdd from "../pages/datas/masterMessage/MasterMessageAdd";
import MasterCouponList from "../pages/coupon/Coupons";
import MasterCouponDetail from "../pages/coupon/GenerateCodeCouponDetail";
import CreateCoupon from "../pages/coupon/CreateCoupon";
import CouponProjects from "../pages/coupon/CouponProjects";
import GenerateCodeCouponDetailList from "../pages/coupon/GenerateCodeCouponDetailList";
import CouponProjectDetail from "../pages/coupon/CouponProjectDetail";
import BattleAnyIsConfig from "../pages/battle/BattleAnyIsConfig";
import Magazine from '../pages/culture/magazine/Magazine';
import MagazineEditSeq from '../pages/culture/magazine/MagazineEditSeq';
import MagazineDetail from '../pages/culture/magazine/MagazineDetail';
import MagazineCategory from '../pages/culture/magazineCategory/MagazineCategory';
import MagazineCategoryDetail from '../pages/culture/magazineCategory/MagazineCategoryDetail';
import CuratedMagazine from '../pages/culture/curatedMagazine/CuratedMagazine';
import CuratedMagazineDetail from '../pages/culture/curatedMagazine/CuratedMagazineDetail';
import SuggestWord from '../pages/operation/suggestWord/SuggestWord';
import SuggestWordCategory from '../pages/operation/suggestWord/SuggestWordCategory';
import SuggestWordRandom from '../pages/operation/suggestWord/SuggestWordRandom';
import SuggestWordRandomDetail from '../pages/operation/suggestWord/SuggestWordRandomDetail';
import CuratedSuggestWordArticle from '../pages/explore/curatedSuggestWordArticle/CuratedSuggestWordArticle';
import CuratedSuggestWordArticleDetail from '../pages/explore/curatedSuggestWordArticle/CuratedSuggestWordArticleDetail';
import ExposureMood from "../pages/explore/components/category/ExposureMood"
import ExposureMoodDetail from "../pages/explore/components/category/ExposureMoodDetail"
import Advertisers from  '../pages/advertisement/advertiser/Advertisers';
import AdvertiserDetail from  '../pages/advertisement/advertiser/AdvertiserDetail';
import Advertisements from  '../pages/advertisement/advertisement/Advertisements';
import AdvertisementDetail from  '../pages/advertisement/advertisement/AdvertisementDetail';
import AdvertisementLandingDetail from '../pages/advertisement/advertisementLanding/AdvertisementLandingDetail';
import AdvertisementLandings from '../pages/advertisement/advertisementLanding/AdvertisementLandings';
import AdvertisementCampaigns from '../pages/advertisement/advertisementCampaign/AdvertisementCampaigns';
import AdvertisementCampaignDetail from '../pages/advertisement/advertisementCampaign/AdvertisementCampaignDetail';
import AdvertisementCampaignEditSeqEL from '../pages/advertisement/advertisementCampaignEditSeq/AdvertisementCampaignEditSeqEL';
import AdvertisementCampaignEditSeqNM from '../pages/advertisement/advertisementCampaignEditSeq/AdvertisementCampaignEditSeqNM';
import ExploreBanner from "../pages/explore/ExploreBanner";
import CommunityBoardArticleSearch from '../pages/operation/community/search/CommunityBoardArticleSearch'
import BaundProjectFaq from '../pages/baundProject/BaundProjectFaqs';
import BaundProjectFaqDetail from '../pages/baundProject/BaundProjectFaqDetail';
import BaundProjectFaqEditSeq from '../pages/baundProject/BaundProjectFaqEditSeq';
import BaundProjectCaution from '../pages/baundProject/BaundProjectCautions';
import BaundProjectCautionDetail from '../pages/baundProject/BaundProjectCautionDetail';
import BaundProjectCautionEditSeq from '../pages/baundProject/BaundProjectCautionEditSeq';
import BaundProject from '../pages/baundProject/BaundProjects';
import BaundProjectDetail from '../pages/baundProject/BaundProjectDetail';
import BaundProjectQna from '../pages/baundProject/BaundProjectQnas';
import BaundProjectQnaDetail from '../pages/baundProject/BaundProjectQnaDetail';
import BaundProjectParticipant from '../pages/baundProject/BaundProjectParticipants';
import BaundProjectParticipantDetail from '../pages/baundProject/BaundProjectParticipantDetail';

type routesProps = {
	path: string;
	element: object;
}

export const routesList: Array<routesProps> = [
	{path: '/', element: <DashBoard />},
	{path: '/account', element: <MyAccount />},
	{path: '/admin/adminRole', element: <AdminRoleManagement />},
	{path: '/admin/adminRole/edit', element: <AdminRoleManagementDetail />},
	{path: '/admin/adminRole/add', element: <AdminRoleManagementDetail />},
	{path: '/admin/adminRole/editCategory', element: <EditCategoryOfAdminRole />},
	{path: '/admin/adminRole/member', element: <MemberOfRole />},
	{path: '/admin/adminAccount', element: <AdminAccountManagement />},
	{path: '/admin/adminAccount/add', element: <AddAccount />},
	{path: '/admin/adminCategory', element: <AdminCategory />},
	{path: '/admin/adminCategory/edit', element: <AdminCategoryDetail />},
	{path: '/admin/adminCategory/add', element: <AdminCategoryDetail />},

	// 회원
	{path: '/member/searchMember', element: <SearchMember />},
	{path: '/member/searchMember/add', element: <AddMember />},
	{path: '/member/searchMember/detail', element: <DetailMember />},
	{path: '/member/history', element: <MemberHistory />},
	{path: '/member/resign', element: <ResignMember />},


	// 비트
	{path: '/beat/', element: <Genre />},
	{path: '/beat/genre', element: <Genre />},
	{path: '/beat/genre/add', element: <AddGenre />},
	{path: '/beat/genre/detail', element: <AddGenre />},
	{path: '/beat/mood', element: <Mood />},
	{path: '/beat/mood/add', element: <AddMood />},
	{path: '/beat/mood/detail', element: <AddMood />},
	{path: '/beat/list', element: <Beat />},
	{path: '/beat/list/add', element: <AddBeat />},
	{path: '/beat/list/detail', element: <AddBeat />},
	{path: '/beat/beatmaker', element: <BeatMaker />},
	{path: '/beat/beatmaker/add', element: <AddBeatMaker />},
	{path: '/beat/beatmaker/edit', element: <AddBeatMaker />},
	{path: '/beat/curatedBeat', element: <CuratedBeat />},
	{path: '/beat/curatedBeat/edit', element: <CuratedBeatDetail />},
	{path: '/beat/curatedBeat/add', element: <CuratedBeatDetail />},
	{path: '/beat/curatedBeat/editSeq', element: <CuratedBeatEditSeq />},


	// 비디오
	{path: '/video', element: <SearchVideo />},
	{path: '/video/searchVideo', element: <SearchVideo />},
	{path: '/video/searchVideo/add', element: <AddVideo />},
	{path: '/video/changeBeat', element: <ChangeBeatOfVideo />},


	// cs
	{path: '/cs', element: <RekognitionVideo />},
	{path: '/cs/rekognitionVideo', element: <RekognitionVideo />},
	{path: '/cs/rekognitionVideo/detail', element: <DetailBlock />},
	{path: '/cs/blockVideo', element: <BlockVideo />},
	{path: '/cs/blockElse', element: <BlockElse />},
	{path: '/cs/blockVideo/detail', element: <DetailBlock />},
	{path: '/cs/restrictMember', element: <RestrictMember />},


	// 운영
	{path: '/operation', element: <Notice />},
	{path: '/operation/management', element: <Management />},
	{path: '/operation/management/add', element: <AddReport />},
	{path: '/operation/management/edit', element: <EditReport />},
	{path: '/operation/rekognition', element: <RekognitionManagement />},
	{path: '/operation/event', element: <Event />},
	{path: '/operation/event/add', element: <EventDetail />},
	{path: '/operation/event/detail', element: <EventDetail />},
	{path: '/operation/event/editSeq', element: <EventEditSeq />},
	{path: '/operation/notice', element: <Notice />},
	{path: '/operation/notice/add', element: <NoticeDetail />},
	{path: '/operation/notice/detail', element: <NoticeDetail />},
	{path: '/operation/notice/editSeq', element: <NoticeEditSeq />},
	{path: '/operation/word', element: <BannedWordManagement />},
	{path: '/operation/reservation', element: <ReserveIdManagement />},
	{path: '/operation/version', element: <AppVersionManagement />},
	{path: '/operation/officialAccount', element: <OfficialAccount />},
	{path: '/operation/officialAccount/edit', element: <OfficialAccountDetail />},
	{path: '/operation/push', element: <Push />},
	{path: '/operation/individualPush', element: <IndividualPush />},
	{path: '/operation/communityFixArticle', element: <CommunityFixArticle />},
	{path: '/operation/communityFixArticle/edit', element: <CommunityFixArticleDetail />},
	{path: '/operation/communityFixArticle/add', element: <CommunityFixArticleDetail />},
	{path: '/operation/communityFixArticle/articleList', element: <CommunityFixArticleAddList />},
	{path: '/operation/communityCuratedArticle', element: <CommunityCuratedArticle />},
	{path: '/operation/communityCuratedArticle/edit', element: <CommunityCuratedArticleDetail />},
	{path: '/operation/communityCuratedArticle/add', element: <CommunityCuratedArticleDetail />},
	{path: '/operation/communityCuratedArticle/editSeq', element: <CommunityCuratedArticleEditSeq />},
	{path: '/operation/communityBoardBanner', element: <CommunityBoardBannerList />},
	{path: '/operation/communityBoardBanner/edit', element: <CommunityBoardBannerDetail />},
	{path: '/operation/communityBoardBanner/add', element: <CommunityBoardBannerDetail />},
	{path: '/operation/communityBoardBanner/editSeq', element: <CommunityBoardBannerEditSeq />},
	{path: '/operation/suggestWord', element: <SuggestWord /> },
	{path: '/operation/suggestWordCategory', element: <SuggestWordCategory /> },
	{path: '/operation/suggestWordRandom', element: <SuggestWordRandom /> },
	{path: '/operation/suggestWordRandom/add', element: <SuggestWordRandomDetail /> },
	{path: '/operation/suggestWordRandom/edit', element: <SuggestWordRandomDetail /> },
	{path: '/operation/noticeNormal', element: <NoticeNormal /> },
	{path: '/operation/noticeNormal/add', element: <NoticeNormalDetail /> },
	{path: '/operation/noticeNormal/detail', element: <NoticeNormalDetail /> },
	{path: '/operation/noticeNormal/editSeq', element: <NoticeNormalEditSeq /> },

	{path: '/operation/communityBoardArticleSearch', element: <CommunityBoardArticleSearch/>},

	// 둘러보기
	{path: '/explore/allTab', element: <Category />},
	{path: '/explore/allTab/recentlyBeat', element: <RecentlyBeat />},
	{path: '/explore/allTab/recentlyBeat/add', element: <RecentlyBeatDetail />},
	{path: '/explore/allTab/recentlyBeat/edit', element: <RecentlyBeatDetail />},
	{path: '/explore/allTab/genre', element: <ExposureGenre />},
	{path: '/explore/allTab/genre/edit', element: <ExposureGenreDetail />},
	{path: '/explore/allTab/recommendGenre', element: <RecommendGenre />},
	{path: '/explore/allTab/recommendGenre/add', element: <RecommendGenreDetail />},
	{path: '/explore/allTab/recommendGenre/edit', element: <RecommendGenreDetail />},
	{path: '/explore/allTab/recommendBeatMaker', element: <RecommendBeatMaker />},
	{path: '/explore/allTab/recommendBeatMaker/add', element: <RecommendBeatMakerDetail />},
	{path: '/explore/allTab/recommendBeatMaker/edit', element: <RecommendBeatMakerDetail />},
	{path: '/explore/allTab/upcomingBeat', element: <UpcomingBeat />},
	{path: '/explore/allTab/upcomingBeat/add', element: <UpcomingBeatAdd />},
	{path: '/explore/allTab/upcomingBeat/edit', element: <UpcomingBeatEdit />},
	{path: '/explore/allTab/recommendCollection', element: <RecommendCollection />},
	{path: '/explore/allTab/recommendCollection/colList', element: <RecommendCollectionAddList />},
	{path: '/explore/allTab/recommendCollection/add', element: <RecommendCollectionDetail />},
	{path: '/explore/allTab/recommendCollection/edit', element: <RecommendCollectionDetail />},
	{path: '/explore/allTab/hashTag', element: <RecommendHashTag />},
	{path: '/explore/allTab/hashTag/edit', element: <RecommendHashTagDetail />},
	{path: '/explore/allTab/hashTag/editSeq', element: <RecommendHashTagEditSeq />},
	{path: '/explore/allTab/hashTag/colList', element: <RecommendHashTagList />},
	{path: '/explore/allTab/hashTag/colList/add', element: <RecommendHashTagDetail />},
	{path: '/explore/allTab/baundMusic', element: <ExploreContentsBaundMusic />},
	{path: '/explore/allTab/baundMusic/add', element: <ExploreContentsBaundMusicDetail />},
	{path: '/explore/allTab/baundMusic/edit', element: <ExploreContentsBaundMusicDetail />},
	{path: '/explore/genreTab', element: <GenreTabCategory />},
	{path: '/explore/genreTab/recentlyBeat', element: <RecentlyBeat />},
	{path: '/explore/genreTab/recentlyBeat/add', element: <RecentlyBeatDetail />},
	{path: '/explore/genreTab/recentlyBeat/edit', element: <RecentlyBeatDetail />},
	{path: '/explore/genreTab/recommendBeatMaker', element: <RecommendBeatMaker />},
	{path: '/explore/genreTab/recommendBeatMaker/add', element: <RecommendBeatMakerDetail />},
	{path: '/explore/genreTab/recommendBeatMaker/edit', element: <RecommendBeatMakerDetail />},
	{path: '/explore/genreTab/upcomingBeat', element: <UpcomingBeat />},
	{path: '/explore/genreTab/upcomingBeat/add', element: <UpcomingBeatAdd />},
	{path: '/explore/genreTab/upcomingBeat/edit', element: <UpcomingBeatEdit />},
	{path: '/explore/collection', element: <OfficialCollectionList />},
	{path: '/explore/collection/list', element: <RegisterCollection />},
	{path: '/explore/collection/add', element: <OfficialCollectionDetail />},
	{path: '/explore/collection/edit', element: <OfficialCollectionDetail />},
	{path: '/explore/communityCategory', element: <CommunityCategory />},
	{path: '/explore/communityCategory/editSeq', element: <CommunityCategoryEditSeq />},
	{path: '/explore/communityCategory/add', element: <CommunityCategoryDetail />},
	{path: '/explore/communityCategory/edit', element: <CommunityCategoryDetail />},
	{path: '/explore/curatedArticle', element: <CuratedArticle />},
	{path: '/explore/curatedArticle/edit', element: <CuratedArticleDetail />},
	{path: '/explore/curatedArticle/add', element: <CuratedArticleDetail />},
	{path: '/explore/curatedArticle/editSeq', element: <CuratedArticleEditSeq />},
	{path: '/explore/curatedTagsArticle', element: <CuratedTagsArticle />},
	{path: '/explore/curatedTagsArticle/edit', element: <CuratedTagsArticleDetail />},
	{path: '/explore/curatedTagsArticle/add', element: <CuratedTagsArticleDetail />},
	{path: '/explore/allTab/hotBeat', element: <HotBeat />},
	{path: '/explore/allTab/hotBeat/add', element: <HotBeatAdd />},
	{path: '/explore/allTab/hotBeat/edit', element: <HotBeatEdit />},
	{path: '/explore/allTab/hotBeat/editSeq', element: <HotBeatEditSeq />},
	{path: '/explore/allTab/mood', element: <ExposureMood /> },
	{path: '/explore/allTab/mood/edit', element: <ExposureMoodDetail />,},
	{path: '/explore/genreTab/hotBeat', element: <HotBeat />},
	{path: '/explore/genreTab/hotBeat/add', element: <HotBeatAdd />},
	{path: '/explore/genreTab/hotBeat/edit', element: <HotBeatEdit />},
	{path: '/explore/genreTab/hotBeat/editSeq', element: <HotBeatEditSeq />},
	{path: '/explore/curatedTagsArticle', element: <CuratedTagsArticle />},
	{path: '/explore/curatedTagsArticle/edit', element: <CuratedTagsArticleDetail />},
	{path: '/explore/curatedTagsArticle/add', element: <CuratedTagsArticleDetail />},
  // 전체 탭 > 무드
  {path: '/explore/allTab/mood', element: <ExposureMood /> },
  {path: '/explore/allTab/mood/edit', element: <ExposureMoodDetail />,},
	{path: '/explore/curatedSuggestWordArticle', element: <CuratedSuggestWordArticle />},
	{path: '/explore/curatedSuggestWordArticle/edit', element: <CuratedSuggestWordArticleDetail />},
	{path: '/explore/curatedSuggestWordArticle/add', element: <CuratedSuggestWordArticleDetail />},
	{path: '/explore/banner', element: <ExploreBanner />},


	// 필터
	{path: '/filter/category', element: <FilterCategory />},
	{path: '/filter/category/add', element: <FilterCategoryDetail />},
	{path: '/filter/category/edit', element: <FilterCategoryDetail />},
	{path: '/filter', element: <Filter />},
	{path: '/filter/add', element: <FilterDetail />},
	{path: '/filter/edit', element: <FilterDetail />},

	// 시스템
	{path: '/system/updateData', element: <UpdateData />},
	{path: '/system/refreshCache', element: <RefreshCache />},
	{path: '/system/maintenance', element: <Maintenance />},
	{path: '/system/baundApiCategory', element: <BaundApiCategory />},
	{path: '/system/baundApiCategory/edit', element: <BaundApiCategoryDetail />},
	{path: '/system/baundApiCategory/add', element: <BaundApiCategoryDetail />},
	{path: '/system/baundApiCategory/editSeq', element: <BaundApiCategoryEditSeq />},
	{path: '/system/baundApi', element: <BaundApi />},
	{path: '/system/baundApi/edit', element: <BaundApiDetail />},
	{path: '/system/baundApi/add', element: <BaundApiDetail />},
	{path: '/system/baundApi/editSeq', element: <BaundApiEditSeq />},

	// 바운드 뮤직
	{path: '/baundMusic/type', element: <SoundType />},
	{path: '/baundMusic/release', element: <ReleaseSound />},
	{path: '/baundMusic/release/add', element: <ReleaseSoundDetail />},
	{path: '/baundMusic/release/edit', element: <ReleaseSoundDetail />},
	{path: '/baundMusic/project', element: <BaundMusicProject />},
	{path: '/baundMusic/project/edit', element: <BaundMusicProjectDetail />},
	{path: '/baundMusic/project/add', element: <BaundMusicProjectDetail />},
	{path: '/baundMusic/project/editSeq', element: <BaundMusicProjectEditSeq />},

	// 컬쳐
	{path: '/culture/channel', element: <CultureChannel />},
	{path: '/culture/channel/edit', element: <CultureChannelDetail />},
	{path: '/culture/channel/add', element: <CultureChannelDetail />},
	{path: '/culture/program', element: <CultureProgram />},
	{path: '/culture/program/edit', element: <CultureProgramDetail />},
	{path: '/culture/program/add', element: <CultureProgramDetail />},
	{path: '/culture/program/editSeq', element: <CultureProgramEditSeq />},
	{path: '/culture/episode', element: <CultureEpisode />},
	{path: '/culture/episode/edit', element: <CultureEpisodeDetail />},
	{path: '/culture/episode/add', element: <CultureEpisodeDetail />},
	{path: '/culture/performance', element: <Performance />},
	{path: '/culture/performance/detail', element: <PerformanceDetail />},
	{path: '/culture/performanceCategory', element: <PerformanceCategory />},
	{path: '/culture/performanceCategory/detail', element: <PerformanceCategoryDetail />},
	{path: '/culture/curatedPerformance', element: <CuratedPerformance />},
	{path: '/culture/curatedPerformance/edit', element: <CuratedPerformanceDetail />},
	{path: '/culture/curatedPerformance/add', element: <CuratedPerformanceDetail />},
	{path: '/culture/curatedPerformance/editSeq', element: <CuratedPerformanceEditSeq />},
    {path: '/operation/noticeNormal', element: <NoticeNormal />},
    {path: '/operation/noticeNormal/add', element: <NoticeNormalDetail />},
    {path: '/operation/noticeNormal/detail', element: <NoticeNormalDetail />},
    {path: '/operation/noticeNormal/editSeq', element: <NoticeNormalEditSeq />},

	// 미션
	{path: '/rewards/mission-list', element: <MissionList /> },
	{path: '/rewards/mission-edit', element: <MissionDetail /> },
	{path: '/rewards/point-guide-area-list', element: <PointGuideAreaList /> },
	{path: '/rewards/point-guide-area-detail', element: <PointGuideAreaDetail /> },
	{path: '/rewards/master', element: <RewardMasterList /> },
	{path: '/rewards/master/edit', element: <RewardMasterDetail /> },
	{path: '/rewards/give-currency', element: <GiveCurrency /> },

	// 배틀
	{path: '/battle', element: <Battle />},
	{path: '/battle/detail', element: <BattleDetail />},
	{path: '/battle/config', element: <BattleBasicConfig />},
	{path: '/battle/config-any-is', element: <BattleAnyIsConfig />},

	// 캠페인
	{path: '/campaign/goldCampaign', element: <GoldCampaignList />},
	{path: '/campaign/goldCampaign/edit', element: <GoldCampaignDetail />},
	{path: '/campaign/goldCampaign/add', element: <GoldCampaignDetail />},
	{path: '/campaign/goldCampaign/editSeq', element: <GoldCampaignEditSeq />},

	// 임시
	{path: '/campaign/history-delete', element: <DeleteParticipationHistory />},

	// 배틀
	{path: '/battle', element: <Battle />},
	{path: '/battle/detail', element: <BattleDetail />},
	{path: '/battle/config', element: <BattleBasicConfig />},
	{path: '/battle/config-any-is', element: <BattleAnyIsConfig />},

	// 데이터 관리
	{path: '/datas/masterMessage', element: <MasterMessage />},
	{path: '/datas/masterMessage/add', element: <MasterMessageAdd />},

	// 쿠폰
	{path: '/coupons/coupon-list', element: <MasterCouponList />},
	{path: '/coupons/coupon-detail', element: <MasterCouponDetail />},
	{path: '/coupons/coupon-projects/coupon-list/detail', element: <MasterCouponDetail />},
	{path: '/coupons/coupon-projects', element: <CouponProjects />},
	{path: '/coupons/coupon-projects/coupon-list', element: <GenerateCodeCouponDetailList />},
	{path: '/coupons/coupon-projects/detail', element: <CouponProjectDetail />},
	{path: '/coupons/coupon-projects/add', element: <CreateCoupon />},

	// 매거진
	{ path: '/culture/magazine', element: <Magazine /> },
	{ path: '/culture/magazine/add', element: <MagazineDetail /> },
	{ path: '/culture/magazine/detail', element: <MagazineDetail /> },
	{ path: '/culture/magazine/editSeq', element: <MagazineEditSeq /> },
	{ path: '/culture/magazine-category', element: <MagazineCategory /> },
	{ path: '/culture/magazine-category/add', element: <MagazineCategoryDetail /> },
	{ path: '/culture/magazine-category/detail', element: <MagazineCategoryDetail /> },
	{path: '/culture/curatedMagazine', element: <CuratedMagazine />},
	{path: '/culture/curatedMagazine/edit', element: <CuratedMagazineDetail />},
	{path: '/culture/curatedMagazine/add', element: <CuratedMagazineDetail />},

	// 광고
  {path: '/advertisement/advertiser', element: <Advertisers />},
  {path: '/advertisement/advertiser/edit', element: <AdvertiserDetail />},
  {path: '/advertisement/advertiser/add', element: <AdvertiserDetail />},
  {path: '/advertisement/advertisementSummary', element: <Advertisements />},
  {path: '/advertisement/advertisementSummary/edit', element: <AdvertisementDetail />},
  {path: '/advertisement/advertisementSummary/add', element: <AdvertisementDetail />},
	{path: '/advertisement/advertisementLanding', element: <AdvertisementLandings />},
	{path: '/advertisement/advertisementLanding/edit', element: <AdvertisementLandingDetail />},
	{path: '/advertisement/advertisementLanding/add', element: <AdvertisementLandingDetail />},
	{path: '/advertisement/advertisementCampaign', element: <AdvertisementCampaigns />},
	{path: '/advertisement/advertisementCampaign/edit', element: <AdvertisementCampaignDetail />},
	{path: '/advertisement/advertisementCampaign/add', element: <AdvertisementCampaignDetail />},
	{path: '/advertisement/advertisementCampaignEditSeq_EL', element: <AdvertisementCampaignEditSeqEL />},
	{path: '/advertisement/advertisementCampaignEditSeq_NM', element: <AdvertisementCampaignEditSeqNM />},

	// 바운드 프로젝트
	{path: '/baundProject/baundProjectFaq', element:<BaundProjectFaq />},
	{path: '/baundProject/baundProjectFaq/edit', element:<BaundProjectFaqDetail />},
	{path: '/baundProject/baundProjectFaq/add', element:<BaundProjectFaqDetail />},
	{path: '/baundProject/baundProjectFaq/editSeq', element:<BaundProjectFaqEditSeq />},
	{path: '/baundProject/baundProjectCaution', element:<BaundProjectCaution />},
	{path: '/baundProject/baundProjectCaution/edit', element:<BaundProjectCautionDetail />},
	{path: '/baundProject/baundProjectCaution/add', element:<BaundProjectCautionDetail />},
	{path: '/baundProject/baundProjectCaution/editSeq', element:<BaundProjectCautionEditSeq />},
	{path: '/baundProject/baundProject', element:<BaundProject />},
	{path: '/baundProject/baundProject/edit', element:<BaundProjectDetail />},
	{path: '/baundProject/baundProject/add', element:<BaundProjectDetail />},
	{path: '/baundProject/baundProjectQna', element:<BaundProjectQna />},
	{path: '/baundProject/baundProjectQna/edit', element:<BaundProjectQnaDetail />},
	{path: '/baundProject/baundProject/baundProjectParticipant', element:<BaundProjectParticipant />},
	{path: '/baundProject/baundProject/baundProjectParticipant/detail', element:<BaundProjectParticipantDetail />},
];