import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import { addBeatCollectionColList } from '../../../constant/explore';
import DetailAddBeatRowComponents from '../../explore/components/category/DetailAddBeatRowComponents';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import { beatBpmOptions, beatSearchTypeList } from '../../../constant/beat';
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import { getBeatList } from '../../../redux/actions/beat/beat';
import { useAppSelector } from '../../../redux/store/hooks';
import useInput from '../../../hooks/useInput';
import { CustomProps } from '../../../definitions/common';
import { postsPerPage } from '../../../constant/common';
import ListDateSelect from '../../../components/common/Select/ListDateSelect';
import { RowComponentDataProps } from '../../../definitions/beat';

const PushForBeat = ({
  closeModal,
  setSelectedModalObj,
  setSelectedModalType,
}: any) => {
  const dispatch = useDispatch();
  const beatList = useAppSelector((state) => state.beat.beatList);
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const [selectedBeatList, setSelectedBeatList] = useState<any>([]);
  const [playBeat, setPlayBeat] = useState('');
  const [word, setWord, wordReset] = useInput('');
  const [beatType, setBeatType] = useState<string>('');
  const [bpm, setBpm] = useState<string>('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);
  const [privateYn, setPrivateYn] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const beatParams = {
    type: beatType,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn,
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!beatType) {
        setBeatType('T');
        beatParams.type = 'T';
      }
      setPageNumber(0);
      beatParams.offset = 0;
      dispatch(getBeatList(beatParams));
    }
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const filterSelectedContent = (el: any) => {
    const result = selectedBeatList.filter((item: any) => item !== el);
    setSelectedBeatList(result);
  };

  // 비트 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (selectedBeatList.length !== 0) {
      alert('비트는 하나만 선택할 수 있습니다.');
    } else setSelectedBeatList([...selectedBeatList, data]);
  };

  const saveBeat = () => {
    if (selectedBeatList.length === 0) {
      alert('최소 하나의 비트를 선택해야합니다.');
      return;
    }
    alert('저장되었습니다.');
    setSelectedModalObj(selectedBeatList[0]);
    setSelectedModalType('BT');
    closeModal();
  };

  useEffect(() => {
    dispatch(getBeatList(beatParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    beatParams.offset = 0;
    setPageNumber(0);
    switch (bpm) {
      case '':
        setMinBpm(0);
        setMaxBpm(250);
        break;
      case '49':
        setMinBpm(0);
        setMaxBpm(49);
        break;
      case '50':
        setMinBpm(50);
        setMaxBpm(80);
        break;
      case '80':
        setMinBpm(80);
        setMaxBpm(100);
        break;
      case '100':
        setMinBpm(100);
        setMaxBpm(140);
        break;
      case '140':
        setMinBpm(140);
        setMaxBpm(180);
        break;
      case '180':
        setMinBpm(180);
        setMaxBpm(230);
        break;
      default:
        setMinBpm(0);
        setMaxBpm(250);
        break;
    }
    dispatch(getBeatList(beatParams));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, bpm, maxBpm, minBpm, minStartDate, maxStartDate]);

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn>
      <VideoModalWrapper>
        <ModalButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={closeModal}
            className="cancelBtn"
          />
          <NormalButton text="저장하기" onSubmit={saveBeat} />
        </ModalButtonWrapper>
        <VideoModalTitle>선택 비트</VideoModalTitle>
        {selectedBeatList?.length !== 0 && selectedBeatList ? (
          <>
            <TableTitle>{selectedBeatList?.length}개 비트</TableTitle>
            <LineDiv />
            <Table colList={addBeatCollectionColList} className="nonListTable">
              {selectedBeatList?.map((data: any) => (
                <DetailAddBeatRowComponents
                  key={data.id}
                  data={data}
                  playBeat={playBeat}
                  setPlayBeat={setPlayBeat}
                  onClick={() => {
                    filterSelectedContent(data);
                  }}
                  selected
                  editable
                />
              ))}
            </Table>
          </>
        ) : (
          // 비트 모달
          <>
            <VideoModalSubText>
              선택된 비트가 없습니다. 아래 검색 후 비트를 선택해주세요.
            </VideoModalSubText>
            <LineDiv />
          </>
        )}
        <SpaceDiv value="90px" />
        <VideoModalTitle>비트 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={beatSearchTypeList}
              onClick={handleSelect}
              setState={setBeatType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          <TableTitle>{beatTotalCount}개 비트</TableTitle>
          {beatTotalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={beatTotalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv className="30" />
        <Table
          colList={addBeatCollectionColList}
          thead={
            <>
              <ListHead title="Cover" />
              <ListHead title="Title" />
              <ListHead title="Pid" />
              <ListHead title="장르" />
              <ListSelect
                title="BPM"
                list={beatBpmOptions}
                onClick={handleSelect}
                setState={setBpm}
              />
              <ListHead title="BeatKey" />
              <ListHead title="공개여부" />
              <ListDateSelect
                title="공개 날짜"
                setMinStartDate={setMinStartDate}
                setMaxStartDate={setMaxStartDate}
              />
              <ListHead title="" />
            </>
          }
        >
          {beatList?.map((el: RowComponentDataProps) => (
            <DetailAddBeatRowComponents
              key={el.id}
              data={el}
              onClick={addSelectedContent}
              playBeat={playBeat}
              setPlayBeat={setPlayBeat}
              editable
            />
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

export default PushForBeat;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpaceDiv = styled.div<CustomProps>`
  height: ${({ value }) => value};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
