import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import axios from "axios";
import {useDispatch} from "react-redux";

import {resetLoading, setLoading} from "../../redux/slices/user";

import Modal from "../../components/common/Modal/Modal";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import NormalButton from "../../components/common/Button/NormalButton";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import Pagination from "../../components/common/Pagination";
import ListItem from "../../components/common/List/ListItem";
import EditButton from "../../components/common/Button/EditButton";
import ListCoverImg from "../../components/common/List/ListCoverImg";
import SelectModule from "../../components/common/Select/SelectModule";

import handleDate from "../../hooks/handleDate";
import * as commonFn from "../../utils/commonFn";
import {postsPerPage as limit} from "../../constant/common";
import {EXPLORE_BANNER} from "../../constant/apiUrl";
import {bannerImgTypeValues, bannerTypeValues} from "../../constant/explore";
import {navigationSubTypeValues, navigationTypeValues} from "../../constant/operation";
import FileInput from "../../components/common/Input/FileInput";

const initExploreBanner = {
	exploreBannerId: null,
	type: null,
	naviType: null,
	naviTypeId: null,
	naviSubType: null,
	naviSubTypeId: null,
	imgUrl: null
}

const ExploreBanner = () => {
	const dispatch = useDispatch();
	
	const [exploreBannerList, setExploreBannerList] = useState([]);
	const [offset, setOffset] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	
	const [detail, setDetail] = useState(null);
	const [imgType, setImgType] = useState('ADDRESS');
	const [bannerImgFile, setBannerImgFile] = useState(null);
	
	const [copyTextInfo, setCopyTextInfo] = useState(false);
	
	const pageDispatch = useCallback((e) => setOffset(e - 1), []);
	
	const getExploreBannerList = () => {
		dispatch(setLoading());
		axios
		.get(`${EXPLORE_BANNER}`, {params: {offset, limit}})
		.then(res => {
			setExploreBannerList(res.data.banners || []);
			setTotalCount(res.data.totalCount);
		})
		.finally(() => dispatch(resetLoading()))
	}
	
	const saveExploreBanner = () => {
		dispatch(setLoading());
		
		if (!bannerImgFile && !detail.imgUrl) {
			alert('이미지를 등록해주세요.');
			dispatch(resetLoading());
			return;
		}
		
		const _detail = {...detail};
		if (!_detail.naviTypeId) _detail.naviTypeId = null;
		if (!_detail.naviSubTypeId) _detail.naviSubTypeId = null;
		if (bannerImgFile) _detail.imgUrl = null;
		
		const formData = new FormData();
		formData.append('bannerRequest', new Blob([JSON.stringify({banner: _detail})], {type: 'application/json'}));
		formData.append('bannerImgFile', bannerImgFile);
		
		axios
			.post(`${EXPLORE_BANNER}`, formData, {})
			.then(() => {
				alert('저장(수정) 되었습니다.');
				setDetail(null);
				getExploreBannerList();
			})
			.catch((err) => {
				console.log(err);
				alert('필수값이 누락되었거나 서버에 문제가 있습니다. 다시 시도해주세요.');
			})
			.finally(() => dispatch(resetLoading()))
	}
	
	const deleteExploreBanner = (exploreBannerId) => {
		dispatch(setLoading());
		axios
		.delete(`${EXPLORE_BANNER}/${exploreBannerId}`)
		.then(() => {
			alert('삭제되었습니다.');
			setDetail(null);
			getExploreBannerList();
		})
		.finally(() => dispatch(resetLoading()))
	}
	
	const makeCopyText = () => {
		let text = `/explore/banner/${detail.type}/${detail.naviType}`;
		text += detail.naviTypeId ? `/${detail.naviTypeId}` : `/0`;
		text += detail.naviSubType ? `/${detail.naviSubType}` : `/0`;
		text += detail.naviSubTypeId ? `/${detail.naviSubTypeId}` : `/0`;
		
		if (window?.navigator?.clipboard !== undefined) {
			window.navigator.clipboard.writeText(text).then(() => {
				setCopyTextInfo('복사 성공!');
				setInterval(() => setCopyTextInfo(null), 3000);
			});
		} else {
			const textArea = document.createElement('textarea');
			textArea.value = text;
			document.body.appendChild(textArea);
			textArea.select();
			textArea.setSelectionRange(0, 99);
			
			let infoText;
			try {
				document.execCommand('copy');
				textArea.setSelectionRange(0, 0);
				document.body.removeChild(textArea);
				infoText = '복사 성공!';
			} catch (err) {
				infoText = '복사 실패!';
			} finally {
				setCopyTextInfo(infoText);
				setTimeout(() => setCopyTextInfo(null), 3000);
			}
		}
	}
	
	useEffect(() => getExploreBannerList(), [offset]);
	useEffect(() => detail || setImgType('ADDRESS'), [detail]);
	useEffect(() => {
		setBannerImgFile(null);
		detail && setDetail({...detail, imgUrl: ''});
	}, [imgType]);
	
	return <>
		<TopContainer>
			<NormalButton text="둘러보기 배너 추가" onSubmit={() => setDetail({...initExploreBanner})}/>
		</TopContainer>
		
		<LineDiv mb={40}/>
		
		<MainContainer>
			<PaginationWrapper>
				{totalCount !== 0 &&
					<Pagination
						totalCount={totalCount}
						postsPerPage={limit}
						currentPage={offset}
						pageDispatch={pageDispatch}
					/>
				}
			</PaginationWrapper>
			
			<Table thead={<>
				<ListHead title="이미지" rowWidth={190} />
				<ListHead title="배너 타입" rowWidth={80} />
				<ListHead title="네비 타입" rowWidth={100} />
				<ListHead title="네비 타입 아이디" rowWidth={100} />
				<ListHead title="네비 서브 타입" rowWidth={100} />
				<ListHead title="생성일시" rowWidth={120} />
				<ListHead title="수정" rowWidth={50} />
			</>}>
				{exploreBannerList?.map(exploreBanner => <tr key={exploreBanner.exploreBannerId}>
					<td><ListCoverImg
						url={exploreBanner.imgUrl}
						className={exploreBanner.type === 'STICK' ? 'fixedArticle' : ''}
					/></td>
					<td><ListItem text={exploreBanner.type} /></td>
					<td><ListItem text={exploreBanner.naviType} /></td>
					<td><ListItem text={exploreBanner.naviTypeId} /></td>
					<td><ListItem text={exploreBanner.naviSubType} /></td>
					<td><ListItem text={handleDate(exploreBanner.createDate)} /></td>
					<td><EditButton text="수정" onClick={() => setDetail(exploreBanner)}/></td>
				</tr>)}
			</Table>
		</MainContainer>
		
		{
			detail && <Modal closeModal={() => setDetail(null)} option={{class: 'compact'}}>
				<ModalWrapper>
					{
						detail?.exploreBannerId > 0 && <InputWrapper className='copyTextInfo'>
							<NormalButton text="API 공식 복사" onSubmit={makeCopyText}/>
							<span style={{opacity: copyTextInfo ? 1 : 0}}>{copyTextInfo}</span>
						</InputWrapper>
					}
					<InputWrapper>
						<SelectModule
							Required
							name="type"
							label='둘러보기 배너 타입'
							value={bannerTypeValues[detail.type]}
							options={commonFn.transObjectToOptions(bannerTypeValues)}
							onChange={e => setDetail({...detail, type: e.value})}
							placeholder=''
						/>
					</InputWrapper>
					<InputWrapper>
						<SelectModule
							name="type"
							label='네비게이션 타입'
							value={navigationTypeValues[detail.naviType]}
							options={commonFn.transObjectToOptions(navigationTypeValues)}
							onChange={e => setDetail({...detail, naviType: e.value})}
							placeholder=''
						/>
					</InputWrapper>
					<InputWrapper>
						<LabelTextInput
							type='number'
							label='네비게이션 타입 아이디'
							value={detail.naviTypeId}
							name='naviTypeId'
							onChange={e => setDetail({...detail, naviTypeId: Number(e.target.value)})}
							min={0}
						/>
					</InputWrapper>
					<InputWrapper>
						<SelectModule
							name="type"
							label='네비게이션 서브 타입'
							value={navigationSubTypeValues[detail.naviSubType]}
							options={commonFn.transObjectToOptions(navigationSubTypeValues)}
							onChange={e => setDetail({...detail, naviSubType: e.value === 'NONE' ? null : e.value})}
							placeholder=''
						/>
					</InputWrapper>
					<InputWrapper>
						<LabelTextInput
							type='number'
							label='네비게이션 서브 타입 아이디'
							value={detail.naviSubTypeId}
							name='naviSubTypeId'
							onChange={e => setDetail({...detail, naviSubTypeId: Number(e.target.value)})}
							min={0}
						/>
					</InputWrapper>
					<InputWrapper>
						<SelectModule
							name="type"
							label='둘러보기 배너 이미지 타입 선택'
							value={bannerImgTypeValues[imgType]}
							options={commonFn.transObjectToOptions(bannerImgTypeValues)}
							onChange={e => setImgType(e.value)}
							placeholder=''
							Required
						/>
						{
							imgType === 'ADDRESS' ? <InputWrapper>
								<LabelTextInput
									value={detail.imgUrl}
									name='imgUrl'
									onChange={e => setDetail({...detail, imgUrl: e.target.value})}
								    label=''
								/>
								<Img src={detail.imgUrl ? detail.imgUrl : ''}/>
							</InputWrapper> :
							
							imgType === 'FILE' ? <InputWrapper>
								<FileInput
									type="bannerImg"
									placeholder={detail.imgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
									value={bannerImgFile}
									setFile={file => setBannerImgFile(file)}
									setImgUrl={data => setDetail({...detail, imgUrl: data})}
									fileUrl={detail.imgUrl}
								/>
								<div>
									<Img src={detail.imgUrl ? detail.imgUrl : ''}/>
									<ValueDesc>
										<span className='red'>이미지 수정 시 바로 반영되지않으며 각 엔드포인트마다 변경 시점이 다를 수 있습니다.</span>
										<span className='red'>(* 긴급 시 시점을 어느 정도 앞당길 수 있는 작업이 있으니 서버팀에 요청해주세요.)</span>
									</ValueDesc>
								</div>
							</InputWrapper> : null
						}
					</InputWrapper>
					
					<DetailButtonWrapper>
						<NormalButton text="저장" onSubmit={saveExploreBanner}/>
						{
							detail.exploreBannerId > 0 && <NormalButton text="삭제" className='deleteBtn' onSubmit={() => {
								if (confirm('삭제하시겠습니까?')) deleteExploreBanner(detail.exploreBannerId)
							}}/>
						}
					</DetailButtonWrapper>
				</ModalWrapper>
			</Modal>
		}
	</>;
};

export default ExploreBanner;


const TopContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
  text-align: right;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
`;

const MainContainer = styled.div`
  width: 100%;
`;

const PaginationWrapper = styled.div`
  margin-bottom: 30px;
`;

const DetailButtonWrapper = styled.div`
	display: flex;
	gap: 15px;
	justify-content: center;
`;

const ModalWrapper = styled.div`
	width: 600px;
	display: grid;
	gap: 20px;
`;

const InputWrapper = styled.section`
	transition: 2s;
  
  &.copyTextInfo {
    margin-bottom: 20px;
    span {
      transition: 1s;
      margin-left: 10px;
      font-size: 14px;
    }
  }
`;

const Img = styled.img`
  width: 100%;
  max-height: 200px;
  margin-top: 10px;
  border-radius: 10px;
`;

const ValueDesc = styled.div`
  padding: 10px;
  line-height: 1.5;

  span {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
    
    &.red {
      color: ${({ theme }) => theme.color.mainRed};
    }
  }
  li {
    display: block;
    font-size: 13px;
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
    margin-left: 20px;
  }
`;