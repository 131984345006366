import React from 'react';
import styled from 'styled-components';

interface EditButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
}

const EditButton = ({ onClick, text, className }: EditButtonProps) => (
  <Button onClick={onClick} className={className}>
    {text}
  </Button>
);

export default EditButton;

const Button = styled.button`
  padding: 10px 14px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  color: ${({ theme }) => theme.color.subDarkGray};
  white-space: nowrap;
  cursor: pointer;

  // 검정 저장 버튼
  &.saveBtn {
    border: 1px solid ${({ theme }) => theme.color.mainBlack};
    background-color: ${({ theme }) => theme.color.mainBlack};
    color: ${({ theme }) => theme.color.mainWhite};
  }

  // 보라 선택 버튼
  &.selectBtn {
    border: 1px solid ${({ theme }) => theme.color.subBrightGray};
    background-color: ${({ theme }) => theme.color.subBrightGray};
    color: ${({ theme }) => theme.color.mainPurple};
  }

  // 회색 삭제 버튼
  &.deleteBtn {
    border: 1px solid ${({ theme }) => theme.color.subDarkGray};
    background-color: ${({ theme }) => theme.color.subDarkGray};
    color: ${({ theme }) => theme.color.mainWhite};
  }

  // 라벨 인풋용 버튼
  &.labelInputBtn {
    margin: 25px 0 0 20px;
  }


  &.performanceAddBtn {
    border: 1px solid ${({ theme }) => theme.color.mainBlack};
    background-color: ${({ theme }) => theme.color.mainBlack};
    color: ${({ theme }) => theme.color.mainWhite};
    margin-top: 35px;
  }
  &.performancePopBtn {
    margin-top: 35px;
  }
`;
