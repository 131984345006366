import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import styled from 'styled-components';

// common
import RoundButton from '../../components/common/Button/RoundButton';
import SearchInput from '../../components/common/Input/SearchInput';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import Pagination from '../../components/common/Pagination';

//constant
import {
  csDeleteColList,
  csBlockColList,
  csConfirmColList,
  csSearchTypeList,
} from '../../constant/cs';
import { postsPerPage } from '../../constant/common';

// 타입
import { blockRowComponentDataProps } from '../../definitions/customService';

// hooks
import useInput from '../../hooks/useInput';

// config
import {
  CONTENTS_ARTICLE,
  REPORT_BLOCK_LIST,
  REPORT_CONFIRM_LIST,
  REPORT_REJECT_LIST,
} from '../../constant/apiUrl';

// component
import DeleteListRowComponent from './components/DeletListRowComponent';
import NeedConfirmListRowComponent from './components/NeedConfirmRowComponent';
import BlockListRowComponent from './components/BlockListRowComponent';

const BlockVideo = () => {
  const navigate = useNavigate();
  const storageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('blockVideoType');
  const [blockList, setBlockList] = useState([]);
  const [needConfirmList, setNeedConfirmList] = useState([]);
  const [deleteList, setDeleteList] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, onReset] = useInput('');
  const [type, setType] = useState(storageType || '미차단 내역');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storageNumber ? JSON.parse(storageNumber) : 0,
  );

  const params = {
    searchType,
    contentsType: 'AR',
    keyword,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  useEffect(() => {
    if (storageNumber || storageType) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('blockVideoType', '');
    }
  }, [storageNumber, storageType]);

  const saveStorage = () => {
    window.localStorage.setItem('pageNumber', String(pageNumber));
    window.localStorage.setItem('blockVideoType', type);
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      if (!searchType) {
        setSearchType('TM');
        params.searchType = 'TM';
      }
      setPageNumber(0);
      params.offset = 0;
      getReportList();
    }
  };

  //신고 미처리, 차단, 삭제 내역 조회
  const getReportList = async () => {
    switch (type) {
      case '미차단 내역':
        try {
          const resp = await axios.get(REPORT_CONFIRM_LIST, {
            params,
          });
          setNeedConfirmList(resp.data.adminArticleReportList);
          setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      case '차단 내역':
        try {
          const resp = await axios.get(REPORT_BLOCK_LIST, {
            params,
          });
          setBlockList(resp.data.adminLogList);
          setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      case '삭제 내역':
        try {
          const resp = await axios.get(REPORT_REJECT_LIST, {
            params,
          });
          setDeleteList(resp.data.adminLogList);
          setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;

      default:
    }
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 상단 버튼 클릭 이벤트
  const handleButton = (e: any) => {
    const { title } = e.target;
    setType(title);
    onReset();
  };

  // 리스트별 디테일 페이지 나타내기
  const goToVideoDetail = async (id: number, data: any) => {
    try {
      const resp = await axios.get(`${CONTENTS_ARTICLE}/${id}`);
      navigate('/cs/blockVideo/detail', {
        state: { contentsUrl: resp.data.article.contentsUrl, data, type },
        replace: false,
      });
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
      console.log(err);
    }
  };

  useEffect(() => {
    if (storageType !== type) {
      params.offset = 0;
      setPageNumber(0);
    }
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    getReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);
  return (
    <>
      <ButtonContainer>
        <RoundButton
          title="미차단 내역"
          onClick={handleButton}
          selected={type === '미차단 내역' && true}
        />
        <RoundButton
          title="차단 내역"
          selected={type === '차단 내역' && true}
          onClick={handleButton}
        />
        <RoundButton
          title="삭제 내역"
          selected={type === '삭제 내역' && true}
          onClick={handleButton}
        />
      </ButtonContainer>
      <Line />
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={csSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
              state={csSearchTypeList[0]}
            />
            <SearchInputWrapper>
              <SearchInput
                value={keyword}
                onChange={setKeyword}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        {type === '미차단 내역' && (
          <Table
            colList={csConfirmColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="" />
              </>
            }
          >
            {needConfirmList?.map((el: blockRowComponentDataProps) => (
              <NeedConfirmListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el.contentsId, el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '차단 내역' && (
          <Table
            colList={csBlockColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="처리자" />
                <ListHead title="처리 날짜" />
                <ListHead title="신고 상태" />
                <ListHead title="" />
              </>
            }
          >
            {blockList?.map((el: blockRowComponentDataProps) => (
              <BlockListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el.contentsId, el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '삭제 내역' && (
          <Table
            colList={csDeleteColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="신고 유형" />
                <ListHead title="신고 받은 Pid" />
                <ListHead title="신고 한 Pid" />
                <ListHead title="신고 날짜" />
                <ListHead title="처리자" />
                <ListHead title="처리 날짜" />
                <ListHead title="신고 상태" />
                <ListHead title="" />
              </>
            }
          >
            {deleteList?.map((el: blockRowComponentDataProps) => (
              <DeleteListRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el.contentsId, el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
      </MainContainer>
    </>
  );
};

export default BlockVideo;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Line = styled.div`
  display: block;
  border-bottom: 1px solid #e7e7e7;
  margin: 30px 0 50px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 50px;
`;
