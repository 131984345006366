import React from 'react';

// component
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';

// constant
import { rekognitionType } from '../../../constant/cs';

const RekognitionRowComponent = ({ data, onClick, saveStorage }: any) => {
  const { id, memberPid, uploadDt, articleType } = data;

  return (
    <tr key={id}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={uploadDt} />
      </td>
      <td>
        <ListItem text={rekognitionType[articleType]} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            onClick(data);
            saveStorage();
          }}
          text="영상"
        />
      </td>
    </tr>
  );
};

export default RekognitionRowComponent;
