export const missionConditionTypeList = [
  { text: '목표 없음', value: 'NO_OBJECT' },
  { text: '카운트', value: 'COUNT' },
];

export const missionRewardTypeList = [
  { text: '일일 초기화', value: 'DAILY' },
  { text: '단일 미션', value: 'ONLY_ONCE' },
];

export const rewardCurrencyTypeList = [
    { text: '바운드 골드', value: 'BAUND_GOLD' },
];

export const missionConditionList = [
    { text: 'WORD_COUNT', value: '글자수 제한' },
    { text: 'SECONDS_COUNT', value: '시간초 제한' },
    { text: 'EXCLUDE_OWNER_CONTENTS', value: '자기 자신의 컨텐츠 제외' },
    { text: 'EXCLUDE_SAME_CONTENTS_PERIOD', value: '동일 컨텐츠 주기성 제외' },
    { text: 'NO_OBJECT', value: '조건 없음' },
];
