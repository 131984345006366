import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { COMMUNITY_CURATED_ARTICLE } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import ListHead from '../../../components/UI/ListHead';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Table from '../../../components/common/List/Table';
import { CustomProps } from '../../../definitions/common';
import SearchInput from '../../../components/common/Input/SearchInput';
import useInput from '../../../hooks/useInput';
import { CuratedTagsRowComponent } from './component/CuratedTagsModalRowComponent';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import handleDate from '../../../hooks/handleDate';
import { IsPrivate, IsStatus } from '../../../constant/common';
import { eventModalColList } from '../../../constant/event';

const CuratedTagsArticleDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const [contentsIds, setContentsIds] = useState<any>([]);
  const [keyword, setKeyword, onReset] = useInput('');
  //선택된 태그
  const [tagsCurated, setTagsCurated] = useState<any>([]);
  //선택된 태그로 옮기기 전 임시 저장
  const [tagsWork, setTagsWork] = useState<any>([]);
  //선택을 위한 검색 결과
  const [tagsMaster, setTagsMaster] = useState<any>([]);

  //태그를 사용한 영상 확인 용
  const [targetArticles, setTargetArticles] = useState<any>([]);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${COMMUNITY_CURATED_ARTICLE}/${prevId}/detail`,
      );
      if (resp.data.adminCuratedTags) {
        setName(resp.data.adminCuratedTags.name);
        setNameEn(resp.data.adminCuratedTags.nameEn);
        setDescription(resp.data.adminCuratedTags.description);
        setDescriptionEn(resp.data.adminCuratedTags.descriptionEn);
        setSequence(resp.data.adminCuratedTags.sequence);
        setActiveYn(resp.data.adminCuratedTags.activeYn);
        setStartDt(
          resp.data.adminCuratedTags.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCuratedTags.endDt?.replace(' ', 'T').slice(0, 16),
        );
        setTagsCurated(resp.data.adminCuratedTags.adminTags);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]);

  useEffect(() => {
    setContentsIds(tagsCurated.map((el: any) => el.id));
    setTargetArticles([]);
    setTagsWork([]);
  }, [tagsCurated]);

  // 모달 열기
  const setModal = () => {
    setModalOpen(true);
    setTagsWork([...tagsCurated]);
    setTagsMaster([]);
  };

  // 태그 검색
  const searchTags = async () => {
    try {
      const searchTagsParams = {
        offset: 0,
        limit: 20,
        search: keyword,
      };
      const resp = await axios.get('/tag/name', { params: searchTagsParams });
      setTagsMaster(resp.data.tags);
    } catch (err) {
      console.log(err);
    }
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    setTagsWork([]);
    onReset();
  };

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    setTagsCurated([...tagsWork]);
    closeModal();
  };

  // 클릭시 추가
  const addSelectedContent = (data: any) => {
    console.log(data);
    if (!tagsWork?.find((e: any) => e.id === data.id)) {
      setTagsWork([...tagsWork, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (id: any) => {
    const result = tagsWork.filter((item: any) => item.id !== id);
    setTagsWork(result);
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      searchTags();
    }
  };

  const getTargetArticles = async () => {
    try {
      const targetArticleParams = {
        ids: contentsIds,
      };
      const resp = await axios.get('/tag/articles', {
        params: targetArticleParams,
      });
      if (resp.data.articles) {
        setTargetArticles(resp.data.articles || []);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  const addContents = async (type: string) => {
    if (contentsIds?.length === 0) {
      alert('게시글을 선택해주세요.');
      return;
    }
    if (!startDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const body = {
      name,
      nameEn,
      description,
      descriptionEn,
      contentsType: 'TAG',
      contentsIds,
      sequence,
      activeYn,
      startDate: startDt,
      endDate: endDt,
    };

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(
          `${COMMUNITY_CURATED_ARTICLE}/${prevId}/edit`,
          body,
          {},
        );
      } else {
        await axios.post(`${COMMUNITY_CURATED_ARTICLE}`, body, {});
      }
      await navigate('/explore/curatedTagsArticle');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="컨텐츠 명"
            value={name}
            placeholder="컨텐츠 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="컨텐츠 명 EN"
            value={nameEn}
            placeholder="컨텐츠 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="컨텐츠 설명"
            value={description}
            placeholder="컨텐츠 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="컨텐츠 설명 EN"
            value={descriptionEn}
            placeholder="컨텐츠 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <VideoInput onClick={() => setModal()} text="태그 선택" />
        </InputWrapper>
        <InputWrapper>
          <LineDiv value="0" />
        </InputWrapper>
        <InputWrapper>
          {tagsCurated?.map((data: any) => (
            <SelectTag key={data.id}>
              <SelectTagText>{data.tag}</SelectTagText>
            </SelectTag>
          ))}
        </InputWrapper>
        {tagsCurated?.length !== 0 && (
          <>
            <InputWrapper>
              <SpaceDiv />
              <VideoInput
                onClick={() => getTargetArticles()}
                text="태그를 사용한 영상 확인"
              />
            </InputWrapper>
            <InputWrapper>
              <Table
                colList={eventModalColList}
                thead={
                  <>
                    <ListHead title="id" />
                    <ListHead title="썸네일" />
                    <ListHead title="Pid" />
                    <ListHead title="이름" />
                    <ListHead title="비트명" />
                    <ListHead title="업로드 날짜" />
                    <ListHead title="공개 여부" />
                    <ListHead title="상태" />
                    <ListHead title="" />
                  </>
                }
              >
                {targetArticles?.map((data: any) => (
                  <tr key={data.id}>
                    <td>
                      <ListItem text={data.id} />
                    </td>
                    <td>
                      <ListCoverImg
                        className="article"
                        url={data.coverImgUrl}
                      />
                    </td>
                    <td>
                      <ListItem text={data.pid} />
                    </td>
                    <td>
                      <ListItem text={data.name} />
                    </td>
                    <td>
                      <ListItem text={data.beatTitle} />
                    </td>
                    <td>
                      <ListItem text={handleDate(data.updateDt)} />
                    </td>
                    <td>
                      <ListItem text={IsPrivate[data.privateYn]} />
                    </td>
                    <td>
                      <ListItem text={IsStatus[data.articleStatus]} />
                    </td>
                  </tr>
                ))}
              </Table>
            </InputWrapper>
          </>
        )}
      </MainContainer>
      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={closeModal}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => saveContentList()}
              />
            </ModalButtonWrapper>
            <VideoModalTitle>선택된 태그</VideoModalTitle>
            {tagsWork?.length !== 0 && tagsWork ? (
              <>
                <LineDiv />
                {tagsWork?.map((data: any) => (
                  <SelectTag key={data.id}>
                    <SelectTagText>{data.tag}</SelectTagText>
                    <TagDeleteButton
                      onClick={() => filterSelectedContent(data.id)}
                    />
                  </SelectTag>
                ))}
              </>
            ) : (
              <>
                <VideoModalSubText>
                  선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.
                </VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>태그 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchInputWrapper>
                  <SearchInput
                    value={keyword}
                    onChange={setKeyword}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <LineDiv className="30" />
            <Table
              colList={[20, 100, 20]}
              thead={
                <>
                  <ListHead title="id" />
                  <ListHead title="태그명" />
                  <ListHead title="" />
                </>
              }
            >
              {tagsMaster?.map((el: any) => (
                <CuratedTagsRowComponent
                  data={el}
                  onClick={addSelectedContent}
                  btnType="add"
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default CuratedTagsArticleDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }
  &:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(n + 6):nth-child(-n + 7) {
    grid-row: 4;
  }
  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-row: 6;
    grid-column: span 2;
  }
  &:nth-child(10) {
    grid-row: 7;
    grid-column: span 2;
  }
  &:nth-child(11) {
    grid-row: 8;
    grid-column: span 2;
  }
  &:nth-child(12) {
    grid-row: 9;
    grid-column: span 2;
  }
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const LineDiv = styled.div<CustomProps>`
  width: 100%;
  height: 1px;
  margin: ${({ value }) => value || '10'}px 0 ${({ value }) => value || '10'}px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div<CustomProps>`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SelectTag = styled.div`
  display: inline-flex;
  align-items: center; //세로가운데
  margin: 0 10px 0 0;
  padding: 8px 10px 8px 12px;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  border-radius: 30px;
`;

const SelectTagText = styled.span`
  margin-right: 5px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TagDeleteButton = styled.button`
  width: 18px;
  height: 18px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
  &.unEditable {
    display: none;
  }
`;
