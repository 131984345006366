import React, {useEffect, useState} from "react";
import { useDispatch } from 'react-redux';
import {createAsyncThunk} from "@reduxjs/toolkit";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import {
  campaignOptions,
  IsPrivate,
  postsPerPage
} from "../../../constant/common";

import NormalButton from "../../../components/common/Button/NormalButton";
import Pagination from "../../../components/common/Pagination";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import ListItem from "../../../components/common/List/ListItem";
import useInput from "../../../hooks/useInput";
import {CAMPAIGNS, CAMPAIGNS_COPY} from "../../../constant/apiUrl";
import axios from "axios";
import RoundButton from "../../../components/common/Button/RoundButton";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import Modal from "../../../components/common/Modal/Modal";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";

// 골드 캠페인 목록 화면
const GoldCampaignList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 검색 조건
  const [type, setType] = useState('');
  const [word, setWord] = useInput('');
  const [privateYn, setPrivateYn] = useState('N');
  const [goodsType, setGoodsType] = useState('A');
  // 페이징
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  //표시 데이터
  const [campaignList, setCampaignList] = useState([]);

  // 복제하기에서 사용
  const [modalOpen, setModalOpen] = useState(false);
  const [campaignId, setCampaignId] = useState(0);

  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params = {
    type,
    word,
    privateYn,
    goodsType,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };

  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getCampaigns = createAsyncThunk(
      'campaign/GoldCampaigns',
      async(params, {rejectWithValue}) => {
        await axios.get(CAMPAIGNS, {params})
        .then(res => {
          setCampaignList(res.data.campaignList);
          setTotalCount(res.data.count);
        })
        .catch(reason => {
          console.error(rejectWithValue(reason.response));
          return rejectWithValue(reason.response);
        });
      },
  );
  //검색 조건 변경시 dispatch
  useEffect(()=> {
    dispatch(getCampaigns(params));
  }, [dispatch, privateYn, goodsType, pageNumber]);

  // 복제하기
  const eventCampaign = () => {

    if (!campaignId) {
      alert('캠페인 아이디를 입력해주세요.');
      return;
    }
    dispatch(setLoading());

    axios.post(`${CAMPAIGNS_COPY}/${campaignId}`)
    .then(() => {
      setCampaignId(0);
      setModalOpen(false);
      getCampaigns();
    })
    .catch(error => {
      console.log(error.response);
      error?.response?.data
          ? alert(error.response.data.message)
          : alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    })
    .finally( () =>{
      dispatch(resetLoading());
    });
  }

  return (
    <>
      <TopContainer>
        <TopWrapper>
          {/*<SearchWrapper>*/}
          {/*  <SearchMenuSelect*/}
          {/*      options={[*/}
          {/*        { text: '캠페인 명', value: 'N' },*/}
          {/*        { text: '경품 명', value: 'G' },*/}
          {/*        { text: '브랜드 명', value: 'B' },*/}
          {/*      ]}*/}
          {/*      onClick={handleSelect} setState={setType}*/}
          {/*  />*/}
          {/*  <SearchInputWrapper>*/}
          {/*    <SearchInput value={word} onChange={setWord} onKeyPress={enterKeyPress}/>*/}
          {/*  </SearchInputWrapper>*/}
          {/*</SearchWrapper>*/}
          <ButtonWrapper>
            <NormalButton
                text="캠페인 복제"
                onSubmit={() => setModalOpen(true)}
            />
            {campaignList && (
                <>
                  {privateYn === 'N' && (
                      <NormalButton
                          text="상단 노출 순서 변경"
                          className="cancelBtn"
                          onSubmit={() => navigate('editSeq', {})}
                      />
                  )}
                </>
            )}
            <NormalButton
                text="캠페인 등록"
                onSubmit={() => navigate('/campaign/goldCampaign/add')}
            />
          </ButtonWrapper>
        </TopWrapper>
        <LineDiv />

        <ButtonWrapper>
          <RoundButton title="공개" selected={privateYn === 'N'} onClick={() => setPrivateYn('N')}/>
          <RoundButton title="비공개" selected={privateYn === 'Y'} onClick={() => setPrivateYn('Y')}/>
        </ButtonWrapper>
        <LineDiv />

        <PaginationWrapper>
          {totalCount !== 0 && (
              <PaginationWrapper>
                <Pagination
                    postsPerPage={postsPerPage}
                    totalCount={totalCount}
                    currentPage={pageNumber}
                    pageDispatch={e => setPageNumber(e - 1)}
                />
              </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>

      <MainContainer>
        <Table
            colList={[50, 60, 60, 80, 100, 100, 100, 60, 110]}
            thead={<>
              <ListHead title="Id" />
              <ListHead title="썸네일"/>
              <ListHead title="상위 노출"/>
              {/*<ListSelect*/}
              {/*    title="캠페인 타입"*/}
              {/*    list={[{ text: '전체', value: '' }, ...campaignOptions]}*/}
              {/*    onClick={handleSelect}*/}
              {/*    setState={setGoodsType}*/}
              {/*/>*/}
              <ListHead title="캠페인 타입"/>
              <ListHead title="캠페인 명"/>
              <ListHead title="경품명"/>
              <ListHead title="브랜드명"/>
              {/*<ListSelect*/}
              {/*    title="공개 여부"*/}
              {/*    list={[*/}
              {/*      { text: '전체', value: '' },*/}
              {/*      { text: '공개', value: 'N' },*/}
              {/*      { text: '비공개', value: 'Y' },*/}
              {/*    ]}*/}
              {/*    onClick={handleSelect}*/}
              {/*    setState={setPrivateYn}*/}
              {/*/>*/}
              <ListHead title="공개 여부"/>
              <ListHead title="캠페인 기간"/>
            </>}
        >
          {
            campaignList?.map(campaign =>
                <CampaignRow key={campaign.campaignId} data={campaign} />
            )
          }
        </Table>
      </MainContainer>

      {modalOpen && <Modal option={{class: 'compact'}} closeModal={() => setModalOpen(false)} >
        <InputContainer>
          <InputWrapper>
            <LabelTextInput name="campaignId" label="캠페인 아이디" type='number'
                value={campaignId}
                placeholder="캠페인를 입력해주세요."
                onChange={e => setCampaignId(e.target.value)}
                Required
            />
          </InputWrapper>
          <InputWrapper>
            복제된 캠페인은 비공개 탭에서 표시 됩니다.
          </InputWrapper>
          <NormalButton text="복제하기" onSubmit={eventCampaign}/>
        </InputContainer>
      </Modal>}
    </>
  );
};
export default GoldCampaignList;

const CampaignRow = ({data}) => {
  const navigate = useNavigate();

  let campaignPeriod = [
      data.startDate ? data.startDate.slice(0,10):"",
      data.endDate ? data.endDate.slice(0,10):""
  ].join(' ~ ');

  return (
      <tr onClick={() => navigate(
            '/campaign/goldCampaign/edit',
            {state: {campaignId: data.campaignId}}
      )}>
        <td><ListItem text={data.campaignId} /></td>
        <td><ListCoverImg url={data.thumbnailImgUrl} /></td>
        <td><ListItem text={data.upperExposureYn === 'Y' ? "노출":""} /></td>
        <td><ListItem text={campaignOptions.filter(
            option => option.value === data.goodsType
        )[0].text} /></td>
        <td><ListItem text={data.name} /></td>
        <td><ListItem text={data.goodsName} /></td>
        <td><ListItem text={data.goodsBrand} /></td>
        <td><ListItem text={IsPrivate[data.privateYn]} /></td>
        <td><ListItem text={campaignPeriod} /></td>
      </tr>
  );
};

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
  `;

const LineDiv = styled.div`
  width: 100%;
  margin-bottom: 10px;
	margin-top: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 20px;
`;

const InputWrapper = styled.div`
  padding-bottom: 25px;
`;