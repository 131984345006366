import React from 'react';
import styled from 'styled-components';
import clipIcon from '../../../assets/images/page/clipIcon.png';

interface FileInputProps {
  label?: string;
  placeholder?: string;
  type?: string;
  value?: any;
  name?: string;
  setFile?: any;
  Required?: boolean;
  setImgUrl?: any;
  setAudioUrl?: any;
  fileUrl?: string;
  fileInputRef?: any;
}

const FileInput = ({
  type,
  value,
  name,
  label,
  setFile,
  placeholder,
  Required,
  setImgUrl,
  setAudioUrl,
  fileUrl,
  fileInputRef,
}: FileInputProps) => {
  const handleImageChange = (e: any) => {
    e.preventDefault();

    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setFile(file, name);

      if (type === 'audioFile' && setAudioUrl) {
        const audioUrl = URL.createObjectURL(file);
        setAudioUrl(audioUrl);
      } else if (type === 'article' && setAudioUrl) {
        setAudioUrl(file);
      } else if (setImgUrl) {
        setImgUrl(reader.result);
      }
    };
    reader.readAsDataURL(file);
  };

  return (
    <InputContainer>
      <InputText>
        {label} {Required && <span>*</span>}
      </InputText>
      <InputBox label='' placeholder=''>
        {
          value
              ? <FileText>{value.name}</FileText>
              : <>{
                fileUrl
                    ? <PlaceHolderText>파일이 등록되었습니다</PlaceHolderText>
                    : <PlaceHolderText>{placeholder}</PlaceHolderText>
              }</>
        }
        <FileInputModule
          id={label}
          type="file"
          placeholder={placeholder}
          onChange={handleImageChange}
          accept={
            (type === 'coverImg' || type === 'externalLinkImg' || type === 'profileImg' || type === 'bannerImg') ? "image/jpeg, image/jpg, image/png"
                : type === 'eventImg' ? "image/jpg"
                : type === 'audioFile' ? "audio/*"
                : type === 'article' ? "video/mp4"
                : type === 'push' ? "image/*"
                : type === 'file' ? "video/mp4, audio/mp3"
                : type === 'json' ? "application/json"
                : type === 'iconFile' ? ".png"
                : type === 'battleCoverImg' ? "image/jpg, image/gif, image/png, image/jpeg, image/bmp"
                : type === 'advertisementMain' ? "image/jpg, image/png, image/jpeg, image/gif"
                : type === 'advertisementOther' ? "image/jpg, image/gif"
                : ''
          }
          ref={fileInputRef}
        />
        <FileBtn htmlFor={label}  label='' placeholder='' />
      </InputBox>
    </InputContainer>
  );
};

export default FileInput;

const InputContainer = styled.label`
  display: block;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div<FileInputProps>`
  position: relative;
  height: 65px;
`;

const FileText = styled.span`
  position: absolute;
  width: 85%;
  top: 18px;
  left: 20px;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  z-index: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const PlaceHolderText = styled.span`
  position: absolute;
  top: 18px;
  left: 20px;
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.subGray};
  z-index: 1;
`;

const FileInputModule = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const FileBtn = styled.label<FileInputProps>`
  position: absolute;
  width: 100%;
  height: 55px;
  padding: 0 40px 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  background: url(${clipIcon}) calc(100% - 10px) no-repeat
    ${({ theme }) => theme.color.subBrightGray};
  background-size: 32px;
`;
