import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

// config
import { COMMUNITY_FIXED_ARTICLE } from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';

const FixArticle = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [validationType, setValidationType] = useState('valid');
  const [communityArticleList, setCommunityArticleList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate('/operation/communityFixArticle/edit', {
      state: {
        prevId: state?.id,
      },
    });
  };

  const getFixedArticleList = useCallback(async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(
        `${COMMUNITY_FIXED_ARTICLE}/${validationType}`,
        { params },
      );
      setTotalCount(resp.data.count);
      setCommunityArticleList(resp.data.articleList);
    } catch (err) {
      console.log(err);
    }
  }, [validationType, pageNumber]);

  useEffect(() => {
    getFixedArticleList();
  }, [getFixedArticleList, validationType]);

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
            title="공개"
            selected={validationType === 'valid'}
            onClick={() => setValidationType('valid')}
          />
          <RoundButton
            title="비공개"
            selected={validationType === 'invalid'}
            onClick={() => setValidationType('invalid')}
          />
        </div>
        <ButtonWrapper>
          <NormalButton
            text="게시글 배너 등록"
            onSubmit={() => {
              navigate('/operation/communityFixArticle/articleList', {
                state: {
                  prevId: null,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="게시글 아이디" />
              <ListHead title="카테고리 제목" />
              <ListHead title="표시 이미지" />
              <ListHead title="노출 개시 일시" />
              <ListHead title="노출 종료 일시" />
            </>
          }
        >
          {communityArticleList?.map((el: any) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td>
                <ListItem text={el.id} />
              </td>
              <td>
                <ListItem text={el.title} />
              </td>
              <td>
                <ListCoverImg url={el.bannerImgUrl} className="fixedArticle" />
              </td>
              <td>
                <ListItem text={el.bannerStartDt} />
              </td>
              <td>
                <ListItem text={el.bannerEndDt} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default FixArticle;

export const colList = [100, 100, 100, 100, 100];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
