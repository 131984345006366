// 바운드 프로젝트 상태
export const baundProjectStatusTypeOptions = [
  { value: null,            text: '전체'},
  { value: 'PRE_RELEASE',  text: '사전 공개'},
  { value: 'APPLY',        text: '모집'},
  { value: 'FINAL',        text: '결과 발표'},
  { value: 'VOTE',         text: '투표중'},
  { value: 'JUDGE',        text: '투표 마감'},
  { value: 'FINISH',       text: '최종 발표'}
];

export const baundProjectPrivateYnOptions = [
  { text: '전체', value: null },
  { text: '공개', value: 'N' },
  { text: '비공개', value: 'Y' },
];

export const baundProjectSearchTypeOptions = [
  { text: '프로젝트 이름', value: 'title' },
];

export const baundProjectVoteTypeOptions = [
  {value: 'SINGLE', text: '단일 일반 투표'},
  {value: 'MULTI', text: '다중 일반 투표'},
];

export const baundProjectParticipantSearchTypeOptions = [
  { text: '사용자 pid', value: 'pid' },
];

export const baundProjectParticipantResultTypeOption = [
  { value: 'NOT_DECIDED', text: '미정' },
  { value: 'PASS', text: '진출' },
  { value: 'FAIL', text: '탈락' },
];

export const baundProjectUseVoteYnOptions = [
  {value: 'N', text: '1번(투표 미사용)'},
  {value: 'Y', text: '2번(투표 사용)'}
];