import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NormalButton from '../../../../components/common/Button/NormalButton';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import NotExposure from '../../../../components/UI/NotExposure';
import HotBeatRowComponents from './HotBeatRowComponents';
import Pagination from '../../../../components/common/Pagination/index';

// config
import { EXPLORE, HOT_BEAT } from '../../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../../constant/common';
import { hotBeatColList } from '../../../../constant/beat';

const HotBeat = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const location = useLocation();
  const { detailData = {}, tabType = 'ALL' }: any = location.state || {};
  const [listType, setListType] = useState(true);
  const [hotBeatList, setHotBeatList] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getHotBeatList = useCallback(
    async (type: boolean) => {
      const params = {
        limit: postsPerPage,
        offset: pageNumber * postsPerPage,
        type: '',
      };
      console.log(detailData);
      try {
        if (!type) {
          params.type = 'disable';
        }
        if (tabType === 'GENRE') {
          const resp = await axios.get(
            `${EXPLORE}/${detailData?.masterGenreId}/hot-beat`,
            { params },
          );
          setHotBeatList(resp.data.masterRecommendHotBeatList);
          setTotalCount(resp.data.count || 0);
        }
        if (tabType === 'ALL') {
          const resp = await axios.get(HOT_BEAT, { params });
          setHotBeatList(resp.data.masterRecommendHotBeatList);
          setTotalCount(resp.data.count || 0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [detailData?.masterGenreId, pageNumber, tabType],
  );

  useEffect(() => {
    getHotBeatList(listType);
  }, [getHotBeatList, listType]);

  useEffect(() => {
    if (getNumber) {
      window.localStorage.setItem('pageNumber', '0');
    }
  }, [getNumber]);

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
            title="공개"
            selected={listType}
            onClick={() => {
              setListType(true);
            }}
          />
          <RoundButton
            title="비공개"
            selected={!listType}
            onClick={() => {
              setListType(false);
            }}
          />
        </div>
        {hotBeatList && (
          <ButtonWrapper>
            {listType && (
              <NormalButton
                text="순서 변경"
                className="cancelBtn"
                onSubmit={() => {
                  navigate('editSeq', {
                    state: { detailData, tabType },
                  });
                }}
              />
            )}
            <NormalButton
              text="비트 추가하기"
              onSubmit={() => {
                if (Object.keys(detailData).length !== 0) {
                  navigate('add', {
                    state: { masterGenreId: detailData.masterGenreId },
                  });
                } else {
                  navigate('add', { state: {} });
                }
                // {state:}
              }}
            />
          </ButtonWrapper>
        )}
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      {hotBeatList?.length !== 0 && hotBeatList !== undefined ? (
        <MainContainer>
          <Table
            colList={hotBeatColList}
            thead={
              <>
                <ListHead title="비트 아이디" />
                <ListHead title="cover" />
                <ListHead title="Title" />
                <ListHead title="시작일" />
                <ListHead title="종료일" />
                <ListHead title="" />
              </>
            }
          >
            {hotBeatList?.map((el: any) => (
              <HotBeatRowComponents
                key={el.id}
                data={el}
                genreTabData={{ detailData, tabType }}
              />
            ))}
          </Table>
        </MainContainer>
      ) : (
        <NotExposure
          title="인기 비트"
          link="add"
          onSubmit={() => {
            if (Object.keys(detailData).length !== 0) {
              navigate('add', {
                state: { masterGenreId: detailData.masterGenreId },
              });
            } else {
              navigate('add', { state: {} });
            }
          }}
        />
      )}
    </Container>
  );
};

export default HotBeat;

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const MainContainer = styled.div`
  margin-top: 50px;
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;
