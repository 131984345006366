import React, {useState} from 'react';
import {useDispatch} from "react-redux";
import styled from "styled-components";
import axios from "axios";

import NormalButton from "../../../components/common/Button/NormalButton";

import {DATAS_MASTER_MESSAGE} from "../../../constant/apiUrl";

import {resetLoading, setLoading} from "../../../redux/slices/user";
import TextInput from "../../../components/common/Input/TextInput";
import {useNavigate} from "react-router-dom";

const initMessage = {
	locale: 'ko',
	messageKey: '',
	messageTitle: '',
	messageContents: '',
	imageUrl: '',
	link: '',
	added: false,
}

const MasterMessageAdd = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	
	const [mmList, setMmList] = useState([initMessage]);
	
	const saveMasterMessages = () => {
		dispatch(setLoading());
		axios
		.post(DATAS_MASTER_MESSAGE, {messages: mmList})
		.then(() => {
			alert('저장이 완료되었습니다.');
			navigate('/datas/masterMessage')
		})
		.finally(() => dispatch(resetLoading()))
	}
	
	return <>
		<TopContainer>
			<ButtonWrapper>
				<NormalButton text="저장" onSubmit={saveMasterMessages}/>
			</ButtonWrapper>
		</TopContainer>
		
		<LineDiv mb={40}/>
		
		{mmList.map((mm, i) => <MainContainer key={i}>
			<TextInput
				key={`locale${i}`} name='언어' value={mm.locale} placeholder='언어' className={mm.locale === 'ko' && 'masterMessage'}
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, locale: e.target.value} : el))}
			/>
			<TextInput
				key={`messageKey${i}`} name='키' value={mm.messageKey} placeholder='키'
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, messageKey: e.target.value} : el))}
			/>
			<TextInput
				key={`messageContents${i}`} name='내용' value={mm.messageContents} placeholder='내용'
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, messageContents: e.target.value} : el))}
			/>
			<TextInput
				key={`messageTitle${i}`} name='제목' value={mm.messageTitle} placeholder='제목'
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, messageTitle: e.target.value} : el))}
			/>
			<TextInput
				key={`imageUrl${i}`} name='이미지 주소' value={mm.imageUrl} placeholder='이미지 주소'
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, imageUrl: e.target.value} : el))}
			/>
			<TextInput
				key={`link${i}`} name='링크' value={mm.link} placeholder='링크'
				onChange={e => setMmList(mmList.map((el, j) => i === j ? {...el, link: e.target.value} : el))}
			/>
			{
				mm.added || <NormalButton
					className='cancelBtn'
					text="언어추가"
					onSubmit={() => {
						if (!mm.messageKey) {
							alert('messageKey를 작성 후 언어추가를 해주세요.');
							return;
						}
						
						const _mmList = mmList.map((el, j) => i === j ? {...el, added: true} : el)
						
						const es = {...mm};
						es.locale = 'es';
						es.added = true;
						_mmList.splice(i+1, 0, es);
						
						const en = {...mm};
						en.locale = 'en';
						en.added = true;
						_mmList.splice(i+1, 0, en);
						
						setMmList(_mmList);
					}}
				/>
			}
		</MainContainer>)}
		
		<ButtonWrapper>
			<NormalButton text="추가" onSubmit={() => setMmList([...mmList, {...initMessage}])}/>
		</ButtonWrapper>
	</>;
};

export default MasterMessageAdd;

const TopContainer = styled.div`
  width: 100%;
  margin-bottom: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
`;

const MainContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
  
  input {
    margin-bottom: 5px;
  }
`;

