// 페이지네이션 postsPerPage
export const postsPerPage = 20;

// 유효성 검사 정규식
// 비밀번호
export const passwordRegExp =
    /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[*.!@#$%^&(){}:;<>,?\\~_+[\]\-=|])[A-Za-z0-9*.!@#$%^&(){}:;<>,?\\~_+[\]\-=|]{8,20}$/;
// 이메일
export const emailRegExp =
    /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
// 비트key
export const beatKeyRegExp = /^[ABCDEFG][#b]? ((Minor)|(Major))$/;
// 앱버젼
export const appVersionRegExp = /^\d+\.\d+\.\d+$/;

// Y, N 공개, 비공개로 변경
type privateOptions = {
    [key: string]: string;
};

export const IsPrivate: privateOptions = {
    N: '공개',
    Y: '비공개',
};

export const IsActive: privateOptions = {
    Y: '공개',
    N: '비공개',
};

export const IsAgree: privateOptions = {
    Y: '동의',
    N: '비동의',
};

// 추후에 어떤 상태인지에 대한 정리가 필요함.
// NORMAL:정상 게시
// NC: Report 차단, CS 처리 대기
// RNC: Rekognition 차단, CS 처리 대기
// NNC: 처리 대기 중, Rekognition 로그 확인불가
// CB: Report CS 처리 완료 및 Block
// RCB: Rekognition 차단, CS 처리 완료 및 Block
// NCB: 차단이 됐지만 Rekognition 로그 확인불가
export const IsStatus: privateOptions = {
    NORMAL: '정상',
    NC: '신고 완료',
    RNC: 'Rekognition 신고 완료',
    NNC: 'Rekognition 신고 완료',
    CB: '차단',
    RCB: 'Rekognition 차단',
    NCB: 'Rekognition 차단',
    // null: '차단',
};

export const GetLanguageOption: privateOptions = {
    all: '전체',
    ko: '한국어',
    en: '영어',
    es: '스페인어',
};

export const GetCountryOption: privateOptions = {
    ALL: '전체',
    KR: '한국',
    US: '미국',
    ES: '스페인',
};

export const GetNotContainsAllCountryOption: privateOptions = {
    KR: '한국',
    US: '미국',
    ES: '스페인',
};

export const GetCountryCodeOption: privateOptions = {
    82: '한국',
    1: '미국',
    34: '스페인',
};

export const loginTypeOptions: privateOptions = {
    E: '이메일',
    M: '모바일',
    K: '카카오',
    A: '애플',
    N: '네이버',
    F: '페이스북',
    G: '구글',
    L: '라인',
};

export const memberLoginTypeOptions: privateOptions = {
    SI: '로그인',
    SO: '로그아웃',
};

export const sortTypeValues: privateOptions = {
    LATEST: '최신순',
    POPULAR: '인기순',
    SOON_CLOSE: '마감임박순',
}

//국가 옵션
export const countryOptions = [
    {name: '전체', value: 'ALL'},
    {name: '한국', value: 'KR'},
    {name: '미국', value: 'US'},
    {name: '스페인', value: 'ES'},
];

export const notContainsAllCountryOptions = [
    {name: '한국', value: 'KR'},
    {name: '미국', value: 'US'},
    {name: '스페인', value: 'ES'},
];

//언어 옵션
export const languageOptions = [
    {name: '전체', value: 'all'},
    {name: '한국어', value: 'ko'},
    {name: '영어', value: 'en'},
    {name: '스페인어', value: 'es'},
];

export const campaignOptions = [
    {text: '기프티콘', value: 'GIFTICON'},
    {text: '티켓', value: 'TICKET'},
    {text: '배송상품', value: 'DELIVERY'},
];

export const campaignParticipateOptions = [
    {text: '모두', value: 'ALL'},
    {text: '응모', value: 'RAFFLE'},
    {text: '교환', value: 'EXCHANGE'},
];

// 미디어 타입 선택
export const mediaTypeValues = {
    IMAGE: '이미지',
    VIDEO: '영상',
};

// 지면 유형 관련
export const campaignTypes = {
    EL:  '라운지 상단 배너',
    NM:  '메인 팝업',
    CBL: '커뮤니티 인피드 배너A',
    ML:  '바운드 골드 배너A',
    CL:  '골드 캠페인 배너A',
    PU:  '프로젝트 상단 배너',
    PL:  '프로젝트 미들 배너',
};