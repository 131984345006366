import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import ListItem from '../../../../components/common/List/ListItem';
import NormalButton from '../../../../components/common/Button/NormalButton';
import RoundButton from '../../../../components/common/Button/RoundButton';
import Pagination from '../../../../components/common/Pagination';

// config
import { ADMIN_BAUND_API_CATEGORY } from '../../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../../constant/common';
import {
  baundApiCategoryTypeOptions,
  getBaundApiCategoryTypeName
} from "../../../../constant/baundApi";
import SelectModule from "../../../../components/common/Select/SelectModule";

const BaundApiCategory = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);

  // 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition') || '{}');
  const [pageNumber, setPageNumber] = useState(
      storageSearchCondition?.pageNumberForSearch || 0
  );
  const [activationType, setActivationType] = useState(
      storageSearchCondition?.activationTypeForSearch || 'active'
  );
  const [baundApiCategoryType, setBaundApiCategoryType] = useState(
      storageSearchCondition?.baundApiCategoryTypeForSearch || baundApiCategoryTypeOptions[0]
  );
  const [searchTrigger, setSearchTrigger] = useState(false);

  // 초기 처리 /////////////////////////////////////////////////////////////////////////////////////
  useEffect(()=> {
    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  // 표시 데이터 조회 //////////////////////////////////////////////////////////////////////////////
  // 파라미터
  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    categoryType: baundApiCategoryType.value
  };
  // 데이터 조회
  const getContents = async () => {
    try {
      const resp = await axios.get(
          `${ADMIN_BAUND_API_CATEGORY}/${activationType}`,
          {params},
      );
      setTotalCount(resp.data.count);
      let contentsList = resp.data.adminBaundApiCategoryList;
      contentsList.forEach((content:any) => {
        content.categoryType = (!content.categoryType)
          ? '홈' : getBaundApiCategoryTypeName(content.categoryType)
      });
      setContentsList(contentsList);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };
  // 조건 변경시 실행
  useEffect(() => {
    getContents().then((r) => r);
  }, [searchTrigger]);
  // 조건 변경 트리거
  const executeSearch = (resetPageNumber: boolean) => {
    if(resetPageNumber) {
      params.offset = 0;
      setPageNumber(0);
    }
    // 트리거 값 변경으로 검색 수행 유도
    setSearchTrigger(!searchTrigger);
  }

  // 페이지 이동 ///////////////////////////////////////////////////////////////////////////////////
  // 상세
  const goToDetail = (_baundApiCategoryId: any) => {
    navigate(`/system/baundApiCategory/${_baundApiCategoryId ? 'edit': 'add'}`, {
      state: {
        baundApiCategoryId: _baundApiCategoryId,
        activationTypeForSearch: activationType,
        baundApiCategoryTypeForSearch: baundApiCategoryType,
        pageNumberForSearch: pageNumber,
      },
    });
  };

  // 노출 순서
  const goToEditSeq = () => {
    navigate('/system/baundApiCategory/editSeq', {
      state: {
        activationTypeForSearch: activationType,
        baundApiCategoryTypeForSearch: baundApiCategoryType,
        pageNumberForSearch: pageNumber,
      },
    });
  };

  // 조건 설정 ///////////////////////////////////////////////////////////////////////////////////
  // 카테고리 타입
  const handleBaundApiCategoryType = (_value: any, _setter: any) => {
    _setter(_value);
    params.categoryType = _value.value;
    executeSearch(true);
  }
  // 활성 여부
  const handleActivationType = (_activationType: string) => {
    setActivationType(_activationType);
    executeSearch(true);
  }
  // 페이지 변경
  const handlePageNumber = (e: any) => {
    setPageNumber(e - 1);
    executeSearch(false);
  }

  return (
    <Container>
      <TitleText>바운드 API 카테고리 목록</TitleText>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SelectModule
                name="baundApiCategoryType" className={"minWidth300"} placeholder={''} label={''}
                options={baundApiCategoryTypeOptions}
                value={baundApiCategoryType.text}
                onChange={e => handleBaundApiCategoryType(e, setBaundApiCategoryType)}
            />
            <ButtonWrapper>
              <RoundButton title="활성" selected={activationType === 'active'} className={'noMargin'}
                onClick={() => handleActivationType('active')}
              />
              <RoundButton title="비활성" selected={activationType === 'inactive'} className={'noMargin'}
                onClick={() => handleActivationType('inactive')}
              />
            </ButtonWrapper>
          </SearchWrapper>
        </TopWrapper>
        <ButtonWrapper>
          {contentsList && (
            <>
              {activationType === 'active' && (
                <NormalButton text="노출 순서 변경" className="cancelBtn" onSubmit={goToEditSeq}/>
              )}
            </>
          )}
          <NormalButton text="카테고리 등록" onSubmit={goToDetail}/>
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={handlePageNumber}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[50, 100, 100, 100]}
          thead={
            <>
              <ListHead title="아이디" />
              <ListHead title="제목" />
              <ListHead title="설명" />
              <ListHead title="타입" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
              <tr key={el.baundApiCategoryId} onClick={() => goToDetail(el.baundApiCategoryId)}>
                <td><ListItem text={el.baundApiCategoryId}/></td>
                <td><ListItem text={el.name}/></td>
                <td><ListItem text={el.description}/></td>
                <td><ListItem text={el.categoryType}/></td>
              </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default BaundApiCategory;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
