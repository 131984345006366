import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import NormalButton from "../../../components/common/Button/NormalButton";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import {ADVERTISEMENT_CAMPAIGN,} from "../../../constant/apiUrl";
import axios from "axios";
import ListItem from "../../../components/common/List/ListItem";
import {campaignTypes, postsPerPage} from "../../../constant/common";
import handleDate from "../../../hooks/handleDate";
import RoundButton from "../../../components/common/Button/RoundButton";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";

// 상태 표시값 설정
export const handleStatus = (advertisementCampaignObj) => {
  // 캠페인 정보 없으면
  if(!advertisementCampaignObj || !advertisementCampaignObj.advertisementCampaignId) {
    return '';
  }

  const now = new Date();
  if (advertisementCampaignObj.activeYn === 'N') {
    return "광고비공개";
  }
  else {
    if(now < new Date(advertisementCampaignObj.startDt)) {
      return "예약 완료";
    }
    else if(new Date(advertisementCampaignObj.endDt) < now) {
      return "종료";
    }
    else {
      return "노출중";
    }
  }
}
// 광고 목록 화면
const AdvertisementCampaigns = () => {
  const navigate = useNavigate();

  // 페이징
  const storagePageNumber = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
      storagePageNumber ? JSON.parse(storagePageNumber) : 0
  );
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
    window.localStorage.setItem('pageNumber', String(e - 1));
  }, []);

  // 검색 조건
  const storageCampaignStatus = localStorage.getItem('storageCampaignStatus');
  const [campaignStatus, setCampaignStatus] = useState(storageCampaignStatus || 'ALL');

  //표시 데이터
  const [advertisementCampaignList, setAdvertisementCampaignList] = useState([]);

  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params = {
    campaignStatus,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };

  //광고 캠페인 조회 ////////////////////////////////////////////////////////////
  const getAdvertisementCampaigns = async() => {
    await axios.get(`${ADVERTISEMENT_CAMPAIGN}`, {params})
    .then(res => {
      setAdvertisementCampaignList(res.data.adminAdvertisementCampaignList);
      setTotalCount(res.data.count);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => getAdvertisementCampaigns(), [campaignStatus, pageNumber]);

  const handleCampaignStatus = (e) => {
    setCampaignStatus(e);
    window.localStorage.setItem('storageCampaignStatus', e);
  };

  // 상세 페이지로 이동
  const moveToDetail = (_type, _advertisementCampaignId) => {
    let _state = (_type === 'edit')
        // 수정 일때만 정보 전달
        ? {advertisementCampaignId: _advertisementCampaignId,}
        // 신규 작성일 때는 광고주 정보를 강제적으로 선택
        : null;
    navigate(`/advertisement/advertisementCampaign/${_type}`, {state:_state})
  }

  return (
      <>
        <TitleText>광고캠페인 리스트</TitleText>
        <TopContainer>
          <TopWrapper jc={'right'}>
            <ButtonWrapper>
              <NormalButton
                  text="새로 만들기" onSubmit={() => moveToDetail('add', null)}
              />
            </ButtonWrapper>
          </TopWrapper>
          <TopWrapper jc={'left'}>
            <RoundButton title="전체" selected={campaignStatus === 'ALL'} onClick={() => handleCampaignStatus('ALL')}/>
            <RoundButton title="예약완료" selected={campaignStatus === 'RESERVED'} onClick={() => handleCampaignStatus('RESERVED')}/>
            <RoundButton title="노출중" selected={campaignStatus === 'PROCEEDING'} onClick={() => handleCampaignStatus('PROCEEDING')}/>
            <RoundButton title="종료" selected={campaignStatus === 'COMPLETED'} onClick={() => handleCampaignStatus('COMPLETED')}/>
            <RoundButton title="광고 비공개" selected={campaignStatus === 'INACTIVE'} onClick={() => handleCampaignStatus('INACTIVE')}/>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 광고캠페인'}
                      currentPage={pageNumber}
                      pageDispatch={pageDispatch}/>
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {advertisementCampaignList.length !== 0 ? (
              <Table
                  colList={[50, 100, 50, 50, 50, 30]}
                  thead={<>
                    <ListHead title="번호" />
                    <ListHead title="광고캠페인 식별제목"/>
                    <ListHead title="지면유형"/>
                    <ListHead title="시작일시"/>
                    <ListHead title="종료일시" />
                    <ListHead title="상태" />
                  </>}
              >
                {
                  advertisementCampaignList?.map(data =>
                      <tr key={data.advertisementCampaignId}
                          onClick={() => moveToDetail('edit', data.advertisementCampaignId)}
                      >
                        <td><ListItem text={data.advertisementCampaignId} /></td>
                        <td><ListItem text={data.title} /></td>
                        <td><ListItem text={campaignTypes[data.campaignType]} /></td>
                        <td><ListItem text={handleDate(data.startDt)} /></td>
                        <td><ListItem text={handleDate(data.endDt)} /></td>
                        <td><ListItem text={handleStatus(data)} /></td>
                      </tr>
                  )
                }
              </Table>
          ) : (
              <SpanNoResult>검색 결과가 없습니다.</SpanNoResult>
          )}
        </MainContainer>
      </>
  );
};
export default AdvertisementCampaigns;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
  `;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;