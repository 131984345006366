import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";

import axios from 'axios';
import styled from 'styled-components';

import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import {PERFORMANCE_CATEGORY, PERFORMANCE_CATEGORY_DETAIL} from '../../../constant/apiUrl';
import {resetLoading, setLoading} from "../../../redux/slices/user";

const PerformanceCategoryDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [performanceCategoryId] = useState(location.state?.id);
    const [name, setName] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [activeYn, setActiveYn] = useState('Y');

    const categorySave = async () => {
        dispatch(setLoading());

        // 필수 및 유효성 체크
        if (!name) {
            alert('카테고리 이름은 필수값입니다.')
            dispatch(resetLoading());
            return;
        }

        const request = {
            name,
            nameEn,
            description,
            descriptionEn,
            activeYn,
        };

        try {
            if (performanceCategoryId) {
                request['performanceCategoryId'] = performanceCategoryId; // 수정시 카테고리 아이디 세팅

                await axios.put(PERFORMANCE_CATEGORY, request, {})
                alert('수정되었습니다.');
                window.location.reload();
            } else {
                request['sequence'] = location.state?.count + 1; // 저장시 시퀸스 세팅

                await axios.post(PERFORMANCE_CATEGORY, request, {})
                alert('저장되었습니다.');
                navigate('/culture/performanceCategory');
            }
        } catch (err) {
            console.log(err);
            alert('예상치 못한 오류가 발생하였습니다. 담당자에게 문의하세요.');
        } finally {
            dispatch(resetLoading());
        }
    };

    const getCategoryDetail = async () => {
        await axios.get(`${PERFORMANCE_CATEGORY_DETAIL}/${performanceCategoryId}`)
            .then(res => {
                console.log(res.data)
                setName(res.data.name);
                setNameEn(res.data.nameEn);
                setDescription(res.data.description);
                setDescriptionEn(res.data.descriptionEn);
                setActiveYn(res.data.activeYn);
            })
            .catch(reason => console.error(reason));
    }

    useEffect(() => {
        console.log(' location.state',  location.state)
    }, [])

    useEffect(() => performanceCategoryId && getCategoryDetail(), [])

    return (
        <>
            <TopContainer>
                <ButtonWrapper>
                      <NormalButton text="취소" onSubmit={() => navigate('/culture/performanceCategory')} className="cancelBtn"/>
                      <NormalButton text={performanceCategoryId ? "수정" : "저장"} onSubmit={() => categorySave()}/>
                </ButtonWrapper>
            </TopContainer>

            <MainContainer>
                {/* 이름 */}
                <InputWrapper span={1}>
                      <LabelTextInput
                          name="name" label="카테고리 이름" value={name} placeholder="카테고리 이름을 입력해 주세요."
                          onChange={(e) => setName(e.target.value)}
                          onReset={() => setName('')}
                          Required
                      />
                </InputWrapper>
                <InputWrapper span={1}>
                  <LabelTextInput
                      name="nameEn" label="카테고리 영어 이름" value={nameEn} placeholder="카테고리 영어 이름을 입력해 주세요."
                      onChange={(e) => setNameEn(e.target.value)}
                      onReset={() => setNameEn('')}
                  />
                </InputWrapper>

                {/* 설명 */}
                <InputWrapper span={2}>
                      <LabelTextInput
                          name="description" label="설명" value={description} placeholder="카테고리 설명을 입력해 주세요."
                          onChange={(e) => setDescription(e.target.value)}
                          onReset={() => setDescription('')}
                      />
                </InputWrapper>
                <InputWrapper span={2}>
                      <LabelTextInput
                          name="descriptionEn" label="영어 설명" value={descriptionEn} placeholder="카테고리 영어 설명을 입력해 주세요."
                          onChange={(e) => setDescriptionEn(e.target.value)}
                          onReset={() => setDescriptionEn('')}
                      />
                </InputWrapper>

                {/* 공개 여부 */}
                <InputWrapper span={2}>
                      <RadioButton
                            value={activeYn}
                            name="activeYn"
                            label="공개 여부"
                            onChange={e => setActiveYn(e.target.value)}
                            text={['공개', '비공개']}
                            type="activeYn"
                            Required
                      />
                </InputWrapper>
            </MainContainer>
        </>
    );
};

export default PerformanceCategoryDetail;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  
    button {
        &:last-child {
            margin-left: 20px;
        }
    }
`;

const MainContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
    grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '40px'};
`;
