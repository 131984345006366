import React from 'react';
//컴포넌트
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';
import { IsPrivate } from '../../../../constant/common';

// hooks
import handleDate from '../../../../hooks/handleDate';

const RecentlyBeatRowComponents = ({ data }: any) => {
  const {
    coverImgUrl,
    title,
    bmPid,
    genreNames,
    bpm,
    key,
    privateYn,
    startDt,
  } = data;

  const handleAudio = (e: any) => {
    e.stopPropagation();
  };

  return (
    <tr>
      <td>
        <ListCoverImg url={coverImgUrl} onClick={(e) => handleAudio(e)} />
      </td>
      <td>
        <ListItem text={title} />
      </td>
      <td>
        <ListItem text={bmPid} />
      </td>
      <td>
        <ListItem text={genreNames.join(', ')} />
      </td>
      <td>
        <ListItem text={bpm} />
      </td>
      <td>
        <ListItem text={key} />
      </td>
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
    </tr>
  );
};

export default RecentlyBeatRowComponents;
