import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import {
  eventActiveYnOptions,
  eventCountryTypeList,
} from '../../../constant/event';
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import useInput from '../../../hooks/useInput';
import {
  GetCountryOption,
  GetLanguageOption,
  IsActive,
  postsPerPage
} from '../../../constant/common';
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import ListItem from "../../../components/common/List/ListItem";
import handleDate from "../../../hooks/handleDate";
import EditButton from "../../../components/common/Button/EditButton";


export const PushForMagazineRowComponent = ({
  data, onClick, selected,
}) => {
  const {
    bannerImgUrl,
    activeYn,
    title,
    countryCode,
    startDt,
    endDt,
    language,
  } = data;

  return (
      <tr>
        <td><ListCoverImg url={bannerImgUrl} className="event" /></td>
        <td><ListItem type="title" text={title} /></td>
        <td><ListItem text={IsActive[activeYn]} /></td>
        <td><ListItem text={handleDate(startDt)} /></td>
        <td><ListItem text={handleDate(endDt)} /></td>
        <td><ListItem text={GetCountryOption[countryCode]} /></td>
        <td><ListItem text={GetLanguageOption[language]} /></td>
        { onClick && (
          <td>
            {selected ? (
                <EditButton onClick={() => onClick(data)} text="삭제" className="deleteBtn"/>
            ) : (
                <EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>
            )}
          </td>
        )}
      </tr>
  );
};

const PushForMagazine = ({
  closeModal,
  setSelectedModalObj,
  setSelectedModalType,
}) => {

  const [magazinesSelected, setMagazinesSelected] = useState(null);
  const [magazinesMaster, setMagazinesMaster] = useState([]);
  const [word, setWord] = useInput('');
  const [country, setCountry] = useState('');
  const [activeYn, setActiveYn] = useState('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  const params = {
    countryCode: country,
    activeYn,
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  // 전체 조회
  const getMagazinesMaster = async () => {
    await axios.get('magazine', {
      params,
    })
    .then((resp)=>{
      setMagazinesMaster(resp.data.magazineList);
      setTotalCount(resp.data.count || 0);
    })
    .catch((err)=>{
      console.log(err);
    });
  };
  useEffect(() => {
    getMagazinesMaster().then();
  }, [pageNumber, activeYn, country]);

  // 엔터키로 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter' && word) {
      setPageNumber(0);
      // params.offset = 0;
    }
    getMagazinesMaster().then();
  };

  // 공개여부, 국가 변경에 따른 값 설정
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
  };

  // 선택된 매거진 정보를 부모창으로 전달
  const saveMagazine = () => {
    if (magazinesSelected === null) {
      alert('매거진이 선택되어 있지 않습니다.');
      return;
    }
    //저장 진행 여부
    let isProceed = true
    //비활성화 되어 있거나, 공개일이 지난 이벤트는 저장 전 확인을 받는다
    if (magazinesSelected.activeYn !== 'Y'
        || new Date() > new Date(magazinesSelected.endDt)) {
      isProceed = confirm("비공개 상태이거나 노출 기간이 경과된 매거진이 선택 되었습니다. 진행하시겠습니까?");
    }
    // 저장 진행하는 경우에는
    if (isProceed) {
      alert('저장되었습니다.');
      setSelectedModalObj(magazinesSelected);
      setSelectedModalType('MAGAZINE');
      closeModal();
    }
  };

  // 선택된 내용 삭제
  const filterSelectedContent = () => {
    setMagazinesSelected(null);
  };

  // 선택된 내용 추가
  const addSelectedContent = (data) => {
    if (magazinesSelected !== null) {
      alert('하나만 선택할 수 있습니다.');
    } else {
      setMagazinesSelected(data);
    }
  };

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
      <VideoModalWrapper>
        <ModalButtonWrapper>
          <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
          <NormalButton text="저장하기" onSubmit={saveMagazine} />
        </ModalButtonWrapper>
        <VideoModalTitle>선택된 매거진</VideoModalTitle>
        { magazinesSelected !== null ? (
          <>
            <LineDiv />
            <Table colList={[160, 110, 100, 140, 140, 100, 70, 60,]} className="nonListTable">
              <PushForMagazineRowComponent
                key={magazinesSelected.id}
                data={magazinesSelected}
                onClick={filterSelectedContent}
                selected
              />
            </Table>
          </>
        ) : (
          <>
            <VideoModalSubText>
              선택된 매거진이 없습니다. 아래 검색 후 매거진을 선택해주세요.
            </VideoModalSubText>
            <LineDiv />
            {<SpaceDiv value="112px"/>}
          </>
        )}
        <SpaceDiv value="90px" />
        <VideoModalTitle>매거진 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SearchInputWrapper>
              <SearchInput value={word} onChange={setWord} onKeyPress={enterKeyPress}/>
            </SearchInputWrapper>
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          <TableTitle>{totalCount}개 매거진</TableTitle>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv />
        <Table
          colList={[160, 110, 100, 140, 140, 100, 70, 60,]}
          thead={
            <>
              <ListHead title="이미지" />
              <ListHead title="제목" />
              <ListSelect title="공개여부" list={eventActiveYnOptions} onClick={handleSelect} setState={setActiveYn}/>
              <ListHead title="시작일" />
              <ListHead title="종료일" />
              <ListSelect title="국가" list={eventCountryTypeList} onClick={handleSelect} setState={setCountry}/>
              <ListHead title="언어" />
            </>
          }
        >
          {magazinesMaster?.map((el) => (
            <PushForMagazineRowComponent
              key={el.id}
              data={el}
              onClick={addSelectedContent}
            />
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

export default PushForMagazine;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpaceDiv = styled.div`
  height: ${({ value }) => value};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
