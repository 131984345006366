import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import Table from '../../../../components/common/List/Table';
import ListItem from '../../../../components/common/List/ListItem';
import EditButton from '../../../../components/common/Button/EditButton';
import SearchInput from '../../../../components/common/Input/SearchInput';

// constant
import { recommendHashTagList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';

// config
import { CONTENTS_HASHTAG } from '../../../../constant/apiUrl';

// hooks
import useInput from '../../../../hooks/useInput';
import Pagination from '../../../../components/common/Pagination';

const RecommendHashTagList = () => {
  const navigate = useNavigate();
  const [word, setWord] = useInput('');
  const [tagList, setTagList] = useState<any>([]);

  // pagination state
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);

  const params = {
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getTagList();
    }
  };

  const getTagList = async () => {
    try {
      const resp = await axios.get(CONTENTS_HASHTAG, {
        params,
      });
      setTagList(resp.data.tagList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getTagList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <Container>
      <TitleText> {totalCount}개의 해시태그</TitleText>
      <TopWrapper>
        <SearchInputWrapper>
          <SearchInput
            value={word}
            onChange={setWord}
            onKeyPress={enterKeyPress}
          />
        </SearchInputWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </TopWrapper>
      <SpaceDiv />
      <Table colList={recommendHashTagList}>
        {tagList?.map((el: any) => (
          <tr key={el.id}>
            <td>
              <ListItem text={el.tag} className="hashTagText" />
            </td>
            <td>
              <EditButton
                onClick={() => {
                  navigate('add', { state: { tagId: el.id, tagName: el.tag } });
                }}
                text="선택"
                className="selectBtn"
              />
            </td>
          </tr>
        ))}
      </Table>
    </Container>
  );
};

export default RecommendHashTagList;

const Container = styled.div`
  width: 100%;
`;

const TitleText = styled.h1`
  font-size: 36px;
  font-weight: 600;
  margin: 30px 0 50px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 55px;
`;
