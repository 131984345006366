import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';

interface props {
  data: dataTypes;
  onClick: (data: dataTypes) => void;
}

interface dataTypes {
  id: number;
  language: string;
  activeYn: string;
  name: string;
  parentName: string;
  blockConfidence: number;
}

const RekognitionRowComponent = ({ data, onClick }: props) => {
  const { id, language, activeYn, name, parentName, blockConfidence } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={language} />
      </td>
      <td width="25%">
        <ListItem text={parentName} />
      </td>
      <td width="25%">
        <ListItem text={name} />
      </td>
      <td>
        <ListItem text={blockConfidence} />
      </td>
      <td>
        <ListItem text={activeYn} />
      </td>
      <td>
        <EditButton onClick={() => onClick(data)} text="수정" />
      </td>
    </tr>
  );
};

export default RekognitionRowComponent;
