import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import NormalButton from '../../../components/common/Button/NormalButton';
import FileInput from '../../../components/common/Input/FileInput';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import LabelTextAreaInput from '../../../components/common/Input/LabelTextAreaInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';
import DateInput from '../../../components/common/Input/DateInput';
import {
  baundMusicStreamingServiceType,
  baundMusicType,
  baundMusicTypeOption,
  modalExternalLinkColList,
  modalStreamingServicesColList,
} from '../../../constant/baundMusic';
import { BAUND_MUSIC, BAUND_MUSIC_SAVE } from '../../../constant/apiUrl';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import ListHead from '../../../components/UI/ListHead';
import Table from '../../../components/common/List/Table';
import EditButton from '../../../components/common/Button/EditButton';
import blankImg from '../../../assets/images/page/blankImg.png';
import blankUserImg from '../../../assets/images/page/blankUserImg.png';
import { resetLoading, setLoading } from '../../../redux/slices/user';

// 컴포넌트 ###################################################################
// 외부 영상 이미지
const ExternalLinkImg = ({ url, className }) => {
  return (
    <ExternalLinkImgWrapper>
      <ExternalLink url={url} className={className} />
    </ExternalLinkImgWrapper>
  );
};

// 바운드 영상 정보
const ArticleTable = ({data, idx, list, listSetter}) => {
  return <ArticleTableContainer key={idx}>
    <InputWrapper span={4}>
      <LabelTextInput
          name={`${data.originalArticleId}_${idx}`}
          label={'원본 영상 ID'}
          value={data.originalArticleId}
          placeholder={`원본 영상 ID를 입력해 주세요.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].originalArticleId = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={4}>
      <LabelTextInput readOnly
          name={`${data.originalMemberId}_${idx}`}
          label={'원본 영상의 작성자 ID'}
          value={data.originalMemberId}
          placeholder={`자동으로 입력됩니다.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].originalMemberId = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={4}>
      <LabelTextInput readOnly
        name={`${data.beatId}_${idx}`}
        label={'원본 영상의 비트 ID'}
        value={data.beatId}
        placeholder={`자동으로 입력됩니다.`}
        onChange={e => {
          const _list = [...list];
          _list[idx].beatId = e.target.value;
          listSetter(_list);
        }}
      />
    </InputWrapper>
    <InputWrapper span={4}>
      <LabelTextInput readOnly
          name={`${data.articleId}_${idx}`}
          label={'복사된 영상 ID'}
          value={data.articleId}
          placeholder={`자동으로 입력됩니다.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].articleId = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={4}>
      <LabelTextInput readOnly
          name={`${data.memberId}_${idx}`}
          label={'복사된 영상의 회원 ID'}
          value={data.memberId}
          placeholder={`자동으로 입력됩니다.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].memberId = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={4}>
      <LabelTextInput
          name={`${data.releasedBeatId}_${idx}`}
          label={'발매된 영상의 비트 ID'}
          value={data.releasedBeatId}
          placeholder={`발매된 영상의 비트 ID를 입력해 주세요.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].releasedBeatId = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={6}>
      <LabelTextInput
          name={`${data.title}_${idx}`}
          label={'수록곡 명'}
          value={data.title}
          placeholder={`바운드 뮤직 상세 화면에 표시될 곡 명`}
          onChange={e => {
            const _list = [...list];
            _list[idx].title = e.target.value;
            _list[idx].titleEn = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={6}>
      <LabelTextInput
          name={`${data.titleEn}_${idx}`}
          label={'수록곡 명(영문)'}
          value={data.titleEn}
          placeholder={`바운드 뮤직 상세 화면에 표시될 곡 명`}
          onChange={e => {
            const _list = [...list];
            _list[idx].titleEn = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    <InputWrapper span={11}>
      <LabelTextInput
          name={`${data.editCopyArticleDescription}_${idx}`}
          label={'복사 될 영상의 설명 수정 문구'}
          value={data.editCopyArticleDescription}
          placeholder={`복사될 영상의 설명 수정이 필요 할 시 입력해 주세요.`}
          onChange={e => {
            const _list = [...list];
            _list[idx].editCopyArticleDescription = e.target.value;
            listSetter(_list);
          }}
      />
    </InputWrapper>
    {/*<InputWrapper span={1}>*/}
    {/*  {*/}
    {/*      <EditButton*/}
    {/*          text="삭제" className='performancePopBtn'*/}
    {/*          onClick={() => {*/}
    {/*            const _list = [...list];*/}
    {/*            _list.splice(idx, 1);*/}
    {/*            listSetter(_list);*/}
    {/*          }} />*/}
    {/*  }*/}
    {/*</InputWrapper>*/}
  </ArticleTableContainer>;
};

const initialStreamingServicesData = [
  {streamingServiceType: 'APM', link: '',},
  {streamingServiceType: 'SPT', link: '',},
  {streamingServiceType: 'UTM', link: '',},
  {streamingServiceType: 'MEL', link: '',},
];

//페이지 세팅
const ReleaseSoundDetail = () => {
  // state
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const today = new Date();
  const todayWIthFormat =
      today.getFullYear() +
      '-' +
      ('0' + (today.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + today.getDate()).slice(-2) +
      'T' +
      ('0' + today.getHours()).slice(-2) +
      ':' +
      ('0' + today.getMinutes()).slice(-2);

  // 이전 페이지에서 넘어온 값
  const detailData = location.state;
  // 상세를 불러오기 위해 이전 페이이지에서 넘어온 값이 있으면 해당 ID 세팅
  const prevId = detailData !== null ? detailData.baundMusicId : null;

  // 파일 이미지 값을 세팅하기 위한 State
  const [coverImg, setCoverImg] = useState('');
  const [profileImg, setProfileImg] = useState('');

  const [baundMusicId, setBaundMusicId] = useState(null);
  const [type, setType] = useState('OP');
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [description, setDescription] = useState('');
  const [artist, setArtist] = useState('');
  const [artistEn, setArtistEn] = useState('');
  const [coverImgUrl, setCoverImgUrl] = useState('');
  const [profileImgUrl, setProfileImgUrl] = useState('');
  const [externalLinks, setExternalLinks] = useState([]);
  const [tracks, setTracks] = useState([{
    originalArticleId:null,
    originalMemberId:null,
    beatId:null,
    articleId:null,
    memberId:null,
    releasedBeatId:null,
    editCopyArticleDescription:''
  }]);
  const [streamingServices, setStreamingServices] = useState([]);
  const [privateYn, setPrivateYn] = useState('Y');
  const [startDate, setStartDate] = useState(todayWIthFormat);
  const [coverImgFile, setCoverImgFile] = useState(null);
  const [profileImgFile, setProfileImgFile] = useState(null);

  // Modal
  const [checkCloseModal, setCheckCloseModal] = useState(false);
  const [modalType, setModalType] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalExternalLinks, setModalExternalLinks] = useState([]);
  const [modalStreamingServices, setModalStreamingServices] = useState([]);

  // 서버 통신 ##################################################################
  // 페이지 수정으로 들어왔을 시 해당 상세를 들고와서 데이터 세팅 -----------------
  const getDetailBaundMusic = useCallback(async () => {
    await axios.get(`${BAUND_MUSIC}/${prevId}`)
    .then( res => {
      const result = res.data.baundMusic;

      setBaundMusicId(result.baundMusicId);
      setType(result.type);
      setTitle(result.title);
      setTitleEn(result.titleEn);
      setDescription(result.description);
      setArtist(result.artist);
      setArtistEn(result.artistEn);
      setCoverImgUrl(result.coverImgUrl);
      setProfileImgUrl(result.profileImgUrl);
      setExternalLinks(result.externalLinks);
      setTracks(result.tracks);
      setStreamingServices(result.streamingServices);
      setPrivateYn(result.privateYn);
      setStartDate(result.startDate);

      // 이미지 파일 세팅
      setCoverImg(result.coverImgUrl ? result.coverImgUrl : '');
      setProfileImg(result.profileImgUrl ? result.profileImgUrl : '');
    })
    .catch(reason => {
      console.error(reason);
      alert('잠시후 다시 이용해주세요.');
    });
  }, [prevId]);

  // Baund Music Insert, Update 하기 -------------------------------------------
  const saveBaundMusicData = async () => {

    if (!type || !title || !artist || !privateYn || !startDate) {
      alert('필수 양식을 전부 채워주세요.');
      return;
    }
    if (tracks.length < 1) {
      alert('수록곡 정보를 입력해 주세요.');
      return;
    }
    let uniqueOriginalArticleIds = [...new Set(tracks.map(track => track.originalArticleId))];
    if(tracks.length !== uniqueOriginalArticleIds.length ) {
      alert('중복된 원본 영상 ID가 있습니다.');
      return;
    }

    const formData = new FormData();
    // Data 추가
    if (baundMusicId !== null) {
      formData.append(
          'baundMusicId',
          baundMusicId === 0 ? null : parseInt(baundMusicId, 10),
      );
    }
    formData.append('type', type);
    formData.append('title', title);
    formData.append('titleEn', titleEn);
    formData.append('description', description);
    formData.append('artist', artist);
    formData.append('artistEn', artistEn);
    formData.append('coverImgUrl', coverImgUrl);
    formData.append('profileImgUrl', profileImgUrl);

    //--------------------------------------------------------------------------
    if (tracks) {
      for (let i = 0; i < tracks.length; i++) {
        if (tracks[i].originalArticleId) {
          // 원본 영상 아이디
          formData.append(`tracks[${i}].originalArticleId`,
              parseInt(tracks[i].originalArticleId, 10));
        }
        else {
          alert('원본 영상 ID가 지정 되어 있지 않습니다.');
          return;
        }
        //원본 영상의 작성자 ID
        if (tracks[i].originalMemberId !== null) {
          formData.append(
              `tracks[${i}].originalMemberId`,
              tracks[i].originalMemberId === 0 ? null : parseInt(tracks[i].originalMemberId, 10)
          );
        }
        //원본 영상의 비트 ID
        if (tracks[i].beatId !== null) {
          formData.append(
              `tracks[${i}].beatId`,
              tracks[i].beatId === 0 ? null : parseInt(tracks[i].beatId, 10)
          );
        }
        //복사된 영상 ID
        if (tracks[i].articleId !== null) {
          formData.append(
              `tracks[${i}].articleId`,
              tracks[i].articleId === 0 ? null : parseInt(tracks[i].articleId, 10),
          );
        }
        //복사된 영상의 회원 ID
        if (tracks[i].memberId !== null) {
          formData.append(
              `tracks[${i}].memberId`,
              tracks[i].memberId === 0 ? null : parseInt(tracks[i].memberId, 10),
          );
        }
        //발매된 음원의 비트 ID
        if (tracks[i].releasedBeatId !== null) {
          formData.append(
              `tracks[${i}].releasedBeatId`,
              tracks[i].releasedBeatId === 0 ? null : parseInt(tracks[i].releasedBeatId, 10)
          );
        }
        // 곡명
        if (tracks[i].title !== null && tracks[i].title !== undefined && tracks[i].title !== '') {
          formData.append(`tracks[${i}].title`, tracks[i].title);
        }
        else {
          alert('수록 곡 명이 지정 되어 있지 않습니다.');
          return;
        }
        // 곡명
        if (tracks[i].titleEn !== null && tracks[i].titleEn !== undefined && tracks[i].titleEn !== '') {
          formData.append(`tracks[${i}].titleEn`, tracks[i].titleEn);
        }
        else {
          alert('수록 곡 명(영문)이 지정 되어 있지 않습니다.');
          return;
        }
        //복사될 영상의 설명 수정 문구
        if (
            tracks[i].editCopyArticleDescription !== null &&
            tracks[i].editCopyArticleDescription !== undefined &&
            tracks[i].editCopyArticleDescription.replace(/ /g, '').length > 0
        ) {
          formData.append(`tracks[${i}].editCopyArticleDescription`, tracks[i].editCopyArticleDescription);
        }
      }
    }
    formData.append('privateYn', privateYn);
    formData.append(
        'startDate',
        `${startDate.replace('T', ' ').slice(0, 16)}:00`,
    );

    // File 추가
    if (coverImgFile != null) {
      formData.append('coverImgFile', coverImgFile);
    }
    if (profileImgFile != null) {
      formData.append('profileImgFile', profileImgFile);
    }

    if (externalLinks != null) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < externalLinks.length; i++) {
        formData.append(`externalLinks[${i}].title`, externalLinks[i].title);
        formData.append(`externalLinks[${i}].imageUrl`, externalLinks[i].imageUrl);
        formData.append(`externalLinks[${i}].contentsUrl`, externalLinks[i].contentsUrl);
        formData.append(`externalLinks[${i}].description`, externalLinks[i].description);
        if(externalLinks[i].externalLinkImgFile != null) {
          formData.append(`externalLinkImgFiles[${i}]`, externalLinks[i].externalLinkImgFile);
        }
      }
    }

    if (streamingServices != null) {
      for (let i = 0; i < streamingServices.length; i++) {
        formData.append(`streamingServices[${i}].streamingServiceType`, streamingServices[i].streamingServiceType);
        formData.append(`streamingServices[${i}].link`, streamingServices[i].link);
      }
    }

    dispatch(setLoading());
    try {
      await axios.post(BAUND_MUSIC_SAVE, formData, {});
      alert('저장 완료됐습니다.');
      goBack();
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // 함수 #######################################################################
  // 취소 버튼 클릭 시 뒤로 가는 메소드
  const goBack = () => {
    navigate('/baundMusic/release');
  };

  // 모달 창의 Input 태그의 데이터 세팅을 위한 메소드
  const handleModalInputSetData = useCallback((event, callerIndex) => {
    try {
      const { name, value } = event.target;
      // 데이터 세팅
      setModalExternalLinks(
        modalExternalLinks.map((modalExternalLink, index) =>
          index === callerIndex ? { ...modalExternalLink, [name]: value } : modalExternalLink,
        ),
      );
    } catch (e) {}
  }, [modalExternalLinks],);

  // 음원 링크 등록
  const handleModalStreamingServicesInputSetData = useCallback((e) => {
    try {
      const { name, value } = e.target;

      // 데이터 세팅
      setModalStreamingServices(
        modalStreamingServices.map((modalStreamingService) =>
          modalStreamingService.streamingServiceType === name
            ? { ...modalStreamingService, link: value }
            : modalStreamingService,
        ),
      );
      // eslint-disable-next-line @typescript-eslint/no-shadow,no-empty
    } catch (e) {}
  }, [modalStreamingServices],);

  // 외부 영상 등록 모달 창 열 때 초기 값 설정
  const initModalExternalLinks = useCallback((name) => {
      // index 세팅
      const addIndexExternalLinks = [];
      if (externalLinks != null) {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < externalLinks.length; i++) {
          const addIndexModalExternalLink = externalLinks[i];
          // addIndexModalExternalLink.index = i;
          addIndexExternalLinks.push(addIndexModalExternalLink);
        }
      }
      setModalExternalLinks(addIndexExternalLinks);
      setModalType(name);
      setModalOpen(true);
    }, [externalLinks,],);

  // 음원 링크 등록 모달 창 등록시 초기 값 설정
  const initModalStreamingServices = useCallback((name) => {
      const addStreamingServices = initialStreamingServicesData;

      if (
        streamingServices != null &&
        streamingServices !== []
      ) {
        const originalStreamingServices = streamingServices;

        for (let i = 0; i < addStreamingServices.length; i++) {
          for (
            let index = 0;
            index < originalStreamingServices.length;
            index++
          ) {
            if (
              addStreamingServices[i].streamingServiceType ===
              originalStreamingServices[index].streamingServiceType
            ) {
              addStreamingServices[i].link =
                originalStreamingServices[index].link;
            }
          }
        }
      }

      setModalStreamingServices(addStreamingServices);
      setModalType(name);
      setModalOpen(true);
    },
    [streamingServices],
  );

  // Modal #####################################################################
  // Modal 열기
  const setModal = (name) => {
    // 데이터 초기화
    if (name === 'externalLinks') {
      initModalExternalLinks(name);
    }

    if (name === 'streamingServices') {
      initModalStreamingServices(name);
    }
  };

  // Modal 닫기
  const closeModal = useCallback(async () => {
    setModalOpen(false);
  }, []);

  // 모달 닫을 때 데이터 저장
  const saveModal = () => {
    // 외부 영상 등록 모달 창 닫을 때
    if (modalType === 'externalLinks') {
      let hasInValidValue = false;
      modalExternalLinks.forEach((modalExternalLink)=>{
        if((!modalExternalLink.imageUrl && !modalExternalLink.externalLinkImgFile)
            ||!modalExternalLink.contentsUrl
            ||!modalExternalLink.title) {
          hasInValidValue = true;
        }
      });

      if(hasInValidValue) {
          alert('외부 영상 이미지, 외부 영상 링크, 타이틀은 필수 항목 입니다.');
          return;
      }

      // 저장 할 데이터에 해당 Modal 데이터 넣기
      // index 정보 제거하기
      const saveExternalLinks = modalExternalLinks;
      setExternalLinks(saveExternalLinks.length !== 0 ? saveExternalLinks : null);
    }

    // 음원 링크 모달창 닫을 때
    if (modalType === 'streamingServices') {
      // 저장 할 데이터에 해당 Modal 데이터 넣기
      const saveStreamingServices = [];

      // 링크가 입력 안된 데이터는 빼기
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < modalStreamingServices.length; i++) {
        if (modalStreamingServices[i] !== '') {
          saveStreamingServices.push(modalStreamingServices[i]);
        }
      }
      setStreamingServices(saveStreamingServices.length !== 0 ? saveStreamingServices : null);
    }

    setCheckCloseModal(true);
  };

  // 외부영상 추가하기
  const addExternalLink = useCallback(async () => {
      const addData = {
        // index: modalExternalLinks.length,
        title: '',
        imageUrl: '',
        contentsUrl: '',
        description: '',
        externalLinkImgFile: null,
      };

      setModalExternalLinks((externalLinksData) => [...externalLinksData, addData,]);
  }, [modalExternalLinks]);

  // 외부영상 삭제하기
  const deleteExternalLink = useCallback(async (callerIndex) => {
    setModalExternalLinks(
        modalExternalLinks.filter((modalExternalLink, index) => index !== callerIndex)
    );
  }, [modalExternalLinks,],);

  // 외부 링크 파일 설정
  const setExternalLinkImgFile = (event, callerIndex) => {
    let _externalLinkList = [...modalExternalLinks];
    _externalLinkList = _externalLinkList.map((modalExternalLink, index) => {
      if (index === callerIndex) modalExternalLink['externalLinkImgFile'] = event;
      return modalExternalLink;
    });
    setModalExternalLinks(_externalLinkList);
  };

  // 외부 링크 이미지 설정
  const setExternalLinkImg = (event, callerIndex) => {
    // 데이터 세팅
    setModalExternalLinks(
        modalExternalLinks.map((modalExternalLink, index) =>
            index === callerIndex
                ? { ...modalExternalLink, imageUrl: event }
                : modalExternalLink,
        )
    );
  };

  // useEffect #################################################################
  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  // 바운드 뮤직 상세 표시를 위한 조회
  useEffect(() => {
    if (prevId) {
      getDetailBaundMusic().then((r) => r);
    }
    // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함
  }, [getDetailBaundMusic, prevId]);

  //모달 창 닫기
  useEffect(() => {
    if (!checkCloseModal) {
      return;
    }
    closeModal().then(r => r);
    setCheckCloseModal(false);
  }, [checkCloseModal, closeModal]);

  // 페이지 화면 관련 ###########################################################
  return (
    <>
      <TopContainer>
        <TopWrapper>
          <EventId>{prevId}</EventId>
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>
            {baundMusicId !== null ? (
                <NormalButton text="수정하기" onSubmit={saveBaundMusicData} />
            ) : (
                <NormalButton text="저장하기" onSubmit={saveBaundMusicData} />
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <TitleText>기본 정보</TitleText><LineDiv/>
      <MainContainer>
        {/*커버 이미지*/}
        <InputWrapper span={12}>
          <FileWrapper>
            <FileInput
              type="coverImg" label="커버"
              placeholder={coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'}
              value={coverImgFile}
              setFile={(e)=>setCoverImgFile(e)}
              setImgUrl={setCoverImg}
            />
            <EventImg src={coverImg || ''}/>
          </FileWrapper>
        </InputWrapper>
        {/*썸네일 이미지*/}
        <InputWrapper span={12}>
          <FileWrapper>
            <FileInput
              type="coverImg" label="썸네일(둘러보기 노출)"
              placeholder={coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'}
              value={profileImgFile}
              setFile={(e)=>setProfileImgFile(e)}
              setImgUrl={setProfileImg}
            />
            <EventImg src={profileImg || ''}/>
          </FileWrapper>
        </InputWrapper>
        {/*고유 아이디*/}
        <InputWrapper span={6}>
          <LabelTextInput
            name="baundMusicId" label="고유 ID(Index)" placeholder="고유 ID 는 자동으로 입력됩니다."
            value={baundMusicId}
            onChange={(e)=>setBaundMusicId(e.target.value)}
            readOnly
          />
        </InputWrapper>
        {/*Baund Music 타입*/}
        <InputWrapper span={6}>
          <SelectModule
            name="baundMusicType" placeholder="Baund Music 타입을 선택해주세요." label="Baund Music 타입"
            value={baundMusicType[type]}
            onChange={(e)=>setType(e.value)}
            options={baundMusicTypeOption}
            Required
          />
        </InputWrapper>
        {/*한글 제목*/}
        <InputWrapper span={12}>
          <LabelTextInput
            name="title" label="타이틀" placeholder="제목을 입력해주세요."
            value={title} onChange={(e)=>setTitle(e.target.value)} Required
          />
        </InputWrapper>
        {/*영문 제목*/}
        <InputWrapper span={12}>
          <LabelTextInput
            name="titleEn" label="영문 타이틀" placeholder="영문 타이틀을 입력해주세요."
            value={titleEn} onChange={(e)=>setTitleEn(e.target.value)}
          />
        </InputWrapper>
        {/*한글 아티스트 명*/}
        <InputWrapper span={6}>
          <LabelTextInput
            name="artist" label="아티스트" placeholder="아티스트를 입력해주세요."
            value={artist} onChange={(e)=>setArtist(e.target.value)} Required
          />
        </InputWrapper>
        {/*영문 아티스트 명*/}
        <InputWrapper span={6}>
          <LabelTextInput
              name="artistEn" label="아티스트 EN" placeholder="영문 아티스트를 입력해주세요."
              value={artistEn} onChange={(e)=>setArtistEn(e.target.value)} Required
          />
        </InputWrapper>
        {/*설명*/}
        <InputWrapper span={12} >
          <LabelTextAreaInput
            name="description" label="설명" placeholder="설명을 입력해주세요."
            value={description} setValue={(e)=>setDescription(e.target.value)}
          />
        </InputWrapper>
        {/*공개 여부*/}
        <InputWrapper span={12}>
          <RadioButton
              name="privateYn" label="공개 여부" type="privateYn"
              value={privateYn} onChange={(e)=>setPrivateYn(e.target.value)}
              Required
          />
        </InputWrapper>
        {/*공개 시간*/}
        <InputWrapper span={12} >
          <DateInput
              label="공개 시간" name="startDate"
              initialStartDate={startDate} onChange={(e)=>setStartDate(e.target.value)}
              Required
          />
        </InputWrapper>
      </MainContainer>

      <TitleText>수록곡</TitleText>
      <LineDiv />
      {/*<EditButton*/}
      {/*    text="수록곡 추가" className='saveBtn'*/}
      {/*    onClick={() => setTracks([...tracks, {*/}
      {/*      originalArticleId:null,*/}
      {/*      originalMemberId:null,*/}
      {/*      beatId:null,*/}
      {/*      articleId:null,*/}
      {/*      memberId:null,*/}
      {/*      editCopyArticleDescription:''*/}
      {/*    }])}*/}
      {/*/>*/}
      <MainContainer>
        {/* 영상 정보 */}
        <InputWrapper span={12} mb={0}>
          {
            tracks?.map((row, index) =>
                <ArticleTable data={row} idx={index} list={tracks} listSetter={setTracks} />)
          }
        </InputWrapper>
      </MainContainer>
      <TitleText>외부영상 & 음원사이트 선택</TitleText><LineDiv />
      <MainContainer>
        <InputWrapper span={12}>
          <VideoInput onClick={() => setModal('externalLinks')} text="외부영상 등록하기" isBaundMusic/>
          <VideoInput onClick={() => setModal('streamingServices')} text="음원 링크 등록하기" isBaundMusic/>
        </InputWrapper>
      </MainContainer>

      {/*외부 영상 세팅 Modal*/}
      {modalOpen && modalType === 'externalLinks' && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <ModalWrapper>
            <ModalButtonWrapper>
              <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
              <NormalButton text="저장하기" onSubmit={() => saveModal()} />
            </ModalButtonWrapper>
            <ModalTitle>외부영상 등록</ModalTitle>
            <NormalButton text="외부영상 추가하기 +" onSubmit={() => addExternalLink()}/>
            <LineDiv />
            <Table
              trHeight="115px"
              colList={modalExternalLinkColList}
              thead={
                <>
                  <ListHead title="외부영상 이미지" />
                  <ListHead title="이미지 업로드" />
                  <ListHead title="외부영상 링크" />
                  <ListHead title="타이틀" />
                  <ListHead title="설명" />
                </>
              }
            >
              {modalExternalLinks?.map((el, index) => (
                <tr key={index}>
                  <td>{el.imageUrl ?<ExternalLinkImg url={el.imageUrl} /> : <ExternalLinkImg />}</td>
                  <td>
                      <FileInput
                        type="externalLinkImg" label=""
                        placeholder={el.imageUrl ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'}
                        value={el.externalLinkImgFile}
                        setFile={(e) => setExternalLinkImgFile(e, index)}
                        setImgUrl={(e) => setExternalLinkImg(e, index)}
                        isModalExternalLinkImg
                    />
                  </td>
                  <td>
                    <LabelTextInput
                        name="contentsUrl" label="" placeholder="외부영상 URL을 입력해주세요."
                        value={el.contentsUrl}
                        onChange={(e) => handleModalInputSetData(e, index)}
                    />
                  </td>
                  <td>
                    <LabelTextInput
                      name="title" label="" placeholder="타이틀을 입력해주세요."
                      value={el.title}
                      onChange={(e) => handleModalInputSetData(e, index)}
                    />
                  </td>
                  <td>
                    <LabelTextInput
                        name="description" label="" placeholder="외부영상 설명을 입력해주세요."
                        value={el.description}
                        onChange={(e) => handleModalInputSetData(e, index)}
                    />
                  </td>
                  <td><EditButton text="삭제" onClick={() => deleteExternalLink(index).then((r) => r)}/></td>
                </tr>
              ))}
            </Table>
          </ModalWrapper>
        </VideoModal>
      )}

      {/*음원 링크 세팅 Modal*/}
      {modalOpen && modalType === 'streamingServices' && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <ModalWrapper>
            <ModalButtonWrapper>
              <NormalButton text="취소" className="cancelBtn" onSubmit={closeModal}/>
              <NormalButton text="저장하기" onSubmit={() => saveModal()} />
            </ModalButtonWrapper>
            <ModalTitle>음원 링크 등록</ModalTitle>
            <LineDiv />
            <Table
              trHeight="115px"
              colList={modalStreamingServicesColList}
              thead={
                <>
                  <ListHead title="음원 사이트 타입" />
                  <ListHead title="음원 사이트 링크" />
                </>
              }
            >
              {modalStreamingServices?.map((el) => (
                <tr key={el.streamingServiceType}>
                  <td>{baundMusicStreamingServiceType[el.streamingServiceType]}</td>
                  <td>
                    <LabelTextInput
                      name={el.streamingServiceType}
                      label=""
                      value={el.link}
                      placeholder="음원 링크를 입력해주세요."
                      onChange={handleModalStreamingServicesInputSetData}
                    />
                  </td>
                </tr>
              ))}
            </Table>
          </ModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default ReleaseSoundDetail;

// CSS
const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LineDiv = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 18px 0;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const ArticleTableContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 20px 30px;
  grid-gap: 10px 20px;
  background-color: #ccc;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 5px
`;

// Modal
const ModalWrapper = styled.div`
  width: 1275px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const ExternalLinkImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 0;
`;

const ExternalLink = styled.div`
  width: 130px;
  height: 80px;
  border-radius: 6px;
  background: url(${({ url }) => url || blankImg}) center no-repeat;
  background-size: cover;

  &.ExternalLink {
    border-radius: 6px;
    background: url(${({ url }) => url || blankUserImg}) center no-repeat;
    background-size: cover;
  }
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  margin-bottom: 20px;
  gap: 30px;
  grid-gap: 30px;
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const EventId = styled.h1`
  width: 500px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;
