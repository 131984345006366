import React, { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import ProfileImg from '../../../../components/common/image/ProfileImg';
import RadioButton from '../../../../components/common/Button/RadioButton';
import SelectModule from '../../../../components/common/Select/SelectModule';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import DateInput from '../../../../components/common/Input/DateInput';
import VideoModal from '../../../../components/common/Modal/VideoModal';
import { CustomProps } from '../../../../definitions/common';
import {
  CollectionBeatDetailRowComponent,
  CollectionVideoDetailRowComponent,
} from './RecommendCollectionRowComponent';
import Pagination from '../../../../components/common/Pagination';

//hooks
import { useAppSelector } from '../../../../redux/store/hooks';
import handleDate from '../../../../hooks/handleDate';
import changeUrl from '../../../../hooks/changeUrl';

// constant
import {
  beatCollectionList,
  collectionType,
  videoCollectionList,
} from '../../../../constant/explore';
import {
  GetLanguageOption,
  languageOptions,
} from '../../../../constant/common';

// config
import {
  CONTENTS_MEMBER_COLLECTION,
  COLLECTION,
} from '../../../../constant/apiUrl';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

type data = {
  [key: string]: any;
};

//초기 계정 데이터
const initialDetailData: data = {
  id: '',
  memberPid: '',
  activeYn: '',
  name: '',
  type: '',
  recommendStartDt: '',
  recommendEndDt: '',
  language: '',
};

const RecommendCollectionDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const adminName = useAppSelector((state) => state.user.user.name);
  const { addData, editData }: any = location.state;
  const [contentList, setContentList] = useState<any>([]);
  const [contentsUrl, setContentsUrl] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [detailData, setDetailData] = useState<any>(
    editData || addData || initialDetailData,
  );
  const {
    id,
    memberPid,
    name,
    type,
    activeYn,
    recommendStartDt,
    recommendEndDt,
    language,
    coverImgUrl,
    collectionId,
  } = detailData;

  const initialStartDate = recommendStartDt?.replace(' ', 'T').slice(0, 16);
  const initialEndDate = recommendEndDt?.replace(' ', 'T').slice(0, 16);

  // pagination
  const postsPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      setDetailData({ ...detailData, [name]: value });
    },
    [detailData],
  );

  // select set 함수
  const handleSetData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (e: any, name: string) => {
      setDetailData({ ...detailData, [name]: e.value });
    },
    [detailData],
  );
  // 멤버 컬렉션 리스트 불러오기
  const getCollectionList = useCallback(async () => {
    const params = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
    };
    try {
      if (type === 'BT') {
        const resp = await axios.get(
          `${CONTENTS_MEMBER_COLLECTION}/beat/${collectionId || id}/contents`,
          {
            params,
          },
        );
        setContentList(resp.data.contentsList);
        setTotalCount(resp.data.count || 0);
      } else {
        const resp = await axios.get(
          `${CONTENTS_MEMBER_COLLECTION}/article/${
            collectionId || id
          }/contents`,
          {
            params,
          },
        );
        setContentList(resp.data.contentsList);
        setTotalCount(resp.data.count || 0);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
  }, [collectionId, id, pageNumber, type]);

  // 컬렉션 등록하기
  const addRecommendCollection = async (btnType: string) => {
    const recommendStartDate = `${recommendStartDt
      ?.replace('T', ' ')
      .slice(0, 16)}:00`;
    const recommendEndDate = `${recommendEndDt
      ?.replace('T', ' ')
      .slice(0, 16)}:59`;
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(recommendEndDate);
    const params = {
      adminName,
      activeYn,
      recommendStartDate,
      recommendEndDate,
      language,
      memberCollectionId: id,
      contentsSubType: type,
    };

    if (activeYn === undefined) {
      alert('공개여부를 설정해주세요.');
      return;
    }
    if (recommendStartDt > recommendEndDt) {
      alert('시작일시가 종료일시보다 느릴 수 없습니다.');
      return;
    }
    if (compareEndDate) {
      alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
      return;
    }
    if (language === undefined) {
      alert('언어 코드를 선택해주세요.');
      return;
    }
    try {
      dispatch(setLoading());
      if (btnType === 'add') {
        params.recommendStartDate = `${recommendStartDt?.slice(0, 16)}:00`;
        params.recommendEndDate = `${recommendEndDt?.slice(0, 16)}:00`;

        await axios.post(
          COLLECTION,
          {},
          {
            params,
          },
        );
        alert('저장 되었습니다.');
        navigate('/explore/allTab/recommendCollection');
      }
      if (btnType === 'change') {
        await axios.post(
          `${COLLECTION}/${id}/update`,
          {},
          {
            params,
          },
        );
        alert('수정 되었습니다.');
        navigate('/explore/allTab/recommendCollection');
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // 영상 버튼 클릭
  const viewArticle = (coverUrl: any) => {
    setContentsUrl(coverUrl);
    setModalOpen(true);
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
  };

  // url 컨트롤
  const changedUrl = useMemo(() => changeUrl(contentsUrl), [contentsUrl]);

  useEffect(() => {
    getCollectionList();
  }, [getCollectionList, pageNumber]);

  // 모달 오버레이에서 스크롤 방지
  useEffect(() => {
    if (modalOpen) {
      document.body.style.cssText = `
      position: fixed;
      overflow-y: scroll;
      width: 100%;`;
    }
    return () => {
      document.body.style.cssText = '';
    };
  }, [modalOpen]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ProfileWrapper>
            {coverImgUrl ? <ProfileImg url={coverImgUrl} /> : <ProfileImg />}
            <ProfileTitleWrapper>
              <BeatTitle>
                {collectionId || id}. {name}
              </BeatTitle>
              <BeatMaker>{memberPid}</BeatMaker>
              <TypeWrapper>
                {collectionType[type]} 컬렉션
                <span>
                  {recommendStartDt &&
                    recommendEndDt &&
                    `${handleDate(recommendStartDt)} ~ ${handleDate(
                      recommendEndDt,
                    )}`}
                </span>
              </TypeWrapper>
            </ProfileTitleWrapper>
          </ProfileWrapper>
          <LineDiv />
          <ButtonWrapper>
            {editData?.id ? (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="수정하기"
                  onSubmit={() => addRecommendCollection('change')}
                />
              </>
            ) : (
              <>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="등록하기"
                  onSubmit={() => addRecommendCollection('add')}
                />
              </>
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <SpaceDiv />
      <MainContainer>
        <InputWrapper data={editData?.id}>
          <TitleWrapper>
            <TableTitle>{collectionType[type]}</TableTitle>
            {totalCount !== 0 && (
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            )}
          </TitleWrapper>
          {type === 'BT' ? (
            <Table
              colList={beatCollectionList}
              thead={
                <>
                  <ListHead title="Id" />
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListHead title="BPM" />
                  <ListHead title="BeatKey" />
                  <ListHead title="공개 여부" />
                  <ListHead title="공개 날짜" />
                </>
              }
            >
              {contentList?.map((el: any) => (
                <CollectionBeatDetailRowComponent key={el.id} data={el} />
              ))}
            </Table>
          ) : (
            <Table
              colList={videoCollectionList}
              thead={
                <>
                  <ListHead title="번호" />
                  <ListHead title="썸네일" />
                  <ListHead title="Pid" />
                  <ListHead title="이름" />
                  <ListHead title="비트명" />
                  <ListHead title="업로드 날짜" />
                  <ListHead title="공개 여부" />
                  <ListHead title="상태" />
                  <ListHead title="" />
                </>
              }
            >
              {contentList?.map((el: any) => (
                <CollectionVideoDetailRowComponent
                  key={el.id}
                  data={el}
                  onClick={() => {
                    viewArticle(el.contentsUrl);
                  }}
                />
              ))}
            </Table>
          )}
          {modalOpen && (
            <VideoModal closeModal={closeModal}>
              <VideoModalWrapper>
                <Video controls autoPlay>
                  <track default kind="captions" />
                  <source src={changedUrl} type="video/mp4" />
                  Your browser does not support the video tag. I suggest you
                  upgrade your browser.
                </Video>
              </VideoModalWrapper>
            </VideoModal>
          )}
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateWrapper>
            <DateInput
              label="노출기간(시작일)"
              name="recommendStartDt"
              onChange={handleInputSetData}
              initialStartDate={initialStartDate}
              Required
            />
            <DateInput
              label="노출기간(종료일)"
              name="recommendEndDt"
              onChange={handleInputSetData}
              initialStartDate={initialEndDate}
              Required
            />
          </DateWrapper>
        </InputWrapper>
        <InputWrapper>
          <SelectModule
            name="language"
            placeholder="언어 코드를 선택해주세요."
            value={GetLanguageOption[language]}
            label="언어 코드"
            onChange={handleSetData}
            options={languageOptions}
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default RecommendCollectionDetail;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const BeatTitle = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  letter-spacing: -0.3px;
  line-height: 35px;
`;

const BeatMaker = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 30px;
  letter-spacing: -0.3px;
`;

const TypeWrapper = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.color.mainPurple};

  span {
    margin-left: 20px;
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const LineDiv = styled.div`
  position: absolute;
  width: 100%;
  top: 205px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 18px 0;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const MainContainer = styled.div`
  width: 100%;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div<CustomProps>`
  margin-bottom: 40px;
  &:nth-child(1) {
    margin-bottom: 60px;
    grid-row: ${({ data }) => data && '4'};
  }
`;

const TableTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const VideoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const Video = styled.video`
  max-width: 300px;
`;

const SpaceDiv = styled.div`
  height: 50px;
`;
