import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// common
import RoundButton from '../../components/common/Button/RoundButton';
import SearchInput from '../../components/common/Input/SearchInput';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import Pagination from '../../components/common/Pagination';
import RekognitionRowComponent from './components/RekognitionRowComponent';

// constant
import { rekognitionColList, rekognitionOptions } from '../../constant/cs';
import { postsPerPage } from '../../constant/common';

// hooks
import useInput from '../../hooks/useInput';

// definition
import { blockRowComponentDataProps } from '../../definitions/customService';

// config
import {
  REKOGNITION_BLOCK_LIST,
  REKOGNITION_LIST,
  REKOGNITION_REJECT_LIST,
} from '../../constant/apiUrl';

const RekognitionVideo = () => {
  const navigate = useNavigate();
  const storageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('blockVideoType');
  const [blockList, setBlockList] = useState([]);
  const [needConfirmList, setNeedConfirmList] = useState([]);
  const [rejectList, setRejectList] = useState([]);
  const [type, setType] = useState(storageType || '미차단 내역');
  const [keyword, setKeyword, onReset] = useInput('');
  const [videoType, setVideoType] = useState('');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storageNumber ? JSON.parse(storageNumber) : 0,
  );

  const params = {
    searchType: 'PID',
    articleType: videoType,
    keyword,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  useEffect(() => {
    if (storageNumber || storageType) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('blockVideoType', '');
    }
  }, [storageNumber, storageType]);

  const saveStorage = () => {
    window.localStorage.setItem('pageNumber', String(pageNumber));
    window.localStorage.setItem('blockVideoType', type);
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && keyword) {
      setPageNumber(0);
      params.offset = 0;
      getRekognitionList();
    }
  };

  // 신고 미처리, 차단, 삭제 내역 조회
  const getRekognitionList = async () => {
    switch (type) {
      case '미차단 내역':
        try {
          const resp = await axios.get(REKOGNITION_LIST, {
            params,
          });
          await setNeedConfirmList(resp.data.adminRekognitionResultList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      case '차단 내역':
        try {
          const resp = await axios.get(REKOGNITION_BLOCK_LIST, {
            params,
          });
          await setBlockList(resp.data.adminRekognitionResultList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      case '삭제 내역':
        try {
          const resp = await axios.get(REKOGNITION_REJECT_LIST, {
            params,
          });
          await setRejectList(resp.data.adminRekognitionResultList);
          await setTotalCount(resp.data.count || 0);
        } catch (err) {
          alert('잠시후 다시 시도 해주세요.');
        }
        break;
      default:
        break;
    }
  };

  // 상단 버튼 클릭 이벤트
  const handleButton = (e: any) => {
    const { title } = e.target;
    setType(title);
    onReset();
  };

  const goToVideoDetail = async (data: any) => {
    navigate('/cs/rekognitionVideo/detail', {
      state: {
        contentsUrl: data.contentsUrl,
        data,
        type: 'rekognition',
        rekognitionType: type,
      },
    });
  };

  const handleSelect = (e: React.MouseEvent<HTMLLIElement>, setState: any) => {
    setState(e.currentTarget.title);
  };

  useEffect(() => {
    getRekognitionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (storageType !== type) {
      params.offset = 0;
      setPageNumber(0);
    }
    setVideoType('');
    getRekognitionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useEffect(() => {
    if (videoType) {
      params.offset = 0;
      setPageNumber(0);
    }
    getRekognitionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoType]);

  return (
    <>
      <ButtonContainer>
        <RoundButton
          title="미차단 내역"
          onClick={handleButton}
          selected={type === '미차단 내역' && true}
        />
        <RoundButton
          title="차단 내역"
          selected={type === '차단 내역' && true}
          onClick={handleButton}
        />
        <RoundButton
          title="삭제 내역"
          onClick={handleButton}
          selected={type === '삭제 내역' && true}
        />
      </ButtonContainer>
      <Line />
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchInputWrapper>
              <SearchInput
                placeholder="검색하실 Pid를 입력해주세요."
                value={keyword}
                onChange={setKeyword}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </TopWrapper>
        <SpaceDiv />
      </TopContainer>
      <MainContainer>
        {type === '미차단 내역' && (
          <Table
            colList={rekognitionColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="Pid" />
                <ListHead title="업로드 날짜" />
                <ListSelect
                  title="영상 분류"
                  list={rekognitionOptions}
                  onClick={handleSelect}
                  setState={setVideoType}
                />
                <ListHead title="" />
              </>
            }
          >
            {needConfirmList?.map((el: blockRowComponentDataProps) => (
              <RekognitionRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '차단 내역' && (
          <Table
            colList={rekognitionColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="Pid" />
                <ListHead title="업로드 날짜" />
                <ListSelect
                  title="영상 분류"
                  list={rekognitionOptions}
                  onClick={handleSelect}
                  setState={setVideoType}
                />
                <ListHead title="" />
              </>
            }
          >
            {blockList?.map((el: blockRowComponentDataProps) => (
              <RekognitionRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
        {type === '삭제 내역' && (
          <Table
            colList={rekognitionColList}
            thead={
              <>
                <ListHead title="접수 번호" />
                <ListHead title="Pid" />
                <ListHead title="업로드 날짜" />
                <ListSelect
                  title="영상 분류"
                  list={rekognitionOptions}
                  onClick={handleSelect}
                  setState={setVideoType}
                />
                <ListHead title="" />
              </>
            }
          >
            {rejectList?.map((el: blockRowComponentDataProps) => (
              <RekognitionRowComponent
                key={el.id}
                data={el}
                onClick={() => goToVideoDetail(el)}
                saveStorage={saveStorage}
              />
            ))}
          </Table>
        )}
      </MainContainer>
    </>
  );
};

export default RekognitionVideo;

const ButtonContainer = styled.div`
  width: 100%;
`;

const Line = styled.div`
  display: block;
  border-bottom: 1px solid #e7e7e7;
  margin: 30px 0 50px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SpaceDiv = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  padding-bottom: 50px;
`;
