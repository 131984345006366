import React from 'react';
//컴포넌트
import ListItem from '../../../components/common/List/ListItem';

// hooks
import handleDate from '../../../hooks/handleDate';

const ResignMemberRowComponent = ({ data }: any) => {
  const { id, memberPid, masterReason, deletedDt } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={masterReason || '사유 없음'} />
      </td>
      <td>
        <ListItem text={deletedDt ? handleDate(deletedDt) : ''} />
      </td>
    </tr>
  );
};

export default ResignMemberRowComponent;
