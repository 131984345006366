import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import LabelTextAreaInput from '../../components/common/Input/LabelTextAreaInput';
import NormalButton from '../../components/common/Button/NormalButton';
import { SERVER_REFRESH } from '../../constant/apiUrl';

const RefreshCache = () => {
  const [textUrl, setTextUrl] = useState('');

  const handleTextUrl = (e: any) => {
    setTextUrl(e.target.value);
  };

  const requestPurge = async () => {
    const purgeUrls = textUrl.split('\n');
    try {
      await axios.get(`${SERVER_REFRESH}/cdn-purge`, {
        params: {
          purgeUrls: purgeUrls.filter((el: string) => el !== ''),
        },
      });
      alert('초기화가 완료되었습니다.');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <MainContainer>
      <InputContainer>
        <LabelTextAreaInput
          name="textUrl"
          label="url입력"
          value={textUrl}
          placeholder="url을 한줄 단위로 작성해주세요."
          setValue={handleTextUrl}
        />
      </InputContainer>
      <ButtonContainer>
        <NormalButton
          text="Purge"
          onSubmit={() => {
            requestPurge();
          }}
          className=""
        />
      </ButtonContainer>
    </MainContainer>
  );
};

export default RefreshCache;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 50px;
`;

const InputContainer = styled.div`
  width: 820px;
`;

const ButtonContainer = styled.div`
  width: calc(100% - 820px);
  margin: 27.5px 0 0 35px;
`;
