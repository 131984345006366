import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// common
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import NormalButton from '../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import ListDateSelect from '../../components/common/Select/ListDateSelect';
import RowComponent from './components/RowComponent';

// 액션
import { getBeatList } from '../../redux/actions/beat/beat';
import { resetDetailBeat } from '../../redux/slices/beat';

// 타입
import { RowComponentDataProps } from '../../definitions/beat';
import {
  beatSearchTypeList,
  beatBpmOptions,
  beatPrivateYnOptions,
  beatColList,
} from '../../constant/beat';

// hooks
import useInput from '../../hooks/useInput';
import saveStorage from '../../hooks/saveStorage';
import { useAppSelector } from '../../redux/store/hooks';

// constant
import { postsPerPage } from '../../constant/common';

const Beat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const beatList = useAppSelector((state) => state.beat.beatList);
  const totalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState<string>(storageType || '');
  const [bpm, setBpm] = useState<string>('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);
  const [privateYn, setPrivateYn] = useState<string>('');
  const [searchPrivateYn, setSearchPrivateYn] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');

  // pagination
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );
  const [playBeat, setPlayBeat] = useState('');

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const params = {
    type,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn,
  };

  const enterKeyPress = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (!type) {
        setType('T');
        params.type = 'T';
      }
      setPageNumber(0);
      params.offset = 0;
      dispatch(getBeatList(params));
    }
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // useEffect(() => {
  //   dispatch(getBeatList(params));
  // }, [pageNumber]);

  useEffect(() => {
    if (storagePageNumber === '0') {
      params.offset = 0;
      setPageNumber(0);
    }
    dispatch(getBeatList(params));
  }, [dispatch, pageNumber, maxBpm, minBpm, minStartDate, maxStartDate, privateYn]);

  useEffect(() => {
    setPageNumber(0);
    switch (bpm) {
      case  '49': setMinBpm(  0); setMaxBpm( 49); break;
      case  '50': setMinBpm( 50); setMaxBpm( 80); break;
      case  '80': setMinBpm( 80); setMaxBpm(100); break;
      case '100': setMinBpm(100); setMaxBpm(140); break;
      case '140': setMinBpm(140); setMaxBpm(180); break;
      case '180': setMinBpm(180); setMaxBpm(999); break;
      default:    setMinBpm(  0); setMaxBpm(999); break;
    }
  }, [bpm]);

  useEffect(() => {
    setPageNumber(0);
    setPrivateYn(searchPrivateYn);
  }, [searchPrivateYn])

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={beatSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="비트 등록하기"
            onSubmit={() => {
              navigate('/beat/list/add');
              dispatch(resetDetailBeat());
            }}
            className=""
          />
        </TopWrapper>
        <SpaceDiv />
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </TopContainer>
      <SpaceDiv />
      <MainContainer>
        <Table
          colList={beatColList}
          thead={
            <>
              <ListHead title="Id" />
              <ListHead title="Cover" />
              <ListHead title="Title" />
              <ListHead title="Pid" />
              <ListHead title="장르" />
              <ListSelect
                title="BPM"
                list={beatBpmOptions}
                onClick={handleSelect}
                setState={setBpm}
              />
              <ListHead title="BeatKey" />
              <ListSelect
                title="공개 여부"
                list={beatPrivateYnOptions}
                onClick={handleSelect}
                setState={setSearchPrivateYn}
              />
              <ListDateSelect
                title="공개 날짜"
                setMinStartDate={setMinStartDate}
                setMaxStartDate={setMaxStartDate}
              />
            </>
          }
        >
          {beatList?.map((el: RowComponentDataProps) => (
            <RowComponent
              key={el.id}
              data={el}
              saveStorage={() => {
                saveStorage(pageNumber, type, word);
              }}
              playBeat={playBeat}
              setPlayBeat={setPlayBeat}
            />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default Beat;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SpaceDiv = styled.div`
  height: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  padding-bottom: 50px;
`;
