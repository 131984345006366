import React from 'react';
import styled from 'styled-components';

interface ButtonModuleProps {
  text: string;
  onSubmit: () => void;
  className?: string;
}

const FullButton = ({ text, onSubmit, className }: ButtonModuleProps) => (
  <Button onClick={onSubmit} className={className}>
    {text}
  </Button>
);

export default FullButton;

const Button = styled.button`
  width: 100%;
  padding: 13px 30px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }) => theme.color.mainWhite};
  background-color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;

  &.unActiveBtn {
    background-color: ${({ theme }) => theme.color.subUnactiveGray};
  }
`;
