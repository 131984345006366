import React from 'react';
import styled from 'styled-components';

// asset
import blankImg from '../../../assets/images/page/blankImg.png';

const ImgBox = ({ url, label, className, width, height }) => {
  return (
    <ImgDivWrapper>
      {label && <InputText>{label}</InputText>}
      <ImgDiv url={url} className={className} width={width} height={height} />
    </ImgDivWrapper>
  );
};

export default ImgBox;

const ImgDivWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ImgDiv = styled.div`
  width: ${({width}) => `${width}%`};
  height: ${({height}) => `${height}%`};
  //width: 100%;
  //height: 100%;
  border-radius: 6px;
  background: #f1f3f5 url(${({url}) => url || blankImg}) no-repeat center;
  background-size: contain;
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};
`;
