import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';

// config
import { ADMIN_CATEGORY } from '../../../constant/apiUrl';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';

const AdminCategoryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const detailData: any = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [categoryName, setCategoryName] = useState('');
  const [endPoint, setEndPoint] = useState('');
  const [adminRoleList, setAdminRoleList] = useState([]);

  const getAdminCategory = async () => {
    try {
      const resp = await axios.get(`${ADMIN_CATEGORY}/${prevId}/detail`);
      setCategoryName(resp.data.category?.categoryName);
      setEndPoint(resp.data.category?.endPoint);
      setAdminRoleList(resp.data.adminRoleList);
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  useEffect(() => {
    if (prevId) {
      getAdminCategory();
    }
  }, []);

  const saveFilterCategory = async (type: string) => {
    if (!categoryName || !endPoint) {
      alert('필요한 양식을 전부 채워주세요.');
      return;
    }

    const body = {
      categoryName,
      endPoint,
    };
    try {
      if (type === 'add') {
        await axios.post(`${ADMIN_CATEGORY}/save`, body);
        alert('생성 되었습니다.');
      }
      if (type === 'edit') {
        await axios.put(`${ADMIN_CATEGORY}/${prevId}/edit`, body, {});
        alert('수정 되었습니다.');
      }
      navigate('/admin/adminCategory');
    } catch (err: any) {
      if (err.response.data.message) {
        alert(err.response.data.message);
        return;
      }
      alert('잠시후 다시 이용해주세요.');
    }
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton
              text="저장하기"
              onSubmit={() => saveFilterCategory('add')}
            />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => saveFilterCategory('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="categoryName"
            label="카테고리 명"
            value={categoryName}
            placeholder="카테고리 명을 입력해 주세요."
            onChange={(e) => setCategoryName(e.target.value)}
            onReset={() => setCategoryName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="endPoint"
            label="엔드포인트"
            value={endPoint}
            placeholder="엔드포인트를 입력해 주세요."
            onChange={(e) => setEndPoint(e.target.value)}
            onReset={() => setEndPoint('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <Table
            colList={[100, 100]}
            thead={
              <>
                <ListHead title="관리자 권한 아이디" />
                <ListHead title="관리자 권한 명" />
              </>
            }
          >
            {adminRoleList?.map((el: any) => (
              <tr key={el.id}>
                <td>
                  <ListItem text={el.id} />
                </td>
                <td>
                  <ListItem text={el.userRole} />
                </td>
              </tr>
            ))}
          </Table>
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AdminCategoryDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
    grid-column: span 2;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
  }
  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }
`;
