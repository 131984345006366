import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import dragAndDrop from '../../../assets/images/page/dragAndDrop.png';

const FilterCategoryList = ({ key, data, moveListItem, index }: any) => {
  const componentRef = useRef<any>(null);

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element: any, monitor: any) => {
      // const dragIndex = item.index;
      const hoverIndex = index;
      // const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // const hoverMiddleY =
      //   (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // const hoverActualY = monitor.getClientOffset().y - hoverBoundingRect.top;
      //
      // // if dragging down, continue only when hover is smaller than middle Y
      // if (dragIndex < hoverIndex && hoverActualY < hoverMiddleY) return;
      // // if dragging up, continue only when hover is bigger than middle Y
      // if (dragIndex > hoverIndex && hoverActualY > hoverMiddleY) return;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
    (node: any) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );
  return (
    // <Li key={data?.id} ref={applyRef}>
    //   <img src={dragAndDrop} alt="dnd icon" />
    //   <GenreName>{data?.genre}</GenreName>
    //   <InputDeleteButton onClick={() => onClick(data)} tabIndex={-1} />
    // </Li>

    <Li key={data?.id} ref={applyRef}>
      <img src={dragAndDrop} alt="dnd icon" />
      {data.name}
    </Li>
  );
};

export default FilterCategoryList;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 59px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};

  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};

    img {
      width: 15px;
      margin-right: 10px;
    }
  }
`;
