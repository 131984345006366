import React from 'react';
import styled from 'styled-components';

interface DateInputProps {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  initialStartDate: string;
  name?: string;
  label?: string;
  Required?: boolean;
  className?: string;
  type?: string;
}

interface InputProps {
  value: string;
  className?: string;
}

const DateInput = ({
  name,
  label,
  Required,
  onChange,
  initialStartDate,
  className,
  type,
}: DateInputProps) => {
  return (
    <InputContainer>
      {label && (
        <InputText>
          {label} {Required && <span>*</span>}
        </InputText>
      )}
      <InputBox value={initialStartDate} className={className}>
        <input
          id="date"
          max="9999-12-31T23:59"
          value={initialStartDate}
          name={name}
          type={type ? type :"datetime-local"}
          onChange={onChange}
        />
      </InputBox>
    </InputContainer>
  );
};

export default DateInput;

const InputContainer = styled.main`
  width: 100%;
  display: block;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div<InputProps>`
  position: relative;

  input {
    width: 100%;
    height: 55px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  input[type='datetime-local']:before {
    display: ${({ value }) => (!value ? 'block' : 'none')};
    content: '날짜를 선택해주세요. YYYY-MM-DD HH:DD  ';
    color: ${({ theme }) => theme.color.subGray};
    font-weight: 500;
    width: 95%;
  }

  &.restrict {
    input[type='datetime-local']:before {
      display: block;
    }
  }
`;
