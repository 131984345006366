import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';
import { CustomProps } from '../../../definitions/common';
import useOutSideClick from '../../../hooks/useOutSideClick';

interface optionProps {
  text: string;
  value: string;
}

interface ListSelectProps {
  title: string;
  list: Array<optionProps>;
  onClick: (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => void;
  setState: React.Dispatch<React.SetStateAction<string>>;
  state?: any;
}

const ListSelect = (props: ListSelectProps) => {
  const { title, list, onClick, setState, state } = props;
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const toggling = () => setIsOpen(!isOpen);
  const onOptionClicked = (event: string) => {
    setSelectedOption(event);
    setIsOpen(false);
  };
  // 로컬스토리지에 저장된 옵션이 있으면 저장하기
  useEffect(() => {
    if (state) {
      setSelectedOption(state);
    }
  }, [state]);

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  return (
    <TableHead>
      <MenuList
        onClick={() => {
          toggling();
        }}
        ref={ref}
      >
        <OptionMenu className="option-menu">
          {selectedOption.length === 0 ? (
            <>{title}</>
          ) : (
            <TitleOption>{selectedOption}</TitleOption>
          )}
        </OptionMenu>
        {isOpen && (
          <DropDownList>
            {list?.map((option: optionProps) => (
              <ListItem
                title={option.value}
                onClick={(e) => {
                  onClick(e, setState);
                  onOptionClicked(option.text);
                }}
                key={Math.random()}
              >
                {selectedOption === option.text ? (
                  <TitleOption>{option.text}</TitleOption>
                ) : (
                  <>{option.text}</>
                )}
              </ListItem>
            ))}
          </DropDownList>
        )}
      </MenuList>
    </TableHead>
  );
};

export default ListSelect;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;

const MenuList = styled.div`
  display: inline-block;
  position: relative;
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ theme }) => theme.color.subBrightGray};
  z-index: 1;

  &.option-menu {
    padding-right: 32px;
    background: url(${bottomArrow}) calc(100% - 6px) center no-repeat
      ${({ theme }) => theme.color.subBrightGray};
    background-size: 22px;
  }
`;

const DropDownList = styled.ul`
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 100%;
  max-height: 360px;
  top: 12px;
  left: 50%;
  margin: auto;
  padding: 26px 10px 4px;
  background: #ffffff;
  overflow-y: scroll;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  z-index: 0;
  transform: translate(-50%, 0);
`;

const ListItem = styled.li<CustomProps>`
  margin: 12px auto;
  white-space: nowrap;
  font-size: ${({ type }) => type === 'searchMenu' && '14px'};
  cursor: pointer;
`;

const TitleOption = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;
