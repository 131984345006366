import React, { useState, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import ProfileImg from '../../../../components/common/image/ProfileImg';
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';
import SelectModule from '../../../../components/common/Select/SelectModule';
import VideoInput from '../../../../components/common/Input/VideoInput';
import { CustomProps } from '../../../../definitions/common';
import Table from '../../../../components/common/List/Table';
import { eventModalColList } from '../../../../constant/event';
import {
  EventModalRowComponent,
  SelectedEventRowComponent,
  SelectedVideoComponent,
} from '../../../operation/components/EventModalRowComponent';
import SearchMenuSelect from '../../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../../components/common/Input/SearchInput';
import Pagination from '../../../../components/common/Pagination';
import ListHead from '../../../../components/UI/ListHead';
import VideoModal from '../../../../components/common/Modal/VideoModal';
import useInput from '../../../../hooks/useInput';
import {
  ARTICLE,
  CONTENTS_MEMBER_COLLECTION,
  OFFICIAL_COLLECTION,
} from '../../../../constant/apiUrl';
import { getBeatList } from '../../../../redux/actions/beat/beat';
import { useAppSelector } from '../../../../redux/store/hooks';
import {
  beatBpmOptions,
  beatPrivateYnOptions,
  beatSearchTypeList,
} from '../../../../constant/beat';
import ListSelect from '../../../../components/common/Select/ListSelect';
import ListDateSelect from '../../../../components/common/Select/ListDateSelect';
import { RowComponentDataProps } from '../../../../definitions/beat';
import DetailAddBeatRowComponents from './DetailAddBeatRowComponents';
import {
  addArticleCollectionColList,
  addBeatCollectionColList,
  beatCollectionColList,
} from '../../../../constant/explore';
import { articleSearchTypeList } from '../../../../constant/video';
import FileInput from '../../../../components/common/Input/FileInput';

type collectionType = {
  coverImgFile: any;
  description: string;
  isPrivate: 'Y' | 'N';
  memberId: string;
  name: string;
  type: string;
  typeIds: Array<number>;
  id: number;
  nameEn: string;
  descriptionEn: string;
};

const initialData: collectionType = {
  coverImgFile: '',
  description: '',
  isPrivate: 'N',
  memberId: '',
  name: '',
  type: '',
  typeIds: [],
  id: 0,
  nameEn: '',
  descriptionEn: '',
};

const OfficialCollectionDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const detailData: any = location.state;
  const [collectionData, setCollectionData] = useState(initialData);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedContentList, setSelectedContentList] = useState<any>([]);
  const [coverImg, setCoverImg] = useState('');
  const [pageType] = useState(detailData?.data ? 'edit' : 'add');

  // Video modal  state
  const [articleList, setArticleList] = useState([]);
  const [searchType, setSearchType] = useState('');
  const [keyword, setKeyword, keywordReset] = useInput('');

  // Beat modal state
  const dispatch = useDispatch();
  const beatList = useAppSelector((state) => state.beat.beatList);
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const [playBeat, setPlayBeat] = useState('');
  const [word, setWord, wordReset] = useInput('');
  const [beatType, setBeatType] = useState<string>('');
  const [bpm, setBpm] = useState<string>('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);
  const [privateYn, setPrivateYn] = useState<string>('');
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');

  const {
    coverImgFile,
    description,
    isPrivate,
    type,
    memberId,
    name,
    id,
    nameEn,
    descriptionEn,
  } = collectionData;

  // pagination
  const postsPerPage = 10;
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const beatParams = {
    type: beatType,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/explore/collection/list', {
      state: { id: detailData?.officialId },
    });
  };

  const handleInputSetData = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setCollectionData({ ...collectionData, [e.target.name]: value });
    },
    [collectionData],
  );

  const handleSelectSetData = useCallback(
    (e) => {
      setCollectionData({ ...collectionData, type: e });
    },
    [collectionData],
  );

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const handleImgFile = useCallback(
    (e: string) => {
      setCollectionData({ ...collectionData, coverImgFile: e });
    },
    [collectionData],
  );

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (type === '플레이') {
        getArticleList();
      }
      if (type === '비트') {
        if (!beatType) {
          setBeatType('BMP');
          beatParams.type = 'BMP';
        }
        setPageNumber(0);
        beatParams.offset = 0;
        dispatch(getBeatList(beatParams));
      }
    }
  };

  // 컬렉션 저장하기, 수정하기
  const handleSaveCollection = async (reqType: string) => {
    const formData = new FormData();

    if (reqType === 'add') {
      if (coverImgFile !== undefined) {
        formData.append('coverImgFile', coverImgFile);
      }
      formData.append('description', description);
      formData.append('isPrivate', isPrivate === 'Y' ? 'true' : 'false');
      formData.append('name', name);
      formData.append('type', type === '플레이' ? 'AR' : 'BT');
      formData.append(
        'typeIds',
        selectedContentList.map((el: any) => el.id),
      );
      formData.append('nameEn', nameEn);
      formData.append('descriptionEn', descriptionEn);

      try {
        await axios.post(
          `${OFFICIAL_COLLECTION}/${process.env.REACT_APP_OFFICIAL_ACCOUNT_ID}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        alert('등록 되었습니다.');
        navigate('/explore/collection/list', {
          state: { id: detailData.officialId },
        });
      } catch (err) {
        console.log(err);
        alert('잠시후 다시 이용해주세요.');
      }
    }

    if (reqType === 'edit') {
      formData.append('coverImgFile', !coverImgFile ? null : coverImgFile);
      formData.append('description', description);
      formData.append('isPrivate', isPrivate === 'Y' ? 'true' : 'false');
      formData.append('name', name);
      formData.append(
        'contentsIdList',
        selectedContentList.map((el: any) => el.id),
      );
      formData.append('nameEn', nameEn);
      formData.append('descriptionEn', descriptionEn);

      try {
        await axios.post(
          `${OFFICIAL_COLLECTION}/${id}/update`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        );
        alert('수정 되었습니다.');
        navigate('/explore/collection/list', {
          state: { id: detailData.officialId },
        });
      } catch (err) {
        console.log(err);
        alert('잠시후 다시 이용해주세요.');
      }
    }
  };

  const closeModal = () => {
    setModal(false);
    setSelectedContentList([]);

    // article state 초기화
    setSearchType('');
    keywordReset();
    setArticleList([]);

    // beat state 초기화
    wordReset();
    setBeatType('');
    setBpm('');
    setMinBpm(0);
    setMaxBpm(250);
    setPrivateYn('');
    setMinStartDate('');
    setMaxStartDate('');

    setPageNumber(0);
  };

  // 공식 계정 영상 컬렉션 article detail list 불러오기
  const getOfficialCollectionArticleList = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${CONTENTS_MEMBER_COLLECTION}/article/${id}/contents`,
        { params: { limit: 1000, offset: 0 } },
      );
      const result = resp.data.contentsList;

      setSelectedContentList(
        result?.map((el: any) => ({
          id: el.id,
          coverImgUrl: el.contentsCoverImgUrl,
          beatTitle: el.beatTitle,
          memberPid: el.contentsMemberPid,
          memberName: el.contentsMemberName,
          uploadDt: el.contentsCreatedDt,
          privateYn: el.contentsPrivateYn,
          articleStatus: el.contentsReportType,
        })),
      );
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  // 영상 리스트 불러오기
  const getArticleList = useCallback(async () => {
    const videoParams = {
      offset: pageNumber * postsPerPage,
      limit: postsPerPage,
      keyword,
      searchType,
      articleType: 'NR',
    };
    try {
      const resp = await axios.get(ARTICLE, { params: videoParams });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [keyword, pageNumber, searchType]);

  const deleteVideo = (data: any) => {
    setSelectedContentList(
      selectedContentList.filter((e: any) => e.id !== data.id),
    );
  };

  const filterSelectedContent = (el: any) => {
    const result = selectedContentList.filter((item: any) => item !== el);
    setSelectedContentList(result);
  };

  const addSelectedContent = (item: any) => {
    if (selectedContentList?.find((e: any) => e.id === item.id)) {
      alert('중복 컨텐츠는 추가할 수 없습니다.');
    } else setSelectedContentList([...selectedContentList, item]);
  };

  useEffect(() => {
    if (modal && type === '플레이') {
      getOfficialCollectionArticleList();
      getArticleList();
    }
  }, [getArticleList, getOfficialCollectionArticleList, modal, type]);

  useEffect(() => {
    if (modal && type === '비트') {
      dispatch(getBeatList(beatParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNumber,
    dispatch,
    modal,
    type,
    maxBpm,
    minBpm,
    minStartDate,
    maxStartDate,
    privateYn,
  ]);

  useEffect(() => {
    switch (bpm) {
      case '':
        setMinBpm(0);
        setMaxBpm(250);
        break;
      case '49':
        setMinBpm(0);
        setMaxBpm(49);
        break;
      case '50':
        setMinBpm(50);
        setMaxBpm(80);
        break;
      case '80':
        setMinBpm(80);
        setMaxBpm(100);
        break;
      case '100':
        setMinBpm(100);
        setMaxBpm(140);
        break;
      case '140':
        setMinBpm(140);
        setMaxBpm(180);
        break;
      case '180':
        setMinBpm(180);
        setMaxBpm(230);
        break;
      default:
        setMinBpm(0);
        setMaxBpm(250);
        break;
    }
  }, [bpm]);

  useEffect(() => {
    if (detailData?.data) {
      setCollectionData({
        ...collectionData,
        type: detailData.data?.type === 'AR' ? '플레이' : '비트',
        isPrivate: detailData.data?.privateYn,
        name: detailData.data?.name,
        description: detailData.data?.description,
        id: detailData.data?.id,
        nameEn: detailData.data?.nameEn,
        descriptionEn: detailData.data?.descriptionEn,
      });
      setCoverImg(detailData.data?.coverImgUrl);

      if (detailData.data?.type === 'AR') {
        setSelectedContentList(
          detailData.data?.contentsList.map((el: any) => ({
            id: el.contentsId,
            coverImgUrl: el.contentsCoverImgUrl,
          })),
        );
      }
      if (detailData.beatCollectionList) {
        setSelectedContentList(
          detailData.beatCollectionList?.map((el: any) => ({
            id: el.contentsId,
            coverImgUrl: el.contentsCoverImgUrl,
            contentsUrl: el.contentsUrl,
            title: el.contentsTitle,
            bmPid: el.contentsMemberPid,
            genreNames: el.genreNames,
            bpm: el.bpm,
            key: el.beatKey,
            privateYn: el.contentsPrivateYn,
            startDt: el.contentsCreatedDt,
          })),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData]);

  useEffect(() => {
    if (pageType === 'add') {
      setSelectedContentList([]);
    }
  }, [pageType, type]);

  return (
    <>
      <TopContainer>
        <ProfileWrapper>
          {coverImg ? <ProfileImg url={coverImg} /> : <ProfileImg />}
          <ProfileTitleWrapper>
            <CollectionName>{name}</CollectionName>
            <BeatMaker>{memberId}</BeatMaker>
          </ProfileTitleWrapper>
        </ProfileWrapper>
        <ButtonWrapper>
          {id ? (
            <>
              <NormalButton
                text="취소"
                onSubmit={goBack}
                className="cancelBtn"
              />
              <NormalButton
                text="수정하기"
                onSubmit={() => {
                  handleSaveCollection('edit');
                }}
              />
            </>
          ) : (
            <>
              <NormalButton
                text="취소"
                onSubmit={goBack}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => {
                  handleSaveCollection('add');
                }}
              />
            </>
          )}
        </ButtonWrapper>
      </TopContainer>
      <InputContainer>
        <InputWrapper>
          <FileInput
            type="coverImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버"
            value={coverImgFile}
            setFile={handleImgFile}
            setImgUrl={setCoverImg}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="제목"
            value={name}
            placeholder="제목을 입력해주세요."
            onChange={handleInputSetData}
            // onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="nameEn"
              label="제목 EN"
              value={nameEn}
              placeholder="제목 EN을 입력해주세요."
              onChange={handleInputSetData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="설명"
            value={description}
            placeholder="설명을 입력해주세요."
            onChange={handleInputSetData}
            // onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="descriptionEn"
              label="설명 EN"
              value={descriptionEn}
              placeholder="설명 EN을 입력해주세요."
              onChange={handleInputSetData}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={isPrivate}
            name="isPrivate"
            label="공개 여부"
            onChange={handleInputSetData}
            type="privateYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          {detailData ? (
            <LabelTextInput
              name={type}
              value={type}
              label="컬렉션 종류"
              readOnly
            />
          ) : (
            <SelectModule
              name=''
              placeholder="컬렉션 종류를 선택해 주세요."
              value={type}
              label="컬렉션 종류"
              onChange={handleSelectSetData}
              options={['비트', '플레이']}
              Required
            />
          )}
        </InputWrapper>
      </InputContainer>
      <VideoContainer>
        {(type === '비트' || type === '플레이') && (
          <>
            <LineDiv />
            <VideoInput
              onClick={() => setModal(true)}
              text={`${type === '비트' ? '비트' : '영상'} 추가하기`}
            />
            {type === '플레이' ? (
              <SelectedVideoContainer>
                {selectedContentList?.map((el: any) => (
                  <SelectedVideoComponent
                    key={el.id}
                    data={el}
                    deleteVideo={deleteVideo}
                  />
                ))}
              </SelectedVideoContainer>
            ) : (
              <>
                <SpaceDiv value="20px" />
                <Table colList={beatCollectionColList} className="nonListTable">
                  {selectedContentList?.map((el: any) => (
                    <DetailAddBeatRowComponents
                      key={el.id}
                      data={el}
                      name="beat"
                      onClick={deleteVideo}
                      selected
                      className="fixList"
                    />
                  ))}
                </Table>
              </>
              // <>
              //   {selectedContentList?.map((el: any) => (
              //     <DetailAddBeatRowComponents data={el} />
              //   ))}
              // </>
            )}
            {modal && type === '플레이' && (
              <VideoModal
                closeModal={closeModal}
                className="beat"
                blockCloseBtn
                blockOutSideClick
              >
                <VideoModalWrapper>
                  <ModalButtonWrapper>
                    <NormalButton
                      text="취소"
                      onSubmit={closeModal}
                      className="cancelBtn"
                    />
                    <NormalButton
                      text="저장하기"
                      onSubmit={() => {
                        setModal(false);
                      }}
                    />
                  </ModalButtonWrapper>
                  <VideoModalTitle>선택 영상</VideoModalTitle>
                  {selectedContentList.length !== 0 ? (
                    <>
                      <TableTitle>
                        {selectedContentList.length}개 영상
                      </TableTitle>
                      <LineDiv />
                      <Table colList={eventModalColList}>
                        {selectedContentList?.map((el: any) => (
                          <SelectedEventRowComponent
                            key={el.id}
                            data={el}
                            onClick={() => deleteVideo(el)}
                          />
                        ))}
                      </Table>
                    </>
                  ) : (
                    <VideoModalSubText>
                      선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.
                      <LineDiv />
                    </VideoModalSubText>
                  )}
                  {selectedContentList.length === 0 && <EmptyDiv> </EmptyDiv>}
                  <SpaceDiv value="90px" />
                  <VideoModalTitle>영상 검색</VideoModalTitle>
                  <TitleWrapper>
                    <SearchWrapper>
                      <SearchMenuSelect
                        options={articleSearchTypeList}
                        onClick={handleSelect}
                        setState={setSearchType}
                      />
                      <SearchInputWrapper>
                        <SearchInput
                          value={keyword}
                          onChange={setKeyword}
                          onKeyPress={enterKeyPress}
                        />
                      </SearchInputWrapper>
                    </SearchWrapper>
                  </TitleWrapper>
                  <PaginationWrapper>
                    <TableTitle>{totalCount}개 영상</TableTitle>
                    {totalCount !== 0 && (
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalCount={totalCount}
                        currentPage={pageNumber}
                        pageDispatch={pageDispatch}
                      />
                    )}
                  </PaginationWrapper>
                  <LineDiv />
                  <Table
                    colList={addArticleCollectionColList}
                    thead={
                      <>
                        <ListHead title="번호" />
                        <ListHead title="썸네일" />
                        <ListHead title="Pid" />
                        <ListHead title="이름" />
                        <ListHead title="비트명" />
                        <ListHead title="업로드 날짜" />
                        <ListHead title="공개 여부" />
                        <ListHead title="상태" />
                        <ListHead title="" />
                      </>
                    }
                  >
                    {articleList?.map((el: any) => (
                      <EventModalRowComponent
                        key={el.id}
                        data={el}
                        onClick={() => addSelectedContent(el)}
                      />
                    ))}
                  </Table>
                </VideoModalWrapper>
              </VideoModal>
            )}
            {modal && type === '비트' && (
              <VideoModal
                closeModal={closeModal}
                className="beat"
                blockCloseBtn
                blockOutSideClick
              >
                <VideoModalWrapper>
                  <ModalButtonWrapper>
                    <NormalButton
                      text="취소"
                      onSubmit={closeModal}
                      className="cancelBtn"
                    />
                    <NormalButton
                      text="저장하기"
                      onSubmit={() => {
                        setModal(false);
                      }}
                    />
                  </ModalButtonWrapper>
                  <VideoModalTitle>선택 비트</VideoModalTitle>
                  {selectedContentList.length !== 0 ? (
                    <>
                      <TableTitle>
                        {selectedContentList.length}개 영상
                      </TableTitle>
                      <LineDiv />
                      <Table
                        colList={addBeatCollectionColList}
                        className="nonListTable"
                      >
                        {selectedContentList?.map((data: any) => (
                          <DetailAddBeatRowComponents
                            key={data.id}
                            data={data}
                            playBeat={playBeat}
                            setPlayBeat={setPlayBeat}
                            onClick={() => {
                              filterSelectedContent(data);
                            }}
                            selected
                            editable
                          />
                        ))}
                      </Table>
                    </>
                  ) : (
                    <>
                      <VideoModalSubText>
                        선택된 영상이 없습니다. 아래 검색 후 영상을
                        선택해주세요.
                      </VideoModalSubText>
                      <LineDiv />
                    </>
                  )}
                  <SpaceDiv value="90px" />
                  {selectedContentList.length === 0 && <EmptyDiv />}
                  <VideoModalTitle>비트 검색</VideoModalTitle>
                  <TitleWrapper>
                    <SearchWrapper>
                      <SearchMenuSelect
                        options={beatSearchTypeList}
                        onClick={handleSelect}
                        setState={setBeatType}
                      />
                      <SearchInputWrapper>
                        <SearchInput
                          value={word}
                          onChange={setWord}
                          onKeyPress={enterKeyPress}
                        />
                      </SearchInputWrapper>
                    </SearchWrapper>
                  </TitleWrapper>
                  <PaginationWrapper>
                    <TableTitle>{beatTotalCount}개 비트</TableTitle>
                    {beatTotalCount !== 0 && (
                      <Pagination
                        postsPerPage={postsPerPage}
                        totalCount={beatTotalCount}
                        currentPage={pageNumber}
                        pageDispatch={pageDispatch}
                      />
                    )}
                  </PaginationWrapper>
                  <LineDiv />
                  <Table
                    colList={addBeatCollectionColList}
                    thead={
                      <>
                        <ListHead title="Cover" />
                        <ListHead title="Title" />
                        <ListHead title="Pid" />
                        <ListHead title="장르" />
                        <ListSelect
                          title="BPM"
                          list={beatBpmOptions}
                          onClick={handleSelect}
                          setState={setBpm}
                        />
                        <ListHead title="BeatKey" />
                        <ListSelect
                          title="공개 여부"
                          list={beatPrivateYnOptions}
                          onClick={handleSelect}
                          setState={setPrivateYn}
                        />
                        <ListDateSelect
                          title="공개 날짜"
                          setMinStartDate={setMinStartDate}
                          setMaxStartDate={setMaxStartDate}
                        />
                        <ListHead title="" />
                      </>
                    }
                  >
                    {beatList?.map((el: RowComponentDataProps) => (
                      <DetailAddBeatRowComponents
                        key={el.id}
                        data={el}
                        onClick={() => {
                          addSelectedContent(el);
                        }}
                        playBeat={playBeat}
                        setPlayBeat={setPlayBeat}
                        editable
                      />
                    ))}
                  </Table>
                </VideoModalWrapper>
              </VideoModal>
            )}
          </>
        )}
      </VideoContainer>
    </>
  );
};

export default OfficialCollectionDetail;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 30px;
`;

const CollectionName = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  letter-spacing: -0.3px;
  line-height: 35px;
`;

const BeatMaker = styled.h2`
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 30px;
  letter-spacing: -0.3px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 60px;
  }
  &:nth-child(2) {
    grid-row: 2;
  }
  &:nth-child(3) {
    grid-row: 2;
  }
  &:nth-child(4) {
    grid-row: 3;
    margin-bottom: 60px;
  }
  &:nth-child(5) {
    grid-row: 3;
    margin-bottom: 60px;
  }
  &:nth-child(6) {
    grid-row: 4;
    grid-column: span 2;
    margin-bottom: 60px;
  }
  &:nth-child(7) {
    grid-row: 5;
    grid-column: span 2;
  }
`;

const VideoContainer = styled.div`
  //width: ;
`;

const EmptyDiv = styled.div`
  margin-top: 120px;
  height: 90px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SelectedVideoContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const SpaceDiv = styled.div<CustomProps>`
  height: ${({ value }) => value};
`;
