import {createAsyncThunk} from '@reduxjs/toolkit';
import axios from 'axios';
import {BEAT_LIST, BEATMAKER_LIST} from '../../../constant/apiUrl';

// 비트 받아오기
export const getBeatList = createAsyncThunk(
    'beat/getBeatList',
    async (params: object, {rejectWithValue}) => {
        try {
            const response = await axios.get(BEAT_LIST, {params});
            return response.data;
        } catch (error: any) {
            console.log(rejectWithValue(error.response.data));
            return rejectWithValue(error.response.data);
        }
    },
);

// 비트메이커 받아오기
export const getBeatMakerList = createAsyncThunk(
    'beat/beatMakerList',
    async (params: object, {rejectWithValue}) => {
        try {
            const response = await axios.get(BEATMAKER_LIST, {params});
            return response.data;
        } catch (error: any) {
            console.log(rejectWithValue(error.response));
            return rejectWithValue(error.response);
        }
    },
);
