import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// component
import LabelTextAreaInput from '../../components/common/Input/LabelTextAreaInput';
import NormalButton from '../../components/common/Button/NormalButton';
import FileInput from '../../components/common/Input/FileInput';
import ProfileImg from '../../components/common/image/ProfileImg';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import RadioButton from '../../components/common/Button/RadioButton';
import { SelectedEventRowComponent } from './components/EventModalRowComponent';

// config
import { PUSH } from '../../constant/apiUrl';

// hook
import { useAppSelector } from '../../redux/store/hooks';
import SelectModule from '../../components/common/Select/SelectModule';
import VideoInput from '../../components/common/Input/VideoInput';
import PushForArticle from './components/PushForArticle';
import PushForBeat from './components/PushForBeat';
import PushForEvent from './components/PushForEvent';
import PushForMember from './components/PushForMember';
import DetailAddBeatRowComponents from '../explore/components/category/DetailAddBeatRowComponents';
import SearchMemberRowComponent from '../member/components/SearchMemberRowComponent';
import PushForEventRowComponent from './components/PushForEventRowComponent';
import Table from '../../components/common/List/Table';
import PushForMagazine, {PushForMagazineRowComponent} from "./components/PushForMagazine";

const Push = () => {
  const adminAccount = useAppSelector((state) => state.user.user);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [pushImg, setPushImg] = useState('');
  const [imgFile, setImgFile] = useState<Blob | null>();
  const [receiveNightTimeInfoYn, setReceiveNightTimeInfoYn] = useState('N');
  const [os, setOS] = useState('ALL');
  const [modal, setModal] = useState<boolean>(false);
  const [navigateType, setNavigateType] = useState('');
  const [selectedModalObj, setSelectedModalObj] = useState<any>({});
  const [selectedModalType, setSelectedModalType] = useState<any>({});

  const closeModal = () => {
    setModal(false);

    // // article state 초기화
    // setSearchType('');
    // keywordReset();
    // setArticleList([]);
    //
    // // beat state 초기화
    // wordReset();
    // setBeatType('');
    // setBpm('');
    // setMinBpm(0);
    // setMaxBpm(250);
    // setPrivateYn('');
    // setMinStartDate('');
    // setMaxStartDate('');
  };

  const handleTextUrl = (e: any) => {
    setMessage(e.target.value);
  };

  const requestPurge = async () => {
    const imgMaxSize = 3 * 1024 * 100;
    const formData = new FormData();
    // const checkNums = /[0-9]/;

    if (!message) {
      alert('푸시 내용을 입력해주세요.');
      return;
    }
    if (imgFile && imgFile?.size > imgMaxSize) {
      alert('이미지는 300KB를 넘을 수 없습니다.');
      return;
    }

    if (imgFile && imgFile?.size < imgMaxSize) {
      formData.append('imageFile', imgFile);
      formData.append('message', message);
      formData.append('pushType', 'AD');
      formData.append('receiveNightTimeInfoYn', receiveNightTimeInfoYn);
      formData.append('senderName', adminAccount.name);
      formData.append('title', title);
      formData.append('os', os);

      if (selectedModalObj && selectedModalType) {
        formData.append('type', selectedModalType);
        formData.append('typeId', selectedModalObj?.id);
      }

      try {
        await axios.post(PUSH, formData);
        alert('등록되었습니다.');
      } catch (err) {
        console.log(err);
      }
    }
    if (!imgFile) {
      formData.append('imageFile', '');
      formData.append('message', message);
      formData.append('pushType', 'AD');
      formData.append('receiveNightTimeInfoYn', receiveNightTimeInfoYn);
      formData.append('senderName', adminAccount.name);
      formData.append('title', title);
      formData.append('os', os);
      if (selectedModalObj && selectedModalType) {
        formData.append('type', selectedModalType);
        formData.append('typeId', selectedModalObj?.id);
      }

      try {
        await axios.post(PUSH, formData);
        alert('등록되었습니다.');
      } catch (err) {
        console.log(err);
      }
    }

    // if (!checkNums.test(eventId)) {
    //   alert('이벤트아이디는 숫자만 입력할 수 있습니다.');
    // }
  };

  const handleImgFile = useCallback((e: any) => {
    setImgFile(e);
  }, []);

  const handleInputData = useCallback((e: any) => {
    const { value } = e.target;
    setTitle(value);
  }, []);

  const handleNavigateType = useCallback((e) => {
    setNavigateType(e);
  }, []);

  const handleRadioData = useCallback((e) => {
    setReceiveNightTimeInfoYn(e.target.value);
  }, []);

  const handleOS = useCallback((e) => {
    setOS(e.target.value);
  }, []);

  useEffect(() => {
    if (navigateType === '선택안함') {
      setSelectedModalObj(null);
      setSelectedModalType(null);
    }
  }, [navigateType]);

  return (
    <MainContainer>
      <InputWrapper>
        <FileWrapper>
          <FileInput
            type="push"
            placeholder={
              pushImg
                ? '파일이 등록되었습니다.'
                : '파일을 선택해주세요. (용량 300KB 미만)'
            }
            label="푸시 이미지"
            value={imgFile}
            name="imgFile"
            setFile={handleImgFile}
            setImgUrl={setPushImg}
          />
        </FileWrapper>
        <PushImgWrapper>
          {pushImg ? <ProfileImg url={pushImg} /> : <ProfileImg />}
        </PushImgWrapper>
      </InputWrapper>
      <InputContainer>
        <InputWrapper>
          <LabelTextInput
            name="title"
            label="타이틀"
            value={title}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextAreaInput
            name="push"
            label="푸시 내용 입력"
            value={message}
            placeholder="내용을 입력해주세요."
            setValue={handleTextUrl}
            Required
          />
        </InputWrapper>
        <InputWrapper style={{ display: 'block' }}>
          <RadioButton
            value={os}
            name="selectOS"
            label="OS 선택"
            onChange={handleOS}
            text={['전체', 'iOS(아이폰)', 'aOS(안드로이드)']}
            type="os"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={receiveNightTimeInfoYn}
            name="receiveNightTimeInfoYn"
            label="푸시 전송 시간대"
            onChange={handleRadioData}
            text={['주간', '심야']}
            type="privateYn"
            Required
          />
        </InputWrapper>
        {/*<InputWrapper>*/}
        {/*  <LabelTextInput*/}
        {/*    type="id"*/}
        {/*    name="eventId"*/}
        {/*    label="이벤트 고유Id"*/}
        {/*    value={eventId}*/}
        {/*    placeholder="이벤트 아이디를 입력해주세요."*/}
        {/*    onChange={handleEventId}*/}
        {/*    Required*/}
        {/*  />*/}
        {/*</InputWrapper>*/}
        <InputWrapper>
          <SelectModule
            id="push"
            name="navigateType"
            placeholder="네비게이션 타입을 정해주세요."
            value={navigateType}
            label="네비게이션 타입"
            onChange={handleNavigateType}
            options={['선택안함', '영상', '비트', '멤버', '이벤트', '매거진']}
          />
        </InputWrapper>
        {navigateType !== '선택안함' && (
          <>
            <LineDiv />
            <VideoInput
              onClick={() => setModal(true)}
              text={`${navigateType} 추가하기`}
            />
          </>
        )}
      </InputContainer>
      <TableContainer>
        <Table className="nonListTable">
          {selectedModalType === 'AR' && selectedModalObj && (
            <SelectedEventRowComponent data={selectedModalObj} type="preview" />
          )}
          {selectedModalType === 'BT' && selectedModalObj && (
            <DetailAddBeatRowComponents
              data={selectedModalObj}
              type="preview"
            />
          )}
          {selectedModalType === 'MB' && selectedModalObj && (
            <SearchMemberRowComponent
              data={selectedModalObj}
              listType="preview"
            />
          )}
          {selectedModalType === 'EV' && selectedModalObj && (
            <PushForEventRowComponent data={selectedModalObj} type="preview" />
          )}
          {selectedModalType === 'MAGAZINE' && selectedModalObj && (
              <PushForMagazineRowComponent data={selectedModalObj}
                                           onClick={''} selected={''} />
          )}
        </Table>
      </TableContainer>
      <ButtonContainer>
        <NormalButton
          text="등록하기"
          onSubmit={() => {
            requestPurge();
          }}
          className=""
        />
      </ButtonContainer>
      {modal && navigateType === '영상' && (
        <PushForArticle
          closeModal={closeModal}
          setSelectedModalObj={setSelectedModalObj}
          setSelectedModalType={setSelectedModalType}
        />
      )}
      {modal && navigateType === '비트' && (
        <PushForBeat
          closeModal={closeModal}
          setSelectedModalObj={setSelectedModalObj}
          setSelectedModalType={setSelectedModalType}
        />
      )}
      {modal && navigateType === '멤버' && (
        <PushForMember
          closeModal={closeModal}
          setSelectedModalObj={setSelectedModalObj}
          setSelectedModalType={setSelectedModalType}
        />
      )}
      {modal && navigateType === '이벤트' && (
        <PushForEvent
          closeModal={closeModal}
          setSelectedModalObj={setSelectedModalObj}
          setSelectedModalType={setSelectedModalType}
        />
      )}
      {modal && navigateType === '매거진' && (
          <PushForMagazine
              closeModal={closeModal}
              setSelectedModalObj={setSelectedModalObj}
              setSelectedModalType={setSelectedModalType}
          />
      )}
    </MainContainer>
  );
};

export default Push;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
`;

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
`;

const FileWrapper = styled.div`
  width: 60%;
  height: 100%;
`;

const PushImgWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 28px;
`;

const InputContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 40px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TableContainer = styled.div`
  z-index: 1;
`;
