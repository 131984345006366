import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

const ListHeadWithSort = ({
  title,
  className,
  sortKey,
  selectedSortKey,
  selectedSortDirection,
  onClick
}) => {
  const [changeableTitle, setChangeableTitle] = useState(title);

  useEffect(()=>{
    if (selectedSortKey === sortKey) {
      if (selectedSortDirection === 'ASC') {
        setChangeableTitle(title + " ▲");
      } else if (selectedSortDirection === 'DESC') {
        setChangeableTitle(title + " ▼");
      } else {
        setChangeableTitle(title);
      }
    } else {
      setChangeableTitle(title);
    }
  },[selectedSortKey, selectedSortDirection])

  return (
    <TableHead>
      <OptionMenu className={className}
          onClick={() => {
            let sortDirection = 'ASC';
            // 정렬 컬럼이 동일한 경우
            if (selectedSortKey === sortKey) {
              // 이전 타입에 따라 다음으로 변경
              if (selectedSortDirection === 'ASC') {sortDirection = 'DESC';}
              else if(selectedSortDirection === 'DESC') {sortDirection = '';}
              else {sortDirection = 'ASC';}
            }
            onClick(sortKey, sortDirection);
          }}
      >
        {changeableTitle}
      </OptionMenu>
    </TableHead>
  );
};
export default ListHeadWithSort;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ title, theme }) =>
    title === '' ? theme.color.mainWhite : theme.color.subBrightGray};
  z-index: 1;
  cursor: pointer;

  &.alignLeft {
    float: left;
  }
  &.marginRight {
    margin-right: 24px;
  }
`;
