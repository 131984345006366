import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import RoundButton from '../../components/common/Button/RoundButton';
import NormalButton from '../../components/common/Button/NormalButton';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ManagementRowComponent from './components/ManagementRowComponent';

// config
import { REPORT_MANAGEMENT_LIST } from '../../constant/apiUrl';

//constant
import { managementColList } from '../../constant/operation';

const Management = () => {
  const navigate = useNavigate();
  const [reportList, setReportList] = useState([]);
  const [type, setType] = useState('컨텐츠');
  const [contentsType, setContentsType] = useState('CT');

  // 신고 유형 관리 type 별 조회
  const getReportList = useCallback(async (params) => {
    try {
      const resp = await axios.get(REPORT_MANAGEMENT_LIST, {
        params,
      });
      const resultList = resp.data.masterReportList;
      setReportList(resultList);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  }, []);

  // 상단 버튼 클릭 이벤트
  const handleButton = (e: any) => {
    const { title } = e.target;
    setType(title);
    if (title === '컨텐츠') {
      setContentsType('CT');
    } else {
      setContentsType('MB');
    }
  };

  useEffect(() => {
    const params = {
      contentsType,
      // 모든 언어 검색
      language: null,
      limit: 40,
      offset: 0,
    };
    getReportList(params);
  }, [contentsType, getReportList]);

  return (
    <>
      <ButtonWrapper>
        <NormalButton
          text="항목 수정하기"
          onSubmit={() => {
            navigate('/operation/management/edit');
          }}
          className="blockBtn"
        />
        <NormalButton
          text="항목 추가하기"
          onSubmit={() => {
            navigate('/operation/management/add');
          }}
        />
      </ButtonWrapper>
      <ButtonContainer>
        <RoundButton
          title="컨텐츠"
          selected={type === '컨텐츠' && true}
          onClick={handleButton}
        />
        <RoundButton
          title="유저"
          selected={type === '유저' && true}
          onClick={handleButton}
        />
      </ButtonContainer>
      <TopContainer>
        <PaginationWrapper>{/*<Pagination />*/}</PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={managementColList}
          thead={
            <>
              <ListHead title="신고 항목" />
              <ListHead title="세부 내용" />
              <ListHead title="벌점" />
              <ListHead title="활성화 여부" />
            </>
          }
        >
          {reportList?.map((el: any) => (
            <ManagementRowComponent key={el.id} data={el} />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default Management;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;
