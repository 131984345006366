import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useRef, useState} from "react";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import axios from "axios";

import NormalButton from "../../../components/common/Button/NormalButton";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import {
  ADVERTISEMENT, ADVERTISEMENT_CAMPAIGN, ADVERTISEMENT_LANDING,
} from "../../../constant/apiUrl";
import FileInput from "../../../components/common/Input/FileInput";
import RadioButton from "../../../components/common/Button/RadioButton";
import DateInput from "../../../components/common/Input/DateInput";
import {
  campaignTypes,
  GetCountryOption,
  notContainsAllCountryOptions
} from "../../../constant/common";
import AdvertisementSelector from "../component/AdvertisementSelector";
import LabelTextAreaInput
  from "../../../components/common/Input/LabelTextAreaInput";
import SelectModule from "../../../components/common/Select/SelectModule";
import * as commonFn from "../../../utils/commonFn";
import NormalButtonWithLabel from "../component/NormalButtonWiteLabel";
import MultiSelect from "../../../components/common/Select/MultiSelect";
import handleDate from "../../../hooks/handleDate";

// 광고 상세 화면(추가, 수정)
const AdvertisementCampaignDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const _videoRef = useRef();

  const _imgFileInputRef = useRef();
  const _movieFileInputRef = useRef();

  // 목록 화면에서 전달 받은 값. 여기서는 캠페인 아이디
  const detailData = location.state;
  const advertisementCampaignId = detailData !== null ? detailData.advertisementCampaignId : null;

  // 광고 속성 //////////////////////////////////////////////////////////////////
  const [advertisement, setAdvertisement] = useState(null);
  const [advertisementList, setAdvertisementList] = useState([]);
  const [advertisementTitle, setAdvertisementTitle] = useState('');
  const [advertisementStrId, setAdvertisementStrId] = useState('');
  const [advertisementStartDt, setAdvertisementStartDt] = useState('');
  const [advertisementEndDt, setAdvertisementEndDt] = useState('');
  const [advertisementMemo, setAdvertisementMemo] = useState('');
  const [advertisementCountries, setAdvertisementCountries] = useState('');

  // 광고 캠페인 속성 ///////////////////////////////////////////////////////////
  // 광고 캠페인 Str Id
  // const [advertisementCampaignStrId, setAdvertisementCampaignStrId] = useState('');
  // 광고 Id
  const [advertisementId, setAdvertisementId] = useState();
  // 이동 유형
  const [linkType, setLinkType] = useState('');
  // 외부 URL
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  // 광고 랜딩 Id
  const [advertisementLandingId, setAdvertisementLandingId] = useState();
  // 지면 유형
  const [campaignType, setCampaignType] = useState('');
  // 소재 유형
  const [componentType, setComponentType] = useState('IMAGE');
  // 이미지 URL
  const [bannerImgUrl, setBannerImgUrl] = useState('');
  const [bannerImgFile, setBannerImgFile] = useState();
  // 동영상 URL
  const [bannerMovieUrl, setBannerMovieUrl] = useState('');
  const [bannerMovieFile, setBannerMovieFile] = useState('');
  // 개시일
  const [startDt, setStartDt] = useState('');
  // 종료일
  const [endDt, setEndDt] = useState('');
  // 국가 코드
  const [countriesSelected, setCountriesSelected] = useState([]);
  // 광고 캠페인 식별 제목
  const [title, setTitle] = useState('');
  // 메모
  const [memo, setMemo] = useState('');
  //유저 선택시 비 표시할 일 수
  const [hideDays, setHideDays] = useState(0);
  // 팝업 버튼 노출 여부
  const [popupBtnYn, setPopupBtnYn] = useState('N');
  // 팝업 버튼 명
  const [popupBtnName, setPopupBtnName] = useState('');
  // 팝업 버튼 명 EN
  const [popupBtnNameEn, setPopupBtnNameEn] = useState('');
  // 공개 여부
  const [activeYn, setActiveYn] = useState('N');

  const [titleCheckResult, setTitleCheckResult] = useState('');

  //활성화 된 광고 목록 조회 ////////////////////////////////////////////////////
  const getActiveAdvertisements = async() => {
    await axios.get(`${ADVERTISEMENT}-active`, {params:{offset: 0, limit: 100,}})
    .then(res => {
      setAdvertisementList(res.data.adminAdvertisementList);
      // console.log('AdvertisementLanding > getActiveAdvertisements > adminAdvertisementList:',res.data.adminAdvertisementList);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  useEffect(() => {
    getActiveAdvertisements().then(() => {})
  }, []);

  // 광고 정보 변경시 설정
  const handleAdvertisement = (e) => {
    // console.log('AdvertisementCampaignDetail > handleAdvertisement > e:', e);
    if(!advertisementId || confirm(`아래 광고캠페인 데이터도 함께 변경됩니다. 광고id를 변경하시겠습니까?`)) {
      const countrySelected = setAdvertisementInfo(e);
      setCountriesSelected(countrySelected);
      setStartDt(handleDateForUpdate(e.startDt, false , true).slice(0,16));
      setEndDt(handleDateForUpdate(e.endDt, false, false).slice(0,16));
    }
  };
  // 광고 정보 설정
  const setAdvertisementInfo = (advertisementDetail) => {
    setAdvertisement(advertisementDetail);
    setAdvertisementId(advertisementDetail.advertisementId);
    setAdvertisementStrId(advertisementDetail.advertisementStrId);
    setAdvertisementTitle(advertisementDetail.title);
    setAdvertisementStartDt(advertisementDetail.startDt);
    setAdvertisementEndDt(advertisementDetail.endDt);
    let countrySelected = advertisementDetail.countries.map(country => {
          return GetCountryOption[country];
        }
    );
    setAdvertisementCountries(countrySelected.join(","));
    setAdvertisementMemo(advertisementDetail.memo);
    return countrySelected;
  }

  // 광고 랜딩 속성 /////////////////////////////////////////////////////////////
  const [advertisementLandingList, setAdvertisementLandingList] = useState([]);
  const [advertisementLandingTitle, setAdvertisementLandingTitle] = useState('');
  //광고 랜딩 데이터 조회 ///////////////////////////////////////////////////////
  const getAdvertisementLandings = async() => {
    await axios.get(`${ADVERTISEMENT_LANDING}-by-advertisement`, {
      params: {advertisementId, offset: 0, limit: 100}
    })
    .then(res=>{
      setAdvertisementLandingList(res.data.adminAdvertisementLandingList);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  };
  useEffect(()=>{
    if(advertisementId) getAdvertisementLandings().then();
  }, [advertisementId]);

  // 랜딩 정보 변경시 설정
  const handleAdvertisementLanding = (e) => {
    setAdvertisementLandingId(e.advertisementLandingId);
    setAdvertisementLandingTitle(`${e.title}(${e.advertisementLandingStrId})`);
  };

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    let retVal = null;
    await axios.get(
        `${ADVERTISEMENT_CAMPAIGN}/${advertisementCampaignId}`,
    )
    .then(res => {
      const detail = res.data.adminAdvertisementCampaign;
      // console.log('AdvertisementDetail > getDetail > detail:',detail);

      // setAdvertisementCampaignStrId(detail.advertisementCampaignStrId);
      setAdvertisementId(detail.advertisementId);
      setLinkType(detail.linkType);
      setExternalLinkUrl(detail.externalLinkUrl);
      setAdvertisementLandingId(detail.advertisementLandingId);
      setCampaignType(detail.campaignType);
      setComponentType(detail.componentType);
      setBannerImgUrl(detail.bannerImgUrl);
      setBannerMovieUrl(detail.bannerMovieUrl);
      setStartDt(handleDateForUpdate(detail.startDt, false , true).slice(0,16));
      setEndDt(handleDateForUpdate(detail.endDt, false, false).slice(0,16));
      setCountriesSelected( detail.countries.map(country => { return GetCountryOption[country]; }));
      setTitle(detail.title);
      setMemo(detail.memo);
      setHideDays(detail.hideDays || 0);
      setPopupBtnYn(detail.popupBtnYn || 'N');
      setPopupBtnName(detail.popupBtnName);
      setPopupBtnNameEn(detail.popupBtnNameEn);
      setActiveYn(detail.activeYn);

      // 내부 이동 유형이면
      if (detail.linkType === 'INTERNAL') {
        // 랜딩 제목 설정
        setAdvertisementLandingTitle(`${detail.adminAdvertisementLanding.title}(${detail.adminAdvertisementLanding.advertisementLandingStrId})`);
      }

      // setAdvertisementInfo(advertisementList.filter(ad=>ad.advertisementId===detail.advertisementId)[0]);
      retVal = detail.advertisementId;
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
    return retVal;
  };
  // (호출 순서 보장을 위해) 활성화된 광고 목록에 변화가 있으면 캠페인 상세 조회
  useEffect(() => {
    // 수정일 때만
    if (advertisementCampaignId) {
      // 캠페인 상세 조회
      getDetail().then((_advertisementId) => {
        // 캠페인의 광고 상세를 조회하고,
        getAdvertisementDetail(_advertisementId).then();
      });
    }
  }, [advertisementList]);

  // 광고 상세 조회
  const getAdvertisementDetail = async (_advertisementId) => {
    await axios.get(
        `${ADVERTISEMENT}/${_advertisementId}`,
    )
    .then(res => {
      // 광고 정보 설정
      setAdvertisementInfo(res.data.adminAdvertisement);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };

  // 활성화된 광고 목록이나 캠페인의 광고에 변화가 있을때
  useEffect(()=>{
    // 활성화된 광고 목록이 없고, 사용된 광고가 있을때
    if ((!advertisementList || advertisementList.length === 0) && advertisement) {
      // 이 캠페인의 광고를 활성화 된 광고 목록으로 설정
      setAdvertisementList([advertisement]);
    }
  }, [advertisementList, advertisement]);

  // 정보 저장 //////////////////////////////////////////////////////////////////

  // 국가 선택시
  const processSelectedCategory = (_countryName) => {
    // 선택된 국가에 존재하면
    if (countriesSelected?.indexOf(_countryName) !== -1) {
      // 제거
      setCountriesSelected(countriesSelected?.filter((el) => el !== _countryName));
      return;
    }
    // 존재하지 않으면 추가
    setCountriesSelected((prev) => [...prev, _countryName]);
  };

  // 데이트 저장시 사용
  const handleDateForUpdate = (date, tRemove, start) => {
    let _date = date;

    if (tRemove && date.indexOf('T') < 0) {
      _date = date.split(' ');
      _date = _date[0] + 'T' + _date[1];
    }
    if (!tRemove && date.indexOf('T') > 0) {
      _date = date.split('T');
      _date = _date[0] + ' ' + _date[1];
    }

    return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
  }

  // n일간 보지 않기 값 변경시
  const handleHideDays = (_hideDays) => {
    setHideDays(_hideDays);
    (_hideDays < 0) && setHideDays(0);
    ( 10 < _hideDays) && setHideDays(10);
  }

  // 지면 유형 변경시
  const handleCampaignType = (_campaignType)=>{
    if(!campaignType || confirm(`광고캠페인 정보에 입력된 값이 삭제됩니다. 삭제하시겠습니까?`)) {
      if(campaignType) {
        setStartDt('');
        setEndDt('');
        setCountriesSelected([]);
      }
      setComponentType('');
      setBannerImgUrl('');
      setBannerImgFile(null);
      setBannerMovieUrl('');
      setBannerMovieFile(null);
      setTitle('');
      setMemo('');
      setActiveYn('N');
      setCampaignType(_campaignType);
      setHideDays(0);
      setPopupBtnYn('N');
      setPopupBtnName('');
      setPopupBtnNameEn('');

      if(_campaignType !== 'ML') {
        setComponentType('IMAGE');
      }
    }
  }

  // 저장
  const saveDetail = async () => {
    // 파라미터 체크
    if(!parameterCheck()) return;

    if(!confirm('광고 캠페인을 저장하시겠습니까?')) {
      return;
    }

    //광고 캠페인 식별 제목 중복 체크
    const params = {title};
    // 광고캠페인 식별 제목
    // 수정인 경우에는 화면 값 그대로 설정
    if(advertisementCampaignId) {
      params.advertisementCampaignId = advertisementCampaignId;
    }
    await axios.get(`${ADVERTISEMENT_CAMPAIGN}/title-duplication-check`, {params})
    .then(()=>{
      setTitleCheckResult('');
      // 체크 처리 후 정상 일 때 보존 처리 호출
      executeSaveDetail();
    })
    .catch (err => {
      // console.log('AdvertisementLandings > deleteSelected > err.response:', err.response);
      if (err.response.status === 418) {
        alert('중복된 광고 캠페인 식별 제목이 있습니다.');
        setTitleCheckResult('중복된 광고 캠페인 식별 제목이 있습니다.');
      }
      else {
        alert('문제가 발생하였습니다. 담당자에게 문의해주세요.'+ err.response.data.message);
      }
    });
  };

  // 저장 처리 호출 전 필수 입력값 체크
  const parameterCheck = () => {

    let notEnteredItems = [];
    (!advertisementId ) && notEnteredItems.push('- 광고 id');
    (!linkType) && notEnteredItems.push('- 이동 유형');
    ((linkType==='INTERNAL') && !advertisementLandingId) && notEnteredItems.push('- 랜딩페이지ID');
    ((linkType==='EXTERNAL') && !externalLinkUrl) && notEnteredItems.push('- [외부] URL');
    (!campaignType ) && notEnteredItems.push('- 지면 유형');
    (!componentType) && notEnteredItems.push('- 소재 유형');
    if (
        (!bannerImgUrl && !bannerImgFile && !bannerMovieUrl && !bannerMovieFile)
        || ((componentType==='IMAGE') && (!bannerImgUrl && !bannerImgFile))
        || ((componentType==='MOVIE') && (!bannerMovieUrl && !bannerMovieFile))
    ) notEnteredItems.push('- 소재 등록');
    (!startDt ) && notEnteredItems.push('- 노출 시작일시');
    (!endDt ) && notEnteredItems.push('- 노출 종료일시');
    (!countriesSelected || countriesSelected.length === 0) && notEnteredItems.push('- 광고 노출 국가');
    (advertisementCampaignId && !title ) && notEnteredItems.push('- 광고캠페인 식별제목');
    if (notEnteredItems.length !== 0) {
      notEnteredItems = ['아래 입력값을 확인해주세요', ...notEnteredItems];
      alert(notEnteredItems.join('\n'));
      return false;
    }

    if (new Date(advertisementEndDt) < new Date(startDt)) {
      alert('노출 시작 일시는 연동된 광고의 노출 종료일시 이전이어야 합니다.');
      return false;
    }
    if (new Date(endDt) < new Date() || new Date(advertisementEndDt) < new Date(endDt) || new Date(endDt) < new Date(startDt) ) {
      alert('노출 종료 일시는 오늘 이후 부터 연동된 광고의 노출 종료일시 이전까지이며, 해당 광고캠페인의 노출 시작일시보다 이후여야 합니다.');
      return false;
    }
    return true;
  }

  // 저장 처리 호출
  const executeSaveDetail = async () => {
    const formData = new FormData();
    // 광고 Id
    formData.append('advertisementId', advertisementId);
    // 이동 유형
    formData.append('linkType', linkType);
    // 외부 URL
    (linkType==='EXTERNAL') && formData.append('externalLinkUrl', externalLinkUrl);
    // 광고 랜딩 Id
    (linkType==='INTERNAL') && formData.append('advertisementLandingId', advertisementLandingId);
    // 지면 유형
    formData.append('campaignType', campaignType);
    // 소재 유형
    formData.append('componentType', componentType);
    // 이미지 URL
    if (bannerImgFile != null) formData.append('bannerImgFile', bannerImgFile);
    // 동영상 URL
    if (bannerMovieFile != null) formData.append('bannerMovieFile', bannerMovieFile);
    // 개시일
    formData.append('startDate', handleDateForUpdate(startDt, false , true));
    // 종료일
    formData.append('endDate', handleDateForUpdate(endDt, false, false));
    // 국가
    let tmpCountries = [];
    notContainsAllCountryOptions.forEach((countryOption)=>{
      if(countriesSelected.includes(countryOption.name) ) {
        tmpCountries.push(countryOption.value);
      }
    })
    formData.append('countries', tmpCountries);
    // 광고캠페인 식별 제목
    // 수정인 경우에는 화면 값 그대로 설정
    if(advertisementCampaignId) {
      formData.append('title', title);
    }
    // 신규인 경우에는 프리픽스 작성하여 보내기
    else {
      // ◾ 연동된 광고 및 선택된 지면 유형이 없는 경우, {제목없는 광고캠페인}_{순서번호} 형태로 제목 생성됨
      // 단 비공개의 경우에도 필수 체크를 수행하므로, 이 경우는 없을것
      if((!advertisementId || advertisementId === 0) && (!campaignType)) {
        formData.append('title', '제목없는 광고캠페인');
      }
      else {
        // ◾ 양식: {연동된 광고 식별제목}_{지면 유형 이름}_{순서번호}
        // 	--> 예시: 이마트24 맥주 프로모션_바운드골드 배너A_1
        let work = [];
        // ◾ 연동된 광고가 없는 경우, {연동된 광고 식별제목} 값 없이 제목 생성됨
        if(advertisementTitle) {
          work.push(advertisementTitle);
        }
        // ◾ 선택된 지면 유형이 없는 경우, {지면 유형 이름} 값 없이 제목 생성됨
        if (campaignType) {
          work.push(campaignTypes[campaignType]);
        }
        formData.append('title', work.join('_'));
      }
    }
    // 메모
    formData.append('memo', memo);
    formData.append('hideDays', hideDays);
    formData.append('popupBtnYn', popupBtnYn);
    formData.append('popupBtnName', popupBtnName);
    formData.append('popupBtnNameEn', popupBtnNameEn);

    // 공개 여부
    formData.append('activeYn', activeYn);

    dispatch(setLoading());
    try {
      (advertisementCampaignId)
          ? await axios.put(`${ADVERTISEMENT_CAMPAIGN}/${advertisementCampaignId}`, formData)
          : await axios.post(`${ADVERTISEMENT_CAMPAIGN}`, formData, {});
      alert('저장 완료');
      navigate('/advertisement/advertisementCampaign');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 이미지 파일 첨부 버튼 클릭시
  const handleBannerImg = () => {
    if(
        // 파일이 선택되어 있지 않거나
        (!bannerImgUrl && !bannerImgFile)
        // 확인을 받은 경우에는
        || confirm(`첨부된 소재가 있습니다. 소재를 교체하시겠습니까?`)
    ) {
      // 파일 인풋의 이벤트 kick
      _imgFileInputRef.current.click();
    }
  }
  // 영상 파일 첨부 버튼 클릭시
  const handleBannerMovie = () => {
    if(
        // 파일이 선택되어 있지 않거나
        (!bannerMovieUrl && !bannerMovieFile)
        // 확인을 받은 경우에는
        || confirm(`첨부된 소재가 있습니다. 소재를 교체하시겠습니까?`)
    ) {
      // 파일 인풋의 이벤트 kick
      _movieFileInputRef.current.click();
    }
  }

  // 삭제
  const deleteSelected = async () => {
    if(!advertisementCampaignId || advertisementCampaignId === 0) {return;}
    if (!confirm('광고캠페인이 삭제됩니다. 삭제하시겠습니까?')) {
      return;
    }

    await axios.delete(`${ADVERTISEMENT_CAMPAIGN}/${advertisementCampaignId}`, {})
    .then(() => {
      alert('삭제 완료');
      navigate('/advertisement/advertisementCampaign');
    })
    .catch (err => {
      const errorMessage = (err.response.status === 418)
          ? '종료된 캠페인은 삭제할 수 없습니다.'
          : '문제가 발생하였습니다. 담당자에게 문의해주세요.';
      console.log('AdvertisementLandings > deleteSelected > err.response:', err.response);
      alert(errorMessage);
    });
  };

  return <>
    <TitleText>광고캠페인 {advertisementCampaignId ? '상세':'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper>
        {advertisementCampaignId && (
          <NormalButton
            text={"삭제"}
            className={(!advertisementCampaignId || advertisementCampaignId === 0) ? "unActiveBtn": "blockBtn"}
            onSubmit={deleteSelected}
          />
        )}
        {/*<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>*/}
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>

    <SubTitle>광고 정보</SubTitle>
    <SpaceDiv value={20} />
    <MainContainer>
      <InputWrapper colSpan={2}>
        {advertisementList.length === 0
          ?(<LabelTextInput
              name={'warn'} label={'광고ID 선택'} readOnly
              value={'광고ID가 없습니다.'}
          />)
          :(<AdvertisementSelector
              placeholder="광고를 선택해주세요." label="광고ID 선택" Required
              type={'advertisement'}
              options={advertisementList}
              value={
                (advertisementTitle && advertisementStrId) ? `${advertisementTitle}(${advertisementStrId})`:''
              }
              onChange={handleAdvertisement}
          />)
        }
      </InputWrapper>
      <InputWrapper>
        <LabelTextInput
            name={'advertisementTitle'} label={'광고 식별 제목'} placeholder={'광고 식별제목을 입력해주세요.'} readOnly
            value={advertisementTitle}
        />
      </InputWrapper>
      <InputWrapper>
        <LabelTextInput
            name={'advertisementCountries'} label={'광고 노출 국가'} placeholder={'노출 국가를 선택해주세요.'} readOnly
            value={advertisementCountries}
        />
      </InputWrapper>
      <InputWrapper>
        <LabelTextInput
            name={'advertisementStartDt'} label={'노출 시작일시'} placeholder={'노출 시작일시를 선택해주세요.'} readOnly
            value={advertisementStartDt && handleDate(advertisementStartDt)}
        />
      </InputWrapper>
      <InputWrapper>
        <LabelTextInput
            name={'advertisementEndDt'} label={'노출 종료일시'} placeholder={'노출 종료일시를 선택해주세요.'} readOnly
            value={advertisementEndDt && handleDate(advertisementEndDt)}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="advertisementMemo" label="광고 메모(선택)" placeholder="메모를 입력해주세요." readOnly
            value={advertisementMemo}
        />
      </InputWrapper>
    </MainContainer>

    <LineDiv/>
    <SubTitle>지면 정보</SubTitle>
    <MainContainer>
      <InputWrapper colSpan={2}>
        <RadioButton
            name="linkType" label="이동 유형" Required
            type="list" text={['외부', '내부']} values={['EXTERNAL', 'INTERNAL']}
            value={linkType} onChange={(e)=>setLinkType(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <LabelTextInput
            name="externalLinkUrl" label="[외부] URL 입력 필드" placeholder="외부 URL을 입력해주세요."
            value={externalLinkUrl}
            onChange={(e) => setExternalLinkUrl(e.target.value)}
            onReset={() => setExternalLinkUrl('')}
            readOnly={linkType !== 'EXTERNAL'}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        {(linkType==='INTERNAL')
          ?(advertisementLandingList.length === 0
            ?(<LabelTextInput
                name={'warn'} label={'[내부] 랜딩페이지ID 선택'} readOnly
                value={'랜딩페이지ID가 없습니다.'}
            />)
            :(<AdvertisementSelector
                placeholder="랜딩페이지ID를 선택해주세요." label="[내부] 랜딩페이지ID 선택"
                options={advertisementLandingList} type={'advertisementLanding'}
                value={advertisementLandingTitle} onChange={handleAdvertisementLanding}
            />)
          )
          :(<LabelTextInput
              name="advertisementLandingList" placeholder="랜딩페이지ID를 선택해주세요." label="[내부] 랜딩페이지ID 선택"
              value={advertisementLandingTitle}
              readOnly
          />)
        }
      </InputWrapper>

      <InputWrapper colSpan={2}>
        <SelectModule
            name="type" label="지면 유형" placeholder="지면 유형을 선택해주세요."
            value={campaignTypes[campaignType]}
            onChange={e => handleCampaignType(e.value)}
            options={commonFn.transObjectToOptions(campaignTypes)}
        />
      </InputWrapper>
    </MainContainer>

    {(campaignType==='NM') && (<>
      <LineDiv/>
      <SubTitle>메인 팝업 광고 추가 정보</SubTitle>
      <MainContainer>
        <InputWrapper colSpan={2}>
          <RadioButton
              name="popupBtnYn" label="팝업 버튼 노출 여부" Required
              text={['노출', '미노출']} type="activeYn"
              value={popupBtnYn} onChange={(e) => setPopupBtnYn(e.target.value)}
          />
        </InputWrapper>
        {(popupBtnYn==='Y') && (<>
          <InputWrapper>
            <LabelTextInput
                name="popupBtnName" label="팝업 버튼명(국문)" placeholder="버튼명을 입력해주세요(최대 15자)."
                value={popupBtnName} maxLen={15}
                onChange={(e) => setPopupBtnName(e.target.value)}
                onReset={() => setPopupBtnName('')}
            />
          </InputWrapper>
          <InputWrapper>
            <LabelTextInput
                name="popupBtnNameEn" label="팝업 버튼명(영문)" placeholder="버튼명을 입력해주세요(최대 15자)."
                value={popupBtnNameEn} maxLen={15}
                onChange={(e) => setPopupBtnNameEn(e.target.value)}
                onReset={() => setPopupBtnNameEn('')}
            />
          </InputWrapper>
        </>)}
        <InputWrapper colSpan={2}>
          <LabelTextInput
              name="hideDays" label="n일간 보지 않기 버튼" placeholder="비표시일 수를 선택해주세요."
              type="number" value={hideDays} onChange={(e) => handleHideDays(e.target.value)}
          />
          <ResultText className={'normal'}>0으로 입력할 경우, n일간 보지 않기 버튼은 노출되지 않습니다.</ResultText>
        </InputWrapper>
      </MainContainer>
    </>)}

    <LineDiv/>
    <SubTitle>광고캠페인 정보</SubTitle>
    <MainContainer>
      <InputWrapper colSpan={2}>
        <RadioButton
            name="componentType" label="소재 유형" Required
            type="list"
            text={(campaignType === 'ML') ?['이미지', '동영상']:['이미지']}
            values={(campaignType === 'ML') ?['IMAGE', 'MOVIE']:['IMAGE']}
            value={componentType} onChange={(e)=>setComponentType(e.target.value)}
        />
      </InputWrapper>

      {componentType === 'IMAGE' && (<>
        <InputWrapper>
          <NormalButtonWithLabel
              label={"소재 등록"} buttonText={"파일 첨부"} Required
            buttonOnClick={handleBannerImg}
          />
        </InputWrapper>
        <InputWrapper className={'hide'}>
            <FileInput
              type={(campaignType === 'EL' || campaignType === 'NM') ? 'advertisementMain':'advertisementOther'}
              label="메인 공지 이미지" name="noticeMainBannerImgUrl"
              placeholder={(bannerImgUrl || bannerImgFile)  ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
              value={bannerImgFile} setFile={setBannerImgFile}
              fileUrl={bannerImgUrl} setImgUrl={e => setBannerImgUrl(e)}
              fileInputRef={_imgFileInputRef}
            />
          </InputWrapper>
        <InputWrapper mt={29}><EventImg src={bannerImgUrl || ''}/></InputWrapper>
      </>)}

      {componentType === 'MOVIE' && <>
        <InputWrapper>
          <NormalButtonWithLabel
              label={"소재 등록"} buttonText={"파일 첨부"} Required
              buttonOnClick={handleBannerMovie}
          />
        </InputWrapper>
        <InputWrapper className={'hide'}>
          <FileInput
            type="article" label="동영상"
            placeholder={(bannerMovieUrl || bannerMovieFile) ? '파일이 등록되었습니다.' : '파일을 선택해주세요. (mp4 파일만 가능)'}
            value={bannerMovieFile} setFile={setBannerMovieFile}
            fileInputRef={_movieFileInputRef}
          />
        </InputWrapper>

        <InputWrapper mt={29}>
          {(bannerMovieUrl || bannerMovieFile) && (
              <Video key={bannerMovieFile ? bannerMovieFile.name: 1} ref={_videoRef} controls>
                <track default kind="captions" />
                <source type="video/mp4"
                        src={bannerMovieFile ? URL.createObjectURL(bannerMovieFile):bannerMovieUrl}/>
                Your browser does not support the video tag. I suggest you upgrade your browser.
              </Video>
          )}
        </InputWrapper>
      </>}

      {/*공개 기간*/}
      <InputWrapper>
          {(!advertisementId)
              ?<LabelTextInput name="startDtReadOnly" placeholder="노출 시작일시를 선택해주세요." label="노출 시작일시" value={''} readOnly/>
              :<DateInput
                  name="startDt" label="노출 시작일시" Required
                  initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)}
              />
          }
      </InputWrapper>
      <InputWrapper>
        {(!advertisementId)
              ?<LabelTextInput name="endDtReadOnly" placeholder="노출 종료일시를 선택해주세요." label="노출 종료일시" value={''} readOnly/>
              : <DateInput
                  name="endDt" label="노출 종료일시" Required
                  initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)}
              />
          }

      </InputWrapper>

      <InputWrapper colSpan={(advertisementCampaignId) ?1:2}>
        {(!advertisementId)
          ?<LabelTextInput name="countriesSelectedReadOnly" placeholder="국가를 선택해주세요." label="광고 노출 국가" value={''} readOnly/>
          : <MultiSelect label="광고 노출 국가" name="countriesSelected" placeholder="국가를 선택해주세요." Required
                         value={countriesSelected}
                         onChange={processSelectedCategory}
                         options={[{name: '한국', value: 'KR'}].map((el)=>el.name)}
                // options={notContainsAllCountryOptions.map((el)=>el.name)}
            />
        }

      </InputWrapper>
      {advertisementCampaignId &&(
          <>
            <InputWrapper>
              <LabelTextInput
                  name="title" label="광고캠페인 식별제목" placeholder="광고캠페인 식별제목을 입력해주세요."
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  onReset={() => setTitle('')}
              />
              {titleCheckResult && <ResultText>{titleCheckResult}</ResultText>}
            </InputWrapper>
          </>
      )}

      <InputWrapper colSpan={2}>
        <LabelTextAreaInput
            name="memo" label="광고캠페인 메모(선택)" placeholder={"메모를 입력해주세요."}
            value={memo}
            setValue={(e) => setMemo(e.target.value)}
            onReset={() => setMemo('')}
        />
      </InputWrapper>

      <InputWrapper colSpan={2}>
        <RadioButton
            name="activeYn" label="캠페인 공개 여부" Required
            text={['공개', '비공개']} type="activeYn"
            value={activeYn} onChange={(e) => setActiveYn(e.target.value)}
        />
      </InputWrapper>
    </MainContainer>
  </>;
};
export default AdvertisementCampaignDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
    grid-row: ${({row}) => row && `span ${row}`};
    grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

    &.area1 {
      background-color: #ccc;
      padding: 15px;
      border-radius: 6px;
    }
    &.hide{display:none;}
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 0px;
  border-radius: 10px;
`;

const Video = styled.video`
  width: 100%;
  // width: 485px;
  // height: 863px;
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-bottom: ${({value}) => `${value}px`};
`;

const ResultText = styled.span`
  position: absolute;
  // margin-top: 12px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
  &.normal {
    color: ${({ theme }) => theme.color.mainBlue};
  }
`;
