import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import NormalButton from '../../components/common/Button/NormalButton';
import Pagination from '../../components/common/Pagination';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import { COUPONS_PROJECTS } from '../../constant/apiUrl';
import { postsPerPage } from '../../constant/common';
import { IssuanceTypeObjects } from './CouponParams';
import VideoInput from "../../components/common/Input/VideoInput";
import { CouponProjectModal } from "./CouponProjectModal";
import modal from "../../components/common/Modal/Modal";

interface CouponProject {
  couponProjectId: number;
  name: string;
  privateYn: string;
  value: number;
  issuanceType: string;
  issuanceCount: number;
  maxIssuanceCount: number;
  usedYn: string;
  startDateTime: string;
  endDateTime: string;
  createDate: string;
  updateDate: string;
  code: string;
  maxRedeemCount: number;
}

interface SearchParam {
  offset: number;
  limit: number;
}

// 골드 캠페인 목록 화면
const CouponProjects = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 페이징
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // 표시 데이터
  const [couponProjectList, setCouponProjectList] = useState([]);
  // 모달 표시 조건
  const [modalOpen, setModalOpen] = useState(false);
  const [modalIssuanceType, setModalIssuanceType] = useState('');
  const [modalCouponProjectId, setModalCouponProjectId] = useState(0);
  const [modalTitleName, setModalTitleName] = useState('');
  const [modalCode, setModalCode] = useState('');
  const [maxRedeemCount, setMaxRedeemCount] = useState(0);
  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getCampaigns = createAsyncThunk(
    'coupon/Coupons',
    async (parameters: SearchParam) => {
      await axios
        .get(COUPONS_PROJECTS, { params: parameters })
        .then((res) => {
          const { data } = res;
          setTotalCount(data.count);
          setCouponProjectList(data.couponProjectList);
        })
        .catch((error) => alert(error.response));
    },
  );
  //검색 조건 변경시 dispatch
  useEffect(() => {
    dispatch(getCampaigns(params));
  }, [dispatch, pageNumber]);

  const convertIssuanceTypeValueToKey = (issuanceTypeValue: string) => {
    const issuanceTypeObj = IssuanceTypeObjects.find((issuanceTypeObjData) =>
        Object.entries(issuanceTypeObjData).map((el) => el[0] === issuanceTypeValue),
    );
    if (!issuanceTypeObj) {
      alert('데이터를 조회하던 중 에러가 발생하였습니다.');
      return '';
    }
    return Object.entries(issuanceTypeObj).map((el) => el[1]);
  };

  const CouponRow = (data: CouponProject) => {
    const couponPeriod = `${
      data.startDateTime ? convertUtcToLocalTimeZone(data.startDateTime) : ''
    } ~
  ${data.endDateTime ? convertUtcToLocalTimeZone(data.endDateTime) : ''}`;

    return (
      <tr
        id={`${data.couponProjectId}`}
        key={data.couponProjectId}
        onClick={() => {
          const dataIssuanceType = data.issuanceType;
          if (dataIssuanceType === 'GENERATE') {
            navigate('/coupons/coupon-projects/coupon-list', {
              state: { couponProjectId: data.couponProjectId },
            });
          } else if (dataIssuanceType === 'DOWNLOAD') {
            navigate('/coupons/coupon-projects/detail', {
              state: {
                couponProjectId: data.couponProjectId,
              },
            });
          }
        }}
      >
        <td>
          <ListItem id={`${data.couponProjectId}id`} text={data.couponProjectId} />
        </td>
        <td>
          <ListItem text={data.name} />
        </td>
        <td>
          <ListItem text={data.value} />
        </td>
        <td>
          <ListItem text={getCouponStatus(data)} />
        </td>
        <td id={`${data.couponProjectId}issuanceType`}>
          <ListItem
            text={`${convertIssuanceType(data.issuanceType, data.maxRedeemCount)}`}
          />
        </td>
        <td>
          <ListItem text={`${data.issuanceCount}/${data.maxIssuanceCount}`} />
        </td>
        <td>
          <ListItem text={couponPeriod} />
        </td>
        <td>
          <ListItem text={convertUtcToLocalTimeZone(data.createDate)} />
        </td>
        <td onClick={(e) => e.stopPropagation()}>
          {/* 교환 희망자 확인 모달 열기 */}
          <InputWrapper span={1}>
            <VideoInput
              onClick={(e) => {
                setModalOpen(true);
                const trId =
                  e.target.parentNode.parentNode.parentNode.parentNode.id;
                setModalCouponProjectId(trId);
                const issuanceType = document.getElementById(
                  `${trId}issuanceType`,
                )?.firstChild?.textContent;
                if (issuanceType === '시리얼 설정') {
                  setModalTitleName(`${data.name} (쿠폰코드 생성-시리얼)`);
                  setModalIssuanceType('DOWNLOAD');
                  setModalCode(data.code ? data.code : '');
                } else {
                  setModalTitleName(`${data.name} (쿠폰코드 생성-갯수제한)`);
                  setModalIssuanceType('GENERATE');
                }
              }}
              text="더보기"
            />
          </InputWrapper>
        </td>
      </tr>
    );
  };

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ButtonWrapper>
            <NormalButton
              text="쿠폰 만들기"
              onSubmit={() => navigate('/coupons/coupon-projects/add')}
            />
          </ButtonWrapper>
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={(e) => setPageNumber(e - 1)}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>

      <MainContainer>
        <Table
          colList={[50, 60, 60, 80, 100, 100, 100, 100, 110]}
          thead={
            <>
              <ListHead title="Id" />
              <ListHead title="쿠폰 명" />
              <ListHead title="쿠폰 가격" />
              <ListHead title="상태" />
              <ListHead title="상품권 형식" />
              <ListHead title="등록/발행" />
              <ListHead title="사용 유효기간" />
              <ListHead title="발급 일자" />
              <ListHead title="사용/등록 현황" />
            </>
          }
        >
          {couponProjectList?.map((couponProject: CouponProject) => (
            <CouponRow key={couponProject.couponProjectId} {...couponProject} />
          ))}
        </Table>
      </MainContainer>
      {modalOpen && (
        <CouponProjectModal
          issuanceTypeValue={modalIssuanceType}
          couponProjectId={modalCouponProjectId}
          titleName={modalTitleName}
          code={modalCode}
          onClose={(modalOpenParam: boolean) => {
            setModalOpen(modalOpenParam);
            setModalTitleName('');
          }}
        />
      )}
    </>
  );
};
export default CouponProjects;

const getCouponStatus = (data: CouponProject) => {
  // utc now timestamp
  const nowLocalDateTime = new Date();
  // 서버에서 받은 시간값 타임존 = utc
  const localStartDateTime = new Date(`${data.startDateTime}Z`);
  // 서버에서 받은 시간값 타임존 = utc
  const localEndTimestamp = new Date(`${data.endDateTime}Z`);
  if (nowLocalDateTime < localStartDateTime) {
    return '대기';
  }
  if (
    localStartDateTime <= nowLocalDateTime &&
    nowLocalDateTime < localEndTimestamp
  ) {
    return '진행중';
  }
  return '종료';
};

const convertUtcToLocalTimeZone = (targetDateTime: string) => {
  if (!targetDateTime) return '';
  // yyyy-MM-ddTHH:mm:ss 패턴으로 입력되는 타입이므로
  // yyyy-MM-ddTHH:mm:ssZ 로 utc 기준 시간이란 것을 표현
  const utcTargetDateTime = new Date(`${targetDateTime}Z`);
  return utcTargetDateTime.toLocaleString().substring(0, 11);
};

function convertIssuanceType(originIssuanceType: string, maxRedeemCount: number) {
  // 상품권 발급 형식 변환
  const convertedIssuanceType: string = IssuanceTypeObjects.flatMap(
    (issuanceTypeObj) => {
      return Object.entries(issuanceTypeObj)
        .filter((typeObj) => typeObj[0] === originIssuanceType)
        .map((typeObj) => typeObj[1]);
    },
  );
  let issuanceType = '';
  // 상품권 발급 형식 객체 목록 상수에 없는 발급 형식의 경우 반환할 데이터 객체에 원본 데이터 세팅
  if (!convertedIssuanceType) {
    issuanceType = originIssuanceType;
  }
  // 있는 경우 변환 값 세팅
  else {
    issuanceType = convertedIssuanceType;
  }
  if (originIssuanceType === 'GENERATE') {
    issuanceType = `${issuanceType}(${maxRedeemCount})`;
  }
  return issuanceType;
}

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const LineDiv = styled.div`
  width: 100%;
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TopWrapper = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: right;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;
const InputWrapper = styled.div`
  grid-row: ${({ row }) => row && `${row}`};
  grid-column: ${({ span }) => span && `span ${span}`};
  margin-bottom: ${({ mb }) => (typeof mb === 'number' ? `${mb}px` : '5px')};
  margin-top: ${({ mt }) => (typeof mt === 'number' ? `${mt}px` : '')};

  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }
`;
