import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import axios from 'axios';
import NormalButton from '../../../components/common/Button/NormalButton';
import ProfileImg from '../../../components/common/image/ProfileImg';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';

// redux
import { setLoading, resetLoading } from '../../../redux/slices/user';

// config
import { OFFICIAL_ACCOUNTS } from '../../../constant/apiUrl';

type data = {
  [key: string]: any;
};

//초기 비트메이커 데이터
const initialAccountData: data = {
  coverImgUrl: null,
  profileImgUrl: null,
  url: '',
  createdDt: '',
  description: '',
  id: '',
  name: '',
  pid: '',
};

const OfficialAccountDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const detailData: any = location.state;

  const [officialAccountData, setOfficialAccountData] =
    useState(initialAccountData);
  const [profileImgFile, setProfileImgFile] = useState('');
  const [coverImgFile, setCoverImgFile] = useState('');
  const [profileImgUrl, setProfileImgUrl] = useState('');
  const [coverImgUrl, setCoverImgUrl] = useState('');

  const { id, pid, name, description, url } = officialAccountData;

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  // input 등록하기
  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      setOfficialAccountData({ ...officialAccountData, [name]: value });
    },
    [officialAccountData],
  );

  // input 리셋하기
  const handleResetData = useCallback(
    (e) => {
      setOfficialAccountData({
        ...officialAccountData,
        [e]: initialAccountData[e],
      });
    },
    [officialAccountData],
  );

  const handleProfileSetData = (e: string) => {
    setProfileImgFile(e);
  };

  const handleCoverSetData = (e: string) => {
    setCoverImgFile(e);
  };

  const isEdit = () => {
    return (
      detailData !== officialAccountData ||
      detailData.profileImgUrl !== profileImgUrl ||
      detailData.coverImgUrl !== coverImgUrl
    );
  };

  useEffect(() => {
    setOfficialAccountData(detailData || initialAccountData);
    setProfileImgUrl(detailData.profileImgUrl || '');
    setCoverImgUrl(detailData.coverImgUrl || '');
  }, [detailData]);

  // 계정 상세 수정하기
  const changeDetail = async () => {
    if (!pid) {
      alert('사용자 이름을 입력해주세요.');
      return;
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (url && !url.includes('https://')) {
      alert('https:// 가 붙은 url을 입력해주세요.');
      return;
    }
    const formData = new FormData();

    formData.append('coverImg', coverImgFile);
    formData.append('profileImg', profileImgFile);
    formData.append('description', description === null ? '' : description);
    formData.append('name', name);
    formData.append('pid', pid);
    formData.append('url', url === null ? '' : url);

    try {
      dispatch(setLoading());

      await axios.post(`${OFFICIAL_ACCOUNTS}/${id}`, formData, {});
      await navigate('/operation/officialAccount');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <NoticeText>입력된 정보는 앱내의 Baund 계정에 표시됩니다.</NoticeText>
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
            {isEdit() && (
              <NormalButton
                text="저장하기"
                onSubmit={changeDetail}
                className=""
              />
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <ImageContainer>
        <InputWrapper>
          <ProfileImg url={profileImgUrl} />
          <SpaceDiv />
          <FileInput
            type="coverImg"
            placeholder={
              profileImgUrl ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="프로필 이미지"
            value={profileImgFile}
            setFile={handleProfileSetData}
            setImgUrl={setProfileImgUrl}
          />
        </InputWrapper>
        <InputWrapper>
          <ProfileImg url={coverImgUrl} />
          <SpaceDiv />
          <FileInput
            type="coverImg"
            placeholder={
              coverImgUrl ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="배경 이미지"
            value={coverImgFile}
            setFile={handleCoverSetData}
            setImgUrl={setCoverImgUrl}
          />
        </InputWrapper>
      </ImageContainer>
      <MainContainer>
        <SpaceDiv />
        <InputWrapper>
          <LabelTextInput
            name="pid"
            label="사용자이름"
            value={pid}
            placeholder="사용자 이름을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="이름"
            value={name}
            placeholder="이름을 입력해주세요.(1자 이상)"
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <SpaceDiv />
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="소개"
            value={description}
            placeholder="소개를 입력해주세요.(최대 30자)"
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>

        <InputWrapper>
          <LabelTextInput
            name="url"
            label="웹사이트"
            value={url}
            placeholder="웹사이트를 입력해 주세요. (ex: https:// 으로 시작)"
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default OfficialAccountDetail;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NoticeText = styled.p`
  font-size: 18px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const ImageContainer = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px 30px;
  grid-gap: 40px 30px;
`;

const MainContainer = styled.div`
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  gap: 20px;
  grid-gap: 20px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div``;

const SpaceDiv = styled.div`
  width: 100%;
  margin-top: 30px;
`;
