import React, {useEffect, useState} from "react";
import {
  IsPrivate,
  postsPerPage
} from "../../constant/common";
import axios from "axios";
import {BAUND_PROJECT} from "../../constant/apiUrl";
import NormalButton from "../../components/common/Button/NormalButton";
import PaginationWithTotalCount
  from "../../components/common/Pagination/PaginationWithTotalCount";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import styled from "styled-components";
import {useNavigate} from "react-router-dom";
import ListCoverImg from "../../components/common/List/ListCoverImg";
import ListHeadWithSort from "../../components/UI/ListHeadWithSort";
import ListSelect from "../../components/common/Select/ListSelect";
import {
  baundProjectPrivateYnOptions,
  baundProjectSearchTypeOptions,
  baundProjectStatusTypeOptions
} from "../../constant/baundProject";
import useInput from "../../hooks/useInput";
import SearchMenuSelect from "../../components/common/Select/SearchMenuSelect";
import SearchInput from "../../components/common/Input/SearchInput";

// 바운드 프로젝트 목록
const BaundProjects = () => {
  const navigate = useNavigate();

  // 저장된 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition'));

  // 검색 및 정렬 조건 ///////////////////////////////////////////////////////////////
  //정렬 기준
  const [sortProperty, setSortProperty] = useState(storageSearchCondition?.propertyForSort || 'createDate');
  // 정렬 순
  const [sortDirection, setSortDirection] = useState(storageSearchCondition?.directionForSort || 'DESC');
  // 공개 여부
  const[privateYn, setPrivateYn] = useState(storageSearchCondition?.privateYnForSearch);
  const[privateYnText, setPrivateYnText] = useState(storageSearchCondition?.privateYnTextForSearch);
  // 상태
  const[status, setStatus] = useState(storageSearchCondition?.statusForSearch);
  const[statusText, setStatusText] = useState(storageSearchCondition?.statusTextForSearch);
  // 페이징
  const [pageNumber, setPageNumber] = useState(storageSearchCondition?.pageNumberForSearch || 0);
  // 검색
  const [keyword, setWord] = useInput(storageSearchCondition?.keywordForSearch || '');
  const [searchType, setSearchType] = useState(storageSearchCondition?.searchTypeForSearch || '');

  useEffect(() => {
    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  //표시 데이터 ////////////////////////////////////////////////////////////////////
  const [baundProjectList, setBaundProjectList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  //목록 조회 /////////////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    privateYn,
    sortProperty,
    sortDirection,
    status,
    searchType,
    keyword
  };
  const getBaundProjects = async() => {
    await axios.get(`${BAUND_PROJECT}`, {params})
    .then(res => {
      setBaundProjectList(res.data.baundProjects);
      setTotalCount(res.data.totalCount);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(
      () => {
        getBaundProjects().then();
      },
      [pageNumber, privateYn, sortProperty, sortDirection, status]
  );

  // 정렬 방법 변경
  const setSort = (sortProperty, sortDirection) => {
    setSortProperty(sortProperty);
    setSortDirection(sortDirection);
  }

  // 검색 ///////////////////////////////////////////////////////////////////////
  // 검색 옵션, 프로젝트 상태, 노출 여부 변경시
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
  };
  // 엔터키 검색 개시
  const enterKeyPress = async (e) => {
    if (e.key === 'Enter') {
      if (!searchType) {
        setSearchType('title');
        params.type = 'title';
      }
      setPageNumber(0);
      params.offset = 0;
      await getBaundProjects();
    }
  };

  // setter Wrapper ///////////////////////////////////////////////////////////
  // 프로젝트 상태 변경시
  const handleStatus = (value) => {
    setPageNumber(0);
    params.offset = 0;
    setStatus(value);
    setStatusText(
        value
        ? baundProjectStatusTypeOptions.filter(e=>e.value === value)[0].text
        : "전체"
    );
  }
  // 노출 여부 변경시
  const handlePrivateYn = (value) => {
    setPageNumber(0);
    params.offset = 0;
    setPrivateYn(value);
    setPrivateYnText(IsPrivate[value])
  }

  // 페이지 이동 ///////////////////////////////////////////////////////////////////
  // 상세 페이지로 이동
  const moveToDetail = (baundProjectId) => {
    navigate(
      `/baundProject/baundProject/${baundProjectId ? 'edit': 'add'}`,
      {
        state: {
          baundProjectId,
          propertyForSort: sortProperty,
          directionForSort: sortDirection,
          statusForSearch: status,
          statusTextForSearch: statusText,
          privateYnForSearch: privateYn,
          privateYnTextForSearch: privateYnText,
          pageNumberForSearch: pageNumber,
          keywordForSearch: keyword,
          searchTypeForSearch: searchType
        }
      }
    )
  }
  // QnA 페이지로 이동
  const moveToQnas = (baundProjectId) => {
    // 선택된 프로젝트의 내용 표시를 위해
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ baundProjectIdForSearch: baundProjectId })
    );
    navigate(`/baundProject/baundProjectQna/`);
  }

  return (
      <>
        <TitleText>프로젝트 리스트</TitleText>
        <TopContainer>
          <TopWrapper>
            <SearchWrapper>
              <SearchMenuSelect
                  options={baundProjectSearchTypeOptions}
                  onClick={handleSelect}
                  setState={setSearchType}
              />
              <SearchInputWrapper>
                <SearchInput value={keyword} onChange={setWord} onKeyPress={enterKeyPress}/>
              </SearchInputWrapper>
            </SearchWrapper>
            <ButtonWrapper>
              <NormalButton text="새로 만들기" onSubmit={moveToDetail}/>
            </ButtonWrapper>
          </TopWrapper>
          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 프로젝트'}
                      currentPage={pageNumber}
                      pageDispatch={(e)=>setPageNumber(e-1)}
                  />
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
            <Table
                colList={[10, 40, 100, 45, 35, 25, 30, 45]}
                thead={<>
                  <ListHead title="Id" />
                  <ListHead title="대표 이미지"/>
                  <ListHead title="프로젝트 이름"/>
                  <ListSelect
                    title="프로젝트 상태"
                    list={baundProjectStatusTypeOptions}
                    onClick={handleSelect}
                    setState={handleStatus}
                    state={statusText}
                  />
                  <ListHeadWithSort
                    title="지원자 수"
                    sortKey={"participantCount"}
                    selectedSortKey={sortProperty}
                    selectedSortDirection={sortDirection}
                    onClick={setSort}
                  />
                  <ListHead title="QnA"/>
                  <ListSelect
                    title="노출 여부"
                    list={baundProjectPrivateYnOptions}
                    onClick={handleSelect}
                    setState={handlePrivateYn}
                    state={privateYnText}
                  />
                  <ListHeadWithSort
                    title="생성일"
                    sortKey={"createDate"}
                    selectedSortKey={sortProperty}
                    selectedSortDirection={sortDirection}
                    onClick={setSort}
                  />
                </>}
            >
              {baundProjectList?.map(el =>
                  <tr key={el.baundProjectId}>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={el.baundProjectId}/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListCoverImg url={el.detailImgUrl}/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={`${el.title}`}/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={
                        baundProjectStatusTypeOptions.filter(e=>e.value === el.status)[0].text
                      }/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={`${el.participantCount}`}/>
                    </td>
                    <td onClick={() => moveToQnas(el.baundProjectId)}>
                      <ListItem text={`${el.qnaCount}`}/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={IsPrivate[el.privateYn]}/>
                    </td>
                    <td onClick={() => moveToDetail(el.baundProjectId)}>
                      <ListItem text={`${el.createDt}`}/>
                    </td>
                  </tr>
                )}
                {baundProjectList.length === 0 && (
                    <tr>
                      <td colSpan={8}>
                        <SpanNoResult>검색 결과가 없습니다.</SpanNoResult>
                      </td>
                    </tr>
                )}
            </Table>
        </MainContainer>
      </>
  );
}
export default BaundProjects;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 430px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;