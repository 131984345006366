export const csSearchTypeList = [
  // { text: '전체', value: '' },
  { text: '신고 받은 Pid', value: 'TM' },
  { text: '신고 한 Pid', value: 'OM' },
];

export const memberSearchTypeList = [
  // { text: '전체', value: '' },
  { text: 'Pid', value: 'P' },
  { text: '이메일', value: 'E' },
  { text: '인덱스', value: 'I' },
];

export const blockTypeOptions = [
  { text: '전체', value: '' },
  { text: '유저', value: 'MB' },
  { text: '영상 댓글', value: 'ACM' },
  { text: '영상 대댓글', value: 'ASCM' },
  { text: '비트 댓글', value: 'BCM' },
  { text: '비트 대댓글', value: 'BSCM' },
  { text: '컬렉션', value: 'CL' },
  { text: 'DM', value: 'DM' },
  { text: '커뮤니티', value: 'CB' },
  { text: '커뮤니티 댓글', value: 'CBCM' },
  { text: '커뮤니티 대댓글', value: 'CBSCM' },
];

type blockTypeOption = {
  [key: string]: string;
};

export const blockType: blockTypeOption = {
  AR: '영상',
  MB: '유저',
  ACM: '영상 댓글',
  ASCM: '영상 대댓글',
  BCM: '비트 댓글',
  BSCM: '비트 대댓글',
  CL: '컬렉션',
  DM: 'DM',
  CB: '커뮤니티',
  CBCM: '커뮤니티 댓글',
  CBSCM: '커뮤니티 대댓글',
};

export const rekognitionOptions = [
  { text: '전체', value: '' },
  { text: '일반 영상', value: 'NR' },
  { text: '배틀 영상', value: 'BT' },
];

export const rekognitionType: blockTypeOption = {
  NR: '일반 영상',
  BT: '배틀 영상',
};

export const statusChangeType: blockTypeOption = {
  Y: '처리 번복',
  N: '정상 처리',
};

export const csConfirmColList = [80, 340, 150, 150, 190, 80];

export const csBlockColList = [80, 220, 130, 130, 80, 100, 80, 90, 80];

export const csDeleteColList = [80, 220, 130, 130, 80, 100, 80, 90, 80];

export const csExConfirmColList = [80, 140, 200, 120, 120, 150, 70];

export const csExBlockColList = [80, 110, 140, 130, 130, 80, 80, 90, 80];

export const csExDeleteColList = [80, 110, 140, 130, 130, 80, 80, 90, 80];

export const rekognitionColList = [180, 235, 229, 199, 152];
