export const articleSearchTypeList = [
  // { text: '전체', value: '' },
  { text: 'id', value: 'ID' },
  { text: 'Pid', value: 'PID' },
  { text: '이름', value: 'NAME' },
  { text: '회원 아이디', value: 'MEMBER_ID' },
  { text: '해시 태그', value: 'TAGS' },
  { text: '제시어', value: 'SUGGEST_WORDS' },
];

export const articlePrivateYnOptions = [
  { text: '전체', value: '' },
  { text: '공개', value: 'N' },
  { text: '비공개', value: 'Y' },
];

export const articleColList = [50, 70, 70, 75, 120, 130, 120, 150, 105, 60, 70];
