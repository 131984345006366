const isRunning: Record<string, boolean> = {};

/**
 * 연속 동작 방지 함수
 * @param fn
 * @param id
 * @param delay
 */
export const limitCall = <A extends any[], R>(
    fn: (...args: A) => Promise<R> | R,
    id = '',
    delay = 500, // 혹시 모르니 딜레이. 전체 적용
) => {
    return async (...args: A): Promise<R> => {
        // 다시 요청이 들어왔을때 중복 클릭일 경우 에러 던지기
        if (isRunning[id]) throw new Error('Already running!');
        // 첫번째 요청에서 요청 등록
        isRunning[id] = true;
        try {
            const result = await fn(...args);

            //delay가 파라미터로 넘어올 경우 delay가 끝나기 전까지 isRunning = true로 남겨둠
            if (delay > 0) {
                await new Promise((resolve) => setTimeout(resolve, delay));
            }

            return result;
        } finally {
            //에러를 띄우는 isRunning을 false로 바꿔줌
            isRunning[id] = false;
        }
    };
};
