import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import notFound from '../assets/images/page/notFound.png';
import FullButton from '../components/common/Button/FullButton';

const NotFound = () => {
	const navigate = useNavigate();
	return <Container>
		<StatusNumber>404</StatusNumber>
		<Img src={notFound} alt="unHappy icon" />
		<Title>접근할 수 없는 페이지 입니다.</Title>
		<ButtonContainer>
			<FullButton
				text="홈으로 가기"
				onSubmit={() => navigate('/')}
			/>
		</ButtonContainer>
	</Container>;
};

export default NotFound;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 64px;
  background-color: #ffffff;
`;

const StatusNumber = styled.div`
  width: 115px;
  height: 80px;
  margin-bottom: 10px;
  flex-grow: 0;
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const Img = styled.img`
  width: 96px;
  margin-bottom: 50px;
  object-fit: contain;
`;

const Title = styled.div`
  height: 60px;
  margin-bottom: 40px;
  flex-grow: 0;
  font-size: 48px;
  color: #000;
`;

const ButtonContainer = styled.div`
  width: 380px;
  height: 44px;
`;
