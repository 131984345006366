import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import TextField from '../../../components/common/TextField';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';

// config
import { COMMUNITY_FIXED_ARTICLE } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import FileInput from '../../../components/common/Input/FileInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';

const FixArticleDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [memberName, setMemberName] = useState('');
  const [memberPid, setMemberPid] = useState('');
  const [communityBoardCategoryIds, setCommunityBoardCategoryIds] =
    useState<any>([]);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [createdDt, setCreatedDt] = useState('');
  const [bannerImg, setBannerImg] = useState<string>('');
  const [bannerImgFile, setBannerImgFile] = useState<any>(null);
  const [bannerStartDt, setBannerStartDt] = useState('');
  const [bannerEndDt, setBannerEndDt] = useState('');

  const getCommunityCategoryDetail = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${COMMUNITY_FIXED_ARTICLE}/${prevId}/detail`,
      );
      if (resp.data.article) {
        setMemberName(resp.data.article.memberName);
        setMemberPid(resp.data.article.memberPid);
        setCommunityBoardCategoryIds(
          resp.data.article.communityBoardCategoryIds,
        );
        setTitle(resp.data.article.title);
        setContent(resp.data.article.content);
        setCreatedDt(resp.data.article.createdDt);
        // 이미지 파일 세팅
        setBannerImg(
          resp.data.article.bannerImgUrl ? resp.data.article.bannerImgUrl : '',
        );
        setBannerStartDt(
          resp.data.article.bannerStartDt?.replace(' ', 'T').slice(0, 16),
        );
        setBannerEndDt(
          resp.data.article.bannerEndDt?.replace(' ', 'T').slice(0, 16),
        );
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  // useEffect 는 컴포넌트가 렌더링 될 때마다 특정 작업을 실행할 수 있도록 하는 Hook
  useEffect(() => {
    if (prevId) {
      getCommunityCategoryDetail().then((r) => r);
    }
  }, [getCommunityCategoryDetail, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  const addFixedArticle = async () => {
    if (!bannerImg) {
      alert('배너 이미지를 입력해주세요.');
      return;
    }
    if (!bannerStartDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!bannerEndDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }
    const formData = new FormData();
    if (bannerImgFile != null) {
      formData.append('bannerImg', bannerImgFile);
    }
    formData.append('bannerStartDate', `${bannerStartDt.replace('T', ' ')}:00`);
    formData.append('bannerEndDate', `${bannerEndDt.replace('T', ' ')}:59`);

    console.log(formData.get('bannerStartDate'));

    try {
      dispatch(setLoading());
      await axios.post(
        `${COMMUNITY_FIXED_ARTICLE}/${prevId}/edit`,
        formData,
        {},
      );
      await navigate('/operation/communityFixArticle');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  const handleBannerImgSetData = (e: string) => {
    setBannerImgFile(e);
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton
              text="저장하기"
              onSubmit={() => addFixedArticle()}
            />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addFixedArticle()}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <CoverImgWrapper>
          {bannerImg ? <EventImg src={bannerImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="bannerImg"
            placeholder={
              bannerImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="배너 이미지(둘러보기 노출)"
            value={bannerImgFile}
            setFile={handleBannerImgSetData}
            setImgUrl={setBannerImg}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="bannerStartDt"
            initialStartDate={bannerStartDt}
            onChange={(e) => setBannerStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="bannerEndDt"
            initialStartDate={bannerEndDt}
            onChange={(e) => setBannerEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 작성자 명" text={memberName} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 작성자 Pid" text={memberPid} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 카테고리" text={communityBoardCategoryIds} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 제목" text={title} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 내용" text={content} />
        </InputWrapper>
        <InputWrapper>
          <TextField label="게시글 작성 일시" text={createdDt} />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default FixArticleDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }

  &:nth-child(2) {
    grid-row: 1;
  }

  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
    margin-bottom: 40px;
  }

  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }

  &:nth-child(7) {
    grid-row: 4;
  }
  &:nth-child(8) {
    grid-row: 4;
  }
  &:nth-child(9) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(10) {
    grid-row: 6;
    grid-column: span 2;
  }
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;
