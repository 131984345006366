export const searchMemberSearchTypeList = [
  // { text: '전체', value: '' },
  { text: '인덱스', value: 'I' },
  { text: 'Pid', value: 'P' },
  { text: '이메일', value: 'E' },
];

export const memberLogSearchTypeList = [
  // { text: '전체', value: '' },
  { text: '이메일', value: 'E' },
  { text: 'Pid', value: 'P' },
];

export const memberResignLogSearchTypeList = [
  // { text: '전체', value: '' },
  { text: '인덱스', value: 'I' },
  { text: 'Pid', value: 'P' },
];

export const memberRestrictOptions = [
  { text: '전체', value: '' },
  { text: '정상', value: 'false' },
  { text: '제재', value: 'true' },
];

export const memberOsOptions = [
  { text: '전체', value: '' },
  { text: 'iOS', value: 'iOS' },
  { text: 'AOS', value: 'AOS' },
];

export const userTypeOptions = [
  { text: '전체', value: '' },
  { text: '이메일', value: 'E' },
  { text: '모바일', value: 'M' },
  { text: '애플', value: 'A' },
  { text: '네이버', value: 'N' },
  { text: '카카오', value: 'K' },
  { text: '페이스북', value: 'F' },
  { text: '구글', value: 'G' },
  { text: '라인', value: 'L' },
];

export const celebTypeOptions = [
  { text: '전체', value: '' },
  { text: '인증 완료', value: 'Y' },
  { text: '미인증', value: 'N' },
];

export const userLogOptions = [
  { text: '전체', value: '' },
  { text: '로그인', value: 'SI' },
  { text: '로그아웃', value: 'SO' },
];
export const searchMemberColList = [75, 140, 170, 100, 100, 140, 55, 110, 70];
export const rewardGiveCurrencySearchMemberColList = [
  75, 140, 170, 100, 100, 140, 55, 110, 70, 70,
];
export const memberHistoryColList = [120, 180, 150, 140, 150, 130, 120];
export const resignMemberColList = [177, 238, 348, 232];
