import {useNavigate} from "react-router-dom";
import React, { useEffect, useState} from "react";
import {
  BAUND_PROJECT,
  BAUND_PROJECT_CAUTION,
} from "../../constant/apiUrl";
import NormalButton from "../../components/common/Button/NormalButton";
import PaginationWithTotalCount
  from "../../components/common/Pagination/PaginationWithTotalCount";
import {postsPerPage} from "../../constant/common";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import styled from "styled-components";
import axios from "axios";
import SelectWithSearch from "../../components/common/Select/SelectWithSearch";

// 바운드 프로젝트 유의사항 목록
const BaundProjectCautions = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [baundProjectCautionList, setBaundProjectCautionList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  // 저장된 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition'));

  // 필터 //////////////////////////////////////////////////////////////////////////////////////////
  const[baundProjectId, setBaundProjectId] = useState(storageSearchCondition?.baundProjectIdForSearch || 0);
  const [pageNumber, setPageNumber] = useState(storageSearchCondition?.pageNumberForSearch || 0);
  const[projectTitle, setProjectTitle] = useState(storageSearchCondition?.projectTitleForSearch ||'공통');

  const[projects, setProjects] = useState();

  // 전체 프로젝트 조회
  const getProjects = async() => {
    try {
      const resp = await axios.get(`${BAUND_PROJECT}-all`);
      setProjects([
          {baundProjectId:0, title:'공통', deleteYn:'N'}, ...resp.data.baundProjects
      ]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjects();

    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  const handleSelectSetData = (e) => {
    setBaundProjectId(e.baundProjectId);
    setProjectTitle(e.title + ((e.deleteYn === "Y") ? '(삭제)':''));
    setPageNumber(0);
  };

  //목록 조회 //////////////////////////////////////////////////////////////////////////////////////
  // 공통 유의사항 화면이므로 0 고정
  const params = {
    baundProjectId: baundProjectId,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage
  };
  const getBaundProjectCautions = async() => {
    await axios.get(`${BAUND_PROJECT_CAUTION}`, {params})
    .then(res => {
      setBaundProjectCautionList(res.data.baundProjectCautions);
      setTotalCount(res.data.totalCount);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => getBaundProjectCautions(), [baundProjectId, pageNumber]);

  // 상세 페이지로 이동
  const moveToDetail = (_type, baundProjectCautionId) => {
    navigate(
        `/baundProject/baundProjectCaution/${_type}`,
        {
          state: {
            baundProjectCautionId: (_type === 'edit') ? baundProjectCautionId : null,
            projectTitle,
            projects,
            baundProjectIdForSearch: baundProjectId,
            pageNumberForSearch: pageNumber,
          }
        }
    );
  };

  const moveToEditSeq = () => {
    navigate(
        `/baundProject/baundProjectCaution/editSeq`,
        {
          state: {
            projectTitle,
            baundProjectIdForSearch: baundProjectId,
            pageNumberForSearch: pageNumber,
          }
        }
    );
  };

  return (
      <>
        <TitleText>유의사항 목록</TitleText>
        <TopContainer>
          <TopWrapper>
            <SearchWrapper>
              <SelectWithSearch placeholder="" label="" className={'widthThin'}
                  value={projectTitle} options={projects} onChange={handleSelectSetData}
              />
            </SearchWrapper>
            <ButtonWrapper>
              <NormalButton text="순서 변경" onSubmit={() => moveToEditSeq()}/>
              <NormalButton text="새로 만들기" onSubmit={() => moveToDetail('add')}/>
            </ButtonWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 유의사항'}
                      currentPage={pageNumber}
                      pageDispatch={(e)=>setPageNumber(e-1)}
                  />
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {baundProjectCautionList.length !== 0 ? (
              <Table
                  colList={[20, 100, 30]}
                  thead={<>
                    <ListHead title="번호" />
                    <ListHead title="유의사항"/>
                    <ListHead title="유의사항 작성일자"/>
                  </>}
              >
                {
                  baundProjectCautionList?.map(el =>
                      <tr key={el.baundProjectCautionId}
                          onClick={() => moveToDetail('edit',
                              el.baundProjectCautionId)
                          }
                      >
                        <td><ListItem text={el.baundProjectCautionId}/></td>
                        <td><ListItem text={el.caution}/></td>
                        <td><ListItem text={el.createDt}/></td>
                      </tr>
                  )
                }
              </Table>
          ) : (
              <VideoModalSubText>검색 결과가 없습니다.</VideoModalSubText>
          )}
        </MainContainer>
      </>
  );
};
export default BaundProjectCautions;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
