import React from 'react';

// common
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';

// hooks
import handleDate from '../../../hooks/handleDate';
import refineContentsType from '../../../hooks/changeReportType';

// constant
import { statusChangeType } from '../../../constant/cs';

interface props {
  data: dataTypes;
  onClick: (data: dataTypes) => void;
  saveStorage: () => void;
}

interface dataTypes {
  id: number;
  contentsId: number;
  contentsType: string;
  description: string;
  masterReportContentsType: string;
  masterReportDescription: string;
  originMemberPid: string;
  tarMemberPid: string;
  createdDt: string;
  reportedDt: string;
  adminName: string;
  statusChangedYn: string;
}

const ExDeleteListRowComponent = ({ data, onClick, saveStorage }: props) => {
  const {
    id,
    contentsType,
    masterReportDescription,
    tarMemberPid,
    originMemberPid,
    createdDt,
    reportedDt,
    adminName,
    statusChangedYn,
  } = data;

  return (
    <tr key={id}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={refineContentsType(contentsType)} />
      </td>
      <td>
        <ListItem text={masterReportDescription} />
      </td>
      <td>
        <ListItem text={tarMemberPid} />
      </td>
      <td>
        <ListItem text={originMemberPid} />
      </td>
      <td>
        <ListItem text={`${handleDate(createdDt)}`} />
      </td>
      <td>
        <ListItem text={adminName} />
      </td>
      <td>
        <ListItem text={`${handleDate(reportedDt)}`} />
      </td>
      <td>
        <ListItem text={statusChangeType[statusChangedYn]} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            onClick(data);
            saveStorage();
          }}
          text="내용"
        />
      </td>
    </tr>
  );
};

export default ExDeleteListRowComponent;
