import React from 'react';
// common
import ListItem from '../../../components/common/List/ListItem';

// hooks
import handleDate from '../../../hooks/handleDate';

// constant
import { memberLoginTypeOptions } from '../../../constant/common';

const MemberHistoryRowComponent = ({ data }: any) => {
  const { os, accessDt, accessIp, device, loginType, osVersion, appVersion } =
    data;

  return (
    <tr>
      <td>
        <ListItem text={memberLoginTypeOptions[loginType]} />
      </td>
      <td>
        <ListItem text={accessDt ? handleDate(accessDt) : '-'} />
      </td>
      <td>
        <ListItem text={os} />
      </td>
      <td>
        <ListItem text={osVersion} />
      </td>
      <td>
        <ListItem text={appVersion} />
      </td>
      <td>
        <ListItem text={device} />
      </td>
      <td>
        <ListItem text={accessIp} />
      </td>
    </tr>
  );
};

export default MemberHistoryRowComponent;
