import React, { useCallback, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import dragAndDrop from '../../../assets/images/page/dragAndDrop.png';
import ListItem from "../../../components/common/List/ListItem";

const BaundProjectCautionEditSeqRow = ({data, moveListItem, index}) => {
  const componentRef = useRef(null);
  const {sequence, baundProjectCautionId, caution, createDt} = data;
  const [, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element, monitor) => {
      // const dragIndex = item.index;
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
          (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
      (node) => {
        componentRef.current = node;
        dragRef(dropRef(node));
      },
      [dragRef, dropRef],
  );
  return (
      <Row ref={applyRef}>
        <img src={dragAndDrop} alt="dnd icon" />
        <ListItem text={sequence} />
        <ListItem text={baundProjectCautionId} />
        <ListItem text={caution} />
        <ListItem text={createDt} />
      </Row>
  );
};

export default BaundProjectCautionEditSeqRow;

const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 2fr 10fr 3fr;
  padding: 27px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  
  img {
    display: block;
    width: 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};
  }
`;
