import * as XLSX from "xlsx";
import {postsPerPage} from "../../../constant/common";

// 전체 리포트 자료형
export class ParsedFullReport {
  constructor(dateUnit) {
    // 날짜
    this.dateUnit = dateUnit;
    // 노출수 - 전체
    this.imprCnt = 0;
    // 노출수 - 유니크
    this.imprUnq = 0;
    // 재생수 - 전체
    this.playCnt = 0;
    // 재생수 - 유니크
    this.playUnq = 0;
    // 클릭수 - 전체
    this.clckCnt = 0;
    // 클릭수 - 유니크
    this.clckUnq = 0;
    // 조회수 - 전체
    this.ldvwCnt = 0;
    // 조회수 - 유니크
    this.ldvwUnq = 0;
    // 참여수 - 전체
    this.engaCnt = 0;
    // 참여수 - 유니크
    this.engaUnq = 0;
    // 클릭률
    this.clckRat = 0;
    // 참여율
    this.engaRat = 0;
  }
}

// 커뮤니티 인피드 배너 리포트 자료형
class CommunityInfeedBannerReport {
  constructor(dateUnit) {
    // 날짜
    this.dateUnit = dateUnit;
    //구좌1 노출수 - 전체/유니크
    this.imprCnt1 = 0;
    this.imprUnq1 = 0;
    //구좌1 재생수 - 전체/유니크
    this.playCnt1 = 0;
    this.playUnq1 = 0;
    //구좌1 클릭수 - 전체/유니크
    this.clckCnt1 = 0;
    this.clckUnq1 = 0;
    //구좌1 조회수 - 전체/유니크
    this.ldvwCnt1 = 0;
    this.ldvwUnq1 = 0;
    //구좌1 참여수 - 전체/유니크
    this.engaCnt1 = 0;
    this.engaUnq1 = 0;
    //구좌1 클릭률
    this.clckRat1 = 0;
    //구좌1 참여율
    this.engaRat1 = 0;

    //구좌2 노출수 - 전체/유니크
    this.imprCnt2 = 0;
    this.imprUnq2 = 0;
    //구좌2 재생수 - 전체/유니크
    this.playCnt2 = 0;
    this.playUnq2 = 0;
    //구좌2 클릭수 - 전체/유니크
    this.clckCnt2 = 0;
    this.clckUnq2 = 0;
    //구좌2 조회수 - 전체/유니크
    this.ldvwCnt2 = 0;
    this.ldvwUnq2 = 0;
    //구좌2 참여수 - 전체/유니크
    this.engaCnt2 = 0;
    this.engaUnq2 = 0;
    //구좌2 클릭률
    this.clckRat2 = 0;
    //구좌2 참여율
    this.engaRat2 = 0;

    //구좌3 노출수 - 전체/유니크
    this.imprCnt3 = 0;
    this.imprUnq3 = 0;
    //구좌3 재생수 - 전체/유니크
    this.playCnt3 = 0;
    this.playUnq3 = 0;
    //구좌3 클릭수 - 전체/유니크
    this.clckCnt3 = 0;
    this.clckUnq3 = 0;
    //구좌3 조회수 - 전체/유니크
    this.ldvwCnt3 = 0;
    this.ldvwUnq3 = 0;
    //구좌3 참여수 - 전체/유니크
    this.engaCnt3 = 0;
    this.engaUnq3 = 0;
    //구좌3 클릭률
    this.clckRat3 = 0;
    //구좌3 참여율
    this.engaRat3 = 0;
  }
}

// 외부용 리포트 헤더
export const BRIEF_HEADERS = [
  {name: '기간', value: 'dateUnit', tooltip:'' },
  {name: '노출수', value: 'imprCnt', tooltip:'' },
  {name: '재생수', value: 'playCnt', tooltip:'' },
  {name: '클릭수', value: 'clckCnt', tooltip:'' },
  {name: '조회수', value: 'ldvwCnt', tooltip:'' },
  {name: '참여수', value: 'engaCnt', tooltip:'' },
  {name: '클릭률', value: 'clckRat', tooltip:'노출수 대비 클릭률' },
  {name: '참여율', value: 'engaRat', tooltip:'클릭수 대비 참여율' },
];

// 리포트 데이터 파싱
export const parseFullReport = (rawReports) => {
  return rawReports.map(report =>{
    let parsedReport = new ParsedFullReport(
        report.dateUnit.slice((report.dateUnit.length >= 8 )?-6:-4)
    );
    report.reportData.forEach(reportData => {
      /** 노출수:	ad_impression (광고가 사용자에게 노출된 횟수) **/
      if (reportData.actionType === 'AD_IMPR') {
        parsedReport.imprCnt = reportData.dataCount;
        parsedReport.imprUnq = reportData.memberCount;
      }
      /** 재생수:	ad_play (사용자가 동영상을 0% 이상 재생한 횟수) **/
      else if (reportData.actionType === 'AD_PLAY') {
        parsedReport.playCnt = reportData.dataCount;
        parsedReport.playUnq = reportData.memberCount;
      }
      /** 클릭수:	ad_click (사용자가 광고를 클릭한 횟수 (이동 유형 '내부'만 해당)) **/
      else if (reportData.actionType === 'AD_CLCK') {
        parsedReport.clckCnt = reportData.dataCount;
        parsedReport.clckUnq = reportData.memberCount;
      }
      /** 조회수:	ad_landingpage_view (사용자가 광고 랜딩페이지 조회한 횟수) **/
      else if (reportData.actionType === 'AD_LDVW') {
        parsedReport.ldvwCnt = reportData.dataCount;
        parsedReport.ldvwUnq = reportData.memberCount;
      }
      /** 참여수:	ad_engagement (외부링크로 넘어가는 버튼 선택한 횟수) **/
      else if (reportData.actionType === 'AD_ENGA') {
        parsedReport.engaCnt = reportData.dataCount;
        parsedReport.engaUnq = reportData.memberCount;
      }
    });
    parsedReport.clckRat = ((parsedReport.clckCnt && parsedReport.imprCnt)
        ? (parsedReport.clckCnt/parsedReport.imprCnt) * 100 : 0).toFixed(2);
    parsedReport.engaRat = ((parsedReport.engaCnt && parsedReport.clckCnt)
        ? (parsedReport.engaCnt/parsedReport.clckCnt) * 100 : 0).toFixed(2);

    return parsedReport;
  });
}

// 리포트 데이터에서 TOTAL 작성
export const processTotalRow = (parsedReports) => {
  let fullReportTotal = new ParsedFullReport('TOTAL');

  parsedReports.forEach(reportData => {
    fullReportTotal.imprCnt += reportData.imprCnt || 0;
    fullReportTotal.imprUnq += reportData.imprUnq || 0;
    fullReportTotal.playCnt += reportData.playCnt || 0;
    fullReportTotal.playUnq += reportData.playUnq || 0;
    fullReportTotal.clckCnt += reportData.clckCnt || 0;
    fullReportTotal.clckUnq += reportData.clckUnq || 0;
    fullReportTotal.ldvwCnt += reportData.ldvwCnt || 0;
    fullReportTotal.ldvwUnq += reportData.ldvwUnq || 0;
    fullReportTotal.engaCnt += reportData.engaCnt || 0;
    fullReportTotal.engaUnq += reportData.engaUnq || 0;
    // TOTAL오의 클릭률/참여율은 날짜별 오의 평균값. 이 계산을 위해 총합을 구하고
    fullReportTotal.clckRat += Number(reportData.clckRat) || 0;
    fullReportTotal.engaRat += Number(reportData.engaRat) || 0;
  });
  // 날짜별 데이터가 있을때는
  if (parsedReports.length) {
    // 데이터 수로 나누어 평균을 구한다.
    fullReportTotal.clckRat = (fullReportTotal.clckRat / parsedReports.length).toFixed(2);
    fullReportTotal.engaRat = (fullReportTotal.engaRat / parsedReports.length).toFixed(2);
  }
  // 없을때는
  else {
    // 0 고정
    fullReportTotal.clckRat = 0;
    fullReportTotal.engaRat = 0;
  }
  // TOTAL 오의 노출수/클릭수/참여수로 새로 계산하는 경우
  // fullReportTotal.clckRat = ((fullReportTotal.clckCnt && fullReportTotal.imprCnt)
  //   ? (fullReportTotal.clckCnt/fullReportTotal.imprCnt) * 100 : 0).toFixed(2);
  // fullReportTotal.engaRat = ((fullReportTotal.engaCnt && fullReportTotal.clckCnt)
  //   ? (fullReportTotal.engaCnt/fullReportTotal.clckCnt) * 100 : 0).toFixed(2);

  return fullReportTotal;
}

// 내부용 리포트를 위한 워크 시트
export const getFullReportWorkSheet = (parsedReportTotal, parsedReports) => {

  // 출력 데이터 만들기
  let exportArr = [];
  // 예외처리 userList가 있을 경우에만 Export
  if (parsedReports.length > 0) {
    exportArr.push( {
      dateUnit:parsedReportTotal.dateUnit,
      imprCnt:parsedReportTotal.imprCnt, imprUnq:parsedReportTotal.imprUnq,
      playCnt:parsedReportTotal.playCnt, playUnq:parsedReportTotal.playUnq,
      clckCnt:parsedReportTotal.clckCnt, clckUnq:parsedReportTotal.clckUnq,
      ldvwCnt:parsedReportTotal.ldvwCnt, ldvwUnq:parsedReportTotal.ldvwUnq,
      engaCnt:parsedReportTotal.engaCnt, engaUnq:parsedReportTotal.engaUnq,
      clckRat:parsedReportTotal.clckRat, engaRat:parsedReportTotal.engaRat,
    });
    for (let i in parsedReports) {
      // 데이터 가공
      const processedData = {
        dateUnit:parsedReports[i].dateUnit,
        imprCnt:parsedReports[i].imprCnt, imprUnq:parsedReports[i].imprUnq,
        playCnt:parsedReports[i].playCnt, playUnq:parsedReports[i].playUnq,
        clckCnt:parsedReports[i].clckCnt, clckUnq:parsedReports[i].clckUnq,
        ldvwCnt:parsedReports[i].ldvwCnt, ldvwUnq:parsedReports[i].ldvwUnq,
        engaCnt:parsedReports[i].engaCnt, engaUnq:parsedReports[i].engaUnq,
        clckRat:parsedReports[i].clckRat, engaRat:parsedReports[i].engaRat,
      };
      exportArr.push(processedData);
    }
  }

  // 시트에 추가
  const workSheet = XLSX.utils.json_to_sheet(exportArr);
  // 제목 추가
  XLSX.utils.sheet_add_aoa(workSheet,
      [[
        '기간',
        '노출수 전체', '노출수 유니크', '재생수 전체', '재생수 유니크',
        '클릭수 전체', '클릭수 유니크', '조회수 전체', '조회수 유니크',
        '참여수 전체', '참여수 유니크', '클릭률 전체', '참여율 전체'
      ]]);
  return workSheet;
}

// 외부용 리포트를 위한 워크 시트
export const getBriefReportWorkSheet = (parsedReportTotal, parsedReports) => {

  // 출력 데이터 만들기
  let exportArr = [];
  // 예외처리 userList가 있을 경우에만 Export
  if (parsedReports.length > 0) {
    exportArr.push( {
      dateUnit:parsedReportTotal.dateUnit,
      imprCnt:parsedReportTotal.imprCnt,
      playCnt:parsedReportTotal.playCnt,
      clckCnt:parsedReportTotal.clckCnt,
      ldvwCnt:parsedReportTotal.ldvwCnt,
      engaCnt:parsedReportTotal.engaCnt,
      clckRat:parsedReportTotal.clckRat,
      engaRat:parsedReportTotal.engaRat,
    });
    for (let i in parsedReports) {
      // 데이터가공
      exportArr.push({
        dateUnit:parsedReports[i].dateUnit,
        imprCnt:parsedReports[i].imprCnt,
        playCnt:parsedReports[i].playCnt,
        clckCnt:parsedReports[i].clckCnt,
        ldvwCnt:parsedReports[i].ldvwCnt,
        engaCnt:parsedReports[i].engaCnt,
        clckRat:parsedReports[i].clckRat,
        engaRat:parsedReports[i].engaRat,
      });
    }
  }

  // 시트에 추가
  const workSheet = XLSX.utils.json_to_sheet(exportArr);
  // 제목 추가
  XLSX.utils.sheet_add_aoa(workSheet, [BRIEF_HEADERS.map(e => e.name)]);

  return workSheet;
}

// 커뮤니티 인피드 배너 리포트를 위한 워크 시트
export const getCommunityInfeedBannerReportWorkSheet = (rawData) => {
  // 구좌별 정보 파싱
  let exportArr = rawData.map(report =>{
    let dataRow = new CommunityInfeedBannerReport(report.dateUnit.slice((report.dateUnit.length >= 8 )?-6:-4));

    report.reportData.forEach(reportData => {
      /** 노출수:	ad_impression (광고가 사용자에게 노출된 횟수) **/
      if (reportData.actionType === 'AD_IMPR') {
        dataRow['imprCnt' + reportData.accountIndex] = reportData.dataCount;
        dataRow['imprUnq' + reportData.accountIndex] = reportData.memberCount;
      }
      /** 재생수:	ad_play (사용자가 동영상을 0% 이상 재생한 횟수) **/
      else if (reportData.actionType === 'AD_PLAY') {
        dataRow['playCnt' + reportData.accountIndex] = reportData.dataCount;
        dataRow['playUnq' + reportData.accountIndex] = reportData.memberCount;
      }
      /** 클릭수:	ad_click (사용자가 광고를 클릭한 횟수 (이동 유형 '내부'만 해당)) **/
      else if (reportData.actionType === 'AD_CLCK') {
        dataRow['clckCnt' + reportData.accountIndex] = reportData.dataCount;
        dataRow['clckUnq' + reportData.accountIndex] = reportData.memberCount;
      }
      /** 조회수:	ad_landingpage_view (사용자가 광고 랜딩페이지 조회한 횟수) **/
      else if (reportData.actionType === 'AD_LDVW') {
        dataRow['ldvwCnt' + reportData.accountIndex] = reportData.dataCount;
        dataRow['ldvwUnq' + reportData.accountIndex] = reportData.memberCount;
      }
      /** 참여수:	ad_engagement (외부링크로 넘어가는 버튼 선택한 횟수) **/
      else if (reportData.actionType === 'AD_ENGA') {
        dataRow['engaCnt' + reportData.accountIndex] = reportData.dataCount;
        dataRow['engaUnq' + reportData.accountIndex] = reportData.memberCount;
      }
    });

    for( let index = 1; index <= 3; index++) {
      dataRow['clckRat' + index] = (dataRow['clckCnt' + index] && dataRow['imprCnt' + index])
          ? ((dataRow['clckCnt' + index] / dataRow['imprCnt' + index]) * 100).toFixed(2) : 0;
      dataRow['engaRat' + index] = (dataRow['engaCnt' + index] && dataRow['clckCnt' + index])
          ? ((dataRow['engaCnt' + index] / dataRow['clckCnt' + index]) * 100).toFixed(2) : 0;
    }

    return dataRow;
  });

  // TOTAL row 생성
  let parsedReportTotal = new CommunityInfeedBannerReport('TOTAL');
  // TOTAL row 계산
  for (let i in exportArr) {
    for( let index = 1; index <= 3; index++) {
      parsedReportTotal['imprCnt'+ index] += exportArr[i]['imprCnt'+ index];
      parsedReportTotal['imprUnq'+ index] += exportArr[i]['imprUnq'+ index];
      parsedReportTotal['playCnt'+ index] += exportArr[i]['playCnt'+ index];
      parsedReportTotal['playUnq'+ index] += exportArr[i]['playUnq'+ index];
      parsedReportTotal['clckCnt'+ index] += exportArr[i]['clckCnt'+ index];
      parsedReportTotal['clckUnq'+ index] += exportArr[i]['clckUnq'+ index];
      parsedReportTotal['ldvwCnt'+ index] += exportArr[i]['ldvwCnt'+ index];
      parsedReportTotal['ldvwUnq'+ index] += exportArr[i]['ldvwUnq'+ index];
      parsedReportTotal['engaCnt'+ index] += exportArr[i]['engaCnt'+ index];
      parsedReportTotal['engaUnq'+ index] += exportArr[i]['engaUnq'+ index];
      // TOTAL오의 클릭률/참여율은 날짜별 오의 평균값. 이 계산을 위해 총합을 구하고
      parsedReportTotal['clckRat'+ index] += Number(exportArr[i]['clckRat'+ index]);
      parsedReportTotal['engaRat'+ index] += Number(exportArr[i]['engaRat'+ index]);
    }
  }
  // 날짜별 데이터가 있을때는
  if(exportArr.length) {
    for( let index = 1; index <= 3; index++) {
      // 데이터 수로 나누어 평균을 구한다.
      parsedReportTotal['clckRat'+ index] = (parsedReportTotal['clckRat'+ index] / exportArr.length).toFixed(2);
      parsedReportTotal['engaRat'+ index] = (parsedReportTotal['engaRat'+ index] / exportArr.length).toFixed(2);
    }
  }
  // 없을때는
  else {
    for( let index = 1; index <= 3; index++) {
      // 0 고정
      parsedReportTotal['clckRat'+ index] = 0;
      parsedReportTotal['engaRat'+ index] = 0;
    }
  }
  // TOTAL 오의 노출수/클릭수/참여수로 새로 계산하는 경우
  // for( let index = 1; index <= 3; index++) {
  //   parsedReportTotal['clckRat'+ index] = (parsedReportTotal['clckCnt'+ index] && parsedReportTotal['imprCnt'+ index])
  //       ? ((parsedReportTotal['clckCnt'+ index]/parsedReportTotal['imprCnt'+ index]) * 100).toFixed(2) : 0;
  //   parsedReportTotal['engaRat'+ index] = (parsedReportTotal['engaCnt'+ index] && parsedReportTotal['clckCnt'+ index])
  //       ? ((parsedReportTotal['engaCnt'+ index]/parsedReportTotal['clckCnt'+ index]) * 100).toFixed(2) : 0;
  // }

  // 열 순서를 sheet화
  const workSheet = XLSX.utils.json_to_sheet([parsedReportTotal, ...exportArr]);

  // ws에 excelTableHead를 추가
  XLSX.utils.sheet_add_aoa(workSheet, [[
    '기간',
    '구좌1 노출수 전체',	'구좌1 노출수 유니크', '구좌1 재생수 전체',	'구좌1 재생수 유니크',
    '구좌1 클릭수 전체',	'구좌1 클릭수 유니크', '구좌1 조회수 전체',	'구좌1 조회수 유니크',
    '구좌1 참여수 전체',	'구좌1 참여수 유니크', '구좌1 클릭률 전체',	'구좌1 참여율 전체',
    '구좌2 노출수 전체',	'구좌2 노출수 유니크', '구좌2 재생수 전체',	'구좌2 재생수 유니크',
    '구좌2 클릭수 전체',	'구좌2 클릭수 유니크', '구좌2 조회수 전체',	'구좌2 조회수 유니크',
    '구좌2 참여수 전체',	'구좌2 참여수 유니크', '구좌2 클릭률 전체',	'구좌2 참여율 전체',
    '구좌3 노출수 전체',	'구좌3 노출수 유니크', '구좌3 재생수 전체',	'구좌3 재생수 유니크',
    '구좌3 클릭수 전체',	'구좌3 클릭수 유니크', '구좌3 조회수 전체',	'구좌3 조회수 유니크',
    '구좌3 참여수 전체',	'구좌3 참여수 유니크', '구좌3 클릭률 전체',	'구좌3 참여율 전체'
  ]]);

  // XLSX.utils.sheet_add_json(workSheet, exportArr, { origin: "A2", skipHeader: true });

  return workSheet;
}

// 두 날짜 간에 차이 구하기
export const getLimitCount = (startDateTime, endDateTime, timeUnit) => {
  if(!startDateTime || !endDateTime) {
    return postsPerPage;
  }
  const startDate = new Date(startDateTime);
  const endDate = new Date(endDateTime);

  let diffDate = endDate.getTime() - startDate.getTime();

  diffDate = (timeUnit === 'DAYS')
      ? Math.abs(diffDate / (1000 * 60 * 60 * 24))
      : Math.abs(diffDate / (1000 * 60 * 60 * 24 * 30))

  return Math.ceil(diffDate) + 1
}