// 배틀 검색 타입
export const battleSearchTypeValues = {
    title: '제목',
    member: '회원 아이디',
    article: '플레이 아이디',
};

// 배틀 타입 선택
export const battleTypeValues = {
    SPECIAL: '스페셜 배틀',
    SPECIAL_PICK: '스페셜 픽',
    NORMAL: '일반 배틀',
};

// 배틀 상태 선택
export const battleStatusValues = {
    VOTING: 'VOTING',
    BEGIN: 'BEGIN',
    SPARK: 'SPARK',
};

export const battleAnyIsValues = {
    RANKING: '랭킹',
    SPECIAL_PICK: '스페셜 픽',
}