import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import RadioButton from '../../../../components/common/Button/RadioButton';
import DateInput from '../../../../components/common/Input/DateInput';

// asset
import blankImg from '../../../../assets/images/page/blankImg.png';

// hook
import handleDate from '../../../../hooks/handleDate';
import { useAppSelector } from '../../../../redux/store/hooks';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// config
import { HOT_BEAT } from '../../../../constant/apiUrl';

const HotBeatEdit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const adminName = useAppSelector((state) => state.user.user.name);
  const locationData: any = location.state;
  const { detailData, tabType } = locationData.genreTabData;
  const [beatData, setBeatData] = useState(locationData.beat);
  const {
    id,
    beatCoverImgUrl,
    beatTitle,
    exposeStartDt,
    exposeEndDt,
    activeYn,
  } = beatData;
  const initialStartDate = exposeStartDt?.replace(' ', 'T').slice(0, 16);
  const initialEndDate = exposeEndDt?.replace(' ', 'T').slice(0, 16);

  // 뒤로가기
  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  // input Date 컨트롤
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setBeatData({ ...beatData, [name]: value });
    },
    [beatData],
  );

  // 발매 예정 비트 수정하기
  const requestHotBeat = useCallback(async () => {
    const exposeStartDate = `${exposeStartDt
      .replace('T', ' ')
      .slice(0, 16)}:00`;
    const exposeEndDate = `${exposeEndDt.replace('T', ' ').slice(0, 16)}:59`;
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(exposeEndDate);

    const params = {
      adminName,
      activeYn,
      exposeStartDate,
      exposeEndDate,
    };

    try {
      if (exposeStartDt > exposeEndDt) {
        alert('시작일시가 종료일시보다 느릴 수 없습니다.');
        return;
      }

      if (compareEndDate) {
        alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
        return;
      }

      dispatch(setLoading());

      await axios.post(`${HOT_BEAT}/${id}`, {}, { params });
      alert('수정되었습니다.');

      // 전체탭 수정
      if (Object.keys(detailData).length === 0) {
        navigate('/explore/allTab/hotBeat');
        return;
      }

      // 장르탭 수정
      if (detailData?.masterGenreId !== 0) {
        navigate('/explore/genreTab/hotBeat', {
          state: { detailData, tabType },
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  }, [
    activeYn,
    adminName,
    detailData,
    dispatch,
    exposeEndDt,
    exposeStartDt,
    id,
    navigate,
    tabType,
  ]);

  return (
    <>
      <TopContainer>
        <ProfileWrapper>
          {beatCoverImgUrl ? (
            <Img src={beatCoverImgUrl} alt="coverImg" />
          ) : (
            <Img src={blankImg} alt="defaultImg" />
          )}
          <ProfileTitleWrapper>
            <Title>{beatTitle}</Title>
            <DateTitle>
              {`${handleDate(exposeStartDt)} ~ ${handleDate(exposeEndDt)}`}
            </DateTitle>
          </ProfileTitleWrapper>
        </ProfileWrapper>
        <ButtonWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
          <NormalButton text="수정하기" onSubmit={requestHotBeat} />
        </ButtonWrapper>
      </TopContainer>
      <LineDiv />
      <MainContainer>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateWrapper>
            <DateInput
              label="노출기간(시작일)"
              name="exposeStartDt"
              onChange={handleInputSetData}
              initialStartDate={initialStartDate}
            />
            <DateInput
              label="노출기간(종료일)"
              name="exposeEndDt"
              onChange={handleInputSetData}
              initialStartDate={initialEndDate}
            />
          </DateWrapper>
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default HotBeatEdit;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ProfileWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  object-fit: contain;
  margin-right: 30px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const Title = styled.h1`
  height: 45px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const DateTitle = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.subGray};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 40px 0;
`;

const MainContainer = styled.div`
  width: 100%;
  //margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 60px;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
    //margin-bottom: 40px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;
