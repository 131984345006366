import React, { useState, useEffect, useCallback, Fragment } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NotExposure from '../../../../components/UI/NotExposure';
import blankImg from '../../../../assets/images/page/blankImg.png';
import NormalButton from '../../../../components/common/Button/NormalButton';

// 타입
import { RecentlyBeatType } from '../../../../definitions/explore';

// config
import { EXPLORE, RECENTLY_BEAT } from '../../../../constant/apiUrl';

// hooks
import handleDate from '../../../../hooks/handleDate';

const RecentlyBeat = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { detailData = {}, tabType = 'ALL' }: any = location.state || {};
  const [listType, setListType] = useState(true);
  const [recentlyList, setRecentlyList] =
    useState<Array<RecentlyBeatType> | null>(null);
  const getRecentlyBeatList = useCallback(
    async (type: boolean) => {
      const params = { limit: 100, offset: 0, type: '' };
      try {
        if (!type) {
          params.type = 'disable';
        }
        if (tabType === 'GENRE') {
          const resp = await axios.get(
            `${EXPLORE}/${detailData?.masterGenreId}/master-recently-beat`,
            { params },
          );
          setRecentlyList(resp.data.masterRecentlyBeatList);
        }
        if (tabType === 'ALL') {
          const resp = await axios.get(RECENTLY_BEAT, { params });
          setRecentlyList(resp.data.masterRecentlyBeatList);
        }
      } catch (err) {
        setRecentlyList([]);
        console.log(err);
        alert('잠시후 다시 시도해 주세요.');
      }
    },
    [detailData?.masterGenreId, tabType],
  );

  // genreTabData
  useEffect(() => {
    getRecentlyBeatList(listType);
  }, [getRecentlyBeatList, listType]);

  return (
    <Container>
      <RoundButton
        title="공개"
        selected={listType}
        onClick={() => {
          setListType(true);
        }}
      />
      <RoundButton
        title="비공개"
        selected={!listType}
        onClick={() => {
          setListType(false);
        }}
      />
      {recentlyList?.length !== 0 ? (
        <MainContainer>
          {recentlyList?.map((item) => (
            <Fragment key={item.id}>
              <Item>
                {item.coverImgUrl ? (
                  <Img src={item.coverImgUrl} alt="coverImg" />
                ) : (
                  <Img src={blankImg} alt="defaultImg" />
                )}
                <TextWrapper>
                  <Title>
                    <span>{item.id}.</span> {item.title}
                  </Title>
                  <SubTitle>
                    {item.titleEn} / {item.titleEs}
                  </SubTitle>
                  <ButtonWrapper>
                    <Date>{`${handleDate(item.updateDt)} 수정`}</Date>
                    <NormalButton
                      text="수정하기"
                      onSubmit={() => {
                        navigate('edit', { state: item });
                      }}
                    />
                  </ButtonWrapper>
                </TextWrapper>
              </Item>
              <LineDiv />
            </Fragment>
          ))}
        </MainContainer>
      ) : (
        <NotExposure
          title="최신 비트"
          link="add"
          onSubmit={() => {
            if (Object.keys(detailData).length !== 0) {
              navigate('add', {
                state: { masterGenreId: detailData.masterGenreId },
              });
            } else {
              navigate('add', { state: {} });
            }
          }}
        />
      )}
    </Container>
  );
};

export default RecentlyBeat;

const Container = styled.div`
  padding-top: 20px;
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 175px;
`;

const Item = styled.div`
  display: flex;
  margin: 40px 0;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};

  &:last-child {
    display: none;
  }
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  margin-right: 30px;
  border-radius: 3px;
`;

const TextWrapper = styled.div`
  width: calc(100% - 205px);
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  height: 45px;
  margin-bottom: 10px;
  flex-grow: 0;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};

  span {
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const SubTitle = styled.div`
  margin-bottom: 16px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.mainBlack};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const Date = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.subGray};
`;
