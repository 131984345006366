import React, { useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import axios from 'axios';
import styled from 'styled-components';

// common
import TextField from '../../components/common/TextField';
import NormalButton from '../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import LargeRoundButton from '../../components/common/Button/LargeRoundButton';
import DateInput from '../../components/common/Input/DateInput';
import SearchDataListInput from '../../components/common/Input/SearchDataListInput';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';

// hook
import { useAppSelector } from '../../redux/store/hooks';

// config
import {
  SEARCH_MEMBER,
  RESTRICT_MEMBER,
  MEMBER_RESTRICT_HISTORY,
} from '../../constant/apiUrl';

// constant
import { memberSearchTypeList } from '../../constant/cs';

// component
import ResignMemberRowComponent from './components/RestrictMemberRowComponent';

const initialMemberData = {
  id: 0,
  email: '',
  pid: '',
  reportScore: '',
  reportScoreExpireDt: '',
};

const initialType = {
  title: '',
  value: 0,
};

const RestrictMember = () => {
  const location = useLocation();
  const adminId = useAppSelector((state) => state.user.user.id);
  const [searchType, setSearchType] = useState('P');
  const [word, setWord] = useState('');
  const [type, setType] = useState(initialType);
  const [memberData, setMemberData] = useState(initialMemberData);
  const [searchMemberList, setSearchMemberList] = useState<any>([]);
  const [restrictDate, setRestrictDate] = useState('');
  const [restrictList, setRestrictList] = useState([]);
  const tarMemberId: any = location.state;
  const nowDate = `${new Date().toISOString().slice(0, 16)}:00`;
  const { email, id, pid, reportScore, reportScoreExpireDt } = memberData;

  // 검색 타입 입력
  const handleSelect = (e: React.MouseEvent<HTMLLIElement>, setState: any) => {
    setState(e.currentTarget.title);
  };

  const getMemberRestrictData = (option: any) => {
    setMemberData(option);
    setRestrictDate(option.reportScoreExpireDt || '');
  };

  // 멤버 계정 정지 로그 조회
  const getMemberRestrictHistory = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${MEMBER_RESTRICT_HISTORY}/${id}/restrict`,
        { params: { limit: 100, offset: 0 } },
      );
      setRestrictList(resp.data.restrictLogList);
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  // 계정 찾기
  const findMemberData = useCallback(
    async (memberId = 0) => {
      const params = {
        type: searchType,
        word,
        limit: 100,
        offset: 0,
      };

      if (tarMemberId) {
        params.type = 'I';
        params.word = tarMemberId;
      }

      if (memberId !== 0) {
        params.type = 'I';
        params.word = memberId;
      }

      try {
        const resp = await axios.get(SEARCH_MEMBER, {
          params,
        });

        if (word) {
          if (memberId !== 0) {
            setMemberData(resp.data.memberList[0]);
            setRestrictDate(resp.data.memberList[0].reportScoreExpireDt || '');
          }
          if (resp.data.memberList.length > 0) {
            setSearchMemberList(resp.data.memberList);
            return;
          }
          setSearchMemberList([]);
          return;
        }
        setMemberData(resp.data.memberList[0]);
        setSearchMemberList(resp.data.memberList);
        setRestrictDate(resp.data.memberList[0].reportScoreExpireDt || '');
      } catch (err) {
        alert('검색하신 계정을 찾을 수 없습니다.');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchType, word, memberData.id],
  );
  // 우측 날짜 버튼 클릭 이벤트
  const handleButton = (e: any) => {
    const { title, value } = e.target;
    setType({ title, value });
  };

  // 제재 날짜 구하기
  const getRestrictDate = useCallback(() => {
    const now = new Date();
    let result = new Date(restrictDate);
    if (reportScoreExpireDt === null || now > result) {
      result = new Date();
    }
    const resultTime = new Date(
      result.getTime() - now.getTimezoneOffset() * 60000,
    );
    const params = { adminId, restrictDate };
    const days = Number(type.value);

    // 오늘날짜기준 하루 빼고 적용
    if (days === -1) {
      // utc.setHours(now.getHours() - 7);
      now.setHours(now.getHours() - 10);
      const clearDate = new Date(
        now.getTime() - now.getTimezoneOffset() * 60000,
      );

      params.restrictDate = clearDate
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      return params;
    }
    if (days === 7) {
      resultTime.setDate(result.getDate() + days);
      params.restrictDate = resultTime
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      return params;
    }
    if (days === 30) {
      resultTime.setDate(result.getDate() + days);
      params.restrictDate = resultTime
        .toISOString()
        .slice(0, 19)
        .replace('T', ' ');
      return params;
    }
    if (days === 9999) {
      params.restrictDate = '9999-12-31 23:59:59';
      return params;
    }

    resultTime.setDate(result.getDate());
    // console.log(
    //   'resultTime.setDate(result.getDate()>>>>',
    //   resultTime.setDate(result.getDate()),
    // );
    params.restrictDate = resultTime
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    return params;
  }, [adminId, reportScoreExpireDt, restrictDate, type.value]);

  // input Date 컨트롤
  const handleInputSetData = (e: any) => {
    const { value } = e.target;
    setRestrictDate(value);
  };

  // 계정 제재하기
  const restrictMember = useCallback(async () => {
    if (!id) {
      alert('유저를 선택해주세요.');
      return;
    }
    try {
      await axios.post(
        `${RESTRICT_MEMBER}/${id}`,
        {},
        {
          params: getRestrictDate(),
        },
      );
      await findMemberData(id);
      await setType(initialType);
      await getMemberRestrictHistory();
    } catch (err) {
      alert('잠시후 다시 시도해주세요.');
    }
  }, [findMemberData, getMemberRestrictHistory, getRestrictDate, id]);

  useEffect(() => {
    if (tarMemberId || word) {
      findMemberData();
    }
    if (!word) {
      setSearchMemberList([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tarMemberId, word]);

  useEffect(() => {
    if (id !== 0) {
      getMemberRestrictHistory();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reportScoreExpireDt]);

  return (
    <MainContainer>
      <ContentContainer>
        <MainText>계정 검색</MainText>
        <SearchWrapper>
          <SearchMenuSelect
            options={memberSearchTypeList}
            onClick={handleSelect}
            setState={setSearchType}
          />
          <SearchInputWrapper>
            <SearchDataListInput
              value={word}
              setValue={setWord}
              // onKeyPress={enterKeyPress}
              options={searchMemberList}
              selectedMember={getMemberRestrictData}
              optionType={searchType}
            />
          </SearchInputWrapper>
        </SearchWrapper>
        {pid && (
          <ContentWrapper>
            <TextFiledWrapper>
              <TextField text={pid} label="Pid" />
            </TextFiledWrapper>
            <TextFiledWrapper>
              <TextField text={email || '-'} label="이메일" />
            </TextFiledWrapper>
            <TextFiledWrapper>
              <TextField
                text={
                  reportScoreExpireDt === null ||
                  Date.parse(nowDate) > Date.parse(reportScoreExpireDt)
                    ? '정상'
                    : '제재'
                }
                label="계정 상태"
              />
            </TextFiledWrapper>
            <TextFiledWrapper>
              <TextField
                text={reportScore !== null ? reportScore : '-'}
                label="벌점 현황"
              />
            </TextFiledWrapper>
            {restrictList.length !== 0 && (
              <TableContainer>
                <TableHead>제재 로그</TableHead>
                <Table
                  thead={
                    <>
                      <ListHead title="제재 처리일" />
                      <ListHead title="제재 처리자" />
                      <ListHead title="제재 기간" />
                    </>
                  }
                  colList={[100, 120, 220]}
                >
                  {restrictList?.map((el: any) => (
                    <ResignMemberRowComponent key={el.id} data={el} />
                  ))}
                </Table>
              </TableContainer>
            )}
          </ContentWrapper>
        )}
      </ContentContainer>
      <ContentContainer>
        <MainText>제재 설정</MainText>
        <ButtonContainer>
          <LargeRoundButton
            title="7일"
            value={7}
            onClick={handleButton}
            selected={type.title === '7일' && true}
          />
          <LargeRoundButton
            title="30일"
            value={30}
            selected={type.title === '30일' && true}
            onClick={handleButton}
          />
          <LargeRoundButton
            title="영구 제재"
            value={9999}
            selected={type.title === '영구 제재' && true}
            onClick={handleButton}
          />
          <LargeRoundButton
            title="제재 해제"
            value={-1}
            selected={type.title === '제재 해제' && true}
            onClick={handleButton}
          />
        </ButtonContainer>
        <InputWrapper>
          <DateInput
            label="제재 종료일"
            name="startDt"
            initialStartDate={restrictDate?.replace(' ', 'T').slice(0, 16)}
            onChange={handleInputSetData}
            className={
              Date.parse(nowDate) > Date.parse(restrictDate) ? 'restrict' : ''
            }
          />
        </InputWrapper>
        <SubmitButtonContainer>
          <NormalButton text="제재하기" onSubmit={restrictMember} />
        </SubmitButtonContainer>
      </ContentContainer>
    </MainContainer>
  );
};

export default RestrictMember;

const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 50px 0 100px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  min-height: 800px;
  border-right: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  padding-right: 36px;

  &:last-child {
    width: 60%;
    padding-right: 0;
    margin-left: 36px;
    border-right: 0;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const SearchInputWrapper = styled.div``;

const MainText = styled.span`
  margin-bottom: ${(props) =>
    props.className === 'restrict' ? '30px' : '40px'};
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
`;

const TextFiledWrapper = styled.div`
  margin-bottom: 20px;
`;

const TableHead = styled.span`
  display: block;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const TableContainer = styled.div`
  margin: 30px 0;
`;

const InputWrapper = styled.div`
  margin: 50px 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  gap: 20px;
  grid-gap: 20px;
`;

const SubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
