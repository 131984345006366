import { css } from 'styled-components';
import media from './media';

const font = {
  titleFont: css`
    font-size: 36px;
    font-weight: 600;
    color: #343a40;
  `,
  listFont: css`
    font-size: 18px;
    font-weight: 600;
    color: #343a40;
  `,
};

const color = {
  mainRed: '#ff3800',
  mainBlack: '#343a40',
  mainWhite: '#ffffff',
  mainPurple: '#8f43ff',
  mainBlue: '#0080FF',
  subUnactiveGray: '#e2e4e7',
  subGray: '#adb5bd',
  subDarkGray: '#5a626a',
  subBrightGray: '#f1f3f5',
};

const shadow = {
  border: '1px solid black',
};

const boxShadow = {
  default: '0 10px 40px 0 rgba(0, 0, 0, 0.1)',
};

export const theme = {
  font,
  color,
  media,
  shadow,
  boxShadow,
};

export type Theme = typeof theme;
