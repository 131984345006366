import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// common
import NormalButton from '../../components/common/Button/NormalButton';
import SearchInput from '../../components/common/Input/SearchInput';
import ColumnList from '../../components/common/List/ColumnList';
import Pagination from '../../components/common/Pagination';

//hooks
import { useAppSelector } from '../../redux/store/hooks';
import useInput from '../../hooks/useInput';
import saveStorage from '../../hooks/saveStorage';

//
import { postsPerPage } from '../../constant/common';

// 액션
import { getDetailGenre, resetDetailGenre } from '../../redux/slices/genre';
import {
  getParentGenreList,
  getGenreList,
} from '../../redux/actions/genre/genre';

// 타입
interface GenreList {
  name: string;
  id: number;
  genre?: string;
  genreImgUrl?: string;
  code?: string;
}

const Genre = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const genreList = useAppSelector((state) => state.genre.genreList);
  const getNumber = localStorage.getItem('pageNumber');
  const getWord = localStorage.getItem('word');

  // pagination state
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );
  const [word, setWord] = useInput(getWord || '');
  const count = useAppSelector((state) => state.genre.genreCount);
  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const params = {
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      dispatch(getGenreList(params));
    }
  };

  const goToDetail = (e: any) => {
    dispatch(getDetailGenre(e));
    // 페이지 저장하기
    saveStorage(pageNumber, '', word);
    navigate('/beat/genre/detail');
  };

  useEffect(() => {
    if (getNumber || getWord) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('word', '');
    }
  }, [getNumber, getWord]);

  useEffect(() => {
    if (word) {
      dispatch(getGenreList(params));
      return;
    }
    dispatch(getParentGenreList(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, pageNumber]);

  return (
    <>
      <TopContainer>
        <SearchWrapper>
          <SearchInputWrapper>
            <SearchInput
              placeholder="검색하실 장르를 입력해주세요."
              value={word}
              onChange={setWord}
              onKeyPress={enterKeyPress}
            />
          </SearchInputWrapper>
          <NormalButton
            text="장르 추가하기"
            onSubmit={() => {
              dispatch(resetDetailGenre());
              navigate('/beat/genre/add');
            }}
            className=""
          />
        </SearchWrapper>
        <PaginationWrapper>
          <Title>{count}개의 장르</Title>
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={Number(count)}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        {genreList?.map((genre: GenreList) => {
          return (
            <ColumnList
              menu={genre}
              type="genre"
              goToDetail={goToDetail}
              key={Math.random()}
            />
          );
        })}
      </MainContainer>
    </>
  );
};

export default Genre;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 25px;
  padding-bottom: 50px;
`;

export const Title = styled.span`
  ${({ theme }) => theme.font.titleFont};
`;
