import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import leftArrow from '../../../assets/images/page/leftArrow.png';
import rightArrow from '../../../assets/images/page/rightArrow.png';
import firstPageArrow from '../../../assets/images/page/firstPageArrow.png';
import lastPageArrow from '../../../assets/images/page/lastPageArrow.png';

interface PropsType {
	postsPerPage: number;
	totalCount: number;
	currentPage: number;
	pageDispatch: (number: any) => void;
}

const Pagination = ({postsPerPage, totalCount, currentPage, pageDispatch}: PropsType) => {
	const totalPageNumbers: any = [];
	const [page, setPage] = useState(currentPage + 1);

	for (let i = 1; i <= Math.ceil(totalCount / postsPerPage); i++) {
		totalPageNumbers.push(i);
	}

	const showCount = 5;

	const showPages = (selectedPage: number) => {
		const last = totalPageNumbers[totalPageNumbers.length - 1];
		if (selectedPage < 1 || page > last) return null;

		const side = Math.floor(showCount / 2);
		const sIdx = totalPageNumbers.indexOf(
			selectedPage <= side
				? 1
				: selectedPage >= last - side
					? last - showCount + 1
					: page - side,
		);

		if (totalPageNumbers.length > 3) {
			if (selectedPage === last)      return totalPageNumbers.slice(sIdx + 2, sIdx + showCount);
			if (selectedPage === last - 1)  return totalPageNumbers.slice(sIdx + 1, sIdx + showCount);
		}

		return totalPageNumbers.slice(sIdx, sIdx + showCount);
	};

	useEffect(() => setPage(currentPage + 1), [currentPage]);

	return (
		<Container>
			{/* << < */}
			{currentPage !== 0 && <>
				<ImgButton
					type="button"
					onClick={() => {
						if (pageDispatch) pageDispatch(1);
						setPage(1);
					}}
				><img alt="firstPageArrow" src={firstPageArrow} /></ImgButton>

				<ImgButton
					type="button"
					onClick={() => {
						if (pageDispatch) pageDispatch(page - 1);
						setPage(page - 1);
					}}
				><img alt="leftArrow" src={leftArrow} /></ImgButton>
			</>}

			{/* 숫자들 */}
			<Ul>
				{showPages(page)?.map((number: any) =>
					<li className={String(page === number)} key={number}>
						<Button
							type="button"
							onClick={() => {
								if (pageDispatch) pageDispatch(number);
								setPage(number);
							}}
							className={String(page === number)}
						>{number}</Button>
					</li>
				)}
			</Ul>

			{/* > >> */}
			{totalPageNumbers.length !== currentPage + 1 && <>
				<ImgButton
					type="button"
					onClick={() => {
						if (pageDispatch) pageDispatch(page + 1);
						setPage(page + 1);
					}}
				><img alt="rightArrow" src={rightArrow} /></ImgButton>

				<ImgButton
					type="button"
					onClick={() => {

						if (pageDispatch) pageDispatch(totalPageNumbers.length);
						setPage(totalPageNumbers.length);
					}}
				><img alt="lastPageArrow" src={lastPageArrow} /></ImgButton>
			</>}
		</Container>
	);
};

export default Pagination;

const Container = styled.div`
  display: flex;
  justify-content: right;
`;

const ImgButton = styled.button`
  width: 30px;
  height: 30px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Ul = styled.ul`
  display: flex;
  margin: 0 9px;
`;

const Button = styled.button`
  width: 30px;
  height: 30px;
  padding: 7px 11px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: ${({ theme }) => theme.color.subUnactiveGray};
  &.true {
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;
