import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';

// config
import { resetLoading, setLoading } from '../../../redux/slices/user';

/**
 * 로미님 요청으로 배포 전 테스트용 어드민 페이지 입니다.
 * 배포 전 삭제 해야 합니다.
 */
const DeleteParticipationHistory = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [campaignId, setCampaignId] = useState('');
    const [memberId, setMemberId] = useState('');

    const deleteHistory = async () => {
        if (!campaignId || campaignId === '') {
            alert('캠페인 id를 입력 해 주세요');
            return;
        }
        if (!memberId || memberId === '') {
            alert('멤버 id를 입력 해 주세요');
            return;
        }

        const formData = new FormData();
        formData.append('memberId', memberId);
        try {
            dispatch(setLoading());
            await axios.delete(`/campaigns/${campaignId}/history?memberId=${memberId}`);
            alert('삭제되었습니다.');
        } catch (error) {
            alert(error.response.data.message);
        }
        dispatch(resetLoading());
    };

    return (
        <>
            <TopContainer>
                <ButtonWrapper>
                    <NormalButton
                        text="취소"
                        className="cancelBtn"
                        onSubmit={() => navigate(-1)}
                    />
                    <NormalButton text="삭제하기" onSubmit={() => deleteHistory()} />
                </ButtonWrapper>
            </TopContainer>
            <MainContainer>
                <InputWrapper>
                    <LabelTextInput
                        key='campaignId'
                        name='campaignId'
                        label='캠페인 id'
                        value={campaignId}
                        onChange={(e) => setCampaignId(e.target.value)}
                        onReset={() => setCampaignId('')}
                    />
                </InputWrapper>
                <InputWrapper>
                    <LabelTextInput
                        key='memberId'
                        name='memberId'
                        label='멤버 id'
                        value={memberId}
                        onChange={(e) => setMemberId(e.target.value)}
                        onReset={() => setMemberId('')}
                    />
                </InputWrapper>
            </MainContainer>
        </>
    );
};

export default DeleteParticipationHistory;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1) {
    grid-row: 1;
  }

  &:nth-child(n + 2) {
    grid-row: 2;
  }
`;
