import React, {useState, useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import { rewardCurrencyTypeList } from '../../constant/mission';

// 컴포넌트
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';

// config
import { REWARD_CURRENCY_GIVE, SEARCH_MEMBER } from '../../constant/apiUrl';
import { resetLoading, setLoading } from '../../redux/slices/user';
import SelectModule from "../../components/common/Select/SelectModule";
import Table from "../../components/common/List/Table";
import { rewardGiveCurrencySearchMemberColList } from "../../constant/member";
import ListHead from "../../components/UI/ListHead";
import SearchMemberRowComponent from "../member/components/SearchMemberRowComponent";
import saveStorage from "../../hooks/saveStorage";
import useInput from "../../hooks/useInput";
import Pagination from "../../components/common/Pagination";
import { postsPerPage } from "../../constant/common";
import TextField from "../../components/common/TextField";

interface GiveCurrencyBody {
  memberId: number;
  currencyType: string;
  value: number;
}

const GiveCurrency = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const limit = 20;

  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState<string>(storageType || '');
  const [pid, setPid] = useState('');
  const [selectedMemberPid, setSelectedMemberPid] = useState('');
  const [memberId, setMemberId] = useState(0);
  const [currencyType, setCurrencyType] = useState('BAUND_GOLD');
  const [currencyName, setCurrencyName] = useState('바운드 골드');
  const [value, setValue] = useState(0);
  const [memberList, setMemberList] = useState([]);
  // 필수 파라미터 체크
  const paramsCheck = () => {
    if (
      !memberId ||
      memberId <= 0 ||
      !selectedMemberPid ||
      selectedMemberPid === ''
    ) {
      alert('재화를 지급할 멤버를 선택해 주세요.');
      return false;
    }
    if (!currencyType || currencyType === '') {
      alert('지급할 재화의 종류를 입력 해 주세요.');
      return false;
    }
    if (!value || value === '0') {
      alert('지급 재화의 양을 입력 해 주세요.');
      return false;
    }
    return true;
  };
  const giveCurrency = async () => {
    if (!paramsCheck()) return;
    if (value >= 10000 && !confirm(`현재 입력된 재화 지급량이 ${value} 인데, 정말 지급 하시겠습니까?`)) return;
    else if (value < 0 && !confirm(`현재 입력된 재화 지급량은 ${value}로 차감될 예정입니다. 정말 차감 하시겠습니까?`)) return;
    if (!confirm(`선택한 멤버 id=${memberId}, pid=${selectedMemberPid} 에게 ${currencyName} ${value} 만큼 ${value > 0 ? '지급':'차감'}하시겠습니까?`)) return;
    const body: GiveCurrencyBody = {
      memberId,
      currencyType,
      value,
    };
    try {
      dispatch(setLoading());
      await axios.patch(`${REWARD_CURRENCY_GIVE}`, body, {});
      alert('지급 되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };
  const searchMember = async () => {
    await axios
      .get(`${SEARCH_MEMBER}?word=${pid}&type=P&offset=${pageNumber * limit}&limit=${limit}`)
      .then((res) => {
        setMemberList(res.data.memberList);
        setTotalCount(res.data.count);
      })
      .catch((error: any) => {
        alert(error.response.data.message);
      });
  };
  useEffect(() => {
    searchMember();
  }, [pageNumber]);
  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);
  const setSearchMemberId = (member: any) => {
    setMemberId(member.id);
    setSelectedMemberPid(member.pid);
  };
  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && pid) {
      setPageNumber(0);
      searchMember();
    }
  };
  const setChooseCurrencyNameType = (chooseCurrencyName: string) => {
    rewardCurrencyTypeList.forEach((el) => {
      if (el.text === chooseCurrencyName) {
        setCurrencyName(el.text);
        setCurrencyType(el.value);
      }
    })
  }

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          <NormalButton
            text="재화 지급"
            onSubmit={() => giveCurrency()}
          />
        </ButtonWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={limit}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="pid"
            value={pid}
            label="재화 지급할 멤버 pid"
            onChange={(e) => setPid(e.target.value)}
            onReset={() => {
              setPid('');
              setMemberId(0);
              setSelectedMemberPid('');
            }}
            placeholder="재화를 지급할 멤버의 pid를 입력 해 주세요."
            onKeyPress={enterKeyPress}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <ButtonDiv>
            <NormalButton
              text="멤버 검색"
              onSubmit={searchMember}
              className="inputPairBtn"
            />
          </ButtonDiv>
        </InputWrapper>
        <InputWrapper>
          <Table
            colList={rewardGiveCurrencySearchMemberColList}
            thead={
              <>
                <ListHead title="인덱스" />
                <ListHead title="Pid" />
                <ListHead title="이메일" />
                <ListHead title="가입 유형" />
                <ListHead title="인증 여부" />
                <ListHead title="가입 일자" />
                <ListHead title="벌점" />
                <ListHead title="공개 상태" />
                <ListHead title="" />
                <ListHead title="" />
              </>
            }
          >
            {memberList?.map((el: any) => (
              <SearchMemberRowComponent
                key={el.id}
                data={el}
                saveStorage={() => {
                  saveStorage(pageNumber, type, word);
                }}
                listType="oneMemberSelect"
                modalOnClick={setSearchMemberId}
              />
            ))}
          </Table>
        </InputWrapper>
        <InputWrapper>
          <TextField
            label="선택된 멤버 id"
            text={memberId.toString()}
          />
        </InputWrapper>
        <InputWrapper>
          <SelectModule
            name="currencyName"
            placeholder="지급 재화 종류"
            value={currencyName}
            label="지급 재화 종류"
            options={rewardCurrencyTypeList.map((el) => el.text)}
            onChange={(currency) => {
              setChooseCurrencyNameType(currency);
            }}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            type="number"
            name="value"
            value={value}
            label="재화 지급 량"
            onChange={(e) => {
              if (!e.target.value || isNaN(Number.parseInt(e.target.value, 10))) {
                setValue(0);
              } else {
                if (e.target.value.length >= 2 && e.target.value.substring(0, 1) === '0') {
                  e.target.value = e.target.value.substring(1, e.target.value.length);
                } else if (e.target.value.substring(0, 1) === '-' && e.target.value.substring(1, 2) === '0') {
                  e.target.value = `-${e.target.value.substring(2, e.target.value.length)}`;
                }
                setValue(Number.parseInt(e.target.value, 10));
              }
            }}
            onReset={() => setValue(0)}
            placeholder="지급할 재화의 양을 입력 해 주세요."
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};
export default GiveCurrency;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
    grid-column: span 1;
  }
  &:nth-child(n + 3) {
    grid-row: 2;
    grid-column: span 2;
  }
  &:nth-child(n + 4) {
    grid-row: 3;
  }
  &:nth-child(n + 5) {
    grid-row: 4;
  }
  &:nth-child(n + 6) {
    grid-row: 5;
  }
  &:nth-child(n + 7) {
    grid-row: 6;
  }
  &:nth-child(n + 8) {
    grid-row: 7;
  }
  &:nth-child(n + 9) {
    grid-row: 8;
  }
  &:nth-child(n + 10) {
    grid-row: 9;
  }
  &:nth-child(n + 11) {
    grid-row: 10;
  }
  &:nth-child(n + 12) {
    grid-row: 11;
  }
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const ButtonDiv = styled.div`
  padding-top: 25px;
  vertical-align: center;
`;
