import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';
import SelectModule from '../../../../components/common/Select/SelectModule';
import DateInput from '../../../../components/common/Input/DateInput';

import { useAppSelector } from '../../../../redux/store/hooks';

// 액션
import { getGenreList } from '../../../../redux/actions/genre/genre';

// config
import { RECOMMEND_GENRE } from '../../../../constant/apiUrl';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

const RecommendGenreDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const detailData: any = location.state;
  const genreListData = useAppSelector((state) => state.genre.genreList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [data, setData] = useState<any>(detailData);
  const {
    id,
    masterGenre,
    masterGenreId,
    recommendStartDt,
    recommendEndDt,
    titleKo,
    titleEn,
    titleEs,
    descriptionKo,
    descriptionEn,
    descriptionEs,
    activeYn,
  } = data;
  const initialStartDate = recommendStartDt?.replace(' ', 'T').slice(0, 16);
  const initialEndDate = recommendEndDt?.replace(' ', 'T').slice(0, 16);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSetData = useCallback(
    (e: any) => {
      setData({ ...data, masterGenre: e.genre, masterGenreId: e.id });
    },
    [data],
  );

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setData({ ...data, [name]: value });
    },
    [data],
  );

  const handleResetData = useCallback(
    (e) => {
      setData({ ...data, [e]: '' });
    },
    [data],
  );
  const requestRecommendGenre = async (type: string) => {
    const recommendStartDate = `${recommendStartDt
      .replace('T', ' ')
      .slice(0, 16)}:00`;
    const recommendEndDate = `${recommendEndDt
      .replace('T', ' ')
      .slice(0, 16)}:59`;
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(recommendEndDate);

    const params = {
      adminName,
      activeYn,
      recommendStartDate,
      recommendEndDate,
      titleKo,
      titleEn,
      titleEs,
      descriptionKo,
      descriptionEn,
      descriptionEs,
      masterGenreId,
    };

    if (recommendStartDt > recommendEndDt) {
      alert('시작일시가 종료일시보다 느릴 수 없습니다.');
      return;
    }

    if (compareEndDate) {
      alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
      return;
    }

    try {
      dispatch(setLoading());
      if (type === 'add') {
        await axios.post(
          RECOMMEND_GENRE,
          {},
          {
            params,
          },
        );
        alert('저장 되었습니다.');
        goBack();
      }
      if (type === 'change') {
        await axios.post(
          `${RECOMMEND_GENRE}/${id}/update`,
          {},
          {
            params,
          },
        );
        alert('수정 되었습니다.');
        goBack();
      }
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    const params = { limit: 1000, offset: 0 };
    dispatch(getGenreList(params));
  }, [dispatch]);

  return (
    <>
      <TopContainer>
        <ProfileWrapper>
          <ProfileTitleWrapper>
            <Title>{titleKo}</Title>
          </ProfileTitleWrapper>
        </ProfileWrapper>
        <ButtonWrapper>
          {Object.keys(detailData).length !== 0 ? (
            <>
              <NormalButton
                text="취소"
                onSubmit={goBack}
                className="cancelBtn"
              />
              <NormalButton
                text="수정하기"
                onSubmit={() => {
                  requestRecommendGenre('change');
                }}
              />
            </>
          ) : (
            <>
              <NormalButton
                text="취소"
                onSubmit={goBack}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => {
                  requestRecommendGenre('add');
                }}
              />
            </>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <SelectModule
            name="parentGenre"
            placeholder="장르를 선택해주세요."
            value={masterGenre}
            label="장르"
            onChange={handleSetData}
            options={genreListData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleKo"
            label="타이틀"
            value={titleKo}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEn"
            label="영어어 타이틀"
            value={titleEn}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="titleEs"
            label="스페인어 타이틀"
            value={titleEs}
            placeholder="타이틀을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          {/*<DateRangeInput*/}
          {/*  title="노출 기간"*/}
          {/*  placeHolder={*/}
          {/*    data.recommendStartDt*/}
          {/*      ? `${data.recommendStartDt} ~ ${data.recommendEndDt}`*/}
          {/*      : '날짜를 선택해주세요. YYYY-MM-DD ~ YYYY-MM-DD'*/}
          {/*  }*/}
          {/*  setMinStartDate={setMinStartDate}*/}
          {/*  setMaxStartDate={setMaxStartDate}*/}
          {/*  Required*/}
          {/*/>*/}
          <DateWrapper>
            <DateInput
              initialStartDate={initialStartDate}
              onChange={handleInputSetData}
              name="recommendStartDt"
              label="노출 시작일"
              Required
            />
            <DateInput
              initialStartDate={initialEndDate}
              onChange={handleInputSetData}
              name="recommendEndDt"
              label="노출 종료일"
              Required
            />
          </DateWrapper>
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default RecommendGenreDetail;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 40px;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
`;

const ProfileTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h1`
  height: 45px;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  //margin: 50px 0 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:nth-child(1) {
    grid-row: 1;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(2) {
    grid-row: 2;
    grid-column: span 2;
  }
  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(4) {
    grid-row: 4;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(5) {
    grid-row: 5;
    grid-column: span 2;
    margin-bottom: 40px;
  }
  &:nth-child(6) {
    grid-row: 6;
    grid-column: span 2;
    margin-bottom: 100px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;
