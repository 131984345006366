import React from 'react';
import styled from 'styled-components';

// common
import EditButton from '../../../components/common/Button/EditButton';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

// constant
import {
  GetCountryOption,
  GetLanguageOption,
  IsActive,
  IsPrivate,
  IsStatus,
} from '../../../constant/common';

// hook
import handleDate from '../../../hooks/handleDate';

export const EventModalRowComponent = ({ data, onClick }: any) => {
  const {
    id,
    coverImgUrl,
    beatTitle,
    memberPid,
    memberName,
    uploadDt,
    privateYn,
    articleStatus,
  } = data;
  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg className="article" url={coverImgUrl} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={memberName} />
      </td>
      <td>
        <ListItem text={beatTitle} />
      </td>
      <td>
        <ListItem text={handleDate(uploadDt)} />
      </td>
      <td>
        <ListItem className={privateYn === 'Y' ? 'private' : ''} text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={IsStatus[articleStatus]} />
      </td>
      <td>
          {privateYn !== 'Y' && < EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>}
      </td>
    </tr>
  );
};

export const SelectedEventRowComponent = ({ data, onClick, type }: any) => {
  const {
    id,
    coverImgUrl,
    beatTitle,
    memberPid,
    memberName,
    uploadDt,
    privateYn,
    articleStatus,
    createdDt,
  } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg className="article" url={coverImgUrl} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={memberName} />
      </td>
      <td>
        <ListItem text={beatTitle} />
      </td>
      <td>
        <ListItem text={handleDate(uploadDt || createdDt)} />
      </td>
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={IsStatus[articleStatus] || '정상'} />
      </td>
      {type !== 'preview' && (
        <td>
          <EditButton
            onClick={() => onClick(data)}
            text="삭제"
            className="deleteBtn"
          />
        </td>
      )}
    </tr>
  );
};

export const SelectedVideoComponent = ({
  data,
  deleteVideo,
  name,
  editable,
}: any) => {
    return <VideoImg>
        <ListCoverImg className="eventVideo" url={data.coverImgUrl} />
        <InfoBox>
            <span>{`articleId: ${data.id || '-'}`}</span>
            <span>{`memberId: ${data.memberId || '-'}`}</span>
            <span>{`memberPid: ${data.memberPid || '-'}`}</span>
            <span>{`memberName: ${data.memberName || '-'}`}</span>
            <span>{`beatId: ${data.beatId || '-'}`}</span>
            <span>{`beatTitle: ${data.beatTitle || '-'}`}</span>
        </InfoBox>
        {editable && <DeleteBtn onClick={() => deleteVideo(data, name)} />}
    </VideoImg>;
};

const VideoImg = styled.div`
  position: relative;
`;

const InfoBox = styled.div`
  position: absolute;
  bottom: 0;
  width: 170px;
  height: 110px;
  padding: 10px 7px;
  border-radius: 15px 15px 0 0;
  background-color: rgba(255, 255, 255, 0.6);
  overflow: hidden;

  span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: #111111;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 1.3;
  }
`;

const DeleteBtn = styled.button`
  position: absolute;
  width: 23px;
  height: 23px;
  top: 22px;
  right: 37px;
  background: rgba(255, 255, 255, 0.3) url(${closeRoundButton}) no-repeat center;
  background-size: 35px;
  cursor: pointer;
  border-radius: 30px;
`;

export const NoticeModalRowComponent = ({ data, onClick }: any) => {
  const {
    id,
    bannerImgUrl,
    activeYn,
    title,
    countryCode,
    startDt,
    endDt,
    language,
    projectName,
  } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg url={bannerImgUrl} className="event" />
      </td>
      <td>
        <ListItem text={projectName || '-'} />
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={IsActive[activeYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
      <td>
        <ListItem text={handleDate(endDt)} />
      </td>
      <td>
        <ListItem text={GetCountryOption[countryCode]} />
      </td>
      <td>
        <ListItem text={GetLanguageOption[language]} />
      </td>
      <td>
        <EditButton
          text="선택"
          className="selectBtn"
          onClick={() => onClick(data)}
        />
      </td>
    </tr>
  );
};

export const SelectedNoticeModalRowComponent = ({
  data,
  onClick,
  editable,
}: any) => {
  const {
    id,
    bannerImgUrl,
    activeYn,
    title,
    countryCode,
    startDt,
    endDt,
    language,
    projectName,
  } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg url={bannerImgUrl} className="event" />
      </td>
      <td>
        <ListItem text={projectName || '-'} />
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={IsActive[activeYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
      <td>
        <ListItem text={handleDate(endDt)} />
      </td>
      <td>
        <ListItem text={GetCountryOption[countryCode]} />
      </td>
      <td>
        <ListItem text={GetLanguageOption[language]} />
      </td>
      {editable && (
        <td>
          <EditButton
            text="삭제"
            className="deleteBtn"
            onClick={() => onClick(data)}
          />
        </td>
      )}
    </tr>
  );
};

export const PromotionModalRowComponent = ({ data, onClick }: any) => {
    const {eventPromotionId, name, description} = data;
    return (
        <tr>
            <td><ListItem text={eventPromotionId} /></td>
            <td><ListItem type="title" text={name} /></td>
            <td><ListItem text={description} /></td>
            <td>
                <EditButton
                    text="선택"
                    className="selectBtn"
                    onClick={() => onClick(data)}
                />
            </td>
        </tr>
    );
};

export const ActionEventModalRowComponent = ({ data, onClick }: any) => {
    return (
        <tr>
            {Object.keys(data).map(key => <td key={key}><ListItem text={data[key]} /></td>)}
            <td><EditButton text="선택" className="selectBtn" onClick={() => onClick(data)}/></td>
        </tr>
    );
};

export const EventPromotionModalRowComponent = ({ data, active, onEdit, onSelect }: any) => {
    return <tr>
        {Object.keys(data).map(key => <td key={key}><ListItem text={data[key]} /></td>)}
        <td><EditButton text="수정" className="saveBtn" onClick={() => onEdit(data)}/></td>
        {active === 'Y' && <td><EditButton text="선택" className="selectBtn" onClick={() => onSelect(data)}/></td>}
    </tr>;
};

export const BattleArticleModalRowComponent = ({ data, onClick }: any) => {
    const {
        id, coverImgUrl, memberId, memberPid, memberName,
        beatTitle, privateYn, articleStatus, uploadDt, articleType
    } = data;
    return (
        <tr>
            <td><ListItem text={id} /></td>
            <td><ListCoverImg className="article" url={coverImgUrl} /></td>
            <td><ListItem text={memberId} /></td>
            <td><ListItem text={memberPid} /></td>
            <td><ListItem text={memberName} /></td>
            <td><ListItem text={beatTitle} /></td>
            <td><ListItem text={handleDate(uploadDt)} /></td>
            <td><ListItem className={articleType === 'BT' ? 'battleType' : ''} text={articleType === 'BT' ? '배틀' : '일반'} /></td>
            <td><ListItem className={privateYn === 'Y' ? 'private' : ''} text={IsPrivate[privateYn]} /></td>
            <td><ListItem text={IsStatus[articleStatus]} /></td>
            <td>{privateYn !== 'Y' && <EditButton onClick={() => onClick(data)} text="선택" className="saveBtn"/>}</td>
        </tr>
    );
};