import React from 'react';

// common
import EditButton from '../../../../components/common/Button/EditButton';
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// constant
import { collectionType, reportType } from '../../../../constant/explore';
import { IsPrivate } from '../../../../constant/common';

// hook
import handleDate from '../../../../hooks/handleDate';

export const CollectionRowComponent = ({ data, onClick }: any) => {
  const { id, memberPid, name, type, contentsCount, coverImgUrl, shareCount } =
    data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={collectionType[type]} />
      </td>
      <td>
        <ListItem text={contentsCount || 0} />
      </td>
      <td>
        <ListCoverImg url={coverImgUrl} />
      </td>
      <td>
        <ListItem text={name} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={shareCount || 0} />
      </td>
      <td>
        <EditButton
          onClick={() => onClick(data)}
          text="선택"
          className="selectBtn"
        />
      </td>
    </tr>
  );
};

export const CollectionBeatDetailRowComponent = ({ data }: any) => {
  const {
    contentsCoverImgUrl,
    contentsMemberPid,
    contentsPrivateYn,
    bpm,
    startDt,
    id,
    beatKey,
    genre,
    contentsTitle,
  } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg url={contentsCoverImgUrl} />
      </td>
      <td>
        <ListItem text={contentsTitle} type="title" />
      </td>
      <td>
        <ListItem text={contentsMemberPid} />
      </td>
      <td>
        <ListItem text={genre?.replace(/ /gi, ', ')} />
      </td>
      <td>
        <ListItem text={bpm} />
      </td>
      <td>
        <ListItem text={beatKey} />
      </td>
      <td>
        <ListItem text={IsPrivate[contentsPrivateYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
    </tr>
  );
};

export const CollectionVideoDetailRowComponent = ({ data, onClick }: any) => {
  const {
    contentsCoverImgUrl,
    contentsCreatedDt,
    contentsMemberPid,
    contentsPrivateYn,
    contentsReportType,
    beatTitle,
    id,
  } = data;
  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg className="article" url={contentsCoverImgUrl} />
      </td>
      <td>
        <ListItem text={contentsMemberPid} />
      </td>
      <td>
        <ListItem text={contentsMemberPid} />
      </td>
      <td>
        <ListItem text={beatTitle} />
      </td>
      <td>
        <ListItem text={handleDate(contentsCreatedDt)} />
      </td>
      <td>
        <ListItem text={IsPrivate[contentsPrivateYn]} />
      </td>
      <td>
        <ListItem text={reportType[contentsReportType]} />
      </td>
      <td>
        <EditButton onClick={onClick} text="영상" />
      </td>
    </tr>
  );
};

export const RecommendHashTagDetailRowComponent = ({ data, onClick }: any) => {
  const { coverImgUrl, createdDt, privateYn, memberPid, beatTitle, id } = data;

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListCoverImg className="article" url={coverImgUrl} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={beatTitle} />
      </td>
      <td>
        <ListItem text={handleDate(createdDt)} />
      </td>
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={reportType[data.reportType]} />
      </td>
      <td>
        <EditButton onClick={onClick} text="영상" />
      </td>
    </tr>
  );
};
