import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// common
import ListHead from '../../../../components/UI/ListHead';
import NormalButton from '../../../../components/common/Button/NormalButton';
import RecommendHashTagRowComponents from './RecommendHashTagRowComponent';

// redux
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// constant
import { RECOMMEND_HASHTAG } from '../../../../constant/apiUrl';

const RecommendHashTagEditSeq = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState<any>([]);

  // 공개 해시태그 리스트 받아오기
  const getTagList = async () => {
    const params = { limit: 20, offset: 0, activeYn: 'Y' };
    try {
      const resp = await axios.get(RECOMMEND_HASHTAG, {
        params,
      });
      setTagList(resp.data.recommendTagsList);
    } catch (err) {
      console.log(err);
    }
  };

  // 해시태그 순서 바꾸기
  const editSeqList = async () => {
    const recommendTagsIdList = tagList.map((tag: any) => tag.id);
    try {
      dispatch(setLoading());
      await axios.post(`${RECOMMEND_HASHTAG}/update/sequence`, {
        recommendTagsIdList,
      });
      alert('해시태그 순서가 변경되었습니다.');
      navigate('/explore/allTab/hashTag');
    } catch (err) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    getTagList();
  }, []);

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = tagList[dragIndex];
      const hoverItem = tagList[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setTagList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [tagList],
  );

  const setIndex = () => {
    // 드래그 드롭이 끝났을 때 이뤄지는 함수
  };

  return (
    <Container>
      <TopWrapper>
        <TitleText>Admin 등록 해시태그</TitleText>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => {
              navigate(-1);
            }}
          />
          <NormalButton text="저장하기" onSubmit={editSeqList} />
        </ButtonWrapper>
      </TopWrapper>
      <SpaceDiv />
      <Ul>
        <TagHead>
          <ListHead title="해시태그 명" className="alignLeft" />
          <ListHead title="시작일" />
          <ListHead title="종료일" />
        </TagHead>
        {tagList?.map((item: any, index: number) => (
          <RecommendHashTagRowComponents
            key={item.id}
            data={item}
            moveListItem={movePetListItem}
            setIndex={setIndex}
            index={index}
          />
        ))}
      </Ul>
    </Container>
  );
};

export default RecommendHashTagEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const Ul = styled.ul`
  width: 100%;
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 0.7fr 0.7fr;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 55px;
`;
