import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import CuratedArticleEditSeqRowComponent from './CuratedArticleEditSeqRowComponent';
import ListHead from '../../../components/UI/ListHead';

// config
import {COMMUNITY_CURATED_ARTICLE,} from '../../../constant/apiUrl';

const CuratedArticleEditSeq = () => {
  const navigate = useNavigate();
  const [contentsList, setContentsList] = useState<any>([]);

  useEffect(() => {
    getCommunityCategoryList();
  }, []);

  const getCommunityCategoryList = async () => {
    const params = {
      limit: 100,
      offset: 0,
      contentsType: 'CBA',
    };
    try {
      const resp = await axios.get(`${COMMUNITY_CURATED_ARTICLE}/active`, {
        params,
      });
      setContentsList(resp.data.communityBoardArticleList);
    } catch (err) {
      console.log(err);
    }
  };

  const changeSequence = async () => {
    const ids = contentsList.map((el: any) => el.id);
    try {
      await axios.put(`${COMMUNITY_CURATED_ARTICLE}/sequence`, {ids,});
      alert('저장되었습니다.');
      navigate('/operation/communityCuratedArticle');
    } catch (err) {
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = contentsList[dragIndex];
      const hoverItem = contentsList[hoverIndex];

      setContentsList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [contentsList],
  );

  return (
    <Container>
      <TopContainer>
        <TitleText>노출 순서 변경</TitleText>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        <SpaceDiv />
      </PaginationWrapper>
      <MainContainer>
        <TagHead>
          <ListHead title="" />
          <ListHead title="표시 순서" />
          <ListHead title="컨텐츠 아이디" />
          <ListHead title="컨텐츠 명" />
          <ListHead title="컨텐츠 설명" />
          <ListHead title="노출 개시 일시" />
          <ListHead title="노출 종료 일시" />
        </TagHead>
        {contentsList?.map((item: any, index: number) => (
          <CuratedArticleEditSeqRowComponent
            key={item.id}
            data={item}
            moveListItem={moveItem}
            index={index}
          />
        ))}
      </MainContainer>
    </Container>
  );
};

export default CuratedArticleEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 10fr 15fr 20fr 30fr 20fr 30fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
