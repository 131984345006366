import React from 'react';
import { useNavigate } from 'react-router-dom';

// common
import EditButton from '../../../components/common/Button/EditButton';
import ListItem from '../../../components/common/List/ListItem';

// hooks
import handleDate from '../../../hooks/handleDate';

// constant
import { loginTypeOptions } from '../../../constant/common';

const SearchMemberRowComponent = ({
  data,
  saveStorage,
  type,
  selected,
  modalOnClick,
  listType,
}: any) => {
  const navigate = useNavigate();
  const {
    id,
    pid,
    reportScore,
    email,
    reportScoreExpireDt,
    createdDt,
    loginType,
    verifiedYn,
  } = data;

  const nowDate = `${new Date().toISOString().slice(0, 16)}:00`;

  const editMember = () => {
    navigate('/member/searchMember/detail', { state: id });
  };

  return (
    <tr key={id}>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={pid} />
      </td>
      <td>
        <ListItem text={email} />
      </td>
      <td>
        <ListItem text={loginTypeOptions[loginType]} />
      </td>
      <td>
        <ListItem text={verifiedYn === 'Y' ? '인증 완료' : '미인증'} />
      </td>
      <td>
        <ListItem text={createdDt ? handleDate(createdDt) : '-'} />
      </td>
      <td>
        <ListItem text={reportScore || 0} />
      </td>
      <td>
        <ListItem
          text={
            reportScoreExpireDt === null ||
            Date.parse(nowDate) > Date.parse(reportScoreExpireDt)
              ? '정상'
              : `제재(${handleDate(reportScoreExpireDt)}까지)`
          }
        />
      </td>
      {listType !== 'preview' && (
        <td>
          {/* eslint-disable-next-line no-nested-ternary */}
          {type === 'modal' ? (
            selected ? (
              <EditButton
                onClick={() => modalOnClick(data)}
                text="삭제"
                className="deleteBtn"
              />
            ) : (
              <EditButton
                onClick={() => modalOnClick(data)}
                text="선택"
                className="selectBtn"
              />
            )
          ) : (
            <EditButton
              onClick={() => {
                editMember();
                saveStorage();
              }}
              text="상세"
            />
          )}
        </td>
      )}
      {listType === 'oneMemberSelect' && (
        <td>
          <EditButton
            onClick={() => modalOnClick(data)}
            text="선택"
            className="selectBtn"
          />
        </td>
      )}
    </tr>
  );
};

export default SearchMemberRowComponent;
