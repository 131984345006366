import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CustomProps } from '../../../definitions/common';

interface TextInputProps {
  name: string;
  label: string;
  value: string | number;
  placeholder?: string;
  Required?: boolean;
  setValue?: any;
  readOnly?: boolean;
}

const LabelTextAreaInput = ({
  name,
  value,
  label,
  placeholder,
  Required,
  setValue,
  readOnly,
}: TextInputProps) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    ref.current.style.height = 'auto';
    ref.current.style.height = `${ref.current.scrollHeight + 10}px`;
  }, [value]);

  return (
    <InputContainer className={name}>
      <InputText>
        {label} {Required && <span>*</span>}
      </InputText>
      <InputBox>
        <Textarea
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={e => setValue(e)}
          ref={ref}
          readOnly={readOnly}
        />
      </InputBox>
    </InputContainer>
  );
};

export default LabelTextAreaInput;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;

  ${(props) => props.className === 'push' && css`margin: 40px 0 30px 0;`}
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div<CustomProps>`
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: auto;
  padding: 15px 40px 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};

  ::placeholder {
    color: ${({ theme }) => theme.color.subGray};
    font-weight: 500;
  }

  ${(props) => props.name === 'push' ? css`min-height: 100px;` 
      : (props.name === 'battle_rankingis_description' || props.name === 'battle_rankingis_descriptionEn') ? css`height: 112px !important`
      : ''
  }
`;