import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Spoqa Han Sans Neo', 'sans-serif';
  }
  html, body{height: 100%;}
  
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a,
  abbr,
  address,
  em,
  img,
  q,
  strong,
  b,
  i,
  ol,
  ul,
  li,
  form,
  label,
  article,
  figure,
  figcaption,
  footer,
  nav,
  section,
  dl,
  dd,
  dt,
  main,
  tr,
  th,
  td{
    margin: 0;
    padding: 0;
    border: 0;
  }
  ol,
  ul,
  li {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  b,
  strong,
  i,
  em {
    font-weight: normal;
    font-style: normal;
  }
  textarea {
    border: none;
    overflow: auto;
    outline: none;
    box-shadow: none;
    resize: none;
    cursor: text;
  }
  input,
  button,
  select{
    border: none;
    outline: none;
    background: transparent;
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px #eeeeee inset;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  blockquote,
  q {
    quotes: none;
  }
  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }


  @font-face {
    font-family: 'D2Coding';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_three@1.0/D2Coding.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;

export default GlobalStyle;
