import React, { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Routes from './Routes';
import { GlobalStyles } from '../styles';
import { theme } from '../styles/theme';
import { loadUser } from '../redux/actions/user/user';
import { useAppSelector } from '../redux/store/hooks';
import { resetLoading } from '../redux/slices/user';
import Loader from '../components/common/Loader';

const App = () => {
  const dispatch = useDispatch();
  const routing = useRoutes(Routes());
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const loading = useAppSelector((state) => state.user.isLoading);
  useEffect(() => {
    if (isLoggedIn) {
      dispatch(loadUser());
      dispatch(resetLoading());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  return (
    <>
      <GlobalStyles />
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            {/*pending 할 때 로딩 띄우기*/}
            {loading && <Loader />}
            {routing}
          </ThemeProvider>
        </DndProvider>
    </>
  );
};

export default App;
