import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { DepthUnits } from '../constant/depthUnits';
import { useLocation } from "react-router";

const Depth = () => {
	const location = useLocation();
	const navigate = useNavigate();
	
	const urlPath = window.location.pathname;
	const url = urlPath.split('/').splice(1);
	const urlArr = [];
	
	// url 한글로 변환 후 배열로 만듦
	url.forEach(key => urlArr.push(DepthUnits[key]));
	
	const onMove = el => {
		// nav바 메뉴 누르면 로컬스토리지 비우기
		localStorage.clear();
		
		const urlIndex = urlArr.indexOf(el);
		const moveUrl = urlArr.slice(0, urlIndex + 1);
		const navUrl = moveUrl.map((e) => Object.keys(DepthUnits).find(key => DepthUnits[key] === e)).join('/');
		const resultUrl = `/${navUrl}`;
		
		// nav텍스트의 첫 번째를 눌렀을 때는 이동 X
		if (moveUrl.length < 2 || resultUrl === urlPath) return;
		
		// 페이지 이동
		navigate(resultUrl, {
			state: location.state,
		});
	};
	
	return (
		<DepthWrapper>
			{urlArr.map((el) =>
				<NavLink key={Math.random()} onClick={() => onMove(el)}>
					{el}
				</NavLink>
			)}
		</DepthWrapper>
	);
};

export default Depth;

const DepthWrapper = styled.div`
  margin-bottom: 30px;
`;

const NavLink = styled.span`
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
  &:not(:last-child):after {
    content: '>';
    margin: 0 10px;
  }

  &:last-child {
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;
