//신고 유형 옵션
export const reportTypeOptions = ['컨텐츠', '유저'];

export type reportOptions = {
  [key: string]: string;
};

export const getReportTypeOption: reportOptions = {
  컨텐츠: 'CT',
  유저: 'MB',
};

export const navigationTypeValues = {
  // AR : '플레이',
  BATTLE : '배틀',
  // BT : '비트',
  // EV : '이벤트',
  MISSION : '미션',
  // CAMPAIGN : '캠페인',
  // MAGAZINE : '매거진',
}

export const navigationSubTypeValues = {
  NONE: '선택안함',
  BATTLE_PICK : '스페셜 픽',
}

export const managementColList = [200, 200, 100, 100];
export const appVersionManagementColList = [100, 150, 150, 150, 150, 80];
export const officialAccountColList = [100, 160, 400, 80];
