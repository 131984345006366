import NormalButton from "../Button/NormalButton";
import Table from "../List/Table";
import {addBeatCollectionColList} from "../../../constant/explore";
import DetailAddBeatRowComponents
  from "../../../pages/explore/components/category/DetailAddBeatRowComponents";
import SearchMenuSelect
  from "../Select/SearchMenuSelect";
import {beatBpmOptions, beatSearchTypeList} from "../../../constant/beat";
import SearchInput from "../Input/SearchInput";
import Pagination from "../Pagination";
import {postsPerPage} from "../../../constant/common";
import ListHead from "../../UI/ListHead";
import ListDateSelect from "../Select/ListDateSelect";
import VideoModal from "./VideoModal";
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {getBeatList} from "../../../redux/actions/beat/beat";
import {useAppSelector} from "../../../redux/store/hooks";
import {useDispatch} from "react-redux";
import ListSelect from "../Select/ListSelect";

/**
 * 비트 선택 공통 모달
 * @param closeModal 모달창 닫을때 caller의 함수.
 *                   모달에서는 검색 조건 초기화등을 실시한 후에 최종 호출할 것
 * @param playBeat 비트 재생을 위한 값. caller에서 동시 플레이 방지를 위함
 * @param setPlayBeat 비트 재생을 위한 값. caller에서 동시 플레이 방지를 위함
 * @param beatSelected caller에서 기 선택된 비트 정보. 모달 초기화 때 기본 표시 값으로 사용
 * @param beatSetter 모달에서 선택 된 비트setter. 저장하기 버튼 클릭시 호출하여 caller로 전달
 * @param maxBeatCount 선택 가능한 최대 비트 수
 * @returns {JSX.Element}
 * @constructor
 */
const BeatSelectModal = ({
  closeModal,
  playBeat,
  setPlayBeat,
  beatSelected,
  beatSetter,
  maxBeatCount
}) => {
  const dispatch = useDispatch();

  // 모달 창에서 선택한 비트
  const [beatSelectedWork, setBeatSelectedWork] = useState(beatSelected || []);

  // 비트 검색 조건
  const [beatSearchType, setBeatSearchType] = useState('');
  const [keyword, setKeyword] = useState('');
  const [bpm, setBpm] = useState('');
  const [minBpm, setMinBpm] = useState(0);
  const [maxBpm, setMaxBpm] = useState(250);
  const [minStartDate, setMinStartDate] = useState('');
  const [maxStartDate, setMaxStartDate] = useState('');
  // 비트 검색 결과
  const [pageNumber, setPageNumber] = useState(0);
  const beatSearched = useAppSelector((state) => state.beat.beatList);
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);

  // 검색을 수행하기 위한 값 ///////////////////////////////////////////////////
  const [searchTrigger, setSearchTrigger] = useState(false);
  // 트리거 값 변경시 검색 수행
  useEffect(() => {
    dispatch(getBeatList(beatParams));
  }, [searchTrigger]);
  const executeSearch = (resetPageNumber) => {
    if(resetPageNumber) {
      beatParams.offset = 0;
      setPageNumber(0);
    }
    // 트리거 값 변경으로 검색 수행 유도
    setSearchTrigger(!searchTrigger);
  }

  // 모달창 최초 표시할 때
  useEffect(() => {
    executeSearch(false);
  }, []);

  // 비트 검색 /////////////////////////////////////////////////////////////////
  const beatParams = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    word: keyword,
    type: beatSearchType,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn: 'N',
  };

  useEffect(() => {
    switch (bpm) {
      case '':
        setMinBpm(0);
        setMaxBpm(250);
        break;
      case '49':
        setMinBpm(0);
        setMaxBpm(49);
        break;
      case '50':
        setMinBpm(50);
        setMaxBpm(80);
        break;
      case '80':
        setMinBpm(80);
        setMaxBpm(100);
        break;
      case '100':
        setMinBpm(100);
        setMaxBpm(140);
        break;
      case '140':
        setMinBpm(140);
        setMaxBpm(180);
        break;
      case '180':
        setMinBpm(180);
        setMaxBpm(230);
        break;
      default:
        setMinBpm(0);
        setMaxBpm(250);
        break;
    }
    executeSearch(true);
  }, [bpm]);

  // bpm
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!beatSearchType) {
        setBeatSearchType('T');
        beatParams.type = 'T';
      }
      executeSearch(true);
    }
  };

  // 비트 선택, 삭제 ///////////////////////////////////////////////////////////
  // 클릭시 추가
  const addSelectedBeat = (data) => {
    if (maxBeatCount && beatSelectedWork.length >=maxBeatCount) {
      alert(`비트는 ${maxBeatCount}개까지 등록할 수 있습니다.`);
      return;
    }
    if (!beatSelectedWork?.find((e) => e.id === data.id)) {
      setBeatSelectedWork([...beatSelectedWork, data]);
    }
  };
  // 클릭시 삭제
  const filterSelectedBeat = (el) => {
    const result = beatSelectedWork.filter((item) => item !== el);
    setBeatSelectedWork(result);
  };

  // 선택 비트 정보 저장 ///////////////////////////////////////////////////////
  const saveAndClose = () => {
    // 모달창에서 선택한 비트 정보를 caller로 전달
    beatSetter(beatSelectedWork);
    // 모달 창 닫기
    close();
  }

  // 모달 창 닫기 //////////////////////////////////////////////////////////////
  const close = () => {
    // 각종 검색 조건 초기화
    setPageNumber(0);
    setBeatSearchType('');
    setMinBpm(0);
    setMaxBpm(250);
    setMinStartDate('');
    setMaxStartDate('');
    // 모달창에서 선택한 비트 정보 초기화
    setBeatSelectedWork([]);

    //caller의 모달 창 닫기 함수
    closeModal();
  }

  // setter wrapper ////////////////////////////////////////////////////////////
  // 검색 유형 변경시
  const handleBeatSearchType = (_beatSearchType) => {
    if (
        // 숫자 타입이 선택 되었을 때
        ('BPM' === _beatSearchType)
        // 이전에 문자 타입이고
        && ['T', 'BMP', 'GENRE'].includes(beatSearchType)
        // 값이 있는데 숫자가 아니면
        && keyword && !Number(keyword)
    ) {
      // 검색어 클리어
      setKeyword('');
    }

    setBeatSearchType(_beatSearchType);
    executeSearch(true);
  }

  // 검색어 설정
  const handleKeyword = (_keyword) => {
    if (beatSearchType === 'BPM' && _keyword && !Number(_keyword)) {
      alert('BPM은 숫자만 검색 가능합니다.');
      setKeyword('');
      return;
    }
    setKeyword(_keyword);
    // 검색어 변경으로 인한 즉각 검색은 없음
  }

  // 페이지 변경시
  const handlePageNumber = (e) => {
    setPageNumber(e - 1);
    executeSearch(false);
  }

  return <VideoModal closeModal={close} blockCloseBtn>
    <VideoModalWrapper>
      <ModalButtonWrapper>
        <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
        <NormalButton text="저장하기" onSubmit={saveAndClose}/>
      </ModalButtonWrapper>
      <VideoModalTitle>선택 비트</VideoModalTitle>
      {beatSelectedWork?.length !== 0 && beatSelectedWork
        ? (<>
          <TableTitle>{beatSelectedWork?.length}개의 비트</TableTitle>
          <LineDiv />
          <Table colList={addBeatCollectionColList} className="nonListTable">
              {beatSelectedWork?.map((data) => (
                  <DetailAddBeatRowComponents
                      key={data.id}
                      data={data}
                      playBeat={playBeat}
                      setPlayBeat={setPlayBeat}
                      onClick={() => filterSelectedBeat(data)}
                      selected
                      editable
                  />
              ))}
            </Table>
        </>)
        : (<>
            <VideoModalSubText>선택된 비트가 없습니다. 아래 검색 후 비트를 선택해 주세요.</VideoModalSubText>
            <LineDiv />
        </>)
      }
      <SpaceDiv/>
      <VideoModalTitle>비트 검색</VideoModalTitle>
      <TitleWrapper>
        <SearchWrapper>
          <SearchMenuSelect options={beatSearchTypeList} onClick={handleSelect} setState={handleBeatSearchType}/>
          <SearchInputWrapper>
            <SearchInput
                value={keyword} onKeyPress={enterKeyPress}
                onChange={(e) => handleKeyword(e.target.value)}
            />
          </SearchInputWrapper>
        </SearchWrapper>
      </TitleWrapper>
      <PaginationWrapper>
        <TableTitle>{beatTotalCount}개 비트</TableTitle>
        {beatTotalCount !== 0 && (
            <Pagination
                postsPerPage={postsPerPage}
                totalCount={beatTotalCount}
                currentPage={pageNumber}
                pageDispatch={handlePageNumber}
            />
        )}
      </PaginationWrapper>
      <LineDiv/>
      <Table
          colList={addBeatCollectionColList}
          thead={
            <>
              <ListHead title="Cover" />
              <ListHead title="Title" />
              <ListHead title="Pid" />
              <ListHead title="장르" />
              <ListSelect
                  title="BPM"
                  list={beatBpmOptions}
                  onClick={handleSelect}
                  setState={setBpm}
              />
              <ListHead title="BeatKey" />
              <ListHead title="공개여부" />
              <ListDateSelect title="공개 날짜" setMinStartDate={setMinStartDate} setMaxStartDate={setMaxStartDate}/>
              <ListHead title="" />
            </>
          }
      >
        {beatSearched?.map((el) => (
            <DetailAddBeatRowComponents
                key={el.id}
                data={el}
                onClick={addSelectedBeat}
                playBeat={playBeat}
                setPlayBeat={setPlayBeat}
                editable
            />
        ))}
      </Table>
    </VideoModalWrapper>
  </VideoModal>
}
export default BeatSelectModal;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 0 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px;
  align-items: center;
`;

const SpaceDiv = styled.div`
  width: 100%;
  min-height: 50px;
  height: ${({ value }) => value};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  margin-right: 20px;
  line-height: 35px;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;