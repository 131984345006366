import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination';

// config
import {
  CULTURE_CHANNEL,
  CULTURE_EPISODE,
  CULTURE_PROGRAM,
} from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';
import CultureMenuSelect from '../component/CultureMenuSelect';

const CultureEpisode = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );
  const [channelId, setChannelId] = useState(0);
  const [channelName, setChannelName] = useState('채널 선택');
  const [cultureChannels, setCultureChannels] = useState<any>([]);
  const [programId, setProgramId] = useState(0);
  const [programName, setProgramName] = useState('프로그램 선택');
  const [culturePrograms, setCulturePrograms] = useState<any>([]);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 전체 채널 불러오기
  const getCultureChannels = useCallback(async () => {
    try {
      const resp = await axios.get(CULTURE_CHANNEL);
      setCultureChannels(resp.data.adminCultureChannels);
    } catch (err) {
      console.log(err);
    }
  }, []);

  // 전체 프로그램 불러오기
  const getCulturePrograms = useCallback(async () => {
    try {
      const params = { cultureChannelId: channelId };
      const resp = await axios.get(CULTURE_PROGRAM, { params });
      setCulturePrograms(resp.data.adminCulturePrograms);
    } catch (err) {
      console.log(err);
    }
  }, [channelId]);

  useEffect(() => {
    getCulturePrograms();
  }, [channelId]);

  useEffect(() => {
    getCultureChannels();
    getCulturePrograms();
  }, []);

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate(`${CULTURE_EPISODE}/edit`, {
      state: {
        prevId: state?.id,
        nextSequence: totalCount + 1,
        channelId,
        channelName,
        cultureChannels,
        programId,
        programName,
        culturePrograms,
      },
    });
  };

  const getCultureEpisodes = useCallback(async () => {
    const params = {
      cultureProgramId: programId,
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(`${CULTURE_EPISODE}/${activationType}`, {
        params,
      });
      setTotalCount(resp.data.count);
      setContentsList(resp.data.adminCultureEpisodes);
    } catch (err) {
      console.log(err);
    }
  }, [activationType, pageNumber, programId]);

  useEffect(() => {
    getCultureEpisodes();
  }, [programId, activationType, pageNumber]);

  const handleChannelSetData = (e: any) => {
    setChannelId(e.id);
    setChannelName(e.name);
  };

  const handleProgramSetData = (e: any) => {
    setProgramId(e.id);
    setProgramName(e.name);
  };

  return (
    <Container>
      <TopContainer>
        <div>
          <SearchWrapper>
            <CultureMenuSelect
              placeholder=""
              value={channelName}
              onChange={handleChannelSetData}
              options={cultureChannels}
              label=""
            />
            <CultureMenuSelect
              placeholder=""
              value={programName}
              onChange={handleProgramSetData}
              options={culturePrograms}
              label=""
            />
            <RoundButton
              title="활성"
              selected={activationType === 'active'}
              onClick={() => setActivationType('active')}
            />
            <RoundButton
              title="비활성"
              selected={activationType === 'inactive'}
              onClick={() => setActivationType('inactive')}
            />
          </SearchWrapper>
        </div>
        <ButtonWrapper>
          <NormalButton
            text="에피소드 등록"
            onSubmit={() => {
              navigate(`${CULTURE_EPISODE}/add`, {
                state: {
                  nextSequence: totalCount + 1,
                  prevId: null,
                  channelId,
                  channelName,
                  cultureChannels: [
                    { id: 0, name: '채널 선택' },
                    ...cultureChannels,
                  ],
                  programId,
                  programName,
                  culturePrograms: [
                    { id: 0, name: '프로그램 선택' },
                    ...culturePrograms,
                  ],
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[50, 100, 100, 60, 60]}
          thead={
            <>
              <ListHead title="프로그램 아이디" />
              <ListHead title="제목" />
              <ListHead title="설명" />
              <ListHead title="노출 개시 일시" />
              <ListHead title="노출 종료 일시" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td>
                <ListItem text={el.id} />
              </td>
              <td>
                <ListItem text={el.name} />
              </td>
              <td>
                <ListItem text={el.description} />
              </td>
              <td>
                <ListItem text={el.startDt} />
              </td>
              <td>
                <ListItem text={el.endDt} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default CultureEpisode;

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
