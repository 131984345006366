import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getRekognitionList,
  changeRekognitionDetail,
} from '../actions/operation/operation';

interface initialType {
  rekognitionList: Array<any>;
  totalCount: number;
}

const initialState: initialType = {
  rekognitionList: [],
  totalCount: 0,
};

const operationSlice = createSlice({
  name: 'operation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 장르 리스트가져오기
      .addCase(getRekognitionList.pending, (state, action) => {})
      .addCase(getRekognitionList.fulfilled, (state, action) => {
        state.rekognitionList = action.payload.masterRekognitionList;
        state.totalCount = action.payload.count;
      })
      .addCase(
        getRekognitionList.rejected,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload);
        },
      )
      .addCase(changeRekognitionDetail.pending, (state, action) => {})
      .addCase(changeRekognitionDetail.fulfilled, (state, action) => {
        console.log(action.payload);
      })
      .addCase(
        changeRekognitionDetail.rejected,
        (state, action: PayloadAction<any>) => {
          console.log(action.payload);
        },
      );
  },
});

export default operationSlice;
