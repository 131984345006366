import React from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Navbar from './Nav/Navbar';
import SideBar from './SideBar/SideBar';
import Depth from './Depth';

const DashboardLayout = () => {
	return <>
		<Navbar />
		<Container>
			<SideBar />
			<OutContainer>
				<Depth />
				<Outlet />
			</OutContainer>
		</Container>
	</>;
};

export default DashboardLayout;

const Container = styled.main`
  width: 1300px;
  margin: 0 auto;
  padding-top: 60px;
`;

const OutContainer = styled.div`
  width: 80%;
  float: right;
  margin-top: 42px;
  padding-bottom: 65px;
`;
