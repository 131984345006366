import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

// common
import axios from 'axios';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListDate from '../../components/common/List/ListDate';

// constant
import {
  memberHistoryColList,
  memberLogSearchTypeList,
  memberOsOptions,
  userLogOptions,
} from '../../constant/member';
import { postsPerPage } from '../../constant/common';

// hooks
import useInput from '../../hooks/useInput';

// config
import { MEMBER_LOG, } from '../../constant/apiUrl';
import ListSelect from '../../components/common/Select/ListSelect';
import MemberHistoryRowComponent from './components/MemberHistoryRowComponent';

const MemberHistory = () => {
  const [logList, setLogList] = useState('');
  const [word, setWord] = useInput('');
  const [searchType, setSearchType] = useState<string>('E');
  const [osType, setOsType] = useState('');
  const [loginType, setLoginType] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  // 검색어 인코딩
  const encodeStr = encodeURI(word);

  const params = {
    osType,
    loginType,
    searchType,
    searchStartDate: minStartDate,
    searchEndDate: maxEndDate,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      searchMember();
    }
  };

  // member 조회
  const searchMember = async () => {
    if (word) {
      try {
        const resp = await axios.get(MEMBER_LOG, {
          params,
        });
        setLogList(resp.data.accessLogList);
        setTotalCount(resp.data.count || 0);
      } catch (err) {
        alert('잠시후 다시 시도 해주세요.');
      }
    }
  };

  useEffect(() => {
    searchMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    params.offset = 0;
    setPageNumber(0);
    searchMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osType, loginType, minStartDate, maxEndDate]);

  return (
    <>
      <TopContainer>
        <Title>계정 검색</Title>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={memberLogSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <ListDate
            setMinStartDate={setMinStartDate}
            setMaxStartDate={setMaxEndDate}
            active={!!(minStartDate && maxEndDate)}
            requiredDate
          />
        </TopWrapper>
        <LineDiv />
        <SubtitleWrapper>
          {Array.isArray(logList) && <Title>접속 이력</Title>}
          {logList && logList?.length !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </SubtitleWrapper>
      </TopContainer>
      {Array.isArray(logList) && (
        <MainContainer>
          <Table
            colList={memberHistoryColList}
            thead={
              <>
                <ListSelect
                  title="구분"
                  list={userLogOptions}
                  onClick={handleSelect}
                  setState={setLoginType}
                />
                <ListHead title="로그인/아웃 일시" />
                <ListSelect
                  title="OS"
                  list={memberOsOptions}
                  onClick={handleSelect}
                  setState={setOsType}
                />
                <ListHead title="OS Version" />
                <ListHead title="App Version" />
                <ListHead title="기종" />
                <ListHead title="접속 IP" />
                <ListHead title="" />
              </>
            }
          >
            {logList.map((el: any) => (
              <MemberHistoryRowComponent key={el.id} data={el} />
            ))}
          </Table>
        </MainContainer>
      )}
    </>
  );
};

export default MemberHistory;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  &:first-child {
    margin-bottom: 30px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;

const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 40px 0;
`;
