import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import ListItem from '../../../../components/common/List/ListItem';
import NormalButton from '../../../../components/common/Button/NormalButton';
import RoundButton from '../../../../components/common/Button/RoundButton';
import Pagination from '../../../../components/common/Pagination';

// config
import {
  ADMIN_BAUND_API_CATEGORY,
  ADMIN_BAUND_API_API,
} from '../../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../../constant/common';
import BaundApiCategoryMenuSelect from '../component/BaundApiCategoryMenuSelect';
import {getActiveTypeName, getBaundApiCategoryTypeName} from "../../../../constant/baundApi";

const BaundApi = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);

  // 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition') || '{}');
  const [pageNumber, setPageNumber] = useState(
      storageSearchCondition?.pageNumberForSearch || 0
  );
  const [activationType, setActivationType] = useState(
      storageSearchCondition?.activationTypeForSearch || 'active'
  );
  const [categories, setCategories] = useState<any>([]);
  const [categoryId, setCategoryId] = useState(
      storageSearchCondition?.categoryIdForSearch ||  0
  );
  const [categoryName, setCategoryName] = useState(
      storageSearchCondition?.categoryNameForSearch || '카테고리 선택'
  );

  // 초기 처리 /////////////////////////////////////////////////////////////////////////////////////
  const getBaundApiCategories = async () => {
    try {
      const resp = await axios.get(ADMIN_BAUND_API_CATEGORY);
      const workCategories = resp.data.adminBaundApiCategoryList.map((category:any)=>{
            category.name = "[" + getBaundApiCategoryTypeName(category.categoryType) + "] "
                + category.name
                + " (" + getActiveTypeName(category.activeYn) + ")";
            return category;
          }
      );
      setCategories(workCategories);
      if (workCategories && workCategories.length > 0) {
        setCategoryId((categoryId === 0) ? workCategories[0].baundApiCategoryId : categoryId);
        setCategoryName( (categoryId === 0) ? workCategories[0].name : categoryName);
      }
    } catch (error: any) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBaundApiCategories().then();
    
    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  // 표시 데이터 조회 //////////////////////////////////////////////////////////////////////////////
  const getContents = async () => {
    const params = {
      baundApiCategoryId: categoryId,
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(`${ADMIN_BAUND_API_API}/${activationType}`, {
        params,
      });
      setTotalCount(resp.data.count);
      setContentsList(resp.data.adminBaundApiList);
    } catch (error: any) {
      console.log(error.response.data);
    }
  };
  useEffect(() => {
    getContents().then();
  }, [categoryId, activationType, pageNumber]);

  // 페이지 이동 ///////////////////////////////////////////////////////////////////////////////////
  // 상세
  const goToDetail = (_baundApiId: any) => {
    navigate(`/system/baundApi/${_baundApiId ? 'edit': 'add'}`, {
      state: {
        baundApiId: _baundApiId,
        nextSequence: totalCount + 1,
        categories,
        categoryIdForSearch: categoryId,
        categoryNameForSearch: categoryName,
        pageNumberForSearch: pageNumber,
        activationTypeForSearch: activationType
      },
    });
  };

  // 노출 순서
  const goToEditSeq = () => {
    if (categoryId === 0) {
      alert('카테고리를 선택해 주세요');
    }
    else {
      navigate('/system/baundApi/editSeq', {
        state: {
          categoryIdForSearch: categoryId,
          categoryNameForSearch: categoryName,
          pageNumberForSearch: pageNumber,
          activationTypeForSearch: activationType
        }
      });
    }
  };

  const handleSelectSetData = (e: any) => {
    setCategoryId(e.baundApiCategoryId);
    setCategoryName(e.name);
  };

  return (
    <Container>
      <TitleText>바운드 API 목록</TitleText>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <BaundApiCategoryMenuSelect
              placeholder="" label=""
              value={categoryName}
              onChange={handleSelectSetData}
              options={categories}
            />
            <ButtonWrapper>
              <RoundButton title="활성" selected={activationType === 'active'} className={'noMargin'}
                onClick={() => setActivationType('active')}
              />
              <RoundButton title="비활성" selected={activationType === 'inactive'} className={'noMargin'}
                onClick={() => setActivationType('inactive')}
              />
            </ButtonWrapper>
          </SearchWrapper>
        </TopWrapper>
        <ButtonWrapper>
          {contentsList && (
            <>
              {activationType === 'active' && (
                <NormalButton text="노출 순서 변경" className="cancelBtn" onSubmit={() => goToEditSeq()}/>
              )}
            </>
          )}
          <NormalButton text="API 등록" onSubmit={goToDetail}/>
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={(e)=>setPageNumber(e-1)}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[50, 100, 100, 100]}
          thead={
            <>
              <ListHead title="아이디" />
              <ListHead title="제목" />
              <ListHead title="호출용 URL" />
              <ListHead title="설명" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.baundApiId} onClick={() => goToDetail(el.baundApiId)}>
              <td><ListItem text={el.baundApiId} /></td>
              <td><ListItem text={el.name} /></td>
              <td><ListItem text={el.apiUrl} /></td>
              <td><ListItem text={el.description} /></td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};
export default BaundApi;

const Container = styled.div`
  width: 100%;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;
