import React from 'react';
import styled from 'styled-components';

interface ListHeadProps {
  title?: string;
  thClassName?: string | undefined;
  className?: string | undefined;
  rowWidth?: number | undefined;
}

const ListHead = (props: ListHeadProps) => {
  const { title, thClassName, className, rowWidth } = props;
  return (
    <TableHead style={{width:rowWidth}} className={thClassName}>
      <OptionMenu className={className} title={title}>
        {title}
      </OptionMenu>
    </TableHead>
  );
};

export default ListHead;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
  &.simpleGray {
    padding: 0px 0px;
    background-color: #ccc;
  }
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ title, theme }) =>
    title === '' ? theme.color.mainWhite : theme.color.subBrightGray};
  z-index: 1;

  &.alignLeft {
    float: left;
  }
  &.marginRight {
    margin-right: 24px;
  }
`;
