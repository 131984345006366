import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { allTabMenuList } from '../../constant/explore';

const Category = () => {
  return (
    <MainContainer>
      {allTabMenuList.map((item) => {
        const { id, title, link } = item;
        return (
          <Link
            key={id}
            id={String(id)}
            to={link}
            state={{ detailData: {}, tabType: 'ALL' }}
          >
            <ListItem>
              <ListText>{title}</ListText>
            </ListItem>
          </Link>
        );
      })}
    </MainContainer>
  );
};

export default Category;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 2px;
`;

const ListItem = styled.div`
  display: block;
  position: relative;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const ListText = styled.span`
  ${({ theme }) => theme.font.listFont};
`;
