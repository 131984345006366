import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import searchIcon from '../../../assets/images/page/searchIcon.png';
import useOutSideClick from '../../../hooks/useOutSideClick';

interface SearchDataListProps {
  value?: string;
  setValue?: any;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  options: Array<any>;
  selectedMember: any;
  optionType: string;
}

const SearchDataListInput = ({
  value,
  setValue,
  onKeyPress,
  placeholder,
  options,
  selectedMember,
  optionType,
}: SearchDataListProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggling = () => setIsOpen(!isOpen);

  const handleValue = (e: any) => {
    setValue(e.target.value);
  };

  const onOptionClicked = (option: any) => {
    if (setValue) {
      setValue(option.pid);
    }
    selectedMember(option);
    setIsOpen(false);
  };

  // 외부 클릭시 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  return (
    <SearchInputWrapper
      ref={ref}
      onClick={() => {
        toggling();
      }}
    >
      <InputWrapper>
        <Img alt="searchIcon" src={searchIcon} />
        <Input
          placeholder={placeholder || '검색어를 입력해주세요.'}
          type="text"
          value={value}
          onChange={handleValue}
          onKeyPress={onKeyPress}
        />
      </InputWrapper>
      {options.length !== 0 && isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((option) => (
              <ListItem
                onClick={() => onOptionClicked(option)}
                key={Math.random()}
              >
                {optionType === 'E' ? <>{option.email}</> : <>{option.pid}</>}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </SearchInputWrapper>
  );
};

export default SearchDataListInput;

const SearchInputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const InputWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 44px;
  padding: 7px 12px;
  border-radius: 25px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  z-index: 10;
`;

const Img = styled.img`
  position: relative;
  width: 30px;
  margin-right: 6px;
  z-index: 5;
`;

const Input = styled.input`
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  z-index: 5;

  &::placeholder {
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 22px;
  left: 0;
  z-index: 2;
`;

const DropDownList = styled.ul`
  min-width: fit-content;
  max-height: 250px;
  margin-top: 22px;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListItem = styled.li`
  margin: 14px 30px 0 30px;
  cursor: pointer;
  list-style: none;

  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin-top: 14px;
  }

  &:last-child:after {
    margin-top: 14px;
    border-bottom: 0px;
  }
`;
