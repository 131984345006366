import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { signup, logIn, loadUser, logOut } from '../actions/user/user';

export interface User {
  id: number;
  name: string;
  email: string;
  mobile: string;
  countryCode: string;
  roles: Array<string>;
}

const initialState = {
  user: <User>{
    id: 0,
    name: '',
    email: '',
    mobile: '',
    countryCode: '',
    roles: [],
  },
  category: [],
  isLoggedIn: false,
  logInError: '',
  signupError: '',
  signupDone: false,
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    },
    resetLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      // 회원가입
      .addCase(signup.pending, (state, action) => {})
      .addCase(signup.fulfilled, (state, action) => {
        console.log(action.payload);
        state.signupDone = true;
      })
      .addCase(signup.rejected, (state, action: PayloadAction<any>) => {
        state.signupError = action.payload;
      })
      // 로그인
      .addCase(logIn.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logIn.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user.email = action.payload.email;
        state.user.mobile = action.payload.mobile;
        state.user.roles = action.payload.roles;
        state.user.id = action.payload.id;
        state.user.name = action.payload.name;
        state.user.countryCode = action.payload.countryCode;
      })
      .addCase(logIn.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.logInError = action.payload;
      })
      // 로그아웃
      .addCase(logOut.pending, (state, action) => {})
      .addCase(logOut.fulfilled, (state, action) => {
        state.isLoggedIn = false;
      })
      .addCase(logOut.rejected, (state, action) => {})
      // 로그인 상태 불러오기
      .addCase(loadUser.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        state.isLoggedIn = true;
        state.user.email = action.payload.adminAccount.email;
        state.user.mobile = action.payload.adminAccount.mobile;
        state.user.roles = action.payload.adminAccount.roles;
        state.user.id = action.payload.adminAccount.id;
        state.user.name = action.payload.adminAccount.name;
        state.user.countryCode = action.payload.adminAccount.countryCode;
        state.category = action.payload.categoryList;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.isLoggedIn = false;
      });
  },
});

export default userSlice;
export const { setLoading, resetLoading } = userSlice.actions;
