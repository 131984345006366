import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import NormalButton from '../../components/common/Button/NormalButton';
import Pagination from '../../components/common/Pagination';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import { COUPONS } from '../../constant/apiUrl';
import { postsPerPage } from '../../constant/common';
import { IssuanceTypeObjects } from './CouponParams';

interface Coupon {
  masterCouponId: number;
  name: string;
  privateYn: string;
  value: number;
  issuanceType: string;
  issuanceCount: number;
  maxIssuanceCount: number;
  usedYn: string;
  startDateTime: string;
  endDateTime: string;
  createDate: string;
  updateDate: string;
}

interface SearchParam {
  offset: number;
  limit: number;
}

// 골드 캠페인 목록 화면
const MasterCouponList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 페이징
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  //표시 데이터
  const [masterCouponList, setMasterCouponList] = useState([]);

  // 검색 조건 관련 /////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  //캠페인 데이터 조회 //////////////////////////////////////////////////////////
  const getCampaigns = createAsyncThunk(
    'coupon/Coupons',
    async (parameters: SearchParam) => {
      await axios
        .get(COUPONS, { params: parameters })
        .then((res) => {
          const { data } = res;
          setTotalCount(data.count);
          setMasterCouponList(data.masterCouponList);
        })
        .catch((error) => alert(error.response));
    },
  );
  //검색 조건 변경시 dispatch
  useEffect(() => {
    dispatch(getCampaigns(params));
  }, [dispatch, pageNumber]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <ButtonWrapper>
            <NormalButton
              text="쿠폰 만들기"
              onSubmit={() => navigate('/coupons/add')}
            />
          </ButtonWrapper>
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={(e) => setPageNumber(e - 1)}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>

      <MainContainer>
        <Table
          colList={[50, 60, 60, 80, 100, 100, 100, 60, 110]}
          thead={
            <>
              <ListHead title="Id" />
              <ListHead title="쿠폰 명" />
              <ListHead title="쿠폰 가격가격" />
              <ListHead title="상태" />
              <ListHead title="상품권 형식" />
              <ListHead title="등록/발행" />
              <ListHead title="사용 유효기간" />
              <ListHead title="발급 일자" />
              <ListHead title="수정 일자" />
            </>
          }
        >
          {masterCouponList?.map((masterCoupon) => (
            <CouponRow key={masterCoupon.masterCouponId} {...masterCoupon} />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};
export default MasterCouponList;

const getCouponStatus = (data: Coupon) => {
  // utc now timestamp
  const nowLocalDateTime = new Date();
  // 서버에서 받은 시간값 타임존 = utc
  const localStartDateTime = new Date(`${data.startDateTime}Z`);
  // 서버에서 받은 시간값 타임존 = utc
  const localEndTimestamp = new Date(`${data.endDateTime}Z`);
  if (nowLocalDateTime < localStartDateTime) {
    return '대기';
  }
  if (
    localStartDateTime <= nowLocalDateTime &&
    nowLocalDateTime < localEndTimestamp
  ) {
    return '진행중';
  }
  return '종료';
};

const convertUtcToLocalTimeZone = (targetDateTime: string) => {
  if (!targetDateTime) return '';
  // yyyy-MM-ddTHH:mm:ss 패턴으로 입력되는 타입이므로
  // yyyy-MM-ddTHH:mm:ssZ 로 utc 기준 시간이란 것을 표현
  const utcTargetDateTime = new Date(`${targetDateTime}Z`);
  return utcTargetDateTime.toLocaleString().substring(0, 11);
};

function convertIssuanceType(originIssuanceType: string) {
  // 상품권 발급 형식 변환
  const convertedIssuanceType = IssuanceTypeObjects.map((issuanceTypeObj) => {
    return Object.entries(issuanceTypeObj)
      .filter((typeObj) => typeObj[0] === originIssuanceType)
      .map((typeObj) => typeObj[1]);
  });
  // 상품권 발급 형식 객체 목록 상수에 없는 발급 형식의 경우 원본 값 그대로 리턴
  if (!convertedIssuanceType) return originIssuanceType;
  // 있는 경우 변환 값 리턴
  return convertedIssuanceType;
}

const CouponRow = (data: Coupon) => {
  const navigate = useNavigate();
  const couponPeriod = `${
    data.startDateTime ? convertUtcToLocalTimeZone(data.startDateTime) : ''
  } ~
  ${data.endDateTime ? convertUtcToLocalTimeZone(data.endDateTime) : ''}`;

  return (
    <tr
      key={data.masterCouponId}
      onClick={() =>
        navigate('/coupons/coupon-detail', {
          state: { masterCouponId: data.masterCouponId },
        })
      }
    >
      <td>
        <ListItem text={data.masterCouponId} />
      </td>
      <td>
        <ListItem text={data.name} />
      </td>
      <td>
        <ListItem text={data.value} />
      </td>
      <td>
        <ListItem text={getCouponStatus(data)} />
      </td>
      <td>
        <ListItem text={convertIssuanceType(data.issuanceType)} />
      </td>
      <td>
        <ListItem text={`${data.issuanceCount}/${data.maxIssuanceCount}`} />
      </td>
      <td>
        <ListItem text={couponPeriod} />
      </td>
      <td>
        <ListItem text={convertUtcToLocalTimeZone(data.createDate)} />
      </td>
      <td>
        <ListItem text={convertUtcToLocalTimeZone(data.updateDate)} />
      </td>
    </tr>
  );
};

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;
