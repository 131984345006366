import React from 'react';
import styled from 'styled-components';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

interface TextInputProps {
  name: string;
  type?: string;
  labels: string[];
  placeholder?: string;
  value: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onReset?: (name: string) => void | undefined;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  Required?: boolean;
  readOnly?: boolean;
}

const MultiLabelTextInput = ({
    name,
    type,
    value,
    labels,
    onChange,
    onReset,
    placeholder,
    onKeyPress,
    Required,
    readOnly,
}: TextInputProps) => {
  return (
    <InputContainer>
        {(labels) && (
            labels.map((label, idx) => (
                <InputText key={`label${idx}`}>
                    {label} {(idx === 0) && Required && <span>*</span>}
                </InputText>
                )
            )
            )
        }
      <InputBox>
        {(type === 'priceInput' ||
          type === 'reportPoint' ||
          type === 'mobile' ||
          type === 'number' ||
          type === 'id') && (
          <input
            name={name}
            value={value}
            type="number"
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        )}
        {type === 'passwordInput' && (
          <input
            name={name}
            value={value}
            type="password"
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        )}
          {type === 'textarea' && (
              <textarea cols={50} rows={10}/>
          )}
        {!type && (
          <input
            name={name}
            value={value || ''}
            placeholder={placeholder}
            onChange={onChange}
            onKeyPress={onKeyPress}
            readOnly={readOnly}
          />
        )}
        {value !== 0 && value && onReset && (readOnly !== true) && (
          <InputDeleteButton onClick={() => onReset(name)} tabIndex={-1} />
        )}
      </InputBox>
    </InputContainer>
  );
};

export default MultiLabelTextInput;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-bottom: 10px;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div`
  position: relative;

  input {
    width: 100%;
    height: 55px;
    padding: 0 40px 0 20px;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.color.subBrightGray};
    font-size: 18px;
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }

  input::placeholder {
    color: ${({ theme }) => theme.color.subGray};
    font-weight: 500;
  }
`;

const InputDeleteButton = styled.button`
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;
