import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import axios from 'axios';

import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import MultiSelect from '../../../components/common/Select/MultiSelect';
import { useAppSelector } from '../../../redux/store/hooks';

//api
import { ADD_ACCOUNTS, MASTER_ROLE_LIST } from '../../../constant/apiUrl';

type data = {
  [key: string]: any;
};

//초기 계정 데이터
const initialAccountData: data = {
  name: '',
  email: '',
  password: '',
  mobile: '',
  roles: [],
  countryCode: '82',
  activeYn: '',
};

const AddAccount = () => {
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState(initialAccountData);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [adminRoleList, setAdminRoleList] = useState<any>([]);
  const accountRoleList = adminRoleList?.map((el: any) => el.userRole);
  console.log(accountRoleList);
  console.log(adminRoleList);

  const { name, email, password, mobile, roles, activeYn, countryCode } =
    accountData;

  const handleSetData = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    (e: string, name: string) => {
      setAccountData({ ...accountData, [name]: e });
    },
    [accountData],
  );
  // 권한 여러개 저장하기
  const handleMultiSetData = useCallback(
    (e: string) => {
      const duplicateCheck: number = roles?.indexOf(e);
      if (duplicateCheck !== -1) {
        const removeOption = roles?.filter((el: string) => el !== e);

        setAccountData({
          ...accountData,
          roles: removeOption,
        });
        return;
      }
      setAccountData({
        ...accountData,
        roles: roles.concat(e),
      });
    },
    [accountData, roles],
  );

  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      setAccountData({ ...accountData, [name]: value });
    },
    [accountData],
  );

  const handleResetData = useCallback(
    (e) => {
      setAccountData({ ...accountData, [e]: initialAccountData[e] });
    },
    [accountData],
  );

  // 관리자 계정 추가
  const createAccount = async () => {
    const adminRole = adminRoleList.filter((el: any) => {
      const upperCaseRoles = roles.map((e: string) => e.toUpperCase());
      return upperCaseRoles.includes(el.userRole);
    });
    const body = {
      email,
      password,
      mobile: mobile.slice(1, 11),
      name,
      countryCode,
      activeYn,
      roleIds: adminRole?.map((el: any) => el.id),
      adminName,
    };
    if (!email || !password || !mobile || !name || !activeYn || !roles) {
      alert('정보를 모두 입력해주세요.');
    } else {
      try {
        await axios.post(ADD_ACCOUNTS, body);
        navigate('/admin/adminAccount');
      } catch (err) {
        alert('양식에 따라 작성해주세요.');
      }
    }
  };

  // 어드민 권한 리스트 불러오기
  const getAdminRoleList = useCallback(async () => {
    const params = {
      limit: 100,
      offset: 0,
    };
    try {
      const resp = await axios.get(MASTER_ROLE_LIST, { params });
      setAdminRoleList(resp.data.adminRoleList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getAdminRoleList();
  }, [getAdminRoleList]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <div />
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
            <NormalButton
              text="저장하기"
              onSubmit={createAccount}
              className=""
            />
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="이름"
            value={name}
            placeholder="이름을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="email"
            label="이메일"
            value={email}
            placeholder="이메일을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
          <InputWrapper />
          <LabelTextInput
            name="password"
            label="비밀번호"
            value={password}
            placeholder="비밀번호를 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            type="passwordInput"
          />
          <InputWrapper />
          <LabelTextInput
            name="mobile"
            label="휴대폰 번호"
            value={mobile}
            placeholder="휴대폰 번호를 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
          <InputWrapper />
          <MultiSelect
            name="roles"
            placeholder="권한을 선택해주세요."
            value={roles}
            label="권한 설정"
            onChange={handleMultiSetData}
            options={accountRoleList}
          />
          <InputWrapper />
          <SelectModule
            name="activeYn"
            placeholder="활성화 여부를 선택해주세요."
            value={activeYn}
            label="활성화 여부"
            onChange={handleSetData}
            options={['Y', 'N']}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AddAccount;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 100px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;
`;
