import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// common
import axios from 'axios';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';

// constant
import { postsPerPage } from '../../constant/common';

// hooks
import useInput from '../../hooks/useInput';

// config
import { BAUND_MUSIC_LIST } from '../../constant/apiUrl';

// component
import NormalButton from '../../components/common/Button/NormalButton';
import {
  baundMusicTypeOption,
  musicPrivateYnOptions,
  releaseMusicColList,
  releaseMusicSearchTypeList,
} from '../../constant/baundMusic';
import ReleaseSoundRowComponent from './components/ReleaseSoundRowComponent';

const ReleaseSound = () => {
  const navigate = useNavigate();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [musicList, setMusicList] = useState([]);
  const [word, setWord] = useInput(storageWord || '');
  const [searchType, setSearchType] = useState<string>(storageType || 'I');
  const [type, setType] = useState('');
  const [filterType, setFilterType] = useState('');
  const [privateYn, setPrivateYn] = useState('');
  const [filterPrivateYn, setFilterPrivateYn] = useState('');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  const params = {
    searchType,
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    type,
    privateYn
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      getBaundMusicList();
    }
    if (e.key === 'Enter' && !word) {
      params.searchType = '';
      getBaundMusicList();
    }
  };

  // 발매 음원 조회
  const getBaundMusicList = async () => {
    try {
      const resp = await axios.get(BAUND_MUSIC_LIST, {
        params,
      });
      setMusicList(resp.data.baundMusics);
      setTotalCount(resp.data.totalCount || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  useEffect(() => {
    if (storagePageNumber === '0') {
      params.offset = 0;
      setPageNumber(0);
    }
    getBaundMusicList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, type, privateYn]);

  useEffect(() => {
    setPageNumber(0);
    setType(filterType);
  }, [filterType])

  useEffect(() => {
    setPageNumber(0);
    setPrivateYn(filterPrivateYn);
  }, [filterPrivateYn])

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={releaseMusicSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="음원 등록하기"
            onSubmit={() => {
              navigate('/baundMusic/release/add');
            }}
          />
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={releaseMusicColList}
          thead={
            <>
              <ListHead title="Index" />
              <ListHead title="Cover" />
              <ListHead title="Title" />
              <ListHead title="아티스트" />
              <ListSelect
                title="음원 타입"
                list={baundMusicTypeOption}
                onClick={handleSelect}
                setState={setFilterType}
              />
              <ListSelect
                title="공개 여부"
                list={musicPrivateYnOptions}
                onClick={handleSelect}
                setState={setFilterPrivateYn}
              />
              <ListHead title="노출 시작일" />
              <ListHead title="수정" />
            </>
          }
        >
          {musicList?.map((el: any) => (
            <ReleaseSoundRowComponent key={el.baundMusicId} data={el} />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default ReleaseSound;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 60px;
`;
