import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import NotExposure from '../../../../components/UI/NotExposure';
import ExploreContentsBaundMusicRowComponents from './ExploreContentsBaundMusicRowComponents';
import Pagination from '../../../../components/common/Pagination/index';

// config
import { EXPLORE_CONTENTS_LIST } from '../../../../constant/apiUrl';

// constant
import { baundMusicSearchTypeList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';
import SearchMenuSelect from '../../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../../components/common/Input/SearchInput';
import useInput from '../../../../hooks/useInput';

const ExploreContentsBaundMusic = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const location = useLocation();
  const { detailData = {} }: any = location.state || {};
  const [baundMusicExploreContentsList, setBaundMusicExploreContentsList] =
    useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [word, setWord] = useInput(storageWord || '');
  const [searchType, setSearchType] = useState<string>(storageType || 'I');
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const params = {
    searchType,
    word,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (e: React.MouseEvent<HTMLLIElement>) => {
    setSearchType(e.currentTarget.title);
  };

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      getBaundMusicExploreContents();
    }
    if (e.key === 'Enter' && !word) {
      params.searchType = '';
      getBaundMusicExploreContents();
    }
  };

  const getBaundMusicExploreContents = useCallback(async () => {
    try {
      const resp = await axios.get(EXPLORE_CONTENTS_LIST, {
        params,
      });

      setBaundMusicExploreContentsList(resp.data.adminExploreContents);
      setTotalCount(resp.data.totalCount);
    } catch (err) {
      console.log(err);
    }
  }, [params]);

  useEffect(() => {
    getBaundMusicExploreContents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (getNumber) {
      window.localStorage.setItem('pageNumber', '0');
    }
  }, [getNumber]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={baundMusicSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          <NormalButton
            text="둘러보기 Baund Music 관련 추가하기"
            onSubmit={() => navigate('/explore/allTab/baundMusic/add')}
          />
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
      </TopContainer>
      {baundMusicExploreContentsList?.length !== 0 &&
      baundMusicExploreContentsList !== undefined ? (
        <MainContainer>
          <Table
            colList={baundMusicExploreContentsList}
            thead={
              <>
                <ListHead title="Index" />
                <ListHead title="cover" />
                <ListHead title="type" />
                <ListHead title="Title" />
                <ListHead title="공개 여부" />
                <ListHead title="시작일" />
                <ListHead title="종료일" />
                <ListHead title="수정" />
              </>
            }
          >
            {baundMusicExploreContentsList?.map((el: any) => (
              <ExploreContentsBaundMusicRowComponents
                key={el.exploreContentsId}
                data={el}
              />
            ))}
          </Table>
        </MainContainer>
      ) : (
        <NotExposure
          title="둘러보기 Baund Music 관련 세팅하기"
          link="add"
          onSubmit={() => {
            if (Object.keys(detailData).length !== 0) {
              navigate('add', {
                state: { exploreContentsId: detailData.exploreContentsId },
              });
            } else {
              navigate('add', { state: {} });
            }
          }}
        />
      )}
    </>
  );
};

export default ExploreContentsBaundMusic;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const MainContainer = styled.div`
  margin-top: 50px;
`;
