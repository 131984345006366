import React from 'react';
import { useNavigate } from 'react-router-dom';

// common
import EditButton from '../../../components/common/Button/EditButton';
import ListItem from '../../../components/common/List/ListItem';

// hooks
import handleDate from '../../../hooks/handleDate';

// constant
import { IsPrivate } from '../../../constant/common';
import { baundMusicType } from '../../../constant/baundMusic';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

const ReleaseSoundRowComponent = ({ data }) => {
	const navigate = useNavigate();
	const {
		baundMusicId,
		coverImgUrl,
		title,
		artist,
		type,
		privateYn,
		startDate,
	} = data;

	const editMember = () => navigate('/baundMusic/release/edit', {state: data});

	return (
		<tr>
			<td><ListItem text={baundMusicId} /></td>
			<td><ListCoverImg url={coverImgUrl} /></td>
			<td><ListItem text={title} /></td>
			<td><ListItem text={artist} /></td>
			<td><ListItem text={baundMusicType[type]} /></td>
			<td><ListItem text={IsPrivate[privateYn]} /></td>
			<td><ListItem text={handleDate(startDate)} /></td>
			<td><EditButton text="수정" onClick={() => editMember()}/></td>
		</tr>
	);
};

export default ReleaseSoundRowComponent;