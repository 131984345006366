import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';

// common components
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import DateInput from '../../../components/common/Input/DateInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';
import Table from '../../../components/common/List/Table';
import VideoInput from "../../../components/common/Input/VideoInput";
import MultiLabelTextInput from "../../../components/common/Input/MultiLabelTextInput";

// components
import {SelectedNoticeModalRowComponent,} from '../components/EventModalRowComponent';
import PushForEvent from "../components/PushForEvent";
import PushForBattle from "../components/PushForBattle";
import BattleTable from "../../battle/BattleTable";

// constants
import {GetLanguageOption, GetCountryOption, countryOptions, languageOptions,} from '../../../constant/common';
import {noticeModalColList, noticeTypeList} from '../../../constant/event';

// config
import {BATTLE_SIMPLE, EVENT_LIST, NOTICE,} from '../../../constant/apiUrl';

// redux
import {setLoading, resetLoading} from '../../../redux/slices/user';

//타입
import {limitCall} from '../../../utils/utils';

const NoticeDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const detailData = location.state;
	const prevId = detailData !== null ? detailData.prevId : null;

	// 배너 이미지 주소
	const [bannerImgUrl, setBannerImgUrl] = useState('');
	const [bannerImgFile, setBannerImgFile] = useState(null);
	// 프로젝트 명
	const [projectName, setProjectName] = useState('');
	// 공지 제목
	const [title, setTitle] = useState('');
	// 프로젝트 명
	const [description, setDescription] = useState('');
	// 공지 활성화 여부
	const [activeYn, setActiveYn] = useState('Y');
	// 국가 코드
	const [countryCode, setCountryCode] = useState('');
	// 공지사항 적용 국가 언어
	const [language, setLanguage] = useState('');
	// 공지사항 유형 : 메인 공지 이므로 M 고정
	// const [noticeType, setNoticeType] = useState('');
	// 공지 시작 시간
	const [startDt, setStartDt] = useState('');
	// 공지 종료 시간
	const [endDt, setEndDt] = useState('');

	// 자세히 보기 클릭시 이동할 컨텐츠 유형
	const [relatedContentsTypeString, setRelatedContentsTypeString] = useState('');
	const [relatedContentsType, setRelatedContentsType] = useState('');
	// 공지사항 클릭시 이동할 페이지 주소
	const [relatedContentsUrl, setRelatedContentsUrl] = useState('');
	// 자세히 보기 클릭시 보여줄 컨텐츠 Id
	const [relatedContentsId, setRelatedContentsId] = useState('');
	// 자세히 보기 클릭시 보여줄 컨텐츠
	const [relatedContents, setRelatedContents] = useState(null);
	// 유저 선택시 비 표시할 일 수
	const [hideDays, setHideDays] = useState(0);
	// 이미지 파일 확장자 체크 결과
	const [isFileError, setIsFileError] = useState(false);
	// 광고 여부
	const [isAdYn, setIsAdYn] = useState('N');
	// 참고 문구. 좌상단 [AD] 표시를 위해 추가
	const [markupTag, setMarkupTag] = useState(null);

	//공지 상세 조회
	const getDetail = useCallback(async () => {
			await axios.get(`${NOTICE}/${prevId}`)
			.then(res => {
				const detail = res.data.notice;
				console.log(detail);
				
				setBannerImgUrl(detail.bannerImgUrl);
				setProjectName(detail.projectName)
				setTitle(detail.title);
				setDescription(detail.description);
				setActiveYn(detail.activeYn);
				setCountryCode(detail.countryCode);
				setLanguage(detail.language);
				setStartDt(detail.startDt?.replace(' ', 'T').slice(0, 16));
				setEndDt(detail.endDt?.replace(' ', 'T').slice(0, 16));
				setRelatedContentsId(detail.relatedContentsId);
				setRelatedContentsType(detail.relatedContentsType);
				setRelatedContentsUrl(detail.relatedContentsUrl)
				setRelatedContentsType(detail.relatedContentsType);
				setHideDays(detail.hideDays ? detail.hideDays : 0);
				setIsAdYn(detail.isAdYn? detail.isAdYn:'N');
				setMarkupTag(detail.markupTag);
				
				// 선택된 콘텐츠 가져오기
				if (detail.relatedContentsId) {
					if (detail.relatedContentsType === 'EV') {
						getEventDetail(detail.relatedContentsId);
					} else if (detail.relatedContentsType === 'BATTLE_ROOM') {
						getBattleDetail(detail.relatedContentsId);
					}
				}
			})
			.catch(reason => {
				console.error(reason);
				alert('잠시후 다시 이용해주세요.');
			});
	}, [prevId]);

	// 검색 키워드 변경 확인
	const checkProjectNameChange = () =>
			JSON.stringify(
				projectName !== null ? projectName?.split(',') : projectName
			) !== JSON.stringify(projectName);

	// 데이트 저장시 사용
	const handleDate = (date, tRemove, start) => {
		let _date = date;

		if (tRemove && date.indexOf('T') < 0) {
			_date = date.split(' ');
			_date = _date[0] + 'T' + _date[1];
		}
		if (!tRemove && date.indexOf('T') > 0) {
			_date = date.split('T');
			_date = _date[0] + ' ' + _date[1];
		}

		return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
	}

	// 이벤트 정보 가져오기
	const getEventDetail = eventId => {
		axios.get(`${EVENT_LIST}/${eventId}`)
		.then(res => {
			if (res?.data) setRelatedContents(res.data.event)
			else new Error('데이터가 없습니다.');
		})
		.catch(reason => {
			alert(reason);
			console.error(reason);
		});
	}
	
	// 배틀 정보 가져오기
	const getBattleDetail = battleId => {
		axios.get(`${BATTLE_SIMPLE}/${battleId}`)
		.then(res => {
			if (res?.data) setRelatedContents(res.data.battle);
			else new Error('데이터가 없습니다.');
		})
		.catch(reason => {
			alert(reason);
			console.error(reason);
		});
	}
	
	// 취소하면 뒤로가기
	const goBack = () => navigate('/operation/notice');

	const getSubmitRelatedContentsType = (relatedContentsType) => {
		if (relatedContentsType === 'MOVE_WHAT_IS_BAUND_GOLD') {
			return 'MISSION';
		}
		if (relatedContentsType === 'MOVE_WHAT_IS_GOLD_CAMPAIGN') {
			return 'CAMPAIGN';
		}
		else {
			return relatedContentsType;
		}
	};

	/* 저장 및 수정 */
	const handleEventBanner = limitCall(async (handleType) => {
		if(isFileError) {
			alert('파일 확장자를 확인해주세요.');
			return;
		}
		// 데이터 유효성 체크
		if (!activeYn || !startDt || !endDt || !title || !countryCode || !language) {
			alert('양식을 모두 채워주세요.');
			return;
		}
		if (relatedContentsType === 'EV' && !relatedContentsId) {
			alert('이동할 이벤트를 선택해 주세요.');
			return;
		}
		if ((relatedContentsType === 'EL' ||
				relatedContentsType === 'MOVE_WHAT_IS_GOLD_CAMPAIGN' ||
				relatedContentsType === 'MOVE_WHAT_IS_BAUND_GOLD') &&
			!relatedContentsUrl) {
			alert('이동할 URL을 입력해 주세요.');
			return;
		}
		if (startDt > endDt) {
			alert('시작일시가 종료일시보다 느릴 수 없습니다.');
			return;
		}
		if (new Date() > new Date(endDt)) {
			alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
			return;
		}

		const formData = new FormData();
		formData.append('noticeType', 'M');
		formData.append('noticeBannerImgFile', bannerImgFile);
		if (relatedContentsType) {
			formData.append('relatedContentsType', getSubmitRelatedContentsType(relatedContentsType));
		}
		if (relatedContentsId) {
			formData.append('relatedContentsId', relatedContentsId);
		}

		const request = {};
		if (checkProjectNameChange()) {
			request['projectName'] = projectName;
		}
		request['title'] = title;
		request['description'] = description === null ? '' : description;
		request['activeYn'] = activeYn;
		request['startDate'] = handleDate(startDt, false, true);
		request['endDate'] = handleDate(endDt, false, false);
		request['countryCode'] = countryCode;
		request['language'] = language;
		request['hideDays'] = hideDays;
		request['isAdYn'] = isAdYn;
		if (relatedContentsUrl) request['relatedContentsUrl'] = relatedContentsUrl;
		if (markupTag) request['markupTag'] = markupTag;
		
		Object.keys(request).forEach(key => formData.append(key, request[key]));

		dispatch(setLoading());
		await axios.post(
				handleType === 'add' ? NOTICE : `${NOTICE}/${prevId}`,
				formData,
				{}
		)
		.then(() => {
			alert('공지가 저장되었습니다.');
			navigate('/operation/notice');
		})
		.catch(reason => {
			alert('잠시후 다시 이용해주세요');
			console.error(reason);
		})
		.finally(() => dispatch(resetLoading()))

	}, 'event-detail');

	// 이미지 파일 변경시 확장자 검사
	useEffect(() => {
		// const eventFileNm = bannerImgFile?.name;
		const getImgType = (file) => file.slice(file.lastIndexOf('.') + 1).toLowerCase();

		if (bannerImgFile && getImgType(bannerImgFile.name) !== 'jpg') {
			setIsFileError(true);
		} else {
			setIsFileError(false);
		}

	}, [bannerImgFile]);
	
	// 공지 타입 변경시 공지 타입 명칭 변경
	useEffect(() => {
		let contentsTypeObj;
		if (relatedContentsType === 'CAMPAIGN' && relatedContentsUrl != null && relatedContentsUrl.length > 0) {
			contentsTypeObj = noticeTypeList.find(el => el.value === 'MOVE_WHAT_IS_GOLD_CAMPAIGN');
		} else if (relatedContentsType === 'MISSION' && relatedContentsUrl != null && relatedContentsUrl.length > 0) {
			contentsTypeObj = noticeTypeList.find(el => el.value === 'MOVE_WHAT_IS_BAUND_GOLD');
		} else {
			contentsTypeObj = noticeTypeList.find(el => el.value === (relatedContentsType ? relatedContentsType : ''));
		}
		if (contentsTypeObj) {
			setRelatedContentsTypeString(contentsTypeObj.name);
			setRelatedContentsType(contentsTypeObj.value);
		}
	}, [relatedContentsType]);
	
	// 수정으로 페이지 진입시, 공지 상세 조회
	useEffect(() => {
		if (prevId) getDetail().then((r) => r);
	}, []);
	
	// 모달 영역 -----------------------------------------------------------------
	// 이벤트 선택 모달 창
	const [modalOpen, setModalOpen] = useState(false);
	const [selectedContentList, setSelectedContentList] = useState([]);

	// modal 열기
	const openModal = () => {
		setModalOpen(true);
		relatedContents && setSelectedContentList([relatedContents]);
	};

	// modal 닫기
	const closeModal = () => {
		setModalOpen(false);
	};

	// 선택된 이벤트 세팅
	const selectEventRelatedContents = (selectedContent) => {
		setRelatedContents(selectedContent);
		setRelatedContentsId(selectedContent.id);
		closeModal();
	};

	return <>
		<TopContainer>
			<ButtonWrapper>
				<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>
				<NormalButton
					text={prevId ? '수정하기' : '저장하기'}
					onSubmit={() => handleEventBanner(prevId ? 'edit' : 'add').then(() => dispatch(resetLoading()))}
				/>
			</ButtonWrapper>
		</TopContainer>
		<TitleText>공지 기본 정보</TitleText>
		<LineDiv/>
		<MainContainer>
			{/*공지 이미지*/}
			<InputWrapper span={2}>
				<FileWrapper>
					<FileInput
						type="eventImg" label="공지 이미지 (jpg 파일만 등록 가능)" name="eventBannerFile"
						placeholder={bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
						value={bannerImgFile}
						setFile={(e) => setBannerImgFile(e)}
						setImgUrl={setBannerImgUrl}
						// fileUrl={eventBannerImgUrl}
						Required
					/>
					{isFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
					<CoverImgWrapper>
						{bannerImgUrl ? <EventImg src={bannerImgUrl } /> : <EventImg />}
					</CoverImgWrapper>
				</FileWrapper>
			</InputWrapper>

			{/*프로젝트 명*/}
			<InputWrapper span={2}>
				<LabelTextInput
					name="projectName" label="프로젝트 명" placeholder="프로젝트 명을 입력해주세요."
					value={projectName} onChange={(e) => setProjectName(e.target.value)} onReset={() => setProjectName('')}
					Required
				/>
			</InputWrapper>

			{/*제목*/}
			<InputWrapper span={2}>
				<LabelTextInput
					name="title" label="제목" placeholder="제목을 입력해주세요."
					value={title} onChange={(e) => setTitle(e.target.value)} onReset={() => setTitle('')}
					Required
				/>
			</InputWrapper>

			{/*설명*/}
			<InputWrapper span={2} mb={15}>
				<LabelTextInput
					name="description" label="설명" placeholder="설명을 입력해주세요."
					value={description} onChange={(e) => setDescription(e.target.value)} 	onReset={() => setDescription('')}
				/>
			</InputWrapper>

			{/*공개 여부*/}
			<InputWrapper span={2}>
				<RadioButton
					name="activeYn" label="공개 여부" type="activeYn"
					value={activeYn} onChange={(e) => setActiveYn(e.target.value)} text={['공개', '비공개']}
					Required
				/>
			</InputWrapper>

			{/*게시 시작,종료일*/}
			<InputWrapper span={2} mb={15}>
				<DateWrapper>
					<DateInput initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)} name="startDt" label="게시 시작일" Required/>
					<DateInput initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)} name="endDt" label="게시 종료일" Required/>
				</DateWrapper>
			</InputWrapper>

			{/*국가*/}
			<InputWrapper>
				<SelectModule
					name="countryCode" placeholder="국가를 선택해주세요." label="국가"
					value={GetCountryOption[countryCode]} onChange={e => setCountryCode(e.value)} options={countryOptions}
					Required
				/>
			</InputWrapper>

			{/*언어*/}
			<InputWrapper>
				<SelectModule
					name="language" placeholder="언어 코드를 선택해주세요." label="언어 코드"
					value={GetLanguageOption[language]} onChange={e => setLanguage(e.value)} options={languageOptions}
					Required
				/>
			</InputWrapper>

			{/*유저 선택시 비 표시할 일 수*/}
			<InputWrapper span={2} mb={15}>
				<LabelTextInput
						name="hideDays" label="유저 선택시 비 표시할 일 수" placeholder="유저 선택시 비 표시할 일수를 입력해주세요."
						type="number" value={hideDays} onChange={(e) => setHideDays(e.target.value)}
				/>
			</InputWrapper>

			{/*광고 여부*/}
			<InputWrapper>
				<RadioButton
						name="isAdYn" label="광고 여부" type="activeYn"
						value={isAdYn} onChange={(e) => setIsAdYn(e.target.value)} text={['광고', '광고 아님']}
						Required
				/>
			</InputWrapper>

			{/*참고 문구*/}
			<InputWrapper mb={15}>
				<LabelTextInput
						name="markupTag" label="참고 문구" placeholder="공지 좌상단 화면에 표시될 문구를 입력해주세요(eg.[AD])."
						value={markupTag} onChange={(e) => setMarkupTag(e.target.value)} onReset={() => setMarkupTag('')}
				/>
			</InputWrapper>

			{/*공지 타입 선택	*/}
			<InputWrapper span={2}>
				<SelectModule
						id="noticeType" name="noticeType" label="공지 타입" placeholder="공지 타입을 선택해 주세요."
						value={relatedContentsTypeString}
						onChange={(e) => {
							setRelatedContentsId(null);
							setRelatedContentsUrl(null);
							setRelatedContents(null);
							setRelatedContentsType(e.value);
							setSelectedContentList([]);
						}}
						options={noticeTypeList}
				/>
			</InputWrapper>
			
			{/* -------------------------------------------------------------------------------- */}
			{/*선택된 이벤트 정보	*/}
			{relatedContentsType === 'EV' && (
				<InputWrapper span={2}>
					<VideoInput text={`${relatedContentsTypeString} 선택`} onClick={openModal}/>
				</InputWrapper>
			)}

			{/*외부 링크*/}
			{
				(relatedContentsType === 'EL') &&
				(<InputWrapper span={2}>
					<LabelTextInput
							name="relatedContentsUrl"
							label="외부 링크"
							placeholder="이동할 외부 링크를 입력해 주세요."
							value={relatedContentsUrl}
							onChange={(e) => setRelatedContentsUrl(e.target.value)}
							onReset={() => setRelatedContentsUrl('')}
					/>
				</InputWrapper>)
			}
			{
				(relatedContentsType === 'MOVE_WHAT_IS_GOLD_CAMPAIGN' || relatedContentsType === 'MOVE_WHAT_IS_BAUND_GOLD') &&
				(<InputWrapper span={2}>
					<MultiLabelTextInput
						name="relatedContentsUrl"
						labels={
							[
								`이동할 링크`,
								`골드 캠페인 이란? = ${process.env.REACT_APP_WHAT_IS_GOLD_CAMPAIGN}`,
								`BaundGold 란 ? = ${process.env.REACT_APP_WHAT_IS_BAUND_GOLD}`
							]
						}
						placeholder="이동할 외부 링크를 입력해 주세요."
						value={relatedContentsUrl}
						onChange={(e) => setRelatedContentsUrl(e.target.value)}
						onReset={() => setRelatedContentsUrl('')}
					/>
				</InputWrapper>)
			}

			{/* 배틀 룸 타입 선택 시 */}
			{relatedContentsType === 'BATTLE_ROOM' && (
				<InputWrapper span={2}>
					<VideoInput text='배틀 선택' onClick={openModal}/>
				</InputWrapper>
			)}

			{/* -------------------------------------------------------------------------------- */}
			{/* 선택된 이벤트 목록 */}
			{(relatedContentsType === 'EV' && relatedContents) &&
				<InputWrapper span={2}>
					<Table colList={noticeModalColList} className="nonListTable">
						<SelectedNoticeModalRowComponent data={relatedContents}/>
					</Table>
				</InputWrapper>
			}
			
			{/* 선택된 배틀 */}
			{(relatedContentsType === 'BATTLE_ROOM' && relatedContents) &&
				<InputWrapper span={2}>
					<BattleTable battleList={relatedContents && [relatedContents]} readOnly={true} />
				</InputWrapper>
			}
		</MainContainer>
		
		
		{/* -------------------------------------------------------------------------------- */}
		{/* 이벤트 선택 모달*/}
		{(relatedContentsType === 'EV' && modalOpen) && <PushForEvent
			closeModal={closeModal}
			setSelectedModalObj={selectEventRelatedContents}
			setSelectedModalType={setRelatedContentsType}
			selectedContentList={selectedContentList}
		/>}
		
		{/* 배틀 선택 모달*/}
		{(relatedContentsType === 'BATTLE_ROOM' && modalOpen) && <PushForBattle
			closeModal={closeModal}
			selectedList={relatedContents && [relatedContents]}
			maxSelectCnt={1}
			selectedSetter={(selectedContent) => {
				setRelatedContents(selectedContent[0]);
				setRelatedContentsId(selectedContent[0].battleId);
			}}
		/>}
	</>;
};

export default NoticeDetail;

const TopContainer = styled.div`
  // position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 40px;
  grid-gap: 15px 40px;
  padding-bottom: 230px;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const ErrorText = styled.span`
  position: absolute;
  top: 60px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({theme}) => theme.color.mainRed};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  
  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }

  &.external-add {
    text-align: right;
    display: block !important;
  }

  &.area2 {
    background-color: #f1f3f5;
    padding: 25px;
    border-radius: 6px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  gap: 30px;
  grid-gap: 15px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;
