import React from 'react';

// component
import ListItem from '../../../../components/common/List/ListItem';
import EditButton from '../../../../components/common/Button/EditButton';

// constant
import {loginTypeOptions,} from '../../../../constant/common';

// hooks
import handleDate from '../../../../hooks/handleDate';

const UserRowComponent = ({ data, onClick, buttonType }: any) => {
  const {
    id,
    pid,
    email,
    loginType,
    createdDt,
    reportScore,
    reportScoreExpireDt,
  } = data;

  const nowDate = `${new Date().toISOString().slice(0, 16)}:00`;

  return (
    <tr key={id}>
      <td><ListItem text={id} /></td>
      <td><ListItem text={pid} /></td>
      <td><ListItem text={email} /></td>
      <td><ListItem text={loginTypeOptions[loginType]} /></td>
      <td><ListItem text={handleDate(createdDt)} /></td>
      <td><ListItem text={reportScore || 0} /></td>
      <td>
        <ListItem
          text={
            reportScoreExpireDt === null ||
            Date.parse(nowDate) > Date.parse(reportScoreExpireDt)
              ? '정상'
              : `제재(${handleDate(reportScoreExpireDt)}까지)`
          }
        />
      </td>
      <td>
        {buttonType === 'delete' && (
          <EditButton onClick={() => onClick(data)} text="삭제" className="deleteBtn"/>
        )}
        {buttonType === 'add' && (
          <EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>
        )}
      </td>
    </tr>
  );
};
export default UserRowComponent;
