import React from 'react';

//컴포넌트
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hooks
import EditButton from '../../../../components/common/Button/EditButton';
import {collectionType} from "../../../../constant/explore";

const WorkCollectionRow = ({
  data,
  onClick,
  selected,
  editable,
}) => {
  const {
    id,
    type,
    contentsCount,
    coverImgUrl,
    name,
    memberPid,
    shareCount,
  } = data;

  return (
    <tr>
      <td><ListItem text={id} /></td>
      <td><ListItem text={collectionType[type]} /></td>
      <td><ListItem text={contentsCount || 0} /></td>
      <td><ListCoverImg url={coverImgUrl} /></td>
      <td><ListItem text={name} /></td>
      <td><ListItem text={memberPid} /></td>
      <td><ListItem text={shareCount || 0} /></td>
      {editable && (
        <td>
          {selected ? (
            <EditButton onClick={() => onClick(data, name)} text="삭제" className="deleteBtn"/>
          ) : (
             <EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/>
          )}
        </td>
      )}
    </tr>
  );
};
export default WorkCollectionRow;
