import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';

// common
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';

// constant
import {
  memberResignLogSearchTypeList,
  resignMemberColList,
} from '../../constant/member';
import { postsPerPage } from '../../constant/common';

// hooks
import useInput from '../../hooks/useInput';

// config
import { MASTER_ACCOUNT_REASON, MEMBER_RESIGN_LOG } from '../../constant/apiUrl';

// component
import ResignMemberRowComponent from './components/ResignMemberRowComponent';

const ResignMember = () => {
  const [logList, setLogList] = useState([]);
  const [word, setWord] = useInput('');
  const [searchType, setSearchType] = useState<string>('I');
  const [reasonId, setReasonId] = useState<any>('');
  const [reasonList, setReasonList] = useState<any>([]);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );
  // 검색어 인코딩
  const encodeStr = encodeURI(word);

  const params = {
    searchType,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    reasonId,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // 인덱스 string 검색 차단
    if (e.key === 'Enter') {
      if (searchType === 'I' && !Number(word)) {
        alert('인덱스는 숫자만 검색 가능합니다.');
        return;
      }
      setPageNumber(0);
      params.offset = 0;
      getResignMemberList();
    }
  };

  // 계정 탈퇴 및 삭제 사유 조회
  const getMasterAccountReason = async () => {
    const reasonParams = {
      keyword: '',
      language: 'ko',
      limit: 20,
      offset: 0,
      reasonType: 'DELETE',
      searchType: '',
    };

    try {
      const resp = await axios.get(MASTER_ACCOUNT_REASON, {
        params: reasonParams,
      });
      const result = resp.data.masterAccountStatusReasonList;
      const arr = result.map((el: any) => {
        return { text: el.reason, value: el.id };
      });
      setReasonList(arr);
    } catch (err) {
      console.log(err);
    }
  };

  // member 조회
  const getResignMemberList = async () => {
    try {
      const resp = await axios.get(MEMBER_RESIGN_LOG, {
        params,
      });
      setLogList(resp.data.deleteLogList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  useEffect(() => {
    getResignMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    params.offset = 0;
    setPageNumber(0);
    getResignMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonId]);

  useEffect(() => {
    getMasterAccountReason();
  }, []);

  useEffect(() => {
    const allReason = { text: '전체', value: '' };
    reasonList.unshift(allReason);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reasonList]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={memberResignLogSearchTypeList}
              onClick={handleSelect}
              setState={setSearchType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
          {logList.length !== 0 && (
            <PaginationWrapper>
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            </PaginationWrapper>
          )}
        </TopWrapper>
        <LineDiv />
      </TopContainer>
      {logList && (
        <MainContainer>
          <Table
            colList={resignMemberColList}
            thead={
              <>
                <ListHead title="인덱스" />
                <ListHead title="Pid" />
                <ListSelect
                  title="탈퇴 사유"
                  list={reasonList}
                  onClick={handleSelect}
                  setState={setReasonId}
                />
                <ListHead title="탈퇴 날짜" />
              </>
            }
          >
            {logList?.map((el: any) => (
              <ResignMemberRowComponent key={el.id} data={el} />
            ))}
          </Table>
        </MainContainer>
      )}
    </>
  );
};

export default ResignMember;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MainContainer = styled.div`
  z-index: 0;
  width: 100%;
  margin-top: 40px;
`;

const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 40px 0;
`;
