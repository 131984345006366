import React, { useState, useEffect, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NotExposure from '../../../../components/UI/NotExposure';
import blankImg from '../../../../assets/images/page/blankImg.png';
import NormalButton from '../../../../components/common/Button/NormalButton';

// hook
import handleDate from '../../../../hooks/handleDate';

// 타입
import { RecommendGenreType } from '../../../../definitions/explore';

// config
import { RECOMMEND_GENRE } from '../../../../constant/apiUrl';

const RecommendGenre = () => {
  const navigate = useNavigate();
  const [listType, setListType] = useState(true);
  const [list, setList] = useState<Array<RecommendGenreType> | null>(null);

  const getRecommendGenreList = async (type: boolean) => {
    const params = { limit: 100, offset: 0, type: '' };
    try {
      if (!type) {
        params.type = 'disable';
      }
      const resp = await axios.get(RECOMMEND_GENRE, { params });
      setList(resp.data.masterRecommendGenreList);
    } catch (err) {
      setList([]);
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
  };

  useEffect(() => {
    getRecommendGenreList(listType);
  }, [listType]);

  return (
    <Container>
      <RoundButton
        title="공개"
        selected={listType}
        onClick={() => {
          setListType(true);
        }}
      />
      <RoundButton
        title="비공개"
        selected={!listType}
        onClick={() => {
          setListType(false);
        }}
      />
      {list?.length !== 0 && listType && (
        <MainContainer>
          {list?.map((item) => {
            return (
              <Item>
                {item.coverImgUrl ? (
                  <Img src={item.coverImgUrl} alt="coverImg" />
                ) : (
                  <Img src={blankImg} alt="defaultImg" />
                )}
                <TextWrapper>
                  <TitleContainer>
                    <Title>
                      <span>{item.id}.</span> {item.title}
                    </Title>
                    <GenreName>{item.masterGenre}</GenreName>
                  </TitleContainer>
                  <SubTitle>
                    {item.titleEn} / {item.titleEs}
                  </SubTitle>
                  <ButtonWrapper>
                    <DateTitle>
                      {`${handleDate(item.recommendStartDt)} ~ ${handleDate(
                        item.recommendEndDt,
                      )}`}
                    </DateTitle>
                    <NormalButton
                      text="수정하기"
                      onSubmit={() => {
                        navigate('edit', { state: item });
                      }}
                    />
                  </ButtonWrapper>
                </TextWrapper>
              </Item>
            );
          })}
        </MainContainer>
      )}
      {list?.length === 0 && (
        <NotExposure
          title="추천 장르"
          link="add"
          onSubmit={() => {
            navigate('add', { state: {} });
          }}
        />
      )}
      {list?.length !== 0 && !listType && (
        <MainContainer>
          {list?.map((item) => {
            return (
              <Fragment key={item.id}>
                <Item>
                  {item.coverImgUrl ? (
                    <Img src={item.coverImgUrl} alt="coverImg" />
                  ) : (
                    <Img src={blankImg} alt="defaultImg" />
                  )}
                  <TextWrapper>
                    <TitleContainer>
                      <Title>
                        <span>{item.id}.</span> {item.title}
                      </Title>
                      <GenreName>{item.masterGenre}</GenreName>
                    </TitleContainer>
                    <SubTitle>
                      {item.titleEn} / {item.titleEs}
                    </SubTitle>
                    <ButtonWrapper>
                      <DateTitle>
                        {`${handleDate(item.recommendStartDt)} ~ ${handleDate(
                          item.recommendEndDt,
                        )}`}
                      </DateTitle>
                      <NormalButton
                        text="수정하기"
                        onSubmit={() => {
                          navigate('edit', { state: item });
                        }}
                      />
                    </ButtonWrapper>
                  </TextWrapper>
                </Item>
                <LineDiv />
              </Fragment>
            );
          })}
        </MainContainer>
      )}
    </Container>
  );
};

export default RecommendGenre;

const Container = styled.div`
  width: 100%;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  height: 175px;
  margin-top: 40px;
`;

const Item = styled.div`
  display: flex;
  margin: 40px 0;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};

  &:last-child {
    display: none;
  }
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  margin-right: 30px;
  border-radius: 3px;
`;

const TextWrapper = styled.div`
  width: calc(100% - 205px);
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Title = styled.div`
  height: 45px;
  margin-bottom: 10px;
  flex-grow: 0;
  font-size: 36px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};

  span {
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const GenreName = styled.div`
  height: 20px;
  flex-grow: 0;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const SubTitle = styled.div`
  margin-bottom: 16px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.mainBlack};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const DateTitle = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.subGray};
`;
