import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { CULTURE_EPISODE } from '../../../constant/apiUrl';
import DateInput from '../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import ListHead from '../../../components/UI/ListHead';
import VideoInput from '../../../components/common/Input/VideoInput';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Table from '../../../components/common/List/Table';
import Pagination from '../../../components/common/Pagination';
import { postsPerPage } from '../../../constant/common';
import { RowComponentDataProps } from '../../../definitions/beat';
import { CustomProps } from '../../../definitions/common';
import DetailAddBeatRowComponents from '../../explore/components/category/DetailAddBeatRowComponents';
import { addBeatCollectionColList } from '../../../constant/explore';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import { beatBpmOptions, beatSearchTypeList } from '../../../constant/beat';
import SearchInput from '../../../components/common/Input/SearchInput';
import useInput from '../../../hooks/useInput';
import { getBeatList } from '../../../redux/actions/beat/beat';
import ListSelect from '../../../components/common/Select/ListSelect';
import ListDateSelect from '../../../components/common/Select/ListDateSelect';
import { useAppSelector } from '../../../redux/store/hooks';
import FileInput from '../../../components/common/Input/FileInput';
import CultureMenuSelect from '../component/CultureMenuSelect';
import CultureEpisodeBeatRow from './CultureEpisodeBeatRow';

const CultureEpisodeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const today = new Date();
  const todayWIthFormat =
    today.getFullYear() +
    '-' +
    ('0' + (today.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + today.getDate()).slice(-2) +
    'T' +
    ('0' + today.getHours()).slice(-2) +
    ':' +
    ('0' + today.getMinutes()).slice(-2);

  const detailData: any = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [channelId, setChannelId] = useState(
    detailData !== null ? detailData.channelId : 0,
  );
  const [channelName, setChannelName] = useState(
    detailData !== null ? detailData.channelName : '채널 선택',
  );
  const [cultureChannels, setCultureChannels] = useState<any>(
    detailData !== null ? detailData.cultureChannels : [],
  );

  const [programId, setProgramId] = useState(
    detailData !== null ? detailData.programId : 0,
  );
  const [programName, setProgramName] = useState(
    detailData !== null ? detailData.programName : '프로그램 선택',
  );
  const [culturePrograms, setCulturePrograms] = useState<any>(
    detailData !== null ? detailData.culturePrograms : [],
  );
  const [filteredCulturePrograms, setFilteredCulturePrograms] = useState<any>(
    detailData !== null ? detailData.culturePrograms : [],
  );

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [coverImg, setCoverImg] = useState<string>('');
  const [coverImgFile, setCoverImgFile] = useState<any>(null);
  const [externalLinkUrl, setExternalLinkUrl] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [beatTitle, setBeatTitle] = useState('');
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState(todayWIthFormat);
  const [endDt, setEndDt] = useState('2222-12-31 23:59');
  const [selectedBeat, setSelectedBeat] = useState<any>([]);
  const [contentsIds, setContentsIds] = useState<any>([]);
  const [playBeat, setPlayBeat] = useState('');
  const [beatType, setBeatType] = useState<string>('');
  const [keyword, setKeyword, onReset] = useInput('');
  const [searchType, setSearchType] = useState('');
  const [minBpm, setMinBpm] = useState<number>(0);
  const [maxBpm, setMaxBpm] = useState<number>(250);
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');
  const [bpm, setBpm] = useState<string>('');
  const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
  const beatList = useAppSelector((state) => state.beat.beatList);

  const [selectedContentList, setSelectedContentList] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  useEffect(() => {
    const result = cultureChannels.filter(
      (item: any) => item.id === channelId,
    )[0];
    if (result) {
      setChannelName(result.name);
      setFilteredCulturePrograms(
        culturePrograms.filter(
          (item: any) => item.cultureChannelId === channelId,
        ),
      );
    }
  }, [channelId, cultureChannels]);

  useEffect(() => {
    const result = filteredCulturePrograms.filter(
      (item: any) => item.id === programId,
    )[0];
    if (result) {
      setProgramName(result.name);
    }
  }, [programId, filteredCulturePrograms]);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(`${CULTURE_EPISODE}/${prevId}/detail`);
      if (resp.data.adminCultureEpisode) {
        setChannelId(resp.data.adminChannelId);
        setProgramId(resp.data.adminCultureEpisode.cultureProgramId);
        setName(resp.data.adminCultureEpisode.name);
        setNameEn(resp.data.adminCultureEpisode.nameEn);
        setDescription(resp.data.adminCultureEpisode.description);
        setDescriptionEn(resp.data.adminCultureEpisode.descriptionEn);
        setCoverImg(
          resp.data.adminCultureEpisode.coverImgUrl
            ? resp.data.adminCultureEpisode.coverImgUrl
            : '',
        );
        setExternalLinkUrl(resp.data.adminCultureEpisode.externalLinkUrl);
        setSequence(resp.data.adminCultureEpisode.sequence);
        setBeatTitle(resp.data.adminCultureEpisode.beatTitle);
        setActiveYn(resp.data.adminCultureEpisode.activeYn);
        setStartDt(
          resp.data.adminCultureEpisode.startDt?.replace(' ', 'T').slice(0, 16),
        );
        setEndDt(
          resp.data.adminCultureEpisode.endDt?.replace(' ', 'T').slice(0, 16),
        );
        setSelectedBeat(resp.data.adminCultureEpisode.beats);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]); // deps 에 추가된 객체 및 메소드는 갱신 될때마다 자동으로 해당 메소드를 실행함

  useEffect(() => {
    setContentsIds(selectedBeat?.map((el: any) => el.id));
  }, [selectedBeat]);

  const handleChannelSetData = (e: any) => {
    setChannelId(e.id);
    setChannelName(e.name);
  };

  const handleProgramSetData = (e: any) => {
    setProgramId(e.id);
    setProgramName(e.name);
  };

  const addContents = async (type: string) => {
    if (!channelId) {
      alert('채널을 선택해주세요.');
      return;
    }
    if (!programId) {
      alert('프로그램을 선택해주세요.');
      return;
    }
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }
    if (!sequence) {
      alert('에피소드 번호를 입력해주세요.');
      return;
    }
    // if (!coverImg) {
    //   alert('에피소드 상세 이미지를 입력해주세요.');
    //   return;
    // }

    const formData = new FormData();
    formData.append('cultureProgramId', programId);
    formData.append('name', name);
    formData.append('nameEn', nameEn);
    if (description != null) {
      formData.append('description', description);
    }
    if (descriptionEn != null) {
      formData.append('descriptionEn', descriptionEn);
    }
    if (coverImgFile != null) {
      formData.append('coverImg', coverImgFile);
    }
    if (externalLinkUrl != null) {
      formData.append('externalLinkUrl', externalLinkUrl);
    }
    formData.append('sequence', sequence);
    if (beatTitle != null) {
      formData.append('beatTitle', beatTitle);
    }
    if (contentsIds != null) {
      formData.append('beatIds', contentsIds);
    }
    formData.append('activeYn', activeYn);
    formData.append('startDate', `${startDt.replace('T', ' ')}:59`);
    formData.append('endDate', `${endDt.replace('T', ' ')}:59`);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${CULTURE_EPISODE}/${prevId}/edit`, formData, {});
      } else {
        await axios.post(`${CULTURE_EPISODE}`, formData, {});
      }
      await navigate('/culture/episode');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 모달 열기
  const setModal = () => {
    setModalOpen(true);
    setSelectedContentList(selectedBeat || []);
    dispatch(getBeatList(beatParams));
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    onReset();
    setPageNumber(0);
    setSearchType('');

    // beat state 초기화
    setBeatType('');
    setBpm('');
    setMinBpm(0);
    setMaxBpm(250);
    setMinStartDate('');
    setMaxStartDate('');
  };

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    setSelectedBeat(selectedContentList);
    closeModal();
  };

  // 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (!selectedContentList?.find((e: any) => e.id === data.id)) {
      setSelectedContentList([...selectedContentList, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (el: any) => {
    const result = selectedContentList.filter((item: any) => item !== el);
    setSelectedContentList(result);
  };

  const beatParams = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    word: keyword,
    type: beatType,
    maxBpm,
    minBpm,
    maxStartDate,
    minStartDate,
    privateYn: 'N',
  };

  useEffect(() => {
    dispatch(getBeatList(beatParams));
  }, [pageNumber, searchType, beatType]);

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 엔터키로 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      beatParams.offset = 0;
      setPageNumber(0);
      if (!searchType) {
        setSearchType('I');
        // userParams.type = 'I';
        setBeatType('T');
        beatParams.type = 'T';
        setPageNumber(0);
      }
      dispatch(getBeatList(beatParams));
    }
  };

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = selectedBeat[dragIndex];
      const hoverItem = selectedBeat[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setSelectedBeat((adminCommunityBoardArticles: any) => {
        const workList = [...adminCommunityBoardArticles];
        workList[dragIndex] = hoverItem;
        workList[hoverIndex] = dragItem;
        return workList;
      });
    },
    [selectedBeat],
  );

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <CoverImgWrapper>
          {coverImg ? <EventImg src={coverImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="상세 이미지"
            value={coverImgFile}
            setFile={(e: string) => setCoverImgFile(e)}
            setImgUrl={setCoverImg}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <CultureMenuSelect
            placeholder="채널을 선택해주세요."
            value={channelName}
            onChange={handleChannelSetData}
            options={cultureChannels}
            label="채널"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <CultureMenuSelect
            placeholder="프로그램을 선택해주세요."
            value={programName}
            onChange={handleProgramSetData}
            options={filteredCulturePrograms}
            label="프로그램"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="에피소드 명"
            value={name}
            placeholder="에피소드 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="에피소드 명 EN"
            value={nameEn}
            placeholder="에피소드 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="에피소드 설명"
            value={description}
            placeholder="에피소드 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="에피소드 설명 EN"
            value={descriptionEn}
            placeholder="에피소드 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="externalLink"
            label="에피소드 링크"
            value={externalLinkUrl}
            placeholder="채널 링크 입력해 주세요."
            onChange={(e) => setExternalLinkUrl(e.target.value)}
            onReset={() => setExternalLinkUrl('')}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 개시 일시"
            name="startDt"
            initialStartDate={startDt}
            onChange={(e) => setStartDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="노출 종료 일시"
            name="endDt"
            initialStartDate={endDt}
            onChange={(e) => setEndDt(e.target.value)}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="beatTitle"
            label="비트 타이틀"
            value={beatTitle}
            placeholder="비트 타이틀을 입력해 주세요."
            onChange={(e) => setBeatTitle(e.target.value)}
            onReset={() => setBeatTitle('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="sequence"
            label="에피소드 번호"
            value={sequence}
            placeholder="에피소드 번호를 입력해 주세요."
            onChange={(e) => setSequence(e.target.value)}
            onReset={() => setSequence('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <VideoInput onClick={() => setModal()} text="비트 선택하기" />
        </InputWrapper>
        <InputWrapper>
          <TagHead>
            <ListHead title="" />
            <ListHead title="비트아이디" />
            <ListHead title="Cover" />
            <ListHead title="Title" />
            <ListHead title="Pid" />
            <ListHead title="장르" />
            <ListHead title="BPM" />
            <ListHead title="BeatKey" />
            <ListHead title="공개여부" />
            <ListHead title="공개 날짜" />
          </TagHead>
          <>
            {selectedBeat?.map((item: any, index: number) => (
              <CultureEpisodeBeatRow
                rowKey={item.id}
                data={item}
                moveListItem={movePetListItem}
                index={index}
              />
            ))}
          </>
        </InputWrapper>
      </MainContainer>
      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton
                text="취소"
                onSubmit={closeModal}
                className="cancelBtn"
              />
              <NormalButton
                text="저장하기"
                onSubmit={() => saveContentList()}
              />
            </ModalButtonWrapper>
            <VideoModalTitle>선택 비트</VideoModalTitle>
            {selectedContentList?.length !== 0 && selectedContentList ? (
              <>
                <TableTitle>{selectedContentList?.length}개의 비트</TableTitle>
                <LineDiv />
                <Table
                  colList={addBeatCollectionColList}
                  className="nonListTable"
                >
                  {selectedContentList?.map((data: any) => (
                    <DetailAddBeatRowComponents
                      key={data.id}
                      data={data}
                      playBeat={playBeat}
                      setPlayBeat={setPlayBeat}
                      onClick={() => filterSelectedContent(data)}
                      selected
                      editable
                    />
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>
                  선택된 비트가 없습니다. 아래 검색 후 비트를 선택해주세요.
                </VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv value="90px" />
            <VideoModalTitle>비트 검색</VideoModalTitle>
            <TitleWrapper>
              <SearchWrapper>
                <SearchMenuSelect
                  options={beatSearchTypeList}
                  onClick={handleSelect}
                  setState={setBeatType}
                />
                <SearchInputWrapper>
                  <SearchInput
                    value={keyword}
                    onChange={setKeyword}
                    onKeyPress={enterKeyPress}
                  />
                </SearchInputWrapper>
              </SearchWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{beatTotalCount}개 비트</TableTitle>
              {beatTotalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={beatTotalCount}
                  currentPage={pageNumber}
                  pageDispatch={pageDispatch}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={addBeatCollectionColList}
              thead={
                <>
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListSelect
                    title="BPM"
                    list={beatBpmOptions}
                    onClick={handleSelect}
                    setState={setBpm}
                  />
                  <ListHead title="BeatKey" />
                  <ListHead title="공개여부" />
                  <ListDateSelect
                    title="공개 날짜"
                    setMinStartDate={setMinStartDate}
                    setMaxStartDate={setMaxStartDate}
                  />
                  <ListHead title="" />
                </>
              }
            >
              {beatList?.map((el: RowComponentDataProps) => (
                <DetailAddBeatRowComponents
                  key={el.id}
                  data={el}
                  onClick={addSelectedContent}
                  playBeat={playBeat}
                  setPlayBeat={setPlayBeat}
                  editable
                />
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default CultureEpisodeDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
  grid-row: 1;

  &:first-child {
    grid-row: 1;
  }
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }
  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }
  &:nth-child(n + 7):nth-child(-n + 8) {
    grid-row: 4;
  }
  &:nth-child(9) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(10) {
    grid-row: 6;
    grid-column: span 2;
  }
  &:nth-child(n + 11):nth-child(-n + 12) {
    grid-row: 7;
  }
  &:nth-child(n + 13):nth-child(-n + 14) {
    grid-row: 8;
  }
  &:nth-child(15) {
    grid-row: 9;
    grid-column: span 2;
  }
  &:nth-child(16) {
    grid-row: 10;
    grid-column: span 2;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.8fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 1fr;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SpaceDiv = styled.div<CustomProps>`
  width: 100%;
  height: ${({ value }) => value};
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;
