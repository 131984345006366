import React from 'react';
import { useNavigate } from 'react-router-dom';

// component
import EditButton from '../../../../components/common/Button/EditButton';
import ListItem from '../../../../components/common/List/ListItem';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hook
import handleDate from '../../../../hooks/handleDate';
import { baundMusicType } from '../../../../constant/baundMusic';
import { IsActive } from '../../../../constant/common';

const RowComponent = ({ data }) => {
	const {
		exploreContentsId,
		coverImgUrl,
		type,
		title,
		activeYn,
		startDate,
		endDate,
	} = data;
	const navigate = useNavigate();
	
	const editExploreContents = (exploreContentsId) => {
		navigate('/explore/allTab/baundMusic/edit', {
			state: { exploreContentsId, pageType: 'edit' },
		});
	};
	
	return <tr>
		<td><ListItem text={exploreContentsId} /></td>
		<td><ListCoverImg url={coverImgUrl} /></td>
		<td><ListItem text={baundMusicType[type]} /></td>
		<td><ListItem text={title} /></td>
		<td><ListItem text={IsActive[activeYn]} /></td>
		<td><ListItem text={handleDate(startDate)} /></td>
		<td>{endDate ? <ListItem text={handleDate(endDate)} /> : <ListItem text="무한 노출" />}</td>
		<td><EditButton text="수정" onClick={() => editExploreContents(data.exploreContentsId)}/></td>
	</tr>;
};

export default RowComponent;
