// 바운드 프로젝트 FAQ 상세 화면(추가, 수정)
import {useLocation} from "react-router";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {BAUND_PROJECT_CAUTION} from "../../constant/apiUrl";
import {resetLoading, setLoading} from "../../redux/slices/user";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextAreaInput
  from "../../components/common/Input/LabelTextAreaInput";
import styled from "styled-components";
import BaundProjectMenuSelect from "./component/BaundProjectMenuSelect";
import LabelText from "../../components/common/LabelText";
import {isBlank} from "../../utils/commonFn";
import CheckBoxInput from "../../components/common/Input/CheckBoxInput";

const BaundProjectCautionDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  const baundProjectCautionId = detailData?.baundProjectCautionId || null;
  const baundProjectIdForSearch = detailData?.baundProjectIdForSearch || 0;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;
  const projectTitleForSearch = detailData?.projectTitle || '프로젝트 선택';

  const[projectId, setProjectId] = useState(baundProjectIdForSearch);
  const[projectTitle, setProjectTitle] = useState(projectTitleForSearch);
  const projects = detailData !== null ? detailData.projects : [];

  const [caution, setCaution] = useState('');
  const [cautionEn, setCautionEn] = useState('');
  const [createDt, setCreateDt] = useState('');
  const [updateDt, setUpdateDt] = useState('');
  const [createAdminAccountName, setCreateAdminAccountName] = useState('');
  const [updateAdminAccountName, setUpdateAdminAccountName] = useState('');

  // 내용 입력의 편의를 위해 true 인 경우에 한글 입력 값을 영문 값으로 복사. 신규 일때는 기본 값 true
  const [copyKR2EN, setCopyKR2EN] = useState(!baundProjectCautionId);

  // 상세 조회 /////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${BAUND_PROJECT_CAUTION}/${baundProjectCautionId}`,
    )
    .then(res => {
      const detail = res.data.baundProjectCaution;
      setCaution(detail.caution);
      setCautionEn(detail.cautionEn);
      //setPrivateYn(detail.privateYn);
      setCreateDt(detail.createDt);
      setUpdateDt(detail.updateDt);
      setCreateAdminAccountName(detail.createAdminAccountName);
      setUpdateAdminAccountName(detail.updateAdminAccountName);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  useEffect(() => {
    // console.log('AdvertisementDetail > useEffect > advertiserList:',advertiserList, ' / advertisementId:', advertisementId);
    if (baundProjectCautionId) {
      getDetail().then();
    }

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ baundProjectIdForSearch, pageNumberForSearch, projectTitleForSearch })
    );

  }, [baundProjectCautionId]);

  // 저장 //////////////////////////////////////////////////////////////////////
  const saveDetail = async () => {

    const confirmMessage = (baundProjectCautionId)
            ? `유의사항 내용을 수정하시겠습니까? 수정 시, 변경된 내용으로 유의사항 내용이 노출됩니다.`
            : `유의사항 내용을 저장하시겠습니까? 저장된 유의사항 내용이 노출됩니다.`;

    if (!confirm(confirmMessage)) {
      return;
    }
    if (isBlank(caution) || isBlank(cautionEn)) {
      alert('유의사항 상세 내용에 글자가 누락되었습니다. 최소 1자 이상 작성해야합니다.');
      return;
    }

    const formData = new FormData();
    // 공통 유의사항 화면이므로 0 고정
    formData.append('baundProjectId', projectId);
    formData.append('caution', caution);
    formData.append('cautionEn', cautionEn);

    dispatch(setLoading());
    try {
       (baundProjectCautionId)
          ? await axios.put(`${BAUND_PROJECT_CAUTION}/${baundProjectCautionId}`, formData)
          : await axios.post(`${BAUND_PROJECT_CAUTION}`, formData, {});
      alert('저장 되었습니다.');
      navigate('/baundProject/baundProjectCaution');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 논리 삭제 //////////////////////////////////////////////////////////////////////
  const deleteSelected = async() => {

    if (!confirm(`유의사항을 삭제하시겠습니까? 해당 유의사항이 목록에서 사라지며, 삭제 이후 복구가 어렵습니다.`)) {
      return;
    }

    dispatch(setLoading());
    try {
      await axios.delete(`${BAUND_PROJECT_CAUTION}/${baundProjectCautionId}`);
      alert('삭제 되었습니다.');
      navigate('/baundProject/baundProjectCaution');
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  const handleSelectSetData = (e) => {
    setProjectId(e.baundProjectId);
    setProjectTitle(e.title + ((e.deleteYn === "Y") ? '(삭제)':''));
  };

  useEffect(() => {
    const result = projects.filter(
        (item) => item.baundProjectId === projectId,
    )[0];
    if (result) {
      setProjectTitle(result.title + ((result.deleteYn === "Y") ? '(삭제)':''));
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (copyKR2EN) setCautionEn(caution);
  }, [caution]);

  useEffect(() => {
    if (copyKR2EN) {
      setCautionEn(caution);
    }
  }, [copyKR2EN]);

  return <>
    <TitleText>바운드 프로젝트 유의사항 {baundProjectCautionId ? '상세':'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper jc={'flex-end'}>
        {baundProjectCautionId && (
            <NormalButton
                text={"삭제"}
                className={(!baundProjectCautionId || baundProjectCautionId === 0) ? "unActiveBtn": "blockBtn"}
                onSubmit={deleteSelected}
            />
        )}
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>

    <MainContainer>
      <InputWrapper span={3} mt={-20}>
        <CheckBoxInput
            name="copyKR2EN" text="한글 / 영문 동일값 사용" checked={copyKR2EN} onChange={setCopyKR2EN}
        />
      </InputWrapper>
      <InputWrapper colSpan={3}>
        <BaundProjectMenuSelect label="프로젝트 정보" placeholder="바운드 프로젝트를 선택해주세요."
            value={projectTitle}
            onChange={handleSelectSetData}
            options={projects}
            Required
            className={'noMarginRight'}
        />
      </InputWrapper>
      <InputWrapper colSpan={3}>
        <LabelTextAreaInput
            name="caution" label="유의사항 상세 내용" placeholder="유의사항을 입력해주세요."
            value={caution}
            setValue={(e)=> setCaution(e.target.value)}
            onReset={()=> setCaution('')}
        />
      </InputWrapper>
      <InputWrapper colSpan={3}>
        <LabelTextAreaInput
            name="cautionEn" label="유의사항 상세 내용(EN)" placeholder={"유의사항(EN)을 입력해주세요."}
            value={cautionEn} readOnly={copyKR2EN}
            setValue={(e)=> setCautionEn(e.target.value)}
            onReset={()=> setCautionEn('')}
        />
      </InputWrapper>
    </MainContainer>

    {/*FAQ 생성 정보*/}
    <MainContainer>
      <InputWrapper><LabelText label="생성 일자" value={createDt}/></InputWrapper>
      <InputWrapper><LabelText label="수정 일자" value={updateDt}/></InputWrapper>
      <InputWrapper><LabelText label="최초 생성자" value={createAdminAccountName}/></InputWrapper>
      <InputWrapper><LabelText label="최종 수정자" value={updateAdminAccountName}/></InputWrapper>
    </MainContainer>
  </>;

};
export default BaundProjectCautionDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc && `${jc}`};;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
  grid-row: ${({row}) => row && `span ${row}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

  textarea:read-only {
    color: ${({ theme }) => theme.color.subGray};
  }
`;


const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;
