import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import axios from 'axios';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';

//hooks
import { useAppSelector } from '../../../redux/store/hooks';

// constants
import {
  getReportTypeOption,
  reportTypeOptions,
} from '../../../constant/operation';

// actions
import { getManagementList } from '../../../redux/actions/customService/customService';
import { setLoading, resetLoading } from '../../../redux/slices/user';

// config
import { MASTER_REPORT } from '../../../constant/apiUrl';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialReportData: data = {
  masterReportId: '',
  masterReportDetailId: '',
  activeYn: '',
  contentsType: 'CT',
  description: '',
  subDescription: null,
  reportPoint: null,
  sequence: null,
};
const EditReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const masterReportList = useAppSelector((state) => state.cs.managementList);
  const [reportData, setReportData] = useState(initialReportData);
  const [reportType, setReportType] = useState('컨텐츠');
  const [firstReportOption, setFirstReportOption] = useState<any>('');
  const [secondReportOption, setSecondReportOption] = useState<any>('');
  const [secondReportOptionList, setSecondReportOptionList] = useState<any>();

  const [onEdit, setOnEdit] = useState(false);

  const setInitialData = useCallback(() => {
    if (firstReportOption) {
      const { id, description, reportPoint, sequence, activeYn } =
        firstReportOption;
      setReportData({
        ...reportData,
        masterReportId: id,
        subDescription: '',
        description,
        reportPoint,
        sequence,
        activeYn,
      });
    }
    if (secondReportOption) {
      const { id, subDescription, reportPoint, sequence, activeYn } =
        secondReportOption;
      setReportData({
        ...reportData,
        masterReportDetailId: id,
        subDescription,
        reportPoint,
        sequence,
        activeYn,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstReportOption, secondReportOption]);

  useEffect(() => {
    setInitialData();
  }, [setInitialData]);

  const {
    activeYn,
    contentsType,
    description,
    reportPoint,
    masterReportId,
    masterReportDetailId,
    sequence,
    subDescription,
  } = reportData;

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  // 1뎁스 등록
  const changeFirstOption = (option: any) => {
    setFirstReportOption(option);
    const secondDeps = option.masterReportDetailList;
    if (secondDeps) {
      setSecondReportOption('');
      setSecondReportOptionList(secondDeps);
    } else {
      setSecondReportOptionList('');
      setSecondReportOption('');
    }
  };

  // 2뎁스 등록
  const changeSecondOption = (option: any) => {
    setSecondReportOption(option);
  };

  // select set 데이터
  const handleSelectSetData = useCallback(
    (e: any, name) => {
      // 2 뎁스 이름 입력
      if (name === 'contentsType') {
        setReportType(e);
        setReportData({ ...reportData, [name]: getReportTypeOption[e] });
        return;
      }
      setReportData({ ...reportData, [name]: e });
    },
    [reportData],
  );

  // input set 데이터
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setReportData({ ...reportData, [name]: value });
    },
    [reportData],
  );

  // reset 데이터
  const handleResetData = useCallback(
    (e) => {
      setReportData({ ...reportData, [e]: initialReportData[e] });
    },
    [reportData],
  );

  // edit 버튼
  const handleEdit = () => {
    if (firstReportOption) {
      setOnEdit(true);
    }
  };

  // 신고 항목 수정하기 (1뎁스, 2뎁스)
  const editReportItem = async () => {
    // 1뎁스만 수정
    if (masterReportId && !masterReportDetailId) {
      const params = {
        activeYn,
        description,
        reportPoint,
        sequence,
      };
      try {
        dispatch(setLoading());
        await axios.post(
          `${MASTER_REPORT}/${masterReportId}/update`,
          {},
          { params },
        );
        alert('수정이 완료되었습니다.');
        goBack();
      } catch (err) {
        console.log(err);
      }
      dispatch(resetLoading());
    }
    // 1뎁스+2뎁스 수정
    if (masterReportId && masterReportDetailId) {
      // 2뎁스 있는 1뎁스 수정
      // if (firstReportOption.description !== description) {
      //   const params = {
      //     description,
      //     reportPoint: null,
      //   };
      //   try {
      //     const resp = axios.post(
      //       `${MASTER_REPORT}/${masterReportId}/update`,
      //       {},
      //       { params },
      //     );
      //     console.log(resp);
      //     // navigate('/admin/report/management');
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }
      try {
        dispatch(setLoading());
        const params = {
          activeYn,
          subDescription,
          reportPoint,
          sequence,
        };
        await axios.post(
          `${MASTER_REPORT}/detail/${masterReportDetailId}/update`,
          {},
          { params },
        );
        alert('수정이 완료되었습니다.');
        goBack();
      } catch (err) {
        console.log(err);
      }
      dispatch(resetLoading());
    }
    dispatch(resetLoading());
  };

  useEffect(() => {
    const params = {
      contentsType,
      language: null,
      offset: 0,
      limit: 100,
    };
    dispatch(getManagementList(params));
  }, [dispatch, contentsType]);

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
        </ButtonWrapper>
      </TopContainer>
      <OptionContainer>
        <InputWrapper>
          <SelectModule
            name="contentsType"
            placeholder="신고 유형을 선택해주세요."
            value={reportType}
            label="신고 유형"
            onChange={handleSelectSetData}
            options={reportTypeOptions}
            className="borderType"
          />
        </InputWrapper>
        <ReportWrapper>
          <InputWrapper>
            <SelectModule
              name="firstReportOption"
              placeholder="선택하세요"
              value={firstReportOption?.description}
              label="신고 항목"
              onChange={changeFirstOption}
              options={masterReportList}
              className="borderType"
            />
          </InputWrapper>
          {secondReportOptionList && (
            <>
              <Space />
              <InputWrapper>
                <SelectModule
                  name="secondReportOption"
                  placeholder="선택하세요"
                  value={secondReportOption?.subDescription || null}
                  label="세부 내용"
                  onChange={changeSecondOption}
                  options={secondReportOptionList}
                  className="borderType"
                />
              </InputWrapper>
            </>
          )}
        </ReportWrapper>
      </OptionContainer>
      <ButtonWrapper>
        <NormalButton
          text="수정하기"
          onSubmit={handleEdit}
          className="cancelBtn"
        />
      </ButtonWrapper>
      {onEdit && (
        <>
          <LineDiv />
          <MainContainer>
            <InputWrapper>
              <LabelTextInput
                name="description"
                label="신고 항목"
                value={description}
                placeholder="세부 내용을 입력해주세요."
                onChange={handleInputSetData}
                onReset={handleResetData}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput
                name="subDescription"
                label="세부 내용"
                value={subDescription}
                placeholder={
                  subDescription
                    ? '세부 내용을 입력해주세요.'
                    : '세부 내용이 없습니다.'
                }
                onChange={handleInputSetData}
                onReset={handleResetData}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput
                name="reportPoint"
                label="벌점"
                value={reportPoint}
                placeholder="벌점을 입력해주세요."
                onChange={handleInputSetData}
                onReset={handleResetData}
              />
            </InputWrapper>
            <InputWrapper>
              <RadioButton
                value={activeYn}
                name="activeYn"
                label="활성화 여부"
                onChange={handleInputSetData}
                type="activeYn"
                text={['공개', '비공개']}
              />
            </InputWrapper>
            <ButtonWrapper>
              <NormalButton text="저장하기" onSubmit={editReportItem} />
            </ButtonWrapper>
          </MainContainer>
        </>
      )}
    </>
  );
};

export default EditReport;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const OptionContainer = styled.div`
  width: 100%;
  margin: 30px 0 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  gap: 40px 30px;
  grid-gap: 40px 30px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  gap: 40px 30px;
  grid-gap: 40px 30px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const Space = styled.div`
  margin: 0 15px;
`;

const LineDiv = styled.div`
  width: 100%;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  margin: 40px 0;
`;
