import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// 배포 test용수정
// component
import LogIn from '../pages/LogIn';
import DashboardLayout from '../components/DashboardLayout';
import MainLayout from '../components/MainLayout';
import DashBoard from '../pages/DashBoard';
import NotFound from '../pages/NotFound';

// hooks
import { useAppSelector } from '../redux/store/hooks';

import { routesList } from './routesList';

const env = process.env.REACT_APP_SERVER_APP_VERSION;

const Routes = () => {
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const categoryList: any = useAppSelector((state) => state.user.category);
  const navigate = useNavigate();
  // isLoggedIn이 false 이면 loginPath로
  const loginPath = [
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '/', element: <LogIn /> },
        { path: '/login', element: <LogIn /> },
      ],
    },
  ];

  const AuthCheck = () => {
    const defaultObj = {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <DashBoard /> },
        { path: '*', element: <NotFound /> },
      ],
    };
    let arr: any = [];
    for (let i = 0; i < categoryList.length; i += 1) {
      const result = routesList.filter((el) => {
            if (el.path === '/campaign/history-delete' && env === 'prod') {
              return false;
            }
            return el.path.includes(categoryList[i].endPoint);
      });
      arr = [...arr, ...result];
    }
    defaultObj.children = [...defaultObj.children, ...arr];
    return defaultObj;
  };

  useEffect(() => {
    if (!isLoggedIn) {
      if (!window.sessionStorage.getItem('refreshUrl')) {
        window.sessionStorage.setItem('refreshUrl', window.location.toString());
      }
      navigate('/');
    }
  }, [isLoggedIn, navigate]);

  return isLoggedIn && categoryList.length !== 0 ? [AuthCheck()] : loginPath;
};

export default Routes;
