import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';

// config
import {
  COMMUNITY_CURATED_ARTICLE,
  PERFORMANCE_CATEGORY_ACTIVE,
  PERFORMANCE_ONGOING
} from '../../../../constant/apiUrl';
import DateInput from '../../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../../redux/slices/user';
import ListHead from '../../../../components/UI/ListHead';
import CuratedPerformanceRow from './component/CuratedPerformanceRow';
import VideoInput from '../../../../components/common/Input/VideoInput';
import VideoModal from '../../../../components/common/Modal/VideoModal';
import Table from '../../../../components/common/List/Table';
import Pagination from '../../../../components/common/Pagination';
import { postsPerPage } from '../../../../constant/common';
import { eventModalColList } from '../../../../constant/event';
import {PerformanceModalRowComponent} from "./component/PerformanceModalRowComponent";
import RoundButton from "../../../../components/common/Button/RoundButton";

const CuratedPerformanceDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDt, setStartDt] = useState('');
  const [endDt, setEndDt] = useState('');
  const [contentsIds, setContentsIds] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [searchType, setSearchType] = useState('');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [performanceCurated, setPerformanceCurated] = useState([]);
  const [performanceWork, setPerformanceWork] = useState([]);
  const [performanceMaster, setPerformanceMaster] = useState([]);

  // 카테고리 관련
  const [categoryId, setCategoryId] = useState(0);
  const [categoryList, setCategoryList] = useState([]);

  // 페이지 입장시 카테고리 먼저 그리기
  useEffect(() => getCategoryList(), []);

  // 카테고리 조회
  const getCategoryList = async () => {
    await axios.get(`${PERFORMANCE_CATEGORY_ACTIVE}?offset=0&limit=100`)
    .then(res => {
      const list = res.data.performanceCategorys;
      list.unshift({performanceCategoryId:0, name:'전체'});	// 카테고리 전체 카테고리 만들기
      setCategoryList(list);
    })
    .catch(reason => console.error(reason));
  }

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${COMMUNITY_CURATED_ARTICLE}/${prevId}/detail`,
      );
      if (resp.data.adminCuratedPerformances) {
        setName(resp.data.adminCuratedPerformances.name);
        setNameEn(resp.data.adminCuratedPerformances.nameEn);
        setDescription(resp.data.adminCuratedPerformances.description);
        setDescriptionEn(resp.data.adminCuratedPerformances.descriptionEn);
        setSequence(resp.data.adminCuratedPerformances.sequence);
        setActiveYn(resp.data.adminCuratedPerformances.activeYn);
        setStartDt(resp.data.adminCuratedPerformances.startDt?.replace(' ', 'T').slice(0, 16),);
        setEndDt(resp.data.adminCuratedPerformances.endDt?.replace(' ', 'T').slice(0, 16),);
        setPerformanceCurated(resp.data.adminCuratedPerformances.adminPerformances);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  useEffect(() => {
    if (prevId) {getDetail().then((r) => r);}
  }, [getDetail, prevId]);

  useEffect(() => {
    setContentsIds(performanceCurated.map((el) => el.performanceId));
  }, [performanceCurated]);

  const addContents = async (type) => {
    if (contentsIds?.length === 0) {
      alert('공연을 선택해주세요.');
      return;
    }
    if (!startDt) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDt) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const body = {
      name,
      nameEn,
      description,
      descriptionEn,
      contentsType: 'PFMC',
      contentsIds,
      sequence,
      activeYn,
      startDate: startDt,
      endDate: endDt,
    };

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.put(
          `${COMMUNITY_CURATED_ARTICLE}/${prevId}/edit`,
          body,
          {},
        );
      } else {
        await axios.post(`${COMMUNITY_CURATED_ARTICLE}`, body, {});
      }
      await navigate('/culture/curatedPerformance');
      alert('저장되었습니다.');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 모달 열기
  const setModal = () => {
    setModalOpen(true);
    setPerformanceWork(performanceCurated || []);
    getPerformanceList();
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
    setPageNumber(0);
    setSearchType('');
    setPerformanceWork([]);
  };

  useEffect(() => getPerformanceList(), [pageNumber, searchType, categoryId]);

  // 공연 목록 불러오기
  const getPerformanceList = async () => {
    await axios.get(`${PERFORMANCE_ONGOING}/${categoryId}?offset=${pageNumber * postsPerPage}&limit=${postsPerPage}`)
      .then(res => {
        setPerformanceMaster(res.data.performanceList);
        setTotalCount(res.data.totalCount); // 토탈 페이지 추가
      })
      .catch(reason => console.error(reason));
  };

  // 모달안에서 영상이나 비트 저장
  const saveContentList = () => {
    setPerformanceCurated(performanceWork);
    closeModal();
  };

  // 클릭시 추가
  const addSelectedContent = (data) => {
    if (!performanceWork?.find((e) => e.performanceId === data.performanceId)) {
      setPerformanceWork([...performanceWork, data]);
    }
  };

  // 클릭시 삭제
  const filterSelectedContent = (el) => {
    const result = performanceWork.filter((item) => item !== el);
    setPerformanceWork(result);
  };

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = performanceCurated[dragIndex];
      const hoverItem = performanceCurated[hoverIndex];

      setPerformanceCurated((temp) => {
        const workList = [...temp];
        workList[dragIndex] = hoverItem;
        workList[hoverIndex] = dragItem;
        return workList;
      });
    },
    [performanceCurated],
  );

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton text="수정하기" onSubmit={() => addContents('edit')}/>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput name="name" label="컨텐츠 명" value={name} placeholder="컨텐츠 명을 입력해 주세요." onChange={(e) => setName(e.target.value)} onReset={() => setName('')} Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput name="nameEn" label="컨텐츠 명 EN" value={nameEn} placeholder="컨텐츠 명 EN을 입력해 주세요." onChange={(e) => setNameEn(e.target.value)} onReset={() => setNameEn('')} Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput name="description" label="컨텐츠 설명" value={description} placeholder="컨텐츠 설명을 입력해 주세요." onChange={(e) => setDescription(e.target.value)} onReset={() => setDescription('')} Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput name="descriptionEn" label="컨텐츠 설명 EN" value={descriptionEn} placeholder="컨텐츠 설명 EN을 입력해 주세요." onChange={(e) => setDescriptionEn(e.target.value)} onReset={() => setDescriptionEn('')} Required/>
        </InputWrapper>
        <InputWrapper>
          <RadioButton value={activeYn} name="activeYn" label="공개 여부" onChange={(e) => setActiveYn(e.target.value)} text={['공개', '비공개']} type="activeYn" Required/>
        </InputWrapper>
        <InputWrapper>
          <DateInput label="노출 개시 일시" name="startDt" initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)} Required/>
        </InputWrapper>
        <InputWrapper>
          <DateInput label="노출 종료 일시" name="endDt" initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)} Required/>
        </InputWrapper>
        <InputWrapper>
          <VideoInput onClick={() => setModal()} text="공연 선택하기" />
        </InputWrapper>
        <InputWrapper>
          <TagHead>
            <ListHead title="" />
            <ListHead title="공연 아이디" />
            <ListHead title="포스터" />
            <ListHead title="타이틀" />
            <ListHead title="도시" />
            <ListHead title="공연장" />
            <ListHead title="개시일" />
            <ListHead title="종료일" />
          </TagHead>
          <>
            {performanceCurated?.map((item, index) => (
              <CuratedPerformanceRow
                data={item}
                moveListItem={movePetListItem}
                index={index}
              />
            ))}
          </>
        </InputWrapper>
      </MainContainer>
      {/*추가 모달*/}
      {modalOpen && (
        <VideoModal closeModal={closeModal} blockCloseBtn>
          <VideoModalWrapper>
            <ModalButtonWrapper>
              <NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
              <NormalButton text="저장하기" onSubmit={() => saveContentList()}/>
            </ModalButtonWrapper>
            <VideoModalTitle>선택된 공연</VideoModalTitle>
            {performanceWork?.length !== 0 && performanceWork ? (
              <>
                <TableTitle>{performanceWork?.length}개의 공연</TableTitle>
                <LineDiv />
                <Table colList={eventModalColList} className="nonListTable">
                  {performanceWork?.map((data) => (
                    <PerformanceModalRowComponent data={data} type={'remove'} onClick={() => filterSelectedContent(data)}/>
                  ))}
                </Table>
              </>
            ) : (
              <>
                <VideoModalSubText>선택된 공연이 없습니다. 아래 검색 후 공연을 선택해주세요.</VideoModalSubText>
                <LineDiv />
              </>
            )}
            <SpaceDiv height="90px" />
            <VideoModalTitle>공연 검색</VideoModalTitle>
            <TitleWrapper>
              <ButtonWrapper>
                {categoryList.map(c =>
                  <RoundButton
                      key={c.performanceCategoryId}
                      title={c.name}
                      selected={categoryId === c.performanceCategoryId}
                      onClick={() => setCategoryId(c.performanceCategoryId)}
                  />)}
              </ButtonWrapper>
            </TitleWrapper>
            <PaginationWrapper>
              <TableTitle>{totalCount}개 영상</TableTitle>
              {totalCount !== 0 && (
                <Pagination
                  postsPerPage={postsPerPage}
                  totalCount={totalCount}
                  currentPage={pageNumber}
                  pageDispatch={e => setPageNumber(e - 1)}
                />
              )}
            </PaginationWrapper>
            <LineDiv className="30" />
            <Table
              colList={eventModalColList}
              thead={
                <>
                  <ListHead title="공연 아이디" />
                  <ListHead title="포스터" />
                  <ListHead title="타이틀" />
                  <ListHead title="도시" />
                  <ListHead title="공연장" />
                  <ListHead title="개시일" />
                  <ListHead title="종료일" />
                  <ListHead title="" />
                </>
              }
            >
              {performanceMaster?.map((data) => (
                <PerformanceModalRowComponent data={data} type={'add'} onClick={addSelectedContent}/>
              ))}
            </Table>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default CuratedPerformanceDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(n + 3):nth-child(-n + 4) {
    grid-row: 2;
  }
  &:nth-child(5) {
    grid-row: 3;
    grid-column: span 2;
  }
  &:nth-child(n + 6):nth-child(-n + 7) {
    grid-row: 4;
  }
  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }
  &:nth-child(9) {
    grid-row: 6;
    grid-column: span 2;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.6fr 1.2fr 1.5fr 1.1fr 1.5fr 1.7fr 0.8fr 0.9fr;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SpaceDiv = styled.div` width: 100%; height: ${props => props.height}; `;

const TitleWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex; justify-content: flex-start; 
  color: ${({ theme }) => theme.color.mainBlack};
`;

