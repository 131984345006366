import React, { useState, useEffect, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import NormalButton from '../../../../components/common/Button/NormalButton';
import Pagination from '../../../../components/common/Pagination';
import { RecommendHashTagDetailRowComponent } from './RecommendCollectionRowComponent';
import VideoModal from '../../../../components/common/Modal/VideoModal';
import RadioButton from '../../../../components/common/Button/RadioButton';
import DateInput from '../../../../components/common/Input/DateInput';
import { CustomProps } from '../../../../definitions/common';

// constant
import { videoCollectionList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';

// config
import { CONTENTS_HASHTAG, RECOMMEND_HASHTAG } from '../../../../constant/apiUrl';

// hooks
import changeUrl from '../../../../hooks/changeUrl';
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// 타입
type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialTagData: data = {
  activeYn: '',
  startDt: '',
  endDt: '',
};

const RecommendHashTagDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [contentsUrl, setContentsUrl] = useState('');
  const [articleList, setArticleList] = useState<any>([]);
  const [tagData, setTagData] = useState<any>(initialTagData);
  const { tagId, tagName, editTagId }: any = location.state;
  const { activeYn, startDt, endDt } = tagData;
  // pagination state
  const [totalCount, setTotalCount] = useState<number>(0);
  const storagePageNumber = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );
  const initialStartDate = startDt?.replace(' ', 'T').slice(0, 16);
  const initialEndDate = endDt?.replace(' ', 'T').slice(0, 16);
  const params = {
    articleLimit: postsPerPage,
    articleOffset: pageNumber * postsPerPage,
  };
  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getTagList = async () => {
    try {
      const resp = await axios.get(`${CONTENTS_HASHTAG}/${tagId}/detail`, {
        params,
      });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
    }
  };

  const getTagDetail = async () => {
    try {
      const resp = await axios.get(`${RECOMMEND_HASHTAG}/${editTagId}`, {});
      setTagData(resp.data.recommendTags);
    } catch (err) {
      console.log(err);
    }
  };

  const addTag = async () => {
    const nowDate = new Date();
    const compareEndDate = nowDate > new Date(endDt);

    const data = {
      activeYn,
      startDate: `${initialStartDate}:00`,
      endDate: `${initialEndDate}:59`,
      tagId: tagId || tagData.tagsId,
    };
    if (!activeYn || !startDt || !endDt) {
      alert('양식을 모두 채워주세요.');
      return;
    }
    if (startDt > endDt) {
      alert('시작일시가 종료일시보다 느릴 수 없습니다.');
      return;
    }
    if (compareEndDate) {
      alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
      return;
    }

    if (editTagId) {
      try {
        dispatch(setLoading());
        await axios.post(`${RECOMMEND_HASHTAG}/${editTagId}/update`, data, {});
        alert('해시태그가 수정되었습니다.');
        navigate('/explore/allTab/hashTag');
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        dispatch(setLoading());
        await axios.post(RECOMMEND_HASHTAG, data, {});
        alert('해시태그가 등록되었습니다.');
        navigate('/explore/allTab/hashTag');
      } catch (err) {
        console.log(err);
      }
    }
    dispatch(resetLoading());
  };

  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setTagData({ ...tagData, [name]: value });
    },
    [tagData],
  );

  // 영상 버튼 클릭
  const viewArticle = (coverUrl: any) => {
    setContentsUrl(coverUrl);
    setModalOpen(true);
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
  };

  // url 컨트롤
  const changedUrl = useMemo(() => changeUrl(contentsUrl), [contentsUrl]);

  useEffect(() => {
    getTagList();
    if (editTagId) {
      getTagDetail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <Container>
      <TopWrapper>
        <TitleText>{tagName || tagData.tag}</TitleText>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={() => {
              navigate(-1);
            }}
            className="cancelBtn"
          />
          {editTagId ? (
            <NormalButton text="수정하기" onSubmit={addTag} />
          ) : (
            <NormalButton text="저장하기" onSubmit={addTag} />
          )}
        </ButtonWrapper>
      </TopWrapper>
      <CountText>{totalCount}개의 영상</CountText>
      <LineDiv />
      {modalOpen && (
        <VideoModal closeModal={closeModal}>
          <VideoModalWrapper>
            <Video controls autoPlay>
              <track default kind="captions" />
              <source src={changedUrl} type="video/mp4" />
              Your browser does not support the video tag. I suggest you upgrade
              your browser.
            </Video>
          </VideoModalWrapper>
        </VideoModal>
      )}
      <MainContainer>
        <InputWrapper data={editTagId}>
          <TopWrapper>
            <TableText>영상</TableText>
            {totalCount !== 0 && (
              <Pagination
                postsPerPage={postsPerPage}
                totalCount={totalCount}
                currentPage={pageNumber}
                pageDispatch={pageDispatch}
              />
            )}
          </TopWrapper>
          <Table
            colList={videoCollectionList}
            thead={
              <>
                <ListHead title="번호" />
                <ListHead title="썸네일" />
                <ListHead title="Pid" />
                <ListHead title="이름" />
                <ListHead title="비트명" />
                <ListHead title="업로드 날짜" />
                <ListHead title="공개 여부" />
                <ListHead title="상태" />
                <ListHead title="" />
              </>
            }
          >
            {articleList?.map((el: any) => (
              <RecommendHashTagDetailRowComponent
                key={el.id}
                data={el}
                onClick={() => {
                  viewArticle(el.contentsUrl);
                }}
              />
            ))}
          </Table>
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={handleInputSetData}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateWrapper>
            <DateInput
              initialStartDate={initialStartDate}
              onChange={handleInputSetData}
              name="startDt"
              label="시작일"
              Required
            />
            <DateInput
              initialStartDate={initialEndDate}
              onChange={handleInputSetData}
              name="endDt"
              label="종료일"
              Required
            />
          </DateWrapper>
        </InputWrapper>
      </MainContainer>
    </Container>
  );
};

export default RecommendHashTagDetail;

const Container = styled.div`
  width: 100%;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const TitleText = styled.h1`
  font-size: 36px;
  font-weight: 600;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const CountText = styled.span`
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
  margin-top: 40px;
`;

const TableText = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const VideoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
  padding: 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const Video = styled.video`
  max-width: 300px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 40px 40px;
  grid-gap: 40px 40px;
`;

const InputWrapper = styled.div<CustomProps>`
  &:nth-child(1) {
    margin-bottom: 60px;
    grid-row: ${({ data }) => data && '4'};
  }
`;
const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const LineDiv = styled.div`
  width: 100%;
  margin: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
