import React from 'react';

// component
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

const FilterRowComponent = ({ data, onClick, filterTypeList }: any) => {
  const {
    id,
    iconUrl,
    shaderName,
    studioCameraFilterTypeIds,
    endDt,
    shaderUrl,
  } = data;

  const handleFilterName = () => {
    const arr: string[] = [];
    filterTypeList.forEach((filter: any) => {
      if (studioCameraFilterTypeIds.includes(filter.id)) arr.push(filter.name);
    });
    return arr;
  };

  const handleFileTitle = (url: string) => {
    const arr = url.split('/');
    return arr[arr.length - 1];
  };

  return (
    <tr onClick={() => onClick(data)} key={id}>
      <td>
        <ListCoverImg url={iconUrl} />
      </td>
      <td>
        <ListItem text={shaderName} />
      </td>
      <td>
        <ListItem text={handleFilterName().join(', ')} />
      </td>
      <td>
        <ListItem text={handleFileTitle(shaderUrl)} />
      </td>
    </tr>
  );
};

export default FilterRowComponent;
