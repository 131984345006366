import React, {useEffect, useState} from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";

import {BATTLE} from "../../constant/apiUrl";

import styled, {css} from "styled-components";
import NormalButton from "../../components/common/Button/NormalButton";
import LabelTextInput from "../../components/common/Input/LabelTetxInput";
import LabelTextAreaInput from "../../components/common/Input/LabelTextAreaInput";

const BattleBasicConfig = () => {
	const navigate = useNavigate();

	const [fixedMasterConfig, setFixedMasterConfig] = useState({
		joiningHour: 0, votingHour: 0, votingPoints: [],
		normalNotice: '', specialNotice1: '', specialNotice2: '', specialNotice3: '',
		normalNoticeEn: '', specialNotice1En: '', specialNotice2En: '', specialNotice3En: '',
		specialPickNotice: '', specialPickNoticeEn: '',
	});
	const [transMasterConfig, setTransMasterConfig] = useState({
		joiningHour: 0, votingHour: 0, votingPoints: [],
		normalNotice: '', specialNotice1: '', specialNotice2: '', specialNotice3: '',
		normalNoticeEn: '', specialNotice1En: '', specialNotice2En: '', specialNotice3En: '',
		specialPickNotice: '', specialPickNoticeEn: '',
	});
	const [modified, setModified] = useState(false);

	const saveConfig = () => {
		if (!confirm('변경사항을 저장하시겠습니까?')) return;
		axios.post(`${BATTLE}/master-config`, transMasterConfig).then(() => navigate(0));
	}

	useEffect(() => {
		let pointsCheck = false;

		pointsCheck = (transMasterConfig.joiningHour !== fixedMasterConfig.joiningHour) || (transMasterConfig.votingHour !== fixedMasterConfig.votingHour) ||
			(transMasterConfig.normalNotice !== fixedMasterConfig.normalNotice) || (transMasterConfig.normalNoticeEn !== fixedMasterConfig.normalNoticeEn) ||
			(transMasterConfig.specialNotice1 !== fixedMasterConfig.specialNotice1) || (transMasterConfig.specialNotice1En !== fixedMasterConfig.specialNotice1En) ||
			(transMasterConfig.specialNotice2 !== fixedMasterConfig.specialNotice2) || (transMasterConfig.specialNotice2En !== fixedMasterConfig.specialNotice2En) ||
			(transMasterConfig.specialNotice3 !== fixedMasterConfig.specialNotice3) || (transMasterConfig.specialNotice3En !== fixedMasterConfig.specialNotice3En) ||
			(transMasterConfig.specialPickNotice !== fixedMasterConfig.specialPickNotice) || (transMasterConfig.specialPickNoticeEn !== fixedMasterConfig.specialPickNoticeEn);

		transMasterConfig.votingPoints.forEach((point, index) => {
			if (point !== fixedMasterConfig.votingPoints[index]) pointsCheck = true;
		});

		setModified(pointsCheck);
	}, [transMasterConfig])

	useEffect(() => {
		axios.get(`${BATTLE}/master-config`).then((res) => {
			const setConfig = {
				votingPoints: res.data?.votingPoints || [0,0,0,0,0,0],
				joiningHour: res.data?.joiningHour || 0, votingHour: res.data?.votingHour || 0,
				normalNotice: res.data?.normalNotice || '', normalNoticeEn: res.data?.normalNoticeEn || '',
				specialNotice1: res.data?.specialNotice1 || '', specialNotice1En: res.data?.specialNotice1En || '',
				specialNotice2: res.data?.specialNotice2 || '', specialNotice2En: res.data?.specialNotice2En || '',
				specialNotice3: res.data?.specialNotice3 || '', specialNotice3En: res.data?.specialNotice3En || '',
				specialPickNotice: res.data?.specialPickNotice || '', specialPickNoticeEn: res.data?.specialPickNoticeEn || '',
			};

			setFixedMasterConfig(setConfig);
			setTransMasterConfig(setConfig);
		})
	}, [])

	return <MainContainer>
		<ButtonWrapper>
			{
				modified
					? <NormalButton text='수정' onSubmit={() => saveConfig()} />
					: <NormalButton text='수정' onSubmit={() => {}} className='unActiveBtn' />
			}
		</ButtonWrapper>

		<InputWrapper>
			<LabelTextInput
				style={{'grid-column': '1 / span 2'}}
				Required
				type='number'
				name="joiningHour"
				label="일반 배틀 '참가' 가능 기간 설정"
				value={transMasterConfig.joiningHour}
				onChange={e => setTransMasterConfig({...transMasterConfig, joiningHour: Number(e.target.value)})}
			/>
			<UnitText>시간</UnitText>
			
			<LabelTextInput
				style={{'grid-column': '4 / span 2'}}
				Required
				type='number'
				name="votingHour"
				label="일반 배틀 '투표' 가능 기간 설정"
				value={transMasterConfig.votingHour}
				onChange={e => setTransMasterConfig({...transMasterConfig, votingHour: Number(e.target.value)})}
			/>
			<UnitText>시간</UnitText>
		</InputWrapper>

		<InputText>일반 배틀 퀵 투표 골드 설정 <span>*</span></InputText>
		<QuickWrapper>
			{transMasterConfig.votingPoints.map((point, index) => <InputBox key={index}>
				<input
					type='number'
					name={name}
					value={point || 0}
					onChange={e => {
						const setPoint = [...transMasterConfig.votingPoints];
						setPoint[index] = Number(e.target.value) > 999_999_999 ? 999_999_999        // 최대 체크
										: Number(e.target.value) < 1 ? 1 : Number(e.target.value);  // 최소 체크
						setTransMasterConfig({...transMasterConfig, votingPoints: setPoint});
					}}
				/>
				<UnitText ml={-25}>G</UnitText>
			</InputBox>)}
		</QuickWrapper>

		<NoticeBox className='normal'>
			<InputWrapper2>
				<LabelTextAreaInput
					Required
					name="normalNotice"
					label="일반 배틀 유의사항"
					placeholder="설명을 입력해주세요."
					value={transMasterConfig.normalNotice}
					setValue={e => setTransMasterConfig({...transMasterConfig, normalNotice: e.target.value})}
				/>
				
				<SpaceDiv />
				
				<LabelTextAreaInput
					name="normalNoticeEn"
					label="일반 배틀 유의사항 (영어)"
					placeholder="설명을 입력해주세요."
					value={transMasterConfig.normalNoticeEn}
					setValue={e => setTransMasterConfig({...transMasterConfig, normalNoticeEn: e.target.value})}
				/>
			</InputWrapper2>
		</NoticeBox>
		
		<NoticeBox className='specialPick'>
			<InputWrapper2>
				<LabelTextAreaInput
					Required
					name="specialPickNotice"
					label="스페셜 픽 유의사항"
					placeholder="설명을 입력해주세요."
					value={transMasterConfig.specialPickNotice}
					setValue={e => setTransMasterConfig({...transMasterConfig, specialPickNotice: e.target.value})}
				/>
				
				<SpaceDiv />
				
				<LabelTextAreaInput
					name="specialPickNoticeEn"
					label="스페셜 픽 유의사항 (영어)"
					placeholder="설명을 입력해주세요."
					value={transMasterConfig.specialPickNoticeEn}
					setValue={e => setTransMasterConfig({...transMasterConfig, specialPickNoticeEn: e.target.value})}
				/>
			</InputWrapper2>
		</NoticeBox>

		<NoticeBox className='special'>
			<NoticeSpan>
				<span>**</span> = 승리자 추가 포인트 값으로 치환됨<br/>
				<span>++</span> = 투표자 추가 포인트 값으로 치환됨
			</NoticeSpan>
			
			<SpaceDiv />
			<SpaceDiv />

			<InputWrapper2>
				<InputText>특별 배틀 유의사항 1 (승리자 추가 포인트 <span>{'>'}</span> 0, 투표자 추가 포인트 <span>{'>'}</span> 0) <span>*</span></InputText>
				<TextareaBox>
					<textarea
						name='specialNotice1'
						value={transMasterConfig.specialNotice1}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice1: e.target.value})}
					/>
				</TextareaBox>
				
				<SpaceDiv />
				
				<InputText>특별 배틀 유의사항 1 (영어)</InputText>
				<TextareaBox>
					<textarea
						name='specialNotice1En'
						value={transMasterConfig.specialNotice1En}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice1En: e.target.value})}
					/>
				</TextareaBox>
			</InputWrapper2>
			
			<LineDiv />
			<SpaceDiv />

			<InputWrapper2>
				<InputText>특별 배틀 유의사항 2 (승리자 추가 포인트 <span>{'>'}</span> 0, 투표자 추가 포인트 <span>{'='}</span> 0) <span>*</span></InputText>
				<TextareaBox>
					<textarea
						name='specialNotice2'
						value={transMasterConfig.specialNotice2}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice2: e.target.value})}
					/>
				</TextareaBox>
				
				<SpaceDiv />
				
				<InputText>특별 배틀 유의사항 2 (영어)</InputText>
				<TextareaBox>
					<textarea
						name='specialNotice2En'
						value={transMasterConfig.specialNotice2En}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice2En: e.target.value})}
					/>
				</TextareaBox>
			</InputWrapper2>
			
			<LineDiv />
			<SpaceDiv />
			
			<InputWrapper2>
				<InputText>특별 배틀 유의사항 3 (승리자 추가 포인트 <span>=</span> 0, 투표자 추가 포인트 <span>{'>'}</span> 0) <span>*</span></InputText>
				<TextareaBox>
					<textarea
						name='specialNotice3'
						value={transMasterConfig.specialNotice3}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice3: e.target.value})}
					/>
				</TextareaBox>
				
				<SpaceDiv />
				
				<InputText>특별 배틀 유의사항 3 (영어)</InputText>
				<TextareaBox>
					<textarea
						name='specialNotice3En'
						value={transMasterConfig.specialNotice3En}
						onChange={e => setTransMasterConfig({...transMasterConfig, specialNotice3En: e.target.value})}
					/>
				</TextareaBox>
			</InputWrapper2>
		</NoticeBox>
	</MainContainer>;
};

export default BattleBasicConfig;

const MainContainer = styled.div`
	width: 100%;
	margin-top: 10px;
`;

const ButtonWrapper = styled.div`
	width: 100%;
	text-align: right;
`;

const InputWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	gap: 10px;
	margin: 40px 0;
`;

const QuickWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
  	gap: 10px
`;

const InputWrapper2 = styled.div`
`;

const InputText = styled.span`
	display: block;
	margin-bottom: 10px;
	font-weight: bold;
	font-size: 14px;
	color: ${({ theme }) => theme.color.subDarkGray};
	grid-column: 1 / span 6;
  
  span {
	font-size: 16px;
	color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div`
  	position: relative;

	input {
	  	width: 100%;
		height: 55px;
		padding: 0 30px 0 20px;
		border-radius: 6px;
		background-color: ${({ theme }) => theme.color.subBrightGray};
		font-size: 18px;
		font-weight: 600;
		color: ${({ theme }) => theme.color.mainBlack};
		margin-bottom: 10px;
	}
`;

const TextareaBox = styled.div`
	position: relative;
 
	textarea {
		width: 100%;
		min-height: 100px;
		padding: 15px 15px;
		border-radius: 6px;
		background-color: ${({ theme }) => theme.color.subBrightGray};
		font-size: 18px;
		font-weight: 600;
		color: ${({ theme }) => theme.color.mainBlack};
		overflow: auto;
		
		::placeholder {
			  color: ${({ theme }) => theme.color.subGray};
			  font-weight: 500;
		}
		
		${(props) => props.name === 'push' && css`min-height: 100px;`}
	}
`;

const UnitText = styled.span`
	padding-top: 40px;
	font-weight: bold;
	font-size: 20px;
	margin-left: ${({ml}) => ml && `${ml}px`};
`;

const NoticeBox = styled.div`
	padding: 15px;
	margin-top: 30px;
	border-radius: 5px;
 
	&.normal {
      background-color: #5db8ff4d;
	}

	&.specialPick {
		background-color: #fff41938;
	}
 
	&.special {
		background-color: #cccccc;
	}
`;

const NoticeSpan = styled.div`
	font-weight: bold;
	font-size: 15px;
	display: block;
  
  	span {
      	font-family: 'D2Coding', serif;
      	font-size: 25px;
		color: ${({ theme }) => theme.color.mainRed};
	}
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const SpaceDiv = styled.div`
  display: flex;
  margin: 10px 0;
`;