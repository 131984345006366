import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import { SEARCH_MEMBER } from '../../../constant/apiUrl';
import { CustomProps } from '../../../definitions/common';
import {
  celebTypeOptions,
  memberRestrictOptions,
  searchMemberColList,
  searchMemberSearchTypeList,
  userTypeOptions,
} from '../../../constant/member';
import SearchMemberRowComponent from '../../member/components/SearchMemberRowComponent';
import useInput from '../../../hooks/useInput';
import { postsPerPage } from '../../../constant/common';

const PushForMember = ({
  closeModal,
  setSelectedModalObj,
  setSelectedModalType,
  allowMultiSelect=false,
}: any) => {
  const [selectedMemberList, setSelectedMemberList] = useState<any>([]);
  const [memberList, setMemberList] = useState([]);
  const [word, setWord] = useInput('');
  const [type, setType] = useState<string>('');
  const [isRestricted, setIsRestricted] = useState('');
  const [loginType, setLoginType] = useState('');
  const [celebType, setCelebType] = useState('');

  // pagination
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 엔터키로 member 검색
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && word) {
      // 인덱스 string 검색 차단
      if (type === 'I' && !Number(word)) {
        alert('인덱스는 숫자만 검색 가능합니다.');
        return;
      }
      if (!type) {
        setType('I');
        params.type = 'I';
      }
      setPageNumber(0);
      params.offset = 0;
      getMemberList();
    } else getMemberList();
  };

  // 검색어 인코딩
  const encodeStr = encodeURI(word);

  const params = {
    isRestricted,
    loginType,
    type,
    word: encodeStr,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    verifiedYn: celebType,
  };
  // member 조회
  const getMemberList = async () => {
    try {
      const resp = await axios.get(SEARCH_MEMBER, {
        params,
      });
      setMemberList(resp.data.memberList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      alert('잠시후 다시 시도 해주세요.');
    }
  };

  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  const filterSelectedContent = (el: any) => {
    const result = selectedMemberList.filter((item: any) => item !== el);
    setSelectedMemberList(result);
  };

  // 멤버 클릭시 추가
  const addSelectedContent = (data: any) => {
    if (selectedMemberList.length !== 0 && !allowMultiSelect) {
      alert('멤버는 하나만 선택할 수 있습니다.');
    } else {
      if (!selectedMemberList?.find((e:any) => e.id === data.id)) {
        setSelectedMemberList([...selectedMemberList, data]);
      }
    }
  };

  const saveMember = () => {
    if (selectedMemberList.length === 0) {
      alert('최소 한명의 멤버를 선택해야합니다.');
      return;
    }
    alert('저장되었습니다.');

    setSelectedModalObj(allowMultiSelect ? selectedMemberList : selectedMemberList[0]);
    setSelectedModalType('MB');
    closeModal();
  };

  useEffect(() => {
    setPageNumber(0);
    getMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestricted, loginType, celebType]);

  useEffect(() => {
    getMemberList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
      <VideoModalWrapper>
        <ModalButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={closeModal}
            className="cancelBtn"
          />
          <NormalButton text="저장하기" onSubmit={saveMember} />
        </ModalButtonWrapper>
        <VideoModalTitle>선택 멤버</VideoModalTitle>
        {selectedMemberList.length !== 0 ? (
          <>
            <TableTitle>{selectedMemberList.length}개 멤버</TableTitle>
            <LineDiv />
            <Table colList={searchMemberColList} className="nonListTable">
              {selectedMemberList?.map((data: any) => (
                <SearchMemberRowComponent
                  key={data.id}
                  data={data}
                  type="modal"
                  selected
                  modalOnClick={filterSelectedContent}
                />
              ))}
            </Table>
          </>
        ) : (
          <>
            <VideoModalSubText>
              선택된 멤버가 없습니다. 아래 검색 후 멤버를 선택해주세요.
            </VideoModalSubText>
            <LineDiv />
          </>
        )}
        <SpaceDiv value="90px" />
        {selectedMemberList.length === 0 && <EmptyDiv />}
        <VideoModalTitle>멤버 검색</VideoModalTitle>
        <TitleWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={searchMemberSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
          </SearchWrapper>
        </TitleWrapper>
        <PaginationWrapper>
          <TableTitle>{totalCount}명의 멤버</TableTitle>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
        <LineDiv />
        <Table
          colList={searchMemberColList}
          thead={
            <>
              <ListHead title="인덱스" />
              <ListHead title="Pid" />
              <ListHead title="이메일" />
              <ListSelect
                title="가입 유형"
                list={userTypeOptions}
                onClick={handleSelect}
                setState={setLoginType}
              />
              <ListSelect
                title="셀럽 인증"
                list={celebTypeOptions}
                onClick={handleSelect}
                setState={setCelebType}
              />
              <ListHead title="계정 생성일" />
              <ListHead title="벌점" />
              <ListSelect
                title="계정 상태"
                list={memberRestrictOptions}
                onClick={handleSelect}
                setState={setIsRestricted}
              />
              <ListHead title="" />
            </>
          }
        >
          {memberList?.map((el: any) => (
            <SearchMemberRowComponent
              key={el.id}
              data={el}
              type="modal"
              modalOnClick={addSelectedContent}
            />
          ))}
        </Table>
      </VideoModalWrapper>
    </VideoModal>
  );
};

export default PushForMember;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 30px;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 50px;
  align-items: center;
`;

const SpaceDiv = styled.div<CustomProps>`
  height: ${({ value }) => value};
`;

const EmptyDiv = styled.div`
  margin-top: 120px;
  height: 90px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
