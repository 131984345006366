import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import calender from '../../../assets/images/page/calender.png';
import activeCalender from '../../../assets/images/page/activeCalender.png';

// hooks
import useOutSideClick from '../../../hooks/useOutSideClick';
import getUtcTime from '../../../hooks/getUtcTIme';

const ListDate = ({
  requiredDate,
  setMinStartDate,
  setMaxStartDate,
  active,
}: any) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onChange = (dates: any, e: any) => {
    e.stopPropagation();
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setMinStartDate(getUtcTime(start, 'start'));
    if (end) {
      setMaxStartDate(getUtcTime(end, 'end'));
    }
  };

  const changeDate = (date: Date) => {
    const arr = date.toLocaleDateString().split('. ');
    const year = arr[0].slice(2, 4);
    const month = arr[1];
    const day = arr[2].replace('.', '');
    return `${year}-${month}-${day}`;
  };
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    setIsOpen(true);
  };

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(false);
  }, [endDate]);

  return (
    <Container
      onClick={() => {
        toggling();
      }}
      ref={ref}
    >
      {requiredDate && startDate && endDate !== null && (
        <OptionMenu className="option-menu">
          <TitleOption>
            {changeDate(startDate)} ~ {changeDate(endDate)}
          </TitleOption>
        </OptionMenu>
      )}
      <img src={active ? activeCalender : calender} alt="calenderImg" />
      {isOpen && (
        <AbsoluteDiv>
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            shouldCloseOnSelect={false}
            inline
          />
        </AbsoluteDiv>
      )}
    </Container>
  );
};

export default ListDate;

const Container = styled.div`
  display: flex;
  position: relative;
  height: 44px;
  margin-left: 12px;
  padding: 0;

  img {
    width: 44px;
    height: 44px;
    object-fit: contain;
  }
`;

const AbsoluteDiv = styled.div`
  position: absolute;
  right: 0;
  top: 44px;
  z-index: 3;
`;

const OptionMenu = styled.span`
  position: relative;
  padding: 14px;
  margin-right: 15px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  z-index: 1;
`;

const TitleOption = styled.span`
  color: ${({ theme }) => theme.color.subDarkGray};
`;
