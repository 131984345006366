import React from 'react';
import styled, { css } from 'styled-components';

// 3개 이상의 항목을 갖는 라디오 버튼.
// RadioButton.tsx은 OS의 경우 구분 값을 고정으로 갖고 있어 신규 작성함
const MultipleRadioButton = ({
  label,
  Required,
  name,
  value,
  options,
  onChange,
  disabled,
}) => {
  return (
    <InputContainer className={name}>
      {label && (
        <InputText>{label} {Required && <span>*</span>}</InputText>
      )}
      <RadioBox>
        {/*options(name, value)를 순회하며 라디오 버튼 생성*/}
        {options && options.map((item, index) => (
          <RadioSection key={index}>
            <RadioLabel>
              <InputField
                  type="radio"
                  id={`${name}_radio${index + 1}`}
                  name={name}
                  value={item.value}
                  onChange={onChange}
                  checked={value !== '' && value === item.value}
                  disabled={disabled}
              />
              <p>{item.text}</p>
            </RadioLabel>
          </RadioSection>
        ))}
      </RadioBox>
    </InputContainer>
  );
};
export default MultipleRadioButton;

const InputContainer = styled.div`
  display: block;

  ${(props) =>
    props.className === 'receiveNightTimeInfoYn' &&
    css`
      width: 100%;
    `}
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const RadioBox = styled.div`
  display: flex;
  align-content: center;
`;

const RadioSection = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  line-height: 52px;

  &:not(:last-child) {
    margin-right: 30px;
  }

  &.uneditable {
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  }
  
  &.custom {
    margin-right: 5px;
  }
`;

const RadioLabel = styled.label`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  color: ${({ theme }) => theme.color.subGray};
`;

const InputField = styled.input.attrs({ type: 'radio' })`
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  margin: 0 5px 0 0;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.mainWhite};

  &:before {
    content: '';
    display: block;
    width: 70%;
    height: 70%;
    margin: 15% auto;
    border-radius: 50%;
  }

  &:checked:before {
    background-color: ${({ theme }) => theme.color.mainPurple};
  }

  &:checked + p {
    font-weight: 600;
    color: ${({ theme }) => theme.color.mainBlack};
  }
}

p {
  margin-left: 10px;
}

`;
