import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { GENRE_LIST, PARENT_GENRE_LIST } from '../../../constant/apiUrl'; // 장르 받아오기

// 장르 받아오기
export const getGenreList = createAsyncThunk(
  'genre/getGenreList',
  async (params: object, { rejectWithValue }) => {
    try {
      const response = await axios.get(GENRE_LIST, {
        params,
      });
      return response.data;
    } catch (error: any) {
      console.log(rejectWithValue(error.response.data));
      return rejectWithValue(error.response.data);
    }
  },
);

// 부모 장르 받아오기
export const getParentGenreList = createAsyncThunk(
  'genre/getParentGenreList',
  async (params: object, { rejectWithValue }) => {
    try {
      const response = await axios.get(PARENT_GENRE_LIST, {
        params,
      });
      console.log(response.data.masterParentGenreList);
      return response.data;
    } catch (error: any) {
      console.log(rejectWithValue(error.response.data));
      return rejectWithValue(error.response.data);
    }
  },
);

// 마스터 장르 추가하기
// const addMasterGenre = async () => {
//   if (genreList.some((object: any) => object.genre === genreName)) {
//     alert('해당 장르가 이미 등록되어 있습니다.');
//   } else {
//     try {
//       const resp = await axios.post(
//         GENRE_LIST,
//         {},
//         { params: { adminName: managerName, genre: genreName } },
//       );
//       getMasterGenre();
//     } catch (err) {
//       console.log(err);
//     }
//   }
// };
