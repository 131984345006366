import React from 'react';
import styled from 'styled-components';
import EditButton from './Button/EditButton';
import clipIcon from '../../assets/images/page/clipIcon.png';

interface TextFieldProps {
  text?: string | null;
  label: string | null;
  reportScore?: any;
  point?: any;
  type?: string | null;
  percent?: any;
  onClick?: any;
}

const TextField = ({
  text,
  label,
  reportScore,
  point,
  type,
  percent,
  onClick,
}: TextFieldProps) => {
  // 첫번째 벌점 계산
  const getPoint = () => {
    return (
      <ScoreWrapper>
        <div>
          <CurrentScore>
            {reportScore === null ? 0 : `${reportScore}`}
          </CurrentScore>
          <ScoreSubText>(현재 벌점)</ScoreSubText>
          <PointScore>{` + ${point}`}</PointScore>
          <ScoreSubText>(부여 벌점)</ScoreSubText>
        </div>
        <div>
          <PointScore>{` =${reportScore + point}`}</PointScore>
          <ScoreSubText>(차단시 누적 벌점)</ScoreSubText>
        </div>
      </ScoreWrapper>
    );
  };

  // 두번째 벌점 계산
  const getSecondPoint = () => {
    return (
      <ScoreWrapper>
        <div>
          <CurrentScore>
            {reportScore === null ? 0 : `${reportScore}`}
          </CurrentScore>
          <ScoreSubText>(현재 벌점)</ScoreSubText>
          <PointScore>{` - ${point}`}</PointScore>
          <ScoreSubText>(차감 벌점)</ScoreSubText>
        </div>
        <div>
          <PointScore>{` =${reportScore - point}`}</PointScore>
          <ScoreSubText>(삭제시 누적 벌점)</ScoreSubText>
        </div>
      </ScoreWrapper>
    );
  };

  return (
    <TextContainer>
      <LabelWrapper>
        <span>{label}</span>
        {percent && <span>정확도</span>}
      </LabelWrapper>

      {(type === '미차단 내역' || type === '삭제 내역') && (
        <TextBox>{getPoint()}</TextBox>
      )}
      {type === '차단 내역' && <TextBox>{getSecondPoint()}</TextBox>}
      {type === 'rekognition' && (
        <TextBox>
          {text}
          <RekognitionPercent>{percent}%</RekognitionPercent>
        </TextBox>
      )}
      {type === 'unActive' && <PlaceHolderText>{text}</PlaceHolderText>}
      {type === 'fileInput' && <FileTextBox>{text}</FileTextBox>}
        {type === 'clickButton' && onClick && (
            <ClickBox onClick={onClick}>{text}</ClickBox>
        )}
      {!type && (
        <ScoreWrapper>
          <TextBox>{text}</TextBox>
          {onClick && (
            <TextBoxWrapper>
              <EditButton onClick={onClick} text="수정" />
            </TextBoxWrapper>
          )}
        </ScoreWrapper>
      )}
    </TextContainer>
  );
};

export default TextField;

const TextContainer = styled.div`
  width: 100%;
  display: block;
`;

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const TextBox = styled.div`
  display: flex;
  min-height: 50px;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 16px 20px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.mainBlack};
`;

// 파일인풋용
const FileTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  padding: 16px 20px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 10px;
  background: url(${clipIcon}) calc(100% - 10px) no-repeat
    ${({ theme }) => theme.color.mainWhite};
  background-size: 32px;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const RekognitionPercent = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;

const ScoreWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextBoxWrapper = styled.div`
  width: 15%;
  margin-left: 15px;
`;

const CurrentScore = styled.span`
  color: ${({ theme }) => theme.color.mainBlack};
`;
const ScoreSubText = styled.span`
  color: ${({ theme }) => theme.color.subGray};
  font-weight: 400;
`;
const PointScore = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;
const PlaceHolderText = styled.span`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 16px 20px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 10px;
  color: ${({ theme }) => theme.color.subGray};
`;

const ClickBox = styled.button`
  padding: 10px 14px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 600;
  border: 1px solid ${({ theme }) => theme.color.subGray};
  color: ${({ theme }) => theme.color.subDarkGray};
  white-space: nowrap;
  cursor: pointer;
`;
