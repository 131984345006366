import React from 'react';
import styled from 'styled-components';

// asset
import blankUserImg from '../../../assets/images/page/blankUserImg.png';
import blankImg from '../../../assets/images/page/blankImg.png';

interface ProfileImgProps {
  url?: string;
  className?: string;
  label?: string;
}

interface ProfileProps {
  url?: string;
}

const ProfileImg = ({ url, label, className }: ProfileImgProps) => {
  return (
    <ProfileWrapper>
      {label && <InputText>{label}</InputText>}
      <Profile url={url} className={className} />
    </ProfileWrapper>
  );
};

export default ProfileImg;

const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 0;
`;

const Profile = styled.div<ProfileProps>`
  width: 175px;
  height: 175px;
  border-radius: 6px;
  background: url(${({ url }) => url || blankImg}) center no-repeat;
  background-size: cover;

  &.profile {
    border-radius: 6px;
    background: url(${({ url }) => url || blankUserImg}) center no-repeat;
    background-size: cover;
  }

  &.block {
    border-radius: 0;
    width: 308px;
    height: 308px;
  }
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};
`;
