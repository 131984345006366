import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getManagementList } from '../actions/customService/customService';

interface initialType {
  managementList: Array<any>;
}

const initialState: initialType = {
  managementList: [],
};

const csSlice = createSlice({
  name: 'cs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // 장르 리스트가져오기
      .addCase(getManagementList.pending, (state, action) => {})
      .addCase(getManagementList.fulfilled, (state, action) => {
        state.managementList = action.payload;
      })
      .addCase(
        getManagementList.rejected,
        (state, action: PayloadAction<any>) => {
          console.log('rejected');
        },
      );
  },
});

export default csSlice;
