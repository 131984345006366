import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';

// common
import Table from '../../../components/common/List/Table';
import RoundButton from '../../../components/common/Button/RoundButton';
import ListHead from '../../../components/UI/ListHead';
import Pagination from '../../../components/common/Pagination';

// constant
import { postsPerPage } from '../../../constant/common';

// config
import { COMMUNITY_BOARD_ARTICLE } from '../../../constant/apiUrl';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';

const CommunityFixArticleAddList = () => {
  const navigate = useNavigate();
  const [listType, setListType] = useState(true);
  const [articleList, setArticleList] = useState([]);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );

  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getArticleList = async () => {
    let url: string;
    if (listType) {
      url = `${COMMUNITY_BOARD_ARTICLE}/official`;
    } else {
      url = `${COMMUNITY_BOARD_ARTICLE}/member`;
    }
    try {
      const resp = await axios.get(url, { params });
      setArticleList(resp.data.articleList);
      setTotalCount(resp.data.count || 0);
    } catch (err) {
      console.log(err);
    }
  };

  const addArticle = (articleId: any) => {
    navigate('/operation/communityFixArticle/add', {
      state: {
        prevId: articleId,
      },
    });
  };

  useEffect(() => {
    getArticleList();
  }, [pageNumber]);

  useEffect(() => {
    setPageNumber(0);
    params.offset = 0;
    getArticleList();
  }, [listType]);

  return (
    <Container>
      <TopWrapper>
        <ButtonWrapper>
          <RoundButton
            title="Baund"
            selected={listType}
            onClick={() => setListType(true)}
          />
          <RoundButton
            title="유저"
            selected={!listType}
            onClick={() => setListType(false)}
          />
        </ButtonWrapper>
        <NormalButton
          text="취소"
          className="cancelBtn"
          onSubmit={() => navigate(-1)}
        />
      </TopWrapper>
      {totalCount !== 0 && (
        <Pagination
          postsPerPage={postsPerPage}
          totalCount={totalCount}
          currentPage={pageNumber}
          pageDispatch={pageDispatch}
        />
      )}
      <Table
        colList={addCommunityBoardArticleList}
        thead={
          <>
            <ListHead title="게시글 아이디" />
            <ListHead title="게시글 작성자 Pid" />
            <ListHead title="게시글 작성자 명" />
            <ListHead title="게시글 제목" />
            <ListHead title="게시글 내용" />
          </>
        }
      >
        {articleList?.map((el: any) => (
          <tr key={el.id} onClick={() => addArticle(el.id)}>
            <td>
              <ListItem text={el.id} />
            </td>
            <td>
              <ListItem text={el.memberPid} />
            </td>
            <td>
              <ListItem text={el.memberName} />
            </td>
            <td>
              <ListItem text={el.title} />
            </td>
            <td>
              <ListItem text={el.content} />
            </td>
          </tr>
        ))}
      </Table>
    </Container>
  );
};

export const addCommunityBoardArticleList = [80, 100, 100, 180, 300];

export default CommunityFixArticleAddList;

const Container = styled.div`
  width: 100%;
  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin-bottom: 50px;
`;
