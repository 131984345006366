import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination';

// config
import { COMMUNITY_CURATED_ARTICLE } from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';

const CuratedTagsArticle = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate('/explore/curatedTagsArticle/edit', {
      state: {
        prevId: state?.id,
      },
    });
  };

  const getContents = useCallback(async () => {
    const params = {
      contentsType: 'TAG',
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(
        `${COMMUNITY_CURATED_ARTICLE}/${activationType}`,
        { params },
      );
      setTotalCount(resp.data.count);
      setContentsList(resp.data.communityBoardArticleList);
    } catch (err) {
      console.log(err);
    }
  }, [activationType, pageNumber]);

  useEffect(() => {
    getContents();
  }, [getContents, activationType]);

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
            title="활성"
            selected={activationType === 'active'}
            onClick={() => setActivationType('active')}
          />
          <RoundButton
            title="비활성"
            selected={activationType === 'inactive'}
            onClick={() => setActivationType('inactive')}
          />
        </div>
        <ButtonWrapper>
          {contentsList && (
            <>
              {activationType === 'active' && (
                <NormalButton
                  text="노출 순서 변경"
                  className="cancelBtn"
                  onSubmit={() => {
                    navigate('editSeq', {});
                  }}
                />
              )}
            </>
          )}
          <NormalButton
            text="추천 태그 등록"
            onSubmit={() => {
              navigate('/explore/curatedTagsArticle/add', {
                state: {
                  nextSequence: totalCount + 1,
                  prevId: null,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="표시 순서" />
              <ListHead title="컨텐츠 아이디" />
              <ListHead title="컨텐츠 명" />
              <ListHead title="컨텐츠 설명" />
              <ListHead title="노출 개시 일시" />
              <ListHead title="노출 종료 일시" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td>
                <ListItem text={el.sequence} />
              </td>
              <td>
                <ListItem text={el.id} />
              </td>
              <td>
                <ListItem text={el.name} />
              </td>
              <td>
                <ListItem text={el.description} />
              </td>
              <td>
                <ListItem text={el.startDt} />
              </td>
              <td>
                <ListItem text={el.endDt} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default CuratedTagsArticle;

export const colList = [50, 50, 80, 100, 80, 80];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
