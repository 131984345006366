import React from 'react';

// component
import ListItem from '../../../components/common/List/ListItem';
import EditButton from '../../../components/common/Button/EditButton';

const CommunityBoardArticleRow = ({ data, onClick, selected, name }: any) => {
  const { id, title, content, memberPid, memberName } = data;
  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={title} />
      </td>
      <td>
        <ListItem text={content} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      <td>
        <ListItem text={memberName} />
      </td>
      <td>
        {selected ? (
          <EditButton
            onClick={() => onClick(data, name)}
            text="삭제"
            className="deleteBtn"
          />
        ) : (
          <EditButton
            onClick={() => onClick(data)}
            text="선택"
            className="selectBtn"
          />
        )}
      </td>
    </tr>
  );
};
export default CommunityBoardArticleRow;
