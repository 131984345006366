const refineContentsType = (type: string) => {
  switch (type) {
    case 'MB':
      return '유저';
    case 'ACM':
      return '영상 댓글';
    case 'ASCM':
      return '영상 대댓글';
    case 'BCM':
      return '비트 댓글';
    case 'BSCM':
      return '비트 대댓글';
    case 'CL':
      return '컬렉션';
    case 'DM':
      return 'DM';
    case 'CB':
      return '커뮤니티';
    case 'CBCM':
      return '커뮤니티 댓글';
    case 'CBSCM':
      return '커뮤니티 대댓글';
    default:
      return '신고타입 없음';
  }
};

export default refineContentsType;
