import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';

// config
import { ADMIN_BAUND_API_CATEGORY } from '../../../../constant/apiUrl';
import { resetLoading, setLoading } from '../../../../redux/slices/user';
import SelectModule from "../../../../components/common/Select/SelectModule";
import {baundApiCategoryTypeOptions} from "../../../../constant/baundApi";
import {CustomProps} from "../../../../definitions/common";

const BaundApiCategoryDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const baundApiCategoryId = detailData !== null ? detailData.baundApiCategoryId : null;
  const activationTypeForSearch = detailData?.activationTypeForSearch || 0;
  const baundApiCategoryTypeForSearch = detailData?.baundApiCategoryTypeForSearch || null;
  const pageNumberForSearch = detailData?.pageNumberForSearch || null;

  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [sequence, setSequence] = useState(0);
  const [description, setDescription] = useState('');
  const [tabImgUrl, setTabImgUrl] = useState<string>('');
  const [coverImgUrl, setCoverImgUrl] = useState<string>('');
  const [coverImgUrlEn, setCoverImgUrlEn] = useState<string>('');
  const [activeYn, setActiveYn] = useState('Y');
  const [categoryType, setCategoryType] = useState('HOME');
  const [categoryTypeText, setCategoryTypeText] = useState('홈');


  useEffect(() => {
    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ activationTypeForSearch, baundApiCategoryTypeForSearch, pageNumberForSearch })
    );
  }, []);

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(
        `${ADMIN_BAUND_API_CATEGORY}/${baundApiCategoryId}/detail`,
      );
      if (resp.data.adminBaundApiCategory) {
        setName(resp.data.adminBaundApiCategory.name);
        setNameEn(resp.data.adminBaundApiCategory.nameEn);
        setSequence(resp.data.adminBaundApiCategory.sequence);
        setDescription(resp.data.adminBaundApiCategory.description);
        setTabImgUrl(
          resp.data.adminBaundApiCategory.tabImgUrl
            ? resp.data.adminBaundApiCategory.tabImgUrl
            : '',
        );
        setCoverImgUrl(
          resp.data.adminBaundApiCategory.coverImgUrl
            ? resp.data.adminBaundApiCategory.coverImgUrl
            : '',
        );
        setCoverImgUrlEn(
          resp.data.adminBaundApiCategory.coverImgUrlEn
            ? resp.data.adminBaundApiCategory.coverImgUrlEn
            : '',
        );
        setActiveYn(resp.data.adminBaundApiCategory.activeYn);
        if (resp.data.adminBaundApiCategory.categoryType) {
          setCategoryType(resp.data.adminBaundApiCategory.categoryType);
          setCategoryTypeText(baundApiCategoryTypeOptions.filter(e => e.value === resp.data.adminBaundApiCategory.categoryType)[0].text);
        }
        else {
          setCategoryType('HOME');
          setCategoryTypeText('홈');
        }
      }
    } catch (error: any) {
      console.log(error.response.data);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [baundApiCategoryId]);
  useEffect(() => {
    if (baundApiCategoryId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, baundApiCategoryId]);

  const addContents = async (type: string) => {
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('nameEn', nameEn);
    formData.append('sequence', sequence.toString());
    if (description != null) {
      formData.append('description', description);
    }
    formData.append('activeYn', activeYn);
    formData.append('categoryType', categoryType);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(
          `${ADMIN_BAUND_API_CATEGORY}/${baundApiCategoryId}/edit`,
          formData,
          {},
        );
      } else {
        await axios.post(`${ADMIN_BAUND_API_CATEGORY}`, formData, {});
      }
      await navigate('/system/baundApiCategory');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  const handleCategoryType = (e:any) => {
    setCategoryType(e.value);
    setCategoryTypeText(e.text);
  }
  return (
    <>
      <TitleText>바운드 API 카테고리 상세</TitleText>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {baundApiCategoryId
            ? (<NormalButton text="수정하기" onSubmit={() => addContents('edit')}/>)
            : (<NormalButton text="저장하기" onSubmit={() => addContents('add')}/>)
          }
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper data={2}>
          <SelectModule
              name="categoryType" label="카테고리 타입" placeholder={"카테고리 타입을 선택해 주세요"} Required
              value={categoryTypeText}
              onChange={e => handleCategoryType(e)}
              options={baundApiCategoryTypeOptions}
          />
        </InputWrapper>
        {(tabImgUrl || coverImgUrl || coverImgUrlEn) && (<>
          <InputWrapper>
            <InputText>이미지는 수기 업로드 후 데이터 수정<br/>&nbsp;</InputText>
            <CoverImgWrapper>
              <InputText>탭 이미지</InputText>
              <EventImg src={tabImgUrl}/>
            </CoverImgWrapper>
          </InputWrapper>
          <InputWrapper>&nbsp;</InputWrapper>
          <InputWrapper>
            <CoverImgWrapper>
              <InputText>커버 이미지</InputText>
              <EventImg src={coverImgUrl} />
            </CoverImgWrapper>
          </InputWrapper>
          <InputWrapper>
            <CoverImgWrapper>
              <InputText>커버 이미지(EN)</InputText>
              <EventImg src={coverImgUrlEn} />
            </CoverImgWrapper>
          </InputWrapper>
        </>)}
        <InputWrapper>
          <LabelTextInput
            name="name" label="카테고리 명" placeholder="카테고리 명을 입력해 주세요." Required
            value={name}
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn" label="카테고리 명 EN" placeholder="카테고리 명 EN을 입력해 주세요." Required
            value={nameEn}
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
          />
        </InputWrapper>
        <InputWrapper data={2}>
          <LabelTextInput
            name="description" label="카테고리 설명" placeholder="카테고리 설명을 입력해 주세요."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper data={2}>
          <RadioButton
            name="activeYn" label="공개 여부" text={['공개', '비공개']} type="activeYn" Required
            value={activeYn}
            onChange={(e: any) => setActiveYn(e.target.value)}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default BaundApiCategoryDetail;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const InputWrapper = styled.div<CustomProps>`
  grid-column: ${({data}) => data && `span ${data}`};
`;

const EventImg = styled.img`
  width: 100%;
  border-radius: 10px;
  padding-top: 10px;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 0;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;
