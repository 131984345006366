import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NormalButton from '../../../../components/common/Button/NormalButton';
import blankImg from '../../../../assets/images/page/blankImg.png';

// config
import { COLLECTION } from '../../../../constant/apiUrl';

// hooks
import handleDate from '../../../../hooks/handleDate';

// constant
import { GetLanguageOption } from '../../../../constant/common';
import { collectionType } from '../../../../constant/explore';

const RecommendCollection = () => {
  const navigate = useNavigate();
  const [viewType, setViewType] = useState(true);
  const [list, setList] = useState([]);

  const getRecommendCollectionList = async (type: string) => {
    const params = {
      limit: 1000,
      offset: 0,
      type,
    };
    try {
      const resp = await axios.get(COLLECTION, { params });
      setList(resp.data.adminRecommendCollectionList);
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도해 주세요.');
    }
  };

  useEffect(() => {
    getRecommendCollectionList('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ButtonContainer>
        <ActiveButtonWrapper>
          <RoundButton
            title="공개"
            selected={viewType}
            onClick={() => {
              setViewType(!viewType);
              getRecommendCollectionList('');
            }}
          />
          <RoundButton
            title="비공개"
            selected={!viewType}
            onClick={() => {
              setViewType(!viewType);
              getRecommendCollectionList('disable');
            }}
          />
        </ActiveButtonWrapper>
        <NormalButton
          text="컬렉션 추가하기"
          onSubmit={() => {
            navigate('/explore/allTab/recommendCollection/colList');
          }}
        />
      </ButtonContainer>
      <CollectionContainer>
        {list?.map((item: any) => (
          <Li key={item.id}>
            {item.coverImgUrl ? (
              <Img src={item.coverImgUrl} alt="coverImg" />
            ) : (
              <Img src={blankImg} alt="defaultImg" />
            )}
            <TextWrapper>
              <TitleWrapper>
                <Title>
                  <span>{item.collectionId}.</span>
                  {item.name}
                </Title>
                <TypeWrapper>{collectionType[item.type]}</TypeWrapper>
              </TitleWrapper>
              <SubTitle>{item.memberPid}</SubTitle>
              <ButtonWrapper>
                <ButtonTextWrapper>
                  <DateText>
                    {`${handleDate(item.recommendStartDt)} ~ ${handleDate(
                      item.recommendEndDt,
                    )}`}
                  </DateText>
                  <span>{GetLanguageOption[item.language]}</span>
                </ButtonTextWrapper>
                <NormalButton
                  className="cancelBtn"
                  text="수정하기"
                  onSubmit={() => {
                    navigate('edit', { state: { editData: item } });
                  }}
                />
              </ButtonWrapper>
            </TextWrapper>
          </Li>
        ))}
      </CollectionContainer>
    </Container>
  );
};

export default RecommendCollection;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0 100px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ActiveButtonWrapper = styled.div``;

const CollectionContainer = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  display: flex;
  width: 100%;
  padding: 40px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const Img = styled.img`
  width: 175px;
  height: 175px;
  margin-right: 30px;
  border-radius: 3px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 205px);
  margin: 20px 0;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: bold;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const Title = styled.div`
  max-width: 700px;
  span {
    margin-right: 8px;
    color: ${({ theme }) => theme.color.subGray};
  }
`;

const TypeWrapper = styled.p`
  width: 30px;
  font-size: 16px;
  font-weight: bold;
  text-align: right;
  color: ${({ theme }) => theme.color.mainPurple};
`;

const SubTitle = styled.div`
  margin-bottom: 16px;
  height: 20px;
  font-size: 16px;
  font-weight: normal;
  color: ${({ theme }) => theme.color.mainBlack};
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  span {
    margin-left: 15px;
  }
`;

const DateText = styled.div`
  height: 20px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: ${({ theme }) => theme.color.subGray};
`;
