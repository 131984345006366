import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BAUND_PROJECT, BAUND_PROJECT_QNA} from "../../constant/apiUrl";
import PaginationWithTotalCount
  from "../../components/common/Pagination/PaginationWithTotalCount";
import {postsPerPage} from "../../constant/common";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import styled from "styled-components";
import axios from "axios";
import RoundButton from "../../components/common/Button/RoundButton";
import SelectWithSearch from "../../components/common/Select/SelectWithSearch";

// 바운드 프로젝트 QnA 목록
const BaundProjectQnas = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [baundProjectQnaList, setBaundProjectQnaList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  // 저장된 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition'));

  // 필터 //////////////////////////////////////////////////////////////////////////////////////////
  const [baundProjectId, setBaundProjectId] = useState(storageSearchCondition?.baundProjectIdForSearch || 0);
  const [pageNumber, setPageNumber] = useState(storageSearchCondition?.pageNumberForSearch || 0);
  const [answerYn, setAnswerYn] = useState(storageSearchCondition?.answerYnForSearch || 'N');

  const [projectTitle, setProjectTitle] = useState('전체');
  const [projects, setProjects] = useState();

  // 전체 프로젝트 조회
  const getProjects = async() => {
    try {
      const resp = await axios.get(`${BAUND_PROJECT}-all`);
      let workProjects = [
          {baundProjectId:0, title:'전체', deleteYn:'N'}, ...resp.data.baundProjects
      ];
      setProjects(workProjects);
      let selectedProject = workProjects.filter(el => el.baundProjectId === baundProjectId);
      // console.log("BaundProjectQnas >> getProjects >> temp:", temp);
      setProjectTitle(selectedProject[0].title);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getProjects();

    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  const handleSelectSetData = (e) => {
    setBaundProjectId(e.baundProjectId);
    setProjectTitle(e.title + ((e.deleteYn === "Y") ? '(삭제)':''));
  };

  //목록 조회 //////////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    answerYn: answerYn,
    baundProjectId: baundProjectId
  };
  const getBaundProjectQnas = async() => {
    await axios.get(`${BAUND_PROJECT_QNA}`, {params})
    .then(res => {
      setBaundProjectQnaList(res.data.baundProjectQnas);
      setTotalCount(res.data.totalCount);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => {
        getBaundProjectQnas().then();
      },
      [pageNumber, answerYn, baundProjectId]
  );

  // 상세 페이지로 이동
  const moveToDetail = (baundProjectQnAId) => {
    navigate(
      `/baundProject/baundProjectQna/edit`,
      {
        state:{
          baundProjectQnAId,
          baundProjectIdForSearch: baundProjectId,
          pageNumberForSearch: pageNumber,
          answerYnForSearch: answerYn
        }
      }
    );
  }

  const handleAnswerYn = (_answerYn) => {
    setAnswerYn(_answerYn);
    setPageNumber(0);
  }

  return (<>
        <TitleText>Qna 목록</TitleText>
        <TopContainer>
          <TopWrapper jc={'flex-start'}>
            <SearchWrapper>
              <SelectWithSearch placeholder="" label="" className={'widthThin'}
                  value={projectTitle} options={projects} onChange={handleSelectSetData}
              />
            </SearchWrapper>
            <ButtonWrapper>
              <RoundButton title="답변 완료"
                 selected={answerYn === 'Y'} onClick={() => handleAnswerYn('Y')}
              />
              <RoundButton title="미답변"
                 selected={answerYn === 'N'} onClick={() => handleAnswerYn('N')}
              />
            </ButtonWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 QnA'}
                      currentPage={pageNumber}
                      pageDispatch={(e)=>setPageNumber(e-1)}
                  />
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {baundProjectQnaList.length !== 0 ? (
              <Table
                  colList={[20, 100, 30, 30, 30]}
                  thead={<>
                    <ListHead title="번호" />
                    <ListHead title="질문"/>
                    <ListHead title="사용자 pid"/>
                    <ListHead title="질문일시"/>
                    <ListHead title="답변 작성 완료 여부"/>
                  </>}
              >
                {baundProjectQnaList?.map(el =>
                  <tr key={el.baundProjectQnAId}
                      onClick={() => moveToDetail(el.baundProjectQnAId)}>
                    <td><ListItem text={el.baundProjectQnAId}/></td>
                    <td><ListItem text={el.question}/></td>
                    <td><ListItem text={el.memberPid}/></td>
                    <td><ListItem text={el.questionDt}/></td>
                    <td><ListItem text={(el.answerYn === 'Y') ? '답변 완료' : '미답변'}/></td>
                  </tr>
                )}
              </Table>
          ) : (
              <VideoModalSubText>검색 결과가 없습니다.</VideoModalSubText>
          )}
        </MainContainer>
      </>
  );
};
export default BaundProjectQnas;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    cursor: pointer;
  }
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

