import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import closeBtn from '../../../assets/images/modal/closeBtn.png';
import useOutSideClick from '../../../hooks/useOutSideClick';
import useKeyEscClose from '../../../hooks/useKeyEscClose';

interface Props {
  closeModal: () => void;
  blockCloseBtn?: boolean;
  blockOutSideClick?: boolean;
}

const VideoModal: React.FC<Props> = ({
  children,
  closeModal,
  blockCloseBtn,
  blockOutSideClick,
}) => {
  const ref = useRef(null);
  if (!blockOutSideClick) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useOutSideClick(ref, closeModal);
  }
  // esc 누르면 모달 닫힘
  useKeyEscClose(closeModal);

  return (
    <Container>
      <ModalWrapper ref={ref}>
        {!blockCloseBtn && (
          <ButtonWrapper>
            <button onClick={closeModal} type="button">
              <img src={closeBtn} alt="closeBtn" />
            </button>
          </ButtonWrapper>
        )}
        {children}
      </ModalWrapper>
    </Container>
  );
};

export default VideoModal;

const Container = styled.div`
  align-items: center;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: scroll;
  z-index: 40;
  margin-bottom: 500px;
`;

const ModalWrapper = styled.div`
  display: block;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 100;
  margin: 0 auto;
  padding: 50px 0;
`;

const ButtonWrapper = styled.div`
  display: block;
  position: absolute;
  top: 55px;
  right: 5px;

  button {
    padding: 0;
    cursor: pointer;
  }

  img {
    width: 30px;
  }
`;
