import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import CommunityCategoryEditSeqRowComponent from './CommunityCategoryEditSeqRowComponent';
import ListHead from '../../../components/UI/ListHead';

// config
import { COMMUNITY_CATEGORY } from '../../../constant/apiUrl';

const CommunityCategoryEditSeq = () => {
  const navigate = useNavigate();
  const [activeYn, setActiveYn] = useState('Y');
  const [categoryCategoryList, setCommunityCategoryList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);

  const getCommunityCategoryList = useCallback(async () => {
    const params = {
      limit: 100,
      offset: 0,
      activeYn,
    };
    try {
      const resp = await axios.get(COMMUNITY_CATEGORY, { params });
      setTotalCount(resp.data.count);
      setCommunityCategoryList(resp.data.categoryList);
    } catch (err) {
      console.log(err);
    }
  }, [activeYn]);

  const changeSequence = async () => {
    const ids = categoryCategoryList.map((el: any) => el.id);
    try {
      await axios.put(`${COMMUNITY_CATEGORY}/sequence`, { ids });
      alert('저장되었습니다.');
      navigate('/explore/communityCategory');
    } catch (err) {
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = categoryCategoryList[dragIndex];
      const hoverItem = categoryCategoryList[hoverIndex];

      setCommunityCategoryList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [categoryCategoryList],
  );

  useEffect(() => {
    getCommunityCategoryList();
  }, [getCommunityCategoryList, activeYn]);

  return (
    <Container>
      <TopContainer>
        <TitleText>커뮤니티 카테고리</TitleText>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        <SpaceDiv />
      </PaginationWrapper>
      <MainContainer>
        <TagHead>
          <ListHead title="표시 순서" />
          <ListHead title="카테고리 아이디" />
          <ListHead title="카테고리 명" />
          <ListHead title="카테고리 설명" />
        </TagHead>
        {categoryCategoryList?.map((item: any, index: number) => (
          <CommunityCategoryEditSeqRowComponent
            key={item.id}
            data={item}
            moveListItem={moveItem}
            index={index}
          />
        ))}
      </MainContainer>
    </Container>
  );
};

export default CommunityCategoryEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10fr 10fr 20fr 30fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
