// common
import exp from "constants";

export const SIGNIN = `/signin`;
export const SIGNOUT = `/signout`;
export const SIGNIN_LOAD = `/category`;
export const CATEGORY_LIST = `/category/all`;

// navigate
export const REPORT_DETAIL = '/cs/blockVideo/detail';

// member
export const SEARCH_MEMBER = `/member`;
export const ADD_MEMBER = `/member/registration`;
export const MEMBER_LOG = `/member/log/access`;
export const MEMBER_RESIGN_LOG = `/member/log/delete`;
export const MEMBER_RESTRICT_HISTORY = `/member/log`;
export const ROLE_FUNCTIONS = `/master/roles/functions`;
export const ADD_ACCOUNTS = `/accounts`;
export const OFFICIAL_ACCOUNTS = `/member/official`;

// report
export const REPORT_CONFIRM_LIST = `/report/need-confirm`;
export const REPORT_BLOCK_LIST = `/report/articles/block`;
export const REPORT_REJECT_LIST = `/report/articles/reject`;
export const EXCLUDE_ARTICLE_LIST = `/report/need-confirm/exclude/article`;
export const EXCLUDE_ARTICLE_BLOCK_LIST = `/report/exclude/articles/block`;
export const EXCLUDE_ARTICLE_REJECT_LIST = `/report/exclude/articles/reject`;
export const MASTER_REPORT = `/master/report`;
export const REKOGNITION_LIST = `/articles/need-confirm`;
export const REKOGNITION_BLOCK_LIST = `/articles/blocked`;
export const REKOGNITION_REJECT_LIST = `/articles/unblocked`;
export const REPORT_CONTROL = `/report`;
export const REPORT_TYPE_CHANGE = `/report/log`;
export const REPORT_MANAGEMENT_LIST = `/master/report`;
export const RESTRICT_MEMBER = `/member/restrict/date`;

// contents
export const CONTENTS_ARTICLE = `/contents/article`;
export const CONTENTS_ARTICLE_COMMENT = `/contents/article/comment`;
export const CONTENTS_ARTICLE_SUB_COMMENT = `/contents/article/comment/sub`;
export const CONTENTS_BEAT_COMMENT = `/contents/beat/comment`;
export const CONTENTS_BEAT_SUB_COMMENT = `/contents/beat/comment/sub`;
export const CONTENTS_MEMBER_COLLECTION = `/contents/member/collection`;
export const CONTENTS_MEMBER = `/contents/member`;
export const CONTENTS_HASHTAG = `/contents/tags`;
export const CONTENTS_COMMUNITY_BOARD = `/contents/community-board`;
export const CONTENTS_COMMUNITY_BOARD_COMMENT = `/contents/community-board/comment`;
export const CONTENTS_COMMUNITY_BOARD_SUB_COMMENT = `/contents/community-board/comment/sub`;

// article(video)
export const ARTICLE = `/articles`;
export const ARTICLE_REGISTER = `/articles/register`;
export const ARTICLE_CONFIRM_LIST = `/articles/need-confirm`;
export const ARTICLE_BLOCK_LIST = `/articles/blocked`;
export const MASTER_REKOGNITION_LIST = `/master/rekognition`;

// beat
export const BEAT_DETAIL = `/beat`;
export const BEAT_LIST = `/beat/list`;
export const BEAT_SAVE = `/beat/save`;

// beatMaker
export const BEATMAKER_DETAIL = `/beat-maker`;
export const BEATMAKER_LIST = `/beat-maker/list`;
export const BEATMAKER_SAVE = `/beat-maker/save`;

// event
export const EVENT_LIST = `/events`;
export const EVENT_COPY = `/events/copy`;
export const EVENT_PROMOTION = `/events/promotion`;
export const EVENT_DEFAULT_URL = `/events/default/url`;

// explore - totalTab
export const EXPLORE = `/explore`;
export const RECENTLY_BEAT = `/explore/master-recently-beat`;
export const RECOMMEND_GENRE = `/explore/recommend/genre`;
export const RECOMMEND_HASHTAG = `/explore/tags`;
export const RECOMMEND_BEATMAKER = `/explore/recommend/beat-maker`;
export const UPCOMING_BEAT = `/explore/upcoming-beat`;
export const UPCOMING_BEAT_LIST = `/explore/beat/upcoming`;
export const COLLECTION = `/explore/recommend/member/collection`;
export const DETAIL_COLLECTION = `/explore/recommend/member/collection/detail`;
export const MEMBER_COLLECTION_LIST = `/contents/member-collection`;
export const HOT_BEAT = `/explore/hot-beat`;

// explore - genreTab
export const TAB_GENRE = '/explore/tab-genre';
// explore - moodTab
export const TAB_MOOD = '/explore/tab-mood';

// explore - collection
export const OFFICIAL_COLLECTION = `/explore/collection/official`;

// notice
export const NOTICE = `/notices`;

// master
export const GENRE_LIST = `/master/genre`;
export const PARENT_GENRE_LIST = `/master/genre/parents`;
export const CHILD_GENRE_LIST = `/master/genre/parents/child`;
export const MOOD_LIST = `/master/mood`;
export const MASTER_ROLE_LIST = `/master/roles`;
export const MASTER_ACCOUNT_REASON = '/master/account-disable-reason';
export const CHANGE_CATEGORY_ROLE = '/master/roles';

// setting
export const APP_VERSION = `/master/version`;
export const FAQ_LIST = `/setting/faq`;
export const BANNED_WORD = `/setting/banned/words`;
export const RESERVATION = `/setting/reservations`;
export const MAINTENANCE = `/setting/maintenance`;

// system
export const SERVER_REFRESH = `/refresh`;

// studio
export const FILTER = `/studio/camera-filter`;
export const FILTER_TYPE = `/studio/camera-filter-type`;

// push
export const PUSH = `/push`;
export const INDIVIDUAL_PUSH = `/individual-push`;

// baund-music
export const BAUND_MUSIC_LIST = `/baundMusic/list`;
export const BAUND_MUSIC = `/baundMusic`;
export const BAUND_MUSIC_SAVE = `/baundMusic/save`;
export const BAUND_MUSIC_PROJECT = `/baund-music-project`;

// explore contents
export const EXPLORE_CONTENTS_LIST = `/explore/contents/baundMusic/list`;
export const EXPLORE_CONTENTS = `/explore/contents`;

// community
export const COMMUNITY_CATEGORY = `/community/board/categories`;
export const COMMUNITY_FIXED_ARTICLE = `/community/board/fixed-article`;
export const COMMUNITY_BOARD_ARTICLE = `/community/board/article`;
export const COMMUNITY_BOARD_BANNER = `/community/board/banner`;

export const CULTURE_CHANNEL = `/culture/channel`;
export const CULTURE_PROGRAM = `/culture/program`;
export const CULTURE_EPISODE = `/culture/episode`;

export const COMMUNITY_CURATED_ARTICLE = `/curated-contents`;

export const ADMIN_CATEGORY = `/category`;

export const ADMIN_BAUND_API_CATEGORY = '/baund-api/category';
export const ADMIN_BAUND_API_API = '/baund-api/api';

// 공연
export const PERFORMANCE = '/performance';                    // 공연 저장 및 수정
export const PERFORMANCE_DETAIL = '/performance/detail';      // 공연 상세
export const PERFORMANCE_ONGOING = '/performance/ongoing';    // 진행중인 공연 리스트
export const PERFORMANCE_BYGONE = '/performance/bygone';      // 지났거나 비공개 공연 리스트

export const PERFORMANCE_CATEGORY = '/performance-category';                    // 공연 카테고리 저장 및 수정
export const PERFORMANCE_CATEGORY_SEQUENCE = '/performance-category/sequence';  // 공연 카테고리 저장 및 수정
export const PERFORMANCE_CATEGORY_DETAIL = '/performance-category/detail';      // 공연 카테고리 상세
export const PERFORMANCE_CATEGORY_ACTIVE = '/performance-category/active';      // 공연 카테고리 활성 리스트
export const PERFORMANCE_CATEGORY_INACTIVE = '/performance-category/inactive';  // 공연 카테고리 비활성 리스트

// 재화-미션
export const REWARDS = '/rewards';
export const MASTER_REWARD_API = '/master/reward';
export const REWARD_MASTER = '/rewards/master';
export const REWARD_CURRENCY_GIVE = '/rewards/currency/give';
export const MISSIONS = '/rewards/missions';
export const MISSIONS_POINT_GUIDE_AREA = '/rewards/missions/point/guide/area';

// 골드 캠페인
export const CAMPAIGNS = '/campaigns';
export const CAMPAIGNS_EXPOSE_UPPER = '/campaigns/expose/upper';
export const CAMPAIGNS_SET_WINNER = '/campaigns/setWinner';
export const CAMPAIGNS_COPY = '/campaigns/copy';

// battle
export const BATTLE = '/battle';
export const BATTLE_SIMPLE = '/battle-simple';

// 데이터 관리
export const DATAS_MASTER_MESSAGE = '/master/message';

// 둘러보기 배너
export const EXPLORE_BANNER = '/explore/banner';

// 매거진
export const MAGAZINE = `/magazine`;
export const MAGAZINE_CATEGORY = `/magazine-category`;
export const MAGAZINE_CATEGORY_SEQUENCE = `/magazine-category/sequence`;
export const MAGAZINE_CATEGORY_DETAIL = `/magazine-category/detail`;

// coupon
export const COUPONS = '/coupons';
export const COUPONS_PROJECTS = '/coupons/projects';

// 광고
export const ADVERTISER = '/advertiser';  //광고주
export const ADVERTISEMENT = '/advertisement/summary'; // 광고
export const ADVERTISEMENT_CAMPAIGN = '/advertisement/campaign'; // 광고 캠페인
export const ADVERTISEMENT_LANDING = '/advertisement/landing'; // 광고 랜딩

// baundProject
export const BAUND_PROJECT = "/baund-project";
export const BAUND_PROJECT_CAUTION = "/baund-project-caution";
export const BAUND_PROJECT_FAQ = "/baund-project-faq";
export const BAUND_PROJECT_QNA = "/baund-project-qna";
export const BAUND_PROJECT_PARTICIPANT = "/baund-project-participant";
