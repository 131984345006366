import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../components/common/Button/NormalButton';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TagTextInput from '../../components/common/Input/TagTextInput';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import ListDateSelect from '../../components/common/Select/ListDateSelect';

// 타입
import { RowComponentDataProps } from '../../definitions/beat';

// hooks
import { useAppSelector } from '../../redux/store/hooks';
import { beatPrivateYnOptions, genreBeatColList } from '../../constant/beat';

// config
import { MOOD_LIST } from '../../constant/apiUrl';

import GenreRowComponent from './components/GenreRowComponent';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';

const AddMood = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const detailMood = useAppSelector((state) => state.mood.detailMood);
  const [moodName, setMoodName] = useState('');
  const [keyWord, setKeyword] = useState<any>(null);
  const [addWord, setAddWord] = useState('');
  const [moodBeatList, setMoodBeatList] = useState<any>([]);
  const [minStartDate, setMinStartDate] = useState<string>('');
  const [maxStartDate, setMaxStartDate] = useState<string>('');
  const [privateYn, setPrivateYn] = useState<string>('');

  const handleSetMood = (e: any) => {
    const { value } = e.target;
    setMoodName(value);
  };

  const handleResetMood = () => {
    setMoodName('');
  };

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  // 검색 키워드 변경 확인
  const checkKeyWordChange = () => {
    return (
      JSON.stringify(
        detailMood.searchKeywords !== null
          ? detailMood.searchKeywords.split(',')
          : detailMood.searchKeywords,
      ) !== JSON.stringify(keyWord)
    );
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // 무드 추가하기
  const handleAddMood = async () => {
    const moodData = { moodName, searchKeywords: keyWord || [] };
    try {
      dispatch(setLoading());
      await axios.post(MOOD_LIST, moodData, {});
      await alert('무드가 등록되었습니다.');
      await goBack();
    } catch (error: any) {
      console.log(error.response.data);
      await alert('무드 이름을 작성해주세요.');
    }
    dispatch(resetLoading());
  };

  // 무드 수정하기
  const onChangeMood = async () => {
    const editData = {
      moodName,
      searchKeywords: keyWord,
    };
    try {
      dispatch(setLoading());
      await axios.post(`${MOOD_LIST}/${detailMood.id}`, editData, {});
      await alert('무드가 수정되었습니다.');
      await goBack();
    } catch (error: any) {
      console.log(error.response.data);
    }
    dispatch(resetLoading());
  };

  // 무드 삭제하기
  const onDeleteMood = async () => {
    try {
      await axios.delete(`${MOOD_LIST}/${detailMood.id}`, {});
      await alert('무드가 삭제되었습니다.');
      await goBack();
    } catch (error: any) {
      console.log(error.response.data);
    }
  };

  // 무드에 따른 비트리스트 불러오기
  const getMoodBeatList = useCallback(async () => {
    const params = {
      type: 'MOOD',
      word: detailMood?.moodName,
      offset: 0,
      limit: 1000,
      maxStartDate,
      minStartDate,
      privateYn,
    };
    try {
      const response = await axios.get('/beat/list', { params });
      setMoodBeatList(response.data.beatList);
    } catch (error: any) {
      // console.log(error);
    }
  }, [detailMood?.moodName, maxStartDate, minStartDate, privateYn]);

  useEffect(() => {
    setMoodName(detailMood?.moodName);
    if (detailMood?.searchKeywords) {
      const keywords = detailMood.searchKeywords.split(',');
      setKeyword(keywords);
    }
    getMoodBeatList();
  }, [detailMood, getMoodBeatList]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          {detailMood.id ? (
            <>
              <MoodTitle>{detailMood.moodName}</MoodTitle>
              {detailMood.moodName === moodName && !checkKeyWordChange() ? (
                <ButtonWrapper>
                  <NormalButton
                    text="무드 삭제하기"
                    onSubmit={onDeleteMood}
                    className="cancelBtn"
                  />
                </ButtonWrapper>
              ) : (
                <ButtonWrapper>
                  <NormalButton
                    text="취소"
                    onSubmit={goBack}
                    className="cancelBtn"
                  />
                  <NormalButton text="수정하기" onSubmit={onChangeMood} />
                </ButtonWrapper>
              )}
            </>
          ) : (
            <>
              <div />
              <ButtonWrapper>
                <NormalButton
                  text="취소"
                  onSubmit={goBack}
                  className="cancelBtn"
                />
                <NormalButton
                  text="저장하기"
                  onSubmit={handleAddMood}
                  className=""
                />
              </ButtonWrapper>
            </>
          )}
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="moodName"
            label="무드명"
            value={moodName}
            placeholder="무드를 입력해주세요."
            onChange={handleSetMood}
            onReset={handleResetMood}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TagTextInput
            placeholder="키워드를 입력해주세요."
            label="검색 키워드"
            keyWord={keyWord || []}
            setKeyWord={setKeyword}
            addWord={addWord}
            setAddWord={setAddWord}
            Required
          />
        </InputWrapper>

        {detailMood?.id && (
          <InputWrapper>
            <Table
              colList={genreBeatColList}
              thead={
                <>
                  <ListHead title="Cover" />
                  <ListHead title="Title" />
                  <ListHead title="Pid" />
                  <ListHead title="장르" />
                  <ListSelect
                    title="공개 여부"
                    list={beatPrivateYnOptions}
                    onClick={handleSelect}
                    setState={setPrivateYn}
                  />
                  <ListDateSelect
                    title="공개 날짜"
                    setMinStartDate={setMinStartDate}
                    setMaxStartDate={setMaxStartDate}
                  />
                </>
              }
            >
              {moodBeatList?.map((el: RowComponentDataProps) => (
                <GenreRowComponent key={el.id} data={el} />
              ))}
            </Table>
          </InputWrapper>
        )}
      </MainContainer>
    </>
  );
};

export default AddMood;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 30px;
`;

const MoodTitle = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  letter-spacing: -0.3px;
  line-height: 35px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;
`;
