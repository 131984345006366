import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import RoundButton from '../../../../components/common/Button/RoundButton';
import NormalButton from '../../../../components/common/Button/NormalButton';
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import NotExposure from '../../../../components/UI/NotExposure';
import UpcomingBeatRowComponents from './UpcomingBeatRowComponents';
import Pagination from '../../../../components/common/Pagination/index';

// config
import { UPCOMING_BEAT } from '../../../../constant/apiUrl';

// constant
import { upComingColList } from '../../../../constant/explore';
import { postsPerPage } from '../../../../constant/common';

const UpcomingBeat = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const location = useLocation();
  const { detailData = {}, tabType = 'ALL' }: any = location.state || {};
  const [listType, setListType] = useState(true);
  const [upcomingBeatList, setUpcomingBeatList] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const getUpcomingBeatList = useCallback(
    async (type: boolean) => {
      const params = {
        limit: postsPerPage,
        offset: pageNumber * postsPerPage,
        type: '',
      };
      try {
        if (!type) {
          params.type = 'disable';
        }
        if (tabType === 'GENRE') {
          const resp = await axios.get(
            `${UPCOMING_BEAT}/${detailData?.masterGenreId}`,
            { params },
          );
          setUpcomingBeatList(resp.data.masterRecommendUpcomingBeatList);
          setTotalCount(resp.data.count || 0);
        }
        if (tabType === 'ALL') {
          const resp = await axios.get(UPCOMING_BEAT, { params });
          setUpcomingBeatList(resp.data.masterRecommendUpcomingBeatList);
          setTotalCount(resp.data.count || 0);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [detailData?.masterGenreId, pageNumber, tabType],
  );

  useEffect(() => {
    getUpcomingBeatList(listType);
  }, [getUpcomingBeatList, listType]);

  useEffect(() => {
    if (getNumber) {
      window.localStorage.setItem('pageNumber', '0');
    }
  }, [getNumber]);

  return (
    <>
      <TopContainer>
        <div>
          <RoundButton
            title="공개"
            selected={listType}
            onClick={() => {
              setListType(true);
            }}
          />
          <RoundButton
            title="비공개"
            selected={!listType}
            onClick={() => {
              setListType(false);
            }}
          />
        </div>
        {upcomingBeatList && (
          <NormalButton
            text="비트 추가하기"
            onSubmit={() => {
              if (Object.keys(detailData).length !== 0) {
                navigate('add', {
                  state: { masterGenreId: detailData.masterGenreId },
                });
              } else {
                navigate('add', { state: {} });
              }
              // {state:}
            }}
          />
        )}
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      {upcomingBeatList?.length !== 0 && upcomingBeatList !== undefined ? (
        <MainContainer>
          <Table
            colList={upComingColList}
            thead={
              <>
                <ListHead title="번호" />
                <ListHead title="cover" />
                <ListHead title="Title" />
                <ListHead title="시작일" />
                <ListHead title="종료일" />
                <ListHead title="" />
              </>
            }
          >
            {upcomingBeatList?.map((el: any) => (
              <UpcomingBeatRowComponents
                key={el.id}
                data={el}
                genreTabData={{ detailData, tabType }}
              />
            ))}
          </Table>
        </MainContainer>
      ) : (
        <NotExposure
          title="발매 예정 비트"
          link="add"
          onSubmit={() => {
            if (Object.keys(detailData).length !== 0) {
              navigate('add', {
                state: { masterGenreId: detailData.masterGenreId },
              });
            } else {
              navigate('add', { state: {} });
            }
          }}
        />
      )}
    </>
  );
};

export default UpcomingBeat;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const MainContainer = styled.div`
  margin-top: 50px;
`;
