import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';

// config
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import AccountRowComponent from "../components/AccountRowComponent";
import Modal from "../../../components/common/Modal/Modal";
import MultiSelect from "../../../components/common/Select/MultiSelect";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import {ADD_ACCOUNTS, MASTER_ROLE_LIST} from "../../../constant/apiUrl";
import {adminColList} from "../../../constant/admin";
import {useDispatch} from "react-redux";

//초기 계정 데이터
const initialAccountData = {id: '', roles: []};

const MemberOfRole = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const detailData = location.state;
  const userRole = detailData?.userRole;

  const [adminAccountList, setAdminAccountList] = useState([]);

  // 해당 롤을 갖는 어드민 계정 정보 조회
  const params = {limit: 100, offset: 0, activeYn: "Y", role: userRole};
  const getAccountList = async () => {
    await axios.get(`/accounts`, {params,})
    .then(res =>{
      setAdminAccountList(res.data.adminAccountList);
    })
    .catch(reason => {
      console.error(reason);
      alert('잠시후 다시 이용해주세요.');
    });
  };
  useEffect(() => { getAccountList().then(r => r); }, []);

  const editAccount = (e) => {
    const { activeYn, roles, id } = e;
    setAccountData({...accountData, roles, id,activeYn,});
    setModalOpen(true);
  };

  // 모달 영역 -----------------------------------------------------------------
  const [adminRoleList, setAdminRoleList] = useState([]);
  const accountRoleList = adminRoleList?.map((el) => el.userRole);
  const [modalOpen, setModalOpen] = useState(false);
  const [accountData, setAccountData] = useState(initialAccountData);
  const { id, roles, activeYn } = accountData;

  // 어드민 권한 리스트 불러오기
  const getAdminRoleList = useCallback(async () => {
    const params = {limit: 100, offset: 0,};
    await axios.get(MASTER_ROLE_LIST, { params })
    .then(res => {
      setAdminRoleList(res.data.adminRoleList);
    })
    .catch(reason => {
      console.error(reason);
      alert('잠시후 다시 이용해주세요.');
    });
  }, []);
  useEffect(() => {
    getAdminRoleList().then(r => r);
  }, [getAdminRoleList]);

  const handleMultiSetData = useCallback((e) => {
    const duplicateCheck = roles?.indexOf(e);
    if (duplicateCheck !== -1) {
      const removeOption = roles?.filter((el) => el !== e);
      setAccountData({...accountData, roles: removeOption,});
      return;
    }
    setAccountData({...accountData, roles: roles.concat(e),});
  }, [accountData, roles],);

  // 관리자 활성화 및 권한 수정
  const changeActive = async () => {
    const adminRole = adminRoleList.filter((el) => {
      return roles.includes(el.userRole);
    });
    const adminAccountRolesActiveYnUpdateRequest = {
      activeYn,
      roleIds: adminRole?.map((el) => el.id),
    };
    console.log(adminAccountRolesActiveYnUpdateRequest);
    dispatch(setLoading());
    await axios.post(
        `${ADD_ACCOUNTS}/${id}/active`,
        adminAccountRolesActiveYnUpdateRequest,
        {},
    )
    .then(()=>{
      setModalOpen(false);
      alert('계정이 수정되었습니다.');
      window.location.reload();
      getAccountList();
    })
    .catch(reason => {
      console.error(reason);
      alert('잠시후 다시 이용해주세요.');
    })
    .finally(() => dispatch(resetLoading()))
  };

  return (
    <>
      <TopContainer>
        <TitleWrapper>{userRole}</TitleWrapper>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <Table
            colList={adminColList}
            thead={
              <>
                <ListHead title="이름" />
                <ListHead title="이메일" />
                <ListHead title="휴대폰 번호" />
                <ListHead title="지역" />
                <ListHead title="권한"/>
                <ListHead title="활성화 여부"/>
                <ListHead title="" />
              </>
            }
          >
          {adminAccountList?.map((el) => (
            <AccountRowComponent key={el.id} data={el} onClick={editAccount} />
          ))}
          </Table>
        </InputWrapper>
      </MainContainer>
      {modalOpen && (
        <Modal closeModal={()=>setModalOpen(false)}>
          <InputContainer>
            <InputWrapper mb={20}>
              <MultiSelect
                name="roles" label="권한" placeholder="권한을 선택해주세요."
                value={roles} onChange={handleMultiSetData} options={accountRoleList}
              />
            </InputWrapper>
            <InputWrapper>
              <NormalButton text="저장하기" onSubmit={changeActive} />
            </InputWrapper>
          </InputContainer>
        </Modal>
      )}
    </>
  );
};
export default MemberOfRole;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const TitleWrapper = styled.div`
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
  margin: 0 94px;
`;

