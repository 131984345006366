import React, {useState, useEffect, useCallback, useRef} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import ListHead from '../../../components/UI/ListHead';

// config
import ListSelect from "../../../components/common/Select/ListSelect";
import {
  eventActiveYnOptions,
  eventCountryTypeList, eventLanguageTypeList
} from "../../../constant/event";
import {useDrag, useDrop} from "react-dnd";
import dragAndDrop from '../../../assets/images/page/dragAndDrop.png';
import ListItem from "../../../components/common/List/ListItem";
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import handleDate from "../../../hooks/handleDate";
import {MAGAZINE} from "../../../constant/apiUrl";
import {
  GetCountryOption,
  GetLanguageOption,
  IsActive
} from "../../../constant/common";

const MagazineEditSeq = () => {
  const navigate = useNavigate();
  const [contentsList, setContentsList] = useState([]);
  const [activeYn, setActiveYn] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');

  // 정렬을 위한 공지 정보 조회
  const getNoticesForEditSeq = async () => {
    const params = {
      limit: 100,
      offset: 0,
      activeYn,
      countryCode: country,
      language,
    };
    await axios.get(`${MAGAZINE}/edit-sequence`, {params})
    .then(resp => setContentsList(resp.data.magazineList))
    .catch(reason => {
      console.error(reason);
      alert('시스템 관리자에게 문의해 주세요');
    });
  };

  // 순서 변경
  const changeSequence = async () => {
    const ids = contentsList.map((el) => el.id);
    await axios.put(
        `${MAGAZINE}/sequence`,
        {ids}
    )
    .then( () => {
      // console.log(resp);
      alert('저장되었습니다.');
      navigate(-1);
    })
    .catch ( reason => {
      console.error(reason);
      alert('시스템 관리자에게 문의해 주세요');
    });
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
      (dragIndex, hoverIndex) => {
        const dragItem = contentsList[dragIndex];
        const hoverItem = contentsList[hoverIndex];

        setContentsList((tagList) => {
          const updatedPets = [...tagList];
          updatedPets[dragIndex] = hoverItem;
          updatedPets[hoverIndex] = dragItem;
          return updatedPets;
        });
      },
      [contentsList],
  );

  // type, bpm, privateUse, openDate
  const handleSelect = (e, setState) => setState(e.currentTarget.title);

  useEffect(() => {
    getNoticesForEditSeq().then(r => r);
  }, [activeYn, country, language]);

  return (
      <Container>
        <TopContainer>
          <TitleText>노출 순서 변경</TitleText>
          <ButtonWrapper>
            <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
            <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
          </ButtonWrapper>
        </TopContainer>
        <PaginationWrapper>
          <SpaceDiv />
        </PaginationWrapper>
        <MainContainer>
          <TagHead>
            <ListHead title="" />
            <ListHead title="표시순서" />
            <ListHead title="번호"/>
            <ListHead title="이미지"/>
            <ListHead title="타이틀"/>
            <ListSelect
                title="공개여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setActiveYn}
            />
            <ListHead title="시작일"/>
            <ListHead title="종료일"/>
            <ListSelect
                title="국가"
                list={eventCountryTypeList}
                onClick={handleSelect}
                setState={setCountry}
            />
            <ListSelect
                title="언어"
                list={eventLanguageTypeList}
                onClick={handleSelect}
                setState={setLanguage}
            />
          </TagHead>
          {contentsList?.map((item, index) => (
              <MagazineEditSeqRowComponent
                  key ={item.id}
                  data={item}
                  moveListItem={moveItem}
                  index={index}
              />
          ))}
        </MainContainer>
      </Container>
  );
};
export default MagazineEditSeq;

const MagazineEditSeqRowComponent = ({key, data, moveListItem, index}) => {
  const componentRef = useRef(null);
  const {
    id,
    sequence,
    bannerImgUrl,
    activeYn,
    countryCode,
    startDt,
    endDt,
    language,
    title
  } = data;
  const [, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element, monitor) => {
      // const dragIndex = item.index;
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
          (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
          (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
      (node) => {
        componentRef.current = node;
        dragRef(dropRef(node));
      },
      [dragRef, dropRef],
  );
  return (
      <Row ref={applyRef} key={key}>
        <img src={dragAndDrop} alt="dnd icon" />
        <ListItem text={sequence} />
        <ListItem text={id} />
        <ListCoverImg url={bannerImgUrl} className="event" />
        <ListItem text={title || '-'} />
        <ListItem text={IsActive[activeYn]} />
        <ListItem text={handleDate(startDt)} />
        <ListItem text={handleDate(endDt)} />
        <ListItem text={GetCountryOption[countryCode]} />
        <ListItem text={GetLanguageOption[language]} />
      </Row>
  );
};

const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 80fr 80fr 110fr 110fr 80fr 110fr 110fr 80fr 80fr;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};
  }
`;


const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 80fr 80fr 110fr 110fr 80fr 110fr 110fr 80fr 80fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
