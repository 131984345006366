import activeCloseButton from "../../assets/images/page/largeCloseButton.png";
import activePlusButton from "../../assets/images/page/purplePlusIcon.png";
import inactivePlusButton from "../../assets/images/page/plusIcon.png";
import styled from "styled-components";
import React, {Fragment, useEffect} from "react";
import LabelTextInput from "./Input/LabelTetxInput";

/**
 * 한/영 셋트 값을 갖는 속성 목록
 * @param name 상단 소제목 영역과 입력 유도 문구에 사용될 항목 명
 * @param list 데이터
 * @param listSetter 데이터 setter 함수
 * @param readOnly 항목 입력란 과 추가/삭제 버튼 비활성화
 * @param keyPrefix 키 접두어
 * @param propertyNameKr 국문 속성명
 * @param propertyNameEn 영문 속성명
 * @param propertyNameCommon 삭제 시도시 컨펌 창에 표시되는 항목 명
 * @param copyKR2EN 한글/영문 동일값 사용 여부
 * @param actionButton 테이블 영역 내에서 특정 액션 버튼
 * @returns {JSX.Element}
 * @constructor
 */
const BilingualPropertyTable = ({
  name,
  list,
  listSetter,
  readOnly,
  keyPrefix,
  propertyNameKr,
  propertyNameEn,
  propertyNameCommon,
  copyKR2EN,
  actionButton
}) => {

  // row 추가
  const addRow = () => {
    const work = {};
    work[propertyNameKr] = '';
    work[propertyNameEn] = '';
    listSetter([...list, work]);
  }

  // 국문 속성값 추가
  const changeKoreanValue = (index, value) => {
    // 국문 속성 설정
    changePropetyValue(index, propertyNameKr, value);
    // 한글/영문 동일값 사용인 경우 영문에 동일 값 설정
    copyKR2EN && changePropetyValue(index, propertyNameEn, value);
  }

  // row 삭제
  const deleteRow = (index) => {
    if (!confirm(`${propertyNameCommon}을 삭제하시겠습니까? 해당 ${propertyNameCommon}이 목록에서 사라지며, 삭제 이후 복구가 어렵습니다. `)) {
      return;
    }
    const _list = [...list];
    _list.splice(index, 1);
    listSetter(_list);
  }

  // 영문 속성 값 추가
  const changeEnglishValue = (index, value) => {
    changePropetyValue(index, propertyNameEn, value);
  }

  // 속성 값 변경
  const changePropetyValue = (index, propertyName, value) => {
    const _list = [...list];
    _list[index][propertyName] = value;
    listSetter(_list);
  }

  // 호출 측에서 한글/영문 동일값 사용 변경시
  useEffect(() => {
    if(copyKR2EN) {
      const _list = [...list];
      _list.forEach(el => {
        // 국문 값을 영문 값에 복사
        el[propertyNameEn] = el[propertyNameKr];
      });
      listSetter(_list);
    }
  }, [copyKR2EN]);

  return <TableContainer>
    <TableRow colSpan={2} mt={5} mb={5}>
      <TableTitle>{name}</TableTitle>
      {actionButton}
    </TableRow>
    <TableRow>
      {/* 읽기 전용 일때는 추가 불가 */}
      <TableButton className={'add'} disabled={readOnly} onClick={addRow} />
    </TableRow>
    {list?.map((data, index) => <Fragment key={`${keyPrefix}_${index}`}>
      <TableRow mt={-5}>
        <LabelTextInput readOnly={readOnly}
            label={""} name={`${data[propertyNameKr]}_${index}`}
            placeholder={`${name}을 입력해주세요.`} value={data[propertyNameKr]}
            onChange={(e) => {
              changeKoreanValue(index, e.target.value);
            }}
        />
      </TableRow>
      <TableRow mt={-5}>
        <LabelTextInput readOnly={(readOnly || copyKR2EN)}
          label={""} name={`${data[propertyNameEn]}_${index}`}
          placeholder={`${name}(EN)을 입력해주세요.`} value={data[propertyNameEn]}
          onChange={e => {
            changeEnglishValue(index, e.target.value);
          }}
        />
      </TableRow>
      <TableRow mt={11}>
        {/* 읽기 전용 일때는 삭제 불가 */}
        <TableButton className={'delete'} disabled={readOnly} onClick={() => deleteRow(index)}/>
      </TableRow>
    </Fragment>)}
    {list.length === 0 && (
        <TableRow colSpan={3} mt={-5}>
          <LabelTextInput
            label={""} name={`noRow`} readOnly={true}
            placeholder={`작성된 ${name}이 없습니다.`} value={''}
          />
        </TableRow>
    )}
  </TableContainer>;
};
export default BilingualPropertyTable;

const TableContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 8fr 8fr 1fr;
  gap: 5px 10px;
  grid-gap: 5px 10px;
`;

const TableRow = styled.div`
  grid-row: ${({rowSpan}) => rowSpan && `span ${rowSpan}`};
  grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  input {
    padding: 0 20px 0 20px;
  }
  input:read-only {
    color: ${({ theme }) => theme.color.subGray};
  }
  display:inline;
  vertical-align: center;
`;

const TableTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-right: 10px;
  display: inline;
`;

const TableButton = styled.button`
  display: block;
  width: 45px;
  height: 45px;
  margin-left: 10px;
  background: url(${({ value }) => value}) center no-repeat;
  background-size: 45px;
  cursor: pointer;
  // 읽기 전용일때는 클릭 불가
  :disabled {
    cursor: not-allowed;
  }
  
  &.add {
    background: url(${activePlusButton}) center no-repeat;
    background-size: 45px;
  }
  &.add:disabled {
    background: url(${inactivePlusButton}) center no-repeat;
    background-size: 45px;
  }
  &.delete {
    background: url(${activeCloseButton}) center no-repeat;
    background-size: 45px;
  }
  &.delete:disabled {
    background: url(${activeCloseButton}) center no-repeat;
    background-size: 45px;
    -webkit-filter: opacity(25%);
  }
`;