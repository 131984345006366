import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ko } from 'date-fns/esm/locale';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';

// hooks
import useOutSideClick from '../../../hooks/useOutSideClick';
import getUtcTime from '../../../hooks/getUtcTIme';

const ListDateSelect = ({
  title,
  setMinStartDate,
  setMaxStartDate,
  width,
}: any) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any, e: any) => {
    e.stopPropagation();
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setMinStartDate(getUtcTime(start, 'start'));
    if (end) {
      setMaxStartDate(getUtcTime(end, 'end'));
    }
  };

  const changeDate = (date: Date) => {
    const arr = date.toLocaleDateString().split('. ');
    const year = arr[0].slice(2, 4);
    const month = arr[1];
    const day = arr[2].replace('.', '');
    return `${year}-${month}-${day}`;
  };
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => {
    setIsOpen(true);
  };

  // 외부 클릭시 모달 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  useEffect(() => {
    setIsOpen(false);
  }, [endDate]);

  return (
    <TableHead key={width}>
      <MenuList
        onClick={() => {
          toggling();
        }}
        ref={ref}
      >
        <OptionMenu className="option-menu">
          {startDate !== null ? (
            <>
              {endDate !== null ? (
                <TitleOption>
                  {changeDate(startDate)} ~ {changeDate(endDate)}
                </TitleOption>
              ) : (
                <TitleOption>{changeDate(startDate)} ~</TitleOption>
              )}
            </>
          ) : (
            <>{title}</>
          )}
        </OptionMenu>
        {isOpen && (
          <DropDownList>
            <DateDate
              locale={ko}
              dateFormat="yyyy.MM.dd"
              selected={startDate}
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange
              shouldCloseOnSelect={false}
              inline
            />
          </DropDownList>
        )}
      </MenuList>
    </TableHead>
  );
};

export default ListDateSelect;

const TableHead = styled.th`
  width: ${(props) => props.key};
  padding: 10px 0 18px;
  background-color: white;
`;

const MenuList = styled.div`
  display: block;
  position: relative;
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ theme }) => theme.color.subBrightGray};
  z-index: 1;

  &.option-menu {
    padding-right: 32px;
    background: url(${bottomArrow}) calc(100% - 6px) center no-repeat
      ${({ theme }) => theme.color.subBrightGray};
    background-size: 22px;
  }
`;

const DropDownList = styled.div`
  position: absolute;
  right: 0;
  top: 12px;
  margin: 26px 0 4px;
  background: #ffffff;
  box-shadow: ${({ theme }) => theme.boxShadow.default};
  border-radius: 6px;
  color: ${({ theme }) => theme.color.mainBlack};
  z-index: 5;
`;

const DateDate = styled(DatePicker)``;

const TitleOption = styled.span`
  color: ${({ theme }) => theme.color.mainPurple};
`;
