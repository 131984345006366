import React from 'react';
import styled from 'styled-components';

//컴포넌트
import ListItem from '../../../components/common/List/ListItem';

// 타입
import { RowComponentDataProps } from '../../../definitions/beat';
import { CustomProps } from '../../../definitions/common';

// 인터페이스
interface dataProps {
  data: RowComponentDataProps;
}

const RestrictMemberRowComponent = ({ data }: dataProps) => {
  const { adminName, postRestrictDt, preRestrictDt, restrictedDt } = data;

  const handleDate = (beforeDt: number, afterDt: number) => {
    const now = new Date();
    const before = new Date(beforeDt);
    const after = new Date(afterDt);
    let dateDiff = Math.ceil(
      (after.getTime() - before.getTime()) / (1000 * 3600 * 24),
    );
    if (beforeDt === null || now > before) {
      dateDiff = Math.ceil(
        (after.getTime() - now.getTime()) / (1000 * 3600 * 24),
      );
    }
    if (dateDiff > 100000) {
      return '영구제재';
    }
    if (dateDiff < -100000) {
      return '영구제재 해제';
    }

    return `${dateDiff}일`;
  };

  return (
    <tr>
      <td>
        <ListItem text={restrictedDt.slice(0, 10)} />
      </td>
      <td>
        <ListItem text={adminName} />
      </td>
      <td>
        <Item>
          <Strong>{handleDate(preRestrictDt, postRestrictDt)}</Strong>
          {!preRestrictDt
            ? `( ~ ${postRestrictDt?.slice(0, 10)})`
            : `(${preRestrictDt?.slice(0, 10)} ~ ${postRestrictDt?.slice(
                0,
                10,
              )})`}
        </Item>
      </td>
    </tr>
  );
};

export default RestrictMemberRowComponent;

const Strong = styled.span`
  font-weight: 700;
`;

const Item = styled.span<CustomProps>`
  word-break: keep-all;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: ${({ type }) => (type === 'title' ? '600' : '400')};
  color: ${({ theme }) => theme.color.mainBlack};
`;
