import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';
import axios from 'axios';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';

//hooks
import { useAppSelector } from '../../../redux/store/hooks';

// constants
import {
  getReportTypeOption,
  reportTypeOptions,
} from '../../../constant/operation';

// actions
import { getManagementList } from '../../../redux/actions/customService/customService';
import { setLoading, resetLoading } from '../../../redux/slices/user';

// config
import { MASTER_REPORT, REPORT_MANAGEMENT_LIST } from '../../../constant/apiUrl';

type data = {
  [key: string]: any;
};

//초기 비트 데이터
const initialReportData: data = {
  activeYn: 'Y',
  contentsType: '',
  description: '',
  language: 'ko',
  name: '',
  reportPoint: null,
  sequence: null,
  masterReportDetailList: {
    activeYn: 'Y',
    language: 'ko',
    reportPoint: null,
    sequence: null,
    subDescription: '',
  },
};
const AddReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const masterReportList = useAppSelector((state) => state.cs.managementList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [reportData, setReportData] = useState(initialReportData);
  const [reportType, setReportType] = useState('');
  const [inputReportText, setInputReportText] = useState('');
  const [addReportOptionList, setAddReportOptionList] = useState<any>([]);
  // const [addReportData, setAddReportData] = useState('');
  // const [firstReportOption, setFirstReportOption] = useState<any>('');
  // const [secondReportOption, setSecondReportOption] = useState<any>('');
  // const [secondReportOptionList, setSecondReportOptionList] = useState<any>();
  // const [optionReportPoint, setOptionReportPoint] = useState('');
  // const detailData: any = location.state;
  const {
    activeYn,
    contentsType,
    description,
    masterReportDetailList,
    reportPoint,
  } = reportData;
  // const changeFirstOption = (option: any, name: string) => {
  //   setFirstReportOption(option);
  //   const secondDeps = option.masterReportDetailListList;
  //   if (secondDeps) {
  //     setSecondReportOption('');
  //     setSecondReportOptionList(secondDeps);
  //     setOptionReportPoint(option.reportPoint);
  //   } else {
  //     setSecondReportOptionList('');
  //     setSecondReportOption('');
  //     setOptionReportPoint(option.reportPoint);
  //   }
  // };

  // 초기 데이터 세팅
  const handleSetInitialData = useCallback(() => {
    // 생성시 데이터셋
    if (masterReportList) {
      const reportNameList = masterReportList?.map((el) => el.name);
      // 직접 입력 select 추가
      reportNameList.push('직접 입력');
      setAddReportOptionList(reportNameList);
    }
  }, [masterReportList]);

  // select set 데이터
  const handleSelectSetData = useCallback(
    (e: any, name) => {
      // 2 뎁스 이름 입력
      if (name === 'contentsType') {
        setReportType(e);
        setReportData({ ...reportData, [name]: getReportTypeOption[e] });
        return;
      }
      setReportData({ ...reportData, [name]: e });
    },
    [reportData],
  );
  // input set 데이터
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      if (name === 'subDescription') {
        setReportData({
          ...reportData,
          masterReportDetailList: {
            ...reportData.masterReportDetailList,
            subDescription: value,
          },
        });
        return;
      }
      if (name === 'inputReportText') {
        setInputReportText(value);
        return;
      }
      setReportData({ ...reportData, [name]: value });
    },
    [reportData],
  );

  // reset 데이터
  const handleResetData = useCallback(
    (e) => {
      if (e === 'inputReportText') {
        setInputReportText('');
        return;
      }
      if (e === 'subDescription') {
        setReportData({
          ...reportData,
          masterReportDetailList: [
            { ...reportData.masterReportDetailList, subDescription: '' },
          ],
        });
        return;
      }
      setReportData({ ...reportData, [e]: initialReportData[e] });
    },
    [reportData],
  );

  // 1뎁스 유무 확인
  const checkReportId = () => {
    return addReportOptionList.some((el: any) => {
      return el === description;
    });
  };

  // 기타 정보
  const etcData = masterReportList.find((el) => el.description === '기타');

  // Master Report 추가 (1뎁스, 1+2뎁스, 2뎁스)
  const addMasterReport = async () => {
    const checkReportType = description === '직접 입력';

    // 신규 1뎁스 또는 신규 1뎁스 + 2뎁스 일 때
    if (checkReportType) {
      try {
        dispatch(setLoading());
        const getRequestData = () => {
          // 신규 1뎁스만 일때
          if (!masterReportDetailList.subDescription) {
            return {
              ...reportData,
              description: inputReportText,
              name: inputReportText,
              masterReportDetailList: null,
              reportType: null,
              sequence: etcData.sequence,
            };
          }
          // 신규 1뎁스 + 2뎁스까지 있을 때
          return {
            ...reportData,
            description: inputReportText,
            name: inputReportText,
            reportPoint: null,
            masterReportDetailList: [
              {
                ...reportData.masterReportDetailList,
                reportPoint,
                sequence: 1,
              },
            ],
            reportType: null,
            sequence: etcData.sequence,
          };
        };

        const params = {
          sequence: etcData.sequence + 1,
        };
        await axios.post(
          `${MASTER_REPORT}/${etcData.id}/update`,
          {},
          { params },
        );
        await axios.post(MASTER_REPORT, getRequestData(), {
          params: { adminName },
        });
        alert('추가 되었습니다');
        navigate('/operation/management');
      } catch (err) {
        console.log(err);
      }
      dispatch(resetLoading());
      return;
    }
    // 기존 1뎁스 + 신규 2뎁스
    if (
      checkReportId() &&
      description &&
      masterReportDetailList.subDescription &&
      !checkReportType
    ) {
      try {
        dispatch(setLoading());

        // 1뎁스의 2뎁스 찾기
        const findReportItem = masterReportList.find(
          (el: any) => el.description === description,
        );

        // 2뎁스 중복 유무 체크
        const duplicateCheck = masterReportList.some(
          (el: any) =>
            el.masterReportDetailList?.subDescription ===
            masterReportDetailList.subDescription,
        );

        if (duplicateCheck) {
          alert('신고 세부 항목이 중복되었습니다.');
          return;
        }

        const params = {
          activeYn,
          adminName,
          language: 'ko',
          reportPoint,
          sequence:
            findReportItem.masterReportDetailList !== null
              ? findReportItem.masterReportDetailList.length + 1
              : 1,
          subDescription: masterReportDetailList.subDescription,
        };

        await axios.post(
          `${REPORT_MANAGEMENT_LIST}/${findReportItem.id}/detail`,
          {},
          { params },
        );
        alert('추가 되었습니다');
        navigate('/operation/management');
      } catch (err) {
        // console.log(err);
      }
      dispatch(resetLoading());
    }
  };

  //취소하면 뒤로가기
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    handleSetInitialData();
  }, [handleSetInitialData]);

  useEffect(() => {
    const params = {
      contentsType,
      language: 'ko',
      offset: 0,
      limit: 100,
    };
    dispatch(getManagementList(params));
  }, [dispatch, contentsType]);

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
          <NormalButton text="추가하기" onSubmit={addMasterReport} />
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <SelectModule
            name="contentsType"
            placeholder="신고 유형을 선택해주세요."
            value={reportType}
            label="신고 유형"
            onChange={handleSelectSetData}
            options={reportTypeOptions}
          />
        </InputWrapper>
        <ReportWrapper>
          <InputWrapper>
            <SelectModule
              name="description"
              placeholder="신고 항목을 선택해주세요."
              value={description}
              label="신고 항목"
              onChange={handleSelectSetData}
              options={addReportOptionList}
            />
          </InputWrapper>
          {description === '직접 입력' && (
            <>
              <SpaceDiv />
              <InputWrapper>
                <LabelTextInput
                  name="inputReportText"
                  label="&nbsp;"
                  value={inputReportText}
                  placeholder=""
                  onChange={handleInputSetData}
                  onReset={handleResetData}
                />
              </InputWrapper>
            </>
          )}
        </ReportWrapper>
        <InputWrapper>
          <LabelTextInput
            name="subDescription"
            label="세부 내용"
            value={masterReportDetailList.subDescription}
            placeholder="세부 내용을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="reportPoint"
            label="벌점"
            value={reportPoint}
            placeholder="벌점을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="활성화 여부"
            onChange={handleInputSetData}
            type="activeYn"
            text={['공개', '비공개']}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AddReport;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin: 50px 0 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  gap: 40px 30px;
  grid-gap: 40px 30px;
`;

const InputWrapper = styled.div`
  width: 100%;
`;

const ReportWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SpaceDiv = styled.div`
  margin: 0 15px;
`;
