import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import NormalButton from '../../../components/common/Button/NormalButton';
import checkedButton from '../../../assets/images/page/checkedButton.png';
import closeRoundButton from '../../../assets/images/page/closeRoundButton.png';

// config
import {
  CATEGORY_LIST,
  CHANGE_CATEGORY_ROLE,
  MASTER_ROLE_LIST,
} from '../../../constant/apiUrl';

// redux
import { setLoading, resetLoading } from '../../../redux/slices/user';
import { MenuList } from '../../../constant/menuList';

const EditCategoryOfAdminRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { id = 0, userRole = '없음' }: any = location.state;
  const [initialList, setInitialList] = useState([]);
  const [selectedList, setSelectedList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);

  const sortCategory = (source: any) => {
    let sortedCategoryList: any = [];
    MenuList.forEach((menu: any) => {
      menu?.subMenu?.forEach((subM: any) => {
        source.forEach((element: any) => {
          if (element.endPoint === subM.address) {
            sortedCategoryList = [...sortedCategoryList, element];
          }
        });
      });
    });
    return sortedCategoryList;
  };

  const editSelectedList = useCallback(
    (item: any) => {
      if (selectedList.find((el: any) => el.id === item.id)) {
        setSelectedList(
          sortCategory(selectedList.filter((el: any) => el.id !== item.id)),
        );
      } else setSelectedList(sortCategory(selectedList?.concat(item)));
    },
    [selectedList],
  );

  const checkSelect = useCallback(
    (item: any) => {
      const list = selectedList.map((el: any) => el.id);
      if (list.includes(item.id)) {
        return 'checked';
      }
      return '';
    },
    [selectedList],
  );

  // 전체 카테고리 목록 불러오기
  const getAllCategory = async () => {
    try {
      const resp = await axios.get(CATEGORY_LIST, {
        params: { limit: 100, offset: 0 },
      });
      setCategoryList(sortCategory(resp.data.categoryList));
    } catch (err) {
      console.log(err);
    }
  };

  // list에 title 만들어주기
  const handleTitle = (category: any) => {
    const { endPoint } = category;
    const selectedMenu = MenuList.find((n) => endPoint.includes(n.address));
    return selectedMenu ? selectedMenu.name : '';
  };

  // 선택한 권한 저장하기
  const changeCategoryRole = async () => {
    const categoryIdList = selectedList.map((el: any) => el.id);
    try {
      dispatch(setLoading());
      await axios.post(`${CHANGE_CATEGORY_ROLE}/${id}/category`, {
        categoryIdList,
      });
      alert('수정되었습니다.');
      navigate('/admin/adminRole');
    } catch (err) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  const getRolePermissionList = useCallback(async () => {
    try {
      const resp = await axios.get(`${MASTER_ROLE_LIST}/category/role/${id}`, {
        params: { limit: 100, offset: 0 },
      });
      setSelectedList(sortCategory(resp.data.categoryList));
      setInitialList(sortCategory(resp.data.categoryList));
    } catch (err) {
      console.log(err);
    }
  }, [id]);

  useEffect(() => {
    getRolePermissionList();
    getAllCategory().then((r) => r);
  }, [getRolePermissionList]);

  return (
    <>
      <TopContainer>
        <TitleWrapper>{userRole}</TitleWrapper>
        <ButtonWrapper>
          <NormalButton
            className="cancelBtn"
            text="취소하기"
            onSubmit={() => {
              navigate(-1);
            }}
          />
          {initialList !== selectedList && (
            <NormalButton text="저장하기" onSubmit={changeCategoryRole} />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <SearchContainer>
          <h1>메뉴 검색</h1>
          <Ul>
            {categoryList?.map((item: any) => (
              <ListItem onClick={() => editSelectedList(item)} key={item.id}>
                <CheckBox className={checkSelect(item)} />
                <p>{`${handleTitle(item)} - ${item.categoryName}`}</p>
              </ListItem>
            ))}
          </Ul>
        </SearchContainer>
        <SelectedContainer>
          <TitleContainer>
            <h1>권한</h1>
          </TitleContainer>
          <Ul>
            {selectedList?.map((item: any | never) => (
              <Li key={item.id}>
                <GenreName>{`${handleTitle(item)} - ${
                  item.categoryName
                }`}</GenreName>
                <InputDeleteButton
                  onClick={() => editSelectedList(item)}
                  tabIndex={-1}
                />
              </Li>
            ))}
          </Ul>
          <DisplayRight>
            {selectedList.length !== 0 && (
              <NormalButton
                className="cancelBtn"
                text="전체 취소"
                onSubmit={() => {
                  setSelectedList([]);
                }}
              />
            )}
          </DisplayRight>
        </SelectedContainer>
      </MainContainer>
    </>
  );
};

export default EditCategoryOfAdminRole;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 45px;
`;

const TitleWrapper = styled.div`
  font-size: 36px;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  height: 44px;

  button:last-child {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 456px;
  padding-right: 36px;
  border-right: 1px solid ${({ theme }) => theme.color.subUnactiveGray};

  h1 {
    margin-bottom: 32px;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 40px;
  width: 100%;
  max-height: 620px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    width: 80%;
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const CheckBox = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 100%;

  &.checked {
    background: url(${checkedButton}) center no-repeat;
    border: 1px solid ${({ theme }) => theme.color.mainWhite};
    background-size: 30px;
  }
`;

const DisplayRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin-left: 36px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 59px;
`;

const GenreName = styled.div`
  width: 100%;
  margin-right: 14px;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const InputDeleteButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;
