import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';

// config
import { BAUND_MUSIC_PROJECT } from '../../../constant/apiUrl';
import { resetLoading, setLoading } from '../../../redux/slices/user';

import FileInput from '../../../components/common/Input/FileInput';

const BaundMusicProjectDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const detailData: any = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;
  const [name, setName] = useState('');
  const [nameEn, setNameEn] = useState('');
  const [badgeName, setBadgeName] = useState('뮤직 프로젝트');
  const [badgeNameEn, setBadgeNameEn] = useState('Music Project');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionDetail, setDescriptionDetail] = useState('');
  const [descriptionDetailEn, setDescriptionDetailEn] = useState('');
  const [profileImg, setProfileImg] = useState<string>('');
  const [profileImgFile, setProfileImgFile] = useState<any>(null);
  const [coverImg, setCoverImg] = useState<string>('');
  const [coverImgFile, setCoverImgFile] = useState<any>(null);
  const [contentsUrl, setContentsUrl] = useState('');
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');

  const getDetail = useCallback(async () => {
    try {
      const resp = await axios.get(`${BAUND_MUSIC_PROJECT}/${prevId}/detail`);
      if (resp.data.adminBaundMusicProject) {
        setName(resp.data.adminBaundMusicProject.name);
        setNameEn(resp.data.adminBaundMusicProject.nameEn);
        if (resp.data.adminBaundMusicProject.badgeName) {
          setBadgeName(resp.data.adminBaundMusicProject.badgeName);
        }
        if (resp.data.adminBaundMusicProject.badgeNameEn) {
          setBadgeNameEn(resp.data.adminBaundMusicProject.badgeNameEn);
        }
        setDescription(resp.data.adminBaundMusicProject.description);
        setDescriptionEn(resp.data.adminBaundMusicProject.descriptionEn);
        setDescriptionDetail(
            resp.data.adminBaundMusicProject.descriptionDetail
            ? resp.data.adminBaundMusicProject.descriptionDetail
            : ''
        );
        setDescriptionDetailEn(
            resp.data.adminBaundMusicProject.descriptionDetailEn
            ? resp.data.adminBaundMusicProject.descriptionDetailEn
            : ''
        );
        setProfileImg(
          resp.data.adminBaundMusicProject.profileImgUrl
            ? resp.data.adminBaundMusicProject.profileImgUrl
            : '',
        );
        setCoverImg(
          resp.data.adminBaundMusicProject.coverImgUrl
            ? resp.data.adminBaundMusicProject.coverImgUrl
            : '',
        );
        setContentsUrl(resp.data.adminBaundMusicProject.contentsUrl);
        setSequence(resp.data.adminBaundMusicProject.sequence);
        setActiveYn(resp.data.adminBaundMusicProject.activeYn);
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  }, [prevId]);

  useEffect(() => {
    if (prevId) {
      getDetail().then((r) => r);
    }
  }, [getDetail, prevId]);

  const addContents = async (type: string) => {
    if (!name) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (!nameEn) {
      alert('이름(영문)을 입력해주세요.');
      return;
    }
    if (!descriptionDetail) {
      alert('상세 표시용 설명을 입력해주세요.');
      return;
    }
    if (!descriptionDetailEn) {
      alert('상세 표시용 설명(영문)을 입력해주세요.');
      return;
    }
    if (!profileImg) {
      alert('프로필 이미지를 입력해주세요.');
      return;
    }
    if (!coverImg) {
      alert('커버 이미지를 입력해주세요.');
      return;
    }
    if (!contentsUrl) {
      alert('해당 컨텐츠의 목록을 호출할 URL을 입력해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('name', name);
    formData.append('nameEn', nameEn);
    formData.append('badgeName', badgeName);
    formData.append('badgeNameEn', badgeNameEn);
    formData.append('description', description);
    formData.append('descriptionEn', descriptionEn);
    formData.append('descriptionDetail', descriptionDetail);
    formData.append('descriptionDetailEn', descriptionDetailEn);
    if (profileImgFile != null) {
      formData.append('profileImg', profileImgFile);
    }
    if (coverImgFile != null) {
      formData.append('coverImg', coverImgFile);
    }
    formData.append('contentsUrl', contentsUrl);
    formData.append('sequence', sequence);
    formData.append('activeYn', activeYn);

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${BAUND_MUSIC_PROJECT}/${prevId}/edit`, formData, {});
      } else {
        await axios.post(`${BAUND_MUSIC_PROJECT}`, formData, {});
      }
      await navigate('/baundMusic/project');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <CoverImgWrapper>
          {profileImg ? <EventImg src={profileImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              profileImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="프로필 이미지 : 목록 표시용"
            value={profileImgFile}
            setFile={(e: string) => setProfileImgFile(e)}
            setImgUrl={setProfileImg}
            Required
          />
        </InputWrapper>
        <CoverImgWrapper>
          {coverImg ? <EventImg src={coverImg} /> : <EventImg />}
        </CoverImgWrapper>
        <InputWrapper>
          <FileInput
            type="profileImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버 이미지 : 상세 표시용"
            value={coverImgFile}
            setFile={(e: string) => setCoverImgFile(e)}
            setImgUrl={setCoverImg}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="name"
            label="프로젝트 명"
            value={name}
            placeholder="프로젝트 명을 입력해 주세요."
            onChange={(e) => setName(e.target.value)}
            onReset={() => setName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="프로젝트 명 EN"
            value={nameEn}
            placeholder="프로젝트 명 EN을 입력해 주세요."
            onChange={(e) => setNameEn(e.target.value)}
            onReset={() => setNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="badgeName"
            label="배지 명"
            value={badgeName}
            placeholder="배지 명을 입력해 주세요."
            onChange={(e) => setBadgeName(e.target.value)}
            onReset={() => setBadgeName('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="badgeNameEn"
            label="배지 명 EN"
            value={badgeNameEn}
            placeholder="배지 명 EN을 입력해 주세요."
            onChange={(e) => setBadgeNameEn(e.target.value)}
            onReset={() => setBadgeNameEn('')}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="description"
            label="목록 표시용 프로젝트 설명"
            value={description}
            placeholder="프로젝트 설명을 입력해 주세요."
            onChange={(e) => setDescription(e.target.value)}
            onReset={() => setDescription('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="descriptionEn"
            label="목록 표시용 프로젝트 설명 EN"
            value={descriptionEn}
            placeholder="프로젝트 설명 EN을 입력해 주세요."
            onChange={(e) => setDescriptionEn(e.target.value)}
            onReset={() => setDescriptionEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="descriptionDetail"
              label="상세 표시용 프로젝트 설명"
              value={descriptionDetail}
              placeholder="상세 표시용 프로젝트 설명을 입력해 주세요."
              onChange={(e) => setDescriptionDetail(e.target.value)}
              onReset={() => setDescriptionDetail('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="descriptionDetailEn"
              label="상세 표시용 프로젝트 설명 EN"
              value={descriptionDetailEn}
              placeholder="상세 표시용 프로젝트 설명 EN을 입력해 주세요."
              onChange={(e) => setDescriptionDetailEn(e.target.value)}
              onReset={() => setDescriptionDetailEn('')}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="contentsUrl"
            label="해당 컨텐츠의 목록을 호출할 URL"
            value={contentsUrl}
            placeholder="해당 컨텐츠의 목록을 호출할 URL을 입력해 주세요."
            onChange={(e) => setContentsUrl(e.target.value)}
            onReset={() => setContentsUrl('')}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default BaundMusicProjectDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;

  &:first-child {
    grid-row: 1;
  }
  &:nth-child(3) {
    grid-row: 2;
  }
`;

const InputWrapper = styled.div`
  &:nth-child(2) {
    grid-row: 1;
  }
  &:nth-child(4) {
    grid-row: 2;
  }
  &:nth-child(n + 5):nth-child(-n + 6) {
    grid-row: 3;
  }
  &:nth-child(n + 7):nth-child(-n + 8) {
    grid-row: 4;
  }
  &:nth-child(n + 9):nth-child(-n + 10) {
    grid-row: 5;
  }
  &:nth-child(n + 11):nth-child(-n + 12) {
    grid-row: 6;
  }
  &:nth-child(n + 13) {
    grid-row: 7;
    grid-column: span 2;
  }
  &:nth-child(14) {
    grid-row: 8;
    grid-column: span 2;
  }
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;
