import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListItem from '../../components/common/List/ListItem';
import RoundButton from '../../components/common/Button/RoundButton';
import Pagination from '../../components/common/Pagination';

// config
import {MISSIONS, REWARDS} from '../../constant/apiUrl';

// constant
import { postsPerPage } from '../../constant/common';

const MissionList = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
      getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  function convertRewardType(mission: any) {
    let convertedText = mission.rewardType;
    switch (mission.rewardType) {
      case 'ONLY_ONCE':
        convertedText = '단일 미션';
        break;
      case 'DAILY':
        convertedText = '일일 초기화';
        break;
      default:
    }
    mission.rewardType = convertedText;
  }
  function convertRewardCurrentType(mission: any) {
    let convertedText = mission.rewardCurrencyType;
    switch (mission.rewardCurrencyType) {
      case 'BAUND_POINT':
        convertedText = '바운드 포인트';
        break;
      default:
    }
    mission.rewardCurrencyType = convertedText;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function convertParams(missionList: any) {
    missionList.forEach((mission: any) => {
      convertRewardType(mission);
      convertRewardCurrentType(mission);
    });
    return missionList;
  }

  const getContents = useCallback(async () => {
    const params = {
      privateYn: activationType === 'active' ? 'N' : 'Y',
      limit: postsPerPage,
      offset: pageNumber,
    };
    await axios
    .get(`${MISSIONS}`, { params })
    .then((resp) => {
      setTotalCount(resp.data.totalCount);
      setContentsList(convertParams(resp.data.missionList));
    })
    .catch((err) => {
      console.log(err);
    });
  }, [activationType, contentsList, pageNumber]);

  useEffect(() => {
    getContents();
  }, [activationType, pageNumber]);

  const goToDetail = (state: any) => {
    navigate(`${REWARDS}/mission-edit`, {
      state: {
        prevId: state?.missionId,
      },
    });
  };

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
            title="활성"
            selected={activationType === 'active'}
            onClick={() => {
              setActivationType('active');
              setPageNumber(0);
            }}
          />
          <RoundButton
            title="비활성"
            selected={activationType === 'inactive'}
            onClick={() => {
              setActivationType('inactive');
              setPageNumber(0);
            }}
          />
        </div>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="Id" />
              <ListHead title="이름" />
              <ListHead title="설명" />
              <ListHead title="영문 이름" />
              <ListHead title="영문 설명" />
              <ListHead title="달성 조건 횟수" />
              <ListHead title="보상 타입" />
              <ListHead title="보상 재화 타입" />
              <ListHead title="보상 량" />
              <ListHead title="최대 보상 지급 횟수" />
            </>
          }
        >
          {contentsList?.map((el: any) => (
            <tr key={el.missionId} onClick={() => goToDetail(el)}>
              <td>
                <ListItem text={el.missionId} />
              </td>
              <td>
                <ListItem text={el.missionName} />
              </td>
              <td>
                <ListItem text={el.missionDescription} />
              </td>
              <td>
                <ListItem text={el.missionNameEn} />
              </td>
              <td>
                <ListItem text={el.missionDescriptionEn} />
              </td>
              <td>
                <ListItem text={el.conditionCount} />
              </td>
              <td>
                <ListItem text={el.rewardType} />
              </td>
              <td>
                <ListItem text={el.rewardCurrencyType} />
              </td>
              <td>
                <ListItem text={el.rewardValue} />
              </td>
              <td>
                <ListItem text={el.maxCompensateCount} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default MissionList;

export const colList = [80, 150, 150, 100, 100, 80, 100, 80, 80];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
