import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { MASTER_REPORT } from '../../../constant/apiUrl';

// 장르 받아오기
export const getManagementList = createAsyncThunk(
  'cs/getManagementList',
  async (params: object, { rejectWithValue }) => {
    try {
      const response = await axios.get(MASTER_REPORT, {
        params,
      });
      return response.data.masterReportList;
    } catch (error: any) {
      console.log(rejectWithValue(error.response.data));
      return rejectWithValue(error.response.data);
    }
  },
);
