export const beatSearchTypeList = [
  { text: 'Title', value: 'T' },
  { text: 'pid', value: 'BMP' },
  { text: '장르', value: 'GENRE' },
  { text: 'BPM', value: 'BPM' },
];

export const beatMakerSearchTypeList = [
  { text: 'pid', value: 'P' },
  { text: '아티스트명', value: 'N' },
  { text: '이메일', value: 'E' },
];

export const beatBpmOptions = [
  { text: '전체', value: '' },
  { text: '49 이하', value: '49' },
  { text: '50 ~ 80', value: '50' },
  { text: '80 ~ 100', value: '80' },
  { text: '100 ~ 140', value: '100' },
  { text: '140 ~ 180', value: '140' },
  { text: '180 이상', value: '180' },
];

export const beatPrivateYnOptions = [
  { text: '전체', value: '' },
  { text: '공개', value: 'N' },
  { text: '비공개', value: 'Y' },
];

export const beatColList = [70, 70, 100, 120, 105, 130, 90, 100, 200];

export const beatMakerColList = [70, 80, 120, 120, 120, 200, 80];

export const genreBeatColList = [70, 200, 120, 230, 90, 140];

export const hotBeatColList = [80, 120, 200, 180, 180, 90];
