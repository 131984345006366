import React from 'react';
import { useNavigate } from 'react-router-dom';

// common
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import EditButton from '../../../components/common/Button/EditButton';

const OfficialAccountRowComponent = ({ data }: any) => {
  const navigate = useNavigate();
  const { pid, profileImgUrl, name } = data;

  return (
    <tr>
      <td>
        <ListCoverImg url={profileImgUrl} />
      </td>
      <td>
        <ListItem text={pid} type="title" />
      </td>
      <td>
        <ListItem text={name} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            navigate('/operation/officialAccount/edit', { state: data });
          }}
          text="수정"
        />
      </td>
    </tr>
  );
};

export default OfficialAccountRowComponent;
