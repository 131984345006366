import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';

// components
import Pagination from '../../../components/common/Pagination';
import VideoModal from '../../../components/common/Modal/VideoModal';
import SearchInput from '../../../components/common/Input/SearchInput';
import RadioButton from "../../../components/common/Button/RadioButton";
import NormalButton from '../../../components/common/Button/NormalButton';
import SelectModule from "../../../components/common/Select/SelectModule";

import BattleTable from "../../battle/BattleTable";

// constant
import {battleSearchTypeValues} from "../../../constant/battle";
import {postsPerPage as limit} from "../../../constant/common";

import {BATTLE} from '../../../constant/apiUrl';
import * as commonFn from "../../../utils/commonFn";


const PushForBattle = ({
	closeModal,             // function: 모달 닫기
	selectedList,           // list: 선택됐던 객체
	maxSelectCnt,           // number: 최대 선택 개수
	selectedSetter,         // function: 선택된 배틀 전달
}) => {
	const [type, setType] = useState('Y');
	const [activeYn, setActiveYn] = useState('Y');
	const [searchType, setSearchType] = useState('title');
	const [keyword, setKeyword] = useState('');
	const [totalCount, setTotalCount] = useState(0);
	const [offset, setOffset] = useState(0);
	
	const [selectedBattleList, setSelectedBattleList] = useState(selectedList || []);
	const [battleList, setBattleList] = useState([]);
	
	const pageDispatch = useCallback((e) => setOffset(e - 1), []);
	
	// 타입 및 활성여부 트리거
	useEffect(() => {
		setKeyword('');
		setOffset(0);
		getBattleList(false);
	}, [type, activeYn])
	
	
	// 엔터키로 검색
	const enterKeyPress = e => {
		if (e.key === 'Enter') getBattleList();
	};
	
	// 불러오기
	const getBattleList = () => {
		const searchUrl = new URLSearchParams({
			type: type === 'Y' ? 'SPECIAL' : 'NORMAL',
			activeYn, searchType, keyword, limit,
			offset: offset * limit
		});
		
		axios
			.get(`${BATTLE}?${searchUrl.toString()}`)
			.then(res => {
				if (res?.data) {
					setBattleList(res.data.battles);
					setTotalCount(res.data.totalCount);
				}
			})
			.catch(reason => console.log(reason));
	}
	
	// 선택 삭제
	const removeSelectedBattle = (data) => {
		setSelectedBattleList(selectedBattleList.filter(el => el.battleId !== data.battleId));
	}
	
	// 선택 완료
	const selectFinish = (_selectedBattleList) => {
		if (_selectedBattleList.length === 0) {
			alert('선택된 배틀이 없습니다.');
			closeModal();
		}
		
		// 전달
		selectedSetter(_selectedBattleList);
		closeModal();
	};
	
	return (
		<VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
			<ModalWrapper>
				<ModalButtonWrapper>
					<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
					<NormalButton text="완료" onSubmit={() => selectFinish(selectedBattleList)}/>
				</ModalButtonWrapper>
				<ModalTitle>선택된 배틀</ModalTitle>
				{selectedBattleList.length > 0
					? <>
						<TableTitle>{selectedBattleList.length}개 배틀</TableTitle>
						<LineDiv />
						<BattleTable
							battleList={selectedBattleList}
							readOnly={true} select={true}
							remover={removeSelectedBattle}
						/>
					</>
					: <>
						<VideoModalSubText>선택된 배틀이 없습니다.<LineDiv /></VideoModalSubText>
						<EmptyDiv />
					</>
				}
				
				<LineDiv />
				<TopContainer>
					<TopWrapper>
						<SearchWrapper>
							<InputWrapper>
								<RadioButton
									value={type}
									name="type"
									label=""
									onChange={e => setType(e.target.value)}
									text={['특별방', '일반방']}
									type="activeYn"
								/>
							</InputWrapper>
							
							{
								type === 'Y' &&
								<InputWrapper>
									<RadioButton
										value={activeYn}
										name="activeYn"
										label=""
										onChange={e => setActiveYn(e.target.value)}
										text={['공개', '비공개']}
										type="activeYn"
									/>
								</InputWrapper>
							}
							
							<InputWrapper2>
								<SelectModule
									name="type"
									label=""
									placeholder=""
									className='battleSearchType'
									value={battleSearchTypeValues[searchType]}
									onChange={e => setSearchType(e.value)}
									options={commonFn.transObjectToOptions(battleSearchTypeValues)}
								/>
								<SearchInput
									value={keyword}
									onChange={e => setKeyword(e.target.value)}
									onKeyPress={enterKeyPress}
								/>
								<NormalButton text="검색" className='cancelBtn' onSubmit={() => getBattleList(false)} />
							</InputWrapper2>
						</SearchWrapper>
					</TopWrapper>
					
					<PaginationWrapper>
						{totalCount !== 0 && (
							<Pagination
								totalCount={totalCount}
								postsPerPage={limit}
								currentPage={offset}
								pageDispatch={pageDispatch}
							/>
						)}
					</PaginationWrapper>
				</TopContainer>
				
				<LineDiv />
				
				<BattleTable
					battleList={battleList}
					readOnly={false}
					select={true}
					selector={el => {
						if (selectedBattleList.find(b => b.battleId === el.battleId)) {
							alert("이미 선택된 배틀입니다.");
							return;
						}
						
						const _selectedBattleList = [...selectedBattleList];
						if (_selectedBattleList.length === maxSelectCnt) {
							alert(`${maxSelectCnt}개만 선택 가능합니다.`);
							return;
						}
						
						_selectedBattleList.push(el);
						setSelectedBattleList(_selectedBattleList);
						
						// 최대 선택 가능 개수 도달 시 모달 닫기
						if (_selectedBattleList.length === maxSelectCnt) {
							selectFinish(_selectedBattleList);
						}
					}}
				/>
			</ModalWrapper>
		</VideoModal>
	);
};

export default PushForBattle;



const TopContainer = styled.div``;
const TopWrapper = styled.div`
	padding: 10px 20px;
	background-color: #ccc;
	border-radius: 10px;
`;
const InputWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;
const InputWrapper2 = styled.div`
	margin: 20px 0;
	display: grid;
	gap: 10px;
	grid-template-columns: 1fr 8fr 1fr;
	align-items: center;
`;







const ModalWrapper = styled.div`
  width: 1175px;
  padding: 50px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ModalTitle = styled.span`
  display: inline-block;
  margin: 0 20px 30px 0;
  font-size: 24px;
  font-weight: 600;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const SearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  margin-top: 30px;
`;

const EmptyDiv = styled.div`
  margin-top: 120px;
  height: 90px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
