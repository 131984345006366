import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getBeatList, getBeatMakerList } from '../actions/beat/beat';

interface initialType {
  beatList: Array<any>;
  beatMakerList: Array<any>;
  getBeatListError: string;
  getGenreListError: string;
  detailBeat: any;
  detailBeatMaker: any;
  beatTotalCount: number;
  beatMakerTotalCount: number;
}

const initialState: initialType = {
  beatTotalCount: 0,
  beatList: [],
  beatMakerTotalCount: 0,
  beatMakerList: [],
  getBeatListError: '',
  getGenreListError: '',
  detailBeat: {
    bmName: '',
    bmPid: '',
    bpm: '',
    startDt: '',
    genreNames: [],
    moodNames: [],
    id: '',
    key: '',
    length: '',
    price: '',
    privateYn: '',
    title: '',
    titleEn: '',
    titleEs: '',
    bmId: '',
    genreIds: [],
    moodIds: [],
    beatFile: null,
    beatCoverImgFile: null,
  },
  detailBeatMaker: {},
};

const beatSlice = createSlice({
  name: 'beat',
  initialState,
  reducers: {
    getDetailBeat: (state, action) => {
      state.detailBeat = action.payload;
    },
    resetDetailBeat: (state) => {
      state.detailBeat = initialState.detailBeat;
    },
  },
  extraReducers: (builder) => {
    builder
      // 비트 리스트 가져오기
      .addCase(getBeatList.pending, (state, action) => {})
      .addCase(getBeatList.fulfilled, (state, action) => {
        if (!action.payload) {
          return;
        }
        state.beatList = action.payload.beatList;
        state.beatTotalCount = action.payload.count;
      })
      .addCase(getBeatList.rejected, (state, action: PayloadAction<any>) => {
        state.getBeatListError = action.payload;
      })

      // 비트메이커 리스트 가져오기
      .addCase(getBeatMakerList.pending, (state, action) => {})
      .addCase(getBeatMakerList.fulfilled, (state, action) => {
        state.beatMakerList = action.payload.beatMakerList;
        state.beatMakerTotalCount = action.payload.count;
      })
      .addCase(
        getBeatMakerList.rejected,
        (state, action: PayloadAction<any>) => {
          console.log('rejected');
        },
      );
  },
});

export default beatSlice;
export const { getDetailBeat, resetDetailBeat } = beatSlice.actions;
