import React, {useCallback, useEffect, useState} from "react";
import styled from "styled-components";
import { useNavigate } from 'react-router-dom';

import NormalButton from "../../../components/common/Button/NormalButton";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import {ADVERTISEMENT} from "../../../constant/apiUrl";
import axios from "axios";
import ListItem from "../../../components/common/List/ListItem";
import {
  postsPerPage
} from "../../../constant/common";
import SelectModule from "../../../components/common/Select/SelectModule";
import * as commonFn from "../../../utils/commonFn";
import SearchInput from "../../../components/common/Input/SearchInput";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";

// 광고 목록 화면
const Advertisements = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [advertisementList, setAdvertisementList] = useState([]);

  // 검색 조건
  const storageType = localStorage.getItem('storageType');
  const [type, setType] = useState(storageType || 'ADVERTISER_NAME');
  const storageWord = localStorage.getItem('storageWord');
  const [word, setWord] = useState(storageWord || '');

  // 페이징
  const storagePageNumber = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
      storagePageNumber ? JSON.parse(storagePageNumber) : 0
  );
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
    window.localStorage.setItem('pageNumber', String(e - 1));
  }, []);

  //광고 데이터 조회 //////////////////////////////////////////////////////////
  const params = {
    type,
    word,
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
  };
  const getAdvertisements = async() => {
    await axios.get(`${ADVERTISEMENT}`, {params})
    .then(res => {
      setAdvertisementList(res.data.adminAdvertisementList);
      setTotalCount(res.data.count);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }
  // 검색 조건 변경시 호출
  useEffect(() => getAdvertisements(), [pageNumber]);

  const handleType = (_type) => {
    setType(_type);
    window.localStorage.setItem('storageType', _type);
  }
  const handleWord = (_word) => {
    setWord(_word);
    window.localStorage.setItem('storageWord', _word);
  }
  const resetPageAndGetAdvertisements = () => {
    if(word) {
      setPageNumber(0);
      params.offset = 0;
      getAdvertisements().then();
    }
  }
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      resetPageAndGetAdvertisements()
    }
  };

  // 상세 페이지로 이동
  const moveToDetail = (_type, _advertisementId) => {
    let _state = (_type === 'edit')
        // 수정 일때만 정보 전달
        ? {advertisementId: _advertisementId}
        : null;
    navigate(`/advertisement/advertisementSummary/${_type}`, {state:_state})
  }

  const advertisementSearchTypes = {
    ADVERTISER_NAME: '광고주',
    ADVERTISEMENT_TITLE: '광고 식별 제목',
  };

  return (
      <>
        <TitleText>광고 목차</TitleText>
        <TopContainer>
          <TopWrapper jc={'right'}>
            <ButtonWrapper>
              <NormalButton text="새로 만들기" onSubmit={() => moveToDetail('add')}/>
            </ButtonWrapper>
          </TopWrapper>
          <TopWrapper>
            <SearchWrapper>
              <SelectModule
                  name="type" label="" placeholder="" className='commonSearchType200'
                  value={advertisementSearchTypes[type]}
                  onChange={e => handleType(e.value)}
                  options={commonFn.transObjectToOptions(advertisementSearchTypes)}
              />
              <SearchInputWrapper>
                <SearchInput value={word} onChange={e => handleWord(e.target.value)}
                             onKeyPress={enterKeyPress}/>
              </SearchInputWrapper>
              <NormalButton text="검색" className='cancelBtn' onSubmit={resetPageAndGetAdvertisements} />
            </SearchWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 광고'}
                      currentPage={pageNumber}
                      pageDispatch={pageDispatch}
                  />
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {advertisementList.length !== 0 ? (
          <Table
              colList={[50, 100, 100]}
              thead={<>
                <ListHead title="번호" />
                <ListHead title="광고주 이름"/>
                <ListHead title="광고 식별제목"/>
              </>}
          >
            {
              advertisementList?.map(ad =>
                <tr key={ad.advertisementId}
                    onClick={() => moveToDetail('edit', ad.advertisementId)}
                >
                  <td><ListItem text={ad.advertisementId} /></td>
                  <td><ListItem text={ad.advertiser.name} /></td>
                  <td><ListItem text={ad.title} /></td>
                </tr>
              )
            }
          </Table>
          ) : (
              <VideoModalSubText>검색 결과가 없습니다.</VideoModalSubText>
          )}
        </MainContainer>
      </>
  );
};
export default Advertisements;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 60px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  min-width: 483px;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;
