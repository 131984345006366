import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import React, { useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { resetLoading, setLoading } from '../../../redux/slices/user';
import { MASTER_ROLE_LIST } from '../../../constant/apiUrl';
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';

const AdminRoleManagementDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData: any = location.state;
  const roleId = detailData !== null ? detailData.roleId : null;
  const [userRole, setUserRole] = useState(
    detailData !== null ? detailData.userRole : '',
  );

  const addContents = async (type: string) => {
    if (!userRole) {
      alert('이름을 입력해주세요.');
      return;
    }

    // const formData = new FormData();
    // formData.append('userRole', userRole);
    const body = {
      userRole,
      changeRole: userRole,
    };
    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${MASTER_ROLE_LIST}/${roleId}`, body, {});
      } else {
        await axios.post(`${MASTER_ROLE_LIST}`, body, {});
      }
      await navigate('/admin/adminRole');
      alert('저장되었습니다.');
    } catch (error: any) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            className="cancelBtn"
            onSubmit={() => navigate(-1)}
          />
          {!roleId ? (
            <NormalButton text="저장하기" onSubmit={() => addContents('add')} />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => addContents('edit')}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <LabelTextInput
            name="userRole"
            label="롤 명"
            value={userRole}
            placeholder="롤 명을 입력해 주세요."
            onChange={(e) => setUserRole(e.target.value)}
            onReset={() => setUserRole('')}
            Required
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};
export default AdminRoleManagementDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
    grid-column: span 2;
  }
`;
