import React, { useCallback, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';

// common
import blankUserImg from '../../../assets/images/page/blankUserImg.png';
import blankImg from '../../../assets/images/page/blankImg.png';
import prevButton from '../../../assets/images/page/prevButton.png';
import nextButton from '../../../assets/images/page/nextButton.png';
import TextField from '../../../components/common/TextField';
import SelectModule from '../../../components/common/Select/SelectModule';
import NormalButton from '../../../components/common/Button/NormalButton';

// hooks
import { useAppSelector } from '../../../redux/store/hooks';
import handleDate from '../../../hooks/handleDate';
import changeUrl from '../../../hooks/changeUrl';

// constant
import { blockType } from '../../../constant/cs';

// redux
import { setLoading, resetLoading } from '../../../redux/slices/user';

// dispatch
import { getManagementList } from '../../../redux/actions/customService/customService';
import {
  REPORT_CONTROL,
  REPORT_TYPE_CHANGE,
  ARTICLE,
} from '../../../constant/apiUrl';
import EditButton from '../../../components/common/Button/EditButton';
import VideoModal from '../../../components/common/Modal/VideoModal';

const DetailBlock = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const masterReportList = useAppSelector((state) => state.cs.managementList);
  const adminId = useAppSelector((state) => state.user.user.id);
  const adminName = useAppSelector((state) => state.user.user.name);

  const [firstReportOption, setFirstReportOption] = useState<any>('');
  const [secondReportOption, setSecondReportOption] = useState<any>('');
  const [secondReportOptionList, setSecondReportOptionList] = useState<any>();
  const [optionReportPoint, setOptionReportPoint] = useState('');
  const [imgCurrentNo, setImgCurrentNo] = useState(0);
  const [modal, setModal] = useState(false);
  const {
    type,
    member,
    contentsUrl,
    comment,
    memberCollection,
    board,
    data,
  }: any = location.state;
  const [contentUrl, setContentUrl] = useState(contentsUrl);
  const {
    id,
    createdDt,
    masterReportContentsType,
    contentsType,
    originMemberPid,
    reportPoint,
    tarMemberReportScore,
    tarMemberPid,
    masterReportId,
    masterReportDetailId,
    reportImgUrl,
    moderationLabels,
    tarMemberId,
    statusChangedYn,
  } = data;
  const totalReportPoint = Number(tarMemberReportScore + optionReportPoint);
  //masterReportId -> 신고 유형 첫번째 뎁스
  // 첫번째 뎁스가 바뀔때마다 두번째 뎁스를 넣어주거나 그래야 함.
  //masterReportDetailId -> 두번째 뎁스
  //masterReportContentsType 에 따라 CT or DM으로 첫번째 뎁스 추적 가능

  const changeFirstOption = (option: any) => {
    setFirstReportOption(option);
    const secondDeps = option.masterReportDetailList;
    if (secondDeps) {
      setSecondReportOption('');
      setSecondReportOptionList(secondDeps);
      setOptionReportPoint(option.reportPoint);
    } else {
      setSecondReportOptionList('');
      setSecondReportOption('');
      setOptionReportPoint(option.reportPoint);
    }
  };

  const changeSecondOption = (option: any) => {
    setSecondReportOption(option);
    setOptionReportPoint(option.reportPoint);
  };

  // url 컨트롤
  const changedUrl = useMemo(() => changeUrl(contentUrl), [contentUrl]);

  useEffect(() => {
    const params = {
      //나중에 타입으로 결정
      contentsType: masterReportContentsType,
      language: null,
      offset: 0,
      limit: 100,
    };
    dispatch(getManagementList(params));
  }, [dispatch, masterReportContentsType]);

  // 신고 유형 set
  const setInitialOption = useCallback(async () => {
    const result = masterReportList?.find(
      (option: any) => option.id === masterReportId,
    );
    await setFirstReportOption(result);
    await setOptionReportPoint(reportPoint);
    if (masterReportDetailId) {
      const firstOption: any = masterReportList?.find((option: any) => {
        return option.id === masterReportId;
      });

      const secondOption = firstOption.masterReportDetailList?.find(
        (option: any) => {
          return option.id === masterReportDetailId;
        },
      );
      await setSecondReportOptionList(firstOption.masterReportDetailList);
      await setSecondReportOption(secondOption);
    }
  }, [masterReportDetailId, masterReportId, masterReportList, reportPoint]);

  useEffect(() => {
    setInitialOption().then((r) => r);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterReportList]);

  const onChangeImg = (imgUrl: Array<any>, index: number) => {
    if (imgUrl?.length <= index) {
      index = 0;
    }
    if (index < 0) {
      index = imgUrl.length - 1;
    }
    setImgCurrentNo(index);
  };

  // 미처리 신고 차단 처리
  const processingBlock = async () => {
    const params: any = {
      masterReportId,       // 1뎁스 신고 유형 id
      masterReportDetailId, // 2뎁스 신고 유형 id
      adminId,
    };

    if (firstReportOption && secondReportOption) {
      params.masterReportId = null;
      params.masterReportDetailId = secondReportOption.id;
    } else if (firstReportOption && !secondReportOption) {
      params.masterReportId = firstReportOption.id;
      params.masterReportDetailId = null;
    }
    try {
      await axios.post(`${REPORT_CONTROL}/${id}/block`, {}, { params });
      alert('차단되었습니다.');

      // 벌점 30점 이상은 제재화면으로 이동
      if (totalReportPoint >= 30) {
        navigate('/cs/restrictMember', { state: tarMemberId });
        return;
      }
      navigate(-1);
    } catch (err) {
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 미처리 신고 삭제 처리
  const precessingUnblock = async () => {
    const params = { adminId };
    try {
      dispatch(setLoading());
      await axios.post(`${REPORT_CONTROL}/${id}/unblock`, {}, { params });
      alert('삭제되었습니다.');
      navigate(-1);
    } catch (err) {
      alert('잠시후 다시 이용해주세요.');
    }
    dispatch(resetLoading());
  };

  // 차단 신고 삭제 처리
  const removeBlockReport = async () => {
    const params = { adminId };
    try {
      dispatch(setLoading());
      await axios.post(`${REPORT_TYPE_CHANGE}/${id}/unblock`, {}, { params });
      navigate(-1);
      alert('삭제되었습니다.');
    } catch (err: any) {
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // 삭제 신고 차단 처리
  const blockDeleteReport = async () => {
    const params: any = {
      // 2뎁스 신고 유형 id
      masterReportDetailId,
      // 1뎁스 신고 유형 id
      masterReportId,
      adminId,
    };

    if (masterReportId && masterReportDetailId) {
      params.masterReportId = null;
      params.masterReportDetailId = masterReportDetailId;
    } else if (masterReportId && !masterReportDetailId) {
      params.masterReportId = masterReportId;
      params.masterReportDetailId = null;
    }
    try {
      dispatch(setLoading());
      await axios.post(`${REPORT_TYPE_CHANGE}/${id}/block`, {}, { params });
      alert('차단되었습니다.');

      // 벌점 30점 이상은 제재화면으로 이동
      if (totalReportPoint >= 30) {
        navigate('/cs/restrictMember', { state: tarMemberId });
        return;
      }
      navigate(-1);
    } catch (err) {
      alert('잠시후 다시 시도해 주세요.');
    }
    dispatch(resetLoading());
  };

  // rekognition 영상 차단
  const handleArticleBlock = async () => {
    try {
      dispatch(setLoading());
      await axios.post(
        `${ARTICLE}/${id}/confirm/block`,
        {},
        { params: { adminName } },
      );
      // if (totalReportPoint >= 30) {
      //   navigate('/cs/restrictMember', { state: tarMemberId });
      //         return;
      // }
      navigate(-1);
      alert('차단되었습니다.');
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  };

  // rekognition 영상 삭제
  const handleArticleUnblock = async () => {
    try {
      dispatch(setLoading());
      await axios.post(
        `${ARTICLE}/${data.id}/confirm/unblock`,
        {},
        { params: { adminName } },
      );
      navigate(-1);
      alert('삭제되었습니다.');
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  };

  // 영상 댓글, 대댓글 영상 보기
  const showArticle = () => {
    setModal(true);
    setContentUrl(comment.articleContentsUrl);
  };

  // modal 닫기
  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <EventId>{id}</EventId>
          {(statusChangedYn === 'N' || type === '미차단 내역') && (
            <ButtonWrapper>
              {type === '미차단 내역' && (
                <>
                  <NormalButton
                    text="차단하기"
                    onSubmit={processingBlock}
                    className="blockBtn"
                  />
                  <NormalButton text="삭제하기" onSubmit={precessingUnblock} />
                </>
              )}
              {type === '차단 내역' && (
                <NormalButton text="삭제하기" onSubmit={removeBlockReport} />
              )}
              {type === '삭제 내역' && (
                <NormalButton
                  text="차단하기"
                  onSubmit={blockDeleteReport}
                  className="blockBtn"
                />
              )}
              {type === 'rekognition' && (
                <>
                  <NormalButton
                    text="차단하기"
                    onSubmit={handleArticleBlock}
                    className="blockBtn"
                  />
                  <NormalButton
                    text="삭제하기"
                    onSubmit={handleArticleUnblock}
                  />
                </>
              )}
            </ButtonWrapper>
          )}
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <ContentContainer>
          <MainText>
            내용 확인
            {comment && <EditButton text="영상" onClick={showArticle} />}
          </MainText>
          <ContentWrapper>
            <MediaContainer>
              {member && (
                <>
                  <ProfileContainer>
                    <LabelText>이름</LabelText>
                    <UserNameText>{member.name}</UserNameText>
                  </ProfileContainer>
                  <ProfileContainer>
                    <LabelText>프로필 이미지</LabelText>
                    <ProfileImg
                      src={member.profileImgUrl || blankUserImg}
                      alt="profileImg"
                    />
                  </ProfileContainer>
                  <ProfileContainer>
                    <LabelText>커버 이미지</LabelText>
                    <ProfileImg
                      src={member.coverImgUrl || blankImg}
                      alt="profileImg"
                    />
                  </ProfileContainer>
                </>
              )}
              {comment && (
                <ProfileContainer>
                  <LabelText>댓글</LabelText>
                  <CommentContainer>
                    <CommentText>{comment.comment}</CommentText>
                  </CommentContainer>
                </ProfileContainer>
              )}
              {memberCollection && (
                <>
                  <ProfileContainer>
                    <LabelText>제목</LabelText>
                    <CommentContainer>
                      <CommentText>{memberCollection.name}</CommentText>
                    </CommentContainer>
                  </ProfileContainer>
                  <ProfileContainer>
                    <LabelText>내용</LabelText>
                    <CommentContainer>
                      <CommentText>{memberCollection.description}</CommentText>
                    </CommentContainer>
                  </ProfileContainer>
                  <ProfileContainer>
                    <LabelText>커버 이미지</LabelText>
                    <ProfileImg
                      src={memberCollection.coverImgUrl || blankImg}
                      alt="coverImg"
                    />
                  </ProfileContainer>
                </>
              )}
              {reportImgUrl && (
                <>
                  <ProfileContainer>
                    <LabelText>첨부 사진</LabelText>
                    <PageSlide>
                      {imgCurrentNo + 1}/{reportImgUrl.length}
                      <SlideButtonWrapper>
                        <PrevButton
                          onClick={() =>
                            onChangeImg(reportImgUrl, imgCurrentNo - 1)
                          }
                        />
                        <NextButton
                          onClick={() =>
                            onChangeImg(reportImgUrl, imgCurrentNo + 1)
                          }
                        />
                      </SlideButtonWrapper>
                    </PageSlide>
                  </ProfileContainer>
                  <SlideBox>
                    <SlideList
                      style={{
                        transform: `translate3d( ${
                          imgCurrentNo * -360
                        }px, 0px, 0px`,
                      }}
                    >
                      {reportImgUrl.map((imgUrl: string) => (
                        <SlideContent>
                          <ImgContainer>
                            <Img src={imgUrl} />
                          </ImgContainer>
                        </SlideContent>
                      ))}
                    </SlideList>
                  </SlideBox>
                </>
              )}
              {contentsUrl?.length > 0 && !contentsUrl.includes('mp3') && (
                <VideoContainer>
                  <LabelText>영상</LabelText>
                  <Video controls autoPlay>
                    <track default kind="captions" />
                    <source src={changedUrl} type="video/mp4" />
                    Your browser does not support the video tag. I suggest you
                    upgrade your browser.
                  </Video>
                </VideoContainer>
              )}
              {board && (
                <ProfileContainer>
                  <LabelText>커뮤니티 보드</LabelText>
                  <CommentContainer>
                    <CommentText>{board.content}</CommentText>
                  </CommentContainer>
                  {board.tags && (
                    <>
                      <br /> <br />
                      <LabelText>태그</LabelText>
                      <CommentContainer>
                        <CommentText>
                          {board.tags.map((tag: string) => `${tag} `)}
                        </CommentText>
                      </CommentContainer>
                    </>
                  )}
                  {board.imgUrls && (
                    <>
                      <br /> <br />
                      <LabelText>첨부 사진</LabelText>
                      <PageSlide>
                        {imgCurrentNo + 1}/{board.imgUrls.length}
                        <SlideButtonWrapper>
                          <PrevButton
                            onClick={() =>
                              onChangeImg(board.imgUrls, imgCurrentNo - 1)
                            }
                          />
                          <NextButton
                            onClick={() =>
                              onChangeImg(board.imgUrls, imgCurrentNo + 1)
                            }
                          />
                        </SlideButtonWrapper>
                      </PageSlide>
                      <SlideBox>
                        <SlideList
                          style={{
                            transform: `translate3d( ${
                              imgCurrentNo * -360
                            }px, 0px, 0px`,
                          }}
                        >
                          {board.imgUrls.map((imgUrl: string) => (
                            <SlideContent>
                              <ImgContainer>
                                <Img src={imgUrl} />
                              </ImgContainer>
                            </SlideContent>
                          ))}
                        </SlideList>
                      </SlideBox>
                    </>
                  )}
                </ProfileContainer>
              )}
            </MediaContainer>
          </ContentWrapper>
        </ContentContainer>
        <ContentContainer>
          {type === 'rekognition' ? (
            <>
              <MainText>rekognition 기준</MainText>
              <ContentWrapper>
                {moderationLabels?.map((el: any) => {
                  const { confidence, name } = el.moderationLabel;
                  return (
                    <RekognitionTextFiledWrapper>
                      <TextField
                        type={type}
                        text={name}
                        label="차단 기준"
                        percent={confidence.toFixed(1)}
                      />
                    </RekognitionTextFiledWrapper>
                  );
                })}
              </ContentWrapper>
            </>
          ) : (
            <>
              <MainText>신고 내역</MainText>
              <ContentWrapper>
                <TextFiledWrapper>
                  <TextField text={handleDate(createdDt)} label="날짜" />
                </TextFiledWrapper>
                <TextFiledWrapper>
                  <TextField
                    text={`${blockType[contentsType]} 신고`}
                    label="분류"
                  />
                </TextFiledWrapper>
                <TextFiledWrapper>
                  {type === '미차단 내역' && (
                    <>
                      <SelectModule
                        name="firstReportOption"
                        placeholder="선택하세요"
                        value={firstReportOption?.name}
                        label="신고 유형"
                        onChange={changeFirstOption}
                        options={masterReportList}
                      />
                      {secondReportOptionList && (
                        <>
                          <SecondSelect />
                          <SelectModule
                            name="secondReportOption"
                            placeholder="선택하세요"
                            value={secondReportOption?.subDescription || null}
                            label="신고 내용"
                            onChange={changeSecondOption}
                            options={secondReportOptionList}
                          />
                        </>
                      )}
                    </>
                  )}
                  {(type === '차단 내역' || type === '삭제 내역') && (
                    <>
                      <TextField
                        text={firstReportOption?.name}
                        label="신고 유형"
                      />
                      {secondReportOptionList && (
                        <>
                          <SecondSelect />
                          <TextField
                            text={secondReportOption?.subDescription || null}
                            label="신고 내용"
                          />
                        </>
                      )}
                    </>
                  )}
                </TextFiledWrapper>
                <TextFiledWrapper>
                  <TextField text={originMemberPid} label="신고 한 Pid" />
                </TextFiledWrapper>
                <TextFiledWrapper>
                  <TextField text={tarMemberPid} label="신고 받은 Pid" />
                </TextFiledWrapper>
                <TextFiledWrapper>
                  <TextField
                    type={type}
                    reportScore={tarMemberReportScore || 0}
                    point={optionReportPoint || 0}
                    label="벌점 현황"
                  />
                </TextFiledWrapper>
              </ContentWrapper>
            </>
          )}
        </ContentContainer>
      </MainContainer>
      {modal && (
        <VideoModal closeModal={closeModal}>
          <VideoModalWrapper>
            <ModalVideo controls autoPlay>
              <track default kind="captions" />
              <source src={changedUrl} type="video/mp4" />
              Your browser does not support the video tag. I suggest you upgrade
              your browser.
            </ModalVideo>
          </VideoModalWrapper>
        </VideoModal>
      )}
    </>
  );
};

export default DetailBlock;

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const EventId = styled.h1`
  width: 500px;
  font-size: 36px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  margin-bottom: 10px;
  letter-spacing: -0.3px;
  line-height: 35px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 50px 0 100px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  padding-right: 36px;

  &:last-child {
    width: 60%;
    padding-right: 0;
    margin-left: 36px;
    border-right: 0;
  }
`;

const MediaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
  min-height: 660px;
`;

const VideoContainer = styled.div`
  position: relative;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CommentContainer = styled.div`
  min-height: 160px;
  padding: 20px 16px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 6px;
`;

const CommentText = styled.span`
  font-weight: 400;
`;

const LabelText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const PageSlide = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const SlideButtonWrapper = styled.div`
  display: flex;
`;

const PrevButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: url(${prevButton}) center no-repeat;
  background-size: 30px;
  margin: 0 14px;
`;
const NextButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: url(${nextButton}) center no-repeat;
  background-size: 30px;
`;

const SlideBox = styled.div`
  position: relative;
  width: 100%;
  margin: auto;
  overflow-x: hidden;
`;

const SlideList = styled.div`
  width: 3400px;
  transition: all 200ms 0s;
  overflow: hidden;
`;

const SlideContent = styled.div`
  display: table;
  float: left;
  width: 360px;
`;

const ImgContainer = styled.div`
  display: table-cell;
  vertical-align: middle;
  text-align: center;
`;

const Img = styled.img`
  width: 100%;
  /* height: auto; */
`;

const VideoModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 668px;
  //margin-top: 82px;
  padding: 98px 180px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalVideo = styled.video`
  max-width: 308px;
`;

const Video = styled.video`
  width: 310px;
  top: 30px;
  left: 0;
  padding-bottom: 60px;
  position: absolute;
`;

const UserNameText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ProfileImg = styled.img`
  width: 308px;
`;

const MainText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const RekognitionTextFiledWrapper = styled.div`
  margin-bottom: 20px;
`;

const TextFiledWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 3) {
    margin-bottom: 20px;
  }
  &:nth-child(4) {
    margin-bottom: 60px;
  }
  &:nth-child(5) {
    margin-bottom: 20px;
  }
`;

const SecondSelect = styled.div`
  margin-bottom: 20px;
`;
