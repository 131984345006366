import React, {useCallback, useEffect, useState} from 'react';

// hooks
import styled from "styled-components";
import axios from "axios";
import NormalButton from "../../../components/common/Button/NormalButton";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import RoundButton from "../../../components/common/Button/RoundButton";
import Pagination from "../../../components/common/Pagination";
import SelectModule from "../../../components/common/Select/SelectModule";
import ListItem from "../../../components/common/List/ListItem";
import Table from "../../../components/common/List/Table";
import ListHeadWithSort from "../../../components/UI/ListHeadWithSort";
import ListHead from "../../../components/UI/ListHead";

const SuggestWordRow = ({ data, setSelected }) => {
  return (<>
    <tr key={data.id}>
      <td>
        <input id={`checkbox_${data.id}`} type="checkbox"
               checked={ data.selected}
               onChange={(e) => setSelected(data.id, e.currentTarget.checked)}/>
      </td>
      <td><ListItem text={data.id} /></td>
      <td><ListItem text={data.name} /></td>
      <td><ListItem text={data.categoryName} /></td>
    </tr>
  </>);
};

const SuggestWord = () => {
  // 카테고리 필터링을 위한 카테고리 목록
  const [categoryAll, setCategoryAll] = useState([]);
  // 검색 조건
  const [categoryIdSearch, setCategoryIdSearch] = useState(0);
  const [categoryNameSearch, setCategoryNameSearch] = useState('전체');
  const [wordSearch, setWordSearch] = useState('');
  const [selectedSortKey, setSelectedSortKey] = useState('');
  const [selectedSortDirection, setSelectedSortDirection] = useState('');
  const [sortParam, setSortParam] = useState('');
  // 검색 결과
  const [suggestWords, setSuggestWords] = useState([]);
  const [suggestWordCount, setSuggestWordCount] = useState(0);
  // 전체 삭제 선택 여부
  const [checkAll, setCheckAll] = useState(false);
  // 삭제할 제시어의 아이디 목록
  const [wordIdsToDelete, setWordIdsToDelete] = useState([]);
  // 추가 정보
  const [wordAdd, setWordAdd] = useState('');
  const [categoryIdAdd, setCategoryIdAdd] = useState(0);
  const [categoryIdName, setCategoryIdName] = useState('');

  const [result, setResult] = useState('');
  const [resultStyle, setResultStyle] = useState('');

  // pagination state
  const postsPerPage = 10;
  // const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
      // storage ? JSON.parse(storage) : 0,
      0
  );

  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    suggestWordCategoryId:categoryIdSearch,
    name: wordSearch,
    sortType: sortParam,
  };

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  // 페이지 초기 표시 ///////////////////////////////////////////////////////////
  // 페이지 진입시 실행
  useEffect(async () => {
    // 카테고리 조회
    await axios.get(`/setting/suggest/word_category`, {
      params: {
        sortType: 'NAME_ASC',
        limit: 100,
        offset: 0,
      },
    })
    .then(res => {
      setCategoryAll(res.data.suggestWordCategories);
    })
    .catch (err => {
      console.log(err);
      alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  }, []);

  // 조회 //////////////////////////////////////////////////////////////////////
  // 제시어 불러오기
  const getSuggestWords = async () => {
    await axios.get(`/setting/suggest/words`, {
      params,
    })
    .then(res => {
      setSuggestWords(res.data.suggestWords.map((cat) => {
            return {
              id: cat.id, name: cat.name, categoryName: cat.categoryName, selected: false
            }
          })
      );
      setSuggestWordCount(res.data.count || 0);
      setSelectedAll(false);
    })
    .catch (err => {
      console.log(err);
      alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  };
  // 페이지 변경시 조회
  useEffect(() => {
    getSuggestWords().then();
  }, [postsPerPage, pageNumber]);

  // 검색 버튼으로 제시어 검색
  const getSuggestWordsFromFirstPage = () => {
    let isCallManual = pageNumber === 0;
    setPageNumber(0);
    // 이미 첫번째 페이지 인 경우, 명시적으로 검색 수행
    if (isCallManual) {
      getSuggestWords().then();
    }
  };
  // 검색 조건 변경시에는 페이지 1부터 조회
  useEffect(() => {
    getSuggestWordsFromFirstPage();
  }, [sortParam, categoryIdSearch]);

  // 입력받은 검색어 설정하고, 입력값이 없을때는 자동 검색
  const setWordSearchAndDoSearchOnBlank = (_wordSearch) => {
    //입력 받은 검색어 설정
    setWordSearch(_wordSearch);

    // 입력값이 공백일 때 검색 수행
    const blankPattern = /^\s+|\s+$/g;
    const isBlank = _wordSearch.replace(blankPattern, '') === '';
    if (_wordSearch === '' || isBlank) {
      // 즉시 검색을 위해 직접 설정
      params.name = _wordSearch;
      getSuggestWordsFromFirstPage();
    }
  }

  // 정렬 방법 변경
  const setSort = (sortKey, sortDirection) => {
    setSelectedSortKey(sortKey);
    setSelectedSortDirection(sortDirection);

    if (!sortKey || !sortDirection) {
      setSortParam(null);
    } else {
      setSortParam(sortKey.concat('_', sortDirection));
    }
  }

  // 추가 //////////////////////////////////////////////////////////////////////
  // 제시어 추가 영역에서 카테고리 변경시
  const setCategoryInfoForAdd = (selectedOption) => {
    setCategoryIdAdd(selectedOption.id);
    setCategoryIdName(selectedOption.name);
    setResult('');
    setResultStyle('');
  }

  // 제시어 추가
  const addSuggestWord = async () => {
    if (categoryIdAdd === 0) {
      setResult('카테고리를 선택해 주세요.');
      setResultStyle('error');
      return;
    }

    if (!wordAdd) {
      setResult('제시어를 입력해 주세요.');
      setResultStyle('error');
      return;
    }

    setResult('');
    setResultStyle('');

    await axios.post(
        `/setting/suggest/words`,
        {},
        {
          params : {
            names: wordAdd.split(','),
            suggestWordCategoryId: categoryIdAdd
          }
        },
    )
    .then(() => {
      setResult('제시어가 추가되었습니다.');
      setResultStyle('normal');

      setWordAdd('');

      // 동일 카테고리에 복수의 단어 입력시 편의를 위해 카테고리 초기화 하지 않음
      // setCategoryIdAdd(0);
      // setCategoryIdName('');

      getSuggestWordsFromFirstPage();
    })
    .catch (err => {
      console.log(err.response);
      setResult(err.response.data.message);
      setResultStyle('error');
      // alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  };

  // 엔터키로 제시어 저장
  const addByEnter = (e) => {
    if (e.key === 'Enter') {
      // 공백 체크
      const blankPattern = /^\s+|\s+$/g;
      const isBlank = wordAdd.replace(blankPattern, '') === '';
      if (wordAdd !== '' && !isBlank) {
        addSuggestWord().then();
      }
    }
  };

  // 삭제 //////////////////////////////////////////////////////////////////////
  // 제시어 삭제
  const deleteWord = async () => {
    if(wordIdsToDelete.length === 0) {
      return;
    }

    if (!confirm('선택한 제시어를 삭제하시겠습니까?.\n삭제 시, 해당 제시어는 랜덤 제시어로 노출되지 않습니다.')) {
      return;
    }

    await axios.delete(`/setting/suggest/words`, {
      params:{
        suggestWordIds: wordIdsToDelete
      }
    })
    .then(() => {
      // getSuggestWordsFromFirstPage();
      getSuggestWords();
      setCheckAll(false);
    })
    .catch (err => {
      console.log(err);
      alert(`문제가 발생하였습니다. 담당자에게 문의해주세요.`);
    });
  };

  // 제시어 전체 선택
  const setSelectedAll = (checked) => {
    suggestWords.map((item) => {
      item.selected = checked;
    });

    const arr = [];
    //선택한 것이면
    if (checked) {
      // 전체 추가
      suggestWords.forEach((el) => {
        arr.push(el.id);
      });
    }
    setWordIdsToDelete(arr);
    setCheckAll(checked);
  }

  // 제시어 선택
  const setSelected = (wordId, checked) => {
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    suggestWords.map((item) => {
      if (item.id === wordId) {
        item.selected = checked;
      }
    });

    setWordIdsToDelete(
        //선택한 것이면
        checked
            // 아이디 목록에 추가
            ? [...wordIdsToDelete, wordId]
            // 아이디 목록에서 제외
            : wordIdsToDelete.filter((item)=> item !== wordId)
    );
    setCheckAll(false);
  }

  //추가할 제시어 공백 제거
  const setWordAddWithoutBlank = (_wordAdd) => {
    _wordAdd = _wordAdd.replace(/^,/,'');
    _wordAdd = _wordAdd.replace(/ /g, '');
    _wordAdd = _wordAdd.replace(/,,/g, ',');
    setWordAdd(_wordAdd);
    setResult('');
    setResultStyle('');
  }

  return (<>

    <TitleText>제시어</TitleText>
    <LineDiv/>

    <MainContainer>
      {/*검색*/}
      <InputWrapper span={3}>
        <LabelTextInput
            name="suggestWordSearch"
            label={"검색"}
            placeholder="검색어를 입력해주세요."
            value={wordSearch}
            onChange={(e)=>setWordSearchAndDoSearchOnBlank(e.target.value)}
            // onKeyPress={searchByEnter}
        />
      </InputWrapper>
      <InputWrapper mt={31}>
        <RoundButton title="검색" selected={true} onClick={getSuggestWordsFromFirstPage}/>
      </InputWrapper>

      {/*카테고리*/}
      <InputWrapper span={4}>
        <ButtonWrapper>
          <RoundButton
              key={0}
              title={'전체'}
              selected={categoryIdSearch === 0}
              onClick={() => {
                setCategoryNameSearch('전체');
                setCategoryIdSearch(0);
              }}
          />
          {categoryAll.map(cat =>
              <RoundButton
                  key={cat.id}
                  title={cat.name}
                  selected={categoryIdSearch === cat.id}
                  onClick={() => {
                    setCategoryNameSearch(cat.name);
                    setCategoryIdSearch(cat.id);
                  }}
              />
          )}
        </ButtonWrapper>
      </InputWrapper>

      {/*제시어 목록*/}
      <InputWrapper span={2}>
        {categoryNameSearch} : {suggestWordCount}
      </InputWrapper>
      <InputWrapper span={2}>
        <NormalButton
            text="선택 삭제"
            className={(wordIdsToDelete.length === 0) ? "unActiveBtn": "blockBtn"}
            onSubmit={deleteWord}
        />
      </InputWrapper>
      <InputWrapper span={4}>
        <TopContainer>
          {suggestWordCount !== 0 && (
              <PaginationWrapper>
                <Pagination
                    postsPerPage={postsPerPage}
                    totalCount={suggestWordCount}
                    currentPage={pageNumber}
                    pageDispatch={pageDispatch}
                />
              </PaginationWrapper>
          )}
        </TopContainer>
      </InputWrapper>

      <InputWrapper span={4}>
        <TableWrapper>
        <Table
            colList={[10, 10, 100, 20]}
            thead={
              <>
                <TableHead>
                  <input id={`checkbox_0`} type="checkbox"
                         checked={checkAll}
                         onChange={(e) => setSelectedAll(e.currentTarget.checked)}/>
                </TableHead>
                <ListHeadWithSort
                    title="No"
                    sortKey={"CREATE_DATE"}
                    selectedSortKey={selectedSortKey}
                    selectedSortDirection={selectedSortDirection}
                    onClick={setSort}
                />
                <ListHeadWithSort
                    title="제시어 이름"
                    sortKey={"NAME"}
                    selectedSortKey={selectedSortKey}
                    selectedSortDirection={selectedSortDirection}
                    onClick={setSort}
                />
                <ListHead title="카테고리" />
              </>
            }
        >
          {suggestWords?.map((el) => (
              <SuggestWordRow
                  key={el.id}
                  data={el}
                  setSelected={setSelected}
              />
          ))}
        </Table>
        </TableWrapper>
      </InputWrapper>

      {/*추가*/}
      <InputWrapper >
        <SelectModule
            name="suggestWordCategoryIdAdd"
            placeholder="카테고리 선택"
            value={categoryIdName}
            label="제시어 추가"
            options={categoryAll}
            onChange={setCategoryInfoForAdd}
            Required
        />
      </InputWrapper>

      <InputWrapper mt={16}>
        <LabelTextInput
            name={"suggestWordAdd"}
            label={""}
            placeholder="제시어를 입력해주세요.(2개 이상의 단어는 컴마(,)로 구분하며, 공백은 삭제됩니다.)"
            value={wordAdd}
            onChange={(e) => setWordAddWithoutBlank(e.target.value)}
            onKeyPress={addByEnter}
        />
        {result && <ResultText className={resultStyle}>{result}</ResultText>}
      </InputWrapper>

      <InputWrapper span={2} mt={31}>
        <NormalButton
            text="추가"
            className={(categoryIdAdd === 0) ? "unActiveBtn": "blockBtn"}
            onSubmit={addSuggestWord}
        />
      </InputWrapper>


    </MainContainer>
  </>);
};
export default SuggestWord;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;

  button {
    // &:last-child {
    //   margin-left: 20px;
    // }
    margin-bottom: 10px;
  }
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
  min-height: 30px;
`;

const PaginationWrapper = styled.div`
`;

const TableWrapper = styled.div`
  min-height: 765px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
  grid-template-columns: 4fr 16fr 1fr 2fr ;
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const ResultText = styled.span`
  position: absolute;
  margin-top: 12px;
  font-size: 14px;
  &.error {
    color: ${({ theme }) => theme.color.mainRed};
  }
  &.normal {
    color: ${({ theme }) => theme.color.mainBlue};
  }
`;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;
