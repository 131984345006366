import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

// asset
import closeRoundButton from '../../../assets/images/page/largeCloseButton.png';
import plusButton from '../../../assets/images/page/purplePlusIcon.png';

// 타입
import { CustomProps } from '../../../definitions/common';
import {hasSpecialSymbol} from "../../../utils/commonFn";

interface TextInputProps {
  name: string;
  label: string;
  Required?: boolean;
  value: Array<any>;
  onClick?: any;
  onRemove?: any;
  onEdit?: any;
  readOnly?: boolean;
  placeholder?: string;
  maxCount?: number;
  forbidSpecialSymbol?: boolean;
}

const LabelPlusButtonInput = ({
  name,
  value,
  label,
  Required,
  onClick,
  onRemove,
  onEdit,
  readOnly,
  placeholder,
  maxCount,
  forbidSpecialSymbol
}: TextInputProps) => {
  const [content, setContent] = useState('');
  const [editValue, setEditValue] = useState('');
  const [textareaHeight, setTextareaHeight] = useState<string>();
  const [editTextareaHeight, setEditTextareaHeight] = useState<string>();
  // 업데이트 state
  const [isUpdate, setIsUpdate] = useState<any>('');
  const inputRef = useRef<any>(null);
  const ref = useRef<any>(null);

  let placeholderText = placeholder ? placeholder:
      <>유의사항의 항목을 입력해 주세요.<span>(∙ 입력하신 항목 내용)</span>으로 표기 됩니다.</>;

  // textarea 높이 자동 조절
  const autoResizeTextarea = (e: any) => {
    if (!(forbidSpecialSymbol && hasSpecialSymbol(e.target.value))) {
      const height = e.target.value;
      setTextareaHeight(height);
      setContent(e.target.value);
    }
  };

  const handleSubmit = () => {
    // 만약 input 창이 빈채로 submit을 하려고 할 땐 return시키기
    if (!content || content.replace(/\s/g, '') === '') return;
    onClick(content);
    // submit을 한 후에는 input 창을 비우기
    setContent('');
    setTextareaHeight('');
  };

  //수정할 때 값 state에 저장
  const onChangeInput = (e: any) => {
    if (!(forbidSpecialSymbol && hasSpecialSymbol(e.target.value))) {
      const height = e.target.value;
      setEditTextareaHeight(height);
      setEditValue(e.target.value);
    }
  };

  // 커서 다른 곳으로 옮겼을 때 상태값 저장
  const onBlurInput = () => {
    onEdit(isUpdate, editValue);
    setIsUpdate('');
  };

  // 키를 다른 곳으로 옮겼을 때 상태값 저장
  const onKeyUpInput = (e: any) => {
    if (e.key === 'Escape') {
      onEdit(isUpdate, editValue);
      setIsUpdate('');
    }
  };

  // editbutton 클릭했을 때
  const onClickEditButton = (id: number, text: string) => {
    setIsUpdate(id);
    setEditValue(text);
  };

  useEffect(() => {
    if (ref === null || ref.current === null) {
      return;
    }
    ref.current.style.height = 'auto';
    const scrollHeights = ref.current.scrollHeight;
    ref.current.style.height = `${scrollHeights}px`;
  }, [textareaHeight]);

  useEffect(() => {
    if (inputRef === null || inputRef.current === null) {
      return;
    }
    inputRef.current.style.height = 'auto';
    const scrollHeights = inputRef.current.scrollHeight;
    inputRef.current.style.height = `${scrollHeights + 8}px`;
  }, [editTextareaHeight, isUpdate]);

  return (
    <InputContainer className={name}>
      {label && (
        <InputText>
          {label} {Required && <span>*</span>}
        </InputText>
      )}
      {value.length !== 0 &&
        value.map((noticeText: any) => (
          <InputBox key={noticeText.id}>
            {isUpdate === noticeText.id ? (
              <Textarea
                value={editValue}
                onBlur={onBlurInput}
                onKeyUp={onKeyUpInput}
                onChange={onChangeInput}
                ref={inputRef}
              />
            ) : (
              <NoticeText>
                {noticeText.text}
                {!readOnly && (
                  <EditButton
                    className="editButton"
                    onClick={() =>
                      onClickEditButton(noticeText.id, noticeText.text)
                    }
                  >
                    <span>수정</span>
                  </EditButton>
                )}
              </NoticeText>
            )}
            {!readOnly && (
              <PlusButton
                onClick={() => onRemove(noticeText.id)}
                value={closeRoundButton}
              />
            )}
          </InputBox>
        ))}
      {!readOnly && (!maxCount || (maxCount && value.length < maxCount)) && (
        <InputBox>
          <Label>
            <Textarea value={content} onChange={autoResizeTextarea} ref={ref} />
            {!content && (
              <PlaceHolderText>
                {placeholderText}
              </PlaceHolderText>
            )}
          </Label>
          <PlusButton onClick={handleSubmit} value={plusButton} />
        </InputBox>
      )}
    </InputContainer>
  );
};

export default LabelPlusButtonInput;

const InputContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div<CustomProps>`
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Textarea = styled.textarea`
  position: relative;
  width: 100%;
  height: auto;
  padding: 20px 40px 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const NoticeText = styled.div`
  display: flex;
  width: 100%;
  word-break: break-all;
  white-space: pre-wrap;
  height: auto;
  align-items: center;
  padding: 20px 80px 20px 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const PlaceHolderText = styled.p`
  position: absolute;
  margin-left: 20px;
  font-size: 18px;
  color: ${({ theme }) => theme.color.subGray};
  font-weight: 500;

  span {
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const PlusButton = styled.button<CustomProps>`
  display: block;
  width: 45px;
  height: 45px;
  margin-left: 10px;
  background: url(${({ value }) => value}) center no-repeat;
  background-size: 45px;
  cursor: pointer;
`;

const EditButton = styled.button`
  position: absolute;
  width: 20px;
  height: 20px;
  right: 110px;
  cursor: pointer;

  span {
    word-break: keep-all;
    padding: 7px 11px;
    font-size: 11px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
    color: ${({ theme }) => theme.color.subDarkGray};
    border-radius: 30px;
  }
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
`;
