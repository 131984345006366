import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';

// common components
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import DateInput from '../../../components/common/Input/DateInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';

// redux
import {setLoading, resetLoading} from '../../../redux/slices/user';

//타입
import {limitCall} from '../../../utils/utils';
import EditorQuill from "../../../components/common/editor/EditorQuill";
import LabelTextAreaInput
	from "../../../components/common/Input/LabelTextAreaInput";
import RoundButton from "../../../components/common/Button/RoundButton";
import useInput from "../../../hooks/useInput";
import {getBeatList} from "../../../redux/actions/beat/beat";
import VideoInput from "../../../components/common/Input/VideoInput";
import ListHead from "../../../components/UI/ListHead";
import CuratedBeatRow from "../../beat/curatedBeat/CuratedBeatRow";
import VideoModal from "../../../components/common/Modal/VideoModal";
import Table from "../../../components/common/List/Table";
import {
	addBeatCollectionColList,
} from "../../../constant/explore";
import DetailAddBeatRowComponents
	from "../../explore/components/category/DetailAddBeatRowComponents";
import SearchMenuSelect
	from "../../../components/common/Select/SearchMenuSelect";
import {beatSearchTypeList} from "../../../constant/beat";
import SearchInput from "../../../components/common/Input/SearchInput";
import Pagination from "../../../components/common/Pagination";
import ListDateSelect from "../../../components/common/Select/ListDateSelect";
import {useAppSelector} from "../../../redux/store/hooks";
import CuratedCollectionRow, {
	curatedCollectionList
} from "./component/CuratedCollectionRow";
import WorkCollectionRow from "./component/WorkCollectionRow";
import CultureMenuSelect from "../component/CultureMenuSelect";
import {
	ARTICLE,
	MAGAZINE,
	MEMBER_COLLECTION_LIST
} from "../../../constant/apiUrl";
import {
	countryOptions,
	GetCountryOption,
	GetLanguageOption,
	languageOptions,
	postsPerPage
} from "../../../constant/common";
import {eventModalColList} from "../../../constant/event";
import {
	EventModalRowComponent,
	SelectedEventRowComponent
} from "../../operation/components/EventModalRowComponent";
import {articleSearchTypeList} from "../../../constant/video";
import CuratedArticleRow
	from "../../explore/curatedArticle/component/CuratedArticleRow";

const MagazineDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const detailData = location.state;
	const prevId = detailData !== null ? detailData.prevId : null;
	const nextSequence = detailData !== null ? detailData.nextSequence : null;

	const today = new Date();
	const todayWithFormat =
			today.getFullYear() +
			'-' +
			('0' + (today.getMonth() + 1)).slice(-2) +
			'-' +
			('0' + today.getDate()).slice(-2) +
			'T' +
			('0' + today.getHours()).slice(-2) +
			':' +
			('0' + today.getMinutes()).slice(-2);

	// 제목
	const [title, setTitle] = useState('');
	// 배너 이미지
	const [bannerImgUrl, setBannerImgUrl] = useState('');
	const [bannerImgFile, setBannerImgFile] = useState(null);
	// 커버 이미지
	const [coverImgUrl, setCoverImgUrl] = useState('');
	const [coverImgFile, setCoverImgFile] = useState(null);
	// 내용
	const [contents, setContents] = useState('');
	// 언어
	const [language, setLanguage] = useState('');
	// 국가 코드
	const [countryCode, setCountryCode] = useState('');
	// 활성화 여부
	const [activeYn, setActiveYn] = useState('Y');
	// 시작 시간
	const [startDt, setStartDt] = useState(todayWithFormat);
	// 종료 시간
	const [endDt, setEndDt] = useState('2222-12-31 23:59');

	// 서문
	const [introduction, setIntroduction] = useState('');
	const [introductionStatus, setIntroductionStatus] = useState('');
	// 컬렉션 정보
	const [memberCollectionIds , setMemberCollectionIds] = useState([]);
	// 비트 정보
	const [beatIds, setBeatIds] = useState([]);
	// 영상 정보
	const [articleIds, setArticleIds] = useState([]);

	const [magazineCategoryId, setMagazineCategoryId] = useState(
			detailData !== null ? detailData.magazineCategoryId : 0
	);
	const [magazineCategoryName, setMagazineCategoryName] = useState(
			detailData !== null ? detailData.magazineCategoryName : '카테고리 선택'
	);
	const [magazineCategories, setMagazineCategories] = useState(
			detailData !== null ? detailData.magazineCategories : []
	);

	const handleSelectSetData = (e) => {
		setMagazineCategoryId(e.magazineCategoryId);
		// setMagazineCategoryName(e.name);
	};

	useEffect(() => {
		const result = magazineCategories.filter(item => item.magazineCategoryId === magazineCategoryId);
		if (result[0]) {
			setMagazineCategoryName(result[0].name);
		}
	}, [magazineCategoryId, magazineCategories]);

	//상세 조회
	const getDetail = useCallback(async () => {
			await axios.get(`${MAGAZINE}/${prevId}`)
			.then(res => {
				const detail = res.data.magazine;
				// console.log('MagazineDetail > getDetail > detail :', detail);
				setMagazineCategoryId(detail.magazineCategoryId);
				setTitle(detail.title);
				setBannerImgUrl(detail.bannerImgUrl);
				setCoverImgUrl(detail.coverImgUrl);
				setContents(detail.contents);
				setIntroduction(detail.introduction);
				setMemberCollectionIds(detail.memberCollectionIds);
				setBeatIds(detail.beatIds);
				setArticleIds(detail.articleIds);
				setLanguage(detail.language);
				setCountryCode(detail.countryCode);
				setActiveYn(detail.activeYn);
				setStartDt(detail.startDt?.replace(' ', 'T').slice(0, 16));
				setEndDt(detail.endDt?.replace(' ', 'T').slice(0, 16));

				setMemberCollectionCurated(res.data.adminMemberCollections);
				setBeatCurated(res.data.adminBeats);
				setArticleCurated(res.data.adminArticles);

			})
			.catch(reason => {
				console.error(reason);
				alert('시스템 관리자에게 문의해 주세요');
			});
	}, [prevId]);

	// 날짜 저장시 사용
	const handleDate = (date, tRemove, start) => {
		let _date = date;

		if (tRemove && date.indexOf('T') < 0) {
			_date = date.split(' ');
			_date = _date[0] + 'T' + _date[1];
		}
		if (!tRemove && date.indexOf('T') > 0) {
			_date = date.split('T');
			_date = _date[0] + ' ' + _date[1];
		}
		return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
	}

	// 취소하면 뒤로가기
	const goBack = () => navigate('/culture/magazine');

	const handleIntroduction = (_introduction) => {
		setIntroductionStatus(
				(_introduction.length > 200)
					? '서문은 최대 200자까지 입력할 수 있습니다.'
					: ''
		)
		setIntroduction(_introduction);
	}

	/* 저장 및 수정 */
	const handleEventBanner = limitCall(async () => {

		if (!magazineCategoryId) {
			alert('카테고리를 선택해주세요.');
			return;
		}

		// 데이터 유효성 체크
		if (!activeYn || !startDt || !endDt || !title || !countryCode || !language || !contents) {
			alert('양식을 모두 채워주세요.');
			return;
		}
		if (startDt > endDt) {
			alert('시작일시가 종료일시보다 느릴 수 없습니다.');
			return;
		}
		if (new Date() > new Date(endDt)) {
			alert('종료일시를 오늘 날짜보다 뒤에 설정해 주세요.');
			return;
		}

		if (introductionStatus) {
			alert('서문 입력 내용을 확인해 주세요.');
			return;
		}

		if (!prevId && (!bannerImgFile || !coverImgFile)) {
			alert('이미지를 등록해주세요.');
			return;
		}
		const formData = new FormData();
		formData.append('magazineBannerImgFile', bannerImgFile);
		formData.append('magazineCoverImgFile', coverImgFile);

		const request = {};
		request['magazineCategoryId'] = magazineCategoryId;
		request['title'] = title;
		request['contents'] = contents;
		request['introduction'] = introduction;
		request['memberCollectionIds'] = memberCollectionIds;
		request['beatIds'] = beatIds;
		request['articleIds'] = articleIds;
		request['language'] = language;
		request['countryCode'] = countryCode;
		request['activeYn'] = activeYn;
		request['startDate'] = handleDate(startDt, false, true);
		request['endDate'] = handleDate(endDt, false, false);
		request['nextSequence'] = nextSequence === null ? 0: nextSequence;

		Object.keys(request).forEach(key => formData.append(key, request[key]));

		dispatch(setLoading());
		await axios.post(
				prevId ? `${MAGAZINE}/${prevId}` : MAGAZINE,
				formData,
				{}
		)
		.then( () => {
			alert('저장되었습니다.');
			navigate('/culture/magazine');
		})
		.catch(reason => {
			console.error(reason);
			alert('시스템 관리자에게 문의해 주세요');
		})
		.finally(() => dispatch(resetLoading()))
	}, 'magazine-detail');

	// 수정으로 페이지 진입시, 상세 조회
	useEffect(() => {
		if (prevId) {
			getDetail().then((r) => r);
		}
	}, [getDetail, prevId]);

	//////////////////////////////////////////////////////////////////////////////
	// 컬렉션, 비트 검색 팝업 관련
	//////////////////////////////////////////////////////////////////////////////
	const [modalType, setModalType] = useState('');
	const [beatType, setBeatType] = useState('');
	const [keyword, setKeyword, onReset] = useInput('');
	const [searchType, setSearchType] = useState('');
	const [minBpm, setMinBpm] = useState(0);
	const [maxBpm, setMaxBpm] = useState(250);
	const [minStartDate, setMinStartDate] = useState('');
	const [maxStartDate, setMaxStartDate] = useState('');

	const [playBeat, setPlayBeat] = useState('');

	const [useBaundCollection, setUseBaundCollection] = useState(true);

	const [memberCollectionCurated, setMemberCollectionCurated] = useState([]);
	const [memberCollectionWork, setMemberCollectionWork] = useState([]);
	const [memberCollectionMaster, setMemberCollectionMaster] = useState([]);
	const [memberCollectionTotalCount, setMemberCollectionTotalCount] = useState(0);

	const [beatCurated, setBeatCurated] = useState([]);
	const [beatWork, setBeatWork] = useState([]);

	const [articleCurated, setArticleCurated] = useState([]);
	const [articleWork, setArticleWork] = useState([]);
	const [articleMaster, setArticleMaster] = useState([]);
	const [articleTotalCount, setArticleTotalCount] = useState([]);

	const [pageNumber, setPageNumber] = useState(0);
	const beatTotalCount = useAppSelector((state) => state.beat.beatTotalCount);
	const beatMaster = useAppSelector((state) => state.beat.beatList);

	const pageDispatch = useCallback((e) => {
		setPageNumber(e - 1);
	}, []);

	const beatParams = {
		offset: pageNumber * postsPerPage,
		limit: postsPerPage,
		word: keyword,
		type: beatType,
		maxBpm,
		minBpm,
		maxStartDate,
		minStartDate,
		privateYn: 'N',
	};

	// 모달 열기
	const openModal = (selectedModalType) => {
		setModalType(selectedModalType);
		if (selectedModalType ==='beat') {
			setBeatWork(beatCurated || []);
			dispatch(getBeatList(beatParams));
		}
		if (selectedModalType === 'collection' ){
			setMemberCollectionWork(memberCollectionCurated || []);
			getCollectionMaster().then(() => {});
		}
		if (selectedModalType === 'article') {
			setArticleWork(articleCurated || []);
			getArticleMaster().then(() => {});
		}
	};

	// modal 닫기
	const closeModal = () => {
		setModalType('');
		onReset();
		setPageNumber(0);
		setSearchType('');

		// beat state 초기화
		setBeatType('');
		setMinBpm(0);
		setMaxBpm(250);
		setMinStartDate('');
		setMaxStartDate('');

		setBeatWork([]);
		setArticleWork([]);

		setUseBaundCollection(true);
	};

	// 비트 모달 처리 /////////////////////////////////////////////////////////////
	// 모달안에서 영상이나 비트 저장
	const saveBeatWork = () => {
		setBeatCurated(beatWork);
		closeModal();
	};

	// 클릭시 추가
	const addSelectedBeat = (data) => {
		if (beatWork.length >=50) {
			alert('비트는 50개까지 등록할 수 있습니다.');
			return;
		}
		if (!beatWork?.find((e) => e.id === data.id)) {
			setBeatWork([...beatWork, data]);
		}
	};

	// 클릭시 삭제
	const filterSelectedBeat = (el) => {
		const result = beatWork.filter((item) => item !== el);
		setBeatWork(result);
	};

	// 선택된 비트 정보에서 아이디 추출
	useEffect(() => {
		setBeatIds(beatCurated.map((el) => el.id));
	}, [beatCurated]);

	// 비트 검색
	useEffect(() => {
		if (modalType ==='beat') {
			dispatch(getBeatList(beatParams));
		}
		if (modalType === 'collection' ) {
			getCollectionMaster().then(() => {});
		}
		if (modalType === 'article' ) {
			getArticleMaster().then(() => {});
		}
	}, [pageNumber, searchType, beatType]);

	// type, bpm, privateUse, openDate
	const handleSelect = (e, setState) => {
		setState(e.currentTarget.title);
	};

	// 엔터키로 검색
	const enterKeyPress = (e) => {
		if (e.key === 'Enter') {
			beatParams.offset = 0;
			setPageNumber(0);
			if (!searchType) {
				setSearchType('I');
				// userParams.type = 'I';
				setBeatType('T');
				beatParams.type = 'T';
				setPageNumber(0);
			}
			dispatch(getBeatList(beatParams));
		}
	};

	// 드래그 앤 드롭
	const moveBeatListItem = useCallback(
			(dragIndex, hoverIndex) => {
				const dragItem = beatCurated[dragIndex];
				const hoverItem = beatCurated[hoverIndex];

				// eslint-disable-next-line @typescript-eslint/no-shadow
				setBeatCurated((temp) => {
					const workList = [...temp];
					workList[dragIndex] = hoverItem;
					workList[hoverIndex] = dragItem;
					return workList;
				});
			},
			[beatCurated],
	);

	// 컬렉션 모달 처리 ///////////////////////////////////////////////////////////
	const collectionParams = {
		limit: postsPerPage,
		offset: pageNumber * postsPerPage,
	};

	// 컬렉션 정보 조회
	const getCollectionMaster = async () => {
		await axios.get(
				(useBaundCollection) ? `${MEMBER_COLLECTION_LIST}/official` : MEMBER_COLLECTION_LIST,
				{params: collectionParams}
		)
		.then( resp => {
			setMemberCollectionMaster(resp.data.memberCollectionList);
			setMemberCollectionTotalCount(resp.data.count || 0);
		})
		.catch(reason => {
			console.error(reason);
			alert('시스템 관리자에게 문의해 주세요');
		});
	};

	// 모달안에서 영상이나 비트 저장
	const saveCollectionWork = () => {
		setMemberCollectionCurated(memberCollectionWork);
		closeModal();
	};

	// 클릭시 추가
	const addSelectedCollection = (data) => {
		if (memberCollectionWork.length >=15) {
			alert('컬렉션은 15개까지 등록할 수 있습니다.');
			return;
		}
		if (!memberCollectionWork?.find((e) => e.id === data.id)) {
			setMemberCollectionWork([...memberCollectionWork, data]);
		}
	};

	// 클릭시 삭제
	const filterSelectedCollection = (el) => {
		const result = memberCollectionWork.filter((item) => item !== el);
		setMemberCollectionWork(result);
	};

	// 선택된 컬렉션 정보에서 아이디 추출
	useEffect(() => {
		setMemberCollectionIds(memberCollectionCurated.map((el) => el.id));
	}, [memberCollectionCurated]);

	// 드래그 앤 드롭
	const moveCollectionListItem = useCallback(
			(dragIndex, hoverIndex) => {
				const dragItem = memberCollectionCurated[dragIndex];
				const hoverItem = memberCollectionCurated[hoverIndex];

				setMemberCollectionCurated((temp) => {
					const workList = [...temp];
					workList[dragIndex] = hoverItem;
					workList[hoverIndex] = dragItem;
					return workList;
				});
			},
			[memberCollectionCurated],
	);

	// 영상 모달 처리 ///////////////////////////////////////////////////////////
	const articleParams = {
		offset: pageNumber * postsPerPage,
		limit: postsPerPage,
		keyword,
		searchType,
		articleType: 'NR',
		privateYn: 'N',
	};

	// 영상 정보 조회
	const getArticleMaster = async () => {
		await axios.get(ARTICLE, {params: articleParams})
		.then( resp => {
			setArticleMaster(resp.data.articleList);
			setArticleTotalCount(resp.data.count || 0);
		})
		.catch(reason => {
			console.error(reason);
			alert('시스템 관리자에게 문의해 주세요');
		});
	};

	// 모달안에서 영상 저장
	const saveArticleWork = () => {
		setArticleCurated(articleWork);
		closeModal();
	};

	// 클릭시 추가
	const addSelectedArticle = (data) => {
		if (articleWork.length >=50) {
			alert('영상은 50개까지 등록할 수 있습니다.');
			return;
		}
		if (!articleWork?.find((e) => e.id === data.id)) {
			setArticleWork([...articleWork, data]);
		}
	};

	// 클릭시 삭제
	const filterSelectedArticle = (el) => {
		const result = articleWork.filter((item) => item !== el);
		setArticleWork(result);
	};

	// 선택된 영상 정보에서 아이디 추출
	useEffect(() => {
		setArticleIds(articleCurated.map((el) => el.id));
	}, [articleCurated]);

	// 드래그 앤 드롭
	const moveArticleListItem = useCallback(
			(dragIndex, hoverIndex) => {
				const dragItem = articleCurated[dragIndex];
				const hoverItem = articleCurated[hoverIndex];

				setArticleCurated((temp) => {
					const workList = [...temp];
					workList[dragIndex] = hoverItem;
					workList[hoverIndex] = dragItem;
					return workList;
				});
			},
			[articleCurated],
	);

	return <>
		<TopContainer>
			<ButtonWrapper>
				<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>
				<NormalButton
					text={prevId ? '수정하기' : '저장하기'}
					onSubmit={() => handleEventBanner().then(() => dispatch(resetLoading()))}
				/>
			</ButtonWrapper>
		</TopContainer>
		<TitleText>매거진 정보{prevId ? (' : ' + prevId) : ''}</TitleText>
		<LineDiv/>
		<MainContainer>
			<InputWrapper span={2}>
				<CultureMenuSelect
						placeholder="카테고리를 선택해주세요."
						value={magazineCategoryName}
						onChange={handleSelectSetData}
						options={magazineCategories}
						label="카테고리"
						Required
				/>
			</InputWrapper>

			{/*제목*/}
			<InputWrapper span={2}>
				<LabelTextInput
					name="title" label="제목" placeholder="제목을 입력해주세요."
					value={title} onChange={(e) => setTitle(e.target.value)} onReset={() => setTitle('')}
					Required
				/>
			</InputWrapper>

			{/*배너 이미지*/}
			<InputWrapper >
				<VerticalFileWrapper>
					<FileInput
						type="eventImg" label="매거진 썸네일 이미지" name="bannerImgFile"
						placeholder={bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
						value={bannerImgFile}
						setFile={(e) => setBannerImgFile(e)}
						setImgUrl={setBannerImgUrl}
						Required
					/>
					<CoverImgWrapper>
						{bannerImgUrl ? <EventImg src={bannerImgUrl } /> : <EventImg />}
					</CoverImgWrapper>
				</VerticalFileWrapper>
			</InputWrapper>

			{/*커버 이미지*/}
			<InputWrapper >
				<VerticalFileWrapper>
					<FileInput
							type="eventImg" label="상세 상단 이미지" name="coverImgFile"
							placeholder={coverImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
							value={coverImgFile}
							setFile={(e) => setCoverImgFile(e)}
							setImgUrl={setCoverImgUrl}
							Required
					/>
					<CoverImgWrapper>
						{coverImgUrl ? <EventImg src={coverImgUrl } /> : <EventImg />}
					</CoverImgWrapper>
				</VerticalFileWrapper>
			</InputWrapper>

			{/*언어*/}
			<InputWrapper>
				<SelectModule
						name="language" placeholder="언어 코드를 선택해주세요." label="언어 코드"
						value={GetLanguageOption[language]} onChange={e => setLanguage(e.value)} options={languageOptions}
						Required
				/>
			</InputWrapper>

			{/*국가*/}
			<InputWrapper>
				<SelectModule
						name="countryCode" placeholder="국가를 선택해주세요." label="국가"
						value={GetCountryOption[countryCode]} onChange={e => setCountryCode(e.value)} options={countryOptions}
						Required
				/>
			</InputWrapper>

			{/*공개 여부*/}
			<InputWrapper span={2}>
				<RadioButton
						name="activeYn" label="공개 여부" type="activeYn"
						value={activeYn} onChange={(e) => setActiveYn(e.target.value)} text={['공개', '비공개']}
						Required
				/>
			</InputWrapper>

			{/*게시 시작,종료일*/}
			<InputWrapper span={2} mb={15}>
				<DateWrapper>
					<DateInput initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)} name="startDt" label="게시 시작일" Required/>
					<DateInput initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)} name="endDt" label="게시 종료일" Required/>
				</DateWrapper>
			</InputWrapper>

			<InputWrapper span={2}>
				<LineDiv/>
			</InputWrapper>

			{/* 비트 선택 */}
			<InputWrapper span={2}>
				<VideoInput onClick={() => openModal('beat')} text="비트 선택하기" />
			</InputWrapper>
			{ (beatCurated && beatCurated.length > 0) && (
				<InputWrapper span={2} mb={15}>
					<TagHeadBeat>
						<TagHeaderColumnTitle title="" />
						<TagHeaderColumnTitle title="비트아이디" />
						<TagHeaderColumnTitle title="Cover" />
						<TagHeaderColumnTitle title="Title" />
						<TagHeaderColumnTitle title="Pid" />
						<TagHeaderColumnTitle title="장르" />
						<TagHeaderColumnTitle title="BPM" />
						<TagHeaderColumnTitle title="BeatKey" />
						<TagHeaderColumnTitle title="공개여부" />
						<TagHeaderColumnTitle title="공개 날짜" />
					</TagHeadBeat>
					<>
						{beatCurated?.map((item, index) => (
								<CuratedBeatRow
										rowKey={item.id}
										data={item}
										moveListItem={moveBeatListItem}
										index={index}
										playBeat={playBeat}
										setPlayBeat={setPlayBeat}
								/>
						))}
					</>
				</InputWrapper>
			)}
			<InputWrapper span={2}><LineDiv/></InputWrapper>

			{/* 컬렉션 선택 */}
			<InputWrapper span={2}>
				<VideoInput onClick={() => openModal('collection')} text="컬렉션 선택하기" />
			</InputWrapper>
			{(memberCollectionCurated && memberCollectionCurated.length>0 ) && (
				<InputWrapper span={2} mb={15}>
					<TagHeadCollection>
						<TagHeaderColumnTitle title="" />
						<TagHeaderColumnTitle title="번호" />
						<TagHeaderColumnTitle title="컬렉션 종류" />
						<TagHeaderColumnTitle title="컨텐츠 수" />
						<TagHeaderColumnTitle title="Cover" />
						<TagHeaderColumnTitle title="컬렉션 제목" />
						<TagHeaderColumnTitle title="작성 Pid" />
						<TagHeaderColumnTitle title="보관 수" />
					</TagHeadCollection>
					<>
						{memberCollectionCurated?.map((item, index) => (
								<CuratedCollectionRow
										rowKey={item.id}
										data={item}
										moveListItem={moveCollectionListItem}
										index={index}
								/>
						))}
					</>
				</InputWrapper>
			)}
			<InputWrapper span={2}><LineDiv/></InputWrapper>

			{/* 영상 선택 */}
			<InputWrapper span={2}>
				<VideoInput onClick={() => openModal('article')} text="영상 선택하기" />
			</InputWrapper>
			{(articleCurated && articleCurated.length>0 ) && (
					<InputWrapper span={2} mb={15}>
						<TagHeadArticle>
							<TagHeaderColumnTitle title="" />
							<TagHeaderColumnTitle title="번호" />
							<TagHeaderColumnTitle title="썸네일" />
							<TagHeaderColumnTitle title="Pid" />
							<TagHeaderColumnTitle title="이름" />
							<TagHeaderColumnTitle title="비트명" />
							<TagHeaderColumnTitle title="업로드 날짜" />
							<TagHeaderColumnTitle title="공개 여부" />
							<TagHeaderColumnTitle title="상태" />
						</TagHeadArticle>
						<>
							{articleCurated?.map((item, index) => (
									<CuratedArticleRow
											data={item}
											moveListItem={moveArticleListItem}
											index={index}
									/>
							))}
						</>
					</InputWrapper>
			)}

			<InputWrapper span={2}>
				<LineDiv/>
			</InputWrapper>

			<InputWrapper span={2} mb={12}>
				<LabelTextAreaInput
						name="introduction"
						label="서문"
						value={introduction}
						placeholder="서문을 입력해주세요"
						setValue={e => handleIntroduction(e.target.value)}
				/>
				{introductionStatus && <StatusText>{introductionStatus}</StatusText>}
			</InputWrapper>

			<InputWrapper span={2}>
				<EditorQuill
						value={contents}
						onValueChange={setContents}
						fileSaveUrl={`/magazine/contents-image/${prevId}`}
						label={'매거진 내용'}
						allowFileUpload={!!prevId}
						Required
				/>
				{/*<EditorToastUi*/}
				{/*	editorRef={editorRef}*/}
				{/*	content={contents}*/}
				{/*	onValueChange={setContents}*/}
				{/*	fileSaveUrl={`/magazine/contents-image/${prevId}`}*/}
				{/*	label={'매거진 내용'}*/}
				{/*	allowFileUpload={!!prevId}*/}
				{/*	Required*/}
				{/*/>*/}
				{/*<EditorCK*/}
				{/*	editorRef={editorRef}*/}
				{/*	content={contents}*/}
				{/*	onValueChange={setContents}*/}
				{/*	fileSaveUrl={`/magazine/contents-image/${prevId}`}*/}
				{/*	label={'매거진 내용'}*/}
				{/*	allowFileUpload={!!prevId}*/}
				{/*	Required*/}
				{/*/>*/}
			</InputWrapper>
		</MainContainer>

		{/* 비트 추가 모달*/}
		{(modalType === 'beat') && (
				<VideoModal closeModal={closeModal} blockCloseBtn>
					<VideoModalWrapper>
						<ModalButtonWrapper>
							<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
							<NormalButton text="저장하기" onSubmit={() => saveBeatWork()}/>
						</ModalButtonWrapper>
						<VideoModalTitle>선택 비트</VideoModalTitle>
						{beatWork?.length !== 0 && beatWork ? (
								<>
									<TableTitle>{beatWork?.length}개의 비트</TableTitle>
									<LineDiv />
									<Table colList={addBeatCollectionColList} className="nonListTable">
										{beatWork?.map((data) => (
												<DetailAddBeatRowComponents
														key={data.id}
														data={data}
														playBeat={playBeat}
														setPlayBeat={setPlayBeat}
														onClick={() => filterSelectedBeat(data)}
														selected
														editable
												/>
										))}
									</Table>
								</>
						) : (
								<>
									<VideoModalSubText>선택된 비트가 없습니다. 아래 검색 후 비트를 선택해 주세요.</VideoModalSubText>
									<LineDiv />
								</>
						)}
						<SpaceDiv value="90px" />
						<VideoModalTitle>비트 검색</VideoModalTitle>
						<TitleWrapper>
							<SearchWrapper>
								<SearchMenuSelect options={beatSearchTypeList} onClick={handleSelect} setState={setBeatType}/>
								<SearchInputWrapper>
									<SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
								</SearchInputWrapper>
							</SearchWrapper>
						</TitleWrapper>
						<PaginationWrapper>
							<TableTitle>{beatTotalCount}개 비트</TableTitle>
							{beatTotalCount !== 0 && (
									<Pagination
											postsPerPage={postsPerPage}
											totalCount={beatTotalCount}
											currentPage={pageNumber}
											pageDispatch={pageDispatch}
									/>
							)}
						</PaginationWrapper>
						<LineDiv className="30" />
						<Table
								colList={addBeatCollectionColList}
								thead={
									<>
										<ListHead title="Cover" />
										<ListHead title="Title" />
										<ListHead title="Pid" />
										<ListHead title="장르" />
										<ListHead title="BPM" />
										<ListHead title="BeatKey" />
										<ListHead title="공개여부" />
										<ListDateSelect title="공개 날짜" setMinStartDate={setMinStartDate} setMaxStartDate={setMaxStartDate}/>
										<ListHead title="" />
									</>
								}
						>
							{beatMaster?.map((el) => (
									<DetailAddBeatRowComponents
											key={el.id}
											data={el}
											onClick={addSelectedBeat}
											playBeat={playBeat}
											setPlayBeat={setPlayBeat}
											editable
									/>
							))}
						</Table>
					</VideoModalWrapper>
				</VideoModal>
		)}

		{/* 컬렉션 추가 모달*/}
		{(modalType === 'collection') && (
				<VideoModal closeModal={closeModal} blockCloseBtn>
					<VideoModalWrapper>
						<ModalButtonWrapper>
							<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
							<NormalButton text="저장하기" onSubmit={() => saveCollectionWork()}/>
						</ModalButtonWrapper>
						<VideoModalTitle>선택 컬렉션</VideoModalTitle>
						{memberCollectionWork?.length !== 0 && memberCollectionWork ? (
								<>
									<TableTitle>{memberCollectionWork?.length}개의 컬렉션</TableTitle>
									<LineDiv />
									<Table colList={curatedCollectionList} className="nonListTable">
										{memberCollectionWork?.map((data) => (
												<WorkCollectionRow
														key={data.id}
														data={data}
														onClick={() => filterSelectedCollection(data)}
														selected
														editable
												/>
										))}
									</Table>
								</>
						) : (
								<>
									<VideoModalSubText>선택된 컬렉션이 없습니다. 아래 검색 후 컬렉션을 선택해주세요.</VideoModalSubText>
									<LineDiv />
								</>
						)}
						<SpaceDiv value="90px" />
						<VideoModalTitle>컬렉션 검색</VideoModalTitle>
						<TitleWrapper>
							<SearchWrapper>
								<RoundButton title="Baund" selected={useBaundCollection} onClick={() => setUseBaundCollection(true)}/>
								<RoundButton title="유저" selected={!useBaundCollection} onClick={() => setUseBaundCollection(false)}/>
							</SearchWrapper>
						</TitleWrapper>
						<PaginationWrapper>
							<TableTitle>{memberCollectionTotalCount}개 비트</TableTitle>
							{memberCollectionTotalCount !== 0 && (
									<Pagination
											postsPerPage={postsPerPage}
											totalCount={memberCollectionTotalCount}
											currentPage={pageNumber}
											pageDispatch={pageDispatch}
									/>
							)}
						</PaginationWrapper>
						<LineDiv className="30" />
						<Table
								colList={curatedCollectionList}
								thead={
									<>
										<ListHead title="번호" />
										<ListHead title="컬렉션 종류" />
										<ListHead title="컨텐츠 수" />
										<ListHead title="Cover" />
										<ListHead title="컬렉션 제목" />
										<ListHead title="작성 Pid" />
										<ListHead title="보관 수" />
										<ListHead title="" />
									</>
								}
						>
							{memberCollectionMaster?.map((el) => (
									<WorkCollectionRow
											key={el.id}
											data={el}
											onClick={addSelectedCollection}
											editable
									/>
							))}
						</Table>
					</VideoModalWrapper>
				</VideoModal>
		)}

		{/* 영상 추가 모달	*/}
		{(modalType === 'article') && (
				<VideoModal closeModal={closeModal} blockCloseBtn>
					<VideoModalWrapper>
						<ModalButtonWrapper>
							<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
							<NormalButton text="저장하기" onSubmit={() => saveArticleWork()}/>
						</ModalButtonWrapper>
						<VideoModalTitle>선택 영상</VideoModalTitle>
						{articleWork?.length !== 0 && articleWork ? (
								<>
									<TableTitle>{articleWork?.length}개의 영상</TableTitle>
									<LineDiv />
									<Table colList={eventModalColList} className="nonListTable">
										{articleWork?.map((data) => (
												<SelectedEventRowComponent
														key={data.id}
														data={data}
														onClick={() => filterSelectedArticle(data)}
												/>
										))}
									</Table>
								</>
						) : (
								<>
									<VideoModalSubText>선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.</VideoModalSubText>
									<LineDiv />
								</>
						)}
						<SpaceDiv value="90px" />
						<VideoModalTitle>영상 검색</VideoModalTitle>
						<TitleWrapper>
							<SearchWrapper>
								<SearchMenuSelect options={articleSearchTypeList} onClick={handleSelect} setState={setSearchType}/>
								<SearchInputWrapper>
									<SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
								</SearchInputWrapper>
							</SearchWrapper>
						</TitleWrapper>
						<PaginationWrapper>
							<TableTitle>{articleTotalCount}개 영상</TableTitle>
							{articleTotalCount !== 0 && (
									<Pagination postsPerPage={postsPerPage} totalCount={articleTotalCount} currentPage={pageNumber} pageDispatch={pageDispatch}/>
							)}
						</PaginationWrapper>
						<LineDiv className="30" />
						<Table
								colList={eventModalColList}
								thead={
									<>
										<ListHead title="id" />
										<ListHead title="썸네일" />
										<ListHead title="Pid" />
										<ListHead title="이름" />
										<ListHead title="비트명" />
										<ListHead title="업로드 날짜" />
										<ListHead title="공개 여부" />
										<ListHead title="상태" />
										<ListHead title="" />
									</>
								}
						>
							{articleMaster?.map((el) => (
									<EventModalRowComponent
											key={el.id}
											data={el}
											onClick={addSelectedArticle}
									/>
							))}
						</Table>
					</VideoModalWrapper>
				</VideoModal>
		)}

	</>
};
export default MagazineDetail;

const TagHeaderColumnTitle = ({ title }) => {
	return (
			<ColumnTitleWrapper>
				<ColumnTitle title={title}>{title}</ColumnTitle>
			</ColumnTitleWrapper>
	);
};

const TopContainer = styled.div`
  // position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 40px;
  grid-gap: 15px 40px;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-bottom: 10px;
  border-radius: 10px;
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div`
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
  
  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }

  &.external-add {
    text-align: right;
    display: block !important;
  }

  &.area2 {
    background-color: #f1f3f5;
    padding: 25px;
    border-radius: 6px;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const CoverImgWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const TagHeadBeat = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.8fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 1fr;
`;

const TagHeadCollection = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.7fr 1fr 1fr 1fr 2.6fr 1.8fr 1fr;
`;

const TagHeadArticle = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.6fr 1.2fr 1.5fr 1.1fr 1.5fr 1.7fr 0.8fr 0.9fr;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({ value }) => value};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const VerticalFileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  // display: grid;
  // grid-template-columns: repeat(1, 1fr);
  gap: 30px 40px;
  // grid-gap: 5px 40px;
`;


const ColumnTitleWrapper = styled.div`
  padding: 10px 0 18px;
  background-color: white;
  text-align: center;
`;

const ColumnTitle = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ title, theme }) =>
		title === '' ? theme.color.mainWhite : theme.color.subBrightGray};
  z-index: 1;
`;

const StatusText = styled.span`
  position: absolute;
  font-size: 14px;
	color: ${({ theme }) => theme.color.mainRed};
`;

