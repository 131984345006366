import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// common
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import NormalButton from '../../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import ListDate from '../../../components/common/List/ListDate';

// hook
import useInput from '../../../hooks/useInput';

// constant
import {
  eventColList,
  eventCountryTypeList,
  eventActiveYnOptions,
  eventSearchTypeList,
} from '../../../constant/event';
import {GetCountryOption, GetLanguageOption, IsActive, postsPerPage} from '../../../constant/common';

// config
import { NOTICE } from '../../../constant/apiUrl';
import ListItem from "../../../components/common/List/ListItem";
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import handleDate from "../../../hooks/handleDate";

// 타입

const Notice = () => {
  const navigate = useNavigate();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageType = localStorage.getItem('searchType');
  const storageWord = localStorage.getItem('searchWord');
  const [eventList, setNoticeList] = useState([]);
  const [word, setWord] = useInput(storageWord || '');
  const [type, setType] = useState(storageType || '');
  const [country, setCountry] = useState('');
  const [activeYn, setActiveYn] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  const params = {
    searchType: type,
    countryCode: country,
    activeYn,
    word,
    limit: postsPerPage,
    searchStartDate: minStartDate,
    searchEndDate: maxEndDate,
    offset: pageNumber * postsPerPage,
    noticeType: 'M'
  };

  useEffect(() => {
    if (storagePageNumber) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('searchType', '');
      window.localStorage.setItem('searchWord', '');
    }
  }, [storagePageNumber]);

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  // type, bpm, privateUse, openDate
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
    setPageNumber(0);
  }

  // 배너 리스트 불러오기
  const getNoticeList = async () => {
    await axios.get(NOTICE, {params,})
    .then(resp => {
      setNoticeList(resp.data.noticeList);
      setTotalCount(resp.data.count || 0);
    })
    .catch(err => console.log(err))
  };

  // 엔터키로 notice 검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter' && word) {
      if (!type) {
        setType('T');
        params.searchType = 'T';
      }
      setPageNumber(0);
      params.offset = 0;
      getNoticeList();
    }
  };

  // 상세로 이동
  const goToDetail = (state) => {
    navigate('/operation/notice/detail', {
      state: {
        prevId: state?.id,
        nextSequence: totalCount + 1,
      },
    });
  };

  useEffect(() => {
    if (storagePageNumber === '0') {
      params.offset = 0;
      setPageNumber(0);
    }
    getNoticeList().then(r => r);
  }, [activeYn, country, minStartDate, maxEndDate, pageNumber]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={eventSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputWrapper>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputWrapper>
            <ListDate
              setMinStartDate={setMinStartDate}
              setMaxStartDate={setMaxEndDate}
              active={!!(minStartDate && maxEndDate)}
            />
          </SearchWrapper>
          <NormalButton
            text="공지 등록하기"
            onSubmit={() => {
              navigate('/operation/notice/add', { state: { type: 'notice' } });
            }}
            className=""
          />
          <NormalButton
              text="순서 변경"
              onSubmit={() => navigate('/operation/notice/editSeq')}
              className=""
          />
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={eventColList}
          thead={
            <>
              <ListHead title="번호" />
              <ListHead title="이미지" />
              <ListHead title="프로젝트 명" />
              <ListHead title="제목" />
              <ListSelect
                title="공개여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setActiveYn}
              />
              <ListHead title="노출 시작일" />
              <ListHead title="노출 종료일" />
              <ListSelect
                title="국가"
                list={eventCountryTypeList}
                onClick={handleSelect}
                setState={setCountry}
              />
              <ListHead title="언어" />
            </>
          }
        >
          {eventList?.map((el) => (
              <tr key={el.id} onClick={() => goToDetail(el)}>
                <td><ListItem text={el.id} /></td>
                <td><ListCoverImg url={el.bannerImgUrl} className="event" /></td>
                <td><ListItem text={el.projectName || '-'} /></td>
                <td><ListItem type="title" text={el.title} /></td>
                <td><ListItem text={IsActive[el.activeYn]} /></td>
                <td><ListItem text={handleDate(el.startDt)} /></td>
                <td><ListItem text={handleDate(el.endDt)} /></td>
                <td><ListItem text={GetCountryOption[el.countryCode]} /></td>
                <td><ListItem text={GetLanguageOption[el.language]} /></td>
              </tr>
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default Notice;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;
