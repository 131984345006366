import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// components
import NormalButton from '../../components/common/Button/NormalButton';

// config
import { MAINTENANCE } from '../../constant/apiUrl';
import FileInput from '../../components/common/Input/FileInput';
import TextField from '../../components/common/TextField';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';

const Maintenance = () => {
  const dispatch = useDispatch();

  const [jsonUrl, setJsonUrl] = useState('');
  const [jsonData, setJsonData] = useState('');
  const [maintenanceJsonFile, setMaintenanceJsonFile] = useState('');
  const request = new XMLHttpRequest();

  //파일 다운로드 하는 함수, onDownloadBtn에서 인수를 받아온다
  const downloadFile = async ({ data, fileName, fileType }: any) => {
    //파일로 다운로드할 데이터로 Blob를 만든다 [Blob이란? (Binary Large Object, 블랍) 이미지, 사운드, 비디오와 같은 멀티미디어 데이터를 다룰 때 사용]
    const blob = new Blob([data], { type: fileType });
    // a태그를 생성하고 해당 요소에 클릭 이벤트를 보낸다
    // 다운로드를 한다
    const link = document.createElement('a');
    link.download = fileName;
    link.href = URL.createObjectURL(blob);

    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    link.dispatchEvent(clickEvt);
    link.remove();
  };

  //버튼을 클릭하면 실행되는 함수
  // data, fileName, fileType에 값을 담아 downloadFile의 인수로 보낸다
  const onDownloadBtn = () => {
    const name = 'maintenance'; //파일명
    downloadFile({
      data: JSON.stringify(jsonData),
      fileName: `${name}.json`,
      fileType: 'text/json',
    });
  };

  // json파일 업로드
  const handleJsonFile = (e: any) => {
    setMaintenanceJsonFile(e);
  };

  // json파일 업로드하기
  const upLoadJsonFile = useCallback(async () => {
    const formData = new FormData();
    formData.append('maintenanceJsonFile', maintenanceJsonFile);
    if (!maintenanceJsonFile) {
      alert('json 파일을 업로드 해주세요.');
      return;
    }
    dispatch(setLoading());
    try {
      await axios.post(MAINTENANCE, formData, {});
      alert('업로드가 완료되었습니다.');
      setMaintenanceJsonFile('');
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  }, [dispatch, maintenanceJsonFile]);

  // 배너 리스트 불러오기
  const getAccountList = useCallback(async () => {
    try {
      const resp = await axios.get(MAINTENANCE, {
        params: { limit: 1000, offset: 0 },
      });
      setJsonUrl(resp.data.maintenanceUrl);
      request.open('GET', resp.data.maintenanceUrl);
      request.responseType = 'json';
      request.send();

      // eslint-disable-next-line func-names
      request.onload = function () {
        const requestData = request.response; // get the string from the response
        setJsonData(requestData);
      };
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAccountList();
  }, [getAccountList]);

  return (
    <MainContainer>
      <ButtonWrapper>
        <NormalButton
          text="json 파일 다운로드"
          className="cancelBtn"
          onSubmit={onDownloadBtn}
        />
        <NormalButton text="업로드 하기" onSubmit={upLoadJsonFile} />
      </ButtonWrapper>
      <DownLoadWrapper>
        {jsonUrl && <TextField text={jsonUrl} label="json 파일" />}
      </DownLoadWrapper>
      <SpaceDiv />
      <UpLoadWrapper>
        <FileInput
          type="json"
          placeholder="파일을 선택해주세요."
          label="json 파일 업로드"
          value={maintenanceJsonFile}
          setFile={handleJsonFile}
        />
      </UpLoadWrapper>
    </MainContainer>
  );
};

export default Maintenance;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 50px;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const DownLoadWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const UpLoadWrapper = styled.div`
  width: 100%;
`;

const SpaceDiv = styled.div`
  height: 30px;
`;
