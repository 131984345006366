import React from 'react';
import styled from 'styled-components';
import logo from '../../assets/images/nav/logo.png';

const Logo = () => {
  return <LogoImg src={logo} alt="Baund Logo" />;
};
export default Logo;

const LogoImg = styled.img`
  width: 97px;
  height: 24px;
`;
