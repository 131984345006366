import React from 'react';
import styled from 'styled-components';

const TypicalTable  = ({
  className,
  thead,
  children,
}) => {
  return (
    <TableContainer className={className}>
      <TableWrapper>
        <thead>
          {thead}
        </thead>
        <tbody>{children}</tbody>
      </TableWrapper>
    </TableContainer>
  );
};

export default TypicalTable;

const TableContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  min-height: 400px;
  -ms-overflow-style: none;
  z-index: 0;
  &::-webkit-scrollbar {
    display: none;
  }

  &.nonListTable {
    min-height: 0;
  }
`;

const TableWrapper = styled.table`
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-collapse: separate;
  border-spacing: 0;
  line-height: 1.5;
  text-align: center;
  table-layout: fixed;
  width: 100%;

  th {
    text-align: center;
    vertical-align: center;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    background: #eee;
    padding: 5px 0px 5px 0px;
    font-size: 14px;
  }
  td {
    padding: 5px 1px 5px 1px;
    text-align: center;
    vertical-align: center;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
`;