import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

import axios from 'axios';
import {PERFORMANCE, PERFORMANCE_CATEGORY_ACTIVE, PERFORMANCE_DETAIL} from '../../../constant/apiUrl';

// components
import ImgBox from '../../../components/common/image/ImgBox';
import NormalButton from '../../../components/common/Button/NormalButton';
import FileInput from '../../../components/common/Input/FileInput';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import LabelTextAreaInput from '../../../components/common/Input/LabelTextAreaInput';
import MultiSelect from '../../../components/common/Select/MultiSelect';
import RadioButton from '../../../components/common/Button/RadioButton';
import DateInput from '../../../components/common/Input/DateInput';

// util
import { resetLoading, setLoading } from '../../../redux/slices/user';
import { validateStrDate } from '../../../utils/commonFn';
import EditButton from "../../../components/common/Button/EditButton";

const MiniTable = ({data, idx, name, list, listSetter}) => {
    return <MiniTableContainer>
        <InputWrapper span={2} mt={idx === 0 ? '' : -45}>
            <LabelTextInput
                name={`${data.name}_${idx}`}
                label={idx === 0 ? `${name}명` : ''}
                value={data.name}
                placeholder={`${name}명을 입력해주세요.`}
                onChange={e => {
                    const _list = [...list];
                    _list[idx].name = e.target.value;
                    listSetter(_list);
                }}
            />
        </InputWrapper>
        <InputWrapper span={2} mt={idx === 0 ? '' : -45}>
            <LabelTextInput
                name={`${data.nameEn}_${idx}`}
                label={idx === 0 ? `${name}명 (영어)` : ''}
                value={data.nameEn}
                placeholder={`${name}명을 입력해주세요.`}
                onChange={e => {
                    const _list = [...list];
                    _list[idx].nameEn = e.target.value;
                    listSetter(_list);
                }}
            />
        </InputWrapper>
        <InputWrapper span={5} mt={idx === 0 ? '' : -45}>
            <LabelTextInput
                name={`${data.url}_${idx}`}
                label={idx === 0 ? `${name}URL` : ''}
                value={data.url}
                placeholder={`${name}URL을 입력해주세요.`}
                onChange={e => {
                    const _list = [...list];
                    _list[idx].url = e.target.value;
                    listSetter(_list);
                }}
            />
        </InputWrapper>
        <InputWrapper span={1} mt={idx === 0 ? '' : -65}>
            {
                idx === 0
                    ? <EditButton
                        text="추가"
                        className='performanceAddBtn'
                        onClick={() => listSetter([...list, {name:'', nameEn:'', url:''}])} />
                    : <EditButton
                        text="삭제"
                        className='performancePopBtn'
                        onClick={() => {
                            const _list = [...list];
                            _list.splice(idx, 1);
                            listSetter(_list);
                        }} />
            }
        </InputWrapper>
    </MiniTableContainer>;
};

//페이지 세팅
const PerformanceDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    // 기본 데이터
    const [performanceId] = useState(location.state && location.state.id);
    const [privateYn, setPrivateYn] = useState('N');                            // 공개 여부
    const [publicStartDate, setPublicStartDate] = useState('');                 // 공개 시작 일시
    const [publicEndDate, setPublicEndDate] = useState('');                     // 공개 종료 일시
    const [ageLimit, setAgeLimit] = useState(0);                                // 관람가
    const [categoryIds, setCategoryIds] = useState([]);                         // 카테고리 아이디 리스트
    const [posterImgUrl, setPosterImgUrl] = useState('');                       // 포스터 이미지 URL
    const [price, setPrice] = useState('');                                     // 가격
    const [bookingInfos, setBookingInfos] = useState([{name:'', nameEn:'', url:''}]);   // 예매처 리스트
    const [contactInfos, setContactInfos] = useState([{name:'', nameEn:'', url:''}]);   // 문의처 리스트

    const [title, setTitle] = useState('');                                         // 제목
    const [titleEn, setTitleEn] = useState('');                                     // 제목 (영어)
    const [description, setDescription] = useState('');                             // 설명
    const [descriptionEn, setDescriptionEn] = useState('');                         // 설명 (영어)
    const [lineup, setLineup] = useState('');                                       // 출연진
    const [lineupEn, setLineupEn] = useState('');                                   // 출연진 (영어)
    const [place, setPlace] = useState(['', '', '']);                               // 장소
    const [placeEn, setPlaceEn] = useState(['', '', '']);                           // 장소 (영어)
    const [strDate, setStrDate] = useState('');                                     // 기간 (string)
    const [startDate, setStartDate] = useState('');                                 // 공연 시작일 (date)
    const [periodEn, setPeriodEn] = useState('');                                   // 기간 (영어)
    const [ticketOpenDate, setTicketOpenDate] = useState('');                       // 티켓 오픈 일시
    const [ticketOpenDateEn, setTicketOpenDateEn] = useState('');                   // 티켓 오픈 일시 (영어)

    const [note, setNote] = useState('');                                           // 특이사항
    const [noteEn, setNoteEn] = useState('');                                       // 특이사항 (영어)
    const [notice, setNotice] = useState('');                                       // 유의사항
    const [noticeEn, setNoticeEn] = useState('');                                   // 유의사항 (영어)

    // 페이지에 필요한 state
    const [posterImgFile, setPosterImgFile] = useState(null);                       // 포스터 이미지 파일
    const [categoryList, setCategoryList] = useState([]);                           // 카테고리 리스트
    const [selectedCategoryNames, setSelectedCategoryNames] = useState([]);         // 선택된 카테고리 이름 리스트

    // 카테고리 관련 로직
    // 페이지 오픈 시 카테고리 세팅 용
    const setCategoryNamesFn = (ids) => {
        const names = [];
        categoryList.forEach(c => {
            if (ids.includes(c.value)) names.push(c.name);
        })
        setSelectedCategoryNames(names);
    }
    // 카테고리 선택시 로직
    const unsetCategoryId = (categoryName) => {
        categoryList.forEach(c => {
            if (c.name === categoryName) {
                if (categoryIds.indexOf(c.value) > -1) {                        // 해당 요소가 존재하면
                    setCategoryIds(categoryIds.filter(id => id !== c.value));   // 빼기
                } else {                                                        // 존재하지않으면
                    setCategoryIds([...categoryIds, c.value]);            // 더하기
                }

                if (selectedCategoryNames.indexOf(c.name) > -1) {                                       // 해당 요소가 존재하면
                    setSelectedCategoryNames(selectedCategoryNames.filter(name => name !== c.name));    // 빼주고
                } else {                                                                                // 존재하지않으면
                    setSelectedCategoryNames([...selectedCategoryNames, c.name]);                 // 더해주고
                }
            }
        })
    }

    // Insert, Update 하기
    const savePerformance = async () => {
        dispatch(setLoading());

        // 필수 및 유효성 체크
        let validCheck = true;
        if (!title) validCheck = false;
        if (!description) validCheck = false;
        if (!lineup) validCheck = false;
        if (!privateYn) validCheck = false;
        if (!publicStartDate) validCheck = false;
        if (!publicEndDate) validCheck = false;
        if (!place[0] || !place[1] || !place[2]) validCheck = false;
        if (!price) validCheck = false;
        if (!strDate) validCheck = false;
        if (!startDate) validCheck = false;
        if (!categoryIds.length) validCheck = false;
        if (!validCheck) {
            alert('필수값이 누락되었습니다. 확인해주세요.');
            dispatch(resetLoading());
            return;
        }

        // 파라미터 세팅
        const request = {};
        request['performanceId'] = performanceId;
        request['privateYn'] = privateYn;
        request['publicStartDate'] = publicStartDate;
        request['publicEndDate'] = publicEndDate;
        request['ageLimit'] = ageLimit || 0;
        request['categoryIds'] = categoryIds;
        request['posterImgUrl'] = posterImgUrl;
        request['strPrice'] = price;
        request['startDate'] = startDate;

        request['title'] = title;
        request['titleEn'] = titleEn;
        request['description'] = description;
        request['descriptionEn'] = descriptionEn;
        request['lineup'] = lineup;
        request['lineupEn'] = lineupEn;
        request['place'] = place;
        request['placeEn'] = placeEn;

        request['strDate'] = strDate;
        request['strDateEn'] = periodEn;
        request['strTicketOpenDate'] = ticketOpenDate;
        request['strTicketOpenDateEn'] = ticketOpenDateEn;

        request['notice'] = notice;
        request['noticeEn'] = noticeEn;
        request['note'] = note;
        request['noteEn'] = noteEn;
        request['bookingInfos'] = [...bookingInfos].filter(b => b.name !== ''); // 빈값 제외 삽입
        request['contactInfos'] = [...contactInfos].filter(c => c.name !== ''); // 빈값 제외 삽입
        const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});

        const formData = new FormData();
        formData.append('request', blob);                                   // 기본 데이터 삽입
        if (posterImgFile) formData.append('posterImgFile', posterImgFile); // 이미지 변경됐으면 삽입

        try {
            performanceId > 0
                ? await axios.put(PERFORMANCE, formData, {})  // 수정
                : await axios.post(PERFORMANCE, formData, {}) // 저장

            alert('저장 완료됐습니다.');
            performanceId > 0
                ? window.location.reload()          // 수정시 페이지 새로고침
                : navigate('/culture/performance')  // 저장시 목록 가기
        } catch (err) {
            console.log(err);
            alert('예상치 못한 오류가 발생하였습니다. 담당자에게 문의하세요.');
        } finally {
            dispatch(resetLoading());
        }
    };

    // 수정 시
    const getDetail = async () => {
        await axios.get(`${PERFORMANCE_DETAIL}/${performanceId}`)
            .then(res => {
                setPrivateYn(res.data.privateYn);
                setPublicStartDate(res.data.publicStartDate);
                setPublicEndDate(res.data.publicEndDate);
                setAgeLimit(res.data.ageLimit);
                setCategoryIds(res.data.categoryIds);
                setPosterImgUrl(res.data.posterImgUrl);
                setPrice(res.data.strPrice);
                setStartDate(res.data.startDate || '');

                setTitle(res.data.title);
                setTitleEn(res.data.titleEn || '');
                setDescription(res.data.description);
                setDescriptionEn(res.data.descriptionEn || '');
                setLineup(res.data.lineup);
                setLineupEn(res.data.lineupEn || '');
                setPlace(res.data.place);
                setPlaceEn(res.data.placeEn || ['', '', '']);
                setStrDate(res.data.strDate);
                setPeriodEn(res.data.strDateEn || '');

                // 필수값 아닌것 (= null값이 넘어올 수 있는 것)
                setTicketOpenDate(res.data.strTicketOpenDate || '');
                setTicketOpenDateEn(res.data.strTicketOpenDateEn || '');
                setNotice(res.data.notice || '');
                setNoticeEn(res.data.noticeEn || '');
                setNote(res.data.note || '');
                setNoteEn(res.data.noteEn || '');
                setBookingInfos(res.data.bookingInfos?.length > 0 ? res.data.bookingInfos : [{name:'', nameEn:'', url:''}]);
                setContactInfos(res.data.contactInfos?.length > 0 ? res.data.contactInfos : [{name:'', nameEn:'', url:''}]);
            })
            .catch(reason => console.error(reason));
    }

    // 카테고리 선택박스 세팅
    const getCategoryList = async () => {
        await axios.get(`${PERFORMANCE_CATEGORY_ACTIVE}?offset=0&limit=100`)
            .then(res => {
                const list = [];
                res.data && res.data.performanceCategorys.forEach(category => {
                    list.push({name: category.name, value: category.performanceCategoryId});
                })
                setCategoryList(list);
            })
            .catch(reason => console.error(reason));
    }

    // categoryIds 변경 시 선택된 카테고리 보여주기
    useEffect(() => setCategoryNamesFn(categoryIds), [categoryIds]);

    useEffect(() => {
        if (publicStartDate.length > 0 && publicEndDate.length > 0 && !validateStrDate(publicStartDate, publicEndDate)) {
            setPublicEndDate('');
        }
    }, [publicEndDate]);

    // 페이지 진입 시 1회 실행할 로직
    useEffect(() => {
        getCategoryList();                  // 카테고리 리스트 가져오기
        performanceId && getDetail();       // 상세 가져오기
    }, []);

    //한글 입력시 영문 동시 입력
    useEffect(()=> {
        setTitleEn(title);
    }, [title]);
    useEffect(()=> {
        setDescriptionEn(description);
    }, [description]);
    useEffect(()=> {
        setLineupEn(lineup);
    }, [lineup]);

    // 페이지 화면 관련
    return (
        <>
            <TopContainer>
                <TopWrapper>
                    <ButtonWrapper>
                        <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate('/culture/performance')}/>
                        <NormalButton text={performanceId > 0 ? '수정하기' : '저장하기'} onSubmit={savePerformance} />
                    </ButtonWrapper>
                </TopWrapper>
            </TopContainer>

            <MainContainer>

                {/* 포스터 */}
                <InputWrapper span={5} mb={400}>
                    <FileInput
                        type="coverImg"
                        placeholder={posterImgFile ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'}
                        label="공연 포스터"
                        value={posterImgFile}               // 파일 지정
                        setFile={e => setPosterImgFile(e)}  // 파일 세팅
                        setImgUrl={setPosterImgUrl}         // url이 있으면 삽입됨.
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <ImgWrapper>
                        {posterImgUrl ? <ImgBox url={posterImgUrl} width={80} height={100} /> : <ImgBox width={80} height={100} />}
                    </ImgWrapper>
                </InputWrapper>

                {/* 제목 */}
                <InputWrapper span={5}>
                    <LabelTextInput
                        name="title"
                        label="공연 제목"
                        value={title}
                        placeholder="제목을 입력해주세요."
                        onChange={(e) => setTitle(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <LabelTextInput
                        name="titleEn"
                        label="공연 제목 (영어)"
                        value={titleEn}
                        placeholder="제목을 입력해주세요. (영어)"
                        onChange={(e) => setTitleEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 설명 */}
                <InputWrapper span={5}>
                    <LabelTextAreaInput
                        name="description"
                        label="공연 설명"
                        value={description}
                        placeholder="공연에 대한 상세한 설명을 써주세요."
                        setValue={(e) => setDescription(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <LabelTextAreaInput
                        name="descriptionEn"
                        label="공연 설명 (영어)"
                        value={descriptionEn}
                        placeholder="공연에 대한 상세한 설명을 써주세요. (영어)"
                        setValue={(e) => setDescriptionEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 출연진 */}
                <InputWrapper span={5}>
                    <LabelTextAreaInput
                        name="lineup"
                        label="출연진"
                        value={lineup}
                        placeholder="출연진을 써주세요."
                        setValue={(e) => setLineup(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <LabelTextAreaInput
                        name="lineupEn"
                        label="출연진 (영어)"
                        value={lineupEn}
                        placeholder="출연진을 써주세요. (영어)"
                        setValue={(e) => setLineupEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 카테고리 선택 */}
                <InputWrapper span={10}>
                    <MultiSelect
                        label="공연 카테고리"
                        name="categoryIds"
                        placeholder="공연 카테고리를 선택해주세요."
                        value={selectedCategoryNames} // 선택된 애들
                        onChange={e => unsetCategoryId(e)}
                        options={categoryList.map(el => el.name)}
                        Required
                    />
                </InputWrapper>

                {/* 공연 기간 */}
                <InputWrapper span={3}>
                    <LabelTextInput
                        name="period"
                        label="공연 기간 (수기)"
                        value={strDate}
                        placeholder="공연 기간을 입력해주세요."
                        onChange={e => setStrDate(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={4}>
                    <DateInput
                        label="공연 시작일 (D-day 계산용, 시간은 상관없음)"
                        name="startDate"
                        initialStartDate={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={3}>
                    <LabelTextInput
                        name="periodEn"
                        label="공연 기간 (영어 표기)"
                        value={periodEn}
                        placeholder="공연 기간을 입력해주세요. (영어 표기)"
                        onChange={e => setPeriodEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 공연장 */}
                <InputWrapper span={2} mb={-5}>
                    <LabelTextInput
                        name="place1"
                        label="도시"
                        value={place[0]}
                        placeholder="도시를 입력해주세요."
                        onChange={e => setPlace([e.target.value, place[1], place[2]])}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={3} mb={-5}>
                    <LabelTextInput
                        name="place2"
                        label="공연장 이름"
                        value={place[1]}
                        placeholder="공연장 이름을 입력해주세요."
                        onChange={e => setPlace([place[0], e.target.value, place[2]])}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5} mb={-5}>
                    <LabelTextInput
                        name="place3"
                        label="상세주소"
                        value={place[2]}
                        placeholder="상세주소를 입력해주세요."
                        onChange={e => setPlace([place[0], place[1], e.target.value])}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={2}>
                    <LabelTextInput
                        name="place1En"
                        label="도시 (영어)"
                        value={placeEn[0]}
                        placeholder="도시를 입력해주세요. (영어)"
                        onChange={e => setPlaceEn([e.target.value, placeEn[1], placeEn[2]])}
                    />
                </InputWrapper>
                <InputWrapper span={3}>
                    <LabelTextInput
                        name="place2En"
                        label="공연장 이름 (영어)"
                        value={placeEn[1]}
                        placeholder="공연장 이름을 입력해주세요. (영어)"
                        onChange={e => setPlaceEn([placeEn[0], e.target.value, placeEn[2]])}
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <LabelTextInput
                        name="place3En"
                        label="상세주소 (영어)"
                        value={placeEn[2]}
                        placeholder="상세주소를 입력해주세요. (영어)"
                        onChange={e => setPlaceEn([placeEn[0], placeEn[1], e.target.value])}
                    />
                </InputWrapper>

                {/* 가격 */}
                <InputWrapper span={5}>
                    <LabelTextInput
                        name="price"
                        label="가격"
                        value={price}
                        placeholder="티켓 가격을 입력해주세요."
                        onChange={e => setPrice(e.target.value)}
                        Required
                    />
                </InputWrapper>

                {/* 관람가 */}
                <InputWrapper span={5}>
                    <LabelTextInput
                        label="관람가 (0은 전체관람가입니다.)"
                        name="ageLimit"
                        value={ageLimit}
                        placeholder="관람가를 입력해주세요."
                        onChange={e => setAgeLimit(e.target.value)}
                        type="number"
                        Required
                    />
                </InputWrapper>

                <InputWrapper span={10}>
                    <LineDiv />
                </InputWrapper>

                {/* 티켓 오픈 일시 */}
                <InputWrapper span={5}>
                    <LabelTextInput
                        label="티켓 오픈 일시"
                        name="ticketOpenDate"
                        value={ticketOpenDate}
                        placeholder="티켓 오픈 일시를 입력해주세요."
                        onChange={e => setTicketOpenDate(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <LabelTextInput
                        label="티켓 오픈 일시 (영어 표기)"
                        name="ticketOpenDateEn"
                        value={ticketOpenDateEn}
                        placeholder="티켓 오픈 일시를 입력해주세요. (영어 표기)"
                        onChange={e => setTicketOpenDateEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 특이사항 */}
                <InputWrapper span={10} mb={-10}>
                    <LabelTextAreaInput
                        name="note"
                        label="특이사항"
                        value={note}
                        placeholder="특이사항을 써주세요."
                        setValue={e => setNote(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper span={10}>
                    <LabelTextAreaInput
                        name="noteEn"
                        label="특이사항 (영어)"
                        value={noteEn}
                        placeholder="특이사항을 써주세요. (영어)"
                        setValue={e => setNoteEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 유의사항 */}
                <InputWrapper span={10} mb={-10}>
                    <LabelTextAreaInput
                        name="notice"
                        label="유의사항"
                        value={notice}
                        placeholder="유의사항을 써주세요."
                        setValue={e => setNotice(e.target.value)}
                    />
                </InputWrapper>
                <InputWrapper span={10}>
                    <LabelTextAreaInput
                        name="noticeEn"
                        label="유의사항 (영어)"
                        value={noticeEn}
                        placeholder="유의사항을 써주세요. (영어)"
                        setValue={e => setNoticeEn(e.target.value)}
                    />
                </InputWrapper>

                {/* 예매처 */}
                <InputWrapper span={10} mb={0}>
                    {
                        bookingInfos?.map((b, i) =>
                            <MiniTable key={i} data={b} idx={i} name='예매처' list={bookingInfos} listSetter={setBookingInfos} />)
                    }
                </InputWrapper>

                {/* 문의처 */}
                <InputWrapper span={10} mb={0}>
                    {
                        contactInfos?.map((c, i) =>
                            <MiniTable key={i} data={c} idx={i} name='문의처' list={contactInfos} listSetter={setContactInfos} />)
                    }
                </InputWrapper>

                <InputWrapper span={10}>
                    <LineDiv />
                </InputWrapper>

                {/* 공개 여부 */}
                <InputWrapper span={10}>
                    <RadioButton
                        value={privateYn}
                        name="privateYn"
                        label="공개 여부"
                        onChange={e => setPrivateYn(e.target.value)}
                        type="privateYn"
                        Required
                    />
                </InputWrapper>

                {/* 게시 기간 */}
                <InputWrapper span={5}>
                    <DateInput
                        label="게시 시작 일시"
                        name="publicStartDate"
                        initialStartDate={publicStartDate}
                        onChange={e => setPublicStartDate(e.target.value)}
                        Required
                    />
                </InputWrapper>
                <InputWrapper span={5}>
                    <DateInput
                        label="게시 종료 일시"
                        name="publicEndDate"
                        initialStartDate={publicEndDate}
                        onChange={e => setPublicEndDate(e.target.value)}
                        Required
                    />
                </InputWrapper>

            </MainContainer>
      </>
    );
};

export default PerformanceDetail;

// CSS
const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
`;
const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: right;
  align-items: flex-start;
`;
const ImgWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  height: 100%;
`;
const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;
const InputWrapper = styled.div`
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '40px'};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
`;

const MiniTableContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const LineDiv = styled.div`
  	width: 100%;
  	margin-bottom: 15px;
	margin-top: 15px;
  	border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;