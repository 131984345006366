export const baundApiCategoryTypeOptions = [
  { text: '홈', value: 'HOME' },
  { text: '바운드 프로젝트', value: 'BAUND_PROJECT' },
];

export const getBaundApiCategoryTypeName = (_typeValue) => {
  return getTypeText(baundApiCategoryTypeOptions, _typeValue);
}

export const activeTypeOptions = [
  { text: '활성', value: 'Y' },
  { text: '비활성', value: 'N' },
];

export const getActiveTypeName = (_typeValue) => {
  return getTypeText(activeTypeOptions, _typeValue);
}

export const getTypeText = (_typeOptions, _typeValue) => {
  let typeName = _typeOptions
  .filter((option) => option.value === _typeValue)
  .map((option) => {
    return option.text;
  });
  return typeName?.[0] || '';
}