import React from 'react';
import styled, { css } from 'styled-components';

type CustomProps = {
  selected: boolean;
};

interface Props {
  title: string;
  selected: boolean;
  onClick: any;
  className?: string;
}

const RoundButton: React.FC<Props> = ({ title, selected, onClick, className }) => {
  return (
    <Container selected={selected} onClick={onClick} title={title} className={className}>
      {title}
    </Container>
  );
};

export default RoundButton;

const Container = styled.button<CustomProps>`
  width: 85px;
  height: 44px;
  margin-right: 16px;
  padding: 0;
  border-radius: 40px;
  border: solid 1px ${({ theme }) => theme.color.subUnactiveGray};
  color: ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 14px;
  font-weight: 600;
  text-align: center;

  ${(props) =>
    props.selected === true &&
    css`
      border: none;
      background-color: ${({ theme }) => theme.color.subDarkGray};
      color: ${({ theme }) => theme.color.mainWhite};
    `}
  
  &.noMargin {
    margin-right: 0;
  }
`;
