import styled from 'styled-components';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router';
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import DateInput from '../../../components/common/Input/DateInput';
import TagTextInput from '../../../components/common/Input/TagTextInput';
import MultiSelect from '../../../components/common/Select/MultiSelect';
import { FILTER, FILTER_TYPE } from '../../../constant/apiUrl';
import BlankImg from '../../../assets/images/page/blankImg.png';

const FilterDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const detailData: any = location.state;
  const prevId = detailData !== null ? detailData.prevId : null;
  const prevTotalCount = detailData && detailData.prevTotalCount;
  // const checkProps = () => {
  //   return detailData === null;
  // };

  // state
  const [shaderName, setShaderName] = useState('');
  const [flipYn, setFlipYn] = useState('Y');
  const [previewUrl, setPreviewUrl] = useState('');
  const [searchKeyword, setSearchKeyword] = useState<any>();
  const [activeYn, setActiveYn] = useState('Y');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [categoryList, setCategoryList] = useState([]);
  const [selectedcategoryList, setSelectedCategoryList] = useState<any>([]);
  const [shaderFile, setShaderFile] = useState(null);
  const [iconFile, setIconFile] = useState(null);
  const [iconImgUrl, setIconImgUrl] = useState<any>();
  const [addkeyword, setAddKeyword] = useState('');
  const [studioCameraFilterTypeIds, setStudioCameraFilterTypeIds] =
    useState<any>([]);
  const [sequence, setSequence] = useState<any>(0);

  const handleShaderFile = (e: any) => {
    setShaderFile(e);
  };

  const handleIconFile = (e: any) => {
    setIconFile(e);
  };

  const handleFilterTypeNames = useCallback(() => {
    const arr: string[] = [];
    categoryList.forEach((el: any) => {
      if (studioCameraFilterTypeIds?.includes(el.id)) arr.push(el.name);
    });
    setSelectedCategoryList(arr);
  }, [categoryList, studioCameraFilterTypeIds]);

  const getDetailFilter = async () => {
    try {
      const resp = await axios.get(`${FILTER}/${prevId}`);
      const result = resp.data.studioCameraFilter;
      setActiveYn(result?.activeYn);
      setFlipYn(result?.flipYn);
      setPreviewUrl(result?.previewLink);
      setShaderName(result?.shaderName);
      setStudioCameraFilterTypeIds(result?.studioCameraFilterTypeIds);
      setSearchKeyword(result?.tags);
      setSequence(result?.sequence);
      setIconImgUrl(result?.iconUrl);
      if (result.endDt) {
        setEndDate(result?.endDt.slice(0, 16).replace(' ', 'T'));
      }
      if (result.startDt) {
        setStartDate(result?.startDt.slice(0, 16).replace(' ', 'T'));
      }
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  const getFilterType = async () => {
    const typeParams = { limit: 100, offset: 0 };
    try {
      const resp = await axios.get(FILTER_TYPE, { params: typeParams });
      setCategoryList(resp.data.adminStudioCameraFilterTypeList);
    } catch (err) {
      console.log(err);
    }
  };

  const handleMultiSetData = (e: string) => {
    const duplicateCheck: number = selectedcategoryList?.indexOf(e);
    if (duplicateCheck !== -1) {
      const removeOption = selectedcategoryList?.filter(
        (el: string) => el !== e,
      );

      setSelectedCategoryList(removeOption);
      return;
    }
    setSelectedCategoryList((prev: any) => [...prev, e]);
  };

  const handleFilterTypeIds = (list: any) => {
    const arr: number[] = [];
    categoryList.forEach((el: any) => {
      if (list.includes(el.name)) arr.push(el.id);
    });
    return arr;
  };

  const addFilter = async (type: string) => {
    if ((type === 'add') && (!iconFile || !shaderFile)) {
      alert('파일을 등록해주세요.');
      return;
    }
    if (selectedcategoryList.length === 0) {
      alert('최소 한가지 이상의 카테고리를 선택해야됩니다.');
      return;
    }
    const formData = new FormData();

    formData.append('activeYn', activeYn);
    formData.append('endDate', `${endDate.replace('T', ' ')}:59`);
    formData.append('flipYn', flipYn);
    formData.append('iconFile', iconFile || '');
    formData.append('previewLink', previewUrl);
    if (type === 'add') {
      formData.append('sequence', prevTotalCount + 1);
    }
    if (type === 'edit') {
      formData.append('sequence', sequence);
    }
    formData.append('shaderFile', shaderFile || '');
    formData.append('shaderName', shaderName);
    formData.append('startDate', `${startDate.replace('T', ' ')}:00`);
    handleFilterTypeIds(selectedcategoryList).forEach((el) =>
      formData.append('studioCameraFilterTypeIds', String(el)),
    );
    if (searchKeyword) {
      searchKeyword.forEach((el: string) => formData.append('tags', el));
    }

    if (type === 'add') {
      try {
        await axios.post(FILTER, formData, {headers: {'Content-Type': 'multipart/form-data',},});
        alert('저장되었습니다!');
        navigate('/filter');
      } catch (err) {
        console.log(err);
      }
    }
    if (type === 'edit') {
      try {
        await axios.post(`${FILTER}/${prevId}`, formData, {headers: {'Content-Type': 'multipart/form-data',},});
        alert('수정되었습니다!');
        navigate('/filter');
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    getFilterType();
    if (prevId) {
      getDetailFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (prevId !== null) {
      handleFilterTypeNames();
    }
  }, [prevId, handleFilterTypeNames]);

  return (
    <>
      <TopContainer>
        <PreviewImageWrapper>
          <PreviewImg src={iconImgUrl || BlankImg} />
        </PreviewImageWrapper>
        <ButtonWrapper>
          <NormalButton
            text="취소"
            onSubmit={() => {
              navigate('/filter');
            }}
            className="cancelBtn"
          />
          {!prevId ? (
            <NormalButton
              text="저장하기"
              onSubmit={() => {
                addFilter('add');
              }}
            />
          ) : (
            <NormalButton
              text="수정하기"
              onSubmit={() => {
                addFilter('edit');
              }}
            />
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <FileInput
            type="shaderFile"
            placeholder={
              prevId ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="필터 파일"
            value={shaderFile}
            setFile={handleShaderFile}
            Required={!prevId}
          />
        </InputWrapper>
        <InputWrapper>
          <FileInput
            type="iconFile"
            placeholder={
              prevId ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="아이콘 파일"
            value={iconFile}
            name="eventBannerFile"
            setFile={handleIconFile}
            setImgUrl={setIconImgUrl}
            fileUrl={iconImgUrl}
            Required={!prevId}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="shaderName"
            label="필터 명"
            value={shaderName}
            placeholder="프로젝트 명을 입력해 주세요."
            onChange={(e) => {
              setShaderName(e.target.value);
            }}
            onReset={() => {
              setShaderName('');
            }}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <MultiSelect
            name="category"
            placeholder="카테고리를 선택해주세요."
            value={selectedcategoryList}
            label="카테고리"
            onChange={handleMultiSetData}
            options={categoryList?.map((el: any) => el.name)}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            name="flipYn"
            value={flipYn}
            label="미러링 여부"
            onChange={(e: any) => setFlipYn(e.target.value)}
            text={['Y', 'N']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="previewUrl"
            label="미리보기 링크"
            value={previewUrl}
            placeholder="링크를 입력해 주세요."
            onChange={(e) => {
              setPreviewUrl(e.target.value);
            }}
            onReset={() => setPreviewUrl('')}
          />
        </InputWrapper>
        <InputWrapper>
          <TagTextInput
            placeholder="키워드를 입력해주세요."
            label="검색 키워드"
            keyWord={searchKeyword || []}
            setKeyWord={setSearchKeyword}
            addWord={addkeyword}
            setAddWord={setAddKeyword}
          />
        </InputWrapper>
        <InputWrapper>
          <RadioButton
            value={activeYn}
            name="activeYn"
            label="공개 여부"
            onChange={(e: any) => setActiveYn(e.target.value)}
            text={['공개', '비공개']}
            type="activeYn"
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="필터 노출 시작일"
            name="startDate"
            initialStartDate={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
            }}
          />
        </InputWrapper>
        <InputWrapper>
          <DateInput
            label="필터 노출 종료일"
            name="endDate"
            initialStartDate={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
            }}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default FilterDetail;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px 40px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
  &:first-child {
    grid-row: 1;
  }

  &:nth-child(2) {
    grid-row: 1;
    margin-bottom: 40px;
  }

  &:nth-child(3) {
    grid-row: 3;
    grid-column: span 2;
  }

  &:nth-child(4) {
    grid-row: 4;
    grid-column: span 2;
    margin-bottom: 30px;
  }

  &:nth-child(5) {
    grid-row: 5;
    grid-column: span 2;
  }

  &:nth-child(6) {
    grid-row: 6;
    grid-column: span 2;
  }

  &:nth-child(7) {
    grid-row: 7;
    grid-column: span 2;
    margin-bottom: 30px;
  }

  &:nth-child(8) {
    grid-row: 8;
    grid-column: span 2;
  }

  &:nth-child(9) {
    grid-row: 9;
  }

  &:nth-child(10) {
    grid-row: 9;
  }
`;

const PreviewImageWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const PreviewImg = styled.img`
  width: 175px;
  height: 175px;
  border-radius: 50%;
  object-fit: contain;
`;
