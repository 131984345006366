const changeUrl = (contentsUrl: any) => {
  const arr = contentsUrl?.split('_');
  const URL_PREFIX = `${process.env.REACT_APP_ARTICLE_PREFIX}`;

  if (contentsUrl?.length > 0 && contentsUrl?.includes('mp4')) {
    const url = `${URL_PREFIX}/${arr[0]}/${arr[1]}/${arr[2]}/${arr[3].slice(
      0,
      6,
    )}/${arr[4].replace('.mp4', '')}/original/${contentsUrl}`;
    return url;
  }
  if (contentsUrl?.length > 0 && contentsUrl?.includes('m3u8')) {
    const url = `${URL_PREFIX}/${arr[0]}/${arr[1]}/${arr[2]}/${arr[3].slice(
      0,
      6,
    )}/${arr[4].replace('.m3u8', '')}/original/${contentsUrl.replace(
      '.m3u8',
      '.mp4',
    )}`;
    return url;
  }
  if (contentsUrl?.length > 0 && contentsUrl?.includes('mp3')) {
    const url = `${URL_PREFIX}/${arr[0]}/${arr[1]}/${arr[2]}/${arr[3].slice(
      0,
      6,
    )}/${arr[4].replace('.mp3', '')}/audio/original/${contentsUrl}`;
    return url;
  }

  return 'undefined';
};

export default changeUrl;
