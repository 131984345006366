import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';

// common
import axios from 'axios';
import NormalButton from '../../components/common/Button/NormalButton';
import ProfileImg from '../../components/common/image/ProfileImg';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import TagTextInput from '../../components/common/Input/TagTextInput';
import FileInput from '../../components/common/Input/FileInput';

// hook
import { useAppSelector } from '../../redux/store/hooks';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';

//config
import { BEATMAKER_DETAIL, BEATMAKER_SAVE } from '../../constant/apiUrl';

type data = {
  [key: string]: any;
};

//초기 비트메이커 데이터
const initialBeatMakerData: data = {
  id: '',
  profileFile: '',
  coverFile: '',
  pid: '',
  mobile: '',
  email: '',
  bmId: '',
  name: '',
  nameEn: '',
  password: '',
};

const AddBeatMaker = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const managerName = useAppSelector((state) => state.user.user.name);

  const [beatMakerData, setBeatMakerData] = useState(initialBeatMakerData);
  const [keyWord, setKeyWord] = useState<any>([]);
  const [addWord, setAddWord] = useState('');
  const [profileImg, setProfileImg] = useState('');
  const [coverImg, setCoverImg] = useState('');
  const beatMakerId: any = location.state;

  const {
    id,
    profileFile,
    coverFile,
    pid,
    name,
    email,
    mobile,
    address,
    bankAccountName,
    bankAccountNumber,
    bankAccountRecipient,
    nameEn,
    nameReal,
  } = beatMakerData;

  //취소하면 뒤로가기
  const goBack = () => {
    navigate('/beat/beatmaker');
  };

  // input 등록하기
  const handleInputSetData = useCallback(
    (e: any) => {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      const { name, value } = e.target;
      setBeatMakerData({ ...beatMakerData, [name]: value });
    },
    [beatMakerData],
  );

  // input 리셋하기
  const handleResetData = useCallback(
    (e) => {
      setBeatMakerData({ ...beatMakerData, [e]: initialBeatMakerData[e] });
    },
    [beatMakerData],
  );

  const handleCoverSetData = useCallback(
    (e: string) => {
      setBeatMakerData({ ...beatMakerData, coverFile: e });
    },
    [beatMakerData],
  );

  const handleProfileSetData = useCallback(
    (e: string) => {
      setBeatMakerData({ ...beatMakerData, profileFile: e });
    },
    [beatMakerData],
  );

  // 비트메이커 상세 수정 및 저장하기
  const editMaker = async (type: string) => {
    const formData = new FormData();
    const testMobile = mobile.replaceAll('-', '');

    formData.append('adminName', managerName);
    formData.append('address', address);
    formData.append('bankAccountName', bankAccountName);
    formData.append('bankAccountNumber', bankAccountNumber);
    formData.append('bankAccountRecipient', bankAccountRecipient);
    formData.append('mobile', mobile.slice(1, 11));
    formData.append('name', name);
    formData.append('email', email);
    formData.append('nameEn', nameEn);
    formData.append('nameReal', nameReal);
    formData.append('pid', pid);
    formData.append('profileImg', profileFile);
    formData.append('coverImg', coverFile);
    formData.append('searchKeywords', keyWord);

    if (
      type === 'add' &&
      (!email ||
        !mobile ||
        !name ||
        !nameEn ||
        // !profileFile ||
        // !coverFile ||
        !pid)
    ) {
      alert('양식을 모두 채워주세요.');
      return;
    }

    if (
      !/^((01[0-9])[1-9]+[0-9]{6,7})|(010[1-9][0-9]{7})$/.test(testMobile)
    ) {
      alert('휴대폰 번호 형식이 올바르지 않습니다.');
      return;
    }
    //이메일 유효성 검사
    const checkEmail =
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;

    if (!checkEmail.test(email)) {
      alert('이메일 형식이 올바르지 않습니다.');
      return;
    }

    try {
      dispatch(setLoading());
      if (type === 'edit') {
        await axios.post(`${BEATMAKER_DETAIL}/${id}/update`, formData, {});
        await alert('비트메이커가 수정되었습니다.');
      }
      if (type === 'add') {
        await axios.post(BEATMAKER_SAVE, formData, {});
        await alert('비트메이커가 추가되었습니다.');
      }
      await goBack();
    } catch (error: any) {
      alert('잠시후 다시 시도해주세요.');
    }
    dispatch(resetLoading());
  };

  const getDetailBeatMaker = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BEATMAKER_DETAIL}/${beatMakerId}/detail`,
        {},
      );
      const result = response.data.beatMaker;
      setBeatMakerData({
        ...result,
        mobile: result.mobile ? `0${result.mobile}` : '',
      });
      setProfileImg(result.profileImgUrl || '');
      setCoverImg(result.coverImgUrl || '');
      setKeyWord(result.searchKeywords?.split(',') || []);
    } catch (error) {
      console.log(error);
    }
  }, [beatMakerId]);

  useEffect(() => {
    if (beatMakerId) {
      getDetailBeatMaker().then((r) => r);
    }
  }, [beatMakerId, getDetailBeatMaker]);
  return (
    <>
      <TopContainer>
        <TopWrapper>
          <div />
          <ButtonWrapper>
            <NormalButton text="취소" onSubmit={goBack} className="cancelBtn" />
            {beatMakerId ? (
              <NormalButton
                text="수정하기"
                onSubmit={() => editMaker('edit')}
                className=""
              />
            ) : (
              <NormalButton
                text="저장하기"
                onSubmit={() => editMaker('add')}
                className=""
              />
            )}
          </ButtonWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper>
          <ProfileImg url={profileImg} className="profile" />
          <SpaceDiv />
          <FileInput
            type="coverImg"
            placeholder={
              profileImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="프로필"
            value={profileFile}
            setFile={handleProfileSetData}
            setImgUrl={setProfileImg}
          />
        </InputWrapper>
        <InputWrapper>
          <ProfileImg url={coverImg} />
          <SpaceDiv />
          <FileInput
            type="coverImg"
            placeholder={
              coverImg ? '파일이 등록되었습니다.' : '파일을 선택해주세요.'
            }
            label="커버 이미지"
            value={coverFile}
            setFile={handleCoverSetData}
            setImgUrl={setCoverImg}
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="pid"
            label="Pid"
            value={pid}
            placeholder="Pid를 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>

        <InputWrapper>
          <LabelTextInput
            name="name"
            label="아티스트명 국문"
            value={name}
            placeholder="아티스트명을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="nameEn"
            label="아티스트명 영문"
            value={nameEn}
            placeholder="아티스트명을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="mobile"
            label="휴대폰 번호"
            value={mobile}
            placeholder="휴대폰 번호를 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
            name="email"
            label="이메일"
            value={email}
            placeholder="이메일을 입력해주세요."
            onChange={handleInputSetData}
            onReset={handleResetData}
            Required
          />
        </InputWrapper>
        <InputWrapper>
          <TagTextInput
            placeholder="키워드를 입력해주세요."
            label="검색 키워드"
            keyWord={keyWord}
            setKeyWord={setKeyWord}
            addWord={addWord}
            setAddWord={setAddWord}
          />
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default AddBeatMaker;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  gap: 40px 30px;
  grid-gap: 40px 30px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div`
  &:nth-child(n + 1):nth-child(-n + 2) {
    grid-row: 1;
    margin-bottom: 30px;
  }
  &:nth-child(3) {
    grid-row: 2;
    grid-column: span 2;
  }

  &:nth-child(8) {
    grid-row: 5;
    grid-column: span 2;
  }
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
