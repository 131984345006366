import React, {useCallback, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {postsPerPage} from "../../constant/common";
import axios from "axios";
import { MASTER_REWARD_API, REWARD_MASTER } from '../../constant/apiUrl';
import Pagination from "../../components/common/Pagination";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import styled from "styled-components";
import {convertLanguageType} from "../../constant/reward";

const RewardMasterList = () => {
  const navigate = useNavigate();
    const getNumber = localStorage.getItem('pageNumber');
    const [totalCount, setTotalCount] = useState(0);
    const [pageNumber, setPageNumber] = useState(
        getNumber ? JSON.parse(getNumber) : 0,
    );
    const [contentsList, setContentsList] = useState([])

    const pageDispatch = useCallback((e: number) => {
        setPageNumber(e - 1);
    }, []);

    const getContents = useCallback(async () => {
        const params = {
            limit: postsPerPage,
            offset: pageNumber,
        };
        await axios
            .get(`${MASTER_REWARD_API}`, { params })
            .then((resp) => {
                setTotalCount(resp.data.totalCount);
                setContentsList(resp.data.rewardMasterList);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [contentsList, pageNumber]);

    useEffect(() => {
        getContents();
    }, [pageNumber]);

    const goToDetail = (state: any) => {
        navigate(`${REWARD_MASTER}/edit`, {
            state: {
                languageKey: state?.languageType,
            },
        });
    };

    return (
        <Container>
            <PaginationWrapper>
                {totalCount !== 0 && (
                    <Pagination
                        postsPerPage={postsPerPage}
                        totalCount={totalCount}
                        currentPage={pageNumber}
                        pageDispatch={pageDispatch}
                    />
                )}
            </PaginationWrapper>
            <MainContainer>
                <Table
                    colList={colList}
                    thead={
                        <>
                            <ListHead title="언어 타입" />
                            <ListHead title="일회성 미션 목록 제목" />
                            <ListHead title="일회성 미션 목록 설명" />
                            <ListHead title="일일 초기화 미션 목록 제목" />
                            <ListHead title="일일 초기화 미션 목록 설명" />
                            <ListHead title="완료 미션 목록 제목" />
                            <ListHead title="완료 미션 목록 설명" />
                            <ListHead title="일일 초기화 시간" />
                        </>
                    }
                >
                    {contentsList?.map((el: any) => (
                        <tr key={el.languageType} onClick={() => goToDetail(el)}>
                            <td>
                                <ListItem text={convertLanguageType(el.languageType)} />
                            </td>
                            <td>
                                <ListItem text={el.onlyOnceMissionTitle} />
                            </td>
                            <td>
                                <ListItem text={el.onlyOnceMissionDescription} />
                            </td>
                            <td>
                                <ListItem text={el.dailyMissionTitle} />
                            </td>
                            <td>
                                <ListItem text={el.dailyMissionDescription} />
                            </td>
                            <td>
                                <ListItem text={el.completedMissionTitle} />
                            </td>
                            <td>
                                <ListItem text={el.completedMissionDescription} />
                            </td>
                            <td>
                                <ListItem text={el.dailyInitializeUtcTime} />
                            </td>
                        </tr>
                    ))}
                </Table>
            </MainContainer>
        </Container>
    );
};

export default RewardMasterList;

export const colList = [80, 100, 100, 100, 100, 80, 80, 80];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
