import React, { useEffect } from 'react';
import styled from 'styled-components';

const DashBoard = () => {
  useEffect(() => {
    const refreshUrl = window.sessionStorage.getItem('refreshUrl');
    if (refreshUrl) {
      window.location.href = refreshUrl;
      window.sessionStorage.removeItem('refreshUrl');
    }
  }, []);

  return (
    <FlexContainer>
      <h1>DashBoard는 준비중입니다.</h1>
    </FlexContainer>
  );
};

export default DashBoard;

const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 150px 0;
`;

// const DashBoardWrapper = styled.div`
//   margin: 70px 0 0 30px;
// `;
