import React from 'react';

//컴포넌트
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import { IsPrivate } from '../../../constant/common';

// 타입
import { RowComponentDataProps } from '../../../definitions/beat';

// hooks
import handleDate from '../../../hooks/handleDate';

// 인터페이스
interface dataProps {
  data: RowComponentDataProps;
}

const GenreRowComponent = ({ data }: dataProps) => {
  const { coverImgUrl, title, bmPid, genreNames, privateYn, startDt } = data;

  return (
    <tr>
      <td>
        <ListCoverImg url={coverImgUrl} />
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={bmPid} />
      </td>
      <td>
        <ListItem text={genreNames.join(', ')} />
      </td>
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
    </tr>
  );
};

export default GenreRowComponent;
