import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// common
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import NormalButton from '../../../components/common/Button/NormalButton';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';

// hook
import useInput from '../../../hooks/useInput';

// constant
import {
  eventCountryTypeList,
  eventActiveYnOptions,
} from '../../../constant/event';

import ListItem from "../../../components/common/List/ListItem";
import ListCoverImg from "../../../components/common/List/ListCoverImg";
import handleDate from "../../../hooks/handleDate";
import CultureMenuSelect from "../component/CultureMenuSelect";
import {MAGAZINE, MAGAZINE_CATEGORY} from "../../../constant/apiUrl";
import {
  GetCountryOption, GetLanguageOption,
  IsActive,
  postsPerPage
} from "../../../constant/common";

// 타입

const Magazine = () => {
  const navigate = useNavigate();
  const storagePageNumber = localStorage.getItem('pageNumber');
  const storageWord = localStorage.getItem('searchWord');
  const [magazineList, setMagazineList] = useState([]);
  const [word, setWord] = useInput(storageWord || '');
  const [country, setCountry] = useState('');
  const [activeYn, setActiveYn] = useState('');
  const [minStartDate, setMinStartDate] = useState('');
  const [maxEndDate, setMaxEndDate] = useState('');

  const [magazineCategoryId, setMagazineCategoryId] = useState(0);
  const [magazineCategoryName, setMagazineCategoryName] = useState('카테고리 선택');
  const [magazineCategories, setMagazineCategories] = useState([]);

  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    storagePageNumber ? JSON.parse(storagePageNumber) : 0,
  );

  // 전체 카테고리 불러오기
  useEffect(async () => {
    const params = {
      offset: 0,
      limit: 100
    };
    await axios.get(`${MAGAZINE_CATEGORY}/all`, {params})
    .then(res => {
      setMagazineCategories(res.data.magazineCategories);
    })
    .catch(reason => {
      console.error(reason);
      alert('시스템 관리자에게 문의해 주세요');
    });
  }, []);

  const params = {
    magazineCategoryId: magazineCategoryId,
    countryCode: country,
    activeYn,
    word,
    limit: postsPerPage,
    searchStartDate: minStartDate,
    searchEndDate: maxEndDate,
    offset: pageNumber * postsPerPage,
  };

  useEffect(() => {
    if (storagePageNumber) {
      window.localStorage.setItem('pageNumber', '0');
      window.localStorage.setItem('searchWord', '');
    }
  }, [storagePageNumber]);

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  // type, bpm, privateUse, openDate
  const handleSelect = (e, setState) => {
    setState(e.currentTarget.title);
    setPageNumber(0);
  }

  // 리스트 불러오기
  const getMagazines = async () => {
    await axios.get(MAGAZINE, {params,})
    .then(resp => {
      setMagazineList(resp.data.magazineList);
      setTotalCount(resp.data.count || 0);
    })
    .catch(reason => {
      console.error(reason);
      alert('시스템 관리자에게 문의해 주세요');
    });
  };

  // 엔터키로  검색
  const enterKeyPress = (e) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getMagazines();
    }
  };

  // 상세로 이동
  const goToDetail = (state) => {
    navigate('/culture/magazine/detail', {
      state: {
        prevId: state?.id,
        nextSequence: totalCount + 1,
        magazineCategoryId,
        magazineCategoryName,
        magazineCategories,
      },
    });
  };

  useEffect(() => {
    if (storagePageNumber === '0') {
      params.offset = 0;
      setPageNumber(0);
    }
    getMagazines().then(r => r);
  }, [magazineCategoryId, activeYn, country, minStartDate, maxEndDate, pageNumber]);

  const handleSelectSetData = (e) => {
    setMagazineCategoryId(e.magazineCategoryId);
    setMagazineCategoryName(e.name);
  };

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <CultureMenuSelect
                placeholder=""
                value={magazineCategoryName}
                onChange={handleSelectSetData}
                options={magazineCategories}
                label=""
            />
            <SearchInputWrapper>
              <SearchInput value={word} onChange={setWord} onKeyPress={enterKeyPress}/>
            </SearchInputWrapper>
            {/*<ListDate*/}
            {/*  setMinStartDate={setMinStartDate}*/}
            {/*  setMaxStartDate={setMaxEndDate}*/}
            {/*  active={!!(minStartDate && maxEndDate)}*/}
            {/*/>*/}
          </SearchWrapper>
          <ButtonWrapper>
            <NormalButton
              text="등록"
              onSubmit={
                () => navigate('/culture/magazine/add', {
                  state: {
                    nextSequence: totalCount + 1,
                    magazineCategoryId,
                    magazineCategoryName,
                    magazineCategories,
                  },
                })
              }
            />
            {/*<NormalButton text="순서 변경" onSubmit={() => navigate('/culture/magazine/editSeq',{ state: { magazineCategoryId } })}/>*/}
          </ButtonWrapper>
        </TopWrapper>
        <PaginationWrapper>
          {totalCount !== 0 && (
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <Table
          colList={[60, 100, 150, 70, 110, 110, 70, 70]}
          thead={
            <>
              <ListHead title="번호" />
              <ListHead title="이미지" />
              <ListHead title="제목" />
              <ListSelect
                title="공개여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setActiveYn}
              />
              <ListHead title="노출 시작일" />
              <ListHead title="노출 종료일" />
              <ListSelect
                title="국가"
                list={eventCountryTypeList}
                onClick={handleSelect}
                setState={setCountry}
              />
              <ListHead title="언어" />
            </>
          }
        >
          {magazineList?.map((el) => (
              <tr key={el.id} onClick={() => goToDetail(el)}>
                <td><ListItem text={el.id} /></td>
                <td><ListCoverImg url={el.bannerImgUrl} className="event" /></td>
                <td><ListItem type="title" text={el.title} /></td>
                <td><ListItem text={IsActive[el.activeYn]} /></td>
                <td><ListItem text={handleDate(el.startDt)} /></td>
                <td><ListItem text={handleDate(el.endDt)} /></td>
                <td><ListItem text={GetCountryOption[el.countryCode]} /></td>
                <td><ListItem text={GetLanguageOption[el.language]} /></td>
              </tr>
          ))}
        </Table>
      </MainContainer>
    </>
  );
};
export default Magazine;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  flex-direction: column;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const SearchInputWrapper = styled.div`
  min-width: 439px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;
