import React from 'react';
import styled from 'styled-components';
import blankUserImg from '../../../assets/images/page/blankUserImg.png';
import blankImg from '../../../assets/images/page/blankImg.png';

interface ListCoverImgProps {
  url?: string;
  className?: string;
  onClick?: (e: any) => void;
}

interface CoverImgProps {
  url?: string;
}

const ListCoverImg = ({ url, className, onClick }: ListCoverImgProps) => {
  return <CoverImg url={url} className={className} onClick={onClick} />;
};

export default ListCoverImg;

const CoverImg = styled.div<CoverImgProps>`
  width: 44px;
  height: 44px;
  margin: 0 auto 0;
  border-radius: 3px;
  background: url(${({ url }) => url || blankImg}) center no-repeat;
  background-size: cover;

  &.article {
    width: 55px;
    height: 104px;
    margin: 12px auto;
  }
  &.beatMaker {
    border-radius: 40px;
    background: url(${({ url }) => url || blankUserImg}) center no-repeat;
  }
  &.event {
    width: 130px;
    height: 87px;
    margin: 12px auto;
  }
  &.eventVideo {
    width: 170px;
    height: 323px;
    border-radius: 6px;
    margin: 20px 35px 0 0;
  }
  &.fixedArticle {
    width: 187px;
    height: 40px;
    margin: 12px auto;
  }
`;
