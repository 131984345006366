import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import {useDispatch} from "react-redux";

import axios from 'axios';
import styled from 'styled-components';

import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../components/common/Button/RadioButton';
import {resetLoading, setLoading} from "../../../redux/slices/user";
import LabelTextAreaInput
    from "../../../components/common/Input/LabelTextAreaInput";
import {
  MAGAZINE_CATEGORY,
  MAGAZINE_CATEGORY_DETAIL
} from "../../../constant/apiUrl";

const MagazineCategoryDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [magazineCategoryId] = useState(location.state?.id);
    const [name, setName] = useState('');
    const [nameEn, setNameEn] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionEn, setDescriptionEn] = useState('');
    const [activeYn, setActiveYn] = useState('Y');

    const saveCategory = async () => {
        dispatch(setLoading());

        // 필수 및 유효성 체크
        if (!name || !nameEn) {
            alert('카테고리 이름은 필수 입니다.')
            dispatch(resetLoading());
            return;
        }

        const request = {
            name, nameEn,
            description, descriptionEn,
            activeYn,
        };

        try {
            if (magazineCategoryId) {
                request['magazineCategoryId'] = magazineCategoryId; // 수정시 카테고리 아이디 세팅
                await axios.put(MAGAZINE_CATEGORY, request, {})
                alert('수정되었습니다.');
                window.location.reload();
            }
            else {
                request['sequence'] = location.state?.count + 1; // 저장시 시퀸스 세팅
                await axios.post(MAGAZINE_CATEGORY, request, {})
                alert('저장되었습니다.');
                navigate('/culture/magazine-category');
            }
        } catch (err) {
            console.log(err);
            alert('예상치 못한 오류가 발생하였습니다. 담당자에게 문의하세요.');
        } finally {
            dispatch(resetLoading());
        }
    };

    const getCategoryDetail = async () => {
        await axios.get(`${MAGAZINE_CATEGORY_DETAIL}/${magazineCategoryId}`)
            .then(res => {
                console.log(res.data)
                setName(res.data.name);
                setNameEn(res.data.nameEn);
                setDescription(res.data.description);
                setDescriptionEn(res.data.descriptionEn);
                setActiveYn(res.data.activeYn);
            })
            .catch(reason => console.error(reason));
    }

    useEffect(() => magazineCategoryId && getCategoryDetail(), [])

    return (
        <>
            <TopContainer>
                <ButtonWrapper>
                      <NormalButton text="취소" onSubmit={() => navigate('/culture/magazine-category')} className="cancelBtn"/>
                      <NormalButton text={magazineCategoryId ? "수정" : "저장"} onSubmit={() => saveCategory()}/>
                </ButtonWrapper>
            </TopContainer>
            <TitleText>매거진 카테고리{magazineCategoryId ? (' : ' + magazineCategoryId) : ''}</TitleText>
            <LineDiv/>

            <MainContainer>
                {/* 이름 */}
                <InputWrapper span={1}>
                  <LabelTextInput
                      name="name" label="카테고리 이름" value={name} placeholder="카테고리 이름을 입력해 주세요."
                      onChange={(e) => setName(e.target.value)}
                      onReset={() => setName('')}
                      Required
                  />
                </InputWrapper>
                <InputWrapper span={1}>
                  <LabelTextInput
                      name="nameEn" label="카테고리 영어 이름" value={nameEn} placeholder="카테고리 영어 이름을 입력해 주세요."
                      onChange={(e) => setNameEn(e.target.value)}
                      onReset={() => setNameEn('')}
                      Required
                  />
                </InputWrapper>

                {/* 설명 */}
                <InputWrapper span={2}>
                  <LabelTextAreaInput
                      name="description" label="설명" value={description} placeholder="카테고리 설명을 입력해 주세요."
                      setValue={(e) => setDescription(e.target.value)}
                  />
                </InputWrapper>
                <InputWrapper span={2}>
                  <LabelTextAreaInput
                      name="descriptionEn" label="영어 설명" value={descriptionEn} placeholder="카테고리 영어 설명을 입력해 주세요."
                      setValue={(e) => setDescriptionEn(e.target.value)}
                  />
                </InputWrapper>

                {/* 공개 여부 */}
                <InputWrapper span={2}>
                  <RadioButton
                        value={activeYn}
                        name="activeYn"
                        label="공개 여부"
                        onChange={e => setActiveYn(e.target.value)}
                        text={['공개', '비공개']}
                        type="activeYn"
                        Required
                  />
                </InputWrapper>
            </MainContainer>
        </>
    );
};
export default MagazineCategoryDetail;

const TopContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
  
    button {
        &:last-child {
            margin-left: 20px;
        }
    }
`;

const MainContainer = styled.div`
    width: 100%;
    margin-top: 50px;
    padding-bottom: 100px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 30px;
    grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '30px'};
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;
