import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import bottomArrow from '../../../assets/images/page/bottomArrow.png';
import useOutSideClick from '../../../hooks/useOutSideClick';

interface SelectModuleProps {
  name: string;
  placeholder: string;
  value: string;
  label: string;
  onChange: (option: any, name: string) => void;
  Required?: boolean;
  options: Array<any>;
  className?: string;
  id?: string;
  readonly?: boolean;
}

const SelectModule = ({
  id,
  name,
  placeholder,
  value,
  label,
  onChange,
  Required,
  options,
  className,
  readonly
}: SelectModuleProps) => {
  // console.log('SelectModule > name:',name,'/options:', options);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const toggling = () => {
    !readonly && setIsOpen(!isOpen);
  }

  const onOptionClicked = (option: any) => {
    onChange(option, name);
    setIsOpen(false);
  };

  const chooseOption = (option: any) => {
    switch (name) {
      case 'firstReportOption':
      case 'bmName':
      case 'countryCode':
      case 'language':
      case 'noticeType':
      case 'allowComment':
      case 'type':
      case 'status':
      case 'suggestWordCategoryIdAdd':
        return <>{option.name}</>;

      case 'requireHashTagMemberId':
        return <>{option.pid}</>;

      case 'secondReportOption':
        return <>{option.subDescription}</>;

      case 'parentGenre':
        return <>{option.genre}</>;

      case 'conditionType':
      case 'rewardType':
      case 'rewardCurrencyType':
        return <>{option.text}</>;

      case 'baundMusicType':
      case 'applyResult':
      case 'judgeResult':
      case 'categoryType':
      case 'baundApiCategoryType':
        return <>{option.text}</>;

      default:
        return <>{option}</>;
    }
  };

  // 외부 클릭시 닫기
  useOutSideClick(ref, () => setIsOpen(false));

  if (!className && readonly) {
      className = 'readonlyType';
  }

  return (
    <SelectBox id={id}>
      {label &&(
        <InputText className={className}>
          {label} {Required && <span>*</span>}
        </InputText>
      )}
      <DropDownContainer ref={ref}>
        <DropDownHeader onClick={toggling} className={className}>
          {value || <span>{placeholder}</span>}
        </DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList className={className}>
              {options.map((option) => (
                <ListItem
                  onClick={() => onOptionClicked(option)}
                  key={Math.random()}
                >
                  {chooseOption(option)}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
    </SelectBox>
  );
};

export default SelectModule;

const SelectBox = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 3;

  ${(props) =>
    props.id === 'push' &&
    css`
      width: 100%;
    `}
`;

const InputText = styled.span`
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  &.battleSearchType {
    display: none;
  }

  &.commonSearchType200 { display: none; }
  
  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
  
  &.minWidth300 { min-width: 300px}
`;

const DropDownContainer = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
`;

const DropDownHeader = styled.div`
  width: 100%;
  height: 55px;
  padding: 0 20px;
  border-radius: 6px;
  background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
    ${({ theme }) => theme.color.subBrightGray};
  background-size: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 52px;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;

  &.borderType {
    background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
      ${({ theme }) => theme.color.mainWhite};
    border: 1px solid ${({ theme }) => theme.color.subGray};
  }

  &.battleSearchType {
    height: auto;
    width: 150px;
  }

  &.commonSearchType200 { height: auto; width: 200px; }

  &.readonlyType {
    // background: url(${bottomArrow}) calc(100% - 8px) center no-repeat
    //   ${({ theme }) => theme.color.subBrightGray};
    color: ${({ theme }) => theme.color.subUnactiveGray};
  }
  
  span {
    font-weight: 500;
    color: ${({ theme }) => theme.color.subGray};
  }

  &.minWidth300 { min-width: 300px}
`;

const DropDownListContainer = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
`;

const DropDownList = styled.ul`
  margin: 0;
  max-height: 200px;
  padding: 18px 30px;
  overflow: scroll;
  background: #ffffff;
  box-shadow: 0 10px 40px 0 rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.mainBlack};
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }
  
  &.battleSearchType {
    padding: 20px 10px;
  }
  
  &.commonSearchType200 { padding: 20px 20px; }
  
  &.minWidth300 { min-width: 300px}
`;

const ListItem = styled.li`
  list-style: none;
  cursor: pointer;

  :not(:last-child)&:after {
    content: '';
    display: block;
    border-bottom: 1px solid #e7e7e7;
    margin: 18px 0;
  }
`;
