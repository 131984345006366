import React from 'react';
import styled from 'styled-components';
import { CustomProps } from '../../../definitions/common';

interface Props {
  type?: string;
  text?: string | number;
  className?: string | undefined;
}

const ListItem = ({ type = '', text, className }: Props) => <Item className={className} type={type}>{text}</Item>;
export default ListItem;

const Item = styled.span<CustomProps>`
  word-break: keep-all;
  word-wrap: break-word;
  font-size: 12px;
  font-weight: ${({type}) => (type === 'title' ? '600' : '400')};
  color: ${({theme}) => theme.color.mainBlack};

  &.hashTagText {
    float: left;
    font-size: 18px;
    font-weight: 600;
  }

  &.private {
    color: #db3246;
    font-size: 14px;
  }
  
  &.left {
    text-align: left;
  }

  &.articleType_BT {color: #3f2abd;}
  &.battleType {color: #3f2abd; font-size: 14px;}
  &.battleStatus_BEGIN {color: peachpuff; font-size: 13px; font-weight: bold}
  &.battleStatus_SPARK {color: gold; font-size: 14px; font-weight: bold}
  &.battleStatus_VOTING {color: hotpink; font-size: 14px; font-weight: bold}
  &.battleStatus_MISFIRE {color: darkgray;}
  &.battleStatus_FINISH {color: #000000; font-size: 14px; font-weight: bold}
  
  &.link {color: ${({theme}) => theme.color.mainBlue}; cursor: pointer;}
`;
