import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

// common
import SearchInput from '../../../components/common/Input/SearchInput';
import Pagination from '../../../components/common/Pagination';
import NormalButton from '../../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListSelect from '../../../components/common/Select/ListSelect';
import ListDate from '../../../components/common/List/ListDate';

// constant
import {
	eventColList,
	eventCountryTypeList,
	eventActiveYnOptions,
	eventSearchTypeList,
} from '../../../constant/event';
import {
	countryOptions,
	GetCountryOption,
	GetLanguageOption,
	languageOptions,
	postsPerPage
} from '../../../constant/common';

// config
import {EVENT_COPY, EVENT_LIST} from '../../../constant/apiUrl';
import EventRowComponent from '../components/EventRowComponent';

// hook
import useInput from '../../../hooks/useInput';
import Modal from "../../../components/common/Modal/Modal";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import SelectModule from "../../../components/common/Select/SelectModule";
import {setLoading, resetLoading} from "../../../redux/slices/user";
import {useDispatch} from "react-redux";

const Event = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const storagePageNumber = localStorage.getItem('pageNumber');
	const storageType = localStorage.getItem('searchType');
	const storageWord = localStorage.getItem('searchWord');
	const [eventList, setEventList] = useState([]);
	const [word, setWord] = useInput(storageWord || '');
	const [type, setType] = useState(storageType || '');
	const [country, setCountry] = useState('');
	const [filterCountry, setFilterCountry] = useState('');
	const [activeYn, setActiveYn] = useState('');
	const [filterActiveYn, setFilterActiveYn] = useState('');
	const [minStartDate, setMinStartDate] = useState('');
	const [maxEndDate, setMaxEndDate] = useState('');

	// 복제하기에서 사용
	const [modalOpen, setModalOpen] = useState(false);
	const [countryCode, setCountryCode] = useState('');
	const [language, setLanguage] = useState('');
	const [eventId, setEventId] = useState(0);

	// pagination state
	const [totalCount, setTotalCount] = useState(0);
	const [pageNumber, setPageNumber] = useState(storagePageNumber ? JSON.parse(storagePageNumber) : 0);

	const params = {
		searchType: type,
		countryCode: country,
		activeYn,
		word,
		limit: postsPerPage,
		searchStartDate: minStartDate,
		searchEndDate: maxEndDate,
		offset: pageNumber * postsPerPage,
	};

	useEffect(() => {
		if (storagePageNumber) {
			window.localStorage.setItem('pageNumber', '0');
			window.localStorage.setItem('searchType', '');
			window.localStorage.setItem('searchWord', '');
		}
	}, [storagePageNumber]);

	const pageDispatch = useCallback((e) => setPageNumber(e - 1), []);

	// type, bpm, privateUse, openDate
	const handleSelect = (e, setState,) => setState(e.currentTarget.title);

	// 배너 리스트 불러오기
	const getEventList = async () => {
		try {
			const resp = await axios.get(EVENT_LIST, {
				params,
			});
			setEventList(resp.data.eventList);
			setTotalCount(resp.data.count || 0);
		} catch (err) {
			console.log(err);
		}
	};

	// 엔터키로 member 검색
	const enterKeyPress = (e) => {
		if (e.key === 'Enter' && word) {
			if (!type) {
				setType('T');
				params.searchType = 'T';
			}
			setPageNumber(0);
			params.offset = 0;
			getEventList().then(() => dispatch(resetLoading()));
		}
	};

	// 이벤트 복제하기
	const eventCopy = () => {
		dispatch(setLoading());

		let alertMsg = '';
		if (!eventId) alertMsg += '이벤트 아이디를 입력해주세요.\n';
		if (!language) alertMsg += '언어를 선택해주세요.\n';
		if (!countryCode) alertMsg += '국가를 선택해주세요.\n';
		if (alertMsg.length > 0) alert(alertMsg);

		axios.post(EVENT_COPY, {eventId, language, countryCode})
		.then(() => {
			setEventId(0);
			setLanguage('');
			setCountryCode('');
			setModalOpen(false);
			getEventList().then(() => dispatch(resetLoading()));
		})
		.catch(err => alert(err));
	}

	useEffect(() => {
		if (storagePageNumber === '0') {
			params.offset = 0;
			setPageNumber(0);
		}
		getEventList().then(() => dispatch(resetLoading()));
	}, [pageNumber, activeYn, country, minStartDate, maxEndDate]);

	useEffect(() => {
		setPageNumber(0);
		setCountry(filterCountry);
	}, [filterCountry])

	useEffect(() => {
		setPageNumber(0);
		setActiveYn(filterActiveYn);
	}, [filterActiveYn])

	return (
		<>
			<TopContainer>
				<TopWrapper>
					<SearchWrapper>
						<SearchMenuSelect
							options={eventSearchTypeList}
							onClick={handleSelect}
							setState={setType}
						/>
						<SearchInputWrapper>
							<SearchInput
								value={word}
								onChange={setWord}
								onKeyPress={enterKeyPress}
							/>
						</SearchInputWrapper>
						<ListDate
							setMinStartDate={setMinStartDate}
							setMaxStartDate={setMaxEndDate}
							active={!!(minStartDate && maxEndDate)}
							// requiredDate
						/>
					</SearchWrapper>
					<NormalButton
						text="이벤트 복제하기"
						onSubmit={() => setModalOpen(true)}
						className="cancelBtn"
					/>
					<NormalButton
						text="이벤트 등록하기"
						onSubmit={() => navigate('/operation/event/add', {state: {type: 'event'}})}
						className=""
					/>
					<NormalButton
						text="순서 변경"
						onSubmit={() => navigate('/operation/event/editSeq')}
						className=""
					/>
				</TopWrapper>
				<PaginationWrapper>
					{totalCount !== 0 && (
						<Pagination
							postsPerPage={postsPerPage}
							totalCount={totalCount}
							currentPage={pageNumber}
							pageDispatch={pageDispatch}
						/>
					)}
				</PaginationWrapper>
			</TopContainer>
			<MainContainer>
				<Table
					colList={eventColList}
					thead={
						<>
							<ListHead title="번호"/>
							<ListHead title="이미지"/>
							<ListHead title="프로젝트 명"/>
							<ListHead title="제목"/>
							<ListSelect
								title="공개여부"
								list={eventActiveYnOptions}
								onClick={handleSelect}
								setState={setFilterActiveYn}
							/>
							<ListHead title="시작일"/>
							<ListHead title="종료일"/>
							<ListSelect
								title="국가"
								list={eventCountryTypeList}
								onClick={handleSelect}
								setState={setFilterCountry}
							/>
							<ListHead title="언어"/>
						</>
					}
				>
					{eventList?.map(el => (
						<EventRowComponent
							key={el.id}
							data={el}
							// saveStorage={() => saveStorage(pageNumber, type, word)}
							type="event"
						/>
					))}
				</Table>
			</MainContainer>

			{modalOpen && <Modal option={{class: 'compact'}} closeModal={() => setModalOpen(false)} >
				<InputContainer>
					<InputWrapper>
						<LabelTextInput
							name="eventId"
							label="이벤트 아이디"
							type='number'
							value={eventId}
							placeholder="목표 출석체크 개수를 입력해주세요."
							onChange={e => setEventId(e.target.value)}
							Required
						/>
					</InputWrapper>
					<SpaceDiv value='20px' />
					<InputWrapper>
						<SelectModule
							name="countryCode"
							placeholder="국가를 선택해주세요."
							value={GetCountryOption[countryCode]}
							label="국가"
							onChange={e => setCountryCode(e.value)}
							options={countryOptions}
							Required
						/>
					</InputWrapper>
					<SpaceDiv value='20px' />
					<InputWrapper>
						<SelectModule
							name="language"
							placeholder="언어를 선택해주세요."
							value={GetLanguageOption[language]}
							label="언어"
							onChange={e => setLanguage(e.value)}
							options={languageOptions}
							Required
						/>
					</InputWrapper>
					<SpaceDiv value='40px' />
					<NormalButton text="복제하기" onSubmit={eventCopy}/>
				</InputContainer>
			</Modal>}
		</>
	);
};

export default Event;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 383px;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
`;


//

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  margin: 0 20px;
`;

const InputWrapper = styled.div`
  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }

  &.external-add {
    text-align: right;
    display: block !important;
  }

  &.area2 {
    background-color: #f1f3f5;
    padding: 25px;
    border-radius: 6px;
  }
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({value}) => value};
`;