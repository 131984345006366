import React from 'react';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';

// common
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

// constant
import {
  IsActive,
  GetLanguageOption,
  GetCountryOption,
} from '../../../constant/common';

// hook
import handleDate from '../../../hooks/handleDate';

// 타입
import { EventRowComponentDataProps } from '../../../definitions/event';
import { EVENT_LIST, NOTICE } from '../../../constant/apiUrl';

// 인터페이스
interface dataProps {
  data: EventRowComponentDataProps;
  type: string;
  saveStorage?: () => void;
}

const EventRowComponent = ({ data, type, saveStorage }: dataProps) => {
  const {
    id,
    bannerImgUrl,
    bannerVerticalImgUrl,
    activeYn,
    title,
    countryCode,
    startDt,
    endDt,
    language,
    projectName,
  } = data;
  const navigate = useNavigate();

  // 리스트별 디테일 페이지 이동하기
  const showEventDetail = async () => {
    try {
      if (type === 'event') {
        const resp = await axios.get(`${EVENT_LIST}/${id}`);
        navigate('/operation/event/detail', {
          state: { eventDetailData: resp.data.event, type },
        });
      }
      if (type === 'notice') {
        const resp = await axios.get(`${NOTICE}/${id}`);
        navigate('/operation/notice/detail', {
          state: { noticeDetailData: resp.data.notice, type },
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <tr
      onClick={() => {
        showEventDetail();
        if (saveStorage) {
          saveStorage();
        }
      }}
    >
      <td>
        <ListItem text={id} />
      </td>
      <td>
        {bannerVerticalImgUrl ? (
          <ListCoverImg url={bannerVerticalImgUrl} className="event" />
        ) : (
          <ListCoverImg url={bannerImgUrl} className="event" />
        )}
      </td>
      <td>
        <ListItem text={projectName || '-'} />
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={IsActive[activeYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
      <td>
        <ListItem text={handleDate(endDt)} />
      </td>
      <td>
        <ListItem text={GetCountryOption[countryCode]} />
      </td>
      <td>
        <ListItem text={GetLanguageOption[language]} />
      </td>
    </tr>
  );
};

export default EventRowComponent;
