import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// common
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import NormalButton from '../../components/common/Button/NormalButton';
import SearchMenuSelect from '../../components/common/Select/SearchMenuSelect';
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import ListSelect from '../../components/common/Select/ListSelect';
import Modal from '../../components/common/Modal/Modal';
import SelectModule from '../../components/common/Select/SelectModule';
import MultiSelect from '../../components/common/Select/MultiSelect';

// hook
import useInput from '../../hooks/useInput';

// constant
import { adminColList } from '../../constant/admin';
import { accountSearchTypeList } from '../../constant/account';
import { eventActiveYnOptions } from '../../constant/event';
import { postsPerPage } from '../../constant/common';

// config
import { ADD_ACCOUNTS, MASTER_ROLE_LIST } from '../../constant/apiUrl';
import AccountRowComponent from './components/AccountRowComponent';
import { resetLoading, setLoading } from '../../redux/slices/user';

interface dataTypes {
  id: number;
  activeYn: string;
  roles: Array<string>;
}

type data = {
  [key: string]: any;
};

//초기 계정 데이터
const initialAccountData: data = {
  id: '',
  roles: [],
  activeYn: '',
};

const AdminAccountManagement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [adminRoleList, setAdminRoleList] = useState<any>([]);

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [accountList, setAccountList] = useState([]);
  const [word, setWord] = useInput('');
  const [type, setType] = useState<string>('EMAIL');
  const [country, setCountry] = useState<string>('');
  const [privateYn, setPrivateYn] = useState<string>('');
  const [filterPrivateYn, setFilterPrivateYn] = useState<string>('');
  const [role, setRole] = useState('');
  const [filterRole, setFilterRole] = useState('');
  const [accountData, setAccountData] = useState(initialAccountData);

  const { id, roles, activeYn } = accountData;

  const accountRoleList = adminRoleList?.map((el: any) => el.userRole);
  const accountRoleOptionList = accountRoleList.map((el: any) => {
    return { text: el, value: el };
  });
  // pagination state
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const params = {
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
    activeYn: privateYn,
    role,
    type,
    word,
  };

  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getAccountList();
    }
  };

  // type, bpm, privateUse, openDate
  const handleSelect = (
    e: React.MouseEvent<HTMLLIElement>,
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.title);
  };

  // rekognition 수정 버튼 클릭
  const editAccount = (e: dataTypes) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { activeYn, roles, id } = e;
    setAccountData({
      ...accountData,
      roles,
      activeYn,
      id,
    });
    setModalOpen(true);
  };

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
  };

  // 권한 여러개 저장하기
  const handleMultiSetData = useCallback(
    (e: string) => {
      const duplicateCheck: number = roles?.indexOf(e);
      if (duplicateCheck !== -1) {
        const removeOption = roles?.filter((el: string) => el !== e);

        setAccountData({
          ...accountData,
          roles: removeOption,
        });
        return;
      }
      setAccountData({
        ...accountData,
        roles: roles.concat(e),
      });
    },
    [accountData, roles],
  );

  // 관리자 활성화 및 권한 수정
  const changeActive = async () => {
    const adminRole = adminRoleList.filter((el: any) => {
      return roles.includes(el.userRole);
    });
    const adminAccountRolesActiveYnUpdateRequest = {
      activeYn,
      roleIds: adminRole?.map((el: any) => el.id),
    };
    try {
      dispatch(setLoading());
      await axios.post(
        `${ADD_ACCOUNTS}/${id}/active`,
        adminAccountRolesActiveYnUpdateRequest,
        {},
      );
      closeModal();
      alert('계정이 수정되었습니다.');
      window.location.reload();
      getAccountList();
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
  };

  const handleSetData = useCallback(
    (e: string, name: string) => {
      setAccountData({ ...accountData, [name]: e });
    },
    [accountData],
  );

  // 어드민 리스트 불러오기
  const getAccountList = async () => {
    try {
      const resp = await axios.get(`/accounts`, {
        params,
      });
      setTotalCount(resp.data.count || 0);
      setAccountList(resp.data.adminAccountList);
    } catch (err) {
      console.log(err);
    }
  };

  // 어드민 권한 리스트 불러오기
  const getAdminRoleList = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const params = {
      limit: 100,
      offset: 0,
    };
    try {
      const resp = await axios.get(MASTER_ROLE_LIST, { params });
      setAdminRoleList(resp.data.adminRoleList);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getAdminRoleList();
  }, [getAdminRoleList]);

  useEffect(() => {
    getAccountList();
  }, [pageNumber, country, role, privateYn]);

  useEffect(() => {
    setPageNumber(0);
    setRole(filterRole);
  }, [filterRole])

  useEffect(() => {
    setPageNumber(0);
    setPrivateYn(filterPrivateYn);
  }, [filterPrivateYn])

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchMenuSelect
              options={accountSearchTypeList}
              onClick={handleSelect}
              setState={setType}
            />
            <SearchInputContainer>
              <SearchInput
                value={word}
                onChange={setWord}
                onKeyPress={enterKeyPress}
              />
            </SearchInputContainer>
          </SearchWrapper>
          <NormalButton
            text="계정 추가하기"
            onSubmit={() => {
              navigate('/admin/adminAccount/add');
            }}
            className=""
          />
        </TopWrapper>
        {totalCount !== 0 && (
          <PaginationWrapper>
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          </PaginationWrapper>
        )}
      </TopContainer>
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <InputContainer>
            <InputWrapper>
              <MultiSelect
                name="roles"
                placeholder="권한을 선택해주세요."
                value={roles}
                label="권한"
                onChange={handleMultiSetData}
                options={accountRoleList}
              />
            </InputWrapper>
            <InputWrapper>
              <SelectModule
                name="activeYn"
                placeholder="활성화 여부를 선택해주세요."
                value={activeYn}
                label="활성화 여부"
                onChange={handleSetData}
                options={['Y', 'N']}
              />
            </InputWrapper>
            <InputWrapper>
              <NormalButton text="저장하기" onSubmit={changeActive} />
            </InputWrapper>
          </InputContainer>
        </Modal>
      )}
      <MainContainer>
        <Table
          colList={adminColList}
          thead={
            <>
              <ListHead title="이름" />
              <ListHead title="이메일" />
              <ListHead title="휴대폰 번호" />
              <ListHead title="지역" />
              {/*<ListSelect*/}
              {/*  title="지역"*/}
              {/*  list={eventCountryTypeList}*/}
              {/*  onClick={handleSelect}*/}
              {/*  setState={setCountry}*/}
              {/*/>*/}
              <ListSelect
                title="권한"
                list={accountRoleOptionList}
                onClick={handleSelect}
                setState={setFilterRole}
              />
              <ListSelect
                title="활성화 여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setFilterPrivateYn}
              />
              <ListHead title="" />
            </>
          }
        >
          {accountList?.map((el: any) => (
            <AccountRowComponent key={el.id} data={el} onClick={editAccount} />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default AdminAccountManagement;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputContainer = styled.div`
  min-width: 483px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
  margin: 0 94px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;

  &:nth-child(3) {
    margin: 30px auto 20px;
  }
`;
