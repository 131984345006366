import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import Pagination from '../../../components/common/Pagination';

// config

// constant
import {IsActive, postsPerPage} from '../../../constant/common';

const SuggestWordRandom = () => {
  const navigate = useNavigate();

  const getNumber = localStorage.getItem('pageNumber');
  const [contentsList, setContentsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);


  const getContents = async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    await axios.get(`/setting/suggest/word_random`, {
      params,
    })
    .then((resp)=>{
      setTotalCount(resp.data.count);
      setContentsList(resp.data.suggestWordRandoms);
    })
    .catch(error => {
      console.log(error.response.data);
    });
  };

  useEffect(() => {
    getContents().then();
  }, [pageNumber]);


  // 상세로 이동
  const goToDetail = (state) => {
    navigate('/operation/suggestWordRandom/edit', {
      state: {
        prevId: state?.id,
        nextSequence: totalCount + 1,
      },
    });
  };

  return (
    <Container>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton
            text="추가"
            onSubmit={() => {
              navigate('/operation/suggestWordRandom/add', {
                state: {
                  nextSequence: totalCount + 1,
                  prevId: null,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[15, 100, 30]}
          thead={
            <>
              <ListHead title="번호" />
              <ListHead title="이벤트 랜덤 제시어 이름" />
              <ListHead title="사용 여부" />
            </>
          }
        >
          {contentsList?.map((el) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td><ListItem text={el.id} /></td>
              <td><ListItem text={el.name} /></td>
              <td><ListItem text={IsActive[el.activeYn]} /></td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default SuggestWordRandom;

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  // justify-content: space-between;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
