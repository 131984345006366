import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../../components/common/List/Table';
import ListHead from '../../../../components/UI/ListHead';
import ListItem from '../../../../components/common/List/ListItem';
import NormalButton from '../../../../components/common/Button/NormalButton';
import RoundButton from '../../../../components/common/Button/RoundButton';
import Pagination from '../../../../components/common/Pagination';

// config
import {COMMUNITY_BOARD_BANNER} from '../../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../../constant/common';

const CommunityBoardBannerRow = ({data}) => {
  const navigate = useNavigate();
  return (
    <tr key={data.id} onClick={() =>
        navigate('/operation/communityBoardBanner/edit', {state: {prevId: data.id}})
      }
    >
      <td><ListItem text={data.sequence} /></td>
      <td><ListItem text={data.id} /></td>
      <td><ListItem text={data.title} /></td>
      <td><ListItem text={data.description} /></td>
      <td><ListItem text={data.startDt} /></td>
      <td><ListItem text={data.endDt} /></td>
  </tr>
);
};

const CommunityBoardBannerList = () => {
  const navigate = useNavigate();

  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState([]);

  useEffect(() => {
    getCommunityBoardBannerInfos()
  }, [activationType]);

  // 배너 목록 정보 조회
  const getCommunityBoardBannerInfos = useCallback(async () =>
    {
      const params = {
        limit: postsPerPage,
        offset: pageNumber * postsPerPage,
      };
      await axios.get(
      `${COMMUNITY_BOARD_BANNER}/${activationType}`,
      { params },
      )
      .then( resp => {
        setTotalCount(resp.data.count);
        setContentsList(resp.data.bannerList);
      })
      .catch(reason => console.error(reason));
    }, [activationType, pageNumber]
  );

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
              title="활성" selected={activationType === 'active'}
              onClick={() => setActivationType('active')}
          />
          <RoundButton
              title="비활성" selected={activationType === 'inactive'}
              onClick={() => setActivationType('inactive')}
          />
        </div>
        <ButtonWrapper>
          {contentsList && (
            <>
              {activationType === 'active' && (
                <NormalButton
                    text="노출 순서 변경" className="cancelBtn"
                    onSubmit={() => navigate('editSeq', {})}
                />
              )}
            </>
          )}
          <NormalButton
            text="배너 등록"
            onSubmit={() => {
                navigate('/operation/communityBoardBanner/add', {
                    state: {
                        nextSequence: totalCount + 1,
                        prevId: null,
                    },
                });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={e => setPageNumber(e - 1)}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={[50, 50, 80, 100, 80, 80]}
          thead={
            <>
              <ListHead title="표시 순서" />
              <ListHead title="배너 아이디" />
              <ListHead title="배너 명" />
              <ListHead title="배너 설명" />
              <ListHead title="노출 개시 일시" />
              <ListHead title="노출 종료 일시" />
            </>
          }
        >
          {contentsList?.map((el) =>
              <CommunityBoardBannerRow data ={el} />
          )}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default CommunityBoardBannerList;

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
