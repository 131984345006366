import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination';

// config
import { COMMUNITY_CURATED_ARTICLE } from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';

const CuratedMagazine = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [activationType, setActivationType] = useState('active');
  const [contentsList, setContentsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  const getCuratedContentsList = useCallback(async () => {
    const params = {
      contentsType: 'MAGAZINE',
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
    };
    try {
      const resp = await axios.get(
        `${COMMUNITY_CURATED_ARTICLE}/${activationType}`,
        { params },
      );
      setTotalCount(resp.data.count);
      setContentsList(resp.data.communityBoardArticleList);
    } catch (err) {
      console.log(err);
    }
  }, [activationType, pageNumber]);

  useEffect(() => {
    getCuratedContentsList().then(() => {});
  }, [getCuratedContentsList, activationType]);

  // 상세로 이동
  const goToDetail = (state) => {
    navigate(
        '/culture/curatedMagazine/edit',
        {state: {prevId: state?.id,},}
    );
  };

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton title="활성" selected={activationType === 'active'} onClick={() => setActivationType('active')}/>
          <RoundButton title="비활성" selected={activationType === 'inactive'} onClick={() => setActivationType('inactive')}/>
        </div>
        <ButtonWrapper>
          {/*{contentsList && (*/}
          {/*  <>*/}
          {/*    {activationType === 'active' && (*/}
          {/*      <NormalButton*/}
          {/*        text="노출 순서 변경"*/}
          {/*        className="cancelBtn"*/}
          {/*        onSubmit={() => navigate('editSeq', {})}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </>*/}
          {/*)}*/}
          <NormalButton
            text="추천 매거진 등록"
            onSubmit={() => {
              navigate('/culture/curatedMagazine/add', {
                state: {
                  nextSequence: totalCount + 1,
                  prevId: null,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="표시 순서" />
              <ListHead title="컨텐츠 아이디" />
              <ListHead title="컨텐츠 명" />
              <ListHead title="컨텐츠 설명" />
              <ListHead title="노출 개시 일시" />
              <ListHead title="노출 종료 일시" />
            </>
          }
        >
          {contentsList?.map((el) => (
            <tr key={el.id} onClick={() => goToDetail(el)}>
              <td><ListItem text={el.sequence} /></td>
              <td><ListItem text={el.id} /></td>
              <td><ListItem text={el.name} /></td>
              <td><ListItem text={el.description} /></td>
              <td><ListItem text={el.startDt} /></td>
              <td><ListItem text={el.endDt} /></td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};
export default CuratedMagazine;

export const colList = [50, 50, 80, 100, 80, 80];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
