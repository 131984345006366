import { createSlice } from '@reduxjs/toolkit';
import { RecentlyBeatType } from '../../definitions/explore';

type initialType = {
  recentlyBeat: RecentlyBeatType;
};

const initialState: initialType = {
  recentlyBeat: {
    id: 0,
    coverImgUrl: '',
    title: '',
    titleKo: '',
    titleEn: '',
    titleEs: '',
    description: '',
    descriptionKo: '',
    descriptionEn: '',
    descriptionEs: '',
    masterGenre: '',
    masterGenreId: 0,
    updateDt: '',
    createdDt: '',
  },
};

const exploreSlice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    getRecentlyBeatDetail: (state, action) => {
      state.recentlyBeat = action.payload;
    },
    resetRecentlyBeatDetail: (state) => {
      state.recentlyBeat = initialState.recentlyBeat;
    },
  },
});

export default exploreSlice;
export const { getRecentlyBeatDetail, resetRecentlyBeatDetail } =
  exploreSlice.actions;
