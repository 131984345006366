import React from 'react';

import { useNavigate } from 'react-router-dom';

// common
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';

// constant
import {
  IsActive,
  GetLanguageOption,
  GetCountryOption,
} from '../../../constant/common';

// hook
import handleDate from '../../../hooks/handleDate';

// 타입
import { EventRowComponentDataProps } from '../../../definitions/event';
import EditButton from '../../../components/common/Button/EditButton';

// 인터페이스
interface dataProps {
  data: EventRowComponentDataProps;
  type?: string;
  onClick?: any;
  selected?: any;
}

const PushForEventRowComponent = ({
  data,
  type,
  onClick,
  selected,
}: dataProps) => {
  const {
    bannerImgUrl,
    activeYn,
    title,
    countryCode,
    startDt,
    endDt,
    language,
    projectName,
  } = data;

  return (
    <tr>
      <td>
        <ListCoverImg url={bannerImgUrl} className="event" />
      </td>
      <td>
        <ListItem text={projectName || '-'} />
      </td>
      <td>
        <ListItem type="title" text={title} />
      </td>
      <td>
        <ListItem text={IsActive[activeYn]} />
      </td>
      <td>
        <ListItem text={handleDate(startDt)} />
      </td>
      <td>
        <ListItem text={handleDate(endDt)} />
      </td>
      <td>
        <ListItem text={GetCountryOption[countryCode]} />
      </td>
      <td>
        <ListItem text={GetLanguageOption[language]} />
      </td>
      {type !== 'preview' && (
        <td>
          {selected ? (
            <EditButton
              onClick={() => onClick(data)}
              text="삭제"
              className="deleteBtn"
            />
          ) : (
            <EditButton
              onClick={() => onClick(data)}
              text="선택"
              className="selectBtn"
            />
          )}
        </td>
      )}
    </tr>
  );
};

export default PushForEventRowComponent;
