import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// common
import Table from '../../../components/common/List/Table';
import ListHead from '../../../components/UI/ListHead';
import ListItem from '../../../components/common/List/ListItem';
import NormalButton from '../../../components/common/Button/NormalButton';
import RoundButton from '../../../components/common/Button/RoundButton';
import Pagination from '../../../components/common/Pagination/index';

// config
import { COMMUNITY_CATEGORY, } from '../../../constant/apiUrl';

// constant
import { postsPerPage } from '../../../constant/common';

const CommunityCategory = () => {
  const navigate = useNavigate();
  const getNumber = localStorage.getItem('pageNumber');
  const [privateYn, setPrivateYn] = useState('N');
  const [communityCategoryList, setCommunityCategoryList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(
    getNumber ? JSON.parse(getNumber) : 0,
  );

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  // 상세로 이동
  const goToDetail = (state: any) => {
    navigate('/explore/communityCategory/edit', {
      state: {
        prevId: state?.id,
        prevTotalCount: totalCount,
        communityCategoryList,
      },
    });
  };

  const getCommunityCategoryList = useCallback(async () => {
    const params = {
      limit: postsPerPage,
      offset: pageNumber * postsPerPage,
      privateYn,
    };
    try {
      const resp = await axios.get(COMMUNITY_CATEGORY, { params });
      setTotalCount(resp.data.count);
      setCommunityCategoryList(resp.data.categoryList);
    } catch (err) {
      console.log(err);
    }
  }, [privateYn, pageNumber]);

  useEffect(() => {
    getCommunityCategoryList();
  }, [getCommunityCategoryList, privateYn]);

  return (
    <Container>
      <TopContainer>
        <div>
          <RoundButton
            title="공개"
            selected={privateYn === 'N'}
            onClick={() => setPrivateYn('N')}
          />
          <RoundButton
            title="비공개"
            selected={privateYn === 'Y'}
            onClick={() => setPrivateYn('Y')}
          />
        </div>
        <ButtonWrapper>
          {communityCategoryList && (
            <>
              {privateYn === 'N' && (
                <NormalButton
                  text="노출 순서 변경"
                  className="cancelBtn"
                  onSubmit={() => {
                    navigate('editSeq', {});
                  }}
                />
              )}
            </>
          )}
          <NormalButton
            text="카테고리 등록하기"
            onSubmit={() => {
              navigate('/explore/communityCategory/add', {
                state: {
                  prevId: null,
                  prevTotalCount: totalCount,
                  communityCategoryList,
                },
              });
            }}
          />
        </ButtonWrapper>
      </TopContainer>
      <PaginationWrapper>
        {totalCount !== 0 && (
          <Pagination
            postsPerPage={postsPerPage}
            totalCount={totalCount}
            currentPage={pageNumber}
            pageDispatch={pageDispatch}
          />
        )}
      </PaginationWrapper>
      <MainContainer>
        <Table
          colList={colList}
          thead={
            <>
              <ListHead title="표시 순서" />
              <ListHead title="카테고리 아이디" />
              <ListHead title="카테고리 명" />
              <ListHead title="카테고리 설명" />
            </>
          }
        >
          {communityCategoryList?.map((el: any) => (
            <tr onClick={() => goToDetail(el)} key={el.id}>
              <td>
                <ListItem text={el.sequence} />
              </td>
              <td>
                <ListItem text={el.id} />
              </td>
              <td>
                <ListItem text={el.name} />
              </td>
              <td>
                <ListItem text={el.description} />
              </td>
            </tr>
          ))}
        </Table>
      </MainContainer>
    </Container>
  );
};

export default CommunityCategory;

export const colList = [60, 60, 120, 190];

const Container = styled.div`
  width: 100%;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;
