import React, { useRef, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import ListItem from '../../../components/common/List/ListItem';

interface props {
  data: dataTypes;
}

interface dataTypes {
  id: number;
  description: string;
  activeYn: string;
  reportPoint: string;
  masterReportDetailList?: any;
}

const ManagementRowComponent = ({ data }: props) => {
  const { id, description, masterReportDetailList, activeYn, reportPoint } =
    data;

  return (
    <>
      {masterReportDetailList ? (
        <>
          {masterReportDetailList.map((el: any) => (
            <tr key={el.id}>
              <td>
                <ListItem text={description} />
              </td>
              <td>
                <ListItem text={el.subDescription} />
              </td>
              <td>
                <ListItem text={el.reportPoint} />
              </td>
              <td>
                <ListItem text={el.activeYn} />
              </td>
            </tr>
          ))}
        </>
      ) : (
        <tr>
          <td>
            <ListItem text={description} />
          </td>
          <td>
            <ListItem text="" />
          </td>
          <td>
            <ListItem text={reportPoint} />
          </td>
          <td>
            <ListItem text={activeYn} />
          </td>
        </tr>
      )}
    </>
  );
};

export default ManagementRowComponent;
