import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {BAUND_PROJECT_CAUTION} from "../../constant/apiUrl";
import NormalButton from "../../components/common/Button/NormalButton";
import {useLocation} from "react-router";
import BaundProjectCautionEditSeqRow
  from "./component/BaundProjectCautionEditSeqRow";
import ListHeadDiv from "../../components/UI/ListHeadDiv";

const BaundProjectCautionEditSeq = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [contentsList, setContentsList] = useState([]);

  const detailData = location.state;
  const baundProjectIdForSearch = detailData !== null ? detailData.baundProjectIdForSearch : null;
  const pageNumberForSearch = detailData !== null ? detailData.pageNumberForSearch : null;
  const projectTitleForSearch = detailData !== null ? detailData.projectTitle : '프로젝트 선택';

  // 정렬을 위한 정보 조회
  const getContentsForEditSeq = async () => {
    const params = {
      limit: 100,
      offset: 0,
      baundProjectId: baundProjectIdForSearch,
    };
    await axios.get(`${BAUND_PROJECT_CAUTION}`, {params})
    .then(resp => setContentsList(resp.data.baundProjectCautions))
    .catch(err => console.log(err))
  };
  useEffect(() => {
    getContentsForEditSeq().then(r => r);

    // 목록으로 돌아갔을때 동일 조건 표시를 위해 전달 받은 검색 조건을 localStorage 에 저장
    window.localStorage.setItem(
        'storageSearchCondition',
        JSON.stringify({ baundProjectIdForSearch, pageNumberForSearch, projectTitleForSearch })
    );
  }, []);

  // 순서 변경
  const changeSequence = async () => {
    const ids = contentsList.map((el) => el.baundProjectCautionId);
    await axios.put(
        `${BAUND_PROJECT_CAUTION}/sequence`,
        {ids, baundProjectId: baundProjectIdForSearch}
    )
    .then( () => {
      // console.log(resp);
      alert('저장되었습니다.');
      navigate(-1);
    })
    .catch ( reason => {
      console.log(reason);
      alert('잠시후 다시 이용해주세요.');
    })
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
      (dragIndex, hoverIndex) => {
        const dragItem = contentsList[dragIndex];
        const hoverItem = contentsList[hoverIndex];

        setContentsList((tagList) => {
          const updatedPets = [...tagList];
          updatedPets[dragIndex] = hoverItem;
          updatedPets[hoverIndex] = dragItem;
          return updatedPets;
        });
      },
      [contentsList],
  );

  return (
      <>
        <TitleText>노출 순서 변경</TitleText>
        <TopContainer>
          <TopWrapper>
            <ButtonWrapper>
              <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
              <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
            </ButtonWrapper>
          </TopWrapper>

          <PaginationWrapper>
            <SpaceDiv />
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          <TagHead>
            <ListHeadDiv title="" />
            <ListHeadDiv title="표시순서" />
            <ListHeadDiv title="번호" />
            <ListHeadDiv title="유의사항"/>
            <ListHeadDiv title="유의사항 작성일자"/>
          </TagHead>
          {contentsList?.map((item, index) => (
              <BaundProjectCautionEditSeqRow
                  key ={item.baundProjectCautionId}
                  data={item}
                  moveListItem={moveItem}
                  index={index}
              />
          ))}
        </MainContainer>
      </>
  );
};

export default BaundProjectCautionEditSeq;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr 2fr 10fr 3fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'flex-end'};
  margin-bottom: 10px;
`;
