import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { SIGNIN, SIGNIN_LOAD, SIGNOUT } from '../../../constant/apiUrl';
import { User } from '../../slices/user';

// 회원가입
export const signup = createAsyncThunk(
  'user/signup',
  async (data: User, { rejectWithValue }) => {
    try {
      const response = await axios.post('/signup', data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.response.data);
    }
  },
);

// 로그인
export const logIn = createAsyncThunk(
  SIGNIN,
  async (data: object, { rejectWithValue }) => {
    try {
      const response = await axios.post(SIGNIN, data, {});
      return response.data.adminAccount;
    } catch (error: any) {
      console.log(rejectWithValue(error.response.data));
      return rejectWithValue(error.response.data);
    }
  },
);

// 로그아웃
export const logOut = createAsyncThunk('/user/logOut', async () => {
  const response = await axios.post(SIGNOUT);
  return response.data;
});

// 로그인 상태 불러오기
export const loadUser = createAsyncThunk('/user/load', async () => {
  // get의 두번째 인자에는 data가 아닌, withCredentials 자리지만 defaults로 넣었기때문에 생략
  const response = await axios.get(SIGNIN_LOAD, { withCredentials: true });
  return response.data;
});
