import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {BAUND_PROJECT_FAQ} from "../../constant/apiUrl";
import PaginationWithTotalCount
  from "../../components/common/Pagination/PaginationWithTotalCount";
import {postsPerPage} from "../../constant/common";
import Table from "../../components/common/List/Table";
import ListHead from "../../components/UI/ListHead";
import ListItem from "../../components/common/List/ListItem";
import styled from "styled-components";
import axios from "axios";
import RoundButton from "../../components/common/Button/RoundButton";
import NormalButton from "../../components/common/Button/NormalButton";

// 바운드 프로젝트 FAQ 목록
const BaundProjectFaqs = () => {
  const navigate = useNavigate();

  //표시 데이터
  const [baundProjectFaqList, setBaundProjectFaqList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  // 저장된 검색 조건
  const storageSearchCondition = JSON.parse(localStorage.getItem('storageSearchCondition'));

  // 필터 //////////////////////////////////////////////////////////////////////////////////////////
  const[privateYn, setPrivateYn] = useState(storageSearchCondition?.privateYnForSearch ||'N');
  const [pageNumber, setPageNumber] = useState(storageSearchCondition?.pageNumberForSearch || 0);

  //목록 조회 //////////////////////////////////////////////////////////////////
  const params = {
    offset: pageNumber * postsPerPage,
    limit: postsPerPage,
    privateYn
  };
  const getBaundProjectFaqs = async() => {
    await axios.get(`${BAUND_PROJECT_FAQ}`, {params})
    .then(res => {
      setBaundProjectFaqList(
        res.data.baundProjectFaqs.map((el)=>{
          return {
            baundProjectFaqId: el.baundProjectFaqId,
            question: el.question,
            answer: el.answer,
            createDt: el.createDt,
            privateYn: el.privateYn,
            selected: false
          }
        })
    );
      setTotalCount(res.data.totalCount);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }

  useEffect(() => {
    getBaundProjectFaqs().then();

    // 검색 조건을 localStorage 에서 클리어
    window.localStorage.removeItem('storageSearchCondition');
  }, []);

  // 검색 조건 변경시 호출
  useEffect(() => {
    getBaundProjectFaqs().then();
  }, [pageNumber, privateYn]);

  // 상세 페이지로 이동
  const moveToDetail = (_type, baundProjectFaqId) => {
    navigate(
        `/baundProject/baundProjectFaq/${_type}`,
        {
          state: {
            // 수정 일때만 정보 전달
            baundProjectFaqId: (_type === 'edit') ? baundProjectFaqId: null,
            pageNumberForSearch: pageNumber,
            privateYnForSearch: privateYn
          }
          // _state
        }
    );
  }

  const handlePrivateYn = (_privateYn) => {
    setCheckAll(false);
    setPrivateYn(_privateYn);
    setPageNumber(0);
  }

  // 노출/미노출 제어 //////////////////////////////////////////////////////////////
  // 전체 선택 여부
  const [checkAll, setCheckAll] = useState(false);
  // 대상 아이디 목록
  const [faqIds, setFaqIds] = useState([]);

  const toggleSelected = async () => {
    if(faqIds.length === 0) {return;}
    if (!confirm( (privateYn==='Y')
        ?'선택한 FAQ를 공개 하시겠습니까?.' :'선택한 FAQ를 비공개 하시겠습니까?.'
    )) {
      return;
    }

    const formData = new FormData();
    formData.append('ids', faqIds);
    formData.append('privateYn', (privateYn==='Y') ?'N' :'Y');

    await axios.put(`${BAUND_PROJECT_FAQ}`,formData)
    .then(() => {
      getBaundProjectFaqs();
      setCheckAll(false);
      setFaqIds([]);
      alert('처리 완료');
    })
    .catch (err => {
      console.error(err)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(err.response.data.messages));
    });
  };

  // 전체 선택
  const setSelectedAll = (checked) => {
    baundProjectFaqList.map((item) => {
      item.selected = checked;
    });

    const arr = [];
    //선택한 것이면
    if (checked) {
      // 전체 추가
      baundProjectFaqList.forEach((el) => arr.push(el.baundProjectFaqId));
    }
    setFaqIds(arr);
    setCheckAll(checked);
  }

  // 개별 선택
  const setSelected = (_id, checked) => {
    // console.log('setSelected > _id:',_id, '/checked:', checked);
    // 체크된 데이터가 재 랜더링 될 때 체크 박스 체크 상태를 유지하기 위해 값 보존
    baundProjectFaqList.map((item) => {
      if (item.baundProjectFaqId === _id) {
        item.selected = checked;
      }
    });

    setFaqIds(
        //선택한 것이면
        faqIds
            // 아이디 목록에 추가
            ? [...faqIds, _id]
            // 아이디 목록에서 제외
            : faqIds.filter((item)=> item !== _id)
    );
    setCheckAll(false);
  }

  return (
      <>
        <TitleText>FAQ 목록</TitleText>
        <TopContainer>
          <TopWrapper>
            <ButtonWrapper>
              <RoundButton title="공개"
               selected={privateYn === 'N'} onClick={() => handlePrivateYn('N')}/>
              <RoundButton title="비공개"
               selected={privateYn === 'Y'} onClick={() => handlePrivateYn('Y')}/>
            </ButtonWrapper>
            <ButtonWrapper className={'withButtonSpace'}>
              { (privateYn==='Y')
                ? (<NormalButton text="선택 공개" onSubmit={toggleSelected}
                    className={(faqIds.length === 0) ? "unActiveBtn": "blockBtn"}/>
                )
                : (<>
                    <NormalButton text="순서 변경"
                      onSubmit={() => navigate('/baundProject/baundProjectFaq/editSeq')}/>
                    <NormalButton text="선택 비공개"
                        className={(faqIds.length === 0) ? "unActiveBtn": "blockBtn"}
                        onSubmit={toggleSelected}
                    />
                  </>)
              }
              <NormalButton text="새로 만들기" onSubmit={() => moveToDetail('add')}/>
            </ButtonWrapper>
          </TopWrapper>

          <PaginationWrapper>
            {totalCount !== 0 && (
                <PaginationWrapper>
                  <PaginationWithTotalCount
                      postsPerPage={postsPerPage}
                      totalCount={totalCount}
                      totalCountPostfix={'개 FAQ'}
                      currentPage={pageNumber}
                      pageDispatch={(e)=>setPageNumber(e-1)}
                  />
                </PaginationWrapper>
            )}
          </PaginationWrapper>
        </TopContainer>

        <MainContainer>
          {baundProjectFaqList.length !== 0 ? (
              <Table
                  colList={[10, 20, 100, 100, 40, 30]}
                  thead={<>
                    <TableHead>
                      <input id={`checkbox_0`} type="checkbox"
                             checked={checkAll}
                             onChange={(e) =>
                                 setSelectedAll(e.currentTarget.checked)
                            }
                      />
                    </TableHead>
                    <ListHead title="번호" />
                    <ListHead title="질문"/>
                    <ListHead title="답변"/>
                    <ListHead title="작성일자"/>
                    <ListHead title="공개 여부"/>
                  </>}
              >
                {
                  baundProjectFaqList?.map(faq =>
                      <tr key={faq.baundProjectFaqId}>
                        <td>
                          <input id={`checkbox_${faq.baundProjectFaqId}`}
                                 type="checkbox"
                                 checked={faq.selected}
                                 onChange={(ev) =>
                                     setSelected(faq.baundProjectFaqId, ev.currentTarget.checked)
                                 }
                          />
                        </td>
                        <td onClick={() => moveToDetail('edit', faq.baundProjectFaqId)}>
                          <ListItem text={faq.baundProjectFaqId}/>
                        </td>
                        <td onClick={() => moveToDetail('edit', faq.baundProjectFaqId)}>
                          <ListItem text={faq.question}/>
                        </td>
                        <td onClick={() => moveToDetail('edit', faq.baundProjectFaqId)}>
                          <ListItem text={faq.answer}/>
                        </td>
                        <td onClick={() => moveToDetail('edit', faq.baundProjectFaqId)}>
                          <ListItem text={faq.createDt}/>
                        </td>
                        <td onClick={() => moveToDetail('edit', faq.baundProjectFaqId)}>
                          <ListItem text={(faq.privateYn === 'Y') ? '비공개' : '공개'}/>
                        </td>
                      </tr>
                  )
                }
              </Table>
          ) : (
              <VideoModalSubText>검색 결과가 없습니다.</VideoModalSubText>
          )}
        </MainContainer>
      </>
  );
};
export default BaundProjectFaqs;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
  margin-bottom: 20px;
`;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const PaginationWrapper = styled.div`
  margin-top: 50px;
`;

const MainContainer = styled.div`
  width: 100%; 		
  margin-top: 30px;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc ? `${jc}` : 'space-between'};
  margin-bottom: 10px;
`;

const ButtonWrapper =  styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &.withButtonSpace > button {
    margin-left: 20px;
  }
  button {
    cursor: pointer;
  }
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const TableHead = styled.th`
  padding: 10px 0 18px;
  background-color: white;
`;

