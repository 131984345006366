import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import NormalButton from '../../components/common/Button/NormalButton';
import RoundButton from '../../components/common/Button/RoundButton';
import { FILTER_TYPE } from '../../constant/apiUrl';
import FilterCategoryList from './components/FilterCategoryList';

const FilterCategory = () => {
  const navigate = useNavigate();
  const [activeYn, setActiveYn] = useState('Y');
  const [filterCategoryList, setFilterCategoryList] = useState<any>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [viewType, setViewType] = useState('normal');
  // 상세로 이동
  const goToDetail = (prevPageProps: any) => {
    navigate('/filter/category/edit', {
      state: { prevPageProps, prevTotalCount: totalCount },
    });
  };

  console.log(filterCategoryList);

  const getFilterCategoryList = useCallback(async () => {
    const params = {
      limit: 100,
      offset: 0,
      activeYn,
    };
    try {
      const resp = await axios.get(FILTER_TYPE, { params });
      setTotalCount(resp.data.count);
      setFilterCategoryList(resp.data.adminStudioCameraFilterTypeList);
    } catch (err) {
      console.log(err);
    }
  }, [activeYn]);

  const changeSequence = async () => {
    const ids = filterCategoryList.map((el: any) => el.id);
    try {
      const resp = await axios.post(`${FILTER_TYPE}/sequence`, { ids });
      console.log(resp);
      alert('저장되었습니다.');
      setViewType('normal');
      getFilterCategoryList();
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 이용해주세요.');
    }
  };

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = filterCategoryList[dragIndex];
      const hoverItem = filterCategoryList[hoverIndex];

      setFilterCategoryList((tagList: any) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [filterCategoryList],
  );

  useEffect(() => {
    getFilterCategoryList();
  }, [getFilterCategoryList, activeYn]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  console.log(filterCategoryList);

  // useEffect(() => {
  //   console.log('동작하니');
  //   if (activeYn === 'Y') {
  //     filterCategoryList.filter((el: any) => el?.activeYn === 'Y');
  //     console.log('Y일때');
  //   }
  //   if (activeYn === 'N') {
  //     const result = filterCategoryList.filter(
  //       (el: any) => el?.activeYn !== 'Y',
  //     );
  //     if (result.length === 0) {
  //       setFilterCategoryList([]);
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [activeYn]);

  return (
    <MainContainer>
      <NavigateBtnContainer>
        {viewType === 'sequence' ? (
          <>
            <NormalButton
              text="취소"
              className="cancelBtn"
              onSubmit={() => {
                setViewType('normal');
                getFilterCategoryList();
              }}
            />
            <NormalButton
              text="저장하기"
              onSubmit={() => {
                changeSequence();
              }}
            />
          </>
        ) : (
          <>
            <NormalButton
              text="노출 순서 변경"
              className="cancelBtn"
              onSubmit={() => {
                setViewType('sequence');
              }}
            />
            <NormalButton
              text="카테고리 등록하기"
              onSubmit={() => {
                navigate('/filter/category/add', {
                  state: { prevTotalCount: totalCount },
                });
              }}
            />
          </>
        )}
      </NavigateBtnContainer>
      {viewType === 'normal' && (
        <ButtonWrapper>
          <RoundButton
            title="공개"
            selected={activeYn === 'Y'}
            onClick={() => {
              setActiveYn('Y');
            }}
          />
          <RoundButton
            title="비공개"
            selected={activeYn === 'N'}
            onClick={() => {
              setActiveYn('N');
            }}
          />
        </ButtonWrapper>
      )}
      <Ul>
        {viewType === 'sequence'
          ? filterCategoryList?.map((item: any, index: number) => {
              return (
                <FilterCategoryList
                  key={item.id}
                  data={item}
                  moveListItem={movePetListItem}
                  index={index}
                />
              );
            })
          : filterCategoryList?.map((el: any) => (
              <Li
                key={el.id}
                onClick={() => {
                  goToDetail(el);
                }}
              >
                {el.name}
              </Li>
            ))}
      </Ul>
    </MainContainer>
  );
};

export default FilterCategory;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const NavigateBtnContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 30px;
  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
`;

const Ul = styled.ul`
  width: 100%;
`;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 59px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;
