import React from 'react';
import styled from 'styled-components';

const ListHeadDiv = ({ title }) => {
  return (
    <DivHead>
      <OptionMenu title={title}>
        {title}
      </OptionMenu>
    </DivHead>
  );
};
export default ListHeadDiv;

const DivHead = styled.div`
  padding: 10px 0 18px;
  background-color: white;
  text-align: center;
`;

const OptionMenu = styled.span`
  display: inline-block;
  position: relative;
  padding: 10px 14px;
  border-radius: 40px;
  font-size: 13px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subDarkGray};
  background-color: ${({ title, theme }) =>
    title === '' ? theme.color.mainWhite : theme.color.subBrightGray};
  z-index: 1;
`;
