export const convertLanguageType = (languageType: string) => {
  if (languageType === undefined || languageType === null)
    return '언어 타입 없음';
  switch (languageType) {
    case 'ko':
      return '한국어';
    case 'en':
      return '영어';
    default:
      return '그 외 언어(확인 필요)';
  }
};