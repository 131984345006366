import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styled from 'styled-components';
import dragAndDrop from '../../../assets/images/page/dragAndDrop.png';
import ListItem from '../../../components/common/List/ListItem';
import ListCoverImg from '../../../components/common/List/ListCoverImg';
import smallPlayIcon from '../../../assets/images/page/smallPlayIcon.png';
import smallPauseIcon from '../../../assets/images/page/smallPauseIcon.png';
import { IsPrivate } from '../../../constant/common';
import handleDate from '../../../hooks/handleDate';

interface PlayIconProps {
  url?: string;
}

const CultureEpisodeBeatRow = ({
  rowKey,
  playBeat,
  setPlayBeat,
  data,
  moveListItem,
  index,
}: any) => {
  const componentRef = useRef<any>(null);
  const {
    id,
    coverImgUrl,
    contentsUrl,
    title,
    bmPid,
    genreNames,
    bpm,
    key,
    privateYn,
    startDt,
    beatMakerPid,
  } = data;
  // useDrag - the list item is draggable
  const [, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element: any, monitor: any) => {
      // const dragIndex = item.index;
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const audioPlayer = useRef<any>(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const handleAudio = (e: any) => {
    e.stopPropagation();
  };

  const togglePlayPause = (e: any) => {
    e.stopPropagation();
    const prevValue = isPlaying;
    setPlayBeat(id);
    setIsPlaying(!prevValue);
    if (prevValue) {
      audioPlayer.current?.play();
    } else {
      audioPlayer.current?.pause();
    }
  };

  useEffect(() => {
    if (playBeat !== id) {
      setIsPlaying(true);
      audioPlayer.current?.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playBeat]);

  const applyRef = useCallback(
    (node: any) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );
  return (
    <Row ref={applyRef} key={rowKey}>
      <img src={dragAndDrop} alt="dnd icon" />
      <ListItem text={id} />
      <div>
        <ListCoverImg url={coverImgUrl} onClick={(e) => handleAudio(e)} />
        <AudioPlay>
          <Audio controls src={contentsUrl} ref={audioPlayer} />
          {/*<track default kind="captions" />*/}
          <PlayButton onClick={(e) => togglePlayPause(e)}>
            {isPlaying ? (
              <PlayIcon url={smallPlayIcon} className="play" />
            ) : (
              <PlayIcon url={smallPauseIcon} className="pause" />
            )}
          </PlayButton>
        </AudioPlay>
      </div>
      <ListItem type="title" text={title} />
      <ListItem text={bmPid || beatMakerPid} />
      <ListItem text={genreNames?.join(', ')} />
      <ListItem text={bpm} />
      <ListItem text={key} />
      <ListItem text={IsPrivate[privateYn]} />
      <ListItem text={handleDate(startDt)} />
    </Row>
  );
};

export default CultureEpisodeBeatRow;

const Row = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 0.8fr 0.5fr 1fr 1fr 1fr 0.6fr 0.6fr 0.6fr 1fr;
  padding: 27px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  color: ${({ theme }) => theme.color.mainBlack};
  cursor: pointer;
  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};
  }
`;

const AudioPlay = styled.div`
  display: block;
  margin: -45px 0 0 0;
`;

const Audio = styled.audio`
  width: 0;
  height: 0;
  overflow: hidden;

  audio::-webkit-media-controls-play-button {
    -webkit-appearance: none;
  }
  audio::-internal-media-controls-download-button {
    display: none;
  }
  audio::-webkit-media-controls-enclosure {
    display: none;
  }
  audio::-webkit-media-controls-mute-button {
    -webkit-appearance: none;
  }
  audio::-webkit-media-controls-panel {
    background-color: transparent;
  }
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    color: #1e140d;
  }
`;

const PlayButton = styled.button``;

const PlayIcon = styled.div<PlayIconProps>`
  width: 44px;
  height: 44px;
  &.play {
    &:hover {
      background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
      background-size: 12px;
      border-radius: 3px;
    }
  }

  &.pause {
    background: url(${({ url }) => url}) center no-repeat rgba(0, 0, 0, 0.6);
    background-size: 12px;
    border-radius: 3px;
  }
`;
