import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import invalidName from '../../assets/images/page/invalidName.png';
import FullButton from '../common/Button/FullButton';

type Props = {
  title?: string;
  link?: string;
  onSubmit: () => void;
};

const NotExposure: React.FC<Props> = ({ title, link, onSubmit }) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Img src={invalidName} alt="unHappy icon" />
      <Title>공개 중인 {title}가 없어요!</Title>
      <SubTitle>
        공개 중인 리스트가 없을 경우 둘러보기에서 보이지 않습니다.
      </SubTitle>
      <ButtonContainer>
        <FullButton
          text="등록하기"
          onSubmit={() => {
            navigate(`${link}`);
            onSubmit();
          }}
        />
      </ButtonContainer>
    </Container>
  );
};

export default NotExposure;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  //width: 592px;
  height: 368px;
  margin: 152px auto 0 auto;
`;

const Img = styled.img`
  width: 96px;
  margin-bottom: 80px;
  object-fit: contain;
`;

const Title = styled.div`
  height: 60px;
  margin-bottom: 28px;
  flex-grow: 0;
  font-size: 48px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000;
`;

const SubTitle = styled.div`
  width: 398px;
  height: 20px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.color.subDarkGray};
`;

const ButtonContainer = styled.div`
  width: 380px;
`;
