import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from 'axios';
import { useDrag, useDrop } from 'react-dnd';

// common
import NormalButton from '../../../../components/common/Button/NormalButton';
import SearchInput from '../../../../components/common/Input/SearchInput';
import checkedButton from '../../../../assets/images/page/checkedButton.png';
import closeRoundButton from '../../../../assets/images/page/closeRoundButton.png';

// asset
import dragAndDrop from '../../../../assets/images/page/dragAndDrop.png';

// hook
import useInput from '../../../../hooks/useInput';
import { useAppSelector } from '../../../../redux/store/hooks';

// 액션
import { setLoading, resetLoading } from '../../../../redux/slices/user';

// config
import {TAB_MOOD} from '../../../../constant/apiUrl';
import {getMoodList} from "../../../../redux/actions/mood/mood";

const ExposureMoodDetail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useAppSelector((state) => state.mood.masterMoodList);
  const adminName = useAppSelector((state) => state.user.user.name);
  const [moodList, setMoodList] = useState(data);
  const [selectedList, setSelectedList] = useState([]);
  const [word, setWord] = useInput('');

  const editSelectedList = useCallback((item) => {
    const isSelect = selectedList?.some((e) => e.id === item.id);
    if (isSelect) {
      setSelectedList(selectedList?.filter((el) => el.id !== item.id));
    }
    else {
      setSelectedList(selectedList.concat(item));
    }
  }, [selectedList],);

  const checkSelect = useCallback((item) => {
    const isSelect = selectedList?.some((e) => e.id === item.id);
    if (isSelect) {
      return 'checked';
    }
    return '';
  }, [selectedList],);

  // 기존 무드탭 리스트 불러오기
  const getMoodTabList = async () => {
    try {
      const resp = await axios.get(TAB_MOOD, {params: { limit: 20, offset: 0 },});
      const res = resp.data.masterRecommendTabMoodList;
      const customResponse = res.map((el) => {
        return { id: el.masterMoodId, moodName: el.masterMood };
      });
      const moodIdList = moodList?.map((el) => el.id);

      const result = customResponse.filter((e) =>
        moodIdList?.includes(e.id),
      );
      setSelectedList(result);
    } catch (err) {
      console.log(err);
    }
  };

  // 노출 장르 수정 요청
  const changeTabMood = useCallback(async () => {
    const body = selectedList.map((mood, genreIndex) => ({
      masterMoodId: mood.id,
      sequence: genreIndex + 1,
    }));
    if (selectedList.length === 0) {
      alert('한 개 이상의 장르를 선택해주세요.');
      return;
    }

    try {
      dispatch(setLoading());
      await axios.post(TAB_MOOD, body, { params: { adminName } });
      alert('저장되었습니다.');
      navigate('/explore/allTab/mood');
    } catch (err) {
      console.log(err);
    }
    dispatch(resetLoading());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminName, navigate, selectedList]);

  // 드래그 앤 드롭
  const movePetListItem = useCallback(
    (dragIndex, hoverIndex) => {
      const dragItem = selectedList[dragIndex];
      const hoverItem = selectedList[hoverIndex];

      // eslint-disable-next-line @typescript-eslint/no-shadow
      setSelectedList((tagList) => {
        const updatedPets = [...tagList];
        updatedPets[dragIndex] = hoverItem;
        updatedPets[hoverIndex] = dragItem;
        return updatedPets;
      });
    },
    [selectedList],
  );

  useEffect(() => {
    setMoodList(
      data?.filter((item) => item.moodName.toLowerCase().indexOf(word) !== -1),
    );
  }, [data, word]);

  useEffect(() => {
    const params = { limit: 1000, offset: 0 };
    dispatch(getMoodList(params));
    getMoodTabList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setSelectedList([]);
    };
  }, []);

  return (
    <Container>
      <SearchContainer>
        <h1>무드 검색</h1>
        <SearchInput value={word} onChange={setWord} />
        <Ul>
          {moodList?.map((item) => (
            <ListItem onClick={() => editSelectedList(item)} key={item.id}>
              <CheckBox className={checkSelect(item)} />
              <p>{item.moodName}</p>
            </ListItem>
          ))}
        </Ul>
      </SearchContainer>
      <SelectedContainer>
        <TitleContainer>
          <h1>노출 무드</h1>
          <NormalButton text="저장하기" onSubmit={changeTabMood} />
        </TitleContainer>
        <Ul>
          {selectedList?.map((item, index) => {
            return (
              <MoodList
                key={item.id}
                data={item}
                moveListItem={movePetListItem}
                index={index}
                onClick={editSelectedList}
              />
            );
          })}
        </Ul>
        <DisplayRight>
          {selectedList.length !== 0 && (
            <NormalButton className="cancelBtn" text="전체 취소" onSubmit={() => setSelectedList([])}/>
          )}
        </DisplayRight>
      </SelectedContainer>
    </Container>
  );
};
export default ExposureMoodDetail;

const MoodList = ({ data, moveListItem, index, onClick }) => {
  const componentRef = useRef(null);

  // useDrag - the list item is draggable
  const [, dragRef] = useDrag({
    type: 'element',
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: 'element',
    hover: (element, monitor) => {
      const hoverIndex = index;

      if (!componentRef.current) {
        return null;
      }

      const dragIndex = element.index;
      if (dragIndex === hoverIndex) {
        return null;
      }

      const hoverBoundingRect = componentRef?.current?.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return null;
      }

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (
        (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) ||
        (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
      ) {
        return null;
      }

      element.index = hoverIndex;
      return moveListItem(dragIndex, hoverIndex);
    },
  });

  const applyRef = useCallback(
    (node) => {
      componentRef.current = node;
      dragRef(dropRef(node));
    },
    [dragRef, dropRef],
  );
  return (
    <Li key={data?.id} ref={applyRef}>
      <img src={dragAndDrop} alt="dnd icon" />
      <MoodName>{data?.moodName}</MoodName>
      <InputDeleteButton onClick={() => onClick(data)} tabIndex={-1} />
    </Li>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-top: 20px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 456px;
  padding-right: 36px;
  border-right: 1px solid ${({ theme }) => theme.color.subUnactiveGray};

  h1 {
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const Ul = styled.div`
  display: flex;
  flex-direction: column;
  margin: 22px 0 40px 0;
  width: 100%;
  max-height: 620px;
  overflow-y: scroll;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    width: 80%;
    padding: 18px 0;
    border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const CheckBox = styled.div`
  width: 30px;
  height: 30px;
  margin-right: 14px;
  border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  border-radius: 100%;

  &.checked {
    background: url(${checkedButton}) center no-repeat;
    border: 1px solid ${({ theme }) => theme.color.mainWhite};
    background-size: 30px;
  }
`;

const DisplayRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

const SelectedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin-left: 36px;
`;

const TitleContainer = styled.div`
  width: 100%;
  height: 44px;
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;

const Li = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 59px;
  img {
    display: block;
    width: 0;
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.subBrightGray};

    img {
      width: 15px;
      margin-right: 10px;
    }
  }
`;

const MoodName = styled.div`
  width: 100%;
  margin-right: 14px;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const InputDeleteButton = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 32px;
`;
