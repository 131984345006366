import React, {useState, useEffect,} from 'react';
import axios from 'axios';
import styled from 'styled-components';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import VideoModal from '../../../components/common/Modal/VideoModal';
import NormalButton from '../../../components/common/Button/NormalButton';
import VideoInput from "../../../components/common/Input/VideoInput";
import DateInput from "../../../components/common/Input/DateInput";
import SelectModule from "../../../components/common/Select/SelectModule";
import AdvertisementBriefReport from "./AdvertisementBriefReport";
import AdvertisementFullReport from "./AdvertisementFullReport";
import {ADVERTISER} from "../../../constant/apiUrl";
import {getNowDateStringForFileName} from "../../../utils/commonFn";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import AdvertisementSelector from "./AdvertisementSelector";
import {campaignTypes} from "../../../constant/common";
import {handleStatus} from "../advertisementCampaign/AdvertisementCampaigns";
import handleDate from "../../../hooks/handleDate";
import CopyButton from "../../../components/common/Button/CopyButton";
import {
  getBriefReportWorkSheet,
  getCommunityInfeedBannerReportWorkSheet,
  getFullReportWorkSheet,
  getLimitCount,
  ParsedFullReport,
  parseFullReport,
  processTotalRow
} from "./ReportFunction";

const COUNT_PER_PAGE = 20;
const TIME_UNIT = [
  {name: '일별', value: 'DAYS' },
  {name: '월별', value: 'MONTHS' },
];

const REPORT_OS_OPTION = [
  { text: '전체', value: '' },
  { text: 'iOS', value: 'iOS' },
  { text: 'AOS', value: 'aOS' },
];

const getNameByValue = (TARGET, value) => {
  return TARGET.filter(option=> option.value === value).map(option=>option.name)
}

const getTextByValue = (TARGET, value) => {
  return TARGET.filter(option=> option.value === value).map(option=>option.text)
}

const AdvertisementReportModal = ({
  closeModal,
  advertisementStrId,
  advertiser,
  advertisementTitle,
  advertisementStartDt,
  advertisementEndDt,
  advertisementCampaigns,
  reportType
}) => {

  // 광고 리포트 조회 조건 //////////////////////////////////////////////////////
  const [startDateTime, setStartDateTime] = useState(advertisementStartDt?.slice(0, 10));
  const [endDateTime, setEndDateTime] = useState(advertisementEndDt?.slice(0, 10));
  const [osType, setOsType] = useState('');         // 플랫폼
  const [timeUnit, setTimeUnit] = useState('DAYS'); // 리포트 시간 단위 (DAYS / MONTHS)
  const [pageNumber, setPageNumber] = useState(0);  // 페이지
  const [showMore, setShowMore] = useState(false);  // 더보기 버튼
  // 리포트 내용(서버에서 가져온 집계 전 데이터)
  const [rawReports, setRawReports] = useState([]);
  // 파싱된 리포트 데이터
  const [parsedReports, setParsedReports] = useState([]);
  // 파싱된 리포트 데이터 TOTAL
  const [parsedReportTotal, setParsedReportTotal] = useState(new ParsedFullReport('TOTAL'));

  const campaignAll = {title:'전체'};
  const [campaign, setCampaign] = useState(campaignAll);
  const campaigns = [
    campaignAll, ... advertisementCampaigns.sort((a,b)=>{
      if(a.title > b.title) return 1;
      if(a.title < b.title) return -1;
      return 0;
    })
  ];

  // 화면 진입시 (최초 1회) 광고주 정보 조회 /////////////////////////////////////
  const [advertiserStrId, setAdvertiserStrId] = useState('');
  useEffect( async () => {
    await axios.get(`${ADVERTISER}/${advertiser.advertiserId}`)
    .then(res => {
      // 여기서 필요한 것은 문자열 광고주 아이디
      setAdvertiserStrId(res.data.adminAdvertiser.advertiserStrId);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  }, []);

  const params = {
    offset: 0,
    limit: COUNT_PER_PAGE,
    startDateTime : startDateTime ? startDateTime + " 00:00:00" : '',
    endDateTime : endDateTime ? endDateTime + " 23:59:59" : '',
    advertisementCampaignStrId : campaign ? campaign.advertisementCampaignStrId: null,
    accountIndexYn: 'N',
    osType,
    timeUnit
  };

  // 광고 리포트 조회
  const getAdvertisementReport = async (_page, _limit, _accountIndexYn) => {

    params.offset = _page * COUNT_PER_PAGE;
    params.limit = _limit;
    params.accountIndexYn = _accountIndexYn;
    console.log('AdvertisementReport > getAdvertisementReport > params:', params);

    try {
      const res = await axios.get(
        `/open/ad-report/${advertiserStrId}/${advertisementStrId}`,
        {params}
      );

      return res.data.reportDataByDateUnitList;
    }
    catch(reason) {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
  }
  // 최초 1회 실행 : 광고주 정보 조회에 성공해서 문자열 아이디 가져왔을 때
  useEffect( () => {
    if(advertiserStrId) {
      // 광고 정보 조회
      getAdvertisementReport(0, COUNT_PER_PAGE, 'N').then((_rawReports) => {
        // console.log('STEP01 rawReports:', _rawReports);
        // 로우 데이터 대체
        setRawReports(_rawReports);
        // 요청한 갯수 만큼 데이터 있으면 더보기 버튼 표시
        setShowMore(COUNT_PER_PAGE === _rawReports.length);
      });
    }
  }, [advertiserStrId]);

  // 더보기 버튼 클릭시 : 다음 페이지 호출
  const callViewMore = () => {
    let nextPage = pageNumber + 1;
    setPageNumber(nextPage);
    getAdvertisementReport(nextPage, COUNT_PER_PAGE, 'N').then((_rawReports) => {
      // console.log('STEP02 rawReports:', _rawReports);
      // 로우 데이터 추가
      setRawReports([...rawReports, ..._rawReports]);
      // 요청한 갯수 만큼 데이터 있으면 더보기 버튼 표시
      setShowMore(COUNT_PER_PAGE === _rawReports.length);
    });
  }

  //집계 조건 설정
  const handleSearchCondition = (value, setState) => {
    // console.log('AdvertisementReportModal > handleSearchCondition > value:', value);
    //해당 조건값 설정하고
    setState(value);
    clearReport();
  }

  // 집계 시작일 변경시
  const handleStartDatetime = (newStartDateTime) => {
    // 집계일 사이의 관계 체크 하고
    if(isValidDateTime(newStartDateTime, endDateTime)) {
      // 문제 없으면 조건 설정
      handleSearchCondition(newStartDateTime, setStartDateTime);
    }
  }

  // 집계 종료일 변경시
  const handleEndDateTime = (newEndDateTime) => {
    // 집계일 사이의 관계 체크 하고
    if(isValidDateTime(startDateTime, newEndDateTime)) {
      // 문제 없으면 조건 설정
      handleSearchCondition(newEndDateTime, setEndDateTime);
    }
  }

  // 집계일 사이의 관계 체크
  const isValidDateTime = (startDateTime, endDateTime) => {
    // 종료일은 시작일 이전이어야 함
    if (new Date(endDateTime) < new Date(startDateTime)) {
      alert('집계 시작일은 집계 종료일 보다 앞선 날짜여야 합니다.');
      return false;
    }
    // 시작일과 종료일은 최대 1년
    let diff = Math.abs(new Date(endDateTime).getTime() - new Date(startDateTime).getTime());
    diff = Math.ceil(diff / (1000 * 60 * 60 * 24));
    // console.log('AdvertisementReportModal > isValidDateTime :', startDateTime, '/', endDateTime , '/', diff);
    if (diff > 365) {
      alert('집계 종료일은 집계 시작일 보다 최대 1년까지만 이후일 수 있습니다.');
      return false;
    }
    return true;
  }

  //리포트 관련 내용 초기화
  const clearReport = () => {
    // 이전 리포트 값 클리어
    setRawReports([]);
    // 페이지 초기화
    setPageNumber(0);
    // 집계 조건 변경시 더보기 버튼 비표시
    setShowMore(false);
  }

  // 조회 버튼 클릭
  const executeSearch = () => {
    setPageNumber(0);
    getAdvertisementReport(0, COUNT_PER_PAGE, 'N').then((_rawReports) => {
      // console.log('STEP03 rawReports:', _rawReports);
      // 로우 데이터 대체
      setRawReports(_rawReports);
      // 요청한 갯수 만큼 데이터 있으면 더보기 버튼 표시
      setShowMore(COUNT_PER_PAGE === _rawReports.length);
    });
  }

  // 리포트 출력 ////////////////////////////////////////////////////////////////
  const downloadExcel = async () => {
    const rawReportForExcel = await getAdvertisementReport(
      0,
      getLimitCount(params.startDateTime, params.endDateTime, params.timeUnit),
      'N'
    );
    const parsedReportForExcel = parseFullReport(rawReportForExcel);
    const fullReportTotalForExcel = processTotalRow(parsedReportForExcel);

    let _fileNamePrefix = (reportType==='fullReport') ? '내부용 리포트' : '리포트';
    let _workSheet = (reportType==='fullReport')
      ? getFullReportWorkSheet(fullReportTotalForExcel, parsedReportForExcel)
      : getBriefReportWorkSheet(fullReportTotalForExcel, parsedReportForExcel);
    let _workSheetName = (reportType==='fullReport') ? '전체' : 'report';

    // excel 문서 만들기
    const workBook = XLSX.utils.book_new();

    // 고객용 또는 내부용 리포트에서 받은 시트 추가
    XLSX.utils.book_append_sheet(workBook, _workSheet, _workSheetName);
    // console.log('AdvertisementReportModal > saveExcel > reportTYpe:',reportType, '/campaignType:',campaign.campaignType);

    // 전체 리포트이고 커뮤니티 인피드 배너의 경우
    if(reportType==='fullReport' && campaign && campaign.campaignType === 'CBL') {
      // 구좌별 분리된 데이터 조회해서
      const response = await getAdvertisementReport(
          0,
          getLimitCount(params.startDateTime, params.endDateTime, params.timeUnit),
          'Y'
      );

      let workSheetByAccountIndex = getCommunityInfeedBannerReportWorkSheet(response);
      // console.log('AdvertisementReportModal > saveExcel > workSheetByAccountIndex:',workSheetByAccountIndex);
      // 시트 추가
      XLSX.utils.book_append_sheet(workBook, workSheetByAccountIndex, '구좌 별');
    }

    // 파일 객체 생성해서
    const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array'});
    const excelFile = new Blob(
        [excelBuffer],
        {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        }
    );

    // 파일로 저장
    FileSaver.saveAs(
        excelFile,
        // 다운로드 파일 명
        _fileNamePrefix + "_" + getNowDateStringForFileName() + ".xlsx"
    );
  }

  // 로우데이터 변경될 때마다 로우 데이터로 리포트 데이터 만들기
  useEffect(() => {
    const tempReport = parseFullReport(rawReports);
    setParsedReports(tempReport);
  },[rawReports]);

  // 리포트 내용 바뀔 때 마다 TOTAL 값 재 계산
  useEffect(() => {
    const fullReportTotal = processTotalRow(parsedReports);
    setParsedReportTotal(fullReportTotal);
  }, [parsedReports]);

  return (
    <VideoModal closeModal={closeModal} blockCloseBtn blockOutSideClick>
      <VideoModalWrapper>
        <ModalButtonWrapper>
        <TitleText>{reportType==='fullReport' ? '내부':'고객'}용 리포트 상세</TitleText>
          <NormalButton text="닫기" onSubmit={closeModal} className="cancelBtn"/>
        </ModalButtonWrapper>

        {/* 광고 정보 ####################################################### */}
        <SubTitle>광고 정보</SubTitle>
        <SpaceDiv value={20} />
        <AdvertisementContainer mt={10}>
          <InputWrapper>
            <LabelTextInput
                name={'advertiserStrId'} label={'광고주 ID'} readOnly
                value={advertiser.advertiserStrId}/>
          </InputWrapper>
          <InputWrapper>
            <LabelTextInput
                name={'advertisementStrId'} label={'광고 ID'} readOnly
                value={advertisementStrId}/>
          </InputWrapper>
          <InputWrapper>
            <LabelTextInput
                name={'advertisementTitle'} label={'광고 식별 제목'} readOnly
                value={advertisementTitle}/>
          </InputWrapper>
          <InputWrapper>
            <LabelTextInput
                name={'advertisementStartDt'} label={'노출 시작일시'} readOnly
                value={handleDate(advertisementStartDt)}/>
          </InputWrapper>
          <InputWrapper>
            <LabelTextInput
                name={'advertisementEndDt'} label={'노출 종료일시'} readOnly
                value={handleDate(advertisementEndDt)}/>
          </InputWrapper>
        </AdvertisementContainer>
        <LineDiv/>

        {reportType==='fullReport' && <>
          {/* 광고 캠페인 정보 ################################################ */}
          <SubTitle>광고 캠페인 정보</SubTitle>
          <SpaceDiv value={20} />
          <AdvertisementContainer mt={10}>
            <InputWrapper colSpan={3}>
              <AdvertisementSelector
                  placeholder="" label="광고 캠페인 선택"
                  type={'advertisementCampaign'}
                  options={campaigns}
                  value={(campaign.advertisementStrId)
                      ? `${campaign.title}(${campaign.advertisementStrId})`
                      : campaign.title
                  }
                  onChange={(e)=>handleSearchCondition(e, setCampaign)}
              />
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput name={'campaignStrId'} label={'광고 캠페인 ID'} value={campaign.advertisementCampaignStrId} placeholder="-" readOnly/>
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput
                  name={'campaignLinkType'}
                  label={'이동유형'}
                  value={
                    campaign.linkType === 'EXTERNAL'
                      ? '외부'
                      : campaign.linkType === 'INTERNAL' ? '내부' : ''
                  }
                  placeholder="-"
                  readOnly/>
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput name={'campaignType'} label={'지면유형'} value={campaignTypes[campaign.campaignType]} placeholder="-" readOnly/>
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput
                  name={'campaignStartDt'} label={'노출 시작일시'} placeholder="-" readOnly
                  value={(campaign.startDt) && handleDate(campaign.startDt)} />
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput
                  name={'campaignEndDt'} label={'노출 종료일시'} placeholder="-" readOnly
                  value={(campaign.endDt) && handleDate(campaign.endDt)} />
            </InputWrapper>
            <InputWrapper>
              <LabelTextInput name={'campaignActiveYn'} label={'상태'} value={handleStatus(campaign)} placeholder="-" readOnly/>
            </InputWrapper>
          </AdvertisementContainer>
          <LineDiv/>
        </>}

        {/* 리포트 정보 ##################################################### */}
        <SubTitle>리포트 정보</SubTitle>
        <SpaceDiv value={20} />
        {/* 데이터 검색 조건 ################################################ */}
        <MainContainer mt={10}>
          <InputWrapper>
            <DateInput label="집계 시작일" name="startDateTime" type ={"date"}
              initialStartDate={startDateTime}
              onChange = {e => handleStartDatetime(e.target.value)}/>
          </InputWrapper>
          <InputWrapper>
            <DateInput label="집계 종료일" name="endDateTime" type ={"date"}
              initialStartDate={endDateTime}
              onChange = {e => handleEndDateTime(e.target.value)}/>
          </InputWrapper>
          <InputWrapper>
            <SelectModule name="baundMusicType" placeholder="전체" label="플랫폼"
              value={getTextByValue(REPORT_OS_OPTION, osType)}
              options={REPORT_OS_OPTION}
              onChange = {e => handleSearchCondition(e.value, setOsType)}/>
          </InputWrapper>
          <InputWrapper>
            <SelectModule name="type" placeholder="" label="리포트 시간 단위"
              value={getNameByValue(TIME_UNIT, timeUnit)}
              options={TIME_UNIT}
              onChange = {e => handleSearchCondition(e.value, setTimeUnit)}/>
          </InputWrapper>
          <InputWrapper pt={35} className={'center widthFull'}>
            <NormalButton text={'조 회'} onSubmit={executeSearch} className={'reportBtn'}/>
          </InputWrapper>
          <InputWrapper pt={35} className={'center widthFull'}>
            <NormalButton text={'출 력'} onSubmit={downloadExcel} className={'reportBtn'}/>
          </InputWrapper>
          <InputWrapper pt={35} className={'center widthFull'}>
            {reportType !== 'fullReport' &&
              <CopyButton
                  text={'링크 복사'}
                  copyText={`${process.env.REACT_APP_ADVERTISEMENT_REPORT_SERVER}/${advertiser.advertiserStrId}/${advertisementStrId}`}
                  onCopyText={"복사 되었습니다."}
              />
            }
          </InputWrapper>
        </MainContainer>

        {/* 광고 성과 데이터 ################################################ */}
        <SpaceDiv value={20} />
        <SubTitle>광고 성과 데이터</SubTitle>
        <SpaceDiv value={20} />
        <MainContainer mt={10}>
          <InputWrapper colSpan={7}>
            {reportType==='fullReport'
              // 내부용 리포트 ##################################################
              ? <AdvertisementFullReport parsedReports={parsedReports} parsedReportTotal={parsedReportTotal}/>
              // 고객용 리포트 ################################################
              : <AdvertisementBriefReport parsedReports={parsedReports} parsedReportTotal={parsedReportTotal}/>
            }
          </InputWrapper>

          {showMore && (
            <InputWrapper colSpan={7} className={'center'}>
              <VideoInput text={`더 불러오기`} onClick={callViewMore}/>
            </InputWrapper>
          )}
        </MainContainer>

      </VideoModalWrapper>
    </VideoModal>
  );
};
export default AdvertisementReportModal;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 50px 30px 50px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.color.mainWhite};
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  button {
    margin-left: 10px;
  }
`;

const AdvertisementContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '50px'};
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 30px;
  grid-gap: 10px 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : '50px'};
  display: grid;
  grid-template-columns: 2.1fr 2.1fr 1.1fr 1.1fr 1fr 1fr 1fr;
  gap: 20px 30px;
  grid-gap: 10px 20px;
`;

const InputWrapper = styled.div`
    grid-row: ${({row}) => row && `${row}`};
    grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '5px'};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};
    padding-top: ${({pt}) => typeof(pt) === "number" ? `${pt}px` : ''};
    padding-left: ${({pl}) => typeof(pl) === "number" ? `${pl}px` : ''};
    
    &.center {
      margin:0 auto;
    }
    &.widthFull {
      width: 100%;
    }
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-bottom: ${({value}) => `${value}px`};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 20px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;
