import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// 컴포넌트
import NormalButton from '../../../components/common/Button/NormalButton';
import ListHead from '../../../components/UI/ListHead';

// config
import {NOTICE} from '../../../constant/apiUrl';
import NoticeEditSeqRowComponent from "./component/NoticeEditSeqRowComponent";
import ListSelect from "../../../components/common/Select/ListSelect";
import {
  eventActiveYnOptions,
  eventCountryTypeList, eventLanguageTypeList
} from "../../../constant/event";

const NoticeEditSeq = () => {
  const navigate = useNavigate();
  const [contentsList, setContentsList] = useState([]);
  const [activeYn, setActiveYn] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');

  // 정렬을 위한 공지 정보 조회
  const getNoticesForEditSeq = async () => {
    const params = {
      limit: 100,
      offset: 0,
      activeYn,
      countryCode: country,
      language,
      noticeType: 'M'
    };
    await axios.get(`${NOTICE}/edit-sequence`, {params})
    .then(resp => setContentsList(resp.data.noticeList))
    .catch(err => console.log(err))
  };

  // 순서 변경
  const changeSequence = async () => {
    const ids = contentsList.map((el) => el.id);
    await axios.put(
        `${NOTICE}/sequence`,
        {ids}
    )
    .then( () => {
      // console.log(resp);
      alert('저장되었습니다.');
      navigate(-1);
    })
    .catch ( reason => {
      console.log(reason);
      alert('잠시후 다시 이용해주세요.');
    })
  };

  // 드래그 앤 드롭
  const moveItem = useCallback(
      (dragIndex, hoverIndex) => {
        const dragItem = contentsList[dragIndex];
        const hoverItem = contentsList[hoverIndex];

        setContentsList((tagList) => {
          const updatedPets = [...tagList];
          updatedPets[dragIndex] = hoverItem;
          updatedPets[hoverIndex] = dragItem;
          return updatedPets;
        });
      },
      [contentsList],
  );

  // type, bpm, privateUse, openDate
  const handleSelect = (e, setState) => setState(e.currentTarget.title);

  useEffect(() => {
    getNoticesForEditSeq().then(r => r);
  }, [activeYn, country, language]);

  return (
      <Container>
        <TopContainer>
          <TitleText>노출 순서 변경</TitleText>
          <ButtonWrapper>
            <NormalButton
                text="취소"
                className="cancelBtn"
                onSubmit={() => navigate(-1)}
            />
            <NormalButton text="저장하기" onSubmit={() => changeSequence()} />
          </ButtonWrapper>
        </TopContainer>
        <PaginationWrapper>
          <SpaceDiv />
        </PaginationWrapper>
        <MainContainer>
          <TagHead>
            <ListHead title="" />
            <ListHead title="표시순서" />
            <ListHead title="번호"/>
            <ListHead title="이미지"/>
            <ListHead title="프로젝트 명"/>
            <ListSelect
                title="공개여부"
                list={eventActiveYnOptions}
                onClick={handleSelect}
                setState={setActiveYn}
            />
            <ListHead title="시작일"/>
            <ListHead title="종료일"/>
            <ListSelect
                title="국가"
                list={eventCountryTypeList}
                onClick={handleSelect}
                setState={setCountry}
            />
            <ListSelect
                title="언어"
                list={eventLanguageTypeList}
                onClick={handleSelect}
                setState={setLanguage}
            />
          </TagHead>
          {contentsList?.map((item, index) => (
              <NoticeEditSeqRowComponent
                  key ={item.id}
                  data={item}
                  moveListItem={moveItem}
                  index={index}
              />
          ))}
        </MainContainer>
      </Container>
  );
};

export default NoticeEditSeq;

const Container = styled.div`
  width: 100%;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
`;

const MainContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.mainBlack};
`;

const ButtonWrapper = styled.div`
  display: block;
  button {
    margin-left: 20px;
  }
`;

const TagHead = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 10px 80fr 80fr 110fr 110fr 80fr 110fr 110fr 80fr 80fr;
`;

const PaginationWrapper = styled.div`
  margin: 50px 0 40px 0;
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: 30px;
`;
