import React from 'react';
import styled from 'styled-components';
import EditButton from './EditButton';

interface CheckboxButtonProps {
  value: number;
  label?: string;
  Required?: boolean;
  checkedValue: boolean;
  name: string;
  onChange: () => void;
  eventButton?: () => void;
}

const CheckboxButton = ({
  value,
  label,
  Required,
  checkedValue,
  name,
  onChange,
  eventButton,
}: CheckboxButtonProps) => {
  return (
    <InputContainer>
      <InputText>
        {label} {Required && <span>*</span>}
      </InputText>
      <CheckboxContainer>
        <Checkbox>
          <label htmlFor="checkbox">
            <input
              id={`checkbox_${value}`}
              type="checkbox"
              checked={checkedValue}
              onChange={onChange}
              value={value}
            />
            <p>{name}</p>
          </label>
        </Checkbox>
        {eventButton &&
          <ButtonContainer>
            <EditButton text="적용" onClick={eventButton} />
          </ButtonContainer>
        }
      </CheckboxContainer>
    </InputContainer>
  );
};

export default CheckboxButton;

const InputContainer = styled.div`
  display: block;
`;

const InputText = styled.span`
  display: inline-block;
  // margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const Checkbox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 0 20px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.color.subBrightGray};
  line-height: 52px;

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    color: ${({ theme }) => theme.color.subGray};

    input[type='checkbox'] {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      margin: 0 5px 0 0;
      border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
      border-radius: 100%;
      background-color: ${({ theme }) => theme.color.mainWhite};

      &:before {
        content: '';
        display: block;
        width: 70%;
        height: 70%;
        margin: 15% auto;
        border-radius: 50%;
      }

      &:checked:before {
        background-color: ${({ theme }) => theme.color.mainPurple};
      }

      &:checked + p {
        font-weight: 600;
        color: ${({ theme }) => theme.color.mainBlack};
      }
    }

    p {
      margin-left: 10px;
    }
  }
`;

const ButtonContainer = styled.div`
  width: 15%;
  margin-left: 15px;
`;
