import React from 'react';
import styled from 'styled-components';
import checkedButton from '../../../assets/images/page/checkedButton.png';
import DateInput from './DateInput';

const CheckBoxInput = ({
  label,
  Required,
  name,
  text,
  disabled,
  type,
  checked,
  onChange,
}) => {

  return (
    <CheckBoxContainer>
      <InputContainer>
        { label && <>
          <InputText>
            {label} {Required && <span>*</span>}
          </InputText>
        </>
        }
        <InputBox>
          <label htmlFor={name}>
            {type === 'yn' && (
                <input
                    id={name}
                    type="checkbox"
                    disabled={disabled}
                    checked={checked ==='Y'}
                    onChange={({ target: { checked } }) => onChange(checked?'Y':'N')}
                />
            )}
            {!type && (
              <input
                  id={name}
                  type="checkbox"
                  disabled={disabled}
                  checked={checked}
                  onChange={({ target: { checked } }) => onChange(checked)}
              />
            )}
            <p>{text}</p>
          </label>
        </InputBox>
      </InputContainer>
    </CheckBoxContainer>
  );
};

export default CheckBoxInput;

const CheckBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: block;
`;

const InputText = styled.span`
  display: inline-block;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.color.subDarkGray};

  span {
    content: '*';
    color: ${({ theme }) => theme.color.mainRed};
  }
`;

const InputBox = styled.div`
  label {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    font-weight: 500;
    font-size: 15px;
    color: ${({ theme }) => theme.color.subGray};

    p {
      margin-left: 10px;
    }

    input[type='checkbox'] {
      -webkit-appearance: none;
      margin: 0 5px 0 0;
      background-color: ${({ theme }) => theme.color.mainWhite};
      border-radius: 100%;

      &:before {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
        border-radius: 100%;
      }

      &:checked:before {
        background: url(${checkedButton}) center no-repeat;
        border: 1px solid ${({ theme }) => theme.color.mainWhite};
        background-size: 28px;
      }

      &:checked + p {
        font-weight: 600;
        color: ${({ theme }) => theme.color.mainBlack};
      }
    }
  }
`;

