import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import axios from 'axios';

import { useDispatch } from 'react-redux';

// 컴포넌트
import NormalButton from '../../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../../components/common/Input/LabelTetxInput';
import RadioButton from '../../../../components/common/Button/RadioButton';

// config
import {COMMUNITY_BOARD_BANNER, COMMUNITY_CATEGORY} from '../../../../constant/apiUrl';
import DateInput from '../../../../components/common/Input/DateInput';
import { resetLoading, setLoading } from '../../../../redux/slices/user';
import FileInput from "../../../../components/common/Input/FileInput";
import TagMenuSelect from "../../../../components/common/Select/TagMenuSelect";

const CommunityBoardBannerDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const detailData = location.state;
  const nextSequence = detailData !== null ? detailData.nextSequence : 1;
  const prevId = detailData !== null ? detailData.prevId : null;

  const [exMasterCommunityBoardCategories, setExMasterCommunityBoardCategories] = useState([]);
  const [exposeCommunityBoardCategoryIds, setExposeCommunityBoardCategoryIds] = useState([]);
  const [exposeCommunityBoardCategoryNames, setExposeCommunityBoardCategoryNames] = useState([]);
  const [shMasterCommunityBoardCategories, setShMasterCommunityBoardCategories] = useState([]);
  const [showCommunityBoardCategoryIds, setShowCommunityBoardCategoryIds] = useState([]);
  const [showCommunityBoardCategoryNames, setShowCommunityBoardCategoryNames] = useState([]);
  const [title, setTitle] = useState('');
  const [titleEn, setTitleEn] = useState('');
  const [description, setDescription] = useState('');
  const [descriptionEn, setDescriptionEn] = useState('');
  const [bannerImgFile, setBannerImgFile] = useState(null);
  const [bannerImgUrl, setBannerImgUrl] = useState(null);
  const [sequence, setSequence] = useState(nextSequence);
  const [activeYn, setActiveYn] = useState('Y');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  // 공개된 카테고리 조회
  const getCommunityBoardCategoryInfos = async () => {
    await axios.get(
        `${COMMUNITY_CATEGORY}?offset=0&limit=100&privateYn=N`
    )
    .then(res => {
      const list = res.data.categoryList;
      setShMasterCommunityBoardCategories(list);
      setExMasterCommunityBoardCategories(
        // 전체 카테고리 강제 추가
        [{id: 0, name: '전체'}].concat(list)
      );
    })
    .catch(reason => console.error(reason));
  }

  // 상세 조회
  const getCommunityBoardBannerInfo = useCallback(async () => {
    await axios.get(
  `${COMMUNITY_BOARD_BANNER}/${prevId}/detail`,
    )
    .then(res => {
      setExposeCommunityBoardCategoryIds(
          res?.data?.banner.exposeCommunityBoardCategoryIds
          ? res?.data?.banner.exposeCommunityBoardCategoryIds
          : []
      );
      setShowCommunityBoardCategoryIds(
          res?.data?.banner.showCommunityBoardCategoryIds
              ? res?.data?.banner.showCommunityBoardCategoryIds
              : []
      );
      setTitle(res.data.banner.title);
      setTitleEn(res.data.banner.titleEn);
      setDescription(res.data.banner.description);
      setDescriptionEn(res.data.banner.descriptionEn);
      setBannerImgUrl(res.data.banner.bannerImgUrl);
      setSequence(res.data.banner.sequence);
      setActiveYn(res.data.banner.activeYn);
      setStartDate(res.data.banner.startDt?.replace(' ', 'T').slice(0, 16),);
      setEndDate(res.data.banner.endDt?.replace(' ', 'T').slice(0, 16),);
    })
    .catch(reason => {
      console.error(reason);
      alert('잠시후 다시 이용해주세요.');
    });
  }, [prevId]);

  // 노출할 카테고리 표시
  const changeExposeCommunityBoardCategory = (exposeCommunityBoardCategoryIds) => {
    const arr = [];
    exMasterCommunityBoardCategories.forEach((el) => {
      exposeCommunityBoardCategoryIds.forEach((el2) => {
        if (el.id === el2) arr.push(el.name);
      });
    });
    setExposeCommunityBoardCategoryNames(arr);
  }

  // 표시할 카테고리 표시
  const changeShowCommunityBoardCategory = (setShowCommunityBoardCategoryIds) => {
    const arr = [];
    exMasterCommunityBoardCategories.forEach((el) => {
      setShowCommunityBoardCategoryIds.forEach((el2) => {
        if (el.id === el2) arr.push(el.name);
      });
    });
    setShowCommunityBoardCategoryNames(arr);
  }

  // 노출할 카테고리 수정
  const handleExposeCommunityBoardCategory = useCallback((e) => {
        // 장르가 겹칠 때
        const duplicateCheck = exposeCommunityBoardCategoryNames?.indexOf(e.name);
        if (duplicateCheck !== -1) {
          const filteredNames = exposeCommunityBoardCategoryNames?.filter(
              (el) => el !== e.name,
          );
          const filteredIds = exposeCommunityBoardCategoryIds?.filter(
              (el) => el !== e.id,
          );
          setExposeCommunityBoardCategoryNames(filteredNames);
          setExposeCommunityBoardCategoryIds(filteredIds);
        } else {
          setExposeCommunityBoardCategoryNames(exposeCommunityBoardCategoryNames.concat(e.name));
          setExposeCommunityBoardCategoryIds(exposeCommunityBoardCategoryIds.concat(e.id));
        }
  }, [exposeCommunityBoardCategoryNames, exposeCommunityBoardCategoryIds]);

  // 표시할 카테고리 수정
  const handleShowCommunityBoardCategory = useCallback((e) => {
    shMasterCommunityBoardCategories.forEach(c => {
      if (c.name === e.name) {
        console.log(c.id);
        // 해당 요소가 존재하면
        if (showCommunityBoardCategoryIds.indexOf(c.id) > -1) {
          // 빼기
          setShowCommunityBoardCategoryIds(
              showCommunityBoardCategoryIds.filter(id => id !== c.id)
          );
        }
        // 존재하지않으면
        else {
          // 더하기
          setShowCommunityBoardCategoryIds([...showCommunityBoardCategoryIds, c.id]);
        }

        // 해당 요소가 존재하면
        if (showCommunityBoardCategoryNames.indexOf(c.name) > -1) {
          // 빼주고
          setShowCommunityBoardCategoryNames(
              showCommunityBoardCategoryNames.filter(name => name !== c.name)
          );
        }
        // 존재하지않으면
        else {
          // 더해주고
          setShowCommunityBoardCategoryNames([...showCommunityBoardCategoryNames, c.name]);
        }
      }
    })
  })

  // 배너 정보 저장
  const saveCommunityBoardBannerInfo = async (type) => {
    if (exposeCommunityBoardCategoryIds?.length === 0) {
      alert('노출할 카테고리를 선택해주세요.');
      return;
    }
    if (!showCommunityBoardCategoryIds) {
      alert('이동할 카테고리를 선택해주세요.');
      return;
    }
    if (!setTitle) {
      alert('제목을 입력해주세요.');
      return;
    }
    if ((type !== 'edit') && !bannerImgFile) {
      alert('배너 이미지를 입력해주세요.');
      return;
    }

    if (!startDate) {
      alert('노출 개시 일시를 입력해주세요.');
      return;
    }
    if (!endDate) {
      alert('노출 종료 일시를 입력해주세요.');
      return;
    }

    const formData = new FormData();
    formData.append('exposeCommunityBoardCategoryIds', exposeCommunityBoardCategoryIds);
    formData.append('showCommunityBoardCategoryIds', showCommunityBoardCategoryIds);
    formData.append('title', title);
    formData.append('titleEn', titleEn);
    formData.append('description', description);
    formData.append('descriptionEn', descriptionEn);
    formData.append('sequence', sequence);
    formData.append('activeYn', activeYn);
    formData.append('startDate', `${startDate.replace('T', ' ')}:00`);
    formData.append('endDate', `${endDate.replace('T', ' ')}:00`);
    if (bannerImgFile) {
      formData.append('bannerImg', bannerImgFile);
    }
    dispatch(setLoading());
    try {
      if (type === 'edit') {
        await axios.post(`${COMMUNITY_BOARD_BANNER}/${prevId}/edit`, formData, {},);
      } else {
        await axios.post(`${COMMUNITY_BOARD_BANNER}`, formData, {});
      }
      alert('저장되었습니다.');
      await navigate('/operation/communityBoardBanner');
    } catch (error) {
      alert(error.response.data.message);
    }
    dispatch(resetLoading());
  };

  // 페이지 입장시
  useEffect(() => {
    // 카테고리 조회
    getCommunityBoardCategoryInfos().then(r => r);
    // 상세 조회
    prevId && getCommunityBoardBannerInfo();
  }, []);

  //
    useEffect(() => {
      changeExposeCommunityBoardCategory(exposeCommunityBoardCategoryIds)
    }, [exMasterCommunityBoardCategories, exposeCommunityBoardCategoryIds]);
    useEffect(() => {
      changeShowCommunityBoardCategory(showCommunityBoardCategoryIds)
    }, [exMasterCommunityBoardCategories, showCommunityBoardCategoryIds]);

    return (
    <>
      <TopContainer>
        <ButtonWrapper>
          <NormalButton text="취소" className="cancelBtn" onSubmit={() => navigate(-1)}/>
          {!prevId ? (
            <NormalButton text="저장하기" onSubmit={() => saveCommunityBoardBannerInfo('add')} />
          ) : (
            <NormalButton text="수정하기" onSubmit={() => saveCommunityBoardBannerInfo('edit')}/>
          )}
        </ButtonWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper span={2}>
          <TagMenuSelect
              name="connectedCategoryId"
              label="노출할 카테고리"
              placeholder="노출할 카테고리를 선택해주세요."
              value={exposeCommunityBoardCategoryNames}
              tagIds={exposeCommunityBoardCategoryIds}
              onClick={handleExposeCommunityBoardCategory}
              options={exMasterCommunityBoardCategories}
              Required/>
        </InputWrapper>
        <InputWrapper span={2}>
          <TagMenuSelect
              name="connectedCategoryId"
              label="이동할 카테고리"
              placeholder="이동할 카테고리를 선택해주세요"
              value={showCommunityBoardCategoryNames}
              tagIds={showCommunityBoardCategoryIds}
              onClick={handleShowCommunityBoardCategory}
              options={shMasterCommunityBoardCategories}
              Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="title" label="배너 명" value={title}
              placeholder="배너 명을 입력해 주세요."
              onChange={(e) => setTitle(e.target.value)}
              onReset={() => setTitle('')}
              Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="titleEn" label="배너 명 EN" value={titleEn}
              placeholder="배너 명 EN을 입력해 주세요."
              onChange={(e) => setTitleEn(e.target.value)}
              onReset={() => setTitleEn('')}
              Required/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="description" label="배너 설명" value={description}
              placeholder="배너 설명을 입력해 주세요."
              onChange={(e) => setDescription(e.target.value)}
              onReset={() => setDescription('')}/>
        </InputWrapper>
        <InputWrapper>
          <LabelTextInput
              name="descriptionEn" label="배너 설명 EN" value={descriptionEn}
              placeholder="배너 설명 EN을 입력해 주세요."
              onChange={(e) => setDescriptionEn(e.target.value)}
              onReset={() => setDescriptionEn('')}/>
        </InputWrapper>
        <InputWrapper span={2}>
          <FileWrapper>
            <FileInput
                type="eventImg"
                placeholder={bannerImgFile ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
                label="배너 이미지 (jpg 파일만 등록 가능)"
                value={bannerImgFile}
                name="bannerFile"
                setFile={e => setBannerImgFile(e)}
                setImgUrl={setBannerImgUrl}
                Required
            />
            {bannerImgUrl ? (<EventImg src={bannerImgUrl} />) : (<EventImg  />)}
          </FileWrapper>
        </InputWrapper>
        <InputWrapper span={2}>
          <RadioButton
              value={activeYn} name="activeYn" label="공개 여부"
              onChange={(e) => setActiveYn(e.target.value)}
              text={['공개', '비공개']} type="activeYn"
              Required/>
        </InputWrapper>
        <InputWrapper>
          <DateInput
              label="노출 개시 일시" name="startDt" initialStartDate={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              Required/>
        </InputWrapper>
        <InputWrapper>
          <DateInput
              label="노출 종료 일시" name="endDt" initialStartDate={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              Required/>
        </InputWrapper>
      </MainContainer>
    </>
  );
};

export default CommunityBoardBannerDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  
  button {
    &:last-child {
        margin-left: 20px;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 30px;
  grid-gap: 20px 30px;
`;

const InputWrapper = styled.div`
    grid-row: ${({row}) => row && `${row}`};
    grid-column: ${({span}) => span && `span ${span}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : '0px'};
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  margin-bottom: 20px;
  gap: 30px;
  grid-gap: 30px;
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

