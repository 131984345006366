import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { MOOD_LIST } from '../../../constant/apiUrl';

// 무드 받아오기
export const getMoodList = createAsyncThunk(
  'mood/getMoodList',
  async (params: object, { rejectWithValue }) => {
    try {
      const response = await axios.get(MOOD_LIST, {
        params,
      });
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      console.log(rejectWithValue(error.response.data));
      return rejectWithValue(error.response.data);
    }
  },
);
