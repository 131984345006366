import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

// assets
import logoutIcon from '../../assets/images/nav/logoutIcon.png';
import userIcon from '../../assets/images/nav/userIcon.png';

// 액션
import { logOut } from '../../redux/actions/user/user';

const MenuIcon = () => {
	const dispatch = useDispatch();
	const handleLogout = () => {
		dispatch(logOut());
	};
	return (
		<IconWrapper>
			<LogoutButton>
				<IconImg src={logoutIcon} alt="Logout" onClick={handleLogout} />
			</LogoutButton>
			<li><IconImg src={userIcon} alt="Logout" /></li>
		</IconWrapper>
	);
};
export default MenuIcon;

const IconWrapper = styled.ul`
  display: flex;
  height: 30px;

  li {
    margin-left: 20px;
  }
`;

const LogoutButton = styled.button`
  cursor: pointer;
`;

const IconImg = styled.img`
  width: 30px;
`;
