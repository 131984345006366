import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useDispatch } from 'react-redux';

// component
import EditButton from '../../components/common/Button/EditButton';

// constant
import { serverDataList } from '../../constant/system';
import { SERVER_REFRESH } from '../../constant/apiUrl';

// redux
import { setLoading, resetLoading } from '../../redux/slices/user';

const UpdateData = () => {
  const dispatch = useDispatch();

  // 업데이트 진행
  const setSeverUpdate = async (api: string) => {
    try {
      dispatch(setLoading());
      await axios.get(`${SERVER_REFRESH}/${api}`, {});
      alert('서버 데이터가 갱신되었습니다.');
    } catch (err: any) {
      alert(
        err.response.status === 304
          ? 'DB의 클러스터 ip가 기존과 동일하여 변경되지 않았습니다.'
          : '잠시후 다시 시도해주세요.',
      );
    }
    dispatch(resetLoading());
  };

  return (
    <MainContainer>
      {serverDataList?.map((data: any) => {
        return (
          <Li key={data.id}>
            <h1>{data.title}</h1>
            <EditButton
              text="서버 데이터 갱신하기"
              onClick={() => setSeverUpdate(data.api)}
              className="saveBtn"
            />
          </Li>
        );
      })}
    </MainContainer>
  );
};

export default UpdateData;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 32px;
`;

const Li = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};

  h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: ${({ theme }) => theme.color.mainBlack};
  }
`;
