import {useNavigate} from "react-router-dom";
import styled from "styled-components";

import Table from "../../components/common/List/Table";
import ListItem from "../../components/common/List/ListItem";
import EditButton from "../../components/common/Button/EditButton";
import ListCoverImg from "../../components/common/List/ListCoverImg";

import handleDate from "../../hooks/handleDate";

const BattleTable = ({
	battleList,             // list: 객체 리스트
	readOnly,               // bool: 읽기 모드
	select,                 // bool: 선택 모드
	selector,               // function: 선택 모드시 선택 객체 전달
	remover                 // function: 선택 모드시 석택 객체 삭제
}) => {
	const navigate = useNavigate();

	return <Table
		colList={(readOnly && !select) ? [30, 30, 160, 50, 60, 60] : [30, 30, 160, 50, 60, 60, 30]}
		thead={!readOnly && <>
			<Th><span>배틀ID</span></Th>
			<Th><span>커버</span></Th>
			<Th><span>제목</span></Th>
			<Th><span>상태</span></Th>
			<Th><span>다음 상태변경 일시</span></Th>
			<Th><span>생성일시</span></Th>
			<Th><span>버튼</span></Th>
		</>}>
		{battleList.length > 0 && battleList.map(el => <tr key={el.battleId}>
			<Td><ListItem text={el.battleId} /></Td>
			<Td><ListCoverImg url={el.coverImgUrl} /></Td>
			<Td><ListItem text={el.title} /></Td>
			<Td><ListItem text={el.status} className={`battleStatus_${el.status}`} /></Td>
			<Td><ListItem text={el.nextStatusDate && handleDate(el.nextStatusDate)} /></Td>
			<Td><ListItem text={handleDate(el.createDate)} /></Td>
			{(!readOnly && !select) && <Td><EditButton text="수정" className="selectBtn" onClick={() => navigate('/battle/detail', {state: el.battleId})}/></Td>}
			{(!readOnly && select) && <Td><EditButton text="선택" className="saveBtn" onClick={() => selector(el)}/></Td>}
			{(select && readOnly) && <Td><EditButton text="삭제" className="selectBtn" onClick={() => remover(el)}/></Td>}
		</tr>)}
	</Table>
};

export default BattleTable;


const Th = styled.th`
  position: relative;
  padding: 20px 0;

  ::after {
    content: '';
    position: absolute;
    height: 45%;
    top: 50%;
    transform: translateY(-50%);
    right: -1px;
    border-right: 1px solid #e7e7e7;
  }

  ::before {
    content: '';
    position: absolute;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1px;
    border-bottom: 1px solid #e7e7e7;
  }

  span {
    font-size: 15px;
    color: ${({theme}) => theme.color.subDarkGray};
  }
`;

const Td = styled.td`
	position: relative;
	
  ::after {
      content: '';
      position: absolute;
      top: 50%;
      right: -1px;
      transform: translateY(-50%);
      height: 35%;
      border-right: 1px solid #e7e7e7;
	}
`;