import React, { Fragment, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import SearchInput from '../../components/common/Input/SearchInput';
import Pagination from '../../components/common/Pagination';
import useInput from '../../hooks/useInput';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import closeRoundButton from '../../assets/images/page/closeRoundButton.png';

import { RESERVATION } from '../../constant/apiUrl';

interface wordProps {
  id: number;
  email: string;
  pid: string;
  name: string;
}

const ReserveIdManagement = () => {
  const [searchWord, setSearchWord] = useInput('');
  const [addId, setAddId] = useState('');
  const [reserveId, setReserveId] = useState<Array<wordProps>>([]);
  const [error, setError] = useState('');

  // pagination state
  const [postsPerPage, setPostsPerPage] = useState(100);
  const [totalCount, setTotalCount] = useState(0);
  const storage = localStorage.getItem('pageNumber');
  const [pageNumber, setPageNumber] = useState(
    storage ? JSON.parse(storage) : 0,
  );

  const params = {
    pid: searchWord,
    limit: postsPerPage,
    offset: pageNumber * postsPerPage,
  };

  const pageDispatch = useCallback((e: number) => {
    setPageNumber(e - 1);
  }, []);

  const onChangeWord = useCallback((e: any) => {
    const { value } = e.target;
    setAddId(value);
  }, []);

  // 엔터키로 id 검색
  const searchEnterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setPageNumber(0);
      params.offset = 0;
      getReserveIdData();
    }
  };

  // reserveId 불러오기
  const getReserveIdData = async () => {
    try {
      const res = await axios.get(RESERVATION, {
        params,
      });
      setReserveId(res.data.memberUnableJoinList);
      setTotalCount(res.data.count || 0);
    } catch (err) {
      console.error(err);
    }
  };

  // reserveId 지우기
  const deleteId = async (el: wordProps) => {
    const { id } = el;

    try {
      await axios.delete(`${RESERVATION}/${id}`, {});
      await getReserveIdData();
    } catch (err) {
      // console.log(err);
    }
  };

  // reserveId 추가 api호출 함수
  const addReserveId = async (): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const params = {
      pid: addId,
    };
    try {
      await axios.post(RESERVATION, {}, { params });
      await getReserveIdData();
    } catch (err) {
      // console.log(err);
    }
    setAddId('');
  };

  // reserveId 추가
  const addSelectedId = () => {
    // 중복 체크
    const duplicateCheck: boolean = reserveId
      .map((el: wordProps) => el.pid)
      .includes(addId);
    // 공백 체크
    const blankPattern = /^\s+|\s+$/g;
    const isBlank = addId.replace(blankPattern, '') === '';
    if (duplicateCheck) {
      return setError('이미 등록된 Pid입니다.');
    }
    if (addId === '' || isBlank) {
      return setError('Pid를 입력해주세요.');
    }
    setError('');
    return addReserveId();
  };

  // 엔터키로 reserveId 저장
  const enterKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      addSelectedId();
    }
  };

  useEffect(() => {
    getReserveIdData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postsPerPage, pageNumber]);

  useEffect(() => {
    if (addId === '') {
      setError('');
    }
  }, [addId]);

  return (
    <>
      <TopContainer>
        <TopWrapper>
          <SearchWrapper>
            <SearchInput
              value={searchWord}
              onChange={setSearchWord}
              onKeyPress={searchEnterKeyPress}
            />
          </SearchWrapper>
          <PaginationWrapper>
            <Pagination
              postsPerPage={postsPerPage}
              totalCount={totalCount}
              currentPage={pageNumber}
              pageDispatch={pageDispatch}
            />
          </PaginationWrapper>
        </TopWrapper>
      </TopContainer>
      <MainContainer>
        <AddWordWrapper>
          <LabelTextInput
            name="pid"
            label="pid 입력"
            placeholder="추가할 pid를 입력하세요."
            value={addId}
            onChange={onChangeWord}
            onKeyPress={enterKeyPress}
          />
          {error && <ErrorText>{error}</ErrorText>}
        </AddWordWrapper>
        <BannedWordWrapper>
          {reserveId?.map((el: wordProps) => (
            <Fragment key={el.id}>
              <TextWrapper>
                <TextButton>{el.pid}</TextButton>
                <TagDeleteButton onClick={() => deleteId(el)} />
              </TextWrapper>
              <Line />
            </Fragment>
          ))}
        </BannedWordWrapper>
      </MainContainer>
    </>
  );
};

export default ReserveIdManagement;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 483px;
`;

const PaginationWrapper = styled.div``;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
  padding-bottom: 100px;
`;

const BannedWordWrapper = styled.div`
  width: 100%;
  margin-top: 55px;
`;

const AddWordWrapper = styled.div`
  position: relative;
`;

const ErrorText = styled.span`
  position: absolute;
  bottom: -30px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({ theme }) => theme.color.mainRed};
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;
`;
const TextButton = styled.div`
  font-size: 18px;
  font-weight: 600;
`;

const TagDeleteButton = styled.button`
  right: 25px;
  width: 20px;
  height: 20px;
  background: url(${closeRoundButton}) center no-repeat;
  background-size: 38px;
`;

const Line = styled.div`
  display: block;
  border-bottom: 1px solid #e7e7e7;
`;
