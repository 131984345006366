import React from 'react';
import { useNavigate } from 'react-router-dom';
//컴포넌트
import axios from 'axios';
import ListItem from '../../../../components/common/List/ListItem';
import EditButton from '../../../../components/common/Button/EditButton';
import ListCoverImg from '../../../../components/common/List/ListCoverImg';

// hooks
import { OfficialCollectionType } from '../../../../definitions/explore';
import { IsPrivate } from "../../../../constant/common";

interface PropsType {
  data: OfficialCollectionType;
  saveStorage?: () => void;
  officialId: number;
}

const OfficialCollectionRowComponent = ({
  data,
  saveStorage,
  officialId,
}: PropsType) => {
  const navigate = useNavigate();
  const { id, type, contentsCount, coverImgUrl, name, memberPid, privateYn } = data;

  const getDetailData = async (collectionId: number) => {
    if (saveStorage) {
      saveStorage();
    }
    try {
      const resp = await axios.get(
        `/explore/member-collection/official/${collectionId}`,
        {
          params: { limit: 20, offset: 0 },
        },
      );
      if (type === 'BT') {
        const beatResp = await axios.get(
          `/contents/member/collection/beat/${id}/contents`,
          { params: { limit: 1000, offset: 0 } },
        );
        console.log(beatResp.data.contentsList);
        navigate('/explore/collection/edit', {
          state: {
            data: resp.data.adminOfficialCollection,
            beatCollectionList: beatResp.data.contentsList,
            officialId,
          },
        });
        return;
      }
      navigate('/explore/collection/edit', {
        state: { data: resp.data.adminOfficialCollection },
      });
    } catch (err) {
      console.log(err);
      alert('잠시후 다시 시도 해주세요.');
    }
    // if (type === 'BT') {
    //   try {
    //   } catch (err) {
    //     console.log(err);
    //     alert('잠시후 다시 시도 해주세요.');
    //   }
    // }
  };

  return (
    <tr>
      <td>
        <ListItem text={id} />
      </td>
      <td>
        <ListItem text={type === 'AR' ? '영상' : '비트'} />
      </td>
      <td>
        <ListItem text={contentsCount} />
      </td>
      <td>
        <ListCoverImg url={coverImgUrl} className="coverImg" />
      </td>
      <td>
        <ListItem text={name} />
      </td>
      <td>
        <ListItem text={memberPid} />
      </td>
      {/*<td>*/}
      {/*  <ListItem text={shareCount} />*/}
      {/*</td>*/}
      <td>
        <ListItem text={IsPrivate[privateYn]} />
      </td>
      <td>
        <EditButton
          onClick={() => {
            getDetailData(id);
          }}
          text="수정"
        />
      </td>
    </tr>
  );
};

export default OfficialCollectionRowComponent;
