import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {useLocation} from 'react-router';
import {useDispatch} from 'react-redux';

// common components
import NormalButton from '../../../components/common/Button/NormalButton';
import LabelTextInput from '../../../components/common/Input/LabelTetxInput';
import FileInput from '../../../components/common/Input/FileInput';
import DateInput from '../../../components/common/Input/DateInput';
import SelectModule from '../../../components/common/Select/SelectModule';
import RadioButton from '../../../components/common/Button/RadioButton';
import VideoModal from '../../../components/common/Modal/VideoModal';
import Modal from '../../../components/common/Modal/Modal';
import VideoInput from '../../../components/common/Input/VideoInput';
import TagTextInput from '../../../components/common/Input/TagTextInput';
import Pagination from '../../../components/common/Pagination';
import Table from '../../../components/common/List/Table';
import ListSelect from '../../../components/common/Select/ListSelect';
import ListDateSelect from '../../../components/common/Select/ListDateSelect';
import SearchMenuSelect from '../../../components/common/Select/SearchMenuSelect';
import SearchInput from '../../../components/common/Input/SearchInput';
import TextField from '../../../components/common/TextField';
import LabelPlusButtonInput from '../../../components/common/Input/LabelPlusButtonInput';
import ListHead from '../../../components/UI/ListHead';
import PushForBattle from "../components/PushForBattle";

import {
	ActionEventModalRowComponent,
	EventModalRowComponent,
	SelectedEventRowComponent,
	SelectedNoticeModalRowComponent,
	SelectedVideoComponent,
} from '../components/EventModalRowComponent';
import DetailAddBeatRowComponents from '../../explore/components/category/DetailAddBeatRowComponents';

// constants
import {
	GetLanguageOption,
	GetCountryOption,
	countryOptions,
	languageOptions,
	postsPerPage,
	IsStatus,
} from '../../../constant/common';
import {
	eventModalColList,
	eventTypeKeyAndValue,
	noticeModalColList,
	ptpRuleTypeList,
	pupRuleKeyAndValue
} from '../../../constant/event';
import {articleSearchTypeList} from '../../../constant/video';

// config
import {
	ARTICLE,
	EVENT_LIST,
	NOTICE,
	OFFICIAL_ACCOUNTS,
	EVENT_PROMOTION,
	CONTENTS_HASHTAG,
	COMMUNITY_CATEGORY,
	EVENT_DEFAULT_URL,
	BATTLE_SIMPLE,
} from '../../../constant/apiUrl';
import {beatBpmOptions, beatSearchTypeList} from '../../../constant/beat';
import {addBeatCollectionColList} from '../../../constant/explore';

// redux
import {getBeatList} from '../../../redux/actions/beat/beat';
import {setLoading, resetLoading} from '../../../redux/slices/user';

//hook
import useInput from '../../../hooks/useInput';
import {useAppSelector} from '../../../redux/store/hooks';

//타입
import {limitCall} from '../../../utils/utils';
import EventPromotion from "./EventPromotion";
import * as commonFn from "../../../utils/commonFn";
import MultiLabelTextInput from "../../../components/common/Input/MultiLabelTextInput";

//초기 비트 데이터
const initialData = {
	eventType: 'MC',
	noticeType: 'M',

	id: '',
	title: '',
	description: '',
	countryCode: '',
	language: '',
	endDt: '',
	startDt: '',
	activeYn: 'Y',
	studioYn: 'Y',
	stax: 0,
	webUrl: '',

	bannerImgUrl: '',
	bannerVerticalImgUrl: '',
	eventImgFile: '',
	eventBeatBannerFile: '',
	noticeImgUrl: '',
	contentsImgUrl: '',
	beatBannerImgUrl: '',
	eventBannerFile: '',

	exArticle1Title: '',
	exArticle1Url: '',
	exArticle1ThumbnailImgFile: '',
	exArticle2Title: '',
	exArticle2Url: '',
	exArticle2ThumbnailImgFile: '',

	articleLargeId: '',
	articleLargeTitle: '',
	articleSmallIdList: [],
	articleSmallTitle: '',
	articleSmallDataList: [],

	beatIdList: [],
	beatTitle: '',
	beatDataList: [],

	requireHashTagTitle: '',
	requireHashTagMemberId: '',
	requireTagsMemberId: '',
	requireTagsMemberPid: '',

	baundMusicTitle: '',
	baundMusicIdList: '',

	directMoveLink: ''
};

const initialTagMemberData = {
	coverImgUrl: '',
	createdDt: '',
	description: '',
	id: '',
	name: '',
	pid: '',
	profileImgUrl: '',
	url: '',
};

const EventDetail = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	// 리스트에서 넘어온 값
	const { eventDetailData, noticeDetailData, type, selectedNoticeType, selectedContentId } = location.state;

	const [eventData, setEventData] = useState(initialData);
	const {
		id, title, description, projectName,
		language, countryCode, activeYn, startDt, endDt, studioYn,
		eventImgFile, eventBannerFile, eventBannerVerticalFile, eventType,
		articleLargeTitle, articleLargeData,
		articleSmallTitle, articleSmallDataList,
		baundMusicTitle, baundMusicIdList,
		beatTitle, beatDataList,
		webUrl, requireTagsMemberPid, stax,
		eventBeatBannerFile,
		directMoveLink
	} = eventData;

	const [detailContent, setDetailContent] = useState(initialData);

	const [eventDetailImgUrl, setEventDetailImgUrl] = useState('');
	const [eventBannerImgUrl, setEventBannerImgUrl] = useState('');
	const [eventBannerVerticalImgUrl, setEventBannerVerticalImgUrl] = useState('');
	const [eventBeatBannerImgUrl, setEventBeatBannerImgUrl] = useState('');

	const [isFileError, setIsFileError] = useState(false);
	const [isBannerFileError, setIsBannerFileError] = useState(false);
	const [tags, setTags] = useState([]);
	const [addTag, setAddTag] = useState('');

	const [noticeTextList, setNoticeTextList] = useState([]);
	const [eventNoticeImgFile, setEventNoticeImgFile] = useState(null);
	const [eventNoticeImgUrl, setEventNoticeImgUrl] = useState('');
	const noticeNextId = useRef(0);

	const [exVideoLinkList, setExVideoLinkList] = useState([{index: 1, title: '', imageUrl: '', contentsUrl: '', imageFile: null}]);
	const [externalLink, setExternalLink] = useState({index: 0, title: '', contentsUrl: ''})

	const [modalOpen, setModalOpen] = useState(false);
	const [modalType, setModalType] = useState('');
	const [contentList, setContentList] = useState([]);
	const [selectedContentList, setSelectedContentList] = useState([]);
	const [searchType, setSearchType] = useState('');
	const [keyword, setKeyword, onReset] = useInput('');
	const [accountData, setAccountData] = useState([]);
	const [requireHashTagMemberData, setRequireHashTagMemberData] = useState(initialTagMemberData);

	const [playBeat, setPlayBeat] = useState('');
	const [beatType, setBeatType] = useState('');
	const [bpm, setBpm] = useState('');
	const [minBpm, setMinBpm] = useState(0);
	const [maxBpm, setMaxBpm] = useState(250);
	const [minStartDate, setMinStartDate] = useState('');
	const [maxStartDate, setMaxStartDate] = useState('');

	// 이벤트 고도화
	const [selectedPromotion, setSelectedPromotion] = useState({eventPromotionId: 0, name: ''});
	const [communityBoardCategory, setCommunityBoardCategory] = useState({id: 0, name: ''})
	const [invitation, setInvitation] = useState({goalIdx: 0, script1: '', script2: '', script3: '', script4: '', script5: ''})
	const [attendCheck, setAttendCheck] = useState({goalIdx: 0, straightYn: 'N', validStartDate: '', validEndDate: ''})
	const [participate, setParticipate] = useState({
		whatType: '', howType: '',
		goalIdx: 0, contentId: 0, contentTitle: '',
		script1: '', script2: '', script3: ''
	});
	const [battle, setBattle] = useState({buttonIndex: 1, battleId: 0});
	const [selectedBattle, setSelectedBattle] = useState(null);
	const [suggestWordRandoms, setSuggestWordRandoms] = useState([]);
	const [suggestWordRandomSelectedId, setSuggestWordRandomSelectedId] = useState(0);
	const [suggestWordRandomSelectedName, setSuggestWordRandomSelectedName] = useState('사용 안함');

	const initialStartDate = startDt?.replace(' ', 'T').slice(0, 16);
	const initialEndDate = endDt?.replace(' ', 'T').slice(0, 16);

	// beat modal state
	const beatList = useAppSelector(state => state.beat.beatList);
	const beatTotalCount = useAppSelector(state => state.beat.beatTotalCount);

	// pagination
	const [pageNumber, setPageNumber] = useState(0);
	const [totalCount, setTotalCount] = useState(0);
	const pageDispatch = useCallback((e) => setPageNumber(e - 1), []);

	// params
	const videoParams = {
		offset: pageNumber * postsPerPage,
		limit: postsPerPage,
		keyword,
		searchType,
		articleType: 'NR',
		privateYn: 'N',
	};
	const beatParams = {
		offset: pageNumber * postsPerPage,
		limit: postsPerPage,
		word: keyword,
		type: beatType,
		maxBpm,
		minBpm,
		maxStartDate,
		minStartDate,
		privateYn: 'N',
	};

	// 초기 데이터 세팅
	const handleSetInitialData = useCallback(() => {
		// 배너 데이터 가져올때
		if (eventDetailData) {
			const hashTagArr = eventDetailData.requireTagsDataList?.map(e => e.tag);

			// 전체 데이터 추가
			setEventData({
				...eventDetailData,
				startDate: eventDetailData.startDt?.replace(' ', 'T').slice(0, 16),
				endDate: eventDetailData.endDt?.replace(' ', 'T').slice(0, 16),
			});

			// 해시태그 추가
			setTags(hashTagArr || []);

			// 해시태그 멤버 데이터 추가
			setRequireHashTagMemberData({
				...requireHashTagMemberData,
				id: eventDetailData.requireTagsMemberId,
				pid: eventDetailData.requireTagsMemberPid,
			});

			// 유의사항 추가
			if (eventDetailData.notice) {
				setNoticeTextList(eventDetailData.notice.map((el, idx) => ({id: idx, text: el})));
				noticeNextId.current = eventDetailData.notice.length;
			}
			if (eventDetailData.eventNoticeImgUrl) {
				setEventNoticeImgUrl(eventDetailData.eventNoticeImgUrl);
			}

			// 외부 링크 정보가 있을때
			if (eventDetailData.externalLinks) {
				// 외부 영상 링크 정보 세팅
				let _exVideoLinkList = eventDetailData.externalLinks.filter(el => el.index !== 0);
				if (_exVideoLinkList.length > 0) {
					_exVideoLinkList.forEach(el => el['imageFile'] = null);
					setExVideoLinkList(_exVideoLinkList);
				}

				// 이벤트타입이 외부링크형일때 (_externalLink.length가 이어야함.)
				let _externalLink = eventDetailData.externalLinks.filter(el => el.index === 0);
				if (_externalLink.length === 1) {
					setExternalLink(_externalLink[0]);
				}
			}

			// EventType에 따른 값 세팅
			if (eventDetailData.eventType === 'CP' || eventDetailData.eventType === 'OP' || eventDetailData.eventType === 'PW') {
				// BaundMusicIdList String 으로 변환
				let stringBaundMusicIdList = '';
				if (eventDetailData.baundMusicIdList != null) {
					for (let i = 0; i < eventDetailData.baundMusicIdList.length; i++) {
						if (i === 0) {
							stringBaundMusicIdList += eventDetailData.baundMusicIdList[i];
						} else {
							stringBaundMusicIdList += `, ${eventDetailData.baundMusicIdList[i]}`;
						}
					}
				}
				setEventData({
					...eventDetailData,
					baundMusicTitle: eventDetailData.baundMusicTitle,
					baundMusicIdList: stringBaundMusicIdList,
				});
			} else if (eventDetailData.eventType === 'ATC' && eventDetailData.attendCheck) {
				setAttendCheck({...attendCheck, goalIdx: eventDetailData.attendCheck.goalIdx});
			} else if (eventDetailData.eventType === 'PTP' && eventDetailData.participate) {
				setParticipate(eventDetailData.participate);
			} else if (eventDetailData.eventType === 'ICD') {
				setInvitation(eventDetailData.invitation);
			} else if (eventDetailData.eventType === 'CAR' && eventDetailData.communityBoardCategoryId && eventDetailData.communityBoardCategoryName) {
				setCommunityBoardCategory({
					id: eventDetailData.communityBoardCategoryId,
					name: eventDetailData.communityBoardCategoryName
				});
			} else if ((eventDetailData.eventType === 'BATTLE' || eventDetailData.eventType === 'BATTLE_ROOM') && eventDetailData.battle) {
				setBattle(eventDetailData.battle);
				if (eventDetailData.battle.battleId > 0) getBattleDetail(eventDetailData.battle.battleId);
			}

			// 프로모션 정보 세팅
			if (eventDetailData.eventPromotionId) {
				setSelectedPromotion({
					eventPromotionId: eventDetailData.eventPromotionId,
					name: eventDetailData.eventPromotionName,
				});
			}

			setSuggestWordRandomSelectedId(eventDetailData.suggestWordRandomId);
			// 이벤트 랜덤 제시어 마스터 조회
			getSuggestWordRandomAll(eventDetailData.suggestWordRandomId).then();
		}

		// 공지 상세에서 데이터 가져올때
		if (noticeDetailData) {
			setEventData({
				...noticeDetailData,
				countryCode: noticeDetailData.countryCode,
				language: noticeDetailData.language,
				startDate: noticeDetailData.startDt?.replace(' ', 'T').slice(0, 16),
				endDate: noticeDetailData.endDt?.replace(' ', 'T').slice(0, 16),
			});
		}
	}, []);

	// 공지 등록할 때 기본 배너 데이터 가져올때
	const handleSetInitialEventData = (data) => {
		if (data) {
			setEventData({
				...data,
				activeYn: data.activeYn,
				articleLargeData: [data.articleLargeData],
				articleLargeTitle: data.articleLargeTitle,
			});

			// 외부 링크 정보가 있을때
			if (data.externalLinks) {
				// 외부 영상 링크 정보 세팅
				let _exVideoLinkList = data.externalLinks.filter(el => el.index !== 0);
				if (_exVideoLinkList) {
					_exVideoLinkList.forEach(el => el['imageFile'] = null);
					setExVideoLinkList(_exVideoLinkList);
				}
			}

			setTags(data.requireTagsDataList?.map((e) => e.tag) || []);

			setRequireHashTagMemberData({
				...requireHashTagMemberData,
				id: data.requireTagsMemberId,
				pid: data.requireTagsMemberPid,
			});

			// 공지 상세로 들어온 경우와 아닌 경우
			if (!noticeDetailData) {
				// BaundMusicIdList String 으로 변환
				let stringBaundMusicIdList = '';
				if (data.baundMusicIdList != null) {
					for (let i = 0; i < data.baundMusicIdList.length; i++) {
						if (i === 0) stringBaundMusicIdList += data.baundMusicIdList[i];
						else stringBaundMusicIdList += `${data.baundMusicIdList[i]},`;
					}
				}

				setEventData({
					...data,
					countryCode: data.countryCode,
					language: data.language,
					eventType: data.eventType,
					title: '',
					bannerImgUrl: '',
					projectName: '',
					description: '',
					id: '',
					baundMusicTitle: data.baundMusicTitle,
					baundMusicIdList: stringBaundMusicIdList,
				});
			} else {
				setEventData({
					...data,
					title: noticeDetailData.title,
					bannerImgUrl: noticeDetailData.bannerImgUrl,
					projectName: noticeDetailData.projectName,
					description: noticeDetailData.description,
					id: noticeDetailData.id,
					countryCode: noticeDetailData.countryCode,
					language: noticeDetailData.language,
					startDate: noticeDetailData.startDt?.replace(' ', 'T').slice(0, 16),
					endDate: noticeDetailData.endDt?.replace(' ', 'T').slice(0, 16),
					startDt: noticeDetailData.startDt,
					endDt: noticeDetailData.endDt,
					activeYn: noticeDetailData.activeYn,
					beatBannerImgUrl: noticeDetailData.beatBannerImgUrl,
				});
			}
		}
	};

	// input set 함수
	const handleInputSetData = useCallback(e => {
		const {name, value} = e.target;
		setEventData({...eventData, [name]: value});
	}, [eventData]);

	// input reset 함수
	const handleResetData = useCallback(e => {
		setEventData({...eventData, [e]: initialData[e]});
	}, [eventData]);

	// 유의사항 set 함수
	const handleNoticeTextSetData = (text) => {
		const noticeText = {id: noticeNextId.current, text};
		setNoticeTextList([...noticeTextList, noticeText]);
		noticeNextId.current += 1;
	};

	// 유의사항 삭제 함수
	const onRemoveNoticeText = (noticeId) => {
		setNoticeTextList(noticeTextList.filter((noticeText) => noticeText.id !== noticeId));
	};

	// Select Box 변경 시 데이터 세팅을 위한 메소드
	const handleSelectSetData = useCallback(e => {
		if (e.name) {
			setEventData({...eventData, eventType: e.value});
		}
	}, [eventData]);

	// 유의사항 수정 함수
	const onEditNoticeText = useCallback((noticeId, text) => {
		setNoticeTextList(noticeTextList.map((el) => el.id === noticeId ? {...el, text} : el));
	}, [noticeTextList]);

	// select set 함수
	const handleSetData = useCallback((e, name) => {
		if (name === 'requireHashTagMemberId') {
			setEventData({...eventData, [name]: e.id});
			setRequireHashTagMemberData(e);
		} else {
			setEventData({...eventData, [name]: e.value});
		}
	}, [eventData]);

	// 검색 키워드 변경 확인
	const checkProjectNameChange = () => JSON.stringify(projectName !== null ? projectName?.split(',') : projectName) !== JSON.stringify(projectName);

	// 파일 등록하기
	const handleFileSetData = useCallback((file, name) => {
		setEventData({...eventData, [name]: file});
	}, [eventData]);

	// 데이트 저장시 사용
	const handleDate = (date, tRemove, start) => {
		let _date = date;

		if (tRemove && date.indexOf('T') < 0) {
			_date = date.split(' ');
			_date = _date[0] + 'T' + _date[1];
		}
		if (!tRemove && date.indexOf('T') > 0) {
			_date = date.split('T');
			_date = _date[0] + ' ' + _date[1];
		}

		return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
	}

	// 공식계정 리스트 불러오기
	const getAccountList = useCallback(async () => {
		try {
			const resp = await axios.get(OFFICIAL_ACCOUNTS, {params: {limit: 1000, offset: 0}});
			setAccountData(resp.data.officialAccountList);
		} catch (err) {
			console.log(err);
			dispatch(resetLoading());
		}
	}, []);

	// 공지 상세 진입 시 이벤트 쪽 배너 불러오기
	const getDetailContent = async () => {
		try {
			const resp = await axios.get(
				`${EVENT_LIST}/${selectedContentId || noticeDetailData?.relatedContentsId}`,
				{params: {limit: 500, offset: 0}}
			);
			await setDetailContent(resp.data.event);
			await handleSetInitialEventData(resp.data.event);
		} catch (err) {
			console.log(err);
			dispatch(resetLoading());
		}
	};

	// modal 닫기
	const closeModal = () => {
		// 데이터 초기화
		setContentList([]);

		onReset();
		setPageNumber(0);
		setSearchType('');
		setBeatType('');
		setBpm('');
		setMinBpm(0);
		setMaxBpm(250);
		setMinStartDate('');
		setMaxStartDate('');

		setModalOpen(false);
	};

	// 취소하면 뒤로가기
	const goBack = () => navigate(type === 'event' ? '/operation/event' : '/operation/notice');

	// 영상 리스트 불러오기
	const getArticleList = async () => {
		try {
			const resp = await axios.get(ARTICLE, {params: videoParams});
			setContentList(resp.data.articleList);
			setTotalCount(resp.data.count || 0);
		} catch (err) {
			console.log(err);
			dispatch(resetLoading());
		}
	};

	// 프로모션 리스트 불러오기
	const getEventPromotionList = async () => {
		await axios.get(EVENT_PROMOTION, {
			params: {
				offset: pageNumber * postsPerPage,
				limit: postsPerPage,
				activeYn: 'Y',
			}
		}).then((res) => {
			setContentList(res.data.eventPromotionList);
			setTotalCount(res.data.totalCount || 0);
		}).catch((err) => console.log(err));
	};

	// 태그 리스트 불러오기
	const getTagList = async () => {
		await axios.get(CONTENTS_HASHTAG, {
			params: {
				word: keyword,
				offset: pageNumber * postsPerPage,
				limit: postsPerPage,
			}
		}).then((res) => {
			setContentList(res.data.tagList);
			setTotalCount(res.data.count || 0);
		}).catch((err) => console.log(err));
	};

	// 커뮤니티 카테고리 리스트 불러오기
	const getCommunityCategoryList = async () => {
		await axios.get(`${COMMUNITY_CATEGORY}/simple`, {
			params: {
				limit: postsPerPage,
				offset: pageNumber * postsPerPage,
				privateYn: 'N',
			}
		}).then((res) => {
			setContentList(res.data.categorySimpleList);
			setTotalCount(res.data.count || 0);
		}).catch((err) => console.log(err));
	};

	// 선택됐던 배틀 정보 가져오기
	const getBattleDetail = (battleId) =>  {
		axios
		.get(`${BATTLE_SIMPLE}/${battleId}`)
		.then(res => {
			if (res?.data) setSelectedBattle(res.data.battle);
		});
	}

	// type, bpm, privateUse, openDate
	const handleSelect = (e, setState) => setState(e.currentTarget.title);

	// 엔터키로 검색
	const enterKeyPress = e => {
		if (e.key === 'Enter') {
			dispatch(setLoading());

			videoParams.offset = 0;
			beatParams.offset = 0;
			setPageNumber(0);

			if (!searchType) {
				setSearchType('ID');
				videoParams.searchType = 'ID';
				setBeatType('T');
				beatParams.type = 'T';
				setPageNumber(0);
			}

			if (modalType === 'main' || modalType === 'sub') getArticleList().then(() => dispatch(resetLoading()));
			else if (modalType === 'beat') {
				dispatch(getBeatList(beatParams));
				dispatch(resetLoading());
			}
			else if (modalType === 'PTP') {
				if (participate.whatType === 'TG' && participate.howType === 'PRS') {
					getTagList().then(() => dispatch(resetLoading()));
				}
			} else dispatch(resetLoading());
		}
	};

	// 영상이나 비트 추가하기
	const openModal = (name) => {
		if (name === 'PTP' && (!participate.whatType || !participate.howType)) {
			alert('먼저 참여 방식을 선택해주세요.');
			return;
		}

		setModalType(name);
		setModalOpen(true);

		if (name === 'main') {
			setSelectedContentList(articleLargeData ? [articleLargeData] : []);
			getArticleList().then(() => dispatch(resetLoading()));
		} else if (name === 'beat') {
			setSelectedContentList(beatDataList || []);
			dispatch(getBeatList(beatParams));
			dispatch(resetLoading());
		} else if (name === 'sub') {
			setSelectedContentList(articleSmallDataList || []);
			getArticleList().then(() => dispatch(resetLoading()));
		} else if (name === 'promotion') {
			getEventPromotionList().then(() => dispatch(resetLoading()));
		} else if (name === 'CAR') {
			getCommunityCategoryList().then(() => dispatch(resetLoading()));
		}
	};

	// 모달안에서 영상이나 비트 저장
	const saveContentList = () => {
		const isBlockedContent = selectedContentList.some(content => {
			if (!content.articleStatus) return false;
			return IsStatus[content.articleStatus] !== '정상';
		});

		if (modalType === 'main') {
			if (isBlockedContent) {
				alert('차단된 영상은 등록이 불가합니다. 다시 선택해 주세요.');
				return;
			}
			if (selectedContentList.length > 1) {
				alert('영상(대)은 한 개의 영상만 선택이 가능합니다.');
				return;
			}
			setEventData({...eventData, articleLargeData: selectedContentList[0]});
		}

		if (modalType === 'beat') {
			setEventData({...eventData, beatDataList: selectedContentList});
		}

		if (modalType === 'sub') {
			if (isBlockedContent) {
				alert('차단된 영상은 등록이 불가합니다. 다시 선택해 주세요.');
				return;
			}
			setEventData({...eventData, articleSmallDataList: selectedContentList});
		}

		closeModal();
	};

	// 영상 지우기
	const deleteVideo = (data, name) => {
		if (name === 'main') {
			setEventData({...eventData, articleLargeData: ''});
		} else if (name === 'beat') {
			setEventData({...eventData, beatDataList: beatDataList.filter(e => e.id !== data.id)});
		} else if (name === 'sub') {
			setEventData({...eventData, articleSmallDataList: articleSmallDataList.filter(e => e.id !== data.id)});
		} else {
			setSelectedContentList(selectedContentList.filter((e) => e.id !== data.id));
		}
	};

	// 비트 클릭시 추가
	const addSelectedContent = data => {
		if (!selectedContentList?.find((e) => e.id === data.id)) {
			setSelectedContentList([...selectedContentList, data]);
		}
	};

	// 비트 클릭시 삭제
	const filterSelectedContent = e => {
		const result = selectedContentList.filter((item) => item !== e);
		setSelectedContentList(result);
	};

	// 외부 영상 링크 데이터 수정
	const handleExVideoLink = (i, fieldName, v) => {
		let _externalLinkList = [...exVideoLinkList];
		_externalLinkList = _externalLinkList.map(el => {
			if (el.index === i+1) el[fieldName] = v;
			return el;
		});
		setExVideoLinkList(_externalLinkList);
	}

	/* 저장 및 수정 */
	const handleEventBanner = limitCall(async (handleType) => {
		let apiUrl = handleType === 'add' ? '' : '/' + id;
		let successMessage = ` ${handleType === 'add' ? '저장' : '수정'}되었습니다.`;
		let successUrl = '/operation/';

		const request = {};
		const formData = new FormData();

		// 필수값 데이터 유효성 체크
		let alertMessage = '';
		if (!title) alertMessage = '제목은';
		if (!startDt) alertMessage = '시작일시는';
		if (!endDt) alertMessage = '종료일시는';
		if (!countryCode) alertMessage = '국가는';
		if (!language) alertMessage = '언어코드는';
		if (alertMessage.length > 0) {
			alert(`${alertMessage} 필수값입니다.`);
			return;
		}
		if (startDt > endDt) {
			alert('시작일시가 종료일시보다 느릴 수 없습니다.');
			return;
		}
		if (new Date() > new Date(endDt)) {
			alert('종료일시를 오늘 날짜보다 뒤에 설정해주세요.');
			return;
		}

		request['title'] = title;
		request['countryCode'] = countryCode;
		request['language'] = language;
		request['activeYn'] = activeYn;
		request['description'] = description || '';

		if (checkProjectNameChange()) {
			request['projectName'] = projectName;
		}

		// 이벤트 배너 일 때
		if (type === 'event') {
			request['startDate'] = handleDate(startDt, true, true);
			request['endDate'] = handleDate(endDt, true, false);
			request['stax'] = stax;

			if (!studioYn) {
				alert('스튜디오 이동 버튼 노출 여부를 지정해 주세요. ');
				return;
			}
			request['studioYn'] = studioYn;
			request['eventType'] = eventType;

			// eventType에 따른 유효성 체크 및 값세팅
			if (eventType === 'CP' || eventType === 'OP' || eventType === 'PW') {
				if (baundMusicTitle === '' || baundMusicIdList === '') {
					alert('Baund Music 타이틀, ID 를 필수로 입력해 주세요.');
					return;
				}
				request['baundMusicTitle'] = baundMusicTitle;
				request['baundMusicIdList'] = baundMusicIdList
													.replaceAll(' ', '')
													.split(',')
													.filter(id => id?.length);
			}
			else if (eventType === 'ATC') {
				if (!attendCheck.goalIdx || attendCheck.goalIdx === 0) {
					alert('출석 목표 개수가 설정되지않았거나 0인 상태입니다.');
					return;
				}
				request['attendCheck'] = attendCheck;
			}
			else if (eventType === 'PTP') {
				if (!participate.goalIdx || participate.goalIdx === 0) {
					alert('참여 목표 개수가 설정되지않았거나 0인 상태입니다.');
					return;
				}
				if (participate.contentId === 0 || !participate.contentTitle) {
					alert('참여할 콘텐츠가 설정되지않았습니다.');
					return;
				}
				if (!participate.whatType || !participate.howType) {
					alert('참여 방법 설명 문구가 비어있는 곳이 있습니다.');
					return;
				}
				if (!participate.script1 || !participate.script2 || !participate.script3) {
					alert('참여 방법 설명 문구가 비어있는 곳이 있습니다.');
					return;
				}
				request['participate'] = participate;
			}
			else if (eventType === 'ICD') {
				if (!invitation.goalIdx || invitation.goalIdx === 0) {
					alert('참여 목표 개수가 설정되지않았거나 0인 상태입니다.');
					return;
				}
				if (!invitation.script1 || !invitation.script2 || !invitation.script3 || !invitation.script4 || !invitation.script5) {
					alert('참여 방법 설명 문구가 비어있는 곳이 있습니다.');
					return;
				}
				request['invitation'] = invitation;
			}
			else if (eventType === 'CAR') {
				if (!communityBoardCategory.id) {
					alert('커뮤니티 카테고리가 정해지지않았습니다.');
					return;
				}
				request['communityBoardCategoryId'] = communityBoardCategory.id;
			}
			else if (eventType === 'EXL') {
				if (!externalLink.title || !externalLink.contentsUrl) {
					alert('이벤트타입이 외부링크형때의 필수값이 누락되었습니다.');
					return;
				}
				request['externalLinks'] = [externalLink];
			}
			else if (eventType === 'BATTLE') {
				if (!battle.buttonIndex) {
					alert('배틀 버튼 타입을 선택해주세요.');
					return;
				} else if (battle.buttonIndex === 3 && !battle.battleId) {
					alert('보러가기 선택 시 배틀 아이디를 지정해야합니다.');
					return;
				}
				request['battle'] = battle;
			}
			else if (eventType === 'BATTLE_ROOM') {
				if (battle.buttonIndex !== 0 || !battle.battleId) {
					alert('배틀 아이디를 지정해야합니다.');
					return;
				}
				request['battle'] = battle;
			}

			// 외부 영상 추가
			if (exVideoLinkList[0].contentsUrl || exVideoLinkList[0].imageFile) {
				// request에 필드없으면 추가
				if (!request.hasOwnProperty('externalLinks')) {
					request['externalLinks'] = [];
				}
				let validExVideoLink = true;
				exVideoLinkList.forEach(el => {
					if (handleType === 'add' && (!el.contentsUrl || !el.imageFile)) {
						validExVideoLink = false;
					}
					request['externalLinks'].push({
						index: el.index,
						title: el.title,
						contentsUrl: el.contentsUrl,
						imageUrl: el.imageUrl
					});
					formData.append(`exArticle${el.index}ThumbnailImgFile`, el.imageFile);
				});
				if (!validExVideoLink) {
					alert('외부 영상 부분의 필수값이 누락되었습니다.');
					return;
				}
			}

			// 유의사항 추가 및 수정
			if (noticeTextList.length > 0) {
				request['notice'] = [];
				noticeTextList.forEach(el => request['notice'].push(el.text))
			}
			// 유의사항 이미지
			if (eventNoticeImgFile) {
				formData.append('eventNoticeImgFile', eventNoticeImgFile);
			}

			// 영상(대) 유효성 체크 및 값세팅
			if ((articleLargeData && !articleLargeTitle) || (!articleLargeData && articleLargeTitle)) {
				alert('영상(대)의 필수값이 누락되었습니다.');
				return;
			} else if (articleLargeData && articleLargeTitle) {
				request['articleLargeId'] = articleLargeData.id;
				request['articleLargeTitle'] = articleLargeTitle;
			}

			// 영상(소) 유효성 체크 / 값세팅
			if ((!articleSmallDataList?.length && articleSmallTitle) || (articleSmallDataList?.length && !articleSmallTitle)) {
				alert('영상(소)의 필수값이 누락되었습니다.');
				return;
			} else if (articleSmallDataList?.length && articleSmallTitle) {
				request['articleSmallIdList'] = articleSmallDataList.map((video) => video.id);
				request['articleSmallTitle'] = articleSmallTitle;
			}

			// 비트 리스트 유효성 체크 / 값세팅
			if ((!beatDataList?.length && beatTitle) || (beatDataList?.length && !beatTitle)) {
				alert('비트 리스트의 필수값이 누락되었습니다.');
				return;
			} else if (beatDataList?.length && beatTitle) {
				request['beatIdList'] = beatDataList.map((beat) => beat.id);
				request['beatListTitle'] = beatTitle;
			}

			// 필수해시태그 유효성 체크 및 값세팅
			if ((tags.length === 0 && requireHashTagMemberData?.id) || (tags.length > 0 && !requireHashTagMemberData?.id)) {
				alert('필수 해시태그의 필수값이 누락되었습니다.');
				return;
			} else if (tags.length > 0 && requireHashTagMemberData?.id) {
				request['requireHashTagList'] = tags.map(tag => tag.toLowerCase());
				request['requireHashTagMemberId'] = requireHashTagMemberData.id;
			}
			// 클라의 특정 페이지로 이동 및 이동 시킬 주소가 필요한 경우
			if (eventType === 'ABOUT_BAUND_GOLD' || eventType === 'ABOUT_GOLD_CAMPAIGN') {
				if (!directMoveLink || directMoveLink === '') {
					alert('버튼 클릭시 이동할 주소를 입력 해 주세요');
					return;
				}
				request['directMoveLink'] = directMoveLink;
			}

			if (suggestWordRandomSelectedId && suggestWordRandomSelectedId !== 0) {
				request['suggestWordRandomId'] = suggestWordRandomSelectedId;
			}

			// 저장
			if (handleType === 'add') {
				// 메인과 배너 이미지 전부 업로드 확인
				if (!eventBannerVerticalFile) {
					alert("'새 비율 배너 이미지'는 필수입니다.");
					return;
				}
				formData.append('eventBannerVerticalImgFile', eventBannerVerticalFile);

				if (!eventBannerFile) {
					alert("'기존 배너 이미지'는 필수입니다.");
					return;
				}
				formData.append('eventBannerImgFile', eventBannerFile);

				if (!eventImgFile) {
					alert("'상세 이미지'는 필수입니다.");
					return;
				}
				formData.append('eventImgFile', eventImgFile);

				if (eventType === 'ICD' || eventType === 'ENT' || eventType === 'ATC' || eventType === 'PTP') {
					if (selectedPromotion.eventPromotionId === 0 || !selectedPromotion.name) {
						alert('이벤트 타입이 [초대코드형, 단순응모형, 출석체크형, 참여형]인 경우 프로모션은 필수값입니다.');
						return;
					}
					request['eventPromotionId'] = selectedPromotion.eventPromotionId;
				}

				if (eventBeatBannerFile)
					formData.append('eventBeatBannerImgFile', eventBeatBannerFile);

			}
			// 수정
			else if (handleType === 'edit') {
				if (eventImgFile) formData.append('eventImgFile', eventImgFile);
				if (eventBannerFile) formData.append('eventBannerImgFile', eventBannerFile);
				if (eventBannerVerticalFile) formData.append('eventBannerVerticalImgFile', eventBannerVerticalFile);
				if (eventBeatBannerFile) formData.append('eventBeatBannerImgFile', eventBeatBannerFile);
			}
			dispatch(setLoading());

			const blob = new Blob([JSON.stringify(request)], {type: 'application/json'});
			formData.append(handleType === 'add' ? 'saveRequest' : 'updateRequest', blob);

			apiUrl = EVENT_LIST + apiUrl;
			successMessage = '이벤트가' + successMessage;
			successUrl += 'event';
		}

		// 공지 일 때
		if (type === 'notice') {
			dispatch(setLoading());

			request['startDate'] = handleDate(startDt, false, true);
			request['endDate'] = handleDate(endDt, false, false);
			Object.keys(request).forEach(key => formData.append(key, request[key]));

			formData.append('noticeType', 'M');
			if (eventImgFile) formData.append('noticeImgFile', eventImgFile);
			if (eventBannerFile) formData.append('noticeBannerImgFile', eventBannerFile);
			if (selectedNoticeType) formData.append('relatedContentsType', selectedNoticeType.value);
			if (selectedContentId) formData.append('relatedContentsId', selectedContentId);

			apiUrl = NOTICE + apiUrl;
			successMessage = '공지가' + successMessage;
			successUrl += 'notice';
		}

		await axios
		.post(apiUrl, formData, {})
		.then(() => {
			alert(successMessage);
			navigate(successUrl);
		}).catch((err) => {
			console.log(err);
			alert(`${handleType === 'add' ? '저장' : '수정'} 시 문제가 발생하였습니다. 담당자에게 문의해주세요.`);
			dispatch(resetLoading());
		});
	}, 'event-detail');

	// 모달 오버레이에서 스크롤 방지
	useEffect(() => {
		if (modalOpen) document.body.style.cssText = `overflow-y: hidden; width: 100%;`;
		return () => document.body.style.cssText = '';
	}, [modalOpen]);

	// 이벤트 이미지 파일 유효성 검사
	useEffect(() => {
		const getImgType = (file) => file.slice(file.lastIndexOf('.') + 1).toLowerCase();

		if (eventImgFile?.name && getImgType(eventImgFile?.name) !== 'jpg') {
			setIsFileError(true);
		} else {
			setIsFileError(false);
		}

		if (eventBannerFile?.name && getImgType(eventBannerFile?.name) !== 'jpg') {
			setIsBannerFileError(true);
		} else {
			setIsBannerFileError(false);
		}

		if (eventBeatBannerFile?.name && getImgType(eventBeatBannerFile?.name) !== 'jpg') {
			setIsBannerFileError(true);
		} else {
			setIsBannerFileError(false);
		}
	}, [eventBannerFile, eventImgFile, eventBeatBannerFile]);

	useEffect(() => {
		dispatch(setLoading());
		if (noticeDetailData || eventDetailData) handleSetInitialData();
		getAccountList().then(() => dispatch(resetLoading()));
		getSuggestWordRandomAll(suggestWordRandomSelectedId).then();
	}, []);

	useEffect(() => {
		if (noticeDetailData?.relatedContentsId || selectedContentId) {
			dispatch(setLoading());
			getDetailContent().then(() => dispatch(resetLoading()));
		}
	}, [noticeDetailData?.relatedContentsId, selectedContentId]);

	useEffect(() => {
		dispatch(setLoading());
		if (modalType === 'main' || modalType === 'sub') {
			getArticleList().then(() => dispatch(resetLoading()));
		} else {
			dispatch(getBeatList(beatParams));
			dispatch(resetLoading());
		}
	}, [pageNumber, searchType, beatType]);

	useEffect(() => {
		beatParams.offset = 0;
		setPageNumber(0);
		switch (bpm) {
			case '':
				setMinBpm(0);
				setMaxBpm(250);
				break;
			case '49':
				setMinBpm(0);
				setMaxBpm(49);
				break;
			case '50':
				setMinBpm(50);
				setMaxBpm(80);
				break;
			case '80':
				setMinBpm(80);
				setMaxBpm(100);
				break;
			case '100':
				setMinBpm(100);
				setMaxBpm(140);
				break;
			case '140':
				setMinBpm(140);
				setMaxBpm(180);
				break;
			case '180':
				setMinBpm(180);
				setMaxBpm(230);
				break;
			default:
				setMinBpm(0);
				setMaxBpm(250);
				break;
		}
		dispatch(getBeatList(beatParams));
	}, [dispatch, bpm, maxBpm, minBpm, minStartDate, maxStartDate]);

	useEffect(() => console.log(eventDetailData), [eventDetailData])

	// 이벤트 랜덤 제시어 마스터 조회
	const getSuggestWordRandomAll = useCallback( async (suggestWordRandomId) => {
		console.log('EventDetail > getSuggestWordRandomAll');
		const params = {
			limit: 100,
			offset: 0,
			activeYn: "Y"
		};
		await axios.get(`/setting/suggest/word_random`, {
			params,
		})
		.then((resp)=>{
			let temp = [{id:0, name:'사용 안함'}, ...(resp.data.suggestWordRandoms)];
			// console.log('EventDetail > getSuggestWordRandomAll > resp.data.suggestWordRandoms:',temp);
			setSuggestWordRandoms(temp);
			if(suggestWordRandomId && suggestWordRandomId!==0) {
				temp.forEach((el)=>{
					if(el.id === suggestWordRandomId) {
						setSuggestWordRandomSelectedId(el.id);
						setSuggestWordRandomSelectedName(el.name);
					}
				});
			}
		})
		.catch(error => {
			console.log(error.response.data);
		});
	}, []);

	const setSelectedSuggestWordRandom = (selectedOption) => {
		console.log('EventDetail > setSelectedSuggestWordRandom > selectedOption:', selectedOption);
		setSuggestWordRandomSelectedId(selectedOption.id);
		setSuggestWordRandomSelectedName(selectedOption.name);
	}

	return <>
		<TopContainer>
			<TopWrapper>
				<EventId>{id}</EventId>
				<ButtonWrapper>
					<NormalButton text="취소" onSubmit={goBack} className="cancelBtn"/>
					<NormalButton
						text={id ? '수정하기' : '저장하기'}
						onSubmit={() => handleEventBanner(id ? 'edit' : 'add').then(() => dispatch(resetLoading()))}
					/>
				</ButtonWrapper>
			</TopWrapper>
		</TopContainer>

		<TitleText>{type === 'event' ? '이벤트 기본 정보' : '공지 기본 정보'}</TitleText>
		<LineDiv/>

		<MainContainer>
			{
				type === 'event'
					? <>
						<InputWrapper>
							<FileWrapper>
								<FileInput
									type="eventImg"
									placeholder={eventData?.bannerVerticalImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
									label="둘러보기 상단 표시 이미지 (jpg 파일만 등록 가능)"
									value={eventBannerVerticalFile}
									name="eventBannerVerticalFile"
									setFile={handleFileSetData}
									setImgUrl={setEventBannerVerticalImgUrl}
									fileUrl={eventBannerVerticalImgUrl}
									Required
								/>
								{isBannerFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
								{eventBannerVerticalImgUrl ? <EventImg src={eventBannerVerticalImgUrl}/> : <EventImg src={eventData.bannerVerticalImgUrl || ''}/>}
							</FileWrapper>
						</InputWrapper>
						<InputWrapper>
							<FileWrapper>
								<FileInput
									type="eventImg"
									placeholder={eventData?.bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
									label="이벤트 목록용 이미지 (jpg 파일만 등록 가능)"
									value={eventBannerFile}
									name="eventBannerFile"
									setFile={handleFileSetData}
									setImgUrl={setEventBannerImgUrl}
									fileUrl={eventBannerImgUrl}
									Required
								/>
								{isBannerFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
								{eventBannerImgUrl ? <EventImg src={eventBannerImgUrl}/> : <EventImg src={eventData.bannerImgUrl || ''}/>}
							</FileWrapper>
						</InputWrapper>
						<InputWrapper>
							<FileWrapper>
								<FileInput
										type="eventImg"
										placeholder={eventData?.bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
										label="스튜디오 띠배너 이미지 (jpg 파일만 등록 가능)"
										value={eventBeatBannerFile}
										name="eventBeatBannerFile"
										setFile={handleFileSetData}
										setImgUrl={setEventBeatBannerImgUrl}
										fileUrl={eventBeatBannerImgUrl}
								/>
								{isBannerFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
								{eventBeatBannerImgUrl ? <EventImg src={eventBeatBannerImgUrl}/> : <EventImg src={eventData.beatBannerImgUrl || ''}/>}
							</FileWrapper>
						</InputWrapper>
						</>
					: <>
						{/*type이 공지(notice)일때*/}
						{detailContent.id && <>
							<TextField label="상세 페이지 컨텐츠 선택" text={selectedNoticeType?.name || '이벤트'}/>
							<Table colList={noticeModalColList} className="nonListTable">
								<SelectedNoticeModalRowComponent data={detailContent}
																 onClick={() => navigate('/operation/notice/add')}
																 editable={!id}/>
							</Table>
						</>}

						<SpaceDiv/>

						<InputWrapper>
							<FileWrapper>
								<FileInput
									type="eventImg"
									placeholder={eventData?.bannerImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
									label="공지 이미지 (jpg 파일만 등록 가능)"
									value={eventBannerFile}
									name="eventBannerFile"
									setFile={handleFileSetData}
									setImgUrl={setEventBannerImgUrl}
									fileUrl={eventBannerImgUrl}
									Required
								/>
								{isBannerFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
								{eventBannerImgUrl ? <EventImg src={eventBannerImgUrl}/> : <EventImg src={eventData.bannerImgUrl || ''}/>}
							</FileWrapper>
						</InputWrapper>
					</>
			}

			<SpaceDiv value="0"/>

			<InputWrapper>
				<LabelTextInput
					name="projectName"
					label="프로젝트 명"
					value={projectName}
					placeholder="프로젝트 명을 입력해주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
					Required
				/>
			</InputWrapper>

			<InputWrapper>
				<LabelTextInput
					name="title"
					label="제목"
					value={title}
					placeholder="제목을 입력해주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
					Required
				/>
			</InputWrapper>

			<InputWrapper>
				<LabelTextInput
					name="description"
					label="설명"
					value={description}
					placeholder="설명을 입력해주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
				/>
			</InputWrapper>

			<InputWrapper>
				<RadioButton
					value={activeYn}
					name="activeYn"
					label="공개 여부"
					onChange={handleInputSetData}
					text={['공개', '비공개']}
					type="activeYn"
					Required
				/>
			</InputWrapper>

			<InputWrapper>
				<DateWrapper>
					<DateInput initialStartDate={initialStartDate} onChange={handleInputSetData} name="startDt" label="이벤트 시작일" Required/>
					<DateInput initialStartDate={initialEndDate} onChange={handleInputSetData} name="endDt" label="이벤트 종료일" Required/>
				</DateWrapper>
			</InputWrapper>

			<InputWrapper>
				<SelectModule
					name="countryCode"
					placeholder="국가를 선택해주세요."
					value={GetCountryOption[countryCode]}
					label="국가"
					onChange={handleSetData}
					options={countryOptions}
					Required
				/>
			</InputWrapper>

			<InputWrapper>
				<SelectModule
					name="language"
					placeholder="언어 코드를 선택해주세요."
					value={GetLanguageOption[language]}
					label="언어 코드"
					onChange={handleSetData}
					options={languageOptions}
					Required
				/>
			</InputWrapper>

		</MainContainer>

		<SpaceDiv value="80px"/>

		{type === 'event' && <>
			<TitleText>이벤트 상세</TitleText>
			<LineDiv/>
			<SpaceDiv value="10px"/>

			{/* 상세 이미지 선택 */}
			<InputWrapper>
				<FileWrapper>
					<FileInput
						type="eventImg"
						placeholder={eventData?.contentsImgUrl ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
						label="상세 이미지 (jpg 파일만 등록 가능)"
						name="eventImgFile"
						value={eventImgFile}
						setFile={handleFileSetData}
						setImgUrl={setEventDetailImgUrl}
						fileUrl={eventDetailImgUrl}
						Required
					/>
					{isFileError && <ErrorText>jpg 파일명만 등록이 가능합니다. 다시 등록해 주세요.</ErrorText>}
					{eventDetailImgUrl ? <EventImg src={eventDetailImgUrl}/> : <EventImg src={eventData?.contentsImgUrl}/>}
				</FileWrapper>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 스튜디오 노출 선택 */}
			<InputWrapper className='area1'>
				<RadioButton
					value={studioYn}
					name="studioYn"
					label="스튜디오로 이동 버튼 노출"
					onChange={handleInputSetData}
					text={['노출', '비노출']}
					type="activeYn"
					Required
				/>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 이벤트 타입 선택 */}
			<InputWrapper className='area1'>
				<SelectModule
					name="type"
					placeholder="이벤트 타입을 선택해주세요."
					value={eventTypeKeyAndValue[eventType]}
					label="이벤트 타입"
					onChange={handleSelectSetData}
					options={commonFn.transObjectToOptions(eventTypeKeyAndValue)}
					Required
					readonly={id}
				/>
				<SpaceDiv2 value={20}>
					{(eventType === 'CP' || eventType === 'OP' || eventType === 'PW') && <>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="baundMusicTitle"
								label="Baund Music 타이틀"
								value={baundMusicTitle}
								placeholder="Baund Music 타이틀을 입력해주세요."
								onChange={handleInputSetData}
								onReset={handleResetData}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="baundMusicIdList"
								label="Baund Music 고유 ID"
								value={baundMusicIdList}
								placeholder="Baund Music 고유 ID 를 , 로 구분해서 입력해주세요."
								onChange={handleInputSetData}
								onReset={handleResetData}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'ENT' && <>
						<InputWrapper2 span={10}>
							<TextField type='-' label='단순응모형은 회원이 이벤트 상세 페이지 진입하여 버튼을 클릭하여 응모할 수 있습니다.'/>
						</InputWrapper2>
					</>}
					{eventType === 'ICD' && <>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-goalIdx"
								label="목표 초대 개수"
								type='number'
								value={invitation.goalIdx}
								placeholder="목표 출석체크 개수를 입력해주세요."
								onChange={e => setInvitation({...invitation, goalIdx: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-script1"
								label="이벤트 룰 설명"
								value={invitation.script1}
								placeholder="ex) 친구 5명 초대하고 이벤트에 응모하세요."
								onChange={e => setInvitation({...invitation, script1: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-script2"
								label="미션 완료 시 나타날 문구"
								value={invitation.script2}
								placeholder="ex) 미션 달성 완료! 아래 응모하기 버튼을 눌러주세요."
								onChange={e => setInvitation({...invitation, script2: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-script3"
								label="외부 공유 시 문구 (제목)"
								value={invitation.script3}
								placeholder="ex) 추천코드 입력하고 {{공연명}} 가자!"
								onChange={e => setInvitation({...invitation, script3: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-script4"
								label="외부 공유 시 문구 (설명)"
								value={invitation.script4}
								placeholder="ex) 가입 시 추천 코드를 입력하고 공연 티켓에 응모해보세요!"
								onChange={e => setInvitation({...invitation, script4: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10} mb={5}>
							<LabelTextInput
								name="invitation-script5"
								label="외부 공유 시 문구 (버튼)"
								value={invitation.script5}
								placeholder="ex) 지금 티켓 응모하기"
								onChange={e => setInvitation({...invitation, script5: e.target.value})}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'ATC' && <>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="goalIdx"
								label="목표 출석체크 개수"
								type='number'
								value={attendCheck.goalIdx}
								placeholder="목표 출석체크 개수를 입력해주세요."
								onChange={e => setAttendCheck({...attendCheck, goalIdx: e.target.value})}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'PTP' && <>
						<InputWrapper2 span={7}>
							<SelectModule
								name="type"
								placeholder="방식을 선택해주세요."
								value={pupRuleKeyAndValue[participate.whatType + '_' + participate.howType]}
								label="방식 선택"
								onChange={(selectedObj) => {
									setParticipate({
										...participate,
										whatType: selectedObj.value.split('_')[0],
										howType: selectedObj.value.split('_')[1],
									})
								}}
								options={ptpRuleTypeList}
							/>
						</InputWrapper2>
						<InputWrapper2 span={3}>
							<LabelTextInput
								name="participate-goalIdx"
								label="목표 개수"
								type='number'
								value={participate.goalIdx}
								placeholder="목표 개수 입력"
								onChange={e => setParticipate({...participate, goalIdx: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="participate-script1"
								label="이벤트 룰 설명"
								value={participate.script1}
								placeholder="ex) 좋아요 5개 누르고 이벤트에 응모하세요."
								onChange={e => setParticipate({...participate, script1: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="participate-script2"
								label="이벤트 현황 설명"
								value={participate.script2}
								placeholder="ex) 좋아요한 플레이 영상"
								onChange={e => setParticipate({...participate, script2: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10}>
							<LabelTextInput
								name="participate-script3"
								label="미션 완료 시 나타날 문구"
								value={participate.script3}
								placeholder="ex) 미션 달성 완료! 아래 응모하기 버튼을 눌러주세요."
								onChange={e => setParticipate({...participate, script3: e.target.value})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={10}><LineDiv/></InputWrapper2>
						<InputWrapper2 span={10}>
							<VideoInput
								onClick={() => openModal('PTP')}
								text="콘텐츠 가져오기"
								subText={
									participate.whatType === 'TG' && participate.howType === 'PRS' && participate.contentTitle
										? `[${participate.contentTitle}] 태그에 좋아요 ${participate.goalIdx}번 누르기`
										: ''
								}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'CAR' && <>
						<InputWrapper2 span={10}>
							<VideoInput
								onClick={() => openModal('CAR')}
								text="콘텐츠 가져오기"
								subText={communityBoardCategory.id ? `카테고리 : ${communityBoardCategory.name} (${communityBoardCategory.id})` : ''}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'EXL' && <>
						<InputWrapper2 span={3}>
							<LabelTextInput
								name='externalLink-title'
								label='버튼명'
								value={externalLink.title}
								placeholder="버튼명을 입력해주세요."
								onChange={e => setExternalLink({...externalLink, title: e.target.value})}
								onReset={() => setExternalLink({...externalLink, title: ''})}
							/>
						</InputWrapper2>
						<InputWrapper2 span={7}>
							<LabelTextInput
								name='externalLink-url'
								label='링크'
								value={externalLink.contentsUrl}
								placeholder="링크를 입력해주세요."
								onChange={e => setExternalLink({...externalLink, contentsUrl: e.target.value})}
								onReset={() => setExternalLink({...externalLink, contentsUrl: ''})}
							/>
						</InputWrapper2>
					</>}
					{eventType === 'BATTLE' && <>
						<InputWrapper2 span={4}>
							<RadioButton
								Required
								name="eventBattle"
								type="list"
								label="노출 버튼 선택"
								text={['배틀하기', '투표하기', '보러가기 (배틀 룸 이동)', '보러가기 (스페셜 배틀 리스트 이동)']}
								values={[1, 2, 3, 4]}
								value={String(battle.buttonIndex)}
								onChange={e => {
									setBattle({
										buttonIndex: Number(e.target.value),
										BattleId: e.target.value !== 3 ? 0 : battle.battleId
									})
								}}
							/>

							{battle.buttonIndex === 3 && <VideoInput
								className='eventBattle'
								onClick={() => openModal(eventType)}
								text="콘텐츠 가져오기"
								subText={battle.battleId > 0 && `배틀방 번호: ${battle.battleId}`}
							/>}
						</InputWrapper2>
					</>}
					{eventType === 'BATTLE_ROOM' && <>
						<InputWrapper2 span={4}>
							<VideoInput
								onClick={() => openModal(eventType)}
								text="콘텐츠 가져오기"
								subText={battle.battleId > 0 && `배틀방 번호: ${battle.battleId}`}
							/>
						</InputWrapper2>
					</>}
				</SpaceDiv2>
				{(eventType === 'ENT' || eventType === 'ICD' || eventType === 'ATC' || eventType === 'PTP') && <>
					<SpaceDiv value="20px"/>
					<LineDiv/>
					<SpaceDiv value="10px"/>
					<VideoInput
						onClick={() => {
							if (!id) openModal('promotion');
							else alert("프로모션은 수정할 수 없습니다.");
						}}
						text="프로모션 선택"
						subText={selectedPromotion.name ? `프로모션 : ${selectedPromotion.name}` : '프로모션을 선택해주세요.'}
					/>
				</>}
				{(eventType === 'ABOUT_BAUND_GOLD' || eventType === 'ABOUT_GOLD_CAMPAIGN') &&
					<MultiLabelTextInput
						name='directMoveLink'
						labels={[
							'버튼 클릭시 이동할 주소',
							`BaundGold란? 기본 이미지 주소: ${process.env.REACT_APP_WHAT_IS_BAUND_GOLD}`,
							`GoldCampaign이란? 기본 이미지 주소: ${process.env.REACT_APP_WHAT_IS_GOLD_CAMPAIGN}`
						]}
						value={directMoveLink}
						onChange={(e) => {setEventData({...eventData, directMoveLink: e.target.value})}}
						onReset={() => {setEventData({...eventData, directMoveLink: ''})}}
						Required
					>
				</MultiLabelTextInput>}
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 외부 영상 */}
			<InputWrapper className='area1'>
				{
					<InputWrapper className='external-add'>
						<VideoInput
							text={exVideoLinkList.length === 1 ? '추가' : '삭제'}
							subText=''
							onClick={() => {
								if (exVideoLinkList.length === 1) {
									setExVideoLinkList([
										...exVideoLinkList,
										{index: 2, title: '', imageUrl: '', contentsUrl: '', imageFile: null}
									]);
								} else {
									setExVideoLinkList(exVideoLinkList.filter((v, i) => i !== exVideoLinkList.length - 1));
								}
							}}
						/>
					</InputWrapper>
				}
				{
					exVideoLinkList.map((el, i) => <>
						{i !== 0 && <SpaceDiv value="30px"/>}
						<InputWrapper>
							<LabelTextInput
								name={`exVideoLink_title_${i + 1}`}
								label={`외부 영상${i + 1} 제목`}
								value={el.title}
								placeholder="제목을 입력해주세요."
								onChange={e => handleExVideoLink(i, 'title', e.target.value)}
								onReset={() => handleExVideoLink(i, 'title', '')}
							/>
						</InputWrapper>
						<SpaceDiv value="15px"/>
						<InputWrapper>
							<LabelTextInput
								name={`exVideoLink_url_${i + 1}`}
								label={`외부 영상${i + 1} 링크`}
								value={el.contentsUrl}
								placeholder="첨부하고 싶은 링크를 입력해 주세요. (ex: https:// 으로 시작)"
								onChange={e => handleExVideoLink(i, 'contentsUrl', e.target.value)}
								onReset={() => handleExVideoLink(i, 'contentsUrl', '')}
							/>
						</InputWrapper>
						<SpaceDiv value="15px"/>
						<FileWrapper>
							<FileInput
								type="eventImg"
								name={`exVideoLink_imgFile_${i + 1}`}
								placeholder={el.imageFile ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
								label={`외부 영상${i + 1} 썸네일 (jpg 파일만 등록 가능)`}
								value={el.imageFile}
								setFile={f => handleExVideoLink(i, 'imageFile', f)}
								setImgUrl={e => handleExVideoLink(i, 'imageUrl', e)}
								fileUrl={el.imageUrl}
							/>
							<EventImg src={el.imageUrl || ''}/>
						</FileWrapper>
					</>)
				}
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 영상(대) 제목 */}
			<InputWrapper className='area1'>
				<LabelTextInput
					name="articleLargeTitle"
					label="영상(대) 제목"
					value={articleLargeTitle}
					placeholder="제목을 입력해 주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
				/>
				<LineDiv/>
				<VideoInput onClick={() => openModal('main')} text="영상 추가하기" subText="영상(대)은 한 개의 영상만 선택이 가능합니다."/>
				<SelectedVideoContainer>
					{articleLargeData && <SelectedVideoComponent data={articleLargeData} name="main" deleteVideo={deleteVideo} editable/>}
				</SelectedVideoContainer>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 영상(소) 제목 */}
			<InputWrapper className='area1'>
				<LabelTextInput
					name="articleSmallTitle"
					label="영상(소) 제목"
					value={articleSmallTitle}
					placeholder="제목을 입력해 주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
				/>
				<LineDiv/>
				<VideoInput onClick={() => openModal('sub')} text="영상 추가하기"/>
				<SelectedVideoContainer>
					{articleSmallDataList?.map((el) => <SelectedVideoComponent data={el} name="sub" deleteVideo={deleteVideo} editable/>)}
				</SelectedVideoContainer>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 비트 리스트 제목 */}
			<InputWrapper className='area1'>
				<LabelTextInput
					name="beatTitle"
					label="비트 리스트 제목"
					value={beatTitle}
					placeholder="제목을 입력해 주세요."
					onChange={handleInputSetData}
					onReset={handleResetData}
				/>
				<LineDiv/>
				<VideoInput onClick={() => openModal('beat')} text="비트 추가하기"/>
				<Table colList={addBeatCollectionColList} className="nonListTable">
					{beatDataList?.map(el => <DetailAddBeatRowComponents data={el} name="beat" onClick={deleteVideo} selected editable/>)}
				</Table>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 필수 해시태그 */}
			<InputWrapper className='area1'>
				{/* 필수 해시태그 소유 계정 */}
				{id && requireTagsMemberPid
					? <TextField label="필수 해시태그 소유 계정" text={requireTagsMemberPid}/>
					: <SelectModule
						name="requireHashTagMemberId"
						placeholder={
							eventDetailData?.requireTagsDataList
								? '다른 공식계정에서 소유한 해시태그가 있습니다. 새로운 해시태그 등록을 원하시면 다시 선택해주세요.'
								: '필수 해시태그 소유 계정을 선택해주세요.'
						}
						value={requireHashTagMemberData?.pid}
						label="필수 해시태그 소유 계정"
						onChange={handleSetData}
						options={accountData}
					/>
				}

				<SpaceDiv value="15px"/>

				<TagTextInput
					placeholder="필수 해시태그를 입력해주세요."
					label="필수 해시태그"
					keyWord={tags}
					setKeyWord={setTags}
					addWord={addTag}
					setAddWord={setAddTag}
				/>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 유의사항 */}
			<InputWrapper className='area1'>
				<LabelPlusButtonInput
					name="noticeTextList"
					label="유의사항"
					value={noticeTextList}
					onClick={handleNoticeTextSetData}
					onEdit={onEditNoticeText}
					onRemove={onRemoveNoticeText}
				/>
				<SpaceDiv value="15px"/>
				<FileWrapper>
					<FileInput
						type="eventImg"
						name='eventNoticeImg'
						placeholder={eventNoticeImgFile ? '이미지가 등록되어 있습니다.' : '이미지를 등록해 주세요.'}
						label='유의사항 이미지'
						value={eventNoticeImgFile}
						setFile={setEventNoticeImgFile}
						setImgUrl={setEventNoticeImgUrl}
						fileUrl={eventNoticeImgUrl}
					/>
					<EventImg src={eventNoticeImgUrl}/>
				</FileWrapper>
			</InputWrapper>
			<SpaceDiv value="30px"/>

			{/* 이벤트 랜덤 제시어 */}
			<InputWrapper className='area1'>
				<SelectModule
						name="suggestWordCategoryIdAdd"
						placeholder="카테고리"
						value={suggestWordRandomSelectedName}
						label="이벤트 랜덤 제시어"
						options={suggestWordRandoms}
						onChange={setSelectedSuggestWordRandom}
						readonly={(studioYn==='N')}
				/>
			</InputWrapper>
		</>}

		{type === 'notice' && selectedContentId != null && <>
			<TitleText>상세 내용</TitleText>
			<LineDiv/>
			<MainContainer>
				<InputWrapper>
					<FileWrapper>
						<TextField
							label="상세 이미지"
							text={webUrl ? '이미지가 등록되어 있습니다.' : '이미지가 없습니다.'}
							type="fileInput"
						/>
						{webUrl
							? <EventDetailImg>
								<iframe id="frame" title="detailImg" src={webUrl} name="hidden" width="100%" height="100%" />
							</EventDetailImg>
							: <EventImg src=""/>}
					</FileWrapper>
				</InputWrapper>
				<SpaceDiv value="0"/>
				{exVideoLinkList.map((el, i) => <InputWrapper className='area2'>
					{i !== 0 && <SpaceDiv value="30px"/>}
					<TextField label="외부 영상1 제목" text={el.title}/>
					<SpaceDiv value="10px"/>
					<TextField label="외부 영상1 링크" text={el.contentsUrl}/>
					<SpaceDiv value="10px"/>
					<FileWrapper>
						<TextField
							label="외부 영상1 썸네일"
							text={el.imageUrl ? '이미지가 등록되어 있습니다.' : '이미지가 없습니다.'}
							type="fileInput"
						/>
						<EventImg src={el.imageUrl || ''}/>
					</FileWrapper>
				</InputWrapper>)}
				<SpaceDiv value="30px"/>
				<InputWrapper>
					<TextField label="영상(대) 제목" text={articleLargeTitle}/>
				</InputWrapper>
			</MainContainer>
			<SpaceDiv value="10px"/>
			<SelectedVideoContainer>
				{articleLargeData && <SelectedVideoComponent
					data={articleLargeData}
					name="main"
					deleteVideo={deleteVideo}
				/>}
			</SelectedVideoContainer>
			<SpaceDiv value="75px"/>
			<InputWrapper>
				<TextField label="비트 리스트 제목" text={beatTitle}/>
			</InputWrapper>
			<SpaceDiv value="10px"/>
			<Table colList={addBeatCollectionColList} className="nonListTable">
				{beatDataList?.map((el) => (
					<DetailAddBeatRowComponents
						data={el}
						name="beat"
						onClick={deleteVideo}
						selected
					/>
				))}
			</Table>
			<SpaceDiv value="75px"/>
			<InputWrapper>
				<TextField label="영상(소) 제목" text={articleSmallTitle}/>
			</InputWrapper>
			<SpaceDiv value="10px"/>
			<SelectedVideoContainer>
				{articleSmallDataList?.map((el) => <SelectedVideoComponent
					data={el}
					name="sub"
					deleteVideo={deleteVideo}
				/>)}
			</SelectedVideoContainer>
			<SpaceDiv value="55px"/>
			<MainContainer>
				<InputWrapper>
					<TextField label="필수 해시태그 소유 계정" text={requireHashTagMemberData?.pid}/>
				</InputWrapper>
				<InputWrapper>
					<TagTextInput
						placeholder="필수 해시태그를 입력해주세요."
						label="필수 해시태그"
						keyWord={tags || []}
						setKeyWord={setTags}
						addWord={addTag}
						setAddWord={setAddTag}
						className="unEditable"
					/>
				</InputWrapper>
				<SpaceDiv value="30px"/>
			</MainContainer>
		</>}

		{/* 영상 추가 모달 */}
		{(modalOpen && (modalType === 'main' || modalType === 'sub')) &&
			<VideoModal closeModal={closeModal} blockCloseBtn>
				<VideoModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
						<NormalButton text="저장하기" onSubmit={() => saveContentList()}/>
					</ModalButtonWrapper>
					<VideoModalTitle>선택 영상</VideoModalTitle>
					{selectedContentList?.length !== 0 && selectedContentList ? <>
						<TableTitle>{selectedContentList?.length}개 영상</TableTitle>
						<LineDiv/>
						<Table colList={eventModalColList} className="nonListTable">
							{selectedContentList?.map((el) => <SelectedEventRowComponent key={el.id} data={el} onClick={deleteVideo}/>)}
						</Table>
					</> : <>
						<VideoModalSubText>선택된 영상이 없습니다. 아래 검색 후 영상을 선택해주세요.</VideoModalSubText>
						<LineDiv/>
					</>}
					<SpaceDiv value="90px"/>
					<VideoModalTitle>영상 검색</VideoModalTitle>
					<TitleWrapper>
						<SearchWrapper>
							<SearchMenuSelect options={articleSearchTypeList} onClick={handleSelect} setState={setSearchType}/>
							<SearchInputWrapper>
								<SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
							</SearchInputWrapper>
						</SearchWrapper>
					</TitleWrapper>
					<PaginationWrapper>
						<TableTitle>{totalCount}개 영상</TableTitle>
						{totalCount !== 0 && <Pagination
							postsPerPage={postsPerPage}
							totalCount={totalCount}
							currentPage={pageNumber}
							pageDispatch={pageDispatch}
						/>}
					</PaginationWrapper>
					<LineDiv/>
					<Table
						colList={eventModalColList}
						thead={<>
							<ListHead title="id"/>
							<ListHead title="썸네일"/>
							<ListHead title="Pid"/>
							<ListHead title="이름"/>
							<ListHead title="비트명"/>
							<ListHead title="업로드 날짜"/>
							<ListHead title="공개 여부"/>
							<ListHead title="상태"/>
							<ListHead title=""/>
						</>}
					>
						{contentList?.map((el) => <EventModalRowComponent
							key={el.id}
							data={el}
							onClick={(data) => {
								if (!selectedContentList?.find((e) => e.id === data.id)) {
									setSelectedContentList([...selectedContentList, data]);
								}
							}}
						/>)}
					</Table>
				</VideoModalWrapper>
			</VideoModal>
		}

		{/* 비트 추가 모달 */}
		{(modalOpen && modalType === 'beat') &&
			<VideoModal closeModal={closeModal} className="beat" blockCloseBtn>
				<VideoModalWrapper>
					<ModalButtonWrapper>
						<NormalButton text="취소" onSubmit={closeModal} className="cancelBtn"/>
						<NormalButton text="저장하기" onSubmit={() => saveContentList()}/>
					</ModalButtonWrapper>
					<VideoModalTitle>선택 비트</VideoModalTitle>
					{selectedContentList?.length !== 0 && selectedContentList ? <>
						<TableTitle>{selectedContentList?.length}개 비트</TableTitle>
						<LineDiv/>
						<Table colList={addBeatCollectionColList} className="nonListTable">
							{selectedContentList?.map((data) => <DetailAddBeatRowComponents
								key={data.id}
								data={data}
								playBeat={playBeat}
								setPlayBeat={setPlayBeat}
								onClick={() => filterSelectedContent(data)}
								selected
								editable
							/>)}
						</Table>
					</> : <>
						<VideoModalSubText>선택된 비트가 없습니다. 아래 검색 후 비트를 선택해주세요.</VideoModalSubText>
						<LineDiv/>
					</>}
					<SpaceDiv value="90px"/>
					<VideoModalTitle>비트 검색</VideoModalTitle>
					<TitleWrapper>
						<SearchWrapper>
							<SearchMenuSelect options={beatSearchTypeList} onClick={handleSelect} setState={setBeatType}/>
							<SearchInputWrapper>
								<SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
							</SearchInputWrapper>
						</SearchWrapper>
					</TitleWrapper>
					<PaginationWrapper>
						<TableTitle>{beatTotalCount}개 비트</TableTitle>
						{beatTotalCount !== 0 && <Pagination
							postsPerPage={postsPerPage}
							totalCount={beatTotalCount}
							currentPage={pageNumber}
							pageDispatch={pageDispatch}
						/>}
					</PaginationWrapper>
					<LineDiv className="30"/>
					<Table
						colList={addBeatCollectionColList}
						thead={
							<>
								<ListHead title="Cover"/>
								<ListHead title="Title"/>
								<ListHead title="Pid"/>
								<ListHead title="장르"/>
								<ListSelect title="BPM" list={beatBpmOptions} onClick={handleSelect} setState={setBpm}/>
								<ListHead title="BeatKey"/>
								<ListHead title="공개여부"/>
								<ListDateSelect title="공개 날짜" setMinStartDate={setMinStartDate} setMaxStartDate={setMaxStartDate}/>
								<ListHead title=""/>
							</>
						}
					>
						{beatList?.map(el => <DetailAddBeatRowComponents
							key={el.id}
							data={el}
							onClick={addSelectedContent}
							playBeat={playBeat}
							setPlayBeat={setPlayBeat}
							editable
						/>)}
					</Table>
				</VideoModalWrapper>
			</VideoModal>
		}

		{/* 프로모션 선택 모달 */}
		{(modalOpen && modalType === 'promotion') &&
			<Modal closeModal={closeModal} blockCloseBtn option={{class: 'compact'}}>
				<VideoModalWrapper className='promotion'>
					<EventPromotion onSelect={data => {
						setSelectedPromotion({eventPromotionId: data.eventPromotionId, name: data.name});
						closeModal();
					}}/>
				</VideoModalWrapper>
			</Modal>
		}

		{/* PTP 타입의 방식 선택 모달 */}
		{(modalOpen && (modalType === 'PTP' || modalType === 'CAR')) &&
			<VideoModal closeModal={closeModal} blockCloseBtn>
				<VideoModalWrapper>
					<VideoModalTitle>콘텐츠 검색</VideoModalTitle>
					{
						modalType === 'PTP' &&
						<TitleWrapper>
							<SearchWrapper>
								<SearchMenuSelect
									onClick={handleSelect}
									setState={setSearchType}
									options={modalType === 'PTP' ? [{text: '태그', value: 'tag'}] : [{
										text: '',
										value: ''
									}]}
								/>
								<SearchInputWrapper>
									<SearchInput value={keyword} onChange={setKeyword} onKeyPress={enterKeyPress}/>
								</SearchInputWrapper>
							</SearchWrapper>
						</TitleWrapper>
					}
					{
						contentList.length === 0 ? <><TableTitle>데이터가 없습니다.</TableTitle></> : <>
							<PaginationWrapper>
								<TableTitle>{totalCount}개 콘텐츠</TableTitle>
								{totalCount !== 0 && <Pagination postsPerPage={postsPerPage} totalCount={totalCount}
																 currentPage={pageNumber} pageDispatch={pageDispatch}/>}
							</PaginationWrapper>
							<LineDiv/>
							<Table
								colList={[120, 250, 90]}
								thead={<><ListHead title="ID"/><ListHead title="이름"/></>}
							>
								{contentList?.map((el) => <ActionEventModalRowComponent
									key={el.id}
									data={el}
									onClick={(data) => {
										if (modalType === 'PTP' && participate.whatType === 'TG' && participate.howType === 'PRS') {
											setParticipate({...participate, contentId: data.id, contentTitle: data.tag,});
										}
										if (modalType === 'CAR' && eventType === 'CAR') {
											setCommunityBoardCategory({id: data.id, name: data.name});
										}
										closeModal();
									}}
								/>)}
							</Table>
						</>
					}
				</VideoModalWrapper>
			</VideoModal>
		}

		{/* 배틀 선택 모달*/}
		{(modalOpen && (modalType === 'BATTLE' || modalType === 'BATTLE_ROOM')) && <PushForBattle
			closeModal={closeModal}
			selectedList={selectedBattle && [selectedBattle]}
			maxSelectCnt={1}
			selectedSetter={el => {
				if (eventType === 'BATTLE') {
					setBattle({...battle, battleId: el[0].battleId});
				} else if (eventType === 'BATTLE_ROOM') {
					setBattle({buttonIndex: 0, battleId: el[0].battleId});
				}
				setSelectedBattle(el[0]);
			}}
		/>}

		<SpaceDiv value="50px"/>
	</>;
};

export default EventDetail;

// --------------------------------------------------------------------------------------------------------------------- CONTAINER

const TopContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 50px;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px 40px;
  grid-gap: 30px 40px;
`;

const SelectedVideoContainer = styled.div`
  display: flex;
  overflow-x: auto;
`;

// --------------------------------------------------------------------------------------------------------------------- ELEMENT

const EventId = styled.h1`
  width: 500px;
  margin-bottom: 10px;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const EventImg = styled.img`
  width: 100%;
  margin-top: 30px;
  border-radius: 10px;
`;

const EventDetailImg = styled.div`
  width: 100%;
  height: 400px;
  margin-top: 30px;

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    overflow: scroll;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &.editDetailImg {
    margin-top: 10px;
  }
`;

const VideoModalTitle = styled.span`
  font-size: 24px;
  font-weight: 600;
  margin-right: 20px;
`;

const VideoModalSubText = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme}) => theme.color.subGray};
`;

const SpaceDiv = styled.div`
  width: 100%;
  height: ${({value}) => value};
`;

const SpaceDiv2 = styled.div`
  width: 100%;
  padding: ${({value}) => `${value}px 0 0 ${value + 10}px`};
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 5px 10px;
`;

const ErrorText = styled.span`
  position: absolute;
  top: 60px;
  margin-top: 28px;
  font-size: 14px;
  color: ${({theme}) => theme.color.mainRed};
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({theme}) => theme.color.subUnactiveGray};
`;

const TableTitle = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({theme}) => theme.color.subDarkGray};
`;

// --------------------------------------------------------------------------------------------------------------------- WRAPPER

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const ButtonWrapper = styled.div`
  display: inline-block;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const InputWrapper = styled.div`
  &.area1 {
    background-color: #ccc;
    padding: 25px;
    border-radius: 6px;
  }

  &.external-add {
    text-align: right;
    display: block !important;
  }

  &.area2 {
    background-color: #f1f3f5;
    padding: 25px;
    border-radius: 6px;
  }
`;

const InputWrapper2 = styled.div`
  position: relative;
  grid-column: ${({span}) => span && `span ${span}`};
  margin-bottom: ${({mb}) => typeof (mb) === "number" ? `${mb}px` : ''};
  margin-top: ${({mt}) => typeof (mt) === "number" ? `${mt}px` : ''};
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  main {
    &:first-child {
      margin-right: 30px;
    }
  }
`;

const FileWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: minmax(20%, auto);
  margin-bottom: 20px;
  gap: 30px;
  grid-gap: 30px;
`;

const VideoModalWrapper = styled.div`
  width: 1175px;
  padding: 70px 90px 90px;
  margin-bottom: 100px;
  border-radius: 10px;
  box-shadow: ${({theme}) => theme.boxShadow};
  background-color: ${({theme}) => theme.color.mainWhite};
  
  &.promotion {
	padding: 30px;
    margin-bottom: 0;
  }
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;

  button {
    &:last-child {
      margin-left: 20px;
    }
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 30px 0 55px;
  align-items: center;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchInputWrapper = styled.div`
  min-width: 483px;
`;