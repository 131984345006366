import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import playIcon from '../../../assets/images/page/playIcon.png';
import pauseIcon from '../../../assets/images/page/pauseIcon.png';

interface AudioPlayerProps {
  src: string | undefined;
  handleLengthData: (length: number) => void;
}

const AudioPlayer = ({ src, handleLengthData }: AudioPlayerProps) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const audioPlayer = useRef<any>(null);
  const progressBar = useRef<any>(null);
  const animationRef = useRef<any>(null);

  const calcTime = (secs: number) => {
    const minutes = Math.floor(secs / 60);
    const returnedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.floor(secs % 60);
    const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${returnedMinutes} : ${returnedSeconds}`;
  };

  const togglePlayPause = () => {
    const prevValue = isPlaying;
    setIsPlaying(!prevValue);
    if (prevValue) {
      audioPlayer.current?.play().then((r: any) => r);
      animationRef.current = requestAnimationFrame(whilePlaying);
    } else {
      audioPlayer.current?.pause();
      cancelAnimationFrame(animationRef.current);
    }
  };

  const whilePlaying = () => {
    progressBar.current.value = audioPlayer.current.currentTime;
    changePlayerCurrentTime();
    animationRef.current = requestAnimationFrame(whilePlaying);
  };

  const changeRange = () => {
    audioPlayer.current.currentTime = progressBar.current.value;
    changePlayerCurrentTime();
  };

  const changePlayerCurrentTime = () => {
    progressBar.current.style.setProperty(
      '--seek-before-width',
      `${(progressBar.current.value / duration) * 100}%`,
    );
    setCurrentTime(progressBar.current.value);
  };

  return (
    <AudioWrapper>
      <Audio
        controls
        src={src}
        ref={audioPlayer}
        onLoadedMetadata={() => {
          const seconds = Math.floor(audioPlayer.current?.duration as number);
          const length = Math.round(audioPlayer.current.duration * 1000);
          setDuration(seconds);
          progressBar.current.max = `${seconds}`;
          handleLengthData(length);
        }}
      >
        <track default kind="captions" />
      </Audio>
      <PlayButton onClick={togglePlayPause}>
        {isPlaying ? <PlayIcon src={playIcon} /> : <PlayIcon src={pauseIcon} />}
      </PlayButton>
      <CurrentTime>
        {duration && !Number.isNaN(duration) ? calcTime(currentTime) : '00:00'}
      </CurrentTime>

      <ProgressBar>
        <input
          type="range"
          defaultValue="0"
          ref={progressBar}
          onChange={changeRange}
        />
      </ProgressBar>
      <CurrentTime>
        {duration !== 0 &&
          duration &&
          !Number.isNaN(duration) &&
          calcTime(duration)}
      </CurrentTime>
    </AudioWrapper>
  );
};

export default AudioPlayer;

const AudioWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -3px;
`;

const Audio = styled.audio`
  width: 0;
  height: 0;
  overflow: hidden;
`;

const PlayButton = styled.button`
  width: 44px;
  height: 44px;
`;

const PlayIcon = styled.img`
  width: 40px;
`;

const ProgressBar = styled.div`
  display: flex;
  align-items: center;

  input {
    --bar-bg: ${({ theme }) => theme.color.subBrightGray};
    --seek-before-width: 0%;
    --seek-before-color: ${({ theme }) => theme.color.mainRed};
    --knobby: ${({ theme }) => theme.color.mainRed};
    --selectedKnobby: ${({ theme }) => theme.color.mainRed};

    appearance: none;
    position: relative;
    width: 100%;
    height: 3px;
    outline: none;
    background: var(--bar-bg);

    // progressbar for Safari
    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: var(--bar-bg);
      border-radius: 4px;
      outline: none;
    }

    // progressbar for Firefox
    &::-moz-range-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      background: var(--bar-bg);
      border-radius: 4px;
      outline: none;
    }

    &::-moz-focus-outer {
      border: 0;
    }

    //progressbar for Chrome and Safari
    &::before {
      content: '';
      height: 3px;
      width: var(--seek-before-width);
      background-color: var(--seek-before-color);
      position: absolute;
      border-radius: 4px;
      top: 0;
      left: 0;
      z-index: 2;
      cursor: pointer;
    }

    //progressbar for Firefox
    &::-moz-range-progress {
      background-color: var(--seek-before-color);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      height: 3px;
    }

    //knobby for Chrome
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 13px;
      height: 13px;
      margin-top: -5px;
      border-radius: 50%;
      background: var(--knobby);
      cursor: pointer;
      position: relative;
      z-index: 3;
      box-sizing: border-box;
    }

    //knobby while dragging for Chrome and Safari
    &:active::-webkit-slider-thumb {
      transform: scale(1.2);
      background: var(--selectedKnobby);
    }

    //knobby for Firefox
    &::-moz-range-thumb {
      width: 16px;
      height: 16px;
      border: transparent;
      border-radius: 50%;
      background: var(--knobby);
      cursor: pointer;
      position: relative;
      z-index: 3;
      box-sizing: border-box;
    }

    //knobby while dragging for Firefox
    &:active::-moz-range-thumb {
      transform: scale(1.2);
      background: var(--selectedKnobby);
    }
  }
`;

const CurrentTime = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.color.subGray};
  letter-spacing: -0.5px;
  margin: 0 10px;
`;
