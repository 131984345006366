import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

// common
import Table from '../../components/common/List/Table';
import ListHead from '../../components/UI/ListHead';
import Modal from '../../components/common/Modal/Modal';
import LabelTextInput from '../../components/common/Input/LabelTetxInput';
import SelectModule from '../../components/common/Select/SelectModule';
import NormalButton from '../../components/common/Button/NormalButton';

// 액션
import {
  changeRekognitionDetail,
  getRekognitionList,
} from '../../redux/actions/operation/operation';

// hooks
import { useAppSelector } from '../../redux/store/hooks';

// definition
import { rekognitionRowComponentDataProps } from '../../definitions/beat';

// component
import RekognitionRowComponent from './components/RekognitionRowComponent';

interface dataTypes {
  id: number;
  language: string;
  activeYn: string;
  name: string;
  parentName: string;
  blockConfidence: number;
}

type data = {
  [key: string]: any;
};

//초기 rekognition 데이터
const initialRekogData: data = {
  id: '',
  activeYn: '',
  blockConfidence: '',
};

const RekognitionManagement = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [rekogData, setRekogData] = useState(initialRekogData);
  const managerName = useAppSelector((state) => state.user.user.name);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, activeYn, blockConfidence } = rekogData;

  const rekognitionList = useAppSelector(
    (state) => state.operation.rekognitionList,
  );

  // rekognition 불러오기 함수
  const getRekognition = useCallback(() => {
    const params = { language: '', limit: 100, offset: 0 };
    dispatch(getRekognitionList(params));
  }, [dispatch]);

  // modal 닫기
  const closeModal = () => {
    setModalOpen(false);
  };

  // rekognition 수정 저장하기
  const editRekognitionDetail = async () => {
    const params = { activeYn, blockConfidence, adminName: managerName };

    const data = { params, id };
    await dispatch(changeRekognitionDetail(data));
    await setModalOpen(false);
    await getRekognition();
  };

  // rekognition 수정 버튼 클릭
  const editRekognition = (e: dataTypes) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { id, activeYn, blockConfidence } = e;
    setRekogData({
      ...rekogData,
      id,
      activeYn,
      blockConfidence,
    });
    setModalOpen(true);
  };

  // rekognition input 수정
  const handleInputSetData = useCallback(
    (e: any) => {
      const { name, value } = e.target;
      setRekogData({ ...rekogData, [name]: value });
    },
    [rekogData],
  );

  // rekognition input 리셋
  const handleResetData = useCallback(
    (e) => {
      setRekogData({ ...rekogData, [e]: initialRekogData[e] });
    },
    [rekogData],
  );

  const handleSelectSetData = useCallback(
    (e, name) => {
      setRekogData({ ...rekogData, [name]: e });
    },
    [rekogData],
  );

  useEffect(() => {
    getRekognition();
  }, [getRekognition]);

  return (
    <>
      <TopContainer>
        {/*<Pagination*/}
        {/*  currentPage={1}*/}
        {/*  postsPerPage={currentPage + 1}*/}
        {/*  totalCount={10}*/}
        {/*/>*/}
      </TopContainer>
      {modalOpen && (
        <Modal closeModal={closeModal}>
          <InputContainer>
            <InputWrapper>
              <LabelTextInput
                name="blockConfidence"
                label="차단 기준 점수"
                value={blockConfidence}
                onChange={handleInputSetData}
                placeholder="기준 점수를 입력해주세요."
                onReset={handleResetData}
              />
            </InputWrapper>
            <InputWrapper>
              <SelectModule
                name="activeYn"
                placeholder="활성화 여부를 선택해주세요."
                value={activeYn}
                label="활성화 여부"
                onChange={handleSelectSetData}
                options={['Y', 'N']}
              />
            </InputWrapper>
            <InputWrapper>
              <NormalButton text="저장하기" onSubmit={editRekognitionDetail} />
            </InputWrapper>
          </InputContainer>
        </Modal>
      )}

      <MainContainer>
        <Table
          thead={
            <>
              <ListHead title="rekognition id" />
              <ListHead title="언어 코드" />
              <ListHead title="상위 카테고리" />
              <ListHead title="차단 기준명" />
              <ListHead title="차단 기준 점수" />
              <ListHead title="활성화 여부" />
              <ListHead title="" />
            </>
          }
        >
          {rekognitionList?.map((el: rekognitionRowComponentDataProps) => (
            <RekognitionRowComponent
              key={el.id}
              data={el}
              onClick={editRekognition}
            />
          ))}
        </Table>
      </MainContainer>
    </>
  );
};

export default RekognitionManagement;

const TopContainer = styled.div`
  width: 100%;
  z-index: 1;
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 50px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 483px;
  margin: 0 138px;
`;

const InputWrapper = styled.div`
  margin-bottom: 40px;

  &:nth-child(3) {
    margin: 30px auto 20px;
  }
`;
