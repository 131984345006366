import React from 'react';
import styled from 'styled-components';

// common
import EditButton from '../../../../../components/common/Button/EditButton';
import ListItem from '../../../../../components/common/List/ListItem';

// hook
import blankImg from "../../../../../assets/images/page/blankImg.png";

export const PerformanceModalRowComponent = ({ data, onClick, type }) => {
  const {performanceId, posterImgUrl, title, placeCity, placeHall, publicStartDate, publicEndDate} = data;

  return (
    <tr key={performanceId}>
      <td><ListItem text={performanceId} /></td>
      <td><CoverImg className="fixedArticle" url={posterImgUrl} /></td>
      <td><ListItem text={title} /></td>
      <td><ListItem text={placeCity} /></td>
      <td><ListItem text={placeHall} /></td>
      <td><ListItem text={publicStartDate} /></td>
      <td><ListItem text={publicEndDate} /></td>
      {type ==='add' && (
        <td>< EditButton onClick={() => onClick(data)} text="선택" className="selectBtn"/></td>
      )}
      {type ==='remove' && (
          <td>< EditButton onClick={() => onClick(data)} text="삭제" className="deleteBtn"/></td>
      )}
    </tr>
  );
};

const CoverImg = styled.div`
  width: 55px;
  height: 104px;
  margin: 0 auto 0;
  border-radius: 3px;
  background: url(${props => props?.url || blankImg}) center no-repeat;
  background-size: cover;
`;
