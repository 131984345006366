import styled from "styled-components";
import {useNavigate} from 'react-router-dom';
import React, {useCallback, useEffect, useState} from "react";
import {useLocation} from "react-router";
import {useDispatch} from "react-redux";
import axios from "axios";

import NormalButton from "../../../components/common/Button/NormalButton";
import {resetLoading, setLoading} from "../../../redux/slices/user";
import LabelTextInput from "../../../components/common/Input/LabelTetxInput";
import AdvertisementReportModal from "../component/AdvertisementReportModal";
import {
  ADVERTISEMENT,
  ADVERTISEMENT_CAMPAIGN,
} from "../../../constant/apiUrl";
import DateInput from "../../../components/common/Input/DateInput";
import {
  GetNotContainsAllCountryOption,
  notContainsAllCountryOptions, postsPerPage
} from "../../../constant/common";
import LabelTextAreaInput
  from "../../../components/common/Input/LabelTextAreaInput";
import MultiSelect from "../../../components/common/Select/MultiSelect";
import VideoInput from "../../../components/common/Input/VideoInput";
import ModalSingleAdvertiserSelector from "../component/ModalSingleAdvertiserSelector";
import Table from "../../../components/common/List/Table";
import ListHead from "../../../components/UI/ListHead";
import ListItem from "../../../components/common/List/ListItem";
import PaginationWithTotalCount
  from "../../../components/common/Pagination/PaginationWithTotalCount";
import {handleStatus} from "../advertisementCampaign/AdvertisementCampaigns";

// 광고 상세 화면(추가, 수정)
const AdvertisementDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 목록 화면에서 전달 받은 값
  const detailData = location.state;
  // 여기서는 광고 아이디
  const advertisementId = detailData !== null ? detailData.advertisementId : null;

  //외부 노출 가능한 문자열 광고 Id
  const [advertisementStrId, setAdvertisementStrId] = useState(null)
  // 광고 명
  const [title, setTitle] = useState('')
  // 개시일
  const [startDt, setStartDt] = useState(
      ''
      // getNowDateStringForStartDate()
  );
  // 종료일
  const [endDt, setEndDt] = useState(
      ''
      // '2222-12-31 23:59'
  );
  // 국가 코드
  const [countriesSelected, setCountriesSelected] = useState(['한국']);
  // 메모
  const [memo, setMemo] = useState('');
  // 광고주
  const [advertiser, setAdvertiser] = useState({
    advertiserId:0, advertiserName:''
  });

  // 상세 조회 //////////////////////////////////////////////////////////////////
  const getDetail = async () => {
    await axios.get(
        `${ADVERTISEMENT}/${advertisementId}`,
    )
    .then(res => {
      const detail = res.data.adminAdvertisement;
      setAdvertiser(detail.advertiser);

      setAdvertisementStrId(detail.advertisementStrId);
      setTitle(detail.title);
      detail?.startDt && setStartDt(detail?.startDt?.replace(' ', 'T').slice(0, 16));
      detail?.endDt && setEndDt(detail?.endDt?.replace(' ', 'T').slice(0, 16));
      // setCountry(detail.country);
      // 국가 선택이 있으면
      if(detail.countries) {
        let tmpCountriesSelected = [];
        detail.countries.forEach((country) =>{
          tmpCountriesSelected = [...tmpCountriesSelected, GetNotContainsAllCountryOption[country]]
        });
        setCountriesSelected(tmpCountriesSelected);
      }
      setMemo(detail.memo);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.message));
    });
  };
  // 초기 진입시 아이디 받았으면 데이터 가져오기. 광고주 정보 먼저 가져오기 위해
  useEffect(() => {
    // console.log('AdvertisementDetail > useEffect > advertiserList:',advertiserList, ' / advertisementId:', advertisementId);
    if (advertisementId) {
      getDetail().then();
      getAdvertisementCampaigns().then();
    }
  }, [advertisementId]);

  // 정보 저장 //////////////////////////////////////////////////////////////////
  // 데이트 저장시 사용
  const handleDate = (date, tRemove, start) => {
    let _date = date;

    if (tRemove && date.indexOf('T') < 0) {
      _date = date.split(' ');
      _date = _date[0] + 'T' + _date[1];
    }
    if (!tRemove && date.indexOf('T') > 0) {
      _date = date.split('T');
      _date = _date[0] + ' ' + _date[1];
    }

    return start ? `${_date.slice(0, 16)}:00` : `${_date.slice(0, 16)}:59`
  }

  // 저장
  const saveDetail = async () => {

    let notEnteredItems = [];
    if (!advertiser || advertiser.advertiserId === 0 ) notEnteredItems.push('- 광고주 id')
    if (!title) notEnteredItems.push('- 광고 식별제목');
    if (!startDt) notEnteredItems.push('- 노출 시작일시');
    if (!endDt) notEnteredItems.push('- 노출 종료일시');
    if (!countriesSelected || countriesSelected.length === 0) notEnteredItems.push('- 광고 노출 국가');

    if (notEnteredItems.length !== 0) {
      notEnteredItems = ['아래 입력값을 확인해주세요', ...notEnteredItems];
      alert(notEnteredItems.join('\n'));
      return;
    }

    if (new Date(endDt) < new Date()) {
      alert('노출 종료 일시는 오늘 이후부터 선택 가능합니다.');
      return;
    }
    if (startDt > endDt) {
      alert('노출 종료 일시는 노출 시작 일시 이후로 선택해야 합니다.');
      return;
    }

    const formData = new FormData();

    // 광고주 Id
    formData.append('advertiserId', advertiser.advertiserId);
    // 광고 명
    formData.append('title', title);
    // 개시일
    formData.append('startDate', handleDate(startDt, false , true));
    // 종료일
    formData.append('endDate', handleDate(endDt, false, false));
    // 국가
    let tmpCountries = [];
    notContainsAllCountryOptions.forEach((countryOption)=>{
      if(countriesSelected.includes(countryOption.name) ) {
        tmpCountries.push(countryOption.value);
      }
    })
    formData.append('countries', tmpCountries);
    // 메모
    formData.append('memo', memo);

    dispatch(setLoading());
    try {
      const result = (advertisementId)
        ? await axios.put(`${ADVERTISEMENT}/${advertisementId}`, formData)
        : await axios.post(`${ADVERTISEMENT}`, formData, {});
      if(result.data.adminAdvertisementCampaignList && result.data.adminAdvertisementCampaignList.length > 0) {

        let work = result.data.adminAdvertisementCampaignList.map(campaign=> {
              return "- " + campaign.title;
            });
        notEnteredItems = ['아래 연동된 광고캠페인의 노출 기간과 일치하지 않아 저장할 수 없습니다.', ...work];
        alert(notEnteredItems.join('\n'));
        console.log(result.data.adminAdvertisementCampaignList);
      }
      else {
        alert('저장 되었습니다.');
        navigate('/advertisement/advertisementSummary');
      }
    }
    catch(reason){
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    }
    finally{
      dispatch(resetLoading());
    }
  };

  // 국가 선택시
  const processSelectedCategory = (_countryName) => {
    // 선택된 국가에 존재하면
    if (countriesSelected?.indexOf(_countryName) !== -1) {
      // 제거
      setCountriesSelected(countriesSelected?.filter((el) => el !== _countryName));
      return;
    }
    // 존재하지 않으면 추가
    setCountriesSelected((prev) => [...prev, _countryName]);
  };

  //광고 캠페인 조회 ////////////////////////////////////////////////////////////
  // 페이징
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const pageDispatch = useCallback((e) => {
    setPageNumber(e - 1);
  }, []);

  const [advertisementCampaignList, setAdvertisementCampaignList] = useState([]);
  const getAdvertisementCampaigns = async () => {
    // console.log('AdvertisementDetail > getAdvertisementCampaigns');
    // 광고 아이디 지정 없으면 암것도 안함
    if(!advertisementId) return;
    axios.get(`${ADVERTISEMENT_CAMPAIGN}-by-advertisement`, {params : {
        advertisementId,
        offset: pageNumber * postsPerPage,
        limit: postsPerPage,
      }})
    .then(res => {
      setAdvertisementCampaignList(res.data.adminAdvertisementCampaignList);
      setTotalCount(res.data.count);
    })
    .catch(reason => {
      console.error(reason)
      alert('시스템 관리자에게 문의해 주세요.:\n' + JSON.stringify(reason.response.data.messages));
    });
  };

  // 캠페인 상세 페이지로 이동
  const moveToCampaignDetail = (_advertisementCampaignId) => {
    navigate(
        '/advertisement/advertisementCampaign/edit',
        {state:{advertisementCampaignId: _advertisementCampaignId}}
    )
  }
  // 랜딩 상세 페이지로 이동
  const moveToLandingDetail = (_advertisementLandingId) => {
    navigate(
        '/advertisement/advertisementLanding/edit',
        {state:{advertisementLandingId: _advertisementLandingId}}
    )
  }

  // 삭제
  const deleteSelected = async () => {
    if(!advertisementId || advertisementId === 0) {return;}
    if (!confirm('광고를 삭제하시겠습니까?')) {
      return;
    }

    await axios.delete(`${ADVERTISEMENT}/${advertisementId}`, {})
    .then(() => {
      alert('삭제 완료');
      navigate('/advertisement/advertisementSummary');
    })
    .catch (err => {
      const errorMessage = (err.response.status === 418)
          ? '연동된 광고캠페인이 있을 경우, 삭제할 수 없습니다.'
          : '문제가 발생하였습니다. 담당자에게 문의해주세요.';
      // console.log('AdvertisementLandings > deleteSelected > err.response:', err.response);
      alert(errorMessage);
    });
  };

  // 모달 영역 -----------------------------------------------------------------
  // 광고주 검색 팝업 표시
  const [advertiserModal, setAdvertiserModal] = useState(false);
  // 광고 리포트 팝업
  const [reportType, setReportType] = useState('');

  const openAdvertiserModal = () => {
    setAdvertiserModal(true);
  }

  const closeAdvertiserModal = () => {
    setAdvertiserModal(false);
  }

  const selectedAdvertiserSetter = (_selectedAdvertiser) => {
    setAdvertiser(_selectedAdvertiser);
    closeAdvertiserModal();
  }

  return <>

    <TitleText>광고 {advertisementId ? '상세':'생성'}</TitleText>
    <TopContainer>
      <ButtonWrapper jc={'flex-end'}>
        {/*<NormalButton text="취소" onSubmit={()=>navigate(-1)} className="cancelBtn"/>*/}
        {advertisementId && (
          <NormalButton
              text="삭제"
              className={(!advertisementId || advertisementId === 0) ? "unActiveBtn": "blockBtn"}
              onSubmit={deleteSelected}
          />
        )}
        <NormalButton
            text={'저장하기'}
            onSubmit={() => saveDetail().then(() => dispatch(resetLoading()))}
        />
      </ButtonWrapper>
    </TopContainer>
    <TopContainer>
      {advertisementId
          ? (<ButtonWrapper>
              <NormalButton text={"고객용 리포트"} onSubmit={()=>setReportType('briefReport')}/>
              <NormalButton text={"내부용 리포트"} onSubmit={()=>setReportType('fullReport')}/>
            </ButtonWrapper>)
          : (<SpaceDiv value={40} />)
      }
    </TopContainer>

    <MainContainer>
      {/* 광고 명*/}
      <InputWrapper>
        <LabelTextInput
            name="title" label="광고 식별 제목" placeholder="광고 제목을 입력해주세요." Required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            onReset={() => setTitle('')}
        />
      </InputWrapper>

      {/* 광고주 */}
      {advertisementId
        ? (<InputWrapper colSpan={2}>
          <LabelTextInput
              name="advertiser.name" label="광고주" placeholder="광고주를 선택해주세요." Required readOnly
              value={`${advertiser.name}(${advertiser.advertiserStrId})`}
          />
        </InputWrapper>)
        : (<>
          <InputWrapper>
            <LabelTextInput
                name="advertiser.name" label="광고주" placeholder="광고주를 선택해주세요." Required readOnly
                value={(advertiser.name)?`${advertiser.name}(${advertiser.advertiserStrId})`:''}
            />
          </InputWrapper>
          <InputWrapper mt={37}>
            <VideoInput text={`광고주 선택`} onClick={openAdvertiserModal}/>
          </InputWrapper>
        </>)
      }

      {/*공개 기간*/}
      <InputWrapper>
        <DateInput
            name="startDt" label="노출 시작일시" Required
            initialStartDate={startDt} onChange={(e) => setStartDt(e.target.value)}
        />
      </InputWrapper>
      <InputWrapper colSpan={2}>
        <DateInput
            name="endDt" label="노출 종료일시" Required
            initialStartDate={endDt} onChange={(e) => setEndDt(e.target.value)}
        />
      </InputWrapper>

      <InputWrapper colSpan={3}>
        <MultiSelect label="광고 노출 국가" name="countriesSelected" placeholder="국가를 선택해주세요." Required
                     value={countriesSelected}
                     onChange={processSelectedCategory}
                     options={[{name: '한국', value: 'KR'}].map((el)=>el.name)}
                     // options={notContainsAllCountryOptions.map((el)=>el.name)}
        />
      </InputWrapper>

      {/* 메모 */}
      <InputWrapper colSpan={3}>
        <LabelTextAreaInput
            name="memo" label="광고 메모" placeholder="메모를 입력해주세요."
            value={memo}
            setValue={(e)=> setMemo(e.target.value)}
            onReset={()=> setMemo('')}
        />
      </InputWrapper>
    </MainContainer>


    {advertisementId && (<>
      <LineDiv/>
      <SubTitle>연동된 광고캠페인 정보</SubTitle>
      <TopContainer>
        <PaginationWrapper>
          {totalCount !== 0 && (
              <PaginationWrapper>
                <PaginationWithTotalCount
                    postsPerPage={postsPerPage}
                    totalCount={totalCount}
                    totalCountPostfix={'개 광고캠페인'}
                    currentPage={pageNumber}
                    pageDispatch={pageDispatch}
                />
              </PaginationWrapper>
          )}
        </PaginationWrapper>
      </TopContainer>
      <MainContainer>
        <InputWrapper colSpan={3}>
          {advertisementCampaignList.length !== 0 ? (
              <Table
                  colList={[50, 100, 50, 100]}
                  thead={<>
                    <ListHead title="번호" />
                    <ListHead title="광고캠페인 이름"/>
                    <ListHead title="상태" />
                    <ListHead title="랜딩페이지 제목"/>
                  </>}
              >
                {
                  advertisementCampaignList?.map(ad =>
                      <tr key={ad.advertisementCampaignId}>
                        <td><ListItem text={ad.advertisementCampaignId} /></td>
                        <td onClick={() => moveToCampaignDetail(ad.advertisementCampaignId)}>
                          <ListItem text={ad.title} className={'link'}/>
                        </td>
                        <td><ListItem text={handleStatus(ad)} /></td>
                        {ad.adminAdvertisementLanding
                            ?(<td onClick={() => moveToLandingDetail(ad.adminAdvertisementLanding.advertisementLandingId)}>
                              <ListItem text={ad.adminAdvertisementLanding.title} className={'link'}/>
                            </td>)
                            :(<td/>)
                        }

                      </tr>
                  )
                }
              </Table>
          ) : (
              <SpanNoResult>연동된 광고캠페인이 없습니다.</SpanNoResult>
          )}
        </InputWrapper>
      </MainContainer>
    </>)}

    {/* -------------------------------------------------------------------------------- */}
    {/* 리포트 모달 */}
    {(advertiser.advertiserId !== 0 && advertisementStrId && reportType) && (
      <AdvertisementReportModal
          closeModal={() => setReportType('')}
          advertisementStrId={advertisementStrId}
          advertiser = {advertiser}
          advertisementTitle = {title}
          advertisementStartDt = {startDt}
          advertisementEndDt = {endDt}
          advertisementCampaigns = {advertisementCampaignList}
          reportType={reportType}
      />
    )}

    {/* 광고주 선택 모달*/}
    {advertiserModal && <ModalSingleAdvertiserSelector
        closeModal={closeAdvertiserModal}
        selectedAdvertiserSetter={selectedAdvertiserSetter}
    />}

  </>;
};
export default AdvertisementDetail;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${({jc}) => jc && `${jc}`};;
  align-items: center;

  button {
    margin-left: 20px;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  margin-top: 20px;
  padding-bottom: 10px;
  display: grid;
  grid-template-columns: 5fr 4fr 1fr;
  gap: 20px 30px;
  grid-gap: 20px 30px;
  margin-bottom: 30px;
`;

const InputWrapper = styled.div`
    grid-row: ${({row}) => row && `span ${row}`};
    grid-column: ${({colSpan}) => colSpan && `span ${colSpan}`};
    margin-bottom: ${({mb}) => typeof(mb) === "number" ? `${mb}px` : ''};
    margin-top: ${({mt}) => typeof(mt) === "number" ? `${mt}px` : ''};

    &.area1 {
      background-color: #ccc;
      padding: 15px;
      border-radius: 6px;
    }
`;

const LineDiv = styled.div`
  width: 100%;
  height: 1px;
  margin: 10px 0 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.subUnactiveGray};
`;

const TitleText = styled.h1`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;

const SpanNoResult = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: ${({ theme }) => theme.color.subGray};
`;

const PaginationWrapper = styled.div`
  margin-top: 10px;
`;

const SpaceDiv = styled.div`
  width: 100%;
  margin-bottom: ${({value}) => `${value}px`};
`;

const SubTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 35px;
  color: ${({theme}) => theme.color.mainBlack};
`;