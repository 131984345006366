import { createSlice } from '@reduxjs/toolkit';

export interface MenuType {
  id: number;
  name?: string;
  address?: string;
  subMenu?: Array<SubMenuType> | undefined;
}

interface SubMenuType {
  id: number;
  name: string;
  address: string;
}

const initialState = {
  menu: <MenuType>{
    id: 0,
    name: '',
    address: '',
    subMenu: [],
  },
  subMenu: <SubMenuType>{
    id: 0,
    name: '',
    address: '',
  },
  sideToggle: false,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.menu = action.payload;
    },
    setSubMenu: (state, action) => {
      state.subMenu = action.payload;
    },
    setSideToggle: (state, action) => {
      state.sideToggle = action.payload;
    },
  },
});

export default mainSlice;
export const { setMenu, setSubMenu, setSideToggle } = mainSlice.actions;
